import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';
import { OrderType } from './order_type.graphql';

export interface OrderTypeColumns {
  id: number;
  type: number;
  count?: number;
  width?: string;
  height?:string;
  row_id?: number;
  order_type_id: number;
  position_no: number;
  columns: OrderTypeColumns[],
  order_types: OrderType;
  font_size: string;
}

interface ResponseArray {
  order_type_columns: OrderTypeColumns[];
}

interface ResponseSingle {
    order_type_columns_by_pk: OrderTypeColumns
}

@Injectable()
export class ORDER_TYPE_COLUMNS_ALL extends Query<ResponseArray> {
  override document = gql`
    query ORDER_TYPE_COLUMNS_ALL($where: order_type_columns_bool_exp){
      order_type_columns(where: $where,order_by:{position_no:asc}) {
        id
        type
        count
        width
        height
        row_id
        order_type_id
        position_no
        columns(order_by:{position_no:asc}){
            id
            type
            count
            width
            height
            row_id
            order_type_id
            position_no
            font_size
            order_types{
                id
                name
                position_no
                description
                icon
                active
                process_type_id
                img
                url
            }
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_TYPE_COLUMNS_ID extends Query<ResponseSingle> {
  override document = gql`
    query ORDER_TYPE_COLUMNS_ID($id: Int!){
      order_type_columns_by_pk(id: $id) {
        id
        type
        count
        width
        height
        row_id
        order_type_id
        position_no
      }
    }
  `;
}

@Injectable()
export class ORDER_TYPE_COLUMNS_CREATE extends Mutation<any> {
  // connected_part_id - int | nullable
  override document = gql`
    mutation ORDER_TYPE_COLUMNS_CREATE(
      $type: Int!, 
      $count: Int,
      $width: String
        $height: String
      $row_id: Int,
      $order_type_id: bigint,
      $position_no: Int
      ) {
      insert_order_type_columns(objects: {
        type: $type, 
        count: $count,
        width: $width,
        height: $height,
        row_id: $row_id,
        order_type_id: $order_type_id,
        position_no: $position_no
      }) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}



@Injectable()
export class ORDER_TYPE_COLUMNS_UPDATE extends Mutation<any> {
  override document = gql`
    mutation ORDER_TYPE_COLUMNS_UPDATE($id: Int!, $changes: order_type_columns_set_input ) {
      update_order_type_columns(where: {id: {_eq: $id}}, _set: $changes) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}
@Injectable()
export class ORDER_TYPE_COLUMNS_MANY_UPDATE extends Mutation<any> {
  override document = gql`
  mutation UPDATE_ORDER_TYPE_COLUMNS_MANY($updates: [order_type_columns_set_input!]!) {
    update_order_type_columns_many(updates: $updates) {
      affected_rows
      returning {
        id
      }
    }
  }`;
}


@Injectable()
export class ORDER_TYPE_COLUMNS_DELETE extends Mutation<any> {
  override document = gql`
    mutation ORDER_TYPE_COLUMNS_DELETE($id: Int!) {
      delete_order_type_columns(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}


@Injectable()
export class ORDER_TYPE_COLUMNS_COUNT extends Query<any> {
  override document = gql`
    query ORDER_TYPE_COLUMNS_COUNT($where: order_type_columns_bool_exp) {
      order_type_columns_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}