import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs';
import { GET_GSM_BY_NUMBER, Gsm, GSM_CREATE } from 'src/app/graphql/gsm.graphql';
import { OrderType, ORDER_TYPE_ALL } from 'src/app/graphql/order_type.graphql';
import { Package, PACKAGE_ALL } from 'src/app/graphql/package.graphql';
import { USER_CREATE, USER_GET_EMAIL, USER_GET_ID, USER_UPDATE, USER_UPDATE_BY_PK } from 'src/app/graphql/user.graphql';
import { VehicleBrand, VEHICLE_BRAND_ALL } from 'src/app/graphql/vehicle-brand.graphql';
import { VehicleModelType, VEHICLE_MODEL_TYPE_ALL } from 'src/app/graphql/vehicle-model-type.graphql';
import { VehicleModelYear, VEHICLE_MODEL_YEAR_ALL } from 'src/app/graphql/vehicle-model-year.graphql';
import { VehicleModel, VEHICLE_MODEL_ALL } from 'src/app/graphql/vehicle-model.graphql';
import { Vehicle } from 'src/app/graphql/vehicle.graphql';
import { YearModel } from 'src/app/graphql/year.graphql';
import { CustomValidator } from 'src/app/helpers/custom.validator';
import { VehicleFuels, VehicleGears, VehicleKms, VehicleModels, VehicleModelTypes, VehicleModelYears } from 'src/app/interfaces/appointment.interface';
import { FireBaseEmailAuthReponse } from 'src/app/interfaces/firebase.interface';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { PasswordReset } from 'src/app/services/password.service';
import { greaterThanZeroValidator } from 'src/app/services/validators/formValidators.service';
import { environment } from 'src/environments/environment';
declare var grecaptcha: any;

@Component({
  selector: 'app-appointment-v3-arac-hizmet-secimi',
  templateUrl: './arac-hizmet-secimi.component.html',
  styles: [
  ],
  styleUrls:['./arac-hizmet-secimi.component.css'],
  providers:[
    ORDER_TYPE_ALL,
    VEHICLE_BRAND_ALL,
    VEHICLE_MODEL_ALL,
    VEHICLE_MODEL_YEAR_ALL,
    VEHICLE_MODEL_TYPE_ALL,
    USER_GET_EMAIL,
    USER_GET_ID,
    PasswordReset,
    GSM_CREATE,
    PACKAGE_ALL,
    USER_UPDATE_BY_PK,
    USER_CREATE,
    GET_GSM_BY_NUMBER
  ]
})
export class AracHizmetSecimiComponent implements OnInit {

  @Input() userFormInput:any;
  @Input() vehicleInfoInput:any;
  @Input() vehicleFormInput:any;
  @Input() orderTypeId: Number  = 1;
  @Input() packageList: Package[] | undefined;
  @Input() selectedPackage: any;
  @Input() packageKmList:any
  @Input() stepBack:boolean = false;
  @Input() lastStep:number | undefined;
  @Input() currentStep:number | undefined;
  @Input() removeApproved: boolean = false;



  private baseUrl: string = environment.laravelApiEndpoint;
  public orderTypes: OrderType[] | undefined;

  public selectedKmInfo = {
    selectedKmId:null,
    selectedKmName:null,
    customerKm: 0
  }
  public vehicleModelList: VehicleModels[] | undefined;
  public vehicleModelYearList: YearModel[] | undefined;
  public vehicleModelTypeList: VehicleModelTypes[] | undefined;
  public vehicleGearList: VehicleGears[] | undefined;
  public vehicleFuelList: VehicleFuels[] | undefined;
  public gsmInfo: Gsm[] | undefined;
 
  public brandId: BigInt | undefined;
  public modelId: BigInt | undefined;
  public modelTypeId: BigInt | undefined;
  public modelYearId: BigInt | undefined;
  public vehicleList: Vehicle[] | undefined;
  public vehicleId:BigInt | undefined;
  public brandName:String = "Marka Seçiniz";
  public modelName:String = "Model Seçiniz";
  public selectedYear:String = "Yıl Seçiniz";
  public modelTypeName:String = "Versiyon Seçiniz";
  public vehicleBrandList: VehicleBrand[] = [];
  public vehicleModels: VehicleModel[] | undefined;
  public selectedKms:String = "Km";
  public selecteEditYear:String = "Yıl";
  public selectedModel:any;
  public selectedBrand:any;
  public vehicleModelTypes: VehicleModelType[] = [];
  public garageName:String = "Garaj Seçiniz";
  public garageId: Number = 0;
  public gearId: number | undefined;
  public fuelId: number | undefined;
  public orderForm: any[] = [];
  public selectedVehicleBrandInfo: VehicleBrand | undefined;
  public userInfo: any;
  public vehicleKmsList: VehicleKms[] | undefined;
  public showModal:boolean = true;
  public IsPackageHave: boolean = false;
  public camelCaseSasiInput: string = '';


  public vehicleInfo = {
    chassis_no: "",
    plate: "",
    brand_name: "",
    model_name: "",
    year: "",
    model_type: "",
    fuel_type: "",
    gear_type: "",
    km_name: "",
    customerKm: 0
  }


  public vehicleForm;


  public userForm = new FormGroup({
    firstname: new FormControl('',[Validators.required]),
    lastname: new FormControl('',[Validators.required]),
    gsm_id: new FormControl(null, [Validators.required]),
    gsm: new FormControl(null,[
      Validators.required,
      Validators.pattern(/^[0-9]{10}$/) // Tam 10 rakamlı olmalı
    ]),
    email: new FormControl('',[Validators.required,Validators.email]),
    phone: new FormControl(''),
    approved: new FormControl(null,[Validators.required]),
    id: new FormControl(null),
    job: new FormControl(null),
    is_user_registered: new FormControl(null)
  })

  public userCreateForm = new FormGroup({
    firstname: new FormControl('',[Validators.required]),
    lastname: new FormControl('',[Validators.required]),
    gsm: new FormControl(null,[Validators.required]),
    email: new FormControl('',[Validators.required,Validators.email])
  })

  public userRegistered = {
    is_registered : false,
    email: "",
    gsm: ""
  }

  public workCount = 0;

  public backVehicleForm:any;
  public backVehicleInfo:any;
  public lastBrandId:number = 0;

  public siteKey: string = '6LewYBsqAAAAAEhU96qN_JunjX-youccbB_ilqk0'; // Google reCAPTCHA'dan aldığınız site anahtarınızı buraya ekleyin
  public captchaResolved: boolean = false;
  public captchaResponse: string = "";

  public modelIsClicked = false;
  public brandIsClicked = false;
  public yearIsClicked = false;
  public modelTypeIsClicked = false;

  
  @Output() orderTypeEmitter = new EventEmitter();
  @Output() vehicleFormEmitter = new EventEmitter<any>();
  @Output() userFormEmitter = new EventEmitter<any>();
  @Output() stepControlEmitter = new EventEmitter<boolean>();
  @Output() userInfoEmitter = new EventEmitter<any>();
  @Output() packageEmitter = new EventEmitter<any>();
  @Output() packageListEmitter = new EventEmitter<any>();
  @Output() packageKmListEmitter = new EventEmitter<any>();


  @ViewChild('userName') userNameInput!: ElementRef;
  @ViewChild('userSurname') userSurnameInput!: ElementRef;
  @ViewChild('userEmail') userEmailInput!: ElementRef;
  @ViewChild('userJob') userJobInput!: ElementRef;
  
  constructor(
    private getOrderTypeList: ORDER_TYPE_ALL,
    private alertifyService: AlertifyService,
    private getVehicleBrandList:  VEHICLE_BRAND_ALL,
    private authService: AuthService,
    private http: HttpClient,
    private getVehicleModels: VEHICLE_MODEL_ALL,
    private getVehicleModelYears: VEHICLE_MODEL_YEAR_ALL,
    private getVehicleModelTypes: VEHICLE_MODEL_TYPE_ALL,
    private checkUser: USER_GET_EMAIL,
    private getUser: USER_GET_ID,
    private resetService: PasswordReset,
    private createGsm: GSM_CREATE,
    private getPackageList: PACKAGE_ALL,
    private userUpdate: USER_UPDATE_BY_PK,
    private userCreate: USER_CREATE,
    private renderer: Renderer2,
    private getGmsByNumber: GET_GSM_BY_NUMBER

  ) {
    window.scrollTo(0, 0);
    this.vehicleForm= new FormGroup({
      name: new FormControl('', [Validators.required]),
      brand_id: new FormControl(0, [Validators.required, CustomValidator.isGratherThanZero]),
      model_id: new FormControl(0, [Validators.required, CustomValidator.isGratherThanZero]),
      model_year_id: new FormControl(0, [Validators.required, CustomValidator.isGratherThanZero]),
      type_id: new FormControl(0, [Validators.required, CustomValidator.isGratherThanZero]),
      gear_type_id: new FormControl(0, [Validators.required, CustomValidator.isGratherThanZero]),
      fuel_type_id: new FormControl(0, [Validators.required, CustomValidator.isGratherThanZero]),
      last_km: new FormControl(0, [Validators.required, CustomValidator.isGratherThanZero]),
      km_id: new FormControl(0, [Validators.required, CustomValidator.isGratherThanZero]),
      chasses_no: new FormControl(null, [Validators.minLength(17), Validators.maxLength(17)]),
      is_package_have: new FormControl(false, [Validators.required]),
      beygir: new FormControl(null, []),
      kwh: new FormControl(null, []),
      year_id: new FormControl(null, []),
      note: new FormControl(null, []),
      year: new FormControl(null,[])
    }
   
);
   }

  

  async ngOnInit(): Promise<void> {
   
    await this.getVehicleBrands();
    //this.expired();
    
    this.userForm.patchValue(this.userFormInput);
    this.vehicleInfo = this.vehicleInfoInput;
   // this.vehicleForm = this.vehicleFormInput;

    this.setUserForm('approved',true);

    if(this.stepBack){

      this.userForm.patchValue({approved:null});
      this.userRegistered.is_registered = true;
      this.captchaResolved = false;

      if(this.vehicleForm.valid){
        setTimeout(()=>{
          this.fillInputs();
        },1000)
      }
      
    
     // this.selectedTwo('package',this.selectedPackage.packageInfo,true);

    }

    //this.loadRecaptcha(); // captcha kaldırıldı
    

   
  }



  resolved(captchaResponse: any) {
    
    this.captchaResolved = true;
    this.captchaResponse = captchaResponse;
   // this.setUserForm('approved',true);
 
  }

  loadRecaptcha() {
    const script = this.renderer.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      this.renderRecaptcha();
    };
    this.renderer.appendChild(document.body, script);


  }

  renderRecaptcha() {
    if (window['grecaptcha']) {
      window['grecaptcha'].render('recaptcha-container', {
        sitekey: this.siteKey,
        callback: (response: string) => {
          this.resolved(response);
        },
        'expired-callback': () => {
          this.expired();
        }
      });
    }
  }

  expired() {
    this.captchaResolved = false;
    this.setUserForm('approved',null);
    this.stepControlEmitter.emit(false);
    
  }

  ngOnChanges(){
    if(this.removeApproved){
      this.userForm
      .patchValue({approved:null})
    }


  }

  fillInputs(){


  }


  async getOrderTypes(){
    await this.getOrderTypeList.watch({where:{active:{_eq:true}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.orderTypes = resp.data.order_types;
      }
    })
  }

  

  async getVehicleBrands(){    
    await this.getApi('appointment/get-vehicle-brands')
      .pipe()
      .subscribe(response => {
      
        this.vehicleBrandList = response;
       
        

        if(this.stepBack == true){
        }
  
    })

    /*this.getVehicleBrandList.watch({where:{active:{_eq:true}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }

      if(resp.data){
        this.vehicleBrandList = resp.data.vehicle_brands;
      }
    })*/
  }

  async selectedTwo(key: string, $event:any,isBack = false){



    switch (key) {
      case "brand":
        if( $event != this.lastBrandId || this.vehicleModelList == undefined ){

          

          this.lastBrandId = $event;
          await this.vehicleBrandList.forEach((item)=>{
            if(item.id == $event){
              this.selectedVehicleBrandInfo = item;
            }
          })

          if(!this.stepBack){
            this.vehicleForm.patchValue({
              brand_id: 0,
              model_id: 0,
              type_id: 0,
              model_year_id: 0,
              gear_type_id:0,
              fuel_type_id:0,
              km_id: 0,
              last_km: 0,
              is_package_have:false
            });
            //this.vehicleInfo.customerKm = 0;
          }
         
          this.packageList = [];

          if(this.selectedVehicleBrandInfo){
            this.vehicleInfo.brand_name = this.selectedVehicleBrandInfo.name.toString();
          }
          
          this.workCount += 1;


          await this.http.post<any>(this.baseUrl + "appointment/get-vehicle-models",
          {
            brand_id:$event
        
        },
        { headers: { token: `${localStorage.getItem('access_token')}` } }
        ).subscribe(response=>{

         

            if(response && response.status){
             
              

                this.brandId = $event;
           //     this.vehicleForm.patchValue({brand_id:$event});
                this.vehicleForm.get('brand_id')?.patchValue($event);
                this.vehicleForm.get('brand_id')?.updateValueAndValidity();
                
                this.vehicleModelList = response.result;

                this.modelIsClicked = true;

                if(!this.stepBack){

                  this.modelId = undefined;
                  this.modelYearId = undefined;
                  this.modelTypeId = undefined;
                  this.modelName = 'Model';
                  this.modelTypeName = 'Model Tipi';
                  this.selectedKms = "";
                  this.selecteEditYear = "Yıl Seçiniz";
                  this.selectedYear = "Yıl Seçiniz";
                  this.vehicleModelYearList=[];
                  this.vehicleModelTypes = [];
                  this.selectedKms = "";
                  this.vehicleForm.patchValue({km_id:0,is_package_have:false});
                  this.vehicleInfo.customerKm = 0
                  this.packageKmList = [];
                  this.vehicleFormEmitter.emit({vehicleForm: this.vehicleForm.value,vehicle_info:this.vehicleInfo});
                }else{
                  
                  this.selectedTwo('model',this.vehicleForm.value.model_id);
                }
              

            }else{
              this.alertifyService.error(response.message)
            }
          
           
          })

        


         /* await this.getVehicleModels.watch({where:{vehicle_brand_id:{_eq:$event}}}).valueChanges.subscribe((result:any)=>{ 

            this.brandId = $event;
            this.modelId = undefined;
            this.modelYearId = undefined;
            this.modelTypeId = undefined;
            this.modelName = 'Model';
            this.modelTypeName = 'Versiyon Seçiniz';
            this.selectedKms = "";
            this.selecteEditYear = "Yıl Seçiniz";
            this.selectedYear = "Yıl Seçiniz";
            this.vehicleModelYearList=[];
            this.vehicleModelTypes = [];

          this.vehicleModelList = result.data.vehicle_models;
         
 
        });*/
          
        
       
      }
      break;

      case "model":
        //this.selectedModel = $event;
        if($event != this.modelId || this.stepBack){
          this.vehicleForm.patchValue({model_id:$event});

  

          if(!this.stepBack){
            this.vehicleForm.patchValue({
          
              type_id: 0,
              model_year_id: 0,
              gear_type_id:0,
              fuel_type_id:0,
              km_id: 0,
              last_km: 0,
              is_package_have:false
            });
            this.packageList = [];
            this.selectedPackage = [];
            //this.vehicleInfo.customerKm = 0;
            this.selectedYear = "Yıl Seçiniz";
          }
       

          this.modelId = $event;
          this.clearFormFields(["model_type_id","year_id"]);
         
          this.modelTypeName = 'Versiyon Seçiniz';
            this.selectedKms = "";
            this.selecteEditYear = "";
            this.vehicleModelYearList=[];
           
            this.vehicleModelTypes = [];

            if(this.vehicleModelList){
            await this.vehicleModelList.forEach((item)=>{
              if(item.id == $event){
                this.vehicleInfo.model_name = item.name.toString();
              }
            })
          }

            let url = 'appointment/get-vehicle-model-years';
            await this.getApi(url,{model_id:$event}).pipe().subscribe(async response => {
              if(response){
               /* this.vehicleModelYearList = response.sort((a, b) => {
                  return parseInt(b.name) - parseInt(a.name);
                });*/
                this.vehicleModelYearList = response;
                this.modelYearId = undefined;
                this.modelTypeId = undefined;
                this.selectedKms = "";
                this.yearIsClicked = true;
               
                await this.getPackages();
                this.vehicleModelTypes = [];
    
                if(!this.stepBack){
                  this.selecteEditYear = "Yıl Seçiniz";
                  this.selectedYear = "Yıl Seçiniz";
                  this.vehicleFormEmitter.emit({vehicleForm: this.vehicleForm.value,vehicle_info:this.vehicleInfo});
                  
             
                 }else{
                  this.selectedTwo('year',{id:null,name:this.vehicleForm.value.model_year_id})
                 }
               
              }
            });
/*
            await this.getVehicleModelYears.watch({where:{vehicle_model_id:{_eq:$event}}}).valueChanges.subscribe((result:any)=>{ 

              if(result.errors){
                this.alertifyService.error(result.errors[0].message);
              }
             
              if(result.data){
                this.vehicleModelYearList = result.data.vehicle_model_years;
              }

           
              this.modelYearId = undefined;
              this.modelTypeId = undefined;
              this.selectedKms = "";
              this.selecteEditYear = "Yıl Seçiniz";
              this.selectedYear = "Yıl Seçiniz";
              this.getPackages();
              this.vehicleModelTypes = [];

          
             
 
            });*/
          

            
        }

       
       
      break;

      case "year":
        if(this.stepBack){

        }

        this.selectedYear = $event['name'];
        if(parseInt($event['id']) > 0){
          this.IsPackageHave = true;
          
          
          this.vehicleForm.controls["type_id"].setValidators([Validators.required,CustomValidator.isGratherThanZero]);
          this.vehicleForm.controls["type_id"].updateValueAndValidity();
          this.vehicleForm.controls["chasses_no"].setValidators([]);
          this.vehicleForm.controls["chasses_no"].updateValueAndValidity();
          this.vehicleForm.controls["beygir"].setValidators([]);
          this.vehicleForm.controls["beygir"].updateValueAndValidity();
          this.vehicleForm.controls["kwh"].setValidators([]);
          this.vehicleForm.controls["kwh"].updateValueAndValidity();
          this.vehicleForm.controls["note"].setValidators([]);
          this.vehicleForm.controls["note"].updateValueAndValidity();
          this.vehicleForm.controls["fuel_type_id"].setValidators([]);
          this.vehicleForm.controls["fuel_type_id"].updateValueAndValidity();
          this.vehicleForm.controls["gear_type_id"].setValidators([]);
          this.vehicleForm.controls["gear_type_id"].updateValueAndValidity();
          this.vehicleForm.controls["model_year_id"].setValidators([]);
          this.vehicleForm.controls["model_year_id"].updateValueAndValidity();
          this.vehicleForm.controls["name"].setValidators([Validators.required]);
          this.vehicleForm.controls["name"].updateValueAndValidity();
          this.vehicleForm.controls["km_id"].setValidators([Validators.required,CustomValidator.isGratherThanZero]);
          this.vehicleForm.controls["km_id"].updateValueAndValidity();
          
          this.vehicleForm.patchValue({is_package_have: true});
          if($event['id'] != this.modelYearId){
            this.modelYearId = $event['id'];
            this.vehicleModelTypeList =  [];

            this.vehicleForm.patchValue({ model_year_id: $event['id']});
          if(!this.stepBack){
            this.vehicleForm.patchValue({
             
              type_id: 0,
              gear_type_id:0,
              fuel_type_id:0,
              km_id: 0,
              last_km: 0
           
            });

            this.packageList = [];
            if(!this.stepBack){
              this.selectedPackage = [];
            }
           
          }

           if(!this.stepBack){
              this.vehicleInfo.customerKm = 0;

           }

            if(this.vehicleModelYearList){
              await this.vehicleModelYearList.forEach((item)=>{

                if(item.id == $event['id']){
                  this.vehicleInfo.year = item.name.toString();
                }
              })
            }

           /* this.getVehicleModelTypes.watch({where:{vehicle_model_year_id:{_eq:$event}}}).valueChanges.subscribe((result:any)=>{
              this.vehicleModelTypeList = result.data.vehicle_model_types
             
              this.vehicleForm.patchValue({km_id: null});
              this.vehicleInfo.customerKm = 0;
              this.packageList = [];
             // this.getPackages();
            })*/
            let url = 'appointment/get-vehicle-model-types';
            await this.getApi(url,{year_id:this.modelYearId,order_type_id:this.orderTypeId}).pipe().subscribe(response => {
              this.modelYearId = $event['id'];
              this.vehicleModelTypeList = response;
              if(!this.stepBack){
                this.vehicleForm.patchValue({km_id: null});
               // this.vehicleInfo.customerKm = 0;
              }
             
              this.packageList = [];
              this.clearFormFields(["model_type_id"]);
              this.modelTypeName = "Model Tipi";
              this.modelTypeIsClicked = true;
           
         
            })


              
         /*   this.vehicleModelYearList?.filter((data: {id:any,year_id:any}) => {
              if(data.id ===  $event){
                this.selectedYear = data.year_id;
                //this.formGroup.patchValue({year_id:data.year_id});
                this.clearFormFields(["model_type_id"]);
                this.modelTypeName = "Model Tipi";
              }
            });*/
            
          }
        
       }else{
        this.vehicleModelTypeList = [];
        this.IsPackageHave = false;

        this.vehicleForm.controls["chasses_no"].setValidators([Validators.required,Validators.minLength(17), Validators.maxLength(17)]);
        this.vehicleForm.controls["chasses_no"].updateValueAndValidity();
        this.vehicleForm.controls["type_id"].setValidators([]);
        this.vehicleForm.controls["type_id"].updateValueAndValidity();
        this.vehicleForm.controls["fuel_type_id"].setValidators([Validators.required,CustomValidator.isGratherThanZero]);
        this.vehicleForm.controls["fuel_type_id"].updateValueAndValidity();
        this.vehicleForm.controls["gear_type_id"].setValidators([Validators.required,CustomValidator.isGratherThanZero]);
        this.vehicleForm.controls["gear_type_id"].updateValueAndValidity();
        this.vehicleForm.controls["model_year_id"].setValidators([]);
        this.vehicleForm.controls["model_year_id"].updateValueAndValidity();
        this.vehicleForm.controls["note"].setValidators([Validators.required]);
          this.vehicleForm.controls["note"].updateValueAndValidity();
        this.vehicleForm.controls["name"].setValidators([]);
        this.vehicleForm.controls["name"].updateValueAndValidity();
        this.vehicleForm.controls["km_id"].setValidators([]);
        this.vehicleForm.controls["km_id"].updateValueAndValidity();
        this.vehicleForm.controls["beygir"].setValidators([Validators.required,CustomValidator.isGratherThanZero]);
          this.vehicleForm.controls["beygir"].updateValueAndValidity();
          this.vehicleForm.controls["kwh"].setValidators([Validators.required,CustomValidator.isGratherThanZero]);
          this.vehicleForm.controls["kwh"].updateValueAndValidity();
          
          this.vehicleForm.patchValue({
          is_package_have: this.IsPackageHave,
          year_id:$event.name,
          year:$event.name
        });
        await this.getApi('appointment/get-vehicle-gear-fuel-all',{}).pipe().subscribe(async response => {

          this.vehicleGearList = response.gears;
          this.vehicleFuelList = response.fuels;
          this.vehicleKmsList = [];

          if(!this.stepBack){
            this.vehicleForm.patchValue({ km_id: null });
           // this.vehicleInfo.customerKm = 0;
          }

          await this.selectedTwo('fuel',this.vehicleForm.value.fuel_id);
          await this.selectedTwo('gear',this.vehicleForm.value.gear_id);
         // this.selectedKms = "";
         // this.vehicleInfo.customerKm = 0;
          this.selectedTwo('km',this.vehicleKmsList);
         
          //this.getPackages();


        });
       }
      break; 

      case "vehicle_model_types":
       
        //this.vehicleTypeFromGroup.patchValue({model_type_id:$event})
        this.modelTypeId = $event;
       
      //  if(!this.stepBack){
          this.packageList = [];
          if(!this.selectedPackage){
            this.selectedPackage = [];
          }
          if(this.modelTypeId == null){
            this.IsPackageHave = false;
              //  this.selectedTwo('year',{id:null,name:this.vehicleForm.value.model_year_id});
            // this.vehicleModelTypeList = [];
            this.modelTypeName = 'Aradığım versiyon burada yok';
           
      
              this.vehicleForm.controls["chasses_no"].setValidators([Validators.required,Validators.minLength(17), Validators.maxLength(17)]);
              this.vehicleForm.controls["chasses_no"].updateValueAndValidity();
              this.vehicleForm.controls["type_id"].setValidators([]);
              this.vehicleForm.controls["type_id"].updateValueAndValidity();
              this.vehicleForm.controls["fuel_type_id"].setValidators([Validators.required,CustomValidator.isGratherThanZero]);
              this.vehicleForm.controls["fuel_type_id"].updateValueAndValidity();
              this.vehicleForm.controls["gear_type_id"].setValidators([Validators.required,CustomValidator.isGratherThanZero]);
              this.vehicleForm.controls["gear_type_id"].updateValueAndValidity();
              this.vehicleForm.controls["model_year_id"].setValidators([]);
              this.vehicleForm.controls["model_year_id"].updateValueAndValidity();
              this.vehicleForm.controls["note"].setValidators([Validators.required]);
                this.vehicleForm.controls["note"].updateValueAndValidity();
              this.vehicleForm.controls["name"].setValidators([]);
              this.vehicleForm.controls["name"].updateValueAndValidity();
              this.vehicleForm.controls["km_id"].setValidators([]);
              this.vehicleForm.controls["km_id"].updateValueAndValidity();
              this.vehicleForm.controls["beygir"].setValidators([Validators.required,CustomValidator.isGratherThanZero]);
                this.vehicleForm.controls["beygir"].updateValueAndValidity();
                this.vehicleForm.controls["kwh"].setValidators([Validators.required,CustomValidator.isGratherThanZero]);
                this.vehicleForm.controls["kwh"].updateValueAndValidity();
                
                this.vehicleForm.patchValue({
                  is_package_have: this.IsPackageHave,
                  model_type_id: null,
                  gear_type_id: null,
                  fuel_type_id: null,
                  type_id: null,
                  year_id: this.vehicleForm.value.model_year_id

              });

              await this.getApi('appointment/get-vehicle-gear-fuel-all',{}).pipe().subscribe(async response => {
      
                this.vehicleGearList = response.gears;
                this.vehicleFuelList = response.fuels;
                this.vehicleKmsList = [];
      
                if(!this.stepBack){
                  this.vehicleForm.patchValue({ km_id: null });
                // this.vehicleInfo.customerKm = 0;
                }
      
                await this.selectedTwo('fuel',this.vehicleForm.value.fuel_id);
                await this.selectedTwo('gear',this.vehicleForm.value.gear_id);
              // this.selectedKms = "";
              // this.vehicleInfo.customerKm = 0;
                this.selectedTwo('km',this.vehicleKmsList);
              
                //this.getPackages();
      
      
              });

          }else{
            this.vehicleForm.patchValue({
              name:this.garageName,
              brand_id: this.brandId,
              model_id: this.modelId,
              model_type_id: this.modelTypeId,
              model_year_id: this.modelYearId,
              garage_id:this.garageId,
              gear_type_id: this.gearId,
              fuel_type_id: this.fuelId,
            // km_id: this.vehicleForm.value.km_id,
              type_id: $event,
            })

          if(this.vehicleModelTypeList){

            await this.vehicleModelTypeList.forEach((item)=>{

              if(item.id == $event){
                this.vehicleInfo.model_type = item.name.toString();
              }

            })

          }
          this.vehicleFormEmitter.emit({vehicleForm: this.vehicleForm.value,vehicle_info:this.vehicleInfo});
        }

        if(this.IsPackageHave){
          await this.getApi('appointment/get-vehicle-gear-fuel-km',{model_type_id:this.modelTypeId}).pipe().subscribe(async response => {

            this.vehicleGearList = response.gears;
            this.vehicleFuelList = response.fuels;
            this.vehicleKmsList = response.kms;
          
            if(this.vehicleGearList?.length == 1){
              this.selectedTwo('gear',this.vehicleGearList[0].id);
            }

            if(this.vehicleFuelList?.length == 1){
              this.selectedTwo('fuel',this.vehicleFuelList[0].id);
            }
            if(!this.stepBack){
              this.vehicleForm.patchValue({ km_id: null });
             // this.vehicleInfo.customerKm = 0;
            }

            await this.selectedTwo('fuel',this.vehicleForm.value.fuel_type_id);
            await this.selectedTwo('gear',this.vehicleForm.value.gear_type_id);
           // this.selectedKms = "";
           // this.vehicleInfo.customerKm = 0;
            this.selectedTwo('km',this.vehicleKmsList);
       
            this.getPackages();
  
  
          });
        }
        

      

       

   
        //
       

      break;
      
      case "fuel":
       
     
        
        let fuelTypeId = ($event && $event.target && $event.target?.value != undefined) ? $event.target.value :  $event;

       
       // if(!this.stepBack){
          this.vehicleForm.patchValue({fuel_type_id: fuelTypeId});

        //}
        if(this.vehicleFuelList){
          await this.vehicleFuelList.forEach((item)=>{
            if(item.id == this.vehicleForm.value.fuel_type_id){
              this.vehicleInfo.fuel_type = item.name.toString();
            }
          })

          if(!this.stepBack){
           // this.vehicleForm.patchValue({km_id: null});
          //  this.vehicleInfo.customerKm = 0;
          }
          
          this.packageList = [];

          this.getPackages();
        }
      break;

      case "gear":
        
        let geartTypeId = null;
        
          if($event && $event.target && $event.target?.value != undefined){
            geartTypeId = $event.target.value
          }else if($event > 0){
            geartTypeId = $event;
           
          }
          
        
       
        //if(!this.stepBack){
        this.vehicleForm.patchValue({gear_type_id: geartTypeId});
        //}

        if(this.vehicleGearList){
          await this.vehicleGearList.forEach((item)=>{

            if(item.id == this.vehicleForm.value.gear_type_id){
              this.vehicleInfo.gear_type = item.name.toString();
            }
          })

          if(!this.stepBack){
        //    this.vehicleForm.patchValue({km_id: null});
          //  this.vehicleInfo.customerKm = 0;
          }
          this.packageList = [];
          await this.getPackages();
          
         
        }
        this.packageList = [];
          await this.getPackages();
      break;

      case "km":
       
        this.selectedKmInfo = $event;
        this.vehicleInfo.customerKm = 0;
        this.vehicleInfo.km_name = '';
        this.selectedPackage = [];
        this.vehicleForm.patchValue({
          last_km: null,
          km_id: null
        })
        this.packageList = [];
        let kmName = "";
        //let kmid =0;

        if(this.stepBack){
        this.vehicleInfo.km_name  = this.vehicleInfo.km_name;
        //  this.vehicleInfo.customerKm = this.vehicleForm.value.km_id;
        }else{
          this.vehicleInfo.km_name = "";
        // this.vehicleInfo.customerKm = kmid;
          this.vehicleForm.patchValue({km_id:null});

          
        }
       
       // if(!this.stepBack){
          this.packageKmList = $event;
       // }
        this.packageKmListEmitter.emit(this.packageKmList);

        if(this.selectedKmInfo.customerKm > 0){
          this.vehicleInfo.customerKm = this.selectedKmInfo.customerKm;
          this.vehicleForm.patchValue({last_km:this.selectedKmInfo.customerKm});
        
          if(this.selectedKmInfo && this.selectedKmInfo.selectedKmName != null){
            this.vehicleInfo.km_name = this.selectedKmInfo.selectedKmName;
          
          }

          if(!this.stepBack){
            this.vehicleFormEmitter.emit({vehicleForm: this.vehicleForm.value,vehicle_info:this.vehicleInfo});
          }

          this.packageList = [];
            await this.getPackages();
         
        }
        

      break;
      
      case "package":
      
        if(this.stepBack && this.selectedPackage.length > 0){


          this.vehicleInfo.km_name = this.selectedPackage.packageInfo.maintenance_kms.name;
          this.vehicleInfo.customerKm = this.packageKmList.customerKm;
          this.vehicleForm.patchValue({km_id:this.selectedPackage.packageInfo.maintenance_kms.id});
          this.stepBack = false;
        }else{
          this.vehicleInfo.km_name = $event?.maintenance_kms?.name;
          this.vehicleInfo.customerKm = this.packageKmList?.customerKm;
          this.vehicleForm.patchValue({km_id:$event?.maintenance_kms_id});

          
        
        }
        this.vehicleFormEmitter.emit({vehicleForm: this.vehicleForm.value,vehicle_info:this.vehicleInfo});

          this.packageEmitter.emit($event);

          
       
      break;
      case 'note':
        this.vehicleForm.patchValue({'note':$event.target.value});
        this.stepControl();
      break;
     
    }
   
    
    setTimeout(() => {
      if(this.stepBack && this.vehicleForm.valid){
        this.stepBack = false;
      }

      this.stepControl();
    }, 2000);


  }
  
  

  getApi(url:string,vars: Object | null = null){

    let options = {
      headers: new HttpHeaders({
        'token': this.authService.parseToken(false)
      })
    }

    return this.http.post<any>(this.baseUrl + url,vars,options).pipe(map(response => {
      if (response.status) {
        return response.data;
      } else {
       this.alertifyService.error(response.message);
      }
      return response;
    }))
  }

  clearFormFields(fieldName:any[]){
    if(fieldName.length > 0){
      let ths = this;
      fieldName.forEach(function(value){
        ths.orderForm = ths.orderForm.filter(i => i.order_type_id !== value)
      })
    }
  }

  setUserForm(type:string,$event:any){
    switch(type){
      case "firstname":
        this.userForm.patchValue({firstname:$event.target.value});
        this.userCreateForm.patchValue({firstname:$event.target.value})
      
      break;
      case "lastname":
        this.userForm.patchValue({lastname:$event.target.value});
        this.userCreateForm.patchValue({lastname:$event.target.value});
      
      break;
      case "email":
          this.userForm.patchValue({email: $event?.target.value});
          this.userCreateForm.patchValue({email: $event?.target.value});
         
      break;
      case "gsm":
          this.userForm.patchValue({gsm: $event,phone:$event});
          this.userCreateForm.patchValue({gsm: $event,phone:$event});
          if(this.userForm.value.gsm.length == 10){

            this.getGmsByNumber.watch({number:$event},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {
              if(resp.errors){
                this.alertifyService.error(resp.errors[0].message);
              }

              this.focusNext(this.userSurnameInput);
//debugger;
            

              if(resp.data){
                this.gsmInfo = resp.data.gsms;
                if(this.gsmInfo.length > 0){
                  this.userForm.patchValue({
                    firstname: this.gsmInfo[0].user.name,
                    lastname:this.gsmInfo[0].user.surname,
                    email: this.gsmInfo[0].user.email,
                    job:  this.gsmInfo[0].user.job,
                    gsm_id:this.gsmInfo[0].id,
                    is_user_registered: true,
                    gsm: this.gsmInfo[0].code,
                    id: this.gsmInfo[0].user.id
                  })

                  this.userRegistered = {
                    is_registered : true,
                    email: this.gsmInfo[0].user.email,
                    gsm: this.gsmInfo[0].code.toString()
                  }
      
                }
              }

              if(this.gsmInfo != undefined && this.gsmInfo[0] && this.gsmInfo[0].user?.garages?.length == 1 && this.gsmInfo[0].user?.garages[0]?.vehicles.length == 1){
                this.stepBack = true;
                this.vehicleForm.patchValue({
                  name: this.gsmInfo[0].user.garages[0].vehicles[0].name,
                  brand_id: this.gsmInfo[0].user.garages[0].vehicles[0].brand_id,
                  model_id: this.gsmInfo[0].user.garages[0].vehicles[0].model_id,
                  model_year_id:  this.gsmInfo[0].user.garages[0].vehicles[0].model_year_id,
                  type_id:  this.gsmInfo[0].user.garages[0].vehicles[0].model_type_id,
                  gear_type_id:  this.gsmInfo[0].user.garages[0].vehicles[0].gear_id,
                  fuel_type_id:  this.gsmInfo[0].user.garages[0].vehicles[0].fuel_id,
                 
               
                  year_id: this.gsmInfo[0].user.garages[0].vehicles[0].model_year_id,
               
                })
               
                this.vehicleFormInput.patchValue({km:this.gsmInfo[0].user.garages[0].vehicles[0].km_id})
                this.stepBack = true;
                this.modelName = this.gsmInfo[0].user.garages[0].vehicles[0].vehicle_model.name;
                this.selectedYear = this.gsmInfo[0].user.garages[0].vehicles[0].years.year_id;
               
                this.selectedTwo('brand',this.gsmInfo[0].user.garages[0].vehicles[0].brand_id,true);
               
               
              }
             
            })

          }
      break;

      case "phone":
          this.userForm.patchValue({phone: $event?.target.value});
         
      break;
      case "approved":
        this.userForm.patchValue({approved: $event});
        this.stepControl();
      
      break;

      case "job":
        this.userForm.patchValue({job:$event.target.value});
        this.brandIsClicked = true;
      
      break;
    }

    if(this.userForm.valid ){
      this.userFormEmitter.emit(this.userForm.value);

     
    }
    this.stepControl();

   
  }
  gotoBrand(goto:boolean){
    this.brandIsClicked = goto;
  }
  focusNext(nextInput: ElementRef) {
    nextInput.nativeElement.focus();  // Focus on the next input element
  }

  async checkEmail(){
    
    if( !this.userRegistered.is_registered && this.userCreateForm.valid && this.userForm.value.approved != null ){

      await this.checkUser.watch({email:this.userCreateForm.value.email},{fetchPolicy:"no-cache"}).valueChanges.subscribe(async ret => {
      
        if(ret.errors){
          this.alertifyService.error(ret.errors[0].message);
         
        }
        if(ret.data){
          if(ret.data.users.length == 0){

            await this.createNewUser();

            
            await this.checkEmail();

          this.stepControl();

          }else{

            this.userForm.patchValue({
              id: ret.data.users[0].id
            })

            this.userRegistered = {
              is_registered : true,
              email: ret.data.users[0].email,
              gsm: ""
            }

            this.userInfo = ret.data.users[0];


         
            this.userForm.patchValue({
              firstname: this.userInfo.name,
              lastname: this.userInfo.surname,
              id: this.userInfo.id,
              email: this.userInfo.email
            });


            if( this.userInfo.gsms == null ){

              
             await this.createUserGsm();

             
            }else{

              let isTrue = false;
              let selectedGsmId = 0;
               await this.userInfo.gsms.forEach(async element => {
                if(element.code == this.userForm.value.gsm){
                  isTrue = true;
                  selectedGsmId = element.id;
                  this.userRegistered.gsm = element.code;
                  
                  await this.userUpdate.mutate({id:this.userInfo.id,changes:{
                    gsm_id:  selectedGsmId
                  }}).subscribe(gsmResponse => {
                    if(gsmResponse.errors){
                      this.alertifyService.error(gsmResponse.errors[0].message);
                    }
              
                   //look   
                   this.stepControl();
                  
                  });
                  
                  
                } 
              });
             
              if(!isTrue){
               
                await this.createUserGsm();

              }else{
                this.userForm.patchValue({
                  gsm_id: selectedGsmId
                });
              }

            }
            if(this.userForm.value.id > 0 && !this.stepBack){
              await this.getUserInfo();
            }
          }

          
          this.userForm.patchValue({is_user_registered:true})
         
        
          
        }else{
          await this.createNewUser();
          await this.checkEmail();

        }

      })

    
     

    }

   
  }

  async createUserGsm(){
    await this.createGsm.mutate({
      name: "gsm",
      code: this.userForm.value.phone.toString(),
      active: true,
      is_primary: 1,
      user_id: this.userInfo.id
    }).subscribe(async (retdata:any)=>{
      if(retdata.errors){
        this.alertifyService.error(retdata.errors[0].message);
      }
      if(retdata.data){
        this.userForm.patchValue({
          gsm_id: retdata.data.insert_gsms.returning[0].id
        });


        await this.userUpdate.mutate({id:this.userInfo.id,changes:{
          gsm_id:  retdata.data.insert_gsms.returning[0].id
        }}).subscribe(gsmResponse => {
          if(gsmResponse.errors){
            this.alertifyService.error(gsmResponse.errors[0].message);
          }
    
        //look    this.stepControl();
        
        })
      
      }
    })
  }

 

  createNewUser(){
    return new Promise(async (resolve,reject)=>{
         if(this.userForm.value.firstname.length > 0 && this.userForm.value.lastname.length > 0 && this.userForm.value.email.length > 0){
       
           const password = "123123asdas12312";
           // register
           await this.authService.firebaseAuth(this.userForm.value.email, password, false).subscribe(async response => {
             await this.userCreateApi(response);
             resolve(true)

           
           }, async err => {
             const response: FireBaseEmailAuthReponse = {} as FireBaseEmailAuthReponse
          
             response.email = this.userForm.value.email
             response.idToken = "order create, email exists in firebase"
             response.refreshToken = "order create, email exists in firebase"
             response.localId = "order create, email exists in firebase"
             await this.userCreateApi(response)
             resolve(true)
       
             if(this.vehicleForm.valid && this.userForm.valid){
            //look    this.stepControl();
            }

           })

       }else{
         this.alertifyService.error("Lütfen Kullanıcı Formunu Doldurunuz");
     
       }
    })
       
 }

 userCreateApi(response:any){
    
  return new Promise(async (resolve,reject)=>{
    await this.authService.apiRegister(response,this.userForm.value.firstname, this.userForm.value.lastname,'',null,null).subscribe(async response => {

      if (response.status) {
        await this.resetService.sendPasswordResetResult(this.userForm.value.email).subscribe(async response => {
          if (response.email) {

           await this.checkEmail();
          
          }
        }, err => {
          this.alertifyService.error(err)
        });
       
      } else {
        if(response.error && response.error.code == 400){
          alert("Mail adresi zaten kayıtlı");
        }
        this.alertifyService.error("Kayıt Başarısız");
        resolve(true);
      }
    })
  })

  if(this.vehicleForm.valid && this.userForm.valid){

    this.stepControl();
  }

}

async stepControl(){


  if(this.orderTypeId == 1){//eğer periyodik bakım ise burayı kontrol et

    if(this.IsPackageHave){
      this.vehicleForm.controls["km_id"].setValidators([Validators.required,CustomValidator.isGratherThanZero]);
      this.vehicleForm.controls["km_id"].updateValueAndValidity()

    }
    

  }else{ // periyodik baım değil ise burayı kontrol et

    this.vehicleForm.controls["km_id"].setValidators([]);
    this.vehicleForm.controls["km_id"].updateValueAndValidity()


  }

  let isTrue = (this.vehicleForm.valid && this.userForm.valid && this.orderTypeId && this.orderTypeId > 0) ? true : false;
  
  if(this.userRegistered.is_registered && isTrue){
    this.userInfoEmitter.emit(this.userForm.value);
    this.userFormEmitter.emit(this.userForm.value);
    //setTimeout(()=>{
      this.vehicleFormEmitter.emit({vehicleForm: this.vehicleForm.value,vehicle_info:this.vehicleInfo});
    //},0)
  
    
    
  }else{
  
    await this.checkEmail();
    
    
  }

  
    
    if(this.stepBack && this.selectedPackage){
      setTimeout(async ()=>{
         await this.selectedTwo('package',this.selectedPackage);
         this.stepControlEmitter.emit(isTrue);
        },1000)
    
    }else{
      this.stepControlEmitter.emit(isTrue);
    }




  
}

  getUserInfo() {
    this.getUser.watch({ "id": this.userForm.value.id },{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
      if (response.data) {
           this.userInfo = response.data.users_by_pk;
           
           this.userForm.patchValue({
            firstname: this.userInfo.name,
            lastname: this.userInfo.surname,
            id: this.userInfo.id,
            email: this.userInfo.email
           
           })
           this.userInfoEmitter.emit(this.userForm.value);

        }
      })
  }

  async getPackages(){
/*
    if(this.vehicleForm.value.type_id != null && this.vehicleForm.value.km_id != null && this.vehicleForm.value.fuel_type_id != null && this.vehicleForm.value.gear_type_id){
      let url = "/get-package-list";
      await this.getApi(url,{
        "model_type_id":this.vehicleForm.value.type_id,
        "km_id": this.vehicleForm.value.km_id,
        "fuel_id": this.vehicleForm.value.fuel_type_id,
        "gear_id": this.vehicleForm.value.gear_type_id
    }).pipe().subscribe(response => {
        if(response){
        }
      });
    }*/

  
    if(this.orderTypeId == 1){
      if(this.packageKmList?.km_id_list?.length > 0 && this.vehicleForm.value.gear_type_id > 0 && this.vehicleForm.value.fuel_type_id > 0){
        this.getPackageList.watch(
          {
            where:{
              _and:{
                deleted_at: {
                  _is_null: true
                },
                active: { _eq: true},
                maintenance_kms_id:{
                  _in:this.packageKmList.km_id_list
                },
                order_type_id: { _eq: this.orderTypeId },
                gear_id: { "_eq": this.vehicleForm.value.gear_type_id},
                fuel_id: { "_eq" : this.vehicleForm.value.fuel_type_id},
                package_model_types:{
                  model_type_id: {
                  "_eq":this.vehicleForm.value.type_id
                  }
                } 
              }
            }
          })
          .valueChanges
          .subscribe( resp => {
            if(resp.errors){
              this.alertifyService.error(resp.errors[0].message);
            }
            if(resp.data){
              this.packageList = resp.data.maintenance_packages;
              if(this.stepBack && this.selectedPackage && this.selectedPackage.packageInfo){
                this.selectedTwo('package',this.selectedPackage.packageInfo.id)
              }
             
              this.packageListEmitter.emit(this.packageList);
            }
          } )
      }
    }else{
      if(this.vehicleForm?.value?.type_id > 0 && this.vehicleForm.value.gear_type_id > 0 && this.vehicleForm.value.fuel_type_id > 0){

        await this.http.post<any>(this.baseUrl + "appointment/get-packages-by-order-type",
        {
          model_type_id:this.vehicleForm.value.type_id,
            order_type_id: this.orderTypeId
      },
      { headers: { token: `${localStorage.getItem('access_token')}` } }
      ).subscribe(response=>{
        if(response.status === false){
          this.alertifyService.error("Araç alınamadı!");
        }else{

          this.packageList = response.result;
          if(this.packageList?.length == 1){
            this.selectedTwo('package',this.packageList[0]);
          }

              this.packageListEmitter.emit(this.packageList);

        }
      });

        
      }
    }
    
   
    
  }
  rejectSpecialCharacters(event: any) {
    const pattern = /[a-zA-Z0-9]/;
    const inputChar = String.fromCharCode(event.keyCode);
    if (!pattern.test(inputChar)) {
        event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
    }
}

convertToEnglishAndUppercase(text: string): string {
  if (!text) return '';

  const turkishCharacters = 'çğıöşüÇĞİÖŞÜ';
  const englishCharacters = 'CGIOSUCGIOSU';
  const turkishToEnglishMap: {[key: string]: string} = {};
  turkishCharacters.split('').forEach((char, index) => {
      turkishToEnglishMap[char] = englishCharacters[index];
  });

  let convertedText = '';
  for (let i = 0; i < text.length; i++) {
      const char = text[i];
      const convertedChar = turkishToEnglishMap[char] || char;
      convertedText += convertedChar.toUpperCase();
  }

  return convertedText;
}


  
  
}
