import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { Company, COMPANY_ALL } from 'src/app/graphql/company.graphql';

@Component({
  selector: 'app-appointment-servis-secimi',
  templateUrl: './servis-secimi.component.html',
  styles: [
  ],
  providers:[COMPANY_ALL]
})
export class ServisSecimiComponent implements OnInit {
  public todayWithPipe: any;
  public selectedCityId:Number = 0;
  public selectedServiceId:bigint = BigInt("0");
  public selectedDistrictId:Number = 0;
  public companyList: Company[] | undefined;
  public serviceDate:any;

  @Output() serviceEmitter = new EventEmitter();



  constructor(
    private getCompany: COMPANY_ALL,

  ) { }

  ngOnInit(): void {
    moment.locale('tr');
    this.todayWithPipe = moment().add(1, 'days').endOf('day').format('yyyy-MM-DDTH:mm');

  }

  selected(type:string,event:any){

  }

  changeCity($event:any,type:string="city"){
    this.selectedServiceId = BigInt("0");
    switch(type){
      case "city":
        this.selectedCityId = $event;
        this.selectedDistrictId = 0;
        break;
      case "district":
        this.selectedDistrictId = $event;
      break;  
    }

    let whereObj = {"active":{"_eq":true}};

    if(this.selectedCityId > 0){
      Object.assign(whereObj, { "city_id": { "_eq": this.selectedCityId}})
    }

    if(this.selectedDistrictId > 0){
      Object.assign(whereObj, { "district_id": { "_eq": this.selectedDistrictId}})
    }

    this.getCompany.watch({ where: whereObj,order_by:{"name":"asc"} },{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
      this.companyList = result.data.companies
    });
  }

  setServiceDate($event:any){
    this.serviceDate = $event;
    this.serviceEmitter.emit({service_date : this.serviceDate, service_id: this.selectedServiceId});
  }

  selectService(id:bigint){
    this.selectedServiceId = id;
  }

}
