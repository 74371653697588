import { Injectable } from '@angular/core';
import { gql, Mutation, Query } from 'apollo-angular';
import { OrderInvoiceParts } from './order_invoice_parts.graphql';

export interface OrderPart {
  id: Number;
  order_id: Number;
  part_id: Number;
  labour_type_id?: bigint;
  labour_value?: Number;
  labour_discount?: Number;
  part_type_id?: Number;
  part_value?: Number;
  part_no_vat_price?: Number;
  part_discount?: Number;
  registered_personel_id?: bigint;
  updated_personel_id?: bigint;
  service_value?: Number;
  part_types:any
  is_service?:boolean;
  price_unit_id?:bigint,
  total_price?:Number,
  total_price_no_vat?:Number,
  currency?:Number,
  created_at?:Date,
  part_unit_type_id: Number,
  part_unit: Number,
  part_code:String,
  currency_unit_price: Number,
  currency_total_price: Number,
  currency_price_unit_id: Number,
  parts:any,
  part_unit_types: any,
  part_labour_hour: Number,
  part_labour_price: Number,
  part_brand_labour_price: Number,
  connected_part_type_id: Number;
connected_part_id: Number;
order_invoice_parts:OrderInvoiceParts;
}

interface ResponseArray {
  order_parts: OrderPart[];
}

interface ResponseSingle {
  order_parts_by_pk: OrderPart
}

@Injectable()
export class ORDER_PARTS_ALL extends Query<ResponseArray>{
  override document = gql`
  query ORDER_PARTS_ALL($where: order_parts_bool_exp) {
        order_parts(where: $where){
          id
          order_id
          part_id
          labour_type_id
          labour_value
          labour_discount
          part_type_id
          part_value
          part_no_vat_price
          part_discount
          registered_personel_id
          updated_personel_id
          connected_part_type_id
connected_part_id
          service_value
          is_service
          price_unit_id
          total_price
          total_price_no_vat
          currency
          created_at
          part_unit_type_id
          part_code
          part_unit
          currency_unit_price
          currency_total_price
          currency_price_unit_id
          part_types{
            id
            name
          }
          part_unit_types{
            id
            name
          }
         
          selected_part_id
         
          
          brand_labour_price
          gos_labour_price
         
          service_value_unit
          service_price_lock
          part_equivalent_list{
            part_code
            name
          }
          part_unit_types{
            id
            name
          }
          parts{
            id
            name
            part_group{
              id
              name
            }
          }
        }
      }`;
}

@Injectable()
export class ORDER_PART_CREATE extends Mutation {
  override document = gql`
  mutation ORDER_PART_CREATE(
    $order_id: Int!, 
    $part_id: Int!,
    $part_type_id: Int!,
    $part_code: String,
    $part_value:numeric!,
    $selected_part_id:bigint!,
    $service_value:numeric!,
    $part_unit_type_id:Int,
    $part_unit:numeric,
    $brand_labour_price:numeric,
    $gos_labour_price:numeric,
    $price_unit_id:bigint,
    $total_price:numeric,
    $total_price_no_vat:numeric,
    $currency:numeric,
    $created_at:timestamptz,
    $currency_unit_price: numeric,
    $currency_total_price: numeric,
    $currency_price_unit_id: bigint,
    $labour_type_id: Int,
    $part_discount: Int,
    $updated_personel_id: Int,
    $part_no_vat_price: numeric,
    $labour_discount:Int,
    $labour_value: numeric,
    $registered_personel_id: Int,
    $service_value_unit: numeric,
    $part_labour_hour: numeric,
    $part_labour_price: numeric,
    $part_brand_labour_price: numeric
    ) {
    insert_order_parts(objects: {
      order_id: $order_id, 
      part_id: $part_id, 
      part_type_id:$part_type_id,
      part_code:$part_code,
      part_value:$part_value,
      selected_part_id:$selected_part_id,
      service_value:$service_value,
      part_unit_type_id:$part_unit_type_id,
      part_unit:$part_unit,
      brand_labour_price:$brand_labour_price,
      gos_labour_price:$gos_labour_price,
      price_unit_id:$price_unit_id,
      total_price:$total_price,
      total_price_no_vat:$total_price_no_vat,
      currency:$currency,
      created_at:$created_at,
      currency_unit_price: $currency_unit_price,
      currency_total_price: $currency_total_price,
      currency_price_unit_id: $currency_price_unit_id,
      labour_type_id: $labour_type_id,
      part_discount: $part_discount,
      updated_personel_id: $updated_personel_id,
      part_no_vat_price: $part_no_vat_price,
      labour_discount: $labour_discount,
      labour_value: $labour_value,
      registered_personel_id: $registered_personel_id,
      service_value_unit: $service_value_unit,
      part_labour_hour: $part_labour_hour,
      part_labour_price: $part_labour_price,
      part_brand_labour_price: $part_brand_labour_price
    }) {
      affected_rows
      returning {
        id
      }
    }
  }`;
}

@Injectable()
export class ORDER_PART_UPDATE extends Mutation<any> {
  override document = gql`
    mutation ORDER_PART_UPDATE($id: bigint!, $changes: order_parts_set_input ) {
      update_order_parts(where: {id: {_eq: $id}}, _set: $changes) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_PART_CREATE_ONE extends Mutation<any> {
  override document = gql`
  mutation ORDER_PART_CREATE_ONE( $object: order_parts_insert_input! ) {
      insert_order_parts_one(object: $object) {
          id   
      }
  }
`;
}
