
<style>

  
.hizmet-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: 17px;
    }

    .hizmet-row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 0px;
    }

    .hizmet-item {
      flex: 1;
      margin: 10px;
      box-sizing: border-box;
      min-height: 100px;
      flex-grow: 1;
    }

    .hizmet-box {
      background-color: white; /* Arka plan beyaz */
      border: 2px solid #004422;
      display: flex;
      flex-direction: column; /* Başlangıçta dikey hizalama */
      align-items: center;
      justify-content: center;
      padding: 20px;
      box-sizing: border-box;
      transition: background-color 0.3s ease; /* Geçiş efekti */
      text-align: center; /* Metni ortalar */
      height: 100%; /* Yükseklik %100 */
      border-radius: 15px;
    }

    .hizmet-box:hover {
      background-color: #96c91133; /* Hover sırasında yeşil */
     
    }

    .hizmet-box a {
      text-decoration: none;
      color: inherit;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    

    .hizmet-box img.logo {
      margin-bottom: 10px;
      /*max-width: 30%; /* Standart logo genişliği */
      max-height: 80%;
    }

    /* Dikey hizalama için stil */
    .vertical a {
      flex-direction: column;
    }

    .vertical .logo {
      margin-bottom: 10px;
      margin-right: 0;
    }

   

    /* Yatay hizalama için stil */
    .horizontal a {
      flex-direction: row;
    }

    .horizontal .logo {
      margin-bottom: 0;
      margin-right: 10px;
    }

    /* İki veya daha az öğe olan satırlar için özel stil */
    .two-or-less .logo {
      max-width: 100%;
      max-height: 100%;
      height: auto;
      width: auto;
      margin-bottom: 10px; /* İkonun altında boşluk bırakır */
    }

    .hizmet-box h5 {
      margin: 0;
      text-align: center; /* Metni ortalar */
      word-wrap: break-word; /* Uzun kelimeleri kır */
      white-space: pre-wrap; /* Taşmayı önler */
    }

    /* Üç veya daha fazla öğe olan satırlar için özel stil */
    .three-or-more a {
      flex-direction: column; /* İkonu ve metni dikeyde hizalar */
      justify-content: center;
    }

    .three-or-more .logo {
      max-width: 80% !important; /* Üç veya daha fazla öğe varsa logo genişliği %80 */
      margin-bottom: 10px; /* İkonun altında boşluk bırakır */
    }

    /* Beş veya daha fazla öğe varsa yazı boyutunu küçült */
    .five-or-more h5 {
      font-size: 12px;
    }



/* Ekran genişliğine göre düzeni değiştirmek için medya sorguları */
@media (min-width: 551px) {
  .hizmet-box a {
    flex-direction: row; /* Geniş ekranlarda ikonu ve metni yan yana getirir */
    align-items: center;
  }

  .hizmet-box img.logo {
    margin-bottom: 0;
    margin-right: 10px;
  }

  .three-or-more a {
    flex-direction: column; /* İkonu ve metni dikeyde hizalar */
  }

  .three-or-more .logo {
    max-width: 80%; /* Üç veya daha fazla öğe varsa logo genişliği %80 */
    margin-bottom: 10px; /* İkonun altında boşluk bırakır */
  }
}

@media (max-width: 550px) {
  .hizmet-box a {
    flex-direction: column; /* Dar ekranlarda ikonu üstte tutar */
    align-items: center;
  }

  .hizmet-box img.logo {
    margin-bottom: 10px;
    margin-right: 0;
    max-width: 150px;
  }

  .hizmet-row {
    flex-direction: column;
  }

  .hizmet-item {
    width: 100%;
  }
}

@media (max-width: 560px) {
  a.horizontal > h5{
    font-size: 25px !important;
  }
}
@media (max-width: 600px) {
  a.vertical > h5{
    font-size: 17px !important;
  }
}

</style>


<div class="hizmet-container">
    <div class="hizmet-row" *ngFor="let rows of orderTypeColumnList">
      <div class="hizmet-item" *ngFor="let item of rows.columns;" [ngStyle]="{height: rows.height, 'flex-grow': item.width}">
        <div class="hizmet-box" [ngClass]="{
          'two-or-less': rows.columns.length <= 2,
          'three-or-more': rows.columns.length >= 3,
          'five-or-more': rows.columns.length >= 5
        }" appResize>
          <a (click)="setOrderType(item.order_types.id,item.order_types.url)"  [routerLink]="['/teklif', item.order_types.url]">
            <img *ngIf="item.order_types.img" src="https://cdn.destechhasar.com/garantili-oto-servis/{{item.order_types.img}}" alt="{{ item.order_types.name }}" class="logo">
            <h5 [ngStyle]="{'font-size': item.font_size}">{{ item.order_types.name }}  </h5>
          </a>
        </div>
      </div>
    </div>
  </div>

 



<div class="hizmet-container d-none">
    <div class="hizmet-row" *ngFor="let rows of rows">

      <div class="hizmet-item" *ngFor="let item of rows.columns;" [ngStyle]="{height: rows.height+rows.height_unit,'flex-grow':item.width}">

        <div class="hizmet-box" [ngClass]="{
          'two-or-less': rows.columns.length <= 2,
          'three-or-more': rows.columns.length >= 3,
          'five-or-more': rows.columns.length >= 5
        }">
      
          <a (click)="setOrderType(item.item.id)">
            <img *ngIf="item.item.img" src="https://cdn.destechhasar.com/garantili-oto-servis/{{item.item.img}}" alt="{{ item.item.name }}" class="logo" [ngClass]="{
              'two-or-less-logo': rows.columns.length <= 2,
              'three-or-more-logo': rows.columns.length >= 3
            }">
            <h5>{{ item.item.name }}   </h5>
          </a>
        </div>
      </div>
    </div>
  </div>


<div class="container-xxl p-0 d-none">

    <div class="row gy-5 g-xl-8">
       
        <div class="col-xl-4" *ngFor="let item of orderTypes">
          
              <div class="d-flex align-items-center text-center rounded p-3 mb-7" (click)="setOrderType(item.id)" style="cursor:pointer; background-color: #96bc33; min-height: 120px; color:#fff; flex-direction: row;">
                <div style="width: 30%; border-radius: 5px;-moz-border-radiue:5px;" >
                    <img width="100%;  border-radius: 5px; -moz-border-radiue:5px;" src="https://cdn.destechhasar.com/garantili-oto-servis/{{item?.img}}" alt="">
                </div>
               
                <div class="flex-grow-1 me-2 pt-5">
                    <p class="fw-bolder text-hover-primary text-primary fs-6">{{item.name}}</p>
                    
                </div>
            </div>
        </div>


    </div>

   

</div>