import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class CustomerOfferService {

  private options = {
    headers: new HttpHeaders({
      'token': environment.gosToken
    })
  }

  constructor(
    private http: HttpClient
  ) { }

  send(vehicle_brand_id: string, vehicle_model_id: string, maintenance_kms_id: string, firstname: string, lastname: string, email:string,phone:string,service_date:string,bcc: string[] = []): Observable<any> {
    const formData = new FormData();
    formData.append('vehicle_brand_id', vehicle_brand_id);
    formData.append('vehicle_model_id', vehicle_model_id);
    formData.append('vehicle_km_id', maintenance_kms_id);
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('service_date', service_date);
    if (bcc.length) {
      bcc.forEach(b => {
        formData.append('bcc[]', b);
      })
    }
    return this.http.post<any>(environment.laravelApiEndpoint + "create-customer-offer", formData, this.options);
  }

}