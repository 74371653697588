import { Injectable } from '@angular/core';
import { gql, Mutation, Query, Subscription } from 'apollo-angular';
import { Company } from './company.graphql';
import { User } from './user.graphql';

export interface FormStepParts {
  id: Number
  step_title: String
  description: String
  position: Number
  next_step_id: Number
  previus_step_id: Number,
  service_name: String,
  is_completed: boolean
}

interface ResponseArray {
    form_step_parts: FormStepParts[]
}

interface ResponseSingle {
    form_step_parts_by_pk: FormStepParts
}

interface Aggregate {
  form_step_parts_aggregate: {
    aggregate: {
      count: number
    }
  }
}

interface InsertMutation {
  insert_form_step_parts: {
    affected_rows: number
    returning: [{
      id: Number
    }]
  }
}

interface UpdateMutation {
  update_form_step_parts: {
    affected_rows: number
    returning: [{
      id: Number
    }]
  }
}

interface DeleteMutation {
  delete_form_step_parts: {
    affected_rows: number
    returning: [{
      id: Number
    }]
  }
}

@Injectable()
export class FORM_STEP_PARTS_COUNT extends Query<Aggregate> {
  override document = gql`
  query FORM_STEP_PARTS_COUNT(where: form_step_parts_bool_exp) {
    form_step_parts_aggregate( where: $where) {
      aggregate {
        count
      }
    }
  }`;
}

@Injectable()
export class FORM_STEP_PARTS_ALL extends Query<ResponseArray> {
  override document = gql`
  query FORM_STEP_PARTS_ALL($where: form_step_parts_bool_exp, $limit: Int = 100){
    form_step_parts(where: $where, order_by: {position: asc}, limit: $limit){
      id
      step_title
      description
      position
      next_step_id
      previous_step_id,
      service_name
      is_completed
    }
  }`;
}

@Injectable()
export class FORM_STEP_PARTS_CREATE extends Mutation<InsertMutation> {
  override document = gql`
  mutation FORM_STEP_PARTS_CREATE($step_title: String!, $description: String!, $position: Number!, $next_step_id: Number!, $previous_step_id: Number!) {
    insert_form_step_parts(objects: {step_title: $step_title, description:$description, position: $position, next_step_id: $next_step_id, $previous_step_id: $previous_step_id}) {
      affected_rows
      returning {
        id
      }
    }
  }`;
}

@Injectable()
export class FORM_STEP_PARTS_UPDATE extends Mutation<UpdateMutation> {
  override document = gql`
  mutation FORM_STEP_PARTS_UPDATE($id: Int!, $step_title: String!, $description: String!, $position: Number!, $next_step_id: Number!, $previous_step_id: Number!) {
    update_form_step_parts(where: {id: {_eq: $id}}, _set: {step_title: $step_title, description:$description, position: $position, next_step_id: $next_step_id, $previous_step_id: $previous_step_id}) {
      affected_rows
      returning {
        id
      }
    }
  }`;
}

@Injectable()
export class FORM_STEP_PARTS_DELETE extends Mutation<DeleteMutation> {
  override document = gql`
  mutation FORM_STEP_PARTS_DELETE($id: Int!) {
    delete_form_step_parts(where: {id: {_eq: $id}}) {
      affected_rows
      returning {
        id
      }
    }
  }`;
}