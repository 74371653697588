<label style="display: block;">
    <input type="text" 
        class="form-control" 
        [attr.list]="id" 
        (keyup)="inputValue($event)" 
        [placeholder]="name"
        [value]="selectName" />
</label>
<datalist [id]="id" >
    <option *ngFor="let list of lists" [attr.val]="list.id" >{{list.name}}</option>
</datalist>

    
<div class="input-group" *ngIf="selectedItems != null && selectedItems.length > 0" style="margin-top: 5px;">
    <div class="form-control">
        <a  class="badge badge-light-success fs-6 m-1" *ngFor="let item of selectedItems" (click)="removeList(item.id)">{{item!.name}} <strong style="font-size: 10px; vertical-align: top;">x</strong> </a>
 
    </div>
</div>
