import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { TranslationService } from '../services/translation.service';
import { DrawerComponent } from './drawer/drawer.component';
import { SelectComponent } from './select/select.component';
import { PaginateComponent } from './paginate/paginate.component';
import { DeleteComponent } from './buttons/delete/delete.component';
import { EditComponent } from './buttons/edit/edit.component';
import { FilterComponent } from './filter/filter.component';
import { RangePipe } from './pipes/range.pipe';
import { CountriesComponent } from './geozones/countries.component';
import { CitiesComponent } from './geozones/cities.component';
import { DistrictsComponent } from './geozones/districts.component';
import { TownsComponent } from './geozones/towns.component';
import { CarouselComponent } from './carousel/carousel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MultipleSelectComponent } from './multiple-select/multiple-select.component';
import { SelectwoComponent } from './selectwo/selectwo.component';
import { environment } from 'src/environments/environment';
import { GoogleMapsComponent } from './googleMaps/google-maps.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SelectVehicleComponent } from './select-vehicle/select-vehicle.component';
import { FullcalendarComponent } from './fullcalendar/fullcalendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import { PriceInputComponent } from './price-input/price-input.component';
import { OrderStatusComponent } from './order/status.component';
import { InvoiceGeneratorComponent } from './invoice/invoice-generator.component';
import { InvoiceCreateComponent } from './invoice/invoice-create.component';
import { RateComponent } from './rate/rate.component';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { ModalComponent } from './modal/modal.component';
import { MaintenancePackageComponent } from './piece-selector/maintenance-package.component';
import { ManuelPartsComponent } from './piece-selector/manuel-parts.component';
import { MechanicRepairComponent } from './piece-selector/mechanic-repair.component';
import { CustomerManuelPartsComponent } from './piece-selector/customer-manuel-parts.component';
import { OrderManuelPartComponent } from './order/order-manuel-part.component';
import { PaymentComponent } from './payment/payment.component';
import { ProformaPdfGeneratorComponent } from './profoma/proforma-pdf-generator.component';
import { GetAnOfferComponent } from './get-an-offer/get-an-offer.component';
import { CompanyDateSelectorComponent } from './companyDateSelector/company-date-selector.component';
import { SortPipe } from './pipes/sort.pipe';
import { SelectwoMulipleComponent } from './selectwo-multiple/selectwo-multiple.component';
import { PaymentAuthComponent } from './payment-auth/payment-auth.component';
import { AppointmentCountriesComponent } from './appointment-geozones/countries.component';
import { AppointmentCitiesComponent } from './appointment-geozones/cities.component';
import { AppointmentDistrictsComponent } from './appointment-geozones/districts.component';
import { AppointmentTownsComponent } from './appointment-geozones/towns.component';
import { NgModule } from '@angular/core';
import { UserAddressComponent } from './user-address/user-address.component';
import { ProformaDetailComponent } from './proforma-detail/proforma-detail.component';
import { SupplierProductListComponent } from './supplier-product-list/supplier-product-list.component';
import { UserCreateComponent } from './user/user-create.component';
import { ProfileCompletedComponent } from './company/profile-completed.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { PhoneMaskComponent } from './phone-mask/phone-mask.component';
import { KmsSelectorComponent } from './kms-selector/kms-selector.component';
import { MapsComponent } from './maps/maps.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { LocationComponent } from './maps/location.component';
import { NearestServicesComponent } from './maps/nearest-services.component';
import { NumberFormatPipeComponent } from './pipes/number-format.pipe';
import { InvoiceGeneratorV2Component } from './invoice/invoice-generator-v2.component';
import { SiteProformaComponent } from './site-proforma/site-proforma.component';
import { ProformaPdfGeneratorV2Component } from './proforma/proforma-pdf-generator-v2.component';
import { AppointmentDateSelectorComponent } from './appointment-date-selector/appointment-date-selector.component';
import { CreditCardComponent } from './credit-card/credit-card.component';
import { ModalProformaComponent } from './modal-proforma/modal-proforma.component';
import { ServiceContactTooltipComponent } from './service-contact-tooltip/service-contact-tooltip.component';
import { EmailValidatorComponent } from './email-validator/email-validator.component';
import { PartTooltipComponent } from './part-tooltip/part-tooltip.component';
import { ProformaInvoiceComponent } from './proforma-invoice/proforma-invoice.component';
import { ProformaPdfGeneratorV3Component } from './proforma/proforma-pdf-generator-v3.component';
import { SmsVerificationComponent } from './verification/sms-verification.component';
import { CommentsComponent } from './comments/comments.component';
import { OrderPartAddComponent } from './order-part-add/order-part-add.component';
import { TagifyComponent } from './tagify/tagify.component';






// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin
]);

@NgModule({
  declarations: [
    DrawerComponent,
    SelectComponent,
    RangePipe,
    SortPipe,
    PaginateComponent,
    DeleteComponent,
    EditComponent,
    FilterComponent,
    CountriesComponent,
    CitiesComponent,
    DistrictsComponent,
    TownsComponent,
    CarouselComponent,
    MultipleSelectComponent,
    SelectwoComponent,
    GoogleMapsComponent,
    BreadcrumbComponent,
    SelectVehicleComponent,
    FullcalendarComponent,
    PriceInputComponent,
    OrderStatusComponent,
    InvoiceGeneratorComponent,
    InvoiceCreateComponent,
    RateComponent,
    ModalComponent,
    MaintenancePackageComponent,
    ManuelPartsComponent,
    MechanicRepairComponent,
    CustomerManuelPartsComponent,
    OrderManuelPartComponent,
    PaymentComponent,
    ProformaPdfGeneratorComponent,
    GetAnOfferComponent,
    CompanyDateSelectorComponent,
    SelectwoMulipleComponent,
    PaymentAuthComponent,
    AppointmentCountriesComponent,
    AppointmentCitiesComponent,
    AppointmentDistrictsComponent,
    AppointmentTownsComponent,
    UserAddressComponent,
    ProformaDetailComponent,
    SupplierProductListComponent,
    UserCreateComponent,
    ProfileCompletedComponent,
    PhoneMaskDirective,
    PhoneMaskComponent,
    KmsSelectorComponent,

    MapsComponent,
     LocationComponent,
     NearestServicesComponent,
     NumberFormatPipeComponent,
     InvoiceGeneratorV2Component,
     SiteProformaComponent,
     ProformaPdfGeneratorV2Component,
     AppointmentDateSelectorComponent,
     CreditCardComponent,
     ModalProformaComponent,
     ServiceContactTooltipComponent,
     EmailValidatorComponent,
     PartTooltipComponent,
     ProformaInvoiceComponent,
     ProformaPdfGeneratorV3Component,
     SmsVerificationComponent,
     CommentsComponent,
     OrderPartAddComponent,
     TagifyComponent,
    
    ],
  providers: [
    TranslationService
  ],
  imports: [
    ReactiveFormsModule,
    FullCalendarModule,
    CarouselModule,
    NgxStarRatingModule,
    GoogleMapsModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
    
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    DrawerComponent,
    SelectComponent,
    RangePipe,
    SortPipe,
    PaginateComponent,
    DeleteComponent,
    EditComponent,
    FilterComponent,
    CountriesComponent,
    CitiesComponent,
    DistrictsComponent,
    TownsComponent,
    CarouselComponent,
    MultipleSelectComponent,
    SelectwoComponent,
    GoogleMapsComponent,
    BreadcrumbComponent,
    FullcalendarComponent,
    PriceInputComponent,
    OrderStatusComponent,
    InvoiceGeneratorComponent,
    InvoiceCreateComponent,
    RateComponent,
    ModalComponent, 
    MaintenancePackageComponent,
    ManuelPartsComponent,
    MechanicRepairComponent,
    CustomerManuelPartsComponent,
    OrderManuelPartComponent,
    PaymentComponent,
    ProformaPdfGeneratorComponent,
    GetAnOfferComponent,
    CompanyDateSelectorComponent,
    SelectwoMulipleComponent,
    PaymentAuthComponent,
    AppointmentCountriesComponent,
    AppointmentCitiesComponent,
    AppointmentDistrictsComponent,
    AppointmentTownsComponent,
    UserAddressComponent,
    ProformaDetailComponent,
    SupplierProductListComponent,
    UserCreateComponent,
    ProfileCompletedComponent,
    PhoneMaskDirective,
    PhoneMaskComponent,
    KmsSelectorComponent,
    GoogleMapsModule,
    MapsComponent,
    NearestServicesComponent,
    NumberFormatPipeComponent,
    InvoiceGeneratorV2Component,
    SiteProformaComponent,
    AppointmentDateSelectorComponent,
    CreditCardComponent,
    ModalProformaComponent,
    ServiceContactTooltipComponent,
    PartTooltipComponent,
    ProformaInvoiceComponent,
    ProformaPdfGeneratorV3Component,
    SmsVerificationComponent,
    CommentsComponent,
    OrderPartAddComponent,
    TagifyComponent
    ],
})
export class SharedModule { }
