import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { CompanyAcceptance, COMPANY_ACCEPTANCES } from 'src/app/graphql/company_acceptances.graphql';
import { ORDER_CHECK_BY_DATE } from 'src/app/graphql/order.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';

const DAY_MS = 60 * 60 * 24 * 1000;


@Component({
  selector: 'app-shared-appointment-date-selector',
  templateUrl: './appointment-date-selector.component.html',
  styles: [
  ],
  styleUrls:['./appointment-date-selector.component.css'],
  providers:[COMPANY_ACCEPTANCES,ORDER_CHECK_BY_DATE]
})
export class AppointmentDateSelectorComponent implements OnInit {

  @Input() selectedDateInput: any;

  dates: Array<Date> | undefined;
  days = [ 'PZT', 'SL', 'ÇRŞ', 'PRŞ', 'CM', 'CMT','PZR'];
  date = new Date();
  showCalendar: boolean = true;
  
  private dayList:any[] = [];
  public hourList:any[] = [];
  public hourListWithSelectable:any[]=[];

  public inputText = "Servis Tarihi Seçiniz";
  public selectedData = {
    date: '',
    hour: '',
    rawDate: '',
    mounthName:'',
    yearName:''
  }
  public selectedMonthName: string | undefined;
  public selectedYearName: string | undefined;

  public companyAcceptanceList: CompanyAcceptance[] | undefined;
  public notSelectableHours = [];

  public pendingDayCount = 0;
  public startDate:any;


  @Input() companyId: number | bigint | undefined;
  @Output() selected = new EventEmitter();

  constructor(
    private alertifyService: AlertifyService,
    private getCopmanyacceptance: COMPANY_ACCEPTANCES,
    private checkOrderByDate: ORDER_CHECK_BY_DATE,
    private eRef: ElementRef
  ) { 
    
  }

  async ngOnInit(): Promise<void> {
    

    this.dates = await this.getCalendarDays(this.date);

    await this.getCopmanyacceptance.watch({company_id:this.companyId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(async resp=>{

      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }

      if(resp.data){
        this.companyAcceptanceList = resp.data.company_acceptances;
        if(this.companyAcceptanceList.length > 0){
          await this.companyAcceptanceList.forEach(async (val,key)=>{
            
            let days =  {
              "day" : val.day.tr_name,
              "hours": [""],
              "dayNumber": val.day_id
            };
      
            this.pendingDayCount = parseInt(val.company.City.days);

            let daysToAdd = 0;
            let addedDays = 0;
            let startDate = moment(); // Başlangıç tarihini al
            
            while (addedDays < this.pendingDayCount) {
                daysToAdd++;
                let nextDate = moment(startDate).add(daysToAdd, 'days');
                if (nextDate.day() !== 0) { // 0 Pazar gününü temsil eder
                    addedDays++;
                }
            }
            
            this.startDate = moment(startDate).add(daysToAdd, 'days');

            //this.startDate = moment().add(this.pendingDayCount,"days");

     
           days.hours = [];
            if(val.start_hour && val.finish_hour){
          
              let start = moment(val.start_hour,"H").hour().valueOf();
              let finish = moment(val.finish_hour,"H").hour().valueOf();

              for(let i=start; i <= finish; i++){
               

               let dateString = "";
                if(i < 10){
                 // days.hours.push('0'+i+':00');
                 
                 dateString =  '0'+i;
             
                 
                }else{
                 // days.hours.push(String(i+':00'));
                 dateString = String(i);
                }

                
                let minString1 = dateString+':00';

                let minString2 = dateString+':15';

                let minString3 = dateString+':30';

                let minString4 = dateString+':45';
                
                let oObj = [
                  minString1,
                  minString2,
                  minString3,
                  minString4,
                ]
                
                oObj.forEach(element => {
                   days.hours.push(element);
                });
               
              }
              this.dayList.push(days);

            }
          })

          if(this.selectedDateInput){
            let selectedDate = new Date(this.selectedDateInput);
            await this.changeData(selectedDate,'date',this.isSameMonth(selectedDate));

           let currentHours = selectedDate.getHours().toString();
          
              if(currentHours.length == 1){
                currentHours = ("0" + currentHours);
              }
             
           

              let currentMinutes = selectedDate.getMinutes().toString();
              if(currentMinutes == "0"){
                currentMinutes = currentMinutes+"0";
              }
            this.changeData(currentHours+":"+currentMinutes,'hour',true,false)
          }

        }
      }
    })
  }

  private async getCalendarDays(date = new Date) {
    
    let cal = await this.getCalendarStartDay(date);
   
    if(cal){

      const calendarStartTime:any = cal.getTime() + 60 * 60 * 2 * 1000; /* add 2 hours for day light saving time adjustment */



    return this.range(1, 42)
      .map(num => new Date(calendarStartTime + DAY_MS * num));
    }
   
  }

  private getCalendarStartDay(date = new Date) {
    const [year, month] = [date.getFullYear(), date.getMonth()];


    //const firstDayOfMonth = new Date(year, month, 1).getTime();
    const firstDayOfMonth = new Date(year, month, date.getDate()).getTime();

    return this.range(1,7)
      .map(num => new Date(firstDayOfMonth -  60 * 60 * 24 * 1000 * num))
      .find(dt => dt.getDay() === 0)
  }

  private range(start:any, end:any, length = end - start + 1) {
   
    return Array.from({ length }, (_, i) => start + i)
  }

  isSameMonth(date:any) {
    let curDate     = moment();
    let momentDate  = moment(date);
    let finishhDate = curDate.add(1,"months");
    let different   = curDate.diff(momentDate,'days');
    let isTrue      = true;
     if(different+1 < 0){
       isTrue = false;
     }

     
 
     if(different +2 > 31){
       isTrue = false;
     }
    
 
     if(isTrue){
       let checkday = false;
       this.dayList.forEach((v,k)=>{
         if(v.day && v.day == momentDate.format('dddd')){
           checkday = true;
         }
       })
       isTrue = checkday
     }
     return isTrue;
   }

   async changeData(event:any,type:string,isClicable:boolean,canEmit=true){
    if(isClicable){
      this.selectedData.hour = '';
      if(type == "date"){
        this.hourListWithSelectable= [];
        this.hourList = [];
        this.selectedData.date = moment(event).format("LL");
        this.selectedData.mounthName = moment(event).format("MMMM");
        this.selectedData.yearName = moment(event).format("GGGG");
        this.selectedData.rawDate = moment(event,"MM-DD-YYYY").format("YYYY-MM-DD").toString();
        let selectedDayName = moment(event).format('dddd');
        
        let selectedDay = this.dayList.map(async (i:any)=>{ 

          if( i.day == selectedDayName) { 
           
            this.hourList = i.hours; 
            if(this.hourList.length > 0){
              this.notSelectableHours = [];
              await this.hourList.forEach(async (v,k) => {
                
                let newList={hour:"",isActive:false,orderNum:0};

                
               newList.hour= v;
               let splitted = v.split(":");
                newList.orderNum = parseInt(splitted[0]+splitted[1]);
                newList.isActive = await this.isSelectableHour(v);
                this.hourListWithSelectable.push(newList);
                this.hourListWithSelectable.sort(function(b, a) {
                  return a.orderNum < b.orderNum ? 1 : -1;
              });


              });
            }
          }});

        
      }
  
      if(type == "hour"){
        this.selectedData.hour = event;
       
      }
  
      if(this.selectedData.date){
        this.inputText = this.selectedData.date + ' ' + this.selectedData.hour;
      }else{
        this.inputText = "Servis Tarihi Seçiniz";
      }

      if(this.selectedData.hour && canEmit){
        this.returnData();
      }
      
     //this.dates = this.getCalendarDays(this.date);
    }
    
  }

  public async checkHourSelectable(event:any){
    let searchableDate = moment(this.selectedData.rawDate).format("YYYY-MM-DD").toString()+" "+event;
    let isSelectable = false;
    let count = 1;
     await this.checkOrderByDate.watch({service_id: this.companyId,service_date:searchableDate},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
       
        return false;
      }
      if(resp.data){
         count = resp.data.orders_aggregate.aggregate.count;

        if (count == 0){
         
          return true
         
     
        }else{
        
         return false;
         
        }
       
        
      }
    
    });

    return false;

   
  }

  async isSelectableHour(event:any):Promise<boolean>{
    let searchableDate = moment(this.selectedData.rawDate).format("YYYY-MM-DD").toString()+" "+event;
    return new Promise((resolve,reject)=>{
      this.checkOrderByDate.watch({service_id: this.companyId,service_date:searchableDate},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{
        if(resp.errors){
          this.alertifyService.error(resp.errors[0].message);
          resolve(false);
          return false;
        }
        if(resp.data){
          let count = resp.data.orders_aggregate.aggregate.count;
  
          if (count == 0){
            resolve(true);
            return true
       
          }else{
            resolve(false);
           return false;
           
          }
         
          
        }
      
      });
    })
    }

  returnData(){
    if(this.selectedData.date == '' || this.selectedData.hour == ''){
      this.alertifyService.error("Lütfen tarih ve saaç seçiniz!");
      return false;
    }else{
      
      this.showCalendar = false;
      let returnData =  moment(this.selectedData.date + " " + this.selectedData.hour,'D MMM YYYY H:m').format("YYYY-MM-DDTHH:mm");
      this.selected.emit(returnData);
      
      
    }
  
  }

  isActive(event:any){
    
    return this.selectedData.date == moment(event).format("LL"); 
  }
  setHours(){
    
  }
  isActiveHour(event:any){
    
    return this.selectedData.hour == event; 
  }

  isToday(date:any){
    return date.getMonth() === this.date.getMonth() && date.getDate() == this.date.getDate();
 }

 refreshHourList(){
  let hourList = this.hourListWithSelectable;
  this.hourListWithSelectable = [];
  this.hourListWithSelectable = hourList;
  return this.hourListWithSelectable;
 }

 pendingDateOld(date:any){
  let selectedDate = moment(date);
  let now = moment();
  let diff = selectedDate.diff(now,'days');
  

  if (selectedDate.day() === 0) {
    // Eğer seçilen gün pazar ise, seçilen tarihi bir gün geri al
    selectedDate.subtract(1, 'days');
    // Ve diff'i bir arttır
    diff += 1;
  }
  return (diff  < this.pendingDayCount  ) ? true : false; 
 }  

 pendingDate(date:any){
  let selectedDate = moment(date);
  let now = moment();
  let diff = selectedDate.diff(now,'days');
  let isActive = false;
  let pendingDateDiff = selectedDate.diff(this.startDate,'days');
  let isDayInList = this.dayList.some(day => day.dayNumber === selectedDate.day());

  if (selectedDate.day() === 0) {
   isActive = true;
  }else if(pendingDateDiff < 0){
    isActive = true;
  } else if (!isDayInList) {
    isActive = true;
  } else {
    isActive = false;
  }

  return isActive; 
 } 
}
