<div class="aapointment-date-title">
    <h4>Randevu Tarihi/Saati</h4>
</div>

<div class="appointment-date-container">

    <div class="date-container">
        <div class="date-title">
            <span>{{selectedData.yearName}}</span>
            <span>{{selectedData.mounthName}}</span>
        </div>
        <div class="date-description">
            <ul>
                <li class="days" *ngFor="let day of days"> {{day}} </li>
                <li *ngFor="let date of dates"
                  
                  >
                  
                  <a 
                    [ngClass]="{'grey no-bg ': !isSameMonth(date) || pendingDate(date) ,'cursor-pointer': isSameMonth(date),'current-day': isToday(date) ,'active-date':isActive(date) }"
                    (click)="changeData(date,'date',isSameMonth(date))"
                    >{{date | date:'d'}}</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="hour-container">
        <div class="hour-title">
            Saat
        </div>
        <div class="hour-description">
            <ul>
            
               
                <li *ngFor="let item of hourListWithSelectable" 
                     [ngClass]="{ 'active-hour' : this.selectedData.hour == item.hour, 'deactive-hours': !item.isActive}"
             
                     (click)="changeData(item.hour,'hour',item.isActive)"
                    
                      >{{item.hour}}
                      
                    </li>
                
                
             </ul>
        </div>
    </div>

    <div style="
    display: none;
        width: 287px;
        clear: both;
        position: absolute;
        z-index: 9999;
        background-color: #fff;
        top: 43px;
        border: 1px solid #ccc;
        padding: 5px; clear: both;" [ngClass]="{'d-none': !showCalendar}">
        <div style="float: left;">
           <span class="d-block alert alert-success mb-0" style="margin-right: 10px;" >
            <span *ngIf="selectedData.date"> <i style="color: #205237;" class="fas fa-calendar"></i> {{selectedData.date}}</span>
            <span *ngIf="selectedData.hour"> - <i style="color: #205237;" class="fas fa-clock"></i> {{selectedData.hour}}</span>
           </span>
            <ul>
              <li class="days" *ngFor="let day of days"> {{day}} </li>
              <li *ngFor="let date of dates"
                [ngClass]="{'grey no-bg ': !isSameMonth(date) ,'cursor-pointer': isSameMonth(date),'current-day': isToday(date), 'active-date':isActive(date) }"
                (click)="changeData(date,'date',isSameMonth(date))">
                {{date | date:'d'}}
                
              </li>
            </ul>
        </div>
</div>