import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { OrderSms, ORDER_SMS } from 'src/app/graphql/oder_sms.graphql';
import { Order,  GET_ORDER_STATUS, UPDATE_ORDER_STATUS, ORDER_UPDATE, ORDER_ID } from 'src/app/graphql/order.graphql';
import { OrderBids, ORDER_BIDS_ONE } from 'src/app/graphql/order_bids.graphql';
import { ORDER_STATUS_LOG_CREATE } from 'src/app/graphql/order_status_logs.graphql';
import { OrderTransaction, ORDER_TRANSACTIONS } from 'src/app/graphql/order_transactions.graphql';
import { USER_GET_ROLE } from 'src/app/graphql/user.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { MailService } from 'src/app/services/mail.service';
import { SmsService } from 'src/app/services/sms.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shared-order-status',
  templateUrl: './status.component.html',
  styles: [],
  providers: [ORDER_TRANSACTIONS, UPDATE_ORDER_STATUS, GET_ORDER_STATUS, USER_GET_ROLE,ORDER_UPDATE,ORDER_ID,ORDER_SMS,
  ORDER_STATUS_LOG_CREATE,ORDER_BIDS_ONE]
})
export class OrderStatusComponent implements OnInit {

  @Input() orderId: Number | undefined;
  @Input() showStatusName: boolean = false;
  @Output() stepEmitter = new EventEmitter<Number>();

  public orderTransactions: OrderTransaction[] | undefined
  public order: Order | undefined;
  public curentStatusName: string | undefined
  public showDrawer: boolean = false;
  public drawerTitle:string = "drawer title";
  public drawerTab: string = "changeServiceDate";
  public todayWithPipe:any;
  public pipe = new DatePipe('en_US');
  public newServiceDate:Date | undefined;
  public serviceDate:String | undefined;
  public selectedNextStatusId: Number | undefined;
  public orderStatusId: Number | undefined;
  public orderInfo: Order | undefined;
  public orderSmsList: OrderSms | undefined;
  public roleId: Number | undefined;
  public buttonDisabled = false;
  public bidFinisTime: any;
  private baseUrl: string = environment.laravelApiEndpoint;
  public showCancelButton: boolean = false;
  public orderBids: OrderBids[] | undefined;
  public resultSend = false;
  public resultMessage = "";
 

  constructor(
    private getOrderTransactions: ORDER_TRANSACTIONS,
    private updateOrderStatus: UPDATE_ORDER_STATUS,
    private getOrderStatus: GET_ORDER_STATUS,
    private getUserRole: USER_GET_ROLE,
    private alertifyService: AlertifyService,
    private authService: AuthService,
    private updateOrder: ORDER_UPDATE,
    private getOrder: ORDER_ID,
    private mailService: MailService,
    private smsService: SmsService,
    private getOrderSms: ORDER_SMS,
    private createStatusLog: ORDER_STATUS_LOG_CREATE,
    private http: HttpClient,
    private getOrderBid: ORDER_BIDS_ONE
  ) {

    if(!this.authService.isLogin){
      this.authService.login();
    }
   }

  async ngOnInit(): Promise<void> {

    this.getTransaction()
    await this.getOrder.watch({id:this.orderId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {
      if(resp.data){
        this.order = resp.data.orders_by_pk;

        if(this.authService.userRole == "customer"){
  
          var duration = moment.duration(moment(this.order.service_date).diff(moment.now()));
          var hours = duration.asHours(); 

         if(hours > 72){// eğer servis tarihine 72 saatten az kaldı ise müşteri iptal edemez
          this.showCancelButton = true;
         }
         
        }else if(this.authService.userRole == "admin"){
          this.showCancelButton = true;
        }

        if(this.order && this.order.status_id == 2){
          this.bidFinisTime = moment(this.order.created_at).add(2, 'hours');
          if(moment() <this.bidFinisTime){
           // this.buttonDisabled = true;
          }
        }

        
      }
    })
  }

  ngOnChanges(){

    
  }

  getTransaction() {
    // get transaction
    this.getOrderStatus.watch({ "id": this.orderId }, { fetchPolicy: 'no-cache' }).valueChanges.subscribe((response:any) => {
      if(response.errors){
        this.alertifyService.error(response.errors[0].message);
      }
      if(response.data){
        this.serviceDate =  moment(response.data.orders_by_pk.service_date).format("LLL");

        this.curentStatusName = response.data.orders_by_pk.order_status.name;
        const status_id = response.data.orders_by_pk.status_id;


        this.getUserRole.fetch({ "id": this.authService.userId }).subscribe((response:any) => {
          const role_id = response.data.users_by_pk.role.id;
          this.roleId = role_id;
         
          if(this.roleId == 1 || this.roleId == 2){
            this.getOrderTransactions.watch({  "role_id": role_id, "order_status_id": status_id }, { fetchPolicy: 'no-cache' }).valueChanges.subscribe(response => {

              this.orderTransactions = response.data.order_transactions;
              if(this.orderTransactions == undefined || this.orderTransactions.length == 0 ){
                this.getOrderTransactions.watch({  "role_id": 5, "order_status_id": status_id }, { fetchPolicy: 'no-cache' }).valueChanges.subscribe(response => {
                  this.orderTransactions = response.data.order_transactions;
                })
              }
  
            })
          }else{
            this.getOrderTransactions.watch({ "role_id": role_id, "order_status_id": status_id }, { fetchPolicy: 'no-cache' }).valueChanges.subscribe(response => {
              this.orderTransactions = response.data.order_transactions;
  
            })
          }
          
        })
      }

    
    })
  }

  openDrawer(type:string,event:any,orderStatusId){
    switch(type){
      case "changeServiceDate":
        this.todayWithPipe = this.pipe.transform((Date.now() + 2), 'yyyy-MM-ddTH:mm');
        this.drawerTitle = "Servis Tarihi Düzenle";
        this.drawerTab = "changeServiceDate";
        this.showDrawer = true;
        this.selectedNextStatusId = event;
        this.orderStatusId = orderStatusId;
  
      break;
    }
  }

  toggleDrawer() {
    this.showDrawer = !this.showDrawer
  }

  transaction(nextStatusId: Number,orderStatusID: Number,createInvoice:boolean = false) {

       if (nextStatusId) {
      Swal.fire({
        title: 'Teklif Durumunu Değiştirmek İstiyor musunuz?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Vazgeç',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Evet Değiştir'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let serviceId: bigint | null = null;

          if(this.order){
           
            serviceId = this.order.service_id
          
          }

let canBeUpdate = true;

          if(orderStatusID == 1){
        
            await this.http.post<any>(this.baseUrl + "console-commands/order-to-bid", { },
            { headers: { token: `${localStorage.getItem('access_token')}` } }
            ).subscribe(response=>{

            })
          }

          if(orderStatusID == 2){

            if( this.order?.vehicle?.chassis_no == null || this.order?.vehicle?.chassis_no.length == 0){
              this.resultMessage  = "Aracı kabul etmek için parça durumları altından şasi numarasını giriniz";
              canBeUpdate = false;
            }

            if(this.order && this.order.order_bids && this.order.order_bids && this.order.order_bids[0]){
              let bidId = this.order.order_bids[0].id;
         
              await this.http.post<any>(this.baseUrl + "order-status/bid-answer-check", { bid_id:  bidId  },
              { headers: { token: `${localStorage.getItem('access_token')}` } }
              ).subscribe(response=>{
                
                if(response.status === false){
                 this.alertifyService.error(response.message)
                }else{
                 let counts = response.result;
                 if(response.result.answered != this.order?.order_invoices.invoice_parts.length){
                  canBeUpdate = false;
                  this.resultMessage = "Durum güncellemesi için parçaları seçiniz";
                 }
                
                }
                this.resultSend = true;
              });
            }
            await this.orderBidsInitialization();

          }

        

          if(orderStatusID == 22){
            //eğer ödeme farkı var ise araç kabul edildi yapamaz
             
             // canBeUpdate = false;
             // this.resultMessage = "Lütfen önce ödeme geçmişi adımından faturadaki farkı tahsil ediniz!";
          }


         

          let updateObj = { "id": this.orderId, "status_id": nextStatusId, service_id: serviceId };;

          if(orderStatusID == 29){
            updateObj =  { "id": this.orderId, "status_id": nextStatusId, service_id: null };
        
          }

          if(canBeUpdate){
            await this.updateOrderStatus.mutate(updateObj).subscribe(async (response:any) => {
          
              if (response.data) {
  
                if(response.data.update_orders.returning[0]){
               
                  /** ozanpay ödemeyi çek */
                  this.stepEmitter.emit(nextStatusId);
              
                  await  this.createStatusLog.mutate({order_id: this.orderId, old_status_id: orderStatusID, status_id: nextStatusId, user_id: this.authService.userId}).subscribe(logResp => {
                      if(logResp.errors){
                        this.alertifyService.error(logResp.errors[0].message);
                      }
                    })
  
                  if(nextStatusId == 1){
                    this.updateOrder.mutate({id:this.orderId,changes:{customer_status_id: 14}}).subscribe((orderUpdateRes:any) => {
                      if(orderUpdateRes.errors){
                        orderUpdateRes.errors[0].message;
                      }
                
                    })
                  }
                  
  
                  if(nextStatusId == 2){
                    this.buttonDisabled = true;
                    setTimeout(()=>{
                      this.buttonDisabled = false;
                    },2000)
                  }
  
                  if(createInvoice){
                    
                  }
  
                  
                  
                  let message = "Sayın " + this.order?.customer_info.name + " " + this.order?.customer_info.surname + "<br />";
                
                  message += this.orderId+" numaralı randevunuzun durumu " + response.data.update_orders.returning[0].order_status.name + " olarak güncellenmiştir.";
  
                  //this.order?.customer_info.email
  
                  if(this.order?.customer_info.email){
                  await this.mailService.send(this.order?.customer_info.email,'Garantili Oto Servis Randevu Durumunuz Güncellenmiştir.',message).subscribe(mailResponse => {
    
                      //if (this.order.customer.user_phones.length) {
                      //}
      
                      if(mailResponse.success){
                        this.alertifyService.success("Mail Gönderildi");
    
                      }
      
                    });
                  }
  
                  if(this.order?.customer_info.gsm_id){
          
                      let gsms = this.order.customer_info.gsms.filter((data:any)=>{
                        return (data.id == this.order?.customer_info.gsm_id);
                      })
  
                      if(gsms.length > 0){
                      await  this.getOrderSms.watch({"order_status_id":nextStatusId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(smsResult => {
                          if(smsResult.errors){
                            this.alertifyService.error(smsResult.errors[0].message);
                          }
                          if(smsResult.data){
                          
                            this.orderSmsList = smsResult.data.order_sms[0];
                            if(this.orderSmsList){
                              this.sendSmsService(this.orderSmsList.title,this.orderSmsList.content, [gsms[0].code]);
                            }
                    
                          }
                        })
                      }
  
                  }
  
                 
                    
  
                }
  
              
  
                
  
  
                this.getTransaction();
                
              //  this.sendSms()
                this.alertifyService.success("Durum Güncellendi!");
              } else {
                this.alertifyService.error("Durum Güncellenemedi!");
              }
            })
          }else{
            this.alertifyService.error(this.resultMessage);
          }

         


          
        }
      })
    
  }
    }

  async orderBidsInitialization(): Promise<void> {
    return new Promise<void>(resolve => {
        const checkOrderBids = () => {
            if (this.resultSend) {
                resolve();
            } else {
                setTimeout(checkOrderBids, 100);
            }
        };
        checkOrderBids();
    });
  }

   
    selected(type:string,event:any){
      switch(type){
      
        case "serviceDate":
          this.newServiceDate =  event.target.value;
         // 
         // this.formGroup.patchValue({service_date:this.serviceDate});
          break;
      }
    }


    updateServiceDate(){
      if(this.newServiceDate == null){
        this.alertifyService.error("Lütfen bir tarih seçiniz");
        return false;
      }
      
      Swal.fire({
        title: 'Servis tarihini güncellemek istiyor musunuz?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Vazgeç',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Güncelle'
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateOrder.mutate({id:this.orderId,changes:{"service_date": this.newServiceDate}}).subscribe((result:any) => {
            if(result.errors){
              this.alertifyService.error("Güncelleme sırasında bir hata oluştu");
            }
  
            if(result.data && this.newServiceDate){
              this.toggleDrawer();
              //this.serviceDate = moment(this.newServiceDate).format("LLL");
              if(this.selectedNextStatusId && this.orderStatusId){
                this.transaction(this.selectedNextStatusId,this.orderStatusId);
              }
            }
          })
          this.alertifyService.success("Servis tarihi güncellendi");
        }
      })
    }

    sendSmsService(title: String, content: String, phones: any[]) {
      this.smsService.send(title, content, phones).subscribe(response => {

        if (response.status) {
          // this.alertifyService.success("Sms Gönderildi")
          // Gönderilen smsler için kayıt işlemi olacaksa burada yapılacak
        }
      })
    }

    orderCancel(){
      Swal.fire({
        title: 'Randevuyu iptal etmek istiyor musunuz?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Vazgeç',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'İptal Et'
      }).then(async (result) => {
        if (result.isConfirmed) {

          await this.http.post<any>(this.baseUrl + "order/cancel", { id:  this.orderId  },
            { headers: { token: `${localStorage.getItem('access_token')}` } }
            ).subscribe(response=>{
              if(response.status === false){
                Swal.fire(
                  'Deleted!',
                  response.message,
                  'error'
                )
              }else{
                Swal.fire(
                  'Deleted!',
                  'Randevu iptal edildi',
                  'success'
                )
              
              }
            });

          
        }
      })
    }

 

}

