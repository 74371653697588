import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { Order, OrderSubs, ORDER_SUBS, ORDER_TRACK_ID } from 'src/app/graphql/order.graphql';
import { OrderCards } from 'src/app/graphql/order_cards.graphql';
import { OzanpayResponse, OZANPAY_RESPONSE_SUBS } from 'src/app/graphql/ozanpay_response.graphql';
import { OzanpaySuccessfuls, OZANPAY_SUCCESSFULS_SUBS } from 'src/app/graphql/ozanpay_successful.graphql';
import { UserAddress } from 'src/app/graphql/user_address.graphql';
import { numberFormat, numberFormatStr } from 'src/app/helpers/slugify';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { PaymentService } from 'src/app/services/payment.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ek-hizmet-odeme',
  templateUrl: './ek-hizmet-odeme.component.html',
  styles: [
  ],
  providers:[ORDER_TRACK_ID,ORDER_SUBS,OZANPAY_RESPONSE_SUBS,OZANPAY_SUCCESSFULS_SUBS],

})
export class EkHizmetOdemeComponent implements OnInit {

  public showForm:  Observable<boolean> | undefined;
  public invoiceInfo:any = [];
  public userInfo: any;
  public selectedAddressId:BigInt | undefined;
  public drawerTitle:string = "Adres Ekle";
  public drawerPage:string = "";
  public showDrawer:boolean = false;
  public gsmList:any  = [];
  public addressList:UserAddress[] | undefined;
  public selectedAddressInfo:any;
  public isCompany:boolean = false;
  public needInvoice = true;
  public ordId: any;
  public chName = "";
  public inputCardNo:any;
  public filteredCardNo:any;
  public filteredExpiryDate:any;
  public inputExpiryDate: any;
  public momentOrderId:any;
  public expriceMonth:Number = 0;
  public expiryYear:Number = 0;
  public paymentMessage: String = "";
  public installmentInfo: any;

  public showPaymentMessage: boolean = false;


  public cardName: String | undefined;
  public cardExpire: String | undefined;
  public ccv2: String | undefined;
  public turnCard = false;
  public hashInfo = {
    rnd: "",
    hash: ""
  };
  public orderSubs: OrderSubs | undefined;


  public denizbankUrl = environment.denizbankUrl;
  public shopCodeLive = environment.denizShopCode;
  public userCodeLive = environment.denizUserCode;
  public posPass = "";
  public threedKey = environment.deniz3DKey;
  

  public subOrderCards: OrderCards | undefined;
  public hidePaymentButton= false;
  public priceInfo: any;

  public hash : String | undefined;
  public rnd: any;
  public orderDetail : Order | undefined;
  public serviceLastDate:any;
  public InstallmentCount = "";         //taksit sayisi
  public filteredData:any = [];
  public selectedInstallment = 1;

  public installmentAmount = 0;

  paymentFormGroup = new FormGroup({
    Expire: new FormControl('', [Validators.required]),
    Pan: new FormControl('', [Validators.required,Validators.maxLength(11),Validators.minLength(11)]),
    Cvv2: new FormControl('', [Validators.required,Validators.maxLength(3),Validators.minLength(3)]),
    CardType: new FormControl('', [Validators.required]),
    ShopCode: new FormControl('', [Validators.required]),
    PurchAmount: new FormControl('', [Validators.required]),
    Currency: new FormControl('', [Validators.required]),
    OrderId: new FormControl('', [Validators.required]),
    OkUrl: new FormControl('', [Validators.required]),
    FailUrl: new FormControl('', [Validators.required]),
    Rnd: new FormControl('', [Validators.required]),
    Hash: new FormControl('', [Validators.required]),
    TxnType: new FormControl('', [Validators.required]),
    InstallmentCount: new FormControl(''),
    SecureType: new FormControl('', [Validators.required]),
    Lang: new FormControl('', [Validators.required])

  });

  invoiceAddressForm = new FormGroup({
    order_id: new FormControl(null,[Validators.required]),
    user_id: new FormControl(null,[Validators.required]),
    customer_name: new FormControl(null,[Validators.required]),
    customer_surname: new FormControl(null,[Validators.required]),
    customer_email: new FormControl(null,[Validators.required]),
    identity_no: new FormControl(null,[Validators.required]),
    tax_administration: new FormControl(null),
    tax_number: new FormControl(null),
    gsm_number: new FormControl(null,[Validators.required]),
    tel_number: new FormControl(null),
    invoice_type: new FormControl(3,[Validators.required]),
    address: new FormControl(null,[Validators.required]),
    city_id: new FormControl(null,[Validators.required]),
    district_id: new FormControl(null,[Validators.required]),
    town_id: new FormControl(null,[Validators.required]),
    country_id: new FormControl(null,[Validators.required]),
    company_name: new FormControl(null),
    customer_invoice_type: new FormControl(1,[Validators.required])
  });

  public trackId: any;
  public shopCode = environment.denizShopCode;
  public showPosError = false;
  public posErrorMessage = "";
  public isPayed:Boolean = false;
  public paymentAttempt:Number = 0;
  public paymentErrorMessage = "";
  public ozanpayResonse: OzanpayResponse[] | undefined;
  public ozanpaySuccessFuls: OzanpaySuccessfuls[] | undefined;
  public valueDifference = 0;


  public checkCardInfo = {
    cardHolder: false,
    cardNumber: false,
    cvv: false,
    expiry: false

  }

  @ViewChild('paymentForm') 
  paymentFormElement!: ElementRef; 
  
  constructor(
    private alertifyService: AlertifyService,
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private getOrderByTrackId: ORDER_TRACK_ID,
    private PaymentService: PaymentService,
    private listenOrder: ORDER_SUBS,
    private http: HttpClient,
    private ozanpayResponseSubs: OZANPAY_RESPONSE_SUBS,
    private ozanpaySuccessfulsSubs: OZANPAY_SUCCESSFULS_SUBS,


  ) { 
    this.trackId = this.activatedRoute.snapshot.params["track"];
    
  }

  ngOnInit(): void {
   
    moment.locale('tr');
    if(this.trackId == undefined){
      this.alertifyService.error("Siparişe ulaşılamadı");
      
    }

    this.getOrderDetail();


  }

  getOrderDetail(){
    this.getOrderByTrackId.watch({"track_id":this.trackId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
      if(response.errors){
        this.alertifyService.error(response.errors[0].message);
      }

      if(response.data){
        let order = response.data.orders;
        
        if(order.length > 0){
          this.orderDetail = order[0];
          this.paymentAttempt = this.orderDetail.payment_attemts;


          this.valueDifference = (this.orderDetail.order_value - this.orderDetail.payment_value);

          

        

        }
      }
    });
  }

  toNumberFormat(number: any) {
    return numberFormatStr(number);
  }

  toNumber(number: any){
    return numberFormat(number)
  }


  filterFormatCardNo($event) {
    this.turnCard = false;
    this.inputCardNo = $event.target.value.replace(" ","").trim();

    let value = $event.target.value.replace(/\s/g, '').replace(/(.{4})/g, '$1 ').trim();

    this.filteredCardNo = value;

    if(this.inputCardNo.length == 16 && this.orderDetail){
      this.http.post(environment.laravelApiEndpoint+"payment/check-installment",{
        "card_number": this.inputCardNo,
        "order_card_id": this.orderDetail.id,
        "type": "order"
    },
    { headers: { token: `${localStorage.getItem('access_token')}` } }).subscribe(async (resp:any) => {
        if(!resp.status){
          this.alertifyService.error(resp.message);
        }

        if(resp.status){
          if(resp && resp.result && resp.result.return_data){
            this.installmentInfo = resp.result.return_data;


            if(this.installmentInfo.installmentRateList){
              const interestRates = [3, 6]; // Filtrelemek istediğiniz interestRate değerleri
              this.filteredData = [];
              await Object.keys(this.installmentInfo.installmentRateList).forEach(bank => {
                const bankInstallments = this.installmentInfo.installmentRateList[bank];
                interestRates.forEach(count => {
                  const installmentDetail = bankInstallments[count];
                  
                  if (installmentDetail) {
                    installmentDetail.installmentCount = count;
                    installmentDetail.baseAmount = ( installmentDetail.baseAmount / 100 );
                    installmentDetail.buyerCostAmount = ( installmentDetail.buyerCostAmount / 100 );
                    installmentDetail.buyerCostInstallmentPrice = ( installmentDetail.buyerCostInstallmentPrice / 100 );
                    installmentDetail.installmentPrice = ( installmentDetail.installmentPrice / 100 );
                    this.filteredData.push(installmentDetail);
                  }
                });
              });

             
            }
           
           // this.installmentInfo.installmentRateList = Object.values(this.installmentInfo.installmentRateList);

          // interestRate'e göre artan sırayla sırala
         // this.installmentInfo.installmentRateList = this.installmentInfo.installmentRateList.sort((a:any, b:any) => parseInt(a.interestRate) - parseInt(b.interestRate));


//this.installmentInfo.installmentRateList = installmentListArray;
          }
        }
       
      })
    }
   
  }


   findItemsWithInterestRatesUsingIndex(obj: any, rates: number[]): any[] {
    // Objeden ilk anahtarın alınması
    const firstKey = Object.keys(obj)[0];
    // İlk anahtara ait değerler üzerinden filtreleme
    return Object.values(obj[firstKey]).filter((item: any) => rates.includes(item.interestRate));
}
  

  setCardInfo(type:string,$event:any){
    switch(type){
      case "cardName":
        this.cardName = $event.target.value;
        this.turnCard = false;
      break;
  
      case "expire":
        this.cardExpire = $event.target.value;
        this.turnCard = false;
      break;
  
      case "ccv":
        this.ccv2 = $event.target.value;
        this.turnCard = true;
      break;
    }
  }

  filterFormatExpirationDate($event) {
  
  
    let exp =  $event.target.value.split('/');
  
    this.expriceMonth = exp[0];
    this.expiryYear = exp[1];
  
    this.inputExpiryDate = $event.target.value.replace("/","").trim();
    this.filteredExpiryDate = this.inputExpiryDate.replace(/\D/g, ''); // Sadece sayısal karakterleri alın
   
    if (this.filteredExpiryDate.length > 2) {
      this.filteredExpiryDate = this.filteredExpiryDate.slice(0, 2) + '/' + this.filteredExpiryDate.slice(2);
      this.filteredExpiryDate = this.filteredExpiryDate.slice(0,5);
    }
   
  }

  async formEmit(){

    

    if(this.orderDetail){
      this.hidePaymentButton = true;
      this.http.post(environment.laravelApiEndpoint+"payment/manuel-pay-external",{
        order_id:this.orderDetail.id,
        card_number: this.inputCardNo,
        expiry_month: this.expriceMonth,
        expiry_year: this.expiryYear,
        cvv: this.ccv2,
        installment: this.selectedInstallment,
        total_amount: this.valueDifference
      }).subscribe(async (paymentResponse:any) => {
        if(paymentResponse.errors){
          this.alertifyService.error(paymentResponse.errors[0].message);
        }
        
        if (paymentResponse.status && paymentResponse.result.ozan3dUrl !== null) {
    
          let url = paymentResponse.result?.ozan3dUrl;
  
          if (url) {
            window.open(url, '_blank')?.focus();
           
                this.ozanpayResponseSubs.subscribe(
                  {
                    where:{
                      ozan_pay_request_id: {
                        _eq: paymentResponse.result.payment_request_id
                      }
                    }
                  },
                  {
                    fetchPolicy:"no-cache"
                  }
                ).subscribe(aozanpayResponse => {
  
                  if(aozanpayResponse.errors){
                    this.alertifyService.error(aozanpayResponse.errors[0].message);
                  }
                  if(aozanpayResponse.data){
                    this.ozanpayResonse = aozanpayResponse.data?.ozanpay_response;
                    if(this.ozanpayResonse[0]?.status == "APPROVED"){
  
                      let ozanSub = this.ozanpaySuccessfulsSubs
                      .subscribe(
                        {
                          where:{
                            ozan_pay_request_id: {
                                _eq: paymentResponse.result.payment_request_id
                            }
                          }
                        },
                        {
                          fetchPolicy:"no-cache"
                        }
                        ).subscribe(resp => {
                      if(resp.errors){
                        this.alertifyService.error(resp.errors[0].message);
                      }
  
                      if(resp.data){
  
                        this.paymentMessage = "Bankadan yanıt bekleniyor...";
                       
                        this.ozanpaySuccessFuls = resp.data.ozanpay_successfuls;
      
                        if( this.ozanpaySuccessFuls && this.ozanpaySuccessFuls.length > 0){
                          this.paymentMessage = "İşlem inceleniyor...";
                        }
      
                        this.hidePaymentButton = false;
                       // this.paymentMessage = this.ozanpaySuccessFuls.data
      
                        if( this.ozanpaySuccessFuls[0]?.status == 'APPROVED'){
                          this.isPayed = true;
                        
                          let returnObj = {
                            isPayed: this.isPayed,
                            invoiceInfo:this.invoiceAddressForm.value,
                            orderCreateable : true,
                          }
                         
                          this.getOrderDetail();
                         
                        }
      
                      }
                
                      })
  
                    }
  
                    if(this.ozanpayResonse[0]?.status == "DECLINED"){
  
                      let data = JSON.parse(this.ozanpayResonse[0].response_data);
                      this.paymentMessage    = data.message;
                      this.hidePaymentButton = false;
                      this.showPaymentMessage = true;
                    }
                  }
  
                  
                  
  
                });
  
  
          }
  
          
        }else{
  
          this.hidePaymentButton = false;
  
          this.paymentMessage = paymentResponse.result.message;
          this.showPaymentMessage = true;
          setTimeout(()=>{
            this.showPaymentMessage = false;
          },5000)
  
        }

      })
    }
    

  }

  getField(obj:any,field:string){

    let retvalue:any = "";
    switch(field){
      case "baseAmount":
        case "installmentAmount":
        retvalue = (obj[field] / 100 )
      break;

      default:
        retvalue = obj[field];;
      break;
    }

    return retvalue;
  }

  setInstallment(event:any,obj,def=false){
 
    if(def){
      this.selectedInstallment = event;
      this.installmentAmount = (obj / 100);
    }else{
      this.selectedInstallment = event.target.value;
      this.installmentAmount = obj.buyerCostAmount ;
    }
   
  }


}
