import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { COMPANY_UPDATE_CUSTOM } from 'src/app/graphql/company.graphql';
import { User, USER_GET_ID } from 'src/app/graphql/user.graphql';
import { CustomValidator } from 'src/app/helpers/custom.validator';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { FireBaseEmailAuthReponse } from 'src/app/interfaces/firebase.interface';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: [],
  providers:[USER_GET_ID,COMPANY_UPDATE_CUSTOM]
})
export class SigninComponent implements OnInit {

  @Output() login = new EventEmitter<boolean>()

  public loginForm = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', [Validators.required, Validators.min(6)])
  });

  private _loading: boolean = false;
  public get loading(): boolean {
    return this._loading;
  }
  public set loading(value: boolean) {
    this._loading = value;
  }
  public error: string | undefined;
  public emailExists: boolean = false;
  public userInfo: User | undefined;

  constructor(
    public router: Router,
    private authService: AuthService,
    private alertifyService: AlertifyService,
    private getUserInfo: USER_GET_ID,
    private updateCompany: COMPANY_UPDATE_CUSTOM,
    
  ) { 
    
  }

  authFormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, CustomValidator.isValidEmail]),
    password: new FormControl('', [Validators.required, Validators.minLength(5)])
  })

  ngOnInit(): void {

  }

  authFormSubmit() {
    if (this.authFormGroup.invalid) return;

    const email = this.authFormGroup.value.email;
    const password = this.authFormGroup.value.password;

    // login
    this.authService.firebaseAuth(email, password, true).subscribe(fireBaseResponse => {
      this.authService.apiLogin(fireBaseResponse).subscribe(response => {

       

        if (response.status) {

           // teklif alma formundan login olduysa
           if (this.router.url == '/teklif-al') {
            this.login.emit(true)
          } else {
            this.updateCompanyActiveDate();
            this.router.navigateByUrl(this.authService.userRole);
          }
        
        } else { // firebase kaydı var iken db kaydı yok ise kaydı tekrar oluştur
          if (fireBaseResponse.registered) {
            this.authService.apiRegister(fireBaseResponse,"name", "surname",'',null,null).subscribe(response => {
              if (response.status) {

               
                // teklif alma formundan login olduysa
                if (this.router.url == '/') {
                
                  this.login.emit(true)
                } else {
                  this.router.navigateByUrl(this.authService.userRole)
                  //this.router.navigateByUrl(this.authService.userRole)
                }
              } else {
                this.alertifyService.error("Kayıt Başarısız");
              }
            })
          } else {
            this.alertifyService.error("Giriş Başarısız");
          }
        }
      })
    }, err => {
      this.alertifyService.error(err)
    })
    this.authFormGroup.reset();
  }

  updateCompanyActiveDate(){
    if(this.authService.userId > 0 && this.authService.userRole == "service"){
      this.getUserInfo.watch({id:this.authService.userId}).valueChanges.subscribe((response:any)=>{
        if(response.errors){
          this.alertifyService.error(response.errors[0].message);
        }
        
        if(response.data){
          this.userInfo = response.data.users_by_pk;

          if(this.userInfo){
            if(this.userInfo.company.activation_date == "0001-01-01T00:00:00+00:00"){
              let now  = moment().format('yyyy-MM-DDTH:mm');
              this.updateCompany.mutate({id:this.userInfo.company_id,changes:{activation_date:now}}).subscribe((updateresponse:any)=>{
                if(updateresponse.errors){
                  this.alertifyService.error(response.errors[0].message);
                }
                if(updateresponse.data){
                  //this.alertifyService.success("Kayıt Başarılı");
                }
              });
  
            }
          }

         
        }
    
      })
    }
  }
  

  googleLogin() {
    const auth = getAuth()
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
      .then((result) => {
        const fireBaseResponse: FireBaseEmailAuthReponse = {} as FireBaseEmailAuthReponse
        if (result.user && result.user.email) {
          fireBaseResponse.idToken = result.user.refreshToken
          fireBaseResponse.email = result.user.email
          fireBaseResponse.refreshToken = result.user.refreshToken
          fireBaseResponse.localId = result.user.uid
          fireBaseResponse.registered = true
          this.apiLogin(fireBaseResponse)
        } else {
          this.alertifyService.error("Giriş Başarısız")
        }
      }).catch((error) => {
        this.alertifyService.error(error.message)
      })
  }

  facebookLogin() {
    const auth = getAuth()
    const provider = new FacebookAuthProvider()
    signInWithPopup(auth, provider)
      .then((result) => {
        const fireBaseResponse: FireBaseEmailAuthReponse = {} as FireBaseEmailAuthReponse
        if (result.user && result.user.email) {
          fireBaseResponse.idToken = result.user.providerId
          fireBaseResponse.email = result.user.email
          fireBaseResponse.refreshToken = result.user.refreshToken
          fireBaseResponse.localId = result.user.uid
          fireBaseResponse.registered = true
          this.apiLogin(fireBaseResponse)
        } else {
          this.alertifyService.error("Giriş Başarısız")
        }
      })
      .catch((error) => {
        this.alertifyService.error(error.message)
      })
  }

  private apiLogin(fireBaseResponse: FireBaseEmailAuthReponse) {
    this.authService.apiLogin(fireBaseResponse).subscribe(response => {
      if (response.status) {
        // teklif alma formundan login olduysa
        if (this.router.url == '/') {
          this.login.emit(true)
        } else {
          window.location.href = this.authService.userRole
        }
      } else { // firebase kaydı var iken db kaydı yok ise kaydı tekrar oluştur
        if (fireBaseResponse.registered) {
          this.authService.apiRegister(fireBaseResponse, "name", "surname", "identity_no",null,null).subscribe(response => {
            if (response.status) {
              // teklif alma formundan login olduysa
              if (this.router.url == '/') {
                this.login.emit(true)
              } else {
                window.location.href = this.authService.userRole
              }
            } else {
              this.alertifyService.error("Kayıt Başarısız");
            }
          })
        } else {
          this.alertifyService.error("Giriş Başarısız");
        }
      }
    })
  }



}
