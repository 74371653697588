<div class="row">
    <div class="w-100">
        <div class="alert alert-success">Randevunuz Oluşturulmuştur. Randevunuz {{serviceLastDate}} tarhine kadar geçerlidir.</div>
    </div>
</div>


<div class="mw-lg-950px mx-auto w-100" style="padding: 25px; border: 1px solid #f0f0f0;" id="pdfTable" #pdfTable>
    <!-- begin::Header-->
    <div class="d-flex justify-content-center flex-column flex-sm-row mb-19">
        <!--end::Logo-->
        <div class="text-sm">
            <!--begin::Logo-->
            <a href="#" class="d-block mw-150px mx-auto">
                <img alt="Logo" src="assets/images/logo-garantiliotoservis.svg" class="w-100" />
            </a>
            <!--end::Logo-->
            <!--begin::Text-->
            <div class="text-sm fw-bold fs-4 text-muted mt-7">
                <div class="text-center">Garantili Satış Sonrası Oto Hiz.A.Ş.</div>
                <div class="text-center">Cevizli Mahallesi Tugay Yolu Caddesi No:59 </div>
                <div class="text-center">Maltepe / İstanbul</div>
            </div>
            <!--end::Text-->
        </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="pb-12" *ngIf="orderType && orderType != 3">
        <!--begin::Wrapper-->
        
        <div class="d-flex flex-column gap-7 gap-md-10">
            <div style="display: flex; justify-content: space-between;">
             
                    <!--begin::Message-->
                    <div class="fw-bolder fs-2">Sayın {{userInfo?.name}} {{userInfo?.surname}}
                        <span class="fs-6">({{userInfo?.email}})</span>,
                        <br />
                        <span class="text-muted fs-5">
                            Randevu detayını görüntülemektesiniz. Bizi tercih ettiğiniz için teşekkür ederiz.
                        </span>
                        <p class="text-muted fw-bold fs-7">({{serviceLastDate}} tarihine kadar geçerlidir)</p>
                    </div>
               
                    <app-shared-proforma-pdf-generator
                        
                        [userInfo]="userInfo"
                        [serviceLastDate]="serviceLastDate"
                        [todayDate]="todayDate"
                        [selectedPackageInfo]="selectedPackageInfo"
                        [selectedUncompaignProducts]="selectedUncompaignProducts"
                        [subTotal]="priceInfo.subTotal"
                        [vat]="priceInfo.vat"
                        [discount]="priceInfo.discount"
                        [totalAmount]="priceInfo.totalAmount"
                    >

                    </app-shared-proforma-pdf-generator>
        
             
            </div>
            <!--begin::Message-->
            <!--begin::Separator-->
            <div class="separator"></div>
            <!--begin::Separator-->
            <!--begin::Order details-->
            <div class="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bolder ">
                <div class="flex-root d-flex flex-column">
                    
                </div>
                <div class="flex-root d-flex flex-column">
                    
                </div>
                <div class="flex-root d-flex flex-column">
                    
                    <span class="text-muted " style="align-self: flex-end;">Tarih</span>
                    <span class="fs-5" style="align-self: flex-end;">{{todayDate}}</span>
                </div>
            
            </div>
            <div class="d-flex justify-content-between flex-column">
                <h3 class="fw-bolder fs-2">{{selectedPackageInfo?.name}}</h3>
                <p>Genel Toplam : {{priceInfo.totalAmount | number:'1.0-2'}} &#8378;</p>
                <!--begin::Table-->
                <div class="table-responsive border-bottom mb-9 d-none">
                    <table class="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                        <thead>
                            <tr class="border-bottom fs-6 fw-bolder text-muted">
                                <th class="min-w-175px pb-2">Yedek Parça Adı</th>
                                <th class="min-w-175px pb-2">Parça Türü</th>
                                <th class="min-w-80px text-end pb-2">Adet</th>
                                <th class="min-w-80px text-end pb-2">Birim Fiyat1</th>
                                <th class="min-w-100px text-end pb-2">Tutar</th>
                            </tr>
                        </thead>
                        <tbody class="fw-bold text-gray-600">
                            <!--begin::Products-->
                            <tr *ngFor="let item of selectedUncompaignProducts" >
                                <!--begin::Product-->
                                <td>
                                    <div class="d-flex align-items-center">
                                        <!--begin::Thumbnail-->
                                        
                                    
                                        <span class="symbol symbol-50px me-6">
                                            <span class="symbol-label">
                                                <i class="fa-car fas"></i>
                                            </span>
                                        </span>
                                        <!--end::Thumbnail-->
                                        <!--begin::Title-->
                                        <div class="ms-5">
                                            <div class="fw-bolder">{{item.selected_equivalent_name}}</div>
                                            <div style="display: none;" class="fs-7 text-muted">{{item.part_code}}</div>
                                        </div>
                                        <!--end::Title-->
                                    </div>
                                </td>
                                <!--end::Product-->

                                <td>{{item.selected_equivalent_part_type_name}} </td>
                                
                                <!--begin::Quantity-->
                                <td class="text-end">{{item.part_unit}} {{ item.part_unit_name }}</td>

                                <td class="text-end">{{item.part_value | number:'1.0-2'}} &#8378;</td>
                                <!--end::Quantity-->
                                <!--begin::Total-->
                                <td class="text-end">{{item.total_price | number:'1.0-2'}} &#8378;</td>
                                
                                <!--end::Total-->
                            </tr>
                            <!--end::Products-->

                            <tr *ngFor="let item of selectedCompaignProducts" >
                                <!--begin::Product-->
                                <td style="background-color: #96bc33 !important;
                                color:#015029;
                                padding-left: 10px;
                                border-top-left-radius: 10px;
                                border-bottom-left-radius: 10px;
                                margin-top: 10px;">
                                    <div class="d-flex align-items-center">
                                        <!--begin::Thumbnail-->
                                        
                                    
                                        <span class="symbol symbol-50px me-6">
                                            <span class="symbol-label">
                                                <i class="fa-car fas"></i>
                                            </span>
                                        </span>
                                        <!--end::Thumbnail-->
                                        <!--begin::Title-->
                                        <div class="ms-5">
                                            <div class="fw-bolder">{{item.name}}</div>
                                            <div style="display: none;" class="fs-7 text-muted">{{item.part_code}}</div>
                                        </div>
                                        <!--end::Title-->
                                    </div>
                                </td>
                                <!--end::Product-->

                                <td style="background-color: #96bc33 !important;
                                color:#015029;">{{item.partTypeName}}</td>
                                
                                <!--begin::Quantity-->
                                <td class="text-end" style="background-color: #96bc33 !important;
                                color:#015029;">{{item.part_unit}}</td>

                                <td class="text-end" style="background-color: #96bc33 !important;
                                color:#015029;">{{item.price | number:'1.0-2'}} &#8378;</td>
                                <!--end::Quantity-->
                                <!--begin::Total-->
                                <td class="text-end" style="background-color: #96bc33 !important;
                                color:#015029;
                                padding-right: 10px;
                                border-top-right-radius: 10px;
                                border-bottom-right-radius: 10px;
                                margin-top: 10px;">{{item.price_with_unit | number:'1.0-2'}} &#8378;</td>
                                
                                <!--end::Total-->
                            </tr>

                            <!--begin::Subtotal-->
                            <tr>
                                <td colspan="4" class="text-end">Toplam</td>
                                <td class="text-end">{{priceInfo.subTotal | number:'1.0-2'}} &#8378;</td>
                            </tr>
                            <!--end::Subtotal-->
                         
                            <!--begin::VAT-->
                            <tr>
                                <td colspan="4" class="text-end">Kdv (18%)</td>
                                <td class="text-end">{{ priceInfo.vat | number:'1.0-2'}} &#8378;</td>
                            </tr>
                            <!--end::VAT-->
                            <!--begin::Shipping-->
                            <tr>
                                <td colspan="4" class="text-end">İndirim</td>
                                <td class="text-end">{{(priceInfo.discount | number:'1.0-2')}} &#8378;</td>
                            </tr>
                            <!--end::Shipping-->
                            <!--begin::Grand total-->
                            <tr>
                                <td colspan="4" class="fs-3 text-dark fw-bolder text-end">Genel Toplam</td>
                                <td class="text-dark fs-3 fw-boldest text-end">{{priceInfo.totalAmount | number:'1.0-2'}} &#8378;</td>
                            </tr>
                            <!--end::Grand total-->
                        </tbody>
                    </table>
                </div>
                <!--end::Table-->
            </div>
            <!--end:Order summary-->

            
        </div>
        <!--end::Wrapper-->
    </div>
    <!--end::Body-->

</div>