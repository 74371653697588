<div class="card card-flush py-4">
    <div class="card-body pt-0">
        <div class="row">
           
            
            
            <div class="col-lg-3 col-md-9 col-sm-12" >
                <label for="" class="required form-label">Gos Parça</label>
                <app-shared-selectwo
               
                        [lists]="partList"
                        [id]="'partList'"
                        (selectedItem)="selectedTwo('parts',$event)" 
                        [name]="'Gos Parça'" 
              
                        [showAll]=false
                        [extendSearch]="true"
                        (searchItem)="searchPart($event)"
                        [searchItemLength]="4"
                     
                        ></app-shared-selectwo>
                       
                
            </div>
            <div class="col-lg-2 col-md-9 col-sm-12"  >
                <label for="" class="required form-label">Parça Ekle</label>
                <input type="button" value="Parça Ekle" class="btn btn-primary btn-sm" (click)="addPiece()">
                
                       
                
            </div>
            
        </div>
        
    </div>
</div>

<div class="card card-flush py-4">
    <div class="card-body pt-0">
        <div class="row">
            <div class="d-flex flex-column gap-10 mt-5">
                <div class="w-100">
                    <label class="d-flex align-items-center form-label mb-5">Seçili Ürünler
                        <i class="fas fa-exclamation-circle ms-2 fs-7"
                            aria-label="Monthly billing will be based on your account plan"
                            title="Birden fazla seçim yapabilirsiniz."></i>
                    </label>
                    <div class="w-100" style="overflow: hidden;
                    overflow-x: scroll;">
                        
                    
                        <div class="card card-flush py-4 flex-row-fluid" >
                            <div class="card-header">
                                <div class="card-title">
                                    <h4>Ürün Bilgileri</h4>
                                </div>
                                <div class="card-tool">
            
                                </div>
                            </div>
            
                            
                          
                            <!--begin::Card body-->
                            <div class="card-body pt-0 ">
                                <div class="table-responsive">
                                    <table class="table">
                                      
                                        <thead>
                                            <tr class="border-bottom fs-6 fw-bolder text-muted text-uppercase">
                                                <th class="min-w-175px pb-9">Parça Adı</th>
                                                <th class="min-w-175px pb-9">Parça Grubu</th>
                                                <th class="min-w-175px pb-9">Adet / Ücret</th>
                                                <th class="min-w-175px pb-9">İşlemler</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="fw-bolder text-gray-700 fs-5 "  
                                            *ngFor="let item of selectedParts">
                                                <td>{{item.selectedGosPart_name}}</td>
                                                <td>{{item.selectedGosPart_groupName}}</td>
                                                <td class="text-muted fs-7 fw-bold">Adet ve ücret bilgileri servis tarafından verilecektir.</td>
                                                <td> <button class="btn btn-primary btn-sm" 
                                                   
                                                    (click)="removePiece(item.selectedGosPart_id,item.selectedEquivalent_id)">
                                                     <i class="fas fa-window-close"></i> Kaldır </button> 
                                                    
                                                    </td>
                                            </tr>

                                            
                                        </tbody>
                                       
                                    </table>
                                </div>
            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
