<div class="d-flex justify-content-between py-5 form-container ">
    <div class="randevu-container">
        <div class="service-info-container" *ngIf="company">
            <div class="service-info-img-container">
                <img *ngIf="company.logo !== null && company.logo !== '' " src="https://cdn.destechhasar.com/garantili-oto-servis/{{company.logo}}" alt="">
                <img class="mw-100" *ngIf="company.logo === null ||company.logo === ''"   src="http://cdn.destechhasar.com/garantili-oto-servis/w:150/h:100/gos-no-image.jpeg" alt="" />            </div>
            <div class="service-info-desc-container">
                <h4 class="d-flex">{{company.name}}</h4>
                <p class="d-flex">{{company.address}} &nbsp;<span *ngIf="company.City"> {{company.City?.name}} / {{company.district?.name}}</span></p>    
                <div class="service-info-fav">
                    <span>
                        <i class="fa-star me-1 text-warning fs-5" [ngClass]="company.evalutaion_points && company.evalutaion_points >= 1 ? 'fa' : 'far'"></i>
                        <i class="fa-star me-1 text-warning fs-5" [ngClass]="company.evalutaion_points && company.evalutaion_points >= 2 ? 'fa' : 'far'"></i>
                        <i class="fa-star me-1 text-warning fs-5" [ngClass]="company.evalutaion_points && company.evalutaion_points >= 3 ? 'fa' : 'far'"></i>
                        <i class="fa-star me-1 text-warning fs-5" [ngClass]="company.evalutaion_points && company.evalutaion_points >= 4 ? 'fa' : 'far'"></i>
                        <i class="fa-star me-1 text-warning fs-5" [ngClass]="company.evalutaion_points && company.evalutaion_points >= 5 ? 'fa' : 'far'"></i>
                        <span class="text-gray-800 fw-bolder">
                            {{company.evalutaion_points}}.0
                        </span>              
                    </span>
                    <span>
                        <a href="">34 Yorum</a>
                    </span>
                </div>
                <div class="service-info-properties">
                    <ul>
                        <li><i class="fas fa-dot-circle text-primary"></i> Kuruluş Yılı: 2009</li>
                        <li><i class="fas fa-dot-circle text-primary"></i> Mesleki Yeterlilik Belgesi Uzman Kadro</li>
                        <li><i class="fas fa-dot-circle text-primary"></i> Özel Serviste Premium Hizmet</li>
                    </ul>
                </div> 
            </div>            
        </div>
        <div class="appointment-selector-container">
            <app-shared-appointment-date-selector 
            style="width:100%; display: flex; flex-direction: column; align-items: center; justify-content: center;"
            [companyId]="companyId"
            (selected)="returnData($event)"
            [selectedDateInput]="selectedDateInput">
            </app-shared-appointment-date-selector>
        </div>
    </div>
</div>