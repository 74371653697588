import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AdminGuard } from './services/guards/admin.guard';
import { AuthService } from './services/auth.service';
import { ServiceGuard } from './services/guards/service.guard';
import { AppRoutingModule } from './app-routing.module';
import { GraphQLModule } from './graphql/graphql.module';
import { SupplierGuard } from './services/guards/supplier.guard';
import { CustomerGuard } from './services/guards/customer.guard';
import { AuthComponent } from './site/auth/auth.component';
import { AlertifyService } from './services/alertify.service';
import { HttpErrorInterCeptor } from './helpers/http-error.interceptor';
import { WelcomeComponent } from './site/welcome/welcome.component';
import { SigninComponent } from './site/auth/signin/signin.component';
import { SignupComponent } from './site/auth/signup/signup.component';
import { ForgotComponent } from './site/auth/forgot/forgot.component';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { SiteComponent } from './site/site.component';
import { FooterComponent } from './site/components/footer/footer.component';
import { ToolbarComponent } from './site/components/toolbar/toolbar.component';
import { NavbarComponent } from './site/components/navbar/navbar.component';
import { VehicleServicesListComponent } from './site/vehicle-services/vehicle-services-list.component';
import { VehicleServicesDetailComponent } from './site/vehicle-services/vehicle-services-detail.component';
import { VehicleListFilterComponent } from './site/vehicle-services/vehicle-list-filter.component';
import { OfferComponent } from './site/offer/offer.component';
import { SecurityComponent } from './site/static/security.component';
import { ConfirmationComponent } from './site/static/confirmation.component';
import { InformationTextComponent } from './site/static/information-text.component';
import { UserAggramentComponent } from './site/static/user-aggrament.component';
import { AddressEditComponent } from './site/offer/address-edit.component';
import { AddressAddComponent } from './site/offer/address-add.component';
import { ProfileEditComponent } from './site/offer/profile-edit.component';
import { GsmAddComponent } from './site/offer/gsm-add.component';
import { UserService } from './services/user.service';
import { SssComponent } from './site/static/sss.component';
import { PdfService } from './services/pdf.service';
import { PaymentService } from './services/payment.service';
import { MailService } from './services/mail.service';
import { SmsService } from './services/sms.service';
import { PermissionGuard } from './services/guards/permission.guard';
import { CdnService } from './services/cdn.service';
import { CustomerOfferService } from './services/customerOffer.service';
import { AnonymousGuard } from './services/guards/anonymous.guard';
import { AppointmentComponent } from './site/appointment/appointment.component';
import { HizmetSecimiComponent } from './site/appointment/steps/hizmet-secimi.component';
import { AracSecimiComponent } from './site/appointment/steps/arac-secimi.component';
import { PaketSecimiComponent } from './site/appointment/steps/paket-secimi.component';
import { ParcaSecimiComponent } from './site/appointment/steps/parca-secimi.component';
import { ProformaComponent } from './site/appointment/steps/proforma.component';
import { LoginComponent } from './site/appointment/steps/login.component';
import { OdemeComponent } from './site/appointment/steps/odeme.component';
import { IslemComponent } from './site/appointment/steps/islem.component';
import { ServisSecimiComponent } from './site/appointment/steps/servis-secimi.component';
import { MekanikParcaSecimiComponent } from './site/appointment/steps/mekanik-parca-secimi.component';
import { ManuelParcaSecimiComponent } from './site/appointment/steps/manuel-parca-secimi.component';
import { initializeApp } from 'firebase/app';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApolloModule } from 'apollo-angular';
import { KullaniciFormuComponent } from './site/appointment/steps/kullanici-formu.component';
import { OnlinePaymentComponent } from './site/online-payment/online-payment.component';
import { AppointmentV2Component } from './site/appointment-v2/appointment-v2.component';
import { PackageSelectComponent } from './site/appointment-v2/steps/package-select.component';
import { HizmetSecimiV2Component } from './site/appointment-v2/steps/hizmet-secimi-v2.component';
import { AracSecimiV2Component } from './site/appointment-v2/steps/arac-secimi-v2.component';
import { ServisSecimiV2Component } from './site/appointment-v2/steps/servis-secimi-v2.component';
import { GoogleService } from './services/google.service';
import { MapService } from './services/map.service';
import { OdemeV2Component } from './site/appointment-v2/steps/odeme-v2.component';
import { ProformaV2Component } from './site/appointment-v2/steps/proforma-v2.component';
import { OdemeBasariliV2Component } from './site/appointment-v2/steps/odeme-basarili-v2.component';
import { AppointmentV3Component } from './site/appointment-v3/appointment-v3.component';
import { AracHizmetSecimiComponent } from './site/appointment-v3/steps/arac-hizmet-secimi.component';
import { PaketSecimiV3Component } from './site/appointment-v3/steps/paket-secimi-v3.component';
import { ServisSecimiV3Component } from './site/appointment-v3/steps/servis-secimi-v3.component';
import { RandevuOlusturV3Component } from './site/appointment-v3/steps/randevu-olustur-v3.component';
import { OdemeV3Component } from './site/appointment-v3/steps/odeme-v3.component';
import { OdemeBasariliV3Component } from './site/appointment-v3/steps/odeme-basarili-v3.component';
import { OdemesizRandevuV3Component } from './site/appointment-v3/steps/odemesiz-randevu-v3.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { AppointmentV4Component } from './site/appointment-v4/appointment-v4.component';
import { AracHizmetSecimiV4Component } from './site/appointment-v4/steps/arac-hizmet-secimi-v4.component';
import { PaketSecimiV4Component } from './site/appointment-v4/steps/paket-secimi-v4.component';
import { HizmetSecimiV3Component } from './site/appointment-v3/steps/hizmet-secimi-v3.component';
import { RandevusuzKayitV3Component } from './site/appointment-v3/steps/randevusuz-kayit-v3.component';
import { ResizeDirective } from './directives/resize.directive';
import { KartsizOdemeV3Component } from './site/appointment-v3/steps/kartsiz-odeme-v3.component';
import { EkHizmetOdemeComponent } from './site/online-payment/ek-hizmet-odeme.component';
import { AppointmentForAppComponent } from './site/appointment-for-app/appointment-for-app.component';

const firebaseConfig = {
    apiKey: environment.firebaseWebApiKey,
    authDomain: "rs-group-companies.firebaseapp.com",
    databaseURL: "https://rs-group-companies-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "rs-group-companies",
    storageBucket: "rs-group-companies.appspot.com",
    messagingSenderId: "683216378412",
    appId: "1:683216378412:web:7018ffdacd971d77004f89",
    measurementId: "G-YSFFPBV93N"
  }
  const app = initializeApp(firebaseConfig)
  
  @NgModule({
    declarations: [
      AppComponent,
      WelcomeComponent,
      AuthComponent,
      SigninComponent,
      SignupComponent,
      ForgotComponent,
      SiteComponent,
      FooterComponent,
      ToolbarComponent,
      NavbarComponent,
      VehicleServicesListComponent,
      VehicleServicesDetailComponent,
      VehicleListFilterComponent,
      OfferComponent,
      SecurityComponent,
      ConfirmationComponent,
      InformationTextComponent,
      UserAggramentComponent,
      AddressEditComponent,
      AddressAddComponent,
      ProfileEditComponent,
      GsmAddComponent,
      SssComponent,
      AppointmentComponent,
      HizmetSecimiComponent,
      AracSecimiComponent,
      PaketSecimiComponent,
      ParcaSecimiComponent,
      ProformaComponent,
      LoginComponent,
      OdemeComponent,
      IslemComponent,
      ServisSecimiComponent,
      MekanikParcaSecimiComponent,
      ManuelParcaSecimiComponent,
      KullaniciFormuComponent,
      OnlinePaymentComponent,
      AppointmentV2Component,
      PackageSelectComponent,
      HizmetSecimiV2Component,
      AracSecimiV2Component,
      ServisSecimiV2Component,
      OdemeV2Component,
      ProformaV2Component,
      OdemeBasariliV2Component,
      AppointmentV3Component,
      AracHizmetSecimiComponent,
      PaketSecimiV3Component,
      ServisSecimiV3Component,
      RandevuOlusturV3Component,
      OdemeV3Component,
      OdemeBasariliV3Component,
      OdemesizRandevuV3Component,
      TooltipDirective,
      ResizeDirective,
      AppointmentV4Component,
      AracHizmetSecimiV4Component,
      PaketSecimiV4Component,
      HizmetSecimiV3Component,
      RandevusuzKayitV3Component,
      KartsizOdemeV3Component,
      EkHizmetOdemeComponent,
      AppointmentForAppComponent,
       ],
    imports: [
      ApolloModule,
      BrowserModule,
      HttpClientModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      GraphQLModule,
      SharedModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
      }),
      NgbModule
    ],
    providers: [
      AuthService,
      AdminGuard,
      CdnService,
      AnonymousGuard,
      CustomerGuard,
      ServiceGuard,
      SupplierGuard,
      AlertifyService,
      GoogleService,
      MapService,
      SmsService,
      MailService,
      CustomerOfferService,
      PdfService,
      UserService,
      PaymentService,
      PermissionGuard,
      {provide: 'googleTagManagerId',  useValue: 'GTM-5CFR8FZ'},
      { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterCeptor, multi: true }
    ],
    bootstrap: [AppComponent],
  })
  export class AppModule { }
