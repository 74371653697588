import {  Injectable } from '@angular/core';
import { gql, Mutation, Query, Subscription } from 'apollo-angular';
import { ApiTags } from './api_tag';
import { PaymentTypeRoles } from './payment_type_roles.graphql';
import { PermissionProfile } from './permission_profiles.graphql';

export interface User {
  id: Number;
  name: string;
  surname: string;
  email: string;
  role_id: Number;
  identity_no: Number;
  role: {
    subtype: string
    type: string
  }
  active?: boolean
  gsm_id?: bigint
  fax_id?: bigint
  tel_id?: bigint
  address_id?: bigint,
  company_id:bigint,
  garages: any,
  address: string,
  gsms:any,
  tels:any
  user_address:any;
  get_address:any;
  gsm:any;
  company:any;
  img: string;
  permission_profile: PermissionProfile
  job: string
  payment_type_roles: PaymentTypeRoles[]
  tag_id: bigint
  api_tag: ApiTags

}

interface ResponseArray {
  users: User[];
}

interface ResponseSingle {
  users_by_pk: User;
}

@Injectable()
export class USER_ALL extends Query<ResponseArray> {
  override document = gql`
    query USER_ALL($where: users_bool_exp){
      users(where: $where) {
        id
        email
        name
        surname
        role_id,
        company_id,
        job
        img
        tag_id
        api_tag{
          id
          tag
        }
        permission_profile_id
        gsms{
          code
        }
        payment_type_roles(where:{active:{_eq:true}}){
          id
          payment_types{
            id
            name
          }
          roles{
            id
            type
            subtype
          }

        }
      }
    }
  `;
}

@Injectable()
export class USER_GET_ID extends Query<ResponseSingle> {
  override document = gql`
    query USER_GET_ID($id: bigint!){
      users_by_pk(id: $id) {
        id
        email
        name
        surname
        role_id
        gsm_id
        tel_id
        fax_id
        address_id
        permission_profile_id
        address
        api_tag{
          id
          tag
        }
        active
        identity_no,
        img
        job
        company_id
        payment_type_roles(where:{active:{_eq:true}}){
          id
          payment_types{
            id
            name
          }
          roles{
            id
            type
            subtype
          }

        }
        company{
          id
          name
          activation_date

          address
          district_id
          town_id
          city_id
          country_id
          vat_office
          vat
          company_tel{
            id
            code
          }
          company_gsm{
            id
            code
          }
          City{
            id
            name
          }
          district{
            id
            name
          }
          Town{
            id
            name
          }
          country{
            id
            name
          }
          company_email{
            id
            email
          }

        }
        get_address {
          id
          address
          city_id
          country_id
          district_id
          town_id
          city {
            name
          }
          district {
            name
          }
          country {
            id
            name
          }
          town {
            id
            name
          }
        }
        garages {
          id
          name
          vehicles {
            id
            model_id
            plate
            vehicle_model {
              id
              name
            }
            vehicle_brand {
              id
              name
            }
            model_type_id
            vehicle_model_types {
              name
            }
          }
        }
        
        gsms {
          id
          code
        }
        tels {
          id
          code
        }
        gsm{
          id
          code
        }
      }
    }
  `;
}

@Injectable()
export class SERVICE_USER_GET_ID extends Query<ResponseArray> {
  override document = gql`
    query SERVICE_USER_GET_ID($where: users_bool_exp){
      users(where:$where) {
        id
        email
        name
        surname
        role_id
        gsm_id
        tel_id
        fax_id
        address_id
        permission_profile_id
        address
        active
        identity_no,
        img
        api_tag{
          id
          tag
        }
        job
        company_id
        company{
          id
          name
          activation_date

          address
          district_id
          town_id
          city_id
          country_id
          vat_office
          vat
          company_tel{
            id
            code
          }
          company_gsm{
            id
            code
          }
          city{
            id
            name
          }
          district{
            id
            name
          }
          town{
            id
            name
          }
          country{
            id
            name
          }
          company_email{
            id
            email
          }

        }
        get_address {
          id
          address
          city_id
          country_id
          district_id
          town_id
          city {
            name
          }
          district {
            name
          }
          country {
            id
            name
          }
          town {
            id
            name
          }
        }
        garages {
          id
          name
          vehicles {
            id
            model_id
            plate
            vehicle_model {
              id
              name
            }
            vehicle_brand {
              id
              name
            }
            model_type_id
            vehicle_model_types {
              name
            }
          }
        }
        
        gsms {
          id
          code
        }
        tels {
          id
          code
        }
        gsm{
          id
          code
        }
      }
    }
  `;
}

@Injectable()
export class SUB_USER extends Subscription {
  override document = gql`
    subscription {
      personel {
        id
        img
        email
        name
        surname
        api_tag{
          id
          tag
        }
      }
    }
  `;
}

@Injectable()
export class USER_GET_EMAIL extends Query<ResponseArray> {
  override document = gql`
  query USER_GET_EMAIL($email: String!) {
    users(where: {email: {_eq: $email}}, limit: 1) {
      id
      name
      img
      surname
      email
      identity_no
      api_tag{
        id
        tag
      }
      job
      gsms{
        id
        code
      }
      garages {
        id
        name
        vehicles {
          id
          model_id
          plate
          vehicle_model {
            id
            name
          }
          vehicle_brand {
            id
            name
          }
          model_type_id
          vehicle_model_types {
            name
          }
        }
      }
    }
  }`;
}

@Injectable()
export class USER_UPDATE extends Mutation {
  override document = gql`
  mutation USER_UPDATE($id: bigint!, $name: String!, $surname: String!, $identity_no: bigint, $role_id:Int, $gsm_id:bigint,$active: Boolean,$company_id:bigint,$permission_profile_id:Int) {
    update_users(
      where: {id: {_eq: $id}}
      _set: {
        active: $active, 
        identity_no: $identity_no, 
        name: $name, 
        role_id: $role_id, 
        surname: $surname,
        gsm_id: $gsm_id,
        company_id: $company_id,
        permission_profile_id: $permission_profile_id
      }
    ) {
      affected_rows
    }
  }`;
}

@Injectable()
export class PROFILE_UPDATE extends Mutation {
  override document = gql`
  mutation PROFILE_UPDATE($id: bigint!, $name: String!, $surname: String!, $identity_no: bigint, $gsm_id:bigint,$active: Boolean,$company_id:bigint) {
    update_users(
      where: {id: {_eq: $id}}
      _set: {
        active: $active, 
        identity_no: $identity_no, 
        name: $name, 
       
        surname: $surname,
        gsm_id: $gsm_id,
        company_id: $company_id
      }
    ) {
      affected_rows
    }
  }`;
}

@Injectable()
export class USER_PAGINATE extends Query<ResponseArray> {
  override document = gql`
  query USER_PAGINATE($limit: Int!, $offset: Int!, $orderById: order_by = asc, $where: users_bool_exp) {
    users(limit: $limit, offset: $offset,  order_by: {id: $orderById}, where: $where) {
      id
      name
      surname
      img
      email
      role_id
      api_tag{
        id
        tag
      }
      job
      gsm_id
      role {
        subtype
      }
      active
      gsm {
        id
        code
      }
    }
  }`;
}
@Injectable()
export class SERVICE_USER_COUNT extends Query<any> {
  override document = gql`
  query USER_COUNT($where: users_bool_exp) {
    users_aggregate( where: $where) {
      aggregate {
        count
      }
    }
  }`;
}

@Injectable()
export class USER_COUNT extends Query<any> {
  override document = gql`
  query USER_COUNT($active: Boolean = true) {
    users_aggregate( where: {active: {_eq: $active}}) {
      aggregate {
        count
      }
    }
  }`;
}

@Injectable()
export class USER_UPDATE_GSM extends Mutation<ResponseSingle> {
  override document = gql`
  mutation USER_UPDATE_GSM($id: bigint, $gsm_id: bigint!) {
    update_users(where: {id: {_eq: $id}}, _set: { gsm_id: $gsm_id}) {
      affected_rows
    }
  }`;
}

@Injectable()
export class USER_UPDATE_FAX extends Mutation<ResponseSingle> {
  override document = gql`
  mutation USER_UPDATE_FAX($id: Int, $fax_id: bigint!) {
    update_users(where: {id: {_eq: $id}}, _set: { fax_id: $fax_id}) {
      affected_rows
    }
  }`;
}

@Injectable()
export class USER_UPDATE_ADDRESS extends Mutation<ResponseSingle> {
  override document = gql`
  mutation USER_UPDATE_FAX($id: Int, $address_id: bigint!) {
    update_users(where: {id: {_eq: $id}}, _set: { address_id: $address_id}) {
      affected_rows
    }
  }`;
}

@Injectable()
export class USER_UPDATE_IDENTITY extends Mutation<any> {
  override document = gql`
  mutation USER_UPDATE_IDENTITY($id: bigint, $identity_no: bigint!) {
    update_users(where: {id: {_eq: $id}}, _set: { identity_no: $identity_no}) {
      affected_rows
    }
  }`;
}

@Injectable()
export class USER_UPDATE_TEL extends Mutation<any> {
  override document = gql`
  mutation USER_UPDATE_TEL($id: Int, $tel_id: bigint!) {
    update_users(where: {id: {_eq: $id}}, _set: { tel_id: $tel_id}) {
      affected_rows
      returning {
        id
      }
    }
  }`;
}

@Injectable()
export class USER_CREATE extends Mutation<ResponseSingle>{
  override document = gql `
  mutation CreatetUser($name: String, $surname: String, $email: String, $identity_no: bigint,$role_id: Int,$active: Boolean,$job: String) {
    insert_users(objects: {name: $name, surname: $surname, email: $email, identity_no: $identity_no, role_id:$role_id, active:$active, job:$job}) {
      affected_rows
      returning {
        id
      }
    }
  }`
}

@Injectable()
export class USER_UPDATE_BY_PK extends Mutation<ResponseSingle>{
  override document = gql `
  mutation USER_UPDATE_BY_PK(
    $id:bigint!,
    $changes: users_set_input) {
      update_users(where: {id: {_eq: $id}},  _set: $changes) {
    affected_rows
  }
}`

 
}

@Injectable()
export class USER_GET_ROLE extends Query<ResponseSingle> {
  override document = gql` 
  query USER_GET_ROLE($id: bigint!){
    users_by_pk(id: $id) {
      role{
        id
        description
      }
    }
  }`;
}
