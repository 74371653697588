import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface OrderType {
  id: Number;
  name: String;
  position_no?: Number;
  active?: Boolean;
  description?: String;
  icon?: String;
  process_type_id: Number;
  img: String;
  width: number;
  height: number;
  width_unit:string;
  height_unit:string;
  url:string
}

interface ResponseArray {
  order_types: OrderType[];
}

interface ResponseSingle {
  order_types_by_pk: OrderType
}

@Injectable()
export class ORDER_TYPE_ALL extends Query<ResponseArray> {
  override document = gql`
    query ORDER_TYPE_ALL($where: order_types_bool_exp){
      order_types(where: $where,order_by:{position_no:asc}) {
        id
        name
        position_no
        description
        icon
        active
        process_type_id
        img
        url
      }
    }
  `;
}

@Injectable()
export class ORDER_TYPE_ID extends Query<ResponseSingle> {
  override document = gql`
    query ORDER_TYPE_ID($id: Int!){
      order_types_by_pk(id: $id) {
        id
        name
        position_no
        description
        icon
        active
        process_type_id
        img
        url
      }
    }
  `;
}




@Injectable()
export class ORDER_TYPE_CREATE extends Mutation<ResponseSingle> {
  // connected_part_id - int | nullable
  override document = gql`
    mutation ORDER_TYPE_CREATE(
      $name: String!, 
      $position_no: Int,
      $description: String
        $icon: String
      $active: Boolean,
      $process_type_id: Int,
      $img: String
      ) {
      insert_order_types(objects: {
        name: $name, 
        position_no: $position_no,
        description: $description,
        icon: $icon,
        active: $active,
        process_type_id: $process_type_id,
        img: $img
      }) {
        affected_rows
        returning {
          id
          name
        }
      }
    }
  `;
}



@Injectable()
export class ORDER_TYPE_UPDATE extends Mutation<any> {
  override document = gql`
    mutation ORDER_TYPE_UPDATE($id: Int!, $changes: order_types_set_input ) {
      update_order_types(where: {id: {_eq: $id}}, _set: $changes) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}


@Injectable()
export class ORDER_TYPE_DELETE extends Mutation<any> {
  override document = gql`
    mutation ORDER_TYPE_DELETE($id: bigint!) {
      delete_order_types(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_TYPE_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query ORDER_TYPE_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: order_types_bool_exp) {
      order_types(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        name
        description
        icon
        position_no
        active
        process_type_id
        img
      }
    }
  `;
}

@Injectable()
export class ORDER_TYPE_COUNT extends Query<any> {
  override document = gql`
    query ORDER_TYPE_COUNT($where: order_types_bool_exp) {
      order_types_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}