import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './services/guards/admin.guard';
import { AnonymousGuard } from './services/guards/anonymous.guard';
import { CustomerGuard } from './services/guards/customer.guard';
import { ServiceGuard } from './services/guards/service.guard';
import { SupplierGuard } from './services/guards/supplier.guard';
import { AppointmentForAppComponent } from './site/appointment-for-app/appointment-for-app.component';
import { AppointmentV2Component } from './site/appointment-v2/appointment-v2.component';
import { AppointmentV3Component } from './site/appointment-v3/appointment-v3.component';
import { AppointmentV4Component } from './site/appointment-v4/appointment-v4.component';
import { AppointmentComponent } from './site/appointment/appointment.component';
import { AuthComponent } from './site/auth/auth.component';
import { OfferComponent } from './site/offer/offer.component';
import { EkHizmetOdemeComponent } from './site/online-payment/ek-hizmet-odeme.component';
import { OnlinePaymentComponent } from './site/online-payment/online-payment.component';

import { SiteComponent } from './site/site.component';
import { ConfirmationComponent } from './site/static/confirmation.component';
import { InformationTextComponent } from './site/static/information-text.component';
import { SecurityComponent } from './site/static/security.component';
import { SssComponent } from './site/static/sss.component';
import { UserAggramentComponent } from './site/static/user-aggrament.component';
import { VehicleServicesDetailComponent } from './site/vehicle-services/vehicle-services-detail.component';
import { VehicleServicesListComponent } from './site/vehicle-services/vehicle-services-list.component';
import { WelcomeComponent } from './site/welcome/welcome.component';


const routes: Routes = [
    { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [ AdminGuard] },
   { path: 'customer', loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule), canActivate: [ CustomerGuard] },
    { path: 'service', loadChildren: () => import('./service/service.module').then(m => m.ServiceModule), canActivate: [ ServiceGuard] },
    { path: 'supplier', loadChildren: () => import('./supplier/supplier.module').then(m => m.SupplierModule), canActivate: [ SupplierGuard] },
    { path: 'auth', component: AuthComponent },
    { path: '', component: SiteComponent, canActivate:[AnonymousGuard], children:[
      
     
    //  { path: 'garantili-servisler', component: VehicleServicesListComponent },
      { path: 'garantili-servisler/:slug', component: VehicleServicesDetailComponent },
      { path: 'teklif-al', component: OfferComponent, pathMatch: 'full' },
      { path: 'teklif-v2', component: AppointmentComponent, pathMatch: 'full' },
      { path: 'teklif-v1', component: AppointmentV2Component, pathMatch: 'full' },
      { path: 'teklif/:slug', component: AppointmentV3Component, pathMatch: 'full' },
      { path: 'teklif', component: AppointmentV3Component, pathMatch: 'full' },
      { path: 'teklif-v4', component: AppointmentV4Component, pathMatch: 'full' },
      { path: 'gizlilik-sozlesmesi', component: SecurityComponent, pathMatch: 'full' },
      { path: 'acik-riza-metni', component: ConfirmationComponent, pathMatch: 'full' },
      { path: 'aydinlatma-metni', component: InformationTextComponent, pathMatch: 'full' },
      { path: 'kullanici-sozlesmesi', component: UserAggramentComponent, pathMatch: 'full' },
      { path: 'sss', component: SssComponent, pathMatch: 'full' },
      { path: 'siparis-odeme/:track', component: OnlinePaymentComponent, pathMatch: 'full' },
      { path: 'ek-hizmet-odeme/:track', component: EkHizmetOdemeComponent, pathMatch: 'full' },
      { path: 'app', component: AppointmentForAppComponent, pathMatch: 'full' },
      { path: '', component: WelcomeComponent, pathMatch: 'full' },
  
    ]},
    
  
    { path: '**', redirectTo: '' },
  ];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        preloadingStrategy: PreloadAllModules
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
