import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { OrderType, ORDER_TYPE_ALL } from 'src/app/graphql/order_type.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { OrderTypeColumns, ORDER_TYPE_COLUMNS_ALL } from 'src/app/graphql/order_type_columns.graphql';

interface Row {
  id: number;
  count: number;
  width: number;
  height: number;
  width_unit: string;
  height_unit: string;
  start?: number;
  end?: number;
  type: string | undefined;
  columns:any | undefined ;
}


@Component({
  selector: 'app-appointment-v3-hizmet-secimi',
  templateUrl: './hizmet-secimi-v3.component.html',
  styles: [
  ],
  providers:[
    ORDER_TYPE_ALL,
    ORDER_TYPE_COLUMNS_ALL
  ]
})
export class HizmetSecimiV3Component implements OnInit {

  public orderTypes: OrderType[] | undefined;
  public orderTypeColumnList: OrderTypeColumns[] | undefined;
  currentScreenWidth: number;
  rowItems: OrderType[][] = [];

  @Output() orderTypeEmitter = new EventEmitter();
  rows: Row[] = [
    { id: 1, count: 3, width: 100,height: 150, type: "row", width_unit:"%",height_unit:"px", columns:[
      { id: 6, width: 1, height:0, type: "column", width_unit:"%",height_unit:"px",item:[]},
      { id: 7, width: 1.6, height: 150, type: "column", width_unit:"%",height_unit:"px",item:[]},
      { id: 8, width: 2, height: 150, type: "column", width_unit:"%",height_unit:"px",item:[]},
    ]} ,
    { id: 2, count: 1, width: 1,height: 50, type: "row", width_unit:"%",height_unit:"px", columns:[
      { id: 3, width: 1, height:0, type: "column", width_unit:"%",height_unit:"px",item:[]},
     
    ]},
    
    { id: 3, count: 5, width: 1,height: 150, type: "row", width_unit:"%",height_unit:"px", columns:[
      { id: 5, width: 1, height:0, type: "column", width_unit:"%",height_unit:"px",item:[]},
      { id: 9, width: 1, height:0, type: "column", width_unit:"%",height_unit:"px",item:[]},
      { id: 10, width: 1, height:0, type: "column", width_unit:"%",height_unit:"px",item:[]},
      { id: 11, width: 1, height:0, type: "column", width_unit:"%",height_unit:"px",item:[]},
      { id: 12, width: 1, height:0, type: "column", width_unit:"%",height_unit:"px",item:[]}
     
    ]}  
   
  ];

  
  @ViewChildren('hizmetItem') hizmetItems!: QueryList<ElementRef>;

  constructor(
    private authService: AuthService,
    private alertifyService: AlertifyService,
    private getOrderTypeList: ORDER_TYPE_ALL,
    private renderer: Renderer2,
    private orderTypeColumns: ORDER_TYPE_COLUMNS_ALL
  ) { this.currentScreenWidth = window.innerWidth; }

  async ngOnInit(): Promise<void> {
    window.scrollTo(0, 0);
    await this.getOrderTypes();
  
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateScreenWidth();
  }

  updateScreenWidth() {
    this.currentScreenWidth = window.innerWidth;
  }

  setHeight(item:any){
   
  }
 

  getResponsiveRowItems(): OrderType[][] {
    const result: OrderType[][] = [];
    let currentIndex = 0;
   

    this.rows.forEach((row:any,index:number) => {
      const rowItems: OrderType[] = [];

      for (let i = 0; i < row.count; i++) {
        if (this.orderTypes == undefined || currentIndex >= this.orderTypes.length) {
          break;
        }

        

       let width = (this.orderTypes[currentIndex]['width'] ? this.orderTypes[currentIndex]['width'] : 100);
        


      
        rowItems.push(this.orderTypes[currentIndex]);
        
        currentIndex++;
      }

      result.push(rowItems);
    });

    
    
    return result;
  }

  setResponsiveRowItems() {
  

    this.rows.forEach((row:any,index:number) => {
      const rowItems: OrderType[] = [];
      
      row.columns.forEach((element:any, i:number) => {
       
        if(this.orderTypes){
          element.item = this.orderTypes[i];
        }
      });

     
      
    });

    
  }

  async getOrderTypes(){
    await this.getOrderTypeList.watch({where:{active:{_eq:true}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.orderTypes = resp.data.order_types;
        this.getOrderTypeColumns();
        // this.setResponsiveRowItems();
      }
    })
  }
  
  getOrderTypeColumns(){
    this.orderTypeColumns.watch({where:{type:{_eq:1}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }

      if(resp.data){
        this.orderTypeColumnList = resp.data.order_type_columns;
      }
    })
  }

  setOrderType(event:Number,slug:String = ''){
    let ret = {
      orderTypeId: event,
      slug: slug
    }
    this.orderTypeEmitter.emit(ret);
  }
}
