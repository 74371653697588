import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { resolve } from 'dns';
import moment from 'moment';
import { FORM_STEPS_ALL } from 'src/app/graphql/form_steps.graphql';
import { FormStepParts, FORM_STEP_PARTS_ALL } from 'src/app/graphql/form_step_parts.graphql';
import { GARAGE_CREATE } from 'src/app/graphql/garages.graphql';
import { Order, ORDER_CREATE, ORDER_ID, ORDER_UPDATE } from 'src/app/graphql/order.graphql';
import { ORDER_CARDS_ALL, ORDER_CARDS_CREATE, OrderCards, OrderCardInsert, ORDER_CARDS_UPDATE, ORDER_CARD_PAYMENT_SUBS } from 'src/app/graphql/order_cards.graphql';
import { ORDER_CARD_INVOICE_INFO_CREATE } from 'src/app/graphql/order_card_invoice_info.graphql';
import { ORDER_CARD_PARTS_CREATE, ORDER_CARD_PARTS_DELETE } from 'src/app/graphql/order_card_parts.graphql';
import { ORDER_INVOICE_CREATE } from 'src/app/graphql/order_invoices.graphql';
import { ORDER_INVOICE_INFO_CREATE } from 'src/app/graphql/order_invoice_info.graphql';
import { ORDER_INVOICE_PART_CREATE } from 'src/app/graphql/order_invoice_parts.graphql';
import { ORDER_PART_CREATE } from 'src/app/graphql/order_part.graphql';
import { VEHICLE_CREATE, VEHICLE_CREATE_APPOINTMENT_FORM } from 'src/app/graphql/vehicle.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { MailService } from 'src/app/services/mail.service';
import { SmsService } from 'src/app/services/sms.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styles: [
  ],
  styleUrls:['./appointment.component.css'],
  providers:[ORDER_CARDS_ALL,ORDER_CARDS_CREATE,ORDER_CARDS_UPDATE,
    ORDER_CREATE,
    GARAGE_CREATE,
    ORDER_UPDATE,
    ORDER_PART_CREATE,
    ORDER_INVOICE_INFO_CREATE,
    ORDER_ID,
    ORDER_INVOICE_PART_CREATE,
    ORDER_INVOICE_CREATE,
    VEHICLE_CREATE_APPOINTMENT_FORM,
    ORDER_CARD_PAYMENT_SUBS,FORM_STEPS_ALL,FORM_STEP_PARTS_ALL,ORDER_CARD_PARTS_DELETE,ORDER_CARD_PARTS_CREATE,
    ORDER_CARD_INVOICE_INFO_CREATE]
})
export class AppointmentComponent implements OnInit {

  public step: number = 1;
  public leftWidth:number = 0;
  public totalElm = 4;
  public isStepCompleted:boolean = false;
  public vehicleModelType: Number | undefined;
  public userUuid: String | null | undefined;
  public orderCard: OrderCards | undefined;
  public insertedCard: OrderCardInsert | undefined;
  public orderCardId: bigint | undefined;
  public selecteMaintenanceKmsId: Number | undefined;
  public selecteMaintenanceKmsName: string = "Km Seçiniz";
  public stepParts: FormStepParts[] | undefined;
  public selectedPackageInfo:any;
  public selectedPieces: any;
  public priceInfo:any;
  public orderId:bigint | undefined;
  public userInfo: any;
  public canGoToPrev: boolean = true;
  public orderDetail: Order | undefined;
  public orderCardInvoiceInfoId: bigint | undefined;


  public orderTypeId: Number | undefined;

  public vehicleForm = {
    name:"",
    brand_id: 0,
    model_id: 0,
    model_type_id: 0,
    model_year_id: 0,
    garage_id:0
  }

  public appointmentFormGroup = new FormGroup({
    serviceTypeId: new FormControl(1, [Validators.required]),
    modelTypeId: new FormControl('',Validators.required),
    maintenanceKmsId: new FormControl('',Validators.required),
    maintenancePackageId: new FormControl('',Validators.required)
  });

  public vehicleId: bigint | undefined

  public stepList:any = [];
  public showNextBtn:boolean = true;

 /* public stepList = [
    {title: 'Hizmet Tipi', order: 1, isCompleted: false,service_name: 'app-appointment-hizmet-secimi',id:1},
    {title: 'Araç Seçimi', order: 2, isCompleted: false,service_name: 'app-appointment-arac-secimi',id:2},
    {title: 'Paket Seçimi', order: 3, isCompleted: false,service_name: 'app-appointment-paket-secimi',id:3},
    {title: 'Parça Seçimi', order: 4, isCompleted: false,service_name: 'app-appointment-parca-secimi',id:4},
    {title: 'Servis Seçimi', order: 5, isCompleted: false,service_name: 'app-appointment-servis-secimi',id:5},
    {title: 'Ödeme', order: 6, isCompleted: false,service_name: 'app-appointment-odeme',id:6},
    {title: 'Login', order: 7, isCompleted: false,service_name: 'app-appointment-login',id:7},
    {title: 'İşlem Başarılı', order: 8, isCompleted: false,service_name: 'app-appointment-islem',id:8}
  ];*/
  

  constructor(
    public authService: AuthService,
    public orderCardInsert: ORDER_CARDS_CREATE,
    public checkOrderCard: ORDER_CARDS_ALL,
    public orderCardUpdate: ORDER_CARDS_UPDATE,
    public deleteOrderCardParts: ORDER_CARD_PARTS_DELETE,
    public createOrderCardParts: ORDER_CARD_PARTS_CREATE,
    private createOrderCardInvoiceInfo: ORDER_CARD_INVOICE_INFO_CREATE,
    private alertifyService: AlertifyService,
    private getFormSteps: FORM_STEP_PARTS_ALL,
    private createOrder: ORDER_CREATE,
    private createOrderpart: ORDER_PART_CREATE,
    private createGarage: GARAGE_CREATE,
    private createVehicle: VEHICLE_CREATE_APPOINTMENT_FORM,
    private updateOrder: ORDER_UPDATE,
    private getOrder: ORDER_ID,
    private createOrderInvoicePart: ORDER_INVOICE_PART_CREATE,
    private createOrderInvoice: ORDER_INVOICE_CREATE,
    private createInvoiceInfo: ORDER_INVOICE_INFO_CREATE,
    private smsService: SmsService,
    private mailService: MailService

  ) { 
    
   }

    async ngOnInit(){

      

      if(this.orderTypeId == undefined){
        this.getStepList();
      }


   
  
  }




  getStepList(){
    this.getFormSteps.watch({where:{form_step_id: {_eq:1}, order_type_id: {_eq: this.orderTypeId}}}).valueChanges.subscribe( ret => {
      if(ret.errors){
        this.alertifyService.error(ret.errors[0].message);
      }
  
      if(ret.data){
        this.stepParts = ret.data.form_step_parts;
        this.stepList = [];
        this.stepParts.forEach(element => {
          
            this.stepList.push({step_title: element.step_title, position: element.position, isCompleted: false,service_name: element.service_name,id:element.id});
       
        });
  
         this.setOrderCard(true);
  
      }
  
    });

 
  }



 

  stepChange(direct:String){
    switch(direct){
      case "next":
        this.isStepCompleted = false;
        this.stepList[(this.step-1)].is_completed = true;

        this.step  += 1;

        if(this.step > 2 && this.stepList.length != this.step ){
        
          this.leftWidth -= 180;
        }

      break;

      case "previous":
      
        this.stepList[(this.step-1)].isCompleted = false;
        if(this.step >= 5){
          this.canGoToPrev = false;
        }
        this.step -= 1;
        if(this.step > 1 && (this.stepList.length - 1) != this.step){
      
          this.leftWidth = this.leftWidth+180;
        
        }
        

      break;
    }

    if(this.step > this.stepList.length){
      this.step = this.stepList.length;
    }

    if(this.step < 1){
      this.step = 1;
      this.leftWidth = 0;
    }

  }

   setStep(){
    if(this.orderCard && this.orderCard.step > 0){
      this.appointmentFormGroup.patchValue({
        serviceTypeId: this.orderCard.type_id,
        modelTypeId: this.orderCard.vehicle_model_type_id,
        maintenancePackageId: this.orderCard.maintenance_package_id
      });
      this.vehicleModelType = this.orderCard.vehicle_model_types?.vehicle_model_year.vehicle_model.id;
      this.vehicleForm = {
        name: (this.orderCard.vehicle?.garages) ? this.orderCard.vehicle.garages.name : "",
        brand_id: this.orderCard.vehicle_model_types?.vehicle_model_year.vehicle_model.vehicle_brand.id,
        model_id: this.orderCard.vehicle_model_types?.vehicle_model_year.vehicle_model.id,
        model_type_id: Number(this.orderCard.vehicle_model_types?.id),
        model_year_id: this.orderCard.vehicle_model_types?.vehicle_model_year.id,
        garage_id: (this.orderCard.vehicle?.garage_id) ? this.orderCard.vehicle.garage_id: 0
      }

   
      

      this.selecteMaintenanceKmsId = this.orderCard.maintenance_kms_id;
      let ths = this;
      setTimeout(()=>{
        if(ths.orderCard){
        this.step = ths.orderCard.step;
        }

      },1000)
  
    
    }
  }

  setStepVariable($event:any){
   
      let stepName = this.stepList[(this.step-1)].service_name;
     
      switch(stepName){
        case "app-appointment-hizmet-secimi":
          if(parseInt($event) > 0){
            let oldTypeId = this.orderTypeId
            this.orderTypeId = $event;
            if(oldTypeId != $event){
              this.getStepList();
            }
          
            this.isStepCompleted = true;
            
            this.appointmentFormGroup.patchValue({
              serviceTypeId: this.orderTypeId
            });

            this.updateCard({
              type_id: this.orderTypeId,
              step: this.stepList[this.step].id,
            });

            this.deleteCardParts();
          }
          
        break;

        case "app-appointment-arac-secimi":
      
          if($event.model_type_id != this.vehicleModelType){
            this.appointmentFormGroup.patchValue({
              maintenanceKmsId: 0,
              maintenancePackageId: 0
            })

            this.selecteMaintenanceKmsId = 0;
            this.selectedPackageInfo = null;
            this.selecteMaintenanceKmsName = "Km Seçiniz";
          }
          this.vehicleModelType = $event.model_type_id;
       
          this.appointmentFormGroup.patchValue({
            modelTypeId: this.vehicleModelType
          });

          this.vehicleForm = $event;

       
          this.updateCard({
            vehicle_model_type_id: this.vehicleModelType,
            step: this.stepList[this.step].id,
          });

          this.deleteCardParts();

          this.isStepCompleted = $event.isStepCompleted;
        break;

        case "app-appointment-paket-secimi":
          

          this.appointmentFormGroup.patchValue({
            maintenanceKmsId: $event.selectedKmsId,
            maintenancePackageId: $event.selectedPackageId
          });


          this.selecteMaintenanceKmsId = $event.selectedKmsId;
          this.selectedPackageInfo = $event.selectedPackageInfo;
          this.selecteMaintenanceKmsName = $event.selectedKmsName;

          this.updateCard({
            maintenance_kms_id: this.selecteMaintenanceKmsId,
            maintenance_package_id: $event.selectedPackageId,
            step: this.stepList[this.step].id,
          });

          this.deleteCardParts();
         
          if(this.appointmentFormGroup.value.maintenancePackageId){
            this.isStepCompleted = true;
          }

        break;

        case "app-appointment-parca-secimi":
          this.isStepCompleted = false;
          this.selectedPieces = $event.selectedPieces;
          this.deleteCardParts().then(()=>{

              this.selectedPieces.forEach((item:any)=>{
                this.createOrderCardParts.mutate({object:{
                  order_card_id: this.orderCard?.id,
                  part_id: item.selectedGosPart_id,
                  labour_type_id: 1,
                  labour_value: item.gos_labour_price,
                  labour_discount: 0,
                  part_type_id: item.part_type_id,
                  part_discount: 0,
                  selected_part_id: item.selected_part_id,
                  service_value: 0,
                  brand_labour_price: item.brand_labour_price,
                  gos_labour_price: item.gos_labour_price,
                  price_unit_id: item.price_unit_id,
                  price_without_currency: item.total_price,
                  part_unit_type_id: item.part_unit_type,
                  part_unit: item.part_unit,
                  part_code: item.part_code,
                  part_no_vat_price: item.total_price_no_vat,
                  part_name: item.selected_equivalent_name,
                  package_detail_id: item.package_detail_id,
                  part_in_vat_price:  item.total_price

                }}).subscribe((rep:any)=>{
                  if(rep.errors){
                    this.alertifyService.error(rep.errors[0].message);
                  }

                  if(rep.data){

                  }
                })
              })
          });
     
         
         
          this.priceInfo = {
            totalAmount: $event.totalAmount,
            subTotal: $event.subTotal,
            vat: $event.vat,
            discount: $event.discount
          }

          this.isStepCompleted = true;
        break;

        case "app-appointment-odeme":
          if($event.orderCreateable){

            this.userInfo = $event.userInfo;

            if($event.userInfo.garages == undefined  || $event.userInfo.garages.length == 0){

              this.createGarage.mutate({name:"Garajım",user_id: $event.userInfo.id,active:true}).subscribe((garageRet:any)=>{

                if(garageRet.errors){
                  this.alertifyService.error(garageRet.errors[0].message);
                }

                if(garageRet.data){

                  this.vehicleForm.garage_id = garageRet.data.insert_garages.returning[0].id;

                  this.createVehicle.mutate({
                    brand_id: this.vehicleForm.brand_id,
                    model_id:this.vehicleForm.model_id,
                    model_year_id: this.vehicleForm.model_year_id,
                    model_type_id: this.vehicleForm.model_type_id,
                    garage_id: this.vehicleForm.garage_id
                  }).subscribe((vehicleRet:any)=>{
                    
                    if(vehicleRet.errors){
                      this.alertifyService.error(vehicleRet.errors[0].message);
                    }

                    if(vehicleRet.data){
                      this.vehicleId = vehicleRet.data.insert_vehicles.returning[0].id;

                      this.updateCard({vehicle_id: this.vehicleId});
                      this.createOrders($event);
                    }

                  })
                }

              })

            }else{

              
              $event.userInfo.garages.forEach((garageItem:any) => {
                
                garageItem.vehicles.forEach((vehicleItems:any) => {
                  if(vehicleItems.model_type_id == this.vehicleForm.model_type_id){
                    this.vehicleId = vehicleItems.id
                    this.updateCard({vehicle_id: this.vehicleId});
                    this.createOrders($event);
                  }
                });

                
                
              });

              if(this.vehicleId == undefined){
                this.createVehicle.mutate({
                  brand_id: this.vehicleForm.brand_id,
                  model_id:this.vehicleForm.model_id,
                  model_year_id: this.vehicleForm.model_year_id,
                  model_type_id: this.vehicleForm.model_type_id,
                  garage_id: $event.userInfo.garages[0].id
                }).subscribe((vehicleRet:any)=>{
                  
                  if(vehicleRet.errors){
                    this.alertifyService.error(vehicleRet.errors[0].message);
                  }

                  if(vehicleRet.data){
                    this.vehicleId = vehicleRet.data.insert_vehicles.returning[0].id;

                    this.updateCard({vehicle_id: this.vehicleId});

                    this.createOrders($event);
                  }

                })
              }

            }


           

          }

        break;

        case "app-appointment-servis-secimi": 
        
          this.updateCard({service_id:$event.service_id,service_date: $event.service_date});
          this.updateOrder.mutate({id:this.orderId,changes:{service_id: $event.service_id,service_date: $event.service_date}});

          this.updateOrder.mutate({id:this.orderId, changes: { service_id: $event.service_id, service_date:$event.service_date }}).subscribe(resp=>{

          });

          this.isStepCompleted = true;
        break;

        case "A":

          this.appointmentFormGroup.patchValue({
            maintenanceKmsId: $event.selectedKmsId,
            maintenancePackageId: $event.selectedPackageId
          });


          this.selecteMaintenanceKmsId = $event.selectedKmsId;
          this.selectedPackageInfo = $event.selectedPackageInfo;
          this.selecteMaintenanceKmsName = $event.selectedKmsName;

          this.updateCard({
            maintenance_kms_id: this.selecteMaintenanceKmsId,
            maintenance_package_id: $event.selectedPackageId,
            step: this.stepList[this.step].id,
          });

          this.deleteCardParts();
         
          if(this.appointmentFormGroup.value.maintenancePackageId){
            this.isStepCompleted = true;
          }

        break;

        case "app-appointment-mekanik-parca-secimi":
       
          this.isStepCompleted = false;
          this.selectedPieces = $event.selectedPieces;
          this.deleteCardParts().then(()=>{

              this.selectedPieces.forEach((item:any)=>{
                this.createOrderCardParts.mutate({object:{
                  order_card_id: this.orderCard?.id,
                  part_id: item.selectedGosPart_id,
                  labour_type_id: 1,
                  labour_value: item.gos_labour_price,
                  labour_discount: 0,
                  part_type_id: item.part_type_id,
                  part_discount: 0,
                  selected_part_id: item.selected_part_id,
                  service_value: 0,
                  brand_labour_price: item.brand_labour_price,
                  gos_labour_price: item.gos_labour_price,
                  price_unit_id: item.price_unit_id,
                  price_without_currency: item.total_price,
                  part_unit_type_id: item.part_unit_type,
                  part_unit: item.part_unit,
                  part_code: item.part_code,
                  part_no_vat_price: item.total_price_no_vat,
                  part_name: item.selected_equivalent_name,
                  package_detail_id: item.package_detail_id,
                  part_in_vat_price:  item.total_price

                }}).subscribe((rep:any)=>{
                  if(rep.errors){
                    this.alertifyService.error(rep.errors[0].message);
                  }

                  if(rep.data){

                  }
                })
              })
          });
     
         
         
          this.priceInfo = {
            totalAmount: $event.totalAmount,
            subTotal: $event.subTotal,
            vat: $event.vat,
            discount: $event.discount
          }

          this.isStepCompleted = true;
        break;

        case "app-appointment-manuel-parca-secimi":
     
          this.isStepCompleted = false;
          this.selectedPieces = $event.selectedPieces;
          this.deleteCardParts().then(()=>{

              this.selectedPieces.forEach((item:any)=>{
                this.createOrderCardParts.mutate({object:{
                  order_card_id: this.orderCard?.id,
                  part_id: item.selectedGosPart_id,
                  labour_type_id: 1,
                  labour_value: item.gos_labour_price,
                  labour_discount: 0,
                  part_type_id: item.part_type_id,
                  part_discount: 0,
                  selected_part_id: item.selected_part_id,
                  service_value: item.service_value,
                  brand_labour_price: item.brand_labour_price,
                  gos_labour_price: item.gos_labour_price,
                  price_unit_id: item.price_unit_id,
                  price_without_currency: item.total_price,
                  part_unit_type_id: item.part_unit_type,
                  part_unit: item.part_unit,
                  part_code: item.part_code,
                  part_no_vat_price: item.total_price_no_vat,
                  part_name: item.selected_equivalent_name,
                  package_detail_id: item.package_detail_id,
                  part_in_vat_price:  item.total_price

                }}).subscribe((rep:any)=>{
                  if(rep.errors){
                    this.alertifyService.error(rep.errors[0].message);
                  }

                  if(rep.data){

                  }
                })
              })
          });
     
         
         
          this.priceInfo = {
            totalAmount: $event.totalAmount,
            subTotal: $event.subTotal,
            vat: $event.vat,
            discount: $event.discount
          }

          this.isStepCompleted = true;
        break;
        
        
      }

    
  }

  async createOrders($event:any){


    let infoObj:any = {
      order_id:this.orderId,
      user_id: $event.userInfo.id,
      customer_name: $event.invoiceInfo.firstname,
      customer_surname: $event.invoiceInfo.lastname,
      customer_email: $event.userInfo.email,
      identity_no: null,
      tax_number: null,
      tax_administration: null,
      gsm_number: null,
      tel_number:null,
    //     is_same_with_customer: this.invoiceInfo.is_same_with_customer,
      invoice_type: 3,
      address: $event.invoiceInfo.address,
      city_id: $event.invoiceInfo.city_id,
      town_id: $event.invoiceInfo.town_id,
      district_id: $event.invoiceInfo.district_id,
      country_id: $event.invoiceInfo.country_id,
      company_name: null,
      customer_invoice_type: 1
    }

    if($event.invoiceInfo.isCompany){
                
      infoObj.tax_number = $event.invoiceInfo.tax_number;
      infoObj.tax_administration = $event.invoiceInfo.tax_administration;
      infoObj.company_name = $event.invoiceInfo.company_name;
      if(this.userInfo?.company_id != undefined){
        infoObj.company_id = this.userInfo?.company_id;

      }
      infoObj.tel_number = $event.invoiceInfo.tel_number;
    }else{
      infoObj.gsm_number = $event.invoiceInfo.phone;
      infoObj.identity_no = $event.invoiceInfo.identity_no;
    }

    switch(parseInt($event.paymentType)){
      case 1: //kredi kartı
      if(this.orderTypeId && this.orderTypeId > 0 &&  this.selectedPieces.length > 0){
        let Obj = {
          status_id: 1,
          type_id: this.orderTypeId,
          customer_id: $event.userInfo.id,
          order_value: this.priceInfo.totalAmount.toFixed(2),
          order_no_vat_value: this.priceInfo.subTotal.toFixed(2),
          service_value: 0,
          maintenance_package_id: (this.orderTypeId == 1) ? this.selectedPackageInfo.id : 0,
          customer_status_id: 14, //müşteri onayı verildi,
          payment_type: $event.paymentType,
          is_payed: $event.userInfo.isPayed,
          vehicle_id: this.vehicleId,
          customer_address_id: $event.userInfo.address_id,
          customer_gsm_id: $event.userInfo.gsm_id,
          invoice_firstname: $event.invoiceInfo.firstname,
          invoice_lastname: $event.invoiceInfo.lastname,
          invoice_identity_number: $event.invoiceInfo.identity_no
        }



       await this.createOrder.mutate(Obj).subscribe(async (result:any)=>{
          if(result.errors){
            this.alertifyService.error("Kayıt esnasında hata oluştu");
          }

          if(result.data){
            let insertedId = result.data.insert_orders.returning[0].id;
            this.orderId = insertedId;
            let i = 1;
            let invoiceCreatable = true;
            infoObj.order_id = this.orderId;
            
            let sendsms =  this.sendSmsService('Siparişiniz Oluşturuldu',this.orderId+" numaralı randevunuz oluşturulmuştur.", [$event.userInfo.phone]);

            let message = insertedId+" numaralı randevu " + this.userInfo.name + " " + this.userInfo.surname + " adına oluşturulmuştur.";

            this.mailService.send(environment.garantiliEmail,'Yeni Randevu Oluşturuldu.',message).subscribe(mailResponse => {
 
              //if (this.order.customer.user_phones.length) {
              //}

              if(mailResponse.success){
                // this.alertifyService.success("Mail Gönderildi");
               }

             });

            if($event.userInfo.isPayed){

  
             await this.createInvoiceInfo.mutate({object:infoObj}).subscribe((infoResult:any) => {
                    
                if(infoResult.errors){
                  
                  this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
                }
                if(infoResult.data){
                  let insertedId = infoResult.data.insert_order_invoice_info_one.id;
                 /* this.updateOrder.mutate({id:this.orderId,changes:{ invoice_info_id: insertedId }}).subscribe((e:any)=>{
                    if(e.errors){
                      this.alertifyService.error(e.errors[0].message);
                    }
                  });*/
                }
  
              });
  


            }



           await this.selectedPieces.filter(async (obj:any) => {

             await this.createOrderpart.mutate(
                {
                
                  order_id:insertedId,
                  part_id:obj.part_id,
                  part_type_id:obj.part_type_id,
                  part_value:obj.part_value,
                  selected_part_id:obj.selected_part_id,
                  service_value:0,
                  part_unit_type_id:obj.part_unit_type,
                  part_unit:obj.part_unit,
                  brand_labour_price: obj.brand_labour_price,
                  gos_labour_price: obj.gos_labour_price,
                  price_unit_id:obj.price_unit_id,
                  currency_unit_price: obj.currency_unit_price,
                  currency_total_price: obj.currency_total_price,
                  currency: obj.currency,
                  total_price_no_vat: obj.total_price_no_vat,
                  total_price: obj.total_price,
                  created_at: moment().format('yyyy-MM-DDTH:mm:ssZ'),
                  part_code: obj.part_code,
                  part_no_vat_price: obj.price_no_vat
                  
                }).subscribe((partTypeResult:any) => {
                if(partTypeResult.errors){
                  this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
                }
                if(partTypeResult.data){
                  //this.step = this.steps.siparisi_tamamla.id;
                  i++;
                  if(i>=this.selectedPieces.length && invoiceCreatable && $event.userInfo.isPayed){
                    this.createInvoice();
                    invoiceCreatable = false;
                  }

              //    this.step =step;
                }
              });

              this.updateCard({
                step:5,
                customer_id: this.userInfo.id,
                order_value:this.priceInfo.totalAmount.toFixed(2),
                order_no_vat_value: this.priceInfo.subTotal.toFixed(2),
                main_order_id: this.orderId,
                is_complete: true
              });


            });

            this.alertifyService.success("siparişiniz oluşturuldu");
            this.stepChange('next');

           
           

            
          }

        });


      }
      break;

      case 2:
        if(this.orderTypeId && this.orderTypeId > 0 &&  this.selectedPieces.length > 0){
      
          let Obj = {
            status_id: 1,
            type_id: this.orderTypeId,
            customer_id: $event.userInfo.id,
            order_value: this.priceInfo.totalAmount.toFixed(2),
            order_no_vat_value: this.priceInfo.subTotal.toFixed(2),
            service_value: 0,
            maintenance_package_id: (this.orderTypeId == 1) ? this.selectedPackageInfo.id : 0,
            customer_status_id: 14, //müşteri onayı verildi,
            payment_type: $event.paymentType,
            is_payed: false,
            vehicle_id: this.vehicleId,
            customer_address_id: $event.userInfo.address_id,
            customer_gsm_id: $event.userInfo.gsm_id,
            invoice_firstname: $event.invoiceInfo.firstname,
            invoice_lastname: $event.invoiceInfo.lastname,
            invoice_identity_number: $event.invoiceInfo.identity_no
          }


          this.createOrder.mutate(Obj).subscribe((result:any)=>{
            if(result.errors){
              this.alertifyService.error("Kayıt esnasında hata oluştu");
            }
  
            if(result.data){
              let insertedId = result.data.insert_orders.returning[0].id;
              this.orderId = insertedId;

              infoObj.order_id = this.orderId;

              let sendsms =  this.sendSmsService('Siparişiniz Oluşturuldu',this.orderId+" numaralı randevunuz oluşturulmuştur.", [$event.userInfo.phone]);

              this.selectedPieces.filter((obj:any) => {

                this.createOrderpart.mutate(
                  {
                  
                    order_id:insertedId,
                    part_id:obj.part_id,
                    part_type_id:obj.part_type_id,
                    part_value:obj.part_value,
                    selected_part_id:obj.selected_part_id,
                    service_value:0,
                    part_unit_type_id:obj.part_unit_type,
                    part_unit:obj.part_unit,
                    brand_labour_price: obj.brand_labour_price,
                    gos_labour_price: obj.gos_labour_price,
                    price_unit_id:obj.price_unit_id,
                    currency_unit_price: obj.currency_unit_price,
                    currency_total_price: obj.currency_total_price,
                    currency: obj.currency,
                    total_price_no_vat: obj.total_price_no_vat,
                    total_price: obj.total_price,
                    created_at: moment().format('yyyy-MM-DDTH:mm:ssZ'),
                    part_code: obj.part_code,
                    part_no_vat_price: obj.price_no_vat
                    
                  }).subscribe((partTypeResult:any) => {
                  if(partTypeResult.errors){
                    this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
                  }
                  if(partTypeResult.data){
                    //this.step = this.steps.siparisi_tamamla.id;
                //    this.step =step;
                  }
                });

                this.updateCard({
                  step:5,
                  customer_id: this.userInfo.id,
                  order_value:this.priceInfo.totalAmount.toFixed(2),
                  order_no_vat_value: this.priceInfo.subTotal.toFixed(2),
                  main_order_id: this.orderId,
                  is_complete: true
                });
  
              });
             
              

              
              this.createInvoiceInfo.mutate({object:infoObj}).subscribe((infoResult:any) => {
                    
                if(infoResult.errors){
                  
                  this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
                }
                if(infoResult.data){
                  let insertedId = infoResult.data.insert_order_invoice_info_one.id;
                 /* this.updateOrder.mutate({id:this.orderId,changes:{ invoice_info_id: insertedId }}).subscribe((e:any)=>{
                    if(e.errors){
                      this.alertifyService.error(e.errors[0].message);
                    }
                  });*/
                }
  
              });

              
             
              this.alertifyService.success("siparişiniz oluşturuldu");
              this.stepChange('next');

              
            }
  
          });


        }


      break;
    }

  }

  sendSmsService(title: String, content: String, phones: any[]) {
    this.smsService.send(title, content, phones).subscribe(response => {

      if (response.status) {
        // this.alertifyService.success("Sms Gönderildi")
        // Gönderilen smsler için kayıt işlemi olacaksa burada yapılacak
      }
    })
  }

  async createInvoice(){
    this.getOrder.watch({id:this.orderId},{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any) => {

      if(result.errors){
        this.alertifyService.error(result.errors[0].message);
        return;
      }

      if(result.data){
        this.orderDetail = result.data.orders_by_pk;

        if(this.orderDetail != undefined){
          this.createOrderInvoice.mutate({
            object:{
            "order_id":this.orderDetail.id,
            "date":moment().format("YYYY-MM-DDTHH:mm:ssZ"),
            "value_in_vat":this.orderDetail.order_value,
            "value_no_vat":this.orderDetail.order_no_vat_value,
            "vat": ( this.orderDetail.order_value - this.orderDetail.order_no_vat_value ),
            "discount":0,
            "url":"",
            "invoice_type_id":this.orderDetail.type_id,
            "valor_date":moment().format("YYYY-MM-DDTHH:mm:ssZ"),
            "invoice_status_id":6, // TODO buraya bakılacak
            "paid_value": this.orderDetail.order_value, // TODO buraya bakılacak
            "remaining_value":0, // TODO buraya bakılacak
            "note":"",
            "invoice_reciever_id":this.orderDetail.customer_id,
           // "invoice_reciever_type_id": this.orderDetail.customer_info.role_id
          
          }
          }).subscribe((result:any) => {
            if(result.errors){
              this.alertifyService.error(result.errors[0].message);
              
            }

            if(result.data){

              this.updateOrder.mutate({"id":this.orderId,"changes":{invoice_id:result.data.insert_order_invoices_one.id}}).subscribe((upresult:any) => {
                if(upresult.errors){
                  this.alertifyService.error("Güncelleme esnasında bir sorun oluştu!");
                }
                if(upresult.data){
                  this.alertifyService.success("siparişiniz güncellendi");
                }
              })

             
              this.orderDetail?.order_parts.forEach((element:any) => {

                this.createOrderInvoicePart.mutate({

                  

                  object:{
                  "invoice_id":result.data.insert_order_invoices_one.id,
                  "part_id": element.id,
                  "part_name": element.parts.name,
                  "part_code":  (element.part_type_id == 1 || element.part_type_id == 5) ?  element.part_original_list.part_code  : element.part_equivalent_list[0].part_code,
                  "labour_type_id": element.labour_type_id,
                  "labour_value": element.labour_value,
                  "labour_discount": element.labour_discount,
                  "part_in_vat_price": element.part_value,
                  "part_no_vat_price": (element.part_no_vat_price && element.part_no_vat_price != null) ? element.part_no_vat_price : 0,
                  "invoice_type":1, // TODO buraya bakılacak
                  "part_unit_type_id": element.part_unit_type_id,
                  "part_unit": element.part_unit
                 
                }
  
                }).subscribe((partResult:any) => {
                  if(partResult.errors){
                    this.alertifyService.error(partResult.errors[0].message);
                  }
                 if(partResult.data){
                 
                 }
                });
              });

            
              this.alertifyService.success("Fatura oluşturuldu");
            }
          })
        }
      }
    });
       
            
            
        
    
  }

  async getOrderCard(){

    return new Promise((resolve,reject)=>{
      this.checkOrderCard.watch({where:{card_uuid:{_eq:this.userUuid},is_complete:{_eq:false}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(ret=>{
        if(ret.errors){
          this.alertifyService.error(ret.errors[0].message);
          resolve(false);
        }

        if(ret.data){
          if(ret.data.order_cards.length > 0){
            this.orderCard = ret.data.order_cards[0];
            localStorage.setItem("user_uuid",this.orderCard.card_uuid);
              
            resolve(true);
          }else{
            resolve(false);
          }
        }
      });
    });
  }

  async createOrderCard(){
    return new Promise((resolve,reject)=>{
      this.orderCardInsert.mutate({
        object:{step: 1}
      }).subscribe((ret:any)=>{
        if(ret.errors){
          this.alertifyService.error(ret.errors[0].message);
          resolve(false);
        }

        if(ret.data){
          if(ret.data.insert_order_cards_one.card_uuid){

            localStorage.setItem("user_uuid",ret.data.insert_order_cards_one.card_uuid);
            this.userUuid = ret.data.insert_order_cards_one.card_uuid;
            this.orderCardId = ret.data.insert_order_cards_one.id;

            this.createOrderCardInvoiceInfo.mutate({object: { order_card_id:this.orderCardId,customer_invoice_type: 1, invoice_type: 3 } }).subscribe(orderCardInvoiceInfoResponse => {
              if(orderCardInvoiceInfoResponse.errors){
                this.alertifyService.error(orderCardInvoiceInfoResponse.errors[0].message);
              }
              if(orderCardInvoiceInfoResponse.data?.order_card_invoice_info_by_pk){
                this.orderCardInvoiceInfoId = orderCardInvoiceInfoResponse.data.returning[0].id;
              }

            })

           // this.setOrderCard();


            //this.setOrderCard();
            resolve(true);
          }
         
        }
      })
    })
  }

  async setOrderCard(setStep=false){

    return new Promise(async (resolve,reject)=>{
      this.userUuid = localStorage.getItem('user_uuid');
      if(this.userUuid && this.userUuid != null){

        await this.getOrderCard().then(async ()=>{
     
          if(this.orderCard && setStep){
          //  this.setStep();
         }
          if(this.orderCard == undefined){
            
            await this.createOrderCard().then(async ()=>{
              resolve(true);
             
            })
          }
        })

      }else{

        if( this.authService.isLogin){
          this.checkOrderCard.watch({where:{user_id:{_eq:this.authService.userId},is_complete:false}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(async ret=>{
            if(ret.errors){
              this.alertifyService.error(ret.errors[0].message);
            }

            if(ret.data){
              if(ret.data.order_cards.length > 0){
                //this.orderCard = ret.data.order_cards[0];
                this.orderCardId = ret.data.order_cards[0].id;
              }else{
         
                await this.createOrderCard().then(async ()=>{
                  resolve(true);
              });
              }
              
            }
          })
        }else{

          await this.createOrderCard().then(async ()=>{
            resolve(true);
         
        })
        
        }
      

      }
    })

    
  }

  async updateCard(changes:Object){

    if(this.orderCard){
      return new Promise((resolve,reject)=>{
   
        this.orderCardUpdate.mutate({id:this.orderCard?.id,changes:changes}).subscribe((ret:any)=>{
          if(ret.errors){
            this.alertifyService.error(ret.errors[0].message);
            reject();
          }
          if(ret.data){
            resolve(true);
          }
        })
      });
    }
    
   
  }

  async deleteCardParts(){
    if(this.orderCard){
      return new Promise((resolve,reject)=>{
   
        this.deleteOrderCardParts.mutate({where:{order_card_id:{_eq:this.orderCard?.id}}}).subscribe((ret:any)=>{
          if(ret.errors){
            this.alertifyService.error(ret.errors[0].message);
            reject();
          }
          if(ret.data){
            resolve(true);
          }
      
        })
      });
    }
  }

  setRemoveBtn(event:boolean){
    this.showNextBtn = event;
  }

}
