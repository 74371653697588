<div style="position: relative;">
<div style="width: 100%; height: 40px; border: 1px solid #ccc; position: relative" (click)="toggleCalendar()">
   
    <span style="position: absolute;  top:10px; left: 5px; color: #666;">{{inputText}}</span>
    <i style="color: #ccc; position: absolute; right: 10px; top:12px" class="fas fa-calendar"></i>
</div>

<div style="
width: 287px;
    clear: both;
    position: absolute;
    z-index: 9999;
    background-color: #fff;
    top: 43px;
    border: 1px solid #ccc;
    padding: 5px; clear: both;" [ngClass]="{'d-none': !showCalendar}">
    <div style="float: left;">
       <span class="d-block alert alert-success mb-0" style="margin-right: 10px;" >
        <span *ngIf="selectedData.date"> <i style="color: #205237;" class="fas fa-calendar"></i> {{selectedData.date}}</span>
        <span *ngIf="selectedData.hour"> - <i style="color: #205237;" class="fas fa-clock"></i> {{selectedData.hour}}</span>
       </span>
        <ul>
          <li class="days" *ngFor="let day of days"> {{day}} </li>
          <li *ngFor="let date of dates"
            [ngClass]="{'grey no-bg ': !isSameMonth(date) ,'cursor-pointer': isSameMonth(date),'current-day': isToday(date), 'active-date':isActive(date) }"
            (click)="changeData(date,'date',isSameMonth(date))">
            {{date | date:'d'}}
            
          </li>
        </ul>
    </div>
    
    
    <div class="hour-selector" style="float: left;">
        <ul>
           <li *ngFor="let item of hourListWithSelectable" 
                [ngClass]="{ 'active-hour' : isActiveHour(item.hour),'hours-cursor-pointer':item.isActive, 'deactive-hours': !item.isActive}"
        

                (click)="changeData(item.hour,'hour',item.isActive)"
                 >{{item.hour}}</li>
           
           
        </ul>
    </div>

    <div class="d-none" >
        <button (click)="returnData()" style="width: 100%;" class="d-block btn btn-primary btn-sm mx-auto">Tarihi Onayla</button>
    </div>
    
</div>
</div>
