import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { FORM_STEPS_ALL } from 'src/app/graphql/form_steps.graphql';
import { FormStepParts, FORM_STEP_PARTS_ALL } from 'src/app/graphql/form_step_parts.graphql';
import { GARAGE_CREATE } from 'src/app/graphql/garages.graphql';
import { Order, ORDER_CREATE, ORDER_ID, ORDER_UPDATE } from 'src/app/graphql/order.graphql';
import { OrderCardInsert, OrderCards, ORDER_CARDS_ALL, ORDER_CARDS_CREATE, ORDER_CARDS_UPDATE, ORDER_CARD_PAYMENT_SUBS } from 'src/app/graphql/order_cards.graphql';
import { ORDER_CARD_INVOICE_INFO_CREATE } from 'src/app/graphql/order_card_invoice_info.graphql';
import { ORDER_CARD_PARTS_CREATE, ORDER_CARD_PARTS_DELETE } from 'src/app/graphql/order_card_parts.graphql';
import { ORDER_INVOICE_CREATE } from 'src/app/graphql/order_invoices.graphql';
import { ORDER_INVOICE_INFO_CREATE } from 'src/app/graphql/order_invoice_info.graphql';
import { ORDER_INVOICE_PART_CREATE } from 'src/app/graphql/order_invoice_parts.graphql';
import { ORDER_PART_CREATE } from 'src/app/graphql/order_part.graphql';
import { VEHICLE_CREATE_APPOINTMENT_FORM } from 'src/app/graphql/vehicle.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { MailService } from 'src/app/services/mail.service';
import { SmsService } from 'src/app/services/sms.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-site-appointment-v2',
  templateUrl: './appointment-v2.component.html',
  styles: [
  ],
  styleUrls:['./appointment-v2.component.css'],

  providers:[ORDER_CARDS_ALL,ORDER_CARDS_CREATE,ORDER_CARDS_UPDATE,
    ORDER_CREATE,
    GARAGE_CREATE,
    ORDER_UPDATE,
    ORDER_PART_CREATE,
    ORDER_INVOICE_INFO_CREATE,
    ORDER_ID,
    ORDER_INVOICE_PART_CREATE,
    ORDER_INVOICE_CREATE,
    VEHICLE_CREATE_APPOINTMENT_FORM,
    ORDER_CARD_PAYMENT_SUBS,FORM_STEPS_ALL,FORM_STEP_PARTS_ALL,ORDER_CARD_PARTS_DELETE,ORDER_CARD_PARTS_CREATE,
    ORDER_CARD_INVOICE_INFO_CREATE]
})
export class AppointmentV2Component implements OnInit {

  public step: number = 1;
  public leftWidth:number = 0;
  public totalElm = 4;
  public isStepCompleted:boolean = false;
  public vehicleModelType: Number | undefined;
  public userUuid: String | null | undefined;
  public orderCard: OrderCards | undefined;
  public insertedCard: OrderCardInsert | undefined;
  public orderCardId: bigint | undefined;
  public selecteMaintenanceKmsId: Number | undefined;
  public selecteMaintenanceKmsName: string = "Km Seçiniz";
  public stepParts: FormStepParts[] | undefined;
  public selectedPackageInfo:any;
  public selectedPieces: any;
  public amountInfo: any;
  public priceInfo:any;
  public orderId:bigint | undefined;
  public userInfo: any;
  public canGoToPrev: boolean = true;
  public orderDetail: Order | undefined;
  public orderCardInvoiceInfoId: bigint | undefined;
  public gearId: Number | undefined;
  public fuelId: Number | undefined;
  public kmId: Number | undefined;
  public selectedPaymentType: Number | undefined;
  public selectedPackageTypeId: Number | undefined;
  public invoiceInfoObj: any;
  public proformaCanShow = false;

  public orderTypeId: Number | undefined;

  public vehicleForm = {
    name:"",
    brand_id: 0,
    model_id: 0,
    model_type_id: 0,
    model_year_id: 0,
    garage_id:0,
    gear_id:0,
    fuel_id:0
  }

  public appointmentFormGroup = new FormGroup({
    serviceTypeId: new FormControl(1, [Validators.required]),
    modelTypeId: new FormControl('',Validators.required),
    maintenanceKmsId: new FormControl('',Validators.required),
    maintenancePackageId: new FormControl('',Validators.required),
    gearId: new FormControl(null,[Validators.required]),
    fuelId: new FormControl(null,[Validators.required]),
    serviceId: new FormControl(null,[Validators.required]),
    serviceDate: new FormControl(null,[Validators.required]),
    paymentType: new FormControl(1,[Validators.required])
  });

  public vehicleId: bigint | undefined
  public stepList:any = [];
  public showNextBtn:boolean = true;

  constructor(
    public authService: AuthService,
    public orderCardInsert: ORDER_CARDS_CREATE,
    public checkOrderCard: ORDER_CARDS_ALL,
    public orderCardUpdate: ORDER_CARDS_UPDATE,
    public deleteOrderCardParts: ORDER_CARD_PARTS_DELETE,
    public createOrderCardParts: ORDER_CARD_PARTS_CREATE,
    private createOrderCardInvoiceInfo: ORDER_CARD_INVOICE_INFO_CREATE,
    private alertifyService: AlertifyService,
    private getFormSteps: FORM_STEP_PARTS_ALL,
    private createOrder: ORDER_CREATE,
    private createOrderpart: ORDER_PART_CREATE,
    private createGarage: GARAGE_CREATE,
    private createVehicle: VEHICLE_CREATE_APPOINTMENT_FORM,
    private updateOrder: ORDER_UPDATE,
    private getOrder: ORDER_ID,
    private createOrderInvoicePart: ORDER_INVOICE_PART_CREATE,
    private createOrderInvoice: ORDER_INVOICE_CREATE,
    private createInvoiceInfo: ORDER_INVOICE_INFO_CREATE,
    private smsService: SmsService,
    private mailService: MailService
  ) { }

    ngOnInit(): void {
      
      if(this.orderTypeId == undefined){
        this.getStepList();
      }
    }

    stepChange(direct:String){
      switch(direct){
        case "next":
          this.isStepCompleted = false;
          this.stepList[(this.step-1)].is_completed = true;

          this.step  += 1;

          if(this.step > 2 && this.stepList.length != this.step ){
          
            this.leftWidth -= 180;
          }

        break;

        case "previous":
        
          this.stepList[(this.step-1)].isCompleted = false;
          if(this.step >= 5){
            this.canGoToPrev = false;
          }
          this.step -= 1;
          if(this.step > 1 && (this.stepList.length - 1) != this.step){
        
            this.leftWidth = this.leftWidth+180;
          
          }
          

        break;
      }

      if(this.step > this.stepList.length){
        this.step = this.stepList.length;
      }

      if(this.step < 1){
        this.step = 1;
        this.leftWidth = 0;
      }

    }

    async setStepVariable($event:any){
    
      let stepName = this.stepList[(this.step-1)].service_name;

    
      switch(stepName){

        case "app-appointment-v2-vehicle-select":
      
          if($event.model_type_id != this.vehicleModelType){
            this.appointmentFormGroup.patchValue({
              maintenanceKmsId: 0,
              maintenancePackageId: 0
            })

            this.selecteMaintenanceKmsId = $event.km_id;
            this.selectedPackageInfo = null;
            this.selecteMaintenanceKmsName = "Km Seçiniz";
          }
          this.vehicleModelType = $event.model_type_id;
          this.gearId = $event.gear_id;
          this.fuelId = $event.fuel_id;
          this.kmId = $event.km_id;
      
          this.appointmentFormGroup.patchValue({
            modelTypeId: this.vehicleModelType,
            gearId: this.gearId,
            fuelId: this.fuelId,
            maintenanceKmsId: this.kmId
          });

          this.vehicleForm = $event;
      
          this.updateCard({
            vehicle_model_type_id: this.vehicleModelType,
            step: this.stepList[this.step].id,
            vehicle_fuel_id: this.fuelId,
            vehicle_gear_id: this.gearId,
            maintenance_kms_id: this.appointmentFormGroup.value.maintenanceKmsId
          });

          this.deleteCardParts();

          this.isStepCompleted = $event.isStepCompleted;
        break;

        case "app-appointment-v2-hizmet-secimi-v2":
          if(parseInt($event) > 0){
            let oldTypeId = this.orderTypeId
            this.orderTypeId = $event;
            if(oldTypeId != $event){
              this.getStepList();
            }
          
            this.isStepCompleted = true;
            
            this.appointmentFormGroup.patchValue({
              serviceTypeId: this.orderTypeId
            });

            this.updateCard({
              type_id: this.orderTypeId,
              step: this.stepList[this.step].id,
            });

            this.deleteCardParts();
          }
          
        break;

        case "app-appointment-v2-package-select":
          this.isStepCompleted = false;


          this.selectedPackageInfo = $event.packageInfo;

          this.selectedPieces = $event.packageParts;

          this.amountInfo = $event.amount;
          this.priceInfo = $event.amount;
          this.selectedPackageTypeId = $event.selectedPackageTypeId;
         
         
          this.appointmentFormGroup.patchValue({
            paymentType: $event.paymentType,
            maintenancePackageId: this.selectedPackageInfo.id
          });
         
          this.selectedPaymentType = $event.paymentType;

          this.updateCard({
            maintenance_package_id: this.selectedPackageInfo.id,
            step: this.stepList[this.step].id,
            maintenance_package_type_id: $event.selectedPackageTypeId,
            payment_type: this.selectedPaymentType
          });


          this.stepChange('next');
          
         
        break;

        

        case "app-appointment-v2-servis-secimi":
          this.isStepCompleted = false;
          if($event.service_date && $event.service_id){
            this.updateCard({service_id:$event.service_id,service_date: $event.service_date});
            this.appointmentFormGroup.patchValue({
              serviceId: $event.service_id,
              serviceDate: $event.service_date
            });
            this.isStepCompleted = true;
            
          }

          this.isStepCompleted = true;
          
        break;

        case "app-appointment-v2-proforma":
          this.isStepCompleted = true;
          break;
        
        case "app-appointment-v2-odeme":
          if($event.orderCreateable){

            this.userInfo = $event.userInfo;


            if($event.userInfo.garages == undefined  || $event.userInfo.garages.length == 0){

              this.createGarage.mutate({name:"Garajım",user_id: $event.userInfo.id,active:true}).subscribe((garageRet:any)=>{

                if(garageRet.errors){
                  this.alertifyService.error(garageRet.errors[0].message);
                }

                if(garageRet.data){

                  this.vehicleForm.garage_id = garageRet.data.insert_garages.returning[0].id;

                  this.createVehicle.mutate({
                    brand_id: this.vehicleForm.brand_id,
                    model_id:this.vehicleForm.model_id,
                    model_year_id: this.vehicleForm.model_year_id,
                    model_type_id: this.vehicleForm.model_type_id,
                    garage_id: this.vehicleForm.garage_id,
                    gear_type_id: this.vehicleForm.gear_id,
                    fuel_type_id: this.vehicleForm.fuel_id
                  }).subscribe((vehicleRet:any)=>{
                    
                    if(vehicleRet.errors){
                      this.alertifyService.error(vehicleRet.errors[0].message);
                    }

                    if(vehicleRet.data){
                      this.vehicleId = vehicleRet.data.insert_vehicles.returning[0].id;

                      this.updateCard({vehicle_id: this.vehicleId});
                      this.createOrders($event);
                    }

                  })
                }

              })

            }else{
    
              
              await $event.userInfo.garages.forEach(async (garageItem:any) => {
                
               await garageItem.vehicles.forEach((vehicleItems:any) => {
                  if(vehicleItems.model_type_id == this.vehicleForm.model_type_id){
                    this.vehicleId = vehicleItems.id
                    this.updateCard({vehicle_id: this.vehicleId});
            
                    this.createOrders($event);
                  }
                });

                
                
              });

              if(this.vehicleId == undefined){
              
                this.createVehicle.mutate({
                  brand_id: this.vehicleForm.brand_id,
                  model_id:this.vehicleForm.model_id,
                  model_year_id: this.vehicleForm.model_year_id,
                  model_type_id: this.vehicleForm.model_type_id,
                  garage_id: $event.userInfo.garages[0].id,
                  gear_type_id: this.vehicleForm.gear_id,
                  fuel_type_id: this.vehicleForm.fuel_id
                }).subscribe((vehicleRet:any)=>{
               
                  if(vehicleRet.errors){
                    this.alertifyService.error(vehicleRet.errors[0].message);
                  }

                  if(vehicleRet.data){
                    this.vehicleId = vehicleRet.data.insert_vehicles.returning[0].id;

                    this.updateCard({vehicle_id: this.vehicleId});

                    this.createOrders($event);
                  }

                })
              }

            }


          

          }

        break;

       

       

        

        
        
        
      }

    
    }

    async deleteCardParts(){
      if(this.orderCard){
        return new Promise((resolve,reject)=>{
    
          this.deleteOrderCardParts.mutate({where:{order_card_id:{_eq:this.orderCard?.id}}}).subscribe((ret:any)=>{
            if(ret.errors){
              this.alertifyService.error(ret.errors[0].message);
              reject();
            }
            if(ret.data){
              resolve(true);
            }
        
          })
        });
      }
    }

    setRemoveBtn(event:boolean){
      this.showNextBtn = event;
    }

    async updateCard(changes:Object){

      if(this.orderCard){
        return new Promise((resolve,reject)=>{
    
          this.orderCardUpdate.mutate({id:this.orderCard?.id,changes:changes}).subscribe((ret:any)=>{
            if(ret.errors){
              this.alertifyService.error(ret.errors[0].message);
              reject();
            }
            if(ret.data){
              resolve(true);
            }
          })
        });
      }
      
    
    }

    createOrders($event:any){


      let infoObj:any = {
        order_id:this.orderId,
        user_id: $event.userInfo.id,
        customer_name: $event.invoiceInfo.firstname,
        customer_surname: $event.invoiceInfo.lastname,
        customer_email: $event.userInfo.email,
        identity_no: null,
        tax_number: null,
        tax_administration: null,
        gsm_number: null,
        tel_number:null,
      //     is_same_with_customer: this.invoiceInfo.is_same_with_customer,
        invoice_type: 3,
        address: $event.invoiceInfo.address,
        city_id: $event.invoiceInfo.city_id,
        town_id: $event.invoiceInfo.town_id,
        district_id: $event.invoiceInfo.district_id,
        country_id: $event.invoiceInfo.country_id,
        company_name: null,
        customer_invoice_type: 1
      }

      if($event.invoiceInfo.isCompany){
                  
        infoObj.tax_number = $event.invoiceInfo.tax_number;
        infoObj.tax_administration = $event.invoiceInfo.tax_administration;
        infoObj.company_name = $event.invoiceInfo.company_name;
        if(this.userInfo?.company_id != undefined){
          infoObj.company_id = this.userInfo?.company_id;

        }
        infoObj.tel_number = $event.invoiceInfo.tel_number;
      }else{
        infoObj.gsm_number = $event.invoiceInfo.phone;
        infoObj.identity_no = $event.invoiceInfo.identity_no;
      }

      switch(parseInt($event.paymentType)){
        case 1: //kredi kartı
        if(this.orderTypeId && this.orderTypeId > 0 &&  this.selectedPieces.length > 0){
          let Obj = {
            status_id: 1,
            type_id: this.orderTypeId,
            customer_id: $event.userInfo.id,
            order_value: this.priceInfo.generalTotal.toFixed(2),
            order_no_vat_value: this.priceInfo.total.toFixed(2),
            service_value: 0,
            service_id: this.appointmentFormGroup.value.serviceId,
            maintenance_package_id: (this.orderTypeId == 1) ? this.selectedPackageInfo.id : 0,
            customer_status_id: 14, //müşteri onayı verildi,
            payment_type: $event.paymentType,
            is_payed: $event.userInfo.isPayed,
            vehicle_id: this.vehicleId,
            customer_address_id: $event.userInfo.address_id,
            customer_gsm_id: $event.userInfo.gsm_id,
            invoice_firstname: $event.invoiceInfo.firstname,
            invoice_lastname: $event.invoiceInfo.lastname,
            invoice_identity_number: $event.invoiceInfo.identity_no,
            selected_package_type_id: this.selectedPackageTypeId,
            payment_value: this.priceInfo.generalTotal.toFixed(2)
          }



          this.createOrder.mutate(Obj).subscribe(async (result:any)=>{
            if(result.errors){
              this.alertifyService.error("Kayıt esnasında hata oluştu");
            }

            if(result.data){
              let insertedId = result.data.insert_orders.returning[0].id;
              this.orderId = insertedId;
              let i = 1;
              let invoiceCreatable = true;
              infoObj.order_id = this.orderId;
              
              let sendsms =  this.sendSmsService('Siparişiniz Oluşturuldu',this.orderId+" numaralı randevunuz oluşturulmuştur.", [$event.userInfo.phone]);

              let message = insertedId+" numaralı randevu " + this.userInfo.name + " " + this.userInfo.surname + " adına oluşturulmuştur.";

              this.mailService.send(environment.garantiliEmail,'Yeni Randevu Oluşturuldu.',message).subscribe(mailResponse => {

                //if (this.order.customer.user_phones.length) {
                //}

                if(mailResponse.success){
                  // this.alertifyService.success("Mail Gönderildi");
                }

              });

              if($event.userInfo.isPayed){

                this.invoiceInfoObj = infoObj;

                this.createInvoiceInfo.mutate({object:infoObj}).subscribe((infoResult:any) => {
                      
                  if(infoResult.errors){
                    
                    this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
                  }
                  if(infoResult.data){
                    let insertedId = infoResult.data.insert_order_invoice_info_one.id;
                  /* this.updateOrder.mutate({id:this.orderId,changes:{ invoice_info_id: insertedId }}).subscribe((e:any)=>{
                      if(e.errors){
                        this.alertifyService.error(e.errors[0].message);
                      }
                    });*/
                  }

                });



              }



             await this.selectedPieces.filter( async (obj:any) => {

                let orderPartObj = {
                  
                  order_id:insertedId,
                  part_id:obj.part_id,
                  part_type_id:obj.part_type_id,
                  part_value:obj.part_value,
                  selected_part_id:obj.selected_part_id,
                  service_value:0,
                  part_unit_type_id:obj.part_unit_type,
                  part_unit:obj.part_unit,
                  brand_labour_price: obj.brand_labour_price,
                  gos_labour_price: obj.gos_labour_price,
                  price_unit_id:obj.price_unit_id,
                  total_price_no_vat: obj.newNoVatPrice,
                  total_price: obj.total_price,
                  created_at: moment().format('yyyy-MM-DDTH:mm:ssZ'),
                  part_code: obj.part_code,
                  part_no_vat_price: obj.newNoVatPrice,
                  part_labour_hour: obj.brand_labour_hour,
                  part_labour_price: obj.part_labour_price,
                  part_brand_labour_price: obj.labour_price,
                  labour_value: obj.part_labour_price,
                  labour_discount: 0,//TODO:: buraya dşscount eklenecek
                  labour_type_id: 3
                  
                };

         

               if(obj.part_unit_type == 3){
                orderPartObj.total_price = obj.part_in_vat_price;
                orderPartObj.part_value = obj.part_in_vat_price;
                orderPartObj.part_no_vat_price = obj.part_in_vat_price;
                orderPartObj.part_labour_hour = obj.totalLabourHour;
                orderPartObj.total_price_no_vat =  obj.part_no_vat_price;
                orderPartObj.part_brand_labour_price= obj.part_no_vat_price;
                orderPartObj.part_unit = obj.totalLabourHour;
               }

 

                await this.createOrderpart.mutate(orderPartObj).subscribe((partTypeResult:any) => {
                  if(partTypeResult.errors){
                    this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
                  }
                  if(partTypeResult.data){
                    //this.step = this.steps.siparisi_tamamla.id;
                    i++;
                    if(i>=this.selectedPieces.length && invoiceCreatable && $event.userInfo.isPayed){
                      
                      invoiceCreatable = false;
                    }

                //    this.step =step;
                  }
                });

                

               

                this.updateCard({
                  step:5,
                  customer_id: this.userInfo.id,
                  order_value:this.priceInfo.generalTotal.toFixed(2),
                  order_no_vat_value: this.priceInfo.total.toFixed(2),
                  main_order_id: this.orderId,
                  is_complete: true
                });


              });

              await this.createInvoice();

            
              this.alertifyService.success("siparişiniz oluşturuldu");
              this.stepChange('next');

              
            }

          });


        }
        break;

        case 2:
          if(this.orderTypeId && this.orderTypeId > 0 &&  this.selectedPieces.length > 0){
        
            let Obj = {
              status_id: 1,
              type_id: this.orderTypeId,
              customer_id: $event.userInfo.id,
              order_value: this.priceInfo.generalTotal.toFixed(2),
              order_no_vat_value: this.priceInfo.total.toFixed(2),
              service_value: 0,
              service_id: this.appointmentFormGroup.value.serviceId,
              maintenance_package_id: (this.orderTypeId == 1) ? this.selectedPackageInfo.id : 0,
              customer_status_id: 14, //müşteri onayı verildi,
              payment_type: $event.paymentType,
              is_payed: false,
              vehicle_id: this.vehicleId,
              customer_address_id: $event.userInfo.address_id,
              customer_gsm_id: $event.userInfo.gsm_id,
              invoice_firstname: $event.invoiceInfo.firstname,
              invoice_lastname: $event.invoiceInfo.lastname,
              invoice_identity_number: $event.invoiceInfo.identity_no
            }


            this.createOrder.mutate(Obj).subscribe(async (result:any)=>{
              if(result.errors){
                this.alertifyService.error("Kayıt esnasında hata oluştu");
              }

              if(result.data){
                let insertedId = result.data.insert_orders.returning[0].id;
                this.orderId = insertedId;

                infoObj.order_id = this.orderId;

                let sendsms =  this.sendSmsService('Siparişiniz Oluşturuldu',this.orderId+" numaralı randevunuz oluşturulmuştur.", [$event.userInfo.phone]);

                this.selectedPieces.filter((obj:any) => {

                  this.createOrderpart.mutate(
                    {
                    
                      order_id:insertedId,
                      part_id:obj.part_id,
                      part_type_id:obj.part_type_id,
                      part_value:obj.part_value,
                      selected_part_id:obj.selected_part_id,
                      service_value:0,
                      part_unit_type_id:obj.part_unit_type,
                      part_unit:obj.part_unit,
                      brand_labour_price: obj.brand_labour_price,
                      gos_labour_price: obj.gos_labour_price,
                      price_unit_id:obj.price_unit_id,
                      currency_unit_price: obj.currency_unit_price,
                      currency_total_price: obj.currency_total_price,
                      currency: obj.currency,
                      total_price_no_vat: obj.total_price_no_vat,
                      total_price: obj.total_price,
                      created_at: moment().format('yyyy-MM-DDTH:mm:ssZ'),
                      part_code: obj.part_code,
                      part_no_vat_price: obj.price_no_vat
                      
                    }).subscribe((partTypeResult:any) => {
                    if(partTypeResult.errors){
                      this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
                    }
                    if(partTypeResult.data){
                      //this.step = this.steps.siparisi_tamamla.id;
                  //    this.step =step;
                    }
                  });

                  this.updateCard({
                    step:5,
                    customer_id: this.userInfo.id,
                    order_value:this.priceInfo.generalTotal.toFixed(2),
                    order_no_vat_value: this.priceInfo.total.toFixed(2),
                    main_order_id: this.orderId,
                    is_complete: true
                  });

                });
              
                

                this.invoiceInfoObj = infoObj;
               await this.createInvoiceInfo.mutate({object:infoObj}).subscribe((infoResult:any) => {
                      
                  if(infoResult.errors){
                    
                    this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
                  }
                  if(infoResult.data){
                    let insertedId = infoResult.data.insert_order_invoice_info_one.id;
                   this.updateOrder.mutate({id:this.orderId,changes:{ invoice_info_id: insertedId }}).subscribe((e:any)=>{
                      if(e.errors){
                        this.alertifyService.error(e.errors[0].message);
                      }
                    });
                  }

                });

                
              
                this.alertifyService.success("siparişiniz oluşturuldu");
                this.stepChange('next');

                
              }

            });


          }


        break;
      }

    }

    sendSmsService(title: String, content: String, phones: any[]) {
      this.smsService.send(title, content, phones).subscribe(response => {

        if (response.status) {
          // this.alertifyService.success("Sms Gönderildi")
          // Gönderilen smsler için kayıt işlemi olacaksa burada yapılacak
        }
      })
    }

    async createInvoice(){
      await this.getOrder.watch({id:this.orderId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(async (result:any) => {

        if(result.errors){
          this.alertifyService.error(result.errors[0].message);
          return;
        }

        if(result.data){
          this.orderDetail = result.data.orders_by_pk;

          
          
          if(this.orderDetail != undefined){

            //let invoiceObject = 

           await this.createOrderInvoice.mutate({
              object:{
              "order_id":this.orderDetail.id,
              "date":moment().format("YYYY-MM-DDTHH:mm:ssZ"),
              "value_in_vat":this.orderDetail.order_value,
              "value_no_vat":this.orderDetail.order_no_vat_value,
              "vat": ( this.orderDetail.order_value - this.orderDetail.order_no_vat_value ),
              "discount":0,
              "url":"",
              "invoice_type_id":this.orderDetail.type_id,
              "valor_date":moment().format("YYYY-MM-DDTHH:mm:ssZ"),
              "invoice_status_id":6, // TODO buraya bakılacak
              "paid_value": this.orderDetail.order_value, // TODO buraya bakılacak
              "remaining_value":0, // TODO buraya bakılacak
              "note":"",
              "invoice_reciever_id":this.orderDetail.service_id,
              // "invoice_reciever_type_id": this.orderDetail.customer_info.role_id
            
            }
            }).subscribe(async (result:any) => {
              if(result.errors){
                this.alertifyService.error(result.errors[0].message);
                
              }

              if(result.data){

                await this.updateOrder.mutate({"id":this.orderId,"changes":{invoice_id:result.data.insert_order_invoices_one.id}}).subscribe((upresult:any) => {
                  if(upresult.errors){
                    this.alertifyService.error("Güncelleme esnasında bir sorun oluştu!");
                  }
                  if(upresult.data){
                    this.alertifyService.success("siparişiniz güncellendi");
                  }
                })



 
                let i = 1;
               await  this.selectedPieces.forEach(async (element:any,val:number) => {

                 await this.createOrderInvoicePart.mutate({

                    

                    object:{
                    "invoice_id":result.data.insert_order_invoices_one.id,
                    "part_id": element.id,
                    "part_name": element.original_name,
                    "part_code":  element.part_code,
                    "labour_type_id": null,
                    "labour_value": element.labour_price,
                    "labour_discount": null,
                    "part_in_vat_price": element.totalPartInVatPrice,
                    "part_no_vat_price": element.totalPartNoVatPrice,
                    "invoice_type":1, // TODO buraya bakılacak
                    "part_unit_type_id": element.part_unit_type,
                    "part_unit": element.part_unit,
                    "part_type_id": element.part_type_id,
                    "gos_part_id": element.part_id,
                    "part_unit_price": (element.part_no_vat_price && element.part_no_vat_price != null) ? element.part_no_vat_price : 0,
                    
                  }

                  }).subscribe((partResult:any) => {
                    if(partResult.errors){
                      this.alertifyService.error(partResult.errors[0].message);
                    }
                    if(partResult.data){
                      i++;
                      if(i == this.selectedPieces.length){
                        this.proformaCanShow = true;
                      }
                    }
                  });
                });

               
                this.alertifyService.success("Fatura oluşturuldu");
               
              }
            })
          }
        }
      });
          
              
              
          
      
    }

    getStepList(){
      this.getFormSteps.watch({where:{form_step_id: {_eq:4}, order_type_id: {_eq: this.orderTypeId}}}).valueChanges.subscribe( ret => {
        if(ret.errors){
          this.alertifyService.error(ret.errors[0].message);
        }

        if(ret.data){
          this.stepParts = ret.data.form_step_parts;
          this.stepList = [];
          this.stepParts.forEach(element => {
            
              this.stepList.push({step_title: element.step_title, position: element.position, isCompleted: false,service_name: element.service_name,id:element.id});
          
          });

            this.setOrderCard(true);

        }

      });


    }

    async setOrderCard(setStep=false){

      return new Promise(async (resolve,reject)=>{
        this.userUuid = localStorage.getItem('user_uuid');
        if(this.userUuid && this.userUuid != null){

          await this.getOrderCard().then(async ()=>{
        
            if(this.orderCard && setStep){
            //  this.setStep();
            }
            if(this.orderCard == undefined){
              
              await this.createOrderCard().then(async ()=>{
                resolve(true);
                
              })
            }
          })

        }else{

          if( this.authService.isLogin){
            this.checkOrderCard.watch({where:{user_id:{_eq:this.authService.userId},is_complete:false}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(async ret=>{
              if(ret.errors){
                this.alertifyService.error(ret.errors[0].message);
              }

              if(ret.data){
                if(ret.data.order_cards.length > 0){
                  //this.orderCard = ret.data.order_cards[0];
                  this.orderCardId = ret.data.order_cards[0].id;
                }else{
            
                  await this.createOrderCard().then(async ()=>{
                    resolve(true);
                });
                }
                
              }
            })
          }else{

            await this.createOrderCard().then(async ()=>{
              resolve(true);
            
          })
          
          }
        

        }
      })

      
    }

    async createOrderCard(){
      return new Promise((resolve,reject)=>{
        this.orderCardInsert.mutate({
          object:{step: 1}
        }).subscribe((ret:any)=>{
          if(ret.errors){
            this.alertifyService.error(ret.errors[0].message);
            resolve(false);
          }

          if(ret.data){
            if(ret.data.insert_order_cards_one.card_uuid){
            


              localStorage.setItem("user_uuid",ret.data.insert_order_cards_one.card_uuid);
              this.userUuid = ret.data.insert_order_cards_one.card_uuid;
              this.orderCardId = ret.data.insert_order_cards_one.id;

                
              this.createOrderCardInvoiceInfo.mutate({object: { order_card_id:this.orderCardId,customer_invoice_type: 1, invoice_type: 3 } }).subscribe(orderCardInvoiceInfoResponse => {
                if(orderCardInvoiceInfoResponse.errors){
                  this.alertifyService.error(orderCardInvoiceInfoResponse.errors[0].message);
                }
                if(orderCardInvoiceInfoResponse.data?.order_card_invoice_info_by_pk){
                  this.orderCardInvoiceInfoId = orderCardInvoiceInfoResponse.data.returning[0].id;
                }

              })

              // this.setOrderCard();


              //this.setOrderCard();
              resolve(true);
            }
            
          }
        })
      })
    }

    async getOrderCard(){

      return new Promise((resolve,reject)=>{
        this.checkOrderCard.watch({where:{card_uuid:{_eq:this.userUuid},is_complete:{_eq:false}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(ret=>{
          if(ret.errors){
            this.alertifyService.error(ret.errors[0].message);
            resolve(false);
          }

          if(ret.data){
            if(ret.data.order_cards.length > 0){
              this.orderCard = ret.data.order_cards[0];
              localStorage.setItem("user_uuid",this.orderCard.card_uuid);
                
              resolve(true);
            }else{
              resolve(false);
            }
          }
        });
      });
    }

    buttonEmitter($event:boolean){
      if($event){
        this.stepChange('next');
      }
    }

}