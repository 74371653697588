import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debug } from 'console';
import { map } from 'rxjs';
import { OrderType, ORDER_TYPE_ALL } from 'src/app/graphql/order_type.graphql';
import { Package } from 'src/app/graphql/package.graphql';
import { VehicleBrand } from 'src/app/graphql/vehicle-brand.graphql';
import { YearModel } from 'src/app/graphql/year.graphql';
import { VehicleFuels, VehicleGears, VehicleKms, VehicleModels, VehicleModelTypes } from 'src/app/interfaces/appointment.interface';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment-arac-hizmet-secimi-v4',
  templateUrl: './arac-hizmet-secimi-v4.component.html',
  styles: [
  ],
  styleUrls:['./arac-hizmet-secimi-v4.component.css'],
  providers:[
    ORDER_TYPE_ALL,

  ]
})
export class AracHizmetSecimiV4Component implements OnInit {

  @Input() userFormInput:any;
  @Input() vehicleInfoInput:any;
  @Input() vehicleFormInput:any;
  @Input() orderTypeId: Number  = 1;
  @Input() packageList: Package[] | undefined;
  @Input() selectedPackage: any;
  @Input() packageKmList:any


  @Output() firstStepEmitter = new EventEmitter<any>();
  


  public firstStepForm = new FormGroup({
    customer_name: new FormControl(null,[Validators.required]),
    customer_surname: new FormControl(null,[Validators.required]),
    customer_email: new FormControl(null,[Validators.required]),
    customer_phone: new FormControl(null,[Validators.required]),
    customer_job: new FormControl(null,[Validators.required]),
    vehicle_brand_id: new FormControl(null,[Validators.required]),
    vehicle_model_id: new FormControl(null,[Validators.required]),
    vehicle_year_id: new FormControl(null,[Validators.required]),
    vehicle_model_type_id: new FormControl(null,[Validators.required]),
    vehicle_gear_id: new FormControl(null,[Validators.required]),
    vehicle_fuel_id: new FormControl(null,[Validators.required]),
    vehicle_km_id: new FormControl(null,[Validators.required]),
    customer_km: new FormControl(null,[Validators.required]),
    vehicle_km_name: new FormControl(null,[Validators.required]),
    order_type: new FormControl(1,[Validators.required]),
    approved: new FormControl(null,[Validators.required]),
    package_id: new FormControl(null,[Validators.required])
  });

  public vehicleModelList: VehicleModels[] | undefined;
  public vehicleModelYearList: YearModel[] | undefined;
  public vehicleModelTypeList: VehicleModelTypes[] | undefined;
  public vehicleGearList: VehicleGears[] | undefined;
  public vehicleFuelList: VehicleFuels[] | undefined;
  public vehicleBrandList: VehicleBrand[] = [];
  public vehicleKmsList: VehicleKms[] | undefined;

 
  public brandId: BigInt | undefined;
  public modelId: BigInt | undefined;
  public modelTypeId: BigInt | undefined;
  public modelYearId: BigInt | undefined;
  public vehicleId:BigInt | undefined;
  public brandName:String = "Marka Seçiniz";
  public modelName:String = "Model Seçiniz";
  public selectedYear:String = "Yıl Seçiniz";
  public modelTypeName:String = "Versiyon Seçiniz";
  public selectedKms:String = "Km";
  public selecteEditYear:String = "Yıl";
  public selectedModel:any;
  public selectedBrand:any;
  public garageName:String = "Garaj Seçiniz";
  public garageId: Number = 0;
  public gearId: number | undefined;
  public fuelId: number | undefined;
  public orderForm: any[] = [];
  public selectedVehicleBrandInfo: VehicleBrand | undefined;
  public userInfo: any;
  

  private baseUrl: string = environment.laravelApiEndpoint;
  public orderTypes: OrderType[] | undefined;

  constructor(
    private alertifyService: AlertifyService,
    private getOrderTypeList: ORDER_TYPE_ALL,
    private authService: AuthService,
    private http: HttpClient,
  ) { }

  async ngOnInit(): Promise<void> {


    await this.getOrderTypes();
    await this.getVehicleBrands();

  }

  getApi(url:string,vars: Object | null = null){

    let options = {
      headers: new HttpHeaders({
        'token': this.authService.parseToken(false)
      })
    }

    return this.http.post<any>(this.baseUrl + url,vars,options).pipe(map(response => {
      
      return response;
    }))
  }

  async getOrderTypes(){
    await this.getOrderTypeList.watch({where:{active:{_eq:true}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.orderTypes = resp.data.order_types;
      }
    })
  }

  async getVehicleBrands(){

    let url = 'appointment/get-vehicle-brands';
    await this.getApi(url).pipe().subscribe(response => {
     
     
      if (response.status) {
        this.vehicleBrandList = response.data;
      } else {
       this.alertifyService.error(response.message);
      }
  
    });


    /*this.getVehicleBrandList.watch({where:{active:{_eq:true}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }

      if(resp.data){
        this.vehicleBrandList = resp.data.vehicle_brands;
      }
    })*/
  }

  async getPackages(){
    if(
      this.firstStepForm.value.vehicle_model_type_id != null && 
      this.firstStepForm.value.vehicle_fuel_id != null &&
      this.firstStepForm.value.vehicle_gear_id != null &&
      this.packageKmList?.km_id_list.length > 0 ){
      let url = "appointment/get-package-list-by-km-ids";
      await this.getApi(url,{
        "model_type_id":this.firstStepForm.value.vehicle_model_type_id,
        "customer_km": this.firstStepForm.value.customer_km,
        "fuel_id": this.firstStepForm.value.vehicle_fuel_id,
        "gear_id": this.firstStepForm.value.vehicle_gear_id
    }).pipe().subscribe(response => {
        if(response){
          this.packageList = response.data;
        }
      });

    }
  }

  async selectedTwo(key: string, $event:any,isBack = false){

    switch(key){
      case "brand":

        if($event != this.firstStepForm.value.vehicle_brand_id){

          this.firstStepForm.patchValue({
            vehicle_brand_id: $event,
            vehicle_model_id: null,
            vehicle_year_id: null,
            vehicle_model_type_id: null,
            vehicle_gear_id: null,
            vehicle_fuel_id: null,
            vehicle_km_id: null
          });

          this.vehicleModelList = [];
          this.vehicleModelYearList = [];
          this.vehicleModelTypeList = [];
          this.vehicleKmsList = [];

        let url = 'appointment/get-vehicle-models';
        await this.getApi(url,{brand_id:$event}).pipe().subscribe(response => {
          if(response.status){
            this.vehicleModelList = response.data;

            this.getPackages();
          }
        });

        

      }

       

      break;
      
      case "model":

        if($event != this.firstStepForm.value.vehicle_model_id){

          this.firstStepForm.patchValue({
            vehicle_model_id: $event,
            vehicle_year_id: null,
            vehicle_model_type_id: null,
            vehicle_gear_id: null,
            vehicle_fuel_id: null,
            vehicle_km_id: null
          });

         
          this.vehicleModelYearList = [];
          this.vehicleModelTypeList = [];
          this.vehicleKmsList = [];

          let url = 'appointment/get-vehicle-model-years';
          await this.getApi(url,{model_id:$event}).pipe().subscribe(response => {
            if(response.status){
            
              this.vehicleModelYearList = response.data;
            
            }

            this.getPackages();
          });

        }

      break;

      case "year":

        if($event != this.firstStepForm.value.vehicle_year_id){
          
          this.firstStepForm.patchValue({
            vehicle_year_id: $event,
            vehicle_model_type_id: null,
            vehicle_gear_id: null,
            vehicle_fuel_id: null,
            vehicle_km_id: null
          });

        
          this.vehicleModelTypeList = [];
          this.vehicleKmsList = [];

          let url = 'appointment/get-vehicle-model-types';
          await this.getApi(url,{year_id:$event}).pipe().subscribe(response => {
            if(response.status){
              this.modelYearId = $event;
              this.vehicleModelTypeList = response.data;
          
              this.packageList = [];
            
              this.modelTypeName = "Model Tipi";
            }
            

            this.getPackages();
          });
        }
        
      break;

      case "vehicle_model_types":

        if($event != this.firstStepForm.value.vehicle_model_type_id){
          this.modelTypeId = $event;

          this.firstStepForm.patchValue({
            vehicle_gear_id:null,
            vehicle_fuel_id:null,
            vehicle_km_id: null,
            vehicle_model_type_id: $event
          });
          
        }
       
        await this.getApi('appointment/get-vehicle-gear-fuel-km',{model_type_id:this.modelTypeId}).pipe().subscribe(response => {
          this.vehicleGearList = response.data.gears;
          this.vehicleFuelList = response.data.fuels;
          this.vehicleKmsList = response.data.kms;

          


          this.getPackages();

        });
      break;

       case "km":

        if(this.firstStepForm.value.customer_km != $event){
          
          this.firstStepForm.patchValue({
            customer_km : $event.customerKm,
            vehicle_km_id: null
          })
       
         
           this.packageKmList = $event;
   

           this.getPackages();
        }

        
       break;

       case "fuel":
        this.firstStepForm.patchValue({
          vehicle_fuel_id: null
        })
        if($event != null){
          this.firstStepForm.patchValue({
            vehicle_fuel_id: $event.target.value
          })
        }
        this.getPackages();
       break;

       case "gear":
        this.firstStepForm.patchValue({
          vehicle_gear_id: null
        })
        if($event != null){
          this.firstStepForm.patchValue({
            vehicle_gear_id: $event.target.value
          })
        }
        this.getPackages();
       break;

       case "customer_name":
        this.firstStepForm.patchValue({
          customer_name: $event.target.value
        });
       break;

       case "customer_surname":
        this.firstStepForm.patchValue({
          customer_surname: $event.target.value
        });
       break;

       case "customer_phone":
        this.firstStepForm.patchValue({
          customer_phone: $event
        });
       break;

       case "customer_email":
        this.firstStepForm.patchValue({
          customer_email: $event.target.value
        });
       break;

       case "approved":
        this.firstStepForm.patchValue({
          approved: $event?.target.checked
        });
       break;

       case "customer_job":
        this.firstStepForm.patchValue({
          customer_job: $event.target.value
        });
       break;
       case "package":
        this.firstStepForm.patchValue({
          vehicle_km_id: $event.kms_info.id,
          vehicle_km_name: $event.kms_info.name,
          package_id: $event.id
        })

        
       
       break;
    }
   

    
    this.stepControl();

  }

  stepControl(){
    if(this.firstStepForm.valid){
      this.firstStepEmitter.emit(this.firstStepForm.value);
    }

  }

  setOrderType($event:any){
    this.firstStepForm.patchValue({
      order_type: $event.target.value
    })
  }

}
