import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GSM_CREATE } from 'src/app/graphql/gsm.graphql';
import { USER_UPDATE_GSM } from 'src/app/graphql/user.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-offer-gsm-add',
  templateUrl: './gsm-add.component.html',
  styles: [
  ],
  providers:[ GSM_CREATE,USER_UPDATE_GSM]
})
export class GsmAddComponent implements OnInit {

  @Output() gsmResult = new EventEmitter<Object>();

  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    code: new FormControl('', [Validators.required]),
    user_id: new FormControl('', [Validators.required]),
    active: new FormControl(false,[])
  });

  public resetForm: boolean = false;
  public setValue = false;
 public userId: number | undefined;
  constructor(
    private createGsm: GSM_CREATE,
    private userUpdateGsm: USER_UPDATE_GSM,
    public alertifyService: AlertifyService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.userId = this.authService.userId;
    this.formGroup.patchValue({"user_id": this.userId});
  }

  ngOnChanges(){
  

  }

  formSubmit(){
    if (this.formGroup.invalid) {
      this.alertifyService.error("Kayıt edilemedi!");
      return false;
    };


    this.createGsm.mutate({ ...this.formGroup.value }).subscribe((result:any)=>{

      
      if (result.errors) {
        this.alertifyService.error("Kayıt Eklenemedi!");
        this.gsmResult.emit({status:false});
        return false;
      }
    
  
      if (result.data) {
       
        if(this.formGroup.value.active){


          this.setValue = true;
          this.userUpdateGsm.mutate({id:this.userId, gsm_id:result.data.insert_gsms.returning[0].id}).subscribe(response=>{
          })
        }

      

        this.alertifyService.success("Kayıt Eklendi!");
        this.formGroup.reset();
        this.resetForm = true;
        this.formGroup.patchValue({"user_id": this.userId,active:false});
        
        this.gsmResult.emit({status:true,gsm_id:result.data.insert_gsms.returning[0].id,setValue:this.setValue});
        
      }

    })
  }

}
