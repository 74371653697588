import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company, COMPANY_ID } from 'src/app/graphql/company.graphql';
import { COMPANY_CERTIFICATE_ALL } from 'src/app/graphql/company_certificate.graphql';
import { ServiceCompanyContacts, SERVICE_COMPANY_CONTACTS_ALL } from 'src/app/graphql/service_company_contacts.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-shared-profile-completed',
  templateUrl: './profile-completed.component.html',
  styles: [
  ],
  providers:[COMPANY_ID,SERVICE_COMPANY_CONTACTS_ALL,COMPANY_CERTIFICATE_ALL]
})
export class ProfileCompletedComponent implements OnInit {
  public profilePercentage = 0;
  public requiredProfilePercentage = 0;
  public totalProfileInput = 39;
  public requiredProfileInputs = 16;
  public completedProfileInput = 0;
  public completedRequiredProfileInput = 0;
  public requiredButEmpty: any = [];
  public companyInfo : Company | undefined;
  public companyContacts: ServiceCompanyContacts | undefined;
  @Input() companyId: bigint | undefined;
  @Output() nullInputs = new EventEmitter<any>();
  private nullInputGroups = {
    company_info: false,
    contact_info: false,
    corporate_information: false,
    brands: false,
    services: false,
    work_hours: false,
    ek: false,
    service_groups: false

  }
  constructor(
    private getCompanyById: COMPANY_ID,
    private alertifyService: AlertifyService,
    private getServiceCompanyContacts: SERVICE_COMPANY_CONTACTS_ALL,
    private getCompanyCertificates: COMPANY_CERTIFICATE_ALL
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    this.getCompanyInfo();

 

  }

  getCompanyInfo(){


    this.getCompanyById.watch({id:this.companyId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.companyInfo = resp.data.companies_by_pk;

        this.getServiceCompanyContacts.watch({where:{company_id:{_eq:this.companyId}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(contactsResp=>{
          if(contactsResp.errors){
            this.alertifyService.error(contactsResp.errors[0].message);
          }
          if(contactsResp.data){
            this.companyContacts = contactsResp.data.service_company_contacts[0];
          }
        })

        this.setCompletedInputs();
        this.setRequiredCompletedInputs();
      }
    })
    
  }

  async setRequiredCompletedInputs(){
    this.requiredButEmpty = [];
 

    /** 
     * Firma Adı/Ünvanı (Tam Olarak Belirtilmeli)
     * Servis Statüsü (Özel / Yetkili)
     * Vergi No
     * Vergi Dairesi
     * Telefon Cep
     * E-Mail
     * İl
     * İlçe
     * Adres
     * Şirket Sahibi
     * TSE 12047 - Evet / Hayır
     * HIzmet VerIlen Markalar (Uzmanlık)
     * Periyodik Bakım -  Evet / Hayır
     * Mekanik Onarım
     * Kaporta Boya Onarım (Hasar)  -  Evet / Hayır
     * ZİNCİR SERVİS GRUBU
     * **/


    
    
    if(this.companyInfo){
      if(this.companyInfo.name != null){ 
        //firma adı
        this.completedRequiredProfileInput += 1;
      }else{
        this.requiredButEmpty.push('Firma Adı');
      }
  
      if(this.companyInfo.service_status != null){ 
        //servis statusu
        this.completedRequiredProfileInput += 1;
      }else{
        this.requiredButEmpty.push('Servis Status');
      }
  
     
      if(this.companyInfo.vat != null){ 
        // vergi numarası
        this.completedRequiredProfileInput += 1;
      }else{
        this.requiredButEmpty.push('Vergi Numarası');
      }
  
      if(this.companyInfo.vat_office != null){ 
        // vergi dairesi
        this.completedRequiredProfileInput += 1;
      }else{
        this.requiredButEmpty.push('Vergi Dairesi');
      }

      if(this.companyInfo.company_gsm_no != null){ 
        // cep telefonu
        this.completedRequiredProfileInput += 1;
      }else{
        this.requiredButEmpty.push('Cep Telefonu');
      }

      if(this.companyInfo.company_email_address != null){ 
        // email
        this.completedRequiredProfileInput += 1;
      }else{
        this.requiredButEmpty.push('Email');
      }
  
    
  
      if(this.companyInfo.city_id != null){ 
        // il
        this.completedRequiredProfileInput += 1;
      }else{
        this.requiredButEmpty.push('Şehir');
      }
  
      if(this.companyInfo.district_id != null){ 
        // ilçe id
        this.completedRequiredProfileInput += 1;
      }else{
        this.requiredButEmpty.push('İlçe');
      }
  
  
      if(this.companyInfo.address != null){ 
        // adres
        this.completedRequiredProfileInput += 1;
      }else{
        this.requiredButEmpty.push('Adres');
      }

      if(this.companyInfo.company_vehicle_brands && this.companyInfo.company_vehicle_brands.length > 0){
        this.completedRequiredProfileInput += 1; 
        // hizmet verilen markalar
      }else{
        this.requiredButEmpty.push('Hizmet verilen markalar');
      }

      if(this.companyInfo.company_service_groups && this.companyInfo.company_service_groups.length > 0){
        this.completedRequiredProfileInput += 1; 
        // zincir servis grubu

      }else{
        this.requiredButEmpty.push('Zincir servis grubu');
      }

      


      await this.getServiceCompanyContacts.watch({where:{company_id:{_eq:this.companyId}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(async resp=>{
        if(resp.errors){
          this.alertifyService.error(resp.errors[0].message);
        }

        if(resp.data){
          this.companyContacts = resp.data.service_company_contacts[0];

          if(this.companyContacts){
            if(this.companyContacts.company_owner != null){ 
              // şirket sahibi
              this.completedRequiredProfileInput += 1;
            }else{
              this.requiredButEmpty.push('Şirket Sahibi');
            }
          }
        }


       await this.getCompanyCertificates.watch({where:{_and:{company_id: {_eq: this.companyId}, certificate_id:{_eq:1} }}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(async compResp=>{
          if(compResp.errors){
            this.alertifyService.error(compResp.errors[0].message);
          }
          if(compResp.data){
            if(compResp.data.company_certificates && compResp.data.company_certificates.length > 0){
              this.completedRequiredProfileInput += 1; //tse 12047 
            }else{
              this.requiredButEmpty.push('Tse 12047');
            }
          }
          
          await this.companyInfo?.company_services.forEach(element => {
            if(element.company_service_type_id == 9){ //periyodik baım
              this.completedRequiredProfileInput += 1;
            }
  
            if(element.company_service_type_id == 8){ //mekanik bakım
              this.completedRequiredProfileInput += 1;
            }
  
            if(element.company_service_type_id == 19){ // kaporta boya
              this.completedRequiredProfileInput += 1;
            }
          });

          
  
          this.requiredProfilePercentage = parseFloat((100-(((this.requiredProfileInputs-this.completedRequiredProfileInput)/this.totalProfileInput)*100)).toFixed(2));
  
        })
  

      });


      
      
    }
  }

 

  async setCompletedInputs(){

    /**
     * FİRMA BİLGİLERİ              
     * firma adı                    
     * jenerik adı
     * firma yapısı
     * servis statüsü özel/yetkili
     * vergi no
     * vergi dairesi
     * telefon cep
     * telefon iş
     * email
     * il
     * ilçe
     * adres
     */

    /**
     * İLETİŞİM BİLGİLERİ
     * şirket sahibi
     * Genel Müdür
     * Telefon
     * Email
     * Servis Müdürü
     * Servis Müdürü tel
     * Servis Müdürü Email
     * Servis Danışmanı
     * Servis Danışmanı Tel
     * Servis Danışmanı Email
     * Mesai Saatleri
     */

    /**
     * KURUMSAL BİLGİLER
     * Kuruluş Tarihi
     * TSE 12047
     * TSE 12047 Onay Markası
     * TSE 13168 -  Evet / Hayır
     * MYB (Mesleki Yeterlilik Belgesi)  Evet / Hayır
     * ISO belgesi -  Evet / Hayır
     * Motor Yağı Değ.İzin Belgesi  -  Evet / Hayır
     */

    /**
     * HIZMET BİLGİLER
     * Hizmet verilen markalar (uzmanlık)
     * periyodik bakım
     * mekanik onarım
     * kaporta boya
     * Boyasız Göçük Onarım -  Evet / Hayır
     * Rot Balans Ayarı -  Evet / Hayır
     * Cam Değişim/Tamir -  Evet / Hayır
     * Car Care -  Evet / Hayır
     */

    /**
     * ZİNCİR SERVİSLER GRUBU
     * zincir servisler grubu
     */


    if(this.companyInfo){
       /** FİRMA BİLGİLERİ **/
        /**
       * FİRMA BİLGİLERİ              
       * firma adı                    
       * jenerik adı
       * firma yapısı
       * servis statüsü özel/yetkili
       * vergi no
       * vergi dairesi
       * telefon cep
       * telefon iş
       * email
       * il
       * ilçe
       * adres
       */

       let companyNullInputs = true;

      if(this.companyInfo.name != null){
        //firma adı
        this.completedProfileInput += 1;
      }else{
        companyNullInputs = false;
      }
  
      if(this.companyInfo.title != null){
        //jenerik adı
        this.completedProfileInput += 1;
      }else{
        companyNullInputs = false;
      }

      if(this.companyInfo.company_type_id != null){
        //firma yapısı
        this.completedProfileInput += 1;
      }else{
        companyNullInputs = false;
      }

      if(this.companyInfo.service_status != null){
        //servis statüsü özel/yetkili
        this.completedProfileInput += 1;
      }else{
        companyNullInputs = false;
      }
  
      if(this.companyInfo.vat != null){
        //vergi no
        this.completedProfileInput += 1;
      }else{
        companyNullInputs = false;
      }
  
      if(this.companyInfo.vat_office != null){
        //vergi dairesi
        this.completedProfileInput += 1;
      }else{
        companyNullInputs = false;
      }

      if(this.companyInfo.company_gsm_no != null){
        //telefon cep
        this.completedProfileInput += 1;
      }else{
        companyNullInputs = false;
      }

      if(this.companyInfo.company_tel_no != null){
        //telefon iş
        this.completedProfileInput += 1;
      }else{
        companyNullInputs = false;
      }

      if(this.companyInfo.company_email_address != null){
        //email
        this.completedProfileInput += 1;
      }else{
        companyNullInputs = false;
      }

      if(this.companyInfo.city_id != null){
        //il
        this.completedProfileInput += 1;
      }else{
        companyNullInputs = false;
      }
  
      if(this.companyInfo.district_id != null){
        //ilçe
        this.completedProfileInput += 1;
      }else{
        companyNullInputs = false;
      }
    
      if(this.companyInfo.address != null){
        //adres
        this.completedProfileInput += 1;
      }else{
        companyNullInputs = false;
      }

      
      /** EOF FİRMA BİLGİLERİ **/

      
      this.nullInputGroups.company_info = companyNullInputs;

     let completeSections = {
      section1 : false,
      section2 : false,
      section3: false
     }
    
      /** KURUMSAL BİLGİLER **/

      this.nullInputGroups.corporate_information = false;

      if(this.companyInfo && this.companyInfo.year_of_foundation){
        //kuruluş tarihi
        this.completedProfileInput += 1;
        this.nullInputGroups.corporate_information = true;
      }



      await this.getCompanyCertificates.watch({where:{_and:{company_id: {_eq: this.companyId}, certificate_id:{_eq:1} }}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(compResp=>{
        if(compResp.errors){
          this.alertifyService.error(compResp.errors[0].message);
        }
        if(compResp.data){
          if(compResp.data.company_certificates && compResp.data.company_certificates.length > 0){
             //tse 12047 
            this.completedProfileInput += 1;
            this.nullInputGroups.corporate_information = true;
            if(compResp.data.company_certificates[0].vehicle_brand_id > 0){
               //tse 12047 onay markası
              this.completedProfileInput += 1;
            }else{
              this.nullInputGroups.corporate_information = false;
             }

          }
        }
      });




      await this.getCompanyCertificates.watch({where:{_and:{company_id: {_eq: this.companyId}, certificate_id:{_eq:2} }}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(compResp=>{
        if(compResp.errors){
          this.alertifyService.error(compResp.errors[0].message);
        }
        if(compResp.data){
          if(compResp.data.company_certificates && compResp.data.company_certificates.length > 0){
            //tse 13168
            this.completedProfileInput += 1; 
            this.nullInputGroups.corporate_information = true;

          }
        }
      });



      await this.getCompanyCertificates.watch({where:{_and:{company_id: {_eq: this.companyId}, certificate_id:{_eq:5} }}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(compResp=>{
        if(compResp.errors){
          this.alertifyService.error(compResp.errors[0].message);
        }
        if(compResp.data){
          if(compResp.data.company_certificates && compResp.data.company_certificates.length > 0){
            //mesleki yeterlilik belgesi
            this.completedProfileInput += 1; 
            this.nullInputGroups.corporate_information = true;

          }
        }
      });




      await this.getCompanyCertificates.watch({where:{_and:{company_id: {_eq: this.companyId}, certificate_id:{_eq:6} }}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(compResp=>{
        if(compResp.errors){
          this.alertifyService.error(compResp.errors[0].message);
        }
        if(compResp.data){
          if(compResp.data.company_certificates && compResp.data.company_certificates.length > 0){
            //ISO belgesi
            this.completedProfileInput += 1; 
            this.nullInputGroups.corporate_information = true;
          }else{
           
     
          }
        }
      });




      await this.getCompanyCertificates.watch({where:{_and:{company_id: {_eq: this.companyId}, certificate_id:{_eq:7} }}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(compResp=>{
        if(compResp.errors){
          this.alertifyService.error(compResp.errors[0].message);
        }
        if(compResp.data){
          if(compResp.data.company_certificates && compResp.data.company_certificates.length > 0){
            //Motor Yağı erişim izin belgesi
            this.completedProfileInput += 1; 
            this.nullInputGroups.corporate_information = true;

          }else{
        
          }
        }
      });






      /** EOF KURUMSAL BİLGİLER **/

      /** HIZMET BİLGİLER **/
/**
     * HIZMET BİLGİLER
     * Hizmet verilen markalar (uzmanlık) 
     * periyodik bakım
     * mekanik onarım
     * kaporta boya
     * Boyasız Göçük Onarım -  Evet / Hayır
     * Rot Balans Ayarı -  Evet / Hayır
     * Cam Değişim/Tamir -  Evet / Hayır
     * Car Care -  Evet / Hayır
     */

      
      if(this.companyInfo.company_vehicle_brands && this.companyInfo.company_vehicle_brands.length > 0){
        //Hizmet verilen markalar (uzmanlık)
        this.completedProfileInput += 1;
        this.nullInputGroups.brands = true;
      }else{
        this.nullInputGroups.brands = false;
      }
        if(this.companyInfo.company_services && this.companyInfo.company_services.length > 0){
          this.nullInputGroups.services = true;
          let serviceIds = [ 1,2,3,7,8,9,10,19 ]

          this.nullInputGroups.services = false;
          await serviceIds.forEach(element => {
            let isHave = this.companyInfo?.company_services.filter((m:any)=>{ return m.company_service_type_id  == element});
            if(isHave.length > 0){
              this.nullInputGroups.services = true;
              this.completedProfileInput += 1;
            }
          });
        
        
        }else{
          this.nullInputGroups.services = false;
        }


      /** EOF HIZMET BİLGİLER **/



      

      /** ZİNCİR SERVİS GRUPLARI **/

      if(this.companyInfo.company_service_groups && this.companyInfo.company_service_groups.length > 0){
        this.completedProfileInput += 1; // zincir servis grubu
        this.nullInputGroups.service_groups = true;

      }else{
       
        this.nullInputGroups.service_groups = false;
      }

      /** ZİNCİR SERVİS GRUPLARI **/

      /** ÇALIŞMA SAATLERİ **/
      if(this.companyInfo.company_acceptances && this.companyInfo.company_acceptances.length > 0){
        this.completedProfileInput += 1;
        this.nullInputGroups.work_hours = true;
      }else{
        this.nullInputGroups.work_hours = false;
      }
      /** ÇALIŞMA SAATLERİ **/

      
      if(this.companyInfo.company_service_groups.length > 0){
        this.completedProfileInput += 1;
      }

      /** EK **/
      if(this.companyInfo.service_additionals.length == 3){
        this.completedProfileInput += 1;
        this.completedProfileInput += 1;
        this.completedProfileInput += 1;
        this.nullInputGroups.ek = true;
      }else{
        this.nullInputGroups.ek = false;
      }
      /** EOF EK **/
      

   

      await this.getServiceCompanyContacts.watch({where:{company_id:{_eq:this.companyId}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{
        if(resp.errors){
          this.alertifyService.error(resp.errors[0].message);
        }
        if(resp.data){
          this.companyContacts = resp.data.service_company_contacts[0];

            let cotactNullInputs = true;
            /** İLETİŞİM BİLGİLERİ **/

          if(this.companyContacts){
            if(this.companyContacts.company_owner != null){
              //Şirket Sahibi
              this.completedProfileInput += 1;
            }else{
              cotactNullInputs = false;
              this.requiredButEmpty.push('Zincir servis grubu');
            }

            if(this.companyContacts.general_manager != null){
              //Genel Müdür
              this.completedProfileInput += 1;
            }else{
              cotactNullInputs = false;
            }

            if(this.companyContacts.general_manager_phone != null){
              //Telefon
              this.completedProfileInput += 1;
            }else{
              cotactNullInputs = false;
            }
            if(this.companyContacts.general_manager_email != null){
              //Email
              this.completedProfileInput += 1;
            }else{
              cotactNullInputs = false;
            }

            if(this.companyContacts.service_manager != null){
              //Servis Müdürü
              this.completedProfileInput += 1;
            }else{
              cotactNullInputs = false;
            }

            if(this.companyContacts.service_manager_phone != null){
              //Servis Müdürü tel
              this.completedProfileInput += 1;
            }else{
              cotactNullInputs = false;
            }

            if(this.companyContacts.service_manager_email != null){
              //Servis Müdürü Email
              this.completedProfileInput += 1;
            }else{
              cotactNullInputs = false;
            }

            if(this.companyContacts.service_consultant != null){
              //Servis Danışmanı
              this.completedProfileInput += 1;
            }else{
              cotactNullInputs = false;
            }

            if(this.companyContacts.service_consultant_phone != null){
              //Servis Danışmanı Tel
              this.completedProfileInput += 1;
            }else{
              cotactNullInputs = false;
            }

            if(this.companyContacts.service_consultant_email != null){
              //Servis Danışmanı Email
              this.completedProfileInput += 1;
            }else{
              cotactNullInputs = false;
            }

            if(this.companyContacts.off_hours_phone != null){
              //Mesai Saatleri
              this.completedProfileInput += 1;
            }else{
              cotactNullInputs = false;
            }
            this.nullInputGroups.contact_info = cotactNullInputs;
            /** EOF İLETİŞİM BİLGİLERİ **/
          }
          this.profilePercentage = parseFloat((100-(((this.totalProfileInput-this.completedProfileInput)/this.totalProfileInput)*100)).toFixed(2));

          if(this.profilePercentage > 100){
            this.profilePercentage = 100;
          }
          this.nullInputs.emit({'general_inputs': this.nullInputGroups});
        }
      })
    }
    
   

  }


}
