<div class="w-100">
    <form class="form w-100" novalidate="novalidate" action="#" [formGroup]="signUpFormGroup"
    (ngSubmit)="signUpFormSubmit()">
        <div class="row fv-row mb-7">
            <div class="col-xl-6">
                <label class="form-label fw-bolder text-dark fs-6">Ad</label>
                <input class="form-control form-control-sm form-control-solid" type="text" name="name"
                    autocomplete="off" placeholder="Ad" formControlName="name" />
            </div>
            <div class="col-xl-6">
                <label class="form-label fw-bolder text-dark fs-6">Soyad</label>
                <input class="form-control form-control-sm form-control-solid" type="text" name="surname"
                    autocomplete="off" placeholder="Soyad" formControlName="surname" />
            </div>
        </div>
        <div class="row fv-row mb-7">
            <div class="col-xl-6">
                <label class="form-label fw-bolder text-dark fs-6">Email</label>
                <input class="form-control form-control-sm form-control-solid" type="email" placeholder=""
                    name="email" autocomplete="off" placeholder="Email" formControlName="email" />
            </div>
            <div class="col-xl-6">
                <label class="form-label fw-bolder text-dark fs-6">Telefon</label>
                <input class="form-control form-control-sm form-control-solid" type="number" placeholder=""
                    name="Cep Telefonu" autocomplete="off" placeholder="Email" formControlName="phone" />
            </div>
        </div>  
        
        <div class="row fv-row mb-3 fv-plugins-icon-container">
            <div class="col-md-6">
                <label class="form-check form-check-custom form-check-solid form-check-inline">
                    <input class="form-check-input" type="checkbox" name="toc" value="1" formControlName="check_gizlilik">
                    <span class="form-check-label fw-bold text-gray-700 fs-6">
                    <a target="_blank" [owlRouterLink]="['/gizlilik-sozlesmesi']" class="ms-1 link-primary">Gizlik Politikası</a></span>
                </label>
                <div class="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div class="col-md-6">
                <label class="form-check form-check-custom form-check-solid form-check-inline">
                    <input class="form-check-input" type="checkbox" name="toc" value="1" formControlName="check_riza">
                    <span class="form-check-label fw-bold text-gray-700 fs-6">
                    <a target="_blank" [owlRouterLink]="['/acik-riza-metni']" routerLinkActive="router-link-active"  class="ms-1 link-primary">Açık Rıza Metni</a></span>
                </label>
                <div class="fv-plugins-message-container invalid-feedback"></div>
            </div>
            </div>
      
        <div class="row fv-row mb-3 fv-plugins-icon-container">
            <div class="col-md-6">
                <label class="form-check form-check-custom form-check-solid form-check-inline">
                    <input class="form-check-input" type="checkbox" name="toc" value="1"  formControlName="check_aydinlatma">
                    <span class="form-check-label fw-bold text-gray-700 fs-6">
                    <a target="_blank" [owlRouterLink]="['/aydinlatma-metni']" class="ms-1 link-primary">Aydınlatma Metni</a></span>
                </label>
                <div class="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div class="col-md-6">
                <label class="form-check form-check-custom form-check-solid form-check-inline">
                    <input class="form-check-input" type="checkbox" name="toc" value="1"  formControlName="check_sozlesme">
                    <span class="form-check-label fw-bold text-gray-700 fs-6">
                    <a target="_blank" [owlRouterLink]="'/kullanici-sozlesmesi'" x class="ms-1 link-primary">Kullanıcı Sözleşmesi</a></span>
                </label>
                <div class="fv-plugins-message-container invalid-feedback"></div>
            </div>
        </div>
       
        <div class="text-center">
            <button type="submit" class="btn btn-sm btn-primary" [disabled]="!signUpFormGroup.valid">
                <span class="indicator-label">Kaydet</span>
                <span class="indicator-progress">Please wait...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
            </button>
        </div>
    </form>
</div>