import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-credit-card',
  templateUrl: './credit-card.component.html',
  styles: [
  ],
  styleUrls:['./credit-card.component.css'],
  animations: [
    trigger('flipState', [
      state('active', style({
        transform: 'rotateY(179deg)'
      })),
      state('inactive', style({
        transform: 'rotateY(0)'
      })),
      transition('active => inactive', animate('500ms ease-out')),
      transition('inactive => active', animate('500ms ease-in'))
    ])
  ]
})

export class CreditCardComponent implements OnInit {

  @Input() cardNumber:number = 11111111111;
  @Input() cardName:String  | undefined;
  @Input() cardExpire:String  | undefined;
  @Input() ccv2:String  | undefined;
  @Input() turnCard:boolean  | undefined;
  public number1: string | undefined;
  public number2: string | undefined;
  public number3: string | undefined;
  public number4: string | undefined;
  @Input() flipped: boolean = false;
  public cardType:number = 0;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(){

    
    if(this.cardNumber){
    
      this.cardType = this.cardNumber[0];
      let expNumber = this.cardNumber.toString().split(' ')
      this.number1 = expNumber[0];
      this.number2 = expNumber[1];
      this.number3 = expNumber[2];
      this.number4 = expNumber[3];
    }
  }

  isFlipped(){
    return (this.flipped) ? 'active': 'inactive';
  }
  

}
