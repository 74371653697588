import { Injectable } from "@angular/core";
import { gql, Query } from "apollo-angular";

export interface Country {
  id: Number,
  name: String,
}

interface ResponseArray {
  countries: Country[]
}

@Injectable()
export class COUNTRY_ALL extends Query<ResponseArray>{
  override document = gql`
    query COUNTRY_ALL {
      countries {
        id
        name
        flag
        code
      }
    }
  `;
}

@Injectable()
export class GET_COUNTRIES extends Query<ResponseArray>{
  override document = gql`
    query GET_COUNTRIES {
      countries {
        id
        name
      }
    }
  `;
}