import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface CompanyCertificate {
    active: boolean
    company_id: bigint
    certificate_id: number
    id: number
    certicate?: any
    vehicle_brand_id: bigint
    vehicle_brands: any;
}

interface ResponseArray {
  company_certificates: CompanyCertificate[];
}

interface ResponseSingle {
  company_certificates_by_pk: CompanyCertificate
}

@Injectable()
export class COMPANY_CERTIFICATE_ALL extends Query<ResponseArray> {
  override document = gql`
  query COMPANY_CERTIFICATE_ALL($where: company_certificates_bool_exp) {
    company_certificates(where: $where) {
        active
        certificate_id
        company_id
        vehicle_brand_id
        vehicle_brands{
          id
          name
        }
        id
        certicate {
          name
          active
        }
    }
  }
  `;
}

@Injectable()
export class COMPANY_CERTIFICATE_ID extends Query<ResponseSingle> {
  override document = gql`
    query COMPANY_CERTIFICATE_ID($id: bigint!){
        company_certificates_by_pk(id: $id) {
            active
            certificate_id
            company_id
            vehicle_brand_id
            vehicle_brands{
              id
              name
            }
            id
            certicate {
              name
              active
            }
      }
    }
  `;
}

@Injectable()
export class COMPANY_CERTIFICATE_CREATE extends Mutation<any> {
  // connected_part_id - int | nullable
  override document = gql`
  mutation COMPANY_CERTIFICATE_CREATE(
      $certificate_id: Int, 
      $company_id: Int,
      $active:Boolean,
      $vehicle_brand_id: Int
      $brand_id: Int
      ) {
    insert_company_certificates(objects: {
        certificate_id: $certificate_id, company_id: $company_id,active:$active,vehicle_brand_id:$vehicle_brand_id}) {
      affected_rows
      returning {
        id
      }
    }
  }
  
  `;
}

@Injectable()
export class COMPANY_CERTIFICATE_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
  mutation COMPANY_CERTIFICATE_UPDATE(
      $id:bigint
    $certificate_id: Int, 
    $company_id: Int,
    $active:Boolean,
    $vehicle_brand_id: Int) {
    update_company_certificates(where: {id: {_eq: $id}}, _set: {  certificate_id: $certificate_id, company_id: $company_id,active:$active,vehicle_brand_id:$vehicle_brand_id}) {
      affected_rows
    }
  }
  
  `;
}

@Injectable()
export class COMPANY_CERTIFICATE_MANUEL_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
  mutation COMPANY_CERTIFICATE_UPDATE(
      $id:bigint
      $changes: company_certificates_set_input) {
    update_company_certificates(where: {id: {_eq: $id} }, _set: $changes) {
      affected_rows
    }
  }
  
  `;
}

@Injectable()
export class COMPANY_CERTIFICATE_DELETE extends Mutation<any> {
  override document = gql`
    mutation COMPANY_CERTIFICATE_DELETE($id: bigint!) {
      delete_company_certificates(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}
