import { Injectable } from "@angular/core";
import { gql, Query } from "apollo-angular";
import { OrderStatus } from "./order_status.graphql";
import { Role } from "./roles.graphql";

export interface OrderTransaction {
  id: Number
  role_id: Number
  order_status_id: Number
  next_status_id: Number
  icon: String
  role: Role
  order_status: OrderStatus
  next_status: OrderStatus
}

interface ResponseArray {
  order_transactions: OrderTransaction[]
}

interface ResponseSingle {
  order_transactions_by_pk: OrderTransaction
}

@Injectable()
export class ORDER_TRANSACTIONS extends Query<ResponseArray>{
  override document = gql`
  query ORDER_TRANSACTIONS($role_id: Int!, $order_status_id: Int!) {
    order_transactions(where: {role_id: {_eq: $role_id}, order_status_id: {_eq: $order_status_id}}) {
      id
    	icon
      order_status {
        id
        name
        button_name
        class_name
        create_invoice
      }
      next_status {
        id
        name
        button_name
        class_name
        colour{
          name
        }
      }
    }
  }`;
}
