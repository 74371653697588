import { Injectable } from "@angular/core";
import { gql, Query } from "apollo-angular";

export interface Town {
  id: bigint,
  district_id: Number,
  name: String,
  order_no: Number,
  active: boolean
}

interface ResponseArray {
  towns: Town[]
}

interface ResponseSingle {
  towns_by_pk: Town
}

@Injectable()
export class TOWN_ALL extends Query<ResponseArray> {
  override document = gql`
    query TOWN_ALL($where: towns_bool_exp){
      towns(where: $where) {
        id
        name
      }
    }
  `;
}

@Injectable()
export class TOWN_DISTRICT_ID extends Query<ResponseArray>{
  override document = gql`
  query TOWN_DISTRICT_ID($id: Int!) {
    towns(where: {district_id: {_eq: $id}}) {
      id
      district_id
      name
      code
    }
  }`;
}

@Injectable()
export class TOWN_ID extends Query<ResponseSingle>{
  override document = gql`
  query TOWN_ID($id: bigint!) {
    towns_by_pk(id: $id) {
      active
      code
      district_id
      id
      name
      position_no
    }
  }`;
}