import { Component, Input, OnInit } from '@angular/core';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { PdfService } from 'src/app/services/pdf.service';

@Component({
  selector: 'app-shared-proforma-pdf-generator-v3',
  templateUrl: './proforma-pdf-generator-v3.component.html',
  styles: [
  ]
})
export class ProformaPdfGeneratorV3Component implements OnInit {

  @Input() invoiceInfo:any;

  public docDefinition:TDocumentDefinitions | undefined; 
  public content:any;

  constructor(
    private pdfService: PdfService,
  ) { }

  ngOnInit(): void {
  }

  async ngOnChanges(){
    await this.createProforma();
  }

  async createProforma(){
    this.content = {}


    await this.createHeader();
    await this.createContentLogo();
    await this.createCustomerInfo();
    await this.createProducts();
    await this.createProductTotal();
    await this.createDEscription();
    await this.setInvoice();
  }

  async createHeader(){
 
        this.content = {
          content:[{
            columns: [
              [ {
                text: "",//"Kredi Kartı İle Ödeme",
                fontSize: 10,
                margin: [30, 15, 0, 10]
              }],
              [
                {
                  text: "",//"Periyodik Bakım",
                  bold: true,
                  alignment: 'center',
                  fontSize: 20,
                  margin: [0, 10, 0, 10]
                }
              ],
              [ {
                text: this.invoiceInfo?.invoice_info?.service_date,
                fontSize: 10,
                alignment: 'right',
                margin: [0, 15, 0, 0]
              }],
              
            ]
          }],
          images: {
            invoice: 'https://cdn.destechhasar.com/garantili-oto-servis/invoice/invoice_logo.png',
            invoice_logo: 'https://cdn.destechhasar.com/garantili-oto-servis/invoice/invoice_logo.png'
          },
          
          defaultStyle: {
            fontSize: 9
          },
          styles: {
            titleStyle: {
              fontSize: 8
            },
            tableStyle: {
              fontSize: 8,
              alignment: 'center',
              margin: [0, 10, 0, 10],
              widths: ['30%', '20%', '17.5%', '17.5%', '17.5%']
            },
            customerTableStyle: {
              fontSize: 8,
              alignment: 'center',
              margin: [0, 10, 0, 10],
              widths: ['20%', '30%','20%','30%']
            },
            vehicleTableStyle: {
              fontSize: 8,
              alignment: 'right',
              margin: [300, 0, 0, 10],
              widths: ['*', '50']
            },
            productTableStyle: {
              fontSize: 8,
              alignment: 'center',
              margin: [0, 10, 0, 10],
              widths: ['auto', 'auto','auto','auto','auto','auto','auto']
            },
            productTotalTableStyle: {
              fontSize: 8,
              alignment: 'center',
              margin: [0, 10, 0, 10],
              widths: ['auto', 'auto','auto','auto']
            },
            descriptionTableStyle: {
              fontSize: 8,
              alignment: 'center',
              margin: [0, 10, 0, 10],
              widths: ['auto', 'auto']
            },
          }
        }
    
      }

  async createContentLogo(){
    let gosInfo = [
      this.invoiceInfo?.gos_info?.title+"\n",
      this.invoiceInfo?.gos_info?.company_tel_no+"\n",
      this.invoiceInfo?.gos_info?.company_email_address+"\n",
      this.invoiceInfo?.gos_info?.city+"\n",
      this.invoiceInfo?.gos_info?.district+"\n",
      this.invoiceInfo?.gos_info?.address,
      ];

      let telno = (this.invoiceInfo?.service_info.tel_no) ? this.invoiceInfo?.service_info.tel_no : this.invoiceInfo?.service_info.gsm_no

      let customerInfo = [
        this.invoiceInfo?.service_info?.name+"\n",
        telno+"\n",
        this.invoiceInfo?.service_info.email+"\n",
        this.invoiceInfo?.service_info.city+"\n",
        this.invoiceInfo?.service_info.district+"\n",
        this.invoiceInfo?.service_info.address+"\n"
      ]

      this.content.content.push([{
        columns: [
          [ {
            text: gosInfo,
            fontSize: 10,
            margin: [0, 15, 0, 0],
            color: "#96bc33",
            bold:true
          }],
          [
            {
              image: 'invoice',
              width: 100,
              alignment: 'center'
            }
          ],
          [ {
            text: customerInfo,
            fontSize: 10,
            alignment: 'right',
            margin: [0, 15, 0, 0],
            color: "#96bc33",
            bold:true
          }],
          
        ]
      }
      ])

    
    
  }

  async createCustomerInfo(){

    let customerName = (this.invoiceInfo?.invoice_info?.customer_invoice_type == 1) ? this.invoiceInfo?.invoice_info?.customer_name + " " + this.invoiceInfo?.invoice_info?.customer_surname : this.invoiceInfo?.invoice_info?.company_name;
    let identity = (this.invoiceInfo?.invoice_info?.customer_invoice_type == 1) ? this.invoiceInfo?.invoice_info?.identity_no : this.invoiceInfo?.invoice_info?.tax_number +" / "+ this.invoiceInfo?.invoice_info?.tax_administration;
    let totalContinue = {
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
      
        widths: [ '20%', '30%', '20%', '30%' ],

        body: [
          [
            {
              colSpan:2,
              text:'Müşteri Bilgileri',
              border: [true, true, true, true],
              bold: true,
              alignment: 'center',
              fontSize: 10,
              color: '#015029'
            },
            {
            
              text:'',
              border: [true, true, true, true],
            },
            {
              colSpan:2,
              text:'Araç Bilgileri',
              border: [true, true, true, true],
              bold: true,
              alignment: 'center',
              fontSize: 10,
              color: '#015029'
            },
            {
            
              text:'',
              border: [true, true, true, true],
            }
          ],
          [ 
            {
              text: 'Müşteri Adı/Ünvanı',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: customerName
            }, 
            {
              text: 'Şasi',
              color: "#96bc33",
              bold:true
            },
            {
              text: this.invoiceInfo?.vehicle_info?.chassis_no
            } 
          ],
          [ 
            {
              text: 'Tel No',
              color: "#96bc33",
              bold:true
            }, 
            {
              text:this.invoiceInfo?.invoice_info?.tel_number
            }, 
            {
              text: 'Plaka',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: this.invoiceInfo?.vehicle_info?.plate
            }  
          ],
          [ 
            {
              text: 'Mail Adresi',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: this.invoiceInfo?.invoice_info?.customer_email
            }, 
            {
              text: 'Marka Model',
              color: "#96bc33",
              bold:true
            },
            {
              text: this.invoiceInfo?.vehicle_info?.brand + ' / ' + this.invoiceInfo?.vehicle_info?.model
            }
          ],
          [ 
            {
              text: 'İl',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: this.invoiceInfo?.invoice_info?.city
            }, 
            {
              text: 'Versiyon',
              color: "#96bc33",
              bold:true
            }, 
            {
              text:this.invoiceInfo?.vehicle_info?.model_type
            } 
          ],
          [ 
            {
              text: 'İlçe',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: this.invoiceInfo?.invoice_info?.district
            }, {
              text: 'Yıl',
              color: "#96bc33",
              bold:true
            }, {
              text: '2021'
            } ],
          [ 
            {
              text: 'Adres',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: this.invoiceInfo?.invoice_info?.address
            }, 
            {
              text: 'Yakıt',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: this.invoiceInfo?.vehicle_info?.fuel
            } 
          ],
          
          [ 
            {
              text: 'TCKN / VN',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: identity
            }, 
            {
              text: 'Vites',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: this.invoiceInfo?.vehicle_info?.gear
            } ],
      
        ]
      },
      style: 'customerTableStyle',
      margin: [0, 30, 0, 0],
      alignment: 'left',
    
    }


    
    this.content.content.push(totalContinue);
    
  }

  async createProducts(){

    let table = {
      headerRows: 1,
      widths: ['10%','40%','10%','15%','5%','10%','10%'],
      margin:[0,10,0,0],
      body: [
        [
          {
            colSpan: 7,
            text:'İşlemler',
            border: [true, true, true, true],
            bold: true,
            alignment: 'center',
            fontSize: 10,
            color: '#015029'
          },
          {
          
            text:'',
            border: [true, true, true, true]
          },
          {
          
            text:'',
            border: [true, true, true, true],
          },
          {
          
            text:'',
            border: [true, true, true, true],
          },
          {
          
            text:'',
            border: [true, true, true, true],
          },
          {
          
            text:'',
            border: [true, true, true, true],
          },
          {
          
            text:'',
            border: [true, true, true, true],
          }
        ],
        [
          {
          
            text:'Referans No',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33",
          },
          {
            colSpan: 2,
            text:'Açıklama',
            border: [true, true, false, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33",
          },
          {
          
            text:'',
            border: [false, true, false, true],
          
          },
          {
          
            text:'YP. Tipi',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33",
          },
          {
          
            text:'Adet',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33",
          },
          {
          
            text:'Birim Fiyat',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33",
          },
          {
          
            text:'Toplam',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33"
          },
        ]
      ]
    }
    if(this.invoiceInfo?.invoice_parts && this.invoiceInfo?.invoice_parts.length > 0){
      await this.invoiceInfo?.invoice_parts.forEach(element => {

        table.body.push([
          {
          
            text: element.part_code,
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          {
            
            text:element.part_name,
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          {
            text:(element.part_unit_type_id == 3 ? 'İşçilik' : 'Parça') ,
            border: [true, true, false, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          {
          
            text:element.part_type,
            border: [true, true, true, true],
            bold: false,
          
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          {
          
            text: element.part_unit_formatted,
            border: [true, true, true, true],
            bold: false,
            alignment: 'right',
            fontSize: 8,
            color: "#000"
          },
          {
          
            text:element.part_unit_price_formatted+'₺',
            border: [true, true, true, true],
            bold: false,
            alignment: 'right',
            fontSize: 8,
            color: "#000"
          },
          {
          
            text:element.part_no_vat_price_formatted+'₺',
            border: [true, true, true, true],
            bold: false,
            alignment: 'right',
            fontSize: 8,
            color: "#000"
          },
        ]);
      });
    }

    

    this.content.content.push({table:table, style: 'productTableStyle', margin: [0, 30, 0, 0]});

  }  
 
  async createProductTotal(){

    let table = {
      headerRows: 1,
      widths: ['30%','25%','20%','25%'],
      margin:[300,10,0,0],
      
      body: [
        [
          {
          
            text:'',
            border: [true, true, true, true],
            bold: true,
            alignment: 'center',
            fontSize: 10,
            color: "#96bc33"
          },
          {
            text: 'Tutar',
            border: [true, true, true, true],
            bold: true,
            alignment: 'center',
            fontSize: 10,
            color: "#96bc33"
          },
          {
            text: 'İndirim',
            border: [true, true, true, true],
            bold: true,
            alignment: 'center',
            fontSize: 10,
            color: "#96bc33"
          },
          {
            text: 'Toplam',
            border: [true, true, true, true],
            bold: true,
            alignment: 'center',
            fontSize: 10,
            color: "#96bc33"
          }
        ],
        
      ]
    }

    let noLabourPrice = this.invoiceInfo?.total_amounts?.total_witouth_labour_formatted;

    table.body.push([
      {
        text: 'Yedek Parça',
        border: [true, true, true, true],
        bold: true,
        alignment: 'left',
        fontSize: 8,
        color: "#96bc33"
      },

      {
        text: noLabourPrice+'₺',
        border: [true, true, true, true],
        bold: false,
        alignment: 'right',
        fontSize: 8,
        color: "#000"
      },

      {
        text: '0',
        border: [true, true, true, true],
        bold: false,
        alignment: 'right',
        fontSize: 8,
        color: "#000"
      },

      {
        text: noLabourPrice+'₺',
        border: [true, true, true, true],
        bold: false,
        alignment: 'right',
        fontSize: 8,
        color: "#000"
      },

    ],[
      {
        text: 'İşçilik',
        border: [true, true, true, true],
        bold: true,
        alignment: 'left',
        fontSize: 8,
        color: "#96bc33"
      },

      {
        text: this.invoiceInfo?.total_amounts?.labour_price_formatted+'₺',
        border: [true, true, true, true],
        bold: false,
        alignment: 'right',
        fontSize: 8,
        color: "#000"
      },

      {
        text: '0',
        border: [true, true, true, true],
        bold: false,
        alignment: 'right',
        fontSize: 8,
        color: "#000"
      },

      {
        text:  this.invoiceInfo?.total_amounts?.labour_price_formatted+'₺',
        border: [true, true, true, true],
        bold: false,
        alignment: 'right',
        fontSize: 8,
        color: "#000"
      },

    ],[
      {
        text: 'Kdv',
        border: [true, true, true, true],
        bold: true,
        alignment: 'left',
        fontSize: 8,
        color: "#96bc33"
      },

      {
        text:  this.invoiceInfo?.total_amounts?.vat+'%',
        border: [true, true, true, true],
        bold: false,
        alignment: 'right',
        fontSize: 8,
        color: "#000"
      },

      {
        text: '0',
        border: [true, true, true, true],
        bold: false,
        alignment: 'right',
        fontSize: 8,
        color: "#000"
      },

      {
        text: this.invoiceInfo?.total_amounts?.vat_price_formatted+'₺',
        border: [true, true, true, true],
        bold: false,
        alignment: 'right',
        fontSize: 8,
        color: "#000"
      },

    ],[
      {
        text: 'Genel Toplam',
        border: [true, true, true, true],
        bold: true,
        alignment: 'left',
        fontSize: 8,
        color: "#96bc33"
      },

      {
        text: this.invoiceInfo?.total_amounts?.value_in_vat_formatted+'₺',
        border: [true, true, true, true],
        bold: false,
        alignment: 'right',
        fontSize: 8,
        color: "#000"
      },

      {
        text: '0',
        border: [true, true, true, true],
        bold: false,
        alignment: 'right',
        fontSize: 8,
        color: "#000"
      },

      {
        text: this.invoiceInfo?.total_amounts?.value_in_vat_formatted+'₺',
        border: [true, true, true, true],
        bold: false,
        alignment: 'right',
        fontSize: 8,
        color: "#000"
      },

    ])


    this.content.content.push({table:table, style: 'productTotalTableStyle', margin: [300, 30, 0, 0]});
  }
 
  async createDEscription(){
    let table = {
      headerRows: 1,
      widths: ['20%','80%'],
      margin:[0,50,0,0],
      
      body: [
        [
          {
            colSpan:2,
            text:'Açıklamalar',
            border: [true, true, true, true],
            bold: true,
            alignment: 'center',
            fontSize: 8,
            color: "#96bc33"
          },
          {
            text: '',
            border: [false, true, true, true],
            bold: false,
            alignment: 'center',
            fontSize: 8,
            color: "#000"
          },
          
        ],
        [
          {
            
            text:'Ödeme Tipi',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33"
          },
          {
            text: this.invoiceInfo?.invoice_info?.payment_type,
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          
        ],
        [
          {
            
            text:'Randevu Tarihi',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33"
          },
          {
            text: this.invoiceInfo?.invoice_info?.service_date,
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          
        ],
        [
          {
            
            text:'İş Türü',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33"
          },
          {
            text: this.invoiceInfo?.invoice_info?.order_type,
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          
        ],

        [
          {
            
            text:'İş Türü Detayı',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33"
          },
          {
            text: this.invoiceInfo?.invoice_info?.order_type,
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          
        ],

        [
          {
            
            text:'Müşteri Notu',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33"
          },
          {
            text: this.invoiceInfo.invoice_info?.customer_note,
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          
        ]
        
      ]
    }
    this.content.content.push({table:table, style: 'descriptionTableStyle', margin: [0, 10, 0, 0]});
    
  }

  invoiceChange(event:any)
  {
    switch (event.target.value) {
      case "preview":
        if(this.docDefinition != undefined){
        this.pdfService.preview(this.docDefinition)
      }
        //this.pdfService.generator("preview", this.invoiceBody.nativeElement)
        break;
      case "download":
        if(this.docDefinition != undefined){
        this.pdfService.download(this.docDefinition)
        }
        break;
      case "print":
        if(this.docDefinition != undefined){
        this.pdfService.print(this.docDefinition)
        }
        break;
      default:
        break;
    }
  }

  async setInvoice(){

    this.docDefinition = this.content;
  }

 

}
