import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: [],
})
export class WelcomeComponent implements OnInit {

  isLogin: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  
    const token = localStorage.getItem('access_token');
    window.location.href = 'https://garantiliotoservis.com';



  }

  ngOnInit(): void {

    this.isLogin = this.authService.isLogin
    this.loginCheck()
  }

  ngOnChanges(){

  }


  goToPanel() {
    if (this.isLogin && this.authService.userRole) {
      this.router.navigateByUrl(this.authService.userRole);
    }
  }

  loginCheck() {
    if (!this.isLogin) {
      const token = localStorage.getItem('access_token');
      if (token) {
        this.authService.verify().subscribe(response => {
          if (response.status) {
            this.authService.isLogin = this.isLogin = true;
          }
        })
      }
    }
  }
}
