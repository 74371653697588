<style>
 
    .bg-rs{
        background-color: #99CC11 !important;
    }

    .rs-green{
        color: #004422 !important;

    }

    .map{
        width: 100%;
        height: 220px;
    }
</style>

<!--begin::Post-->
<div class="post d-flex flex-column-fluid" id="kt_post">
        <!--begin::Container-->
        <div id="kt_content_container" class="container-xxl">
            <!--begin::Contact-->
            <div class="card">
                <!--begin::Body-->
                <div class="card-body p-lg-17">

                    <div class="row g-5 mb-5 mb-lg-15">
                        <div class="col-sm-12">
                            <h1 class="fw-bolder text-dark mb-9" style="text-align: left !important; color: #004422 !important;">{{companyDetail?.name}}</h1>
                            <app-shared-rate *ngIf="isLogin" [companyId]="companyDetail?.id" ></app-shared-rate>
                        </div>
                        

                        <div class="col-sm-6" >
                            <!--begin::Address-->
                            <div class="text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-lg-100" #addressElement >
                                <!--begin::Icon-->
                                <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg-->
                                <span class="svg-icon svg-icon-3tx svg-icon-primary">
                                    <i class="fas fa-map-marker-alt" style="color: #004422; font-size: 30px;"></i>
                                </span>
                                <!--end::Svg Icon-->
                                <!--end::Icon-->
                                <!--begin::Subtitle-->
                                <h1 class="text-dark fw-bolder my-5">Adres</h1>
                                <!--end::Subtitle-->
                                <!--begin::Description-->
                                <div class="text-gray-700 fs-3 fw-bold">{{companyDetail?.address}}
                                    <p>
                                        <span >{{companyDetail?.district?.name}} /  </span>
                                    
                                        <span >{{companyDetail?.City?.name}}</span>
                                    </p>
                                    
                                    
                                    </div>
                                <!--end::Description-->
                            </div>
                            <!--end::Address-->
                        </div>

                        <div class="col-sm-6">
                            <div class="map" #mapElement></div>
                        </div>
                            
                    </div>
                    <!--begin::Row-->
                    <div class="row g-5 mb-5 mb-lg-15">
                        <!--begin::Col-->
                        <div class="col-sm-6">
                            <!--begin::Phone-->
                            <div class="text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-lg-100">
                                
                                    <!--begin::Icon-->
                                    <span class="svg-icon svg-icon-3tx svg-icon-primary">
                                        <i class="fas fa-phone" style="color: #004422; font-size: 30px;"></i>
                                    </span>
                                    <!--end::Icon-->
                                    <!--begin::Subtitle-->
                                    <h1 class="text-dark fw-bolder my-5">Telefon</h1>
                                    <!--end::Subtitle-->
                                    <!--begin::Number-->
                                    <div class="text-gray-700 fw-bold fs-2" *ngIf="companyDetail && companyDetail.company_tels !== undefined">
                                        
                                        <span *ngFor="let item of companyDetail.company_tels">
                                            <a (click)="sendToGA('phone',item.code)">{{item.code}} </a></span>
                                    </div>
                                    <!--end::Number-->
                                
                            </div>
                        </div>
                        <!--end::Col-->

                        <div class="col-sm-6">
                            <!--begin::Address-->
                            <div class="text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-lg-100">
                                      
                                <!--begin::Icon-->
                                <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg-->
                                <span class="svg-icon svg-icon-3tx svg-icon-primary">
                                    <i class="fas fa-envelope"  style="color: #004422; font-size: 30px;"></i>
                                </span>
                                <!--end::Svg Icon-->
                                <!--end::Icon-->
                                <!--begin::Subtitle-->
                                <h1 class="text-dark fw-bolder my-5">E-Posta</h1>
                                <!--end::Subtitle-->
                                <!--begin::Description-->
                                <div class="text-gray-700 fs-3 fw-bold" *ngIf="companyDetail && companyDetail?.company_emails !== undefined">
                                   
                                        <span *ngFor="let item of companyDetail.company_emails">
                                            <a href="mailto:{{item.email}}">{{item.email}} </a>  </span>  
                                   
                                </div>
                                <!--end::Description-->
                          
                        </div>
                        <!--end::Address-->
                        </div>
                      
                    </div>
                    <!--end::Row-->
                    <div class="row mb-3" *ngIf="companyDetail !== undefined && companyDetail.company_evaluations !== undefined">
                        <!--begin::Col-->
                       <div class="col-sm-3 mb-3 " *ngFor="let item of companyDetail.company_evaluations[0].company_evaluation_values; index as i;">
                          
                           <!--begin::Phone-->
                           <div class="text-center bg-rs card-rounded d-flex flex-column justify-content-center p-10 h-lg-100">
                               <!--begin::Icon-->
                               <!--SVG file not found: icons/duotune/finance/fin006.svgPhone.svg-->
                               <!--end::Icon-->
                               <!--begin::Subtitle-->
                             
                               <h3 class="text-white fw-bolder my-5">{{item.evaulation_type.name}}</h3>
                               <!--end::Subtitle-->
                               <!--begin::Number-->
                               <div class="text-gray-700 fw-bold fs-2">
                                   <i class="fa fa-star me-1 rs-green fs-5" *ngFor="let i of numSequence(item.value)"></i>
                               </div>
                               <!--end::Number-->
                           </div>
                           <!--end::Phone-->
                       </div>
                   </div>

                   <div class="card mb-4 px-lg-12 py-lg-10 " style="padding-bottom: 0px !important;">
                    <div class="row">
                    <div class="col-md-6 pe-lg-10  my-5">
                        <!--begin::Block-->
                        <div class="pb-lg-10">
                            <!--begin::Title-->
                            <h2 class="fw-bolder text-dark mb-8">Sahip Olduğu Hizmet Belgeleri:</h2>
                            <!--end::Title-->
                            <!--begin::List-->
                            <ul class="fs-4 fw-bold mb-6" *ngIf="companyDetail !== undefined &&companyDetail.company_certificates !== undefined">
                                <li *ngFor="let item of companyDetail.company_certificates">
                                    <span class="text-gray-700">{{item.certicate.name}} </span>
                                </li>
                                
                            </ul>
                            <!--end::List-->
                            
                           
                            <!--begin::Title-->
                            <h2 class="fw-bolder text-dark mb-8">Sunulan Hizmetler:</h2>
                            <!--end::Title-->
                            <!--begin::List-->
                            <ul class="fs-4 fw-bold mb-6" *ngIf="companyDetail !== undefined && companyDetail.company_services">
                                <li *ngFor="let item of companyDetail.company_services">
                                    <span class="text-gray-700">{{item.company_service_type.name}}</span>
                                </li>
                
                            </ul>
                            <!--end::List-->
                            <!--begin::Text-->
                            <div class="fs-6 fw-bold" style="color: #004422;" 
                            *ngIf=" companyDetail !== undefined && companyDetail.activation_date !== undefined && companyDetail.activation_date != '0001-01-01T00:00:00+00:00'">
                                Bu servis {{ companyDetail.activation_date | date:'dd.MM.YYYY' }} tarihinden beri üyemizdir.
                            </div>
                            <!--end::Text-->
                        </div>
                        <!--end::Block-->
                        <!--begin::Card-->
                   
                    <!--end::Card-->
                    </div>
                    <div class="col-md-6 pe-lg-10  my-5">
                        <div *ngIf="companyDetail !== undefined && companyDetail.company_vehicle_brands !== undefined && companyDetail.company_vehicle_brands.length > 0">
                            <!--begin::Title-->
                            <h2 class="fw-bolder text-dark mb-7">TSE 12047 Kapsamında Yetkili Markalar:</h2>
                            <!--end::Title-->
                            <!--begin::List-->
                            <ul >
                                <li *ngFor="let item of companyDetail.company_vehicle_brands">
                                    <span class="fs-4 fw-bold text-gray-700">{{item.vehicle_brand.name}}</span>
                                </li>
                            </ul>
                            <!--end::List-->
                        </div>
                        
                        <h3  *ngIf="companyDetail && companyDetail.companys_franchises !== undefined && companyDetail.companys_franchises.length > 0 ">Şirket Bayileri</h3>
                        <div class="d-flex gap-2 mb-2" *ngIf="companyDetail && companyDetail.companys_franchises !== undefined">
                            <a href="#"  *ngFor="let franchises of companyDetail.companys_franchises">
                                <img src="assets/images/company-franchise/{{franchises.franchise_network.logo}}" class="w-100px" alt="" />
                            </a>
                    
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="card mb-4 bg-light text-center" *ngIf="companyDetail && companyDetail.company_social_medias !== undefined && companyDetail.company_social_medias.length > 0">
                        <h3 class="pt-10">Sosyal Medya Hesapları</h3>
                        <!--begin::Body-->
                        <div class="card-body py-12">
                            <!--begin::Icon-->
                            <a href="{{item.social_media_link}}" target="_blank" class="mx-4" *ngFor="let item of companyDetail.company_social_medias">
                                <img src="assets/media/svg/brand-logos/facebook-4.svg" *ngIf="item.social_media.name === 'Facebook'" class="h-30px my-2" alt="" />
                                <img src="assets/media/svg/brand-logos/instagram-2-1.svg" *ngIf="item.social_media.name === 'Instagram'" class="h-30px my-2" alt="" />
                                <img src="assets/media/svg/brand-logos/github.svg" *ngIf="item.social_media.name == 'Github'" class="h-30px my-2" alt="" />
                                <img src="assets/media/svg/brand-logos/behance.svg" *ngIf="item.social_media.name == 'Behance'" class="h-30px my-2" alt="" />
                                <img src="assets/media/svg/brand-logos/pinterest-p.svg" *ngIf="item.social_media.name == 'Pinterest'" class="h-30px my-2" alt="" />
                                <img src="assets/media/svg/brand-logos/twitter.svg" *ngIf="item.social_media.name == 'Twitter'" class="h-30px my-2" alt="" />
                            </a>
                            <!--end::Icon-->
                           
                        </div>
                        <!--end::Body-->
                    </div>
                       
                </div>

                    
                   </div>
                    
                   
                    
                </div>
                <!--end::Body-->
            </div>
            <!--end::Contact-->
        </div>
        <!--end::Container-->
   
</div>
<!--end::Post-->
