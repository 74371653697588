<div class="row">
    <div class="card">
        <div class="card-header">
            <div class="card-title">
                <h3>İlave Parça Ekleme</h3>
                
            </div>
            <div class="card-toolbar">
                
                <div class="d-flex align-items-stretch">
                    <div class="d-flex align-items-center border-bottom border-3 ">
                        <div class="btn btn-icon btn-active-light-primary w-300px h-30px w-md-300px h-md-40px" 
                        (click)="searchBoxToggle()">
                        <span style="margin-right: 5px;">Parça aramak için tıklayınız</span>
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2"
                                        rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                                    <path
                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                        fill="black"></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div 
                    [ngClass]="{'show':searchBox}"
                    class="menu menu-sub menu-sub-dropdown p-7 w-365px w-md-600px"
                    style="z-index: 105; position: fixed; inset: 0 20% auto auto; margin: 0px; transform: translate3d(-30px, 65px, 0px);"
                    >

                            <div>
                                <form class="w-100 position-relative mb-3" [formGroup]="formGroup" autocomplete="off" (submit)="search($event)">
                                    <span
                                        class="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2"
                                                rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                                            <path
                                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                fill="black"></path>
                                        </svg>
                                    </span>
                                    <input type="text" class="form-control form-control-flush ps-10" name="search"
                                        value="{{searchText}}" placeholder="Parça İsmi, Parça Kodu..." formControlName="search_key">
                                    <span
                                        class="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1">
                                        <span
                                            class="spinner-border h-15px w-15px align-middle text-gray-400"></span>
                                    </span>
                                    <span
                                        class="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none">
                                        <span class="svg-icon svg-icon-2 svg-icon-lg-1 me-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                                    transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                                    transform="rotate(45 7.41422 6)" fill="black"></rect>
                                            </svg>
                                        </span>
                                    </span>
                                    <div class="position-absolute top-50 end-0 translate-middle-y" (click)="searchBoxToggle()">
                                        <div class="btn btn-icon w-20px btn-sm btn-active-color-primary me-1"
                                            title="">
                                            <span class="svg-icon svg-icon-2">
                                                <i class="fas fa-times"></i>
                                            </span>
                                        </div>
                                        
                                    </div>
                                </form>
                                <div class="separator border-gray-200 mb-6"></div>

                                
                                
                                
                                <div class="mb-4" >
                                    <div class="d-flex flex-stack fw-bold mb-4">
                                        <span class="text-muted fs-6 me-2">Sonuçlar:</span>
                                    </div>
                                    <div class="scroll-y mh-200px mh-lg-325px"  >

                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    <th>Ücret</th>
                                                    <th>Parça Adı</th>
                                                    <th>Parça Kodu</th>
                                                    <th>Parça Türü</th>
                                                    <th>Marka</th>
                                                    <th>İşlem</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr  *ngFor="let item of searchList">
                                                    <td><div class="symbol symbol-40px me-4">
                                                        <span class="symbol-label bg-light">
                                                            <span class="svg-icon svg-icon-2 svg-icon-primary">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path
                                                                        d="M2 16C2 16.6 2.4 17 3 17H21C21.6 17 22 16.6 22 16V15H2V16Z"
                                                                        fill="black"></path>
                                                                    <path opacity="0.3"
                                                                        d="M21 3H3C2.4 3 2 3.4 2 4V15H22V4C22 3.4 21.6 3 21 3Z"
                                                                        fill="black"></path>
                                                                    <path opacity="0.3" d="M15 17H9V20H15V17Z" fill="black">
                                                                    </path>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                    </div></td>
                                                    <td>{{item.part_no_vat_price}}</td>
                                                    <td>{{item.name}}</td>
                                                    <td>{{item.part_code}}</td>
                                                    <td>{{item.part_type}}</td>
                                                    <td>{{item.brand_name}}</td>
                                                    <td>  
                                                        <input 
                                                        *ngIf=" order && order.status_id < 22"
                                                        type="button" 
                                                        class="btn btn-sm btn-primary" 
                                                        (click)="createPart(item.id,item.part_type_id, item.part_unit,item.part_id,item.part_unit_type,item.brand_labour_hour)" value="Ekle">
                                                    </td>
                                                </tr>

                                                <tr *ngIf="spinnerOn">
                                                    <td colspan="7">
                                                        <div class="col-md-12" style="display:flex; justify-content: space-around;" >
                                                            <div class="spinner-border text-success" role="status" >
                                                               
                                                              </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>

                                        <div 
                                            *ngIf="searchList && searchList.length > 0 && sendSearchRequest"
                                            class="btn btn-primary btn-sm" 
                                            (click)="setPageNumber()" 
                                            style="display: flex; flex-direction: column;">Daha fazla yükle...</div>
                                        
                                    </div>
                                </div>
                                <div class="text-center d-none">
                                    <div class="pt-10 pb-10">
                                        <span class="svg-icon svg-icon-4x opacity-50">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3"
                                                    d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z"
                                                    fill="black"></path>
                                                <path d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z" fill="black">
                                                </path>
                                                <rect x="13.6993" y="13.6656" width="4.42828" height="1.73089"
                                                    rx="0.865447" transform="rotate(45 13.6993 13.6656)"
                                                    fill="black"></rect>
                                                <path
                                                    d="M15 12C15 14.2 13.2 16 11 16C8.8 16 7 14.2 7 12C7 9.8 8.8 8 11 8C13.2 8 15 9.8 15 12ZM11 9.6C9.68 9.6 8.6 10.68 8.6 12C8.6 13.32 9.68 14.4 11 14.4C12.32 14.4 13.4 13.32 13.4 12C13.4 10.68 12.32 9.6 11 9.6Z"
                                                    fill="black"></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="pb-15 fw-bold">
                                        <h3 class="text-gray-600 fs-5 mb-2">No result found</h3>
                                        <div class="text-muted fs-7">Please try again with a
                                            different query</div>
                                    </div>
                                </div>
                            </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>


<div  *ngIf="this.manuelParts && this.manuelParts.length > 0">
    <div class="row d-flex align-items stretch" *ngFor="let item of manuelParts">
        <div class="card mb-5 mb-xl-8">
            <!--begin::Header-->
            <div class="card-header mb-10 border-1 pt-5" style="background-color: #015029;">
                <div class="card-title d-flex flex-row-fluid justify-content-between gap-5">
                    <div class="flex-column d-flex" style="">
                        <label class="fw-bolder text-muted fs-6" for="">Parça Adı / Kodu: </label>
    
                        <div>
                            <span class="text-white">{{item?.part_name}} </span>
                            <span class="text-muted fs-5" *ngIf="item.part_type_id == 1" > {{item?.original_part_code}} </span>
                            <span class="text-muted fs-5"  *ngIf="item.part_type_id != 1" > {{item?.equivalent_part_code}} </span>
                        </div>
    
                        
    
                    </div>
                    <div class="flex-column d-flex" style="">
                        <label class="fw-bolder text-muted fs-6" for="">Parça Tipi: </label>
                        <span class="card-label text-white  fs-3 mb-1">
                            <span> {{item.part_type_name}} </span>
                            
                        </span>
                    </div>
                    <div class="flex-column d-flex" style="" *ngIf="item.part_unit_type_id != 3">
                        <label class="fw-bolder text-muted fs-6" for="">Parça Birim Fiyatı: </label>
                        <span class="card-label text-white  fs-3 mb-1" >{{ toNumberFormat(item.part_no_vat_price)}} </span>
                    </div>
                    <div class="flex-column d-flex" style="" *ngIf="item.part_unit_type_id == 3">
                        <label class="fw-bolder text-muted fs-6" for="">İşçilik Fiyatı: </label>
                        <span class="card-label text-white  fs-3 mb-1" #laboutTotalPriceSpan >{{ toNumberFormat(item.part_no_vat_price)}} </span>
                    </div>
                   
                   
                </div>
                <div class="card-toolbar d-none">
                    <!--begin::Menu-->
                    <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                        <!--begin::Svg Icon | path: icons/duotune/general/gen024.svg-->
                        <span class="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
                                    <rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                                    <rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                                    <rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </button>
                    <!--begin::Menu 2-->
                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                            <div class="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                        </div>
                        <!--end::Menu item-->
                        <!--begin::Menu separator-->
                        <div class="separator mb-3 opacity-75"></div>
                        <!--end::Menu separator-->
                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">New Ticket</a>
                        </div>
                        <!--end::Menu item-->
                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">New Customer</a>
                        </div>
                        <!--end::Menu item-->
                        <!--begin::Menu item-->
                        <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                            <!--begin::Menu item-->
                            <a href="#" class="menu-link px-3">
                                <span class="menu-title">New Group</span>
                                <span class="menu-arrow"></span>
                            </a>
                            <!--end::Menu item-->
                            <!--begin::Menu sub-->
                            <div class="menu-sub menu-sub-dropdown w-175px py-4">
                                <!--begin::Menu item-->
                                <div class="menu-item px-3">
                                    <a href="#" class="menu-link px-3">Admin Group</a>
                                </div>
                                <!--end::Menu item-->
                                <!--begin::Menu item-->
                                <div class="menu-item px-3">
                                    <a href="#" class="menu-link px-3">Staff Group</a>
                                </div>
                                <!--end::Menu item-->
                                <!--begin::Menu item-->
                                <div class="menu-item px-3">
                                    <a href="#" class="menu-link px-3">Member Group</a>
                                </div>
                                <!--end::Menu item-->
                            </div>
                            <!--end::Menu sub-->
                        </div>
                        <!--end::Menu item-->
                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">New Contact</a>
                        </div>
                        <!--end::Menu item-->
                        <!--begin::Menu separator-->
                        <div class="separator mt-3 opacity-75"></div>
                        <!--end::Menu separator-->
                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                            <div class="menu-content px-3 py-3">
                                <a class="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
                            </div>
                        </div>
                        <!--end::Menu item-->
                    </div>
                    <!--end::Menu 2-->
                    <!--end::Menu-->
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body py-3" >
                <!--begin::Table container-->
                <div class="table-responsive">
                    <!--begin::Table-->
                    <table class="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                        <!--begin::Table head-->
                        <thead>
                            <tr class="fw-bolder text-muted">
                              
                                <th class="min-w-150px">Orjinal Referans No</th>
                                <th class="min-w-140px">Referans No</th>
                                <th class="min-w-120px">Açıklaması</th>
                                <th class="min-w-120px">Miktar</th>
                                <th class="min-w-120px">Y. Parça Markası</th>
                                <th class="min-w-120px">Toplam Fiyatı(Kdv Hariç)</th>
                                <th class="min-w-120px">İşlemler</th>
                                
                                
                            </tr>
                        </thead>
                        <!--end::Table head-->
                        <!--begin::Table body-->
                        <tbody>
                            <tr>
                                <td>
                                    <span>{{item.original_part_code}}</span>
                                    
                                </td>
                                <td>
                                    <span >{{item.part_code}}</span>
                                   
                                    
                                </td>
                                <td>
                                    <span class="text-dark fw-bolder text-hover-primary fs-6">{{item.part_name}} </span>
                                </td>
                                
                                
                                <td style="display: flex; align-items: center;">
                                    <input 
                                        *ngIf="item.part_unit_type_id != 3" 
                                        type="number" 
                                        name="product_count" 
                                        class="form-control form-control-solid" 
                                        value="{{item.part_unit}}" 
                                        [id]="'partUnitInputs_'+item.id"
                                        style="width: 100px;">
                                    <span  *ngIf="item.part_unit_type_id == 3" class="text-dark fw-bolder text-hover-primary fs-6">{{item.part_unit}} </span>
                                    <span class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{{item.part_unit_type}}</span> 
                                </td>
                                
                                <td>
                                    <span  class="badge badge-info">{{item.part_brand}}</span>
                                </td>
                                <td>
                                    
                                    <span class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6 d-flex">
                                        <input 
                                            *ngIf="item.part_unit_type_id == 3" 
                                            type="number" 
                                            name="total_part_no_vat_price" 
                                            class="form-control form-control-solid"
                                            value="{{item.part_no_vat_price}}" 
                                            [id]="'labourPriceInputs_'+item.id"
                                            style="width: 100px;">&#x20BA;
                                        <span 
                                            *ngIf="item.part_unit_type_id != 3" 
                                            class="text-dark fw-bolder text-hover-primary fs-6" 
                                            >{{item.total_price_no_vat}}&#x20BA; </span>
                                        
                                      
                                    </span> 
                                </td>
                                <td>
                                    <input 
                                   *ngIf="item.part_unit_type_id != 3 && order && order.status_id < 22 && order.is_payed == false"
                                    type="button" 
                                    class="btn btn-sm btn-primary"
                                    
                                    (click)="editPart(partUnitInputs,item)" value="Güncelle">

                                    <input 
                                   *ngIf="item.part_unit_type_id != 3 && order && order.status_id < 22 && order.is_payed == false"
                                    type="button" 
                                    class="btn btn-sm btn-danger" 
                                    style="margin-left: 10px;" 
                                    (click)="deletePart(item.id)" value="Sil">

                                    <input 
                                    *ngIf="item.part_unit_type_id == 3 && order && order.status_id < 22 && order.is_payed == false"
                                    type="button" 
                                    class="btn btn-sm btn-primary" 
                                    (click)="editLabour(item)" value="Güncelle">
                                </td>
                               
                                
                            </tr>
                            
                           
                        </tbody>
                        <!--end::Table body-->
                    </table>
                    <!--end::Table-->
                </div>
                <!--end::Table container-->
            </div>
            <!--begin::Body-->
            
        </div>
    </div>
</div>