<form action="" class="mx-auto mw-1000px w-100 pb-10 ng-untouched ng-pristine ng-valid bg-white form-container" style="padding:5px 45px;">
    <div class=" d-flex flex-column-fluid justify-content-center tab-header-container">
        <div class="tab-header" [ngStyle]="{'left.px': leftWidth,'transition': '10s all bounce;'}">
            <div class="tab" *ngFor="let item of stepList; let i = index">
                <span class="tab-number" [ngClass]="{'active': step == item.position}">
                    <span *ngIf="step == item.position" class="active-dot"></span>
                </span> 
               
                <span class="tab-text">{{item.step_title}}</span>
                <span class="dot-left" *ngIf="i != 0">.........</span> 
                <span class="dot-right" *ngIf="(i+1) != stepList.length">.......</span>
            </div>
            
            
        </div>

       
    </div>

    {{orderCardId}}
    <div *ngFor="let item of stepList">

        <app-appointment-arac-hizmet-secimi-v4 
            *ngIf="step == item.position && item.service_name == 'app-appointment-arac-hizmet-secimi-v4'"
            (firstStepEmitter)="setStepVariable($event)"
            
        >

        </app-appointment-arac-hizmet-secimi-v4>

        <app-appointment-paket-secimi-v4 
            *ngIf="step == item.position && item.service_name == 'app-appointment-paket-secimi-v4'"
            [packageId]="firstStepForm.value.package_id"
            >

        </app-appointment-paket-secimi-v4>

        
    </div>

    

  
    <div class="d-flex justify-content-between py-5">
        <div class="form-content-container ">
          
            <button class="btn btn-primary btn-sm form-button" 
            (click)="stepChange('previous')"
            *ngIf="step != stepList.length"
            >Geri</button>

          
        </div>
        <div class="form-content-container " *ngIf="step == 2 && selectedPackageInfo" >
          
          <app-shared-modal-proforma 
            [packageInfo]="selectedPackageInfo"
            [customerInfo]="customerInfo"
            [modelTypeId]="appointmentFormGroup.value.model_type_id"
            [vehicleInfo]="vehicleInfo"
            ></app-shared-modal-proforma>

        </div>
        <div class="form-content-container ">
          
          

            <button class="btn btn-primary btn-sm form-button" 
                *ngIf=" (step != stepList.length)"
                (click)="stepChange('next')"
                [disabled]="stepList && step == stepList.length || !isStepCompleted "
                >İlerle</button>
        </div>
    </div>
    

</form>