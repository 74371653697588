import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map } from 'rxjs';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment-randevusuz-kayit-v3',
  templateUrl: './randevusuz-kayit-v3.component.html',
  styles: [
  ]
})
export class RandevusuzKayitV3Component implements OnInit {

  @Input() vehicleForm:any;
  @Input() userForm:any;
  @Input() orderTypeId:number|undefined;
  private baseUrl: string = environment.laravelApiEndpoint;
  public orderIsCreated:boolean = false;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private alertifyService:AlertifyService
  ) { 
    window.scrollTo(0, 0);
  }

  async ngOnInit(): Promise<void> {
    let createObj = {
      user_id: this.userForm.value.id,
      firstname: this.userForm.value.firstname,
      lastname: this.userForm.value.lastname,
      email: this.userForm.value.email,
      phone: this.userForm.value.gsm,
      job: this.userForm.value.job,
      brand_id: this.vehicleForm.value.brand_id,
      model_id: this.vehicleForm.value.model_id,
      fuel_id: this.vehicleForm.value.fuel_type_id,
      gear_id: this.vehicleForm.value.gear_type_id,
      chasses_no: this.vehicleForm.value.chasses_no,
      kwh: this.vehicleForm.value.kwh,
      beygir: this.vehicleForm.value.beygir,
      year: this.vehicleForm.value.year_id,
      is_approved: this.userForm.value.approved,
      note: this.vehicleForm.value.note,
      km: this.vehicleForm.value.last_km,
      order_type_id: this.orderTypeId

    }

    await this.createUnpackageOrder(createObj)
  
  }


  async createUnpackageOrder(createObj:any){
    let options = {
      headers: new HttpHeaders({
        'token': this.authService.parseToken(false)
      })
    }

    await this.http.post<any>(this.baseUrl + 'appointment/create-unpackage-orders',createObj,options).subscribe(response => {

      if (response.status) {
        this.orderIsCreated = true
      } else {
       this.alertifyService.error(response.message);
       this.orderIsCreated = false
      }
   
    })
  
  }

}
