
<div class="d-flex flex-column flex-root">
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">
        <div class="d-flex flex-column flex-lg-row-auto w-lg-600px positon-xl-relative"
            >
            <div class="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px ">
              
                <div class="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative horizontal-image" style="background-size: cover !important; background-position: right top !important" >

                </div>


                
            </div>
        </div>
        <div class="d-flex flex-column flex-lg-row-fluid py-10" style="background-color: white;">

            <ng-container [ngTemplateOutlet]="authType === 'signin' ? signin : authType === 'signup' ? signup : authType === 'newPassword' ? newPass : forgot">
            </ng-container>
            <ng-template #signin>
                <app-signin></app-signin>
            </ng-template>
            <ng-template #signup>
                <app-signup></app-signup>
            </ng-template>
            <ng-template #forgot>
                <app-forgot [pageType]="'forget'"></app-forgot>
            </ng-template>
            <ng-template #newPass>
                <app-forgot [pageType]="'newPass'"></app-forgot>
            </ng-template>
            <div class="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
                <div class="d-flex flex-center fw-bold fs-6">
                    <a class="text-muted text-hover-primary px-2" target="_blank">About</a>
                    <a class="text-muted text-hover-primary px-2" target="_blank">Support</a>
                    <a class="text-muted text-hover-primary px-2" target="_blank">Purchase</a>
                </div>
            </div>
        </div>
    </div>
</div>

