<app-appointment-kullanici-formu 
    *ngIf="showUserForm"
    [modelTypeId]="modelTypeId"
    >

</app-appointment-kullanici-formu>

<div class="w-100" *ngIf="!showUserForm">
    <label class="d-flex align-items-center form-label mb-5">Kilometre Seçiniz
        <i class="fas fa-exclamation-circle ms-2 fs-7"
            aria-label="Monthly billing will be based on your account plan"
            title="Birden fazla seçim yapabilirsiniz."></i>
    </label>
 
    <div class="w-100">
      
        <app-shared-selectwo
            [name]="kmsName"
            [id]="'maintenance_kms'"
            [lists]="maintenanceKmsLists"
            (selectedItem)="selectedTwo('changeKms',$event)"
            [showAll]="false"
        >
        </app-shared-selectwo>
    </div>

    <label style="margin-top: 50px;" class="d-flex align-items-center form-label mb-5">Paket Seçiniz
        <i class="fas fa-exclamation-circle ms-2 fs-7"
            aria-label="Monthly billing will be based on your account plan"
            title="Birden fazla seçim yapabilirsiniz."></i>
    </label>

    <div class="mb-0">
        <label class="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5"
            *ngFor="let item of packageListFiltered">
            <div class="d-flex align-items-center me-2 w-100">
                <div
                    class="form-check form-check-custom form-check-solid form-check-primary me-6">
                    <input class="form-check-input" [checked]="selectedPackageId && selectedPackageId == item.id" type="radio" name="'package'" value="{{item.id}}"
                    (change)="setPackageInfo($event,item)">
                </div>
                <span class="symbol symbol-50px me-6">
                    <span class="symbol-label">
                        <i class="fa-car fas"></i>
                    </span>
                </span>
                <div class="flex-grow-1">
                    
                    <div class="fw-bold opacity-50">
                        {{item.name}} {{item.id}}
                    </div>
                </div>
                <div class="flex-end">
                    <span
                class="fs-6 d-none" style="margin-right: 15px;" [innerHtml]="getSmallAndBig(item)"></span>
                </div>
            </div>
        </label>
    </div>
</div>