import { Pipe, PipeTransform } from "@angular/core";
import { numberFormat } from "src/app/helpers/slugify";

@Pipe({
  name: "numberFormat"
})
export class NumberFormatPipeComponent implements PipeTransform {

  transform(number: Number) {
    return numberFormat(Number(number))
  }

}