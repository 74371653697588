<div class="row">
    <div class="col-md-12"><span class="text-dark fw-bolder text-hover-primary mb-1 fs-6"> Toplam : {{dataCount}} adet</span></div>
    <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
        <div class="dataTables_length">
            <label *ngIf="pageCount > pageList">
                <select class="form-select form-select-sm form-select-solid" (change)="onChange($event)">
                    <option *ngFor="let selectPage of selectCount">{{selectPage}}</option>
                </select>
            </label>
        </div>
    </div>
    <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <div class="dataTables_paginate paging_simple_numbers">
            <ul class="pagination">
                <li class="paginate_button page-item previous" [ngClass]="{disabled: page == 1}">
                    <a class="page-link" (click)="goToPage(page - 1)"><i class="previous"></i></a>
                </li>
                <li *ngFor="let pageNo of rangeCount" class="paginate_button page-item"
                    [ngClass]="{active: pageNo === page}">
                    <a class="page-link" (click)="goToPage(pageNo)">{{pageNo}}</a>
                </li>
                <li class="paginate_button page-item next" [ngClass]="{disabled: page == pageCount}">
                    <a class="page-link" (click)="goToPage(page + 1)"><i class="next"></i></a>
                </li>
            </ul>
        </div>
    </div>
</div>