import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderOfferAnswers, ORDER_OFFER_ANSWERS_ALL } from 'src/app/graphql/order_offer_answers.graphql';
import { ORDER_OFFERS_ALL, ORDER_OFFERS_UPDATE_ANSWER_ID } from 'src/app/graphql/order_offers.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';

interface supplierProduct{
  id: bigint,
  price: number,
  part_code: String,
  part_equivalent_code: String,
  stock_count: Number,
  part_type: String,
  part_brand: String,
  discount: Number,
  part_name: String
}

@Component({
  selector: 'app-shared-supplier-product-list',
  templateUrl: './supplier-product-list.component.html',
  styles: [
  ],
  providers:[ORDER_OFFERS_ALL,ORDER_OFFER_ANSWERS_ALL,ORDER_OFFERS_UPDATE_ANSWER_ID]
})
export class SupplierProductListComponent implements OnInit {

  @Input() orderOfferId: bigint | undefined;
  @Input() companyId: Number | undefined;
  public productList: supplierProduct[] | undefined;
  public pList: OrderOfferAnswers[] | undefined;
  @Output() refreshList = new EventEmitter<boolean>();

  constructor(
    public otokocAnswers: ORDER_OFFERS_ALL,
    public orderOfferAnswersGet: ORDER_OFFER_ANSWERS_ALL,
     private alertifyService: AlertifyService,
     private updateAnswerId: ORDER_OFFERS_UPDATE_ANSWER_ID
  ) { }

  ngOnInit(): void {
    
  }

  ngOnChanges(){
    if(this.orderOfferId != undefined){
      this.getProducts();
    }
  }

  getProducts(){

    
  

        this.orderOfferAnswersGet.watch({where:{order_offers_id:{_eq:this.orderOfferId}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response=>{


          if(response.errors){
            this.alertifyService.error(response.errors[0].message);
          }

          if(response.data){
            let obj;
            this.pList = response.data.order_offer_answers;
          /*  response.data.order_offer_answers.forEach((val:any,key:Number)=>{
                obj = { 
                  id : val.id,
                  price: val.offer_part_price,
                  part_code: val.part_code,
                  part_equivalent_code: val.part_equivalent_code,
                  stock_count: val.available,
                  part_type: val.part_type,
                  part_brand: val.part_brand,
                  discount: val.discount,
                  part_name: val.part_name
                } 
                this.productList?.push(obj);
            })*/

          }

        })
      
  }

  setAnswer(event:any) {
    let selectedId: bigint = event.target.value;

    if(selectedId > 0){
      this.updateAnswerId.mutate({id:this.orderOfferId,selected_answer_id: selectedId}).subscribe(response=>{
        if(response.errors){
          this.alertifyService.error(response.errors[0].message)
        }
        if(response.data){
          this.alertifyService.success("Seçilen ürün değiştirildi");
          this.refreshList.emit(true);
        }
      })
    }
  }

}
