import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-shared-carousel',
  templateUrl: './carousel.component.html',
  styles: [ '.carousel-container { width: 100vw;height: 100vh;} .carousel-text{ width:100%; height: 50px; position: absolute; bottom: 8px; left: 0px; font-size: 20px; background: #CCC; opacity:0.6; color: #000; font-weight:bold; text-align:center; padding-top:10px}'
  ]
})
export class CarouselComponent implements OnInit {
 
  @Input() sliderType: String | undefined;

  brandResponsive = {
    0: {
      items: 1
    },
    400: {
      items: 2
    },
    740: {
      items: 3
    },
    940: {
      items: 4
    },
    1140: {
      items: 5
    },
    1340: {
      items: 6
    }
  }

  defaultResponsive = {
    0: {
      items: 1
    }
  }

  public customOptions: OwlOptions ={
    loop: true,
    autoplay:true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: this.defaultResponsive,
    nav: false,
    autoHeight:true
  }

  @Input() images: any; 

  constructor() {   
  }

  ngOnInit(): void {
    
    this.customOptions.responsive = ( this.sliderType === "brand" ) ? this.brandResponsive : this.defaultResponsive;

  }

  setStyles(imgUrl: string) {
    return {
      'background-image': `url('${imgUrl}')`,
      'background-position': 'center',
      'background-repeat': 'no-repeat',
      'background-size': 'cover'
    }
  }

}
