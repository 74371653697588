import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Town, TOWN_ALL, TOWN_DISTRICT_ID } from 'src/app/graphql/town.graphql';
import { TOWN_ID } from 'src/app/graphql/town.graphql';

@Component({
  selector: 'app-shared-appointment-towns',
  templateUrl: './towns.component.html',
  styles: [
  ],
  providers:[TOWN_ID,TOWN_ALL]
})
export class AppointmentTownsComponent implements OnInit {
  townList: Town[] | undefined;
  selectedDistrict!: any;
  @Input() townInputId: any;
  @Input() selectedTownId!: any;
  @Output() town_id = new EventEmitter<Number | null>();
  @Input() showLabel: boolean = true;
  @Input() 
  set event(event: Number | null){
    this.selectedDistrict = event;

    this.setList();
    
  }
  constructor(
    public getTowns: TOWN_ALL
  ) { }

  ngOnInit(): void {
  }

  setList(){


    if(this.selectedDistrict == null || this.selectedDistrict == ""){
      this.townList = [];
    } else {

      this.getTowns.watch({"where":{"district_id":{"_eq":this.selectedDistrict}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {

        this.townList = response.data.towns;
      })
    }
  }

  ngOnChanges(){
    if(this.townInputId == undefined){
      this.townInputId = 'Mahalle Seçiniz'
    }
  }

  // Shared Select Event
  selected(event: any) {
   
      if(event.target){
        this.selectedTownId = event.target.value;
      }else{
        this.selectedTownId = event;
      }
    
   
    this.town_id.emit(this.selectedTownId);
  
  
  }

}
