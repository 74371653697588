import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-sss',
  templateUrl: './sss.component.html',
  styles: [
  ]
})
export class SssComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  toggleItem(item: any) {



    let parent = item.srcElement.parentNode;



    if(item.srcElement.classList.contains('prc-btn')) {
      parent = item.srcElement.parentNode.parentNode.parentNode;
    }

   if(parent.nextElementSibling.classList.contains('show')){
   
    parent.nextElementSibling.classList.remove('show');
    parent.parentNode.children.item(0).firstChild.children[0].classList.add('toggle-on');
    parent.parentNode.children.item(0).firstChild.children[0].classList.remove('toggle-off');

    parent.parentNode.children.item(0).firstChild.children[1].classList.remove('toggle-on');
    parent.parentNode.children.item(0).firstChild.children[1].classList.add('toggle-off');

   }else{

    parent.nextElementSibling.classList.add('show');
    parent.parentNode.children.item(0).firstChild.firstChild.classList.remove('toggle-on');
    parent.parentNode.children.item(0).firstChild.firstChild.classList.add('toggle-off');

    parent.parentNode.children.item(0).firstChild.children[1].classList.add('toggle-on');
    parent.parentNode.children.item(0).firstChild.children[1].classList.remove('toggle-off');

   }

  }

}
