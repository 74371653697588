import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MapService } from 'src/app/services/map.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styles: [
  ]
})
export class LocationComponent implements OnInit {

  @Input() title: String = ""
  @Input() latitude: number = 40.918203
  @Input() longitude: number = 29.1591702
  @Output() selectedAddress = new EventEmitter<any>()
  public center!: google.maps.LatLngLiteral
  public markers: any[] = [];
  public options: google.maps.MapOptions = {

  }

  constructor(
    private mapService: MapService
  ) { }

  ngOnInit(): void {
    // Set RS Servis Location
    this.center = {
      lat: this.latitude,
      lng: this.longitude
    }
    // Set Service Location
    this.setLocation()
  }

  markerDragend(event: google.maps.MapMouseEvent) {
    this.mapService.getAddress(event.latLng!.lat(), event.latLng!.lng()).subscribe(response => {
      if (response.status == "OK") {
        this.selectedAddress.emit(response.results[0])
      }
    })
  }

  setLocation() {
    this.markers.push({
      position: {
        lat: this.latitude,
        lng: this.longitude
      },
      label: {
        color: 'red',
        text: this.title,
        fontSize: "15px",
        fontWeight: "bold"
      },
      title: this.title,
      options: {
        draggable: true,
        icon: {
          path: "M27.648 -41.399q0 -3.816 -2.7 -6.516t-6.516 -2.7 -6.516 2.7 -2.7 6.516 2.7 6.516 6.516 2.7 6.516 -2.7 2.7 -6.516zm9.216 0q0 3.924 -1.188 6.444l-13.104 27.864q-0.576 1.188 -1.71 1.872t-2.43 0.684 -2.43 -0.684 -1.674 -1.872l-13.14 -27.864q-1.188 -2.52 -1.188 -6.444 0 -7.632 5.4 -13.032t13.032 -5.4 13.032 5.4 5.4 13.032z",
          fillColor: "green",
          fillOpacity: 1,
          strokeWeight: 2,
          strokeColor: "red",
          scale: 0.9,
          anchor: new google.maps.Point(15, 30)
        }
      }
    })
  }

}
