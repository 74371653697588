import { Component, OnInit, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: 'app-shared-filter',
  templateUrl: './filter.component.html',
  styles: []
})
export class FilterComponent implements OnInit {

  public show: boolean = false

  constructor(private eRef: ElementRef) { }

  ngOnInit(): void { }

  showFilter() {
    this.show = !this.show
  }

  @HostListener('document:click', ['$event'])
  clickout(event:any) {
    if(this.eRef.nativeElement.contains(event.target)) {
      if(event.target.classList.contains('closebtn')){
        this.show = false;
      }else{
        this.show = true;
      }
      
    } else {
     
      this.show = false;
    }
    
  }

  close(){
    this.show = false;
    document.getElementById('filtCont')?.classList.remove('show');
  }



 

}
