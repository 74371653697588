

<div id="drawer" class="bg-body drawer drawer-end" style="width: {{drawerWidth}} ">
    <div class="card shadow-none rounded-0 w-100">
        <div class="card-header" >
            <h3 class="card-title fw-bolder text-gray-700" style="width: 80%;">{{title}}</h3>
            <div class="card-toolbar">
                <button type="button" class="btn btn-sm btn-icon btn-active-color-primary h-40px w-40px me-n6"
                    (click)="drawerClose()">
                    <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                transform="rotate(-45 6 17.3137)" fill="black"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                fill="black"></rect>
                        </svg>
                    </span>
                </button>
            </div>
        </div>
        <div class="card-body pt-5">
            <div [ngClass]="{'scroll-y': isScroll}" class=" me-n5 pe-5" style="min-height: 500px;">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
<!-- Background Dark Opacity -->
<div id="drawer-overlay" style="z-index: 109;" class="drawer-overlay nonvisible" (click)="drawerClose()"></div>