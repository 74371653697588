<label style="display: block;">
    <input type="text" class="form-control" [attr.list]="id" (keyup)="inputValue($event)" [placeholder]="name"
        #sharedSelect [value]="selectName" />
</label>
    
<datalist [id]="id">
    <option *ngFor="let list of itemList" [attr.val]="itemList[returnData]">
       
        <span>{{list.surname!}} </span>
        <span>{{list[displayName]}}</span>
         
    </option>
</datalist>