import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; // NgbModal için (Bootstrap modal kullanıyorsanız)
import { ImageCarouselModalComponent } from '../image-carousel-modal/image-carousel-modal.component';

@Component({
  selector: 'app-shared-image-carousel',
  templateUrl: './image-carousel.component.html',
  styles: [
  ],
  styleUrls:['./image-carousel.component.css']
})
export class ImageCarouselComponent implements OnInit {
  @Input() carouserImages: any[] = [];
  @Input() isDeletable: boolean = false;
  @Output() removeImage = new EventEmitter<any>();

  public selectedImageIndex: number = 0;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
  
  }



  openModal(index: number) {
    this.selectedImageIndex = index;
    const modalRef = this.modalService.open(ImageCarouselModalComponent, { size: 'lg' }); // Modal'ı açıyoruz
    modalRef.componentInstance.carouserImages = this.carouserImages; // Modal'a görselleri gönderiyoruz
    modalRef.componentInstance.selectedImageIndex = this.selectedImageIndex; // Modal'a seçilen indexi gönderiyoruz
    modalRef.componentInstance.isDeletable = this.isDeletable;

    modalRef.componentInstance.removeImage.subscribe((deletedImage: any) => {
      this.handleImageDeletion(deletedImage); // Burada resmi silme işlemini yapabilirsiniz
    });

   
  }

  handleImageDeletion(deletedImage:any) {
    this.removeImage.emit(deletedImage)
    // Burada resim silme mantığınızı ekleyebilirsiniz
  }

}
