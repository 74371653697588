import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-appointment-proforma',
  templateUrl: './proforma.component.html',
  styles: [
  ]
})
export class ProformaComponent implements OnInit {

  @Input() userInfo: any;
  @Input() selectedPackageInfo: any;
  @Input() selectedPieces: any;
  @Input() priceInfo: any;
  @Input() orderType:Number | undefined;
  public serviceLastDate:any;
  public todayDate:any;
  public selectedCompaignProducts:any = [];
  public selectedUncompaignProducts:any = [];


  constructor() { }

  ngOnInit(): void {
    moment.locale('tr');
    this.serviceLastDate = moment().add(1, 'days').endOf('day').format('LLL');
    this.todayDate = moment().format('LL');

    this.selectedCompaignProducts = this.selectedPieces.filter((obj:any) => (obj.selected_part_group_id == 2));//yağ gurubu ise ayrı göster

    this.selectedUncompaignProducts = this.selectedPieces.filter((obj:any) => (obj.selected_part_group_id != 2));

  }

}
