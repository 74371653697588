import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company, COMPANY_ALL } from 'src/app/graphql/company.graphql';
import { MapService } from 'src/app/services/map.service';

@Component({
  selector: 'app-shared-nearest-services',
  templateUrl: './nearest-services.component.html',
  styles: [
  ]
})
export class NearestServicesComponent implements OnInit {

  @Input() title: String = ""
  @Input() latitude: number = 40.918203
  @Input() longitude: number = 29.1591702
  @Input() markerList:any;
  @Output() selectedAddress = new EventEmitter<any>()
  public center!: google.maps.LatLngLiteral
  public markers: any[] = [];
  public options: google.maps.MapOptions = {

  }

  public serviceList: Company[] | undefined;

  constructor(
    private mapService: MapService,
    private getCompany: COMPANY_ALL,
  ) { }

  ngOnInit(): void {
    // Set RS Servis Location
    this.center = {
      lat: this.latitude,
      lng: this.longitude
    }
    // Set Service Location
    this.setLocation()
  }

  markerDragend(event: google.maps.MapMouseEvent) {
    this.mapService.getAddress(event.latLng!.lat(), event.latLng!.lng()).subscribe(response => {
      if (response.status == "OK") {
        this.selectedAddress.emit(response.results[0])
      }
    })
  }

  async setLocation() {

    this.markers.push({
      position: {
        lat: this.latitude,
        lng: this.longitude
      },
      label: {
        color: '#333',
        text: 'Konumum',
        fontSize: "15px",
        fontWeight: "bold"
      },
      title: this.title,
     
      options: {
        draggable: false,
        icon: {
          path: "M27.648 -41.399q0 -3.816 -2.7 -6.516t-6.516 -2.7 -6.516 2.7 -2.7 6.516 2.7 6.516 6.516 2.7 6.516 -2.7 2.7 -6.516zm9.216 0q0 3.924 -1.188 6.444l-13.104 27.864q-0.576 1.188 -1.71 1.872t-2.43 0.684 -2.43 -0.684 -1.674 -1.872l-13.14 -27.864q-1.188 -2.52 -1.188 -6.444 0 -7.632 5.4 -13.032t13.032 -5.4 13.032 5.4 5.4 13.032z",
          fillColor: "green",
          fillOpacity: 1,
          strokeWeight: 2,
          strokeColor: "#47be7d",
          scale: 0.9,
          anchor: new google.maps.Point(15, 30)
        },
        infowindow: new google.maps.InfoWindow({
          content: "<span>any html goes here</span>"
      })
      }
    });

    if(this.markerList && this.markerList.length > 0){
      await this.markerList.forEach(element => {

        let newMarker = {
          
          position: {
            lat: element.lat,
            lng: element.lon
          },
          label: {
            color: '#333',
            text: element.title,
            fontSize: "15px",
            fontWeight: "bold"
          },
          title: this.title,
          company_id: element.company_id,
          options: {
            draggable: false,
            icon: {
              path: "M27.648 -41.399q0 -3.816 -2.7 -6.516t-6.516 -2.7 -6.516 2.7 -2.7 6.516 2.7 6.516 6.516 2.7 6.516 -2.7 2.7 -6.516zm9.216 0q0 3.924 -1.188 6.444l-13.104 27.864q-0.576 1.188 -1.71 1.872t-2.43 0.684 -2.43 -0.684 -1.674 -1.872l-13.14 -27.864q-1.188 -2.52 -1.188 -6.444 0 -7.632 5.4 -13.032t13.032 -5.4 13.032 5.4 5.4 13.032z",
              fillColor: "green",
              fillOpacity: 1,
              strokeWeight: 2,
              strokeColor: "#47be7d",
              scale: 0.9,
              anchor: new google.maps.Point(15, 30)
            },
            infowindow: new google.maps.InfoWindow({
              content: "<span>any html goes here</span>"
          })
          }
        };
        
        const infowindow = new google.maps.InfoWindow({
          content: "Hello World",
          ariaLabel: "Uluru",
        });

        this.markers.push(newMarker)

        
      });

      this.getServiceList();

    }
    
  }

  getServiceInfo($event:any,company_id:number){
    
  }

  getServiceList(){
    let deg2Rad = this.latitude * 180 / 3.14159;
    let radiusLat = 30 / 110.54;
    let radiusLng = 30 / (111.32 * Math.cos(deg2Rad));

    let latMin = this.latitude - radiusLat;
    let latMax = this.latitude + radiusLat;
    let lngMin = this.longitude - radiusLng;
    let lngMax = this.longitude + radiusLng;

   /* this.getCompany.watch({where:{
      _and:{
        active: {_eq: true},
        company_type_id: {_eq: 2},
        lat: {_and:{
          lat: {_gte: latMin},
          lat: { {_lte: latMax}},
        }},
        
        lon: {lat: {_gte: latMin}},
        lat: {lat: {_gte: latMin}},
      }
      
    }})

   ;*/
  }
  

}
