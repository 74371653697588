import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-shared-select',
  templateUrl: './select.component.html',
  styles: []
})
export class SelectComponent implements OnInit {
  @Input() name!: string
  @Input() lists!: any
  @Input() reset: boolean = false
  @Input() returnInsertedValue: boolean = false
  @Input() selectName: String = ""
  @Output() selected = new EventEmitter<any>()
  @ViewChild('sharedSelect', { static: true }) sharedSelect!: ElementRef
  public id: string = ""
  @Input() activeFields = 'name';
  public itemList:any;
  public displayName:string = 'name';
  @Input() returnData:any = 'id';
 
  constructor() { }

  ngOnInit(): void {
    this.id = this.name.replace(' ', '-').toLocaleLowerCase();
    this.itemList = this.lists;
    this.displayName = (this.activeFields) ? this.activeFields : 'name';
  }

  ngOnChanges() {

    if (this.reset) {
      this.sharedSelect.nativeElement.value = ""
      this.sharedSelect.nativeElement.classList.remove('ng-dirty', 'ng-invalid', 'ng-touched', 'ng-valid')
    }

    this.itemList = this.lists;

  }

  inputValue($event: any) {
   /* let exists;
    
    if(this.activeFields == 'part_code'){
      exists = this.lists.filter((obj: { part_code: any; }) => obj.part_code === $event.target.value);
    }else{
      exists = this.lists.filter((obj: { name: any; }) => obj.name === $event.target.value);
    }*/

    let exists = this.lists.filter((obj: { [x: string]: string; }) =>{
       return ($event.target.value == obj[this.activeFields]) ? obj : false;
    });

    if (exists.length > 0) {
      $event.target.classList.remove('ng-invalid')
      $event.target.classList.add('ng-dirty', 'ng-valid')
      this.selected.emit(exists[0][this.returnData])
    } else {
      $event.target.classList.remove('ng-dirty')
      $event.target.classList.add('ng-invalid', 'ng-touched')

      if(this.returnInsertedValue){
        this.selected.emit({key:"newItem",value:$event.target.value})

      }else{
        this.selected.emit(null)

      }
    }
  }
}
