
<div class="d-flew flex-row">
    <div class="d-flex flex-column flex-row-fluid">
      
        <div class="d-flex flex-row ft-menu-container justify-content-between section-gradient" style="padding: 10px 50px;">
            <div class="footer-item">
                <ul class="footer-env">
                    <li>    
                        <a  href="https://www.garantiliotoservis.com" >
                            <img alt="Logo" src="assets/images/logo-garantiliotoservis.svg" class="logo" />
                        </a>
                    </li>
                    <li>
                        <div class="d-flex align-items-stretch flex-shrink-0">
                            <!--begin::Activities-->
                            <div class="d-flex align-items-center ms-1 ms-lg-3">
                                <!--begin::Drawer toggle-->
                                <a href="https://www.facebook.com/garantiliotoservis" target="_blank">
                                    <div class="btn btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px" id="kt_activities_toggle">
                                        <!--begin::Svg Icon | path: icons/duotune/general/gen032.svg-->
                                        
                                        <span class="svg-icon svg-icon-1">
                                            <i class="fab fa-facebook-f"></i>
                                        </span>
                                
                                        <!--end::Svg Icon-->
                                    </div>
                                </a>
                                <!--end::Drawer toggle-->
                            </div>
                            <!--end::Activities-->
                            <!--begin::Notifications-->
                            <div class="d-flex align-items-center ms-1 ms-lg-3">
                                <!--begin::Menu- wrapper-->
                                <a href="https://www.instagram.com/accounts/login/?next=/garantiliotoservis/" target="_blank">
                                <div class="btn btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                    <!--begin::Svg Icon | path: icons/duotune/general/gen022.svg-->
                                    
                                    <span class="svg-icon svg-icon-1">
                                        <i class="fab fa-instagram"></i>
                                    </span>
                       
                                    <!--end::Svg Icon-->
                                </div>
                            </a>
                                <!--end::Menu wrapper-->
                            </div>
            
                            <div class="d-flex align-items-center ms-1 ms-lg-3">
                                <!--begin::Menu- wrapper-->
                                <a href="https://www.linkedin.com/company/garantiliotoservis" target="_blank">
                                <div class="btn btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                    <!--begin::Svg Icon | path: icons/duotune/general/gen022.svg-->
                                    
                                    <span class="svg-icon svg-icon-1">
                                        <i class="fab fa-linkedin-in"></i>
                                    </span>
                            
                                    <!--end::Svg Icon-->
                                </div>
                            </a>
                                <!--end::Menu wrapper-->
                            </div>
            
                            <div class="d-flex align-items-center ms-1 ms-lg-3">
                                <!--begin::Menu- wrapper-->
                                <a href="https://www.youtube.com/channel/UCtKh59XbKRGbSZlNFdLjyMg" target="_blank">
                                <div class="btn btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                    <!--begin::Svg Icon | path: icons/duotune/general/gen022.svg-->
                                  
                                    <span class="svg-icon svg-icon-1">
                                        <i class="fab fa-youtube"></i>
                                    </span>
                            
                                    <!--end::Svg Icon-->
                                </div>
                            </a>
                                <!--end::Menu wrapper-->
                            </div>
    
                        
                        </div>
                    </li>
                </ul>
            </div>
            

            <div class="footer-item">
                <ul class="footer-menu-list">
                    <li><h3>Garanti</h3></li>
                    <li><a href="http://www.garantiliotoservis.com/garanti-nedir">Otomobilinizin Garantisi</a></li>
                    <li><a href="http://www.garantiliotoservis.com/hakkiniz-guvende">Hakkınız Güvende</a></li>
                    <li><a href="http://www.garantiliotoservis.com/kalite-standartlarimiz">Kalite Standartlarımız</a></li>
                    <li><a href="http://www.garantiliotoservis.com/garantili-servis-manifestosu">Garantili Servis Manifestosu</a></li>
                    <li><a href="http://www.garantiliotoservis.com/bilgi-bankasi">Bilgi Bankası</a></li>
                    <li><a href="http://www.garantiliotoservis.com/sss">Sıkça Sorulan Sorular</a></li>
                </ul>
            </div>
            <div class="footer-item">
                <ul class="footer-menu-list">
                    <li><h3>Bilgi</h3></li>
                    <li><a href="http://www.garantiliotoservis.com/garantili-servisler">Garantili Servisler</a></li>
                    <li><a href="http://www.garantiliotoservis.com/hizmetlerimiz">Hizmetlerimiz</a></li>
                    <li><a href="http://www.garantiliotoservis.com/destek-hatti">Destek Hattı</a></li>
                    <li><a href="http://www.garantiliotoservis.com/kvkk-aydinlatma-metni">Kvkk Aydınlatma Metni</a></li>
                    <li><a href="http://www.garantiliotoservis.com/cerez-politikasi">Çerez Politikası</a></li>
                </ul>
            </div>
            <div class="footer-item">
                <ul class="footer-menu-list">
                    <li><h3>Kurumsal</h3></li>
                    <li><a href="http://www.garantiliotoservis.com/hakkimizda">Garantili Oto Servis</a></li>
                    <li><a href="http://www.garantiliotoservis.com/vizyon-ve-degerler">Vizyon ve Değerlerimiz</a></li>
                    <li><a href="http://www.garantiliotoservis.com/servis-uyeligi">Servis Üyeliği</a></li>
                    <li><a href="http://www.garantiliotoservis.com/iletisim">İletişim</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column flex-row-auto">
        <div class="d-flex flex-column-auto h-40px green-deg">
            <div class="d-flex flex-column flex-md-row align-items-center justify-content-between container-fluid">
                <div class="text-dark order-2 order-md-1">
                    <span class="fw-bold me-2" style="color: #FFFFFF77;">
                        © 2021 Garantili Satış Sonrası Oto Hiz.A.Ş.</span>
                </div>
                <div class="fw-bold order-1" style="color: #FFFFFF77;"></div>
            </div>
        </div>
    </div>
</div>