import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vehicle-list-filter',
  templateUrl: './vehicle-list-filter.component.html',
  styles: [
  ],
  styleUrls:['./vehicle-list-filter.component.css']
})
export class VehicleListFilterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
