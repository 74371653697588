import { Injectable } from "@angular/core";
import { gql, Query } from "apollo-angular";

export interface District {
  id: Number,
  city_id: Number,
  name: String,
  order_no: Number,
  active: boolean
}

interface ResponseArray {
  districts: District[]
}

@Injectable()
export class DISTRICT_CITY_ID extends Query<ResponseArray>{
  override document = gql`
  query DISTRICT_CITY_ID($id: Int!) {
    districts(where: {city_id: {_eq: $id}}, order_by: {name: asc}) {
      id
      name
    }
  }`;
}

@Injectable()
export class GET_DISTRICTS extends Query<ResponseArray>{
  override document = gql`
  query GET_DISTRICTS($where: districts_bool_exp) {
    districts(where: $where, order_by: {name: asc}) {
      id
      name
    }
  }`;
}
