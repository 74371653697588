import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { Company, COMPANY_ID } from 'src/app/graphql/company.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-appointment-v3-randevu-olustur',
  templateUrl: './randevu-olustur-v3.component.html',
  styles: [
  ],
  styleUrls:['./randevu-olustur-v3.component.css'],
  providers:[COMPANY_ID]
})
export class RandevuOlusturV3Component implements OnInit {

  @Input() companyId: number | undefined;
  @Input() selectedDateInput: any;
  @Output() serviceDate:EventEmitter<any> = new EventEmitter();
  public todayWithPipe: any;
  public company: Company | undefined;

  constructor(
    public companyGetId: COMPANY_ID,
    public alertifyService: AlertifyService,
    public authService: AuthService
  ) { 
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    moment.locale('tr');
    this.todayWithPipe = moment().add(1, 'days').endOf('day').format('yyyy-MM-DDTH:mm');

    this.companyGetId.watch({id:this.companyId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }

      if(resp.data){
        this.company = resp.data.companies_by_pk;

        if(this.authService.userRole == "service" && this.authService.companyId > 0){

          const today = new Date();

          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, '0'); // Aylar 0-11 arası olduğu için +1 ekliyoruz
          const day = String(today.getDate()).padStart(2, '0');
          const hours = String(today.getHours()).padStart(2, '0');
          const minutes = String(today.getMinutes()).padStart(2, '0');

          this.serviceDate.emit(`${year}-${month}-${day}T08:00`);
    
        }

      }
    })
    
  }

  returnData($event){
    this.serviceDate.emit($event);
  }

}
