import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';
import { Day } from './days.graphql';

export interface CompanyAcceptance {
  id: Number,
  company_id: Number,
  day_id: Number,
  start_hour: Date,
  finish_hour: Date,
  count: Number,
  day: Day,
  company:any
}

interface ResponseArray {
  company_acceptances: CompanyAcceptance[]
}

interface ResponseSingle {
  company_acceptances_by_pk: CompanyAcceptance
}

interface InsertMutation {
  insert_company_acceptances: {
    affected_rows: number
    returning: [{
      id: Number
      name: String
    }]
  }
}

interface UpdateMutation {
  update_company_acceptances: {
    affected_rows: number
    returning: [{
      id: Number
    }]
  }
}

interface DeleteMutation {
  delete_company_acceptances: {
    affected_rows: number
    returning: [{
      id: Number
    }]
  }
}

@Injectable()
export class COMPANY_ACCEPTANCES extends Query<ResponseArray> {
  override document = gql`
  query COMPANY_ACCEPTANCES($company_id: bigint!) {
    company_acceptances(where: {company_id: {_eq: $company_id}}) {
      id
      company_id
      day_id
      start_hour
      finish_hour
      count
      day {
        id
        tr_name
        number
      }
      company {
        City {
          id
          days
        }
      }
    }
  
  }`;
}

@Injectable()
export class COMPANY_ACCEPTANCE_CREATE extends Mutation<InsertMutation> {
  override document = gql`
  mutation COMPANY_ACCEPTANCE_CREATE($company_id: bigint!, $day_id: smallint!, $start_hour: time, $finish_hour: time, $count: numeric) {
    insert_company_acceptances(objects: {company_id: $company_id, day_id: $day_id, start_hour: $start_hour, finish_hour: $finish_hour, count: $count}) {
      affected_rows
      returning {
        id
      }
    }
  }`;
}

@Injectable()
export class COMPANY_ACCEPTANCE_UPDATE extends Mutation<UpdateMutation> {
  override document = gql`
  mutation COMPANY_ACCEPTANCE_UPDATE($id: bigint!, $company_id: bigint!, $day_id: smallint!, $start_hour: time, $finish_hour: time, $count: numeric) {
    update_company_acceptances(where: {id: {_eq: $id}, company_id: {_eq: $company_id}}, _set: {day_id: $day_id, start_hour: $start_hour, finish_hour: $finish_hour, count: $count}) {
      affected_rows
    }
  }`;
}

@Injectable()
export class COMPANY_ACCEPTANCE_DELETE extends Mutation<DeleteMutation> {
  override document = gql`
  mutation COMPANY_ACCEPTANCE_DELETE($id: bigint!, $company_id: bigint!) {
    delete_company_acceptances(where: {id: {_eq: $id}, company_id: {_eq: $company_id}}) {
      affected_rows
    }
  }`;
}