import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-shared-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.css']
})
export class DrawerComponent implements OnInit {

  @Input() show: boolean = false
  @Input() title: string = "Drawer"
  @Input() isScroll: boolean = true;
  @Output() toggleDrawer = new EventEmitter<void>();

  @Input() drawerWidth:String = '300px';

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges() {
    if (this.show) {
      document.getElementById('drawer')?.classList.add('drawer-on');
      document.getElementById('drawer-overlay')?.classList.remove('nonvisible')
    } else {
      document.getElementById('drawer')?.classList.remove('drawer-on');
      document.getElementById('drawer-overlay')?.classList.add('nonvisible')
     // this.drawerStatus.emit(false);
    }
  }

  drawerClose() {
    this.toggleDrawer.emit();
  }

}
