import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface CompanyGsm {
  id: bigint;
  track_id: Number;
  name: String;
  code: String;
  company_id: Number;
  active?: Boolean;
  companies?:{
    name?: String;
  }
}

interface ResponseArray {
    company_gsms: CompanyGsm[];
}

interface ResponseSingle {
    company_gsms_by_pk: CompanyGsm
}

@Injectable()
export class COMPANY_GSM_ALL extends Query<ResponseArray> {
  override document = gql`
    query COMPANY_GSM_ALL($where: company_gsms_bool_exp){
      company_gsms(where: $where) {
        id
        name
      }
    }`;
}

@Injectable()
export class COMPANY_GSM_ID extends Query<ResponseSingle> {
  override document = gql`
    query COMPANY_GSM_ID($id: bigint!){
      company_gsms_by_pk(id: $id) {
        id
        track_id
        name
        code
        company_id
        active
        
      }
    }
  `;
}

@Injectable()
export class POST_COMPANY_GSM extends Mutation<any> {
  // connected_part_id - int | nullable
  override document = gql`
  mutation POST_COMPANY_GSM(
      $name: String, 
      $code: String, 
      $company_id: bigint, 
      $active: Boolean
      ) {
    insert_company_gsms(objects: {
        name: $name, active: $active, code: $code, company_id: $company_id}) {
      affected_rows
      returning {
        id
      }
    }
  }
  
  `;
}

@Injectable()
export class COMPANY_GSM_UPDATE extends Mutation<any> {
  override document = gql`
  mutation COMPANY_GSM_UPDATE(
      $id: bigint, 
      $name: String!, 
      $code: String, 
      $company_id: bigint, 
      $active: Boolean) {
    update_company_gsms(where: {id: {_eq: $id}}, _set: {code: $code, active: $active, name: $name, company_id: $company_id}) {
      affected_rows
      returning {
        id
      }
    }
  }`;
}

@Injectable()
export class COMPANY_GSM_DELETE extends Mutation<any> {
  override document = gql`
    mutation COMPANY_GSM_DELETE($id: bigint!) {
      delete_company_gsms(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class COMPANY_GSM_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query COMPANY_GSM_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = desc, $where: company_gsms_bool_exp) {
      company_gsms(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        name
        track_id
        company_id
        code
        active
        companies {
            name
        }
        
      }
    }
  `;
}

@Injectable()
export class COMPANY_GSM_COUNT extends Query<any> {
  override document = gql`
  query COMPANY_GSM_COUNT($company_id: bigint!) {
    company_gsms_aggregate(where: {company_id: {_eq: $company_id}}) {
      aggregate {
        count
      }
    }
  }
  `;
}

@Injectable()
export class COMPANY_GSM_DEACTIVE extends Mutation<any> {
  override document = gql`
    mutation COMPANY_GSM_DEACTIVE($id: bigint!, $company_id: bigint!) {
      update_company_gsms(where: {_not: {id: {_eq: $id}}, _and: {company_id: {_eq: $company_id}}}, _set: {active: false}) {
        affected_rows
      }
    }
  `;
}