import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DateSelectArg, EventApi, EventClickArg, FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import Swal from 'sweetalert2';
import { fullcalendarItem, INITIAL_EVENTS } from './event-utils';

@Component({
  selector: 'app-shared-fullcalendar',
  templateUrl: './fullcalendar.component.html',
  styles: [
  ]
})
export class FullcalendarComponent implements OnInit {

  @ViewChild('calendar') calendarComponent: FullCalendarComponent | undefined;
  @Input() itemList:fullcalendarItem[] | undefined;
  currentEvents: EventApi[] = [];
  calendarVisible = true;
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    initialView: 'dayGridMonth',
    initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    locale: 'tr',
    eventClick: this.handleEventClick.bind(this),
  };

  constructor() { }

  ngOnInit(): void {
   
    
    
    
  }

  ngOnChanges(changes: SimpleChanges) {

    
    let ii = INITIAL_EVENTS;

    if(this.itemList != undefined){

      for(let i=0;i<this.itemList.length;i++){
        let item = this.itemList[i];
      
        
        ii.push({
          title: item.title,
          start: item.start,
          end: item.end
        });
      }
    }


  }

  handleEventClick(clickInfo: EventClickArg) {
    let startdate =  moment(clickInfo.event.startStr).format('"DD/MM/yyyy HH:mm"');
    let enddate = moment(clickInfo.event.endStr).format('"DD/MM/yyyy HH:mm"');
    let title = clickInfo.event.title;
   
    Swal.fire({
      title: title + "\n" + startdate ,
      icon: 'info',
      
    });
  
  }

  

}
