import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { City, GET_CITIES } from 'src/app/graphql/cities.graphql';
import { Company, COMPANY_ALL, COMPANY_COUNT, COMPANY_PAGINATE } from 'src/app/graphql/company.graphql';
import { VehicleBrand, VEHICLE_BRAND_ALL } from 'src/app/graphql/vehicle-brand.graphql';

@Component({
  selector: 'app-vehicle-services-list',
  templateUrl: './vehicle-services-list.component.html',
  providers:[COMPANY_PAGINATE,COMPANY_COUNT,VEHICLE_BRAND_ALL,COMPANY_ALL,GET_CITIES],
  
  styleUrls: ['./vehicle-services-list.component.css']
})
export class VehicleServicesListComponent implements OnInit {

  public companyList: Company[] | undefined;
  public vehicleBrandList: VehicleBrand[] | undefined;
  public dataCount: number = 0;
  public limit: number = 20;
  public offset: number = 0;
  public selectedBrand: bigint | undefined;
  private selectedCity: bigint | undefined;
  private orderBy: object = {"id":"asc"};
  public brandSelect: boolean = false;
  public cityList: City[] | undefined;
  private where: object = {};

  constructor(
    private getCompany: COMPANY_ALL,
    private getCompanyCount: COMPANY_COUNT,
    private getVehicleBrand: VEHICLE_BRAND_ALL,
    private getCityList: GET_CITIES,
    private router: Router
  ) { }

  ngOnInit(): void {


    this.getList();

    this.getCityList.watch({country_id:1}).valueChanges.subscribe(response => {
      this.cityList = response.data.cities;
    })
    
  }

  getList(){
    // Get Count and Calculate
    this.getCompanyCount.watch(this.where).valueChanges.subscribe((result:any)=>{
      this.dataCount = result.data.companies_aggregate.aggregate.count;
    })

    this.setOffset(this.offset)

  }

  // Shared Offset Event
  setOffset($event: number) {
    this.offset = $event

    Object.assign(this.where,{"active":{"_eq":true}});

    this.getCompany.watch({ where: this.where,order_by:{"name":"asc"} },{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
      this.companyList = result.data.companies
    });

    this.getVehicleBrand.watch({},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
      this.vehicleBrandList = response.data.vehicle_brands;
    })
  }

  search(){

    let whereObj: Object = {"active":{"_eq":true}};

    if(this.selectedBrand != null && this.selectedBrand > 0){
      Object.assign(whereObj, { "company_vehicle_brands": { "company_id":{"_eq": this.selectedBrand}}})
    }

    if(this.selectedCity != null && this.selectedCity > 0){
      Object.assign(whereObj, { "city_id": { "_eq": this.selectedCity}})
    }

    if(whereObj != null){
      this.getCompany.watch({ where: {...whereObj},order_by:this.orderBy},{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
        this.companyList = result.data.companies
      });
    }else{
      //this.getList();
    }

  }

  filterResults($event:any,filterInput:String){

    switch(filterInput){
      case "brand":
        this.selectedBrand = $event;
      break;
      case "city":
        this.selectedCity = $event;
      break;
      case "order":
        switch($event){
          case "sname":
            this.orderBy = {"name":"asc"};
          break;
          case "point":
            this.orderBy = {"evalutaion_points":"desc"};
            break;
          case "city":
            this.orderBy = {"city_id":"asc"}
          break;
        }
      break;
    }

    this.search();

  }

  getUrl(name:String,id:bigint){
    return name.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '')+"-"+id;            // Trim - from end of text
  }

}
