import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Order, ORDER_ID } from 'src/app/graphql/order.graphql';
import { OrderType, ORDER_TYPE_ALL } from 'src/app/graphql/order_type.graphql';
import { PartType, PART_TYPE_ALL } from 'src/app/graphql/part_type.graphql';
import { PartUnitType, PART_UNIT_TYPE_ALL } from 'src/app/graphql/part_unit_type.graphql';
import { numberFormat, numberFormatStr } from 'src/app/helpers/slugify';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shared-order-part-add',
  templateUrl: './order-part-add.component.html',
  styles: [
  ],
  providers:[ORDER_TYPE_ALL,PART_UNIT_TYPE_ALL]
})
export class OrderPartAddComponent implements OnInit {

  @Input() orderId: BigInt | undefined;
  @Input() invoiceId: BigInt | undefined;
  private baseUrl: string = environment.laravelApiEndpoint;
  public orderTypeList: OrderType[] | undefined;
  public avaragePrice: any;
  public formGroup = new FormGroup({
    order_type_id:  new FormControl(''),
    search_key:  new FormControl('', [Validators.required]),
    part_type_id: new FormControl('',[Validators.required]),
    search_part_type_id: new FormControl(1,[Validators.required]),
    vehicle_model_id: new FormControl(null,[Validators.required]),
    order_id: new FormControl(null,[Validators.required]),
    vehicle_model_type_id: new FormControl(null,[Validators.required]),
    page: new FormControl(0,[Validators.required])
  })
  

  public createFormGroup = new FormGroup({
    id: new FormControl(null,[Validators.required]),
    part_type_id:  new FormControl(null,[Validators.required]),
    product_count:  new FormControl(null,[Validators.required]),
    order_id:  new FormControl(null,[Validators.required]),
    invoice_id:  new FormControl(null,[Validators.required]),
    part_id:  new FormControl(null,[Validators.required]),
    vehicle_model_type_id:  new FormControl(null,[Validators.required]),
    part_unit_type_id:  new FormControl(1,[Validators.required]),
    user_id:  new FormControl(null,[Validators.required]),
    labour_price:  new FormControl(null,[Validators.required]),
    brand_labour_hour:  new FormControl(null,[Validators.required]),
  });
  public order: Order | undefined;
  public partUnitsTypes: PartUnitType[] | undefined;
  public selectedPartId:any;

  public partList: any;
  public labourPrice: any;
  public searchBox: boolean = false;
  public searchList: any;
  public searchText: any;
  public selectedPart:any;
  public labour: any;
  public manuelParts: any;
  public spinnerOn = false;
  public sendSearchRequest = true;

  
  constructor(
    private getOrderTypes: ORDER_TYPE_ALL,
    private alertifyService: AlertifyService,
    private http: HttpClient,
    private getOrder: ORDER_ID,
    private authService: AuthService,
    private getPartUnitTypes: PART_UNIT_TYPE_ALL
  ) { 

  }

  @ViewChild('labourPriceInputs', { static: false }) labourPriceInputs!: ElementRef;
  @ViewChild('partUnitInputs', { static: false }) partUnitInputs!: ElementRef;


  ngOnInit(): void {
    this.formGroup.patchValue({order_id:this.orderId});
    this.getOrderTypes.watch({where:{active:{_eq:true}}}).valueChanges.subscribe(resp => {
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }

      if(resp.data){
        this.orderTypeList = resp.data.order_types;
      }
    })

    this.getOrder.watch({id:this.orderId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{

      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }

      if(resp.data){

        this.order = resp.data.orders_by_pk;
        this.formGroup.patchValue({
          part_type_id:this.order.selected_package_type.id,
          vehicle_model_id : this.order.vehicle.vehicle_model.id,
          vehicle_model_type_id: this.order.vehicle.vehicle_model_types.id
        });

      }
    });

    this.getPartUnitTypes.watch().valueChanges.subscribe(resp => {
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.partUnitsTypes = resp.data.part_unit_types;
      }
    })

    this.getManuelParts();

   
  }



  selectedTwo(type:string,event:any){

    switch(type){
      case 'orderTypeList':
        this.formGroup.patchValue({order_type_id:event}); 
      break;
      case "searchPart":
        this.formGroup.patchValue({search_key:event.target.value}); 
      break;
    }

  }

  searchPart(loadmore=false){
    if(!loadmore){
      this.formGroup.patchValue({page:0});
    }
    
    this.http.post<any>(this.baseUrl + "order/search-part", this.formGroup.value  ,
      { headers: { token: `${localStorage.getItem('access_token')}` } }
      ).subscribe(response=>{
        if(response.status === false){
          this.alertifyService.error(response.message);
        }else{
          if(loadmore){

            this.partList.push(...response.result);

            this.avaragePrice += response.result.avarage;
          }else{
            this.partList = response.result;
            this.avaragePrice = response.result.avarage;
          }
          
        }});
  }

  createPart(id:bigint,part_type_id:number, productCount: string,part_id:number,productCountType,brand_labour_hour:string){

    let obj = {
      part_id: part_id,
      model_type_id: this.order?.vehicle.model_type_id
    }

     this.http.post<any>(this.baseUrl + "order/get-labour-price", obj  ,
        { headers: { token: `${localStorage.getItem('access_token')}` } }
        ).subscribe(response=>{
          if(response.status === false){
            this.alertifyService.error(response.message);
          }else{
            this.labour = response.result;
            this.labourPrice = this.labour.labour_price;
            this.createFormGroup.patchValue({
              id: id,
              part_type_id: part_type_id,
              product_count: productCount,
              brand_labour_hour: brand_labour_hour,
              order_id: this.orderId,
              invoice_id: this.invoiceId,
              part_id: part_id,
              vehicle_model_type_id: this.order?.vehicle.model_type_id,
              part_unit_type_id: productCountType,
              user_id: this.authService.userId,
              labour_price: this.labourPrice
            })

           

            if(this.createFormGroup.invalid){
              this.alertifyService.error('Hatalı form grubu');
            }else{
              
              this.http.post<any>(this.baseUrl + "order/add-part-to-order", this.createFormGroup.value  ,
              { headers: { token: `${localStorage.getItem('access_token')}` } }
              ).subscribe(response=>{
                if(response.status === false){
                  this.alertifyService.error(response.message);
                }else{
                  this.alertifyService.success("Parça eklendi");
                  this.partList = undefined;
                  this.labourPrice = null;
                  this.getManuelParts();
                  this.searchBoxToggle();
                }
              });
            }

          }
        });   

    
  
      /*let obj = {
        id: id,
        part_type_id: part_type_id,
        product_count: productCount,
        order_id: this.orderId,
        invoice_id: this.invoiceId,
        part_id: part_id,
        vehicle_model_type_id: this.order?.vehicle.model_type_id,
        part_unit_type_id: 1,
        user_id: this.authService.userId,
      }*/

      
  }

  setSearchPartType($event){
    this.formGroup.patchValue({search_part_type_id:$event.target.value});
  }

  setSelectedPart(id:number,part_id:number){
    this.createFormGroup.patchValue({id:id,part_id:part_id});
    this.getLabourPrice();
  }

  getLabourPrice(){

    let obj = {
      part_id: this.createFormGroup.value.part_id,
      model_type_id: this.order?.vehicle.model_type_id
    }

    this.http.post<any>(this.baseUrl + "order/get-labour-price", obj  ,
        { headers: { token: `${localStorage.getItem('access_token')}` } }
        ).subscribe(response=>{
          if(response.status === false){
            this.alertifyService.error(response.message);
          }else{
            this.labour = response.result;
            this.labourPrice = this.labour.labour_price;
          
          }
        });
  }

  setLabourPrice(event:any){
    this.labourPrice = event.target.value;
  }

  searchBoxToggle(){
    this.searchBox = !this.searchBox;;
  }

  closeSearchBox($event:boolean){
    this.searchBox = $event;
  }

  search(event:any,loadmore=false){
 
    if(!loadmore){
      this.formGroup.patchValue({'page':0});
    
    }

    if(this.sendSearchRequest){
      this.sendSearchRequest = false;
      this.spinnerOn = true;
      this.http.post<any>(this.baseUrl + "order/search-part", this.formGroup.value  ,
          { headers: { token: `${localStorage.getItem('access_token')}` } }
          ).subscribe(response=>{
            if(response.status === false){
              this.alertifyService.error(response.message);
            }else{
              this.spinnerOn = false;
              if (loadmore) {
                if(response.result.length > 0){
                  this.sendSearchRequest = true;
                  this.searchList = this.searchList.concat(response.result);
                }else{
                  this.sendSearchRequest = false;
                }
             
            } else {
              this.searchList = response.result;
            }
            }
            this.sendSearchRequest = true;
          });
    }
    
  }

  setPart(item:any){
    this.selectedPart = item;
    this.createFormGroup.patchValue({id:item.id,part_id:item.part_id,part_unit_type_id:item.part_unit_type});
    this.searchBoxToggle();
    this.getLabourPrice();
  }

  toNumberFormat(number: any) {
    return numberFormatStr(number);
  }

  toNumber(number: any){
    return numberFormat(number)
  }
  
  addPart(productCount:any){
 
    this.createFormGroup.patchValue({
      id: this.selectedPart.id,
      part_type_id: this.selectedPart.part_type_id,
      product_count: productCount,

      order_id: this.orderId,
      invoice_id: this.invoiceId,
      part_id: this.selectedPart.part_id,
      vehicle_model_type_id: this.order?.vehicle.model_type_id,
      part_unit_type_id: this.selectedPart.part_unit_type,
      user_id: this.authService.userId,
      labour_price: this.labourPrice
    })
  
      /*let obj = {
        id: id,
        part_type_id: part_type_id,
        product_count: productCount,
        order_id: this.orderId,
        invoice_id: this.invoiceId,
        part_id: part_id,
        vehicle_model_type_id: this.order?.vehicle.model_type_id,
        part_unit_type_id: 1,
        user_id: this.authService.userId,
      }*/

    

      if(this.createFormGroup.invalid){
        this.alertifyService.error('Hatalı form grubu');
      }else{
        this.http.post<any>(this.baseUrl + "order/add-part-to-order", this.createFormGroup.value  ,
        { headers: { token: `${localStorage.getItem('access_token')}` } }
        ).subscribe(response=>{
          if(response.status === false){
            this.alertifyService.error(response.message);
          }else{
            this.alertifyService.success("Parça eklendi");
            this.partList = undefined;
            this.labourPrice = null;
            this.getManuelParts();
          }
        });
      }
  }

  setTotalPrice(partUnit:any){
    let total = (this.selectedPart.part_no_vat_price * partUnit);
    return this.toNumberFormat(total);
  }

  getManuelParts(){
    if(this.orderId){
      this.http.post<any>(this.baseUrl + "order/get-manuel-parts", {order_id:this.orderId}  ,
      { headers: { token: `${localStorage.getItem('access_token')}` } }
      ).subscribe(response=>{
        if(response.status === false){
          this.alertifyService.error(response.message);
        }else{
       
          this.manuelParts = response.result;
          
          
        }
      });
    }
  }
  editPart(inputElement: any,item:any){
     const oldPartVal = item.part_unit;
    
 
    Swal.fire({
      title: 'Parça adedini güncellemek istiyor musunuz?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Vazgeç',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Evet Güncelle'
    }).then((result) => {
      if (result.isConfirmed) {
        const elementId = 'partUnitInputs_' + item.id;
        const inputElement = document.getElementById(elementId) as HTMLInputElement;
        if (inputElement) {
          const inputValue = inputElement.value;

          let obj = {
            order_part_id: item.id,
            new_unit: inputValue,
            selected_part_id: item.selected_part_id
          };

          this.http.post<any>(this.baseUrl + "order/update-part-unit", obj  ,
          { headers: { token: `${localStorage.getItem('access_token')}` } }
          ).subscribe(response=>{
            if(response.status === false){
              this.alertifyService.error(response.message);
            }else{
           
              this.alertifyService.success('Parça adeti güncellendi');
              this.getManuelParts();
              
            }
          });
    
        
        }
      }
      else{
        inputElement.nativeElement.value = oldPartVal;
      }
    });
  }

  editLabour(item:any){


     const elementId = 'labourPriceInputs_' + item.id;
    const inputElement = document.getElementById(elementId) as HTMLInputElement;
    if (inputElement) {
      const inputValue = inputElement.value;
      // Edit logic using inputValue and item
    }
    const oldPartVal = item.part_no_vat_price;
   
    Swal.fire({
      title: 'İşçilik ücretini güncellemek istiyor musunuz?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Vazgeç',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Evet Güncelle'
    }).then((result) => {
      if (result.isConfirmed) {

        let obj = {
          order_part_id: item.id,
          new_price: inputElement.value,
          selected_part_id: item.selected_part_id
        };
        
      this.http.post<any>(this.baseUrl + "order/update-labour-price", obj  ,
      { headers: { token: `${localStorage.getItem('access_token')}` } }
      ).subscribe(response=>{
        if(response.status === false){
          this.alertifyService.error(response.message);
        }else{
       
          this.alertifyService.success('İşçilik ücreti güncellendi');
          this.getManuelParts();
          
        }
      });

      }
      else{
        inputElement.value = item.part_no_vat_price;
      }
    });
  }

  onScroll(event: any): void {
    if(this.sendSearchRequest){
      const element = event.target;
      if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
        // Scroll sonuna ulaşıldı
        this.formGroup.patchValue({page:this.formGroup.value.page + 1});
    
        this.search("",true);
      }
    }
    
  }

  setPageNumber(){
    if(this.sendSearchRequest){
      this.formGroup.patchValue({page:this.formGroup.value.page + 1});
      this.search("",true);
    }
  }

  deletePart(id:number){
    Swal.fire({
      title: 'Parçayı silmek istediğinize emin misiniz?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Vazgeç',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Evet Sil'
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.post<any>(this.baseUrl+"order/delete-external-part",
                        {id:id},
                        { headers: { token: `${localStorage.getItem('access_token')}` } })
                        .subscribe(resp => {
                            if(!resp.status){
                              this.alertifyService.error(resp.message);
                            }else{
                              this.alertifyService.success(resp.message);
                              this.getManuelParts();
                            }
                        })
      }
    })
    

  }
}
