<div class="w-100">
    <ul class="nav nav-tabs nav-line-tabs mb-5 fs-6" >
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{active: vehicleTab === 'new'}"
                (click)="tabChange('new')">Yeni Araç Ekle</a>
        </li>
        <li class="nav-item" *ngIf="authService.isLogin == true">
            <a class="nav-link" [ngClass]="{active: vehicleTab === 'old'}"
                (click)="tabChange('old')">Kayıtlı Araçlarım </a>
        </li>
    </ul>
    <!-- kayıtlı araçlar -->
    <div *ngIf="vehicleTab === 'old'">
        <div class="mb-10">
            <label class="form-label required">Garaj Seçiniz</label>
            <app-shared-selectwo
            [lists]="garageList"
            [name]="garageName"
            [id]="'garageSelect'"
            [showAll]="false"
            (selectedItem)="selected($event)"></app-shared-selectwo>
           
        </div>

        <label class="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5"
            *ngFor="let vehicle of vehicleList">
            <div class="d-flex align-items-center me-2">
                <div
                    class="form-check form-check-custom form-check-solid form-check-primary me-6">
                    <input class="form-check-input" type="radio" name="garage"
                        value="" (click)="chooseVehicle(vehicle)" [checked]="vehicle.id == vehicleId" />
                </div>
                <div class="flex-grow-1">
                    
                    <div class="fw-bold opacity-50">
                        {{vehicle.id}}
                        {{vehicle.vehicle_brand.name}}
                        {{vehicle.vehicle_model.name}}
                        {{vehicle.years.year_id}}
                    </div>
                </div>
            </div>
        </label>
    </div>
    <!-- yeni araç ekleme -->
    <div *ngIf="vehicleTab === 'new'">
      
        <div *ngIf="authService.isLogin">
            <div class="mb-10" *ngIf="authService.userRole == 'anonymous' || (garageList && garageList.length == 0); else garage_select">
                <label class="form-label required">Garaj İsmi</label>
                <input type="text" class="form-control" (change)="setGarageName($event)">
            </div>
            {{garageId}} {{vehicleTab}}
            <ng-template #garage_select>
                <div class="mb-10">
                    <label class="form-label required">Garaj Seçiniz</label>
                    <app-shared-select
                    [lists]="garageList"
                    name="Garaj Seçiniz"
                    (selected)="setGarage($event)"
                   
                    ></app-shared-select>
                   
                </div>
            </ng-template> 
        </div>
        
         
        <div class="mb-10">
            <label class="form-label required">Araç Markası Seçiniz</label>
            <app-shared-selectwo
                [name] = "brandName"
                [lists] = "vehicleBrands"
                [id]="'vehicleBrands'"
                [setItem]="vehicleForm?.brand_id"
                (selectedItem)="selectedTwo('brand',$event)"
                [showAll]="false">
            </app-shared-selectwo> 
           
        </div>
        <div class="mb-10" *ngIf="vehicleModels">
            <label class="form-label required">Araç Modeli Seçiniz</label>
            <app-shared-selectwo
                [name] = "modelName"
                [lists] = "vehicleModels"
                [id]="'vehicleModels'"
                [setItem]="vehicleForm?.model_id"
                (selectedItem)="selectedTwo('model',$event)"
                [showAll]="false"
            >
            </app-shared-selectwo> 
           
        </div>
        <div class="mb-10" *ngIf="vehicleModelYearList">
            <label class="form-label required">Araç Yılı Seçiniz</label>
            <app-shared-selectwo 
                [lists]="vehicleModelYearList" 
                (selectedItem)="selectedTwo('year',$event)" 
                [name]="selectedYear" 
                [id]="'vehicle-year'"
                [setItem]="vehicleForm?.model_year_id"
                [fieldName]="'year_id'"
                [showAll]="false"
                [returnField]="'id'">
            </app-shared-selectwo>
        </div>
        <div class="mb-10" *ngIf="vehicleModelTypes">
            <label class="form-label required">Araç Model Tipi Seçiniz</label>
            <app-shared-selectwo
                [name] = "modelTypeName"
                [lists] = "vehicleModelTypes"
                [id]="'vehicleModelTypes'"
                [setItem]="vehicleForm?.model_type_id"
                [showAll]="false"
                (selectedItem)="selectedTwo('vehicle_model_types',$event)">
            </app-shared-selectwo> 
        </div>
        
        <div class="mb-10 text-center"  *ngIf="vehicleModelTypes && authService.isLogin">
            <button class="btn btn-primary" (click)="createIfCarNotExist()" type="submit" >Aracı Kaydet</button>
        </div>
    </div>
</div>