import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shared-image-carousel-modal',
  templateUrl: './image-carousel-modal.component.html',
  styles: [
  ],
  styleUrls: ['./image-carousel-modal.component.css']
})

export class ImageCarouselModalComponent implements OnInit {
  @Input() carouserImages: any[] = [];
  @Input() selectedImageIndex: number = 0;
  @Input() isDeletable:boolean = false;
  @Output() removeImage = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    
  }
  

  closeModal() {
    this.activeModal.close();
  }

  prevImage() {
    if (this.selectedImageIndex > 0) {
      this.selectedImageIndex--;
    } else {
      this.selectedImageIndex = this.carouserImages.length - 1;
    }
  }

  nextImage() {
    if (this.selectedImageIndex < this.carouserImages.length - 1) {
      this.selectedImageIndex++;
    } else {
      this.selectedImageIndex = 0;
    }
  }

  delImage(id:Number,image_name:string){

    Swal.fire({
      title: 'Silme İşlemini Onaylıyor musunuz?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Vazgeç',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Evet Sil'
    }).then((result) => {
      if (result.isConfirmed) {

        this.removeImage.emit({id:id,image_name:image_name});
    this.closeModal();
       
      }
    })

    
  }

  downloadImage(imageUrl: string, imageName: string) {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = imageName;
    link.target = '_blank'; // Bu, url'ye gitmesini önler.
    
    // Programatik olarak tıklatma
    link.click();
  }
  

}
