import { Injectable } from '@angular/core';
import { gql, Mutation, Query } from 'apollo-angular';

export interface OrderCardParts {
  id: bigint;
  order_card_id: bigint;
  part_id: Number;
  labour_type_id?: bigint;
  labour_value?: Number;
  labour_discount?: Number;
  part_type_id?: Number;
  part_in_vat_price?: Number;
  part_no_vat_price?: Number;
  part_discount?: Number;
  registered_personel_id?: bigint;
  updated_personel_id?: bigint;
  service_value?: Number;
  part_types:any
  is_service?:boolean;
  price_unit_id?:bigint,
  price_without_currency?:Number,
  price_vat_without_currency?:Number,
  currency?:Number,
  created_at?:Date,
  part_unit_type_id: Number,
  part_unit: Number,
  part_code:String,
  part_name:String,
  package_detail_id: bigint,
  selected_type: String
}

interface ResponseArray {
  order_card_parts: OrderCardParts[];
}

interface ResponseSingle {
  order_card_parts_by_pk: OrderCardParts
}

@Injectable()
export class ORDER_CARD_PARTS_ALL extends Query<ResponseArray>{
  override document = gql`
  query ORDER_CARD_PARTS_ALL($where: order_card_parts_bool_exp) {
        order_card_parts(where: $where){
          id
          order_card_id
          part_id
          labour_type_id
          labour_value
          labour_discount
          part_type_id
          part_in_vat_price
          part_no_vat_price
          part_discount
          registered_personel_id
          updated_personel_id
          service_value
          is_service
          price_unit_id
          price_without_currency
          price_vat_without_currency
          currency
          part_name
          created_at
          part_unit_type_id
          part_code
          part_unit
          package_detail_id
          selected_type
          part_types{
            id
            name
          }
        }
      }`;
}

@Injectable()
export class ORDER_CARD_PARTS_CREATE extends Mutation {
  override document = gql`
  mutation ORDER_CARD_PARTS_CREATE( $object: order_card_parts_insert_input! ) {
    insert_order_card_parts_one(object: $object) {
        id   
    }
}`;
}

@Injectable()
export class ORDER_CARD_PARTS_UPDATE extends Mutation<any> {
  override document = gql`
    mutation ORDER_CARD_PARTS_UPDATE($id: bigint!, $changes: order_card_parts_set_input ) {
      update_order_card_parts(where: {id: {_eq: $id}}, _set: $changes) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_CARD_PARTS_DELETE extends Mutation<any>{
    override document = gql`
    mutation ORDER_CARD_PARTS_DELETE($where: order_card_parts_bool_exp!) {
        delete_order_card_parts(where: $where) {
        affected_rows
        returning {
          id
        }
      }
    }`;
}
