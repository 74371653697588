import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface OrderBids{
  id: bigint;
  track_id?:String;
  start_date: Date;
  order_id: bigint;
  bid_status_id: Number;
  mail_informed: boolean;
  created_at?:Date;
  updated_at?:Date;
  order_bid_part_offers?: any;
  order_bid_parts?: any;
  order?: any;
  order_bid_status?: any; 
  api_finish_time: Date;
  part_type2: any;
}

interface ResponseArray {
  order_bids: OrderBids[];
}

interface ResponseSingle {
  order_bids_by_pk: OrderBids
}



@Injectable()
export class ORDER_BIDS_ALL extends Query<ResponseArray> {
  override document = gql`
    query ORDER_BIDS_ALL($where: order_bids_bool_exp){
        order_bids(where: $where) {
        id
        track_id
        start_date
        order_id
        bid_status_id
        mail_informed
        created_at
        updated_at
        order_bid_status{
          name
        }
        order {
          vehicle {
            plate
          }
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_BIDS_ONE extends Query<ResponseArray> {
  override document = gql`
    query ORDER_BIDS_ONE($where: order_bids_bool_exp, $whereOffer: order_bid_part_offers_bool_exp){
        order_bids(where: $where, limit:1, order_by: {id: desc}) {
        id
        track_id
        start_date
        order_id
        bid_status_id
        mail_informed
        created_at
        updated_at
        api_finish_time
        
        order_bid_parts(order_by: {part: {part_group: {position_no: desc}}}) {
          id
          bid_id
          part_id
          bid_part_status_id
          bid_part_orj_code
          bid_part_orj_value
          bid_part_discount
          bid_part_amount
          selected_part_id
          selected_part_type_id
          choosed_part_price
          part_unit_type_id
          part_unit
          selected_offer_id
          selected_answer_id
          order_parts{
            id
            order_invoice_parts{
              id
              part_code
            }
          }
            selected_offer{
              id
              selected_answer_id
          }
          selected_answer{
            id
            part_price
            offer_part_price
          }
          part_unit_type{
            id
            name
          }
          part_type{
            id
            name
          }
          part_original_list{
            id
            name
            part_code
          }
          part_equivalent_list{
            id
            name
            part_code
            part_original_id
          }
          note
          created_at
          updated_at
          part{
            name
            part_group{
              id
              name
              position_no
            }
          }
          order_bid_part_offers(where:$whereOffer,order_by: {id: desc}){
            id
            bid_id
            part_id
            part_type_id
            part_code
            part_value
            part_discount
            part_vat
            part_final_price
            delivery_days
            send_date
            estimated_delivery_date
            stock_check
            part_brand_id
            company_id
            note
            track_id
            status_id
            created_at
            updated_at
            order_offers{
              id
              order_bid_part_offer_id
              auction_id
              processed
              created_at
              order_offers_answers{
                id
                current_name
                part_code
                equivalent_part_code
                part_name
                available
                part_price
                discount
                offer_part_count
                offer_part_price
                delivery_day_count
                order_offers_id
                created_at
                part_brand
                part_types{
                  id
                  name
                }
                shipment_types{
                  id
                  name
                }
                cargo_company_id
                cargo_number
              }
              selected_order_offers_answer{
                id
                auction_answer_id
                auction_number
                file_number
                current_name
                part_code
                equivalent_part_code
                part_name
                available
                part_price
                discount
                offer_part_count
                offer_part_price
                delivery_day_count
                order_offers_id
                created_at
                part_brand
                cargo_company_id
                cargo_number
                shipment_types{
                  id
                  name
                }
                part_types{
                  id
                  name
                }
              }
            }
            order_bid_parts{
              bid_part_orj_code
              bid_part_orj_value
              bid_part_discount
              bid_part_amount
            }
            order_bid_part_offer_stock{
              company_id
              stock_name
              stock_count
            }
            order_bid_part_offer_stock_aggregate{
              aggregate {
                sum {
                  stock_count
                }
              }
            }
            bid_offer_shipment{
              shipment_date
              estimated_delivery_date
              delivery_date
              shipment_code

              shipment_type{
                name
              }
            }
            bid_offer_status{
              id
              name
            }
            company{
              id
              name
              logo
              company_type_id
            }
            order_bid_offer_part_status{
              id
              name
              class_name
            }
            part_types{
              id
              name
            }

          }
        }
      
       
      }
    }
  `;
}

@Injectable()
export class ORDER_BIDS_ID extends Query<ResponseSingle> {
  override document = gql`
    query ORDER_BIDS_ID($id: bigint!){
        order_bids_by_pk(id: $id) {
        id
        track_id
        start_date
        order_id
        bid_status_id
        mail_informed
        created_at
        updated_at
      }
    }
  `;
}

@Injectable()
export class ORDER_BIDS_CREATE extends Mutation<any> {
  // connected_part_id - int | nullable
  override document = gql`
    mutation ORDER_BIDS_CREATE( $object: order_bids_insert_input! ) {
        insert_order_bids_one(object: $object) {
          affected_rows
          returning {
            id
          }
        }
    }
  `;
}



@Injectable()
export class ORDER_BIDS_UPDATE extends Mutation<any> {
  override document = gql`
    mutation ORDER_BIDS_UPDATE($id: bigint!, $changes: order_bids_set_input ) {
      update_order_bids(where: {id: {_eq: $id}}, _set: $changes) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}


@Injectable()
export class ORDER_BIDS_DELETE extends Mutation<any> {
  override document = gql`
    mutation ORDER_BIDS_DELETE($id: bigint!) {
      delete_order_bids(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_BIDS_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query ORDER_BIDS_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: order_bids_bool_exp) {
      order_bids(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        track_id
        start_date
        order_id
        bid_status_id
        order {
          vehicle {
            plate
          }
        }
        mail_informed
        created_at
        updated_at
        order_bid_status{
          name
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_BIDS_COUNT extends Query<any> {
  override document = gql`
    query ORDER_BIDS_COUNT($where: order_bids_bool_exp) {
      order_bids_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}