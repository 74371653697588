import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertifyService } from '../alertify.service';
import { AuthService } from '../auth.service';

@Injectable()
export class CustomerGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private alertifyService: AlertifyService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const userRole = this.authService.userRole;
      if (this.authService.isTokenExpired()) {
        this.authService.logout();
        this.router.navigateByUrl('/auth');
        this.alertifyService.error("Oturum Süreniz Sona Erdi!")
        return false;
       
      } else if (this.isLogin() && this.isService()) {
        return true;
      } else {
        return this.authService.verify().pipe(map(response => {
          if (response.status) {
            this.authService.login();
           
          } else {
            this.authService.logout();
          }
        
          if (this.isLogin() && this.isService()) {
            return true;
          }
       
          this.router.navigateByUrl(this.authService.userRole);
          this.alertifyService.error("Bu Alana Girme Yetkiniz Bulunmuyor!")
          return false;
        }));
      }
      return false;
  }

  isLogin(): boolean {
    return this.authService.isLogin;
  }

  isService(): boolean {
    return this.authService.userRole === "customer";
  }

}
