import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidator } from 'src/app/helpers/custom.validator';
import { FireBaseEmailAuthReponse } from 'src/app/interfaces/firebase.interface';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: []
})
export class SignupComponent implements OnInit {

  @Output() login = new EventEmitter<boolean>()

  constructor(
    public router: Router,
    private authService: AuthService,
    private alertifyService: AlertifyService
  ) { }

  public authFormGroup = new FormGroup(
    {
      name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
      surname: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
      email: new FormControl('', [Validators.required, CustomValidator.isValidEmail]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      repassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
      check_sozlesme: new FormControl(false, [Validators.required, Validators.requiredTrue]),
      check_riza: new FormControl(false, [Validators.required, Validators.requiredTrue]),
      check_aydinlatma: new FormControl(false, [Validators.required, Validators.requiredTrue]),
      check_gizlilik: new FormControl(false, [Validators.required, Validators.requiredTrue]),
    },
    CustomValidator.isPasswordMatch('password', 'repassword')
  )

  ngOnInit(): void { }

  authFormSubmit() {
    if (this.authFormGroup.invalid) return;

    const name     = this.authFormGroup.value.name;
    const surname  = this.authFormGroup.value.surname;
    const email    = this.authFormGroup.value.email;
    const password = this.authFormGroup.value.password;

    // register
    this.authService.firebaseAuth(email, password, false).subscribe(response => {
   
      this.authService.apiRegister(response, name, surname,'',null,null).subscribe(response => {
       
        if (response.status) {
          // eğer teklif alma bölümünden geliyor ise yönlendirme yapma
          if (this.router.url == '/teklif-al') {
            this.login.emit(true)
          } else {
            
            this.router.navigateByUrl(this.authService.userRole)
          }
        } else {
          this.alertifyService.error("Kayıt Başarısız");
        }
      })
    }, err => {
      const response: FireBaseEmailAuthReponse = {} as FireBaseEmailAuthReponse
      response.email = email
      response.idToken = "order create, email exists in firebase"
      response.refreshToken = "order create, email exists in firebase"
      response.localId = "order create, email exists in firebase"
      this.userCreateApi(response, name, surname, '')
  
    })
    this.authFormGroup.reset();
  }

  userCreateApi(response: FireBaseEmailAuthReponse, name: String, surname: String, identity_no: String) {
    this.authService.apiRegister(response, name, surname, identity_no,null,null).subscribe(response => {
      if (response.status) {
        // eğer teklif alma bölümünden geliyor ise yönlendirme yapma
        if (this.router.url == '/') {
          this.login.emit(true)
        } else {
          window.location.href = this.authService.userRole
        }
      } else {
        this.alertifyService.error("Kayıt Başarısız");
      }
    })
  }

}
