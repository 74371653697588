import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PackageKms, PACKAGE_KMS_ALL } from 'src/app/graphql/package.kms';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-shared-kms-selector',
  templateUrl: './kms-selector.component.html',
  styles: [
  ],
  providers: [ PACKAGE_KMS_ALL]
})
export class KmsSelectorComponent implements OnInit {
  numbers: number[] = [10000, 20000, 30000, 40000];
  public inputNumber: number = 0;
  public closestNumber: number | undefined;
  public firstGreaterNumber: number | null = null;
  public nearestNumber: number | null = null;
  public kmsList: PackageKms[] | undefined;
  @Output() kmInfo = new EventEmitter<any>();
  @Input() kms: any;
  @Input() selectedKmId: number = 0;
  @Input() customerKm: number = 0;
  @Input() addRequiredValidator: boolean = false;
  @Input() isValid: any = false;

 
  constructor(
    private getKmList: PACKAGE_KMS_ALL,
    private alertifyService: AlertifyService
  ) { }



  

  ngOnInit(): void {

    
  //  this.kmsList = this.kms;
    this.getKmList.watch({where:{active:{_eq:true}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.kmsList = resp.data.maintenance_kms;
        if(this.selectedKmId){
          this.getKms(null,true);
        }
      }
    })
  }



  ngOnChanges(){
    
  }

  getKms($event:any,selectedKm=false){

    if($event.target.value == null){
      this.kmInfo.emit({kmList: [], customerKm: 0 , km_id_list: []});
    }
    if(selectedKm && this.selectedKmId > 0){
      this.inputNumber = this.customerKm;
    }else{
      this.inputNumber = $event.target.value;
    }
    
   // this.findNearestNumber();

    this.findClosestNumbers();
  }

  findClosestNumbers() {

      // Veri listesini hedef rakama göre sırala
      this.kms?.sort((a, b) => Math.abs(this.inputNumber - a.km) - Math.abs(this.inputNumber - b.km));
    
      // İlk iki öğeyi seç
      const closestNumbers = this.kms?.slice(0, 2);
    
    
      
      this.closestNumber = closestNumbers.map(item => ({ name: item.name, km: item.km,id: item.id }));
    
      this.kmInfo.emit({kmList: this.closestNumber, customerKm: this.inputNumber , km_id_list: closestNumbers.map(item => item.id).sort((a, b) => a - b)});
    
    
    }


  findClosestNumber(): void {
    
    const closest = this.numbers.reduce((prev, curr) => {
      if(this.inputNumber){
        return Math.abs(curr - this.inputNumber) < Math.abs(prev - this.inputNumber) ? curr : prev;
      }
      return curr;
    });
 
 

  this.closestNumber = closest;
  
}



findFirstGreaterNumber(): void {
  
    const sortedNumbers = this.numbers.sort((a, b) => a - b);
    const index = sortedNumbers.findIndex(num => num > this.inputNumber);
  
    if (index !== -1) {
      this.firstGreaterNumber = sortedNumbers[index];
    } else {
      this.firstGreaterNumber = null;
    }
  
}

  async findNearestNumber(): Promise<void> {
    let closest:number | null = null;
    let kmArray:any = [];
    let selectedKmId:BigInt | null = null;
    let firstKmId:BigInt | null;
    let lastKmInfo: any;



    if(this.kmsList && this.kmsList.length > 0){

      //const maxNumber = Math.max(this.kmsList);
      
      await this.kmsList.forEach(element => {
        kmArray.push(element.km);
        if(firstKmId == null){
          firstKmId = element.id;
        }
     
      if (this.inputNumber < 10000) {
        closest = 10000;
        selectedKmId = firstKmId;
      } else if (this.inputNumber <= element.km) {
        if (closest == null || element.km < closest) {
          closest = element.km;
          selectedKmId = element.id;
        }
      } else if (this.inputNumber <= element.km + 5000) {
        if (closest == null || element.km > closest) {
          closest = element.km;
          selectedKmId = element.id;
        }
      }

      lastKmInfo = { id: element.id, name: element.name };
    });
/*
    if (this.inputNumber > kmArray[kmArray.length - 1]) {
      closest = kmArray[kmArray.length - 1];

      let maxKmObject = this.kmsList.reduce((maxObject, currentObject) => {
        if (currentObject.km > maxObject.km) {
          return currentObject;
        } else {
          return maxObject;
        }
      });
    }*/

      
      if(this.inputNumber > kmArray[kmArray.length-1]){
       
        closest = kmArray[kmArray.length-1];

        let maxKmObject = this.kmsList.reduce((maxObject, currentObject) => {
          if(currentObject.km != null && maxObject.km == null){
            
          }
          if (currentObject.km > maxObject.km) {
            return currentObject;
          } else {
            return maxObject;
          }
        });
        selectedKmId = maxKmObject.id;
      
   
      }

      if (closest === null) {
        this.nearestNumber = this.numbers[this.numbers.length - 1];
      } else {
        this.nearestNumber = closest;
      }

      this.kmInfo.emit({selectedKmId: selectedKmId, selectedKmName: closest, customerKm: this.inputNumber });
    }

    
  }

}
