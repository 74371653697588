import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface OrderInvoiceParts {
  id: Number;
  invoice_id: bigint;
  part_id?: bigint;
  part_name?: string;
  part_code?: string;
  labour_type_id?:Number;
  labour_value?:Number;
  labour_discount?:Number;
  part_in_vat_price?:Number;
  part_no_vat_price?:Number;
  invoice_type?:Number;
  part_unit_type_id: Number;
  part_unit:Number;
  part_unit_types:any;
  part_type_id: Number;
  gos_part_id:Number,
  part_unit_price: Number,
  selected_part_id: bigint
}

interface ResponseArray {
  order_invoice_parts: OrderInvoiceParts[];
}

interface ResponseSingle {
    order_invoice_parts_by_pk: OrderInvoiceParts
}

@Injectable()
export class ORDER_INVOICE_PARTS_ALL extends Query<ResponseArray> {
  override document = gql`
    query ORDER_INVOICE_PARTS_ALL($where: order_invoice_parts_bool_exp){
        order_invoice_parts(where: $where) {
        id
        invoice_id
        part_id
        part_name
        part_code
        labour_type_id
        labour_value
        labour_discount
        part_in_vat_price
        part_no_vat_price
        invoice_type
        part_unit_type_id
        part_unit
        part_unit_types{
          name
        }
        part_type_id
        gos_part_id
        part_unit_price
       
      }
    }
  `;
}

@Injectable()
export class ORDER_INVOICE_PART_ID extends Query<ResponseSingle> {
  override document = gql`
    query ORDER_INVOICE_PART_ID($id: bigint!){
      order_invoice_parts_by_pk(id: $id) {
        id
        invoice_id
        part_id
        part_name
        part_code
        labour_type_id
        labour_value
        labour_discount
        part_in_vat_price
        part_no_vat_price
        invoice_type
        part_unit_type_id
        part_unit
        part_unit_price
      }
    }
  `;
}

@Injectable()
export class ORDER_INVOICE_PART_CREATE extends Mutation<ResponseSingle> {
  // connected_part_id - int | nullable
  override document = gql`
    mutation ORDER_INVOICE_PART_CREATE( $object: order_invoice_parts_insert_input! ) {
        insert_order_invoice_parts_one(object: $object) {
            id   
        }
    }
  `;
}



@Injectable()
export class ORDER_INVOICE_PART_UPDATE extends Mutation<any> {
  override document = gql`
    mutation ORDER_INVOICE_PART_UPDATE($id: bigint!, $changes: order_invoice_parts_set_input ) {
      update_order_invoice_parts(where: {id: {_eq: $id}}, _set: $changes) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}


@Injectable()
export class ORDER_INVOICE_PART_DELETE extends Mutation<any> {
  override document = gql`
    mutation ORDER_INVOICE_PART_DELETE($id: bigint!) {
      delete_order_invoice_parts(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_INVOICE_PART_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query ORDER_INVOICE_PART_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: order_invoice_parts_bool_exp) {
      parts(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        invoice_id
        part_id
        part_name
        part_code
        labour_type_id
        labour_value
        labour_discount
        part_in_vat_price
        part_no_vat_price
        invoice_type
        part_unit_type
        part_unit
        part_unit_price
      }
    }
  `;
}

@Injectable()
export class ORDER_INVOICE_PART_COUNT extends Query<any> {
  override document = gql`
    query ORDER_INVOICE_PART_COUNT($where: order_invoice_parts_bool_exp) {
      order_invoice_parts_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}