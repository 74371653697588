import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class MailService {

  private options = {
    headers: new HttpHeaders({
      'token': environment.gosToken
    })
  }

  constructor(
    private http: HttpClient
  ) { }

  send(to: string, subject: string, content: string, bcc: string[] = []): Observable<any> {
    const formData = new FormData();
    formData.append('to', to);
    formData.append('subject', subject);
    formData.append('content', content);
    if (bcc.length) {
      bcc.forEach(b => {
        formData.append('bcc[]', b);
      })
    }
    return this.http.post<any>(environment.laravelApiEndpoint + "mail/send", formData, this.options);
  }

}