import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Banners, BANNERS_ALL } from '../graphql/banners.graphql';
import { AlertifyService } from '../services/alertify.service';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styles: [
  ],
  styleUrls: ['./site.component.css'],
  providers:[BANNERS_ALL]
})
export class SiteComponent implements OnInit {

  public bannerList: Banners[] | undefined;
  public leftBanner:any;
  public rightBanner: any;
  public step = 1;

  constructor(
    public router: Router,
    private getBanners: BANNERS_ALL,
    private alertifyService: AlertifyService
  ) { }

  ngOnInit(): void {

    moment.locale('tr');
    moment.defaultFormat = "YYYY-MM-DD HH:mm";

    let now = moment().format();
    

      if(this.router.url == "/teklif"){

        this.getBanners.watch({
          where:{
            banner_pages:{
              tag: {
                _eq: "appointment"
              }
            },
            start_date:{
              _lte: now
            },
            end_date:{
              _gte: now
            }
          
          }
        },{fetchPolicy:"no-cache"}).valueChanges.subscribe(async resp => {
          if(resp.errors){
            this.alertifyService.error(resp.errors[0].message);
          }
          if(resp.data){
            this.bannerList = resp.data.banners;
            
            if(this.bannerList.length > 0){
              await this.bannerList.forEach(item => {
                if(item.section == 'left_side'){
                  this.leftBanner = item;
                }

                if(item.section == 'right_side'){
                  this.rightBanner = item;
                }
              })
            }
          
          }
        })
    }
  }

  setStep($event){
    this.step = $event;
  }

}
