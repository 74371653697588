import { Injectable } from '@angular/core';
import { gql, Mutation, Query } from 'apollo-angular';
import { VehicleModelYear } from './vehicle-model-year.graphql';

export interface VehicleModel {
  id: bigint;
  name: String;
  vehicle_brand_id?: Number;
  tsrbs_model_id?: Number;
  segment_id?: Number;
  active?: Boolean,
  gos_labour_price: Number,
  brand_labour_price: Number,
  vehicle_brand?: {
    name: String
  },
  vehicle_segment?: {
    name: String
  },
  vehicle_model_years:VehicleModelYear[]
}

interface ResponseArray {
  vehicle_models: VehicleModel[];
}

interface ResponseSingle {
  vehicle_models_by_pk: VehicleModel;
}

@Injectable()
export class VEHICLE_MODEL_ALL extends Query<ResponseArray> {
  override document = gql`
    query VEHICLE_MODEL_ALL ($where: vehicle_models_bool_exp){
      vehicle_models(where: $where) {
        id
        name
      }
    }
  `;
}

@Injectable()
export class VEHICLE_MODEL_ID extends Query<ResponseSingle> {
  override document = gql`
  query VEHICLE_MODEL_ID ($id: bigint!){
    vehicle_models_by_pk(id: $id) {
      id
      name
      segment_id
      tsrbs_model_id
      vehicle_brand_id
      active
      gos_labour_price
      brand_labour_price
      vehicle_model_years{
        id
        vehicle_model_id
        year_id
        active
          year{
            id
            name
          }
        
      }
    }
  }`;
}

@Injectable()
export class VEHICLE_MODEL_SEARCH extends Query<ResponseArray> {
  override document = gql`
  query VEHICLE_MODEL_SEARCH($like: String = "%%", $active: Boolean = true) {
    vehicle_models(where: {active: {_eq: $active}, _and: {name: {_like: $like}}}) {
      id
      name
    }
  }`;
}

@Injectable()
export class VEHICLE_MODEL_CREATE extends Mutation {
  override document = gql`
  mutation VEHICLE_MODEL_CREATE($active: Boolean, $name: String, $segment_id: Int, $tsrbs_model_id: Int, $vehicle_brand_id: Int) {
    insert_vehicle_models(objects: {active: $active, name: $name, segment_id: $segment_id, tsrbs_model_id: $tsrbs_model_id, vehicle_brand_id: $vehicle_brand_id}) {
      affected_rows
    }
  }`;
}

@Injectable()
export class VEHICLE_MODEL_DELETE extends Mutation<any> {
  override document = gql`
    mutation VEHICLE_MODEL_DELETE($id: bigint!) {
      delete_vehicle_models(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class VEHICLE_MODEL_UPDATE extends Mutation {
  override document = gql`
  mutation VEHICLE_MODEL_UPDATE(
    $id: bigint!, 
    $name: String!, 
    $segment_id: Int!, 
    $tsrbs_model_id: Int!, 
    $vehicle_brand_id: Int!, 
    $active: Boolean!,
    $gos_labour_price: numeric,
    $brand_labour_price: numeric) {
    update_vehicle_models(
      where: {id: {_eq: $id}}, 
      _set: {
        name: $name, 
        segment_id: $segment_id, 
        tsrbs_model_id: $tsrbs_model_id, 
        vehicle_brand_id: $vehicle_brand_id, 
        active: $active,
        gos_labour_price: $gos_labour_price,
        brand_labour_price: $brand_labour_price
      }
    ) {
      affected_rows
    }
  }`;
}

@Injectable()
export class VEHICLE_MODEL_PAGINATE extends Query<ResponseArray>{
  override document = gql`
  query VEHICLE_MODEL_PAGINATE($limit: Int, $offset: Int,$orderById: order_by = asc, $where: vehicle_models_bool_exp) {
    vehicle_models(limit: $limit, offset: $offset, order_by: {id: $orderById}, where: $where) {
      id
      name
      active
      segment_id
      tsrbs_model_id
      vehicle_brand_id
      vehicle_brand {
        name
      }
      vehicle_segment {
        name
      }
    }
  }`;
}

@Injectable()
export class VEHICLE_MODEL_COUNT extends Query<any> {
  override document = gql`
  query VEHICLE_MODEL_COUNT( $where: vehicle_models_bool_exp) {
    vehicle_models_aggregate(where:$where) {
      aggregate {
        count
      }
    }
  }`;
}