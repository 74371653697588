import { Injectable } from "@angular/core";
import { Mutation, Query } from "apollo-angular";
import gql from "graphql-tag";

export interface VehicleGearType{
    id: bigint,
    name: String,
    position_no?: Number,
    active: Boolean
}

interface ResponseArray {
    vehicle_gear_types: VehicleGearType[];
  }
  
interface ResponseSingle {
    vehicle_gear_types_by_pk: VehicleGearType
}


interface InsertMutation {
  insert_vehicle_gear_types: {
    affected_rows: number
    returning: [{
      id: Number
    }]
  }
}

@Injectable()
export class VEHICLE_GEAR_TYPE_ALL extends Query<ResponseArray> {
  override document = gql`
  query VEHICLE_GEAR_TYPE_ALL($where: vehicle_gear_types_bool_exp){
    vehicle_gear_types(where: $where) {
      id
      name
      position_no
      active  
    }
  }`;
}

@Injectable()
export class VEHICLE_GEAR_TYPE_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query VEHICLE_GEAR_TYPE_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc,$where: vehicle_gear_types_bool_exp) {
      vehicle_gear_types(limit: $limit, offset: $offset, order_by: {id: $orderBy},where: $where) {
        id
        name
        position_no
        active
      }
    }
  `;
}

@Injectable()
export class VEHICLE_GEAR_TYPE_COUNT extends Query<any> {
  override document = gql`
    query VEHICLE_GEAR_TYPE_COUNT($where: vehicle_fuel_types_bool_exp) {
        vehicle_gear_types_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}

@Injectable()
export class VEHICLE_GEAR_TYPE_DELETE extends Mutation<any> {
  override document = gql`
    mutation VEHICLE_GEAR_TYPE_DELETE($id: bigint!) {
        delete_vehicle_gear_types(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

Injectable()
export class VEHICLE_GEAR_TYPE_CREATE extends Mutation<InsertMutation> {
  override document = gql`
  mutation VEHICLE_GEAR_TYPE_CREATE($active: Boolean, $name: String, $position_no: Int) {
    insert_vehicle_gear_types(objects: {active: $active, name: $name, position_no: $position_no}) {
      returning {
        id
        name
        position_no
      }
      affected_rows
    }
  }`;
}

@Injectable()
export class VEHICLE_GEAR_TYPE_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
    mutation VEHICLE_GEAR_TYPE_UPDATE($id: bigint!, $active: Boolean, $name: String, $position_no: Int) {
        update_vehicle_gear_types(where: {id: {_eq: $id}}, _set: {active: $active, name: $name, position_no: $position_no}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class VEHICLE_GEAR_TYPE_ID extends Query<ResponseSingle> {
  override document = gql`
    query VEHICLE_GEAR_TYPE_ID($id: bigint!){
        vehicle_gear_types_by_pk(id: $id) {
            id
            name
            position_no
            active
          }
    }
  `;
}