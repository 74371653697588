import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { City, GET_CITIES } from 'src/app/graphql/cities.graphql';

@Component({
  selector: 'app-shared-cities',
  templateUrl: './cities.component.html',
  styles: [
  ],
  providers:[GET_CITIES]
})
export class CitiesComponent implements OnInit {
  @Input() selectedCity!: any;
  selectedCountry!: any;
  @Input() selectedCountryId!: any;
  @Input() cityInputId!: any;
  @Output() city_id = new EventEmitter<number | null>()
  @Input() widthClass: number | undefined = 9;
  @Input() showLabel:boolean = true;
 
  @Input() 
  set event(event: Number | null){
    this.selectedCountry = event;
    if(this.selectedCountry == null || this.selectedCountry == ""){
      this.cityList = [];
    } else {

      this.getCities.watch({country_id:this.selectedCountry}).valueChanges.subscribe(response => {
        this.cityList = response.data.cities;
      })
    }
    
  }


  
  public resetForm: boolean = false;
  cityList: City[] | undefined;


  constructor(
    private getCities: GET_CITIES
  ) { 
  }

  ngOnInit(): void {
   }

   ngOnChanges(){
     if(this.cityInputId == undefined){
        this.cityInputId = 'cityList';
     }
   }
  // Shared Select Event
  selected(event: any) {
   
    this.city_id.emit(event);
  }

}
