<div class="d-flex flex-column flex-lg-row-fluid py-10">
    <div class="d-flex flex-center flex-column flex-column-fluid">
        <div class="w-lg-600px p-10 p-lg-15 mx-auto">
            <form class="form w-100" novalidate="novalidate" action="#" [formGroup]="authFormGroup"
                (ngSubmit)="authFormSubmit()">
                <div class="mb-10 text-center">
                    <h1 class="text-dark mb-3">Yeni Hesap Oluştur</h1>
                    <div class="text-gray-400 fw-bold fs-4" *ngIf="router.url !== '/teklif'">Already have an account?
                        <a [routerLink]="['/auth']" routerLinkActive="router-link-active"
                            class="link-primary fw-bolder">Sign in here</a>
                    </div>
                </div>
                <div class="row fv-row mb-7">
                    <div class="col-xl-6">
                        <label class="form-label fw-bolder text-dark fs-6">Ad</label>
                        <input class="form-control form-control-lg form-control-solid" type="text" name="name"
                            autocomplete="off" placeholder="Ad" formControlName="name" />
                    </div>
                    <div class="col-xl-6">
                        <label class="form-label fw-bolder text-dark fs-6">Soyad</label>
                        <input class="form-control form-control-lg form-control-solid" type="text" name="surname"
                            autocomplete="off" placeholder="Soyad" formControlName="surname" />
                    </div>
                </div>
                <div class="fv-row mb-7">
                    <label class="form-label fw-bolder text-dark fs-6">Email</label>
                    <input class="form-control form-control-lg form-control-solid" type="email" placeholder=""
                        name="email" autocomplete="off" placeholder="Email" formControlName="email" />
                </div>
                <div class="mb-10 fv-row">
                    <div class="mb-1">
                        <label class="form-label fw-bolder text-dark fs-6">Şifre</label>
                        <div class="position-relative mb-3">
                            <input class="form-control form-control-lg form-control-solid" type="password"
                                name="password" autocomplete="off" placeholder="Password" formControlName="password" />
                            <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2">
                                <i class="bi bi-eye-slash fs-2"></i>
                                <i class="bi bi-eye fs-2 d-none"></i>
                            </span>
                        </div>
                        <div class="d-flex align-items-center mb-3">
                            <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                            <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                            <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                            <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                        </div>
                    </div>
                    <div class="text-muted">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>
                </div>
                
                
                <div class="fv-row mb-5">
                    <label class="form-label fw-bolder text-dark fs-6">Şifre Tekrarı</label>
                    <input class="form-control form-control-lg form-control-solid" type="password" name="repassword"
                        autocomplete="off" placeholder="Şifre Tekrarı" formControlName="repassword" />
                </div>
              
                <div class="fv-row mb-3 fv-plugins-icon-container">
                    <label class="form-check form-check-custom form-check-solid form-check-inline">
                        <input class="form-check-input" type="checkbox" name="toc" value="1" formControlName="check_gizlilik">
                        <span class="form-check-label fw-bold text-gray-700 fs-6">
                        <a target="_blank" [routerLink]="['/gizlilik-sozlesmesi']" class="ms-1 link-primary">Gizlik Politikası</a></span>
                    </label>
                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                <div class="fv-row mb-3 fv-plugins-icon-container">
                    <label class="form-check form-check-custom form-check-solid form-check-inline">
                        <input class="form-check-input" type="checkbox" name="toc" value="1" formControlName="check_riza">
                        <span class="form-check-label fw-bold text-gray-700 fs-6">
                        <a target="_blank" [routerLink]="['/acik-riza-metni']" routerLinkActive="router-link-active"  routerLinkActive="router-link-active"  class="ms-1 link-primary">Açık Rıza Metni</a></span>
                    </label>
                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                <div class="fv-row mb-3 fv-plugins-icon-container">
                    <label class="form-check form-check-custom form-check-solid form-check-inline">
                        <input class="form-check-input" type="checkbox" name="toc" value="1"  formControlName="check_aydinlatma">
                        <span class="form-check-label fw-bold text-gray-700 fs-6">
                        <a target="_blank" [routerLink]="['/aydinlatma-metni']" class="ms-1 link-primary">Aydınlatma Metni</a></span>
                    </label>
                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                <div class="fv-row mb-3 fv-plugins-icon-container">
                    <label class="form-check form-check-custom form-check-solid form-check-inline">
                        <input class="form-check-input" type="checkbox" name="toc" value="1"  formControlName="check_sozlesme">
                        <span class="form-check-label fw-bold text-gray-700 fs-6">
                        <a target="_blank" [routerLink]="['/kullanici-sozlesmesi']" class="ms-1 link-primary">Kullanıcı Sözleşmesi</a></span>
                    </label>
                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                <div class="text-center">
                    <button type="submit" class="btn btn-lg btn-primary" [disabled]="!authFormGroup.valid">
                        <span class="indicator-label">Kaydet</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>