import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { Order, ORDER_ID, ORDER_UPDATE } from 'src/app/graphql/order.graphql';
import { OrderCards, ORDER_CARDS_ALL, ORDER_CARDS_UPDATE, ORDER_CARD_PAYMENT_SUBS } from 'src/app/graphql/order_cards.graphql';
import { ORDER_INVOICE_CREATE } from 'src/app/graphql/order_invoices.graphql';
import { ORDER_INVOICE_INFO_CREATE } from 'src/app/graphql/order_invoice_info.graphql';
import { ORDER_INVOICE_PART_CREATE } from 'src/app/graphql/order_invoice_parts.graphql';
import { PaymentTypes, PAYMENT_TYPES_ALL, PAYMENT_TYPES_ID } from 'src/app/graphql/payment_types.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { MailService } from 'src/app/services/mail.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SmsService } from 'src/app/services/sms.service';

@Component({
  selector: 'app-shared-payment',
  templateUrl: './payment.component.html',
  styles: [
  ],
  providers:[
    PAYMENT_TYPES_ALL,
    ORDER_ID,
    ORDER_CARDS_UPDATE,
    ORDER_CARD_PAYMENT_SUBS,
    ORDER_CARDS_ALL,
    ORDER_UPDATE,
    ORDER_INVOICE_INFO_CREATE,
    ORDER_INVOICE_PART_CREATE,
    ORDER_INVOICE_CREATE,
    PAYMENT_TYPES_ID]
})
export class PaymentComponent implements OnInit {

  paymentFormGroup = new FormGroup({
    Expire: new FormControl('', [Validators.required]),
    Pan: new FormControl('', [Validators.required,Validators.maxLength(11),Validators.minLength(11)]),
    Cvv2: new FormControl('', [Validators.required,Validators.maxLength(3),Validators.minLength(3)]),
    CardType: new FormControl('', [Validators.required]),
    ShopCode: new FormControl('', [Validators.required]),
    PurchAmount: new FormControl('', [Validators.required]),
    Currency: new FormControl('', [Validators.required]),
    OrderId: new FormControl('', [Validators.required]),
    OkUrl: new FormControl('', [Validators.required]),
    FailUrl: new FormControl('', [Validators.required]),
    Rnd: new FormControl('', [Validators.required]),
    Hash: new FormControl('', [Validators.required]),
    TxnType: new FormControl('', [Validators.required]),
    InstallmentCount: new FormControl(''),
    SecureType: new FormControl('', [Validators.required]),
    Lang: new FormControl('', [Validators.required])

  });

  @Input() orderId: Number | undefined;
  @Input() paymentTypeId: Number | undefined;
  @Input() isPayed: Boolean | undefined;
  @Output() selectedPaymentType = new EventEmitter<Number>();
  @Output() changedPaymentStatus = new EventEmitter<any>();
  @Input() isPaymentButtonActive: boolean = false;
  public orderCardId:bigint | undefined;
  public paymentTypes: PaymentTypes[] | undefined;
  public orderDetail: Order | undefined;
  public hash : String | undefined;
  public momentOrderId:any;
  public InstallmentCount = "";         //taksit sayisi
  public totalAmount:number=0;
  public rnd: any;
  public paymentFailMessage :String= "";
  public subOrderCards: OrderCards | undefined;
  public showPosMessage: boolean= false;
  public selectedPayment: Number | undefined;
  private orderCards: OrderCards | undefined;
  public disabledBtn: boolean = false;
  public isCompany: boolean = false;
  public gsmNumber = "";

  @ViewChild('paymentForm') 
  paymentFormElement!: ElementRef; 
  public domainName: String = "";



  constructor(
    private alertifyService : AlertifyService,
    private getPaymentTypes: PAYMENT_TYPES_ALL,
    private getOrderDetail: ORDER_ID,
    private updateOrderCards: ORDER_CARDS_UPDATE,
    private PaymentService: PaymentService,
    private orderCardPaymentSubs: ORDER_CARD_PAYMENT_SUBS,
    private getOrderCards: ORDER_CARDS_ALL,
    private updateOrder: ORDER_UPDATE,
    private createOrderInvoiceInfo: ORDER_INVOICE_INFO_CREATE,
    private createOrderInvoicePart: ORDER_INVOICE_PART_CREATE,
    private createOrderInvoice: ORDER_INVOICE_CREATE,
    private smsService: SmsService,
    public router: Router,
    private mailService: MailService,
  ) { }

  ngOnInit(): void {

   this.domainName = this.router['location']._platformLocation.location.origin;

   

  }

  ngOnChanges(){
    this.getOrder();
  }

  setNumber($event){
    this.gsmNumber = $event.target.value;
    
  }
  getOrder(){
    this.getOrderDetail.watch({id:this.orderId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
      if(response.errors){
        this.alertifyService.error(response.errors[0].message);
      }

      if(response.data){
       
        this.orderDetail = response.data.orders_by_pk;
        this.selectedPayment = this.orderDetail.payment_type;
        this.totalAmount = this.orderDetail.order_value;
        let where={active:{_eq:true},id:{_neq:1}};
        
        if(this.selectedPayment == 2 ||  this.selectedPayment == 3 || this.selectedPayment == 4 ){
          Object.assign(where,{main_id: {_eq:2}})
          
        }

        if(this.selectedPayment == 2 && !this.orderDetail.is_payed){
          this.isPaymentButtonActive = true;
        }

        this.getPaymentTypes.watch({where:where},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response=>{
          if(response.errors){
            this.alertifyService.error(response.errors[0].message);
          }
    
          if(response.data){
            this.paymentTypes = response.data.payment_types;
          }
        })

        this.setOrderCardId();
      }
    })
  }

  changePaymentMethod(event:any){
    if(event){

      this.selectedPayment = event.target.value;
      this.selectedPaymentType.emit(event.target.value);
    
      
    }
    
  }

  changePaymentStatus(type:String){
    
    if(this.orderDetail?.order_invoice_info == null){
      this.alertifyService.error("Fatura kesebilmek için kullanıcı adres bilgilerini girmelisiniz!");
    }else{

      switch(type){
        case "cash":
          
        
          //this.createInvoiceInfo();
        
          
          this.changedPaymentStatus.emit({payment: true});
        break;

        case "cc":

        this.mailService.send(this.orderDetail.user.email,'Garantili Oto Servis Randevu Ödemesi.','Ödemenizi https://gos.garantiliotoservis.com/siparis-odeme/'+this.orderDetail.track_id + ' adresinden yapabilirsiniz.').subscribe(mailResponse => {
  
          //if (this.order.customer.user_phones.length) {
          //}

          if(mailResponse.success){
            this.alertifyService.success("Mail Gönderildi");
          }

        });
        
       

        this.gsmNumber = this.gsmNumber.startsWith('0') ? this.gsmNumber : '0' + this.gsmNumber;


          this.sendSmsService('Garantili ödeme bilgisi','Ödemenizi https://gos.garantiliotoservis.com/siparis-odeme/'+this.orderDetail.track_id + ' adresinden yapabilirsiniz. ',[this.gsmNumber])
          .subscribe(response =>{
            if(!response.status){
              this.alertifyService.error(response.message);
            }

            if(response.status){
            //  this.createInvoiceInfo();
              this.alertifyService.success("sms gönderildi")
              this.changedPaymentStatus.emit({send_sms:true, payment:false});
              
            }
          });
        break;
      }

      
    }
   
   
  }

  paymentProcess(e:any){

    this.disabledBtn = true;

    e.preventDefault();
   
    if(this.orderCardId != undefined){

      this.momentOrderId = moment().format('YYMMDDHmmss').toString()+"-"+this.orderCardId;

      this.updateOrderCards.mutate({id:this.orderCardId,changes:{hash_order_id:this.momentOrderId}}).subscribe((result:any)=>{
        if(result.errors){
          this.alertifyService.error(result.errors[0].message);
        }
        if(result.data){

          if(this.orderCardId != undefined){
            if(this.orderDetail){
 
              //api tarafında hash oluşturup alıyoruz
              this.PaymentService.getHash(this.InstallmentCount,this.orderCardId,this.orderDetail.order_value,this.momentOrderId).subscribe((result:any)=>{
  
              if(result.errors){
                this.alertifyService.error(result.errors[0].message);
              }

              if(result.hash){
                this.hash = result.hash;
                this.rnd = result.rnd;

                //card durumunu istek gönderildi yapıyoruz
                this.updateOrderCards.mutate({id:this.orderCardId,changes:{pos_process_status_id:2}}).subscribe((result:any)=>{

                  if(result.errors){

                    this.alertifyService.error(result.errors[0].message);

                  }

                  if(result.data){
              
                    this.paymentFormElement?.nativeElement.submit();

                    //bankadan gelen cevabın tabloda pos_process_status_id sekmesini başarılı veya başarısız yapmasını bekliyoruz 
                    this.orderCardPaymentSubs.subscribe({where:{id:{_eq:this.orderCardId}}}).subscribe((result:any)=> {

                      this.subOrderCards = result.data?.order_cards[0];
                      
                      if(this.subOrderCards?.pos_process_status_id == 4){
                        
                        this.updateOrder.mutate({id:this.orderId,changes:{is_payed:true}}).subscribe((upres:any)=>{
                          if(upres.errors){
                            this.alertifyService.error(upres.errors[0].message);
                          }
                          
                          if(upres.data){

                            if(this.orderDetail?.order_invoice_info == null){
                              this.createInvoiceInfo();
                            }
                            
                            
                          }
                        })
                    }
                
                    this.disabledBtn = false;

                    if(this.subOrderCards?.pos_process_status_id == 3){

                      this.momentOrderId = moment().format('YYMMDDHmmss').toString()+"-"+this.orderCardId;
                
                      this.showPosMessage = true;
                      
                      this.paymentFailMessage = this.subOrderCards.pos_message;

                      var ths = this;

                      setTimeout(function(){
                        ths.removePaymentMessage();
                        ths.paymentFailMessage = "";
                        ths.showPosMessage = false;
                
                      },5000)
                      
                    
                
                    }
                    });
                  
                    

                  }
                });

              }
            })
            }
           
          }
  
        }
      });

      
    }

    
  }

  

  removePaymentMessage(){
    this.updateOrderCards.mutate({id:this.orderCardId,changes:{pos_message:null}}).subscribe(result=> {

    });
  }

  setOrderCardId(){
   
      this.getOrderCards
      .watch({where:{customer_id:{_eq:this.orderDetail?.customer_id},is_complete:{_eq:false}}},{fetchPolicy:"no-cache"})
      .valueChanges
      .subscribe(response => {
        if(response.errors){
          this.alertifyService.error(response.errors[0].message);
        }
        if(response.data){
          this.orderCards = response.data.order_cards[0];
          this.orderCardId = this.orderCards?.id;
  
        
        }
      })
    
    

  }

  createInvoiceInfo(){


    if(this.orderDetail?.order_invoice_info == null){
      let infoObj:any = {
        order_id:this.orderId,
        user_id: this.orderDetail?.customer_id,
        customer_name: this.orderDetail?.order_invoice_info.customer_name,
        customer_surname: this.orderDetail?.order_invoice_info.customer_surname,
        customer_email: this.orderDetail?.user.email,
        identity_no: this.orderDetail?.order_invoice_info.identity_no,
        tax_number: this.orderDetail?.user.tax_number,
        tax_administration: this.orderDetail?.user.tax_administration,
        gsm_number: this.orderDetail?.customer_gsm.code,
        tel_number:this.orderDetail?.customer_tel?.code,
      //     is_same_with_customer: this.invoiceInfo.is_same_with_customer,
        invoice_type: 0,//company= 1, user = 0
        address: this.orderDetail?.user_address?.address,
        city_id: this.orderDetail?.user_address.city_id,
        town_id: this.orderDetail?.user_address.town_id,
        district_id: this.orderDetail?.user_address.district_id,
        country_id: this.orderDetail?.user_address.country_id,
        company_name: null,
      }


      if(this.isCompany){
        
        infoObj.tax_number = this.orderDetail?.user.company.tax_number;
        infoObj.tax_administration =  this.orderDetail?.user.company.tax_administration;
        infoObj.company_name = this.orderDetail?.user.company.company_name;
        infoObj.company_id = this.orderDetail?.user.company_id;
        infoObj.tel_number =  this.orderDetail?.user.company.tel.code;
      }else{
        infoObj.gsm_number = this.orderDetail?.customer_gsm.code;
        infoObj.identity_no = this.orderDetail?.user.identity_no;
      }


      this.createOrderInvoiceInfo.mutate({object:infoObj}).subscribe((infoResult:any) => {
        
        if(infoResult.errors){
          
          this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
        }
        if(infoResult.data){
          this.alertifyService.success("siparişiniz oluşturuldu");
          this.createInvoice();
        }

      });
    }else{
      
      this.createInvoice();
    }

  
   
  }

  createInvoice(){

    if(this.orderDetail?.order_invoice_info){
      let infoObj:any = {
        order_id:this.orderId,
        user_id: this.orderDetail.customer_id,
        customer_name: this.orderDetail.order_invoice_info.customer_name,
        customer_surname: this.orderDetail.order_invoice_info.customer_surname,
        customer_email: this.orderDetail.user.email,
        identity_no: this.orderDetail.order_invoice_info.identity_no,
        tax_number: this.orderDetail.order_invoice_info.tax_number,
        tax_administration: this.orderDetail.order_invoice_info.tax_administration,
        gsm_number: this.orderDetail.order_invoice_info.gsm_number,
        tel_number:this.orderDetail?.order_invoice_info?.tel_number,
      //     is_same_with_customer: this.invoiceInfo.is_same_with_customer,
        invoice_type: 0,//company= 1, user = 0
        address: this.orderDetail.order_invoice_info?.address,
        city_id: this.orderDetail.order_invoice_info.city_id,
        town_id: this.orderDetail.order_invoice_info.town_id,
        district_id: this.orderDetail.order_invoice_info.district_id,
        country_id: this.orderDetail.order_invoice_info.country_id,
        company_name: this.orderDetail.order_invoice_info.company_name
      }

      if(this.orderDetail != undefined){
        this.createOrderInvoice.mutate({
          object:{
          "order_id":this.orderDetail.id,
          "date":moment().format("YYYY-MM-DDTHH:mm:ssZ"),
          "value_in_vat":this.orderDetail.order_value,
          "value_no_vat":this.orderDetail.order_no_vat_value,
          "vat": ( this.orderDetail.order_value - this.orderDetail.order_no_vat_value ),
          "discount":0,
          "url":"",
          "invoice_type_id":this.orderDetail.type_id,
          "valor_date":moment().format("YYYY-MM-DDTHH:mm:ssZ"),
          "invoice_status_id":6, 
          "paid_value": this.orderDetail.order_value,
          "remaining_value":0, // TODO buraya bakılacak
          "note":"",
          "invoice_reciever_id":this.orderDetail.customer_id,
         // "invoice_reciever_type_id": this.orderDetail.customer_info.role_id
        
        }
        }).subscribe((result:any) => {
          if(result.errors){
            this.alertifyService.error(result.errors[0].message);
            
          }

          if(result.data){

            this.updateOrder.mutate({"id":this.orderId,"changes":{invoice_id:result.data.insert_order_invoices_one.id}}).subscribe((upresult:any) => {
              if(upresult.errors){
                this.alertifyService.error("Güncelleme esnasında bir sorun oluştu!");
              }
              if(upresult.data){
                this.alertifyService.success("siparişiniz güncellendi");
              }
            })

            this.orderDetail?.order_parts.forEach((element:any) => {

              this.createOrderInvoicePart.mutate({

                object:{
                "invoice_id":result.data.insert_order_invoices_one.id,
                "part_id": element.id,
                "part_name": element.parts.name,
                "part_code": (element.part_type_id == 1) ? element.part_original_list.part_code : element.part_equivalent_list[0].part_code,
                "labour_type_id": element.labour_type_id,
                "labour_value": element.labour_value,
                "labour_discount": element.labour_discount,
                "part_in_vat_price": element.part_value,
                "part_no_vat_price": (element.part_no_vat_price && element.part_no_vat_price != null) ? element.part_no_vat_price : 0,
                "invoice_type":3, 
                "part_unit_type_id": element.part_unit_type_id,
                "part_unit": element.part_unit
               
              }

              }).subscribe((partResult:any) => {
                if(partResult.errors){
                  this.alertifyService.error(partResult.errors[0].message);
                }
               if(partResult.data){
                
               }
              });
            });

            this.getOrder();
            this.alertifyService.success("Fatura oluşturuldu");
           ;

          }
        })
      }

    }else{
      this.alertifyService.error("Lütfen fatura bilgilerini giriniz");
      return false;
    }

    
  }

  sendSmsService(title: String, content: String, phones: any[]) {
    return this.smsService.send(title, content, phones);
  }
  

}
