import { Inject, Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';
import { OrderOfferAnswers } from './order_offer_answers.graphql';

export interface OrderOffers {
  id: bigint;
  order_bid_part_offer_id: Number;
  auction_id: bigint;
  processed: boolean;
  created_at: Date;
  answered_at: Date;
  selected_answer_id: bigint;
  selected_order_offers_answer: OrderOfferAnswers
}

interface ResponseArray {
    order_offers: OrderOffers[];
}

interface ResponseSingle {
  order_offers_by_pk: OrderOffers
}

@Injectable()
export class ORDER_OFFERS_ALL extends Query<ResponseArray> {
  override document = gql`
    query ORDER_OFFERS_ALL($order_bid_part_offer_id: bigint!){
        order_offers(where: {order_bid_part_offer_id:{_eq:$order_bid_part_offer_id}}) {
        id
        order_bid_part_offer_id
        auction_id
        processed
        created_at
        answered_at
        selected_answer_id
        selected_order_offers_answer{
          id
          auction_answer_id
          auction_number
          file_number
          outsource_auction_no
          current_id
          current_name
          part_code
          equivalent_part_code
          part_name
          available
          part_price
          discount
          offer_part_count
          offer_part_price
          part_type_id
          part_type
          part_brand_id
          part_brand
          answer_description
          delivery_day_count
          order_offers_id
          created_at
          updated_at
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_OFFERS_ID extends Query<ResponseSingle> {
  override document = gql`
    query ORDER_OFFERS_ID($id: bigint!){
      order_offers_by_pk(id: $id) {
            id
            order_bid_part_offer_id
            auction_id
            processed
            created_at
            answered_at
      }
    }
  `;
}

@Injectable()
export class ORDER_OFFERS_CREATE extends Mutation<ResponseSingle> {
  // connected_part_id - int | nullable
  override document = gql`
    mutation ORDER_OFFERS_CREATE(
      $order_bid_part_offer_id: bigint!, 
      $auction_id: bigint, 
      $processed: boolean) {
      insert_order_offers(objects: {
        order_bid_part_offer_id: $order_bid_part_offer_id, 
        auction_id: $auction_id,
        processed: $processed
      }) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_OFFERS_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
    mutation ORDER_OFFERS_UPDATE(
        $id: bigint!, 
        $order_bid_part_offer_id: bigint!, 
        $auction_id: bigint,
        $processed: boolean) {
      update_order_offers(where: {id: {_eq: $id}}, _set: {
        order_bid_part_offer_id: $order_bid_part_offer_id, 
        auction_id: $auction_id, 
        processed: $processed
        }) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_OFFERS_UPDATE_ANSWER_ID extends Mutation<ResponseSingle> {
  override document = gql`
    mutation ORDER_OFFERS_UPDATE_ANSWER_ID(
        $id: bigint!, 
        $selected_answer_id: bigint) {
      update_order_offers(where: {id: {_eq: $id}}, _set: {
        selected_answer_id: $selected_answer_id
        }) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_OFFERS_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query ORDER_OFFERS_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: order_offers_bool_exp) {
        order_offers(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
            id
            order_bid_part_offer_id
            auction_id
            processed
            created_at
            answered_at
      }
    }
  `;
}

@Injectable()
export class ORDER_OFFERS_COUNT extends Query<any> {
  override document = gql`
    query ORDER_OFFERS_COUNT($where: order_offers_bool_exp!) {
        order_offers_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}