import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Currency, CURRENCY_ALL } from 'src/app/graphql/currency.graphql';
import { PartOriginalList } from 'src/app/graphql/part-original.graphql';
import { Part } from 'src/app/graphql/part.graphql';
import { PartUnitType, PART_UNIT_TYPE_ALL } from 'src/app/graphql/part_unit_type.graphql';
import { PriceUnits, PRICE_UNITS_ID } from 'src/app/graphql/price_units.graphql';
import { Vehicle } from 'src/app/graphql/vehicle.graphql';
import { MECHANIC_PARTS_BY_MODEL_TYPE_ID } from 'src/app/graphql/view-vehicle-mechanic-parts.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-shared-mechanic-repair',
  templateUrl: './mechanic-repair.component.html',
  styles: [
  ],
  providers:[MECHANIC_PARTS_BY_MODEL_TYPE_ID,PART_UNIT_TYPE_ALL,CURRENCY_ALL,PRICE_UNITS_ID]
})
export class MechanicRepairComponent implements OnInit {

  @Input() modelTypeId: BigInt | undefined;
  @Input() vehicleInfo: Vehicle | undefined;
  @Output() returnParts:any = new EventEmitter();
  @Input() gosLabourPrice: any;
  @Input() brandLabourPrice: any;

  

  public mechanicPartList:any = []; 
  public partEquivalentList:any = [];
  public partOriginalList: PartOriginalList[] = [];
  public selectedParts: any  = [];
  public selectedPartUnit: number = 0;
  public grandTotal : number = 0;
  public subTotal: number = 0;
  public difference : number = 0;
  public gosMaintenancePrice: number = 0;
  public brandMaintenancePrice: number = 0;
  public currencyList: Currency[] | undefined;
  public activeCurrencyId:number = 1;
  public activeCurrency:PriceUnits | undefined;
  public selectedPartUnitType: PartUnitType | undefined;
  public partOriginalInfo: PartOriginalList | undefined;
  public gosPartInfo: Part | undefined;
  public partCategories:any = [];
  public partUnitTypes: PartUnitType[] | undefined;
  public savingTotal:number = 0;
  public totalsByTypes:any = [];

  public gosMaintenancePriceNoVat: number = 0;
  public brandMaintenancePriceNoVat: number = 0;




  constructor(
    private getMechanicParts: MECHANIC_PARTS_BY_MODEL_TYPE_ID,
    private alertifyService: AlertifyService,
    private getPartUnitTypes: PART_UNIT_TYPE_ALL,
    private getCurrency: CURRENCY_ALL,
    private getCurrencyById: PRICE_UNITS_ID
  ) { }

  ngOnInit(): void {


    this.getCurrencyById.watch({id:this.activeCurrencyId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
      this.activeCurrency = response.data.price_units_by_pk;
    })

    this.getPartUnitTypes.watch().valueChanges.subscribe(partUnitResult => {
      if(partUnitResult.errors){
        this.alertifyService.error(partUnitResult.errors[0].message);
      }

      if(partUnitResult.data){
        this.partUnitTypes = partUnitResult.data.part_unit_types;
      }
    })

    this.getCurrency.watch().valueChanges.subscribe((result:any)=>{
      if(result.errors){
        this.alertifyService.error(result.errors[0].message);
      }

      if(result.data){
        this.currencyList = result.data.currency;
      }
    });


  }

  ngOnChanges():void {
    this.setMechanicParts();
  }

  setMechanicParts(){
    this.partEquivalentList = [];

    this.mechanicPartList = [];

   

    this.getMechanicParts.watch({model_type_id:this.modelTypeId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {

      if(response.error){
        this.alertifyService.error("Bir hata oluştu. Lütfen tekrar deneyiniz");
        return false;
      }

      if(response.data){

        this.mechanicPartList = response.data.view_vehicle_mechanic_parts;

        if(this.mechanicPartList.length == 0){
          this.alertifyService.error("Bu araç için servis paketi bulunamadı");
          return false;
        }else{

          this.partCategories = [];

          this.mechanicPartList.forEach((element:any) => {

           
              let isExists = this.partCategories.find((item:any) => item.parts.id == element.parts.id);

              if(isExists == undefined){
                let newItem = element;
                newItem.name = element.parts.name;
                newItem.part_id = element.parts.id;
                newItem.original_price_ratio = element.parts.original_price_ratio;
                this.partCategories.push(newItem);

              //  this.customPackageList.splice(this.customPackageList.indexOf(element),1);
              }

            
          });


        }
      }

    });


  }

  setPartEquivalentList(partId:BigInt){

    this.partEquivalentList = [];
    this.totalsByTypes = {
      yetkili_orjinal : 0,
      orjinal : 0,
      es_deger : 0,
      logosuz: 0
    };

    let element = this.partCategories.find((item:any) => item.parts.id == partId);

    if(element == undefined){

      this.alertifyService.error("Aradğınız veri bulunamadı");
      return false;
    
    }



      let newObj:any;

      
      newObj = {
        package_detail_id: element.id,
        part_id:element.part_original_list.id,
        selectedGosPart:{
          id: element.parts.id,
          name: element.parts.name,
          unit_type: element.parts.part_unit_type,
          unit: element.parts.part_unit ,
          original_price_ratio:element.parts.original_price_ratio,
          original_ratio: element.parts.price_ratio
        },
        without_logo: null,
        offical_original: null,
        equivalent: null,
        gosPart: null,
        original:null,
        partTypeName:"Orijinal",
        part_unit: element.part_unit,
        part_unit_type: element.part_unit_type,
        part_unit_type_name: element.part_unit_types.name,
        iscilik_id: 0
      };
      if(element.parts.original_price_ratio > 0){
        element.part_original_list.part_in_vat_price  = element.part_original_list.part_in_vat_price  + ((element.part_original_list.part_in_vat_price  * element.parts.original_price_ratio) / 100);
        element.part_original_list.part_no_vat_price  = element.part_original_list.part_no_vat_price + ((element.part_original_list.part_no_vat_price * element.parts.original_price_ratio) / 100);
      }

      newObj.offical_original = {
        id: element.part_original_list.id,
        name: element.part_original_list.name,
        price: element.part_original_list.part_in_vat_price.toFixed(2),
        priceNoVat: element.part_original_list.part_no_vat_price.toFixed(2),
        price_unit: this.activeCurrency?.code,
        price_unit_id: this.activeCurrency?.id,
        currencyPriceTotal: 0,
        currencyPriceNoVatTotal: 0,
        currencyPriceUnitId : element.part_original_list.price_unit_id,
        currencyPriceUnit: element.part_original_list.price_unit,
        part_unit: element.part_unit,
        part_unit_type: element.part_unit_type,
        part_unit_type_name: element.part_unit_types.name
      }

      let originalPriceWithCurrency  = element.part_original_list.part_in_vat_price;
      let originalPriceNoVatCurrency = element.part_original_list.part_no_vat_price;


      

      if(element.part_original_list.price_unit_id != 1){

       let originalCurrentCurrency = this.currencyList?.filter(i => i.price_unit_id == element.part_original_list.price_unit_id);
  
       if(originalCurrentCurrency && originalCurrentCurrency.length > 0){

         let originalCurrency = originalCurrentCurrency[0].currency_value;
         
        
        let originalCurrencyPrice = element.part_original_list.part_in_vat_price;
        let originalCurrencyNoVatPrice = element.part_original_list.part_no_vat_price;

        let originalConvertToTlWithVat = ( element.part_original_list.part_in_vat_price * originalCurrency );
        let originalConvertToTlWithNoVat = ( element.part_original_list.part_no_vat_price * originalCurrency );

        /** kur farkından dolayı üzerine %5 ekliyoruz **/
        let vatPriceDiff = ( originalConvertToTlWithVat + ( (originalConvertToTlWithVat * 5) / 100) )
        let noVatPriceDiff = (originalConvertToTlWithNoVat + ( (originalConvertToTlWithNoVat * 5) / 100) )

         originalPriceWithCurrency  = parseFloat(vatPriceDiff.toFixed(2));
         originalPriceNoVatCurrency = parseFloat(vatPriceDiff.toFixed(2));

         // parça fiyatını çevir ve yeniden ata
         element.part_original_list.part_in_vat_price   = originalPriceWithCurrency;
         element.part_original_list.part_no_vat_price   = noVatPriceDiff;
         element.part_original_list.currencyPriceUnitId = element.part_original_list.price_unit_id;
         element.part_original_list.currencyPriceUnit   = element.part_original_list.price_unit;
         element.part_original_list.price_unit_id       = this.activeCurrency?.id;
         element.part_original_list.price_unit          = this.activeCurrency?.code;
         element.part_original_list.priceCurrency       = originalCurrencyPrice;
         element.part_original_list.priceNoVatCurrency  = originalPriceNoVatCurrency;

      }
     }

     newObj.original = {
      id: element.part_original_list.id,
      price: originalPriceWithCurrency,
      priceNoVat: parseFloat(originalPriceNoVatCurrency.toFixed(2)),
      price_unit: this.activeCurrency?.code,
      partUnitType: element.part_unit_types.name,
      partUnit :  element.part_unit,
      partUnitTypeId: element.part_unit_types.id,
      price_unit_id: this.activeCurrency?.id,
      partCode: element.part_original_list.part_code,
      currencyPriceUnitId: element.part_original_list.price_unit_id,
      currencyPriceUnit: element.part_original_list.price_unit,
      priceCurrency: originalPriceWithCurrency,
      priceNoVatCurrency: originalPriceNoVatCurrency,
      name: element.part_original_list.name,
      partTypeName: element.part_original_list.part_types.name,
      totalPriceCurrency: originalPriceWithCurrency * element.part_unit,
      partTypeId: 1,
      
    };
    this.addPiece(newObj.original.id,newObj.selectedGosPart.id,newObj.original.id,"original",true);

      if(element.part_original_list.part_equivalent_lists.length > 0){

        element.part_original_list.part_equivalent_lists.forEach((item:any) => {
          
          let priceWithCurrency  = 0;
          let priceNoVatCurrency = 0;
          //Equivalent İçin Kur Çevir 
          if( item.price_unit_id != BigInt(1) ){
  
              let currentCurrency = this.currencyList?.filter(i => i.price_unit_id == item.price_unit_id);
  
              if(currentCurrency && currentCurrency.length > 0){
  
                let currency = currentCurrency[0].currency_value;
  
                priceWithCurrency  = parseFloat(item.part_in_vat_price.toFixed(2));
                priceNoVatCurrency = parseFloat(item.part_no_vat_price.toFixed(2));
  
                // parça fiyatını çevir ve yeniden ata
                item.part_in_vat_price = ( priceWithCurrency * currency );
                item.part_no_vat_price = ( priceNoVatCurrency * currency );
                item.currencyPriceUnitId = item.price_unit_id;
                item.currencyPriceUnit = item.price_unit;
                item.price_unit_id = this.activeCurrency?.id;
                item.price_unit = this.activeCurrency?.code;
                item.priceCurrency = priceWithCurrency;
                item.priceNoVatCurrency = priceNoVatCurrency;
              }
  
          }else{
  
            priceWithCurrency  = parseFloat(item.part_in_vat_price.toFixed(2));
            priceNoVatCurrency = parseFloat(item.part_no_vat_price.toFixed(2));
  
            // parça fiyatını çevir ve yeniden ata
            item.part_in_vat_price = priceWithCurrency;
            item.part_no_vat_price = priceNoVatCurrency;
            item.currencyPriceUnitId = item.price_unit_id;
            item.currencyPriceUnit = item.price_unit;
            item.price_unit_id = this.activeCurrency?.id;
            item.price_unit = this.activeCurrency?.code;
            item.priceCurrency = priceWithCurrency;
            item.priceNoVatCurrency = priceNoVatCurrency;
  
          }
  
  
          switch(item.part_type_id){
  
            case 2:
              //logosuz orjinal
              newObj.without_logo = {
                id: item.id,
                price: item.part_in_vat_price,
                priceNoVat: item.part_no_vat_price,
                price_unit: this.activeCurrency?.code,
                partUnitType: element.part_unit_types.name,
                partUnit : element.part_unit,
                partUnitTypeId: element.part_unit_types.id,
                price_unit_id:  this.activeCurrency?.id,
                partCode: item.part_code,
                currencyPriceUnitId: item.currencyPriceUnitId,
                currencyPriceUnit: item.currencyPriceUnit,
                priceCurrency: priceWithCurrency,
                priceNoVatCurrency: priceNoVatCurrency
              };
            break;
  
            case 3:
                //?Manipüle ettik
                let halfPartInVatPrice = ( ( element.part_original_list.part_in_vat_price * 60 ) / 100 );
                let halfPartNoVatPrice = ( ( element.part_original_list.part_no_vat_price * 60 ) / 100 );

                let newItemPartInVatPrice = (halfPartInVatPrice + (halfPartInVatPrice / 2));
                let newItemPartNoVatPrice = (halfPartNoVatPrice + (halfPartNoVatPrice / 2));
                priceWithCurrency = newItemPartInVatPrice;

                newObj.equivalent = {
                  id:item.id,
                  price :newItemPartInVatPrice,
                  priceNoVat: newItemPartNoVatPrice,
                  price_unit: item.price_unit,
                  partUnitType: element.part_unit_types.name,
                  partUnit :  element.part_unit,
                  partUnitTypeId: element.part_unit_types.id,
                  price_unit_id: this.activeCurrency?.id,
                  partCode: item.part_code,
                  currencyPriceUnitId: item.currencyPriceUnitId,
                  currencyPriceUnit: item.currencyPriceUnit,
                  priceCurrency: newItemPartInVatPrice,
                  priceNoVatCurrency: newItemPartNoVatPrice,
                  totalPriceCurrency: (priceWithCurrency * element.part_unit),
                  name: item.name,
                  partTypeName: item.part_types.name,
                  partTypeId: item.part_types.id
                };
              
               
               
              //?Manipüle ettik
              /*
                newObj.equivalent = {
                id:item.id,
                price :item.part_in_vat_price,
                priceNoVat: item.part_no_vat_price,
                price_unit: item.price_unit,
                partUnitType: element.part_unit_types.name,
                partUnit :  element.part_unit,
                partUnitTypeId: element.part_unit_types.id,
                price_unit_id: this.activeCurrency?.id,
                partCode: item.part_code,
                currencyPriceUnitId: item.currencyPriceUnitId,
                currencyPriceUnit: item.currencyPriceUnit,
                priceCurrency: priceWithCurrency,
                priceNoVatCurrency: priceNoVatCurrency
                
              };
              */
            break;
  
       /*     case 5:
              //original
            
              newObj.original = {
                id: item.id,
                price: item.part_in_vat_price,
                priceNoVat: item.part_no_vat_price,
                price_unit: item.price_unit,
                partUnitType: element.part_unit_types.name,
                partUnit :  element.part_unit,
                partUnitTypeId: element.part_unit_types.id,
                price_unit_id: this.activeCurrency?.id,
                partCode: item.part_code,
                currencyPriceUnitId: item.currencyPriceUnitId,
                currencyPriceUnit: item.currencyPriceUnit,
                priceCurrency: priceWithCurrency,
                priceNoVatCurrency: priceNoVatCurrency
                
              };
            break;*/
  
          }
          

        });

        if(element.part_unit_type == 3){
          //this.addPiece(newObj.offical_original.id,newObj.selectedGosPart.id,newObj.iscilik_id,"original",true);
         



         }else{
          //this.partEquivalentList.push(newObj);
        
          this.totalsByTypes.orjinal = parseFloat( (this.totalsByTypes.orjinal + newObj.original.totalPriceCurrency ).toFixed(2));
          newObj.original.totalPriceCurrency = parseFloat(newObj.original.totalPriceCurrency.toFixed(2));
        
          if(newObj.equivalent != null){
            this.totalsByTypes.es_deger =  (parseFloat(this.totalsByTypes.es_deger.toFixed(2)) + parseFloat(newObj.equivalent?.totalPriceCurrency.toFixed(2)) );
            newObj.equivalent.totalPriceCurrency = parseFloat( newObj.equivalent?.totalPriceCurrency.toFixed(2));
          }
          
         
         // this.totalsByTypes.logosuz = 0; //parseFloat( (this.totalsByTypes.logosuz + newObj.without_logo.totalPriceCurrency ).toFixed(2));
         // newObj.without_logo.totalPriceCurrency  = 0; //parseFloat( newObj.without_logo.totalPriceCurrency.toFixed(2) );
          

         }

        this.partEquivalentList.push(newObj);

      }
      
    
    

  }

  selectAll(event:any){

  }

  selectedTwo(type:string,event:any){


    switch (type) {
      case "mechanicPart":
        this.setPartEquivalentList(event);
      break;
    }

  }

  addPiece(partOriginalId:Number,selectedPartId:Number,equivalentId:Number,partType:string,showdebug=false){
 
    if(showdebug){
     //;
    }

    if(selectedPartId == 0 || equivalentId == 0){
      this.alertifyService.error("Bir hata oluştu22");
      return false;
    }
    if(this.partEquivalentList && this.partEquivalentList.length > 0){


      let selectedPartInfo = this.partEquivalentList[0][partType];
      if(selectedPartInfo && selectedPartInfo.length > 0){

        let selectedOriginalPart = selectedPartInfo["original"];

       

              this.selectedParts = this.selectedParts.filter((obj:any)=> obj.selectedGosPart_id != selectedPartId);

             


              //? toFixed ile ücretleri 2 haneye indiriyoruz
              //?burayı manipüle ettik  
            /*  selectedEquivalent.part_in_vat_price = parseFloat(parseFloat(selectedEquivalent.part_in_vat_price).toFixed(2));
              selectedEquivalent.part_no_vat_price = parseFloat(parseFloat(selectedEquivalent.part_no_vat_price).toFixed(2));*/

/*
           
              
              this.selectedParts.push({
                package_detail_id: null,
                selectedGosPart_id :  this.partEquivalentList[0].selectedGosPart.id,
                selectedGosPart_name: this.partEquivalentList[0].selectedGosPart.name,
                labourTypeId: null,
                labourValue: null,
                labourDiscount: null,
                brandLabourPrice: 0, //* manuel parçada işçilik ürün olarak eklenmekte o yüzden gos ve brand labour price 0 gönderilmektedir.
                gosLabourPrice: 0,
                orderId: 0,
                selectedEquivalent_currencyPrice : priceCurrency,
                selectedEquivalent_currencyNoVatPrice : priceNoVatWithUnitCurrency,
                selectedEquivalent_currencyTotalPrice : priceWithUnitCurrency,
                selectedEquivalent_currencyPriceUnitId : selectedEquivalent.price_unit_id,
                selectedEquivalent_currencyNoVatTotalPrice: priceNoVatWithUnitCurrency,
                selectedEquivalent_priceTotal: priceWithUnit.toFixed(2),
                selectedEquivalent_priceVat: parseFloat(( priceWithUnit - priceNoVatWithUnit ).toFixed(2)),
                selectedEquivalent_priceNoVatTotal: priceNoVatWithUnit.toFixed(2),
                selectedEquivalent_partDiscount: null, //TODO: buraya daha sonra discount eklenbilir.
                selectedEquivalent_partTypeId: selectedPartInfo.partTypeId,
                selectedEquivalent_partValue: selectedEquivalent.part_in_vat_price,
                selectedEquivalent_partNoVatValue: selectedEquivalent.part_no_vat_price,
                selectedEquivalent_id: selectedEquivalent.id,
                selectedEquivalent_name: selectedEquivalent.name,
                selectedEquivalent_partTypeName: selectedEquivalent.partTypeNamee,
                selectedEquivalent_priceUnitId: this.activeCurrency?.id,
                selectedEquivalent_priceUnitName: this.activeCurrency?.code,
                selectedEquivalent_partUnitType: this.partEquivalentList[0][partType].partUnitType,
                selectedEquivalent_partUnitTypeId: this.partEquivalentList[0][partType].partUnitTypeId,
                selectedEquivalent_partUnit : this.partEquivalentList[0][partType].partUnit,
                selectedEquivalent_partUnitName : selectedEquivalent.partUnitType,
                selectedEquivalent_partCode : selectedEquivalent.partCode,
                selectedEquivalent_serviceValue: 0,
                selectedEquivalent_serviceValueUnit: 0,
              });

            */
          

           
        

      }

    }
    this.setTotalAmount();
  }

  addPieceold(partOriginalId:Number,selectedPartId:Number,equivalentId:Number,partType:string,inputId:string){
   
    
    if(selectedPartId == 0 || equivalentId == 0){
      this.alertifyService.error("Bir hata oluştu");
      return false;
    }

    if(this.partEquivalentList && this.partEquivalentList.length > 0){


    
      let selectedPartInfo = this.partEquivalentList.filter((obj:any) => (obj.selectedGosPart.id == selectedPartId));
     
      if(selectedPartInfo && selectedPartInfo.length > 0){

        //let selectedEquivalentDetail = this.partOriginalInfo?.part_equivalent_lists.filter((obj:any)=>obj.id == equivalentId)[0];
        let existsOriginalPart = this.partCategories.filter((obj:any)=>obj.part_original_id == partOriginalId );


        if(existsOriginalPart && existsOriginalPart.length > 0){

           let selectedOriginalPart = existsOriginalPart[0].part_original_list;
           this.selectedPartUnit = existsOriginalPart[0].part_unit;

           if(selectedOriginalPart.part_equivalent_lists.length == 0){
             this.alertifyService.error("Seçtiğiniz parçaya ait eşdeğer parça bulunamadı");
             return false;
           }
           let selectedEquivalent = selectedOriginalPart.part_equivalent_lists.filter((equivalentObj:any) => (equivalentObj.id == equivalentId));

           if(selectedEquivalent && selectedEquivalent.length > 0){

              this.selectedParts = this.selectedParts.filter((obj:any)=> obj.selectedGosPart_id != selectedPartId);

              //? toFixed ile ücretleri 2 haneye indiriyoruz
              selectedEquivalent[0].part_in_vat_price = parseFloat(parseFloat(selectedEquivalent[0].part_in_vat_price).toFixed(2));
              selectedEquivalent[0].part_no_vat_price = parseFloat(parseFloat(selectedEquivalent[0].part_no_vat_price).toFixed(2));

              let priceWithUnit:number      = selectedEquivalent[0].part_in_vat_price * this.selectedPartUnit; 
              let priceNoVatWithUnit:number = selectedEquivalent[0].part_no_vat_price * this.selectedPartUnit; 
              

              let priceWithUnitCurrency   = selectedEquivalent[0].priceCurrency * this.selectedPartUnit; 
              let priceNoVatWithUnitCurrency = selectedEquivalent[0].priceNoVatCurrency * this.selectedPartUnit; 


              this.selectedParts.push({
                selectedGosPart_id :  selectedPartInfo[0].selectedGosPart.id,
                selectedGosPart_name: selectedPartInfo[0].selectedGosPart.name,
                labourTypeId: null,
                labourValue: null,
                labourDiscount: null,
                brandLabourPrice: null, //* manuel parçada işçilik ürün olarak eklenmekte o yüzden gos ve brand labour price 0 gönderilmektedir.
                gosLabourPrice: null,
                orderId: 0,
                selectedEquivalent_partOriginalId : selectedEquivalent[0].part_original_id,
                selectedEquivalent_partInputTypeName : partType,
                selectedEquivalent_partInputId : inputId,
                selectedEquivalent_currencyPrice : selectedEquivalent[0].priceCurrency,
                selectedEquivalent_currencyNoVatPrice : selectedEquivalent[0].priceNoVatCurrency,
                selectedEquivalent_currencyTotalPrice : priceWithUnitCurrency,
                selectedEquivalent_currencyPriceUnitId : selectedEquivalent[0].price_unit_id,
                selectedEquivalent_currencyNoVatTotalPrice: priceNoVatWithUnitCurrency,
                selectedEquivalent_priceTotal: priceWithUnit.toFixed(2),
                selectedEquivalent_priceVat: parseFloat(( priceWithUnit - priceNoVatWithUnit ).toFixed(2)),
                selectedEquivalent_priceNoVatTotal: priceNoVatWithUnit.toFixed(2),
                selectedEquivalent_partDiscount: null, //TODO: buraya daha sonra discount eklenbilir.
                selectedEquivalent_partTypeId: selectedEquivalent[0].part_type_id,
                selectedEquivalent_partValue: selectedEquivalent[0].part_in_vat_price,
                selectedEquivalent_partNoVatValue: selectedEquivalent[0].part_no_vat_price,
                selectedEquivalent_id: selectedEquivalent[0].id,
                selectedEquivalent_name: selectedEquivalent[0].name,
                selectedEquivalent_partTypeName: selectedEquivalent[0].part_types.name,
                selectedEquivalent_priceUnitId: this.activeCurrency?.id,
                selectedEquivalent_priceUnitName: this.activeCurrency?.code,
                selectedEquivalent_partUnitType: selectedPartInfo[0][partType].partUnitType,
                selectedEquivalent_partUnitName: selectedPartInfo[0][partType].partUnitType,
                selectedEquivalent_partUnitTypeId: selectedPartInfo[0][partType].partUnitTypeId,
                selectedEquivalent_partUnit : selectedPartInfo[0][partType].partUnit,
                selectedEquivalent_partCode : selectedEquivalent[0].part_code,
                selectedEquivalent_serviceValue: parseFloat( priceWithUnit.toFixed(2) ),
                selectedEquivalent_serviceValueUnit: parseFloat( selectedEquivalent[0].part_in_vat_price.toFixed(2) ),
              });
            
            this.setTotalAmount();

           }
        }

      }

    }

  }

  setTotalAmount(){

    this.grandTotal = 0;
      this.subTotal = 0;
      this.difference = 0;
      let orderInfo = {};
      this.brandMaintenancePrice = 0;
      this.gosMaintenancePrice = 0;
//? işçilik ücreti gosMaintenancePrice kısmına ekelencek mi?
//? işçilik kdv li hesaplanıp kdvsi alınacak mı?
//? gos işçiliik ücreti kdvsiz alınıp kdv si kdv sekmesinemi eklenecek?
//? parça işçilik ücreti kdvsiz alınıp kdv si kdv sekmesinemi eklenecek?

      //this.selectedPieces = this.selectedParts;
    
    if(this.selectedParts && this.selectedParts.length > 0){

     
      this.selectedParts.forEach((element:any) => {

        element.selectedEquivalent_priceTotal = parseFloat(parseFloat(element.selectedEquivalent_priceTotal).toFixed(2));
        element.selectedEquivalent_priceNoVatTotal = parseFloat(parseFloat(element.selectedEquivalent_priceNoVatTotal).toFixed(2));
       
        this.grandTotal = parseFloat(( element.selectedEquivalent_priceTotal + this.grandTotal ).toFixed(2));
        this.subTotal   = parseFloat(( element.selectedEquivalent_priceNoVatTotal + this.subTotal ).toFixed(2));

        if(element.selectedEquivalent_partUnitTypeId == 3){

         if( element.gosLabourPrice > 0 ){
            this.grandTotal += element.gosLabourPrice;
            this.gosMaintenancePrice += element.gosLabourPrice;
          }
  
     /*     if( element.brandLabourPrice > 0 ){
            this.grandTotal += element.brandLabourPrice;
            this.brandMaintenancePrice = element.brandLabourPrice;
            this.brandMaintenancePriceNoVat = ( element.brandLabourPrice - ( ( element.brandLabourPrice * 18 ) / 100 ) )
          }*/

          

          //this.subTotal   = parseFloat(( this.subTotal + this.brandMaintenancePriceNoVat + this.brandMaintenancePriceNoVat ).toFixed(2));
       //   this.subTotal   = parseFloat(this.subTotal.toFixed(2));

          


        }else{

          


        }

        

      });

      let selectedCompaignProducts = this.selectedParts.filter((obj:any) => (obj.selected_part_group_id == 2));//yağ gurubu ise ayrı göster

      let selectedUncompaignProducts = this.selectedParts.filter((obj:any) => (obj.selected_part_group_id != 2));


     
      /*this.selectedParts = selectedCompaignProducts;
      this.selectedParts += selectedUncompaignProducts;*/


      


      this.difference = parseFloat(( this.grandTotal - this.subTotal ).toFixed(2));

      if( this.gosMaintenancePrice > 0 ){
        this.grandTotal += this.gosMaintenancePrice;
      
      }

      this.grandTotal = parseFloat(this.grandTotal.toFixed(2));
      orderInfo = {
        status_id: 1,
        type_id: 3,
        customer_id: 0,
        vehicle_id: this.vehicleInfo?.id,
        service_date: null,
        order_value: this.grandTotal,
        order_no_vat_value: this.subTotal,
        maintenance_package_id: 0,
        service_id: 0,
        customer_status_id: 2
      }


      if(this.selectedParts.length == this.partEquivalentList.length){
        this.returnParts.emit({amount:{grandTotal: this.grandTotal,serviceTotal: this.grandTotal, subTotal: this.subTotal, difference: this.difference},parts:this.selectedParts,orderInfo: orderInfo});

      }else{
        this.savingTotal = 0;
      }


    }else{

      if(this.selectedParts.length == this.partEquivalentList.length){

        this.returnParts.emit({amount:{grandTotal: this.grandTotal,serviceTotal: this.grandTotal, subTotal: this.subTotal, difference: this.difference},parts:this.selectedParts,orderInfo: orderInfo});
      }
    }


  }

  removeFromSelectedList(part_id:number,partInputId:string){
    this.selectedParts = this.selectedParts.filter((obj:any)=>obj.selectedGosPart_id != part_id);
    this.setTotalAmount();
    let selectedRadio = document.getElementById(partInputId) as HTMLInputElement;
    selectedRadio.checked = false;

  }

  

}
