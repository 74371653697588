import { Injectable } from "@angular/core";
import { gql, Query } from "apollo-angular";

export interface Currency {
  id: bigint,
  code: String,
  price_unit_id: BigInt,
  currency_value: number,
  updated_date: Date
}

interface ResponseArray {
  currency: Currency[]
}

interface ResponseSingle {
    currency_by_pk: Currency
  }

@Injectable()
export class CURRENCY_ID extends Query<ResponseSingle>{
  override document = gql`
  query CURRENCY_ID($id:Number) {
    currency_by_pk(id:$id) {
      id
      code
      price_unit_id
      currency_value
      updated_date
    }
  }`;
}

@Injectable()
export class CURRENCY_ALL extends Query<ResponseArray>{
  override document = gql`
  query GET_CITIES($where: currency_bool_exp) {
    currency(where: $where) {
        id
        code
        price_unit_id
        currency_value
        updated_date
    }
  }`;
}