<style>
    .privacy p{
        font-size: 16px;
    }
</style>
<!--begin::Post-->
<div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">

        
        <div class="row gy-5 g-xl-8" >
            
            <!--begin::Col-->
            <div class="col-xl-12 mb-5">
                <!--begin::Mixed Widget 2-->
                <div class="card card-xl-stretch">
                  
                    <!--begin::Body-->
                    <div class="card-body p-0">
                        
                        <!--begin::Stats-->
                        <div class="card position-relative hero-section">
                            <!--begin::Row-->
                            <div class="row g-0" style="background-color: #F3F3F3; background-image:url(https://cdn.destechhasar.com/garantili-oto-servis/static/garantili-oto-servis-01-2000x1200.jpeg); background-repeat: no-repeat; background-position-x: right; background-position-y: bottom;">
                                <!--begin::Col-->
                                <div class="col px-6 py-8 rounded-2 me-7 mb-7" style=" display: table;">
                                </div>
                                <!--end::Col-->
                                <!--begin::Col-->
                                <div class="col-xl-6 px-6 py-8 rounded-2 mb-7" >
                                   
                               
                                </div>
                                <!--end::Col-->
                            </div>
                            <!--end::Row-->
                           
                        </div>
                        <!--end::Stats-->
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Mixed Widget 2-->
            </div>
            <!--end::Col-->
           
           
        </div>
        <!--end::Row-->

        <div class="row gy-5 g-xl-8">
           
            <!--begin::Col-->
            <div class="col-xl-12 col-xxl-12 mb-5 mb-xl-10">
                <!--begin::Table Widget 3-->
                <div class="card card-flush h-xl-100">
                    <!--begin::Card header-->
                    <div class="card-header py-5">
                        <!--begin::Tabs-->
                        <div class="card-title pt-3 mb-0 gap-4 gap-lg-10 gap-xl-15 nav nav-tabs border-bottom-0" data-kt-table-widget-3="tabs_nav">

                            <h2 style="font-weight: 600;
                            font-size: 32px;
                            color: #004422;
                            margin: 0 0 30px; ">Gizlilik Politikası</h2>
                        </div>
                        <!--end::Tabs-->
                    
                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body pt-1">
                        <!--begin::Sort & Filter-->
                        <div class="d-flex flex-stack flex-wrap gap-4 privacy">
                          
                            <p>
                                Gizlilik Koşulları, Kullanıcılar ile akdedilen Kullanıcı Sözleşmesi’nin devamı ve ayrılmaz bir parçası niteliğindedir. Garantili Satış Sonrası Oto Hizmetleri, Web Site üzerinden kendisine elektronik ortamdan iletilen kişisel verileri (isim-soyisim, iletişim bilgileri ile Teklif’in oluşturulmasına yönelik diğer her türlü bilgi), Kullanıcı ile yaptığı Kullanıcı Sözleşmesi ve işbu Gizlilik Koşulları ile belirlenen amaçlar ve kapsam dışında üçüncü kişilere açıklamayacaktır. Bu kapsamda Garantili Satış Sonrası Oto Hizmetleri, gizli bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır saklama yükümü olarak addetmeyi ve gizliliğin sağlanması ve sürdürülmesi, gizli bilginin tamamının veya herhangi bir kısmının yetkisiz kullanımını veya üçüncü bir kişiye ifşasını önlemek için gerekli tüm tedbirleri almayı ve gerekli özeni göstermeyi taahhüt etmektedir.

                            </p>

                            <p>
                                Söz konusu kişisel veriler, Kullanıcı Sözleşmesi’nde belirtilen hallerin dışında, Kullanıcı ile temas kurmak veya Kullanıcı’nın Web Site’deki tecrübesini iyileştirmek (mevcut hizmetlerin geliştirilmesi, yeni hizmetler oluşturulması ve kişiye özel hizmetler sunulması gibi) amacıyla kullanılabileceği gibi, anonimleştirilerek çeşitli istatistiksel değerlendirmeler yapma, veri tabanı oluşturma ve pazar araştırmalarında bulunma amacıyla da kullanılabilecektir. Kullanıcı’ya ait bilgiler ayrıca, Kullanıcı’dan ilgili kapsama ilişkin olarak bir onay alınmış olması kaydıyla;

                            </p>
                            <p>
                                (i) Garantili Satış Sonrası Oto Hizmetleri tarafından ürün ve hizmetlerinin pazarlanması ve Kullanıcı ile iletişime geçilmesi amacıyla işlenebilecek ve saklanabilecek, ve/veya

                            </p>
                            <p>
                                (ii) Garantili Satış Sonrası Oto Hizmetleri'nin işbirliğinde olduğu kişilere yine doğrudan pazarlama amaçlı olarak aktarılabilecektir. Kullanıcı, kişisel verilerinin işlenip işlenmediğini, ilgili bilgileri, işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını, yurtiçinde veya yurtdışında verilerin aktarıldığı üçüncü kişileri, verilerin düzeltilmesini, mevzuatta öngörülen koşullarla kişisel verilerin silinmesini, düzeltme/silme/yok etme işlemlerinin imkânsız olmamak veya orantısız çaba gerektirmemek kaydıyla, verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme; işlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhine bir sonucun ortaya çıkmasına itiraz etme ve verilerin ilgili mevzuata aykırı olarak işlenmesi sebebiyle zarara uğraması halinde zararın giderilmesini talep etme, ayrıca resmi şekilde bildireceği iletişim yöntemiyle kendisiyle iletişime geçilmesine engel olunmasını talep etme hakkına sahiptir. Garantili Satış Sonrası Oto Hizmetleri, Kullanıcı’nın Site üzerinde gerçekleştirdiği işlemlere ilişki bilgileri anonim hale getirerek istatistiki değerlendirmelerde, performans değerlendirmelerinde, yıllık rapor ve benzeri raporlarda kullanmak üzere bu amaçların gerçekleştirilmesi için gereken sürede saklayabilir, işleyebilir ve iş ortaklarına iletebilir. Garantili Satış Sonrası Oto Hizmetleri ayrıca, aşağıdaki koşulların varlığı halinde Kullanıcı’ya ait bilgileri üçüncü kişilerle paylaşabilecektir:

                            </p>
                            <p>
                                Kullanıcı Sözleşmesi kapsamındaki yükümlülüklerin yerine getirilmesi için ilgili ifşanın zorunlu olması,

                            </p>
                            <p>
                                Yetkili idari ve adli bir kurum tarafından ilgili mevzuat doğrultusunda usulüne göre yürütülen bir araştırma veya soruşturmanın yürütümü amacıyla kullanıcılarla ilgili bilgi talep edilmesi,

                            </p>
                            <p>
                                Kullanıcı’nın haklarını korumak veya güvenliklerini sağlamak için bilgi vermenin gerekli olması.

                            </p>
                            <p>
                                Web Site ile ilgili sorunların tanımlanması ve Web Site’de çıkabilecek sorunların ivedilikle giderilebilmesi için, Garantili Satış Sonrası Oto Hizmetleri gereken hallerde kullanıcıların IP adresini tespit etmekte ve kullanmaktadır. IP adresleri, ayrıca kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak amacıyla da kullanılabilir. Garantili Satış Sonrası Oto Hizmetleri, yukarıda anılan amaçlarla ve verdiği hizmetin ifasıyla bağlı olmak üzere ilgili verileri Kullanıcı’nın bulunduğu ülke dışında dünyanın herhangi bir yerinde bulunan sunucularına (sunucular kendisine, bağlı şirketlerine veya alt yüklenicilerine ait olabilir) aktarma hakkına sahiptir. Web Site üzerinden başka site ve uygulamalara link verilmesi mümkün olup, Garantili Satış Sonrası Oto Hizmetleri bu sitenin gizlilik uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk taşımamaktadır. Garantili Satış Sonrası Oto Hizmetleri, işbu Gizlilik Koşulları’nı dilediği zaman Web Site’de yayımlamak suretiyle değiştirebilir. Garantili Satış Sonrası Oto Hizmetleri'nin değişiklik yaptığı Gizlilik Koşulları hükümleri Web Site’de yayınlandığı tarihte yürürlüğe girecek olup, Web Site’nin kullanılması ile Kullanıcı güncel koşulları kabul etmiş addedilir. Söz konusu dokümanların düzenli bir şekilde takip edilmesinden Kullanıcı bizzat sorumlu olacaktır.

                            </p>







                        </div>
                    </div>
                </div>
            </div>
        </div>


    
    </div>
    <!--end::Container-->
</div>
<!--end::Post-->