<div class="modal-header">
    <h5 class="modal-title">Resimler</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div *ngFor="let image of carouserImages; let i = index" class="carousel-item" [class.active]="i === selectedImageIndex">
            <img [src]="image.image" class="d-block w-100" alt="Image {{ i }}" loading="lazy" />

          <!-- Resmin altına içerik alanı -->
          <div class="carousel-caption d-none d-md-block">
            <h5>{{ image.title || 'No Title' }}</h5>
            <p>{{ image.description || 'No Description Available' }}</p>
            <div class="d-flex justify-content-center">
                <button class="btn btn-danger btn-sm" style="margin-right: 5px;" (click)="delImage(image.id,image.image_name)">Görseli Sil</button>
                <a class="btn btn-success btn-sm" (click)="downloadImage(image.image, image.image_name)">Görseli İndir</a>
            </div>
           
          </div>
        </div>
      </div>
      <a class="carousel-control-prev"  role="button" data-slide="prev" (click)="prevImage()">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">geri</span>
      </a>
      <a class="carousel-control-next"  role="button" data-slide="next" (click)="nextImage()">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">İleri</span>
      </a>
    </div>
  </div>
  