import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import moment from 'moment';
import { COMPANY_ALL } from 'src/app/graphql/company.graphql';
import { Order, ORDER_ID } from 'src/app/graphql/order.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-appointment-v3-odeme-basarili',
  templateUrl: './odeme-basarili-v3.component.html',
  styles: [
  ],
  providers:[
    ORDER_ID,
    COMPANY_ALL
  ]
})
export class OdemeBasariliV3Component implements OnInit {

  @Input() userForm: any;
  @Input() orderId: BigInt | undefined;
  public orderDetail: Order | undefined;
  public todayDate: any;
  public serviceLastDate: any;
  public gosCompany: any;
  public orderId1 = 1374;


  public userForm1 = new FormControl( {
    firstname: new FormControl('mehmet',[Validators.required]),
    lastname: new FormControl('özalp',[Validators.required]),
    gsm_id: new FormControl(null),
    gsm: new FormControl(null,[Validators.required]),
    email: new FormControl('mehmetozalp@destechhasar.com',[Validators.required]),
    phone: new FormControl(null),
    approved: new FormControl(1,[Validators.required]),
    id: new FormControl(0,[Validators.required]),
    is_user_registered: new FormControl(1,[Validators.required]),
    job: new FormControl('terzi')
  });
  

  constructor(
    private getOrder: ORDER_ID,
    private alertifyService: AlertifyService,
    private getCompanies: COMPANY_ALL,

  ) { 
    window.scrollTo(0, 0);
  }

  async ngOnInit(): Promise<void> {
    moment.locale('tr');

   await this.getOrder.watch({id:this.orderId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {

      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }

      if(resp.data){
        this.orderDetail = resp.data.orders_by_pk;

        this.serviceLastDate = moment(this.orderDetail.service_date).format('DD.MM.yyyy H:mm').toString();
      }

    })

    await this.getCompanies.watch({where:{id:{_eq:1106}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.gosCompany = resp.data.companies[0];

        
      }
    })


    //this.serviceLastDate = moment().add(2, 'days').format('DD.MM.yyyy H:mm').toString();

   // this.serviceLastDate = moment(this.serviceLastDate).format('DD.MM.yyyy H:mm').toString();
    this.todayDate = moment().format('DD.MM.yyyy H:mm').toString();
  }

}
