import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {


    public garanti_nedir:boolean = false;
    public faydali_bilgiler: boolean = false;
    public kurumsal:boolean = false;
    public profileMenu:boolean = true;
    public drawerPage:string = "";

    headerLeft: string = 'menu';
    showDrawer: boolean = false;

  
  constructor() { }

  ngOnInit(): void {
    this.headerLeft = "menu"

  }

  toggleMenu(cls:string){
    switch(cls){
      case "garanti_nedir" : this.garanti_nedir = !this.garanti_nedir; break;
      case "faydali_bilgiler" : this.faydali_bilgiler = !this.faydali_bilgiler; break;
      case "kurumsal" : this.kurumsal = !this.kurumsal; break;
    }
  }


  profileToggle() {

    this.profileMenu = !this.profileMenu;

  }

  showSub($event:any){
    let menuContainer = $event.target.parentNode.nextSibling.classList;
    if(menuContainer.contains("show")){
      $event.target.classList.remove("active")
      menuContainer.remove("show");
    }else{
      $event.target.classList.add("active")
      menuContainer.add("show");
    }
   
  }


  @HostListener('window:click', ['$event.target'])
  onClick(event: any) {
    if (!event.classList.contains('dropdown')) {
   
      let close = false
     /* while (event.tagName !== "BODY" && !event.tagName.startsWith('APP')) {
        if (event.classList.contains('menu-sub-dropdown')) {
          close = true
          break;
        }
        event = event.parentNode
      }*/
      //if (this.garanti_nedir) this.garanti_nedir = false;
      //if (this.searchBox) this.searchBox = close;
    }
  }

  toggleDrawer() {
    this.showDrawer = !this.showDrawer
  }

  openDrawer(page:string){
    this.drawerPage = page;
    this.showDrawer = true;
  }

}
