
    <div class="d-flex flex-column gap-10 mt-10">
        <div class="fv-row">
            
            <label class="required form-label">Ödeme Metodu</label>
          

            <p class="alert alert-success">{{orderDetail?.payment_types?.name}}</p>

        </div>
    </div>

    <div class="d-none flex-column gap-10 mt-10">
        <div class="fv-row">
            
            <label class="required form-label">Ödeme Türü</label>
          

            <select 
            *ngIf="orderDetail?.is_payed == false || orderDetail?.is_payed == null"
            (change)="changePaymentMethod($event)"
            class="form-select mb-2" 
            data-control="select2" 
            data-hide-search="true" 
            data-placeholder="Select an option" 
            name="payment_method" 
            id="kt_ecommerce_edit_order_payment">
                <option>Seçiniz</option>
                <option value="{{item.id}}" [selected]="orderDetail?.payment_type == item.id" *ngFor="let item of paymentTypes">{{item.name}}</option>
            
            </select> 

        </div>
    </div>


  
   
    <div class="d-flex flex-column gap-10 " *ngIf="selectedPayment == 4 ">
        <p *ngIf="orderDetail?.is_payed">{{ orderDetail?.payment_types?.name }}</p>
    </div>
   

    <div class="d-flex flex-column gap-10 mt-10 d-none" *ngIf=" ((orderDetail?.is_payed == false || orderDetail?.is_payed == null) && selectedPayment==4)">
        <div class="fv-row d-flex" >
            <input 
                *ngIf="orderDetail?.order_invoice_info != null"
                type="button" 
                [disabled]="!isPaymentButtonActive" 
                (click)="changePaymentStatus('cash')" 
                class="btn btn-sm btn-primary flex-end" 
                value="Ödeme Alındı">
            <span *ngIf="orderDetail?.order_invoice_info == null" class="alert alert-danger">Lütfen önce fatura bilgilerini eksiksiz giriniz!</span>    
        </div>
    </div>

    <div class="d-flex flex-column gap-10 mt-10" *ngIf=" (orderDetail?.is_payed == false || orderDetail?.is_payed == null )">
        <select name="" (change)="setNumber($event)" class="form-control" id="usergsms" *ngIf="orderDetail?.user?.gsms">
            <option value="">Sms için numara seçiniz</option>
            <option value="{{item.code}}"  *ngFor="let item of orderDetail?.user?.gsms">{{item.code}}</option>
        </select>
    </div>

    <div class="d-flex flex-column gap-10 mt-10" *ngIf=" (orderDetail?.is_payed == false || orderDetail?.is_payed == null )">
       <span><strong>Ödeme Linki: </strong>{{this.domainName}}/siparis-odeme/{{orderDetail?.track_id}}</span>
        <div class="fv-row d-flex" >
            <input type="button" [disabled]="!isPaymentButtonActive" (click)="changePaymentStatus('cc')" class="btn btn-sm btn-primary flex-end" value="Ödeme için sms gönder">
        </div>
    </div>
   
    <div *ngIf="selectedPayment == 1" class="mx-5 mx-xl-auto my-7 d-none">

        <div>
            <div class="alert alert-danger" *ngIf="this.subOrderCards?.pos_process_status_id == 3">
                {{subOrderCards?.pos_message}}
            </div>
        <form  #paymentForm method="post" action="https://test.inter-vpos.com.tr/mpi/Default.aspx" target="_blank" >
            <!--begin::Input group-->
            <div class="d-flex flex-column mb-7 fv-row">
                <!--begin::Label-->
                <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                    <span class="required">ad Soyad</span>
                    <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Specify a card holder's name"></i>
                </label>
                <!--end::Label-->
                <input type="text" class="form-control form-control-solid" placeholder="" name="card_name" value="Max Doe" />
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="d-flex flex-column mb-7 fv-row">
                <!--begin::Label-->
                <label class="required fs-6 fw-bold form-label mb-2">Kart Numarası </label>
                <!--end::Label-->
                <!--begin::Input wrapper-->
                <div class="position-relative">
                    <!--begin::Input-->
                    <input type="text" class="form-control form-control-solid" placeholder="Enter card number" name="Pan"  value="4459882705284023" />
                    <!--end::Input-->
                   
                </div>
                <!--end::Input wrapper-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="d-flex flex-column mb-7 fv-row">
                <!--begin::Label-->
                <label class="required fs-6 fw-bold form-label mb-2">Kart Türü</label>
                <!--end::Label-->
                <!--begin::Input wrapper-->
                <div class="position-relative">
                    <!--begin::Input-->
                    <select name="CardType" class="form-control">
                        <option value="0">Visa</option>
                        <option selected value="1">MasterCard</option>
                    </select>                                        <!--end::Input-->
                    <!--begin::Card logos-->
                   
                    <!--end::Card logos-->
                </div>
                <!--end::Input wrapper-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-10">
                <!--begin::Col-->
                <div class="col-md-8 fv-row">
                    <!--begin::Label-->
                    <label class="required fs-6 fw-bold form-label mb-2">Son Kullanma Tarihi (MMYY)</label>
                    <!--end::Label-->
                    <!--begin::Row-->
                    <div class="row fv-row">
                        <!--begin::Col-->
                        <div class="col-6">
                            <input class="form-control" type="text" name="Expiry" value="1122" >
                           
                        </div>
                        <!--end::Col-->
                       
                    </div>
                    <!--end::Row-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-md-4 fv-row">
                    <!--begin::Label-->
                    <label class="d-flex align-items-center mb-8 fs-6 fw-bold form-label mb-2">
                        <span class="required">CVV</span>
                        <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Enter a card CVV code"></i>
                      
                    </label>
                    <!--end::Label-->
                    <!--begin::Input wrapper-->
                    <div class="position-relative">
                        <!--begin::Input-->
                        <input type="text" class="form-control form-control-solid" value="456" minlength="3"  maxlength="4" placeholder="CVV" name="Cvv2" />
                        <!--end::Input-->
                    </div>
                    <!--end::Input wrapper-->
                </div>
                <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="d-flex flex-stack">
                <!--begin::Label-->
                <div class="me-5 d-none">
                    <label class="fs-6 fw-bold form-label">Save Card for further billing?</label>
                    <div class="fs-7 fw-bold text-muted">If you need more info, please check budget planning</div>
                </div>
                <!--end::Label-->
                <!--begin::Switch-->
                <label class="d-none form-check form-switch form-check-custom form-check-solid">
                    <input class="form-check-input" type="checkbox" value="1" checked="checked" />
                    <span class="form-check-label fw-bold text-muted">Save Card</span>
                </label>
                <!--end::Switch-->
            </div>
            <!--end::Input group-->
            <!--begin::Actions-->
            <div class="text-center">
                <button type="submit" id="kt_modal_new_card_submit" class="btn btn-primary" (click)="paymentProcess($event)">
                    <span class="indicator-label">Ödeme Yap</span>
                    <span class="indicator-progress">Lütfen Bekleyiniz...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
            </div>

            <!--end::Actions-->
            <input type="hidden" name="ShopCode" value="3123" />
            <input type="hidden" name="PurchAmount" value="{{totalAmount}}" />
            <input type="hidden" name="Currency" value="949" />
            <input type="hidden" name="OrderId" value="{{momentOrderId}}" />
            <input type="hidden" name="OkUrl" value="https://api.garantiliotoservis.com/payment" />
            <input type="hidden" name="FailUrl" value="https://api.garantiliotoservis.com/payment" />
            <input type="hidden" name="Rnd" value="{{rnd}}" />
            <input type="hidden" name="Hash" value="{{hash}}" />
            <input type="hidden" name="TxnType" value="Auth" />
            <input type="hidden" name="InstallmentCount" value="" />
            <input type="hidden" name="SecureType" value="3DPay" />
            <input type="hidden" name="Lang" value="tr" />
            <input type='hidden' name="Version3D" value="2.0" />


        </form>
        </div>

       
</div>

<div class="d-flex flex-column gap-10 mt-5">
    <div class="fv-row">
        <label class="required form-label">Ödeme Durumu</label>
      
        <p class="alert alert-success" *ngIf="orderDetail?.is_payed">Ödeme alındı</p>
        <p class="alert alert-danger" *ngIf="orderDetail?.is_payed == false">Ödeme alınmadı</p>
    </div>
</div>