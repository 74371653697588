import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Breadcrumb, BreadcrumbService } from "src/app/services/breadcrumb.service";

type NewType = Observable<Breadcrumb[]>;

@Component({ 
  selector: 'app-shared-breadcrumb', 
  templateUrl: './breadcrumb.component.html', 
  styleUrls: ['./breadcrumb.component.css'], 
}) 
export class BreadcrumbComponent { 
  breadcrumbs$: NewType; 
  public pageTitle: Promise<string> | undefined;
  public pTitle:string | undefined;
 
  constructor(private readonly breadcrumbService: BreadcrumbService, private router: Router) { 
    this.breadcrumbs$ = breadcrumbService.breadcrumbs$;
    this.breadcrumbs$.subscribe((result:any)=>{
      this.pageTitle =  new Promise<string>((res, reject) => {
        if(result.length > 0){
          if(result.length == 1){
            this.pTitle = result[0].label;;
          }else{
            this.pTitle = result[result.length-1].label;;
          }
        }
        
        
      });

    })
  } 

  goToPage(url:string){
    this.router.navigateByUrl(url);
  }
} 