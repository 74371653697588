import { Injectable } from '@angular/core';
import { gql, Query, Mutation, Subscription } from 'apollo-angular';
import { OzanpayCancel } from './ozanpay_cancel.garphql';
import { OzanpayRequest } from './ozanpay_request.graphql';

export interface OzanpaySuccessfuls {
  id: Number;
  ozan_pay_request_id: Number;
  order_id: Number;
  order_card_id: Number;
  referenceNo: String;
  code: String;
  data: String;
  status: String;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  captured: boolean;
  ozanpay_requests: OzanpayRequest[],
  ozanpay_cancel: OzanpayCancel[]
}

interface ResponseArray {
    ozanpay_successfuls: OzanpaySuccessfuls[];
}

interface ResponseSingle {
    ozanpay_successfuls_by_pk: OzanpaySuccessfuls
}




@Injectable()
export class OZANPAY_SUCCESSFULS_SUBS extends Subscription<ResponseArray> {
  override document = gql`
  subscription OZANPAY_SUCCESSFULS_SUBS($where: ozanpay_successfuls_bool_exp, $limit: Int = 1){
    ozanpay_successfuls(where: $where, order_by: {created_at: desc}, limit: $limit){
        id
        ozan_pay_request_id
        order_id
        order_card_id
        referenceNo
        code
        data
        status
        created_at
        updated_at
        deleted_at
    }
  }`;
}


@Injectable()
export class OZANPAY_SUCCESSFULS_ALL extends Query<ResponseArray> {
  override document = gql`
  query OZANPAY_SUCCESSFULS_ALL($where: ozanpay_successfuls_bool_exp, $limit: Int = 1){
    ozanpay_successfuls(where: $where, order_by: {created_at: desc}, limit: $limit){
        id
        ozan_pay_request_id
        order_id
        order_card_id
        referenceNo
        code
        data
        status
        created_at
        updated_at
        deleted_at
        captured
        ozanpay_requests{
          id
          reference_no
          order_id
        }
        ozanpay_cancel(where:{status:{_eq:"APPROVED"}}){
          id
          ozan_pay_successfuls_id
          order_id
          referenceNo
          code
          data
          status
          message
          users{
            id
            name
            surname
          }
          created_at
          updated_at
          deleted_at
          message
          process_type
        }
    }
  }`;
}

@Injectable()
export class OZANPAY_SUCCESFYLS_UPDATE_ORDER_ID extends Mutation<ResponseSingle> {
  override document = gql`
    mutation OZANPAY_SUCCESFYLS_UPDATE_ORDER_ID($id:bigint!, $order_id:bigint) {
      update_ozanpay_successfuls(where: {id: {_eq: $id}}, _set: {order_id: $order_id}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}
