 

  <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
    {{pTitle}} 
    <span class="h-20px border-gray-200 border-start ms-3 mx-2"></span>
    <ol class="breadcrumb text-muted fs-6 fw-bold">
      <li *ngFor="let breadcrumb of (breadcrumbs$ | async)"> 
          <a (click)="goToPage(breadcrumb.url)">
              <small class="text-muted fs-7 fw-bold my-1 ms-1">                
                  {{ breadcrumb.label }}
              </small>
          </a> 
        </li> 
  </ol>
  </h1>