<div class="w-100">
                                
    <app-shared-customer-manuel-parts
   
    [modelTypeId]="modelTypeId"
    [vehicleInfo]="vehicleInfo"
    [selectedParts]="selectedPieces"
    (returnParts)="setOrderParts($event)"
    >

    </app-shared-customer-manuel-parts>




</div>