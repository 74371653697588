<form action="" class="mx-auto mw-1000px w-100 pb-10 ng-untouched ng-pristine ng-valid">
    <div class="post d-flex flex-column-fluid" id="appointment-container">
        <div id="kt_content_container" class="container-xxl">
            <div class="card">
                <div class="card-header pt-3">
                    <div class="nav-container">
                        <div class="nav-item-container" [ngStyle]="{'left.px': leftWidth,'transition': '10s all bounce;'}">
                     
                            <div class="nav-item" *ngFor="let item of stepList">
                                <div>
                                    <h3 class="stepper-title">
                                        <div  class="d-flex flex-column text-center fs-6"  >{{item.step_title}}</div>
                                    </h3>
                                   <span  [ngClass]="{'act': step == item.position,'completed':item.is_completed && item.position != step}" class="gos-stepper ">
                                       <span *ngIf="!item.is_completed || item.position == step ">{{item.position}}</span>
                                   </span>
                                </div>  
                                   
                            </div>
                        </div>
                    </div>
                    </div>
                    
                <div class="card-body">
                    
                    <div style="display:flex;justify-content: space-between; margin-bottom:20px">
                        <button [disabled]="step == 1 || step > 5" class="btn btn-primary btn-sm" (click)="stepChange('previous')" >geri</button>
                        <button *ngIf="showNextBtn" [disabled]="stepList && step == stepList.length || !isStepCompleted" class="btn btn-primary btn-sm " (click)="stepChange('next')" > İleri</button>
                        
                    </div>
                   
                 
    
                   
                   <div *ngFor="let item of stepList">

                        <app-appointment-v2-vehicle-select
                            *ngIf="step == item.position && item.service_name == 'app-appointment-v2-vehicle-select'"
                            [selectedModelTypeId]="vehicleForm.model_type_id"
                            [gearId]="vehicleForm.gear_id"
                            [fuelId]="vehicleForm.fuel_id"
                            (emitVehicleForm)="setStepVariable($event)"
                            [garageId]="vehicleForm.garage_id"
                            [orderServiceId]="orderTypeId"
                            [selecteMaintenanceKmsId]="appointmentFormGroup.value.maintenanceKmsId"
                            (removeBtn)="setRemoveBtn($event)"
                        >
                        </app-appointment-v2-vehicle-select>

                        <app-appointment-v2-hizmet-secimi-v2
                            *ngIf="step == item.position && item.service_name == 'app-appointment-v2-hizmet-secimi-v2'"
                            [selectedOrderTypeId]="appointmentFormGroup.value.serviceTypeId"
                            (orderTypeId)="setStepVariable($event)"
                            (buttonEmitter)="buttonEmitter($event)"
                            >
                            
                        </app-appointment-v2-hizmet-secimi-v2>
                        <app-appointment-v2-package-select
                            *ngIf="step == item.position && item.service_name == 'app-appointment-v2-package-select'"
                            [appointmentForm] = "appointmentFormGroup"
                            (selectedPackageEmitter)="setStepVariable($event)"
                        >
                            
                        </app-appointment-v2-package-select>
                        <app-appointment-v2-servis-secimi
                            *ngIf="step == item.position && item.service_name == 'app-appointment-v2-servis-secimi'"
                            (serviceEmitter)="setStepVariable($event)"
                        >

                        </app-appointment-v2-servis-secimi>
                        <app-appointment-v2-proforma 
                        *ngIf="step == item.position && item.service_name == 'app-appointment-v2-proforma' &&  proformaCanShow"
                        [orderId]="orderId"
                        (proformaEmitter)="setStepVariable($event)"
                        ></app-appointment-v2-proforma>
                        <app-appointment-v2-odeme
                        *ngIf="step == item.position && item.service_name == 'app-appointment-v2-odeme'"
                        [orderCardId]="orderCard?.id"
                        [selectedPackageInfo]="selectedPackageInfo"
                        [selectedPieces]="selectedPieces"
                        [selectedPaymentTypes]="selectedPaymentType"
                        [priceInfo]="amountInfo"
                        (returnData)="setStepVariable($event)"
                        ></app-appointment-v2-odeme>
                       
                     

                    
                     
                   
                        
    
                    
                       
                          
                        
                        
                           
                   </div>

                   <div style="display:flex;justify-content: space-between; margin-bottom:20px" *ngIf="step!=2">
                    <button [disabled]="step == 1 || step > 5" class="btn btn-primary btn-sm" (click)="stepChange('previous')" >geri</button>
                    <button *ngIf="showNextBtn" [disabled]="stepList && step == stepList.length || !isStepCompleted" class="btn btn-primary btn-sm " (click)="stepChange('next')" > İleri</button>
                    
                    </div>
                </div>
               
            </div>
        </div>
    </div>
    </form>