import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { Order, ORDER_ID } from 'src/app/graphql/order.graphql';
import { User, USER_GET_ID } from 'src/app/graphql/user.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-shared-proforma-detail',
  templateUrl: './proforma-detail.component.html',
  styles: [
  ],
  providers: [ORDER_ID,USER_GET_ID]
})
export class ProformaDetailComponent implements OnInit {

  @Input() orderId: Number | undefined;

  public order: Order | undefined;
  public userInfo: User | undefined;
  public serviceLastDate:any;
  public todayDate:any;
  public selectedCompaignProducts:any = [];
  public selectedUncompaignProducts:any = [];


  constructor(
    private getOrder: ORDER_ID,
    private alertifyService: AlertifyService,
    private getUserInfo: USER_GET_ID
    ) { }

  ngOnInit(): void {
    moment.locale('tr');

    this.serviceLastDate = moment().add(1, 'days').endOf('day').format('LLL');
    this.todayDate = moment().format('LL');

    this.getOrder.watch({id:this.orderId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
      if(response.errors){
        this.alertifyService.error(response.errors[0].message);
      }

      if(response.data){
        this.order = response.data.orders_by_pk;
        this.selectedCompaignProducts = this.order.order_parts.filter((obj:any) => (obj.selected_part_group_id == 2));//yağ gurubu ise ayrı göster

        this.selectedUncompaignProducts = this.order.order_parts.filter((obj:any) => (obj.selected_part_group_id != 2));

        this.getUserInfo.watch({id:this.order.customer_id},{fetchPolicy:"no-cache"}).valueChanges.subscribe(userResponse =>{
          if(userResponse.errors){
            this.alertifyService.error(userResponse.errors[0].message);
          }

          if(userResponse.data){
            this.userInfo = userResponse.data.users_by_pk;
          }
        })

      }
    })

  }

}
