
<style>
    .d-none{
        display: none !important;
    }
    .gos-stepper{
        display:block; 
        margin: 5px auto; 
        padding:  10px; 
        border:1px solid #ccc; 
        width: 40px; 
        height:40px; 
        background-color: #96bc33; 
        color: #015029; 
        text-align: center; 
        border-radius: 20px;
        font-weight: bold;
    }
    .completed::before{
        font-family: "Font Awesome 5 Free";
        content: "\f00c" !important;
        display: inline-block;
        vertical-align: middle;
        padding-left: 1px;
    }
    .act{
        color: #96bc33 !important; 
        background-color: #015029 !important; 
    }
    .gos-stepper-show{
            visibility: show !important;
        }
        .gos-stepper-hide{
            visibility: hidden !important;
        }

    .brand-logo{
        width: 20%;
    }    

    .stepper-nav{
        width: 100%;
    }

    .gos-step-numbers{
            display: block;
            padding: 0;
            list-style-type: none;
            width: 800px;
            overflow: hidden;
            height: 55px;
        }
        .gos-step-number-item{
            width: calc(50%/3);
            display: none;
            float: left;
            padding: 0;
            height: 55px;
        }
        

    @media only screen and (max-width: 600px){
        .brand-logo{
            width: 1%;
            display: none;
        }
        .gos-step-number-item{
            width: 50px;
            display: block;
            float: left;
            padding: 0;
            height: 55px;
        }
        .gos-step-number-item h3{
            display: none;
        }
    }
    @media only screen and (max-width: 1000px){
        .stepper-item{
            display: none;
        }
        .active-stepper{
            display: block;
        }
        .gos-step-number-item{
            width: calc(50%/3);
            display: none;
            float: left;
            padding: 0;
            height: 55px;
        }
       
    }
    
</style>
<style>
    #top-btn{
        position: fixed;
        top:150px;
        right:125px;
        text-align: center;
        z-index: 100;
        color: #fff;
    }
    #top-btn i {
        color: #fff;
    }
</style>
<a class="btn btn-primary" (click)="goToTop(servisSecimiContainer)" *ngIf="step == steps.servis_secimi.id" id="top-btn">
  Yukarı
  <i class="fas fa-arrow-to-top"></i>
</a>
<div class="post d-flex flex-column-fluid" id="kt_post">
    <div id="kt_content_container" class="container-xxl">
        <div class="card">
            <div class="card-body">
               
                <div style="clear: both;"></div>

                <div class="stepper stepper-links d-flex flex-column pt-15" id="kt_create_account_stepper">
                 
                    <div class="stepper-nav mb-5">
                        <div class="stepper-item gos-step-number-item d-block " 
                        [class.active-stepper]="step === steps.hizmet_tipi.id" 
                        [class.d-block]="(step === steps.hizmet_tipi.id) || (step === steps.arac_secimi.id)"
                         data-kt-stepper-element="nav">
                         <h3 class="stepper-title">
                            <div 
                            class="d-flex flex-column text-center" 
                            >Hizmet Tipi</div></h3>
                            <span class="gos-stepper " 
                            [class.act]="step === 1" 
                            [ngClass]="{completed: orderServiceId > 0}"
                            >
                            <span [ngClass]="{'gos-stepper-hide': orderServiceId > 0}" >1</span>
                            </span>
                            
                        </div>
                        
                        <div class="stepper-item gos-step-number-item d-block" 
                        [class.act]="step === steps.arac_secimi.id"
                        [class.d-block]="(step === steps.login.id) "
                        [class.active-stepper]="step === steps.login.id" 
                            *ngIf="!authService.isLogin && step === steps.arac_secimi.id">
                            <span  class="gos-stepper gos-stepper act ">2</span>

                            <h3 class="stepper-title text-center">Hesap Bilgileri</h3>
                            <span 
                            class="gos-stepper "  
                            [ngClass]="{completed: vehicleId > 0}"
                            [class.act]="step === steps.login.id" 
                            >
                            <span [ngClass]="{'gos-stepper-hide': vehicleId > 0}" >2</span>
                            </span>
                        </div>
                        
                        <div class="stepper-item gos-step-number-item"  
                        data-kt-stepper-element="nav"
                        [class.active-stepper]="step === steps.arac_secimi.id" 
                        [class.d-block]="(step === steps.hizmet_tipi.id) || (step === steps.arac_secimi.id) ">

                            <h3 class="stepper-title text-center">Araç Bilgileri</h3>
                            <span 
                            class="gos-stepper "  
                            [ngClass]="{completed: vehicleId > 0}"
                            [class.act]="step === steps.arac_secimi.id" 
                            [class.d-block]="((step === steps.arac_secimi.id)  )">
                            <span [ngClass]="{'gos-stepper-hide': vehicleId > 0}" >2</span>
                            </span>

                        </div>
                        <div class="stepper-item gos-step-number-item" data-kt-stepper-element="nav"
                        [class.d-block]="(step === steps.paket_secimi.id) ||(step === steps.arac_secimi.id) || (step === steps.teklif_al.id) || (step === steps.servis_secimi.id)"
                        *ngIf="orderServiceId == 1"
                        [class.active-stepper]="step === steps.paket_secimi.id" >
                            <h3 class="stepper-title  text-center">Paket Seçimi</h3>
                            <span class="gos-stepper "  
                            [ngClass]="{completed: selectedPackageInfo}"
                            [class.act]="step == steps.paket_secimi.id"
                            >
                            <span [ngClass]="{'gos-stepper-hide': selectedPackageInfo != null}" >{{steps.paket_secimi.id}}</span>
                            </span>
                        </div>
                        <div class="stepper-item gos-step-number-item" data-kt-stepper-element="nav"
                        [class.d-block]="(step === steps.paket_secimi.id) || (step === steps.teklif_al.id) "
                        *ngIf="orderServiceId == 1"
                        [class.active-stepper]="step === steps.teklif_al.id" >
                            <h3 class="stepper-title  text-center">Parça Seçimi</h3>
                            <span class="gos-stepper"  
                            [ngClass]="{completed: selectedPieces.length > 0}"
                            [class.act]="step == steps.teklif_al.id"

                            >
                            <span [ngClass]="{'gos-stepper-hide': selectedPieces.length > 0}" >4</span>
                            </span>
                        </div>

                        <div class="stepper-item gos-step-number-item" 
                        *ngIf="orderServiceId == 2"
                        data-kt-stepper-element="nav"
                        [class.d-block]="(step === steps.parca_secimi.id) || (step === steps.servis_secimi.id) || (step === steps.arac_secimi.id) "

                        [class.active-stepper]="step === steps.parca_secimi.id" >
                            <h3 class="stepper-title  text-center">Parça Seçimi</h3>
                            <span class="gos-stepper "  
                            [ngClass]="{completed: selectedPieces.length > 0}"
                            [class.act]="step == steps.parca_secimi.id"

                            >
                            <span [ngClass]="{'gos-stepper-hide': selectedPieces.length > 0}" >3</span>
                            </span>
                        </div>
                        <div class="stepper-item gos-step-number-item d-block"  data-kt-stepper-element="nav"
                        [class.active-stepper]="step === steps.servis_secimi.id"
                        [class.d-block]=" (step === steps.teklif_al.id) || (step === steps.servis_secimi.id) || (step === steps.paket_secimi.id)  || (step == steps.parca_secimi.id) || (step == steps.adres_secimi.id) "
                        >
                            <h3 class="stepper-title text-center">Servis Bilgileri </h3>
                            <span class="gos-stepper "  
                            [ngClass]="{completed: selectedServiceId > 0}"
                            [class.act]="step == steps.servis_secimi.id"
                            >
                           
                            <span [ngClass]="{'gos-stepper-hide': selectedServiceId > 0}" >
                                <span *ngIf="orderServiceId == 2; else srvblg">4</span>
                                <ng-template #srvblg >5</ng-template>
                            </span>
                            </span>
                        </div>

                        <div class="stepper-item gos-step-number-item d-block"  data-kt-stepper-element="nav"
                        [class.active-stepper]="step === steps.adres_secimi.id"
                        [class.d-block]=" (step === steps.adres_secimi.id) || (step === steps.servis_secimi.id) || (step == steps.parca_secimi.id) || (step === steps.faturalandir_mekanik.id) || step === steps.faturalandir.id"
                        >
                            <h3 class="stepper-title text-center">Adres ve Araç Bilgileri </h3>
                            <span class="gos-stepper "  
                            [ngClass]="{completed: invoiceInfo.address_id > 0}"
                            [class.act]="step == steps.adres_secimi.id"
                            >
                           
                            <span [ngClass]="{'gos-stepper-hide': invoiceInfo.address_id > 0}" >
                                <span *ngIf="orderServiceId == 2" >5</span>
                                <span *ngIf="orderServiceId == 1" >6</span>
                            </span>
                            </span>
                        </div>
                       
                        

                        

                        <div class="stepper-item gos-step-number-item" [class.active-stepper]="step === steps.faturalandir.id"  data-kt-stepper-element="nav"
                        *ngIf="orderServiceId == 1"
                        [class.d-block]="  (step === steps.odeme.id) ||   (step === steps.faturalandir.id) || (step === steps.siparisi_tamamla.id) || (step == steps.adres_secimi.id)"
                        >
                            <h3 class="stepper-title  text-center">Teklif Onayı 1</h3>
                            <span class="gos-stepper "  
                            [ngClass]="{completed: selectedPieces.length > 0 && steppers.teklif_onayi_1 == true}"
                            [class.act]="step == steps.faturalandir.id"
                            >
                            <span [ngClass]="{'gos-stepper-hide': steppers.teklif_onayi_1 == true}">7</span>
                            </span>
                            

                        </div>

                        <div class="stepper-item gos-step-number-item" [class.active-stepper]="step === steps.faturalandir_mekanik.id"  data-kt-stepper-element="nav"
                        *ngIf="orderServiceId == 2"

                        [class.d-block]="(step === steps.odeme.id) ||  (step === steps.faturalandir_mekanik.id) || (step === steps.siparisi_tamamla.id) || (step == steps.adres_secimi.id)"
                        >
                            <h3 class="stepper-title  text-center">Teklif Onayı  2
                            </h3>
                            <span class="gos-stepper "  
                            [ngClass]="{completed: steppers.teklif_onayi_2 == true}"
                            [class.act]="step == steps.faturalandir_mekanik.id"
                            >

                                <span [ngClass]="{'gos-stepper-hide': steppers.teklif_onayi_2 == true}">
                                    <span>6</span>
                                </span>
                            </span>
                            
                        </div>

                        <div class="stepper-item gos-step-number-item" [class.active-stepper]="step === steps.odeme.id"  data-kt-stepper-element="nav"
                        [class.d-block]="   (step === steps.faturalandir.id) || (step === steps.odeme.id) || (step == steps.siparisi_tamamla.id)"
                        >
                            <h3 class="stepper-title  text-center">Ödeme</h3>
                            <span class="gos-stepper "  
                            [ngClass]="{completed: isPayed}"
                            [class.act]="step == steps.odeme.id"
                            >
                            <span [ngClass]="{'gos-stepper-hide': steppers.teklif_onayi_1 == true}">8</span>
                            </span>
                            

                        </div>

                        <div class="stepper-item gos-step-number-item" [class.active-stepper]="step === steps.siparisi_tamamla.id"  data-kt-stepper-element="nav"
                        [class.d-block]=" (step === steps.odeme.id) || (step === steps.siparisi_tamamla.id) || (step === steps.faturalandir_mekanik.id)"
                        >
                            <h3 class="stepper-title  text-center">Randevu Durumu</h3>
                            <span class="gos-stepper" 
                            [ngClass]="{completed: steppers.order_status == true}"
                            [class.act]="step == steps.siparisi_tamamla.id"
                            >
                                <span [ngClass]="{'gos-stepper-hide': steppers.order_status == true}">
                                    <span *ngIf="orderServiceId == 2">8</span>
                                    <span *ngIf="orderServiceId == 1">9</span>
                                </span>
                            </span>
                        </div>

                        <div class="stepper-item gos-step-number-item" [class.active-stepper]=" step==steps.mail_teklif_al_basarili.id"  data-kt-stepper-element="nav"
                        [class.d-block]="step==steps.mail_teklif_al_basarili.id"
                        >
                            <h3 class="stepper-title  text-center">Randevu Durumu</h3>
                            <span class="gos-stepper" 
                            [ngClass]="{completed: steppers.order_status == true}"
                            [class.act]="step == steps.mail_teklif_al_basarili.id"
                            >
                                <span [ngClass]="{'gos-stepper-hide': steppers.order_status == true}">
                                    <span >3</span>
                                </span>
                            </span>
                        </div>
                       
                    </div>
                    <!--end::Nav-->
                    <!--begin::Form-->
                    <form class="mx-auto mw-1000px w-100 pb-10" novalidate="novalidate" id="kt_create_account_form">

                        <!--begin::Actions-->
                        <div class="d-flex flex-stack pt-15 mb-10">
                            <!--begin::Wrapper-->
                            <div class="mr-2">
                                <button  type="button" *ngIf="step > 1 && step !== steps.siparisi_tamamla.id && step !== steps.mail_teklif_al_basarili.id" (click)="stepChange(step-1, false)" class="btn btn-lg btn-light-primary me-3">
                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr063.svg-->
                                <span class="svg-icon svg-icon-4 me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black" />
                                        <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black" />
                                    </svg>
                                </span>
                                <!--end::Svg Icon-->Geri</button>
                            </div>
                            <!--end::Wrapper-->
                            <!--begin::Wrapper-->
                            <div *ngIf=" ( step !== steps.siparisi_tamamla.id && step !== steps.mail_ile_teklif_iste.id && step !== steps.mail_teklif_al_basarili.id ) ||  ( step == steps.odeme.id && selectedPaymentTypes != 1 )">
                                
                                <button type="button" class="btn btn-lg btn-primary" data-kt-stepper-action="next" (click)="stepChange(step+1, true)">{{next}}
                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
                        
                                <!--end::Svg Icon--></button>

                                
                            </div>
                            <!--end::Wrapper-->
                        </div>
                        <!--end::Actions-->

                        <!--begin::Step 1-->
                        <div data-kt-stepper-element="content"  [ngClass]="{current: step==steps.hizmet_tipi.id}">
                           

                            <div class="w-100">

                                                           <label class="d-flex align-items-center form-label mb-5">Hizmet Seçiniz
                                    <i class="fas fa-exclamation-circle ms-2 fs-7"
                                        aria-label="Monthly billing will be based on your account plan"
                                        title="Birden fazla seçim yapabilirsiniz."></i>
                                </label>
                                <div class="mb-0">
                                    <label class="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5"
                                        *ngFor="let order of orderTypeList">
                                        <div class="d-flex align-items-center me-2">
                                            <div
                                                class="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input class="form-check-input" name="orderlist" type="radio" value="{{order.id}}"
                                                (change)="orderTypeChange($event)" [checked]="orderServiceId == order.id">
                                            </div>
                                            <span class="symbol symbol-50px me-6">
                                                <span class="symbol-label">
                                                    <i class="{{order.icon}}"></i>
                                                </span>
                                            </span>
                                            <div class="flex-grow-1">
                                                
                                                <div class="fw-bold opacity-50">
                                                    {{order.name}}
                                                    {{order.description}}
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                    
                                  
                                </div>
                            </div>
                        </div>
                        <!--end::Step 1-->

                        <!--begin::Step 2-->
                        <div class="col-6 offset-3" data-kt-stepper-element="content"  [ngClass]="{current: step==steps.login.id}">
                            
                                <ul class="nav nav-tabs nav-line-tabs mb-5 fs-6 d-flex">
                                    <li>
                                        <ul class="nav nav-tabs nav-line-tabs mb-5 fs-6">
                                            <li class="nav-item">
                                                <a class="nav-link" [ngClass]="{active: authTab === 'login'}"
                                                    (click)="authTabChange('login')">Giriş Yap</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" [ngClass]="{active: authTab === 'register'}"
                                                    (click)="authTabChange('register')">Kayıt Ol</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" [ngClass]="{active: authTab === 'forgot'}"
                                                    (click)="authTabChange('forgot')">Şifre Sıfırla</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <app-signin (login)="login($event)" *ngIf="authTab === 'login'"></app-signin>
                                        <app-signup (login)="login($event)" *ngIf="authTab === 'register'"></app-signup>
                                        <app-forgot *ngIf="authTab === 'forgot'"></app-forgot>
                                    </li>
                                </ul>
                                <!--begin::Wrapper-->
                        
                            <!--end::Wrapper-->
                        </div>
                        <!--end::Step 2-->
                        
                        <!--begin::Step 3-->
                        <div data-kt-stepper-element="content"  [ngClass]="{current: step==steps.arac_secimi.id}">
                            
                            <div class="w-100">
                                <ul class="nav nav-tabs nav-line-tabs mb-5 fs-6" >
                                    <li class="nav-item">
                                        <a class="nav-link" [ngClass]="{active: vehicleTab === 'new'}"
                                            (click)="vehicleTabChange('new')">Yeni Araç Ekle</a>
                                    </li>
                                    <li class="nav-item" *ngIf="showOldVehicles == true">
                                        <a class="nav-link" [ngClass]="{active: vehicleTab === 'old'}"
                                            (click)="vehicleTabChange('old')">Kayıtlı Araçlarım </a>
                                    </li>
                                </ul>
                                <!-- kayıtlı araçlar -->
                                <div *ngIf="vehicleTab === 'old'">
                                    <div class="mb-10">
                                        <label class="form-label required">Garaj Seçiniz</label>
                                        <app-shared-selectwo
                                        [lists]="garageList"
                                        [name]="garageName"
                                        [id]="'garageSelect'"
                                        [showAll]="false"
                                        (selectedItem)="selected('garageChage',$event)"></app-shared-selectwo>
                                       
                                    </div>

                                    <label class="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5"
                                        *ngFor="let vehicle of vehicleList">
                                        <div class="d-flex align-items-center me-2">
                                            <div
                                                class="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input class="form-check-input" type="radio" name="garage"
                                                    value="" (click)="chooseVehicle(vehicle.id,vehicle)" [checked]="vehicle.id == vehicleId" />
                                            </div>
                                            <div class="flex-grow-1">
                                                
                                                <div class="fw-bold opacity-50">
                                                    {{vehicle.id}}
                                                    {{vehicle.vehicle_brand.name}}
                                                    {{vehicle.vehicle_model.name}}
                                                    {{vehicle.years.year_id}}
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <!-- yeni araç ekleme -->
                                <div *ngIf="vehicleTab === 'new'">
                                    <div *ngIf="showOldVehicles">
                                        <div class="mb-10" *ngIf="garageList.length == 0; else garage_select">
                                            <label class="form-label required">Garaj İsmi</label>
                                            <input type="text" class="form-control" (change)="setGarageName($event)">
                                        </div>
                                        <ng-template #garage_select>
                                            <div class="mb-10">
                                                <label class="form-label required">Garaj Seçiniz</label>
                                                <app-shared-select
                                                [lists]="garageList"
                                                name="Garaj Seçiniz"
                                                (selected)="selected('garageChage',$event)"></app-shared-select>
                                               
                                            </div>
                                        </ng-template> 
                                    </div>
                                    
                                     
                                    <div class="mb-10">
                                        <label class="form-label required">Araç Markası Seçiniz</label>
                                        <app-shared-selectwo
                                            [name] = "brandName"
                                            [lists] = "vehicleBrands"
                                            [id]="'vehicleBrands'"
                                            (selectedItem)="selectedTwo('brand',$event)">
                                        </app-shared-selectwo> 
                                       
                                    </div>
                                    <div class="mb-10" *ngIf="vehicleModels">
                                        <label class="form-label required">Araç Modeli Seçiniz</label>
                                        <app-shared-selectwo
                                            [name] = "modelName"
                                            [lists] = "vehicleModels"
                                            [id]="'vehicleModels'"
                                            (selectedItem)="selectedTwo('model',$event)"
                                        >
                                        </app-shared-selectwo> 
                                       
                                    </div>
                                    <div class="mb-10" *ngIf="vehicleModelYearList">
                                        <label class="form-label required">Araç Yılı Seçiniz</label>
                                        <app-shared-selectwo 
                                            [lists]="vehicleModelYearList" 
                                            (selectedItem)="selectedTwo('year',$event)" 
                                            [name]="selectedYear" 
                                            [id]="'vehicle-year'"
                                            [fieldName]="'year_id'"
                                            [returnField]="'id'">
                                        </app-shared-selectwo>
                                    </div>
                                    <div class="mb-10" *ngIf="vehicleModelTypes">
                                        <label class="form-label required">Araç Model Tipi Seçiniz</label>
                                        <app-shared-selectwo
                                            [name] = "modelTypeName"
                                            [lists] = "vehicleModelTypes"
                                            [id]="'vehicleModelTypes'"
                                            (selectedItem)="selectedTwo('vehicle_model_types',$event)">
                                        </app-shared-selectwo> 
                                    </div>
                                    
                                    <div class="mb-10 text-center"  *ngIf="vehicleModelTypes && authService.isLogin">
                                        <button class="btn btn-primary" (click)="createIfCarNotExist()" type="submit" >Aracı Kaydet</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end::Step 3-->
                        <!--begin::Step 4-->
                        <div data-kt-stepper-element="content"  [ngClass]="{current: step==steps.servis_secimi.id}">
                            <div class="w-100" #servisSecimiContainer >
                               
                                <!--begin::Content-->
                                <div class="flex-grow-1" style="width: 100% important;">

                                    <div class="card mb-10 d-none" >
                                        <div class="card-body" style="padding:0px">
                                            <label 
                                            style="background: linear-gradient(-45deg, #004422 0%, #99CC11 100%) !important; color: #fff" 
                                            class="btn btn-outline btn-outline-dashed btn-outline-default active d-flex text-start p-6" data-kt-button="true">
                                              
                                                <!--end::Radio button-->
                                                <span class="ms-5" style="width: 100%;">
                                                    <span class="fs-4 fw-bolder mb-1 d-block">Randevu Tarihi Seçiniz</span>
                                                    <input type="datetime-local" min="{{todayWithPipe}}" (change)="selected('serviceDate',$event)" class="form-control form-control-inline input-medium date-picker" placeholder="Bakım Tarihi Seçiniz" >
                                                </span>


                                            </label>
                                        </div>
                                    </div>

                                <app-shared-cities [event]="1"  (city_id)="changeCity($event)"></app-shared-cities>
                                
                                <app-shared-districts [event]="selectedCityId" (district_id)="changeCity($event,'district')"></app-shared-districts>

                                    <div class="row mb-5">
                                        <div class="col-xl-3">
                                            <div class="fs-6 fw-bold">Randevu Tarihi</div>
                                        </div>
                                        <div class="col-xl-9 fv-row fv-plugins-icon-container">
                                            <app-shared-company-date-selector (selected)="setServiceDate($event)" *ngIf="selectedServiceId" [companyId]="selectedServiceId"></app-shared-company-date-selector>

                                        </div>
                                    </div>

                                <div class="mb-0">
                                    <label class="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5"
                                        *ngFor="let item of companyList">
                                        <div class="d-flex align-items-center me-2 w-100">
                                            <div
                                                class="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input class="form-check-input" type="radio" name="services" value="{{item.id}}"
                                                (click)="selectService(item.id)">
                                            </div>
                                            <span class="symbol symbol-50px me-6">
                                                <span class="symbol-label">
                                                    <img 
                                                    *ngIf="item.logo !== null && item.logo !== '' "
                                                    src="https://cdn.destechhasar.com/garantili-oto-servis/{{item.logo}}" 
                                                    onerror="this.src='assets/images/garantili-oto-servis-38-750x450.png'" 
                                                    class="mw-100"  alt="" />

                                                    <img *ngIf="item.logo === null || item.logo === ''"   src="http://cdn.destechhasar.com/garantili-oto-servis/w:150/h:100/gos-no-image.jpeg" alt="" />
    
                                                </span>
                                            </span>
                                            <div class="flex-grow-1">
                                                
                                                <div class="fw-bold">
                                                     <!--begin::Item-->
                                                <div class="d-flex mb-7 img-container">
                                                   
                                                    <!--begin::Section-->
                                                    <div class="d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1">
                                                        <!--begin::Title-->
                                                        <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                                                            
                                                            <span class="text-gray-400 fw-bold fs-7 my-1">
                                                                <span class="fs-6 fw-bolder opacity-80" style="color:#333">{{item.name}}</span>
                                                                <span style="margin-top: 10px; margin-bottom: 10px; display: block;"  *ngIf="item.evalutaion_points != undefined">
                                                                    <i class="fa fa-star me-1 text-warning fs-5" *ngIf="item.evalutaion_points > 0"></i>
                                                                    <i class="fa fa-star me-1 text-warning fs-5" *ngIf="item.evalutaion_points > 1"></i>
                                                                    <i class="fa fa-star me-1 text-warning fs-5" *ngIf="item.evalutaion_points > 2"></i>
                                                                    <i class="fa fa-star me-1 text-warning fs-5" *ngIf="item.evalutaion_points > 3"></i>
                                                                    <i class="fa fa-star me-1 text-warning fs-5" *ngIf="item.evalutaion_points > 4"></i>
                                                                    <span class="text-gray-800 fw-bolder">
                                                                        </span>
                                                                    
                                                                </span>
                                                                    
                                                                <div 
                                                                class="gap-2 mb-2" 
                                                                [ngClass]="{'no-border': item.companys_franchises === undefined}"
                                                                style="margin-top: 10px; margin-bottom: 0px !important;"  
                                                               >
                                                                
                                                                {{item.City?.name}} / {{ item.district?.name }}
                                                            
                                                                    
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <!--end::Title-->
                                                    
                                                    </div>
                                                    <!--end::Section-->
                                                </div>
                                                <!--end::Item-->
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!--end::Step 4-->
                        
                     
                            
                        </div>

                        <!--begin::Step 1-->
                        <div data-kt-stepper-element="content"  [ngClass]="{current: step==steps.adres_secimi.id}">
                            <div class="w-100" >
                               
                                <div class="card card-flush py-4 flex-row-fluid" style="border: 1px solid #f0f0f0;">
                                    <div class="card-header">
                                        <div class="card-title">
                                            <label class="d-flex align-items-center form-label mb-5">Fatura Adresi Seçiniz!
                                                <i class="fas fa-exclamation-circle ms-2 fs-7"
                                                    aria-label="Monthly billing will be based on your account plan"
                                                    title="Birden fazla seçim yapabilirsiniz."></i>
                                            </label>                                        
                                        </div>
                                        <div class="card-tool">
                                            <button class="btn btn-sm btn-primary" *ngIf="!isCompany" (click)="openDrawer('addressAdd')">Yeni Adres Ekle</button>
                                        </div>
                                    </div>
                                    
                                    <div class="card-body pt-0 " *ngIf="!isCompany">                                     
                                        <app-shared-selectwo
                                        [name] = "'Adres Seçiniz'"
                                        [lists] = "addressList"
                                        [id]="'addresslist'"
                                        (selectedItem)="setInvoiceInfo('address',$event)"
                                        [showAll]=false
                                        [fieldName]="'address_name'">
                                        </app-shared-selectwo>
                                    </div>

                                    <div class="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6 mx-10" *ngIf="selectedAddressInfo != undefined && !isCompany">
                                        <!--begin::Details-->
                                        <div class="d-flex flex-column py-2">
                                            <div class="d-flex align-items-center fs-5 fw-bolder mb-5">{{selectedAddressInfo.address_name}}</div>
                                            <div class="fs-6 fw-bold text-gray-600">{{selectedAddressInfo.address}}
                                            <br />{{selectedAddressInfo.district.name}}
                                            <br />{{selectedAddressInfo.city.name}}</div>
                                        </div>
                                        <!--end::Details-->
                                        <!--begin::Actions-->
                                        <div class="d-flex align-items-center py-2">
                                            <button (click)="openDrawer('addressEdit',selectedAddressInfo.id)" class="btn btn-sm btn-light btn-active-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_address">Düzenle</button>
                                        </div>
                                        <!--end::Actions-->
                                    </div>

                                    <div class="card-body pt-0" *ngIf="isCompany">
                                        <div class="row mb-6"  *ngIf="isCompany">
                                            <label class="col-lg-4 col-form-label required fw-bold fs-6">Adres</label>
                                            <div class="col-lg-8 fv-row">  
                                                <div class="fs-6 fw-bold text-gray-600">
                                                    {{userInfo?.company?.address}} {{userInfo?.company.town.name}} / {{userInfo?.company?.district?.name}} / {{userInfo?.company?.city?.name}}
                                                </div> 
                                             </div>
    
                                            </div>
                                    </div>
                                </div>
                                

                                <div class="card card-flush my-10 py-4 flex-row-fluid" style="border: 1px solid #f0f0f0;">
                                    <div class="card-header">
                                        <div class="card-title">
                                            <label class="d-flex align-items-center form-label mb-5">Fatura Bilgileri
                                                <i class="fas fa-exclamation-circle ms-2 fs-7"
                                                    aria-label="Monthly billing will be based on your account plan"
                                                    title="Birden fazla seçim yapabilirsiniz."></i>
                                            </label> 
                                        </div>
                                    </div>
                                    <div class="card-body">
                                     
                                        <div class="row mb-6">
                                            <label class="col-lg-4 col-form-label required fw-bold fs-6">Kullanıcı Bilgileri Fatura Bilgilerim ile Aynı</label>
                                            <div class="col-lg-8 fv-row">
                                                <input type="checkbox" class="form-check-input" checked="checked" (click)="changeInvoiceInfoCheckbox($event)">
                                            </div>
                                           
                                        </div>

                                        <div class="row mb-6"  *ngIf="!isCompany">
                                            <label class="col-lg-4 col-form-label required fw-bold fs-6">Ad Soyad</label>
                                            <div class="col-lg-8">
                                                <div class="row">
                                                    <div class="col-lg-6 fv-row">
                                                        <input type="text" name="name"
                                                            value=" {{invoiceInfo?.invoice_name}}"
                                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            (change)="setInvoiceInfo('invoice_name',$event)"

                                                            placeholder="Ad" />
                                                    </div>
                                                    <div class="col-lg-6 fv-row">
                                                        <input type="text" name="name"
                                                            value=" {{invoiceInfo?.invoice_surname}}"
                                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Soyad"
                                                            (change)="setInvoiceInfo('invoice_surname',$event)"
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-6"  *ngIf="isCompany">
                                            <label class="col-lg-4 col-form-label required fw-bold fs-6">Şirket Ünvanı</label>
                                            <div class="col-lg-8 fv-row">
                                                <input type="text" name="name"
                                                            value=" {{invoiceInfo?.email}}"
                                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Email"
                                                            (change)="setInvoiceInfo('email',$event)"
                                                            [disabled]="(invoiceInfo.is_same == true) || (isCompany)"
                                                            />
                                            </div>
                                        </div>
                                        <div class="row mb-6">
                                            <label class="col-lg-4 col-form-label required fw-bold fs-6">Email</label>
                                            <div class="col-lg-8 fv-row">
                                                <input type="text" name="name"
                                                            value=" {{invoiceInfo?.email}}"
                                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Email"
                                                            (change)="setInvoiceInfo('email',$event)"
                                                            [disabled]="(invoiceInfo.is_same_with_customer == true) || (isCompany)"

                                                            />
                                            </div>
                                        </div>
                                        <div class="row mb-6" *ngIf="!isCompany">
                                            <label class="col-lg-4 col-form-label required fw-bold fs-6">Tc Kimlik No</label>
                                            <div class="col-lg-8 fv-row">
                                                <input type="number" name="identity"
                                                            value="{{invoiceInfo?.identity_no}}"
                                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Tc kimlik no"
                                                            (change)="setInvoiceInfo('identity_no',$event)"
                                                            />
                                            </div>
                                        </div>
                                        <div class="row mb-6"  *ngIf="isCompany">
                                           
                                                <label class="col-lg-4 col-form-label required fw-bold fs-6">Vergi Dairesi</label>
                                                <div class="col-lg-8 fv-row">
                                                    <input type="text" name="identity"
                                                    value="{{invoiceInfo?.tax_administration}}"
                                                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                placeholder="Vergi Dairesi"
                                                                disabled="disabled"
                                                                />
                                                </div>
                                        </div>

                                        <div class="row mb-6" *ngIf="isCompany">
                                            <label class="col-lg-4 col-form-label required fw-bold fs-6">Vergi Numarası</label>
                                            <div class="col-lg-8 fv-row">
                                                <input type="number" name="identity"
                                                            value="{{invoiceInfo?.tax_number}}"
                                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Vergi Numarası"
                                                            (change)="setInvoiceInfo('tax_number',$event)"
                                                            [disabled]="isCompany"
                                                            />
                                            </div>
                                        
                                        </div>
                                      
                                        <div class="row mb-6">
                                            <label class="col-lg-4 col-form-label required fw-bold fs-6">Telefon Numarası {{invoiceInfo.gsmName}} </label>
                                            <div class="col-lg-8 fv-row">
                                                <app-shared-selectwo
                                                    [lists]="gsmList"
                                                    [id]="'gsmList'"
                                                    (selectedItem)="setInvoiceInfo('gsm',$event)" 
                                                    [name]="invoiceInfo.gsmName"
                                                    [showAll]=false
                                                    *ngIf="!isCompany  && invoiceInfo.is_same_with_customer"
                                                    ></app-shared-selectwo>
                                                    <input *ngIf="isCompany" type="text" name="identity"
                                                            value="{{userInfo?.company?.company_tel?.code}}"
                                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Telefon Numarası"
                                                            [disabled]="isCompany"
                                                            />

                                                            <input *ngIf="!isCompany && !invoiceInfo.is_same_with_customer" type="text" name="gsm_num"
                                                            value=""
                                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Telefon Numarası"
                                                            (change)="setInvoiceInfo('new_gsm',$event)"
                                                            />        
                                               
                                             
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>

                                <div class="card card-flush my-10 py-4 flex-row-fluid" style="border: 1px solid #f0f0f0;">
                                    <div class="card-header">
                                        <div class="card-title">
                                            <label class="d-flex align-items-center form-label mb-5">Araç Bilgileri ( {{vehicleInfo?.vehicle_brand?.name}} {{vehicleInfo?.vehicle_model_types?.name}})
                                                <i class="fas fa-exclamation-circle ms-2 fs-7"
                                                    aria-label="Monthly billing will be based on your account plan"
                                                    title="Birden fazla seçim yapabilirsiniz."></i>
                                            </label> 
                                        </div>
                                    </div>
                                    <div class="card-body">
                                       
                                        <div class="row mb-6">
                                            <label class="col-lg-4 col-form-label required fw-bold fs-6">Ruhsat İsim Soyisim </label>
                                            <div class="col-lg-8">
                                                <div class="row">
                                                    <div class="col-lg-6 fv-row">
                                                        <input type="text" name="license_name"
                                                            value=" {{vehicleInfo?.license_name}}"
                                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Ad"
                                                            (change)="setInvoiceInfo('license_name',$event)"  />
                                                    </div>
                                                    <div class="col-lg-6 fv-row">
                                                        <input type="text" name="license_surname"
                                                            value=" {{vehicleInfo?.license_surname}}"
                                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Soyad"
                                                            (change)="setInvoiceInfo('license_surname',$event)" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-6">
                                            <label class="col-lg-4 col-form-label required fw-bold fs-6">PLaka</label>
                                            <div class="col-lg-8 fv-row">
                                                <input type="text" name="plate"
                                                            value=" {{vehicleInfo?.plate}}"
                                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Email"
                                                            (change)="setInvoiceInfo('plate',$event)" />
                                            </div>
                                        </div>
                                        <div class="row mb-6">
                                            <label class="col-lg-4 col-form-label required fw-bold fs-6">Şasi No</label>
                                            <div class="col-lg-8 fv-row">
                                                <input type="text" name="chassis_no"
                                                            value=" {{vehicleInfo?.chassis_no}}"
                                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Şasi No"
                                                            (change)="setInvoiceInfo('chassis_no',$event)" />
                                            </div>
                                        </div>
<!--
                                        <div class="row mb-6 ">
                                            <label class="col-lg-4 col-form-label required fw-bold fs-6">Motor No</label>
                                            <div class="col-lg-8 fv-row">
                                                <input type="text" name="engine_no"
                                                            value=" {{vehicleInfo?.engine_no}}"
                                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Notor No"
                                                            (change)="setInvoiceInfo('engine_no',$event)" />
                                            </div>
                                        </div>-->
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end::Step 1-->

                        <!--begin::Step 5-->
                        <div data-kt-stepper-element="content"  [ngClass]="{current: step==steps.paket_secimi.id}">
                            <div class="w-100">
                                <label class="d-flex align-items-center form-label mb-5">Kilometre Seçiniz
                                    <i class="fas fa-exclamation-circle ms-2 fs-7"
                                        aria-label="Monthly billing will be based on your account plan"
                                        title="Birden fazla seçim yapabilirsiniz."></i>
                                </label>
                                
                                <div class="w-100">
                                    <app-shared-selectwo
                                        [name]="'Kilometre'"
                                        [id]="'maintenance_kms'"
                                        [lists]="maintenanceKmsLists"
                                        (selectedItem)="selectedTwo('changeKms',$event)"
                                        [showAll]="false"
                                    >
                                    </app-shared-selectwo>
                                </div>

                                <label style="margin-top: 50px;" class="d-flex align-items-center form-label mb-5">Paket Seçiniz
                                    <i class="fas fa-exclamation-circle ms-2 fs-7"
                                        aria-label="Monthly billing will be based on your account plan"
                                        title="Birden fazla seçim yapabilirsiniz."></i>
                                </label>

                                <div class="mb-0">
                                    <label class="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5"
                                        *ngFor="let item of packageListFiltered">
                                        <div class="d-flex align-items-center me-2 w-100">
                                            <div
                                                class="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input class="form-check-input" type="radio" name="'package'" value="{{item.id}}"
                                                (change)="setPackageInfo($event,item)" [checked]="selectedPackageInfo && selectedPackageInfo.id == item.id">
                                            </div>
                                            <span class="symbol symbol-50px me-6">
                                                <span class="symbol-label">
                                                    <i class="fa-car fas"></i>
                                                </span>
                                            </span>
                                            <div class="flex-grow-1">
                                                
                                                <div class="fw-bold opacity-50">
                                                    {{item.name}} {{item.id}}
                                                </div>
                                            </div>
                                            <div class="flex-end">
                                                <span
                                            class="fs-6" style="margin-right: 15px;" [innerHtml]="getSmallAndBig(item)"></span>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!--end::Step 5-->
                          <!--begin::Step 6-->
                        <div data-kt-stepper-element="content" [ngClass]="{current: step==steps.teklif_al.id}">
                            <div class="w-100" style="overflow: hidden;
                            overflow-x: scroll;">
                                
                                <label class="d-flex align-items-center form-label mb-5">Parça Seçiniz
                                    <i class="fas fa-exclamation-circle ms-2 fs-7"
                                        aria-label="Monthly billing will be based on your account plan"
                                        title="Birden fazla seçim yapabilirsiniz."></i>
                                </label>
                                <div class="card card-flush py-4 flex-row-fluid" >
                                   
                                    <!--begin::Card body-->
                                    <div class="card-body pt-0 ">
                                        <div class="table-responsive">
                                            <app-shared-maintenance-package
                                            *ngIf="step==steps.teklif_al.id && orderServiceId == 1"
                                            [modelTypeId]="vehicleInfo?.model_type_id"
                                            [vehicleInfo]="vehicleInfo"
                                            [packageId]="selectedPackageInfo?.id"
                                            (returnParts)="setOrderParts($event)"
                                            >

                                            </app-shared-maintenance-package>
                                          
                                        </div>
                                    </div>
                                    <!--end::Card body-->
                                </div>
                            </div>
                        </div>
                        <!--end::Step 6-->

                        <!--begin::Parça seçiniz-->
                        <div data-kt-stepper-element="content" [ngClass]="{current: step==steps.parca_secimi.id}">
                            <div class="w-100">
                                <app-shared-mechanic-repair
                                *ngIf="step==steps.parca_secimi.id && orderServiceId == 2"
                                [modelTypeId]="vehicleInfo?.model_type_id"
                                [vehicleInfo]="vehicleInfo"
                                (returnParts)="setOrderParts($event)"
                                ></app-shared-mechanic-repair>

                            </div>
                        </div>
                        <!--end::Parça seçiniz-->

                         <!--begin::Parça seçiniz-->
                         <div data-kt-stepper-element="content" [ngClass]="{current: step==steps.manuel_parca_secimi.id}">
                            <div class="w-100">
                                
                                <app-shared-customer-manuel-parts
                                *ngIf="step==steps.manuel_parca_secimi.id && orderServiceId == 3"
                                [modelTypeId]="vehicleInfo?.model_type_id"
                                [vehicleInfo]="vehicleInfo"
                                [selectedParts]="selectedPieces"
                                (returnParts)="setOrderParts($event)"
                                >

                                </app-shared-customer-manuel-parts>

                       


                            </div>
                        </div>
                        <!--end::Parça seçiniz-->
                          
                        <div data-kt-stepper-element="content" [ngClass]="{current: step==steps.faturalandir.id}">

                            <!-- begin::Wrapper-->
										<div class="mw-lg-950px mx-auto w-100" style="padding: 25px; border: 1px solid #f0f0f0;" id="pdfTable" #pdfTable>
											<!-- begin::Header-->
											<div class="d-flex justify-content-center flex-column flex-sm-row mb-19">
												<!--end::Logo-->
												<div class="text-sm">
													<!--begin::Logo-->
													<a href="#" class="d-block mw-150px mx-auto">
														<img alt="Logo" src="assets/images/logo-garantiliotoservis.svg" class="w-100" />
													</a>
													<!--end::Logo-->
													<!--begin::Text-->
													<div class="text-sm fw-bold fs-4 text-muted mt-7">
                                                        <div class="text-center">Garantili Satış Sonrası Oto Hiz.A.Ş.</div>
														<div class="text-center">Cevizli Mahallesi Tugay Yolu Caddesi No:59 </div>
														<div class="text-center">Maltepe / İstanbul</div>
													</div>
													<!--end::Text-->
												</div>
											</div>
											<!--end::Header-->
											<!--begin::Body-->
											<div class="pb-12">
												<!--begin::Wrapper-->
                                                
												<div class="d-flex flex-column gap-7 gap-md-10">
													<div style="display: flex; justify-content: space-between;">
                                                     
                                                            <!--begin::Message-->
                                                            <div class="fw-bolder fs-2">Sayın {{userInfo?.name}} {{userInfo?.surname}}
                                                                <span class="fs-6">({{userInfo?.email}})</span>,
                                                                <br />
                                                                <span class="text-muted fs-5">
                                                                    Randevu detayını görüntülemektesiniz. Bizi tercih ettiğiniz için teşekkür ederiz.
                                                                </span>
                                                                <p class="text-muted fw-bold fs-7">({{serviceLastDate}} tarihine kadar geçerlidir)</p>
                                                            </div>
                                                       
                                                            <app-shared-proforma-pdf-generator
                                                                *ngIf="step==steps.faturalandir.id"
                                                                [userInfo]="userInfo"
                                                                [serviceLastDate]="serviceLastDate"
                                                                [todayDate]="todayDate"
                                                                [selectedPackageInfo]="selectedPackageInfo"
                                                                [selectedUncompaignProducts]="selectedUncompaignProducts"
                                                                [subTotal]="subTotal"
                                                                [vat]="vat"
                                                                [discount]="discount"
                                                                [totalAmount]="totalAmount"
                                                            >

                                                            </app-shared-proforma-pdf-generator>
                                                
                                                     
                                                    </div>
													<!--begin::Message-->
													<!--begin::Separator-->
													<div class="separator"></div>
													<!--begin::Separator-->
													<!--begin::Order details-->
													<div class="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bolder">
														<div class="flex-root d-flex flex-column">
															
														</div>
														<div class="flex-root d-flex flex-column">
															
														</div>
														<div class="flex-root d-flex flex-column">
															
                                                            <span class="text-muted " style="align-self: flex-end;">Tarih</span>
															<span class="fs-5" style="align-self: flex-end;">{{todayDate}}</span>
														</div>
													
													</div>
													<div class="d-flex justify-content-between flex-column">
                                                        <h3 class="fw-bolder fs-2">{{selectedPackageInfo?.name}}</h3>
														<!--begin::Table-->
														<div class="table-responsive border-bottom mb-9">
															<table class="table align-middle table-row-dashed fs-6 gy-5 mb-0">
																<thead>
																	<tr class="border-bottom fs-6 fw-bolder text-muted">
																		<th class="min-w-175px pb-2">Yedek Parça Adı</th>
																		<th class="min-w-175px pb-2">Parça Türü</th>
																		<th class="min-w-80px text-end pb-2">Adet</th>
																		<th class="min-w-80px text-end pb-2">Birim Fiyat1</th>
																		<th class="min-w-100px text-end pb-2">Tutar</th>
																	</tr>
																</thead>
																<tbody class="fw-bold text-gray-600">
																	<!--begin::Products-->
																	<tr *ngFor="let item of selectedUncompaignProducts" >
																		<!--begin::Product-->
																		<td>
																			<div class="d-flex align-items-center">
																				<!--begin::Thumbnail-->
                                                                                
																			
                                                                                <span class="symbol symbol-50px me-6">
                                                                                    <span class="symbol-label">
                                                                                        <i class="fa-car fas"></i>
                                                                                    </span>
                                                                                </span>
																				<!--end::Thumbnail-->
																				<!--begin::Title-->
																				<div class="ms-5">
																					<div class="fw-bolder">{{item.selected_equivalent_name}}</div>
																					<div style="display: none;" class="fs-7 text-muted">{{item.part_code}}</div>
																				</div>
																				<!--end::Title-->
																			</div>
																		</td>
																		<!--end::Product-->

                                                                        <td>{{item.selected_equivalent_part_type_name}} </td>
																		
																		<!--begin::Quantity-->
																		<td class="text-end">{{item.part_unit}} {{ item.part_unit_name }}</td>

                                                                        <td class="text-end">{{item.part_value | number:'1.0-2'}} &#8378;</td>
																		<!--end::Quantity-->
																		<!--begin::Total-->
																		<td class="text-end">{{item.total_price | number:'1.0-2'}} &#8378;</td>
                                                                        
																		<!--end::Total-->
																	</tr>
																	<!--end::Products-->

                                                                    <tr *ngFor="let item of selectedCompaignProducts" >
																		<!--begin::Product-->
																		<td style="background-color: #96bc33 !important;
                                                                        color:#015029;
                                                                        padding-left: 10px;
                                                                        border-top-left-radius: 10px;
                                                                        border-bottom-left-radius: 10px;
                                                                        margin-top: 10px;">
																			<div class="d-flex align-items-center">
																				<!--begin::Thumbnail-->
                                                                                
																			
                                                                                <span class="symbol symbol-50px me-6">
                                                                                    <span class="symbol-label">
                                                                                        <i class="fa-car fas"></i>
                                                                                    </span>
                                                                                </span>
																				<!--end::Thumbnail-->
																				<!--begin::Title-->
																				<div class="ms-5">
																					<div class="fw-bolder">{{item.name}}</div>
																					<div style="display: none;" class="fs-7 text-muted">{{item.part_code}}</div>
																				</div>
																				<!--end::Title-->
																			</div>
																		</td>
																		<!--end::Product-->

                                                                        <td style="background-color: #96bc33 !important;
                                                                        color:#015029;">{{item.partTypeName}}</td>
																		
																		<!--begin::Quantity-->
																		<td class="text-end" style="background-color: #96bc33 !important;
                                                                        color:#015029;">{{item.part_unit}}</td>

                                                                        <td class="text-end" style="background-color: #96bc33 !important;
                                                                        color:#015029;">{{item.price | number:'1.0-2'}} &#8378;</td>
																		<!--end::Quantity-->
																		<!--begin::Total-->
																		<td class="text-end" style="background-color: #96bc33 !important;
                                                                        color:#015029;
                                                                        padding-right: 10px;
                                                                        border-top-right-radius: 10px;
                                                                        border-bottom-right-radius: 10px;
                                                                        margin-top: 10px;">{{item.price_with_unit | number:'1.0-2'}} &#8378;</td>
                                                                        
																		<!--end::Total-->
																	</tr>

																	<!--begin::Subtotal-->
																	<tr>
																		<td colspan="4" class="text-end">Toplam</td>
																		<td class="text-end">{{subTotal | number:'1.0-2'}} &#8378;</td>
																	</tr>
																	<!--end::Subtotal-->
                                                                 
																	<!--begin::VAT-->
																	<tr>
																		<td colspan="4" class="text-end">Kdv (18%)</td>
																		<td class="text-end">{{ vat | number:'1.0-2'}} &#8378;</td>
																	</tr>
																	<!--end::VAT-->
																	<!--begin::Shipping-->
																	<tr>
																		<td colspan="4" class="text-end">İndirim</td>
																		<td class="text-end">{{(discount | number:'1.0-2')}} &#8378;</td>
																	</tr>
																	<!--end::Shipping-->
																	<!--begin::Grand total-->
																	<tr>
																		<td colspan="4" class="fs-3 text-dark fw-bolder text-end">Genel Toplam</td>
																		<td class="text-dark fs-3 fw-boldest text-end">{{totalAmount | number:'1.0-2'}} &#8378;</td>
																	</tr>
																	<!--end::Grand total-->
																</tbody>
															</table>
														</div>
														<!--end::Table-->
													</div>
													<!--end:Order summary-->

                                                    
												</div>
												<!--end::Wrapper-->
											</div>
											<!--end::Body-->
										
										</div>
										<!-- end::Wrapper-->
                        </div>

                        <div data-kt-stepper-element="content" [ngClass]="{current: step==steps.faturalandir_mekanik.id}">
    
                            <!-- begin::Wrapper-->
										<div class="mw-lg-950px mx-auto w-100" style="padding: 25px; border: 1px solid #f0f0f0;">
											<!-- begin::Header-->
											<div class="d-flex justify-content-center flex-column flex-sm-row mb-19">
												<!--end::Logo-->
												<div class="text-sm">
													<!--begin::Logo-->
													<a href="#" class="d-block mw-150px mx-auto">
														<img alt="Logo" src="assets/images/logo-garantiliotoservis.svg" class="w-100" />
													</a>
													<!--end::Logo-->
													<!--begin::Text-->
													<div class="text-sm fw-bold fs-4 text-muted mt-7">
                                                        <div class="text-center">Garantili Satış Sonrası Oto Hiz.A.Ş.</div>
														<div class="text-center">Cevizli Mahallesi Tugay Yolu Caddesi No:59 </div>
														<div class="text-center">Maltepe / İstanbul</div>
													</div>
													<!--end::Text-->
												</div>
											</div>
											<!--end::Header-->
											<!--begin::Body-->
											<div class="pb-12">
												<!--begin::Wrapper-->
												<div class="d-flex flex-column gap-7 gap-md-10">
													<!--begin::Message-->
													<div class="fw-bolder fs-2">Sayın {{userInfo?.name}} {{userInfo?.surname}}
													<span class="fs-6">({{userInfo?.email}})</span>,
													<br />
													<span class="text-muted fs-5">
                                                        Randevu detayını görüntülemektesiniz. Bizi tercih ettiğiniz için teşekkür ederiz.
                                                    </span>
                                                    <p class="text-muted fw-bold fs-7">({{serviceLastDate}} tarihine kadar geçerlidir)</p>
                                                </div>
													<!--begin::Message-->
													<!--begin::Separator-->
													<div class="separator"></div>
													<!--begin::Separator-->
													<!--begin::Order details-->
													<div class="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bolder">
														<div class="flex-root d-flex flex-column">
															
														</div>
														<div class="flex-root d-flex flex-column">
															
														</div>
														<div class="flex-root d-flex flex-column">
															
                                                            <span class="text-muted " style="align-self: flex-end;">Tarih</span>
															<span class="fs-5" style="align-self: flex-end;">{{todayDate}}</span>
														</div>
													
													</div>
													<div class="d-flex justify-content-between flex-column">
                                                        <h3 class="fw-bolder fs-2">{{selectedPackageInfo?.name}}</h3>
														<!--begin::Table-->
														<div class="table-responsive border-bottom mb-9">
															<table class="table align-middle table-row-dashed fs-6 gy-5 mb-0">
																<thead>
																	<tr class="border-bottom fs-6 fw-bolder text-muted">
																		<th class="min-w-175px pb-2">Yedek Parça Adı</th>
																		<th class="min-w-175px pb-2">Parça Türü</th>
																		<th class="min-w-80px text-end pb-2">Adet</th>
																		<th class="min-w-80px text-end pb-2">Birim Fiyat2</th>
																		<th class="min-w-100px text-end pb-2">Tutar</th>
																	</tr>
																</thead>
																<tbody class="fw-bold text-gray-600">
																	<!--begin::Products-->
																	<tr *ngFor="let item of selectedPiecesWithIndex" >
																		<!--begin::Product-->
																		<td>
																			<div class="d-flex align-items-center">
																				<!--begin::Thumbnail-->
                                                                                
																			
                                                                                <span class="symbol symbol-50px me-6">
                                                                                    <span class="symbol-label">
                                                                                        <i class="fa-car fas"></i>
                                                                                    </span>
                                                                                </span>
																				<!--end::Thumbnail-->
																				<!--begin::Title-->
																				<div class="ms-5">
																					<div class="fw-bolder">{{item.selected_equivalent_name }}</div>
																					<div class="fs-7 text-muted">{{item.part_code}}</div>
																				</div>
																				<!--end::Title-->
																			</div>
																		</td>
																		<!--end::Product-->
                                                                        <td>{{item.selected_equivalent_part_type_name}} </td>
																		
																		<!--begin::Quantity-->
																		<td class="text-end">{{item.part_unit}} {{ item.part_unit_name }}</td>

                                                                        <td class="text-end">{{item.part_value | number:'1.0-2'}} &#8378;</td>
																		<!--end::Quantity-->
																		<!--begin::Total-->
																		<td class="text-end">{{item.total_price | number:'1.0-2'}} &#8378;</td>
                                                                        
																		<!--end::Total-->
																	</tr>
																	<!--end::Products-->

																	<!--begin::Subtotal-->
																	<tr>
																		<td colspan="4" class="text-end">Toplam</td>
																		<td class="text-end">{{subTotal | number:'1.0-2'}} &#8378;</td>
																	</tr>
																	<!--end::Subtotal-->
                                                                 
																	<!--begin::VAT-->
																	<tr>
																		<td colspan="4" class="text-end">Kdv (18%)</td>
																		<td class="text-end">{{ vat | number:'1.0-2'}} &#8378;</td>
																	</tr>
																	<!--end::VAT-->
																	<!--begin::Shipping-->
																	<tr>
																		<td colspan="4" class="text-end">İndirim</td>
																		<td class="text-end">{{(discount | number:'1.0-2')}} &#8378;</td>
																	</tr>
																	<!--end::Shipping-->
																	<!--begin::Grand total-->
																	<tr>
																		<td colspan="4" class="fs-3 text-dark fw-bolder text-end">Genel Toplam</td>
																		<td class="text-dark fs-3 fw-boldest text-end">{{totalAmount | number:'1.0-2'}} &#8378;</td>
																	</tr>
																	<!--end::Grand total-->
																</tbody>
															</table>
														</div>
														<!--end::Table-->
													</div>
													<!--end:Order summary-->
												</div>
												<!--end::Wrapper-->
											</div>
											<!--end::Body-->
										
										</div>
										<!-- end::Wrapper-->
                        </div>
                        <!--end::Content-->

                        <div data-kt-stepper-element="content" [ngClass]="{current: step==steps.faturalandir_manuel_parca.id}">
    
                            <!-- begin::Wrapper-->
										<div class="mw-lg-950px mx-auto w-100" style="padding: 25px; border: 1px solid #f0f0f0;">
											<!-- begin::Header-->
											<div class="d-flex justify-content-center flex-column flex-sm-row mb-19">
												<!--end::Logo-->
												<div class="text-sm">
													<!--begin::Logo-->
													<a href="#" class="d-block mw-150px mx-auto">
														<img alt="Logo" src="assets/images/logo-garantiliotoservis.svg" class="w-100" />
													</a>
													<!--end::Logo-->
													<!--begin::Text-->
													<div class="text-sm fw-bold fs-4 text-muted mt-7">
                                                        <div class="text-center">Garantili Satış Sonrası Oto Hiz.A.Ş.</div>
														<div class="text-center">Cevizli Mahallesi Tugay Yolu Caddesi No:59 </div>
														<div class="text-center">Maltepe / İstanbul</div>
													</div>
													<!--end::Text-->
												</div>
											</div>
											<!--end::Header-->
											<!--begin::Body-->
											<div class="pb-12">
												<!--begin::Wrapper-->
												<div class="d-flex flex-column gap-7 gap-md-10">
													<!--begin::Message-->
													<div class="fw-bolder fs-2">Sayın {{userInfo?.name}} {{userInfo?.surname}}
													<span class="fs-6">({{userInfo?.email}})</span>,
													<br />
													<span class="text-muted fs-5">
                                                        Randevu detayını görüntülemektesiniz. Bizi tercih ettiğiniz için teşekkür ederiz.
                                                    </span>
                                                    <p class="text-muted fw-bold fs-7">({{serviceLastDate}} tarihine kadar geçerlidir)</p>
                                                </div>
													<!--begin::Message-->
													<!--begin::Separator-->
													<div class="separator"></div>
													<!--begin::Separator-->
													<!--begin::Order details-->
													<div class="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bolder">
														<div class="flex-root d-flex flex-column">
															
														</div>
														<div class="flex-root d-flex flex-column">
															
														</div>
														<div class="flex-root d-flex flex-column">
															
                                                            <span class="text-muted " style="align-self: flex-end;">Tarih</span>
															<span class="fs-5" style="align-self: flex-end;">{{todayDate}}</span>
														</div>
													
													</div>
													<div class="d-flex justify-content-between flex-column">
                                                        <h3 class="fw-bolder fs-2">{{selectedPackageInfo?.name}}</h3>
														<!--begin::Table-->
														<div class="table-responsive border-bottom mb-9">
															<table class="table align-middle table-row-dashed fs-6 gy-5 mb-0">
																<thead>
																	<tr class="border-bottom fs-6 fw-bolder text-muted">
																		<th class="min-w-175px pb-2">Parça Adı</th>
																		<th class="min-w-175px pb-2">Parça Türü</th>
																		<th class="min-w-80px  pb-2">Adet / Fiyat</th>
																		
																	</tr>
																</thead>
																<tbody class="fw-bold text-gray-600">
																	<!--begin::Products-->
																	<tr *ngFor="let item of selectedPiecesWithIndex" >
																		<!--begin::Product-->
																		<td>
																			<div class="d-flex align-items-center">
																				<!--begin::Thumbnail-->
                                                                                
																			
                                                                                <span class="symbol symbol-50px me-6">
                                                                                    <span class="symbol-label">
                                                                                        <i class="fa-car fas"></i>
                                                                                    </span>
                                                                                </span>
																				<!--end::Thumbnail-->
																				<!--begin::Title-->
																				<div class="ms-5">
																					<div class="fw-bolder">{{item.selectedGosPart_name }}</div>
																				</div>
																				<!--end::Title-->
																			</div>
																		</td>
																		<!--end::Product-->
                                                                        <td>{{item.selectedGosPart_groupName}} </td>
																		
																		<!--begin::Quantity-->
																		<td class="fs-7 text-muted">Adet ve fiyat bilgileri servis tarafından verilecektir.</td>

                                                                        
																	</tr>
																	<!--end::Products-->

																	<!--begin::Subtotal-->
																	
																</tbody>
															</table>
														</div>
														<!--end::Table-->
													</div>
													<!--end:Order summary-->
												</div>
												<!--end::Wrapper-->
											</div>
											<!--end::Body-->
										
										</div>
										<!-- end::Wrapper-->
                        </div>

                        
                        <div data-kt-stepper-element="content" [ngClass]="{current: step==steps.odeme.id}">
                         
                           

                            <div class="mx-5 mx-xl-auto my-7">


                                <div class="fv-row mb-10">
                                    <!--begin::Label-->
                                    <label class="fs-6 fw-bold mb-2">Ödeme Türü
                                    <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Select a discount type that will be applied to this product" aria-label="Select a discount type that will be applied to this product"></i></label>
                                    <!--End::Label-->
                                    <!--begin::Row-->
                                    <div class="row row-cols-1 row-cols-md-3 row-cols-lg-1 row-cols-xl-3 g-9" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button='true']">
                                        <!--begin::Col-->
                                        <div class="col" *ngFor="let item of paymentTypes">
                                            <!--begin::Option-->
                                            <label class="btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 " [ngClass]="{active: selectedPaymentTypes == item.id}" >
                                                <!--begin::Radio-->
                                                <span class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                                    <input class="form-check-input" (click)="changePaymentType(item.id)" [checked]="item.id == selectedPaymentTypes" type="radio" name="discount_option" value="{{item.id}}">
                                                </span>
                                                <!--end::Radio-->
                                                <!--begin::Info-->
                                                <span class="ms-5">
                                                    <span class="fs-4 fw-bolder text-gray-800 d-block">{{item.name}}</span>
                                                </span>
                                                <!--end::Info-->
                                            </label>
                                            <!--end::Option-->
                                        </div>
                                        <!--end::Col-->
                                       
                                    </div>
                                    <!--end::Row-->
                                </div>

                                
                                <div *ngIf="selectedPaymentTypes == 1">
                                    <div class="alert alert-danger" *ngIf="this.subOrderCards?.pos_process_status_id == 3">
                                        {{subOrderCards?.pos_message}}
                                    </div>
                                <form  #paymentForm method="post" action="https://test.inter-vpos.com.tr/mpi/Default.aspx" target="_blank" >
                                    <!--begin::Input group-->
                                    <div class="d-flex flex-column mb-7 fv-row">
                                        <!--begin::Label-->
                                        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                                            <span class="required">ad Soyad</span>
                                            <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Specify a card holder's name"></i>
                                        </label>
                                        <!--end::Label-->
                                        <input type="text" class="form-control form-control-solid" placeholder="" name="card_name" value="Max Doe" />
                                    </div>
                                    <!--end::Input group-->
                                    <!--begin::Input group-->
                                    <div class="d-flex flex-column mb-7 fv-row">
                                        <!--begin::Label-->
                                        <label class="required fs-6 fw-bold form-label mb-2">Kart Numarası  {{momentOrderId}}</label>
                                        <!--end::Label-->
                                        <!--begin::Input wrapper-->
                                        <div class="position-relative">
                                            <!--begin::Input-->
                                            <input type="text" class="form-control form-control-solid" placeholder="Enter card number" name="Pan"  value="4459882705284023" />
                                            <!--end::Input-->
                                            <!--begin::Card logos-->
                                            <div class="position-absolute translate-middle-y top-50 end-0 me-5">
                                                <img src="assets/media/svg/card-logos/visa.svg" alt="" class="h-25px" />
                                                <img src="assets/media/svg/card-logos/mastercard.svg" alt="" class="h-25px" />
                                                <img src="assets/media/svg/card-logos/american-express.svg" alt="" class="h-25px" />
                                            </div>
                                            <!--end::Card logos-->
                                        </div>
                                        <!--end::Input wrapper-->
                                    </div>
                                    <!--end::Input group-->
                                    <!--begin::Input group-->
                                    <div class="d-flex flex-column mb-7 fv-row">
                                        <!--begin::Label-->
                                        <label class="required fs-6 fw-bold form-label mb-2">Kart Türü</label>
                                        <!--end::Label-->
                                        <!--begin::Input wrapper-->
                                        <div class="position-relative">
                                            <!--begin::Input-->
                                            <select name="CardType" class="form-control">
                                                <option value="0">Visa</option>
                                                <option selected value="1">MasterCard</option>
                                            </select>                                        <!--end::Input-->
                                            <!--begin::Card logos-->
                                           
                                            <!--end::Card logos-->
                                        </div>
                                        <!--end::Input wrapper-->
                                    </div>
                                    <!--end::Input group-->
                                    <!--begin::Input group-->
                                    <div class="row mb-10">
                                        <!--begin::Col-->
                                        <div class="col-md-8 fv-row">
                                            <!--begin::Label-->
                                            <label class="required fs-6 fw-bold form-label mb-2">Son Kullanma Tarihi (MMYY)</label>
                                            <!--end::Label-->
                                            <!--begin::Row-->
                                            <div class="row fv-row">
                                                <!--begin::Col-->
                                                <div class="col-6">
                                                    <input class="form-control" type="text" name="Expiry" value="1122" >
                                                   
                                                </div>
                                                <!--end::Col-->
                                               
                                            </div>
                                            <!--end::Row-->
                                        </div>
                                        <!--end::Col-->
                                        <!--begin::Col-->
                                        <div class="col-md-4 fv-row">
                                            <!--begin::Label-->
                                            <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                                                <span class="required">CVV</span>
                                                <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Enter a card CVV code"></i>
                                            </label>
                                            <!--end::Label-->
                                            <!--begin::Input wrapper-->
                                            <div class="position-relative">
                                                <!--begin::Input-->
                                                <input type="text" class="form-control form-control-solid" value="456" minlength="3"  maxlength="4" placeholder="CVV" name="Cvv2" />
                                                <!--end::Input-->
                                                <!--begin::CVV icon-->
                                                <div class="position-absolute translate-middle-y top-50 end-0 me-3">
                                                    <!--begin::Svg Icon | path: icons/duotune/finance/fin002.svg-->
                                                    <span class="svg-icon svg-icon-2hx">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M22 7H2V11H22V7Z" fill="black" />
                                                            <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                    <!--end::Svg Icon-->
                                                </div>
                                                <!--end::CVV icon-->
                                            </div>
                                            <!--end::Input wrapper-->
                                        </div>
                                        <!--end::Col-->
                                    </div>
                                    <!--end::Input group-->
                                    <!--begin::Input group-->
                                    <div class="d-flex flex-stack">
                                        <!--begin::Label-->
                                        <div class="me-5 d-none">
                                            <label class="fs-6 fw-bold form-label">Save Card for further billing?</label>
                                            <div class="fs-7 fw-bold text-muted">If you need more info, please check budget planning</div>
                                        </div>
                                        <!--end::Label-->
                                        <!--begin::Switch-->
                                        <label class="d-none form-check form-switch form-check-custom form-check-solid">
                                            <input class="form-check-input" type="checkbox" value="1" checked="checked" />
                                            <span class="form-check-label fw-bold text-muted">Save Card</span>
                                        </label>
                                        <!--end::Switch-->
                                    </div>
                                    <!--end::Input group-->
                                    <!--begin::Actions-->
                                    <div class="text-center pt-15">
                                        <button type="submit" id="kt_modal_new_card_submit" class="btn btn-primary" (click)="paymentProcess()">
                                            <span class="indicator-label">Ödeme Yap</span>
                                            <span class="indicator-progress">Lütfen Bekleyiniz...
                                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                        </button>
                                    </div>
    
                                    OREDR CARDI ID {{orderCardId}}
                                    <!--end::Actions-->
                                    <input type="hidden" name="ShopCode" value="3123" />
                                    <input type="hidden" name="PurchAmount" value="{{totalAmount}}" />
                                    <input type="hidden" name="Currency" value="949" />
                                    <input type="hidden" name="OrderId" value="{{momentOrderId}}" />
                                    <input type="hidden" name="OkUrl" value="https://api.garantiliotoservis.com/payment" />
                                    <input type="hidden" name="FailUrl" value="https://api.garantiliotoservis.com/payment" />
                                    <input type="hidden" name="Rnd" value="{{rnd}}" />
                                    <input type="hidden" name="Hash" value="{{hash}}" />
                                    <input type="hidden" name="TxnType" value="Auth" />
                                    <input type="hidden" name="InstallmentCount" value="" />
                                    <input type="hidden" name="SecureType" value="3DPay" />
                                    <input type="hidden" name="Lang" value="tr" />
                                    <input type='hidden' name="Version3D" value="2.0" />
    
    
                                </form>
                                </div>

                               
                        </div>
                        </div>
                         <div data-kt-stepper-element="content" [ngClass]="{current: step==steps.siparisi_tamamla.id}">
                            <div class="w-100">
                                <div class="d-flex justify-content-center flex-column flex-sm-row mb-10">
                                    <!--end::Logo-->
                                    <div class="text-sm">
                                        <!--begin::Logo-->
                                        <a href="#" class="d-block mw-150px mx-auto">
                                            <img alt="Logo" src="assets/images/logo-garantiliotoservis.svg" class="w-100" />
                                        </a>
                                        <!--end::Logo-->
                                       
                                    </div>
                                </div>
                                <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                                    <!--begin::Icon-->
                                    <!--begin::Svg Icon | path: icons/duotune/art/art006.svg-->
                                    <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                        <i class="far fa-check-circle" style="font-size: 40px; color: #96bc33; padding: 10px;"></i>
                                    </span>
                                    <!--end::Svg Icon-->
                                    <!--end::Icon-->
                                    <!--begin::Wrapper-->
                                    <div class="d-flex flex-stack flex-grow-1">
                                        <!--begin::Content-->
                                        <div class="fw-bold">
                                            <div class="fs-6 text-gray-700">Randevunuz oluşturuldu!
                                            <a [routerLink]="['/customer/orders']"   class="fw-bolder">Randevularım</a> bölümünden takip edebilirsiniz.</div>
                                        </div>
                                        <!--end::Content-->
                                    </div>
                                    <!--end::Wrapper-->
                                </div>
                               
                            </div>
                        </div>


                        <div data-kt-stepper-element="content" [ngClass]="{current: step==steps.mail_teklif_al_basarili.id}">
                            <div class="w-100">
                                <div class="d-flex justify-content-center flex-column flex-sm-row mb-10">
                                    <!--end::Logo-->
                                    <div class="text-sm">
                                        <!--begin::Logo-->
                                        <a href="#" class="d-block mw-150px mx-auto">
                                            <img alt="Logo" src="assets/images/logo-garantiliotoservis.svg" class="w-100" />
                                        </a>
                                        <!--end::Logo-->
                                       
                                    </div>
                                </div>
                                <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                                    <!--begin::Icon-->
                                    <!--begin::Svg Icon | path: icons/duotune/art/art006.svg-->
                                    <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                        <i class="far fa-check-circle" style="font-size: 40px; color: #96bc33; padding: 10px;"></i>
                                    </span>
                                    <!--end::Svg Icon-->
                                    <!--end::Icon-->
                                    <!--begin::Wrapper-->
                                    <div class="d-flex flex-stack flex-grow-1">
                                        <!--begin::Content-->
                                        <div class="fw-bold">
                                            <div class="fs-6 text-gray-700">Bakım Teklif İsteğiniz Alındı.<br />
                                                En kısa sürede size dönüş sağlayacağız. Bizi tercih ettiğiniz için teşekkür ederiz.
                                            </div>
                                        </div>
                                        <!--end::Content-->
                                    </div>
                                    <!--end::Wrapper-->
                                </div>
                               
                            </div>
                        </div>

                        <!--begin::Actions-->
                        <div class="d-flex flex-stack pt-15 mb-10">
                            <!--begin::Wrapper-->
                            <div class="mr-2">
                                <button  type="button" *ngIf="step > 1 && step !== steps.siparisi_tamamla.id && step !== steps.mail_teklif_al_basarili.id" (click)="stepChange(step-1, false)" class="btn btn-lg btn-light-primary me-3">
                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr063.svg-->
                                <span class="svg-icon svg-icon-4 me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black" />
                                        <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black" />
                                    </svg>
                                </span>
                                <!--end::Svg Icon-->Geri</button>
                            </div>
                            <!--end::Wrapper-->
                            <!--begin::Wrapper-->
                            <div *ngIf=" (step !== steps.siparisi_tamamla.id  && step !== steps.mail_ile_teklif_iste.id && step !== steps.mail_teklif_al_basarili.id) || (step == steps.odeme.id && selectedPaymentTypes != 1 )">
                          
                                <button type="button" class="btn btn-lg btn-primary" [disabled]="step == steps.odeme.id && selectedPaymentTypes == 1" data-kt-stepper-action="next" (click)="stepChange(step+1, true)">{{next}} 
                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
                        
                                <!--end::Svg Icon--></button>

                                
                            </div>
                            <!--end::Wrapper-->
                        </div>
                        <!--end::Actions-->
                        
                    </form>
                    <!--end::Form-->
                </div>
                <!--end::Stepper-->
            </div>
            <!--end::Card body-->
        </div>
        <!--end::Card-->
    </div>
    <!--end::Container-->
</div>
<!--end::Post-->

<app-shared-drawer [show]="showDrawer" [title]="drawerTitle" (toggleDrawer)="toggleDrawer()">
    <app-offer-address-add
        [countryInputId]="'addressAddCountry'"
        [cityInputId]="'addressAddCity'"
        [districtInputId]="'addressAddDistrict'"
        [townInputId]="'addressAddTown'"
    (reloadList)="reloadList($event)" *ngIf="drawerPage === 'addressAdd'"></app-offer-address-add>
    <app-offer-address-edit [addressId]="selectedAddressId" (reloadList)="reloadList($event)" *ngIf="drawerPage === 'addressEdit'"></app-offer-address-edit>
    <app-offer-gsm-add (gsmResult)="reloadList($event)" *ngIf="drawerPage === 'gsmAdd'"></app-offer-gsm-add>
</app-shared-drawer>