import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ORDER_UPDATE_ADDRESS } from 'src/app/graphql/order.graphql';
import { User, USER_GET_ID, USER_UPDATE, USER_UPDATE_IDENTITY } from 'src/app/graphql/user.graphql';
import { UserAddress, USER_ADDRESS_ALL, USER_ADDRESS_CREATE } from 'src/app/graphql/user_address.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-shared-user-address',
  templateUrl: './user-address.component.html',
  styles: [
  ],
  providers: [USER_ADDRESS_ALL,USER_ADDRESS_CREATE,USER_UPDATE_IDENTITY,ORDER_UPDATE_ADDRESS]
})
export class UserAddressComponent implements OnInit {

  @Input() userId: Number | undefined;
  @Input() selectedAddressId: bigint | undefined;
  @Input() orderId: Number | undefined;
  @Input() stretchHeight: number | undefined;
  @Output() refreshInfo = new EventEmitter<boolean>();
  public userAddressList: UserAddress[] | undefined;
  public selectedCountryId: number | null = null;
  public selectedCityId: Number | null  = null;
  public selectedDistrictId: number | null = null;
  public selectedTownId: number | null = null;
  public resetForm: boolean = false;
  public selectedAddress : any;
  public showCreateAddress: boolean = false;
  public userInfo: User | undefined;
  public showIdentityInput = false;
  public addHeight:boolean | undefined;

  formGroup = new FormGroup({
    address_name: new FormControl('', [Validators.required]),
    country_id: new FormControl(null, [Validators.required]),
    city_id: new FormControl(null, [Validators.required]),
    district_id: new FormControl(null, [Validators.required]),
    town_id: new FormControl(null, [Validators.required]),
    address: new FormControl(null, [Validators.required]),
    active: new FormControl(true),
    user_id: new FormControl(null, [Validators.required])
  })
  
  identityFormGroup = new FormGroup({
    identity_no: new FormControl(null, [Validators.required])
  });

  constructor(
    private getUserAddressList: USER_ADDRESS_ALL,
    private alertifyService: AlertifyService,
    private createAddress: USER_ADDRESS_CREATE,
    private getUserInfo: USER_GET_ID,
    private updateUserIdentity: USER_UPDATE_IDENTITY,
    private updateOrderAddress: ORDER_UPDATE_ADDRESS
  ) { 
alert(this.stretchHeight);
    this.addHeight = (this.stretchHeight == undefined || this.stretchHeight == 1) ? true : false;
  
  }

  ngOnInit(): void {

    

  }

  ngOnChanges(){

    this.getUserInfo.watch({id:this.userId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
      if(response.errors){
        this.alertifyService.error(response.errors[0].message);
      }

      if(response.data){
        this.userInfo = response.data.users_by_pk;

        if(this.userInfo == null){
          this.alertifyService.error("kullanıcı bilgilerine ulaşılamadı");
        }else{
          if(this.userInfo.identity_no == null){
            this.showIdentityInput = true;

            
          }
          this.identityFormGroup.patchValue({identity_no: this.userInfo.identity_no});

          this.setUserAddressList();
        }
      }
    })

    if(this.selectedAddressId){
      this.setSelectedAddress(this.selectedAddressId);
    }
    this.formGroup.patchValue({user_id:this.userId});
  }

  

  setUserAddressList(){
    this.getUserAddressList.watch({where:{user_id:{_eq:this.userId}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response=>{
      if(response.errors){
        this.alertifyService.error(response.errors[0].message);
      }

      if(response.data){
        this.userAddressList = response.data.user_address;
      }
    });
  }

  changeGeoZone(type:String,$event:any){
    
    switch(type){
      case "country": 
        this.selectedCountryId = $event;
        this.formGroup.patchValue({country_id:this.selectedCountryId});
        this.selectedCityId = null;
        this.selectedDistrictId = null;
        this.selectedTownId = null;
      break;
      case "city": 
        this.selectedCityId = $event;
        this.formGroup.patchValue({city_id:this.selectedCityId});
        this.selectedDistrictId = null;
        this.selectedTownId = null;
      break;
      case "district":
        this.selectedDistrictId = $event;
        this.formGroup.patchValue({district_id:this.selectedDistrictId});
        this.selectedTownId = null;
        break;
      case "town":
        this.selectedTownId = $event;
        this.formGroup.patchValue({town_id:this.selectedTownId});
      break;
    }


  }

  formSubmit(){
    if (this.formGroup.invalid) {
      this.alertifyService.error("Kayıt edilemedi!");
      return false;
    };


    this.createAddress.mutate({ ...this.formGroup.value }).subscribe((result:any)=>{
      console
      if (result.errors) {
        this.alertifyService.error("Kayıt Eklenemedi!");
      //  this.addressResult.emit({status:false});
        return false;
      }
  
      if (result.data) {
       
        this.alertifyService.success("Kayıt oluşturuldu!");
        this.formGroup.reset();
        this.resetForm = true;
        //this.addressResult.emit({status:true,result:result.data.insert_user_address.returning[0]});
        this.changeGeoZone("country",undefined);
        this.setUserAddressList();
        this.setPage("close_address");
      }
      this.formGroup.patchValue({user_id:this.userId});
      


    })
  }

  setSelectedAddress(event:any){
   
    if(event > 0){
      this.selectedAddress = this.userAddressList?.filter((i:any)=> (i.id == event))[0];
    }
  }

  setPage(event:any){
    switch(event){
      case "address":
        this.showCreateAddress = true;
      break;
      case "close_address":
        this.showCreateAddress = false;
      break;
    }
  }

  emitAddress(){
    
    this.updateOrderAddress.mutate({id:this.orderId, address_id: this.selectedAddress.id }).subscribe(response=>{
      if(response.errors){
        this.alertifyService.error(response.errors[0].message);
      }

      if(response.data){
        this.alertifyService.success("Adres bilgisi güncellendi");
        this.refreshInfo.emit(true);
      }
    })

    
  }

  updateIdentity(){
    if(this.identityFormGroup.invalid){
      this.alertifyService.error("Geçerli bir kimlik numarası giriniz!");
    }

    if(this.identityFormGroup.valid && this.userInfo){
      this.updateUserIdentity.mutate({id:this.userInfo.id, identity_no: this.identityFormGroup.value.identity_no}).subscribe(response => {
        if(response.errors){
          this.alertifyService.error(response.errors[0].message);
        }

        if(response.data){
          this.alertifyService.success("Tc Kimlik numarası güncellendi");
          this.refreshInfo.emit(true);
        }
      })
    }
  }

}
