import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KeyObject } from 'crypto';
import { Package, PACKAGE_ALL, PACKAGE_ID } from 'src/app/graphql/package.graphql';
import { PackageModelType, PACKAGE_MODEL_TYPE_ALL, } from 'src/app/graphql/package_model_type.graphql';
import { numberFormatStr } from 'src/app/helpers/slugify';
import { AlertifyService } from 'src/app/services/alertify.service';
import { runInContext } from 'vm';

@Component({
  selector: 'app-appointment-v2-package-select',
  templateUrl: './package-select.component.html',
  styles: [
  ],
  providers:[PACKAGE_MODEL_TYPE_ALL,PACKAGE_ID]
})
export class PackageSelectComponent implements OnInit {

  @Input() appointmentForm: any;
  public packageModelType: PackageModelType | undefined;
  public package: Package | undefined;
  public originalList: any = [];
  public premiumList: any = [];
  public equivalentList: any = [];

  public premiumObjects:any = [];
  public equivalentObjects:any = [];
  public equivalentReutnObject:any = [];

  public premiumOfferList: any = [];
  public equivalentOfferList: any = [];
  public premiumReutnObject:any = [];

  public originalAmounts:any = [];
  public premiumAmounts:any= [];
  public equivalentAmounts: any = [];
  
  public totalLabourTime = 0;
  public originalLabourPrice = 0;
  public premiumLabourPrice = 0;
  public equivalentLabourPrice = 0;

  public selectedPackage: any;
  public selectedPaymentType = 0;

  public totalLabourHour = 0;

  public packageForm = new FormGroup({
    maintenancePackageId: new FormControl(null,[Validators.required]),
    paymetnType: new FormControl([null,[Validators.required]])
  })

  public showPaymentButtons: boolean = false;
  @Output() selectedPackageEmitter = new EventEmitter<any>();
  
  constructor(
    private alertifyService: AlertifyService,
    private getPackageModelTypes: PACKAGE_MODEL_TYPE_ALL,
    private getPackage: PACKAGE_ID
  ) { }

  ngOnInit(): void {
    this.originalLabourPrice = 0;
    this.premiumLabourPrice = 0;
    this.equivalentLabourPrice = 0;
    if(this.appointmentForm.value.modelTypeId && this.appointmentForm.value.gearId, this.appointmentForm.value.fuelId){
      this.getPackageModelTypes.watch({where:{
        _and:{
          model_type_id:{_eq:this.appointmentForm.value.modelTypeId},
          gear_id:{_eq:this.appointmentForm.value.gearId},
          fuel_id:{_eq:this.appointmentForm.value.fuelId},
          maintenance_packages: {maintenance_kms_id: {_eq: this.appointmentForm.value.maintenanceKmsId}}

        }
       
      }},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
        if(response.error){
          this.alertifyService.error("Bir hata oluştu. Lütfen tekrar deneyiniz.");
          return false;
        }
        if(response.data){

 
         

            this.packageModelType = response.data.maintenance_package_model_types[0];
            if(this.packageModelType && this.packageModelType.package_id > 0){

               this.getPackage.watch({id:this.packageModelType.package_id},{fetchPolicy:"no-cache"}).valueChanges.subscribe(async packageResponse=>{
                if(packageResponse.error){
                  this.alertifyService.error("Bir hata oluştu. Lütfen tekrar deneyiniz.");
                  return false;
                }

                if(packageResponse.data){
                    this.premiumObjects = [];
                    this.equivalentObjects = [];
                    this.package = packageResponse.data.maintenance_packages_by_pk;
    

                    
                    let originalLabourPrice = 0;
                    let equivalentLabourPrice = 0;
                    let premiumLabourPrice = 0;
                    await this.package.maintenance_package_details.forEach(async element => {

                     

                      if( element.part.brand_labour_hour != null && element.part_unit_type != 3){
                        
                     
                        if(this.packageModelType){
                          let labourHour        = (element.part.brand_labour_hour - ((element.part.brand_labour_hour * 10 )/ 100));
                          
                          originalLabourPrice   = (labourHour * this.packageModelType.vehicle_model_types.vehicle_model_year.vehicle_model.brand_labour_price);
                          equivalentLabourPrice = (labourHour * this.packageModelType.vehicle_model_types.vehicle_model_year.vehicle_model.gos_labour_price);
                          premiumLabourPrice    = (labourHour * this.packageModelType.vehicle_model_types.vehicle_model_year.vehicle_model.gos_labour_price);

                          this.originalLabourPrice += originalLabourPrice;
                          this.equivalentLabourPrice += equivalentLabourPrice;
                          this.premiumLabourPrice += premiumLabourPrice;
                          this.totalLabourTime += labourHour;


      
                        }

                       
                        
                        
                      }

    

                      
                      let originalObj = {
                        id:element.part_original_list.id,
                        part_id: element.part_id,
                        part_type_id: element.part_original_list.part_type_id,
                        part_value: element.part_original_list.part_in_vat_price,
                        selected_part_id: element.part_original_list.id,
                        part_unit_type: element.part_unit_type,
                        part_unit:  (element.part_unit_type == 3) ? this.totalLabourTime : element.part_unit,
                        total_price_no_vat: (element.part_original_list.part_no_vat_price * element.part_unit),
                        total_price: (element.part_original_list.part_in_vat_price * element.part_unit),
                        part_code: element.part_original_list.part_code,
                        part_no_vat_price: element.part_original_list.part_no_vat_price,
                        brand_labour_hour: element.part.brand_labour_hour,
                        part_labour_price: (element.part_original_list.part_type_id == 9) ? 0 : this.packageModelType?.vehicle_model_types.vehicle_model_year.vehicle_model.brand_labour_price,
                        labour_price: originalLabourPrice,
                        original_name: element.part_original_list.name,
                        part_original_id: element.part_original_list.id,
                        equivalent_id: 0,
                        count: 0,
                        totalPartInVatPrice: (element.part_unit * element.part_original_list.part_in_vat_price),
                        totalPartNoVatPrice: (element.part_unit * element.part_original_list.part_no_vat_price),
                        part_in_vat_price: element.part_original_list.part_in_vat_price,
                        price_unit: element.part_original_list.price_unit,
                        price_unit_id: element.part_original_list.price_unit_id,
                        part_types: element.part_original_list.part_types,
                        positon_no: element.part.position_no
                        
                      }

                      
                      

                      this.originalList.push(originalObj);
                  

                      
                      await element.part_original_list.part_equivalent_lists.forEach((equivalent:any,key:number) => {
                        
                        if(equivalent.part_type_id == 2 || equivalent.part_type_id == 9){

                          let premiumObj = {
                            part_id: element.part_id,
                            part_type_id: equivalent.part_type_id,
                            part_value: equivalent.part_in_vat_price,
                            id:equivalent.id,
                            selected_part_id: equivalent.id,
                            part_unit_type: element.part_unit_type,
                            part_unit: (element.part_unit_type == 3) ? this.totalLabourTime : element.part_unit,
                            brand_labour_price: 0,
                            gos_labour_price: 0,
                            price_unit_id: equivalent.price_unit_id,
                            total_price_no_vat: (equivalent.part_no_vat_price * element.part_unit),
                            total_price: (equivalent.part_in_vat_price * element.part_unit),
                            part_code: equivalent.part_code,
                            part_no_vat_price: equivalent.part_no_vat_price,
                            brand_labour_hour: element.part.brand_labour_hour,
                            part_labour_price: (equivalent.part_type_id == 9) ? 0 : this.packageModelType?.vehicle_model_types.vehicle_model_year.vehicle_model.gos_labour_price,
                            labour_price: premiumLabourPrice,
                            original_name: element.part_original_list.name,
                            part_original_id: element.part_original_list.id,
                            equivalent_id: equivalent.id,
                            count: 0,
                            totalPartInVatPrice: 0,
                            totalPartNoVatPrice: 0,
                            part_in_vat_price: equivalent.part_in_vat_price,
                            price_unit: equivalent.price_unit,
                            part_types: equivalent.part_types,
                            position_no:  element.part.position_no,
                            part_group_position_no: element.part.part_group.position_no
                          }

                        

                          //this.premiumList.push(equivalent);
                          premiumObj.count += 1;
                          premiumObj.totalPartInVatPrice += (element.part_unit * equivalent.part_in_vat_price),
                          premiumObj.totalPartNoVatPrice += (element.part_unit * equivalent.part_no_vat_price)

                          if(this.premiumObjects[element.part_original_list.id] == undefined){
                            this.premiumObjects[element.part_original_list.id] = [];
                          }

                          this.premiumObjects[element.part_original_list.id].push(premiumObj);


                         
                          
                        }

                        if(equivalent.part_type_id == 3  || equivalent.part_type_id == 9){

                          let equivalentObj = {
                            id:equivalent.id,
                            part_id: element.part_id,
                            part_type_id: equivalent.part_type_id,
                            part_value: equivalent.part_in_vat_price,
                            selected_part_id: equivalent.id,
                            part_unit_type: element.part_unit_type,
                            part_unit:  (element.part_unit_type == 3) ? this.totalLabourTime : element.part_unit,
                            brand_labour_price: 0,
                            gos_labour_price: 0,
                            price_unit_id: equivalent.price_unit_id,
                            total_price_no_vat: (equivalent.part_no_vat_price * element.part_unit),
                            total_price: (equivalent.part_in_vat_price * element.part_unit),
                            part_code: equivalent.part_code,
                            part_no_vat_price: equivalent.part_no_vat_price,
                            brand_labour_hour: element.part.brand_labour_hour,
                            part_labour_price: (equivalent.part_type_id == 9) ? 0 : this.packageModelType?.vehicle_model_types.vehicle_model_year.vehicle_model.gos_labour_price,
                            labour_price: (equivalent.part_type_id == 3) ? 0 : equivalentLabourPrice,
                            original_name: element.part_original_list.name,
                            part_original_id: element.part_original_list.id,
                            equivalent_id: equivalent.id,
                            count: 0,
                            totalPartInVatPrice: 0,
                            totalPartNoVatPrice: 0,
                            part_in_vat_price: equivalent.part_in_vat_price,
                            price_unit: equivalent.price_unit,
                            part_types: equivalent.part_types,
                            position_no:  element.part.position_no,
                            part_group_position_no: element.part.part_group.position_no
                          }

                        

                          //this.equivalentList.push(equivalent);
                          equivalentObj.count += 1;
                          equivalentObj.totalPartInVatPrice += (element.part_unit * equivalent.part_in_vat_price);
                          equivalentObj.totalPartNoVatPrice += (element.part_unit * equivalent.part_no_vat_price);
                    

                          if(this.equivalentObjects[element.part_original_list.id] == undefined){
                            this.equivalentObjects[element.part_original_list.id] = [];
                          }

                          this.equivalentObjects[element.part_original_list.id].push(equivalentObj);
                        }

                      });
                      
     
                    });

                    await this.premiumObjects.forEach(async element => {

                      let result = await this.findClosestItem(element);
                   
                      if(result.brand_labour_hour != null && result.part_type_id != 3){
                        let labourHour = (result.brand_labour_hour - ((result.brand_labour_hour  * 10 )/ 100))
                        let pLabourPrice =labourHour * result.part_labour_price;
                        result.part_labour_hour = result.brand_labour_hour;
                        result.labour_price = pLabourPrice;
                      
                     //   this.premiumLabourPrice += pLabourPrice;
                      
                      }
                   
                        this.premiumOfferList.push(result);
           
                     
                    });

                    this.premiumOfferList = this.sortByName(this.premiumOfferList);

                    await this.equivalentObjects.forEach(async element => {
                      let result = await this.findClosestItem(element);
                      if(result.brand_labour_hour != null){

                        let labourHour = (result.brand_labour_hour - ((result.brand_labour_hour  * 10 )/ 100))
                        let eLabourPrice = labourHour * result.part_labour_price;
                    //    this.equivalentLabourPrice += eLabourPrice;
                        result.part_labour_hour = result.brand_labour_hour;
                        result.labour_price = eLabourPrice;
                      }
                      this.equivalentOfferList.push(result);
                     
                    });

                   this.equivalentOfferList = this.sortByName(this.equivalentOfferList);


                      await this.originalList.map((i)=> { 
                        if(i.part_unit_type == 3){
                          i.part_in_vat_price = (this.originalLabourPrice + ( ( this.originalLabourPrice * 20 ) / 100 ) );
                          i.part_no_vat_price = this.originalLabourPrice;
                          i.totalPartNoVatPrice = this.originalLabourPrice;
                          i.totalPartInVatPrice = i.part_in_vat_price;
                          i.totalLabourHour = this.totalLabourTime;
                          i.part_unit = this.totalLabourTime;
                        }
                      });

                      await this.equivalentOfferList.map((i)=> { 
                        if(i.part_unit_type == 3){
                          i.part_in_vat_price = (this.equivalentLabourPrice + ( ( this.equivalentLabourPrice * 20 ) / 100 ) );
                          i.part_no_vat_price = this.equivalentLabourPrice;
                          i.newNoVatPrice = this.equivalentLabourPrice;
                          i.newVatPrice = i.part_in_vat_price;
                          i.totalLabourHour = this.totalLabourTime;
                          i.part_unit = this.totalLabourTime;
                          i.part_value = this.equivalentLabourPrice;
                        }
                      });

                      await this.premiumOfferList.map((i)=> { 
                        if(i.part_unit_type == 3){
                          i.part_in_vat_price = (this.premiumLabourPrice + ( ( this.premiumLabourPrice * 20 ) / 100 ) );
                          i.part_no_vat_price = this.premiumLabourPrice;
                          i.newNoVatPrice = this.premiumLabourPrice;
                          i.newVatPrice = i.part_in_vat_price;
                          i.totalLabourHour = this.totalLabourTime;
                          i.part_unit = this.totalLabourTime;
                          i.part_value = this.equivalentLabourPrice;
                        }
                      });

                      //this.equivalentOfferList = this.sortByName(this.equivalentOfferList);
                      //this.originalList = this.sortByName(this.originalList);

                    await this.setAmounts();

                }
              })

            }
        }
      });
    }

   

  }

   sortByName(dizi) {
   /* return dizi.sort((a, b) => {
      const nameA = a.position_no;
      const nameB = b.position_no;
      if (nameA > nameB) {
        return -1;
      } else if (nameA < nameB) {
        return 1;
      } else {
        return 0;
      }
    });*/

    return dizi.sort((a, b) => {
      if (a.part_group_position_no === b.part_group_position_no) {
        return b.position_no - a.position_no;
      }
      return b.part_group_position_no - a.part_group_position_no;
    });
  }


  findClosestItem(items) {
    const totalPartInVatPrices = items.map(item => item.totalPartInVatPrice);
    const average = totalPartInVatPrices.reduce((sum, price) => sum + price, 0) / totalPartInVatPrices.length;
  
    let closestItem = items[0];
    let minDiff = Math.abs(items[0].totalPartInVatPrice - average);
  
    for (let i = 1; i < items.length; i++) {
      const diff = Math.abs(items[i].totalPartInVatPrice - average);
      if (diff < minDiff) {
        minDiff = diff;
        closestItem = items[i];
      }
    }

    let prices:any[] = [];
    items.forEach(element => {
      prices.push(element.totalPartNoVatPrice);
    });
  
    const newVatPrice = totalPartInVatPrices.reduce((sum, price) => sum + price, 0) / totalPartInVatPrices.length;
    const newNoVatPrice = items.reduce((sum, item) => sum + item.totalPartNoVatPrice, 0) / items.length;
  
    return {
      ...closestItem,
      newVatPrice,
      newNoVatPrice,
       prices
    };
  }

  async setAmounts(){
    this.originalAmounts = {
      total: 0,
      kdv:0,
      generalTotal: 0,
      labourPrice: 0
    }
    await this.originalList.forEach(element => {
        this.originalAmounts.generalTotal += element.totalPartInVatPrice;
        this.originalAmounts.total += element.totalPartNoVatPrice;        
    });

 //   this.originalAmounts.total += this.originalLabourPrice;
   // this.originalAmounts.generalTotal  += this.originalLabourPrice;
   // this.originalAmounts.labourPrice = this.originalLabourPrice;
    this.originalAmounts.kdv  = (this.originalAmounts.generalTotal - this.originalAmounts.total);


    this.premiumAmounts = {
      total: 0,
      kdv:0,
      generalTotal: 0,
      labourPrice: 0
    }
    await this.premiumOfferList.forEach(element => {
        this.premiumAmounts.generalTotal += element.newVatPrice;
        this.premiumAmounts.total += element.newNoVatPrice;
    });

   // this.premiumAmounts.total += this.premiumLabourPrice;
    //this.premiumAmounts.generalTotal  += this.premiumLabourPrice;
   //this.premiumAmounts.labourPrice = this.premiumLabourPrice;
    this.premiumAmounts.kdv  = this.premiumAmounts.generalTotal - this.premiumAmounts.total;



    this.equivalentAmounts = {
      total: 0,
      kdv:0,
      generalTotal: 0,
      labourPrice: 0
    }
   
    await this.equivalentOfferList.forEach(element => {
        this.equivalentAmounts.generalTotal += element.newVatPrice;
        this.equivalentAmounts.total += element.newNoVatPrice;
    });
    
    //this.equivalentAmounts.total += this.equivalentLabourPrice;
    //this.equivalentAmounts.generalTotal  += this.equivalentLabourPrice;
    //this.equivalentAmounts.labourPrice = this.equivalentLabourPrice;
    this.equivalentAmounts.kdv  = this.equivalentAmounts.generalTotal - this.equivalentAmounts.total;


   
  }

  filterCode(item:any){
    const firstThreeDigits = item.substr(0, 3);
    const remainingChars = item.substr(3);
    const convertedString = firstThreeDigits + remainingChars.replace(/./g, '*');
    return convertedString;
  }

  setSelectedPackage(type:string){
    this.selectedPackage = undefined;
    this.showPayments(false);
    switch(type){
      case "original":
        this.selectedPackage = {
          "amount":this.originalAmounts,
          "packageParts": this.originalList,
          "partTypeId": this.originalList[0].part_type_id,
          "type": "original",
          "packageInfo": this.package,
          "paymentType": this.selectedPaymentType,
          "selectedPackageTypeId": 1
        }
      break;

      case "premium":
        this.selectedPackage = {
          "amount":this.premiumAmounts,
          "packageParts": this.premiumOfferList,
          "partTypeId": this.premiumOfferList[0].part_type_id,
          "type": "premium",
          "packageInfo": this.package,
          "paymentType": this.selectedPaymentType,
          "selectedPackageTypeId": 2
        }
      break;

      case "equivalent":
        this.selectedPackage = {
          "amount":this.equivalentAmounts,
          "packageParts": this.equivalentOfferList,
          "partTypeId": this.equivalentOfferList[0].part_type_id,
          "type": "equivalent",
          "packageInfo": this.package,
          "paymentType": this.selectedPaymentType,
          "selectedPackageTypeId": 3
        }
      break;
    }

  


    if(this.selectedPackage ){
      this.showPayments(true);
   
    }
  }

  showPayments(status:boolean){
    this.showPaymentButtons = status;
  }

  setPaymentType(type:string){
    if(type == "online"){
      this.selectedPaymentType = 1;
     
    }else{
      this.selectedPaymentType = 2;
     
    }

    this.packageForm.patchValue({
      paymentType: this.selectedPaymentType,
      maintenancePackageId: this.selectedPackage.packageInfo.id
    });

    this.selectedPackage.paymentType = this.selectedPaymentType;

    if(this.packageForm.invalid){
      this.alertifyService.error("Lütfen paket ve ödeme türü seçiniz");
      return false;
    }

    if(this.packageForm.valid){

      this.selectedPackageEmitter.emit(this.selectedPackage);
    };
  }

  toNumberFormat(number: any) {
    return numberFormatStr(number);
  }

}
