import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface PieceImages {
    
    id: number,
    image_url: string,
    part_type_id: number,
    position: number,
    created_at: Date,
    updated_at: Date
    image_name: string,
    image_title: string,
    
}

interface ResponseArray {
    piece_images: PieceImages[];
}

interface ResponseSingle {
    piece_images_by_pk: PieceImages
}

@Injectable()
export class PIECE_IMAGES_ALL extends Query<ResponseArray> {
  override document = gql`
  query PIECE_IMAGES_ALL($where: piece_images_bool_exp) {
    piece_images(where: $where) {
        id
        image_url
        part_type_id
        position
        image_name
        image_title
    }
  }
  `;
}

@Injectable()
export class PIECE_IMAGES_ID extends Query<ResponseSingle> {
  override document = gql`
    query PIECE_IMAGES_ID($id: number!){
        piece_images_by_pk(id: $id) {
            id
            image_url
            part_type_id
            position
            image_name
            image_title
      }
    }
  `;
}

@Injectable()
export class PIECE_IMAGES_CREATE extends Mutation<any> {
  // connected_part_id - int | nullable
  override document = gql`
  mutation PIECE_IMAGES_CREATE(
      $image_url: String, 
      $part_type_id: Int,
      $position: Int,
      $image_name: String,
      $image_title: String
      ) {
    insert_piece_images(objects: {
        image_url: $image_url, 
        part_type_id:$part_type_id,
        position:$position,
        image_name:$image_name,
        image_title:$image_title
    }) {
      affected_rows
      returning {
        id
      }
    }
  }
  
  `;
}

@Injectable()
export class PIECE_IMAGES_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
  mutation PIECE_IMAGES_UPDATE(
      $id:number
      $image_url: String, 
      $part_type_id: Int,
      $position: Int,
      $image_name: String,
      $image_title: String) {
    update_piece_images(where: {id: {_eq: $id}}, _set: {  image_url: $image_url, part_type_id: $part_type_id,position:$position,image_name:$image_name,image_title:$image_title}) {
      affected_rows
    }
  }
  
  `;
}

@Injectable()
export class PIECE_IMAGES_DELETE extends Mutation<any> {
  override document = gql`
    mutation PIECE_IMAGES_DELETE($id: Int!) {
      delete_piece_images(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}
