<div class="d-flex justify-content-between py-5 form-container">
    <div class="d-flex flex-column-fluid justify-content-center tab-header-container">
        <div class="tab-header">
            <div class="tab active" [ngClass]="{active: selectedTab == 'individual'}">
                <a (click)="changeTab('individual')">Bireysel Fatura</a>
            </div>
            <div class="tab" [ngClass]="{active: selectedTab == 'institutional'}">
                <a (click)="changeTab('institutional')">Kurumsal Fatura</a>
            </div>
        </div>
    </div>

   
</div>


<div class="d-flex justify-content-between odeme-form-container">
    <div class="odeme-item-container individual-container">
        <form [formGroup]="invoiceAddressForm">
            <div class="form-content-container">
        
                <div class="tab-input-container" *ngIf="selectedTab == 'individual'">
                    <input 
                        class="form-control tab-input"
                        type="text"
                        name="name"
                        (change)="setInvoiceInfo('invoice_name',$event)"
                        formControlName="customer_name"
                        autocomplete="off" placeholder="Ad" 
>
                </div>     
                <div class="tab-input-container" *ngIf="selectedTab == 'individual'">
                    <input 
                        type="text" 
                        class="form-control tab-input" 
                        name="surname"
                        (change)="setInvoiceInfo('invoice_surname',$event)"
                        formControlName="customer_surname"
                        autocomplete="off" 
                        placeholder="Soyad"
                        >
                </div>

                <div class="tab-input-container" *ngIf="selectedTab == 'institutional'">
                    <input 
                        type="text" 
                        class="form-control tab-input" 
                        name="company_name"
                        (change)="setInvoiceInfo('company_name',$event)"
                        formControlName="company_name"
                        autocomplete="off" 
                        placeholder="Şirket Adı"
                        >
                </div>

                <div class="tab-input-container" *ngIf="selectedTab == 'institutional'">
                    <input 
                        type="text" 
                        class="form-control tab-input" 
                        name="tax_administration"
                        (change)="setInvoiceInfo('tax_administration',$event)"
                        formControlName="tax_administration"
                        autocomplete="off" 
                        placeholder="Vergi Dairesi"
                        >
                </div>
                <div class="tab-input-container" *ngIf="selectedTab == 'institutional'">
                    <input 
                        type="text" 
                        class="form-control tab-input" 
                        name="tax_number"
                        (change)="setInvoiceInfo('tax_number',$event)"
                        formControlName="tax_number"
                        autocomplete="off" 
                        placeholder="Vergi Numarası"
                        >
                </div>
        
                <div class="tab-input-container">
                    <input 
                        type="text"
                        class="form-control tab-input" 
                        placeholder="Telefon"
                        (change)="setInvoiceInfo('gsm',$event)"
                        formControlName="tel_number"
                        autocomplete="off" 
                        >
                </div>
        
                <div class="tab-input-container">
                    <input 
                        type="text" 
                        class="form-control tab-input" 
                        placeholder="E-mail"
                        (change)="setInvoiceInfo('email',$event)"
                        formControlName="customer_email"
                        autocomplete="off" >
                </div>
        
                <div class="tab-input-container"  *ngIf="selectedTab == 'individual'">
                    <input 
                        type="text" 
                        class="form-control tab-input" 
                        placeholder="TC Kimlik Numarası"
                        (change)="setInvoiceInfo('identity_no',$event)"
                        formControlName="identity_no"
                        autocomplete="off">
                </div>

                <div class="tab-input-container">
                    <app-shared-appointment-cities 
                        [event]="invoiceAddressForm.value.country_id" 
                        (city_id)="changeGeoZone('city',$event)"
                        [showLabel]="false"
                    ></app-shared-appointment-cities> 
                </div>

                <div class="tab-input-container">
                    <app-shared-appointment-districts 
                        [event]="invoiceAddressForm.value.city_id" 
                        (district_id)="changeGeoZone('district',$event)"
                        [showLabel]="false"
                    ></app-shared-appointment-districts>
                </div>

                <div class="tab-input-container">
                    <app-shared-appointment-towns 
                        [event]="invoiceAddressForm.value.district_id" 
                        (town_id)="changeGeoZone('town',$event)"
                        [showLabel]="false"
                    ></app-shared-appointment-towns>
                </div>

                <div class="tab-input-container">
                    <textarea name="" id="" cols="30" class="form form-control" rows="10" placeholder="adres"  (change)="setInvoiceInfo('address',$event)" formControlName="address"></textarea>
                </div>

                <div class="tab-input-container checkbox-container">
                    <div class="form-check form-check-sm form-check-custom form-check-solid payment-check">
                        <input class="form-check-input checbox-check" type="checkbox" value="1" checked>
                        &nbsp;E-Fatura Mükellefiyim
                    </div>
                    <div class="form-check form-check-sm form-check-custom form-check-solid payment-check">
                        <input class="form-check-input checbox-check" type="checkbox" value="1" checked>
                        &nbsp;Aydınlatma Metni
                    </div>
                    <div class="form-check form-check-sm form-check-custom form-check-solid payment-check">
                        <input class="form-check-input checbox-check" type="checkbox" value="1" checked>
                        &nbsp;Gizlilik Politikası
                    </div>
                    <div class="form-check form-check-sm form-check-custom form-check-solid payment-check">
                        <input class="form-check-input checbox-check" type="checkbox" value="1" checked>
                        &nbsp;Kullanıcı Sözleşmesi
                    </div>
                    <div class="form-check form-check-sm form-check-custom form-check-solid payment-check">
                        <input class="form-check-input checbox-check" type="checkbox" value="1" checked>
                        &nbsp;Açık Rıza Metni
                    </div>
                </div>

                <div class="tab-button-container">
                    <button (click)="createOrder()" class="btn btn-primary btn-sm" [disabled]="buttonDisable">Randevu Oluştur</button>
                    <p *ngIf="buttonDisable">Lütfen bekleyiniz randevu oluşturuluyor ...</p>
                </div>

              
            </div>
        </form>
    </div>
    
</div>