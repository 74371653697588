import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-shared-modal',
  templateUrl: './modal.component.html',
  styles: [
  ]
})
export class ModalComponent implements OnInit {
  public show:boolean=false;
  @Output() modal:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() showCloseButton: boolean = true;
  close(){
    this.show = false;
    document.getElementById('kt_modal_share_earn')?.classList.remove('show');
    this.isPopupOpen = false;
  
    this.modal.emit(true)
  
  }
  public isPopupOpen:boolean = false;
  constructor(private eRef: ElementRef) { }

  ngOnInit(): void {
    this.isPopupOpen = true;
  }

  togglePopup(){
    this.isPopupOpen = !this.isPopupOpen;
  }

}
