import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs';
import { PermissionProfile } from 'src/app/graphql/permission_profiles.graphql';
import { User, USER_ALL, USER_GET_EMAIL } from 'src/app/graphql/user.graphql';
import { CustomValidator } from 'src/app/helpers/custom.validator';
import { FireBaseEmailAuthReponse } from 'src/app/interfaces/firebase.interface';
import { Permission } from 'src/app/interfaces/permission.interface';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { PasswordReset } from 'src/app/services/password.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shared-user-create',
  templateUrl: './user-create.component.html',
  styles: [
  ],
  providers:[USER_ALL,USER_GET_EMAIL,PasswordReset]
})
export class UserCreateComponent implements OnInit {

  @Output() userResult = new EventEmitter<Number>();

  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.min(3)]),
    surname: new FormControl('', [Validators.required, Validators.min(2)]),
    email: new FormControl('', [Validators.required, CustomValidator.isValidEmail]),
    identity_no: new FormControl('', [Validators.required]),
    role_id: new FormControl(3)
  });

  public permission: Permission = {} as Permission
  public permissionProfilesList:  PermissionProfile[] | undefined;
  private baseUrl: string = environment.laravelApiEndpoint;
  ipAddress: string = "127.0.0.1";
  public currentUserId: Number | undefined;
  public userInfo: User | undefined;

  constructor(
    private alertifyService: AlertifyService,
    private http: HttpClient,
    private authService: AuthService,
    private resetService: PasswordReset,
    private getUsers: USER_ALL,
    private checkUser: USER_GET_EMAIL,
  ) { }

  ngOnInit(): void {

   
  }

  onSubmit(){
/*
    if (!this.permission.write) {
      this.alertifyService.error("Yazma İzniniz Yok!")
      return
    }
*/
    if (this.formGroup.invalid) {
      this.alertifyService.error("hatalı veya eksik form bilgileri");
      return;
    }


    this.checkUser.watch({email:this.formGroup.value.email},{fetchPolicy:"no-cache"}).valueChanges.subscribe(ret => {
      
      if(ret.errors){
        this.alertifyService.error(ret.errors[0].message);
        return false;
      }

      if(ret.data){
        this.userInfo = ret.data.users[0];
        if(this.userInfo){

          this.userResult.emit(this.userInfo.id);
          return true;

        }else{
          var randomstring = Math.random().toString(36).slice(-8);
          this.authService.firebaseAuth(this.formGroup.value.email, randomstring, false).subscribe(response => {

         
            this.regiterUser();


          }, err => {
           
              if(err == "kayitli mail"){
               
                this.regiterUser();
                 
              }
          })
        }
      }
    });
    
    
  }

  regiterUser(){
    this.authService.apiCreateUser(this.formGroup.value).subscribe(createResponse => {
           

      this.resetService.sendPasswordResetResult(this.formGroup.value.email).subscribe(resetresponse => {
        
        if (resetresponse.email) {
          this.alertifyService.success("Parola oluşturma linki kullanıcı mail adresine gönderilmiştir.");
        
         
        }
      }, err => {
        this.alertifyService.error(err)
      });


      this.userResult.emit(createResponse.result.result);
    })
  }

}
