import { EventInput } from '@fullcalendar/angular';

let eventGuid = 0;
const TODAY_STR = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

export const INITIAL_EVENTS: EventInput[] = [
  
];

export function createEventId() {
  return String(eventGuid++);
}


export interface fullcalendarItem {
  title: string,
  id: string,
  start: string,
  end?: string
}