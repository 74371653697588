import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AppointmentV3Component } from '../appointment-v3/appointment-v3.component';

@Component({
  selector: 'app-appointment-for-app',
  templateUrl: './appointment-for-app.component.html',
  styles: [
  ]
})
export class AppointmentForAppComponent implements OnInit {

  @ViewChild('appointmentContainer', { read: ViewContainerRef }) container!: ViewContainerRef;

  constructor(private resolver: ComponentFactoryResolver) {}

  ngAfterViewInit() {
    this.loadAppointmentComponent();
  }

  ngOnInit(): void {
    
  }
  loadAppointmentComponent() {
    if (this.container) {
      const factory = this.resolver.resolveComponentFactory(AppointmentV3Component);
      this.container.clear(); // Daha önce yüklenmiş bileşenleri temizler
      const componentRef = this.container.createComponent(factory);
      // componentRef.instance ile bileşenin public özelliklerine erişebilirsiniz
    } else {
      console.error('Container is not available');
    }
  }

}
