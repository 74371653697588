import { Injectable } from "@angular/core";

import jsPDF from "jspdf";
import htmlToPdfmake from 'html-to-pdfmake';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { StyleDictionary, TDocumentDefinitions } from "pdfmake/interfaces";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
// https://pdfmake.github.io/docs/0.1/

@Injectable()
export class PdfService {

  private doc = new jsPDF();

  generator(type: string, body: HTMLElement, styles: StyleDictionary | undefined = undefined) {
    const documentDefinition: TDocumentDefinitions = { content: htmlToPdfmake(body.innerHTML), styles: styles };
    switch (type) {
      case "download":
        this.download(documentDefinition)
        break;
      case "print":
        this.print(documentDefinition)
        break;
      default:
        this.preview(documentDefinition)
        break;
    }
    pdfMake.createPdf(documentDefinition).open();
  }

  preview(document: TDocumentDefinitions) {
    pdfMake.createPdf(document).open();
  }

  download(document: TDocumentDefinitions) {
    pdfMake.createPdf(document).download();
  }

  print(document: TDocumentDefinitions) {
    pdfMake.createPdf(document).print();
  }

  jspdf(body: HTMLElement) {
    this.doc.html(body.innerHTML, {
      callback: (doc) => {
        doc.save()
      }
    })
  }

}