<style>
    .table td,th{
        padding: 0px 5px 0px 5px !important;
        height: 40px !important;
        border: 1px solid #000 !important;
        font-size: 10px !important;
        height: 10px !important;
    }

    .table th{
        font-weight: bold;
    }

    .table tbody tr:last-child th{
        border: 1px solid #000 !important;
    }

    .gos-info-table, .gos-info-table th,  .gos-info-table tr:last-child th{
        border: 0px !important;
    }

    .top-center-info{
        padding: 0px;
        font-size: 0.95rem !important;
        font-weight: bold;
    }

    .customer-info-table{
        border: 1px solid #ccc;
    }

    .customer-info-table td, th{
        border-color: #ccc !important;
    }

    .customer-info-table th, .customer-info-table td, .customer-info-table tr:last-child th{
        border: 0px !important;
        font-size: 0.95rem !important;
        border-right: 1px solid #ccc !important;
      
    }
    .product-detail-table{
        border: 1px solid #ccc;
        border-color: #ccc !important;
    }

    .amount-info-table{
        border: 1px solid #ccc;
        border-color: #ccc !important;
    }

    .amount-info-table td, th{
        border-color: #ccc !important;
    }

    .amount-info-table th:first-child{
        border: 0px !important;
    }

    .product-detail-table{
        border: 0px !important;
        border-color: #ccc !important;
    }

    .product-detail-table td, th{
        border: 1px solid #ccc;
        border-color: #ccc !important;
    }

    

    .desc-info-table{
        width: 100%;
    }

    .desc-info-table th,.desc-info-table th:last-child,.desc-info-table tr:last-child {
        border: 1px solid #ccc !important;
     color: #96bc33 !important;
    }

    .desc-info-table td{
        border-bottom: 1px solid #ccc !important;
    }

    .desc-info-table th{
        width: 11%;
    }

 
  
</style>

<app-shared-proforma-pdf-generator-v3
    *ngIf="invoiceTaked"
    [invoiceInfo]="invoiceInfo"
>

</app-shared-proforma-pdf-generator-v3>

<div  class="w-100 mb-5">
    
    <div class="d-flex justify-content-between">
            
        <div class="text-end top-center-info w-100 align-middle" style="padding-top: 10px;">{{invoiceInfo?.invoice_info?.payment_type}}</div>
        <div class="text-center top-center-info text-center  w-100" style="font-size: 26px !important;">{{invoiceInfo?.invoice_info?.order_type}}</div>
        <div class="top-center-info w-100 text-end" style="padding-top: 10px;">Randevu Tarihi: {{invoiceInfo?.invoice_info?.service_date}}</div>
    </div>
</div>



    <div class="d-flex justify-content-between">
        <div class="w-100">
            <table class="table gos-info-table" >
                <tbody>
                    <tr>
                        <th>
                            <span class="text-muted fs-7">{{invoiceInfo?.gos_info?.title}}</span>
                        </th>
                        
                    </tr>
                    <tr>
                        <th>
                            <span class="text-muted fs-7">{{invoiceInfo?.gos_info?.company_tel_no}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <span class="text-muted fs-7">{{invoiceInfo?.gos_info?.company_email_address}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <span class="text-muted fs-7">{{invoiceInfo?.gos_info?.city}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <span class="text-muted fs-7">{{invoiceInfo?.gos_info?.district}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <span class="text-muted fs-7">{{invoiceInfo?.gos_info?.address}}</span>
                        </th>
                    </tr>
                    
                    <tr>
                        <th style="border: 0px !important;">
                            <span class="text-muted fs-7">{{invoiceInfo?.gos_info?.vat}}</span>
                        </th>
                    </tr>
                   
                    
                </tbody>
            </table>
        </div>
        <div class="w-100">
            <div class="text-sm">
                <!--begin::Logo-->
                <a href="#" class="d-block mw-150px mx-auto">
                    <img alt="Logo" src="assets/images/logo-garantiliotoservis.svg" class="w-100" />
                </a>
                <!--end::Logo-->
              
            </div>
           
          
            
        </div>
   
      

        <div  class="w-100">
            <table class="table gos-info-table" >
                <tbody>
                    <tr>
                        <th class="text-end">
                            <span class="text-muted fs-7">{{invoiceInfo?.service_info?.name}}</span>
                        </th>
                        
                    </tr>
                    <tr>
                        <th class="text-end">
                            <span class="text-muted fs-7" *ngIf="invoiceInfo?.service_info?.gsm_no">
                               {{invoiceInfo?.service_info?.gsm_no}}
                            </span>
                            <span class="text-muted fs-7" *ngIf="invoiceInfo?.service_info?.gsm_no == null && invoiceInfo?.service_info?.tel_no">
                                {{invoiceInfo?.service_info?.tel_no}}
                            </span>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-end">
                            <span class="text-muted fs-7">{{invoiceInfo?.service_info?.email}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-end">
                            <span class="text-muted fs-7">{{invoiceInfo?.service_info?.city}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-end">
                            <span class="text-muted fs-7">{{invoiceInfo?.service_info?.district}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-end">
                            <span class="text-muted fs-7">{{invoiceInfo?.service_info?.address}}</span>
                        </th>
                    </tr>
                    
                    <tr>
                        <th class="text-end" style="border: 0px !important;">
                            <span class="text-muted fs-7">{{invoiceInfo?.service_info?.vat}}</span>
                        </th>
                    </tr>
                   
                    
                </tbody>
            </table>
        </div>
    </div>
    
    <div class="d-flex justify-content-between mt-5">
        <div class="w-50">
            <table class="table customer-info-table align-middle gs-0 gy-4 " >
                <tbody>
                    <tr >
                        <th style="width: 150px; align-items: center !important; text-align: center; border-bottom: 1px solid #ccc !important; font-size: 15px !important;"  colspan="3" >Müşteri Bilgileri</th>
                      
                    </tr>
                    <tr>
                        <th  class="text-muted">Müşteri Adı/Ünvanı</th>
                        <td *ngIf="invoiceInfo?.invoice_info?.customer_invoice_type == 1" style="padding:5px" colspan="2" >{{invoiceInfo?.invoice_info?.customer_name}} {{invoiceInfo?.invoice_info?.customer_surname}}</td>
                        <td *ngIf="invoiceInfo?.invoice_info?.customer_invoice_type == 2" style="padding:5px" colspan="2" >{{invoiceInfo?.invoice_info?.company_name}}</td>
                    </tr>
                    <tr>
                        <th  class="text-muted">Tel No</th>
                        <td style="padding:5px" colspan="2" *ngIf="invoiceInfo?.invoice_info?.tel_number" >
                            {{invoiceInfo?.invoice_info?.tel_number}}
                        </td>

                        <td style="padding:5px" colspan="2" *ngIf="invoiceInfo?.invoice_info?.tel_number == null && invoiceInfo?.invoice_info?.tel_number" >
                            {{invoiceInfo?.invoice_info?.gsm_number}}
                        </td>
                        
                    </tr>
                    <tr>
                        <th  class="text-muted">Mail Adresi</th>
                        <td style="padding:5px" colspan="2" >{{invoiceInfo?.invoice_info?.customer_email}}</td>
                        
                    </tr>
                    <tr>
                        <th class="text-muted">İl</th>
                        <td style="padding:5px" colspan="2">{{invoiceInfo?.invoice_info?.city}}</td>
                    </tr>
                    <tr>
                        <th class="text-muted">İlçe</th>
                        <td style="padding:5px" colspan="2">{{invoiceInfo?.invoice_info?.district}}</td>
                    </tr>
                    <tr>
                        <th class="text-muted">Adres</th>
                        <td style="padding:5px" colspan="2" >{{invoiceInfo?.invoice_info?.address}}</td>
                    </tr>
                    <tr>
                        <th class="text-muted" style="border: 0px !important;  border-right: 1px solid #ccc !important;">TCKN/VN</th>
                        <td *ngIf="invoiceInfo?.invoice_info?.customer_invoice_type == 1" style="padding:5px" colspan="2" >{{invoiceInfo?.invoice_info?.identity_no}}</td>
                        <td *ngIf="invoiceInfo?.invoice_info?.customer_invoice_type == 2"  style="padding:5px" colspan="2" >{{invoiceInfo?.invoice_info?.tax_number}} / {{invoiceInfo?.invoice_info?.tax_administration}}</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
        <div class="w-50">
            <table class="table customer-info-table align-middle gs-0 gy-4 " style="border-left: 0px !important;" >
                <tbody>
                    <tr >
                        <th style="width: 150px; align-items: center !important; border-bottom: 1px solid #ccc !important; font-size: 15px !important;" class="text-center" colspan="3" >Araç Bilgileri</th>
                      
                    </tr>
                    <tr>
                        <th class="text-muted">Şasi</th>
                        <td style="padding:5px" colspan="2">{{invoiceInfo?.vehicle_info?.chassis_no}}</td>
                    </tr>
                    <tr>
                        <th class="text-muted">Plaka</th>
                        <td style="padding:5px" colspan="2">{{invoiceInfo?.vehicle_info?.plate}}</td>
                    </tr>
                    <tr>
                        <th class="text-muted">Marka Model</th>
                        <td style="padding:5px" colspan="2">{{invoiceInfo?.vehicle_info?.brand}} / {{invoiceInfo?.vehicle_info?.model}}</td>
                    </tr>
                    <tr>
                        <th class="text-muted">Versiyon / Yıl</th>
                        <td style="padding:5px" colspan="2" >{{invoiceInfo?.vehicle_info?.model_type}}</td>
                        
                    </tr>
                    <tr>
                        <th class="text-muted"> Yıl</th>
                        <td style="padding:5px" colspan="2">{{invoiceInfo?.vehicle_info?.year}}</td>
                    </tr>
                    <tr>
                        <th class="text-muted">Yakıt</th>
                        <td style="padding:5px" colspan="2" >{{invoiceInfo?.vehicle_info?.fuel}} </td>
                    </tr>
                    <tr>
                        <th class="text-muted" style="border: 0px !important;">Vites</th>
                        <td style="padding:5px" colspan="2" style="border-left: 1px solid #ccc !important;" >{{invoiceInfo?.vehicle_info?.gear}}</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
    </div>

    <div class="d-flex justify-content-between">
        <div class="w-100">
            <table class="table  product-detail-table align-middle " >
                <thead>
                    <tr>
                        <th class="text-center" style=" font-size: 15px !important;" colspan="8">
                            İşlemler
                        </th>
                    </tr>
                </thead>
                <thead>
                    <tr >
                        <th style="width: 150px; align-items: center !important; width: 120px;" class="text-muted"  >Referans No</th>
                        <th style="width: 150px; align-items: center !important;" class="text-muted" colspan="2" >Açıklama</th>
                        
                        <th style="width: 150px; align-items: center !important; width: 108px" class="text-muted" >YP. Tipi</th>
                        <th style="width: 150px; align-items: center !important; width: 101px" class="text-muted">Adet</th>
                        <th style="width: 150px; align-items: center !important; width: 110px" class="text-muted" >Birim Fiyat</th>
                        <th style="width: 150px; align-items: center !important; width: 110px;" class="text-muted" >Toplam</th>                    
                    </tr>
                </thead>
                <tbody>
                   <tr *ngFor="let item of invoiceInfo?.invoice_parts">
                    <td>{{filterCode(item.part_code)}}</td>
                    
                    <td >{{item.part_name}}</td>
                    <td style="border: 1px solid #ccc; padding:5px;" *ngIf="item.part_unit_type_id == 3" >İşçilik</td>
                    <td style="border: 1px solid #ccc; padding:5px" *ngIf="item.part_unit_type_id != 3">Parça</td>
                    <td>{{item.part_type}}</td>
                    <td style="text-align: right">{{item.part_unit_formatted}}</td>
                    <td style="text-align: right">{{item.part_unit_price_formatted}}&#8378;</td>
                    <td style="text-align: right">{{item.part_no_vat_price_formatted}}&#8378;</td>
                   </tr>
                    
    
                </tbody>
            </table>
        </div>
    </div>
    
    <div class="d-flex justify-content-between" *ngIf="invoiceInfo?.total_amounts">
        <div class="w-100">
            <table class="table amount-info-table" style=" border: 0px !important;">
                <tr>
                    <th colspan="3" style="width: 257px;" ></th>
                    <th style="width: 108px !important;"></th>
                    <th class="text-muted" style="width: 101px !important;">Tutar</th>
                    <th class="text-muted" style="width: 110px !important;">İndirim</th>
                    <th class="text-muted" style="width: 110px !important;">Toplam</th>
                </tr>
                <tr>
                    <th colspan="3"></th>
                    <th class="text-muted">Yedek Parça</th>
                    <td style="text-align: right">{{invoiceInfo?.total_amounts?.total_no_vat_without_labour_formatted}}&#8378;</td>
                    <td style="text-align: right">{{invoiceInfo?.total_amounts?.discount_formatted}}&#8378;</td>
                    <td style="text-align: right">{{invoiceInfo?.total_amounts?.total_no_vat_without_labour_formatted}}&#8378;</td>
                </tr>
                <tr>
                    <th colspan="3"></th>
                    <th class="text-muted">İşçilik</th>
                    <td style="text-align: right">{{invoiceInfo?.total_amounts?.labour_price_formatted}}&#8378;</td>
                    <td style="text-align: right">{{invoiceInfo?.total_amounts?.labour_discount_price_formatted}}&#8378;</td>
                    <td style="text-align: right">{{invoiceInfo?.total_amounts?.labour_without_discount_formatted}}&#8378;</td>
                </tr>
                <tr>
                    <th colspan="3"></th>
                    
                    <th class="text-muted">Kdv</th>
                    <td style="text-align: right">{{invoiceInfo?.total_amounts?.vat}}%</td>
                    <td></td>
                    <td style="text-align: right">{{invoiceInfo?.total_amounts?.vat_price_formatted}}&#8378;</td>
                </tr>
                <tr>
                    <th colspan="3"></th>
                   
                    <th class="text-muted">Genel Toplam</th>
                    <td></td>
                    <td></td>
                    <td style="text-align: right">{{invoiceInfo?.total_amounts?.total_in_vat_with_labour_formatted}}&#8378;</td>
                </tr>
            </table>
        </div>
    </div>

    <div class="d-flex">
        <table class="table desc-info-table" >
            
            <tbody>
                <tr>
                    <th colspan="2" style="text-align: center; color: #96bc33 !important;">Açıklamalar</th>
                </tr>
                <tr>
                    <th>Ödeme Tipi</th>
                    <td>{{invoiceInfo?.invoice_info.payment_type}}</td>
                </tr>
                <tr>
                    <th>Randevu Tarihi</th>
                    <td>{{invoiceInfo?.invoice_info.service_date}}</td>
                </tr>
                <tr>
                    <th>İş Türü</th>
                    <td>{{invoiceInfo?.invoice_info?.order_type}}</td>
                </tr>
                <tr>
                    <th>İş Türü Detayı</th>
                    <td>{{invoiceInfo?.invoice_info?.order_type}}</td>
                </tr>
               
                <tr>
                    <th style="  border: 1px solid #ccc !important;"> Müşteri Notu</th>
                    <td>{{invoiceInfo?.invoice_info?.customer_note}}</td>
                </tr>
                <tr></tr>
            </tbody>
        </table>
    </div>

