<div class="row mb-5">
    <div class="col-xl-3" *ngIf="showLabel">
        <div class="fs-6 fw-bold">İlçe</div>
    </div>
    <div class="col-xl-{{widthClass}} fv-row fv-plugins-icon-container">
        <app-shared-selectwo
        [id]="districtInputId"
        [name]="'İlçe Seçiniz'"
        [setItem]="selectedDistrictId"
        [lists]="districtList"
        (selectedItem)="selected($event)"
    >
    </app-shared-selectwo>
       
       
    </div>
</div>
