// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const firebaseWebApiKey = "AIzaSyCrSfJgi38FUs17vHJeU7r5NslVyaie8IE";
export const environment = {
  production: false,
  appName: "Garantili Oto Servis",
  siteUrl: "https://web.garantiliotoservis.turassist.info/",
  laravelApiEndpoint: "https://api.garantiliotoservis.turassist.info/api/", // "http://localhost:8090/api/user/",
  hasuraHttpApiEndpoint: "https://hasura.garantiliotoservis.turassist.info/v1/graphql", // "http://localhost:8080/v1/graphql", 
  hasuraWsApiEndpoint: "wss://hasura.garantiliotoservis.turassist.info/v1/graphql", // "ws://localhost:8080/v1/graphql",
  firebaseWebApiKey: firebaseWebApiKey,
  firebaseRestSignInEndpoint: `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${firebaseWebApiKey}`,
  firebaseRestSignUpEndpoint: `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${firebaseWebApiKey}`,
  firebaseRestForgotPasswordEndPoint: `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${firebaseWebApiKey}`,
  firebaseRestVerifyPasswordResetEndPoint: `https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${firebaseWebApiKey}`,
  firebaseIdpEndpoint: `https://identitytoolkit.googleapis.com/v1/accounts:signInWithIdp?key=${firebaseWebApiKey}`,
  facebookAppSecret: "87f27fca6b7a6b274a7746ce48005b70",
  facebookAppId: "475130007367429",
  firebaseOauthRedirectEndpoint: `https://rs-group-companies.firebaseapp.com/__/auth/handler`,
  anonymousToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoiQW5vbnltb3VzIiwic3VybmFtZSI6IkFub255bW91cyIsImNvbXBhbnlfaWQiOjEsInJvbGVfdHlwZSI6ImFub255bW91cyIsInJvbGVfc3VidHlwZSI6ImFub255bW91cyIsImh0dHBzOi8vaGFzdXJhLmlvL2p3dC9jbGFpbXMiOnsieC1oYXN1cmEtYWxsb3dlZC1yb2xlcyI6WyJhbm9ueW1vdXMiXSwieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoiYW5vbnltb3VzIiwieC1oYXN1cmEtdXNlci1pZCI6IjEifX0.OG6oAVX0zhdK7FcYZNd3xCTyQFPPy4jZ9I9hYH4hr64",
  cdnToken: "3d9704c68f8be7de9e773f0a5562eec14ae024af3315c1871591616bd7e2d8b7",
  cdnEndpoint: "https://cdn.destechhasar.com/",

  googleMapApiKey: "AIzaSyC43yLMNpGM6CGzCimDNTPCN-e9JvMCY1A",
  googleMapIdentity: "be1413f343b13ba5",
  otoApi: "https://rest.otoapi.com/api/",
  basbugApi: "http://servis.basbug.com.tr/api/",
  gosToken: "3M9704e68s8u7t9773G0e5562ne14z0240Of3315c130827",
  garantiliEmail: "sistem@garantiliotoservis.com",
  denizbankUrl: "https://inter-vpos.com.tr/mpi/Default.aspx",
  denizbank3DUrl: "https://inter-vpos.com.tr/mpi/3DHost.aspx",
  denizShopCode: 8209,
  denizUserCode: "garantilisatis_adm",
  deniz3DKey: "9mIkD",
  labourHourDiscountPercentage: 20
};