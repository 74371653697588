import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Currency } from 'src/app/graphql/currency.graphql';
import { PartOriginalList } from 'src/app/graphql/part-original.graphql';
import { Part, PART_ALL } from 'src/app/graphql/part.graphql';
import { PartUnitType } from 'src/app/graphql/part_unit_type.graphql';
import { PriceUnits } from 'src/app/graphql/price_units.graphql';
import { Vehicle } from 'src/app/graphql/vehicle.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-shared-customer-manuel-parts',
  templateUrl: './customer-manuel-parts.component.html',
  styles: [
  ],
  providers:[PART_ALL]
})
export class CustomerManuelPartsComponent implements OnInit {

  @Input() modelTypeId: BigInt | undefined;
  @Input() vehicleInfo: Vehicle | undefined;
  @Input() selectedParts: any  = [];

  @Output() returnParts:any = new EventEmitter();


  public partList: Part[] | undefined;
  public gosPartId: Number | undefined;
  public gosPartInfo: Part | undefined;
  public partEquivalentList:any = [];
  public partOriginalList: PartOriginalList[] = [];
  public selectedPartUnit: number = 0;
  public selectedPartUnitType: PartUnitType | undefined;
  public partOriginalInfo: PartOriginalList | undefined;
  public selectedPartId = BigInt(0);


  public grandTotal : number = 0;
  public subTotal: number = 0;
  public difference : number = 0;
  public gosMaintenancePrice: number = 0;
  public brandMaintenancePrice: number = 0;
  public currencyList: Currency[] | undefined;
  public activeCurrencyId:number = 1;
  public activeCurrency:PriceUnits | undefined;


  constructor(
    private getPartAll: PART_ALL,
    private alertifyService : AlertifyService,

  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    this.getPartAll.watch({},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {
      this.partList = resp.data.parts;
    });


  
  }

  selectedTwo(type:string,event:any){
    switch(type){

     
      case "parts":

        this.selectedPartId = event;
        
        this.gosPartInfo = this.partList?.filter(response => response.id == event)[0];

      break;

    }
  }



  addPiece(){


    if(this.selectedPartId == BigInt(0)){
      this.alertifyService.error("Lütfen parça Seçiniz");
      return false;
    }
    
    if(this.selectedParts){
      this.selectedParts = this.selectedParts.filter((obj:any)=> obj.selectedGosPart_id != this.selectedPartId);

    }else{
      this.selectedParts = [];
    }
  


    if(this.selectedPartId > BigInt(0)){

      let selectedPartInfo = this.partList?.filter((obj:any)=>{ 
        return obj.id == this.selectedPartId
       });

      if(selectedPartInfo && selectedPartInfo.length > 0){
 
       
              this.selectedParts.push({
                selectedGosPart_id :  selectedPartInfo[0].id,
                selectedGosPart_name: selectedPartInfo[0].name,
                selectedGosPart_groupName: selectedPartInfo[0]?.part_group?.name,
                labourTypeId: null,
                labourValue: null,
                labourDiscount: null,
                brandLabourPrice: null, //* manuel parçada işçilik ürün olarak eklenmekte o yüzden gos ve brand labour price 0 gönderilmektedir.
                gosLabourPrice: null,
                orderId: 0,
                selectedEquivalent_currencyPrice : 0,
                selectedEquivalent_currencyNoVatPrice : 0,
                selectedEquivalent_currencyTotalPrice : 0,
                selectedEquivalent_currencyPriceUnitId : 1,
                selectedEquivalent_currencyNoVatTotalPrice: 0,
                selectedEquivalent_priceTotal: 0,
                selectedEquivalent_priceVat: 0,
                selectedEquivalent_priceNoVatTotal: 0,
                selectedEquivalent_partDiscount: 0, //TODO: buraya daha sonra discount eklenbilir.
                selectedEquivalent_partTypeId: 0,
                selectedEquivalent_partValue: 0,
                selectedEquivalent_partNoVatValue: 0,
                selectedEquivalent_id: 0,
                selectedEquivalent_name: null,
                selectedEquivalent_partTypeName: null,
                selectedEquivalent_priceUnitId: this.activeCurrency?.id,
                selectedEquivalent_priceUnitName: this.activeCurrency?.code,
                selectedEquivalent_partUnitType: null,
                selectedEquivalent_partUnitTypeId: 0,
                selectedEquivalent_partUnit : null,
                selectedEquivalent_partCode : null,
                selectedEquivalent_serviceValue: 0,
                selectedEquivalent_serviceValueUnit: null
              });
            

            this.setTotalAmount();
           }

      }else{
        this.alertifyService.error("Lütfen parça Seçiniz");
        return false;
      }

  }

  searchPart(event:any){
    this.getPartAll.watch({where:{name:{_ilike:"%"+event.target.value+"%"}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {
      this.partList = resp.data.parts;
    })
  }

  setTotalAmount(){


      this.grandTotal = 0;
      this.subTotal = 0;
      this.difference = 0;
      let orderInfo = {};
    
      orderInfo = {
        status_id: 1,
        type_id: 3,
        customer_id: 0,
        vehicle_id: this.vehicleInfo?.id,
        service_date: null,
        order_value: this.grandTotal,
        order_no_vat_value: this.subTotal,
        maintenance_package_id: 0,
        service_id: 0,
        customer_status_id: 2
      }

      this.returnParts.emit({
        amount:{grandTotal: this.grandTotal,
          serviceTotal: this.grandTotal, 
          subTotal: this.subTotal, 
          difference: this.difference},
          parts:this.selectedParts,
          orderInfo: orderInfo});

  }

  removePiece(selectedPartId:Number,equivalentId:Number){
    
        
        if(selectedPartId == 0 || equivalentId == 0){
          this.alertifyService.error("Bir hata oluştu");
          return false;
        }
    
        if(this.selectedParts && this.selectedParts.length > 0){
          this.selectedParts = this.selectedParts.filter((obj:any)=>obj.selectedGosPart_id != selectedPartId);
        }
    
        /*
        if(this.partEquivalentList && this.partEquivalentList.length > 0){
          this.partEquivalentList = this.partEquivalentList.filter((obj:any)=>obj.selectedGosPart.id != selectedPartId);
        }*/
    
        let rdbtn = document.getElementsByName("part_"+selectedPartId);
    
        if(rdbtn != null && rdbtn.length > 0){
         
          rdbtn.forEach((obj:any) => {
            if(obj.checked){
              obj.checked = false;
            }
          });
          
        }
    
        this.setTotalAmount();
    
      }

     

}
