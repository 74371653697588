<div class="w-100" style="overflow: hidden;
                            overflow-x: scroll;">
                                
                                <label class="d-flex align-items-center form-label mb-5">Parça Seçiniz
                                    <i class="fas fa-exclamation-circle ms-2 fs-7"
                                        aria-label="Monthly billing will be based on your account plan"
                                        title="Birden fazla seçim yapabilirsiniz."></i>
                                </label>
                                <div class="card card-flush py-4 flex-row-fluid" >
                                   
                                    <!--begin::Card body-->
                                    <div class="card-body pt-0 ">
                                        <div class="table-responsive">
                                            <app-shared-maintenance-package
                                            [modelTypeId]="vehicleInfo?.model_type_id"
                                            [gosLabourPrice]="gosLabourPrice"
                                            [brandLabourPrice]="brandLabourPrice"
                                            [vehicleInfo]="vehicleInfo"
                                            [packageId]="maintenancePackageId"
                                            (returnParts)="setOrderParts($event)"

                                            >
                                            </app-shared-maintenance-package>
                                            
                                        </div>
                                    </div>
                                    <!--end::Card body-->
                                </div>
                            </div>