import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { Company, COMPANY_ALL, COMPANY_COUNT } from 'src/app/graphql/company.graphql';
import { COMPANY_GSM_ALL } from 'src/app/graphql/company_gsm.graphql';
import { Currency, CURRENCY_ALL } from 'src/app/graphql/currency.graphql';
import { Garage, GARAGES_ALL, GARAGE_CREATE } from 'src/app/graphql/garages.graphql';
import { Gsm, GSM_ALL } from 'src/app/graphql/gsm.graphql';
import { Order, ORDER_CREATE, ORDER_ID, ORDER_UPDATE, UPDATE_ORDER_STATUS } from 'src/app/graphql/order.graphql';
import { OrderCardPosSubs, OrderCards, ORDER_CARDS_ALL, ORDER_CARDS_CREATE, ORDER_CARDS_UPDATE, ORDER_CARD_PAYMENT_SUBS } from 'src/app/graphql/order_cards.graphql';
import { OrderCardInvoiceInfo, ORDER_CARD_INVOICE_INFO_CREATE, ORDER_CARD_INVOICE_INFO_ONE, ORDER_CARD_INVOICE_INFO_UPDATE } from 'src/app/graphql/order_card_invoice_info.graphql';
import { OrderCardParts, ORDER_CARD_PARTS_ALL, ORDER_CARD_PARTS_CREATE, ORDER_CARD_PARTS_DELETE, ORDER_CARD_PARTS_UPDATE } from 'src/app/graphql/order_card_parts.graphql';
import { ORDER_INVOICE_CREATE } from 'src/app/graphql/order_invoices.graphql';
import { ORDER_INVOICE_INFO_CREATE } from 'src/app/graphql/order_invoice_info.graphql';
import { ORDER_INVOICE_PART_CREATE } from 'src/app/graphql/order_invoice_parts.graphql';
import {  ORDER_PART_CREATE_ONE } from 'src/app/graphql/order_part.graphql';
import { OrderType, ORDER_TYPE_ALL } from 'src/app/graphql/order_type.graphql';
import { PackageDetail, PACKAGE_DETAIL_ALL, PACKAGE_DETAIL_ID } from 'src/app/graphql/package-detail.graphql';
import { Package, PACKAGE_ALL, PACKAGE_ID } from 'src/app/graphql/package.graphql';
import { PackageKms, PACKAGE_KMS_ALL, PACKAGE_KMS_BY_MODEL_ID } from 'src/app/graphql/package.kms';
import { PackageModelType, PACKAGE_MODEL_TYPE_ALL, PACKAGE_MODEL_TYPE_PAGINATE, PACKAGE_MODEL_TYPE_PARTS_ALL } from 'src/app/graphql/package_model_type.graphql';
import { PartGroup, PART_GROUP_ALL } from 'src/app/graphql/part-group.graphql';
import { PartOriginalList, SEARCH_PART_ORIGINIALS_WITH_EQUIVALENTS } from 'src/app/graphql/part-original.graphql';
import { PART_GROUP_LIST_ALL } from 'src/app/graphql/part_group_list.graphql';
import { PaymentTypes, PAYMENT_TYPES_ALL } from 'src/app/graphql/payment_types.graphql';
import { User, USER_GET_ID } from 'src/app/graphql/user.graphql';
import { UserAddress, USER_ADDRESS_ALL } from 'src/app/graphql/user_address.graphql';
import { VehicleBrand, VEHICLE_BRAND_ALL } from 'src/app/graphql/vehicle-brand.graphql';
import { VehicleModelType, VEHICLE_MODEL_TYPE_ALL } from 'src/app/graphql/vehicle-model-type.graphql';
import { VehicleModelYear, VEHICLE_MODEL_YEAR_ALL } from 'src/app/graphql/vehicle-model-year.graphql';
import { VehicleModel, VEHICLE_MODEL_ALL } from 'src/app/graphql/vehicle-model.graphql';
import { Vehicle, VEHICLES, VEHICLE_CREATE, VEHICLE_ID, VEHICLE_UPDATE_CUSTOM } from 'src/app/graphql/vehicle.graphql';
import { MechanicParts, MECHANIC_PARTS_ALL, MECHANIC_PARTS_BY_MODEL_TYPE_ID } from 'src/app/graphql/view-vehicle-mechanic-parts.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { MailService } from 'src/app/services/mail.service';
import { PaymentService } from 'src/app/services/payment.service';


@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styles: [
  ],
  providers:[
    ORDER_TYPE_ALL,
    VEHICLE_MODEL_ALL,
    VEHICLE_MODEL_YEAR_ALL,
    VEHICLE_MODEL_TYPE_ALL,
    GARAGES_ALL,
    VEHICLE_BRAND_ALL,
    COMPANY_ALL,
    COMPANY_COUNT,
    PACKAGE_KMS_ALL,
    PACKAGE_DETAIL_ALL,
    VEHICLE_CREATE,
    GARAGE_CREATE,
    PACKAGE_MODEL_TYPE_PAGINATE,
    VEHICLES,
    PACKAGE_ALL,
    ORDER_CREATE,
    ORDER_PART_CREATE_ONE,
    USER_GET_ID,
    PACKAGE_MODEL_TYPE_ALL,
    PACKAGE_MODEL_TYPE_PARTS_ALL,
    PART_GROUP_ALL,
    MECHANIC_PARTS_BY_MODEL_TYPE_ID,
    USER_ADDRESS_ALL,
    GSM_ALL,
    ORDER_INVOICE_INFO_CREATE,
    COMPANY_GSM_ALL,
    VEHICLE_UPDATE_CUSTOM,
    CURRENCY_ALL,
    ORDER_ID,
    ORDER_INVOICE_PART_CREATE,
    ORDER_INVOICE_CREATE,
    ORDER_UPDATE,
    ORDER_CARDS_ALL,
    ORDER_CARDS_CREATE,
    ORDER_CARDS_UPDATE,
    ORDER_CARD_PARTS_DELETE,
    ORDER_CARD_PARTS_ALL,
    ORDER_CARD_PARTS_UPDATE,
    ORDER_CARD_PARTS_CREATE,
    ORDER_CARD_INVOICE_INFO_ONE,
    ORDER_CARD_INVOICE_INFO_UPDATE,
    ORDER_CARD_INVOICE_INFO_CREATE,
    VEHICLE_ID,
    PACKAGE_KMS_BY_MODEL_ID,
    PACKAGE_ID,
    ORDER_CARD_PAYMENT_SUBS,
    SEARCH_PART_ORIGINIALS_WITH_EQUIVALENTS,
    PAYMENT_TYPES_ALL
  ]
})
export class OfferComponent implements OnInit {
  public step:number=1;
  public orderTypeList:OrderType[] | undefined;
  public orderForm: any[] = [];
  public vehicleModels: VehicleModel[] | undefined
  public vehicleModelTypes: VehicleModelType[] = [];
  public vehicleBrands: VehicleBrand[] = [];
  public vehicleTab: String = "new"
  public vehicleId: any;
  public garageList: Garage[] = [];
  public authTab: String = "login"
  public selectedServiceId:bigint = BigInt("0");
  public vehicleList: Vehicle[] | undefined;
  public vehicle: Vehicle | undefined;
  public showEsdeger2 = false;


  public brandName:string = 'Marka';
  public modelName:string = 'Model';
  public modelTypeName:string = 'Model Tipi';
  public selectedKms:String = "Km";
  public selecteEditYear:String = "Yıl";
  public selectedModel:any;
  public selectedBrand:any;
  public vehicleModelYearList: VehicleModelYear[] | undefined;
  public selectedYear:any = "Yıl";
  public selectedCityId:Number = 0;
  public selectedDistrictId:Number = 0;
  public companyList: Company[] | undefined;
  private where: object = {"active":{"_eq":true}};
  public dataCount: number = 0;
  public offset: number = 0;
  public packageKms: PackageKms[] | undefined;
  public vehicleInfo:Vehicle | undefined;
  public selectedParts:any= [];
  public selectedPackageInfo: any;
  private selectedPackageId: bigint = BigInt("0");
  public showOldVehicles:boolean = false;
  private gotoPackageDetail: boolean = false;
  public todayWithPipe: any;
  public customPackageList: any= [];
  public paymentSuccess: boolean = false;
  
 
  

public packageList: Package[] | undefined;
public packageListFiltered: Package[] | undefined;
public packageDetailList: PackageDetail[] | undefined;
public customPackageDetailList:any = [];
public customPartList:any = [];
public garageId:number=0;

public selectedPieces:any = []
public selectedPiecesWithIndex:any = []
public selectedCompaignProducts:any = [];
public selectedUncompaignProducts:any = [];
public selectedPartsWithIndex:any = []
public selectedEquivalentParts:any = []
public totalAmount:number=0;
public labourAmount:number=0;
public gosMaintenancePrice: number = 0;
public brandMaintenancePrice: number = 0;
public subTotal:number = 0;
public discount:number = 0;
public vat:number = 0;
public garageName:String = "Garaj Seçiniz";
public orderId:bigint = BigInt("0");
public next = "Sonraki";
public bigintValue: bigint = BigInt(1);
public yetkili_original_part_type_id:number = 5;
public partGroups: PartGroup[] | undefined;
public mechanicPartList: MechanicParts[] | undefined;
public orderDetail: Order | undefined;
public showPosMessage: boolean= false;

public steppers = {
  teklif_onayi_1: false,
  teklif_onayi_2: false,
  order_status: false
}



public navigationList = {
  before:{id:0,title:""},
  current:{id:0,title:""},
  active:{id:0,title:""}
}

  public checkOffer = {
    check1: false,
    check2: false,
    check3: false,
    done: false
  }

  public vehicleForm = {
    name:"",
    brand_id: 0,
    model_id: 0,
    model_type_id: 0,
    model_year_id: 0,
    garage_id:0
  }


  public packageForm = {
    
  }

  public garageFrom = {
    name:"Garajım",
    user_id:0,
    active:true
  }

  public momentOrderId:any;

  paymentFormGroup = new FormGroup({
    Expire: new FormControl('', [Validators.required]),
    Pan: new FormControl('', [Validators.required,Validators.maxLength(11),Validators.minLength(11)]),
    Cvv2: new FormControl('', [Validators.required,Validators.maxLength(3),Validators.minLength(3)]),
    CardType: new FormControl('', [Validators.required]),
    ShopCode: new FormControl('', [Validators.required]),
    PurchAmount: new FormControl('', [Validators.required]),
    Currency: new FormControl('', [Validators.required]),
    OrderId: new FormControl('', [Validators.required]),
    OkUrl: new FormControl('', [Validators.required]),
    FailUrl: new FormControl('', [Validators.required]),
    Rnd: new FormControl('', [Validators.required]),
    Hash: new FormControl('', [Validators.required]),
    TxnType: new FormControl('', [Validators.required]),
    InstallmentCount: new FormControl(''),
    SecureType: new FormControl('', [Validators.required]),
    Lang: new FormControl('', [Validators.required])

  });

  public steps={
    "hizmet_tipi":{id:1,title:"Hizmet Tipi"},
    "arac_secimi":{id:2,title:"Araç Seçimi"},
    "paket_secimi":{id:3,title:"Paket Seçimi"},
    "login":{id:4,title:"Login"},
    "teklif_al":{id:5,title:"Teklif Al"},
    "servis_secimi":{id:6,title:"Servis Seçimi"},
    "adres_secimi":{id:7,title:"Adres Seçimi"},
    "faturalandir":{id:8,title:"Fatura Al"},
    "parca_secimi":{id:9,title:"Parça Seçimi"},
    "faturalandir_mekanik":{id:10,title:"Siparişi Tamamla (Mekanik)"},
    "siparisi_tamamla": {id:11,title:"Siparişi Tamamla"},
    "odeme":{id:12,title:"Ödeme"},
    "mail_ile_teklif_iste": {id:13,title:"Tekli iste"},
    "mail_teklif_al_basarili": {id:14,title:"Teklif alındı"},
    "manuel_parca_secimi": {id:15,title:"Parça Seçimi"},
    "faturalandir_manuel_parca": {id:16,title:"Siparişi Tamamla (Manuel Parça)"}
  }

  //Step1 Variables
  //public orderServiceList:any=[];
  public orderServiceId:Number=0;
  public selectedAddressId:BigInt | undefined;

  //Step7 Variables
  public service_type_id:bigint=BigInt("0");
  public serviceDate:any;
  public serviceLastDate:any;
  public todayDate:any;

 //public packageForm = [];

  public prices = [];
  public userInfo: User | undefined;

  public cheapPieces:any = [];
  public getCustomPackageList: any= [];

  public addressList:UserAddress[] | undefined;
  public selectedAddressInfo:any;
  public drawerTitle:string = "Adres Ekle";
  public drawerPage:string = "";
  public showDrawer:boolean = false;

  public invoiceInfo:any = [];
  public gsmList:any  = [];
  public companyGsmList:any  = [];
  public isCompany:boolean = false;
  public audio:any;
  public currencyList: Currency[] | undefined;
  public isPayed: Boolean = false;
  private orderCards: OrderCards | undefined;
  public subOrderCards: OrderCards | undefined;
  public orderCardId:bigint | undefined;
  public orderCardInvoiceInfo: OrderCardInvoiceInfo[] | undefined;
  public mictime : Number | undefined;
  public ShopCode = "3123";  //Banka tarafindan verilen isyeri numarasi
  public PurchAmount = "5.64";         //Islem tutari
  public Currency = "949"; // Kur Bilgisi - 949 TL
  public OrderId = "";      //Siparis Numarasi
  public OkUrl = "https://api.garantiliotoservis.com/paytestreturn";      //Islem basariliysa dönülecek isyeri sayfasi  (3D isleminin ve ödeme isleminin sonucu)
  public FailUrl = "https://api.garantiliotoservis.com/paytestreturn";	//Islem basarisiz ise dönülecek isyeri sayfasi (3D isleminin ve ödeme isleminin sonucu)
  public InstallmentCount = "";         //taksit sayisi
  public TxnType ="Auth";     //Islem tipi
  public MerchantPass = "gDg1N";  //isyeri 3D anahtari
  public hash : String | undefined;
  public rnd: any;
  public orderCardPartList: OrderCardParts[] | undefined;
  private testAddress = "https://test.inter-vpos.com.tr/mpi/Default.aspx";
  public showOrderSpinner: boolean = false;
  public paymentTypes: PaymentTypes[] | undefined;
  public selectedPaymentTypes: Number | undefined;
  public maintenanceKmsLists: PackageKms[] = [];

  public paymentFailMessage :String= "";
  @ViewChild('paymentForm') 
  paymentFormElement!: ElementRef; 

  public orderCardPosStatus: OrderCardPosSubs | undefined;
  public manuelPartList : PartOriginalList[] | undefined;
  constructor(
    public authService: AuthService,
    private alertifyService: AlertifyService,
    private getOrderTypes: ORDER_TYPE_ALL,
    private getVehicleModels: VEHICLE_MODEL_ALL,
    private vehicleModelYears:VEHICLE_MODEL_YEAR_ALL,
    private getVehicleModelTypes: VEHICLE_MODEL_TYPE_ALL,
    private getGarages: GARAGES_ALL,
    private getVehicleBrand: VEHICLE_BRAND_ALL,
    private getCompany: COMPANY_ALL,
    private getCompanyCount: COMPANY_COUNT,
    private getPackageKms: PACKAGE_KMS_ALL,
    private getPackageDetails: PACKAGE_DETAIL_ALL,
    private createVehicle: VEHICLE_CREATE,
    private createGarage: GARAGE_CREATE,
    private getPackages: PACKAGE_ALL,
    private getVehicles: VEHICLES,
    private getVehicleId: VEHICLE_ID,
    private createOrder: ORDER_CREATE,
    private createOrderpart: ORDER_PART_CREATE_ONE,
    private getUser: USER_GET_ID,
    private getPackageModelTypes: PACKAGE_MODEL_TYPE_ALL,
    private getMechanicParts: MECHANIC_PARTS_BY_MODEL_TYPE_ID,
    private getPartGroups: PART_GROUP_ALL,
    private getUserAddressList: USER_ADDRESS_ALL,
    private getGsms: GSM_ALL,
    private createInvoiceInfo: ORDER_INVOICE_INFO_CREATE,
    private getCompanyGsms: COMPANY_GSM_ALL,
    private updateVehicle: VEHICLE_UPDATE_CUSTOM,
    private getCurrency: CURRENCY_ALL,
    private getOrder: ORDER_ID,
    private createOrderInvoicePart: ORDER_INVOICE_PART_CREATE,
    private createOrderInvoice: ORDER_INVOICE_CREATE,
    private updateOrder: ORDER_UPDATE,
    private getOrderCards: ORDER_CARDS_ALL,
    private createOrderCards: ORDER_CARDS_CREATE,
    private updateOrderCards: ORDER_CARDS_UPDATE,
    private deleteOrderCardParts: ORDER_CARD_PARTS_DELETE,
    private getOrderCardParts: ORDER_CARD_PARTS_ALL,
    private updateOrderCardParts: ORDER_CARD_PARTS_UPDATE,
    private createOrderCardParts: ORDER_CARD_PARTS_CREATE,
    private getOrderCardInvoiceInfo: ORDER_CARD_INVOICE_INFO_ONE,
    private updateOrderCardInvoiceInfo: ORDER_CARD_INVOICE_INFO_UPDATE,
    private createOrderCardInvoiceInfo: ORDER_CARD_INVOICE_INFO_CREATE,
    private PaymentService: PaymentService,
    private getPackageId: PACKAGE_ID,
    private orderCardPaymentSubs: ORDER_CARD_PAYMENT_SUBS,
    private mailService: MailService,
    public searchPartOriginals: SEARCH_PART_ORIGINIALS_WITH_EQUIVALENTS,
    private getPaymentTypes: PAYMENT_TYPES_ALL,
    private getKmsByModelId: PACKAGE_KMS_BY_MODEL_ID
  ) { }

  ngOnInit(): void {  

    
    moment.locale('tr');
    this.serviceLastDate = moment().add(1, 'days').endOf('day').format('LLL');
    this.todayWithPipe = moment().add(1, 'days').endOf('day').format('yyyy-MM-DDTH:mm');

    let dd = new Date();
    this.mictime = dd.getMilliseconds();

    this.todayDate = moment().format('LL');
    this.getUserInfo();

    this.getCurrency.watch().valueChanges.subscribe((result:any)=>{
      if(result.errors){
        this.alertifyService.error(result.errors[0].message);
      }

      if(result.data){
        this.currencyList = result.data.currency;
      }
    });

  

    this.getVehicleBrand.watch().valueChanges.subscribe((result:any)=>{
      this.vehicleBrands = result.data.vehicle_brands
      
    })

    this.getPaymentTypes.watch({where:{active:{_eq:true}}}).valueChanges.subscribe((result:any)=>{
      if(result.errors){
        this.alertifyService.error(result.errors[0].message);
      }
      if(result.data){
        this.paymentTypes = result.data.payment_types;
        if(this.paymentTypes){
        this.selectedPaymentTypes = this.paymentTypes[0].id;
      }
      }
     
    })

    

  /*  if(this.authService.isLogin && this.orderCards == undefined){
      this.checkForOrderCard();
    }*/

    this.invoiceInfo.is_same_with_customer = true;

   this.setOrderCardId();
    
  }

  setOrderCardId(){
    if(this.authService.isLogin){
      this.getOrderCards
      .watch({where:{customer_id:{_eq:this.authService.userId},is_complete:{_eq:false}}},{fetchPolicy:"no-cache"})
      .valueChanges
      .subscribe(response => {
        if(response.errors){
          this.alertifyService.error(response.errors[0].message);
        }
        if(response.data){
          this.orderCards = response.data.order_cards[0];
          this.orderCardId = this.orderCards?.id;
  
        
        }
      })
    }
    

  }

  checkForOrderCard(){

   // this.orderServiceId = 1;
    
    this.getOrderCards.watch({where:{customer_id:{_eq:this.authService.userId},is_complete:{_eq:false}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
      if(response.errors){
        this.alertifyService.error(response.errors[0].message);
      }
      if(response.data){
        if(response.data.order_cards.length > 0){
          this.orderCardId = response.data.order_cards[0].id;

          this.orderCards = response.data.order_cards[0];
          if(this.orderCards != undefined){

            if(this.orderCards.type_id > 0){
              this.orderTypeChange(this.orderCards.type_id);
              if(this.orderCards.vehicle_id > 0){
                this.vehicleId = this.orderCards.vehicle_id;

                this.getVehicleId.watch({id:this.vehicleId},{fetchPolicy:"no-cache"}).valueChanges.subscribe((res:any) => {
                  if(res.errors){
                    this.alertifyService.error(res.errors[0].message);
                  }
                  

                  if(res.data){
                    this.vehicle = res.data.vehicles_by_pk;
                    this.chooseVehicle(this.vehicleId,this.vehicle,BigInt(0),false);
                    this.stepChange(this.steps.arac_secimi.id,true,false);
             
                    

                  }
                })

              }

            }
          //  this.orderServiceId = this.orderCards?.type_id;
           // this.step = this.orderCards.step;

          }

          
        }
      }
      
    })
  }

  getCompanyGsmList(){
    this.companyGsmList = [];
    this.companyGsmList.push({id:"0",name:"Yeni Cep Telefon Ekle"});
    this.getCompanyGsms.watch().valueChanges.subscribe((result:any)=>{
      if(result.errors){
        this.alertifyService.error("bir hata oluştu");
      }
      if(result.data){
        let list = result.data.company_gsms;
        list.filter((gsm:any) => {
          let gsmName:string = gsm.name + " " + gsm.code;
          this.companyGsmList.push({id:gsm.id,name:gsmName});
          if(this.invoiceInfo.gsm_id === gsm.id){
            this.invoiceInfo.gsmName = gsmName;
          }
        });
      }
    })
  }


  getUserGsmList(){
    this.gsmList = [];
    this.gsmList.push({id:"0",name:"Yeni Cep Telefon Ekle"});
    this.getGsms.watch({where:{user_id:{"_eq":this.authService.userId}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
      if(result.errors){
        this.alertifyService.error("bir hata oluştu");
      }
      if(result.data){
        let list = result.data.gsms;
       list.filter((gsm:any) => {
          let gsmName:string = gsm.name + " " + gsm.code;
          this.gsmList.push({id:gsm.id,name:gsmName,code:gsm.code});
          if(this.invoiceInfo.gsm_id === gsm.id){
            
            this.invoiceInfo.gsmName = gsmName;
            this.invoiceInfo.gsm_number = gsm.code;
          }
        });
      }
    })
   }


   getChipPrices(equivalentList:any){
    if(equivalentList.length == 0){
      return 0;
    }
    let price = 0;

    for(let item of equivalentList){
      
      let piecePrice = item.part_no_vat_price;
      if(price == 0){
        price = piecePrice;
      }else{
        if(piecePrice < price){
          price = piecePrice;
          
        }
      }
    }

return price;
    
  }

  ngAfterViewInit(): void {
    //orderTypeList
    this.getOrderTypes.watch({where:{active:{_eq:true}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
      this.orderTypeList = result.data.order_types;
    })

     // Get Package Kms
     this.getPackageKms.watch().valueChanges.subscribe(response => {
      this.packageKms = response.data.maintenance_kms;
    })
  }

  getSmallAndBig(list:any){
    //  const prices:any = [];
      let totalPrice:number = 0;
      if(list.maintenance_package_details !== undefined && Object.keys(list.maintenance_package_details).length > 0){
        for (let i = 0; i < Object.keys(list.maintenance_package_details).length; i++) {
       
          let equivalentInfo = list.maintenance_package_details[i].part_original_list.part_equivalent_lists[0];
          if(equivalentInfo == undefined){
            continue;
          }
  
          let priceWithVat:number = equivalentInfo.part_in_vat_price;
          let priceNoVat:number = equivalentInfo.part_no_vat_price;
  
          if(equivalentInfo.price_unit_id != 1){
            let currentCurrency = this.currencyList?.filter(i => i.price_unit_id == equivalentInfo.price_unit_id);
  
            if(currentCurrency){
              priceWithVat = (currentCurrency[0].currency_value * equivalentInfo.part_in_vat_price);
              priceNoVat = (currentCurrency[0].currency_value * equivalentInfo.part_no_vat_price);
            }
          }

          priceNoVat = ( priceNoVat *  list.maintenance_package_details[i].part_unit);
    
          if(totalPrice == 0){
            totalPrice = priceNoVat;
          }else{
            totalPrice += priceNoVat;
          }
  
        }
  
      }
  
      
      return totalPrice.toFixed(2) + "&#8378; başlayan fiyatlar ile";
    }

 

   stepChange(step: number, isContinue: Boolean,stepChoise:any=undefined) {
    // this.step 1 geriden geliyor sebebi ise step geçişlerindeki kontrol
   this.next = "Sonraki";
    if(this.orderServiceId == 0){
      this.step = this.steps.hizmet_tipi.id;
      this.alertifyService.error("En az bir tane hizmet seçmelisiniz");
      return false;
    }
    if(this.authService.isLogin === false && step > this.steps.paket_secimi.id){
      this.step = this.steps.login.id;
      this.alertifyService.error("Lütfen giriş yapınız");
      return false;
    }
    if(this.authService.isLogin === true){
      this.showOldVehicles = true;
    }

   // this.audio = new Audio();
    //this.audio.src = "./assets/sounds/car-pass.wav";
    //this.audio.load();
   /* let playPromise =  this.audio.play()
    if (playPromise !== undefined) {
      playPromise.then(function(abc:any) {
      // Automatic playback started!
     
      }).catch(function(error:any) {
      // Automatic playback failed.
      // Show a UI element to let the user manually start playback.
      });

    }*/
    switch (this.step) {
      case this.steps.hizmet_tipi.id://Hizmet Seçimi
      this.getGarage();
        this.step = this.steps.arac_secimi.id;

        if(this.authService.currentUser.name == "Anonymous"){
          this.authService.isLogin = false;
        }
        if(this.authService.isLogin === true){
          this.vehicleTab = "old"
          this.showOldVehicles = true
          if(this.orderCardId != undefined && this.orderCardId > 0){
            this.updateOrderCards.mutate({
              id:this.orderCardId,
              changes:{
                type_id:this.orderServiceId,
                step: 1,}
            }).subscribe(response => {
              
            });
          }
          /*else{
            this.createOrderCards.mutate({
              object:{
                type_id:this.orderServiceId,
                customer_id: this.authService.userId,
                step: 1,}
            }).subscribe(response => {
              
            });
          }*/

         // this.checkForOrderCard();
         
            
        }else{
          this.vehicleTab = "new"
          this.showOldVehicles = false
        }

        this.step = this.steps.arac_secimi.id;
            if(this.orderCardId != undefined && this.orderCardId > 0 ){
              if( this.orderCards?.maintenance_package_id != null && this.orderCards?.maintenance_package_id > 0){
                this.getPackageId.watch({id:this.orderCards.maintenance_package_id},{fetchPolicy:"no-cache"}).valueChanges.subscribe(pckRes => {
                  if(pckRes.errors){
                    this.alertifyService.error(pckRes.errors[0].message);
                  }
                  if(pckRes.data){
                    
                  //  this.setPackageInfo(this.orderCards?.maintenance_package_id,pckRes.data.maintenance_packages_by_pk);
                   // this.stepChange(this.steps.paket_secimi.id,true,false);

                    this.getOrderCardParts.watch({where:{order_card_id:{_eq:this.orderCardId}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(cardPartRes => {
                      if(cardPartRes.errors){
                        this.alertifyService.error(cardPartRes.errors[0].message);
                      }
                      if(cardPartRes.data){
                        this.orderCardPartList = cardPartRes.data.order_card_parts;
                        if(this.orderCardPartList.length > 0){
                          // this.stepChange( this.steps.paket_secimi.id,true,false)
                          // this.step = this.steps.paket_secimi.id;
                          
                          let ths = this;
                          this.orderCardPartList.forEach(function(val:any){

                            /* let obj = {
                            
                              part_type_id: val.part_type_id,
                              package_detail_id: val.package_detailid,
                              partTypeName: partTypeName,
                              selected_part_id: val.selected_,
                              price: priceWithVat,
                              price_no_vat: priceNoVat,
                              name: equivalentInfo.name,
                              part_id: list.maintenance_package_details[i].part_id,
                              service_price: equivalentInfo.service_price,
                              type: type,
                              part_code: equivalentInfo.part_code,
                              selectAll: false,
                              package_id:list.maintenance_package_details[i].package_id
                            };

                            ths.pieceChange(
                              item,
                              0,
                              item.type,
                              false,
                              true
                          )*/
                          //  this.pieceChange();
                          })

                        }
                      }
                    })
                  }
                })
                  
              }
            }


        break;
      
      case this.steps.arac_secimi.id://Araç Seçimi

        if(isContinue){

          if(!this.authService.isLogin){
            this.step = this.steps.login.id;
            return false;
          }
  

          if(this.vehicleForm.model_type_id>0){
          


                if(this.orderServiceId == 1){ //periyodik bakım
                  this.getPackageModelTypes.watch({where:{model_type_id:{_eq:this.vehicleForm.model_type_id}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
                    if(response.error){
                      this.alertifyService.error("Bir hata oluştu. Lütfen tekrar deneyiniz");
                      return 0;
                    }
                 
                    if(response.data){
                      let modeltypes = response.data.maintenance_package_model_types;
                
                      if(modeltypes.length > 0){
                        

                     //   this.createIfCarNotExist();
                        this.vehicleTab = "old"
                        this.showOldVehicles = true
                        this.step = (this.gotoPackageDetail) ? this.steps.teklif_al.id : this.steps.paket_secimi.id;
                        this.gotoPackageDetail = false;
  

                       /* this.getKmsByModelId.watch({model_type_id:this.vehicleForm.model_type_id},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {

                          if(resp.errors){
                            this.alertifyService.error(resp.errors[0].message);
                          }

                          if(resp.data){
                            this.maintenanceKmsLists = resp.data.maintenance_kms;
                          }

                        })*/
  
                        this.getPackageModelTypes.watch({where:{model_type_id:{_eq:this.vehicleForm.model_type_id}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
                          if(response.error){
                            this.alertifyService.error("Bir hata oluştu. Lütfen tekrar deneyiniz");
                            return false;
                          }
              
                          if(response.data){


                            let modeltypes = response.data.maintenance_package_model_types;
                            if(modeltypes.length == 0){
                            //  this.alertifyService.error("Bu araç için bakım paketi bulunamadı");
                            if(this.authService.isLogin){
                              this.teklif_al_mail();
                              this.step = this.steps.mail_teklif_al_basarili.id;

                            }else{
                              this.step =this.steps.login.id;
                            }
                              return false;
                            }
                            let packageIds:any = [];
                            modeltypes.forEach((element:any) => {
                              packageIds.push(element.package_id);
                            });
                            if(packageIds.length > 0){
                              this.getPackages.watch({where:{id:{_in:packageIds}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(packageResponse => {
                                if(packageResponse.error){
                                  this.alertifyService.error("Bir hata oluştu. Lütfen tekrar deneyiniz");
                                  return false;
                                }
              
                                if(packageResponse.data){
                                  this.packageList = packageResponse.data.maintenance_packages;
                                  this.packageListFiltered = undefined;
                              
                    
                                  //? bazı bakım paketlerinde ürünlerin part-equivalnets boş geldiğinden dolayı "lütfen bütün parçaları seçiiniz hatası veriyordu. hatalı bakım paketlerini paket içerisinden çıkardık. yönetim paneline hatalı bakım paketleri diye bir bölüm açacağız"
                                  this.packageList.forEach((v,k)=>{
                                    this.maintenanceKmsLists.push(v.maintenance_kms);
                                    
                                      v.maintenance_package_details.forEach((val:any)=>{
                                        if(val.part_original_list.part_equivalent_lists.length == 0){
                                          this.packageList = this.packageList?.filter((pObj:any) => {
                                            return pObj.id != v.id
                                          });
                                        }
                                      })
                                      
                                    });


                                 
            
                                  if(isContinue){
              
                                    this.step = this.steps.paket_secimi.id;

                                  }else{
                                    this.step = this.steps.arac_secimi.id
                                  }
                                }
                              })
                            }
                          }
                        })
  
                      }else{
                      //  this.alertifyService.error("Bu araç için bakım paketi bulunamadı");
                        if(this.authService.isLogin){
                          this.teklif_al_mail();
                          this.step = this.steps.mail_teklif_al_basarili.id;
                        }else{
                          this.step = this.steps.login.id;
                        }
                        
  
                      }
                      
                    }
                    });
                } else if(this.orderServiceId == 2){//mekanik bakım
       
                  if(this.authService.isLogin){
                    this.getPackageList();
              
                    if(isContinue){
                      this.step = this.steps.parca_secimi.id;
                    }else{
                      this.step = this.steps.arac_secimi.id;
                    }
                  }else{
                    this.step = this.steps.login.id;
                  }

                  
                  
                } else if(this.orderServiceId == 3){ // manuel parça seçimi

                  if(this.authService.isLogin){
                 
                    if(isContinue){
                      this.step = this.steps.manuel_parca_secimi.id;
                    }else{
                      this.step = this.steps.arac_secimi.id;
                    }
                  }else{
                    this.step = this.steps.login.id;
                  }

                }

                if(this.orderCardId != undefined && this.orderCardId > 0){
                  this.updateOrderCards.mutate({
                    id:this.orderCardId,
                    changes:{
                      vehicle_id:this.vehicleId,
                      step: 2,}
                  }).subscribe(response => {
                    
                  });
                }
                
              }else{
                this.alertifyService.error("Lütfen araç tipi seçiniz");
              }
        }else{
         
          this.step = this.steps.hizmet_tipi.id;
        }
      break;

      case this.steps.parca_secimi.id://Parça Seçimi
      if(isContinue){

        /*
         status_id: 1,
              type_id: this.orderServiceId,
              customer_id: this.authService.userId,
              vehicle_id: this.vehicleId,
              service_date: this.serviceDate+":00",
              order_value: this.totalAmount,
              order_no_vat_value: this.subTotal,
              service_value: this.totalAmount,
              maintenance_package_id: this.selectedPackageId,
              service_id: this.selectedServiceId,
              customer_status_id: 14 //müşteri onayı verildi
        */


        if(this.orderCardId != undefined && this.orderCardId > 0){
          this.updateOrderCards.mutate({
            id:this.orderCardId,
            changes:{
              step: 4,
              service_date: null,
              order_value: this.totalAmount,
              order_no_vat_value: this.subTotal,
              service_id: null,
              customer_status_id: 14
            }
          }).subscribe(response => {
            
          });

          
      }else{


        this.createOrderCards.mutate({
          object:{
            type_id:this.orderServiceId,
            step: this.steps.parca_secimi.id,
            customer_id: this.authService.userId,
            vehicle_id:this.vehicleId}}).subscribe((response:any)=>{
          if(response.errors){
            this.alertifyService.error(response.errors[0].message);
          }
          if(response.data){
            this.orderCardId = response.data.insert_order_cards_one.id
          }
        });
        

      }
        this.selectedPiecesWithIndex = this.selectedPieces.filter((obj:any) => (obj.part_type_id > 0 || obj.part_unit_type > 0));

              if(this.selectedPiecesWithIndex.length == 0){
                this.alertifyService.error("Lütfen Bütün parçaları seçiniz!");
              }else{
             

                this.step = this.steps.servis_secimi.id;
              }
            /*  this.getCompany.watch({ order_by:{"name":"asc"} },{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
                this.companyList = result.data.companies
              });*/
      }else{
        this.step = this.steps.arac_secimi.id;
      }
 
      
      break;

      case this.steps.manuel_parca_secimi.id: //manuel parça seçimi

        if(isContinue){
          if(this.selectedPieces.length > 0){

            if(this.orderCardId != undefined && this.orderCardId > 0){
              this.updateOrderCards.mutate({
                id:this.orderCardId,
                changes:{
                  step: this.steps.manuel_parca_secimi.id,
                  service_date: null,
                  order_value: this.totalAmount,
                  order_no_vat_value: this.subTotal,
                  service_id: null,
                  customer_status_id: 14
                }
              }).subscribe(response => {
                
              });
    
              
          }else{
    
    
            this.createOrderCards.mutate({
              object:{
                type_id:this.orderServiceId,
                step: this.steps.manuel_parca_secimi.id,
                customer_id: this.authService.userId,
                vehicle_id:this.vehicleId}}).subscribe((response:any)=>{
              if(response.errors){
                this.alertifyService.error(response.errors[0].message);
              }
              if(response.data){
                this.orderCardId = response.data.insert_order_cards_one.id
              }
            });
            
    
          }
           
            if(this.orderServiceId == 3){
              this.selectedPiecesWithIndex = this.selectedPieces;
            }else{
              this.selectedPiecesWithIndex = this.selectedPieces.filter((obj:any) => (obj.part_type_id > 0 || obj.part_unit_type > 0));
            }

            

              if(this.selectedPiecesWithIndex.length == 0){
                this.alertifyService.error("Lütfen Bütün parçaları seçiniz!");
              }else{
             

                this.step = this.steps.servis_secimi.id;
              }
              this.getCompany.watch({ order_by:{"name":"asc"} },{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
                this.companyList = result.data.companies
              });
            this.step = this.steps.servis_secimi.id;
          }else{
            this.alertifyService.error("Lütfen Parça Seçiniz!");
          }
        }else{
          this.step = this.steps.hizmet_tipi.id;
        }

        
      break;

      case this.steps.servis_secimi.id: //servis seçimi
        if(isContinue ){

          if(this.orderCardId != undefined){
            if(this.orderServiceId == 2){
            }
            if(this.serviceDate == undefined){
              this.alertifyService.error("Lütfen servis tarihi seçiniz");
              return;
            }
  
            if(this.selectedServiceId == BigInt("0")){
              this.alertifyService.error("Servis Seçiniz!");
            }else{
              
               
              if(this.orderCardId != undefined && this.orderCardId > 0){
  
                this.updateOrderCards.mutate({
                  id:this.orderCardId,
                  changes:{
                    step: 5,
                    service_date: this.serviceDate,
                    service_id: this.selectedServiceId
                   
                  }
                }).subscribe((response:any) => {
                  if(response.errors){
                    this.alertifyService.error(response.errors[0].message);
                  }
                });
              }
  
              this.getAddressList();
             this.step = this.steps.adres_secimi.id;
  
            
            }
          }else{
            this.alertifyService.error("Lütfen Parça Seçiniz");
          }
         
          
          
          
        }else{
          if(this.orderServiceId == 1){
            this.step = this.steps.teklif_al.id;
          }

          if(this.orderServiceId == 2){
            this.step = this.steps.parca_secimi.id;
          }

          if(this.orderServiceId == 3){
            this.step = this.steps.manuel_parca_secimi.id;
          }
        }

        break;

      case this.steps.adres_secimi.id: //adres seçimi
      if(isContinue){

        let err = false;

          // şirket kontrolleri
          if(this.isCompany){

            if(this.invoiceInfo.address == null){
              this.alertifyService.error("Şirket yetkilisi fatura adresi kayıt etmeli");
              err = true;
              return;
            }

            if(this.invoiceInfo.town_id == null || this.invoiceInfo.town_id == undefined){
              this.alertifyService.error("Şirket yetkilisi fatura adresi için mahalle seçmeli");
              err = true;
              return;
            }

            if(this.invoiceInfo.district_id == null || this.invoiceInfo.district_id == undefined){
              this.alertifyService.error("Şirket yetkilisi fatura adresi için ilçe seçmeli");
              err = true;
              return;
            }

            if(this.invoiceInfo.city_id == null || this.invoiceInfo.city_id == undefined){
              this.alertifyService.error("Şirket yetkilisi fatura adresi için şehir seçmeli");
              err = true;
              return;
            }

            if(this.invoiceInfo.country_id == null || this.invoiceInfo.country_id == undefined){
              this.alertifyService.error("Şirket yetkilisi fatura adresi için ülkçe seçmeli");
              err = true;
              return;
            }

            if(this.invoiceInfo.company_name == undefined || this.invoiceInfo.company_name == "" || this.invoiceInfo.company_name.length < 3){
              this.alertifyService.error("Şirket yetkilisi geçerli bir firma ismi girmeli!");
              err = true;
              return false;
            }

            if(this.invoiceInfo.tax_number == undefined || this.invoiceInfo.tax_number == ""){
              this.alertifyService.error("Vergi numarası 10 haneli olmalıdır!");
              err = true;
              return false;
            }

            if(this.invoiceInfo.tax_administration == undefined || this.invoiceInfo.tax_administration == "" || this.invoiceInfo.tax_administration.length < 3){
              this.alertifyService.error("Vergi dairesi giriniz!");
              err = true;
              return false;
            }

            if(this.invoiceInfo.phone_number == undefined || this.invoiceInfo.phone_number == "" || this.invoiceInfo.phone_number  == 0){
              this.alertifyService.error("Şirket yetkilisi telefon numarası eklemeli!");
              err = true;
              return false;
            }

          }else{ //kullanıcı kontrolleri
            if(this.selectedAddressId == undefined || this.selectedAddressId == BigInt("0")){
              this.alertifyService.error("Lütfen fatura adresi seçiniz");
              return;
            }

            if(this.invoiceInfo.invoice_name == undefined || this.invoiceInfo.invoice_name == "" || this.invoiceInfo.invoice_name.length < 3){
              this.alertifyService.error("Lütfen geçelerli bir ad giriniz!");
              err = true;
              return false;
            }

            if(this.invoiceInfo.invoice_surname == undefined || this.invoiceInfo.invoice_surname == "" || this.invoiceInfo.invoice_surname.length < 3){
              this.alertifyService.error("Lütfen geçelerli bir soyad giriniz!");
              err = true;
              return false;
            }

            if(this.invoiceInfo.identity_no == undefined || this.invoiceInfo.identity_no == ""){
              this.alertifyService.error("Lütfen geçelerli bir kimlik no giriniz!");
              err = true;
              return false;
            }

            if(this.invoiceInfo.address_id == undefined || this.invoiceInfo.address_id == "" || this.invoiceInfo.address_id < 3){
              this.alertifyService.error("Lütfen adres seçiniz!");
              err = true;
              err = true;
              return false;
            }

            if(this.invoiceInfo.gsm_id == undefined || this.invoiceInfo.gsm_id == "" || this.invoiceInfo.gsm_id  == 0){
              this.alertifyService.error("Lütfen telefon numarası seçiniz!");
              err = true;
              return false;
            }

          }
    
          if(this.invoiceInfo.license_name == undefined || this.invoiceInfo.license_name == "" || this.invoiceInfo.license_name.length < 3){
            this.alertifyService.error("Lütfen ruhsat için geçelerli bir ad giriniz!");
            err = true;
            return false;
          }

          if(this.invoiceInfo.license_surname == undefined || this.invoiceInfo.license_surname == "" || this.invoiceInfo.license_surname.length < 3){
            this.alertifyService.error("Lütfen ruhsat için geçelerli bir soyad giriniz!");
            err = true;
            return false;
          }

          if(this.invoiceInfo.plate == undefined || this.invoiceInfo.plate == "" || this.invoiceInfo.plate.length < 3){
            this.alertifyService.error("Lütfen geçelerli bir plaka giriniz!");
            err = true;
            return false;
          }

          if(this.invoiceInfo.chassis_no == undefined || this.invoiceInfo.chassis_no == "" || this.invoiceInfo.chassis_no.length < 3){
            this.alertifyService.error("Lütfen geçelerli bir şasi no giriniz!");
            err = true;
            return false;
          }

        /*  if(this.invoiceInfo.engine_no == undefined || this.invoiceInfo.engine_no == "" || this.invoiceInfo.engine_no.length < 3){
            this.alertifyService.error("Lütfen geçelerli bir motor no giriniz!");
            err = true;
            return false;
          }*/

          if(this.orderCardId != undefined && this.orderCardId > 0){
            this.getOrderCardInvoiceInfo.watch({where:{order_card_id:{_eq:this.orderCardId}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response=>{
              if(response.errors){
                this.alertifyService.error(response.errors[0].message);
              }

              if(response.data){
                this.orderCardInvoiceInfo = response.data.order_card_invoice_info;

                let oObj = {
                  order_card_id: this.orderCardId,
                  customer_name: this.invoiceInfo.invoice_name,
                  customer_surname: this.invoiceInfo.invoice_surname,
                  identity_no: this.invoiceInfo.identity_no,
                  tax_number: this.invoiceInfo.tax_number,
                  tax_administration: this.invoiceInfo.tax_administration,
                  user_id: this.authService.userId,
                  company_id: this.userInfo?.company_id,
                  gsm_number: this.invoiceInfo.gsm_number,
                  customer_email: this.invoiceInfo.email,
                  invoice_type: this.invoiceInfo.invoice_type,
                  created_at: moment().format('yyyy-MM-DDTH:mm:ssZ'),
                  tel_number: this.invoiceInfo.tel_number,
                  address: this.invoiceInfo.address,
                  city_id: this.invoiceInfo.city_id,
                  district_id: this.invoiceInfo.district_id,
                  country_id: this.invoiceInfo.country_id,
                  town_id: this.invoiceInfo.town_id,
                  company_name: this.invoiceInfo.company_name
                }

                if(this.orderCardInvoiceInfo.length > 0){
                  this.updateOrderCardInvoiceInfo.mutate({where:{order_card_id:{_eq:this.orderCardInvoiceInfo[0].order_card_id}},changes:oObj}).subscribe((result:any)=>{
                    if(result.errors){
                      this.alertifyService.error(result.errors[0].message);
                    }

                    if(result.data){
                     
                    }
                  });
                }else{
                  this.createOrderCardInvoiceInfo.mutate({object:oObj}).subscribe((result:any)=>{
                    if(result.errors){
                      this.alertifyService.error(result.errors[0].message);
                    }

                    if(result.data){
                      
                    }
                  });
                }
              }
            })
          }

          if(!err){
            if(this.orderServiceId == 1){
              this.step = this.steps.faturalandir.id;
            }

            if(this.orderServiceId == 2){
              this.step = this.steps.faturalandir_mekanik.id;
            }

            if(this.orderServiceId == 3){
              this.step = this.steps.faturalandir_manuel_parca.id;
            }
          }
            
          
          
        
      }else{
    
        this.step = this.steps.servis_secimi.id;
      }

      break;  
    
      case this.steps.paket_secimi.id: // Paket Bilgileri

     // this.checkForOrderCard();

        if(this.vehicleId == 0){
          this.step = this.steps.arac_secimi.id;
        }

        if(isContinue == true){
        
            if(this.orderCardId != undefined && this.orderCardId > 0 ){

             
                this.deleteOrderCardParts.mutate({where:{order_card_id:{_eq:this.orderCardId}}}).subscribe((response:any) => {
                  if(response.errors){
                    this.alertifyService.error(response.errors[0].message);
                  }
    
                  if(response.data){
    
                  }
                });
              
            

              this.updateOrderCards.mutate({
                id:this.orderCardId,
                changes:{
                  maintenance_package_id:this.selectedPackageId,
                  step: 4,
                  vehicle_id:this.vehicleId,
                  customer_id: this.authService.userId
                }
              }).subscribe(response => {
                
              });

            }else{


              this.createOrderCards.mutate({
                object:{
                  type_id:this.orderServiceId,
                  maintenance_package_id:this.selectedPackageId,
                  step: this.steps.teklif_al.id,
                  customer_id: this.authService.userId,
                  vehicle_id:this.vehicleId}}).subscribe((response:any)=>{
                if(response.errors){
                  this.alertifyService.error(response.errors[0].message);
                }
                if(response.data){
                  this.orderCardId = response.data.insert_order_cards_one.id
                }
              });
              

            }
         

          
         
        if(this.orderServiceId == 1){
          if(this.selectedPackageId > 0){
            this.step = this.steps.teklif_al.id
          }else{
            this.alertifyService.error("Lütfen paket seçiniz");
          }
        }

        if(this.orderServiceId == 2){
          if(1 > 0){ // todo: buraya seçilen parça sayısını koy
            this.step = this.steps.faturalandir_mekanik.id
          }else{
            this.alertifyService.error("Lütfen paket seçiniz");
          }
        }

          
          
        }else{
          this.step = this.steps.arac_secimi.id;
         
        }

        break;

      case this.steps.login.id:
        if(!isContinue){
          this.step = this.steps.paket_secimi.id;
        }
          break;

      case this.steps.teklif_al.id: // Paket Detay Bilgileri
          if(isContinue){

            

            if(this.orderServiceId == 1){

              this.selectedPiecesWithIndex = this.selectedPieces.filter((obj:any) => (obj.part_type_id > 0 || obj.part_unit_type > 0));
              if(this.selectedPiecesWithIndex.length < this.selectedPackageInfo.maintenance_package_details.length){
                console.warn(this.selectedPiecesWithIndex.length + "<" + this.selectedPackageInfo.maintenance_package_details.length);
                this.alertifyService.error("Lütfen Bütün parçaları seçiniz!");
              }else{

                this.selectedCompaignProducts = this.selectedPieces.filter((obj:any) => (obj.selected_part_group_id == 2));//yağ gurubu ise ayrı göster

                this.selectedUncompaignProducts = this.selectedPieces.filter((obj:any) => (obj.selected_part_group_id != 2));
                this.step = this.steps.servis_secimi.id;
              }
            }

            if(this.orderServiceId == 2){
              this.selectedPiecesWithIndex = this.selectedPieces.filter((obj:any) => (obj.part_type_id > 0 || obj.part_unit_type > 0));
              if(this.selectedPiecesWithIndex.length == 0){
                this.alertifyService.error("Lütfen Bütün parçaları seçiniz!");
              }else{
            
                this.step = this.steps.servis_secimi.id;
              }
            }
           /* if(this.companyList == undefined || this.companyList?.length == 0){
              this.getCompany.watch({ order_by:{"name":"asc"} },{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
                this.companyList = result.data.companies;

              });
            }*/
            if(this.orderCardId != undefined && this.orderCardId > 0){

              this.updateOrderCards.mutate({
                id:this.orderCardId,
                changes:{
                  step: 4,
                  service_date: null,
                  order_value: this.totalAmount,
                  order_no_vat_value: this.subTotal,
                  service_id: null,
                  customer_status_id: 14 
                }
              }).subscribe((response:any) => {
                if(response.errors){
                  this.alertifyService.error(response.errors[0].message);
                }
              });
            }
            
           

              
          }else{
            this.selectedPieces = [];
            this.selectedCompaignProducts = [];
            this.selectedUncompaignProducts = [];
           
            this.step = this.steps.paket_secimi.id;
          }
     
        
        break;

        case this.steps.odeme.id: //odeme

        //this.momentOrderId = moment().format('yyMMDDH:mm.ii').toString()+this.orderCardId;
       
        if(isContinue){

          if(this.selectedPaymentTypes == 1){
            if(this.isPayed){
              this.submit();
            }else{
              this.alertifyService.error("Lütfen ödeme işlemini yapınız");
            }
          }else{
            this.submit();
          }

         
        }else{
         
          this.step = this.steps.faturalandir.id;
        }


        break;
      
      case this.steps.faturalandir_mekanik.id:
          if(isContinue){
            this.momentOrderId = moment().format('YYMMDDHmmss').toString()+"-"+this.orderCardId;
            //this.paymentFormContainer.nativeElement.innerHTML = `fsafasdfad adfadsfafas `;
           // this.paymentSubmit();
        
            this.next = "Onayla";

            this.step = this.steps.odeme.id;
            //
        //this.submit();
          }else{
            this.step = this.steps.adres_secimi.id;
          }
     
        
        break;
      
      case this.steps.faturalandir_manuel_parca.id:
          if(isContinue){
            this.orderCreate();
          }else{
            this.step = this.steps.servis_secimi.id;
          }
      break;

      case this.steps.faturalandir.id:
          if(isContinue){
            this.momentOrderId = moment().format('YYMMDDHmmss').toString()+"-"+this.orderCardId;
            //this.paymentFormContainer.nativeElement.innerHTML = `fsafasdfad adfadsfafas `;
           // this.paymentSubmit();
        
            this.next = "Onayla";

            this.step = this.steps.odeme.id;
          //  this.submit();
          }else{

            this.step = this.steps.adres_secimi.id;
          }
          
        break;
    
      case this.steps.siparisi_tamamla.id:
        this.orderId = BigInt(12312312);
      break;
      
      case this.steps.mail_ile_teklif_iste.id:
          this.step = this.steps.arac_secimi.id;
      break;

      case this.steps.mail_teklif_al_basarili.id:
      
        break;
      default: // Hizmet Tipi
        
          this.step = step
        
        break;
    }
  }

 
  getPackageList(){
    if(this.vehicleInfo != undefined && this.vehicleInfo.model_type_id > BigInt(0)){
        this.getPackageModelTypes.watch({where:{model_type_id:{_eq:this.vehicleInfo.model_type_id}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
          if(response.error){
            this.alertifyService.error("Bir hata oluştu. Lütfen tekrar deneyiniz");
            return false;
          }

          if(response.data){
            let modeltypes = response.data.maintenance_package_model_types;
            if(modeltypes.length == 0){
              //this.alertifyService.error("Bu araç için servis paketi bulunamadı");
              //return false;
              this.teklif_al_mail();
              this.step = this.steps.mail_teklif_al_basarili.id;
            }
            let packageIds:any = [];
            modeltypes.forEach((element:any) => {
              packageIds.push(element.package_id);
            });
        
            if(packageIds.length > 0){
              this.getMechanicParts.watch({model_type_id:this.vehicleInfo?.model_type_id},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
                if(response.error){
                  this.alertifyService.error("Bir hata oluştu. Lütfen tekrar deneyiniz");
                  return false;
                }

                if(response.data){
                  this.getCustomPackageList = response.data.view_vehicle_mechanic_parts;
                  if(this.getCustomPackageList.length == 0){
                    this.alertifyService.error("Bu araç için servis paketi bulunamadı");
                    return false;
                  }else{
                    this.customPackageList = [];
                    this.getCustomPackageList.forEach((element:any) => {


                      let isExists = this.customPackageList.find((item:any) => item.parts.id == element.parts.id);
                      if(isExists == undefined){
                        let newItem = element;
                        newItem.name = element.parts.name;
                        newItem.part_id = element.parts.id;
                        this.customPackageList.push(newItem);

                      //  this.customPackageList.splice(this.customPackageList.indexOf(element),1);
                      }
                    });
                  }
                }
              })
            }
          }
        })

       }
  }

  async submit(){
    if(this.selectedPieces.length == 0){
      this.alertifyService.error("Lütfen parça seçiniz");
      this.step = this.steps.teklif_al.id;
      return false;
    }

    const result = await this.orderCreate();

    this.step = this.steps.siparisi_tamamla.id;
  }

   // Step-1 -> Hizmet tipi seçimi
   orderTypeChange(event: any) {
   
    const order_type_id = (typeof(event) == 'object') ? parseInt(event.target.value) : event;
    this.orderServiceId = order_type_id;


  }


  checkForPackageExists(){
    if(this.vehicleId == 0){
      return 0;
    }

    return this.getPackageModelTypes.watch({where:{model_type_id:{_eq:this.vehicleForm.model_type_id}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
      if(response.error){
        this.alertifyService.error("Bir hata oluştu. Lütfen tekrar deneyiniz");
        return 0;
      }

      if(response.data){
        let modeltypes = response.data.maintenance_package_model_types;

          return modeltypes.length;
        
        
      }
      });

  }

  




  clearFormFields(fieldName:any[]){
    if(fieldName.length > 0){
      let ths = this;
      fieldName.forEach(function(value){
        ths.orderForm = ths.orderForm.filter(i => i.order_type_id !== value)
      })
    }
  }

  selectedTwo(key: string, $event:any){

    switch (key) {
      case "brand":
        this.vehicleForm.brand_id = $event;
        this.getVehicleModels.watch({where:{vehicle_brand_id:{_eq:$event}}}).valueChanges.subscribe((result:any)=>{ 
          this.vehicleModels = result.data.vehicle_models;
          //this.clearFormFields(["model_id","model_type_id","year_id"]); buraya bak
          this.modelName = 'Model';
          this.modelTypeName = 'Model Tipi';
          this.selectedKms = "";
          this.selecteEditYear = "";
          this.vehicleModelYearList=[];
          this.vehicleModelTypes = [];
        })
      break;
      case "model":
        //this.selectedModel = $event;
        this.vehicleForm.model_id = $event;
        this.clearFormFields(["model_type_id","year_id"]);
        this.selectedYear = "Yıl";
        this.modelTypeName = 'ModelType';
          this.selectedKms = "";
          this.selecteEditYear = "";
          this.vehicleModelYearList=[];
          this.vehicleModelTypes = [];
          this.vehicleModelYears.watch({where:{vehicle_model_id:{_eq:$event}}}).valueChanges.subscribe((result:any)=>{
            this.vehicleModelYearList = result.data.vehicle_model_years
          })

        
        
      break;
      case "year":
        if(parseInt($event) > 0){
          
          this.vehicleModelYearList?.filter((data: {id:any,year_id:any}) => {
            if(data.id ===  $event){
              this.selectedYear = data.year_id;
              this.vehicleForm.model_year_id = parseInt($event)
              //this.formGroup.patchValue({year_id:data.year_id});
              this.clearFormFields(["model_type_id"]);
              this.modelTypeName = "Model Tipi";
            }
          });
     
          if( parseInt(this.selectedYear) > 0){
              this.getVehicleModelTypes.watch({where:{vehicle_model_year_id:{_eq:$event}}}).valueChanges.subscribe((result:any)=>{
                this.vehicleModelTypes = result.data.vehicle_model_types
              })
          }
       }
      break; 
      case "vehicle_model_types":
        //this.vehicleTypeFromGroup.patchValue({model_type_id:$event})
        this.vehicleForm.model_type_id = $event;
      break;

      case "changeKms":
        this.packageListFiltered = [];
        this.selectedPackageId = BigInt(0);
        if(this.packageList && this.packageList?.length > 0){
          this.packageList.forEach(resp=>{
            if(resp.maintenance_kms_id == $event){
              this.packageListFiltered?.push(resp);
            }
          })
        }
      break;


    }
  }


  setSelectedAddressInfo(){
   

    if(this.addressList != undefined && this.selectedAddressId != undefined ){
      let exists = this.addressList.filter((data: {id:any}) => (data.id === this.selectedAddressId));
      if(exists.length > 0){
        this.selectedAddressInfo = exists[0];
        this.invoiceInfo.address_id = this.selectedAddressInfo.id;
        this.invoiceInfo.country_id = this.selectedAddressInfo.country_id;
        this.invoiceInfo.city_id = this.selectedAddressInfo.city_id;
        this.invoiceInfo.district_id = this.selectedAddressInfo.district_id;
        this.invoiceInfo.town_id = this.selectedAddressInfo.town_id;
      }

    }

  }

  setGarageName(event: any) {
    this.vehicleForm.name = event.target.value;
  }

  deleteOrderCardPart(){
    if(this.orderCardId != undefined && this.orderCardId > 0){
      this.deleteOrderCardParts.mutate({where:{order_card_id:{_eq:this.orderCardId}}}).subscribe((response:any) => {
        if(response.errors){
          this.alertifyService.error(response.errors[0].message);
        }
  
        if(response.data){ }
      });
    }
    
  }

  chooseVehicle(vehicleId: BigInt ,info:any,modelTypeId:bigint=BigInt(0),updateCard:boolean=true) {

    this.vehicleId = vehicleId;
    if(this.orderCardId != undefined && this.orderCardId > 0){
     
      if(updateCard == true){
        this.deleteOrderCardPart();
        this.updateOrderCards.mutate({id:this.orderCardId,changes:{
          vehicle_id:this.vehicleId,
          maintenance_package_id:null,
        }}).subscribe((response:any) => {
          if(response.errors){
            this.alertifyService.error(response.errors[0].message);
          }
          if(response.data){
  
          }
        })
      }
      
    }
    this.brandMaintenancePrice= 0;
    this.gosMaintenancePrice = 0;
    this.vehicleInfo = info;
    this.vehicleForm.brand_id = info.brand_id,
    this.vehicleForm.model_id = info.model_id,
    this.vehicleForm.model_type_id = info.model_type_id,
    this.vehicleForm.model_year_id = info.model_year_id,
    this.vehicleForm.garage_id =this.garageId;

    this.invoiceInfo.plate = this.vehicleInfo?.plate;
    this.invoiceInfo.license_name = this.vehicleInfo?.license_name;
    this.invoiceInfo.license_surname = this.vehicleInfo?.license_surname;
    this.invoiceInfo.chassis_no = this.vehicleInfo?.chassis_no;
    this.invoiceInfo.engine_no = this.vehicleInfo?.engine_no;
    this.invoiceInfo.invoice_type = (this.isCompany) ? 1 : 0;
  }

  vehicleTabChange(tab: String) {
    this.vehicleTab = tab
    //this.vehicleList = [];
  }

  getGarage() {
    // kayıtlı araçlar
    return new Promise(resolve => {
      if (this.authService.isLogin) {
        this.getGarages.watch({ "userId": this.authService.userId },{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
          if(result.error){
            resolve(false);
          }

          if (result.data) {
            this.garageList = result.data.garages;
            if(this.garageList.length > 0){
              //this.vehicleTab = "old";
             
              this.selected("garageChage",this.garageList[0].id);
              
            }

            resolve(true);
            
          }
        })
      }else{
      // araç marka seçimi
      this.getVehicleBrand.watch().valueChanges.subscribe((result:any)=>{
        this.vehicleBrands = result.data.vehicle_brands
        
      })
      }
    
    });

  }

   async createIfCarNotExist() {
   /* if(this.vehicleForm.garage_id === 0){
      return false;
    }*/

    if(this.authService.isLogin){
      let garage = this.getGarage();
      this.showOldVehicles = true;
      let ths = this;
      garage.then(function(success){


          if(ths.garageList.length == 0){
            ths.createGarage.mutate({name:"Garajım",user_id:ths.authService.userId,active:true}).subscribe((result:any)=>{
              if(result.errors){
                ths.alertifyService.error("Garaj oluşturulamadı!");
              }
              if(result.data){
                ths.vehicleForm.garage_id = result.data.insert_garages.returning[0].id;
                const { name, ...vehicleForm } = ths.vehicleForm // remove name
                ths.createVehicle.mutate({...vehicleForm}).subscribe((vehicleResult:any) => {
                    if(vehicleResult.errors){
                      ths.alertifyService.error("Araç kaydı esnasında bir hata oluştu");
                    }
                    if (vehicleResult.data) {
                      ths.vehicleId = vehicleResult.data.insert_vehicles.returning[0].id;
                      ths.vehicleList = [];
             
                      ths.getGarage().then(function(success){
                       
                        ths.chooseVehicle(ths.vehicleId,ths.garageList[0].vehicles[0]);
                        ths.vehicleTab = "old";
                       
                    //    this.step  = this.steps.arac_secimi.id;
                    
                        ths.showOldVehicles = true;
                        ths.step = ths.steps.arac_secimi.id;
                      });
  
                     
                      
                    }
                })
              }
            });
          }else{  
            ths.garageId = ths.garageList[0].id;
            ths.vehicleForm.garage_id = ths.garageId;
            ths.getGarages.watch({ "userId": ths.authService.userId },{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
              if(result.error){
                ths.alertifyService.error("Garajlar getirilirken bir hata oluştu");
              }
              if (result.data) {
                ths.garageList = result.data.garages;
                if(ths.garageList.length > 0){
    
                  let exists = ths.garageList.filter((data:any) =>{ 
                    if(data.vehicles.length > 0){
                      let isCarExists =  data.vehicles.filter((vehicle:any) => vehicle.model_type_id === ths.vehicleForm.model_type_id )
    
                      if(isCarExists.length > 0){
                        isCarExists[0].garage_id = data.id;
                        ths.chooseVehicle(isCarExists[0].id,isCarExists[0]);
                        ths.step  = ths.steps.arac_secimi.id;
                        return true;
                      }
                      return false;
                    }
                    
                  });
                    if(exists.length == 0){
                    const { name, ...vehicleForm } = ths.vehicleForm // remove name
                    ths.createVehicle.mutate({...vehicleForm}).subscribe((vehicleResult:any) => {
                        if(vehicleResult.errors){
                          ths.alertifyService.error("Araç kaydı esnasında bir hata oluştu");
                        }
                        if (vehicleResult.data) {
                         // this.vehicleId = parseInt(response.data.insert_vehicles.returning[0].id);
                         ths.vehicleList = [];
                         ths.getGarage();
                         ths.vehicleTab = "old";
                         ths.garageId = 0;
                         ths.chooseVehicle(vehicleResult.data.insert_vehicles.returning[0].id,vehicleForm);
                         ths.showOldVehicles = true;
                         ths.step = ths.steps.arac_secimi.id;
              
                        }
                    })
                  }else{
                    ths.showOldVehicles = true;
                    if(ths.orderServiceId == 2){
                     /// this.getPackageList();
                      ths.vehicleTab = "old";
                 
                      ths.step = ths.steps.arac_secimi.id;
                      
    
                    }
                    if(ths.orderServiceId == 1){
                      ths.vehicleTab = "old";
                      ths.step = ths.steps.arac_secimi.id;
    
                    }
                  }
                }
                
              }
            })
      
          
          }
        


      })


  
    
      
    }else{
      this.vehicleForm.garage_id = 0;
      const { name, ...vehicleForm } = this.vehicleForm // remove name
      this.vehicleTab = "old";
   /*   this.getVehicles.watch({where:{garage_id:{_eq:this.garageId}}}).valueChanges.subscribe((result:any)=>{
        this.vehicleList = result.data.vehicles;
      })*/
    }

   
        
  }

  authTabChange(tab: String) {
    this.authTab = tab
  }

   // Step-3 -> login event emitter
   login(isLogin: boolean) {
    if (isLogin) {
      this.gotoPackageDetail = true;
      // araçları getir
      
      this.getUserInfo();
      this.createIfCarNotExist();
    
    }
  }

  changeCity($event:any,type:string="city"){
    this.selectedServiceId = BigInt("0");
    switch(type){
      case "city":
        this.selectedCityId = $event;
        this.selectedDistrictId = 0;
        break;
      case "district":
        this.selectedDistrictId = $event;
      break;  
    }

    let whereObj = {"active":{"_eq":true}};

    if(this.selectedCityId > 0){
      Object.assign(whereObj, { "city_id": { "_eq": this.selectedCityId}})
    }

    if(this.selectedDistrictId > 0){
      Object.assign(whereObj, { "district_id": { "_eq": this.selectedDistrictId}})
    }

    this.getCompany.watch({ where: whereObj,order_by:{"name":"asc"} },{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
      this.companyList = result.data.companies
    });

  }

  getUrl(name:String,id:bigint){
    return name.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '')+"-"+id;            // Trim - from end of text
  }

  selectService(id:bigint){
    this.selectedServiceId = id;
  }

  
  
  
  getVehicleList():void{
    
      if(this.garageId > 0){
        this.getVehicles.watch({where:{garage_id:{_eq:this.garageId}}}).valueChanges.subscribe((result:any)=>{
          this.vehicleList = result.data.vehicles;
        })
      }else{
        this.vehicleList = [];
      }
  
  }

  selected(type:string,event:any){
    switch(type){
      case "garageChage":
        if(event == null){
          this.garageId = 0;
          this.vehicleList = [];
          this.vehicleId = 0;
         // this.selected
        }else{
          this.garageId = event;
          let list = this.garageList.filter(i => i.id == this.garageId);
          if(list.length > 0){
            this.vehicleList = list[0].vehicles;
            this.garageName = list[0].name;
         
          }
        }
      break;
      case "serviceDate":
        this.serviceDate = event.target.value;
        break;
    }
  }

  setServiceDate($event:any){
    this.serviceDate = $event;
  }

  setPackageInfo($event:any,info:any){
    
    this.packageDetailList = [];
    this.customPackageDetailList = [];
    this.selectedPackageId = (typeof($event) == "object") ? $event.target.value : $event;
    this.selectedPackageInfo = info;

    if(this.orderCardId != undefined && this.orderCardId > 0){
      this.updateOrderCards.mutate({
        id:this.orderCardId,
        changes:{
          maintenance_package_id:this.selectedPackageId,
          step: this.steps.paket_secimi.id
        }
      }).subscribe(response => {
        
      });
    }

   
    
    this.getPackageDetails.watch({where:{maintenance_package_id:{_eq:this.selectedPackageId}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
      if(result.errors){
        this.alertifyService.error("bir hata oluştu");
      }
      if(result.data){
        
        this.packageDetailList = result.data.maintenance_package_details;
        if(this.packageDetailList && this.packageDetailList.length > 0){
          let newObj:any;
          this.packageDetailList.forEach(element => {
            //paket detayını set ediyoruz
            if(element.part_original_list.id == undefined || element.part_original_list.id == null){
              //TODO: buraya bak
              
            }else{
  
              newObj ={
                selectedPart:null,
                packageDetail:null,
                id:element.id,
                sub_industry: null,
                without_logo: null,
                equivalent_1: null,
                equivalent_2: null,
                original: null,
                offical_original:null,
                partTypeName:"Orijinal",
              };
  
              newObj.packageDetail = {
                id:element.id,
                name:element.maintenance_package.name,
                part_unit:element.part_unit,
                part_unit_type:element.part_unit_type
              }
              
              if(element.part){
                newObj.selectedPart = {
                  id:element.part_id,
                  name:element.part.name,
                  part_group_id: element.part.part_group_id
                }
                
              }else{
                this.alertifyService.error("bir hata oluştu");
                return false;
              }
  
              if(element.part_original_list.length == 0){
                this.alertifyService.error("Beklenemedik bir hata oluştu");
                return false;
              }
  
              
              
              if(element.part_original_list.part_equivalent_lists == undefined || element.part_original_list.part_equivalent_lists.length == 0){
                //TODO buraya bak
                return false;
              }
  
              //yetkili original
              newObj.offical_original = {
                price_with_vat: element.part_original_list.part_in_vat_price,
                price:element.part_original_list.part_in_vat_price,
                price_no_vat:element.part_original_list.part_no_vat_price,
                part_type_name: "yetkili_original",
                part_original_id: element.part_original_list.id,
                id: element.part_original_list.id,
                price_unit: element.part_original_list.price_unit,
                part_type_id:element.part_original_list.part_type_id,
                part_id:element.part_id,
                partTypeName: "Orijinal",
                part_code:element.part_original_list.part_code,
                selected_part_group_id: newObj.selectedPart.part_group_id,
                part_name: element.part_original_list.name
              }
           
              element.part_original_list.part_equivalent_lists.forEach((equivalent:any) => {
  
                let priceWithVat = equivalent.part_in_vat_price;
                let priceNoVat = equivalent.part_no_vat_price;
                let priceUnit = equivalent.price_unit;
                let currency = 0;
                let now  = moment().format('yyyy-MM-DDTH:mm');
  
                if(equivalent.price_unit_id != 1){
                  priceUnit = "TL";
                  let currentCurrency = this.currencyList?.filter(i => i.price_unit_id == equivalent.price_unit_id);
  
                  if(currentCurrency){
                    currency = currentCurrency[0].currency_value;
                    priceWithVat = (currency * equivalent.part_in_vat_price).toFixed(2);
                    priceNoVat = (currency * equivalent.part_no_vat_price).toFixed(2);
                  }
                }
  
                newObj.part_type_id = equivalent.part_type_id;
                newObj.equivalent_name = equivalent.name;
                newObj.part_code = equivalent.part_code;
                
  
                switch(equivalent.part_type_id){
                  case 2://logosuz orijinal
                    newObj.without_logo = {
                      price_with_vat: priceWithVat,
                      price: priceWithVat,
                      price_no_vat: priceNoVat,
                      part_type_name: "logosuz",
                      part_type_id: equivalent.part_type_id,
                      part_original_id: equivalent.part_original_id,
                      id: equivalent.id,
                      price_unit: priceUnit,
                      part_id:element.part_id,
                      partTypeName:"Logosuz Orijinal",
                      part_code:equivalent.part_code,
                      price_unit_id:equivalent.price_unit_id,
                      price_without_currency: equivalent.part_no_vat_price,
                      price_vat_without_currency: equivalent.part_in_vat_price,
                      currency: currency,
                      created_at: now,
                      selected_part_group_id: newObj.selectedPart.part_group_id,
                      part_name: equivalent.name
                    };
                  break;
                  case 3://eşdeğer
                    if(newObj.equivalent_1 == null){
                    newObj.equivalent_1 = {
                      price_with_vat: priceWithVat,
                      price: priceWithVat,
                      price_no_vat: priceNoVat,
                      part_type_name: "esdeger_1",
                      part_type_id: equivalent.part_type_id,
  
                      part_original_id: equivalent.part_original_id,
                      id: equivalent.id,
                      price_unit: priceUnit,
                      part_id:element.part_id,
                      partTypeName:"Eş değer",
                      part_code:equivalent.part_code,
                      price_unit_id:equivalent.price_unit_id,
                      price_without_currency: equivalent.part_no_vat_price,
                      price_vat_without_currency: equivalent.part_in_vat_price,
                      currency: currency,
                      created_at: now,
                      selected_part_group_id: newObj.selectedPart.part_group_id,
                      part_name: equivalent.name
  
                    };
                    }else{
                      newObj.equivalent_2 = {
                        price_with_vat: priceWithVat,
                        price: priceWithVat,
                        price_no_vat: priceNoVat,
                        part_type_name: "esdeger_2",
                        part_type_id: equivalent.part_type_id,
                         part_original_id: equivalent.part_original_id,
                        id: equivalent.id,
                        price_unit: priceUnit,
                        part_id:element.part_id,
                        name: equivalent.name,
                        partTypeName:"Eş değer",
                        part_code:equivalent.part_code,
                        price_unit_id:equivalent.price_unit_id,
                        price_without_currency: equivalent.part_no_vat_price,
                        price_vat_without_currency: equivalent.part_in_vat_price,
                        currency: currency,
                        created_at: now,
                        selected_part_group_id: newObj.selectedPart.part_group_id,
                        part_name: equivalent.name
  
                      };
                    }
                  break;
                  case 4://yan sayayi
                    newObj.sub_industry = {
                      price_with_vat: priceWithVat,
                      price: priceWithVat,
                      price_no_vat: priceNoVat,
                      part_type_name: "yan_sanayi",
                      part_type_id: equivalent.part_type_id,
                       part_original_id: equivalent.part_original_id,
                      id: equivalent.id,
                      price_unit: priceUnit,
                      part_id:element.part_id,
                      partTypeName:"Yan Sanayi",
                      part_code:equivalent.part_code,
                      price_unit_id:equivalent.price_unit_id,
                      price_without_currency: equivalent.part_no_vat_price,
                      price_vat_without_currency: equivalent.part_in_vat_price,
                      currency: currency,
                      created_at: now,
                      selected_part_group_id: newObj.selectedPart.part_group_id,
                      part_name: equivalent.name
  
                    };
                  break;
                  case 5: //Original
                    newObj.original = {
                      price_with_vat: priceWithVat,
                      price: priceWithVat,
                      price_no_vat: priceNoVat,
                      part_type_name: "original",
                      part_type_id: equivalent.part_type_id,
                      part_original_id: equivalent.part_original_id,
                      id: equivalent.id,
                      price_unit: priceUnit,
                      part_id:element.part_id,
                      partTypeName:"Orijinal",
                      part_code:equivalent.part_code,
                      price_unit_id:equivalent.price_unit_id,
                      price_without_currency: equivalent.part_no_vat_price,
                      price_vat_without_currency: equivalent.part_in_vat_price,
                      currency: currency,
                      created_at: now,
                      selected_part_group_id: newObj.selectedPart.part_group_id,
                      part_name: equivalent.name
  
                    }
                  break;
                }
              })
  
              if(newObj.equivalent_1 == null || newObj.equivalent_2 == null){
                //TODO Başbug parçalarını getir.
                if(newObj.equivalent_1 == null){
                  newObj.equivalent_1 = {
                    price_with_vat: 1100,
                    price_no_vat: 800,
                    price:1000,
                    part_type_name: "basbug_1"
                  }
                } 
                
              }
  
              this.customPackageDetailList.push(newObj);
  
            }
          })
        }
        

      }
      
    }
    )
  }



 
  createInvoice(){
    this.getOrder.watch({id:this.orderId},{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any) => {

      if(result.errors){
        this.alertifyService.error(result.errors[0].message);
        return;
      }

      if(result.data){
        this.orderDetail = result.data.orders_by_pk;

        if(this.orderDetail != undefined){
          this.createOrderInvoice.mutate({
            object:{
            "order_id":this.orderDetail.id,
            "date":moment().format("YYYY-MM-DDTHH:mm:ssZ"),
            "value_in_vat":this.orderDetail.order_value,
            "value_no_vat":this.orderDetail.order_no_vat_value,
            "vat": ( this.orderDetail.order_value - this.orderDetail.order_no_vat_value ),
            "discount":0,
            "url":"",
            "invoice_type_id":this.orderDetail.type_id,
            "valor_date":moment().format("YYYY-MM-DDTHH:mm:ssZ"),
            "invoice_status_id":6, // TODO buraya bakılacak
            "paid_value": this.orderDetail.order_value, // TODO buraya bakılacak
            "remaining_value":0, // TODO buraya bakılacak
            "note":"",
            "invoice_reciever_id":this.orderDetail.customer_id,
           // "invoice_reciever_type_id": this.orderDetail.customer_info.role_id
          
          }
          }).subscribe((result:any) => {
            if(result.errors){
              this.alertifyService.error(result.errors[0].message);
              
            }

            if(result.data){

              this.updateOrder.mutate({"id":this.orderId,"changes":{invoice_id:result.data.insert_order_invoices_one.id}}).subscribe((upresult:any) => {
                if(upresult.errors){
                  this.alertifyService.error("Güncelleme esnasında bir sorun oluştu!");
                }
                if(upresult.data){
                  this.alertifyService.success("siparişiniz güncellendi");
                }
              })

              this.orderDetail?.order_parts.forEach((element:any) => {

                this.createOrderInvoicePart.mutate({

                  object:{
                  "invoice_id":result.data.insert_order_invoices_one.id,
                  "part_id": element.id,
                  "part_name": element.parts.name,
                  "part_code": element.part_equivalent_list[0].part_code,
                  "labour_type_id": element.labour_type_id,
                  "labour_value": element.labour_value,
                  "labour_discount": element.labour_discount,
                  "part_in_vat_price": element.part_value,
                  "part_no_vat_price": (element.part_no_vat_price && element.part_no_vat_price != null) ? element.part_no_vat_price : 0,
                  "invoice_type":1, // TODO buraya bakılacak
                  "part_unit_type_id": element.part_unit_type_id,
                  "part_unit": element.part_unit
                 
                }
  
                }).subscribe((partResult:any) => {
                  if(partResult.errors){
                    this.alertifyService.error(partResult.errors[0].message);
                  }
                 if(partResult.data){

                 }
                });
              });

            
              this.alertifyService.success("Fatura oluşturuldu");
             ;

            }
          })
        }
      }
    });
       
            
            
        
    
  }



  orderCreate(){
  

  this.steppers.teklif_onayi_2 = true;
  this.steppers.teklif_onayi_1 = true;
    return new Promise(resolve => {


      if(this.orderServiceId > 0 && 
        this.authService.userId > 0 && 
        this.vehicleId > 0 && 
        this.serviceDate && 
        this.selectedPieces.length > 0){

          let Obj;

          if(this.orderServiceId == 2 ){//mekanik bakım ise package yok
        
             Obj = {
              status_id: 1,
              type_id: this.orderServiceId,
              customer_id: this.authService.userId,
              vehicle_id: this.vehicleId,
              service_date: this.serviceDate,
              order_value: this.totalAmount.toFixed(2),
              order_no_vat_value: this.subTotal.toFixed(2),
              service_value: this.totalAmount.toFixed(2),
              maintenance_package_id: 0,
              service_id: this.selectedServiceId,
              customer_status_id: 14, //müşteri onayı verildi,
              customer_gsm_id: this.invoiceInfo.gsm_id,
              customer_tel_id: this.invoiceInfo.tel_id,
              customer_address_id: this.invoiceInfo.address_id, //müşteri onayı verildi
              payment_type: this.selectedPaymentTypes,
              is_payed: this.isPayed
            }
  
            
          }else if(this.orderServiceId == 3){
            Obj = {
              status_id: 1,
              type_id: this.orderServiceId,
              customer_id: this.authService.userId,
              vehicle_id: this.vehicleId,
              service_date: this.serviceDate,
              order_value: 0,
              order_no_vat_value: 0,
              service_value: 0,
              maintenance_package_id: 0,
              service_id: this.selectedServiceId,
              customer_status_id: 14, //müşteri onayı verildi,
              customer_gsm_id: this.invoiceInfo.gsm_id,
              customer_tel_id: this.invoiceInfo.tel_id,
              customer_address_id: this.invoiceInfo.address_id,
              payment_type: this.selectedPaymentTypes,
              is_payed: this.isPayed
            }
          }else{
             Obj = {
              status_id: 1,
              type_id: this.orderServiceId,
              customer_id: this.authService.userId,
              vehicle_id: this.vehicleId,
              service_date: this.serviceDate+":00",
              order_value: this.totalAmount.toFixed(2),
              order_no_vat_value: this.subTotal.toFixed(2),
              service_value: this.totalAmount.toFixed(2),
              maintenance_package_id: this.selectedPackageId,
              service_id: this.selectedServiceId,
              customer_status_id: 14, //müşteri onayı verildi,
              customer_gsm_id: this.invoiceInfo.gsm_id,
              customer_tel_id: this.invoiceInfo.tel_id,
              customer_address_id: this.invoiceInfo.address_id,//müşteri onayı verildi
              payment_type: this.selectedPaymentTypes,
              is_payed: this.isPayed
            }
          }

          this.updateVehicle.mutate({
            where:{id:{_eq:this.vehicleId}} ,
            changes:{
              license_name: this.invoiceInfo.license_name,
              license_surname: this.invoiceInfo.license_surname,
              plate: this.invoiceInfo.plate,
              chassis_no: this.invoiceInfo.chassis_no,
              engine_no: this.invoiceInfo.engine_no
            }
          }).subscribe((res:any) => {
            if(res.errors){
              this.alertifyService.error(res.errors[0].message);
              return false;
            }

            if(res.data){
              this.alertifyService.success("Araç bilgileri güncellendi");
            }
          });
          


          this.createOrder.mutate(Obj).subscribe((result:any)=>{
            if(result.errors){
              this.alertifyService.error("Kayıt esnasında hata oluştu");
            }
  
            if(result.data){
              let insertedId = result.data.insert_orders.returning[0].id;
              this.orderId = insertedId;
              this.selectedPieces.filter((obj:any) => {

                /** part_id: element.selectedEquivalent_id,
          part_type_id: element.selectedEquivalent_partTypeId,
          part_value: element.selectedEquivalent_partValue,
          selected_part_id: element.selectedGosPart_id,
          service_value: element.selectedEquivalent_partValue,
          part_unit_type: element.selectedEquivalent_partUnitTypeId,
          part_unit: element.selectedEquivalent_partUnit,
          brand_labour_price: element.brandLabourPrice,
          gos_labour_price: element.gosLabourPrice,
          price_unit_id: element.selectedEquivalent_priceUnitId,
          currency_unit_price: element.selectedEquivalent_currencyPriceUnitId,
          currency_total_price: element.selectedEquivalent_currencyTotalPrice,
          currency_price_unit_id: element.selectedEquivalent_currencyPriceUnitId,
          currency: 1, //TODO: o anki döviz kurunu kayıt edecek
          total_price_no_vat : element.selectedEquivalent_priceNoVatTotal,
          total_price: element.selectedEquivalent_priceTotal,
          part_code: element.selectedEquivalent_partCode,
          part_no_vat_price: element.selectedEquivalent_partNoVatValue */

                this.createOrderpart.mutate(
                  {
                    object:{
                    order_id:insertedId,
                    part_id:obj.part_id,
                    part_type_id:obj.part_type_id,
                    part_value:obj.part_value,
                    selected_part_id:obj.selected_part_id,
                    service_value:obj.service_value,
                    part_unit_type_id:obj.part_unit_type,
                    part_unit:obj.part_unit,
                    brand_labour_price: obj.brand_labour_price,
                    gos_labour_price: obj.gos_labour_price,
                    price_unit_id:obj.price_unit_id,
                    currency_unit_price: obj.currency_unit_price,
                    currency_total_price: obj.currency_total_price,
                    currency: obj.currency,
                    total_price_no_vat: obj.total_price_no_vat,
                    total_price: obj.total_price,
                    created_at: moment().format('yyyy-MM-DDTH:mm:ssZ'),
                    part_code: obj.part_code,
                    part_no_vat_price: obj.price_no_vat
                    }
                  }).subscribe((partTypeResult:any) => {
                  if(partTypeResult.errors){
                    this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
                  }
                  if(partTypeResult.data){
                    this.step = this.steps.siparisi_tamamla.id;
                //    this.step =step;
                  }
                });
  
              });

              if(this.orderServiceId != 3){ //? manuel parça değil ise fatura oluştur
                //order_invoice_info tablosuna fatura bilgilerini ekle
                let infoObj:any = {
                  order_id:this.orderId,
                  user_id: this.authService.userId,
                  customer_name: this.invoiceInfo.invoice_name,
                  customer_surname: this.invoiceInfo.invoice_surname,
                  customer_email: this.invoiceInfo.email,
                  identity_no: null,
                  tax_number: null,
                  tax_administration: null,
                  gsm_number: null,
                  tel_number:null,
                //     is_same_with_customer: this.invoiceInfo.is_same_with_customer,
                  invoice_type: this.invoiceInfo.invoice_type,
                  address: this.invoiceInfo.address,
                  city_id: this.invoiceInfo.city_id,
                  town_id: this.invoiceInfo.town_id,
                  district_id: this.invoiceInfo.district_id,
                  country_id: this.invoiceInfo.country_id,
                  company_name: null,
                }


                if(this.isCompany){
                  
                  infoObj.tax_number = this.invoiceInfo.tax_number;
                  infoObj.tax_administration = this.invoiceInfo.tax_administration;
                  infoObj.company_name = this.invoiceInfo.company_name;
                  if(this.userInfo?.company_id != undefined){
                    infoObj.company_id = this.userInfo?.company_id;

                  }
                  infoObj.tel_number = this.invoiceInfo.tel_number;
                }else{
                  infoObj.gsm_number = this.invoiceInfo.gsm_number;
                  infoObj.identity_no = this.invoiceInfo.identity_no;
                }


                this.createInvoiceInfo.mutate({object:infoObj}).subscribe((infoResult:any) => {
                  
                  if(infoResult.errors){
                    
                    this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
                  }
                  if(infoResult.data){
                  
                  }

                });

                this.steppers.order_status = true;
                this.alertifyService.success("siparişiniz oluşturuldu");
                this.createInvoice();
              }

              
            }
  
          });
      }else{
        this.alertifyService.error("Lütfen eksik alanları doldurunuz!");
      }
      resolve(true);
    })

    
  }

  getUserInfo() {
    this.getUser.watch({ "id": this.authService.userId }).valueChanges.subscribe(response => {
      if (response.data) {
           this.userInfo = response.data.users_by_pk;
           if(this.userInfo.company_id > 0){
             //this.isCompany = true; //TODO: burayı armağan beye soracğız
           }
            this.invoiceInfo.invoice_name = this.userInfo.name;
            this.invoiceInfo.invoice_surname = this.userInfo.surname;
            this.invoiceInfo.identity_no = this.userInfo.identity_no;

            if(this.isCompany){
              this.invoiceInfo.email = this.userInfo.company?.company_email.email;
              this.invoiceInfo.gsm_id = this.userInfo?.company?.company_gsm?.code;
              this.invoiceInfo.address = this.userInfo.company.address;
              this.invoiceInfo.town_id = this.userInfo.company.town_id;
              this.invoiceInfo.district_id = this.userInfo.company.district_id;
              this.invoiceInfo.city_id = this.userInfo.company.city_id;
              this.invoiceInfo.country_id = this.userInfo.company.country_id;
              this.invoiceInfo.tax_administration = this.userInfo.company.vat_office;
              this.invoiceInfo.tax_number = this.userInfo.company.vat;
              this.invoiceInfo.company_name = this.userInfo.company.name;
              this.invoiceInfo.phone_number = this.userInfo?.company?.company_tel?.code;
            }else{
              this.invoiceInfo.email = this.userInfo.email;
              this.invoiceInfo.gsm_id = this.userInfo.gsm_id;
            }


        }
      })
  }



  isChecked(id:Number,part_type_id:number){
    return this.selectedPieces.filter((obj:any) => {
      return (obj.part_type_id == part_type_id && obj.piece_id == id)
    }).length > 0;
  }



  getAddressList(){

    if(this.authService.userId == undefined || this.authService.userId == null){
      return false;
    }

    if(this.isCompany){
      this.getCompanyGsmList();
    }else{
      this.setUserAddressList();
      this.getUserGsmList();

    }

  }

  setUserAddressList(){
    this.getUserAddressList.watch({where:{user_id:{_eq:this.authService.userId}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
      this.addressList = result.data.user_address;
      this.setSelectedAddressInfo();

    });  
  }

  setCompanyAddressList(){

  }

  openDrawer(type: string, id?: BigInt) {
    this.drawerTitle = 'Adres Ekle'
    this.drawerPage = type;
    if(this.drawerPage == "addressEdit" && id){
      this.drawerTitle = 'Adres Düzenle'
      this.selectedAddressId = id;
    }
    if(this.drawerPage == "addressAdd" && id){
      this.drawerTitle = 'Adres Ekle'
      this.selectedAddressId = id;
    }

    if(this.drawerPage == "gsmAdd"){
      this.drawerTitle = 'Cep Numarası Ekle'
    }
    this.showDrawer = true;

   
  }

  reloadList($event:any){
    if($event){
      this.toggleDrawer();  

    }
  
    if(this.drawerPage == "addressAdd"){
      if($event.reload){
        this.selectedAddressId = $event.addressId;
        this.invoiceInfo.address_id = $event.addressId;
        this.getAddressList();

      }
    }

    if(this.drawerPage == "addressEdit"){
      this.getAddressList();
    }

    if(this.drawerPage == "gsmAdd"){
      if($event.status){
        this.invoiceInfo.gsm_id = $event.gsm_id;
        this.getUserGsmList();

      }else{
        this.alertifyService.error("Bir hata oluştu");
      }
      
    }
    
  }

  toggleDrawer() {
    
    this.showDrawer = !this.showDrawer
  }

  changeInvoiceInfoCheckbox(event:any){

    if(event.target.checked){
      this.invoiceInfo.is_same_with_customer = true;

      this.invoiceInfo.invoice_name = this.userInfo?.name;
      this.invoiceInfo.invoice_surname = this.userInfo?.surname;
      this.invoiceInfo.identity_no = this.userInfo?.identity_no;
     
    }else{
      this.invoiceInfo.is_same_with_customer = false;
      this.setInvoiceInfo('invoice_name','',true);
      this.setInvoiceInfo('invoice_surname','',true);
      this.setInvoiceInfo('email','',true);
      this.setInvoiceInfo('identity_no','',true);
      this.setInvoiceInfo('tax_number','',true);
      this.setInvoiceInfo('gsm','',true);
      this.setInvoiceInfo('new_gsm','',true);
    }

  }

  setInvoiceInfo(type:string,$event:any,canEmpty:boolean=false){
   
    switch(type){

      case "email":
        if(canEmpty && $event == ''){
          this.invoiceInfo.email = '';
        }else{
          if($event.target.value){
            this.invoiceInfo.email = $event.target.value;
          }else{
            this.invoiceInfo.email = this.userInfo?.email;
          }
         
        }
      break;
      case "invoice_name":
        if(canEmpty && $event == ''){
          this.invoiceInfo.invoice_name = '';
        }else{
          let name = $event.target.value;
          if(name.length < 3){
            this.alertifyService.error("İsim en az 3 karakter olmalıdır");
            //this.invoiceInfo.invoice_name = "";
            return false;
          }else{
            this.invoiceInfo.invoice_name = name;
          }
        }
        
      break;

      case "invoice_surname":
        if(canEmpty && $event == ''){
          this.invoiceInfo.invoice_surname = '';
        }else{
          let surname = $event.target.value;
          if(surname.length < 3){
            this.alertifyService.error("İsim en az 3 karakter olmalıdır");
          //  this.invoiceInfo.invoice_surname = "";
            return false;
          }else{
            this.invoiceInfo.invoice_surname = surname;
          }
        }
        
      break;

      case "address":
        this.selectedAddressId = $event;
        this.invoiceInfo.address_id = $event;
        this.setSelectedAddressInfo();
      break;

      case "gsm":

        if(canEmpty && $event == ''){
          this.invoiceInfo.selectedPhone = '';
        }else{
          if($event == 0){
            this.openDrawer('gsmAdd');
          }else{
           // this.invoiceInfo.gsm_id = $event;
          
            let selectedPhone = this.gsmList.filter((item:any) => item.id == $event);
            if(selectedPhone.length > 0){
              this.invoiceInfo.gsm_number = selectedPhone[0].code;
              this.invoiceInfo.gsm_id = selectedPhone[0].id;
              
            }
           
          }
        }

        
        
      break;

      case "new_gsm":
       
        if(canEmpty && $event == ''){
          this.invoiceInfo.gsm_number = '';
        }else{
          if($event.target.value.length != 11){
            this.alertifyService.error("Gsm numarası 10 haneli olmalıdır");
            return false;
          }

          this.invoiceInfo.gsm_number = $event.target.value;
        }

         
       
      break;

      case "identity_no":
        if(canEmpty && $event == ''){
          this.invoiceInfo.identity_no = '';
        }else{
          let identity_no = $event.target.value;
          if(identity_no.length != 11){
            this.alertifyService.error("Tc kimlik no 11 karakter olmalıdır.");
           // this.invoiceInfo.identity_no = "";
            return false;
          }else{
            this.invoiceInfo.identity_no = identity_no;
          }
        }
        
      break;

      case "license_name":
        let license_name = $event.target.value;
        if(license_name.length < 3){
          this.alertifyService.error("Ad en az 3 karakter olmalıdır.");
       //   this.invoiceInfo.license_name = "";
          return false;
        }else{
          this.invoiceInfo.license_name = license_name;
        }
      break;

      case "license_surname":
        let license_surname = $event.target.value;
        if(license_surname.length < 3){
          this.alertifyService.error("Soyad en az 3 karakter olmalıdır.");
        //  this.invoiceInfo.license_surname = "";
          return false;
        }else{
          this.invoiceInfo.license_surname = license_surname;
        }
      break;

      case "plate":
        let plate = $event.target.value;
        if(plate.length < 3){
          this.alertifyService.error("Plaka en az 3 karakter olmalıdır.");
        //  this.invoiceInfo.plate = "";
          return false;
        }else{
          this.invoiceInfo.plate = plate;
        }
      break;

      case "chassis_no":
        let chassis_no = $event.target.value;
        this.invoiceInfo.chassis_no = chassis_no;
        if(chassis_no.length < 5){
          this.alertifyService.error("Şasi No en az 5 karakter olmalıdır.");
      //    this.invoiceInfo.chassis_no = "";
          return false;
        }
      break;

      case "engine_no":
        let engine_no = $event.target.value;
        if(engine_no.length > 5){
        
          this.invoiceInfo.engine_no = engine_no;
        }
      break;
    }
    

  }

  changeCardType(event:any){
    this.paymentFormGroup.patchValue({
      "CardType": event.target.value
    })
  }

  paymentProcess(){
    if(this.orderCardId != undefined){

      this.momentOrderId = moment().format('YYMMDDHmmss').toString()+"-"+this.orderCardId;

      this.updateOrderCards.mutate({id:this.orderCardId,changes:{hash_order_id:this.momentOrderId}}).subscribe((result:any)=>{
        if(result.errors){
          this.alertifyService.error(result.errors[0].message);
        }
        if(result.data){

          if(this.orderCardId != undefined){

            //api tarafında hash oluşturup alıyoruz
            this.PaymentService.getHash(this.InstallmentCount,this.orderCardId,this.totalAmount,this.momentOrderId).subscribe((result:any)=>{
              if(result.errors){
                this.alertifyService.error(result.errors[0].message);
              }
              if(result.hash){
                this.hash = result.hash;
                this.rnd = result.rnd;
                this.paymentFormGroup.patchValue({
                  Rnd: result.rnd,
                  Hash: result.hash
                });

                //card durumunu istek gönderildi yapıyoruz
                this.updateOrderCards.mutate({id:this.orderCardId,changes:{pos_process_status_id:2}}).subscribe((result:any)=>{
                  if(result.errors){
                    this.alertifyService.error(result.errors[0].message);
                  }
                  if(result.data){

                    this.paymentFormElement?.nativeElement.submit();

                    //bankadan gelen cevabın tabloda pos_process_status_id sekmesini başarılı veya başarısız yapmasını bekliyoruz 
                    this.orderCardPaymentSubs.subscribe({where:{id:{_eq:this.orderCardId}}}).subscribe(result=> {
     
                      this.subOrderCards = result.data?.order_cards[0];
                      
                 
                      if(this.subOrderCards?.pos_process_status_id == 4){
                        this.submit()
                        this.step = this.steps.siparisi_tamamla.id;

                     }
                 
                     if(this.subOrderCards?.pos_process_status_id == 3){
                       this.momentOrderId = moment().format('YYMMDDHmmss').toString()+"-"+this.orderCardId;
                 
                       this.showPosMessage = true;
                       
                 
                       this.paymentFailMessage = this.subOrderCards.pos_message;
                       var ths = this;
                       setTimeout(function(){
                         ths.removePaymentMessage();
                         ths.paymentFailMessage = "";
                         ths.showPosMessage = false;
                 
                       },5000)
                       
                    
                 
                     }
                    });
            
                  }
                });
      
              }
            })
          }
  
        }
      });

      
    }

    
  }

  removePaymentMessage(){
    this.updateOrderCards.mutate({id:this.orderCardId,changes:{pos_message:null}}).subscribe(result=> {

    });
  }

  goToTop(el: HTMLElement){
    el.scrollIntoView({behavior: 'smooth'});
  }

  teklif_al_mail(){
    /*
    if(this.userInfo?.email){
      this.showOrderSpinner = true;
      let message:string = "";
      message += "Müşteri İsim Soyisim : "+ this.userInfo.name + " " + this.userInfo.surname + "\n<br />";
      message += "Email : "+ this.userInfo.email + "\n<br />";
      message += "Telefon : "+ this.userInfo.gsms[0].code + "\n<br />";
      message += "Araç Bilgileri : " + "\n<br />";
      message += "Marka : " + this.vehicleInfo?.vehicle_brand.name +"\n<br />";
      message += "Model : " + this.vehicleInfo?.vehicle_model.name +"\n<br />";
      message += "Türü : " + this.vehicleInfo?.vehicle_model_types.name +"\n<br />";
      message += "Yıl : " + this.vehicleInfo?.years.year_id +"\n<br />";


      this.mailService.send("sistem@garantiliotoservis.com",'Garantili Oto Servis Teklif İsteği.',message).subscribe(mailResponse => {
        if(mailResponse.status){
    
          this.step = this.steps.mail_teklif_al_basarili.id;
     
        }
        this.showOrderSpinner = false;
      });

    }*/
  }

  searchManuelPart($event:any){

      this.searchPartOriginals.watch({where:{part_code:{_ilike:"%"+$event.target.value+"%"}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {
        this.manuelPartList = resp.data.part_original_list;
      })

 

  }

  setOrderParts(event:any){


    this.selectedPieces = [];

    if(event.parts != undefined && event.parts.length > 0){

      event.parts.forEach((element:any) => {
        this.selectedPieces.push({
          part_id: element.selectedGosPart_id, 
          part_type_id: ( element.selectedEquivalent_partTypeId ) ? element.selectedEquivalent_partTypeId : 0,
          part_value: ( element.selectedEquivalent_partValue ) ? element.selectedEquivalent_partValue : 0 ,
          selected_part_id: ( element.selectedEquivalent_id ) ? element.selectedEquivalent_id : 0,
          service_value: ( element.selectedEquivalent_partValue ) ? element.selectedEquivalent_partValue : 0,
          part_unit_type: element.selectedEquivalent_partUnitTypeId,
          part_unit: element.selectedEquivalent_partUnit,
          brand_labour_price: ( element.brandLabourPrice ) ?  element.brandLabourPrice : 0,
          gos_labour_price: ( element.gosLabourPrice ) ?  element.gosLabourPrice : 0,
          price_unit_id: element.selectedEquivalent_priceUnitId,
          currency_unit_price: ( element.selectedEquivalent_currencyPriceUnitId ) ? element.selectedEquivalent_currencyPriceUnitId : 0,
          currency_total_price: ( element.selectedEquivalent_currencyTotalPrice ) ? element.selectedEquivalent_currencyTotalPrice : 0,
          currency_price_unit_id: element.selectedEquivalent_currencyPriceUnitId,
          currency: 1, //TODO: o anki döviz kurunu kayıt edecek
          total_price_no_vat : ( element.selectedEquivalent_priceNoVatTotal ) ? element.selectedEquivalent_priceNoVatTotal : 0,
          total_price: ( element.selectedEquivalent_priceTotal ) ? element.selectedEquivalent_priceTotal : 0,
          part_code: element.selectedEquivalent_partCode,
          part_no_vat_price: ( element.selectedEquivalent_partNoVatValue ) ? element.selectedEquivalent_partNoVatValue : 0,
          selected_equivalent_name: element.selectedEquivalent_name,
          selected_equivalent_part_type_name: element.selectedEquivalent_partTypeName,
          part_unit_name: element.selectedEquivalent_partUnitName,
          selectedGosPart_id :  element.selectedGosPart_id,
          selectedGosPart_name: element.selectedGosPart_name,
          selectedGosPart_groupName: element.selectedGosPart_groupName
        })
      });

      this.totalAmount = event.amount.grandTotal;
      this.subTotal = event.amount.subTotal;
      this.vat = event.amount.difference;
      this.discount = 0; //TODO: discounta bak


      /**
       *  object:{
                    order_id:insertedId,
                    part_id:obj.part_id,
                    part_type_id:obj.part_type_id,
                    part_value:obj.price,
                    selected_part_id:obj.selected_part_id,
                    service_value:obj.service_value,
                    part_unit_type_id:obj.part_unit_type,
                    part_unit:obj.part_unit,
                    brand_labour_price: obj.brand_labour_price,
                    gos_labour_price: obj.gos_labour_price,
                    price_unit_id:obj.price_unit_id,
                    price_without_currency: obj.price_without_currency,
                    price_vat_without_currency: obj.price_vat_without_currency,
                    currency: obj.currency,
                    created_at: moment().format('yyyy-MM-DDTH:mm:ssZ'),
                    part_code: obj.part_code,
                    part_no_vat_price: obj.price_no_vat
                    }
       */

    }

  }

  changePaymentType(paymentId:Number | undefined){
    this.selectedPaymentTypes = paymentId;
  }



}



