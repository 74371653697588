<div class="w-100" *ngIf="!showSuccessMessage">
    <div class="row">

        <div class="card card-flush h-xl-100">
            <div class="card-header pt-5">
                <div class="card-title align-items-start flex-column">
                    <div class="card-label fw-bolder text-gray-800">Kayıt Formu</div>
                    <div class="text-gray-400 pt-1 fw-bold fs-6"></div>
                </div>
                
            </div>
            <div class="card-body py-3">

                <div class="row" *ngIf="modelType">
                    <div class="col-md-12">
                        <div class="alert alert-success">
                            <i class="fas fa-exclamation-circle ms-2 fs-7" style="color: #205237;"></i> &nbsp;
                            <strong>{{modelType.vehicle_model_year.vehicle_model.vehicle_brand.name}} {{modelType.vehicle_model_year.vehicle_model.name}}</strong> model aracınız için en kısa sürede dönüş yapacağız.
                            Lütfen formu doldurunuz.
                        </div>
                    </div>
                </div>
                
                
                <div class="mx-5 mx-xl-auto">
        
                    <form [formGroup]="userFormGroup" (ngSubmit)="formSubmit()" >
                        <div class="card card-flush h-xl-100" id="userInfoContainer">
                            
                            <div class="card-body py-3">
                                <div class="col-md-12">
                                    <div class="row fv-row mb-7">
                                        <div class="col-xl-6">
                                            <label class="form-label fw-bolder text-dark fs-6">Ad</label>
                                            <input class="form-control form-control-sm form-control-solid" 
                                                type="text"
                                                name="name"
                                                formControlName="name"
                                                autocomplete="off" placeholder="Ad"
                                                />
                                        </div>
                                        <div class="col-xl-6">
                                            <label class="form-label fw-bolder text-dark fs-6">Soyad</label>
                                            <input 
                                                class="form-control form-control-sm form-control-solid" 
                                                type="text" 
                                                name="surname"
                                                formControlName="surname"
                                                autocomplete="off" placeholder="Soyad" />
                                        </div>
                                    </div>
                                    <div class="row fv-row mb-7">
                                        <div class="col-xl-6">
                                            <label class="form-label fw-bolder text-dark fs-6">Email</label>
                                            <input 
                                                class="form-control form-control-sm form-control-solid" 
                                                formControlName="email"
                                                type="email" 
                                                placeholder=""
                                                name="email"
                                                autocomplete="off" 
                                                placeholder="Email" 
                                                    />
                                        </div>
                                        <div class="col-xl-6">
                                            <label class="form-label fw-bolder text-dark fs-6">Telefon</label>
                                            <input 
                                                class="form-control form-control-sm form-control-solid" 
                                                type="number" 
                                                name="Cep Telefonu" 
                                                autocomplete="off" 
                                                placeholder="Cep Telefonu" 
                                                formControlName="phone"
                                                    />
                                                
                                        </div>
                                    </div>  

                                    <div class="row fv-row mb-7">
                                        <div class="col-xl-6">
                                            <label class="form-label fw-bolder text-dark fs-6">Kilometre</label>
                                            <input 
                                                class="form-control form-control-sm form-control-solid" 
                                                formControlName="vehicle_km"
                                                type="text" 
                                                name="vehicle_km"
                                                autocomplete="off" 
                                                placeholder="Örnek: 50000" 
                                                    />
                                        </div>
                                        
                                    </div>  
                                    
                                    
                                
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="col-md-12 text-end">
                                    <input type="submit" class="btn btn-primary btn-sm" value="Gönder" [disabled]="userFormGroup.invalid">
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
               
                

            </div>

        </div>

    </div>


</div>

<div class="w-100" *ngIf="showSuccessMessage">
    <div class="row">

        <div class="card card-flush h-xl-100">
           
            <div class="card-body py-3">
                
                <div class="col-md-12">
                    <div class="alert alert-success">
                        <i class="fas fa-exclamation-circle ms-2 fs-7" style="color: #205237;"></i>
                       Kaydınız alınmıştır. En kısa sürede geri dönüş yapacağız.
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>