import { Injectable } from '@angular/core';
import { gql, Mutation, Query, Subscription } from 'apollo-angular';
import { VehicleModelType } from './vehicle-model-type.graphql';

export interface OrderCards {
  id: bigint;
  campaign_id: Number;
  combined_order_id: Number;
  fleet_id: Number;
  main_order_id: Number;
  personel_id: Number;
  service_id: bigint;
  service_status_id: Number;
  service_type_id: Number;
  source_id: Number;
  status_id: Number;
  customer_status_id: Number;
  steering_id: Number;
  type_id: Number;
  user_id: bigint;
  user_address_id: Number;
  service_date:Date;
  part_type_id: bigint;
  user:any;
  order_value: number;
  order_no_vat_value: number;
  service_value: number;
  maintenance_package_id: bigint;
  maintenance_packages:any;
  order_status:any;
  order_types:any;
  order_invoice_info:any;
  vehicle_id: bigint;
  vehicle:any;
  created_at:Date;
  pos_process_status_id:number;
  card_uuid: any;
  customer_id?:number;
  customer_address_id: bigint;
  customer_gsm_id:bigint;
  customer_tel_id:bigint;
  user_addresses: any;
  order_parts:any;
  user_address:any;
  order_packages:any;
  equivalent_list:any;
  companyByServiceId:any;
  customer_tel:any;
  customer_info:any;
  customer_order_status:any;
  step:number;
  is_complete:boolean;
  pos_message:String;
  vehicle_model_type_id:Number;
  maintenance_kms_id:Number;
  vehicle_model_types: VehicleModelType,
  customer_firstname: String;
  customer_lastname: String;
  customer_gsm: String;
  customer_email: String;
  payment_attempts: Number;
  vehicle_gear_id: Number;
  vehicle_fuel_id: Number;
  package_total_value: number;
  installment_count: Number;
  installment_amount: Number;
  url_utm_log_id: bigint;
}




interface UpdateMutation {
  update_order_cards: {
    affected_rows: number
    returning: [{
      id: bigint
    }]
  }
}

interface ResponseArray {
  order_cards: OrderCards[];
}


interface ResponseSingle {
  order_cards_by_pk: OrderCards
}

interface ResponseCustom{
  order_cards: OrderCards[];
}

export interface OrderCardInsert{
  insert_order_cards_one:{
    id: bigint,
    card_uuid: any
  }
}

export interface OrderCardPosSubs{
  id: Number
  pos_process_status_id: Number
}

@Injectable()
export class ORDER_CARDS_ID extends Query<ResponseSingle> {
  override document = gql`
    query ORDER_CARDS_ID($id: bigint!){
      order_cards_by_pk(id: $id) {
        id
        user_id
        status_id
        customer_status_id
        customer_id
        customer_id
        invoice_id
        service_invoice_id
        created_at
        updated_at
        type_id
        maintenance_package_id
        order_value
        order_no_vat_value
        package_total_value
        service_value
        vehicle_id
        service_id
        service_date
        customer_address_id
        customer_gsm_id
        customer_tel_id
        customer_firstname
        customer_lastname
        customer_gsm
        customer_email
        step
        is_complete
        pos_message
        vehicle_model_type_id
        maintenance_kms_id
        installment_count
        installment_amount
        url_utm_log_id
        vehicle{
          id
          track_id
          plate
          brand_id
          model_id
          type_id
          model_year_id
          colour_id
          traffic_release_date
          engine_no
          chassis_no
          note
          colour_code_id
          colour_type_id
          tire_id
          gear_type_id
          fuel_type_id
          last_km
          motor_id
          model_type_id
          garage_id
          license_name
          license_surname
        }
      }
      
    }
  `;
}

@Injectable()
export class ORDER_CARDS_CREATE extends Mutation<OrderCardInsert> {
  override document = gql`
  mutation ORDER_CARDS_CREATE( $object: order_cards_insert_input! ) {
    insert_order_cards_one(object: $object) {
        id   
        card_uuid
    }
}`;
}

@Injectable()
export class ORDER_CARDS_UPDATE extends Mutation<any> {
  override document = gql`
    mutation ORDER_CARDS_UPDATE($id: bigint!, $changes: order_cards_set_input ) {
      update_order_cards(where: {id: {_eq: $id}}, _set: $changes) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_CARDS_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query ORDER_CARDS_PAGINATE($limit: Int = 20, $offset: Int = 0, $orderBy: order_by = desc, $where: order_cards_bool_exp) {
    orders(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        user_id
        status_id
        customer_status_id
        customer_id
        created_at
        updated_at
        order_value
        installment_count
        installemnt_amount
        order_no_vat_value
        package_total_value
        pos_message
        installment_count
        installment_amount
      }
    }
  `;
}

@Injectable()
export class ORDER_CARDS_COUNT extends Query<any> {
  override document = gql`
    query ORDER_CARDS_COUNT( $where: order_cards_bool_exp) {
      order_cards_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
    
  `;
}

@Injectable()
export class ORDER_CARDS_ALL extends Query<ResponseArray>{
    override document = gql`
    query ORDER_CARDS_ALL( $where: order_cards_bool_exp) {
      order_cards( where: $where) {
        id
        status_id
        part_status_id
        service_status_id
        type_id
        customer_id
        service_id
        fleet_id
        user_id
        vehicle_id
        webservice_id
        campaign_id
        steering_id
        source_id
        replacement_vehicle_id
        url_utm_log_id
        repetation
        main_order_id
        combined_order_id
        track_id
        created_at
        updated_at
        service_date
        order_value
        package_total_value
        maintenance_package_id
        customer_address_id
        customer_gsm_id
        customer_tel_id
        customer_status_id
        order_no_vat_value
        step
        is_complete
        pos_message
        card_uuid
        vehicle_model_type_id
        maintenance_kms_id
        installment_count
        installment_amount
        order_card_invoice_info{
          order_card_id
          customer_name
        }
        vehicle_model_types{
          id
          vehicle_model_year{
            id
            year_id
            vehicle_model{
              id
              name
              gos_labour_price
              brand_labour_price
              vehicle_brand {
                id
                name
              }
            }
          }
        }
        vehicle{
          id
          track_id
          plate
          brand_id
          model_id
          type_id
          model_year_id
          colour_id
          traffic_release_date
          engine_no
          chassis_no
          note
          colour_code_id
          colour_type_id
          tire_id
          gear_type_id
          fuel_type_id
          last_km
          motor_id
          model_type_id
          garage_id
          license_name
          license_surname
        }
      }
    }`;
}
@Injectable()
export class ORDER_CARD_PAYMENT_SUBS extends Subscription<ResponseArray>{
  override document = gql`subscription order_cards($where:order_cards_bool_exp) {
      order_cards(where: $where) {
        id
        status_id
        part_status_id
        service_status_id
        type_id
        customer_id
        service_id
        fleet_id
        user_id
        vehicle_id
        webservice_id
        url_utm_log_id
        campaign_id
        steering_id
        source_id
        replacement_vehicle_id
        repetation
        main_order_id
        combined_order_id
        track_id
        created_at
        updated_at
        service_date
        order_value
        package_total_value
        maintenance_package_id
        customer_address_id
        customer_gsm_id
        customer_tel_id
        customer_status_id
        order_no_vat_value
        pos_process_status_id
        payment_attempts
        step
        is_complete
        pos_message
        card_uuid
        vehicle_model_type_id
        maintenance_kms_id
        installment_count
        installment_amount
        vehicle{
          id
          track_id
          plate
          brand_id
          model_id
          type_id
          model_year_id
          colour_id
          traffic_release_date
          engine_no
          chassis_no
          note
          colour_code_id
          colour_type_id
          tire_id
          gear_type_id
          fuel_type_id
          last_km
          motor_id
          model_type_id
          garage_id
          license_name
          license_surname
        }
      }
    }`;
}