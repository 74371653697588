import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import {  CREATE_COMPANY_EVALUATION_POINTS } from 'src/app/graphql/company_evaluation_points.graphql';
import { OrderInvoices, ORDER_INVOICE_ID, ORDER_INVOICE_ONE, ORDER_INVOICE_UPDATE } from 'src/app/graphql/order_invoices.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shared-rate',
  templateUrl: './rate.component.html',
  styleUrls:['./rate.component.css'],
  providers:[ORDER_INVOICE_ID,CREATE_COMPANY_EVALUATION_POINTS,ORDER_INVOICE_UPDATE]
})
export class RateComponent implements OnInit {

  
  public form: FormGroup;
  public invoice: OrderInvoices | undefined;
  @Input() companyId: bigint | undefined;

  constructor(
    private fb: FormBuilder,
    private alertifyService: AlertifyService,
    private getOrderInvoice: ORDER_INVOICE_ID,
    private createEvaluationPoint: CREATE_COMPANY_EVALUATION_POINTS,
    private authService: AuthService,
    private updateInvoice: ORDER_INVOICE_UPDATE
    ){
   
    this.form = this.fb.group({
      rating1: ['', Validators.required],
      rating2: ['', Validators.required],
      rating3: ['', Validators.required],
      rating4: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    
  }

  rate(){
    if(this.form.invalid){
      this.alertifyService.error("Lütfen bütün puanları seçiniz");
    }

    if(this.form.valid){

      
    
        Swal.fire({
          title: 'Lütfen fatura numaranızı giriniz?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          cancelButtonText: 'Vazgeç',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Değerlendir',
          input: 'text',
          inputLabel: 'Fatura Numarası',
         
        }).then((result) => {
          if (result.isConfirmed) {

            if(result.value && result.value.length > 0){
              this.getOrderInvoice.watch({id:result.value},{fetchPolicy:"no-cache"}).valueChanges.subscribe(orderResponse => {
                if(orderResponse.errors){
                  this.alertifyService.error(orderResponse.errors[0].message);
                }
                if(orderResponse.data){
                  this.invoice = orderResponse.data.order_invoices_by_pk;
                  if(this.invoice == null){
                    this.alertifyService.error("Hatalı fatura numarası girdiniz!");
                  }else if(this.invoice.is_rated){
                    this.alertifyService.error("Seçtiğiniz fatura daha önce değerlendirilmiş!");
                  }else{

                    this.createEvaluationPoint.mutate({
                      user_id: this.authService.userId,
                      company_id: this.companyId,
                      invoice_id: result.value,
                      evaluation_point: this.form.value.rating1,
                      evaluation_type_id: 1
                    }).subscribe(rate1result => {

                    })

                   

                    this.createEvaluationPoint.mutate({
                      user_id: this.authService.userId,
                      company_id: this.companyId,
                      invoice_id: result.value,
                      evaluation_point: this.form.value.rating2,
                      evaluation_type_id: 2
                    }).subscribe(rate2result => {
                      
                    })

                    this.createEvaluationPoint.mutate({
                      user_id: this.authService.userId,
                      company_id: this.companyId,
                      invoice_id: result.value,
                      evaluation_point: this.form.value.rating3,
                      evaluation_type_id: 3
                    }).subscribe(rate3result => {
                      
                    })

                    this.createEvaluationPoint.mutate({
                      user_id: this.authService.userId,
                      company_id: this.companyId,
                      invoice_id: result.value,
                      evaluation_point: this.form.value.rating4,
                      evaluation_type_id: 4
                    }).subscribe(rate4result => {
                      
                    });

                    this.updateInvoice.mutate({id:result.value,changes:{is_rated:true}}).subscribe((updateResult:any) => {
                      if(updateResult.errors){
                        this.alertifyService.error(updateResult.errors[0].message);
                      }

                      if(updateResult.data){
                        Swal.fire(
                          'Teşekkürler',
                          'Değerlendirmeniz kayıt edildi',
                          'success'
                        )
                      }
                    })
                    

                   

                  }
                }
              })
            }
          
          }
        })
      
    }
  }

  

}
