import cli from '@angular/cli';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { Company, COMPANY_ALL } from 'src/app/graphql/company.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment-v2-servis-secimi',
  templateUrl: './servis-secimi-v2.component.html',
  styles: [
  ],
  providers:[COMPANY_ALL]
})
export class ServisSecimiV2Component implements OnInit {

  public todayWithPipe: any;
  public selectedCityId:Number = 0;
  public selectedServiceId:bigint = BigInt("0");
  public selectedDistrictId:Number = 0;
  public companyList: Company[] | undefined;
  public serviceDate:any;
  public nearestServices: boolean = false;
  public tab = "manuel";
  public baseUrl: string = environment.laravelApiEndpoint;
  public showLoader = true;

  public userLocation = {
    lat: 0,
    lon: 0
  }



  @Output() serviceEmitter = new EventEmitter();



  constructor(
    private getCompany: COMPANY_ALL,
    private http: HttpClient,
    private alertifyService: AlertifyService
  ) { }

  ngOnInit(): void {
    moment.locale('tr');
    this.todayWithPipe = moment().add(1, 'days').endOf('day').format('yyyy-MM-DDTH:mm');


    

  }

  selected(type:string,event:any){

  }

  async changeCity($event:any,type:string="city"){
    this.companyList = [];
    this.selectedServiceId = BigInt("0");
    //this.selectedDistrictId = 0;
   // this.selectedCityId = 0;
    switch(type){
      case "city":
        this.selectedCityId = $event;
        this.selectedDistrictId = 0;
        break;
      case "district":
        this.selectedDistrictId = $event;
      break;  
      case "nearest":
        
        await this.getLocation()
          .then((location) => {
            this.userLocation.lat = location.latitude;
            this.userLocation.lon = location.longitude
           
          })
          .catch((error) => {
            console.error("Konum alınamadı:", error);
            this.tab = "manuel";
          });
        this.nearestServices = $event;
      break;
      default: 
          this.companyList = [];
      break;
    }

    let whereObj = {"active":{"_eq":true}};

    this.companyList = undefined;
    
    if(this.nearestServices){

       await this.getNearestServices().subscribe(async resp => {
        if(resp.status){
          if(resp.data){
           
            const selectedIds = resp.data.map(({ id }) => id);
            Object.assign(whereObj, { "id": { "_in": selectedIds}})
            
            this.getCompany.watch({ where: whereObj,order_by:{"name":"asc"} },{fetchPolicy:"no-cache"}).valueChanges.subscribe(async (result:any)=>{
              this.companyList = result.data.companies

              await this.companyList?.map(item=>{
                  resp.data.map(i => {
                   
                    if(i.id == item.id){
                      item.distance = i.km;
                    }
                  });
              });
              
              
              await this.sortService();
              this.showLoader = false;

            });

             
            

          }
        }
      });

     
    }else{
      if(this.selectedCityId > 0){
        Object.assign(whereObj, { "city_id": { "_eq": this.selectedCityId}})
      }
  
      if(this.selectedDistrictId > 0){
        Object.assign(whereObj, { "district_id": { "_eq": this.selectedDistrictId}})
      }
      this.getCompany.watch({ where: whereObj,order_by:{"name":"asc"} },{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
        this.companyList = result.data.companies
      });
    }

    

   
  }

  sortService(): void {
     this.companyList?.sort((a, b) => a.distance - b.distance);
  }

  setServiceDate($event:any){
    this.serviceDate = $event;
    this.serviceEmitter.emit({service_date : this.serviceDate, service_id: this.selectedServiceId});
  }

  selectService(id:bigint){
    this.selectedServiceId = id;
  }

  getLocation(): Promise<{ latitude: number; longitude: number }> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            resolve({ latitude, longitude });
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject(new Error("Tarayıcınız konum bilgisini desteklemiyor."));
      }
    });
  }

  tabChange(type:string){
    this.tab = type;
    if(this.tab == "nearest"){
      this.companyList = [];
      this.nearestServices = true;
      this.changeCity(true,'nearest');
    }
    if(this.tab == "manuel"){ 
      this.nearestServices = false;
      this.changeCity(null,'');
    }
  }

  getNearestServices(){
    const formData = new FormData();
    formData.append('latitude', this.userLocation.lat.toString());
    formData.append('longitude', this.userLocation.lon.toString());
   
     return this.http.post<any>(this.baseUrl + "nearest-services", formData);
  }

  

}
