import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, concat, InMemoryCache, split } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { debug } from 'console';
import { onError } from '@apollo/client/link/error';


const httpUrl = environment.hasuraHttpApiEndpoint;
const wsUrl = environment.hasuraWsApiEndpoint;
const token = localStorage.getItem('access_token');

interface Definintion {
  kind: string;
  operation?: string;
};

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink): ApolloClientOptions<any> {

        // Error
        const errorLink = onError(({ graphQLErrors, networkError }) => {

          

          if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) => {
              console.log(`[GraphQL error ]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            })
          }
          if (networkError) {
            console.log(`[Network error]: ${networkError}`);
          }
        });
        
        

        // Create an http link:
        const httpClient = httpLink.create({
          uri: httpUrl,
        });

        const authMiddleware = new ApolloLink((operation, forward) => {
          // add the authorization to the headers
          operation.setContext({
            headers: new HttpHeaders().set('Authorization', "Bearer "+localStorage.getItem('access_token') || '')
          });
    
          return forward(operation);
        });

        // Create a WebSocket link:
        const wsClient = new WebSocketLink({
          uri: wsUrl,
          options: {
            reconnect: true,
            connectionParams: {
              headers: {
                "Authorization": "Bearer " +localStorage.getItem('access_token')
              }
            }
          }
        });


        // Split
        const link = split(
          // split based on operation type
          ({ query }) => {
            const { kind, operation }: Definintion = getMainDefinition(query);
            //const data = getMainDefinition(query);
            return (
              kind === 'OperationDefinition' && operation === 'subscription'
            );
          },
          wsClient,
          concat(authMiddleware,httpClient),
        ).setOnError(() => { errorLink });

        return {
          link,
          cache: new InMemoryCache(),
          defaultOptions: {
            watchQuery: { errorPolicy: 'all' },
            mutate: { errorPolicy: 'all' },
            query: { errorPolicy: 'all' }
          }
        }
      },
      deps: [HttpLink],
    },
  ],
})

export class GraphQLModule { }