import { Component, Input, OnInit } from '@angular/core';
import { Company, COMPANY_ALL } from 'src/app/graphql/company.graphql';
import { Order } from 'src/app/graphql/order.graphql';
import { VehicleModelType, VEHICLE_MODEL_TYPE_ID } from 'src/app/graphql/vehicle-model-type.graphql';
import { numberFormatStr,numberFormat, trFormatCurrency } from 'src/app/helpers/slugify';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-shared-modal-proforma',
  templateUrl: './modal-proforma.component.html',
  styles: [
  ],
  providers:[COMPANY_ALL,VEHICLE_MODEL_TYPE_ID]
})
export class ModalProformaComponent implements OnInit {

  @Input() serviceInfo: any;
  @Input() customerInfo: any;
  @Input() gosInfo: any;
  @Input() invoice: any;
  @Input() hidePartNumbers:boolean = true;
  @Input() packageInfo: any;
  @Input() modelTypeId:number | undefined;
  @Input() vehicleInfo: any;


  public showPopup:boolean = false;
  public gosCompany: Company | undefined;
  public order: Order | undefined;

  public vehicleFuelType:any;
  public vehicleGearType:any;
  public priceInfo:any;
  public labourPrice:any;
  public vehicle:any;
  public serviceLastDate:any;
  public todayDate:any;
  public deliveryDate:any;
  public orderTypeInfo:any;
  public paymentTypeInfo:any;
  public partDiscount: any = 0;
  public labourDiscount: any = 0;
  public isMobile = false;


  constructor(
    private getCompanies: COMPANY_ALL,
    private alertifyService: AlertifyService,
    private getModelType: VEHICLE_MODEL_TYPE_ID
  ) { }

  async ngOnInit():Promise<void>{

      this.isMobile = this.isMobileDevice();
      
      await this.getCompanies.watch({where:{id:{_eq:1106}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{
        if(resp.errors){
          this.alertifyService.error(resp.errors[0].message);
        }
        if(resp.data){
          this.gosCompany = resp.data.companies[0];
  
          
        }
      })
  
      if(this.modelTypeId){
        await this.getModelType.watch({id:this.modelTypeId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {
          if(resp.errors){
            this.alertifyService.error(resp.errors[0].message);
          }
  
          if(resp.data){
            this.vehicleInfo = resp.data.vehicle_model_types_by_pk;
  
          }
        })
      }
  }
  async ngChanges(): Promise<void> {

   
  }

  isMobileDevice(): boolean {
    return /Mobi|Android/i.test(navigator.userAgent);
  }

  showProforma(){
    this.showPopup = !this.showPopup;
  }

  updateOrderPopup($event:any){
    this.showPopup = false;
  }

  toNumberFormat(number: any) {
    return numberFormatStr(number);
  }

  numFormat(number: any) {
    return numberFormat(number);
  }

  trFormantPrice(number:any){
    return trFormatCurrency(number);
  }

  filterCode(item:any){
    if(!this.hidePartNumbers){
      return item;
    }
    if(item != null){
      const firstThreeDigits = item.substr(0, 3);
      const remainingChars = item.substr(3);
      const convertedString = firstThreeDigits + remainingChars.replace(/./g, '*');
      return convertedString;
    }

    return null
 
  }

}
