<div class="d-flex align-items-center selectwo"  style="position: relative;">
    <div
   #selectTwoNativeElement
    class="form-select form-select-solid slctinput position-relative selectwo"
    [ngClass]="{
      'slctinput-valid': addRequiredValidator && isValid,
      'slctinput-invalid': addRequiredValidator && !isValid
     
     
    }"
  >
    <!--begin::Label-->
    <div class="text-muted fs-8 selectwo" style="color:#666 !important" [innerHTML]="inputval"></div>
    <!--end::Label-->
  </div>
  

    
    <div 
        style="top:30px; width:100%; left: 0px; color: #666;"
       
       id="{{id}}" 
        class="menu menu-sub menu-sub-dropdown p-7  position-absolute selectwo">
        <!--begin::Wrapper-->
        <div class="selectwo">
            
            <!--begin::Form-->
            <form class="w-100 position-relative mb-3" action="javascript::void(0)">
          
        
            <!--begin::Input-->
            <input type="text" 
            class="searchinput form-control form-control-sm ps-10" 
            (keyup)="search($event)" 
            name="search" value="" 
            autocomplete="off" autofill="off"
            placeholder="Ara..." 
            />
            <!--end::Input-->
            
            </form>
            <!--end::Form-->
        
            <!--begin::Separator-->
            <div class="separator border-gray-200 mb-6"></div>
            <!--end::Separator-->
        
        
            <!--begin::Recently viewed-->
            <div class="selectwo">
            <!--begin::Items-->
            <div class="scroll-y mh-200px mh-lg-325px selectwo">
                <!--begin::Category title-->
                <h3 class="fs-5 text-muted m-0 pb-5" style="display: none;">
                {{name}}
                </h3>
                <span class="fs-5 text-muted m-0 pb-5" *ngIf="lists == undefined || lists.length == 0">
                    Hiç bir sonuç bulunamadı!
                </span>
                <!--end::Category title-->

                <a 
                class="d-flex text-dark text-hover-primary align-items-center mb-5 selectwoItem"
              
                (click)="selectItem(0)"
                *ngIf="showAll">Hepsi</a>
        
                <!--begin::Item-->
                <a 
                *ngFor="let item of filteringItems" 
                class="d-flex text-dark text-hover-primary align-items-center mb-5 selectwoItem"
                style="padding: 10px;"
                (click)="selectItem(item[fieldName])">
                <!--begin::Symbol-->
                
                <div class="symbol symbol-40px me-4" *ngIf="item?.image">
                    <img src="{{ item.image }}" alt=""/>
                </div>
                <!--end::Symbol-->
        
                <!--begin::Title-->
                <div *ngIf="!isUserSearch; else userSearchTemp" class="d-flex flex-column justify-content-start fw-bold ">
                    <span class="fs-6 fw-bold" data-id="{{item[returnField]}}" [innerHTML]="getField(item)"></span>
                  
                </div>
                <!--end::Title-->

                <ng-template  #userSearchTemp>
                    <div class="d-flex flex-column justify-content-start fw-bold ">
                        <div
                        *ngFor="let resutlItems of resultDesign; let i = index">
                        <span 
                        class="fs-6 fw-bold" 
                        [ngClass]="{'text-muted': i > 0}"
                        data-id="{{item[returnField]}}"
                        *ngFor="let resultItem of resutlItems"
                        > 
                            <div *ngIf=" isObject(resultItem) == 'object'; else notArr" >
                                <span *ngFor="let subItem of item[objectkey(resultItem)]"> {{subItem[objectkey(subItem)]}}</span>
                            </div>
                            <ng-template  #notArr>{{item[resultItem]}} </ng-template> 
                       </span>
                    </div>
                    </div>
                </ng-template>
                

                </a>
                <!--end::Item-->
            </div>
            <!--end::Items-->
            </div>
            <!--end::Recently viewed-->
        
        </div>
        <!--end::Wrapper-->
        </div>
