import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class CdnService {

  private options = {
    headers: new HttpHeaders({
      'Authorization': `Bearer ${environment.cdnToken}`
    })
  }


  constructor(
    private http: HttpClient
  ) { }

  upload(pathName: string, image: any, bucketName: string = "garantili-oto-servis"): Observable<any> {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('bucket', bucketName);
    formData.append('path', pathName);
    return this.http.post<any>(environment.cdnEndpoint + "upload", formData, this.options);
  }

  delete(objectName: string, bucketName: string = "garantili-oto-servis"): Observable<any> {
    return this.http.delete<any>(environment.cdnEndpoint + bucketName + "/" + objectName, this.options);
  }

}