import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertifyService } from '../alertify.service';
import { AuthService } from '../auth.service';

@Injectable()
export class ServiceGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private alertifyService: AlertifyService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
   
      if (this.authService.isTokenExpired()) {
        this.authService.logout()
        this.router.navigateByUrl('/auth')
        this.alertifyService.error("Oturum Süreniz Sona Erdi!")
        return false
      } else if (this.isLogin() && this.isService()) {
        return true
      } else {
        return new Observable<boolean>(obs => {
          this.authService.verify().subscribe((result:any)=>{
            if (result.status) {
              this.authService.login()
              this.authService.setUser().subscribe((result:any)=>{
                if (result.data) {
                  this.authService.user = result.data.users_by_pk
                  this.authService.currentUser = result.data.users_by_pk
                  if (this.isService()) {
                    obs.next(true)
                  } else {
               
                    this.alertifyService.error("Bu Alana Girme Yetkiniz Bulunmuyor!")
                    this.router.navigateByUrl('/')
                    obs.next(false)
                  }
                } else {
                  this.alertifyService.error("Kullanıcı Bulunamadı!")
                  obs.next(false)
                }
              })
            } else {
              this.authService.logout()
              this.router.navigateByUrl('/login')
         
              this.alertifyService.error("Bu Alana Girme Yetkiniz Bulunmuyor!")
              obs.next(false)
            }
          })
        })
      }
  }

  isLogin(): boolean {
    return this.authService.isLogin;
  }

  isService(): boolean {
    return this.authService.userRole === "service";
  }

}
