import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface PartModelTypes {
  id: Number;
  part_id: Number;
  vehicle_model_type_id?: Number;
  brand_labour_hour?: Number;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

interface ResponseArray {
  part_model_types: PartModelTypes[];
}

interface ResponseSingle {
  part_model_types_by_pk: PartModelTypes
}

interface InsertMutation {
  insert_part_model_types: {
    affected_rows: number
    returning: [{
      id: Number
    }]
  }
}

@Injectable()
export class PART_MODEL_TYPES_ALL extends Query<ResponseArray> {
  override document = gql`
    query PART_MODEL_TYPES_ALL($where: part_model_types_bool_exp){
      part_model_types(where: $where) {
        id
        part_id
        vehicle_model_type_id
        brand_labour_hour
        created_at
        updated_at
        deleted_at
      }
    }
  `;
}

@Injectable()
export class PART_MODEL_TYPES_GET extends Query<ResponseArray> {
  override document = gql`
    query PART_MODEL_TYPES_GET($part_id: bigint = "", $vehicle_model_type_id: bigint = "") {
        part_model_types(where: {_and: {part_id: {_eq: $part_id}, vehicle_model_type_id: {_eq: $vehicle_model_type_id}}}) {
        id
        part_id
        vehicle_model_type_id
        brand_labour_hour
        created_at
        updated_at
        deleted_at
        }
    }
  `;
}



@Injectable()
export class PART_MODEL_TYPES_ID extends Query<ResponseSingle> {
  override document = gql`
    query PART_ID($id: bigint!){
      part_model_types_by_pk(id: $id) {
        id
        part_id
        vehicle_model_type_id
        brand_labour_hour
        created_at
        updated_at
        deleted_at
      }
    }
  `;
}


@Injectable()
export class PART_MODEL_TYPES_CREATE extends Mutation<InsertMutation> {
  // connected_part_id - int | nullable
  override document = gql`
    mutation PART_MODEL_TYPES_CREATE(
      $part_id: Int!, 
      $vehicle_model_type_id: Int, 
      $brand_labour_hour: numeric!, 
      $created_at: Date, 
      ) {
      insert_part_model_types(objects: {
        part_id: $part_id, 
        vehicle_model_type_id: $vehicle_model_type_id, 
        brand_labour_hour: $brand_labour_hour, 
        created_at: $created_at
      }) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PART_MODEL_TYPES_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
    mutation PART_MODEL_TYPES_UPDATE($id: bigint!, $part_id: Int, $vehicle_model_type_id: Int, $brand_labour_hour: numeric, $updated_at: Date ) {
      update_part_model_types(where: {id: {_eq: $id}}, _set: 
        {
            part_id: $part_id, 
            vehicle_model_type_id: $vehicle_model_type_id, 
            brand_labour_hour: $brand_labour_hour, 
            updated_at: $updated_at
        }) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}