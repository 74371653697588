import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class GoogleService {

    constructor(
        private http: HttpClient
        //private angAuth: AngularFire
    ) {

    }

}