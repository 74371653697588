import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface PriceUnits {
  id: bigint;
  name: String;
  code: String;
  
}

interface ResponseArray {
    price_units: PriceUnits[];
}

interface ResponseSingle {
    price_units_by_pk: PriceUnits
}

@Injectable()
export class PRICE_UNITS_ALL extends Query<ResponseArray> {
  override document = gql`
    query PRICE_UNITS_ALL($where: price_units_bool_exp){
      price_units(where: $where) {
        id
        name
        code
      }
    }`;
}

@Injectable()
export class PRICE_UNITS_ID extends Query<ResponseSingle> {
  override document = gql`
    query PRICE_UNITS_ID($id: bigint!){
      price_units_by_pk(id: $id) {
        id
        name
        code
      }
    }
  `;
}

@Injectable()
export class PRICE_UNITS_CREATE extends Mutation<any> {
  // connected_part_id - int | nullable
  override document = gql`
  mutation PRICE_UNITS_CREATE(
      $name: String, 
      $code: String
      ) {
    insert_company_gsms(objects: {
        name: $name, code: $code}) {
      affected_rows
      returning {
        id
      }
    }
  }
  
  `;
}

@Injectable()
export class PRICE_UNITS_UPDATE extends Mutation<any> {
  override document = gql`
  mutation PRICE_UNITS_UPDATE(
      $id: bigint, 
      $name: String!, 
      $code: String) {
    update_company_gsms(where: {id: {_eq: $id}}, _set: {code: $code, name: $name}) {
      affected_rows
      returning {
        id
      }
    }
  }`;
}

@Injectable()
export class PRICE_UNITS_DELETE extends Mutation<any> {
  override document = gql`
    mutation PRICE_UNITS_DELETE($id: bigint!) {
      delete_price_units(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PRICE_UNITS_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query PRICE_UNITS_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = desc, $where: company_gsms_bool_exp) {
      company_gsms(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        name
        code
      }
    }
  `;
}

@Injectable()
export class PRICE_UNITS_COUNT extends Query<any> {
  override document = gql`
  query PRICE_UNITS_COUNT {
    price_untis_aggregate {
      aggregate {
        count
      }
    }
  }
  `;
}