import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company, COMPANY_ID } from 'src/app/graphql/company.graphql';
import { CompanyFranchise } from 'src/app/graphql/company_franchise.graphql';
import { AuthService } from 'src/app/services/auth.service';
declare const google:any;


@Component({
  selector: 'app-vehicle-services-detail',
  templateUrl: './vehicle-services-detail.component.html',
  providers:[COMPANY_ID]
})
export class VehicleServicesDetailComponent implements OnInit, AfterViewInit {
  public detailId: number | undefined;
  public slug: string | undefined;
  public lat: any;
  public lng: any;
  public center: any;
  public options: any;
  public companyDetail: Company | undefined;
  public franchiseList: [] = [];
  public isLogin: boolean = false;
  
  map:any;
  @ViewChild('mapElement') mapElement:any;
  @ViewChild('addressElement') addressElement:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private getCompany: COMPANY_ID,
    private authService: AuthService,
    private http: HttpClient
    ) { 
    this.detailId = this.activatedRoute.snapshot.params["id"]
    this.slug = this.activatedRoute.snapshot.params['slug'];
    let exp = this.slug?.split("-").pop();
    if(this.slug != undefined && exp){
      this.detailId = parseInt(exp);
    }
  }
  ngAfterViewInit(): void {
    let myLatLng;
    if(this.companyDetail !== undefined && this.companyDetail.lat != null && this.companyDetail.lon !== undefined){
       myLatLng = { lat: this.companyDetail.lat, lng: this.companyDetail.lon };
    }else{
       myLatLng = { lat: 40.93567, lng: 29.15507 };
    }

    this.mapElement.nativeElement.style.height = (this.addressElement.nativeElement.clientHeight + 52) + "px";

    this.map = new google.maps.Map(this.mapElement.nativeElement, {
      center: myLatLng,
      zoom: 15,
      heading: 320,
      tilt: 47.5,
      mapId: "90f87356969d889c",
    });


    new google.maps.Marker({
      position: myLatLng,
      map: this.map,
      title: "Hello World!",
    });

    google.maps.event.trigger(this.map, 'resize');

  }
  

  ngOnInit(): void {
    if(this.detailId){
        this.getCompany.watch({'id':this.detailId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response=>{
          this.companyDetail = response.data.companies_by_pk;
        })
    }

    this.isLogin = this.authService.isLogin;
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  sendToGA(type:string,code:any){
    switch(type){
      case "phone":
      this.http.get('https://gos.garantiliotoservis.com/garantili-servisler/07-fatih-oto-servis-139/phone');

      break;
    }
  }
  

  

}
