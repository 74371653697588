import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const LOCALIZATION_STORAGE_KEY = 'language';

@Injectable()
export class TranslationService {

    constructor(
        private translate: TranslateService
    ) {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('tr');
        this.translate.addLangs(['tr', 'en'])

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.initLanguage();
    }

    // Load Language
    initLanguage() {
        this.translate.use(this.getSelectedLanguage());
    }

    // Change Language
    setLanguage(lang: string) {
        this.translate.use(lang);
        localStorage.setItem(LOCALIZATION_STORAGE_KEY, lang);
    }

    // Get Languages
    getLangs() {
        return this.translate.getLangs();
    }

    // Selected Language
    getSelectedLanguage(): any {
        return (
            localStorage.getItem(LOCALIZATION_STORAGE_KEY) ||
            this.translate.getDefaultLang()
        );
    }
}
