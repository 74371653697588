<form [formGroup]="firstStepForm">
    <div class="d-flex justify-content-between py-5 form-container">

        <div class="form-content-container">
        
                <div class="tab-input-container">
                    <input 
                        type="text" 
                        class="form-control tab-input" 
                        placeholder="Ad"
                        name="name"
                        (change)="selectedTwo('customer_name',$event)"
                        autocomplete="off" 
                        [value]="firstStepForm.value.customer_name"
                       
                        >
                </div>

                <div class="tab-input-container">
                    <input 
                        type="text" 
                        class="form-control tab-input" 
                        placeholder="Soyad"
                        name="soyad"
                        (change)="selectedTwo('customer_surname',$event)"
                        autocomplete="off" 
                        [value]="firstStepForm.value.customer_surname"
                       
                        >
                </div>

                <div class="tab-input-container">
                    <input 
                        type="email" 
                        class="form-control tab-input" 
                        placeholder="Email"
                        name="email"
                        (change)="selectedTwo('customer_email',$event)"
                        autocomplete="off"
                        [value]="firstStepForm.value.customer_email"
                        
                        >
                </div>

                <div class="tab-input-container">
                    <app-shared-phone-mask [phoneNumber]="firstStepForm.value.customer_phone" (phoneEmitter)="selectedTwo('customer_phone',$event)"></app-shared-phone-mask>

                
                </div>

                <div class="tab-input-container">
                    <input 
                        type="text" 
                        class="form-control tab-input" 
                        placeholder="Mesleğiniz"
                        name="Mesleğiniz" 
                        (change)="selectedTwo('customer_job',$event)"
                        autocomplete="off"
                        [value]="firstStepForm.value.customer_job">
                </div>
            

        </div>
    
        <div class="form-content-container">
        
            <div class="tab-input-container">
                <app-shared-selectwo
                    [name] = "brandName"
                    [lists] = "vehicleBrandList"
                    [id]="'vehicleBrands'"
                    [setItem]="firstStepForm.value.vehicle_brand_id"
                    (selectedItem)="selectedTwo('brand',$event)"
                    [showAll]="false">
                </app-shared-selectwo> 
            </div>

            <div class="tab-input-container">
                <app-shared-selectwo
                [name] = "modelName"
                [lists] = "vehicleModelList"
                [id]="'vehicleModels'"
                [setItem]="firstStepForm.value.vehicle_model_id"
                (selectedItem)="selectedTwo('model',$event)"
                [showAll]="false"
            >
            </app-shared-selectwo>
            </div>

            <div class="tab-input-container">
                <app-shared-selectwo 
                    [lists]="vehicleModelYearList" 
                    (selectedItem)="selectedTwo('year',$event)" 
                    [name]="selectedYear" 
                    [id]="'vehicle-year'"
                    [setItem]="firstStepForm.value.vehicle_year_id"
                    [fieldName]="'name'"
                    [showAll]="false"
                    [returnField]="'id'">
                </app-shared-selectwo>
            </div>

            <div class="tab-input-container">
                <app-shared-selectwo
                    [name] = "modelTypeName"
                    [lists] = "vehicleModelTypeList"
                    [id]="'vehicleModelTypes'"
                    [setItem]="firstStepForm.value.vehicle_model_type_id"
                    [showAll]="false"
                    (selectedItem)="selectedTwo('vehicle_model_types',$event)">
                </app-shared-selectwo> 
            </div>

            <div class="tab-input-container">
                <app-shared-kms-selector 
                    [kms]="vehicleKmsList"
                    [selectedKmId]="firstStepForm.value.km_id"
                    [customerKm]="firstStepForm.value.customerKm"
                    (kmInfo)="selectedTwo('km',$event)"></app-shared-kms-selector>
            </div>

        </div>
       
        <div class="form-content-container">
            <div class="tab-input-container">
                <div class="tab-brand-logo">
                    <img *ngIf="selectedVehicleBrandInfo && selectedVehicleBrandInfo.logo_url != null && selectedVehicleBrandInfo.logo_url != ''"  src="https://cdn.destechhasar.com/garantili-oto-servis/{{selectedVehicleBrandInfo.logo_url}}" alt="">
                    <img  *ngIf="selectedVehicleBrandInfo?.logo_url === null ||selectedVehicleBrandInfo?.logo_url == ''"   src="http://cdn.destechhasar.com/garantili-oto-servis/w:169/h:143/garantili-oto-servis-logo.png" alt="" />

                </div>
            </div>

            <div class="tab-input-container d-flex justify-content-between">
                
                    <select name="" id="" class="form-control gos-select" (change)="selectedTwo('gear',$event)">
                        <option value="" [selected]="firstStepForm.value.gear_type_id ==  null">Seçiniz</option>
                        <option value="{{item.id}}" [selected]="firstStepForm.value.gear_type_id == item.id" *ngFor="let item of vehicleGearList">{{item.name}}</option>
                    </select>
                
            </div>

            <div class="tab-input-container d-flex justify-content-between">
                <select name="" id=""  class="form-control gos-select" (change)="selectedTwo('fuel',$event)">
                    <option value="" [selected]="firstStepForm.value.fuel_type_id ==  null">Seçiniz</option>
                    <option value="{{item.id}}" [selected]="firstStepForm.value.fuel_type_id == item.id" *ngFor="let item of vehicleFuelList">{{item.name}}</option>
                
                </select>
            </div>

        </div>
    
    </div>


    <div class="d-flex justify-content-center pb-5">
        <div class="form-content-container form-select">
            <select name="" id="" class="form-control" (change)="setOrderType($event)">
                <option value="">Hizmet Türü Seçiniz</option>
                <option value="{{item.id}}" [selected]="orderTypeId == item.id" *ngFor="let item of orderTypes">{{item.name}}</option>
            
            </select>
        </div>
    </div>

    <div class="d-flex justify-content-center pb-5">
        <div class="form-content-container form-check w-100 package-selector-container">
        
            <div class="package-select-items" *ngFor="let item of packageList">
                <label for="package_radio_{{item.id}}" class="package-label">
                    <div class="package-selector-col radio-inline">
                        <input [checked]="selectedPackage?.id == item.id" type="radio" name="package" id="package_radio_{{item.id}}" value="{{item.id}}" (click)="selectedTwo('package',item)">
                    </div>
                    <div class="package-selector-col  radio-inline">
                        <p>{{item.name}}</p>
                    </div>
                </label>
        </div>

        </div>
    </div>

    <div class="d-flex justify-content-center pb-5 form-container">
        <div class="form-content-container form-check">
            <input type="checkbox" class="form form-checkbox" [checked]="firstStepForm.value.approved" (change)="selectedTwo('approved',$event)"> 
        
            <span> Yukarıdaki bilgileri onaylıyorum</span>
        </div>
    </div>
</form>