import { Injectable } from "@angular/core";
import { gql, Mutation, Query } from "apollo-angular";

export interface UserAddress {
  id: bigint,
  user_id: bigint,
  country_id: Number,
  city_id: Number,
  district_id: Number,
  address_name: String,
  address: String,
  active: Boolean,
  town_id: Number,
  is_primary: Number,
  address_type: Number,
  identity_no: bigint,
  firstname: String,
  lastname: String,
  tax_administration: String,
  tax_number: String,
  company_name: String,
  city?: {
    name: String
  }
  country?: {
    name: String
  }
  district?: {
    name: String
  }
  town?: {
    name: String
  }
}

interface ResponseArray {
  user_address: UserAddress[]
}

interface ResponseSingle {
  user_address_by_pk: UserAddress
}

@Injectable()
export class USER_ADDRESS_ALL extends Query<ResponseArray> {
  override document = gql`
  query USER_ADDRESS_ALL($where: user_address_bool_exp) {
    user_address(where:$where) {
      id
      user_id
      town_id
      district_id
      country_id
      city_id
      address_name
      address
      active
      is_primary
      city{
        name
      }
      country{
        name
      }
      district{
        name
      }
      town{
        name
      }
    }
  }`;
}

@Injectable()
export class USER_ADDRESS_CREATE extends Mutation<any> {
  override document = gql`
  mutation USER_ADDRESS_CREATE(
    $address: String, 
    $address_name: String, 
    $city_id: Int, 
    $country_id: Int, 
    $district_id: Int, 
    $town_id: Int, 
    $user_id: bigint,
    $active: Boolean,
    $firstname: String,
    $lastname: String,
    $identity_no: bigint,
    $address_type: Int,
    $company_name: String,
    $tax_administration: String,
    $tax_number:String) {
    insert_user_address(objects: {
      address: $address, 
      address_name: $address_name, 
      city_id: $city_id, 
      country_id: $country_id, 
      district_id: $district_id, 
      town_id: $town_id, 
      user_id: $user_id,
      active: $active,
      firstname: $firstname,
      lastname: $lastname,
      identity_no: $identity_no,
      address_type: $address_type,
      company_name: $company_name,
      tax_administration: $tax_administration,
      tax_number: $tax_number
    })
    {
      returning {id}
    }
  }`;
}

@Injectable()
export class USER_ADDRESS_UPDATE_CUSTOM extends Mutation<any>{
  override document = gql`
    mutation USER_ADDRESS_UPDATE_CUSTOM(
      $where: user_address_bool_exp!,
      $changes: user_address_set_input
    ){
      update_user_address(where:$where,  _set: $changes){
        affected_rows
        returning{
          id
        }
      }
    }
  `;
}

@Injectable()
export class USER_ADDRESS_UPDATE extends Mutation<any> {
  override document = gql`
  mutation USER_ADDRESS_UPDATE(
    $id: bigint, 
    $address: String = "", 
    $address_name: String = "", 
    $city_id: Int, 
    $country_id: Int, 
    $district_id: Int, 
    $town_id: Int,
    $active: Boolean) {
    update_user_address(where: {
      id: {_eq: $id}}, 
      _set: {
        address: $address, 
        address_name: $address_name, 
        city_id: $city_id, 
        country_id: $country_id, 
        district_id: $district_id, 
        town_id: $town_id,
        active: $active}) {
      affected_rows
    }
  }`;
}


@Injectable()
export class USER_ADDRESS_USER_ID extends Query<ResponseArray>{
  override document = gql`
  query USER_ADDRESS_USER_ID($user_id: bigitn) {
    user_address(where: {user_id: {_eq: $user_id}}) {
      id
      user_id
      country_id
      city_id
      district_id
      address_name
      address
      active
      is_primary
      city {
        name
      }
      country {
        name
      }
      district {
        name
      }
      town {
        name
      }
    }
  }`;
}

@Injectable()
export class USER_ADDRESS_ID extends Query<ResponseSingle>{
  override document = gql`
  query USER_ADDRESS_ID($id: bigint!) {
    user_address_by_pk(id: $id) {
      address
      address_name
      city_id
      country_id
      is_primary
      id
      active
      town_id
      user_id
      district_id
    }
  }`;
}

@Injectable()
export class USER_ADDRESS_DEACTIVE extends Mutation {
  override document = gql`
  mutation USER_ADDRESS_DEACTIVE($user_id: Int) {
    update_user_address(where: {user_id: {_eq: $user_id}}, _set: {active: false}) {
      affected_rows
    }
  }`;
}

@Injectable()
export class USER_ADDRESS_PAGINATE extends Query<ResponseArray> {
  override document = gql`
  query USER_ADDRESS_PAGINATE($limit: Int, $offset: Int, $user_id: bigint) {
    user_address(limit: $limit, offset: $offset, order_by: {is_primary: desc}, where: {user_id: {_eq: $user_id}}) {
      id
      user_id
      town_id
      district_id
      country_id
      city_id
      address_name
      is_primary
      address
      active
      city{
        name
      }
      country{
        name
      }
      district{
        name
      }
      town{
        name
      }
    }
  }`;
}

@Injectable()
export class USER_ADDRESS_DELETE extends Mutation<any> {
  override document = gql`
    mutation USER_ADDRESS_DELETE($id: bigint!) {
      delete_user_address(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class USER_ADDRESS_COUNT extends Query<any> {
  override document = gql`
  query USER_ADDRESS_COUNT($where: user_address_bool_exp) {
    user_address_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  `;
}

