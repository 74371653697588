<div *ngIf="showStatusName">
    <p   class="text-bold" *ngIf="orderTransactions">
       ( {{curentStatusName}} )
    </p>
</div>

<div  *ngFor="let orderTransaction of orderTransactions">

    <div [ngSwitch]="orderTransaction.next_status.id">
        
        <!-- servis tarihi değiştir -->
        <button  
            *ngSwitchCase="15" 
            type="button" 
            class="btn btn-sm mb-5 {{orderTransaction.next_status.class_name}}"
            (click)="openDrawer('changeServiceDate',orderTransaction.next_status.id,orderTransaction.order_status_id)" >
            {{orderTransaction.next_status.button_name}} 
        </button>

        
  <button *ngSwitchDefault 
       
        [disabled]="((order?.status_id == 12 && roleId == 5) || buttonDisabled)"
        type="button" class="btn btn-sm mb-5  {{orderTransaction.next_status.class_name}}"
        (click)="transaction(orderTransaction.next_status.id,orderTransaction.order_status.id)" >
        {{orderTransaction.next_status.button_name}}
    </button>
    </div>
</div>

<button 
       
        *ngIf="showCancelButton"
        type="button" class="btn btn-sm mb-5 btn-warning"
        (click)="orderCancel()" >
        Randevuyu İptal Et
    </button>



<app-shared-drawer [show]="showDrawer" [title]="drawerTitle" (toggleDrawer)="toggleDrawer()">
    <div *ngIf="drawerTab === 'changeServiceDate'">
        <div class="col-lg-12 col-md-9 col-sm-12">
            <label for="" class="required form-label">Mevcut Servis Tarihi</label>
            <p class="fs-6 fw-bolder text-gray-700 mb-3">
                {{serviceDate}}
            </p>
        </div>
        <div class="col-lg-12 col-md-9 col-sm-12">
            <label for="" class="required form-label">Revize Et</label>
            <p class="fs-6 fw-bolder text-gray-700 mb-3">
                <input value="{{serviceDate}}" type="datetime-local" min="{{todayWithPipe}}" (change)="selected('serviceDate',$event)" class="form-control form-control-inline input-medium date-picker" placeholder="Bakım Tarihi Seçiniz" >          
            </p>
        </div>
       

        <app-shared-appointment-date-selector
        *ngIf="order?.service_id"
        [companyId]="order?.service_id"
        (selected)="selected('serviceDate',$event)"
        >

        </app-shared-appointment-date-selector>

        <div class="d-flex justify-content-end">
            <p class="fs-6 fw-bolder text-gray-700 mb-3">
                <button class="btn btn-primary btn-sm" (click)="updateServiceDate()" >Revize Et</button>                
            </p>
            
        </div>
    </div>


</app-shared-drawer>