 <!-- begin::Wrapper-->
 <div class="mw-lg-950px mx-auto w-100 " style="padding: 25px; border: 1px solid #f0f0f0;" id="pdfTable" #pdfTable>
    <!-- begin::Header-->
    <div class="d-flex justify-content-center flex-column flex-sm-row mb-19">
        <!--end::Logo-->
        <div class="text-sm">
            <!--begin::Logo-->
           <i class="fas fa-check" style="
               display: block;
                margin: auto auto;
                text-align: center;
                font-size: 80px;
                color: #96bc33 !important;
                border: 1px solid;
                border-radius: 170px;
                width: 150px;
                padding: 32px;"></i>
            <!--end::Logo-->
            <!--begin::Text-->
            <div class="text-sm fw-bold fs-4 text-muted mt-7 d-none">
                <div class="text-center">Garantili Satış Sonrası Oto Hiz.A.Ş.</div>
                <div class="text-center">Cevizli Mahallesi Tugay Yolu Caddesi No:59 </div>
                <div class="text-center">Maltepe / İstanbul</div>
            </div>
            <!--end::Text-->
        </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="pb-12">
        <!--begin::Wrapper-->
        
        <div class="d-flex flex-column gap-7 gap-md-10">
            <div style="display: flex; justify-content: space-between;">
             
                    <!--begin::Message-->
                    <div class="fw-bolder fs-2">Sayın {{userForm.value.firstname}} {{userForm.value.lastname}}
                        <span class="fs-6">({{userForm.value?.email}})</span>,
                        <br />
                        <span class="text-muted fs-5">
                           {{orderDetail?.id}} numaralı randevu detayını görüntülemektesiniz. Bizi tercih ettiğiniz için teşekkür ederiz.
                        </span>
                        <p class="text-muted fw-bold fs-7">({{serviceLastDate}} tarihine kadar geçerlidir)</p>

                       
                    </div>
               
                   
        
             
            </div>
            <!--begin::Message-->
            <!--begin::Separator-->
            <div class="separator"></div>
            <!--begin::Separator-->
            <!--begin::Order details-->
            <div class="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bolder d-none">
                <div class="flex-root d-flex flex-column">
                    
                </div>
                <div class="flex-root d-flex flex-column">
                    
                </div>
                <div class="flex-root d-flex flex-column">
                    
                    <span class="text-muted " style="align-self: flex-end;">Tarih</span>
                    <span class="fs-5" style="align-self: flex-end;">{{todayDate}}</span>
                  
                </div>
            
            </div>
           

            <app-shared-proforma-invoice
            *ngIf="orderDetail?.invoice_id"
                        [invoice_id]="orderDetail?.invoice_id"
                       >

                       </app-shared-proforma-invoice> 

           
            
        </div>
        <!--end::Wrapper-->
    </div>
    <!--end::Body-->

</div>
<!-- end::Wrapper-->