import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-appointment-odeme-basarili-v2',
  templateUrl: './odeme-basarili-v2.component.html',
  styles: [
  ]
})
export class OdemeBasariliV2Component implements OnInit {

  @Input() userInfo: any;
  @Input() selectedPackageInfo:any;
  @Input() selectedPieces: any;
  @Input() priceInfo: any;

  public todayDate: any;
  public serviceLastDate: any;

  constructor() { }

  ngOnInit(): void {
    moment.locale('tr');
    this.serviceLastDate = moment().add(2, 'days').format('DD.MM.yyyy H:mm').toString();

   // this.serviceLastDate = moment(this.serviceLastDate).format('DD.MM.yyyy H:mm').toString();
    this.todayDate = moment().format('DD.MM.yyyy H:mm').toString();
  }

}
