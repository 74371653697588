import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';
import { EquivalentModels } from './equivalent_models.graphql';

export interface PartOriginalList {
  id: Number;
  name: String;
  part_code: String;
  vehicle_brand_id: Number;
  part_no_vat_price: Number;
  part_in_vat_price: Number;
  part_type_id: Number;
  price_unit: String;
  list_date?: Date;
  position_no?: Number;
  cost: Number;
  vehicle_brand?: any;
  part_equivalent_lists: any;
  price_unit_id?:bigint;
  part_units:any;
  vat_ratio:Number;
  vehicle_model_type_id: Number;
  profit_margen:Number;
  equivalent_models:EquivalentModels[]
}

interface ResponseArray {
  part_original_list: PartOriginalList[];
}

interface ResponseSingle {
  part_original_list_by_pk: PartOriginalList
}

interface ResponseSingleWithEquivalents {
  part_original_list: PartOriginalList[]
}

@Injectable()
export class PART_ORIGINAL_ALL extends Query<ResponseArray> {
  override document = gql`
    query PART_ORIGINAL_ALL($where: part_original_list_bool_exp){
      part_original_list(where:$where) {
        id
        name
        part_code
        vehicle_brand_id
        part_no_vat_price
        part_in_vat_price
        price_unit
        list_date
        position_no
        vehicle_model_type_id
      }
    }
  `;
}

@Injectable()
export class PART_ORIGINAL_ID extends Query<ResponseSingle> {
  override document = gql`
    query PART_ORIGINAL_ID($id: bigint!){
      part_original_list_by_pk(id: $id) {
        id
        name
        part_code
        vehicle_brand_id
        part_no_vat_price
        part_in_vat_price
        price_unit
        list_date
        position_no
        price_unit_id
        cost
        vat_ratio
        vehicle_model_type_id
        profit_margen
        part_equivalent_lists{
          id
          name
          part_code
          vat_ratio
          part_no_vat_price
          part_in_vat_price
          price_unit
          part_type_id
          price_unit_id
          part_types{
            name
          }
        }
       
      }
    }
  `;
}


@Injectable()
export class SEARCH_PART_ORIGINIALS_WITH_EQUIVALENTS extends Query<ResponseSingleWithEquivalents> {
  override document = gql`
    query SEARCH_PART_ORIGINIALS_WITH_EQUIVALENTS($where: part_original_list_bool_exp){
      part_original_list(where: $where) {
        id
        name
        part_code
        vehicle_brand_id
        part_no_vat_price
        part_in_vat_price
        price_unit
        list_date
        position_no
        price_unit_id
        vehicle_model_type_id
        part_equivalent_lists{
          id
          name
          part_code
          part_no_vat_price
          part_in_vat_price
          price_unit
          part_type_id
          price_unit_id
          part_types{
            name
          }
        }
      }
    }
  `;
}

@Injectable()
export class PART_ORIGINAL_CREATE extends Mutation<any> {
  override document = gql`
    mutation PART_ORIGINAL_CREATE(
        $name: String!, 
        $part_code: String!, 
        $vehicle_brand_id: Int!, 
        $part_no_vat_price: numeric!, 
        $part_in_vat_price: numeric!, 
        $price_unit: String!, 
        $list_date: timestamptz!, 
        $position_no: Int!,
        $price_unit_id:bigint,
        $vat_ratio: numeric,
        $vehicle_model_type_id: Int,
    ) {
        insert_part_original_list(objects: {
            name: $name, 
            part_code: $part_code, 
            vehicle_brand_id: $vehicle_brand_id, 
            part_no_vat_price: $part_no_vat_price, 
            part_in_vat_price: $part_in_vat_price, 
            price_unit: $price_unit, 
            list_date: $list_date, 
            position_no: $position_no,
            price_unit_id: $price_unit_id,
            vat_ratio: $vat_ratio,
            vehicle_model_type_id: $vehicle_model_type_id
        }) {
        affected_rows
        returning {
          id
          name
        }
      }
    }
    `;
}

@Injectable()
export class PART_ORIGINAL_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
    mutation PART_ORIGINAL_UPDATE(
      $id: bigint!, 
      $name: String!, 
      $part_code: String!, 
      $part_in_vat_price: numeric!, 
      $part_no_vat_price: numeric!, 
      $price_unit: String!, 
      $vehicle_brand_id: Int!, 
      $position_no: Int!, 
      $list_date: timestamptz!,
      $price_unit_id:bigint,
      $vat_ratio: numeric,
      $vehicle_model_type_id: Int,
      $profit_margen: numeric,
      $cost: numeric

      ) {
      update_part_original_list(where: {id: {_eq: $id}}, _set: {
        name: $name, 
        part_code: $part_code, 
        part_in_vat_price: $part_in_vat_price, 
        part_no_vat_price: $part_no_vat_price, 
        price_unit: $price_unit, 
        vehicle_brand_id: $vehicle_brand_id,
        position_no: $position_no, 
        list_date: $list_date,
        price_unit_id: $price_unit_id,
        vat_ratio: $vat_ratio,
        vehicle_model_type_id: $vehicle_model_type_id,
        profit_margen: $profit_margen,
        cost: $cost}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PART_ORIGINAL_DELETE extends Mutation<any> {
  override document = gql`
    mutation PART_ORIGINAL_DELETE($id: bigint!) {
      delete_part_original_list(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PART_ORIGINAL_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query PART_ORIGINAL_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: part_original_list_bool_exp){
      part_original_list(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        name
        part_code
        vehicle_brand_id
        part_no_vat_price
        part_in_vat_price
        price_unit
        list_date
        position_no
        vehicle_model_type_id
        vehicle_brand {
          name
        }
      }
    }
  `;
}

@Injectable()
export class PART_ORIGINAL_COUNT extends Query<any> {
  override document = gql`
    query PART_ORIGINAL_COUNT($where: part_original_list_bool_exp){
      part_original_list_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}