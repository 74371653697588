import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
declare const google:any;

@Component({
  selector: 'app-shared-google-maps',
  templateUrl: './google-maps.component.html',
  styles: [
  ]
})
export class GoogleMapsComponent implements OnInit, AfterViewInit {
  @Input() lat: any;
  @Input() lng: any;
  @Input() zoom: any;
  public center: any;
  public options: any;
  map:any;
  @ViewChild('mapElement') mapElement:any;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const myLatLng = { lat: this.lat, lng: this.lng };
    this.map = new google.maps.Map(this.mapElement.nativeElement, {
      center: myLatLng,
      zoom: this.zoom,
      mapId: "be1413f343b13ba5",
    });

    new google.maps.Marker({
      position: myLatLng,
      map: this.map,
      title: "Hello World!",
    });

    google.maps.event.trigger(this.map, 'resize');

  }

}
