import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  authType: string = "signin"

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((query:any) => {
      if (query.type == "signup" || query.type == "forgot" || query.type == "newPassword") {
        this.authType = query.type
      } else {
        this.authType = "signin";
      }
    })
  }

}


