import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { exit } from 'process';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "../../environments/environment";

export interface FireBaseEmailAuthReponse {
  idToken: string,
  email: string,
  refreshToken: string,
  expiresIn: string,
  localId: string,
  registered?: string,
  ipAddress?: string,
}

@Injectable()
export class PaymentService {

  //private baseUrl: string = environment.laravelApiEndpoint;
  private baseUrl: string = "https://api.garantiliotoservis.com/api/";
  //private baseUrl: string = "http://localhost:9090/api/";

  public paymentForm = {
    name: "",
    ccNumber: "",
    lMonth: "",
    lYear: "",
    cvv: "",
    orderCardInvoiceId: "",
    customerId: 0
  };

  constructor(
    private http: HttpClient
  ) { 
  }



  // token verify with laravel api
  pay(): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'order/pay',{...this.paymentForm});
  }

  getPaymentForm(): Observable<any>{
    return this.http.post<any>(this.baseUrl+'get-payment-form',{});
  }

  getHash(InstallmentCount:String,orderId:bigint | Number,purchAmount:Number,momentOrderId:String): Observable<any>{
    return this.http.post<any>(this.baseUrl + 'get-deniz-hash-api',{InstallmentCount:InstallmentCount,orderId:orderId,purchAmount:purchAmount,momentOrderId:momentOrderId});  
  }
  sendForm(formObj:any): Observable<any>{
   /* let headers = new HttpHeaders({
      'Content-Type': 'application/XMLHttpRequest',
      'Content-Language': 'tr',
      'Pragma': 'no-cache',
      'Expires': 'now'
      });*/
      let _headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    //  const headers = _headers.set('X-Requested-With', 'XMLHttpRequest');
 //    let options = { headers: headers };
    return this.http.post<any>('https://test.inter-vpos.com.tr/mpi/Default.aspx',formObj,{..._headers});  
  }

}
