 <div class="row">

    <form [formGroup]="userForm">
        <div class="card card-flush h-xl-100" id="userInfoContainer" style="border-bottom: 1px solid #f0f0f0; padding-bottom: 30px;">
            <div class="card-header pt-5">
                <div class="card-title align-items-start flex-column">
                    <div class="card-label fw-bolder text-gray-800">Kullanıcı Bilgileri</div>
                    <div class="text-gray-400 pt-1 fw-bold fs-6"></div>
                </div>
            </div>
            <div class="card-body py-3">
                <div class="col-md-12">
                    <div class="row fv-row mb-7">
                        <div class="col-xl-6">
                            <label class="form-label fw-bolder text-dark fs-6">Ad</label>
                            <input 
                                class="form-control form-control-sm form-control-solid" 
                                type="text"
                                name="name"
                                formControlName="firstname"
                                (change)="setUserForm('firstname',$event)"
                                autocomplete="off" placeholder="Ad"
                                [value]="userInfo?.name"
                                [disabled]="userInfo && userInfo.id > 1"
                                />
                        </div>
                        <div class="col-xl-6">
                            <label class="form-label fw-bolder text-dark fs-6">Soyad</label>
                            <input 
                                class="form-control form-control-sm form-control-solid" 
                                type="text" 
                                name="surname"
                                (change)="setUserForm('lastname',$event)"
                                formControlName="lastname"
                                autocomplete="off" placeholder="Soyad" 
                                [value]="userInfo?.surname"
                                [disabled]="userInfo && userInfo.id > 1" />
                        </div>
                    </div>
                    <div class="row fv-row mb-7">
                        <div class="col-xl-6">
                            <label class="form-label fw-bolder text-dark fs-6">Email</label>
                            <input 
                                class="form-control form-control-sm form-control-solid" 
                                (blur)="checkEmail($event)" 
                                formControlName="email"
                                type="email" 
                                placeholder=""
                                name="email"
                                autocomplete="off" 
                                placeholder="Email" 
                                [value]="userInfo?.email"
                                [disabled]="userInfo && userInfo.id > 1" />
                        </div>
                        <div class="col-xl-6">
                            <label class="form-label fw-bolder text-dark fs-6">Telefon</label>
                            <input 
                                class="form-control form-control-sm form-control-solid" 
                               
                                type="number" 
                                placeholder=""
                                (change)="setUserForm('gsm',$event)" 
                                name="Cep Telefonu" 
                                autocomplete="off" 
                                placeholder="Cep Telefonu" 
                                formControlName="phone"
                                 />
                             
                        </div>
                    </div>  
                    
                    <div class="row fv-row mb-3 fv-plugins-icon-container" *ngIf="showKvkk">
                        <div class="col-md-6">
                            <label class="form-check form-check-custom form-check-solid form-check-inline">
                                <input class="form-check-input" type="checkbox" name="toc" value="1" formControlName="check_gizlilik">
                                <span class="form-check-label fw-bold text-gray-700 fs-6">
                                <a target="_blank" [routerLink]="['/gizlilik-sozlesmesi']" class="ms-1 link-primary">Gizlik Politikası</a></span>
                            </label>
                            <div class="fv-plugins-message-container invalid-feedback"></div>
                        </div>
                        <div class="col-md-6">
                            <label class="form-check form-check-custom form-check-solid form-check-inline">
                                <input class="form-check-input" type="checkbox" name="toc" value="1" formControlName="check_riza">
                                <span class="form-check-label fw-bold text-gray-700 fs-6">
                                <a target="_blank" [routerLink]="['/acik-riza-metni']" routerLinkActive="router-link-active"  class="ms-1 link-primary">Açık Rıza Metni</a></span>
                            </label>
                            <div class="fv-plugins-message-container invalid-feedback"></div>
                        </div>
                        </div>
                
                    <div class="row fv-row mb-3 fv-plugins-icon-container"  *ngIf="showKvkk">
                        <div class="col-md-6">
                            <label class="form-check form-check-custom form-check-solid form-check-inline">
                                <input class="form-check-input" type="checkbox" name="toc" value="1"  formControlName="check_aydinlatma">
                                <span class="form-check-label fw-bold text-gray-700 fs-6">
                                <a target="_blank" [routerLink]="['/aydinlatma-metni']" class="ms-1 link-primary">Aydınlatma Metni</a></span>
                            </label>
                            <div class="fv-plugins-message-container invalid-feedback"></div>
                        </div>
                        <div class="col-md-6">
                            <label class="form-check form-check-custom form-check-solid form-check-inline">
                                <input class="form-check-input" type="checkbox" name="toc" value="1"  formControlName="check_sozlesme">
                                <span class="form-check-label fw-bold text-gray-700 fs-6">
                                <a target="_blank" [routerLink]="'/kullanici-sozlesmesi'" x class="ms-1 link-primary">Kullanıcı Sözleşmesi</a></span>
                            </label>
                            <div class="fv-plugins-message-container invalid-feedback"></div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    </form>
 </div>

 

 <div class="row">

    <div class="card card-flush h-xl-100"  style="border-bottom: 1px solid #f0f0f0; margin-bottom: 30px;">
        <div class="card-header pt-5">
            <div class="card-title align-items-start flex-column">
                <div class="card-label fw-bolder text-gray-800">Fatura Bilgileri</div>
                <div class="text-gray-400 pt-1 fw-bold fs-6"></div>
            </div>
            
        </div>
        <div class="card-body py-3">
            
            <div class="col-md-12">
              
        
                <div class="mx-5 mx-xl-auto">
        
        

                    <div class="fv-row mb-5">
                        <form action="" [formGroup]="invoiceAddressForm" id="invoiceInfoContainer">
                            <div class="fv-row mmb-5">
                                <div class="col-md-12">
                                   
                                
                                        <div class="row fv-row mb-7">
                                            <div class="col-md-6 mb-5">
                                                <label class="d-flex align-items-center fs-7 fw-bold form-label mb-2">Fatura Türü</label>
                                               <select name="" class="form-control form-control-sm" id="" (change)="setInvoiceInfo('customer_invoice_type',$event)">
                                                    <option value="1">Şahıs Faturası</option>
                                                    <option value="2">Şirket Faturası</option>
                                               </select>
                                            </div>
                                            <div class="col-md-6 mb-5" *ngIf="invoiceAddressForm.value.customer_invoice_type == 1">
                                                <label class="required fs-7 fw-bold form-label mb-2">Tc Kimlik No</label>
                                            
                                                <div class="position-relative">
                                                
                                                    <input type="text" 
                                                    (change)="setInvoiceInfo('identity_no',$event)"
                                                    class="form-control form-control-solid  form-control-sm" 
                                                    formControlName="identity_no" 
                                                    placeholder="Tc Kimlik No"
                                                    name="tc" />                                                
                                                
                                                </div>
                                            </div>

                                            <div class="col-md-6 mb-5" *ngIf="invoiceAddressForm.value.customer_invoice_type == 2">
                                                <label class="required fs-7 fw-bold form-label mb-2">Şirket Adı</label>
                                            
                                                <input 
                                                class="form-control form-control-sm form-control-solid" 
                                                type="text"
                                                name="name"
                                                (change)="setInvoiceInfo('company_name',$event)"
                                                formControlName="company_name"
                                                autocomplete="off" placeholder="Şiirket Adı"  />
                                            </div>

                                            <div class="col-md-6" *ngIf="invoiceAddressForm.value.customer_invoice_type == 2">
                                                <label class="required fs-7 fw-bold form-label mb-2">Vergi Dairesi</label>
                                            
                                                <input 
                                                class="form-control form-control-sm form-control-solid" 
                                                type="text"
                                                name="name"
                                                (change)="setInvoiceInfo('tax_administration',$event)"
                                                formControlName="tax_administration"
                                                autocomplete="off" placeholder="Şiirket Adı"  />
                                            </div>

                                            <div class="col-md-6" *ngIf="invoiceAddressForm.value.customer_invoice_type == 2">
                                                <label class="required fs-7 fw-bold form-label mb-2">Vergi Numarası</label>
                                            
                                                <input 
                                                class="form-control form-control-sm form-control-solid" 
                                                type="text"
                                                name="name"
                                                (change)="setInvoiceInfo('tax_number',$event)"
                                                formControlName="tax_number"
                                                autocomplete="off" placeholder="Şiirket Adı"  />
                                            </div>
                                            
                                        
                                            <div class="col-md-6" *ngIf="invoiceAddressForm.value.customer_invoice_type == 1">
                                                <label class="d-flex align-items-center fs-7 fw-bold form-label mb-2">Ad</label>
                                                <input 
                                                    class="form-control form-control-sm form-control-solid" 
                                                    type="text"
                                                    name="name"
                                                    (change)="setInvoiceInfo('invoice_name',$event)"
                                                    formControlName="firstname"
                                                    autocomplete="off" placeholder="Ad"  />
                                            </div>
                                            <div class="col-md-6" *ngIf="invoiceAddressForm.value.customer_invoice_type == 1">
                                                <label class="d-flex align-items-center fs-7 fw-bold form-label mb-2">Soyad</label>
                                                <input 
                                                    class="form-control form-control-sm form-control-solid" 
                                                    type="text" 
                                                    name="surname"
                                                    (change)="setInvoiceInfo('invoice_surname',$event)"
                                                    formControlName="lastname"
                                                    autocomplete="off" placeholder="Soyad"  />
                                            </div>
                                            
                                        </div>
                                        <div class="row fv-row mb-7">
                                            
                                            <div class="col-md-6">
                                                <app-shared-appointment-cities 
                                                    [event]="invoiceAddressForm.value.country_id" 
                                                    (city_id)="changeGeoZone('city',$event)"
                                                ></app-shared-appointment-cities>                         
                                            </div>
                                            <div class="col-md-6 mb-10">
                                                <app-shared-appointment-districts 
                                                    [event]="invoiceAddressForm.value.city_id" 
                                                    (district_id)="changeGeoZone('district',$event)"
                                                ></app-shared-appointment-districts>
                
                                            </div>
                                            <div class="col-md-6">
                                                <app-shared-appointment-towns 
                                                    [event]="invoiceAddressForm.value.district_id" 
                                                    (town_id)="changeGeoZone('town',$event)"
                                                ></app-shared-appointment-towns>
                                            </div>

                                            <div class="col-md-6 mb-10">
                                                <label class="d-flex align-items-center fs-7 fw-bold form-label mb-2">Adres</label>
                                                <input name="adres" type="text" formControlName="address"  class="form-control form-control-solid form-control-sm" placeholder="Adres"/>
                                            </div>
                                            
                                        </div>

                                     

                                        <div class="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6 mb-10" *ngIf="selectedAddressInfo != undefined && !isCompany && 1 != 1">
                                            <!--begin::Details-->
                                            <div class="d-flex flex-column py-2">
                                                <div class="d-flex align-items-center fs-5 fw-bolder mb-5">{{selectedAddressInfo.address_name}}</div>
                                                <div class="fs-6 fw-bold text-gray-600">{{selectedAddressInfo.address}}
                                                <br />{{selectedAddressInfo.district.name}}
                                                <br />{{selectedAddressInfo.city.name}}</div>
                                            </div>
                                            <!--end::Details-->
                                            <!--begin::Actions-->
                                            <div class="d-flex align-items-center py-2">
                                                <button (click)="openDrawer('addressEdit',selectedAddressInfo.id)" class="btn btn-sm btn-light btn-active-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_address">Düzenle</button>
                                            </div>
                                            <!--end::Actions-->
                                        </div>
    
                
                                </div>
                            
                            </div>
                         </form>
        
                       
                    </div>


                    
                </div> 
            </div>
        </div>
    </div>
 </div>

 <div class="row">
   
    <div class="card card-flush h-xl-100"  style="border-bottom: 1px solid #f0f0f0; margin-bottom: 30px;">
        <div class="card-header pt-5">
            <div class="card-title align-items-start flex-column">
                <div class="card-label fw-bolder text-gray-800">Ödeme Bilgileri</div>
                <div class="text-gray-400 pt-1 fw-bold fs-6"></div>
            </div>
            
        </div>
        <div class="card-body py-3">

            <div class="mx-5 mx-xl-auto">
        
        

                <div class="fv-row mb-5">

                    <div class="col-md-12">
                        <div class="alert alert-success">
                            <i class="fas fa-exclamation-circle ms-2 fs-7" style="color: #205237;"></i>
                           {{priceInfo.totalAmount}} Tl - sabit fiyatlandırma için kredi kartı ile ödeme yapın
                        </div>
                    </div>
        
                    <div class="col-md-12">
                        <div class="fv-row mb-5">
                           
                            <!--begin::Row-->
                            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-1 row-cols-xl-2 g-9" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button='true']">
                                <!--begin::Col-->
                                <div class="col-md-6" *ngFor="let item of paymentTypes">
                                    <!--begin::Option-->
                                    <label class="btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-3 " [ngClass]="{active: selectedPaymentTypes == item.id}" >
                                        <!--begin::Radio-->
                                        <span class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                            <input class="form-check-input" (click)="changePaymentType(item.id)" [checked]="item.id == selectedPaymentTypes" type="radio" name="discount_option" value="{{item.id}}">
                                        </span>
                                        <!--end::Radio-->
                                        <!--begin::Info-->
                                        <span class="ms-5">
                                            <span class="fs-7 fw-bolder text-gray-800 d-block">{{item.name}}</span>
                                        </span>
                                        <!--end::Info-->
                                    </label>
                                    <!--end::Option-->
                                </div>
                                <!--end::Col-->
                               
                            </div>
                            <!--end::Row-->
                        </div>
        
                        <div class="fv-row mb-5">
                            <div class="alert alert-danger" *ngIf="this.subOrderCards?.pos_process_status_id == 3 && showPosMessage">
                                {{subOrderCards?.pos_message}}
                            </div>
                            
                            <form  #paymentForm method="post" action="{{denizbankUrl}}" target="_blank" *ngIf="selectedPaymentTypes == 1" >
        
        
                               
                                    
                               
                                <label class="fs-7 fw-bold mb-2 py-3">Kart Bilgileri
                                    <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Select a discount type that will be applied to this product" aria-label="Select a discount type that will be applied to this product"></i>
                                </label>
                            
                                <div class="row fv-row mb-7">
                                    <div class="col-md-6">
                                        <label class="d-flex align-items-center fs-7 fw-bold form-label mb-2">
                                            <span class="required">Ad Soyad</span>
                                        </label>
                                
                                        <input type="text" class="form-control form-control-solid form-control-sm" placeholder="" name="CardHolderName" />
                                    </div>
                                    <div class="col-md-6">
                                        <label class="required fs-7 fw-bold form-label mb-2">Kart Türü</label>
                                        <!--end::Label-->
                                        <!--begin::Input wrapper-->
                                        <div class="position-relative">
                                            <!--begin::Input-->
                                            <select name="CardType" class="form-control  form-control-sm">
                                                <option value="0">Visa</option>
                                                <option selected value="1">MasterCard</option>
                                            </select>                                        <!--end::Input-->
                                            <!--begin::Card logos-->
                                        
                                            <!--end::Card logos-->
                                        </div>
                                    </div>
                                </div>
        
        
                
                                <div class="row fv-row mb-7">
                                    <div class="col-md-6">
                                        <label class="required fs-7 fw-bold form-label mb-2">Kart Numarası </label>
                                    
                                        <div class="position-relative">
                                        
                                            <input type="text" class="form-control form-control-solid  form-control-sm"
                                                 value="{{filteredCardNo}}" 
                                                 placeholder="Kart Numarası" 
                                                 minlength="19"
                                                 maxlength="19"
                                                 name="Pan1" 
                                                 (input)="filterFormatCardNo($event)"   />
                                            <input type="hidden" class="form-control form-control-solid  form-control-sm" placeholder="Kart Numarası" name="Pan" value="{{inputCardNo}}"   />
                                            <div class="position-absolute translate-middle-y top-50 end-0 me-5">
                                                <img src="assets/media/svg/card-logos/visa.svg" alt="" class="h-25px" />
                                                <img src="assets/media/svg/card-logos/mastercard.svg" alt="" class="h-25px" />
                                                <img src="assets/media/svg/card-logos/american-express.svg" alt="" class="h-25px" />
                                            </div>
                                        
                                        </div>
                                    </div>
                                    <div class="row col-md-6">
                                        <div class="col-6">
                                            <label class="required fs-7 fw-bold form-label mb-2">SKT (MMYY)</label>
                                        <div class="row fv-row">
                                            <div class="col-md-12">
                                                <input class="form-control  form-control-sm" minlength="5" maxlength="5" value="{{filteredExpiryDate}}" (input)="filterFormatExpirationDate($event)" type="text" name="Expiry1">
                                                <input class="form-control  form-control-sm" value="{{inputExpiryDate}}" type="hidden" name="Expiry">
                                            </div>
                                        </div>
                                        </div>
                                        <div class="col-6">
                                            <label class="d-flex align-items-center fs-7 fw-bold form-label mb-2">
                                                <span class="required">CVV</span>
                                                <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Enter a card CVV code"></i>
                                            </label>
                                            <div class="position-relative">
                                                <input type="text" class="form-control form-control-solid  form-control-sm"  minlength="3"  maxlength="4" placeholder="CVV" name="Cvv2" />
                                                <div class="position-absolute translate-middle-y top-50 end-0 me-3">
                                                    <span class="svg-icon svg-icon-2hx">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M22 7H2V11H22V7Z" fill="black" />
                                                            <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="row fv-row mb-7">
                                    <div class="col-md-6"></div>
                                    <div class="col-md-6"></div>
                                </div>
        
                                <div class="d-flex flex-stack">
                                    <div class="me-5 d-none">
                                        <label class="fs-6 fw-bold form-label">Save Card for further billing?</label>
                                        <div class="fs-7 fw-bold text-muted">If you need more info, please check budget planning</div>
                                    </div>
                                    <label class="d-none form-check form-switch form-check-custom form-check-solid">
                                        <input class="form-check-input" type="checkbox" value="1" checked="checked" />
                                        <span class="form-check-label fw-bold text-muted">Save Card</span>
                                    </label>
                                </div>
        
                               
                                <div class="text-center">
                                    <button 
                                            type="submit" 
                                            id="kt_modal_new_card_submit" 
                                            class="btn btn-primary btn-sm" 
                                            (click)="paymentProcess()"
                                            [disabled]="hidePaymentButton"
                                            >
                                            <span
                                            class="indicator-label"
                                            [ngClass]="{'d-none': hidePaymentButton}"
                                            >Ödeme Yap</span>
                                          
                                            <span [ngClass]="{'d-none': !hidePaymentButton}" class="translate-middle-y lh-0 me-1">
                                                <span class="spinner-border h-15px w-15px align-middle"></span>
                                                Lütfen Bekleyiniz!
                                            </span>
                                        </button>
                                </div>
                                <input type="hidden" name="ShopCode" value="{{shopCodeLive}}" />
                                <input type="hidden" name="PurchAmount" value="1.15" />
                                <input type="hidden" name="Currency" value="949" />
                                <input type="hidden" name="OrderId" value="{{momentOrderId}}" />
                                <input type="hidden" name="OkUrl" value="https://api.garantiliotoservis.com/payment-response" />
                                <input type="hidden" name="FailUrl" value="https://api.garantiliotoservis.com/payment-response" />
                                <input type="hidden" name="Rnd" value="{{rnd}}" />
                                <input type="hidden" name="Hash" value="{{hash}}" />
                                <input type="hidden" name="TxnType" value="Auth" />
                                <input type="hidden" name="InstallmentCount" value="" />
                                <input type="hidden" name="SecureType" value="3DModel" />
                                <input type="hidden" name="Lang" value="tr" />
                                <input type='hidden' name="Version3D" value="2.0" />
                            </form>
                        </div>
                      
                        <div *ngIf="selectedPaymentTypes == 2" class="text-center">
                            
                            <button [disabled]="hideAppointmentButton" type="submit" class="btn btn-sm btn-primary" (click)="formEmit()">
                                <span class="" data-kt-inbox-form="send">
                                    <span [ngClass]="{'d-none': hideAppointmentButton}" class="indicator-label">Randevu Oluştur</span>
                                    <span [ngClass]="{'d-block': hideAppointmentButton}" class="indicator-progress">Lütfen Bekleyiniz...
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </span>
        
                            </button>
                        </div>
                    </div>

                </div>


            </div>

            
        </div>
    </div>
 </div>

 
 <!-- begin::Wrapper-->
 <div class="mw-lg-950px mx-auto w-100 d-none" style="padding: 25px; border: 1px solid #f0f0f0;" id="pdfTable" #pdfTable>
    <!-- begin::Header-->
    <div class="d-flex justify-content-center flex-column flex-sm-row mb-19">
        <!--end::Logo-->
        <div class="text-sm">
            <!--begin::Logo-->
            <a href="#" class="d-block mw-150px mx-auto">
                <img alt="Logo" src="assets/images/logo-garantiliotoservis.svg" class="w-100" />
            </a>
            <!--end::Logo-->
            <!--begin::Text-->
            <div class="text-sm fw-bold fs-4 text-muted mt-7">
                <div class="text-center">Garantili Satış Sonrası Oto Hiz.A.Ş.</div>
                <div class="text-center">Cevizli Mahallesi Tugay Yolu Caddesi No:59 </div>
                <div class="text-center">Maltepe / İstanbul</div>
            </div>
            <!--end::Text-->
        </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="pb-12">
        <!--begin::Wrapper-->
        
        <div class="d-flex flex-column gap-7 gap-md-10">
            <div style="display: flex; justify-content: space-between;">
             
                    <!--begin::Message-->
                    <div class="fw-bolder fs-2">Sayın {{userInfo?.name}} {{userInfo?.surname}}
                        <span class="fs-6">({{userInfo?.email}})</span>,
                        <br />
                        <span class="text-muted fs-5">
                            Randevu detayını görüntülemektesiniz. Bizi tercih ettiğiniz için teşekkür ederiz.
                        </span>
                        <p class="text-muted fw-bold fs-7">({{serviceLastDate}} tarihine kadar geçerlidir)</p>
                    </div>
               
                    <app-shared-proforma-pdf-generator
                        [userInfo]="userInfo"
                        [serviceLastDate]="serviceLastDate"
                        [todayDate]="todayDate"
                        [selectedPackageInfo]="selectedPackageInfo"
                        [selectedUncompaignProducts]="selectedPieces"
                        [subTotal]="priceInfo.subTotal"
                        [vat]="priceInfo.vat"
                        [discount]="priceInfo.discount"
                        [totalAmount]="priceInfo.totalAmount"
                    >
                    </app-shared-proforma-pdf-generator>
        
             
            </div>
            <!--begin::Message-->
            <!--begin::Separator-->
            <div class="separator"></div>
            <!--begin::Separator-->
            <!--begin::Order details-->
            <div class="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bolder">
                <div class="flex-root d-flex flex-column">
                    
                </div>
                <div class="flex-root d-flex flex-column">
                    
                </div>
                <div class="flex-root d-flex flex-column">
                    
                    <span class="text-muted " style="align-self: flex-end;">Tarih</span>
                    <span class="fs-5" style="align-self: flex-end;">{{todayDate}}</span>
                </div>
            
            </div>
            <div class="d-flex justify-content-between flex-column">
                <h3 class="fw-bolder fs-2">{{selectedPackageInfo?.name}}</h3>
                <!--begin::Table-->
                <div class="table-responsive border-bottom mb-9">
                    <table class="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                        <thead>
                            <tr class="border-bottom fs-6 fw-bolder text-muted">
                                <th class="min-w-175px pb-2">Yedek Parça Adı</th>
                                <th class="min-w-175px pb-2">Parça Türü</th>
                                <th class="min-w-80px text-end pb-2">Adet</th>
                                <th class="min-w-80px text-end pb-2">Birim Fiyat1</th>
                                <th class="min-w-100px text-end pb-2">Tutar</th>
                            </tr>
                        </thead>
                        <tbody class="fw-bold text-gray-600">
                            <!--begin::Products-->
                            <tr *ngFor="let item of selectedPieces" >
                                <!--begin::Product-->
                                <td>
                                    <div class="d-flex align-items-center">
                                        <!--begin::Thumbnail-->
                                        
                                    
                                        <span class="symbol symbol-50px me-6">
                                            <span class="symbol-label">
                                                <i class="fa-car fas"></i>
                                            </span>
                                        </span>
                                        <!--end::Thumbnail-->
                                        <!--begin::Title-->
                                        <div class="ms-5">
                                            <div class="fw-bolder">{{item.selected_equivalent_name}}</div>
                                            <div style="display: none;" class="fs-7 text-muted">{{item.part_code}}</div>
                                        </div>
                                        <!--end::Title-->
                                    </div>
                                </td>
                                <!--end::Product-->

                                <td>{{item.selected_equivalent_part_type_name}} </td>
                                
                                <!--begin::Quantity-->
                                <td class="text-end">{{item.part_unit}} {{ item.part_unit_name }}</td>

                                <td class="text-end">{{item.part_value | number:'1.0-2'}} &#8378;</td>
                                <!--end::Quantity-->
                                <!--begin::Total-->
                                <td class="text-end">{{item.total_price | number:'1.0-2'}} &#8378;</td>
                                
                                <!--end::Total-->
                            </tr>
                            <!--end::Products-->
<!--
                            <tr *ngFor="let item of selectedCompaignProducts" >
                              
                                <td style="background-color: #96bc33 !important;
                                color:#015029;
                                padding-left: 10px;
                                border-top-left-radius: 10px;
                                border-bottom-left-radius: 10px;
                                margin-top: 10px;">
                                    <div class="d-flex align-items-center">
                                       
                                        
                                    
                                        <span class="symbol symbol-50px me-6">
                                            <span class="symbol-label">
                                                <i class="fa-car fas"></i>
                                            </span>
                                        </span>
                                        
                                        <div class="ms-5">
                                            <div class="fw-bolder">{{item.name}}</div>
                                            <div style="display: none;" class="fs-7 text-muted">{{item.part_code}}</div>
                                        </div>
                                      
                                    </div>
                                </td>
                               

                                <td style="background-color: #96bc33 !important;
                                color:#015029;">{{item.partTypeName}}</td>
                                
                               
                                <td class="text-end" style="background-color: #96bc33 !important;
                                color:#015029;">{{item.part_unit}}</td>

                                <td class="text-end" style="background-color: #96bc33 !important;
                                color:#015029;">{{item.price | number:'1.0-2'}} &#8378;</td>
                               
                                <td class="text-end" style="background-color: #96bc33 !important;
                                color:#015029;
                                padding-right: 10px;
                                border-top-right-radius: 10px;
                                border-bottom-right-radius: 10px;
                                margin-top: 10px;">{{item.price_with_unit | number:'1.0-2'}} &#8378;</td>
                                
                              
                            </tr>
                        -->
                            <!--begin::Subtotal-->
                            <tr>
                                <td colspan="4" class="text-end">Toplam</td>
                                <td class="text-end">{{priceInfo.subTotal | number:'1.0-2'}} &#8378;</td>
                            </tr>
                            <!--end::Subtotal-->
                         
                            <!--begin::VAT-->
                            <tr>
                                <td colspan="4" class="text-end">Kdv (18%)</td>
                                <td class="text-end">{{ priceInfo.vat | number:'1.0-2'}} &#8378;</td>
                            </tr>
                            <!--end::VAT-->
                            <!--begin::Shipping-->
                            <tr>
                                <td colspan="4" class="text-end">İndirim</td>
                                <td class="text-end">{{(priceInfo.discount | number:'1.0-2')}} &#8378;</td>
                            </tr>
                            <!--end::Shipping-->
                            <!--begin::Grand total-->
                            <tr>
                                <td colspan="4" class="fs-3 text-dark fw-bolder text-end">Genel Toplam</td>
                                <td class="text-dark fs-3 fw-boldest text-end">{{priceInfo.totalAmount | number:'1.0-2'}} &#8378;</td>
                            </tr>
                            <!--end::Grand total-->
                        </tbody>
                    </table>
                </div>
                <!--end::Table-->
            </div>
            <!--end:Order summary-->

            
        </div>
        <!--end::Wrapper-->
    </div>
    <!--end::Body-->

</div>
<!-- end::Wrapper-->



<app-shared-drawer [show]="showDrawer" [title]="drawerTitle" (toggleDrawer)="toggleDrawer()">
    <app-offer-address-add
        [countryInputId]="'addressAddCountry'"
        [cityInputId]="'addressAddCity'"
        [districtInputId]="'addressAddDistrict'"
        [townInputId]="'addressAddTown'"
    (reloadList)="reloadList($event)" *ngIf="drawerPage === 'addressAdd'"></app-offer-address-add>
    <app-offer-address-edit [addressId]="selectedAddressId" (reloadList)="reloadList($event)" *ngIf="drawerPage === 'addressEdit'"></app-offer-address-edit>
    <app-offer-gsm-add (gsmResult)="reloadList($event)" *ngIf="drawerPage === 'gsmAdd'"></app-offer-gsm-add>
</app-shared-drawer>