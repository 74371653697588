import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-shared-tagify',
  templateUrl: './tagify.component.html',
  styleUrls: ['./tagify.component.css']
})
export class TagifyComponent implements OnInit {

  @Input() tagInputId: string = 'tag_input_id';
  @Output() tagEmitter = new EventEmitter<string[]>();
  @Input() tags: string[] = [];
  @Input() placeholder: string = '';
  inputValue: string = '';

  constructor() {}

  @ViewChild('tagifyInput') 
  tagifyInput!: ElementRef; 

  ngOnInit(): void {}

  addTag(event:any): void {
    const trimmedInput = event.target.value.trim();
    if (trimmedInput !== '' && !this.tags.includes(trimmedInput)) {
      this.tags.push(trimmedInput);
   
      this.tagifyInput.nativeElement.value = ''; 
   

      this.tagEmitter.emit(this.tags);  // etiketleri dışarıya iletiyoruz
    }
   
  }

  removeTag(tag: string): void {
    this.tags = this.tags.filter(t => t !== tag);
    this.tagEmitter.emit(this.tags);  // güncellenen etiketleri dışarıya iletiyoruz
  }
}
