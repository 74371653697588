import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Country, GET_COUNTRIES } from 'src/app/graphql/countries.graphql';

@Component({
  selector: 'app-shared-appointment-countries',
  templateUrl: './countries.component.html',
  styles: [
  ],
  providers:[GET_COUNTRIES]
})
export class AppointmentCountriesComponent implements OnInit {
  @Input() selectedCountry!: any;
  @Output() country_id = new EventEmitter<number | null>();
  @Input() selectedCountryId!: any;

  public resetForm: boolean = false;
  countryList: Country[] | undefined;

  constructor(
    private getCountries: GET_COUNTRIES
  ) { 
    this.getCountries.watch().valueChanges.subscribe(response => {
      this.countryList = response.data.countries;
    })
  }

  ngOnInit(): void {
    
   
    
  }

  ngOnChanges(){
    this.selectedCountry = this.selectedCountry;
  }

   // Shared Select Event
   selected(event: any) {
    
       this.country_id.emit(event.target.value);
      
  
  }

  

}
