import { Injectable } from '@angular/core';
import { gql, Mutation, Query, Subscription } from 'apollo-angular';
import { OrderBids } from './order_bids.graphql';
import { ServiceInvoices } from './service_invoices.graphql';

export interface Order {
  id: Number;
  campaign_id: Number;
  combined_order_id: Number;
  fleet_id: Number;
  main_order_id: Number;
  personel_id: Number;
  service_id: bigint;
  service_status_id: Number;
  service_type_id: Number;
  source_id: Number;
  status_id: Number;
  customer_status_id: Number;
  steering_id: Number;
  type_id: Number;
  user_id: bigint;
  user_address_id: Number;
  service_date:Date;
  part_type_id: bigint;
  user:any;
  order_value: number;
  order_no_vat_value: number;
  service_value: number;
  maintenance_package_id: bigint;
  invoice_id:bigint;
  service_invoice:any;
  maintenance_packages:any;
  order_status:any;
  order_types:any;
  order_invoice_info:any;
  order_card: any;
  vehicle:{
    id:bigint,
    plate:String,
    vehicle_model_types:any,
    model_type_id:BigInt,
    vehicle_model:any,
    vehicle_brand:any,
    chassis_no:any,
    license_name: String,
    license_surname: String,
    years: any
    vehicle_fuel_type:any,
    vehicle_gear_type:any
    }
  vehicle_id: bigint;
  created_at:Date;
  customer_id?:number;
  customer_address_id: bigint;
  customer_gsm_id:bigint;
  customer_tel_id:bigint;
  user_addresses: any;
  order_parts:any;
  user_address:any;
  order_packages:any;
  equivalent_list:any;
  companyByServiceId:any;
  customer_gsm:any;
  customer_tel:any;
  customer_info:any;
  customer_order_status:any;
  order_invoices:any;
  service_invoice_id:any;
  show_popup: boolean;
  payment_type:number;
  is_payed: Boolean;
  payment_types: any;
  invoice_firstname: String;
  invoice_lastname: String;
  invoice_identity_number: bigint;
  track_id: any;
  payment_attemts: Number;
  last_payment_message: String;
  selected_package_type: any;
  vat: Number,
  vat_price: Number,
  labour_price: Number,
  labour_hour:Number,
  spare_part_price: Number,
  customer_ip_address:String,
  customer_user_agent:String,
  installment_count: Number, //taksit sayısı
  installment_amount: Number, // taksit harici tutar,
  labour_discount_percentage: Number,
  labour_discount_price: Number,
  part_discount_percentage: Number,
  part_discount_price: Number,
  order_bids: OrderBids ,
  payment_value: number,
  cargo_cost: number,
  url_utm_log_id: bigint
}


export interface OrderShow{
  id: Number,
  order_value: number,
  service_date: Date,
  maintenance_packages:any,
  companyByServiceId: any,
  vehicle:any,
  user_address: any,
  order_invoice_info:any;
  order_parts: any,
  customer_gsm: any,
  customer_tel: any,
  customer_gsm_id: any,
  order_types: any,
  customer_info: any,
  order_status:any,
  status_id: Number,
  customer_status_id: Number;
  invoice_id:bigint;
  order_invoices:any;
  service_invoice_id:any;
  show_popup: boolean;
  payment_type: number;
  is_payed: Boolean;
  payment_types: any;
  payment_attemts: Number;
  last_payment_message: String;   
  selected_package_type: any;
  payment_request_status: Number;
  installment_count:Number,
  installment_amount: Number,
  labour_discount_percentage: Number,
  labour_discount_price: Number,
  part_discount_percentage: Number,
  part_discount_price: Number,
  cargo_cost: number
}

export interface UpdateMutation {
  update_orders: {
    affected_rows: number
    returning: [{
      id: Number,
      order_status:any
      
    }]
  }
}

interface ResponseArray {
  orders: Order[];
}


interface ResponseSingle {
  orders_by_pk: Order
}

interface ResponseCustom{
  orders: OrderShow[];
}

export interface insertMutation{
  insert_orders: {
    affected_rows: number
    returning: [{
      id: bigint
    }]
  }
}


export interface OrderAggregate {
  orders_aggregate: {
    aggregate: {
      count: number
    }
  }
}

export interface CustomerOrder {
  id: Number
  maintenance_package_id: bigint;
  maintenance_packages:any;
  customer_status_id: Number;
  order_parts: [
    {
      id: Number
      image: String
      note: String
      part: {
        id: Number
        name: String
      }
    }
  ]
  order_type: {
    id: Number
    name: String
  }
  part_status: {
    name: String
  }
  order_status: {
    name: String
  }
  vehicle: {
    garages: {
      name: String
    }
  }
  user:{
    name:string,
    surname:string,
    email: string
  },
  customer_info:any,
  customer_address_id: bigint;
   customer_gsm_id:bigint;
   customer_tel_id:bigint;
   selected_part_id:bigint;
   order_packages:any;
}

interface ResponseCustomerOrderArray {
  orders: CustomerOrder[];
}


@Injectable()
export class ORDER_ID extends Query<ResponseSingle> {
  override document = gql`
    query ORDER_ID($id: bigint!){
      orders_by_pk(id: $id) {
        id
        user_id
        status_id
        customer_status_id
        customer_id
        invoice_firstname
        invoice_lastname
        track_id
        invoice_identity_number
        invoice_id
        vat
        vat_price
        labour_hour
        labour_price
        payment_request_status
        payment_value
        spare_part_price
        installment_count
        installment_amount
        labour_discount_percentage
        labour_discount_price
        part_discount_percentage
        part_discount_price
        cargo_cost
        order_bids{
          id
        }
        user {
          id
          name
          surname
          email,
          identity_no
          gsms{
            id
            code
          }
          company{
            name
            vat
            vat_office
            city_id
            district_id
            town_id
            address
            country_id
          }
          gsm{
            id
            code
          }
          tel{
            id
            code
          }
        }
        customer_id
        invoice_id
        service_invoice_id
        created_at
        updated_at
        type_id
        maintenance_package_id
        payment_type,
        payment_types{
          id
          name
          main_id
        }
        is_payed,
        maintenance_packages{
          name
        }
        order_invoices{
          order_id
          date
          value_no_vat
          value_in_vat
       
          discount
          invoice_type_id
          invoice_status_id
          paid_value
          vat
          vat_price
          labour_hour
          labour_price
          labour_discount
          labour_discount_price
          
          invoice_parts{
            id
            invoice_id
            part_id
            part_name
            part_code
            labour_type_id
            labour_value
            labour_discount
            part_in_vat_price
            part_no_vat_price
            invoice_type
            part_unit_price
            selected_part_id
            parts{
              part_group{
                id
                name
                position_no
              }
              id
              name
              position_no
            }
            part_unit
            part_unit_type_id
            part_unit_types{
              id
              name
            }
            part_type_id
            gos_part_id
            part_types{
              id
              name
            }
          }
        }
        order_invoice_info{
          id
          order_id
          customer_name
          customer_surname
          identity_no
          tax_number
          tax_administration
          user_id
          company_id
          gsm_number
          customer_email
          is_same_with_customer_info
          invoice_type
          created_at
          tel_number
          address
          city_id
          district_id
          country_id
          town_id
          company_name
          customer_invoice_type
          is_rated
          city{
            name
          }
          country{
            name
          }
          town{
            name
          }
          district{
            name
          }
        }
        order_value
        order_no_vat_value
        service_value
        vehicle_id
        service_id
        service_date
        customer_address_id
        customer_gsm_id
        customer_tel_id
       
        order_types{
          id
          name
        }
        order_status{
          id
          name
          class_name
        }
        order_packages{
          id
          package_id
          package_detail_id
          order_id
          part_id
          part_type_id
          part_value
          original_part_id
          parts{
            name
          }
        }
        companyByServiceId {
          name
          title
          country_id
          city_id
          district_id
          town_id
          address
          vat
          vat_office
          country{
            name
          }
          City{
            name
          }
          district{
            name
          }
          Town{
            name
          }
          company_tel{
            id
            code
          }
          company_gsm{
            id
            code
          }
          company_email{
            name
            email
          }

        }

        service_invoice{
          id
          order_id
          value_no_vat
          value_in_vat
          vat
          discount
          url
          invoice_type_id
          valor_date
          invoice_status_id
          refeasual_date
          refasual_value
          refasual_reason_id
          paid_value
          remaining_value
          returned_invoice_id
          note
          invoice_reciever_id
          invoice_reciever_type_id
          vat_ratio
          service_invoice_parts{
            id
            invoice_id
            part_id
            part_name
            part_code
            labour_type_id
            labour_value
            labour_discount
            part_in_vat_price
            part_no_vat_price
            invoice_type
            part_unit_type_id
            part_unit
            part_unit_types{
              id
              name
            }
            part_types{
              id
              name
            }
            parts{
              id
              name
            }
          }
        }
        selected_package_type{
          id 
          name
        }

        order_parts(order_by:{parts:{part_group:{position_no:desc}}}){
          id
          part_id
          part_type_id
          part_unit_type_id
          part_code
          part_unit
          part_value
          part_no_vat_price
          part_discount
          labour_type_id
          labour_value
          labour_discount
          selected_part_id
          service_value
          is_service
          brand_labour_price
          gos_labour_price
          total_price
          total_price_no_vat
          service_value_unit
          service_price_lock
          connected_part_type_id
          connected_part_id
          part_original_lists{
            id
            part_code
            name
            part_equivalent_lists{
              id
              name
              part_code
              part_brand{
                id
                name
              }
              part_no_vat_price
              part_in_vat_price
            }
          }
          
          part_equivalent_lists{
            part_code
            name
            part_original_list{
              id
              name
              part_code
              part_equivalent_lists{
                id
                name
                part_code
                part_brand{
                  id
                  name
                }
                part_no_vat_price
                part_in_vat_price
              }
            }
          }
          part_unit_types{
            id
            name
          }
          parts{
            id
            name
            part_group{
              id
              name
            }
          }
          part_types{
            name
          }
        }
        order_card{
          id
          order_card_invoice_info{
            order_card_id
            customer_name
            customer_surname
            identity_no
            tax_administration
            user_id
            company_id
            gsm_number
            customer_email
            invoice_type
            tel_number
            address
            city_id
            district_id
            country_id
            town_id
            company_name
            customer_invoice_type
            tax_number
          }
        }
        vehicle{
          id
          plate
          license_name
          license_surname
          chassis_no
          vehicle_model_types{
            id 
            name
          }
          vehicle_model{
            id 
            name
          }
          vehicle_brand{
            id 
            name
          }
          years{
            id
            year_id
          }
          vehicle_fuel_type{
            id
            name
          }
          vehicle_gear_type{
            id
            name
          }
          model_type_id
        }

        user_address{
          id
          address
          city_id
          country_id
          district_id
          town_id
          city{
            name
          }
          district{
            name
          }
          town{
            name
          }
        }
        customer_gsm {
          id
          name
          code
        }
        customer_tel {
          id
          name
          code
        }
        customer_order_status{
          name
        }
       
        customer_info {
          id
          name
          surname
          email
          role_id
          identity_no
          role {
            subtype
          }
          active
          gsm_id
          fax_id
          tel_id
          address_id
          company_id
          garages {
            id
            name
            vehicles {
              id
              vehicle_brand {
                name
              }
              vehicle_model {
                name
              }
              vehicle_model_types {
                name
              }
            }
          }
          address
          user_addresses {
            id
            address
            city_id
            country_id
            district_id
            town_id
            city {
              name
            }
            district {
              name
            }
            country {
              id
              name
            }
            town {
              id
              name
            }
          }
          gsms{
            id
            code
          }
          tels{
            id 
            code
          }
        }
        
      }
      
    }
  `;
}

@Injectable()
export class ORDER_TRACK_ID extends Query<ResponseArray> {
  override document = gql`
    query ORDER_TRACK_ID($track_id: uuid!){
      orders(where:{track_id:{_eq:$track_id}} ) {
        id
        user_id
        status_id
        customer_status_id
        customer_id
        invoice_firstname
        invoice_lastname
        invoice_identity_number
        installment_count
        installment_amount
        labour_discount_percentage
        labour_discount_price
        part_discount_percentage
        part_discount_price
        payment_request_status
        payment_value
        url_utm_log_id
        selected_package_type{
          id 
          name
        }
        track_id
        payment_attemts
        last_payment_message
        user {
          name
          surname
          email,
          identity_no
          company{
            name
            vat
            vat_office
            city_id
            district_id
            town_id
            address
            country_id
          }
          gsm{
            id
            code
          }
          tel{
            id
            code
          }
        }
        customer_id
        invoice_id
        service_invoice_id
        created_at
        updated_at
        type_id
        maintenance_package_id
        payment_type,
        payment_types{
          id,
          name,
          main_id
        }
        is_payed,
        maintenance_packages{
          name
        }
        order_invoice_info{
          id
          order_id
          customer_name
          customer_surname
          identity_no
          tax_number
          tax_administration
          user_id
          company_id
          gsm_number
          customer_email
          is_same_with_customer_info
          invoice_type
          created_at
          tel_number
          address
          city_id
          district_id
          country_id
          town_id
          company_name
          customer_invoice_type
          is_rated
          city{
            name
          }
          country{
            name
          }
          town{
            name
          }
          district{
            name
          }
        }
        order_value
        order_no_vat_value
        service_value
        vehicle_id
        service_id
        service_date
        customer_address_id
        customer_gsm_id
        customer_tel_id
       
        order_types{
          name
        }
        order_status{
          name
        }
        order_packages{
          id
          package_id
          package_detail_id
          order_id
          part_id
          part_type_id
          part_value
          original_part_id
          parts{
            name
          }
        }
        companyByServiceId {
          name
          company_email_address
        }

        order_parts{
          id
          part_id
          part_type_id
          part_unit_type_id
          part_code
          part_unit
          part_value
          part_no_vat_price
          part_discount
          labour_type_id
          labour_value
          labour_discount
          selected_part_id
          service_value
          is_service
          brand_labour_price
          gos_labour_price
          total_price
          total_price_no_vat
          service_value_unit
          service_price_lock
          part_equivalent_lists{
            part_code
          }
          part_unit_types{
            id
            name
          }
          parts{
            id
            name
            part_group{
              id
              name
            }
          }
          part_types{
            name
          }
        }
        vehicle{
          id
          plate
          vehicle_model_types{
            id 
            name
          }
          vehicle_brand{
            id 
            name
          }
          years{
            id
            year_id
          }
          model_type_id
        }

        user_address{
          id
          address
          city_id
          country_id
          district_id
          town_id
          city{
            name
          }
          district{
            name
          }
          town{
            name
          }
        }
        customer_gsm {
          id
          name
          code
        }
        customer_tel {
          id
          name
          code
        }
        customer_order_status{
          name
        }
       
        customer_info {
          id
          name
          surname
          email
          role_id
          identity_no
          role {
            subtype
          }
          active
          gsm_id
          fax_id
          tel_id
          address_id
          company_id
          garages {
            id
            name
            vehicles {
              id
              vehicle_brand {
                name
              }
              vehicle_model {
                name
              }
              vehicle_model_types {
                name
              }
            }
          }
          address
          user_addresses {
            id
            address
            city_id
            country_id
            district_id
            town_id
            city {
              name
            }
            district {
              name
            }
            country {
              id
              name
            }
            town {
              id
              name
            }
          }
          gsms{
            id
            code
          }
          tels{
            id 
            code
          }
        }
        
      }
      
    }
  `;
}

@Injectable()
export class ORDER_SHOW extends Query<ResponseCustom> {
  override document = gql`
  query ORDER_SHOW($where: orders_bool_exp) {
    orders(where: $where) {
      id
      order_value
      show_popup
      order_no_vat_value
      service_date
      status_id
      customer_status_id
      invoice_id
      service_invoice_id
      installment_count
      installment_amount
      labour_discount_percentage
      labour_discount_price
      part_discount_percentage
      part_discount_price

      selected_package_type{
        id 
        name
      }
      payment_type
      is_payed
      order_invoice_info{
        customer_name
        customer_surname
        identity_no
        tax_number
        tax_administration
        company_id
        user_id
        gsm_number
        customer_email
        is_same_with_customer_info
        invoice_type
        tel_number
        address
        district{
          name
        }
        town{
          name
        }
        city{
          name
        }
        country{
          name
        }

      }
      order_status{
        name
      }
      maintenance_packages {
        name
      }
      customer_gsm {
        code
      }
      customer_tel {
        code
      }
      order_types {
        name
      }
      companyByServiceId {
        name
      }
      vehicle {
        plate
        vehicle_brand {
          name
        }
        vehicle_model_types {
          name
        }
        vehicle_model {
          name
        }
      }
      user_address {
        address
        district {
          name
        }
        city {
          name
        }
      }
      order_parts {
        part_value
        parts {
          name
        }
        part_types {
          name
        }
        part_unit_types{
          id
          name
        }
        part_unit
        part_discount
        labour_value
      }
      customer_info {
        email
        name
        surname
      }
      
    }
  }
  `;
}

export interface OrderSubs{
    "id": number,
    "customer_id": bigint,
    "main_order_id": bigint,
    "campaign_id": number,
    "combined_order_id": number,
    "created_at": Date,
    "fleet_id": number,
    "part_status_id": number,
    "repetation": number,
    "replacement_vehicle_id": number,
    "service_date": Date,
    "service_id": number,
    "source_id": number,
    "status_id": number,
    "customer_status_id": number,
    "steering_id": number,
    "track_id": number,
    "type_id": number,
    "updated_at": Date,
    "user_id": bigint,
    "user":any;
    "order_parts": any,
    "order_value": number,
    "maintenance_package_id":bigint,
    "maintenance_packages":any,
    "order_status": any
    "vehicle":any;
    "payment_types":number;
    "is_payed": Boolean;
    "payment_request_status":number
    "installment_count": number
    "installment_amount": number
}

@Injectable()
export class ORDER_CREATE extends Mutation<insertMutation> {
  override document = gql`
  mutation ORDER_CREATE(
    $status_id: smallint!,
    $vehicle_id: bigint!,
    $type_id: smallint, 
    $customer_id: bigint!,
    $order_value: numeric!,
    $maintenance_package_id: bigint!,
    $user_id: bigint,
    $service_id:bigint,
    $service_date:timestamp,
    $customer_address_id: bigint,
    $customer_gsm_id: bigint,
    $customer_tel_id: bigint,
    $service_value: numeric,
    $customer_status_id: smallint,
    $order_no_vat_value: numeric,
    $payment_type: Int,
    $is_payed: Boolean,
    $invoice_firstname: String,
    $invoice_lastname: String,
    $invoice_identity_number: bigint,
    $selected_package_type_id: Int,
    $payment_value: numeric,
    $vat: Int,
    $vat_price: numeric,
    $labour_price: numeric,
    $spare_part_price: numeric,
    $labour_hour: numeric,
    $customer_ip_address: String,
    $customer_user_agent: String,
    $installment_count: numeric,
    $installment_amount: numeric,
    $labour_discount_percentage: numeric,
    $labour_discount_price: numeric,
    $part_discount_percentage: numeric,
    $part_discount_price: numeric,
    $note: String,
    $cargo_cost: numeric,
    $url_utm_log_id: bigint
    ) {
      insert_orders(objects: {
        customer_status_id: $customer_status_id,
        status_id: $status_id,
        vehicle_id: $vehicle_id, 
        type_id: $type_id, 
        customer_id:$customer_id, 
        order_value:$order_value,
        user_id:$user_id,
        service_id:$service_id,
        service_date: $service_date,
        maintenance_package_id:$maintenance_package_id
        customer_address_id:$customer_address_id,
        customer_gsm_id:$customer_gsm_id,
        customer_tel_id:$customer_tel_id,
        service_value:$service_value
        order_no_vat_value: $order_no_vat_value,
        payment_type: $payment_type,
        is_payed: $is_payed,
        invoice_firstname: $invoice_firstname,
        invoice_lastname: $invoice_lastname,
        invoice_identity_number: $invoice_identity_number,
        selected_package_type_id: $selected_package_type_id
        payment_value: $payment_value,
        vat: $vat,
        vat_price: $vat_price,
        labour_price: $labour_price,
        spare_part_price: $spare_part_price,
        labour_hour: $labour_hour ,
        customer_ip_address: $customer_ip_address,
        customer_user_agent: $customer_user_agent,
        installment_count: $installment_count,
        installment_amount: $installment_amount,
        note: $note,
        labour_discount_percentage: $labour_discount_percentage,
        labour_discount_price: $labour_discount_price,
        part_discount_percentage: $part_discount_percentage,
        part_discount_price: $part_discount_price,
        cargo_cost: $cargo_cost,
        url_utm_log_id: $url_utm_log_id
        }) {
        affected_rows
        returning {
          id
        }
      }
    }`;
}


@Injectable()
export class ORDER_UPDATE extends Mutation<any> {
  override document = gql`
    mutation ORDER_UPDATE($id: bigint!, $changes: orders_set_input ) {
      update_orders(where: {id: {_eq: $id}}, _set: $changes) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_UPDATE_ADDRESS extends Mutation<any> {
  override document = gql`
    mutation ORDER_UPDATE_ADDRESS($id: bigint!, $address_id: bigint! ) {
      update_orders(where: {id: {_eq: $id}}, _set: {customer_address_id: $address_id}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_ALL extends Query<ResponseCustomerOrderArray> {
  override document = gql`
    query ORDER_ALL($user_id: bigint!) {
    orders(where: {user_id: {_eq: $user_id}}, order_by: {id: desc}) {
      id
      order_value
      order_no_vat_value
      show_popup
      payment_type
      is_payed
      installment_count
      installment_amount,
      url_utm_log_id,
      labour_discount_percentage
        labour_discount_price
        part_discount_percentage
        part_discount_price
      order_parts {
        id
        image
        note
        part_type_id
        part {
          id
          name
        }
      }
      order_type {
        id
        name
      }
      order_status {
        name
        class_name
      }
      vehicle {
        name
        garages {
          name
        }
      }
    }
  }`;
}

@Injectable()
export class ORDER_SUBS extends Subscription{
  override document = gql`subscription orders($where: orders_bool_exp) {
  orders(where: $where, order_by: {id: desc}, limit: 10) {
    id
    customer_id
    main_order_id
    campaign_id
    combined_order_id
    customer_status_id
    created_at
    fleet_id
    part_status_id
    order_value
    payment_type
    installment_count
    installment_amount
    is_payed
    repetation
    replacement_vehicle_id
    maintenance_package_id
    payment_request_status
    payment_attemts
    last_payment_message
    labour_discount_percentage,
    url_utm_log_id,
    labour_discount_price
    part_discount_percentage
    part_discount_price
    maintenance_packages{
      name
    }
    vehicle{
      vehicle_brand{
        logo_url
      }
      vehicle_model_types{
        name
      }
    }
    status_id
    order_status {
      name
      class_name
    }
    service_date
    service_id
    source_id
    status_id
    steering_id
    track_id
    type_id
    updated_at
    vehicle{
      plate
    }
    user_id
    user {
      name
      surname
      email
    }
    order_parts {
      id
      labour_discount
      labour_type_id
      labour_value
      part_discount
      order_id
      part_id
      part_type_id
      part_value
      registered_personel_id
      updated_personel_id
      part_types {
        name
      }
      
      parts {
        name
        part_group {
          name
        }
      }
    }
  }
}
`
}

@Injectable()
export class ORDER_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query ORDER_PAGINATE($limit: Int = 20, $offset: Int = 0, $orderBy: order_by = desc, $where: orders_bool_exp) {
    orders(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        user_id
        status_id
        customer_status_id
        user {
          name
          surname
          email
        }
        customer_id
        created_at
        updated_at
        installment_count,
        url_utm_log_id,
        installment_amount
        order_value
        order_no_vat_value
        payment_type
        is_payed
        labour_discount_percentage
        labour_discount_price
        part_discount_percentage
        part_discount_price
        order_types{
          name
        }
        order_status{
          name
          class_name
        }
        vehicle{
          vehicle_brand{
            name
          }
          vehicle_model{
            name
          }
          vehicle_model_types{
            name
          }
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_COUNT extends Query<any> {
  override document = gql`
    query ORDER_COUNT( $where: orders_bool_exp) {
      orders_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
    
  `;
}

@Injectable()
export class ORDER_CHECK_BY_DATE extends Query<OrderAggregate>{
  override document = gql`
  query ORDER_CHECK_BY_DATE($service_date: timestamp = "", $service_id: bigint = "") {
    orders_aggregate(where: {service_id: {_eq: $service_id}, service_date: {_eq: $service_date}}) {
      aggregate {
        count
      }
    }
  }`
}

@Injectable()
export class GET_ORDER_STATUS extends Query<ResponseSingle>{
  override document = gql`
  query GET_ORDER_STATUS($id: bigint!) {
    orders_by_pk(id: $id) {
      id
      service_id
      status_id
      service_date
      order_status{
        name
        class_name
      }
    }
  }`
}

@Injectable()
export class UPDATE_ORDER_STATUS extends Mutation<UpdateMutation>{
  override document = gql`
  mutation UPDATE_ORDER_STATUS($id: bigint!, $status_id: smallint!, $service_id: bigint) {
    update_orders(where: {id: {_eq: $id}}, _set: {status_id: $status_id,service_id: $service_id}) {
      affected_rows
      returning {
        id
        order_status{
          name
        }
      }
    }
  }`
}

@Injectable()
export class ORDER_GET_ALL extends Query<ResponseArray> {
  override document = gql`
  query ORDER_GET_ALL($where: orders_bool_exp!) {
    orders(where: $where, order_by: {id: desc}) {
      id
      order_value
      order_no_vat_value
      show_popup
      payment_type
      is_payed
      service_date
      installment_count
      installment_amount
      payment_request_status
      labour_discount_percentage
      url_utm_log_id
      labour_discount_price
      part_discount_percentage
      part_discount_price
      order_parts {
        id
        part_type_id
        total_price
        part_unit
        part_unit_types{
          id
          name
        }
        parts {
          id
          name
        }
      }
      order_types {
        id
        name
      }
      order_status {
        name
      }
      vehicle {
        plate
        garages {
          name
        }
      }
     
    }
  }`;
}
