<div class="w-100">

    <div class="row">
       <div class="col-md-12">
        <app-shared-cities [event]="1"  (city_id)="changeCity($event)"></app-shared-cities>
                                
        <app-shared-districts [event]="selectedCityId" (district_id)="changeCity($event,'district')"></app-shared-districts>

        <div class="row mb-5">
            <div class="col-md-3">
                <div class="fs-6 fw-bold">Servis Tarihi</div> </div>
            <div class="col-md-9">
                <app-shared-company-date-selector (selected)="setServiceDate($event)" *ngIf="selectedServiceId" [companyId]="selectedServiceId"></app-shared-company-date-selector>

            </div>
        </div>

       </div>
    </div>

    <div class="row mt-20" *ngIf="companyList">
            <div class="row d-flex" style="justify-content: space-around !important; height: 300px; overflow: scroll;">
        
     
                <label class="btn btn-outline btn-outline-dashed d-flex  text-start p-6 mb-5 col-md-5"
                style="padding-right: 10px;"
                                            *ngFor="let item of companyList">
                    <div class="d-flex align-items-center me-2 w-100">
                        <div
                            class="form-check form-check-custom form-check-solid form-check-primary me-6">
                            <input class="form-check-input" type="radio" name="services" value="{{item.id}}"
                            (click)="selectService(item.id)">
                        </div>
                        <span class="symbol symbol-50px me-6">
                            <span class="symbol-label">
                                <img 
                                *ngIf="item.logo !== null && item.logo !== '' "
                                src="https://cdn.destechhasar.com/garantili-oto-servis/{{item.logo}}" 
                                onerror="this.src='assets/images/garantili-oto-servis-38-750x450.png'" 
                                class="mw-100"  alt="" />
    
                                <img class="mw-100" *ngIf="item.logo === null || item.logo === ''"   src="http://cdn.destechhasar.com/garantili-oto-servis/w:150/h:100/gos-no-image.jpeg" alt="" />
    
                            </span>
                        </span>
                        <div class="flex-grow-1">
                            
                            <div class="fw-bold">
                                    <!--begin::Item-->
                            <div class="d-flex mb-7 img-container">
                                
                                <!--begin::Section-->
                                <div class="d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1">
                                    <!--begin::Title-->
                                    <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                                        
                                        <span class="text-gray-400 fw-bold fs-7 my-1">
                                            <span class="fs-6 fw-bolder opacity-80" style="color:#333">{{item.name}}</span>
                                            <span style="margin-top: 10px; margin-bottom: 10px; display: block;"  *ngIf="item.evalutaion_points != undefined">
                                                <i class="fa fa-star me-1 text-warning fs-5" *ngIf="item.evalutaion_points > 0"></i>
                                                <i class="fa fa-star me-1 text-warning fs-5" *ngIf="item.evalutaion_points > 1"></i>
                                                <i class="fa fa-star me-1 text-warning fs-5" *ngIf="item.evalutaion_points > 2"></i>
                                                <i class="fa fa-star me-1 text-warning fs-5" *ngIf="item.evalutaion_points > 3"></i>
                                                <i class="fa fa-star me-1 text-warning fs-5" *ngIf="item.evalutaion_points > 4"></i>
                                                <span class="text-gray-800 fw-bolder">
                                                    </span>
                                                
                                            </span>
                                                
                                            <div 
                                            class="gap-2 mb-2" 
                                            [ngClass]="{'no-border': item.companys_franchises === undefined}"
                                            style="margin-top: 10px; margin-bottom: 0px !important;"  
                                            >
                                            
                                            {{item.City?.name}} / {{ item.district?.name }}
                                        
                                                
                                            </div>
                                        </span>
                                    </div>
                                    <!--end::Title-->
                                
                                </div>
                                <!--end::Section-->
                            </div>
                            <!--end::Item-->
                            </div>
                        </div>
                        
                    </div>
                </label>
            
             
            </div>
    </div>
    

</div>
