import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { OrderInvoices, ORDER_INVOICE_ID } from 'src/app/graphql/order_invoices.graphql';
import { OrderInvoiceInfo, ORDER_INVOICE_INFO_ONE } from 'src/app/graphql/order_invoice_info.graphql';
import { ServiceInvoices, SERVICE_INVOICE_ID } from 'src/app/graphql/service_invoices.graphql';
import { ServiceInvoiceInfo, SERVICE_INVOICE_INFO_ONE } from 'src/app/graphql/service_invoice_info.graphql';
import { numberFormat } from 'src/app/helpers/slugify';
import { AlertifyService } from 'src/app/services/alertify.service';
import { PdfService } from 'src/app/services/pdf.service';

@Component({
  selector: 'app-shared-invoice-generator-v2',
  templateUrl: './invoice-generator-v2.component.html',
  styles: [
  ],
  providers:[ORDER_INVOICE_ID,ORDER_INVOICE_INFO_ONE, SERVICE_INVOICE_ID,SERVICE_INVOICE_INFO_ONE  ]

})
export class InvoiceGeneratorV2Component implements OnInit {

  private gosInfo:any;
 private customerInfo:any;

public content:any;
public invoiceInfo: OrderInvoices | undefined;

@Input() invoiceId:bigint | undefined;
@Input() type:string | undefined;

 public docDefinition:TDocumentDefinitions | undefined; 
public orderInoviceInfo: OrderInvoiceInfo | undefined;
public serviceInvoiceInfo: ServiceInvoices | undefined;
public serviceInvoiceDetailInfo: ServiceInvoiceInfo | undefined;
public selectedInvoiceInfo : any;


  constructor(
    private pdfService: PdfService,
    private alertifyService: AlertifyService,
    private getOrderInvoice: ORDER_INVOICE_ID,
    private getOrderInvoiceInfo: ORDER_INVOICE_INFO_ONE,
    private getServiceInvoice: SERVICE_INVOICE_ID,
    private getServiceInvoiceInfo: SERVICE_INVOICE_INFO_ONE
  ) { }

  ngOnInit(): void {
    if(this.invoiceId !== undefined){
      this.getInvoiceInfo();
   }
  }

  async createInvoice(){
  
    this.content = {}
  
      await this.createContentLogo();
      await this.setCompanyInfo();
      await this.setCustomerInfo();
      await this.setEttn();
      await this.setProducts();
      await this.setTotalTable();
      await this.setInvoiceEnd()
      await this.setInvoice();
  
    
  }

  async getInvoiceInfo(){

    let gosInfo = {
      table: {
        widths: ['*', 'auto'],
        body: [
          ["Sigorta Şirketi", ""],
          ["Dosya No ", ""],
          ["Dosya Sorumlusu", ""],
          ["Plaka", " 34BAF679"],
          ["Masraf Yeri", "GOS - MALTEPE"],
          ["Eksper", ""],
          ["KM Bilgisi ", ""],
          ["Onay No", ""],
          ["Ödeme Koşulu ", "#45 GÜN"],
          ["Genel Açıklamalar", ""],
          [{
            colSpan: 2, text: "Banka Bilgileri"
          }],
          ["Banka Adı", "Denizbank"],
          ["Şube Adı/Kodu", " Maltepe şb"],
          ["Hesap No", ""],
          ["IBAN", "TR74 0013 4000 0191 2298 9000 01"],
        ]
      },
      style: 'tableStyle',
      margin: [0, 150, 0, 0],
      alignment: 'left',
      layout: {
        hLineWidth: function (i:any, node:any) {
          return (i === 0 || i === node.table.body.length) ? 1 : 0;
        },
        vLineWidth: function (i:any, node:any) {
          return (i === 0 || i === node.table.widths?.length) ? 1 : 0;
        }
      }
    };

 
    this.gosInfo = ["-----------------------------------------------------------------------\n",
    "GARANTİLİ SATIŞ SONRASI OTO HİZMETLERİ ANONİM ŞİRKETİ\n",
    "CEVİZLİ MAH TUGAY YOLU CAD No:59\n",
    "34846 MALTEPE / İSTANBUL\n",
    "Tel: 0850 480 66 55 Fax: \n",
    "Web Sitesi: http://www.garantiliotoservis.com.tr\n",
    "E-posta: destek@garantiliotoservis.com\n",
    "Vergi Dairesi: MASLAK VERGİ DAİRESİ\n",
    "VKN: 3891616118\n",
    "TİCARETSİCİLNO: 315000-5\n",
    "MERSISNO: 389161611800001\n",
    "-----------------------------------------------------------------------"];
  
    switch(this.type){
      case "customer":
        await this.getOrderInvoice.watch({id:this.invoiceId}).valueChanges.subscribe((result:any)=>{

          if(result.errors){
            this.alertifyService.error(result.errors[0].message);
          
          }
    
          if(result.data){

              this.selectedInvoiceInfo = result.data.order_invoices_by_pk;
            

              this.getOrderInvoiceInfo.watch({where:{order_id:{_eq:this.selectedInvoiceInfo?.order_id}}}).valueChanges.subscribe((result:any)=>{
              if(result.errors){
                this.alertifyService.error(result.errors[0].message);
              }
          
              if(result.data){
                this.orderInoviceInfo = result.data.order_invoice_info[0];

                let tel = "";
                if(this.orderInoviceInfo?.gsm_number != null){
                  tel = this.orderInoviceInfo?.gsm_number;
                }else if( this.orderInoviceInfo?.tel_number ){
                  tel = this.orderInoviceInfo?.tel_number;
                }

                let tckn;
                if(this.orderInoviceInfo?.customer_invoice_type == 1){
                  tckn = this.orderInoviceInfo.identity_no;
                }else{
                  tckn = (this.orderInoviceInfo?.tax_number == null ? "": this.orderInoviceInfo?.tax_number);
                }
                this.customerInfo = [
                  "-----------------------------------------------------------------------\n",
                  "SAYIN\n",
                  this.orderInoviceInfo?.customer_name+ " " + this.orderInoviceInfo?.customer_surname + "\n",
                  this.orderInoviceInfo?.address + " " + 
                  this.orderInoviceInfo?.town?.name + " " + 
                  this.orderInoviceInfo?.district?.name + " / " + 
                  this.orderInoviceInfo?.city?.name + " \n" + 
                  "E-Posta: " + this.orderInoviceInfo?.customer_email + "\n",
                  "Tel: "+ tel +"\n",
                  "Vergi Dairesi: "+ (this.orderInoviceInfo?.tax_administration == null ? "" : this.orderInoviceInfo?.tax_administration) +"\n",
                  "TCKN/VKN: "+ tckn +"\n",
                  "-----------------------------------------------------------------------"
                ];
                this.createInvoice();
          
              }
            });
              
          }
    
        });
      
      break;

      case "gos":
      
        this.customerInfo =["-----------------------------------------------------------------------\n",
        "GARANTİLİ SATIŞ SONRASI OTO HİZMETLERİ ANONİM ŞİRKETİ\n",
        "CEVİZLİ MAH TUGAY YOLU CAD No:59\n",
        "34846 MALTEPE / İSTANBUL\n",
        "Tel: 0850 480 66 55 Fax: \n",
        "Web Sitesi: http://www.garantiliotoservis.com.tr\n",
        "E-posta: destek@garantiliotoservis.com\n",
        "Vergi Dairesi: MASLAK VERGİ DAİRESİ\n",
        "VKN: 3891616118\n",
        "TİCARETSİCİLNO: 315000-5\n",
        "MERSISNO: 389161611800001\n",
        "-----------------------------------------------------------------------"];
      ;

        this.getServiceInvoice.watch({id:this.invoiceId}).valueChanges.subscribe((result:any)=>{
          if(result.errors){
            this.alertifyService.error(result.errors[0].message);
          
          }
          if(result.data){
            this.selectedInvoiceInfo = result.data.service_invoices_by_pk;

              this.getOrderInvoiceInfo.watch({where:{order_id:{_eq:this.selectedInvoiceInfo?.order_id}}}).valueChanges.subscribe((result:any)=>{
              if(result.errors){
                this.alertifyService.error(result.errors[0].message);
              }

              
          
              if(result.data){
                this.orderInoviceInfo = result.data.order_invoice_info[0];

                this.getServiceInvoiceInfo.watch({where:{order_id:{_eq:this.selectedInvoiceInfo?.order_id}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(serviceInfoRes => {
                  if(serviceInfoRes.errors){
                    this.alertifyService.error(serviceInfoRes.errors[0].message);
                  }

                  if(serviceInfoRes.data){
                    this.serviceInvoiceDetailInfo = serviceInfoRes.data.service_invoice_info[0];
                    this.customerInfo = [
                      "-----------------------------------------------------------------------\n",
                      "SAYIN\n",
                      this.serviceInvoiceDetailInfo?.customer_name+ " " + 
                      (this.serviceInvoiceDetailInfo?.customer_surname == null ? "" : this.serviceInvoiceDetailInfo?.customer_surname) + "\n",
                      this.serviceInvoiceDetailInfo?.address + " " + 
                      this.serviceInvoiceDetailInfo?.town?.name + " " + 
                      this.serviceInvoiceDetailInfo?.district?.name + " / " + 
                      this.serviceInvoiceDetailInfo?.city?.name + " \n" + 
                      "E-Posta: " + this.serviceInvoiceDetailInfo?.customer_email + "\n",
                      "Tel: "+ this.serviceInvoiceDetailInfo?.tel_number +":\n",
                      "Vergi Dairesi: "+ (this.serviceInvoiceDetailInfo?.tax_administration == null ? "" : this.serviceInvoiceDetailInfo?.tax_administration) +"\n",
                      "VKN: "+ (this.serviceInvoiceDetailInfo?.tax_number == null ? "": this.serviceInvoiceDetailInfo?.tax_number) +"\n",
                      "-----------------------------------------------------------------------"
                    ];

                    let town = (this.serviceInvoiceDetailInfo?.service_town?.name) ? this.serviceInvoiceDetailInfo?.service_town.name : "";

                    this.gosInfo = ["-----------------------------------------------------------------------\n",
                      this.serviceInvoiceDetailInfo.service_name + "\n",
                      town + " " +this.serviceInvoiceDetailInfo.address+ "\n",
                      this.serviceInvoiceDetailInfo.service_district?.name+ " / "+ 
                      this.serviceInvoiceDetailInfo.service_city?.name + "\n",
                      "Tel: "+(this.serviceInvoiceDetailInfo.service_tel_number == null ? "" : this.serviceInvoiceDetailInfo.service_tel_number)+" Fax: \n",
                      "Vergi Dairesi: "+this.serviceInvoiceDetailInfo.tax_administration+"\n",
                      "VKN: "+this.serviceInvoiceDetailInfo.tax_number+"\n",
                      "-----------------------------------------------------------------------"];
                    ;

                    this.createInvoice();

                  }
                })

                
                
          
              }
            });
          }
        })
      break;

    }

   
 
   

  }

  async setInvoice(){
    this.docDefinition = this.content;
  }
  
  async setInvoiceEnd(){
    
    let totalContinue = {
      table: {
        widths: ['*', 'auto'],
        body: [
          ["Sigorta Şirketi", "EUREKO SİGORTA"],
          ["Dosya No ", "22211772"],
          ["Dosya Sorumlusu", ""],
          ["Plaka", (this.orderInoviceInfo?.orders?.vehicle) ? this.orderInoviceInfo?.orders?.vehicle.plate : ""],
          ["Masraf Yeri", "GOS - MALTEPE"],
          ["Eksper", ""],
          ["KM Bilgisi ", ""],
          ["Onay No", ""],
          ["Ödeme Koşulu ", "#45 GÜN"],
          ["Genel Açıklamalar", ""],
          [{
            colSpan: 2, text: "Banka Bilgileri"
          }],
          ["Banka Adı", "Denizbank"],
          ["Şube Adı/Kodu", "Maltepe şb"],
          ["Hesap No", ""],
          ["IBAN", "TR74 0013 4000 0191 2298 9000 01"],
        ]
      },
      style: 'tableStyle',
      margin: [0, 150, 0, 0],
      alignment: 'left',
      layout: {
        hLineWidth: function (i:any, node:any) {
          return (i === 0 || i === node.table.body.length) ? 1 : 0;
        },
        vLineWidth: function (i:any, node:any) {
          return (i === 0 || i === node.table.widths?.length) ? 1 : 0;
        }
      }
    };
  
    this.content.content.push([' ']);
  
    this.content.content.push(totalContinue);
  }
  
  async setTotalTable(){
    this.content.content.push([' ']);
      let totalTable = {
        columns: [
          [],
          [
            {
              table: {
                body: [
                  ["Malzeme/Hizmet Toplam Tutarı", { text: this.trFormatCurrency(this.selectedInvoiceInfo?.value_no_vat), noWrap: true, alignment: 'right' }],
                  ["Toplam İskonto", { text: "0,00 TL", noWrap: true, alignment: 'right' }],
                  ["Hesaplanan KDV (%20,00)", { text: this.trFormatCurrency(this.selectedInvoiceInfo?.vat), noWrap: true, alignment: 'right' }],
                  ["Tevkifata Tabi İşlem Tutar", { text: this.trFormatCurrency(this.selectedInvoiceInfo?.value_in_vat), noWrap: true, alignment: 'right' }],
                  ["Tevkifata Tabi İşlem Üzerinden Hes. KDV", { text: this.trFormatCurrency(this.selectedInvoiceInfo?.vat), noWrap: true, alignment: 'right' }],
                  ["Vergiler Dahil Toplam Tutar", { text: this.trFormatCurrency(this.selectedInvoiceInfo?.value_in_vat), noWrap: true, alignment: 'right' }],
                  ["Ödenecek Tutar ", { text: this.trFormatCurrency(this.selectedInvoiceInfo?.value_in_vat) + " TL", noWrap: true, alignment: 'right' }],
                ]
              }
            }
          ]
        ]
      };
      
      this.content.content.push(totalTable);
  }
  
  async setProducts(){
    let table = {
      headerRows: 1,
      body: [
        ["Sıra No", "Malzeme/Hizmet Kodu", "Malzeme/Hizmet Açıklaması", "Miktar", "Birim Fiyat", "İskonto Oranı", "KDV Oranı", "Malzeme/Hizmet Tutarı"]
      ]
    }
   
    if(this.type == "gos"){
      this.selectedInvoiceInfo?.service_invoice_parts.forEach((obj:any,index:number)=>{
     
        let birimFiyat = (obj.service_price > 0) ? this.toNumberFormat(obj.service_parice) : this.toNumberFormat(obj.part_in_vat_price);
        let toplamFiyat = (obj.service_price > 0) ? this.toNumberFormat(obj.service_parice * obj.part_unit) : this.toNumberFormat(obj.part_in_vat_price * obj.part_unit);
        table.body.push([(index+1).toString(), obj.part_code, obj.part_name, obj.part_unit + " " + (obj.part_unit_types?.name == undefined ? "":  obj.part_unit_types?.name), birimFiyat + " TL", "0", "% 20", toplamFiyat + " TL"]);
    
      })
    }else{
      this.selectedInvoiceInfo?.invoice_parts.forEach((obj:any,index:number)=>{
     
        table.body.push([(index+1).toString(), obj.part_code, obj.part_name, this.toNumberFormat(obj.part_unit) + " " + (obj.part_unit_types?.name == undefined ? "":  obj.part_unit_types?.name), this.trFormatCurrency(obj.part_in_vat_price) , "0", "% 20", this.trFormatCurrency(obj.part_in_vat_price) ]);
    
      })
    }
    
  
    this.content.content.push({table:table,
    style: 'titleStyle'});
  
    this.content.content.push([' ']);
  }
  
  async setEttn(){

    if(this.invoiceInfo?.ettn != null){
      this.content.content.push({ text: "ETTN: "+this.invoiceInfo?.ettn, fontSize: 10, margin: [0, 50, 0, 0] });
    }

    this.content.content.push([' ']);
  }
  
  async setCustomerInfo(){
    this.content.content.push({
      columns: [
        [
          {
            text: this.customerInfo,
            style: 'titleStyle'
          }
        ],
        [],
        [
          {
            table: {
              widths: ['*', 'auto'],
              body: [
               
                ["Senaryo:", "TICARIFATURA"],
                ["Fatura Tipi:", "SATIS"],
                ["Fatura No:", (this.invoiceInfo?.invoice_no) ? this.invoiceInfo.invoice_no: ""],
                ["Fatura Tarihi:", moment(this.selectedInvoiceInfo?.valor_date).format("LLL")],
                ["Sipariş No: ", (this.selectedInvoiceInfo?.order_id == undefined) ? 0 : this.selectedInvoiceInfo?.order_id ]
              ]
            },
            style: 'titleStyle'
          }
        ]
      ]
    })
  
  }
  
  async setCompanyInfo(){
    this.content.content.push({
      columns: [
        [
          {
            text: this.gosInfo,
            style: 'titleStyle'
          }
        ],
        [
          {
            image: 'invoice',
            width: 100,
            alignment: 'center'
          },
          {
            text: "e-Fatura",
            bold: true,
            alignment: 'center',
            fontSize: 20
          }
        ],
        [
          {
            image: 'invoice_logo',
            width: 120,
            height: 100,
            alignment: 'right'
          }
        ]
      ]
    });
  }
  
  toNumberFormat(item:any){
    return numberFormat(item)
  } 

 trFormatCurrency = (value: number): string => {
  // Türk Lirası formatına çevirme, ancak para birimi simgesi olmadan
  const formatted = new Intl.NumberFormat('tr-TR', {
      style: 'decimal', // 'currency' yerine 'decimal' kullanılıyor
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  }).format(value);

  // Sonuca '₺' işaretini ekleyerek döndürme
  return formatted + '₺';
}
  
    async createContentLogo(){
  
    
  
      this.content = {
        content:[],
        images: {
          invoice: 'https://cdn.destechhasar.com/garantili-oto-servis/invoice/invoice.png',
          invoice_logo: 'https://cdn.destechhasar.com/garantili-oto-servis/invoice/invoice_logo.png'
        },
        defaultStyle: {
          fontSize: 9
        },
        styles: {
          titleStyle: {
            fontSize: 8
          },
          tableStyle: {
            fontSize: 8,
            alignment: 'center',
            margin: [0, 10, 0, 10]
          }
        }
      }
    }
  
    invoiceChange(event: any) {
      switch (event.target.value) {
        case "preview":
          if(this.docDefinition != undefined){
          this.pdfService.preview(this.docDefinition)
        }
          //this.pdfService.generator("preview", this.invoiceBody.nativeElement)
          break;
        case "download":
          if(this.docDefinition != undefined){
          this.pdfService.download(this.docDefinition)
          }
          break;
        case "print":
          if(this.docDefinition != undefined){
          this.pdfService.print(this.docDefinition)
          }
          break;
        default:
          break;
      }
    }


    
}
