import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { Currency, CURRENCY_ALL } from 'src/app/graphql/currency.graphql';
import { ORDER_CARDS_UPDATE } from 'src/app/graphql/order_cards.graphql';
import { PackageDetail, PACKAGE_DETAIL_ALL } from 'src/app/graphql/package-detail.graphql';
import { Package, PACKAGE_ALL } from 'src/app/graphql/package.graphql';
import { PackageKms } from 'src/app/graphql/package.kms';
import { PACKAGE_MODEL_TYPE_ALL } from 'src/app/graphql/package_model_type.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-appointment-paket-secimi',
  templateUrl: './paket-secimi.component.html',
  styles: [
  ],
  providers:[PACKAGE_MODEL_TYPE_ALL,PACKAGE_ALL,ORDER_CARDS_UPDATE,PACKAGE_DETAIL_ALL,CURRENCY_ALL]
})
export class PaketSecimiComponent implements OnInit {

  @Input() modelTypeId: Number | undefined;
  @Output() packageEmitter = new EventEmitter<any>();
  @Output() removeBtn = new EventEmitter<boolean>();
  public maintenanceKmsLists: PackageKms[] = [];
  public packageList: Package[] | undefined;
  public packageListFiltered: Package[] | undefined;
  @Input() selectedPackageId: Number | undefined;
  public currencyList: Currency[] | undefined;
  public customPackageDetailList:any = [];
  public packageDetailList: PackageDetail[] | undefined;
  public selectedPackageInfo: any;
  public orderCardId:bigint | undefined;
  public maintenanceKmsId: Number | undefined;
  public selectedModelTypeId: Number | undefined;
  @Input() selecteMaintenanceKmsId: Number | undefined;
  @Input() kmsName: String | undefined;
  public showUserForm: boolean = false;


  constructor(
    private getPackageModelTypes: PACKAGE_MODEL_TYPE_ALL,
    private alertifyService: AlertifyService,
    private authService: AuthService,
    private getPackages: PACKAGE_ALL,
    private getCurrency: CURRENCY_ALL,
    private updateOrderCards: ORDER_CARDS_UPDATE,
    private getPackageDetails: PACKAGE_DETAIL_ALL,

  ) { 
    
  }

  ngOnInit(): void {



    this.getCurrency.watch().valueChanges.subscribe((result:any)=>{
      if(result.errors){
        this.alertifyService.error(result.errors[0].message);
      }

      if(result.data){
        this.currencyList = result.data.currency;
      }

     

    });

 

    setTimeout(() => {
    
  if(this.modelTypeId){

    this.getPackageModelTypes.watch({where:{model_type_id:{_eq:this.modelTypeId}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
      if(response.error){
        this.alertifyService.error("Bir hata oluştu. Lütfen tekrar deneyiniz.");
        return false;
      }

      if(response.data){

        let modeltypes = response.data.maintenance_package_model_types;
        
        if(modeltypes.length == 0){
          this.showUserForm = true;
          this.removeBtn.emit(false);
          return false;

        }else{
          this.removeBtn.emit(true);
        }

        let packageIds:any = [];

        modeltypes.forEach((element:any) => {
          packageIds.push(element.package_id);
        });

        if(packageIds.length > 0){

          this.getPackages.watch({where:{id:{_in:packageIds}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(packageResponse => {
            if(packageResponse.error){
              this.alertifyService.error("Bir hata oluştu. Lütfen tekrar deneyiniz");
              return false;
            }

            if(packageResponse.data){
              this.packageList = packageResponse.data.maintenance_packages;
              this.packageListFiltered = undefined;
  

                if(this.selecteMaintenanceKmsId){
                  this.selectedTwo('changeKms',this.selecteMaintenanceKmsId);
          
                }

                if(this.packageDetailList?.length == 0){
                }

              //? bazı bakım paketlerinde ürünlerin part-equivalnets boş geldiğinden dolayı "lütfen bütün parçaları seçiiniz hatası veriyordu. hatalı bakım paketlerini paket içerisinden çıkardık. yönetim paneline hatalı bakım paketleri diye bir bölüm açacağız"
              this.packageList.forEach((v,k)=>{

                if(this.selectedPackageId && this.selectedPackageId == v.id){
                  this.setPackageInfo(v.id,v);
                }

                if(v.maintenance_kms != null){
                  this.maintenanceKmsLists.push(v.maintenance_kms);

                }
                  v.maintenance_package_details.forEach((val:any)=>{
                    if(val.part_original_list.part_equivalent_lists.length == 0){
                      this.packageList = this.packageList?.filter((pObj:any) => {
                        return pObj.id != v.id
                      });
                    }
                  })
                  
                })

            }
          })
        

        }
      }
    })

  }
  },100);

   
  }



  selectedTwo(key: string, $event:any){

    switch(key){
      case "changeKms":
        this.packageListFiltered = [];
        this.selecteMaintenanceKmsId = $event;
       this.maintenanceKmsLists.filter((item)=>{
        if(item.id == $event){
          this.kmsName = item.name;
        } 
      })
      //  this.selectedPackageId = undefined;
   
        if(this.packageList && this.packageList?.length > 0){
          this.packageList.forEach(resp=>{
            if(resp.maintenance_kms_id == $event){
              this.packageListFiltered?.push(resp);
            }
          })

        }
      break;
    }

  }

  getSmallAndBig(list:any){


    //  const prices:any = [];
      let totalPrice:number = 0;
      if(list.maintenance_package_details !== undefined && Object.keys(list.maintenance_package_details).length > 0){
       
        list.maintenance_package_details.forEach((packageItem:any)=>{

          if  (packageItem.part_unit_type != 3 && packageItem.part_original_list.part_equivalent_lists && packageItem.part_original_list.part_equivalent_lists != null){
            let selectedEsdegerPrice:any;
            packageItem.part_original_list.part_equivalent_lists.forEach(equivalentItem => {

              if(equivalentItem.part_type_id == 3){
                
                let tlPrice = parseFloat(( equivalentItem.part_in_vat_price * packageItem.part_unit ).toFixed(2));

            

                if(equivalentItem.price_unit_id != 1){
           
                  let currentCurrency = this.currencyList?.filter(i => i.price_unit_id == equivalentItem.price_unit_id);
           
                       if(currentCurrency && currentCurrency.length > 0){
           
                         let currency = currentCurrency[0].currency_value;
  
                         tlPrice = parseFloat( ((currency *  equivalentItem.part_in_vat_price ) * packageItem.part_unit).toFixed(2)  );
                         tlPrice = tlPrice + ( (tlPrice * 5) / 100)
  
                        }
  
                }
  
                if(selectedEsdegerPrice == undefined){
               
                  selectedEsdegerPrice = tlPrice;
                }else{
                  if(tlPrice > selectedEsdegerPrice){
            
                    selectedEsdegerPrice = tlPrice;
                  }
                }
              }
              

            });

            if(totalPrice == 0){
              totalPrice = selectedEsdegerPrice;
        
            }else{
              totalPrice = parseFloat((totalPrice + selectedEsdegerPrice).toFixed(2));
            }

          }

        })

        return totalPrice.toFixed(2) + "&#8378; başlayan fiyatlar ile";

      //
       /*
        for (let i = 0; i < Object.keys(list.maintenance_package_details).length; i++) {
       
          let equivalentInfo = list.maintenance_package_details[i].part_original_list.part_equivalent_lists[0];
          if(equivalentInfo == undefined){
            continue;
          }
  
          let priceWithVat:number = equivalentInfo.part_in_vat_price;
          let priceNoVat:number = equivalentInfo.part_no_vat_price;
  
          if(equivalentInfo.price_unit_id != 1){
            let currentCurrency = this.currencyList?.filter(i => i.price_unit_id == equivalentInfo.price_unit_id);
  
            if(currentCurrency){
              priceWithVat = (currentCurrency[0].currency_value * equivalentInfo.part_in_vat_price);
              priceNoVat = (currentCurrency[0].currency_value * equivalentInfo.part_no_vat_price);
            }
          }

          priceWithVat = ( priceWithVat *  list.maintenance_package_details[i].part_unit);
    
          if(totalPrice == 0){
            totalPrice = priceWithVat;
          }else{
            totalPrice += priceWithVat;
          }
  
        }*/
  
      }
  
      
      return totalPrice.toFixed(2) + "&#8378; başlayan fiyatlar ile";
    }

    setPackageInfo($event:any,info:any){
    
      this.packageDetailList = [];
      this.customPackageDetailList = [];
      this.selectedPackageId = (typeof($event) == "object") ? $event.target.value : $event;
      this.selectedPackageInfo = info;
      this.packageEmitter.emit({"selectedPackageId": this.selectedPackageId, "selectedKmsId":this.selecteMaintenanceKmsId,"selectedPackageInfo":this.selectedPackageInfo,
    "selectedKmsName":this.kmsName});
  /*
      if(this.orderCardId != undefined && this.orderCardId > 0){
        this.updateOrderCards.mutate({
          id:this.orderCardId,
          changes:{
            maintenance_package_id:this.selectedPackageId,
            step: 3
          }
        }).subscribe(response => {
          
        });
      }
  
     
      
      this.getPackageDetails.watch({where:{maintenance_package_id:{_eq:this.selectedPackageId}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
        if(result.errors){
          this.alertifyService.error("bir hata oluştu");
        }
        if(result.data){
          
          this.packageDetailList = result.data.maintenance_package_details;
  
          let newObj:any;
          this.packageDetailList.forEach(element => {
            //paket detayını set ediyoruz
            if(element.part_original_list.id == undefined || element.part_original_list.id == null){
              //TODO: buraya bak
              
            }else{
  
              newObj ={
                selectedPart:null,
                packageDetail:null,
                id:element.id,
                sub_industry: null,
                without_logo: null,
                equivalent_1: null,
                equivalent_2: null,
                original: null,
                offical_original:null,
                partTypeName:"Orijinal",
              };
  
              newObj.packageDetail = {
                id:element.id,
                name:element.maintenance_package.name,
                part_unit:element.part_unit,
                part_unit_type:element.part_unit_type
              }
              
              if(element.part){
                newObj.selectedPart = {
                  id:element.part_id,
                  name:element.part.name,
                  part_group_id: element.part.part_group_id
                }
                
              }else{
                this.alertifyService.error("bir hata oluştu");
                return false;
              }
  
              if(element.part_original_list.length == 0){
                this.alertifyService.error("Beklenemedik bir hata oluştu");
                return false;
              }
  
              
              
              if(element.part_original_list.part_equivalent_lists == undefined || element.part_original_list.part_equivalent_lists.length == 0){
                //TODO buraya bak
                return false;
              }
  
              //yetkili original
              newObj.offical_original = {
                price_with_vat: element.part_original_list.part_in_vat_price,
                price:element.part_original_list.part_in_vat_price,
                price_no_vat:element.part_original_list.part_no_vat_price,
                part_type_name: "yetkili_original",
                part_original_id: element.part_original_list.id,
                id: element.part_original_list.id,
                price_unit: element.part_original_list.price_unit,
                part_type_id:element.part_original_list.part_type_id,
                part_id:element.part_id,
                partTypeName: "Orijinal",
                part_code:element.part_original_list.part_code,
                selected_part_group_id: newObj.selectedPart.part_group_id,
                part_name: element.part_original_list.name
              }
           
              element.part_original_list.part_equivalent_lists.forEach((equivalent:any) => {
  
                let priceWithVat = equivalent.part_in_vat_price;
                let priceNoVat = equivalent.part_no_vat_price;
                let priceUnit = equivalent.price_unit;
                let currency = 0;
                let now  = moment().format('yyyy-MM-DDTH:mm');
  
                if(equivalent.price_unit_id != 1){
                  priceUnit = "TL";
                  let currentCurrency = this.currencyList?.filter(i => i.price_unit_id == equivalent.price_unit_id);
  
                  if(currentCurrency){
                    currency = currentCurrency[0].currency_value;
                    priceWithVat = (currency * equivalent.part_in_vat_price).toFixed(2);
                    priceNoVat = (currency * equivalent.part_no_vat_price).toFixed(2);
                  }
                }
  
                newObj.part_type_id = equivalent.part_type_id;
                newObj.equivalent_name = equivalent.name;
                newObj.part_code = equivalent.part_code;
                
  
                switch(equivalent.part_type_id){
                  case 2://logosuz orijinal
                    newObj.without_logo = {
                      price_with_vat: priceWithVat,
                      price: priceWithVat,
                      price_no_vat: priceNoVat,
                      part_type_name: "logosuz",
                      part_type_id: equivalent.part_type_id,
                      part_original_id: equivalent.part_original_id,
                      id: equivalent.id,
                      price_unit: priceUnit,
                      part_id:element.part_id,
                      partTypeName:"Logosuz Orijinal",
                      part_code:equivalent.part_code,
                      price_unit_id:equivalent.price_unit_id,
                      price_without_currency: equivalent.part_no_vat_price,
                      price_vat_without_currency: equivalent.part_in_vat_price,
                      currency: currency,
                      created_at: now,
                      selected_part_group_id: newObj.selectedPart.part_group_id,
                      part_name: equivalent.name
                    };
                  break;
                  case 3://eşdeğer
                    if(newObj.equivalent_1 == null){
                    newObj.equivalent_1 = {
                      price_with_vat: priceWithVat,
                      price: priceWithVat,
                      price_no_vat: priceNoVat,
                      part_type_name: "esdeger_1",
                      part_type_id: equivalent.part_type_id,
  
                      part_original_id: equivalent.part_original_id,
                      id: equivalent.id,
                      price_unit: priceUnit,
                      part_id:element.part_id,
                      partTypeName:"Eş değer",
                      part_code:equivalent.part_code,
                      price_unit_id:equivalent.price_unit_id,
                      price_without_currency: equivalent.part_no_vat_price,
                      price_vat_without_currency: equivalent.part_in_vat_price,
                      currency: currency,
                      created_at: now,
                      selected_part_group_id: newObj.selectedPart.part_group_id,
                      part_name: equivalent.name
  
                    };
                    }else{
                      newObj.equivalent_2 = {
                        price_with_vat: priceWithVat,
                        price: priceWithVat,
                        price_no_vat: priceNoVat,
                        part_type_name: "esdeger_2",
                        part_type_id: equivalent.part_type_id,
                         part_original_id: equivalent.part_original_id,
                        id: equivalent.id,
                        price_unit: priceUnit,
                        part_id:element.part_id,
                        name: equivalent.name,
                        partTypeName:"Eş değer",
                        part_code:equivalent.part_code,
                        price_unit_id:equivalent.price_unit_id,
                        price_without_currency: equivalent.part_no_vat_price,
                        price_vat_without_currency: equivalent.part_in_vat_price,
                        currency: currency,
                        created_at: now,
                        selected_part_group_id: newObj.selectedPart.part_group_id,
                        part_name: equivalent.name
  
                      };
                    }
                  break;
                  case 4://yan sayayi
                    newObj.sub_industry = {
                      price_with_vat: priceWithVat,
                      price: priceWithVat,
                      price_no_vat: priceNoVat,
                      part_type_name: "yan_sanayi",
                      part_type_id: equivalent.part_type_id,
                       part_original_id: equivalent.part_original_id,
                      id: equivalent.id,
                      price_unit: priceUnit,
                      part_id:element.part_id,
                      partTypeName:"Yan Sanayi",
                      part_code:equivalent.part_code,
                      price_unit_id:equivalent.price_unit_id,
                      price_without_currency: equivalent.part_no_vat_price,
                      price_vat_without_currency: equivalent.part_in_vat_price,
                      currency: currency,
                      created_at: now,
                      selected_part_group_id: newObj.selectedPart.part_group_id,
                      part_name: equivalent.name
  
                    };
                  break;
                  case 5: //Original
                    newObj.original = {
                      price_with_vat: priceWithVat,
                      price: priceWithVat,
                      price_no_vat: priceNoVat,
                      part_type_name: "original",
                      part_type_id: equivalent.part_type_id,
                      part_original_id: equivalent.part_original_id,
                      id: equivalent.id,
                      price_unit: priceUnit,
                      part_id:element.part_id,
                      partTypeName:"Orijinal",
                      part_code:equivalent.part_code,
                      price_unit_id:equivalent.price_unit_id,
                      price_without_currency: equivalent.part_no_vat_price,
                      price_vat_without_currency: equivalent.part_in_vat_price,
                      currency: currency,
                      created_at: now,
                      selected_part_group_id: newObj.selectedPart.part_group_id,
                      part_name: equivalent.name
  
                    }
                  break;
                }
              })
  
              if(newObj.equivalent_1 == null || newObj.equivalent_2 == null){
                //TODO Başbug parçalarını getir.
                if(newObj.equivalent_1 == null){
                  newObj.equivalent_1 = {
                    price_with_vat: 1100,
                    price_no_vat: 800,
                    price:1000,
                    part_type_name: "basbug_1"
                  }
                } 
                
              }
  
              this.customPackageDetailList.push(newObj);
  
            }
          })
  
        }
        
      }
      )*/
    }


}
