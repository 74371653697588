  <owl-carousel-o [options]="customOptions">
    
    <ng-template carouselSlide *ngFor="let item of images" >
            <div class="d-flex flex-center flex-wrap position-relative px-5"  *ngIf="sliderType === 'brand'; else imageSlide">
                <div class="d-flex flex-center m-3 m-md-6">
                    <img src="{{item.image}}" class="mh-30px mh-lg-40px" alt="">
                   
                </div>
            </div>
    
            <ng-template  #imageSlide>
                <div class="card card-xl-stretch mb-xl-8">
                    <div class="card">
                        <img src="{{item.image}}" alt="">
                        <div class="carousel-text">
                            {{item.text}}
                        </div>
                      </div>
                </div>
            </ng-template>

            
        
    </ng-template>  

   
    
  </owl-carousel-o>
