<div class="card card-flush py-4">
    <div class="card-body p-0">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12"  >
                <span class="fw-bolder fs-3 text-gray-800 mb-8">{{packageDetail?.name}}</span>
               
                       <div class="separator separator-dashed mt-5 mb-4"></div>
                
            </div>
            
            
            
            <div class="table-responsive" *ngIf="partEquivalentList.length > 0">
                <table class="table">
                      
                    <thead>
                        <tr class="border-bottom fs-7 fw-bolder text-muted text-uppercase">
                            <th class="min-w-175px pb-9">Parça</th>
                            <th class="min-w-150px pb-9 d-none">
                                Yetkili Orjinal 
                            </th>
                            <th class="min-w-150px pb-9"> 
                                <input type="radio" class="radio radio-success form-check-input"  name="selectAll" (click)="selectAll($event)" value="original">
                                Orjinal
                                <i
                                class="fas fa-exclamation-circle ms-2 fs-4 text-primary"
                                ngbTooltip="Yetkili servis de kullanılan parçaların aynısı logolu üretim veya markalı üretim"
                              ></i>  
                            </th>
                            <th class="min-w-100px pe-lg-6 pb-9  d-none" >
                                <input type="radio" class="radio radio-success form-check-input"  (click)="selectAll($event)" name="selectAll" value="without_logo">
                                Logosuz Orjinal 
                                <i
                                class="fas fa-exclamation-circle ms-2 fs-4 text-primary"
                                ngbTooltip="Firmanın üretmiş olduğu aynı Parça üzerinde logo olmayan"
                              ></i>   
                            </th>
            
                            <th class="min-w-80px pb-9 "> 
                                <input type="radio" class="radio radio-success form-check-input"  name="selectAll" (click)="selectAll($event)" value="equivalent">
                                Eşdeğer
                                <i
                                                            class="fas fa-exclamation-circle ms-2 fs-4 text-primary"
                                                            container="body"
                                                            ngbTooltip="Farklı firmaların ürettiği OEM onaylı parçalar"
                                                          ></i>  
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="fw-bolder text-gray-700 fs-5 "  
                        *ngFor="let item of partEquivalentList">
                        <td class="d-flex align-items-center pt-5">
                            <div class="d-flex justify-content-start flex-column">
                            <div class="text-dark fw-bolder text-hover-primary fs-6"> {{item.selectedGosPart.name}}</div>
                           
                            <span class="text-muted fw-bold d-block fs-7"> {{item?.part_unit}} {{item.part_unit_type_name}} </span>
                        </div>
                        </td>
                        <td class="pt-5 px-0 text-left d-none">
                            
                                <span class="ps-3">
                                    {{item.offical_original.totalPriceCurrency | number:'1.0-2'}} {{item.offical_original.price_unit}} 
                                </span>
            
                        </td>
                        <td class="pt-5 px-0 text-left">
                            <input 
                                *ngIf="item.original"
                                class="form-check-input" 
                               type="radio" name="part_{{item.selectedGosPart.id}}"
                               (click)="addPiece(item.part_id,item.selectedGosPart.id,item.original.id,'original')"
                               
                                id="original_{{item.original.id}}">
                                <span class="ps-3">
                             {{item.original?.totalPriceCurrency | number:'1.0-2'}} {{item.original?.price_unit}} 
                                </span>
                        </td>    
                           
                        <td class="pt-5 text-left d-none">
                            <input 
                            *ngIf="item.without_logo"
                            class="form-check-input" 
                            type="radio" name="part_{{item.selectedGosPart.id}}"
                            (click)="addPiece(item.part_id,item.selectedGosPart.id,item.without_logo.id,'without_logo')"
                            id="without_logo_{{item.offical_original.id}}">
                            <span class="ps-3">
                                {{item.without_logo?.totalPriceCurrency | number:'1.0-2'}} {{item.without_logo?.price_unit}} 
                            </span>
                        </td>
                     
                        <td class="pt-5 px-0 text-left">
                            
                            <input class="form-check-input" 
                            *ngIf="item.equivalent"
                             type="radio" name="part_{{item.selectedGosPart.id}}"
                             (click)="addPiece(item.part_id,item.selectedGosPart.id,item.equivalent.id,'equivalent')"
                            id="equivalent_{{item.offical_original.id}}">
                                <span class="ps-3">
                                 {{item.equivalent?.totalPriceCurrency | number:'1.0-2'}} {{item.equivalent?.price_unit}}  
                                </span>
                        </td>
                            
                          
                         
                        </tr>
                       
                    
                        
                    </tbody>
                    <tfoot>
                        <tr style="background-color: #015029 !important;
                                    color: #fff;
                                    font-weight: bold;">
                            <td style="padding-left: 10px;">Toplam</td>
                            <td class="d-none">{{totalsByTypes.yetkili_orjinal.toFixed(2)}}₺</td>
                            <td  style="padding-left: 30px;" >{{totalsByTypes.orjinal.toFixed(2)}}₺</td>
                            <td class="text-center d-none">{{totalsByTypes.logosuz.toFixed(2)}}₺</td>
                            <td style="padding-left: 30px;">{{totalsByTypes.es_deger.toFixed(2)}}₺</td>
                        </tr>
                        <tr class="border fs-6 fw-bolder text-muted text-uppercase" >
                            <td colspan="2" class="text-end">
                                <span class="text-dark fw-bolder d-block">Toplam</span>
                                </td>
                            <td class="text-dark fw-bolder text-hover-primary fs-6">{{(subTotal+difference).toFixed(2)}} {{activeCurrency?.code}}</td>
                        </tr>
                        <tr class="border fs-6 fw-bolder text-muted text-uppercase " >
                            <td colspan="2" class="text-end">
                                <span class="text-dark fw-bolder d-block">Gos İşçilik Hizmet Ücreti</span>
                                </td>
                            <td class="text-dark fw-bolder text-hover-primary fs-6" *ngIf="selectedParts.length == partEquivalentList.length">{{gosMaintenancePrice}} {{activeCurrency?.code}}</td>
                            <td class="text-dark fw-bolder text-hover-primary fs-6" *ngIf="selectedParts.length < partEquivalentList.length">0 TL</td>
                        </tr>
                        <tr class="border fs-6 fw-bolder text-muted text-uppercase d-none" >
                            <td colspan="2" class="text-end">
                                <span class="text-dark fw-bolder d-block">Ara Toplam</span>
                                </td>
                            <td class="text-dark fw-bolder text-hover-primary fs-6">{{subTotal}} {{activeCurrency?.code}}</td>
                        </tr>
                       
                        <tr class="border fs-6 fw-bolder text-muted text-uppercase d-none" >
                            <td colspan="2" class="text-end">
                                <span class="text-dark fw-bolder d-block">Marka Hizmet Ücreti</span>
                                </td>
                            <td class="text-dark fw-bolder text-hover-primary fs-6">{{brandMaintenancePriceNoVat}} {{activeCurrency?.code}}</td>
                        </tr>
                        <tr class="border fs-6 fw-bolder text-muted text-uppercase d-none" >
                            <td colspan="2" class="text-end">
                                <span class="text-dark fw-bolder d-block">Kdv</span>
                                </td>
                            <td class="text-dark fw-bolder text-hover-primary fs-6">{{difference}} {{activeCurrency?.code}}</td>
                        </tr>
                        <tr class="border border-bottom fs-6 fw-bolder text-muted text-uppercase " >
                            <td colspan="2" class="text-end">
                                <span class="text-dark fw-bolder d-block">Genel Toplam</span>
                                </td>
                            <td class="text-dark fw-bolder text-hover-primary fs-6">{{grandTotal}} {{activeCurrency?.code}}</td>
                        </tr>
                        <tr class="border border-bottom fs-6 fw-bolder text-muted text-uppercase d-none" >
                            <td colspan="2" class="text-end">
                                <span class="text-dark fw-bolder d-block">Toplam Tasarruf</span>
                                </td>
                            <td class="text-dark fw-bolder text-hover-primary fs-6">{{savingTotal.toFixed(2)}} {{activeCurrency?.code}}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            
            
        </div>
        
    </div>
</div>
