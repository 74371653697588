import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-email-validator',
  templateUrl: './email-validator.component.html',
  styles: [
  ]
})
export class EmailValidatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

 
}
