<a href="https://api.whatsapp.com/send?phone=908504806655" class="whatsapp" target="_blank" style="display: none;">
	<img style="width:60px;" src="https://demobul.net/images/whatsapp.png" alt="whatsapp">
</a>
<style>
  

    
</style>
<div
    class="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed">
    <div class="page d-flex flex-row flex-column-fluid">

        <div class="wrapper d-flex flex-column flex-row-fluid">

            <!-- NAVBAR -->
            <app-web-navbar *ngIf="router.url != '/app'"></app-web-navbar>

            <div class="content d-flex flex-column flex-column-fluid" [ngClass]="{'fix-margin-top': router.url == '/app'}">

                <app-web-toolbar *ngIf="router.url != '/teklif' && router.url != '/app'"></app-web-toolbar>

                <div class="post d-flex flex-column-fluid">
                    <div class="pagekin" *ngIf="step == 1">
                        <a href="{{leftBanner.url}}" *ngIf="leftBanner && leftBanner.url != null && leftBanner.url != ''" target="_blank">
                            <img src="{{leftBanner.image}}" alt="{{leftBanner.title}}">
                        </a>
                        <img *ngIf="leftBanner && ( leftBanner.url == null || leftBanner.url == '' ) " src="{{leftBanner.image}}" alt="{{leftBanner.title}}">
                    </div>
                    <div class="container-xxl" style="padding-left:0px; padding-right:0px; background-color:#f5f8fa">
              
                        <app-appointment-v3 
                            *ngIf="router.url == '/teklif'"
                            (stepEmitter)="setStep($event)"
                            ></app-appointment-v3>
                        <!-- ROUTER -->
                        <router-outlet  *ngIf="router.url != '/teklif'"></router-outlet>

                    </div>
                    <div class="pagekin" *ngIf="step == 1">
                        <a href="{{rightBanner.url}}" *ngIf="rightBanner && rightBanner.url != null && rightBanner.url != ''" target="_blank">
                            <img src="{{rightBanner.image}}" alt="{{rightBanner.title}}">
                        </a>
                        <img *ngIf="rightBanner && ( rightBanner.url == null || rightBanner.url == '' ) " src="{{rightBanner.image}}" alt="{{rightBanner.title}}">
                    </div>
                </div>
            </div>

            <!-- FOOTER -->
            <app-web-footer *ngIf="router.url != '/app'"></app-web-footer>

        </div>
    </div>
</div>