import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';
import { User } from './user.graphql';
import { VehicleBrand } from './vehicle-brand.graphql';
import { VehicleModelType } from './vehicle-model-type.graphql';
import { VehicleModelYear } from './vehicle-model-year.graphql';
import { VehicleModel } from './vehicle-model.graphql';
import { WaitingAppointmentNotes } from './waiting_appointment_notes.graphql';

export interface WaitingAppointments {
  id: bigint;
  name: String;
  surname: String;
  email: String;
  phone: Number;
  vehicle_brand_id: Number;
  vehicle_model_id: Number;
  vehicle_model_type_id: Number;
  vehicle_year_id: Number;
  returned: boolean;
  created_at?: Date;
  updated_at?: Date;
  vehicle_brands: VehicleBrand;
  vehicle_model: VehicleModel;
  vehicle_model_years: VehicleModelYear,
  vehicle_model_types: VehicleModelType;
  users: User
  waiting_appointment_notes: WaitingAppointmentNotes[],
  vehicle_km: String;
  
}

interface ResponseArray {
  waiting_appointments: WaitingAppointments[];
}

interface ResponseSingle {
    waiting_appointments_by_pk: WaitingAppointments
}


@Injectable()
export class WAITING_APPOINTMENTS_ALL extends Query<ResponseArray> {
  override document = gql`
    query WAITING_APPOINTMENTS_ALL($where: waiting_appointments_bool_exp){
        waiting_appointments(where:$where) {
        id
        name
        surname
        email
        phone
        vehicle_brand_id
        vehicle_model_id
        vehicle_year_id
        vehicle_model_type_id
        returned
        created_at
        updated_at
        vehicle_km
      }
    }
  `;
}

@Injectable()
export class WAITING_APPOINTMENTS_ID extends Query<ResponseSingle> {
  override document = gql`
    query WAITING_APPOINTMENTS_ID($id: bigint!){
        waiting_appointments_by_pk(id: $id) {
            id
            name
            surname
            email
            phone
            vehicle_brand_id
            vehicle_model_id
            vehicle_year_id
            vehicle_model_type_id
            vehicle_km
            returned
            created_at
            updated_at
            vehicle_brands{
                id
                name
            }
            vehicle_model{
                id
                name
            }
            vehicle_model_years{
                id
                year{
                    id
                    name
                }
               
            }
            vehicle_model_types{
                id
                name
            }
            users{
                id
                name
            }
            waiting_appointment_notes{
              id
              user_id
              waiting_appointment_id
              note
              created_at
              users{
                name
                surname
              }
              
            }
       
      }
    }
  `;
}

@Injectable()
export class WAITING_APPOINTMENTS_CREATE extends Mutation<any> {
  override document = gql`
    mutation WAITING_APPOINTMENTS_CREATE(
        $name: String!, 
        $surname: String!, 
        $email: String!, 
        $phone: numeric!, 
        $vehicle_brand_id: bigint!, 
        $vehicle_model_id: bigint!, 
        $vehicle_year_id: bigint!, 
        $vehicle_model_type_id: bigint!, 
        $returned: Boolean!, 
        $vehicle_km: String
       
    ) {
        insert_waiting_appointments(objects: {
            name: $name, 
            surname: $surname, 
            email: $email, 
            phone: $phone, 
            vehicle_brand_id: $vehicle_brand_id, 
            vehicle_model_id: $vehicle_model_id, 
            vehicle_year_id: $vehicle_year_id, 
            vehicle_model_type_id: $vehicle_model_type_id, 
            returned: $returned,
            vehicle_km: $vehicle_km
        }) {
        affected_rows
        returning {
          id
          name
        }
      }
    }
    `;
}

@Injectable()
export class WAITING_APPOINTMENTS_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
    mutation WAITING_APPOINTMENTS_UPDATE($id:bigint!,  $returned: Boolean!) {
      update_waiting_appointments(where: {id: {_eq: $id}}, _set: {
        returned: $returned
        }) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class WAITING_APPOINTMENTS_DELETE extends Mutation<any> {
  override document = gql`
    mutation WAITING_APPOINTMENTS_DELETE($id: bigint!) {
      delete_waiting_appointments(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class WAITING_APPOINTMENTS_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query WAITING_APPOINTMENTS_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: waiting_appointments_bool_exp){
        waiting_appointments(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
            id
            name
            surname
            email
            phone
            vehicle_brand_id
            vehicle_model_id
            vehicle_year_id
            vehicle_model_type_id
            vehicle_km
            returned
            created_at
            updated_at
            vehicle_brands{
                id
                name
            }
            vehicle_model{
                id
                name
            }
            vehicle_model_years{
                id
                year{
                    id
                    name
                }
               
            }
            vehicle_model_types{
                id
                name
            }
            users{
                id
                name
            }
            
      }
    }
  `;
}

@Injectable()
export class WAITING_APPOINTMENTS_COUNT extends Query<any> {
  override document = gql`
    query WAITING_APPOINTMENTS_COUNT($where: waiting_appointments_bool_exp){
      waiting_appointments_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}