

<div class="d-flex justify-content-between  form-container" style="padding-top: 5px;">
    <div class="form-content-container">
        <form [formGroup]="userForm">
            <div class="tab-input-container">
                <app-shared-phone-mask [isValid]="userForm.get('gsm')?.valid" [phoneNumber]="userForm.value.gsm" (phoneEmitter)="setUserForm('gsm',$event)"></app-shared-phone-mask>
            </div>
            <div class="tab-input-container">
                <input 
                    #userName 
                    type="text" 
                    class="form-control tab-input ng-touched ng-pristine ng-dirty" 
                    placeholder="Ad"
                    name="name"
                    formControlName="firstname"
                    autocomplete="off" 
                    [value]="userForm.value.name"
                    (keydown.enter)="focusNext(userSurnameInput)"
                    (change)="setUserForm('firstname',$event)"
                    >
            </div>

            <div class="tab-input-container">
                <input 
                    #userSurname
                    type="text" 
                    class="form-control tab-input ng-touched ng-pristine ng-dirty" 
                    placeholder="Soyad"
                    name="soyad"
                    formControlName="lastname"
                    autocomplete="off" 
                    [value]="userForm.value.lastname"
                    (keydown.enter)="focusNext(userEmailInput)"
                    (change)="setUserForm('lastname',$event)"
                    >
            </div>

            <div class="tab-input-container">
                <input 
                    #userEmail
                    type="email" 
                    class="form-control tab-input ng-touched ng-pristine ng-dirty" 
                    placeholder="Email"
                    name="email"
                    formControlName="email"
                    autocomplete="off"
                    [value]="userForm.value.email"
                    (keydown.enter)="focusNext(userJobInput)"
                    (change)="setUserForm('email',$event)"
                    >
            </div>

           

            <div class="tab-input-container">
                <input 
                    #userJob
                    type="text" 
                    class="form-control tab-input" 
                    placeholder="Mesleğiniz"
                    name="Mesleğiniz" 
                    formControlName="job"
                    autocomplete="off"
                    [value]="userForm.value.job"
                    (keydown.enter)="gotoBrand(true)"
                    (change)="setUserForm('job',$event)">
            </div>

          
           
        </form>
    </div>
    <div class="form-content-container">
        <form [formGroup]="vehicleForm">
        <div class="tab-input-container">
            <app-shared-selectwo
               
                [name] = "brandName"
                [lists] = "vehicleBrandList"
                [id]="'vehicleBrands'"
                [setItem]="vehicleFormInput.value.brand_id"
                (selectedItem)="selectedTwo('brand',$event)"
                [showAll]="false"
                [addRequiredValidator]="true"
                [isValid]="vehicleForm.get('brand_id')?.valid"
                [isClicked]="brandIsClicked"
                (clickOutside)="gotoBrand(false)"
                >
                
            </app-shared-selectwo> 
        </div>

        <div class="tab-input-container">
            
            <app-shared-selectwo
           
            [name] = "modelName"
            [lists] = "vehicleModelList"
            [id]="'vehicleModels'"
            [setItem]="vehicleFormInput.value.model_id"
            (selectedItem)="selectedTwo('model',$event)"
            [showAll]="false"
            [addRequiredValidator]="true"
                [isValid]="vehicleForm.get('model_id')?.valid"
                [isClicked]="modelIsClicked"
        >
        </app-shared-selectwo>
        </div>

        <div class="tab-input-container">
            <app-shared-selectwo 
                [lists]="vehicleModelYearList" 
                (selectedItem)="selectedTwo('year',$event)" 
                [name]="selectedYear" 
                [id]="'vehicle-year'"
                [setItem]="vehicleFormInput.value.model_year_id"
                [fieldName]="'name'"
                [showAll]="false"
                [multipleReturn]="true"
                [returnFields]="['id','name']"
                [addRequiredValidator]="true"
                [isValid]="vehicleForm.get('model_year_id')?.valid"
                [isClicked]="yearIsClicked"
                >
            </app-shared-selectwo>
        </div>

    
        <div class="tab-input-container" *ngIf="IsPackageHave && vehicleForm.value.model_year_id">
            <app-shared-selectwo
                [name] = "modelTypeName"
                [lists] = "vehicleModelTypeList"
                [id]="'vehicleModelTypes'"
                [setItem]="vehicleFormInput.value.type_id"
                [showAll]="false"
                (selectedItem)="selectedTwo('vehicle_model_types',$event)"
                [addRequiredValidator]="true"
                [isValid]="vehicleForm.get('type_id')?.valid"
                [isClicked]="modelTypeIsClicked"
                >
            </app-shared-selectwo> 
        </div>
        
        <div class="tab-input-container"  *ngIf="!IsPackageHave  && vehicleForm.value.year_id">
            <input
                style="text-transform: uppercase !important;"
                class="form-control tab-input ng-touched" 
                type="text"
                placeholder="Şasi No: Ruhasatta bulunan 17 karakter "
                formControlName="chasses_no"
                name="chasses_no"
                maxlength="17"
                minlength="17"
                [value]="vehicleForm.value.chasses_no"
                pattern="[a-zA-Z0-9]{17}"
                (keyup)="rejectSpecialCharacters($event)"
                [ngClass]="{'ng-invalid': vehicleForm.get('chasses_no').valid}"
                >
        </div>

        <div class="tab-input-container" *ngIf="!IsPackageHave && vehicleForm.value.chasses_no">
        
            <input
                class="form-control tab-input ng-touched" 
                type="text"
                pattern="\d*"
                placeholder="KWH: Ruhsat sağ alt kısımda yazar"
                formControlName="kwh"
                name="kwh"
                [value]="vehicleForm.value?.kwh"
                minlength="2"
                maxlength="4"
                [ngClass]="{'ng-invalid': vehicleForm.get('kwh').valid}"
                >
        </div>

        <div class="tab-input-container" *ngIf="!IsPackageHave && vehicleForm.value.kwh">
            <input
                class="form-control tab-input ng-touched" 
                type="text"
                pattern="\d*"
                placeholder="Beygir: Beyir gücü KW * 1.36"
                formControlName="beygir"
                name="beygir"
                minlength="2"
                maxlength="4"
                [value]="vehicleForm.value.beygir"
                [ngClass]="{'ng-invalid': vehicleForm.get('beygir').valid}" >
        </div>

        

        <div class="tab-input-container">
            <app-shared-kms-selector 
                *ngIf="vehicleKmsList && (vehicleForm.value.kwh || vehicleForm.value.type_id)"
                [kms]="vehicleKmsList"
                [selectedKmId]="vehicleFormInput.value.km_id"
                [customerKm]="vehicleInfo.customerKm"
                (kmInfo)="selectedTwo('km',$event)"
                [addRequiredValidator]="true"
                [isValid]="vehicleForm.get('last_km')?.valid"
                >
            </app-shared-kms-selector>
        </div>
       
    </form>
    </div>
    <div class="form-content-container">
        <div class="tab-input-container" class="tab-brand-logo-container">
            <div class="tab-brand-logo">
                <img *ngIf="selectedVehicleBrandInfo && selectedVehicleBrandInfo.logo_url != null && selectedVehicleBrandInfo.logo_url != ''"  src="https://cdn.destechhasar.com/garantili-oto-servis/{{selectedVehicleBrandInfo.logo_url}}" alt="">
                <img  *ngIf="selectedVehicleBrandInfo?.logo_url === null ||selectedVehicleBrandInfo?.logo_url == ''"   src="http://cdn.destechhasar.com/garantili-oto-servis/w:169/h:143/garantili-oto-servis-logo.png" alt="" />
            </div>
        </div>

        <div class="tab-input-container d-flex justify-content-between" *ngIf="((IsPackageHave && vehicleForm.value.type_id) || (!IsPackageHave && vehicleForm.value.last_km))">     
                <select 
                    name="" 
                    id="" 
                    class="form-control gos-select ng-dirty ng-pristine ng-touched" 
                    (change)="selectedTwo('gear',$event)"
                    [ngClass]="{'ng-valid': vehicleForm.get('gear_type_id').valid,
                                'ng-invalid': vehicleForm.get('gear_type_id').invalid} "
                    
                    >
                    <option value="" [selected]="vehicleFormInput.value.gear_type_id ==  null">Vites Tipi Seçiniz</option>
                    <option value="{{item.id}}" [selected]="vehicleFormInput.value.gear_type_id == item.id" *ngFor="let item of vehicleGearList">{{item.name}}</option>
                </select>
           
        </div>

        <div class="tab-input-container d-flex justify-content-between" *ngIf="((IsPackageHave && vehicleForm.value.type_id) || (!IsPackageHave && vehicleForm.value.last_km))">
            <select 
                name=""
                id=""  
                class="form-control gos-select ng-dirty ng-pristine ng-touched" 
                (change)="selectedTwo('fuel',$event)"
                [ngClass]="{'ng-valid': vehicleForm.get('fuel_type_id').valid,
                            'ng-invalid': vehicleForm.get('fuel_type_id').invalid} "
                >
                <option value="" [selected]="vehicleFormInput.value.fuel_type_id ==  null">Yakıt Tipi Seçiniz</option>
                <option value="{{item.id}}" [selected]="vehicleFormInput.value.fuel_type_id == item.id" *ngFor="let item of vehicleFuelList">{{item.name}}</option>                  
            </select>
        </div>
        <div class="tab-input-container d-flex justify-content-between" *ngIf="((IsPackageHave && vehicleForm.value.type_id) || (!IsPackageHave && vehicleForm.value.last_km))">
            <textarea name="" style="resize: none;" class="form-control" placeholder="Özel isteğiniz var mı? Biz bunu servisimize iletelim. " (change)="selectedTwo('note',$event)"  id="" cols="30" rows="3"></textarea>

        </div>
       

    </div>
   
</div>

<div class="d-none justify-content-between py-5 form-container" style="padding-top: 5px !important;">
    <div class="form-content-container d-flex justify-content-between" style="width: 100% !important;">
        <div class="captcha-container">
            <p class="captcha-text" [ngClass]="{'d-none': vehicleInfo.customerKm == 0}">Gizliliğinizi ciddiye alıyoruz. Garantili Satış Sonrası, size ilgili içerik, ürün güncellemeleri ve / veya haberler göndermek için bilgilerinizi kullanabilir</p>
        </div>
        <div class="captcha-container recaptcha-container" style=" margin-right: 5px; margin-bottom: 5px;"   [ngClass]="{'d-none': vehicleInfo.customerKm == 0}" >
            <div id="recaptcha-container" class="g-recaptcha" data-sitekey="6LewYBsqAAAAAEhU96qN_JunjX-youccbB_ilqk0"></div>
        </div>
        
    </div>
</div>




<div class="d-flex justify-content-center pb-5" *ngIf="packageList">
    <div class="form-content-container form-check w-100 package-selector-container">
       
        <div class="package-select-items" *ngFor="let item of packageList">
            <label for="package_radio_{{item.id}}" class="package-label">
                <div class="package-selector-col radio-inline">
                    <input [checked]="selectedPackage?.id == item.id || selectedPackage?.packageInfo?.id == item.id" type="radio" name="package" id="package_radio_{{item.id}}" value="{{item.id}}" (click)="selectedTwo('package',item)">
                </div>
                <div class="package-selector-col  radio-inline">
                    <p>{{item.name}}</p>
                </div>
            </label>
       </div>

    </div>
</div>



<div class="d-flex align-items-center rounded py-5 px-5 bg-light-primary" *ngIf="!IsPackageHave &&  vehicleForm.value.year_id">
    <span class="svg-icon svg-icon-3x svg-icon-primary me-5">
        <i style="font-size: 25px; color: #ff0000;" class="fa fa-exclamation-triangle" aria-hidden="true"></i>

    </span>
    <div class="text-gray-700 fw-bold fs-6">
        Seçtiğiniz Aracı veya Hizmeti henüz kataloğumuza eklemedik. Aracınız hakkındaki ilgi alanları lütfen doldurunuz. Size en kısa sürede teklif sunacağız.
    </div>
</div>