import { Component, Input, OnInit } from '@angular/core';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { Order } from 'src/app/graphql/order.graphql';
import { numberFormatStr } from 'src/app/helpers/slugify';
import { PdfService } from 'src/app/services/pdf.service';

@Component({
  selector: 'app-shared-proforma-pdf-generator-v2',
  templateUrl: './proforma-pdf-generator-v2.component.html',
  styles: [
  ]
})
export class ProformaPdfGeneratorV2Component implements OnInit {



  @Input() orderId: Number | undefined;
  @Input() serviceInfo: any;
  @Input() gosInfo: any;
  @Input() customerInfo: any;
  @Input() vehicleInfo: any;
  @Input() packageParts: any;
  @Input() priceInfo: any;
  @Input() invoice: any;
  @Input() hidePartNumbers:boolean = false;
  @Input() orderInfo: Order | undefined;


  public docDefinition:TDocumentDefinitions | undefined; 
  public content:any;


  private proformaInfo:any;

  constructor(
    private pdfService: PdfService,
  ) { }

  ngOnInit(): void {

  

  }

  ngOnChanges(){
   
    
    this.createProforma();
  }

  async createProforma(){
    this.content = {}


    await this.createHeader();
    await this.createContentLogo();
    await this.createCustomerInfo();
    await this.createProducts();
    await this.createProductTotal();
    await this.createDEscription();
    await this.setInvoice();
  }

  async createHeader(){
 
        this.content = {
          content:[{
            columns: [
              [ {
                text: "",//"Kredi Kartı İle Ödeme",
                fontSize: 10,
                margin: [30, 15, 0, 10]
              }],
              [
                {
                  text: "",//"Periyodik Bakım",
                  bold: true,
                  alignment: 'center',
                  fontSize: 20,
                  margin: [0, 10, 0, 10]
                }
              ],
              [ {
                text: "8/15/23",
                fontSize: 10,
                alignment: 'right',
                margin: [0, 15, 0, 0]
              }],
              
            ]
          }],
          images: {
            invoice: 'https://cdn.destechhasar.com/garantili-oto-servis/invoice/invoice_logo.png',
            invoice_logo: 'https://cdn.destechhasar.com/garantili-oto-servis/invoice/invoice_logo.png'
          },
          
          defaultStyle: {
            fontSize: 9
          },
          styles: {
            titleStyle: {
              fontSize: 8
            },
            tableStyle: {
              fontSize: 8,
              alignment: 'center',
              margin: [0, 10, 0, 10],
              widths: ['30%', '20%', '17.5%', '17.5%', '17.5%']
            },
            customerTableStyle: {
              fontSize: 8,
              alignment: 'center',
              margin: [0, 10, 0, 10],
              widths: ['20%', '30%','20%','30%']
            },
            vehicleTableStyle: {
              fontSize: 8,
              alignment: 'right',
              margin: [300, 0, 0, 10],
              widths: ['*', '50']
            },
            productTableStyle: {
              fontSize: 8,
              alignment: 'center',
              margin: [0, 10, 0, 10],
              widths: ['auto', 'auto','auto','auto','auto','auto','auto']
            },
            productTotalTableStyle: {
              fontSize: 8,
              alignment: 'center',
              margin: [0, 10, 0, 10],
              widths: ['auto', 'auto','auto','auto']
            },
            descriptionTableStyle: {
              fontSize: 8,
              alignment: 'center',
              margin: [0, 10, 0, 10],
              widths: ['auto', 'auto']
            },
          }
        }
    
      }

  async createContentLogo(){
    let gosInfo = [
      this.gosInfo?.title+"\n",
      this.gosInfo.company_tel_no+"\n",
      this.gosInfo.company_email_address+"\n",
      this.gosInfo.City.name+"\n",
      this.gosInfo.district.name+"\n",
      this.gosInfo.address,
      ];

      let customerInfo = [
        this.serviceInfo.name+"\n",
        this.serviceInfo.company_gsm.code+"\n",
        this.serviceInfo.company_email.name+"\n",
        this.serviceInfo.City.name+"\n",
        this.serviceInfo.district.name+"\n",
        this.serviceInfo.address+"\n"
      ]

      this.content.content.push([{
        columns: [
          [ {
            text: gosInfo,
            fontSize: 10,
            margin: [0, 15, 0, 0],
            color: "#96bc33",
            bold:true
          }],
          [
            {
              image: 'invoice',
              width: 100,
              alignment: 'center'
            }
          ],
          [ {
            text: customerInfo,
            fontSize: 10,
            alignment: 'right',
            margin: [0, 15, 0, 0],
            color: "#96bc33",
            bold:true
          }],
          
        ]
      }
      ])

    
    
  }

  async createCustomerInfo(){
    let totalContinue = {
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
      
        widths: [ '20%', '30%', '20%', '30%' ],

        body: [
          [
            {
              colSpan:2,
              text:'Müşteri Bilgileri',
              border: [true, true, true, true],
              bold: true,
              alignment: 'center',
              fontSize: 10,
              color: '#015029'
            },
            {
            
              text:'',
              border: [true, true, true, true],
            },
            {
              colSpan:2,
              text:'Araç Bilgileri',
              border: [true, true, true, true],
              bold: true,
              alignment: 'center',
              fontSize: 10,
              color: '#015029'
            },
            {
            
              text:'',
              border: [true, true, true, true],
            }
          ],
          [ 
            {
              text: 'Müşteri Adı/Ünvanı',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: this.customerInfo?.customer_name + " " + this.customerInfo?.customer_surname
            }, 
            {
              text: 'Şasi',
              color: "#96bc33",
              bold:true
            },
            {
              text: this.vehicleInfo?.chassis_no
            } 
          ],
          [ 
            {
              text: 'Tel No',
              color: "#96bc33",
              bold:true
            }, 
            {
              text:this.customerInfo?.tel_number
            }, 
            {
              text: 'Plaka',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: this.vehicleInfo?.plate
            }  
          ],
          [ 
            {
              text: 'Mail Adresi',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: this.customerInfo?.customer_email
            }, 
            {
              text: 'Marka Model',
              color: "#96bc33",
              bold:true
            },
            {
              text: this.vehicleInfo?.vehicle_brand?.name + ' / ' + this.vehicleInfo?.vehicle_model?.name
            }
          ],
          [ 
            {
              text: 'İl',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: this.customerInfo?.city?.name
            }, 
            {
              text: 'Versiyon',
              color: "#96bc33",
              bold:true
            }, 
            {
              text:this.vehicleInfo?.vehicle_model_types?.name
            } 
          ],
          [ 
            {
              text: 'İlçe',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: this.customerInfo?.district?.name
            }, {
              text: 'Yıl',
              color: "#96bc33",
              bold:true
            }, {
              text: '2021'
            } ],
          [ 
            {
              text: 'Adres',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: this.customerInfo?.address
            }, 
            {
              text: 'Yakıt',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: this.vehicleInfo?.vehicle_fuel_type?.name
            } 
          ],
          
          [ 
            {
              text: 'TCKN',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: this.customerInfo?.identity_no
            }, 
            {
              text: 'Vites',
              color: "#96bc33",
              bold:true
            }, 
            {
              text: this.vehicleInfo?.vehicle_gear_type?.name
            } ],
      
        ]
      },
      style: 'customerTableStyle',
      margin: [0, 30, 0, 0],
      alignment: 'left',
    
    }


    
    this.content.content.push(totalContinue);
    
  }

  async createProducts(){

    let table = {
      headerRows: 1,
      widths: ['10%','40%','10%','15%','5%','10%','10%'],
      margin:[0,10,0,0],
      body: [
        [
          {
            colSpan: 7,
            text:'İşlemler',
            border: [true, true, true, true],
            bold: true,
            alignment: 'center',
            fontSize: 10,
            color: '#015029'
          },
          {
          
            text:'',
            border: [true, true, true, true]
          },
          {
          
            text:'',
            border: [true, true, true, true],
          },
          {
          
            text:'',
            border: [true, true, true, true],
          },
          {
          
            text:'',
            border: [true, true, true, true],
          },
          {
          
            text:'',
            border: [true, true, true, true],
          },
          {
          
            text:'',
            border: [true, true, true, true],
          }
        ],
        [
          {
          
            text:'Referans No',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33",
          },
          {
            colSpan: 2,
            text:'Açıklama',
            border: [true, true, false, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33",
          },
          {
          
            text:'',
            border: [false, true, false, true],
          
          },
          {
          
            text:'YP. Tipi',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33",
          },
          {
          
            text:'Adet',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33",
          },
          {
          
            text:'Birim Fiyat',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33",
          },
          {
          
            text:'Toplam',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33"
          },
        ]
      ]
    }
    if(this.invoice?.invoice_parts && this.invoice.invoice_parts.length > 0){
      await this.invoice.invoice_parts.forEach(element => {
        table.body.push([
          {
          
            text: element.part_code,
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          {
            
            text:element.part_name,
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          {
            text:(element.part_unit_type_id == 3 ? 'İşçilik' : 'Parça') ,
            border: [true, true, false, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          {
          
            text:element.part_types.name,
            border: [true, true, true, true],
            bold: false,
          
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          {
          
            text: element.part_unit.toFixed(2),
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          {
          
            text:numberFormatStr(element.part_unit_price),
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          {
          
            text:numberFormatStr(element.part_no_vat_price),
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
        ]);
      });
    }

    

    this.content.content.push({table:table, style: 'productTableStyle', margin: [0, 30, 0, 0]});

  }  
 
  async createProductTotal(){

    let table = {
      headerRows: 1,
      widths: ['30%','25%','20%','25%'],
      margin:[300,10,0,0],
      
      body: [
        [
          {
          
            text:'',
            border: [true, true, true, true],
            bold: true,
            alignment: 'center',
            fontSize: 10,
            color: "#96bc33"
          },
          {
            text: 'Tutar',
            border: [true, true, true, true],
            bold: true,
            alignment: 'center',
            fontSize: 10,
            color: "#96bc33"
          },
          {
            text: 'İndirim',
            border: [true, true, true, true],
            bold: true,
            alignment: 'center',
            fontSize: 10,
            color: "#96bc33"
          },
          {
            text: 'Toplam',
            border: [true, true, true, true],
            bold: true,
            alignment: 'center',
            fontSize: 10,
            color: "#96bc33"
          }
        ],
        
      ]
    }

    let noLabourPrice = numberFormatStr(this.invoice.value_no_vat-this.invoice.labour_price).toString();

    table.body.push([
      {
        text: 'Yedek Parça',
        border: [true, true, true, true],
        bold: true,
        alignment: 'left',
        fontSize: 8,
        color: "#96bc33"
      },

      {
        text: noLabourPrice,
        border: [true, true, true, true],
        bold: false,
        alignment: 'left',
        fontSize: 8,
        color: "#000"
      },

      {
        text: '0',
        border: [true, true, true, true],
        bold: false,
        alignment: 'left',
        fontSize: 8,
        color: "#96bc33"
      },

      {
        text: noLabourPrice,
        border: [true, true, true, true],
        bold: false,
        alignment: 'left',
        fontSize: 8,
        color: "#000"
      },

    ],[
      {
        text: 'İşçilik',
        border: [true, true, true, true],
        bold: true,
        alignment: 'left',
        fontSize: 8,
        color: "#96bc33"
      },

      {
        text: numberFormatStr(this.invoice.labour_price),
        border: [true, true, true, true],
        bold: false,
        alignment: 'left',
        fontSize: 8,
        color: "#000"
      },

      {
        text: '0',
        border: [true, true, true, true],
        bold: false,
        alignment: 'left',
        fontSize: 8,
        color: "#000"
      },

      {
        text:  numberFormatStr(this.invoice.labour_price),
        border: [true, true, true, true],
        bold: false,
        alignment: 'left',
        fontSize: 8,
        color: "#000"
      },

    ],[
      {
        text: 'Kdv',
        border: [true, true, true, true],
        bold: true,
        alignment: 'left',
        fontSize: 8,
        color: "#96bc33"
      },

      {
        text:  numberFormatStr(this.invoice.vat_price),
        border: [true, true, true, true],
        bold: false,
        alignment: 'left',
        fontSize: 8,
        color: "#000"
      },

      {
        text: '0',
        border: [true, true, true, true],
        bold: false,
        alignment: 'left',
        fontSize: 8,
        color: "#000"
      },

      {
        text: numberFormatStr(this.invoice.vat_price),
        border: [true, true, true, true],
        bold: false,
        alignment: 'left',
        fontSize: 8,
        color: "#000"
      },

    ],[
      {
        text: 'Genel Toplam',
        border: [true, true, true, true],
        bold: true,
        alignment: 'left',
        fontSize: 8,
        color: "#96bc33"
      },

      {
        text: numberFormatStr(this.invoice.value_in_vat),
        border: [true, true, true, true],
        bold: false,
        alignment: 'left',
        fontSize: 8,
        color: "#000"
      },

      {
        text: '0',
        border: [true, true, true, true],
        bold: false,
        alignment: 'left',
        fontSize: 8,
        color: "#000"
      },

      {
        text: numberFormatStr(this.invoice.value_in_vat),
        border: [true, true, true, true],
        bold: false,
        alignment: 'left',
        fontSize: 8,
        color: "#000"
      },

    ])


    this.content.content.push({table:table, style: 'productTotalTableStyle', margin: [300, 30, 0, 0]});
  }
 
  async createDEscription(){
    let table = {
      headerRows: 1,
      widths: ['20%','80%'],
      margin:[0,50,0,0],
      
      body: [
        [
          {
            colSpan:2,
            text:'Açıklamalar',
            border: [true, true, true, true],
            bold: true,
            alignment: 'center',
            fontSize: 8,
            color: "#96bc33"
          },
          {
            text: '',
            border: [false, true, true, true],
            bold: false,
            alignment: 'center',
            fontSize: 8,
            color: "#000"
          },
          
        ],
        [
          {
            
            text:'Ödeme Tipi',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33"
          },
          {
            text: this.orderInfo?.payment_types.name,
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          
        ],
        [
          {
            
            text:'Randevu Tarihi',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33"
          },
          {
            text: this.orderInfo?.service_date,
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          
        ],
        [
          {
            
            text:'İş Türü',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33"
          },
          {
            text: this.orderInfo?.order_types.name,
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          
        ],

        [
          {
            
            text:'İş Türü Detayı',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33"
          },
          {
            text: this.orderInfo?.order_types.name,
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          
        ],

        [
          {
            
            text:'Açıklama',
            border: [true, true, true, true],
            bold: true,
            alignment: 'left',
            fontSize: 8,
            color: "#96bc33"
          },
          {
            text: '...',
            border: [true, true, true, true],
            bold: false,
            alignment: 'left',
            fontSize: 8,
            color: "#000"
          },
          
        ],
        
      ]
    }
    this.content.content.push({table:table, style: 'descriptionTableStyle', margin: [0, 10, 0, 0]});
    
  }

  async setInvoice(){

    this.docDefinition = this.content;
  }

  invoiceChange(event:any)
  {
    switch (event.target.value) {
      case "preview":
        if(this.docDefinition != undefined){
        this.pdfService.preview(this.docDefinition)
      }
        //this.pdfService.generator("preview", this.invoiceBody.nativeElement)
        break;
      case "download":
        if(this.docDefinition != undefined){
        this.pdfService.download(this.docDefinition)
        }
        break;
      case "print":
        if(this.docDefinition != undefined){
        this.pdfService.print(this.docDefinition)
        }
        break;
      default:
        break;
    }
  }


  toNumberFormat(number: any) {
    return numberFormatStr(number);
  }

  numberFormat(number: any) {
    return this.numberFormat(number);
  }
}
