import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {

  @Input() appTooltip: string | undefined; // Tooltip içeriği

  constructor(private el: ElementRef) {}

  // Fare üzerine geldiğinde tooltip'i görüntüle
  @HostListener('mouseenter') onMouseEnter() {
    this.showTooltip();
  }

  // Fareyi elementin üzerinden çıkardığınızda tooltip'i gizle
  @HostListener('mouseleave') onMouseLeave() {
    this.hideTooltip();
  }

  private showTooltip() {
    if(this.appTooltip){
      const tooltip = document.createElement('div');
      tooltip.className = 'custom-tooltip';
      tooltip.textContent = this.appTooltip;
      tooltip.style.position = 'absolute';
      tooltip.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
      tooltip.style.color = 'white';
      tooltip.style.padding = '4px 8px';
      tooltip.style.marginLeft = '25px';
      tooltip.style.borderRadius = '4px';
      tooltip.style.zIndex = '9999';
      tooltip.style.maxWidth = '250px';

      const rect = this.el.nativeElement.getBoundingClientRect();
      tooltip.style.top = (rect.top + window.scrollY - 30) + 'px';
      tooltip.style.left = (rect.left + window.scrollX) + 'px';

      document.body.appendChild(tooltip);
    }
  }

  private hideTooltip() {
    const tooltips = document.querySelectorAll('.custom-tooltip');
    tooltips.forEach(tooltip => tooltip.remove());
  }

}
