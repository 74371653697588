import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { debug } from 'console';
import { exit } from 'process';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { User } from '../graphql/user.graphql';
import { FireBaseEmailAuthReponse } from '../interfaces/firebase.interface';
import { Permission } from '../interfaces/permission.interface';


@Injectable()
export class AuthService {

  private baseUrl: string = environment.laravelApiEndpoint;

  public isLogin: boolean = false;
  public userRole: string = "anonymous";
  public userId: number = 1; // anonymous user id for users table
  public companyId: number = 1;
  public ipAddress: string = "127.0.0.1";
  public userEmail: string | undefined;
  public user: User = {} as User

  
  public currentUser = {
    name: "",
    surname: "",
    email: "",
    img: "",
    company_id: 0,
    identity_no: 0,
    sidebarShrink: false,
    darkMode: false,
    permission_profile_id: 0,
    is_admin: false,
    permission_profile:{
      id: 0,
      permission_profile_menus: {}
    }
  };

  constructor(
    private http: HttpClient
  ) { 
  }

  /// Firebase Email Authentication
  firebaseAuth(email: string, password: string, isLogin: boolean): Observable<FireBaseEmailAuthReponse> {
    const signInUrl = environment.firebaseRestSignInEndpoint;
    const signUpUrl = environment.firebaseRestSignUpEndpoint;
    const requestUrl = isLogin ? signInUrl : signUpUrl;
    const idpUrl = environment.firebaseIdpEndpoint;

    //this.setIPAddress(); - localhostta cors origin hatası alıyor
  
    return this.http.post<FireBaseEmailAuthReponse>(requestUrl, {
      email: email,
      password: password,
      returnSecureToken: true
    })
  }

  apiLogin(fireBaseEmailAuthReponse: FireBaseEmailAuthReponse): Observable<any> {
    return this.http.post<any>(this.baseUrl + "user/login", { ...fireBaseEmailAuthReponse, ipAddress: this.ipAddress }).pipe(map(response => {

     
      if (response.status) {
        localStorage.setItem('access_token', response.result.token);
        this.login();
      } else {
        if (fireBaseEmailAuthReponse.registered) {
          return this.apiRegister(fireBaseEmailAuthReponse, "name", "surname", "identity_no",null,null)
        }
      }
      return response;
    }))
  }

  /*apiRegister(fireBaseEmailAuthReponse: FireBaseEmailAuthReponse,login=true, name: String, surname: String): Observable<any> {
    return this.http.post<any>(this.baseUrl + "user/register", { 
      ...fireBaseEmailAuthReponse, 
      name: name,
      surname: surname,
      ipAddress: this.ipAddress }).pipe(map(response => {
      if (response.status) {
        if(login){
          localStorage.setItem('access_token', response.result.token);
          this.login();
        }
        
      }
      return response;
    }))
  }*/

  apiRegister(fireBaseEmailAuthReponse: FireBaseEmailAuthReponse, name: String, surname: String, identity_no: String,phone:String|null,job:String|null): Observable<any> {
    return this.http.post<any>(this.baseUrl + "user/register", {
      ...fireBaseEmailAuthReponse,
      name: name,
      surname: surname,
      identity_no: identity_no,
      phone: phone,
      job: job,
      ipAddress: this.ipAddress
    }).pipe(map(response => {
      if (response.status) {
        localStorage.setItem('access_token', response.result.token);
        this.login();
      }
      return response;
    }))
  }

  appointmentUserCreate(
    fireBaseEmailAuthReponse: FireBaseEmailAuthReponse, 
    customer_name: String, 
    customer_surname: String, 
    customer_email: String,
    customer_phone: String,
    customer_job: String,
    ): Observable<any> {
    return this.http.post<any>(this.baseUrl + "appointment/user-create", {
      ...fireBaseEmailAuthReponse,
      customer_name: customer_name,
      customer_surname: customer_surname,
      customer_email: customer_email,
      customer_phone: customer_phone,
      ip_address: this.ipAddress,
      customer_job: customer_job
    }).pipe(map(response => {
      if (response.status) {
        localStorage.setItem('access_token', response.result.token);
        this.login();
      }
      return response;
    }))
  }

  apiCreateUser(items:Object): Observable<any> {
    return this.http.post<any>(this.baseUrl + "user/create", items);
  }

  // token verify with laravel api
  verify(): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'user/verify', {
      token: this.parseToken(false)
    });
  }

  // logout
  logout(): void {
    localStorage.setItem("access_token", environment.anonymousToken)
    this.login()
  }

  // login
  login(): void {
    const token = this.parseToken(true)
    if (token) {
      this.isLogin = token['role_type'] === "anonymous" ? false : true
      this.userRole = token['role_type']
      this.companyId = token['company_id']
      this.userId = parseInt(token['https://hasura.io/jwt/claims']['x-hasura-user-id'])
    } else {
      this.logout()
    }
  }
  getUser():Observable<any> {
    return this.http.post(environment.hasuraHttpApiEndpoint, {
      query: `query GET_USER{users_by_pk(id:${this.userId}){
                        email
                        name
                        surname
                        company_id
                        identity_no
                        is_admin
                        permission_profile_id
                        permission_profile{
                          id
                          permission_profile_menus{
                            id
                            read
                            write
                            active
                            sidebar{
                              id
                              link
                              component
                            }
                          }
                        }
                       }
                      }`
    }, { headers: { authorization: `Bearer ${this.parseToken(false)}` } });
  }

  setUser(): Observable<any> {
    return this.http.post(environment.hasuraHttpApiEndpoint, {
      query: `query GET_USER{users_by_pk(id:${this.userId}){
                        email
                        name
                        surname
                        company_id
                        identity_no
                        is_admin
                        permission_profile_id
                        payment_type_roles{
                          payment_types{
                            id
                            name
                          }
                        }
                        permission_profile{
                          id
                          permission_profile_menus{
                            id
                            read
                            write
                            active
                            sidebar{
                              id
                              link
                              component
                            }
                          }
                        }
                       }
                      }`
    }, { headers: { authorization: `Bearer ${this.parseToken(false)}` } })
  }

  // set user ip address
  setIPAddress() {
    this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  parseToken(parse: boolean, jwtToken?: string) {
    let token = jwtToken ? jwtToken : localStorage.getItem('access_token')
    if (token) {
     
      if (!parse){
        return token;
      } 
      return JSON.parse(atob(token.split('.')[1]))
    } else {
      return false
    }
  }

  isTokenExpired() {
    const token = this.parseToken(true)
    if (token.exp) {
      return Math.floor((new Date).getTime() / 1000) >= token.exp
    }
    return false
  }

  permission(link: string, componentName: String): Permission {
    const perms: Permission = {} as Permission
    perms.write = false
    perms.read = false
    perms.active = false
    if (this.user.permission_profile && this.user.permission_profile.permission_profile_menus.length) {
      const find = this.user.permission_profile.permission_profile_menus.find(pm => pm.sidebar != null && pm.sidebar.component && pm.sidebar.component == componentName)

    
      if (find && find.active) {
        perms.read = find.read
        perms.write = find.write
        perms.active = true
      }
    }
    return perms
  }

   getUserInfo = () => {
    return  Promise.resolve(this.currentUser);
  }

}
