<div *ngIf="paymentType != 1" class="d-flex align-items-center rounded py-5 px-5 bg-light-primary">
    <span class="svg-icon svg-icon-3x svg-icon-primary me-5">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mh-50px"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect></svg>
    </span>
    <div class="text-gray-700 fw-bold fs-6">Bu işlem bir ön provizyondur ve randevu tarihinden 72 saat önceye kadar iptal hakkınız vardır.
    </div>
</div>

<div class="d-flex justify-content-between py-5 form-container">
    <div class="d-flex flex-column-fluid justify-content-center tab-header-container">
        <div class="tab-header">
            <div class="tab active" [ngClass]="{active: selectedTab == 'individual'}">
                <a (click)="changeTab('individual')">Bireysel Fatura</a>
            </div>
            <div class="tab" [ngClass]="{active: selectedTab == 'institutional'}">
                <a (click)="changeTab('institutional')">Kurumsal Fatura</a>
            </div>
        </div>
    </div>

   
</div>


<div class="d-flex justify-content-between odeme-form-container">
    <div class="odeme-item-container individual-container">
        <form [formGroup]="invoiceAddressForm">
            <div class="form-content-container">
        
                <div class="tab-input-container" *ngIf="selectedTab == 'individual'">
                    <input 
                        class="form-control tab-input"
                        type="text"
                        name="name"
                        (change)="setInvoiceInfo('invoice_name',$event)"
                        formControlName="customer_name"
                        autocomplete="off" placeholder="Ad" 
>
                </div>     
                <div class="tab-input-container" *ngIf="selectedTab == 'individual'">
                    <input 
                        type="text" 
                        class="form-control tab-input" 
                        name="surname"
                        (change)="setInvoiceInfo('invoice_surname',$event)"
                        formControlName="customer_surname"
                        autocomplete="off" 
                        placeholder="Ad Soyad"
                        >
                </div>

                <div class="tab-input-container" *ngIf="selectedTab == 'institutional'">
                    <input 
                        type="text" 
                        class="form-control tab-input" 
                        name="company_name"
                        (change)="setInvoiceInfo('company_name',$event)"
                        formControlName="company_name"
                        autocomplete="off" 
                        placeholder="Şirket Adı"
                        >
                </div>

                <div class="tab-input-container" *ngIf="selectedTab == 'institutional'">
                    <input 
                        type="text" 
                        class="form-control tab-input" 
                        name="tax_administration"
                        (change)="setInvoiceInfo('tax_administration',$event)"
                        formControlName="tax_administration"
                        autocomplete="off" 
                        placeholder="Vergi Dairesi"
                        >
                </div>
                <div class="tab-input-container" *ngIf="selectedTab == 'institutional'">
                    <input 
                        type="text" 
                        class="form-control tab-input" 
                        name="tax_number"
                        (change)="setInvoiceInfo('tax_number',$event)"
                        formControlName="tax_number"
                        autocomplete="off" 
                        placeholder="Vergi Numarası"
                        >
                </div>
        
                <div class="tab-input-container">
                    <input 
                        type="text"
                        class="form-control tab-input" 
                        placeholder="Telefon"
                        (change)="setInvoiceInfo('gsm',$event)"
                        formControlName="tel_number"
                        autocomplete="off" 
                        >
                </div>
        
                <div class="tab-input-container">
                    <input 
                        type="text" 
                        class="form-control tab-input" 
                        placeholder="E-mail"
                        (change)="setInvoiceInfo('email',$event)"
                        formControlName="customer_email"
                        autocomplete="off" >
                </div>
        
                <div class="tab-input-container"  *ngIf="selectedTab == 'individual'">
                    <input 
                        type="text" 
                        class="form-control tab-input" 
                        placeholder="TC Kimlik Numarası"
                        (change)="setInvoiceInfo('identity_no',$event)"
                        formControlName="identity_no"
                        autocomplete="off">
                </div>

                <div class="tab-input-container">
                    <app-shared-appointment-cities 
                        [event]="invoiceAddressForm.value.country_id" 
                        (city_id)="changeGeoZone('city',$event)"
                        [showLabel]="false"
                    ></app-shared-appointment-cities> 
                </div>

                <div class="tab-input-container">
                    <app-shared-appointment-districts 
                        [event]="invoiceAddressForm.value.city_id" 
                        (district_id)="changeGeoZone('district',$event)"
                        [showLabel]="false"
                    ></app-shared-appointment-districts>
                </div>

                <div class="tab-input-container">
                    <app-shared-appointment-towns 
                        [event]="invoiceAddressForm.value.district_id" 
                        (town_id)="changeGeoZone('town',$event)"
                        [showLabel]="false"
                    ></app-shared-appointment-towns>
                </div>

                <div class="tab-input-container">
                    <textarea name="address" id="" cols="30" (change)="setInvoiceInfo('address',$event)" class="form form-control" rows="10" placeholder="adres" ></textarea>
                </div>

            </div>
        </form>
    </div>



    <form  class="payment-form" #paymentForm method="post" target="_blank" >


        <div class="d-flex justify-content-center payment-container mb-6" style="display: none !important;" >
          
                <!--begin::Col-->
                <div class="payment-button-container" >
                    <label class="btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 g-9" data-kt-button="true">
                        <!--begin::Radio button-->
                        <span class="form-check form-check-custom form-check-solid form-check-sm align-items-start" style="margin: auto;">
                            <input class="form-check-input" [checked]="selectedPaymentType == 1" type="radio" name="usage" value="2" style="margin: auto;" (click)="setPaymentType('online')" >
                        </span>
                        <!--end::Radio button-->
                        <span class="ms-5">
                            <span class="fs-6 fw-bolder mb-1 d-block" style="width: 100%;">Şimdi Öde</span>
                            <span class="fw-bold fs-8 text-gray-600" style="width: 100%;">(Fiyatı Sabitle ve Ödül Kazan)</span>
                        </span>
        
                        <span class="ms-5">
                            <i class="far fa-credit-card"></i>
                           
                        </span>
                    </label>
                </div>
                <div class="payment-button-container">
                    <label class="btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 g-9" data-kt-button="true">
                        <span class="form-check form-check-custom form-check-solid form-check-sm align-items-start" style="margin: auto;">
                            <input class="form-check-input" [checked]="selectedPaymentType == 2" type="radio" name="usage" value="2" style="margin: auto;" (click)="setPaymentType('offline')" >
                        </span>
                        <span class="ms-5">
                            <span class="fs-6 fw-bolder mb-1 d-block" style="width: 100%;">Serviste Öde</span>
                            <span class="fw-bold fs-6 text-gray-600" style="width: 100%;">Fiyat Değişebililr</span>
                        </span>
        
                        <span class="ms-5">
                            <i class="fas fa-wrench"></i>
                           
                        </span>
                    </label>
                </div>     
       
        
        </div>

        <div class="odeme-container">
            <div class="odeme-item-container">

                <div class="form-content-container">
            
                    <div class="tab-input-container">
                        <input type="text" class="form-control form-control-solid  form-control-sm"
                                            value="{{filteredCardNo}}" 
                                            placeholder="Kart Numarası" 
                                            minlength="19"
                                            maxlength="19"
                                            autocomplete="off"
                                            name="Pan1" 
                                            (input)="filterFormatCardNo($event)"   />
                        <input type="hidden" class="form-control form-control-solid  form-control-sm" placeholder="Kart Numarası" name="Pan" value="{{inputCardNo}}"   />
                    </div>
            
                    <div class="tab-input-container">
                        <input 
                            type="text" 
                            class="form-control tab-input" 
                            name="surname"
                            (change)="setCardInfo('cardName',$event)"
                        
                            autocomplete="off" 
                            placeholder="Ad Soyad" 
                        >
                    </div>
            
                    <div class="tab-input-container">
                    
                        <input class="form-control  form-control-sm tab-input" (keyup)="setCardInfo('expire',$event)" placeholder="Son Kullanma Tarihi" minlength="5" maxlength="5" value="{{filteredExpiryDate}}" (input)="filterFormatExpirationDate($event)" type="text" name="Expiry1">
                        <input class="form-control  form-control-sm" value="{{inputExpiryDate}}" type="hidden" name="Expiry">
                    </div>
            
                    <div class="tab-input-container">
                        <input type="text" class="form-control form-control-solid  form-control-sm tab-input"  minlength="3" (keyup)="setCardInfo('ccv',$event)"   maxlength="4" placeholder="CVV" name="Cvv2" />
    
                    </div>
                    <div class="tab-input-container checkbox-container">
                        <div class="form-check form-check-sm form-check-custom form-check-solid payment-check">
                            <input class="form-check-input checbox-check" type="checkbox" value="1" checked>
                            &nbsp;E-Fatura Mükellefiyim
                        </div>
                        <div class="form-check form-check-sm form-check-custom form-check-solid payment-check">
                            <input class="form-check-input checbox-check" type="checkbox" value="1" checked>
                            &nbsp;Aydınlatma Metni
                        </div>
                        <div class="form-check form-check-sm form-check-custom form-check-solid payment-check">
                            <input class="form-check-input checbox-check" type="checkbox" value="1" checked>
                            &nbsp;Gizlilik Politikası
                        </div>
                        <div class="form-check form-check-sm form-check-custom form-check-solid payment-check">
                            <input class="form-check-input checbox-check" type="checkbox" value="1" checked>
                            &nbsp;Kullanıcı Sözleşmesi
                        </div>
                        <div class="form-check form-check-sm form-check-custom form-check-solid payment-check">
                            <input class="form-check-input checbox-check" type="checkbox" value="1" checked>
                            &nbsp;Açık Rıza Metni
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="odeme-item-container">
                
                <app-shared-credit-card style="width: 100%;"
                [cardNumber]="filteredCardNo"
                [cardName]="cardName"
                [cardExpire]="cardExpire"
                [ccv2]="ccv2"
                [flipped]="turnCard"
                ></app-shared-credit-card>

                <div class="tab-input-container">
                    <table class="table align-middle table-row-dashed fs-7 fw-bold gy-4 dataTable no-footer" style="margin-left:10px; width:90%;">
                        <thead>
                            <tr>
                                <td style="width: 20px;"> <input checked type="radio" class="form-radio-input" name="installment"> </td>
                                <td>Tek Çekim (Peşin)</td>
                                <td>{{priceInfo.generalTotal}}&#8378;</td>
                            </tr>
                        </thead>
                        <tbody *ngIf="filteredData">
                            <tr *ngFor="let i of filteredData">
                                
                                    <td style="width: 20px;">
                                        <input 
                                            type="radio" 
                                            class="form-radio-input"  
                                            name="installment"
                                            value="{{ getField(i,'installmentCount') | json}}"
                                            (click)="setInstallment($event,i )"
                                            >
                                    </td>
                                    <td>{{ getField(i,"installmentCount") | json}} x Taksit</td>
                                    <td>{{ getField(i,"buyerCostAmount") | json}}&#8378;</td>
                                
                            </tr>
                           
                        </tbody>
                    </table>
                </div>

                
                
                <button 
                type="submit" 
                class="btn btn-primary btn-sm" 
                (click)="formEmit()"
                [ngClass]="{disabled: hidePaymentButton == true}"
                >
                <span
                class="indicator-label"
                [ngClass]="{'d-none': hidePaymentButton == true}"
                >Ödeme Yap</span>
              
                <span [ngClass]="{'d-none': !hidePaymentButton}" class="translate-middle-y lh-0 me-1">
                    <span class="spinner-border h-15px w-15px align-middle"></span>
                    Lütfen Bekleyiniz!
                </span>
                </button>    
           
           

                <div class="alert alert-danger mt-5" *ngIf="showPaymentMessage">
                    {{paymentMessage}}
                </div>
            </div>

            <div class="odeme-item-container">
                <div class="col-md-12">
                    
                </div>
            </div>

            
        </div>
        
       
    </form> 

    
</div>
  
