<div class="tag-input-container">
    <div id="tag-list">
      <input #tagifyInput type="text" id="{{tagInputId}}" class="tagify-input" [value]="inputValue" (keydown.enter)="addTag($event)" placeholder="{{placeholder}}" />
  
      <span *ngFor="let tag of tags" class="tag">
        <span>{{ tag }}</span>
        <span class="close" (click)="removeTag(tag)">&times;</span>
      </span>
    </div>
  </div>
  