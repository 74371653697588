import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface VehicleBrand {
  id: bigint,
  name: String,
  logo_code?: String,
  logo_url?: String,
  tsrbs_brand_id?: Number,
  active: Boolean
}

interface ResponseArray {
  vehicle_brands: VehicleBrand[];
}

interface ResponseSingle {
  vehicle_brands_by_pk: VehicleBrand;
}

@Injectable()
export class VEHICLE_BRAND_ALL extends Query<ResponseArray> {
  override document = gql`
  query VEHICLE_BRAND_ALL($where: vehicle_brands_bool_exp){
    vehicle_brands(where:$where,order_by:{name:asc}) {
      id
      name
      logo_url
    }
  }`;
}

@Injectable()
export class VEHICLE_BRAND_ID extends Query<ResponseSingle> {
  override document = gql`
  query VEHICLE_BRAND_ID($id: bigint!){
    vehicle_brands_by_pk(id: $id) {
      id
      name
      logo_url
      logo_code
      tsrbs_brand_id
      active
    }
  }`;
}

@Injectable()
export class VEHICLE_BRAND_CREATE extends Mutation {
  override document = gql`
  mutation VEHICLE_BRAND_CREATE($active: Boolean = false, $logo_code: String!, $logo_url: String!, $name: String!, $tsrbs_brand_id: Int!) {
    insert_vehicle_brands(objects: {active: $active, logo_code: $logo_code, logo_url: $logo_url, name: $name, tsrbs_brand_id: $tsrbs_brand_id}) {
      affected_rows
    }
  }`;
}

@Injectable()
export class VEHICLE_BRAND_UPDATE extends Mutation {
  override document = gql`
  mutation VEHICLE_BRAND_UPDATE($id: bigint!, $name: String!, $tsrbs_brand_id: Int!, $logo_url: String!, $logo_code: String!, $active: Boolean!) {
    update_vehicle_brands(
      where: {id: {_eq: $id}}, 
      _set: {name: $name, tsrbs_brand_id: $tsrbs_brand_id, logo_url: $logo_url, logo_code: $logo_code, active: $active})
    {
      affected_rows
    }
  }`;
}

@Injectable()
export class VEHICLE_BRAND_DELETE extends Mutation<any> {
  override document = gql`
    mutation VEHICLE_BRAND_DELETE($id: bigint!) {
      delete_vehicle_brands(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class VEHICLE_BRAND_PAGINATE extends Query<ResponseArray>{
  override document = gql`
  query VEHICLE_BRAND_PAGINATE($limit: Int, $offset: Int,$orderById: order_by = asc, $where: vehicle_brands_bool_exp) {
    vehicle_brands(limit: $limit, offset: $offset, order_by: {id: $orderById}, where: $where) {
      id
      name
      logo_code
      logo_url
      active
      tsrbs_brand_id
    }
  }`;
}

@Injectable()
export class VEHICLE_BRAND_COUNT extends Query<any> {
  override document = gql`
  query VEHICLE_BRAND_COUNT($where: vehicle_brands_bool_exp) {
    vehicle_brands_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }`;
}