import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';
import { OrderOffers } from './order_offers.graphql';

export interface OrderOfferAnswers {
  id: bigint;
  auction_answer_id: bigint;
  auction_number: bigint;
  file_number: String;
  outsource_auction_no: Date;
  current_id: bigint;
  current_name: String;
  part_code: String;
  equivalent_part_code: String;
  part_name: String;
  available: Number;
  part_unit_price: Number;
  part_price: Number;
  discount: Number;
  offer_part_count: Number;
  offer_part_price: Number;
  part_type_id: Number;
  part_type: String;
  part_brand_id: Number;
  part_brand: String;
  answer_description: String;
  delivery_day_count: Number;
  order_offers_id: bigint;
  created_at: Date;
  updated_at: Date;
  order_offers: OrderOffers,
  cargo_company_id: Number,
  cargo_number: String
}

interface ResponseArray {
    order_offer_answers: OrderOfferAnswers[];
}

interface ResponseSingle {
    order_offer_answers_by_pk: OrderOfferAnswers
}

@Injectable()
export class ORDER_OFFER_ANSWERS_ALL extends Query<ResponseArray> {
  override document = gql`
    query ORDER_OFFER_ANSWERS_ALL($where: order_offer_answers_bool_exp){
        order_offer_answers(where: $where) {
        id
        auction_answer_id
        auction_number
        file_number
        outsource_auction_no
        current_id
        current_name
        part_code
        equivalent_part_code
        part_name
        available
        part_unit
        part_price
        discount
        offer_part_count
        offer_part_price
        part_type_id
        part_type
        part_brand_id
        part_brand
        answer_description
        delivery_day_count
        order_offers_id
        created_at
        updated_at
        cargo_number
        cargo_company_id
        shipment_types{
          id
          name
        }
        order_offers{
          id
          order_bid_part_offer_id
          auction_id
          processed
          created_at
          answered_at
          selected_answer_id
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_OFFER_ANSWERS_ID extends Query<ResponseSingle> {
  override document = gql`
    query ORDER_OFFER_ANSWERS_ID($id: bigint!){
        order_offer_answers_by_pk(id: $id) {
            id
            auction_answer_id
            auction_number
            file_number
            outsource_auction_no
            current_id
            current_name
            part_code
            equivalent_part_code
            part_name
            available
            part_unit_price
            part_price
            discount
            offer_part_count
            offer_part_price
            part_type_id
            part_type
            part_brand_id
            part_brand
            answer_description
            delivery_day_count
            order_offers_id
            created_at
            updated_at
      }
    }
  `;
}




@Injectable()
export class ORDER_OFFER_ANSWERS_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query ORDER_OFFER_ANSWERS_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: order_offer_answers_bool_exp) {
        order_offer_answers(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
            id
            auction_answer_id
            auction_number
            file_number
            outsource_auction_no
            current_id
            current_name
            part_code
            equivalent_part_code
            part_name
            available
            part_price
            part_unit_price
            discount
            offer_part_count
            offer_part_price
            part_type_id
            part_type
            part_brand_id
            part_brand
            answer_description
            delivery_day_count
            order_offers_id
            created_at
            updated_at
      }
    }
  `;
}

@Injectable()
export class ORDER_OFFER_ANSWERS_COUNT extends Query<any> {
  override document = gql`
    query ORDER_OFFER_ANSWERS_COUNT($where: order_offer_answers_bool_exp!) {
        order_offer_answers_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_OFFER_ANSWERS_CREATE extends Mutation<ResponseSingle> {
  // connected_part_id - int | nullable
  override document = gql`
    mutation ORDER_OFFER_ANSWERS_CREATE($object: order_offer_answers_insert_input!) {
      insert_order_offer_answers_one(object: $object)  {
        
          id

      }
    }
  `;
}