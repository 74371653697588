import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface OrderInvoiceInfo {
  id: Number;
  order_id: bigint;
  customer_name?: string;
  customer_surname?:string;
  identity_no?:bigint;
  tax_number?:bigint;
  tax_administration?:string;
  user_id?: bigint;
  company_id?: bigint;
  gsm_number?:string;
  customer_email?:string;
  is_same_with_customer_info?:boolean;
  invoice_type:Number,
  created_at:Date,
  tel_number: string,
  address: Text,
  city_id: Number,
  district_id: Number,
  country_id: Number,
  town_id: Number,
  company_name?: string,
  is_rated?:boolean,
  town:any;
  district:any,
  city:any,
  country:any,
  customer_invoice_type:Number,
  date:Date
  orders:any
}

interface ResponseArray {
  order_invoice_info: OrderInvoiceInfo[];
}
interface ResponseOne {
  order_invoice_info: OrderInvoiceInfo;
}

interface ResponseSingle {
    order_invoice_info_by_pk: OrderInvoiceInfo
}

@Injectable()
export class ORDER_INVOICE_INFO_ALL extends Query<ResponseArray> {
  override document = gql`
    query ORDER_INVOICE_INFO_ALL($where: order_invoice_info_bool_exp){
        order_invoice_info(where: $where) {
        id
        order_id
        customer_name
        customer_surname
        identity_no
        tax_number
        tax_administration
        user_id
        company_id
        gsm_number
        customer_email
        is_same_with_customer_info
        invoice_type
        created_at
        tel_number
        address
        city_id
        district_id
        country_id
        town_id
        company_name
        is_rated
        customer_invoice_type
      }
    }
  `;
}

@Injectable()
export class ORDER_INVOICE_INFO_ONE extends Query<ResponseArray> {
  override document = gql`
    query ORDER_INVOICE_INFO_ONE($where: order_invoice_info_bool_exp){
      order_invoice_info(where: $where, limit:1) {
        id
        order_id
        customer_name
        customer_surname
        identity_no
        tax_number
        tax_administration
        user_id
        company_id
        gsm_number
        customer_email
        is_same_with_customer_info
        invoice_type
        created_at
        tel_number
        address
        city_id
        district_id
        country_id
        town_id
        company_name
        is_rated
        customer_invoice_type
        city{
          name
        }
        town{
          name
        }
        district{
          name
        }
        country{
          name
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_INVOICE_INFO_ID extends Query<ResponseSingle> {
  override document = gql`
    query ORDER_INVOICE_INFO_ID($id: bigint!){
      order_invoice_info_by_pk(id: $id) {
        id
        order_id
        customer_name
        customer_surname
        identity_no
        tax_number
        tax_administration
        user_id
        company_id
        gsm_number
        customer_email
        is_same_with_customer_info
        invoice_type
        created_at
        tel_number
        customer_invoice_type
        address
        city_id
        district_id
        country_id
        town_id
        company_name
        is_rated
      }
    }
  `;
}

@Injectable()
export class ORDER_INVOICE_INFO_CREATE extends Mutation<any> {
  // connected_part_id - int | nullable
  override document = gql`
    mutation ORDER_INVOICE_INFO_CREATE( $object: order_invoice_info_insert_input! ) {
        insert_order_invoice_info_one(object: $object) {
            id   
        }
    }
  `;
}



@Injectable()
export class ORDER_INVOICE_INFO_UPDATE extends Mutation<any> {
  override document = gql`
    mutation ORDER_INVOICE_INFO_UPDATE($id: bigint!, $changes: order_invoice_info_set_input ) {
      update_order_invoice_info(where: {id: {_eq: $id}}, _set: $changes) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}


@Injectable()
export class ORDER_INVOICE_INFO_DELETE extends Mutation<any> {
  override document = gql`
    mutation ORDER_INVOICE_INFO_DELETE($id: bigint!) {
      delete_order_invoice_info(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_INVOICE_INFO_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query ORDER_INVOICE_INFO_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: order_invoice_info_bool_exp) {
      parts(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        order_id
        customer_name
        customer_surname
        identity_no
        tax_number
        tax_administration
        user_id
        company_id
        gsm_number
        customer_email
        is_same_with_customer_info
        invoice_type
        created_at
        tel_number
        address
        city_id
        district_id
        customer_invoice_type
        country_id
        town_id
        company_name
        is_rated
      }
    }
  `;
}

@Injectable()
export class ORDER_INVOICE_INFO_COUNT extends Query<any> {
  override document = gql`
    query ORDER_INVOICE_INFO_COUNT($where: order_invoice_info_bool_exp) {
      order_invoice_info_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}