import { Injectable } from '@angular/core';
import { gql, Mutation, Query, Subscription } from 'apollo-angular';
import { Company } from './company.graphql';
import { FormStepParts } from './form_step_parts.graphql';
import { User } from './user.graphql';

export interface FormSteps {
  id: Number,
  name: String,
  status: Boolean,
  description: String,
  form_step_parts: FormStepParts[]
}

interface ResponseArray {
    form_steps: FormSteps[]
}

interface ResponseSingle {
    form_steps_by_pk: FormSteps
}

interface Aggregate {
  form_steps_aggregate: {
    aggregate: {
      count: number
    }
  }
}

interface InsertMutation {
  insert_form_steps: {
    affected_rows: number
    returning: [{
      id: Number
    }]
  }
}

interface UpdateMutation {
  update_form_steps: {
    affected_rows: number
    returning: [{
      id: Number
    }]
  }
}

interface DeleteMutation {
  delete_form_steps: {
    affected_rows: number
    returning: [{
      id: Number
    }]
  }
}

@Injectable()
export class FORM_STEPS_ID extends Query<ResponseSingle> {
  override document = gql`
  query FORM_STEPS_ID($id: Int!) {
    form_steps_by_pk( id: $id) {
        id
        name
        description
        status
      
    }
  }`;
}

@Injectable()
export class FORM_STEPS_COUNT extends Query<Aggregate> {
  override document = gql`
  query FORM_STEPS_COUNT($where: form_steps_bool_exp) {
    form_steps_aggregate( where: $where) {
      aggregate {
        count
      }
    }
  }`;
}

@Injectable()
export class FORM_STEPS_ALL extends Query<ResponseArray> {
  override document = gql`
  query FORM_STEPS($where: form_steps_bool_exp, $limit: Int = 100){
    form_steps(where: $where, order_by: {position: asc}, limit: $limit){
      id
      name
      description
      status
    }
  }`;
}

@Injectable()
export class FORM_STEPS_PAGINATE extends Query<ResponseArray>{
  override document = gql`
    query FORM_STEPS_PAGINATE($limit: Int = 20, $offset: Int = 0, $orderBy: order_by = asc,$where: form_steps_bool_exp){
      form_steps(where: $where, limit: $limit, offset: $offset, order_by: {id: $orderBy}){
        id
        name
        description
        status
      }
    }
  `;
}

@Injectable()
export class FORM_STEPS_CREATE extends Mutation<InsertMutation> {
  override document = gql`
  mutation FORM_STEPS_CREATE($name: String!, $description: String!, $status: Boolean!) {
    insert_form_steps(objects: {name: $name, description:$description, status: $status}) {
      affected_rows
      returning {
        id
      }
    }
  }`;
}

@Injectable()
export class FORM_STEPS_UPDATE extends Mutation<UpdateMutation> {
  override document = gql`
  mutation FORM_STEPS_UPDATE($id: Int!, $name: String!, $description: String!, $status: Boolean!) {
    update_form_steps(where: {id: {_eq: $id}}, _set: {name: $name, description:$description, status: $status}) {
      affected_rows
      returning {
        id
      }
    }
  }`;
}

@Injectable()
export class FORM_STEPS_DELETE extends Mutation<DeleteMutation> {
  override document = gql`
  mutation FORM_STEPS_DELETE($id: Int!) {
    delete_form_steps(where: {id: {_eq: $id}}) {
      affected_rows
      returning {
        id
      }
    }
  }`;
}