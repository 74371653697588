import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map } from 'rxjs';
import { Garage, GARAGES_ALL, GARAGE_CREATE } from 'src/app/graphql/garages.graphql';
import { MAINTENANCE_KMS_ALL } from 'src/app/graphql/package.graphql';
import { PackageKms, PACKAGE_KMS_ALL } from 'src/app/graphql/package.kms';
import { VehicleBrand, VEHICLE_BRAND_ALL } from 'src/app/graphql/vehicle-brand.graphql';
import { VehicleFuelType, VEHICLE_FUEL_TYPE_ALL } from 'src/app/graphql/vehicle-fuel-type.graphql';
import { VehicleGearType, VEHICLE_GEAR_TYPE_ALL } from 'src/app/graphql/vehicle-gear-type.graphql';
import { VehicleModelType, VEHICLE_MODEL_TYPE_ALL, VEHICLE_MODEL_TYPE_ID } from 'src/app/graphql/vehicle-model-type.graphql';
import { VehicleModelYear, VEHICLE_MODEL_YEAR_ALL } from 'src/app/graphql/vehicle-model-year.graphql';
import { VehicleModel, VEHICLE_MODEL_ALL } from 'src/app/graphql/vehicle-model.graphql';
import { Vehicle, VEHICLE_CREATE } from 'src/app/graphql/vehicle.graphql';
import { VehicleBrands, VehicleFuels, VehicleGears, VehicleKms, VehicleModels, VehicleModelTypes, VehicleModelYears } from 'src/app/interfaces/appointment.interface';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment-v2-vehicle-select',
  templateUrl: './arac-secimi-v2.component.html',
  styles: [
  ],
  providers:[
    VEHICLE_BRAND_ALL,
    VEHICLE_MODEL_ALL,
    VEHICLE_MODEL_YEAR_ALL,
    VEHICLE_MODEL_TYPE_ALL,
    GARAGES_ALL,
    VEHICLE_MODEL_TYPE_ID,
    GARAGE_CREATE,
    VEHICLE_CREATE,
    VEHICLE_FUEL_TYPE_ALL,
    VEHICLE_GEAR_TYPE_ALL,
    PACKAGE_KMS_ALL
  ]
})
export class AracSecimiV2Component implements OnInit {

  @Input() selectedModelTypeId: number | undefined;
  @Input() selecteMaintenanceKmsId: number | undefined;
  @Output() emitVehicleForm = new EventEmitter<any>();
  @Output() show = new EventEmitter<any>();
  @Output() removeBtn= new EventEmitter<any>();
  @Input() orderServiceId:Number | undefined;
  @Input() gearId: number | undefined;
  @Input() fuelId: number | undefined;


  public vehicleTab:String = "new";
  public garageList: Garage[] | undefined;
 
  public vehicleList: Vehicle[] | undefined;
  public vehicleId:BigInt | undefined;
  public brandName:String = "Marka Seçiniz";
  public modelName:String = "Model Seçiniz";
  public selectedYear:String = "Yıl Seçiniz";
  public modelTypeName:String = "Model Türü Seçiniz";
  public vehicleBrands: VehicleBrand[] = [];
  public vehicleModels: VehicleModel[] | undefined;
  public selectedKms:String = "Km";
  public selecteEditYear:String = "Yıl";
  public selectedModel:any;
  public selectedBrand:any;
  //public vehicleModelYearList: VehicleModelYear[] | undefined;
  public vehicleModelTypes: VehicleModelType[] = [];
  public orderForm: any[] = [];
  public vehicleModelType: VehicleModelType | undefined;
  public showOldVehicles:boolean = false;
  public gearList: VehicleGearType[] | undefined;
  public fuelList: VehicleFuelType[] | undefined;
  public kmsName:String = "Kilometre Seçiniz"; 
  public kmsList: PackageKms[] | undefined;
  public selectedKmInfo = {
    selectedKmId:null,
    selectedKmName:null
  }
  
  public gearName: String = "Vites Seçiniz";
  public fuelName: String = "Yakıt Türü Seçiniz";

  public garageName:String = "Garaj Seçiniz";
  @Input() garageId: Number = 0;
  public brandId: BigInt | undefined;
  public modelId: BigInt | undefined;
  public modelTypeId: BigInt | undefined;
  public modelYearId: BigInt | undefined;

  public vehicleForm :any;

  public vehicle: Vehicle | undefined;
  private baseUrl: string = environment.laravelApiEndpoint;

  public vehicleBrandList: VehicleBrands[] | undefined;
  public vehicleModelList: VehicleModels[] | undefined;
  public vehicleModelYearList: VehicleModelYears[] | undefined;
  public vehicleModelTypeList: VehicleModelTypes[] | undefined;
  public vehicleGearList: VehicleGears[] | undefined;
  public vehicleFuelList: VehicleFuels[] | undefined;
  public vehicleKmsList: VehicleKms[] | undefined;

  constructor(
    public authService: AuthService,
    private getVehicleBrand: VEHICLE_BRAND_ALL,
    private getVehicleModels: VEHICLE_MODEL_ALL,
    private vehicleModelYears:VEHICLE_MODEL_YEAR_ALL,
    private getVehicleModelTypes: VEHICLE_MODEL_TYPE_ALL,
    private getGarages: GARAGES_ALL,
    private getModelType: VEHICLE_MODEL_TYPE_ID,
    private alertifyService: AlertifyService,
    private createGarage: GARAGE_CREATE,
    private createVehicle: VEHICLE_CREATE,
    private getVehicleGearTypes: VEHICLE_GEAR_TYPE_ALL,
    private getVehicleFuelType: VEHICLE_FUEL_TYPE_ALL,
    private getMaintenanceKmsList: PACKAGE_KMS_ALL,
    private http: HttpClient
  ) {   }
  async ngOnInit(): Promise<void> {

   

    if(this.authService.userRole != "anonymous"){
      this.vehicleTab = "old"
          this.showOldVehicles = true
          this.getGarage();

         
         
    }else{
      this.vehicleTab = "new"
      this.showOldVehicles = false
    }

    let url = 'appointment/get-vehicle-brands';
    await this.getApi(url).pipe().subscribe(response => {
      
        this.vehicleBrandList = response;
    })
      

    this.getVehicleBrand.watch({where:{active:{_eq:true}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
      this.vehicleBrands = result.data.vehicle_brands
    });

    this.getVehicleGearTypes.watch({}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.gearList = resp.data.vehicle_gear_types;
      
      }
    })

    this.getVehicleFuelType.watch().valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.fuelList = resp.data.vehicle_fuel_types;
      }
    })

    this.getMaintenanceKmsList.watch({}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.kmsList = resp.data.maintenance_kms;
      }
    })

   

    setTimeout(() => {
      if(this.selectedModelTypeId != undefined ){
      
      //  this.modelTypeId = this.selectedModelTypeId;
  
        this.getModelType.watch({id:this.selectedModelTypeId}).valueChanges.subscribe(resp=>{
  
          if(resp.errors){
            this.alertifyService.error(resp.errors[0].message);
          }
          if(resp.data){
            this.vehicleModelType = resp.data.vehicle_model_types_by_pk;
            
            this.removeBtn.emit(true);
  
            if(this.vehicleModelType != null &&this.vehicleModelType.vehicle_model_year.vehicle_model.vehicle_brand.id){
              this.selectedTwo("brand",this.vehicleModelType.vehicle_model_year.vehicle_model.vehicle_brand.id);
             
              setTimeout(() =>{
                this.selectedTwo("model",this.vehicleModelType?.vehicle_model_year.vehicle_model.id);
              },100)
  
              setTimeout(() =>{
                this.selectedTwo("year",this.vehicleModelType?.vehicle_model_year.id);
              },200)
  
              setTimeout(() =>{
                this.selectedTwo("vehicle_model_types",this.vehicleModelType?.id);
              },300)
              setTimeout(() =>{
              this.selectedTwo("gear",this.gearId);
            },1000)
            setTimeout(() =>{
              this.selectedTwo("fuel",this.fuelId);
            },1000)
            
            }
           
            
          }
        })
      }else{
      }

    });

   
  }

  ngOnChanges(): void{
    
    
  }

  

  tabChange(tabName:String){
    this.vehicleTab = tabName;
  }

  selected(event:any){
   
  
        if(event == null){
          this.garageId = 0;
          this.vehicleList = [];
          this.vehicleId = undefined;
         // this.selected
        }else{
          this.garageId = event;
          let list = this.garageList?.filter(i => i.id == this.garageId);
          if(list && list.length > 0){
            this.vehicleList = list[0].vehicles;
            this.garageName = list[0].name;

            if(this.selectedModelTypeId){
              this.vehicleList?.forEach((item:any)=>{
                if(this.selectedModelTypeId == item.model_type_id){
                  this.chooseVehicle(item);
                }
              })

            }
            
         
          }
        }
    
  }
  chooseVehicle(vehicle: Vehicle){
    this.vehicleId = vehicle.id;

    this.vehicleForm = {
      name:this.garageName,
      brand_id: vehicle.brand_id,
      model_id: vehicle.model_id,
      model_type_id: vehicle.model_type_id,
      model_year_id: vehicle.model_year_id,
      garage_id:this.garageId,
      km_id:this.selectedKmInfo.selectedKmId,
      isStepCompleted:true
    }

    this.emitVehicleForm.emit(this.vehicleForm);
  }

  setGarageName(event:any){
    this.garageName = event.target.value;
  }

  setGarage($event:any){
    this.garageId = $event;
    this.garageList?.forEach((item:any)=>{
      if(item.id == $event){
        this.garageName = item.name;
      }
    })
  }

   async selectedTwo(key: string, $event:any){


    switch (key) {
      case "brand":
        if( $event != this.brandId){

          let url = 'appointment/get-vehicle-models';
          await this.getApi(url,{brand_id:$event}).pipe().subscribe(response => {
            this.brandId = $event;
            this.vehicleModelList = response;
            this.modelId = undefined;
            this.modelYearId = undefined;
            this.modelTypeId = undefined;
            this.modelName = 'Model';
            this.modelTypeName = 'Model Tipi';
            this.selectedKms = "";
            this.selecteEditYear = "Yıl Seçiniz";
            this.selectedYear = "Yıl Seçiniz";
            this.vehicleModelYearList=[];
            this.vehicleModelTypes = [];
            
            this.setStepComplete();
          })
        /*
         this.getVehicleModels.watch({where:{vehicle_brand_id:{_eq:$event}}}).valueChanges.subscribe((result:any)=>{ 

            this.brandId = $event;
            this.modelId = undefined;
            this.modelYearId = undefined;
            this.modelTypeId = undefined;
            this.modelName = 'Model';
            this.modelTypeName = 'Model Tipi';
            this.selectedKms = "";
            this.selecteEditYear = "Yıl Seçiniz";
            this.selectedYear = "Yıl Seçiniz";
            this.vehicleModelYearList=[];
            this.vehicleModelTypes = [];

          this.vehicleModels = result.data.vehicle_models;
          this.setStepComplete();
 
        });*/
        this.setStepComplete();
      }
      break;
      case "model":
        //this.selectedModel = $event;
        if($event != this.modelId){
          this.modelId = $event;
          this.clearFormFields(["model_type_id","year_id"]);
          this.selectedYear = "Yıl Seçiniz";
          this.modelTypeName = 'ModelType';
            this.selectedKms = "";
            this.selecteEditYear = "";
            this.vehicleModelYearList=[];
           
            this.vehicleModelTypes = [];

            let url = 'appointment/get-vehicle-model-years';
            await this.getApi(url,{model_id:this.modelId}).pipe().subscribe(response => {
            
              this.vehicleModelYearList = response;
            })
            this.setStepComplete();

            
        }
       

        
        
      break;
      case "year":
        if(parseInt($event) > 0){

          if($event != this.modelYearId){
            this.modelYearId = $event;


            let url = 'appointment/get-vehicle-model-types';
            await this.getApi(url,{year_id:this.modelYearId}).pipe().subscribe(response => {
              this.modelYearId = $event;
              this.vehicleModelTypeList = response;
              this.clearFormFields(["model_type_id"]);
              this.modelTypeName = "Model Tipi";
              
              this.setStepComplete();
            })


         /*   this.vehicleModelYearList?.filter((data: {id:any,year_id:any}) => {
              if(data.id ===  $event){
                this.selectedYear = data.year_id;
                //this.formGroup.patchValue({year_id:data.year_id});
                this.clearFormFields(["model_type_id"]);
                this.modelTypeName = "Model Tipi";
              }
            });*/
             this.getVehicleModelTypes.watch({where:{vehicle_model_year_id:{_eq:$event}}}).valueChanges.subscribe((result:any)=>{
              this.vehicleModelTypes = result.data.vehicle_model_types
              this.setStepComplete();
            })
          }
        
       }
      break; 
      case "vehicle_model_types":
        //this.vehicleTypeFromGroup.patchValue({model_type_id:$event})
        this.modelTypeId = $event;
        this.vehicleForm = {
          name:this.garageName,
          brand_id: this.brandId,
          model_id: this.modelId,
          model_type_id: this.modelTypeId,
          model_year_id: this.modelYearId,
          garage_id:this.garageId,
          gear_id: this.gearId,
          fuel_id: this.fuelId,
          km_id: this.selectedKmInfo.selectedKmId
        }

        await this.getApi('appointment/get-vehicle-gear-fuel-km',{model_type_id:this.modelTypeId}).pipe().subscribe(response => {

          this.vehicleGearList = response.gears;
          this.vehicleFuelList = response.fuels;
          this.vehicleKmsList = response.kms;

        });

        this.setStepComplete();
        //
       

      break;

      case "gear":
        this.gearId = $event;
        this.vehicleForm = {
          name:this.garageName,
          brand_id: this.brandId,
          model_id: this.modelId,
          model_type_id: this.modelTypeId,
          model_year_id: this.modelYearId,
          garage_id:this.garageId,
          gear_id: this.gearId,
          fuel_id: this.fuelId,
          km_id: this.selectedKmInfo.selectedKmId
          
        }
        this.setStepComplete();
      break;

      case "fuel":
        this.fuelId = $event;
        this.vehicleForm = {
          name:this.garageName,
          brand_id: this.brandId,
          model_id: this.modelId,
          model_type_id: this.modelTypeId,
          model_year_id: this.modelYearId,
          garage_id:this.garageId,
          gear_id: this.gearId,
          fuel_id: this.fuelId,
          km_id: this.selectedKmInfo.selectedKmId
          
        }
        this.setStepComplete();
      break;

    }
    
    
  }

  setStepComplete(){
    if(
      this.vehicleForm?.brand_id != null &&
      this.vehicleForm?.model_id != null &&
      this.vehicleForm?.model_type_id != null &&
      this.vehicleForm?.model_year_id != null &&
      this.vehicleForm?.gear_id != null &&
      this.vehicleForm?.fuel_id != null &&
      this.selectedKmInfo.selectedKmId != null &&
      this.selectedKmInfo.selectedKmId > 0
      ){
        this.vehicleForm = {
          name:this.garageName,
          brand_id: this.brandId,
          model_id: this.modelId,
          model_type_id: this.modelTypeId,
          model_year_id: this.modelYearId,
          garage_id:this.garageId,
          gear_id: this.gearId,
          fuel_id: this.fuelId,
          km_id: this.selectedKmInfo.selectedKmId,
          isStepCompleted: true
        }
       
        this.emitVehicleForm.emit(this.vehicleForm);
      }
  }

  clearFormFields(fieldName:any[]){
    if(fieldName.length > 0){
      let ths = this;
      fieldName.forEach(function(value){
        ths.orderForm = ths.orderForm.filter(i => i.order_type_id !== value)
      })
    }
  }
  async createIfCarNotExist() {
    if(this.authService.userRole != "anonymous"){
      let garage = this.getGarage();
      this.showOldVehicles = true;
      let ths = this;
      garage.then(function(success){


          if(ths.garageList == undefined ||  ths.garageList.length == 0){
            ths.createGarage.mutate({name:"Garajım",user_id:ths.authService.userId,active:true}).subscribe((result:any)=>{
              if(result.errors){
                ths.alertifyService.error("Garaj oluşturulamadı!");
              }
              if(result.data){
                ths.vehicleForm.garage_id = result.data.insert_garages.returning[0].id;
                const { isStepCompleted,name, ...vehicleForm } = ths.vehicleForm // remove name
                ths.createVehicle.mutate({...vehicleForm}).subscribe((vehicleResult:any) => {
                    if(vehicleResult.errors){
                      ths.alertifyService.error("Araç kaydı esnasında bir hata oluştu");
                    }
                    if (vehicleResult.data) {
                      ths.vehicleId = vehicleResult.data.insert_vehicles.returning[0].id;
                      ths.vehicleList = [];
             
                      ths.getGarage();
  
                      ths.vehicleTab = "old";
                        ths.showOldVehicles = true;
                      
                    }
                })
              }
            });
          }else{  
            if(ths.garageId == 0){
              ths.garageId = ths.garageList[0].id;
            }
           
            ths.vehicleForm.garage_id = ths.garageId;
            
            ths.getGarages.watch({ "userId": ths.authService.userId },{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
              if(result.error){
                ths.alertifyService.error("Garajlar getirilirken bir hata oluştu");
              }
              if (result.data) {
                ths.garageList = result.data.garages;
                if(ths.garageList && ths.garageList.length > 0){
    
                  let exists = ths.garageList.filter((data:any) =>{ 
                    if(data.vehicles.length > 0){
                      let isCarExists =  data.vehicles.filter((vehicle:any) => vehicle.model_type_id === ths.vehicleForm.model_type_id )
    
                      if(isCarExists.length > 0){
                        isCarExists[0].garage_id = data.id;
                        ths.chooseVehicle(isCarExists[0]);
                        return true;
                      }
                      return false;
                    }
                    
                  });
                    if(exists.length == 0){
                    const { isStepCompleted,name, ...vehicleForm } = ths.vehicleForm // remove name


                    ths.createVehicle.mutate({...vehicleForm}).subscribe((vehicleResult:any) => {
                        if(vehicleResult.errors){
                          ths.alertifyService.error("Araç kaydı esnasında bir hata oluştu");
                        }
                        if (vehicleResult.data) {
                         // this.vehicleId = parseInt(response.data.insert_vehicles.returning[0].id);
                         ths.vehicleList = [];
                         ths.getGarage();

                         ths.vehicleTab = "old";
                         //ths.selected(ths.garageId);
                         //ths.garageId = 0;
                         //ths.chooseVehicle(vehicleForm);
                         ths.showOldVehicles = true;
              
                        }
                    })
                  }else{
                    ths.showOldVehicles = true;
                    if(ths.orderServiceId == 2){
                     /// this.getPackageList();
                      ths.vehicleTab = "old";
                 
                      
    
                    }
                    if(ths.orderServiceId == 1){
                      ths.vehicleTab = "old";
    
                    }
                  }
                }
                
              }
            })
      
          
          }
        


      })


  
    
      
    }else{
      this.vehicleForm.garage_id = 0;
      const { name, ...vehicleForm } = this.vehicleForm // remove name
      this.vehicleTab = "old";
   /*   this.getVehicles.watch({where:{garage_id:{_eq:this.garageId}}}).valueChanges.subscribe((result:any)=>{
        this.vehicleList = result.data.vehicles;
      })*/
    }
  }

  getGarage() {
    // kayıtlı araçlar
    return new Promise(resolve => {
      if (this.authService.isLogin) {
        this.getGarages.watch({ "userId": this.authService.userId },{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
          if(result.error){
            resolve(false);
          }

          if (result.data) {
            this.garageList = result.data.garages;
            if(this.garageList){
              if(this.garageList.length > 0){
                //this.vehicleTab = "old";
  
               
               if(this.garageId == 0){
  
                this.selected(this.garageList[0].id);
               }else{
                this.selected(this.garageId);
               }
               
                
              }
  
              resolve(true);
            }
           
            
          }
        })
      }else{
      // araç marka seçimi
      this.getVehicleBrand.watch().valueChanges.subscribe((result:any)=>{
        this.vehicleBrands = result.data.vehicle_brands
        
      })
      }
    
    });

  }


  setKmInfo($event:any){
    this.selectedKmInfo = $event;
    this.setStepComplete();
  
  }

  getApi(url:string,vars: Object | null = null){

    let options = {
      headers: new HttpHeaders({
        'token': this.authService.parseToken(false)
      })
    }

    return this.http.post<any>(this.baseUrl + url,vars,options).pipe(map(response => {
      if (response.status) {
        return response.data;
      } else {
       this.alertifyService.error(response.message);
      }
      return response;
    }))
  }

}
