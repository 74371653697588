<style>
    .table td,th{
        padding: 0px 5px 0px 5px !important;
        height: 40px !important;
        border: 1px solid #000 !important;
        font-size: 10px !important;
        height: 10px !important;
    }

    .table th{
        font-weight: bold;
    }

    .table tbody tr:last-child th{
        border: 1px solid #000 !important;
    }

    .gos-info-table, .gos-info-table th,  .gos-info-table tr:last-child th{
        border: 0px !important;
    }

    .top-center-info{
        padding: 0px;
        font-size: 0.95rem !important;
        font-weight: bold;
    }

    .customer-info-table{
        border: 1px solid #ccc;
    }

    .customer-info-table td, th{
        border-color: #ccc !important;
    }

    .customer-info-table th, .customer-info-table td, .customer-info-table tr:last-child th{
        border: 0px !important;
        font-size: 0.95rem !important;
        border-right: 1px solid #ccc !important;
      
    }
    .product-detail-table{
        border: 1px solid #ccc;
        border-color: #ccc !important;
    }

    .amount-info-table{
        border: 1px solid #ccc;
        border-color: #ccc !important;
    }

    .amount-info-table td, th{
        border-color: #ccc !important;
    }

    .amount-info-table th:first-child{
        border: 0px !important;
    }

    .product-detail-table{
        border: 0px !important;
        border-color: #ccc !important;
    }

    .product-detail-table td, th{
        border: 1px solid #ccc;
        border-color: #ccc !important;
    }

    

    .desc-info-table{
        width: 100%;
    }

    .desc-info-table th,.desc-info-table th:last-child,.desc-info-table tr:last-child {
        border: 1px solid #ccc !important;
     color: #96bc33 !important;
    }

    .desc-info-table td{
        border-bottom: 1px solid #ccc !important;
    }

    .desc-info-table th{
        width: 11%;
    }

 
  
</style>

<app-shared-proforma-pdf-generator-v2
    *ngIf="gosInfo && vehicleInfo"
    [gosInfo]="gosInfo"
    [serviceInfo]="serviceInfo"
    [customerInfo]="customerInfo"
    [vehicleInfo]="vehicleInfo"
    [invoice]="invoice"
    [hidePartNumbers]="hidePartNumbers"
    [orderInfo]="orderInfo"
>
    
</app-shared-proforma-pdf-generator-v2>

<div  class="w-100 mb-5">
    <div class="d-flex justify-content-between">
            
        <div class="text-end top-center-info w-100 align-middle" style="padding-top: 10px;">{{order?.payment_types?.name}}</div>
        <div class="text-center top-center-info text-center  w-100" style="font-size: 26px !important;">{{order?.order_types.name}}</div>
        <div class="top-center-info w-100 text-end" style="padding-top: 10px;">Randevu Tarihi: {{invoice?.date | date:'shortDate'}}</div>
    </div>
</div>

    <div class="d-flex justify-content-between">
        <div class="w-100">
            <table class="table gos-info-table" >
                <tbody>
                    <tr>
                        <th>
                            <span class="text-muted fs-7">{{gosInfo?.title}}</span>
                        </th>
                        
                    </tr>
                    <tr>
                        <th>
                            <span class="text-muted fs-7">{{gosInfo?.company_tel_no}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <span class="text-muted fs-7">{{gosInfo?.company_email_address}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <span class="text-muted fs-7">{{gosInfo?.City?.name}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <span class="text-muted fs-7">{{gosInfo?.district?.name}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <span class="text-muted fs-7">{{gosInfo?.address}}</span>
                        </th>
                    </tr>
                    
                    <tr>
                        <th style="border: 0px !important;">
                            <span class="text-muted fs-7">{{gosInfo?.vat}}</span>
                        </th>
                    </tr>
                   
                    
                </tbody>
            </table>
        </div>
        <div class="w-100">
            <div class="text-sm">
                <!--begin::Logo-->
                <a href="#" class="d-block mw-150px mx-auto">
                    <img alt="Logo" src="assets/images/logo-garantiliotoservis.svg" class="w-100" />
                </a>
                <!--end::Logo-->
              
            </div>
           
          
            
        </div>
   
      

        <div  class="w-100">
            <table class="table gos-info-table" >
                <tbody>
                    <tr>
                        <th class="text-end">
                            <span class="text-muted fs-7">{{serviceInfo?.name}}</span>
                        </th>
                        
                    </tr>
                    <tr>
                        <th class="text-end">
                            <span class="text-muted fs-7">{{serviceInfo?.company_gsm.code}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-end">
                            <span class="text-muted fs-7">{{serviceInfo?.company_email.email}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-end">
                            <span class="text-muted fs-7">{{serviceInfo?.City?.name}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-end">
                            <span class="text-muted fs-7">{{serviceInfo?.district?.name}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-end">
                            <span class="text-muted fs-7">{{serviceInfo?.address}}</span>
                        </th>
                    </tr>
                    
                    <tr>
                        <th class="text-end" style="border: 0px !important;">
                            <span class="text-muted fs-7">{{serviceInfo?.vat}}</span>
                        </th>
                    </tr>
                   
                    
                </tbody>
            </table>
        </div>
    </div>
    

       
   
    <div class="d-flex justify-content-between mt-5">
        <div class="w-50">
            <table class="table customer-info-table align-middle gs-0 gy-4 " >
                <tbody>
                    <tr >
                        <th style="width: 150px; align-items: center !important; text-align: center; border-bottom: 1px solid #ccc !important; font-size: 15px !important;"  colspan="3" >Müşteri Bilgileri</th>
                      
                    </tr>
                    <tr>
                        <th  class="text-muted">Müşteri Adı/Ünvanı</th>
                        <td style="padding:5px" colspan="2" >{{invoiceInfo?.customer_name}} {{invoiceInfo?.customer_surname}}</td>
                    </tr>
                    <tr>
                        <th  class="text-muted">Tel No</th>
                        <td style="padding:5px" colspan="2" >{{invoiceInfo?.tel_number}}</td>
                        
                    </tr>
                    <tr>
                        <th  class="text-muted">Mail Adresi</th>
                        <td style="padding:5px" colspan="2" >{{invoiceInfo?.customer_email}}</td>
                        
                    </tr>
                    <tr>
                        <th class="text-muted">İl</th>
                        <td style="padding:5px" colspan="2">{{invoiceInfo?.city.name}}</td>
                    </tr>
                    <tr>
                        <th class="text-muted">İlçe</th>
                        <td style="padding:5px" colspan="2">{{invoiceInfo?.district.name}}</td>
                    </tr>
                    <tr>
                        <th class="text-muted">Adres</th>
                        <td style="padding:5px" colspan="2" >{{invoiceInfo?.address}}</td>
                    </tr>
                    <tr>
                        <th class="text-muted" style="border: 0px !important;  border-right: 1px solid #ccc !important;">TCKN</th>
                        <td style="padding:5px" colspan="2" >{{invoiceInfo?.identity_no}}</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
        <div class="w-50">
            <table class="table customer-info-table align-middle gs-0 gy-4 " style="border-left: 0px !important;" >
                <tbody>
                    <tr >
                        <th style="width: 150px; align-items: center !important; border-bottom: 1px solid #ccc !important; font-size: 15px !important;" class="text-center" colspan="3" >Araç Bilgileri</th>
                      
                    </tr>
                    <tr>
                        <th class="text-muted">Şasi</th>
                        <td style="padding:5px" colspan="2">{{vehicleInfo?.chassis_no}}</td>
                    </tr>
                    <tr>
                        <th class="text-muted">Plaka</th>
                        <td style="padding:5px" colspan="2">{{vehicleInfo?.plate}}</td>
                    </tr>
                    <tr>
                        <th class="text-muted">Marka Model</th>
                        <td style="padding:5px" colspan="2">{{vehicleInfo?.vehicle_brand?.name}} / {{vehicleInfo?.vehicle_model?.name}}</td>
                    </tr>
                    <tr>
                        <th class="text-muted">Versiyon / Yıl</th>
                        <td style="padding:5px" colspan="2" >{{vehicleInfo?.vehicle_model_types?.name}}</td>
                        
                    </tr>
                    <tr>
                        <th class="text-muted"> Yıl</th>
                        <td style="padding:5px" colspan="2">{{vehicleInfo?.years?.year_id}}</td>
                    </tr>
                    <tr>
                        <th class="text-muted">Yakıt</th>
                        <td style="padding:5px" colspan="2" >{{vehicleInfo?.vehicle_fuel_type?.name}} </td>
                    </tr>
                    <tr>
                        <th class="text-muted" style="border: 0px !important;">Vites</th>
                        <td style="padding:5px" colspan="2" style="border-left: 1px solid #ccc !important;" >{{vehicleInfo?.vehicle_gear_type?.name}}</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
    </div>

    <div class="d-flex justify-content-between">
        <div class="w-100">
            <table class="table  product-detail-table align-middle " >
                <thead>
                    <tr>
                        <th class="text-center" style=" font-size: 15px !important;" colspan="8">
                            İşlemler
                        </th>
                    </tr>
                </thead>
                <thead>
                    <tr >
                        <th style="width: 150px; align-items: center !important; width: 120px;" class="text-muted"  >Referans No</th>
                        <th style="width: 150px; align-items: center !important;" class="text-muted" colspan="2" >Açıklama</th>
                        
                        <th style="width: 150px; align-items: center !important; width: 108px" class="text-muted" >YP. Tipi</th>
                        <th style="width: 150px; align-items: center !important; width: 101px" class="text-muted">Adet</th>
                        <th style="width: 150px; align-items: center !important; width: 110px" class="text-muted" >Birim Fiyat</th>
                        <th style="width: 150px; align-items: center !important; width: 110px;" class="text-muted" >Toplam</th>                    
                    </tr>
                </thead>
                <tbody>
                   <tr *ngFor="let item of orderParts">
                    <td>{{filterCode(item.part_code)}}</td>
                    <td *ngIf="item.part_original_lists != null">{{item.part_original_lists?.name}}</td>
                    <td *ngIf="item.part_equivalent_lists != null">{{item.part_equivalent_lists?.name}}</td>
                    <td style="border: 1px solid #ccc; padding:5px;" *ngIf="item.part_unit_type_id == 3" >İşçilik</td>
                    <td style="border: 1px solid #ccc; padding:5px" *ngIf="item.part_unit_type_id != 3">Parça</td>
                    <td>{{item.part_types?.name}}</td>
                    <td>{{numberFormat(item.part_unit)}}</td>
                    <td>{{toNumberFormat(item.total_price_no_vat / item.part_unit)}}</td>
                    <td>{{toNumberFormat(item.total_price_no_vat)}}</td>
                   </tr>
                    
    
                </tbody>
            </table>
        </div>
    </div>
    
    <div class="d-flex justify-content-between" *ngIf="invoiceParts">
        <div class="w-100">
            <table class="table amount-info-table" style=" border: 0px !important;">
                <tr>
                    <th colspan="3" style="width: 257px;" ></th>
                    <th style="width: 108px !important;"></th>
                    <th class="text-muted" style="width: 101px !important;">Tutar</th>
                    <th class="text-muted" style="width: 110px !important;">İndirim</th>
                    <th class="text-muted" style="width: 110px !important;">Toplam</th>
                </tr>
                <tr>
                    <th colspan="3"></th>
                    <th class="text-muted">Yedek Parça</th>
                    <td>{{toNumberFormat(orderInfo.order_no_vat_value - orderInfo.order_invoices.labour_price)}}</td>
                    <td>{{partDiscount}}</td>
                    <td>{{toNumberFormat(orderInfo.order_no_vat_value - orderInfo.order_invoices.labour_price)}}</td>
                </tr>
                <tr>
                    <th colspan="3"></th>
                    <th class="text-muted">İşçilik</th>
                    <td >{{toNumberFormat(orderInfo.order_invoices.labour_price)}}</td>
                    <td>{{toNumberFormat(orderInfo.order_invoices.labour_discount_price)}}</td>
                    <td>{{toNumberFormat(orderInfo.order_invoices.labour_price - orderInfo.order_invoices.labour_discount_price)}}</td>
                </tr>
                <tr>
                    <th colspan="3"></th>
                    
                    <th class="text-muted">Kdv</th>
                    <td></td>
                    <td>{{orderInfo.order_invoices.vat}}%</td>
                    <td>{{toNumberFormat(orderInfo.order_invoices.vat_price)}}</td>
                </tr>
                <tr>
                    <th colspan="3"></th>
                   
                    <th class="text-muted">Genel Toplam</th>
                    <td></td>
                    <td></td>
                    <td>{{toNumberFormat(orderInfo.order_invoices.value_in_vat)}}</td>
                </tr>
            </table>
        </div>
    </div>

    <div class="d-flex">
        <table class="table desc-info-table" >
            
            <tbody>
                <tr>
                    <th colspan="2" style="text-align: center; color: #96bc33 !important;">Açıklamalar</th>
                </tr>
                <tr>
                    <th>Ödeme Tipi</th>
                    <td>{{orderInfo?.payment_types?.name}}</td>
                </tr>
                <tr>
                    <th>Randevu Tarihi</th>
                    <td>{{invoice?.date | date:'shortDate'}}</td>
                </tr>
                <tr>
                    <th>İş Türü</th>
                    <td>{{orderInfo?.order_types?.name}}</td>
                </tr>
                <tr>
                    <th>İş Türü Detayı</th>
                    <td>{{orderInfo?.order_types?.name}}</td>
                </tr>
                <tr>
                    <th style="  border: 1px solid #ccc !important;">Açıklama</th>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>


<div style="border: 1px solid #000; padding: 10px; " class="d-none">
    <div class="mw-lg-950px mx-auto w-100" style="padding: 5px; border: 1px solid #f0f0f0;" id="pdfTable" #pdfTable>
        <!-- begin::Header-->
        <div class="d-flex justify-content-center flex-column flex-sm-row mb-19">
            <!--end::Logo-->
            <div class="text-sm">
                <!--begin::Logo-->
                <a href="#" class="d-block mw-150px mx-auto">
                    <img alt="Logo" src="assets/images/logo-garantiliotoservis.svg" class="w-100" />
                </a>
                <!--end::Logo-->
                <!--begin::Text-->
                <div class="text-sm fw-bold fs-4 text-muted mt-7">
                    <div class="text-center">{{gosCompany?.title}}</div>
                    <div class="text-center">{{gosCompany?.address}} </div>
                    <div class="text-center">{{gosCompany?.district?.name}} / {{gosCompany?.City?.name}}</div>
                </div>
                <!--end::Text-->
            </div>
        </div>
    </div>
    
    <div class="d-flex justify-content-between mb-5">
        <div style="width: 420px;">
            <table class="table table-row-dashed table-row-gray-300 w-410px" >
                <tbody>
                    <tr >
                        <th style="width: 150px;" >Servis Adı Ünvanı</th>
                        <td style=" padding:5px" colspan="2">{{serviceInfo?.name}}</td>
                    </tr>
                    <tr>
                        <th>Adresi</th>
                        <td style=" padding:5px" colspan="2">{{serviceInfo?.address}}
                            {{serviceInfo?.district?.name}} / {{serviceInfo?.City?.name}}</td>
                    </tr>
                    <tr>
                        <th>VD-VKN</th>
                        <td style="padding:5px">{{serviceInfo?.vat_office}}</td>
                        <td style="padding:5px">{{serviceInfo?.vat}}</td>
                    </tr>
                    <tr>
                        <th>Yetkili Adı Soyadı</th>
                        <td style=" padding:5px"  colspan="2">{{serviceInfo?.service_company_contacts?.company_owner}}</td>
                    </tr>
                    <tr>
                        <th>Tel No</th>
                        <td style=" padding:5px"  colspan="2">{{serviceInfo?.service_company_contacts?.general_manager_phone}}</td>
                    </tr>
                    <tr>
                        <th>Tel No</th>
                        <td style="padding:5px" colspan="2">{{serviceInfo?.service_company_contacts?.service_manager_phone}}</td>
                    </tr>
                    <tr>
                        <th>Email</th>
                        <td style="padding:5px" colspan="2">{{serviceInfo?.service_company_contacts?.general_manager_email}}</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
        <div style="width: 420px;">
            <table class="table table-row-dashed table-row-gray-300 w-410px" >
                <tbody>
                    <tr>
                        <th  colspan="3">{{gosCompany?.title}}</th>
                        
                    </tr>
                    <tr>
                        <th style="width: 150px;">Adresi</th>
                        <td style="border: 1px solid #ccc; padding:5px" colspan="2">{{gosCompany?.address}}
                            {{gosCompany?.district?.name}} / {{gosCompany?.City?.name}}</td>
                    </tr>
                    <tr>
                        <th>VD-VKN</th>
                        <td style="border: 1px solid #ccc; padding:5px">{{gosCompany?.vat_office}}</td>
                        <td>{{gosCompany?.vat}}</td>
                    </tr>
                    <tr>
                        <th>Yetkili Adı Soyadı</th>
                        <td style="border: 1px solid #ccc; padding:5px" colspan="2">{{gosCompany?.service_company_contacts?.company_owner}}</td>
                    </tr>
                    <tr>
                        <th>Tel No</th>
                        <td style="border: 1px solid #ccc; padding:5px" colspan="2">{{gosCompany?.company_tel?.code}}</td>
                    </tr>
                    <tr>
                        <th>Tel No</th>
                        <td style="border: 1px solid #ccc; padding:5px" colspan="2">{{gosCompany?.company_gsm?.code}}</td>
                    </tr>
                    <tr>
                        <th>Email</th>
                        <td style="border: 1px solid #ccc; padding:5px" colspan="2">{{gosCompany?.company_email?.email}}</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
    </div>
    
    <div class="d-flex justify-content-between mb-5">
        <div style="width: 270px;">
            <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" >
                <tbody>
                    <tr >
                        <th style="width: 150px; align-items: center !important;" colspan="2" >Araç Bilgileri1</th>
                      
                    </tr>
                    <tr>
                        <th>Marka</th>
                        <td style="border: 1px solid #ccc; padding:5px" colspan="2">{{vehicle?.vehicle_model_year?.vehicle_model.vehicle_brand?.name}}</td>
                    </tr>
                    <tr>
                        <th>Model</th>
                        <td style="border: 1px solid #ccc; padding:5px">{{vehicle?.vehicle_model_year?.vehicle_model?.name}}</td>
                        
                    </tr>
                    <tr>
                        <th>Versiyon</th>
                        <td style="border: 1px solid #ccc; padding:5px" >{{vehicle?.name}}</td>
                    </tr>
                    <tr>
                        <th>Yakıt Tipi</th>
                        <td style="border: 1px solid #ccc; padding:5px" >{{vehicle?.vehicle_fuel_type?.name}}</td>
                    </tr>
                    <tr>
                        <th>Vites Tipi</th>
                        <td style="border: 1px solid #ccc; padding:5px" >{{vehicleGearType?.name}}</td>
                    </tr>
                    <tr>
                        <th>Model Yılı</th>
                        <td style="border: 1px solid #ccc; padding:5px" >{{vehicle?.vehicle_model_year?.year_id}}</td>
                    </tr>
                    <tr>
                        <th>Şasi No</th>
                        <td style="border: 1px solid #ccc; padding:5px" >-</td>
                    </tr>
                    <tr>
                        <th>Plaka No</th>
                        <td style="border: 1px solid #ccc; padding:5px" >-</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
        <div style="width: 270px;">
            <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" >
                <tbody>
                    <tr >
                        <th style="width: 150px; align-items: center !important;" colspan="2" >İşlem Bilgileri</th>
                      
                    </tr>
                    <tr>
                        <th>İşlem No</th>
                        <td style="border: 1px solid #ccc; padding:5px" colspan="2">-</td>
                    </tr>
                    <tr>
                        <th>İşlem Tarih/Saat</th>
                        <td style="border: 1px solid #ccc; padding:5px">{{todayDate}}</td>
                        
                    </tr>
                    <tr>
                        <th>Randevu Tarih/Saat</th>
                        <td style="border: 1px solid #ccc; padding:5px" >{{serviceLastDate}}</td>
                    </tr>
                    <tr>
                        <th>Tahmini Teslim Tarihi</th>
                        <td style="border: 1px solid #ccc; padding:5px" >{{deliveryDate}}</td>
                    </tr>
                    <tr>
                        <th>Dosya Tipi</th>
                        <td style="border: 1px solid #ccc; padding:5px" >{{orderTypeInfo?.name}}m</td>
                    </tr>
                    <tr>
                        <th>Ödeme Tipi</th>
                        <td style="border: 1px solid #ccc; padding:5px" >{{paymentTypeInfo?.name}}</td>
                    </tr>
                    
                    
                </tbody>
            </table>
        </div>
        <div style="width: 270px;">
            <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 " >
                <tbody>
                    <tr >
                        <th style="width: 150px; align-items: center !important; font-size: 15px !important;" colspan="3" >Müşteri Bilgileri1</th>
                      
                    </tr>
                    <tr>
                        <th>Müşteri Adı/Ünvanı</th>
                        <td style="border: 1px solid #ccc; padding:5px" colspan="2"></td>
                    </tr>
                    <tr>
                        <th>Adresi</th>
                        <td style="border: 1px solid #ccc; padding:5px" colspan="2" >Cevizli Mahallesi Tugay Yolu Caddesi No:59
                            Maltepe / İstanbul</td>
                        
                    </tr>
                    <tr>
                        <th>VD-VKN</th>
                        <td style="border: 1px solid #ccc; padding:5px">Rs Maltepe</td>
                        <td style="border: 1px solid #ccc; padding:5px">123456789</td>
                    </tr>
                    <tr>
                        <th>TCKN</th>
                        <td style="border: 1px solid #ccc; padding:5px" colspan="2" >Dizel</td>
                    </tr>
                    <tr>
                        <th>Tel No</th>
                        <td style="border: 1px solid #ccc; padding:5px" colspan="2" >12312312</td>
                    </tr>
                    <tr>
                        <th>Tel No</th>
                        <td style="border: 1px solid #ccc; padding:5px" colspan="2" >123123123</td>
                    </tr>
                    <tr>
                        <th>Email</th>
                        <td style="border: 1px solid #ccc; padding:5px" colspan="2" >mehmetozalp@destechhasar.com</td>
                    </tr>
                    
                    
                </tbody>
            </table>
        </div>
    </div>
    
    
    
</div>