import { Injectable } from "@angular/core";
import { Mutation, Query } from "apollo-angular";
import gql from "graphql-tag";

export interface VehicleModelYear{
    id: bigint,
    vehicle_model_id: bigint,
    year_id: bigint,
    active: Boolean,
    year: any
}

interface ResponseArray {
    vehicle_model_years: VehicleModelYear[];
  }
  
interface ResponseSingle {
    vehicle_model_years_by_pk: VehicleModelYear
}

@Injectable()
export class VEHICLE_MODEL_YEAR_ALL extends Query<ResponseArray> {
  override document = gql`
  query VEHICLE_MODEL_YEAR_ALL($where: vehicle_model_years_bool_exp){
    vehicle_model_years(where:  $where) {
      id
      vehicle_model_id
      year_id
      active
      year {
        name
      }
    }
  }`;
}

@Injectable()
export class VEHICLE_MODEL_YEAR_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query VEHICLE_MODEL_YEAR_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc) {
      vehicle_model_years(limit: $limit, offset: $offset, order_by: {id: $orderBy}) {
        id
        vehicle_model_id
        vehicle_model_year_id
        year_id
        active
        year {
            name
        }
      }
    }
  `;
}

@Injectable()
export class VEHICLE_MODEL_YEAR_COUNT extends Query<any> {
  override document = gql`
    query VEHICLE_MODEL_YEAR_COUNT($active: Boolean = true) {
        vehicle_model_years_aggregate( where: {active: {_eq: $active}}) {
        aggregate {
          count
        }
      }
    }
  `;
}

@Injectable()
export class VEHICLE_MODEL_YEAR_DELETE extends Mutation<any> {
  override document = gql`
    mutation VEHICLE_MODEL_YEAR_DELETE($id: bigint!) {
        delete_vehicle_model_years(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

Injectable()
export class VEHICLE_MODEL_YEAR_CREATE extends Mutation<ResponseSingle> {
  override document = gql`
  mutation VEHICLE_MODEL_YEAR_CREATE($active: Boolean, $vehicle_model_id: Int,$year_id:Int) {
    insert_vehicle_model_years(objects: {active: $active,  vehicle_model_id: $vehicle_model_id,year_id:$year_id}) {
      returning {
        id
      }
      affected_rows
    }
  }`;
}

@Injectable()
export class VEHICLE_MODEL_YEAR_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
  mutation VEHICLE_MODEL_YEAR_UPDATE($id:bigint, $active: Boolean, $vehicle_model_id: Int,$year_id:bigint) {
    update_vehicle_model_years(where: {id: {_eq: $id}}, _set: {active: $active, vehicle_model_id: $vehicle_model_id, year_id: $year_id}) {
      returning {
        id
      }
      affected_rows
    }
  }
   
  `;
}

@Injectable()
export class VEHICLE_MODEL_YEAR_ID extends Query<ResponseSingle> {
  override document = gql`
  query VEHICLE_MODEL_YEAR_ID($id: bigint = "") {
    vehicle_model_years_by_pk(id: $id) {
      id
      vehicle_model_id
      year_id
      active
      vehicle_model {
        id
        name
        vehicle_brand {
          id
          name
        }
        
      }
    }
  }
  `;
}