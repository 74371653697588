<div class="d-flex align-items-center selectwo"  style="position: relative;">
    <div
      class="form-select form-select-solid slctinput position-relative  selectwo"
     
    >
      <!--begin::Label-->
      <div class="text-muted fs-7 selectwo" style="color:#015029 !important" [innerHTML]="inputval"></div>
      <!--end::Label-->
    </div>

    
    <div 
        style="top:30px; width:100%; left: 0px;"
       
       id="{{id}}" 
        class="menu menu-sub menu-sub-dropdown p-7  position-absolute selectwo">
        <!--begin::Wrapper-->
        <div class="selectwo">
            
            <!--begin::Form-->
            <form class="w-100 position-relative mb-3" action="javascript::void(0)">
          
        
            <!--begin::Input-->
            <input type="text" 
            class="searchinput form-control ps-10" 
            (keyup)="search($event)" 
            name="search" value="" 
            autocomplete="off" autofill="off"
            placeholder="Ara..." 
            />
            <!--end::Input-->
            
            </form>
            <!--end::Form-->
        
            <!--begin::Separator-->
            <div class="separator border-gray-200 mb-6"></div>
            <!--end::Separator-->
        
        
            <!--begin::Recently viewed-->
            <div class="selectwo">
            <!--begin::Items-->
            <div class="scroll-y mh-200px mh-lg-325px selectwo">
                <!--begin::Category title-->
                <h3 class="fs-5 text-muted m-0 pb-5" style="display: none;">
                {{name}}
                </h3>
                <span class="fs-5 text-muted m-0 pb-5" *ngIf="lists == undefined || lists.length == 0">
                    Hiç bir sonuç bulunamadı!
                </span>
                <!--end::Category title-->

                <div class=" mb-5 form-check form-check-solid fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                    <input name="selectAllInputId" value="selectAll" class="form-check-input" (change)="selectItem($event)" type="checkbox" value="" [id]="selectAllInputId">
                    <label class="form-check-label fw-bold ps-2 fs-6" [for]="selectAllInputId">Hepsini Seç</label>
                <div class="fv-plugins-message-container invalid-feedback"></div></div>

         
                <div style="margin-bottom:20px;"  *ngFor="let item of filteringItems" 
                 class="form-check form-check-solid fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                    <input  
                        [disabled]="item.disabled"
                        name="selectedList[{{item.id}}]" 
                        class="form-check-input" 
                        (change)="selectItem($event)"
                        [checked]="isChecked(item[returnField])" 
                        type="checkbox" 
                        [value]="item.id" 
                        [id]="'ret_'+item.id">
                    <label [ngClass]="{'alert': item.disabled,'alert-danger':item.disabled}" class="form-check-label fw-bold ps-2 fs-6" [for]="'ret_'+item.id">{{item[fieldName]}}</label>
                <div class="fv-plugins-message-container invalid-feedback"></div></div>
              
                
            </div>
            <!--end::Items-->
            </div>
            <!--end::Recently viewed-->
        
        </div>
        <!--end::Wrapper-->
        </div>
        
