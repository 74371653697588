import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';
import { OzanpayRequest } from './ozanpay_request.graphql';
import { PaymentTypes } from './payment_types.graphql';
import { User } from './user.graphql';

export interface SuccessfulPayments {
  id: bigint
  order_id: bigint
  payment_type_id: bigint
  payment_value: number
  created_at: Date
  updated_at: Date
  created_by: bigint
  request_id: bigint
  ozanpay_requests: OzanpayRequest
  payment_types: PaymentTypes
  user: User
}


interface ResponseArray {
  successful_payments: SuccessfulPayments[];
}

interface ResponseSingle {
    successful_payments_by_pk: SuccessfulPayments
}



interface Aggregate {
    successful_payments_aggregate: {
    aggregate: {
      count: number
    }
  }
}



@Injectable()
export class SUCCESSFUL_PAYMENTES_ALL extends Query<ResponseArray> {
  override document = gql`
  query SUCCESSFUL_PAYMENTES_ALL($where: successful_payments_bool_exp){
    successful_payments(where: $where) {
      id
      order_id
      payment_type_id
      payment_value
      created_at
      created_by
      request_id
      payment_types{
        id
        name
      }
      ozanpay_requests{
        reference_no
        order_id
        ozanpay_successfuls{
            status
            code
            data
            captured
            
        }
      }
      user{
        id
        name
        surname
        identity_no
        email
        role_id
        role{
            id
            type
        }
      }
    }
  }`;
}

@Injectable()
export class SUCCESSFUL_PAYMENTES_CREATE extends Mutation<any> {
  override document = gql`
  mutation SUCCESSFUL_PAYMENTES_CREATE($order_id: bigint!, $payment_type_id: bigint!, $payment_value:numeric!, $created_at: timestamp, $created_by:bigint,$request_id:bigint) {
    insert_successful_payments(objects: {
        order_id: $order_id, 
        payment_type_id: $payment_type_id, 
        payment_value: $payment_value,
        created_at: $created_at,
        created_by: $created_by,
        request_id: $request_id
    }) {
            affected_rows
            returning {
            id
            }
    
        }
    
    
  }`;
}

@Injectable()
export class SUCCESSFUL_PAYMENTES_UPDATE extends Mutation<ResponseSingle> {
    override document = gql`
  mutation SUCCESSFUL_PAYMENTES_UPDATE($id: Int!, $order_id: Bigint!, $payment_type_id: Bigint!, $payment_value:Number!, $updated_at: Date, $created_by:Bigint) {
    update_successful_payments(where: {id: {_eq: $id}}, _set: {order_id: $order_id, payment_type_id: $payment_type_id, payment_value: $payment_value, updated_at: $updated_at,  created_by: $created_by}) {
      affected_rows
    }
  }`;
}


@Injectable()
export class SUCCESSFUL_PAYMENTES_ID extends Query<ResponseSingle>{
  override document = gql`
  query SUCCESSFUL_PAYMENTES_ID($id: Int!) {
    successful_payments_by_pk(id: $id) {
        id
      order_id
      payment_type_id
      payment_value
      created_at
      created_by
      request_id
    }`;
}

@Injectable()
export class SUCCESSFUL_PAYMENTES_PAGINATE extends Query<ResponseArray> {
    override document = gql`
  query SUCCESSFUL_PAYMENTES_PAGINATE($limit: Int, $offset: Int, $where: successful_payments_bool_exp) {
    successful_payments(limit: $limit, offset: $offset, order_by: {position: desc}, where: $where) {
        id
        order_id
        payment_type_id
        payment_value
        created_at
        created_by
        request_id
    }
  }`;
}

@Injectable()
export class SUCCESSFUL_PAYMENTES_COUNT extends Query<Aggregate> {
  override document = gql`
    query SUCCESSFUL_PAYMENTES_COUNT($where: successful_payments_bool_exp) {
        successful_payments_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}