import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { APPOINTMENT_PACKAGE_ID, Package, PACKAGE_ID } from "src/app/graphql/package.graphql";
import { PackageModelType, PACKAGE_MODEL_TYPE_ALL, PACKAGE_MODEL_TYPE_ID } from "src/app/graphql/package_model_type.graphql";
import { PartModelTypes, PART_MODEL_TYPES_GET } from "src/app/graphql/part_model_types.graphql";
import { PieceImages } from "src/app/graphql/piece_images.graphql";
import { numberFormat, numberFormatStr, numberFormatWithoutPennies } from "src/app/helpers/slugify";
import { environment } from "src/environments/environment";
import { AlertifyService } from "../alertify.service";


@Injectable({
    providedIn: 'root'
})

export class PackageParserService {

    public currentSlide = 0;
  public direction = 1;
  public imageWidth: any;
  public isReversed = false;
  public intevalElem: any;

  public packageModelType: PackageModelType | undefined;
  public package: Package | undefined;
  public originalList: any = [];
  public premiumList: any = [];
  public equivalentList: any = [];

  public premiumObjects:any = [];
  public equivalentObjects:any = [];
  public equivalentReutnObject:any = [];

  public premiumOfferList: any = [];
  public equivalentOfferList: any = [];
  public premiumReutnObject:any = [];

  public originalAmounts:any = [];
  public premiumAmounts:any= [];
  public equivalentAmounts: any = [];
  
  public totalLabourTime = 0;
  public originalLabourPrice = 0;
  public premiumLabourPrice = 0;
  public equivalentLabourPrice = 0;
  public originalLabourHour = 0;
  public premiumLabourHour = 0;
  public equivalentLabourHour = 0;

  public selectedPackage: any;
  public selectedPaymentType = 0;

  public totalLabourHour = 0;

  public selectedPackageName = "";

  public pieceImages: PieceImages[] | undefined;

  public packageInfo:any;

  public totalOriginalLabour:any = [];
  public totalPremiumLabour:any;
  public totalEquivalentLabour:any;

  public partMopdelTypes: PartModelTypes | undefined;




  public packageForm = new FormGroup({
    maintenancePackageId: new FormControl(null,[Validators.required]),
    paymetnType: new FormControl([null,[Validators.required]])
  })

  public showPaymentButtons: boolean = false;

    public constructor(
        private getPackage : APPOINTMENT_PACKAGE_ID,
        private alertifyService: AlertifyService,
        private getVehicleModelType: PACKAGE_MODEL_TYPE_ALL,
        private getPartModelTypes: PART_MODEL_TYPES_GET
    ){

    }
    
    async parsePackage(packageId:number | undefined,modelId: number | undefined){

       return new Promise(async (resolve,reject)=>{

            await this.getVehicleModelType.watch({where:{
                _and:{
                    maintenance_packages: {id: {_eq: packageId}}
          
                  }
            }}).valueChanges.subscribe(async resp => {
                if(resp.errors){
                    this.alertifyService.error(resp.errors[0].message);
                }
                if(resp.data){
                    this.packageModelType = resp.data.maintenance_package_model_types[0];
                    await this.getPackage.watch({id:packageId,model_id:modelId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(async packageResponse=>{
                        if(packageResponse.error){
                          this.alertifyService.error("Bir hata oluştu. Lütfen tekrar deneyiniz.");
                          return false;
                        }
        
                        if(packageResponse.data){
                            
                            this.premiumObjects = [];
                            this.equivalentObjects = [];
                            this.package = packageResponse.data.maintenance_packages_by_pk;

                            let originalLabourPrice = 0;
                            let equivalentLabourPrice = 0;
                            let premiumLabourPrice = 0;
                            await this.package.maintenance_package_details.forEach(async element => {
        
                             

        
                              if( element.part.brand_labour_hour != null && element.part_unit_type != 3){

                             
                                if(this.packageModelType){
                                  
                                  await this.getPartModelTypes.watch({part_id:element.part.id, vehicle_model_type_id:this.packageModelType.vehicle_model_types.id },{fetchPolicy:"no-cache"}).valueChanges.subscribe(partModelResonse => {

                                    if(partModelResonse.errors){
                                      this.alertifyService.error(partModelResonse.errors[0].message);
                                    }

                                    if(partModelResonse.data){
                                      this.partMopdelTypes = partModelResonse.data.part_model_types[0];


                                      let labourHour: number = 0;
                                      if(
                                        this.partMopdelTypes.brand_labour_hour &&
                                        typeof this.partMopdelTypes.brand_labour_hour === 'number' && // Kontrol ekle
                                        this.partMopdelTypes.brand_labour_hour > 0 &&
                                        typeof environment.labourHourDiscountPercentage === 'number' // Kontrol ekle
                                      ){
                                        labourHour        = (this.partMopdelTypes.brand_labour_hour - ((this.partMopdelTypes.brand_labour_hour * environment.labourHourDiscountPercentage )/ 100));                 
                                        originalLabourPrice   = (labourHour * this.packageModelType?.vehicle_model_types.vehicle_model_year.vehicle_model.brand_labour_price);
                                          equivalentLabourPrice = (labourHour * this.packageModelType?.vehicle_model_types.vehicle_model_year.vehicle_model.gos_labour_price);
                                          premiumLabourPrice    = (labourHour * this.packageModelType?.vehicle_model_types.vehicle_model_year.vehicle_model.gos_labour_price);
                                          this.originalLabourHour = labourHour;
                                          this.premiumLabourHour = labourHour;
                                          this.equivalentLabourHour = labourHour;
                
                                          let labourElement = {
                                            original_id: element.part_original_id,
                                            part_name: element.part.name,
                                            brand_labour_price: this.packageModelType?.vehicle_model_types.vehicle_model_year.vehicle_model.brand_labour_price,
                                            gos_labour_price: this.packageModelType?.vehicle_model_types.vehicle_model_year.vehicle_model.gos_labour_price,
                                            raw_labour_hour: element.part.brand_labour_hour,
                                            percentage: environment.labourHourDiscountPercentage,
                                            processedLabourHour: labourHour
        
                                          }
        
                                          this.totalOriginalLabour.push(labourElement);

                                        this.originalLabourPrice += originalLabourPrice;
                                        this.equivalentLabourPrice += equivalentLabourPrice;
                                        this.premiumLabourPrice += premiumLabourPrice;
                                        this.totalLabourTime += labourHour;
                                      
                                      }
                                   
    
                                    }

                                  });

                              }  
                                
                            }
                              let originalObj = {
                                package_detail_id: element.id,
                                id:element.part_original_list.id,
                                part_id: element.part_id,
                                part_type_id: element.part_original_list.part_type_id,
                                part_value: element.part_original_list.part_in_vat_price,
                                selected_part_id: element.part_original_list.id,
                                part_unit_type: element.part_unit_type,
                                part_unit:  (element.part_unit_type == 3) ? this.totalLabourTime : element.part_unit,
                                brand_labour_price: 0,
                                gos_labour_price: 0,
                                total_price_no_vat: (element.part_original_list.part_no_vat_price * element.part_unit),
                                total_price: (element.part_original_list.part_in_vat_price * element.part_unit),
                                part_code: element.part_original_list.part_code,
                                part_no_vat_price: element.part_original_list.part_no_vat_price,
                                brand_labour_hour: element.part.brand_labour_hour,
                                part_labour_price: (element.part_original_list.part_type_id == 9) ? 0 : this.packageModelType?.vehicle_model_types.vehicle_model_year.vehicle_model.brand_labour_price,
                                labour_price: originalLabourPrice,
                                original_name: element.part_original_list.name,
                                part_original_id: element.part_original_list.id,
                                equivalent_id: 0,
                                count: 0,
                                totalPartInVatPrice: (element.part_unit * element.part_original_list.part_in_vat_price),
                                totalPartNoVatPrice: (element.part_unit * element.part_original_list.part_no_vat_price),
                                part_in_vat_price: element.part_original_list.part_in_vat_price,
                                price_unit: element.part_original_list.price_unit,
                                price_unit_id: element.part_original_list.price_unit_id,
                                part_types: element.part_original_list.part_types,
                                positon_no: element.part.position_no,
                                part_group_position_no:  (element.part?.part_group?.position_no == undefined ? 0 : element.part?.part_group?.position_no),
                                newNoVatPrice: element.part_original_list.part_no_vat_price,
                                newVatPrice: element.part_original_list.part_in_vat_price,
                                part_labour_hour:element.part.brand_labour_hour,
                                part_group_note: element.part_group_list?.note
                                
                              }
        
                              this.originalList.push(originalObj);
                          
                          
                              await element.part_original_list.equivalent_models?.forEach((equivalent_model:any,key:number)=> {
                               let equivalent = equivalent_model.part_equivalent_list;
                                  if(equivalent.part_type_id == 2 || equivalent.part_type_id == 9){
          
                                    let premiumObj =  this.setPremiumObj(element,equivalent,premiumLabourPrice);
                                  
                                    this.premiumList.push(equivalent);
                                    premiumObj.count += 1;
                                    premiumObj.totalPartInVatPrice += (element.part_unit * equivalent.part_in_vat_price),
                                    premiumObj.totalPartNoVatPrice += (element.part_unit * equivalent.part_no_vat_price)
          
                                
                                    if(this.premiumObjects[element.part_original_list.id] == undefined){
                                      this.premiumObjects[element.part_original_list.id] = [];
                                    }
                                    this.premiumObjects[element.part_original_list.id].push(premiumObj);
          
                                  }
          
                                  if(equivalent.part_type_id == 3  || equivalent.part_type_id == 9){
          
                                    let equivalentObj = this.setEquivalentObj(element,equivalent,equivalentLabourPrice);
                                    
                                    //this.equivalentList.push(equivalent);
                                    equivalentObj.count += 1;
                                    equivalentObj.totalPartInVatPrice += (element.part_unit * equivalent.part_in_vat_price);
                                    equivalentObj.totalPartNoVatPrice += (element.part_unit * equivalent.part_no_vat_price);
                              
          
                                    if(this.equivalentObjects[element.part_original_list.id] == undefined){
                                      this.equivalentObjects[element.part_original_list.id] = [];
                                    }
          
                                    this.equivalentObjects[element.part_original_list.id].push(equivalentObj);
                                  }
          
                              
                              })
                              
                             
        
                              await element.part_original_list.part_equivalent_lists.forEach((equivalent:any,key:number) => {
                              
                                if(equivalent.part_unit == 3){
                                  if(equivalent.part_type_id == 2 || equivalent.part_type_id == 9){
                                    let premiumObj =  this.setPremiumObj(element,equivalent,premiumLabourPrice);
                                  
                                    this.premiumList.push(equivalent);
                                    premiumObj.count += 1;
                                    premiumObj.totalPartInVatPrice += (element.part_unit * equivalent.part_in_vat_price),
                                    premiumObj.totalPartNoVatPrice += (element.part_unit * equivalent.part_no_vat_price)
        
                                    if(this.premiumObjects[element.part_original_list.id] == undefined){
                                      this.premiumObjects[element.part_original_list.id] = [];
                                    }
                                    this.premiumObjects[element.part_original_list.id].push(premiumObj);
                                  }
        
                                  if(equivalent.part_type_id == 3  || equivalent.part_type_id == 9){
                                    let equivalentObj = this.setEquivalentObj(element,equivalent,equivalentLabourPrice);
                                  
                                    //this.equivalentList.push(equivalent);
                                    equivalentObj.count += 1;
                                    equivalentObj.totalPartInVatPrice += (element.part_unit * equivalent.part_in_vat_price);
                                    equivalentObj.totalPartNoVatPrice += (element.part_unit * equivalent.part_no_vat_price);
                              
          
                                    if(this.equivalentObjects[element.part_original_list.id] == undefined){
                                      this.equivalentObjects[element.part_original_list.id] = [];
                                    }
          
                                    this.equivalentObjects[element.part_original_list.id].push(equivalentObj);
                                  }
        
        
                                }
                              });
          
                              
             
                            });
        
                          
        
                            await this.premiumObjects.forEach(async element => {
        
        
                              if(element.part_unit_type != 3){
                                let result = await this.findClosestItem(element);
                          
                                if(result.brand_labour_hour != null){
                                  let labourHour = (result.brand_labour_hour - ((result.brand_labour_hour  *  environment.labourHourDiscountPercentage )/ 100));
                                  let pLabourPrice = labourHour * result.part_labour_price;
                                  result.part_labour_hour = result.brand_labour_hour;
                                  result.labour_price = pLabourPrice;
                                  result.totalPartNoVatPrice = pLabourPrice;
                                
                               //   this.premiumLabourPrice += pLabourPrice;
                                
                                }
                              
                                  this.premiumOfferList.push(result);
                              }else{
                                this.premiumOfferList.push(element);
                              }
        
                             
                            });
    
                       
                            this.premiumOfferList = this.sortByName(this.premiumOfferList);
                            
                       
                            await this.equivalentObjects.forEach(async element => {
                              if(element.part_unit_type != 3){
                                let result = await this.findClosestItem(element);
                                if(result.brand_labour_hour != null){
          
                                  let labourHour = (result.brand_labour_hour - ((result.brand_labour_hour  *  environment.labourHourDiscountPercentage )/ 100))
                                  let eLabourPrice = labourHour * result.part_labour_price;
                              //    this.equivalentLabourPrice += eLabourPrice;
                                  result.part_labour_hour = result.brand_labour_hour;
                                  result.labour_price = eLabourPrice;
                                  result.totalPartNoVatPrice = eLabourPrice;
                                }
                                this.equivalentOfferList.push(result);
                              }else{
                                this.equivalentOfferList.push(element);
                              }
                             
                             
                            });
                           
        
                           this.equivalentOfferList = await this.sortByName(this.equivalentOfferList);
        
        
                              await this.originalList.map((i)=> { 
                                if(i.part_unit_type == 3){

                                  i.part_in_vat_price = this.originalLabourPrice
                                  i.part_no_vat_price = this.originalLabourPrice;
                                  i.newNoVatPrice = this.originalLabourPrice;
                                  i.newVatPrice = i.part_in_vat_price;
                                  i.totalPartNoVatPrice = this.originalLabourPrice;
                                  i.totalPartInVatPrice = i.part_in_vat_price;
                                  i.totalLabourHour = this.totalLabourTime;
                                  i.part_unit = this.totalLabourTime;
                                }
                              });
        
                              await this.equivalentOfferList.map((i)=> { 
                                if(i.part_unit_type == 3){
                                  i.part_in_vat_price = this.equivalentLabourPrice
                                  i.part_no_vat_price = this.equivalentLabourPrice;
                                  i.newNoVatPrice = this.equivalentLabourPrice;
                                  i.newVatPrice = i.part_in_vat_price;
                                  i.totalPartNoVatPrice = i.part_no_vat_price;
                                  i.totalPartInVatPrice = i.part_in_vat_price;
                                  i.totalLabourHour = this.totalLabourTime;
                                  i.part_unit = this.totalLabourTime;
                                  i.part_value = this.equivalentLabourPrice;
                                }
                              });
                        
                              await this.premiumOfferList.map((i)=> { 
                                if(i.part_unit_type == 3){
                                    
                                  i.part_in_vat_price = this.premiumLabourPrice 
                                  i.part_no_vat_price = this.premiumLabourPrice;
                                  i.newNoVatPrice = this.premiumLabourPrice;
                                  i.newVatPrice = i.part_in_vat_price;
                                  i.totalPartNoVatPrice = i.part_no_vat_price;
                                  i.totalPartInVatPrice = i.part_in_vat_price;
                                  i.totalLabourHour = this.totalLabourTime;
                                  i.part_unit = this.totalLabourTime;
                                  i.part_value = this.premiumLabourPrice;
                                }
                              });
                            
                              //this.equivalentOfferList = this.sortByName(this.equivalentOfferList);
                              //this.originalList = this.sortByName(this.originalList);
       
                            await this.setAmounts();
                           resolve(this.packageInfo);
                          /*  this.setSelectedPackage('premium');
                            if(packageId &&  packageId > 0){
                              await this.setSelectedPackage(this.package.name);
                             // await this.setSliderPositions(this.selectedPackageInput.selectedPackageClass,false);
                             
                            }*/
        
                        }
                      })
    
                }
            })
            


        }).then(() => {
            return this.packageInfo;
        });

       
     //   setTimeout(()=>{
          //  if(this.packageInfo){
          
             
           // }
     //   },1000);
      
       
     //  this.getImageList(2);
       
    }

    setPremiumObj(element:any,equivalent:any,premiumLabourPrice){
        return {
          package_detail_id: element.id,
          part_id: element.part_id,
          part_type_id: equivalent.part_type_id,
          part_value: equivalent.part_in_vat_price,
          id:equivalent.id,
          selected_part_id: equivalent.id,
          part_unit_type: element.part_unit_type,
          part_unit: (element.part_unit_type == 3) ? this.totalLabourTime : element.part_unit,
          brand_labour_price: 0,
          gos_labour_price: 0,
          price_unit_id: equivalent.price_unit_id,
          total_price_no_vat: (equivalent.part_no_vat_price * element.part_unit),
          total_price: (equivalent.part_in_vat_price * element.part_unit),
          part_code: equivalent.part_code,
          part_no_vat_price: equivalent.part_no_vat_price,
          brand_labour_hour: element.part.brand_labour_hour,
          part_labour_price: (equivalent.part_type_id == 9) ? 0 : this.packageModelType?.vehicle_model_types.vehicle_model_year.vehicle_model.gos_labour_price,
          labour_price: premiumLabourPrice,
          original_name: element.part_original_list.name,
          premium_name: equivalent.name,
          part_original_id: element.part_original_list.id,
          equivalent_id: equivalent.id,
          count: 0,
          totalPartInVatPrice: 0,
          totalPartNoVatPrice: 0,
          part_in_vat_price: equivalent.part_in_vat_price,
          price_unit: equivalent.price_unit,
          part_types: equivalent.part_types,
          position_no:  element.part.position_no,
          part_group_position_no: (element.part?.part_group?.position_no == undefined ? 0 : element.part?.part_group?.position_no),
          part_group_note: element.part_group_list?.note
        }
      }
    
      setEquivalentObj(element:any,equivalent:any,equivalentLabourPrice){
        return {
          package_detail_id: element.id,
          id:equivalent.id,
          part_id: element.part_id,
          part_type_id: equivalent.part_type_id,
          part_value: equivalent.part_in_vat_price,
          selected_part_id: equivalent.id,
          part_unit_type: element.part_unit_type,
          part_unit:  (element.part_unit_type == 3) ? this.totalLabourTime : element.part_unit,
          brand_labour_price: 0,
          gos_labour_price: 0,
          price_unit_id: equivalent.price_unit_id,
          total_price_no_vat: (equivalent.part_no_vat_price * element.part_unit),
          total_price: (equivalent.part_in_vat_price * element.part_unit),
          part_code: equivalent.part_code,
          part_no_vat_price: equivalent.part_no_vat_price,
          brand_labour_hour: element.part.brand_labour_hour,
          part_labour_price: (equivalent.part_type_id == 9) ? 0 : this.packageModelType?.vehicle_model_types.vehicle_model_year.vehicle_model.gos_labour_price,
          labour_price: (equivalent.part_unit == 3) ? 0 : equivalentLabourPrice,
          original_name: element.part_original_list.name,
          equivalent_name: equivalent.name,
          part_original_id: element.part_original_list.id,
          equivalent_id: equivalent.id,
          count: 0,
          totalPartInVatPrice: 0,
          totalPartNoVatPrice: 0,
          part_in_vat_price: equivalent.part_in_vat_price,
          price_unit: equivalent.price_unit,
          part_types: equivalent.part_types,
          position_no:  element.part.position_no,
          part_group_position_no: (element.part?.part_group?.position_no == undefined ? 0 : element.part?.part_group?.position_no),
          part_group_note: element.part_group_list?.note
        };
      }
    
    
    
       sortByName(dizi) {
       
       /* return dizi.sort((a, b) => {
          const nameA = a.position_no;
          const nameB = b.position_no;
          if (nameA > nameB) {
            return -1;
          } else if (nameA < nameB) {
            return 1;
          } else {
            return 0;
          }
        });*/
        return dizi.sort((a, b) => {
        
    
          if (a.part_group_position_no === b.part_group_position_no) {
            return b.position_no - a.position_no;
          }
          return b.part_group_position_no - a.part_group_position_no;
        });
      }
    
    
      findClosestItem(items) {
        const totalPartInVatPrices = items.map(item => item.totalPartInVatPrice);
        const average = totalPartInVatPrices.reduce((sum, price) => sum + price, 0) / totalPartInVatPrices.length;
      
        let closestItem = items[0];
        let minDiff = Math.abs(items[0].totalPartInVatPrice - average);
      
        for (let i = 1; i < items.length; i++) {
          const diff = Math.abs(items[i].totalPartInVatPrice - average);
          if (diff < minDiff) {
            minDiff = diff;
            closestItem = items[i];
          }
        }
    
        let prices:any[] = [];
        items.forEach(element => {
          prices.push(element.totalPartNoVatPrice);
        });
      
        const newVatPrice = totalPartInVatPrices.reduce((sum, price) => sum + price, 0) / totalPartInVatPrices.length;
        const newNoVatPrice = items.reduce((sum, item) => sum + item.totalPartNoVatPrice, 0) / items.length;
      
        return {
          ...closestItem,
          newVatPrice,
          newNoVatPrice,
           prices
        };
      }
    
      async setAmounts(){
        this.originalAmounts = {
          total: 0,
          kdv:0,
          generalTotal: 0,
          labourPrice: 0,
          labourHour: 0
        }
        await this.originalList.forEach(element => {
            this.originalAmounts.generalTotal += element.totalPartInVatPrice;
            this.originalAmounts.total += element.totalPartNoVatPrice;        
        });
    
     //   this.originalAmounts.total += this.originalLabourPrice;
       // this.originalAmounts.generalTotal  += this.originalLabourPrice;
        this.originalAmounts.labourPrice = this.originalLabourPrice;
        this.originalAmounts.labourHour = this.originalLabourHour;
        this.originalAmounts.kdv  = (this.originalAmounts.generalTotal - this.originalAmounts.total);
    
    
        this.premiumAmounts = {
          total: 0,
          kdv:0,
          generalTotal: 0,
          labourPrice: 0,
          labourHour: 0
        }
        await this.premiumOfferList.forEach(element => {
            this.premiumAmounts.generalTotal += element.newVatPrice;
            this.premiumAmounts.total += element.newNoVatPrice;
        });
        this.premiumAmounts.generalTotal = parseFloat(numberFormat(this.premiumAmounts.generalTotal).replace(".","").replace(",","."));
        this.premiumAmounts.total = parseFloat(numberFormat(this.premiumAmounts.total).replace(".","").replace(",","."));
    
       // this.premiumAmounts.total += this.premiumLabourPrice;
        //this.premiumAmounts.generalTotal  += this.premiumLabourPrice;
        //this.premiumAmounts.labourPrice = this.premiumAmounts.total = parseFloat(numberFormat(this.premiumLabourPrice).replace(".","").replace(",","."));
        this.premiumAmounts.labourPrice = this.premiumAmounts.total;
        this.premiumAmounts.labourHour = this.premiumLabourHour;
        this.premiumAmounts.kdv  = ( this.premiumAmounts.generalTotal - this.premiumAmounts.total );
        
      
    
    
    
        this.equivalentAmounts = {
          total: 0,
          kdv:0,
          generalTotal: 0,
          labourPrice: 0,
          labourHour: 0
        }
       
        await this.equivalentOfferList.forEach(element => {
            this.equivalentAmounts.generalTotal += element.newVatPrice;
            this.equivalentAmounts.total += element.newNoVatPrice;
        });
        
        //this.equivalentAmounts.total += this.equivalentLabourPrice;
        //this.equivalentAmounts.generalTotal  += this.equivalentLabourPrice;
        this.equivalentAmounts.labourPrice = this.equivalentLabourPrice;
        this.equivalentAmounts.labourHour = this.equivalentLabourHour;
        this.equivalentAmounts.kdv  = this.equivalentAmounts.generalTotal - this.equivalentAmounts.total;
    
        this.packageInfo = {
            premiumAmount: this.premiumAmounts,
            originalAmounts: this.originalAmounts,
            equivalentAmounts: this.equivalentAmounts,
            premiumList: this.premiumOfferList,
            equivalentList: this.equivalentOfferList,
            originalList: this.originalList,
            packageInfo: this.package
        }


    

       
      }
    
      filterCode(item:any){
        const firstThreeDigits = item.substr(0, 3);
        const remainingChars = item.substr(3);
       // const convertedString = firstThreeDigits + remainingChars.replace(/./g, '*');
        const convertedString = firstThreeDigits + '****';
        return convertedString;
      }
    
      setSelectedPackage(type:String){
        this.selectedPackage = undefined;
        this.showPayments(false);
        switch(type){
          case "original":
            this.selectedPackageName = "Orjinal";
            this.selectedPackage = {
              "amount":this.originalAmounts,
              "packageParts": this.originalList,
              "partTypeId": this.originalList[0].part_type_id,
              "type": "original",
              "packageInfo": this.package,
              "paymentType": this.selectedPaymentType,
              "selectedPackageTypeId": 1,
              "selectedPackageName": "original",
              "selectedPackageClass": {
                first: "animate-last last-item",
                second: "animate-first first-item",
                last: "animate-middle second-item"
              }
              }
              this.selectedPackage.selectedPackageParts = this.originalList;
          break;
    
          case "premium":
            this.selectedPackageName = "Premium";
            this.selectedPackage = {
              "amount":this.premiumAmounts,
              "packageParts": this.premiumOfferList,
              "partTypeId": this.premiumOfferList[0].part_type_id,
              "type": "premium",
              "packageInfo": this.package,
              "paymentType": this.selectedPaymentType,
              "selectedPackageTypeId": 2,
              "selectedPackageName": "premium",
              "selectedPackageClass": {
                first:  "animate-middle second-item",
                second: "animate-last last-item",
                last: "animate-first first-item"
                }
             
            }
            this.selectedPackage.selectedPackageParts = this.premiumOfferList;
          break;
    
          case "equivalent":
            this.selectedPackageName = "Eşdeğer";
            this.selectedPackage = {
              "amount":this.equivalentAmounts,
              "packageParts": this.equivalentOfferList,
              "partTypeId": this.equivalentOfferList[0].part_type_id,
              "type": "equivalent",
              "packageInfo": this.package,
              "paymentType": this.selectedPaymentType,
              "selectedPackageTypeId": 3,
              "selectedPackageName": "equivalent",
              "selectedPackageClass": {
                first: "animate-first first-item",
                second: "animate-middle second-item",
                last: "animate-last last-item"
              }
             
            }
    
            this.selectedPackage.selectedPackageParts = this.equivalentOfferList;
          break;
    
         
        }
    
      
    
    
    
        if(this.selectedPackage ){
          this.showPayments(true);
          
    
           // this.selectedPackageEmitter.emit(this.selectedPackage);
          return this.selectedPackage;
       
        }
      }
    
      showPayments(status:boolean){
        this.showPaymentButtons = status;
      }
    
      setPaymentType(type:string){
        if(type == "online"){
          this.selectedPaymentType = 1;
         
        }
        
        if(type == "offline"){
          this.selectedPaymentType = 2;
         
        }
    
        this.packageForm.patchValue({
          paymentType: this.selectedPaymentType,
          maintenancePackageId: this.selectedPackage?.packageInfo?.id
        });
    
        this.selectedPackage.paymentType = this.selectedPaymentType;
    
        if(this.packageForm.invalid){
          this.alertifyService.error("Lütfen paket ve ödeme türü seçiniz");
          return false;
        }
        
    
        if(this.packageForm.valid){
    
            return this.selectedPackage;
         // this.selectedPackageEmitter.emit(this.selectedPackage);
        };
      }
    
     
    
      toNumberFormat(number: any) {
        return numberFormatStr(number);
      }
    
      toNumberFormatWithoutPennies(number: any) {
        return numberFormatWithoutPennies(number);
      }
    
      setSliderPositions(classes,position){
       
    
        let selectedPartType = 2;
        switch(classes.second){
          case "animate-first first-item":
            this.setSelectedPackage('premium');
            selectedPartType = 2;
          break;
    
          case "animate-middle second-item":
            this.setSelectedPackage('original');
            selectedPartType = 1;
          break;
    
          case "animate-last last-item":
            this.setSelectedPackage('equivalent');
            selectedPartType = 3;
          break;
        }
        this.currentSlide = 0;
        
        clearInterval(this.intevalElem);
        
        
      }
    
      removePennies($price){
        
        return this.toNumberFormatWithoutPennies($price)
      }
    
   
    
}