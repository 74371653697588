import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface Part {
  id: Number;
  name: String;
  part_group_id?: Number;
  connected_part_id?: Number;
  image_url?: String;
  note?: String;
  active?: Boolean;
  part_group?: any;
  part?: any;
  part_group_lists?: any;
  brand_labour_hour?: number;
  price_ratio?: number;
  original_price_ratio?: number;
}

interface ResponseArray {
  parts: Part[];
}

interface ResponseSingle {
  parts_by_pk: Part
}

interface InsertMutation {
  insert_parts: {
    affected_rows: number
    returning: [{
      id: Number
    }]
  }
}

@Injectable()
export class PART_ALL extends Query<ResponseArray> {
  override document = gql`
    query GET_PARTS($where: parts_bool_exp){
      parts(where: $where) {
        id
        name
        part_group_id
        connected_part_id
        brand_labour_hour
        price_ratio
        original_price_ratio
        part_group{
          name
        }
      }
    }
  `;
}

@Injectable()
export class GET_PARTS_BY_GROUP extends Query<ResponseArray> {
  override document = gql`
    query GET_PARTS($part_group_id: bigint!){
      parts(where: {part_group_lists: {part_group_id: {_eq: $part_group_id}}}) {
        id
        name
        brand_labour_hour
        part_group_id
        connected_part_id
        price_ratio
        original_price_ratio
      }
    }
  `;
}

@Injectable()
export class PART_ID extends Query<ResponseSingle> {
  override document = gql`
    query PART_ID($id: bigint!){
      parts_by_pk(id: $id) {
        id
        name
        connected_part_id
        brand_labour_hour
        price_ratio
        original_price_ratio
        image_url
        note
        active
      
        part {
          name
        }
      }
    }
  `;
}


@Injectable()
export class PART_CREATE extends Mutation<InsertMutation> {
  // connected_part_id - int | nullable
  override document = gql`
    mutation PART_CREATE(
      $name: String!, 
      $connected_part_id: Int, 
      $part_group_id: Int!, 
      $image_url: String, 
      $note: String, 
      $brand_labour_hour: numeric,
      $active: Boolean,
      $price_ratio: numeric,
      $original_price_ratio: numeric) {
      insert_parts(objects: {
        name: $name, 
        connected_part_id: $connected_part_id, 
        part_group_id: $part_group_id, 
        image_url: $image_url, 
        brand_labour_hour: $brand_labour_hour,
        note: $note, 
        active: $active
        price_ratio: $price_ratio,
        original_price_ratio: $original_price_ratio,
      }) {
        affected_rows
        returning {
          id
          name
        }
      }
    }
  `;
}

@Injectable()
export class PART_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
    mutation PART_UPDATE($id: bigint!, $active: Boolean, $connected_part_id: Int, $image_url: String, $name: String, $note: String,$part_group_id: Int, $price_ratio: numeric,$original_price_ratio: numeric ) {
      update_parts(where: {id: {_eq: $id}}, _set: {active: $active, connected_part_id: $connected_part_id, image_url: $image_url, name: $name, note: $note,part_group_id:$part_group_id, price_ratio: $price_ratio,original_price_ratio: $original_price_ratio}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PART_UPDATE_CUSTOM extends Mutation<any> {
  override document = gql`
    mutation PART_UPDATE_CUSTOM($id: bigint!, $changes: parts_set_input ) {
      update_parts(where: {id: {_eq: $id}}, _set: $changes) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}


@Injectable()
export class PART_DELETE extends Mutation<any> {
  override document = gql`
    mutation PART_DELETE($id: bigint!) {
      delete_parts(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PART_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query PART_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: parts_bool_exp) {
      parts(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        name
        note
        image_url
        brand_labour_hour
        part_group_id
        connected_part_id
        price_ratio
        original_price_ratio
        active
        part {
          name
        }
      }
    }
  `;
}

@Injectable()
export class PART_COUNT extends Query<any> {
  override document = gql`
    query PART_COUNT($where: parts_bool_exp) {
      parts_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}