import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Company, COMPANY_ALL, COMPANY_ID } from 'src/app/graphql/company.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment-v3-servis-secimi',
  templateUrl: './servis-secimi-v3.component.html',
  styles: [
  ],
  styleUrls:['./servis-secimi-v3.component.css'],
  providers:[COMPANY_ALL,COMPANY_ID]
})
export class ServisSecimiV3Component implements OnInit {

  @Input() serviceInfoInput:any;

  public selectedTab = "selfService";
  public tooltipid:BigInt | undefined;

  public todayWithPipe: any;
  public selectedCityId:Number = 0;
  public selectedServiceId:bigint = BigInt("0");
  public selectedDistrictId:Number = 0;
  public companyList: Company[] | undefined;
  public serviceDate:any;
  public nearestServices: boolean = false;
  public tab = "manuel";
  public baseUrl: string = environment.laravelApiEndpoint;
  public showLoader = true;
  public openDrawer:boolean = false;
  public selectedCompany: any;

  

  public userLocation = {
    lat: 0,
    lon: 0
  }

  @Output() serviceEmitter = new EventEmitter();
  constructor(
    private getCompany: COMPANY_ALL,
    private getCompanyId: COMPANY_ID,
    private http: HttpClient,
    private alertifyService: AlertifyService,
    private _elementRef: ElementRef,
    private authService: AuthService
  ) { 
    window.scrollTo(0, 0);
  }


  
  
  async ngOnInit(): Promise<void> {


    

    if(this.serviceInfoInput){
      if(this.serviceInfoInput.service_tab == "nearestService"){
        await this.changeTab("nearestService");
      }else{
        if(this.serviceInfoInput.service_city_id > 0){
          await this.changeCity(this.serviceInfoInput.service_city_id,'city');
          if(this.serviceInfoInput.service_district_id > 0){
            await this.changeCity(this.serviceInfoInput.service_district_id,'district');
          }
          
        }
      }

      
      
      if(this.serviceInfoInput.selectedServiceId){
        this.selectedServiceId = this.serviceInfoInput.service_id;
      }
    }

    if(this.authService.userRole == "service" && this.authService.companyId > 0){

      await this.getCompanyId.watch({id:this.authService.companyId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(async resp => {
        if(resp.errors){
          this.alertifyService.error(resp.errors[0].message);
        }

        if(resp.data && resp.data.companies_by_pk){
          let currentService = resp.data.companies_by_pk;

          await this.changeCity(currentService.city_id);
          await this.selectService(currentService.id);

        }
      })

    }
  }

  async changeTab(tab:string){
    this.selectedTab = tab;
    this.companyList = [];
    if(this.selectedTab == "nearestService"){
      this.nearestServices = true;
      await this.changeCity(true,'nearestService');
    }
    if(this.selectedTab == "manuel"){ 
      this.nearestServices = false;
      this.changeCity(null,'');
    }
  }

  selectedTwo($event:any,type:string){
    switch(type){
      case 'city':
        this.selectedCityId = $event;
      break;

      case 'district':

      break;
    }
  }

  async changeCity($event:any,type:string="city"){
    this.companyList = [];
    this.selectedServiceId = BigInt("0");
    //this.selectedDistrictId = 0;
   // this.selectedCityId = 0;
    switch(type){
      case "city":
        this.selectedCityId = $event;
        this.selectedDistrictId = 0;
        break;
      case "district":
        this.selectedDistrictId = $event;
      break;  
      case "nearestService":
        this.showLoader = true;
        await this.getLocation()
          .then((location) => {
            this.userLocation.lat = location.latitude;
            this.userLocation.lon = location.longitude
            this.showLoader = false;
          })
          .catch((error) => {
            console.error("Konum alınamadı:", error);
            this.selectedTab = "manuel";
            this.showLoader = false;
          });
        this.nearestServices = $event;
      break;
      default: 
          this.companyList = [];
      break;
    }

    let whereObj = {"active":{"_eq":true},"visibility":{"_eq":true}};

    this.companyList = undefined;
    
    if(type == "nearestService"){
      this.showLoader = true;
       await this.getNearestServices().subscribe(async resp => {
        if(resp.status){
          if(resp.data){
           
            const selectedIds = resp.data.map(({ id }) => id);
            Object.assign(whereObj, { "id": { "_in": selectedIds}})
            
            await this.getCompany.watch({ where: whereObj,order_by:{"name":"asc"} },{fetchPolicy:"no-cache"}).valueChanges.subscribe(async (result:any)=>{
              this.companyList = result.data.companies

            
              await this.companyList?.map(item=>{
                  resp.data.map(i => {
                   
                    if(i.id == item.id){
                      item.distance = i.km;
                    }
                  });
                  
              });
              
              await this.sortService();
              this.showLoader = false;

              

            });

            

          
            

          }
        }
      });

     
    }else{
      if(this.selectedCityId > 0){
        Object.assign(whereObj, { "city_id": { "_eq": this.selectedCityId}})
      }
  
      if(this.selectedDistrictId > 0){
        Object.assign(whereObj, { "district_id": { "_eq": this.selectedDistrictId}})
      }
      this.getCompany.watch({ where: whereObj,order_by:{"name":"asc"} },{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
        this.companyList = result.data.companies

        

      });

    
    }

    

   
  }

  sortService(): void {
    this.companyList?.sort((a, b) => a.distance - b.distance);
 }

 setServiceDate($event:any){
   this.serviceDate = $event;
   this.serviceEmitter.emit({service_date : this.serviceDate, service_id: this.selectedServiceId});
 }

 selectService(id:bigint){
   this.selectedServiceId = id;

   this.serviceEmitter.emit({ service_id: this.selectedServiceId,service_city_id: this.selectedCityId, service_district_id:this.selectedDistrictId,service_tab:this.selectedTab});
 }

 getLocation(): Promise<{ latitude: number; longitude: number }> {
   return new Promise((resolve, reject) => {
     if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition(
         (position) => {
           const latitude = position.coords.latitude;
           const longitude = position.coords.longitude;
           resolve({ latitude, longitude });
         },
         (error) => {
           reject(error);
         }
       );
     } else {
       reject(new Error("Tarayıcınız konum bilgisini desteklemiyor."));
     }
   });
 }

 tabChange(type:string){
   this.selectedTab = type;
   if(this.selectedTab == "nearest"){
     this.companyList = [];
     this.nearestServices = true;
     this.changeCity(true,'nearest');
   }
   if(this.selectedTab == "manuel"){ 
     this.nearestServices = false;
     this.changeCity(null,'');
   }
 }

 getNearestServices(){
   const formData = new FormData();
   formData.append('latitude', this.userLocation.lat.toString());
   formData.append('longitude', this.userLocation.lon.toString());
  
    return this.http.post<any>(this.baseUrl + "nearest-services", formData);
 }



 tooltipEmitter($event:boolean){
  if(!$event){
    this.tooltipid = undefined;
  }
  
 }

 checkTooltip(id:BigInt){
  
  return this.tooltipid == id;
 }

 setOpenDrawer(isOpen:boolean | null, companyItem:any=undefined){
  
  if(companyItem){
    this.selectedCompany = companyItem;
  }
  if(isOpen != null){
    this.openDrawer = isOpen;
  }else{
    this.openDrawer = !this.openDrawer;
  }

  
 }

  
 

}
