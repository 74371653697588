import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { VehicleModelType, VEHICLE_MODEL_TYPE_ID } from 'src/app/graphql/vehicle-model-type.graphql';
import { WAITING_APPOINTMENTS_CREATE } from 'src/app/graphql/waiting_appointments.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { MailService } from 'src/app/services/mail.service';
import { SmsService } from 'src/app/services/sms.service';

@Component({
  selector: 'app-appointment-kullanici-formu',
  templateUrl: './kullanici-formu.component.html',
  styles: [
  ],
  providers:[VEHICLE_MODEL_TYPE_ID,WAITING_APPOINTMENTS_CREATE]
})
export class KullaniciFormuComponent implements OnInit {
  public userFormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    surname: new FormControl('',Validators.required),
    phone: new FormControl('',Validators.required),
    email: new FormControl('',Validators.required),
    vehicle_km: new FormControl('',[Validators.required])
  });

  @Input() modelTypeId: Number | undefined;
  public modelType: VehicleModelType | undefined;

  public showSuccessMessage = false;
  constructor(
    private alertifyService : AlertifyService,
    private mailService: MailService,
    private smsService: SmsService,
    private getPackageModelTypes: VEHICLE_MODEL_TYPE_ID,
    private createWaitingAppointment: WAITING_APPOINTMENTS_CREATE
  ) { }

  ngOnInit(): void {
    moment.locale('tr');
    moment.defaultFormat = "YYYY-MM-DD HH:mm";

    this.getPackageModelTypes.watch({id:this.modelTypeId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
      if(response.error){
        this.alertifyService.error("Bir hata oluştu. Lütfen tekrar deneyiniz.");
        return false;
      }

      if(response.data){

        this.modelType = response.data.vehicle_model_types_by_pk;
      }
    });


  }

  formSubmit(){
    
    if(this.userFormGroup.invalid){
      this.alertifyService.error("Lütfen formu eksiksiz doldurunuz!");
      return false;
    }

    if(this.userFormGroup.valid,this.modelType){

      this.createWaitingAppointment.mutate({
        name: this.userFormGroup.value.name,
        surname: this.userFormGroup.value.surname,
        email: this.userFormGroup.value.email,
        phone: this.userFormGroup.value.phone,
        vehicle_km: this.userFormGroup.value.vehicle_km,
        vehicle_brand_id : this.modelType.vehicle_model_year.vehicle_model.vehicle_brand.id,
        vehicle_model_id : this.modelType.vehicle_model_year.vehicle_model.id,
        vehicle_year_id : this.modelType.vehicle_model_year.id,
        vehicle_model_type_id : this.modelType.id,
        returned: false
      }).subscribe(resp=>{

        if(resp.errors){
          this.alertifyService.error(resp.errors[0].message);
        }

        if(resp.data){
          this.showSuccessMessage = true;
      
          let serviceTitle = 'Yeni Randevu Teklifi'
          let serviceContent = '<table border="0">';
          serviceContent += '<tr><td>Marka</td><td>'+this.modelType?.vehicle_model_year?.vehicle_model.vehicle_brand.name+'</td></tr>';
          serviceContent += '<tr><td>Model</td><td>'+this.modelType?.vehicle_model_year?.vehicle_model.name+'</td></tr>';
          serviceContent += '<tr><td>Yıl</td><td>'+this.modelType?.vehicle_model_year.year.name+'</td></tr>';
          serviceContent += '<tr><td>Kilometre</td><td>'+this.userFormGroup.value.vehicle_km+'</td></tr>';
          serviceContent += '<tr><td>Email</td><td>'+this.userFormGroup.value.email+'</td></tr>';
          serviceContent += '<tr><td>İsim</td><td>'+this.userFormGroup.value.name+' '+this.userFormGroup.value.surname+'</td></tr>';
          serviceContent += '<tr><td>Telefon</td><td>'+this.userFormGroup.value.name+' '+this.userFormGroup.value.phone+'</td></tr>';
          serviceContent += '</table>';
    
          this.mailService.send("sistem@garantiliotoservis.com",serviceTitle,serviceContent).subscribe(mailResponse => {
            if(mailResponse.status){
        
             
         
            }
            
          });
    
          let message = this.modelType?.vehicle_model_year?.vehicle_model.vehicle_brand.name + + ' ' + this.modelType?.vehicle_model_year?.vehicle_model.name + " aracınız için kaydınız oluşturuldu. En kısa sürede geri dönüş yapacağız.";
          this.mailService.send(this.userFormGroup.value.email,'Garantili Oto Servis Teklif İsteği.',message).subscribe(mailResponse => {
            if(mailResponse.status){
        
             
         
            }
            
          });
    
          let phones = [this.userFormGroup.value.phone];
          let title = "Kayıdınız Oluşturuldu";
          let content = "Kaydınız oluşturuldu. En kısa sürede geri dönüş yapacağız."
    
          this.smsService.send(title, content, phones).subscribe(response => {
    
            if (response.status) {
              // this.alertifyService.success("Sms Gönderildi")
              // Gönderilen smsler için kayıt işlemi olacaksa burada yapılacak
            }
          })
        }
      })


     


    }
  }

 
  
  

}
