import { Injectable } from "@angular/core";
import { gql, Query } from "apollo-angular";
import { OrderStatus } from "./order_status.graphql";

export interface OrderSms {
  id: Number
  order_status_id: Number
  type: String
  title: String
  content: String
  order_status: OrderStatus
}

interface ResponseArray {
  order_sms: OrderSms[]
}

@Injectable()
export class ORDER_SMS extends Query<ResponseArray>{
  override document = gql`
  query ORDER_SMS($order_status_id: Int!) {
    order_sms(where: {order_status_id: {_eq: $order_status_id}}) {
      id
      order_status_id
      type
      title
      content
    }
  }`;
}