<div class="row mb-5">
    <div class="col-xl-3" *ngIf="showLabel">
        <div class="fs-6 fw-bold">Ülke</div>
    </div>
    <div class="col-xl-{{widthClass}} fv-row fv-plugins-icon-container">
        <select name="countries" class="form-control" id="" (change)="selected($event)">
            <option [selected]="(selectedCountry == null || selectedCountry == 0 || selectedCountry === false)" value="">Seçiniz</option>
            <option value="{{item.id}}" [selected]="(selectedCountry && selectedCountry === item.id)" *ngFor="let item of countryList">{{item.name}}</option>
        </select>
       
    </div>
</div>
