import { Injectable } from "@angular/core";
import { gql, Query } from "apollo-angular";

export interface City {
  id: bigint,
  country_id: bigint,
  name: String,
  active: boolean,
  group: number,
  days: number
  position_no:number
}

interface ResponseArray {
  cities: City[]
}

@Injectable()
export class CITY_COUNTRY_ID extends Query<ResponseArray>{
  override document = gql`
  query CITY_COUNTRY_ID($country_id: Int) {
    cities(where: {country_id: {_eq: $country_id}}) {
      id
      name
      group
      days
    }
  }`;
}

@Injectable()
export class GET_CITIES extends Query<ResponseArray>{
  override document = gql`
  query GET_CITIES($country_id: Int) {
    cities(where: {country_id: {_eq: $country_id}},order_by:[{position_no:desc},{name:asc}]) {
      id
      name
      position_no
      group
      days
    }
  }`;
}