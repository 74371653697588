import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class SmsService {

  private options = {
    headers: new HttpHeaders({
      'token': environment.gosToken
    })
  }

  constructor(
    private http: HttpClient
  ) { }

  send(header: String, message: String, phones: any[] = []): Observable<any> {
    const formData = new FormData();
    formData.append('header', String(header));
    formData.append('message', String(message));
    if (phones.length) {
      phones.forEach(phone => {
        formData.append('phones[]', String(phone));
      })
    }
    return this.http.post<any>(environment.laravelApiEndpoint + "sms/send", formData, this.options);
  }

  header(): Observable<any> {
    return this.http.get<any>(environment.laravelApiEndpoint + "sms/header");
  }

  credit(): Observable<any> {
    return this.http.get<any>(environment.laravelApiEndpoint + "sms/header");
  }

  dlr(dlrId: number): Observable<any> {
    return this.http.get<any>(environment.laravelApiEndpoint + "sms/dlr/" + dlrId);
  }

}