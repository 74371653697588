<!--begin::Post-->
<div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">

        
        <div class="row gy-5 g-xl-8" >
            
            <!--begin::Col-->
            <div class="col-xl-12 mb-5">
                <!--begin::Mixed Widget 2-->
                <div class="card card-xl-stretch">
                  
                    <!--begin::Body-->
                    <div class="card-body p-0">
                        
                        <!--begin::Stats-->
                        <div class="card position-relative hero-section">
                            <!--begin::Row-->
                            <div class="row g-0 py-20" style="background-color: #d5d3d6; background-size:contain; background-image: url('https://cdn.destechhasar.com/garantili-oto-servis/garantili-oto-servis-61-750x450.jpeg'); background-repeat: no-repeat; background-position-x: right; background-position-y: bottom;">
                                <!--begin::Col-->
                                <div class="col px-6 py-8 rounded-2 me-7 mb-7" style=" display: table;">
                                    <h1 class="hero-content" style="font-weight: 600; color: #004422; font-size:46px; display:table-cell; vertical-align:middle">SIKÇA SORULAN SORULAR</h1>
                                </div>
                                <!--end::Col-->
                                <!--begin::Col-->
                                <div class="col-xl-6 px-6 py-8 rounded-2" >
                               
                                </div>
                                <!--end::Col-->
                            </div>
                            <!--end::Row-->
                           
                        </div>
                        <!--end::Stats-->
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Mixed Widget 2-->
            </div>
            <!--end::Col-->
           
           
        </div>
        <!--end::Row-->

        <div class="row gy-5 g-xl-8">
           
            <!--begin::Col-->
            <div class="col-xl-12 col-xxl-12 mb-5 mb-xl-10">
                <!--begin::Table Widget 3-->
                <div class="card card-flush h-xl-100">
                    <!--begin::Card header-->
                    <div class="card-header py-5">
                        <!--begin::Tabs-->
                        <div class="card-title pt-3 mb-0 gap-4 gap-lg-10 gap-xl-15 nav nav-tabs border-bottom-0" data-kt-table-widget-3="tabs_nav">
                            <h2 class="text-gray-800 fw-bolder mb-4">Garantili Araçlar Hakkına Sıkça Sorulan Sorular
                            </h2>
                        </div>
                        <!--end::Tabs-->
                    
                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body pt-1">
                        <!--begin::Sort & Filter-->
                        <div class="d-flex flex-stack flex-wrap gap-4">

                            <!--start-->
                            <div class="col-md-12 ps-md-10">
                                <!--begin::Accordion-->
                                <!--begin::Section-->
                                <div class="m-0 cntr">
                                    <!--begin::Heading-->
                                    <div class="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse"  (click)="toggleItem($event)" >
                                        <!--begin::Icon-->
                                        <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->

                                            <span class="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                                <i class="fa fa-plus-square prc-btn" style="font-size: 20px;"></i>

                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                                            <span class="svg-icon toggle-off svg-icon-2">
                                                <i class="fa fa-minus-square prc-btn" style="font-size: 20px;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <h4 class="text-gray-700 fw-bolder cursor-pointer mb-0">Aracımın periyodik bakımın ne zaman ve nerede yaptırmalıyım?</h4>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Body-->
                                    <div class="collapse fs-6 ms-1">
                                        <!--begin::Text-->
                                        <div class="mb-4 text-gray-600 fw-bold fs-6 ps-10">Aracınızın bakımını üretici markanın belirlemiş olduğu süreler içerisinde yaptırmak zorundasınız. Markanın belirlemiş olduğu yetkili servislerde veya özel servislerde yaptırabilirsiniz.</div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Content-->
                                    <!--begin::Separator-->
                                    <div class="separator separator-dashed"></div>
                                    <!--end::Separator-->
                                </div>
                                <!--end::Section-->

                                <!--begin::Section-->
                                <div class="m-0 cntr">
                                    <!--begin::Heading-->
                                    <div class="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse"  (click)="toggleItem($event)" >
                                        <!--begin::Icon-->
                                        <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->

                                            <span class="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                                <i class="fa fa-plus-square prc-btn" style="font-size: 20px;"></i>

                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                                            <span class="svg-icon toggle-off svg-icon-2">
                                                <i class="fa fa-minus-square prc-btn" style="font-size: 20px;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <h4 class="text-gray-700 fw-bolder cursor-pointer mb-0">
                                            Özel servislerde aracımın bakımını yaptırabilir miyim?
                                          </h4>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Body-->
                                    <div class="collapse fs-6 ms-1">
                                        <!--begin::Text-->
                                        <div class="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                            Evet yaptırabilirsiniz ama her özel serviste değil.Blok muafiyeti yönetmeliğiyle tanımlanan özel aracınızın bakımını yaptırabilirsiniz.
                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Content-->
                                    <!--begin::Separator-->
                                    <div class="separator separator-dashed"></div>
                                    <!--end::Separator-->
                                </div>
                                <!--end::Section-->

                                <!--begin::Section-->
                                <div class="m-0 cntr">
                                    <!--begin::Heading-->
                                    <div class="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse"  (click)="toggleItem($event)" >
                                        <!--begin::Icon-->
                                        <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->

                                            <span class="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                                <i class="fa fa-plus-square prc-btn" style="font-size: 20px;"></i>

                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                                            <span class="svg-icon toggle-off svg-icon-2">
                                                <i class="fa fa-minus-square prc-btn" style="font-size: 20px;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <h4 class="text-gray-700 fw-bolder cursor-pointer mb-0">
                                            
                                          Peki bu özel servisler hangileridir? Nasıl hizmet alabilirim?
                                        
                                        </h4>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Body-->
                                    <div class="collapse fs-6 ms-1">
                                        <!--begin::Text-->
                                        <div class="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                            Özel servisler belgeli ve belgesiz özel servisler olmak üzere ikiye ayrılır.Belgesiz servisler bakım konusunda herhangi bir yetkinliği olmayan servisler olup burada yaptıracağınız işlemler aracınızın garantisini bozacaktır.Belgeli özel servisler ise TSE tarafından değerlendirilerek yetkilendirilmiş servislerdir.Eğer özel servisin TSE 1247 ve/veya TSE 13168 belgeleri mevcutsa orijinal veya eşdeğer denklikte parça kullanıyorsa ve üreticinin belirlemiş olduğu standartlara göre işlem yapıyorsa garantiniz korunacaktır.Bu bağlamda sizlere yardımcı olabilmek için Garantili Oto Servis ağını oluşturduk.
    
                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Content-->
                                    <!--begin::Separator-->
                                    <div class="separator separator-dashed"></div>
                                    <!--end::Separator-->
                                </div>
                                <!--end::Section-->

                                <!--begin::Section-->
                                <div class="m-0 cntr">
                                    <!--begin::Heading-->
                                    <div class="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse"  (click)="toggleItem($event)" >
                                        <!--begin::Icon-->
                                        <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->

                                            <span class="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                                <i class="fa fa-plus-square prc-btn" style="font-size: 20px;"></i>

                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                                            <span class="svg-icon toggle-off svg-icon-2">
                                                <i class="fa fa-minus-square prc-btn" style="font-size: 20px;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <h4 class="text-gray-700 fw-bolder cursor-pointer mb-0">
                                            
                                          Garantili Oto Servislerde yaptıracağım işlemler garantiyi bozar mı?
                                        
                                        </h4>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Body-->
                                    <div class="collapse fs-6 ms-1">
                                        <!--begin::Text-->
                                        <div class="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                            Hayır. Garantili Oto Servislerde alacağınız hizmetler aracınızın garantisini bozmayacaktır.

                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Content-->
                                    <!--begin::Separator-->
                                    <div class="separator separator-dashed"></div>
                                    <!--end::Separator-->
                                </div>
                                <!--end::Section-->

                                <!--begin::Section-->
                                <div class="m-0 cntr">
                                    <!--begin::Heading-->
                                    <div class="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse"  (click)="toggleItem($event)" >
                                        <!--begin::Icon-->
                                        <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->

                                            <span class="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                                <i class="fa fa-plus-square prc-btn" style="font-size: 20px;"></i>

                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                                            <span class="svg-icon toggle-off svg-icon-2">
                                                <i class="fa fa-minus-square prc-btn" style="font-size: 20px;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <h4 class="text-gray-700 fw-bolder cursor-pointer mb-0">
                                            
                                            Garanti nedir?  Garanti kapsamı nedir?
                                                                                
                                        </h4>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Body-->
                                    <div class="collapse fs-6 ms-1">
                                        <!--begin::Text-->
                                        <div class="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                            <p>
                                                <i class="fas fa-check text-primary"></i> Satın alınan herhangi bir ürünün üretim hatalarına veya işçilik kusurlarına karşı tüketiciyi belli sürelerde koruyan zorunlu bir taahhüttür ve garantinin süresi otomobillerde en az 2 yıl olmak zorundadır.

                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> Üretici ve Distribütörler, Garanti süresini kendi insiyatiflerinde daha uzun süreli ve kilometreye dayalı olarak verebilmektedirler . Garanti süresi içerisinde kullanım hatalarından kaynaklanmayan ve üreticinin yazılı olarak Garanti dışında olduğunu belirtmediği tüm işlemler ücretsiz olarak yapılmak zorundadır.

                                            </p>
                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Content-->
                                    <!--begin::Separator-->
                                    <div class="separator separator-dashed"></div>
                                    <!--end::Separator-->
                                </div>
                                <!--end::Section-->

                                <!--begin::Section-->
                                <div class="m-0 cntr">
                                    <!--begin::Heading-->
                                    <div class="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse"  (click)="toggleItem($event)" >
                                        <!--begin::Icon-->
                                        <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->

                                            <span class="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                                <i class="fa fa-plus-square prc-btn" style="font-size: 20px;"></i>

                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                                            <span class="svg-icon toggle-off svg-icon-2">
                                                <i class="fa fa-minus-square prc-btn" style="font-size: 20px;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <h4 class="text-gray-700 fw-bolder cursor-pointer mb-0">
                                            
                                            Garanti neden bozulur?  
                                                                                
                                        </h4>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Body-->
                                    <div class="collapse fs-6 ms-1">
                                        <!--begin::Text-->
                                        <div class="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                            <p>
                                                <i class="fas fa-check text-primary"></i> 
                                                Üreticinin belirlemiş olduğu periyotlarda işlem yapılmaması halindeve belgesiz servislerde yapılan bakım onarımlarda

                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Bakım onarımlarda Orijinal ve/veya Eşdeğer denkliği olmayan Yedek parça kullanımında ve faturada Yedek parça ve işçilik detaylarının yazılmaması durumunda
                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Kullanıcı hatası olduğu belirlenen arızalarda
                                            </p>
                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Content-->
                                    <!--begin::Separator-->
                                    <div class="separator separator-dashed"></div>
                                    <!--end::Separator-->
                                </div>
                                <!--end::Section-->

                                 <!--begin::Section-->
                                 <div class="m-0 cntr">
                                    <!--begin::Heading-->
                                    <div class="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse"  (click)="toggleItem($event)" >
                                        <!--begin::Icon-->
                                        <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->

                                            <span class="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                                <i class="fa fa-plus-square prc-btn" style="font-size: 20px;"></i>

                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                                            <span class="svg-icon toggle-off svg-icon-2">
                                                <i class="fa fa-minus-square prc-btn" style="font-size: 20px;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <h4 class="text-gray-700 fw-bolder cursor-pointer mb-0">
                                            
                                            Blok Muafiyeti nedir? Blok muafiyetinin bana faydası nedir?
                                                                                                                        
                                        </h4>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Body-->
                                    <div class="collapse fs-6 ms-1">
                                        <!--begin::Text-->
                                        <div class="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                         
                                                01.01.2007 Tarihinde yürürlüğe giren yönetmeliğin amacı, Satış sonrası servis işlemlerinde daha fazla rekabet yaratmak ve otomobil yedek parça tedarik piyasasına destek sağlamaktır. Bu yasa ile TSE tarafından yetkilendirilmiş Özel servislerde de bakım ve tamir hakkının koruması amaçlanmıştır.

                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Content-->
                                    <!--begin::Separator-->
                                    <div class="separator separator-dashed"></div>
                                    <!--end::Separator-->
                                </div>
                                <!--end::Section-->

                                <!--begin::Section-->
                                <div class="m-0 cntr">
                                    <!--begin::Heading-->
                                    <div class="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse"  (click)="toggleItem($event)" >
                                        <!--begin::Icon-->
                                        <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->

                                            <span class="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                                <i class="fa fa-plus-square prc-btn" style="font-size: 20px;"></i>

                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                                            <span class="svg-icon toggle-off svg-icon-2">
                                                <i class="fa fa-minus-square prc-btn" style="font-size: 20px;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <h4 class="text-gray-700 fw-bolder cursor-pointer mb-0">
                                            
                                            Peki periyodik Bakım Garanti kapsamında mıdır?
                                                                                                                                                                
                                        </h4>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Body-->
                                    <div class="collapse fs-6 ms-1">
                                        <!--begin::Text-->
                                        <div class="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                         
                                            Periyodik bakım Garanti kapsamında değildir, çünkü Periyodik bakımda, aracın kullanım süresi boyunca eskiyen, aşınan, sarf malzeme ve ömürlü parçalarının değişimi yapılmaktadır. Değişen yedek parçaların maliyetleri ve işçilik bedelleri tüketici tarafından karşılanır. Bunlar ile birlikte taktığınız orijinal olmayan aksesuar veya araç üzerinde yaptığınız bir değişikliğin teknik verilerini etkilemesi durumunda aracınızın kısmi veya tamamen garantisi bozulabilir. TSE sertifikalı ve Orijinal veya Eşdeğer Parça Denklik belgesi olan özel servislerde aracınızın bakım işlemlerini yaptırabilirsiniz.

                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Content-->
                                    <!--begin::Separator-->
                                    <div class="separator separator-dashed"></div>
                                    <!--end::Separator-->
                                </div>
                                <!--end::Section-->

                                <!--begin::Section-->
                                <div class="m-0 cntr">
                                    <!--begin::Heading-->
                                    <div class="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse"  (click)="toggleItem($event)" >
                                        <!--begin::Icon-->
                                        <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->

                                            <span class="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                                <i class="fa fa-plus-square prc-btn" style="font-size: 20px;"></i>

                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                                            <span class="svg-icon toggle-off svg-icon-2">
                                                <i class="fa fa-minus-square prc-btn" style="font-size: 20px;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <h4 class="text-gray-700 fw-bolder cursor-pointer mb-0">
                                            Garantili Oto Servislerden alabileceğim hizmetler nelerdir?                            
                                        </h4>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Body-->
                                    <div class="collapse fs-6 ms-1">
                                        <!--begin::Text-->
                                        <div class="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                            <p>
                                                <i class="fas fa-check text-primary"></i> 
                                                Periyodik Bakım

                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Cam Tamiri
                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Lastik Tamiri
                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Klima Gazı

                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Motor Yağı Değişimi
                                            </p>
                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Content-->
                                    <!--begin::Separator-->
                                    <div class="separator separator-dashed"></div>
                                    <!--end::Separator-->
                                </div>
                                <!--end::Section-->

                                 <!--begin::Section-->
                                 <div class="m-0 cntr">
                                    <!--begin::Heading-->
                                    <div class="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse"  (click)="toggleItem($event)" >
                                        <!--begin::Icon-->
                                        <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->

                                            <span class="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                                <i class="fa fa-plus-square prc-btn" style="font-size: 20px;"></i>

                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                                            <span class="svg-icon toggle-off svg-icon-2">
                                                <i class="fa fa-minus-square prc-btn" style="font-size: 20px;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <h4 class="text-gray-700 fw-bolder cursor-pointer mb-0">
                                            
                                          Garantiliotoservis.com üyesi servislerin farkı nedir?
                                                                  
                                        </h4>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Body-->
                                    <div class="collapse fs-6 ms-1">
                                        <!--begin::Text-->
                                        <div class="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                            <p>
                                                Üye Servislerimizde;
                                            </p>
                                            <p>
                                                <i class="fas fa-check text-primary"></i> 
                                                TS 12047 ve/veya TS 13168 belgelerine sahip servislerdir.
                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Üreticinin sunduğu GARANTİ hakkınız korunmaktadır.
                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Periyodik bakım işlemleri Üreticinin belirlemiş olduğu sürelerde yapılmaktadır.
                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Doğru maliyetlerde hizmet verilmektedir.

                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Marka standartlarında belirlenmiş olan hizmetler sunulmaktadır.
                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Yapılan tüm işlemler tüm Türkiye’de üye servislerimizde GARANTİ altındadır.

                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Garanti ve Blok Muafiyeti yönetmeliğinde belirtilen Orijinal ve Eşdeğer yedek parça tanımına uygun ürünler kullanılmaktadır.
                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Bakım ve Arıza tespit için Yeterli teknik ekipmanlar mevcuttur.
                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Uzman ve Tecrübeli teknik kadro ile hizmet sunulmaktadır.
                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Müşteri ve Üye servislerin taleplerinin yönetildiği Merkezi operasyon birimi mevcuttur.
                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Tüketici ile birlikte doğru zaman planlamaları yapılmaktadır.
                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> 
                                                Erişim ve bilgi edinme süreçlerinde kolay iletişim sağlanmaktadır.
                                            </p>

                                            <p>Üye servislerde sunulan hizmetlerin sürdürülebilirliğinin takibi ve Müşteri memnuniyetlerinin değerlendirilmesi noktasında Garantili Oto Servis olarak periyodik kontrolleri yapmaktayız.</p>
                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Content-->
                                    <!--begin::Separator-->
                                    <div class="separator separator-dashed"></div>
                                    <!--end::Separator-->
                                </div>
                                <!--end::Section-->

                                 <!--begin::Section-->
                                 <div class="m-0 cntr">
                                    <!--begin::Heading-->
                                    <div class="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse"  (click)="toggleItem($event)" >
                                        <!--begin::Icon-->
                                        <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->

                                            <span class="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                                <i class="fa fa-plus-square prc-btn" style="font-size: 20px;"></i>

                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                                            <span class="svg-icon toggle-off svg-icon-2">
                                                <i class="fa fa-minus-square prc-btn" style="font-size: 20px;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <h4 class="text-gray-700 fw-bolder cursor-pointer mb-0">

                                            İşlem Maliyetlerinin doğru ve güvenilir olduğundan nasıl emin olabilirim?
                                        </h4>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Body-->
                                    <div class="collapse fs-6 ms-1">
                                        <!--begin::Text-->
                                        <div class="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                            Üye servislerimizden almış olduğunuz hizmetler ile ilgili Merkezi Faturalandırma yapılması durumunda, fatura edilmiş olan yedek parça ve işçilik maliyetleri güven altındadır.

                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Content-->
                                    <!--begin::Separator-->
                                    <div class="separator separator-dashed"></div>
                                    <!--end::Separator-->
                                </div>
                                <!--end::Section-->


                                <div class="m-0 cntr">
                                    <!--begin::Heading-->
                                    <div class="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse"  (click)="toggleItem($event)" >
                                        <!--begin::Icon-->
                                        <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->

                                            <span class="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                                <i class="fa fa-plus-square prc-btn" style="font-size: 20px;"></i>

                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                                            <span class="svg-icon toggle-off svg-icon-2">
                                                <i class="fa fa-minus-square prc-btn" style="font-size: 20px;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <h4 class="text-gray-700 fw-bolder cursor-pointer mb-0">
                                            Özel Servis Kimdir?
                                        </h4>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Body-->
                                    <div class="collapse fs-6 ms-1">
                                        <!--begin::Text-->
                                        <div class="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                            <p>Üretici ve Distribütör tarafından kurulan bir dağıtım sistemi içerisinde yer almaksızın motorlu taşıtlar için bakım ve onarım hizmeti sağlayan teşebbüstür. Özel servisler, Belgeli ve Belgesiz Servis olarak ayrılırlar.</p>
                                            <p>
                                                <i class="fas fa-check text-primary"></i> &nbsp;
                                               <strong>Belgeli Servis</strong> TSE tarafından yetkilendirilmiş, TS 12047 ve/veya TS 13168 belgelerine sahip servislerdir.

                                            </p>
                                            <p>
                                                <i class="fas fa-check  text-primary"></i> &nbsp;
                                                <strong>Belgesiz Servis</strong> Herhangi bir bakım onarım yetkisi olmayan servislerdir.

                                            </p>
                                            
                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Content-->
                                    <!--begin::Separator-->
                                    <div class="separator separator-dashed"></div>
                                    <!--end::Separator-->
                                </div>

                                <div class="m-0 cntr">
                                    <!--begin::Heading-->
                                    <div class="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse"  (click)="toggleItem($event)" >
                                        <!--begin::Icon-->
                                        <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->

                                            <span class="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                                <i class="fa fa-plus-square prc-btn" style="font-size: 20px;"></i>

                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                                            <span class="svg-icon toggle-off svg-icon-2">
                                                <i class="fa fa-minus-square prc-btn" style="font-size: 20px;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <h4 class="text-gray-700 fw-bolder cursor-pointer mb-0">

                                            
                                          Özel servislerde Aracımın Periyodik Bakımını yaptırırsam Garanti bozulur mu?
                                        
                                        </h4>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Body-->
                                    <div class="collapse fs-6 ms-1">
                                        <!--begin::Text-->
                                        <div class="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                            <p>İşlem yapılan özel serviste aşağıda belirtilen koşullar sağlanıyorsa GARANTİ korunmaktadır.</p>

                                            <p>
                                                <i class="fas fa-check text-primary"></i> &nbsp;
                                                Özel servisin TS 12047 ve/veya TS 13168 belgesi mevcutsa
                                            </p>
                                            <p>
                                                <i class="fas fa-check text-primary"></i> &nbsp;
                                                Yapılan işlemlerde kullanılan yedek parçalar, Garanti ve Blok muafiyeti yönetmeliklerinde belirtilen Orijinal ve Eşdeğer tanımlara uygunsa
                                            </p>
                                            <p>
                                                <i class="fas fa-check text-primary"></i> &nbsp;
                                                Faturada değiştirilen yedek parça ve işçiliklerin detayları yer alıyorsa
                                            </p>
                                            <p>
                                                <i class="fas fa-check text-primary"></i> &nbsp;
                                                Üreticinin belirlemiş olduğu periyotlarda işlem yapılıyorsa
                                            </p>
                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Content-->
                                    <!--begin::Separator-->
                                    <div class="separator separator-dashed"></div>
                                    <!--end::Separator-->
                                </div>
                                <div class="m-0 cntr">
                                    <!--begin::Heading-->
                                    <div class="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse"  (click)="toggleItem($event)" >
                                        <!--begin::Icon-->
                                        <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->

                                            <span class="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                                <i class="fa fa-plus-square prc-btn" style="font-size: 20px;"></i>

                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                                            <span class="svg-icon toggle-off svg-icon-2">
                                                <i class="fa fa-minus-square prc-btn" style="font-size: 20px;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <h4 class="text-gray-700 fw-bolder cursor-pointer mb-0">
                                            Garantili Oto Servislerde Yapılan Bakım onarım işlemleri Garanti yi bozar mı?                                                           
                                        </h4>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Body-->
                                    <div class="collapse fs-6 ms-1">
                                        <!--begin::Text-->
                                        <div class="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                            Üye Servislerde verilen hizmetlerde Garantiniz korunmaktadır.

                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Content-->
                                    <!--begin::Separator-->
                                    <div class="separator separator-dashed"></div>
                                    <!--end::Separator-->
                                </div>
                                <div class="m-0 cntr">
                                    <!--begin::Heading-->
                                    <div class="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse"  (click)="toggleItem($event)" >
                                        <!--begin::Icon-->
                                        <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->

                                            <span class="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                                <i class="fa fa-plus-square prc-btn" style="font-size: 20px;"></i>

                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                                            <span class="svg-icon toggle-off svg-icon-2">
                                                <i class="fa fa-minus-square prc-btn" style="font-size: 20px;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <h4 class="text-gray-700 fw-bolder cursor-pointer mb-0">
                                            
                                          Garantili Oto Servislerinde sorun yaşarsam nereye bildirimde bulunabilirim?
                                        
                                        </h4>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Body-->
                                    <div class="collapse fs-6 ms-1">
                                        <!--begin::Text-->
                                        <div class="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                            Herhangi bir sorun yaşadığınızda <a href="https://www.garantiliotoservis.com" target="_blnak">www.garantiliotoservis.com</a> web sayfamız veya 0850 480 66 55 destek hattımızdan tarafımıza ulaşabilirsiniz.

                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Content-->
                                    <!--begin::Separator-->
                                    <div class="separator separator-dashed"></div>
                                    <!--end::Separator-->
                                </div>
                                <div class="m-0 cntr">
                                    <!--begin::Heading-->
                                    <div class="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse"  (click)="toggleItem($event)" >
                                        <!--begin::Icon-->
                                        <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->

                                            <span class="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                                <i class="fa fa-plus-square prc-btn" style="font-size: 20px;"></i>

                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                                            <span class="svg-icon toggle-off svg-icon-2">
                                                <i class="fa fa-minus-square prc-btn" style="font-size: 20px;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <h4 class="text-gray-700 fw-bolder cursor-pointer mb-0">
                                            Garantili Oto Servislere nasıl ulaşabilirim?
                                        </h4>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Body-->
                                    <div class="collapse fs-6 ms-1">
                                        <!--begin::Text-->
                                        <div class="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                            Web sitemizden randevu alarak veya 0850 480 66 55 numaralı telefondan bize ulaşarak randevu alabilirsiniz.
                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Content-->
                                    <!--begin::Separator-->
                                    <div class="separator separator-dashed"></div>
                                    <!--end::Separator-->
                                </div>
                              
                                <!--end::Accordion-->
                            </div>
                            <!--end-->


                        </div>
                    </div>
                </div>
            </div>
        </div>


    
    </div>
    <!--end::Container-->
</div>
<!--end::Post-->