<div class="w-100">

    <label class="d-flex align-items-center form-label mb-5">Hizmet Seçiniz
        <i class="fas fa-exclamation-circle ms-2 fs-7"
            aria-label="Monthly billing will be based on your account plan"
            title="Birden fazla seçim yapabilirsiniz."></i>
    </label>
    <div class="mb-0">
        <label class="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5"
            *ngFor="let order of orderTypes">
            <div class="d-flex align-items-center me-2">
                <div
                    class="form-check form-check-custom form-check-solid form-check-primary me-6">
                    <input class="form-check-input" name="orderlist" type="radio" value="{{order.id}}"
                    (change)="orderTypeChange($event)" [checked]="selectedOrderTypeId == order.id">
                </div>
                <span class="symbol symbol-50px me-6">
                    <span class="symbol-label">
                        <i class="{{order.icon}}"></i>
                    </span>
                </span>
                <div class="flex-grow-1">
                    
                    <div class="fw-bold opacity-50">
                        {{order.name}}
                        {{order.description}}
                    </div>
                </div>
            </div>
        </label>
        
      
    </div>

    <div>
        <button class="btn btn-primary btn-sm" (click)="emitNext()">Teklif Al</button>
    </div>
</div>