import { Injectable } from '@angular/core';
import { TypedDocumentNode } from '@apollo/client/core';
import { gql, Mutation, Query } from 'apollo-angular';
import { EmptyObject } from 'apollo-angular/types';
import { DocumentNode } from 'graphql';

export interface Vehicle {
  id: BigInt
  plate: String
  brand_id: BigInt
  model_id: BigInt
  type_id: BigInt
  model_year_id: BigInt
  colour_id: BigInt
  traffic_release_date: Date
  engine_no: String
  chassis_no: String
  note: String
  colour_code_id: BigInt
  colour_type_id: BigInt
  tire_id: BigInt
  gear_type_id: BigInt
  fuel_type_id: BigInt
  last_km: BigInt
  years: any;
  vehicle_model:any;
  vehicle_brand:any;
  garage_id: BigInt
  garages: any;
  model_type_id: BigInt;
  vehicle_model_types: any;
  license_name: String;
  license_surname: String;
  name?:any;
}

interface ResponseArray {
  vehicles: Vehicle[];
}

interface ResponseSingle {
  vehicles_by_pk: Vehicle;
}

@Injectable()
export class VEHICLES extends Query<ResponseArray> {
  override document = gql`
    query VEHICLES($where: vehicles_bool_exp){
      vehicles(where: $where) {
        id
        plate
        brand_id
        model_id
        type_id
        model_year_id
        colour_id
        traffic_release_date
        engine_no
        chassis_no
        note
        colour_code_id
        colour_type_id
        tire_id
        gear_type_id
        fuel_type_id
        last_km
        model_type_id
        license_name
        license_surname
        garages{
          id
          name
          user_id
        
        }
        vehicle_model_types{
          id
          name
        }
        years {
          id
          year_id
        }
        vehicle_brand {
          id
          name
        }
        vehicle_model {
          id
          name
          gos_labour_price
          brand_labour_price
        }
        vehicle_model_types{
          id
        }
      }
    }
  `;
}

@Injectable()
export class VEHICLE_CREATE extends Mutation<any> {
  override document = gql`
  mutation VEHICLE_CREATE(
    $brand_id: bigint!, 
    $model_id: bigint!, 
    $model_year_id: Int!, 
    $model_type_id: bigint!,
    $garage_id: bigint
    $plate: String,
    $license_name: String,
    $license_surname: String,) {
    insert_vehicles(objects: {
      brand_id: $brand_id, 
      model_id: $model_id, 
      model_year_id: $model_year_id,
      model_type_id: $model_type_id,
      garage_id: $garage_id,
      plate: $plate,
      license_name: $license_name,
      license_surname: $license_surname}) {
      affected_rows
      returning {
        id
      }
    }
  }`;
}

@Injectable()
export class VEHICLE_CREATE_APPOINTMENT_FORM extends Mutation<any> {
  override document = gql`
  mutation VEHICLE_CREATE_APPOINTMENT_FORM(
    $brand_id: bigint!, 
    $model_id: bigint!, 
    $model_year_id: Int!, 
    $model_type_id: bigint!,
    $garage_id: bigint,
    $fuel_type_id: Int!,
    $gear_type_id:Int!
    ) {
    insert_vehicles(objects: {
      brand_id: $brand_id, 
      model_id: $model_id, 
      model_year_id: $model_year_id,
      model_type_id: $model_type_id,
      garage_id: $garage_id,
      fuel_type_id: $fuel_type_id,
      gear_type_id: $gear_type_id
      }) {
      affected_rows
      returning {
        id
      }
    }
  }`;
}

@Injectable()
export class VEHICLE_CREATE_CUSTOM extends Mutation<any>{
  override document = gql`
  mutation VEHICLE_CREATE_CUSTOM( $object: vehicles_insert_input! ) {
    insert_vehicles_one(object: $object) {
        id   
    }
}`;
}




@Injectable()
export class VEHICLE_ID extends Query<ResponseSingle> {
  override document = gql`
    query VEHICLE_ID($id: bigint!){
        vehicles_by_pk(id: $id) {
            id
        plate
        brand_id
        model_id
        type_id
        model_year_id
        colour_id
        traffic_release_date
        engine_no
        chassis_no
        license_name
        license_surname
        note
        garage_id
        garages{
          id
          name
        }
        colour_code_id
        colour_type_id
        tire_id
        gear_type_id
        model_type_id
        fuel_type_id
        last_km
        years {
          id
          year_id
          year {
            id
            name
          }
        }
        vehicle_model_types{
          id
          name
        }
        vehicle_brand {
          id
          name
        }
        vehicle_model {
          id
          name
          gos_labour_price
          brand_labour_price
        }
          }
    }
  `;
}

@Injectable()
export class VEHICLE_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query VEHICLE_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc,$where: vehicles_bool_exp) {
      vehicles(limit: $limit, offset: $offset, order_by: {id: $orderBy},where:$where) {
      
              id
          plate
          brand_id
          model_id
          type_id
          model_year_id
          colour_id
          traffic_release_date
          engine_no
          chassis_no
          license_name
          license_surname
          note
          colour_code_id
          colour_type_id
          tire_id
          gear_type_id
          fuel_type_id
          last_km
          years {
            id
            year_id
          }
          vehicle_brand {
            id
            name
            logo_url
          }
          vehicle_model {
            id
            name
          }
          vehicle_model_types{
            id
            name
          }

        }
      }
  `;
}

@Injectable()
export class VEHICLE_COUNT extends Query<any> {
  override document = gql`
    query VEHICLE_COUNT($where: vehicles_bool_exp) {
        vehicles_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}

@Injectable()
export class VEHICLE_DELETE extends Mutation<any> {
  override document = gql`
    mutation VEHICLE_DELETE($id: bigint!) {
      delete_vehicles(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class VEHICLE_UPDATE_CUSTOM extends Mutation<any>{
  override document = gql`
    mutation VEHICLE_UPDATE_CUSTOM(
      $where: vehicles_bool_exp!,
      $changes: vehicles_set_input
    ){
      update_vehicles(where:$where,  _set: $changes){
        affected_rows
        returning{
          id
        }
      }
    }
  `;
}