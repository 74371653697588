import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAINTENANCE_KMS_ALL } from 'src/app/graphql/package.graphql';
import { PackageKms, PACKAGE_KMS_ALL } from 'src/app/graphql/package.kms';
import { VehicleBrand, VEHICLE_BRAND_ALL } from 'src/app/graphql/vehicle-brand.graphql';
import { VehicleModel, VEHICLE_MODEL_ALL } from 'src/app/graphql/vehicle-model.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { CustomerOfferService } from 'src/app/services/customerOffer.service';
import { MailService } from 'src/app/services/mail.service';

@Component({
  selector: 'app-shared-get-an-offer',
  templateUrl: './get-an-offer.component.html',
  styles: [
  ],
  providers:[VEHICLE_BRAND_ALL,VEHICLE_MODEL_ALL,PACKAGE_KMS_ALL]
})
export class GetAnOfferComponent implements OnInit {

  public vehicleBrandList: VehicleBrand[] | undefined;
  public vehicleModelList: VehicleModel[] | undefined;
  public vehicleKmList: PackageKms[] | undefined;
  public showPopup: boolean = false;
  public disabledBtn: boolean = true;
  public clearInput: boolean = false;
  public isButtonDisable: boolean = false;

  public selectedBrand: VehicleBrand | undefined; 
  public selectedModel: VehicleModel | undefined; 
  public selectedKm: PackageKms | undefined; 
  public selectedBrandName: string = "Marka";
  public selectedModelName: string = "Model";
  public selectedKmName: string = "Kilometre";

  formGroup = new FormGroup({
    vehicle_brand_id: new FormControl('', [Validators.required]),
    vehicle_model_id: new FormControl('', [Validators.required]),
    maintenance_kms_id: new FormControl('', [Validators.required]),
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    service_date: new FormControl('', [Validators.required])
   
  });

  constructor(
    private getVehicleBrandList: VEHICLE_BRAND_ALL,
    private getVehicleModels: VEHICLE_MODEL_ALL,
    private getPackageKms: PACKAGE_KMS_ALL,
    private alertifyService: AlertifyService,
    private customerOfferService: CustomerOfferService,
    private mailService: MailService
  ) { }

  ngOnInit(): void {

    this.getVehicleBrandList.watch({where:{active:{_eq:true}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe((resp)=>{
      if( resp.errors ){
        this.alertifyService.error(resp.errors[0].message);
      }

      if( resp.data ){
        this.vehicleBrandList = resp.data.vehicle_brands;

      }
    })

  }

  selectedTwo(type:string,event:any){

    switch(type){

      case "vehicleBrand":

        this.vehicleModelList = undefined;

        this.selectedBrand = this.vehicleBrandList?.find((obj:any)=>obj.id == event);

       

        this.formGroup.patchValue({vehicle_brand_id:event,vehicle_model_id: ''});
        
        this.getVehicleModels.watch({where:{vehicle_brand_id:{_eq:event}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe((resp)=>{
          if( resp.errors ){
            this.alertifyService.error(resp.errors[0].message);
          }
    
          if( resp.data ){
            this.vehicleModelList = resp.data.vehicle_models;
    
          }
        });
    

      break;

      case "vehicleModel":
     
        this.formGroup.patchValue({vehicle_model_id: event});
        this.selectedModel = this.vehicleModelList?.find((obj:any)=>obj.id == event);
        if(this.vehicleKmList == undefined){
          this.getPackageKms.watch({}).valueChanges.subscribe((resp)=>{
            if( resp.errors ){
              this.alertifyService.error(resp.errors[0].message);
            }
      
            if( resp.data ){
              this.vehicleKmList = resp.data.maintenance_kms;
            }
          });
        }
        
      break;

      case "vehicleKm":
        this.formGroup.patchValue({maintenance_kms_id: event});
        this.selectedKm = this.vehicleKmList?.find((obj:any)=>obj.id == event);
      break;



    }
    this.disabledBtn = (this.formGroup.value.vehicle_brand_id && this.formGroup.value.vehicle_model_id && this.formGroup.value.maintenance_kms_id) ? false : true;


  }

  togglePopup(){
    if(!this.showPopup){
      if(this.formGroup.value.vehicle_brand_id && this.formGroup.value.vehicle_model_id && this.formGroup.value.maintenance_kms_id){
        this.showPopup = !this.showPopup;
      }
    }else{
      this.showPopup = !this.showPopup;
    }
    
  }


  formSubmit(){
    if(this.formGroup.invalid){
      this.alertifyService.error("Hatalı veya eksik form datası");
      return false;
    }
    this.isButtonDisable = true;
      this.customerOfferService.send(this.formGroup.value.vehicle_brand_id, this.formGroup.value.vehicle_model_id,this.formGroup.value.maintenance_kms_id, this.formGroup.value.firstname,this.formGroup.value.lastname,this.formGroup.value.email,this.formGroup.value.phone,this.formGroup.value.service_date,[] ).subscribe(mailResponse => {
 
        //if (this.order.customer.user_phones.length) {
        //}

        if(mailResponse.status){
           this.alertifyService.success("Kaydınız aldındı. En kısa sürede dönüş yapacağız.");
           this.selectedBrandName = 'Marka';
           this.selectedModelName = 'Model';
           this.selectedKmName = 'Kilometre';
           this.clearInput = true;
         }
         this.isButtonDisable = false;
         this.formGroup.reset();
         this.showPopup = false;
        

       });

       this.customerOfferService.send(this.formGroup.value.vehicle_brand_id, this.formGroup.value.vehicle_model_id,this.formGroup.value.maintenance_kms_id, this.formGroup.value.firstname,this.formGroup.value.lastname,this.formGroup.value.email,this.formGroup.value.phone,this.formGroup.value.service_date,[] ).subscribe(mailResponse => {
 
        //if (this.order.customer.user_phones.length) {
        //}

        if(mailResponse.status){
           this.alertifyService.success("Kaydınız aldındı. En kısa sürede dönüş yapacağız.");
           this.selectedBrandName = 'Marka';
           this.selectedModelName = 'Model';
           this.selectedKmName = 'Kilometre';
           this.clearInput = true;
         }
         this.isButtonDisable = false;
         this.formGroup.reset();
         this.showPopup = false;
        

       });
     }
  


  

}
