import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';
import { PackageKms } from './package.kms';

export interface Package {
  id: Number;
  name: String;
  brand_id: bigint;
  model_year_id: Number;
  fuel_id: Number;
  gear_id: Number;
  maintenance_kms_id: bigint,
  active: Boolean;
  position_no: Number;
  vehicle_fuel_type?: any;
  vehicle_brands?:any;
  vehicle_model_types:any;
  maintenance_kms:PackageKms;
  vehicle_model_years:any;
  model_id:bigint;
  vehicle_models:any;
  maintenance_package_details?:any;
  package_model_types?:any;
  labour_added: Number;
  cargo_cost: Number;
}

interface ResponseArray {
  maintenance_packages: Package[];
}

interface ResponseSingle {
  maintenance_packages_by_pk: Package
}

@Injectable()
export class PACKAGE_ALL extends Query<ResponseArray> {
  // (where: {active: {_eq: true}})
  override document = gql`
    query PACKAGE_ALL($where: maintenance_packages_bool_exp){
        maintenance_packages(where: $where, order_by: {maintenance_kms: {position_no: asc}}) {
          id
          name
          maintenance_kms_id
          fuel_id
          gear_id
          active
          position_no
          labour_added
          cargo_cost
          vehicle_fuel_type {
            name
          }
        
         
          maintenance_kms {
            id
            name
            active
            position_no
          }
  
          package_model_types{
            id
            vehicle_model_types{
                id
            name
                vehicle_model_year{
                  id
                  year_id
                  vehicle_model{
                    id
                    name
                    vehicle_brand{
                      id
                      name
                    }
                  }
                }
            }
            
          }
          maintenance_package_details{
            id
            maintenance_package_id
            part_id
            part_original_id
            part_unit
            part_unit_type
            position_no
            part_group_id
            active
            maintenance_package {
              name
            }
            part_group_list {
              name
            }
            part_original_list {
              id
              name
              part_in_vat_price
              part_no_vat_price
              price_unit
              part_code
              part_type_id
              price_unit_id
              part_equivalent_lists( order_by:{part_no_vat_price : asc}) {
                id
                name
                part_code
                part_in_vat_price
                part_no_vat_price
                price_unit
                part_type_id
                price_unit_id
                
              }
              equivalent_models{
                id
                part_equivalent_list{
                  id
                  name
                  part_code
                  part_in_vat_price
                  part_no_vat_price
                  price_unit
                  part_type_id
                  price_unit_id
                }
              }
            }
            
            part{
              name
              part_group_id
            }
          }

        
  
        }
    }
  `;
}



@Injectable()
export class PACKAGE_ID extends Query<ResponseSingle> {
  override document = gql`
  query PACKAGE_ID($id: bigint!) {
    maintenance_packages_by_pk(id: $id) {
      id
      name
      maintenance_kms_id
      fuel_id
      active
      position_no
      labour_added
      cargo_cost
      vehicle_fuel_type {
        name
      }
      maintenance_kms {
        name
      }
      maintenance_package_details(order_by: {part: {part_group: {position_no: desc},position_no: desc}}) {
        id
        maintenance_package_id
        part_id
        part_original_id
        part_unit
        part_unit_type
        position_no
        part_group_id
        active
        part_unit_types {
          id
          name
        }
        maintenance_package {
          name
        }
        part_group_list {
          id
          name
          note
        }
        part_original_list {
          id
          name
          part_in_vat_price
          part_no_vat_price
          price_unit
          part_code
          part_type_id
          price_unit_id
          part_types {
            id
            name
          }
          part_equivalent_lists(order_by: {part_no_vat_price: asc}) {
            id
            name
            part_code
            part_in_vat_price
            part_no_vat_price
            price_unit
            part_type_id
            price_unit_id
            part_types {
              name
            }
            part_brand {
              id
              name
            }
          }
        }
        part {
          id
          name
          part_group_id
          price_ratio
          original_price_ratio
          brand_labour_hour
          position_no
          part_group {
            id
            name
            position_no
            note
          }
        }
      }
      package_model_types {
        id
        vehicle_model_types {
          id
          name
          vehicle_model_year {
            id
            year_id
            vehicle_model {
              id
              name
              vehicle_brand {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  `;
}

@Injectable()
export class APPOINTMENT_PACKAGE_ID extends Query<ResponseSingle> {
  override document = gql`
  query APPOINTMENT_PACKAGE_ID($id: bigint!,$model_id:Int) {
    maintenance_packages_by_pk(id: $id) {
      id
      name
      maintenance_kms_id
      fuel_id
      active
      position_no
      cargo_cost
      vehicle_fuel_type {
        name
      }
      maintenance_kms {
        name
      }
      maintenance_package_details(order_by: {part: {part_group: {position_no: desc},position_no: desc}}) {
        id
        maintenance_package_id
        part_id
        part_original_id
        part_unit
        part_unit_type
        position_no
        part_group_id
        active
        part_unit_types {
          id
          name
        }
        maintenance_package {
          name
        }
        part_group_list {
          id
          name
          note
        }
        part_original_list {
          id
          name
          part_in_vat_price
          part_no_vat_price
          price_unit
          part_code
          part_type_id
          price_unit_id
          equivalent_models(where:{model_id:{_eq:$model_id}}){
            id
            part_equivalent_list{
              id
              name
              part_code
              part_in_vat_price
              part_no_vat_price
              price_unit
              part_type_id
              price_unit_id
              part_types{
                id
                name
              }
              part_brand {
                id
                name
              }
            }
          }
          part_types {
            id
            name
          }
          part_equivalent_lists(order_by: {part_no_vat_price: asc}) {
            id
            name
            part_code
            part_in_vat_price
            part_no_vat_price
            price_unit
            part_type_id
            price_unit_id
            part_types {
              name
            }
            part_brand {
              id
              name
            }
          }
        }
        part {
          id
          name
          part_group_id
          price_ratio
          original_price_ratio
          brand_labour_hour
          position_no
          part_group {
            id
            name
            position_no
            note
          }
        }
      }
      package_model_types {
        id
        vehicle_model_types {
          id
          name
          vehicle_model_year {
            id
            year_id
            vehicle_model {
              id
              name
              vehicle_brand {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  `;
}

@Injectable()
export class PACKAGE_CREATE extends Mutation<any> {
  override document = gql`
    mutation PACKAGE_CREATE(
      $name: String!, 
      $fuel_id: Int!, 
      $gear_id: Int!, 
      $maintenance_kms_id: bigint!, 
      $active: Boolean = false, 
      $position_no: Int = 0,
      $cargo_cost:number) {
    insert_maintenance_packages(objects: {name: $name,fuel_id: $fuel_id,gear_id: $gear_id,  maintenance_kms_id: $maintenance_kms_id, active: $active, position_no: $position_no,cargo_cost:$cargo_cost}) {
    affected_rows
      returning {
        id
      }
    }
    }
  `;
}



@Injectable()
export class PACKAGE_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
     mutation PACKAGE_UPDATE(
       $id: bigint!, 
       $name: String!, 
       $fuel_id: Int!,
       $gear_id: Int!,
       
       $maintenance_kms_id: bigint!, 
       $active: Boolean!,
       $cargo_cost:numeric) {
      update_maintenance_packages(where: {id: {_eq: $id}}, _set: {
        active: $active, 
        fuel_id: $fuel_id, 
        gear_id: $gear_id,
        name: $name, 
        maintenance_kms_id: $maintenance_kms_id, 
        cargo_cost: $cargo_cost
       }) {
        affected_rows
      }
    }
  `;
}


@Injectable()
export class PACKAGE_DELETE extends Mutation<any> {
  override document = gql`
    mutation PACKAGE_DELETE($id: bigint!) {
      delete_maintenance_packages(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PACKAGE_PAGINATE extends Query<ResponseArray> {
  override document = gql`
  query PACKAGE_PAGINATE($limit: Int = 20, $offset: Int = 0, $orderBy: order_by = asc, $where: maintenance_packages_bool_exp,$modelWhere: maintenance_package_model_types_bool_exp) {
    maintenance_packages(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
      id
      name
      fuel_id
      active
      position_no
      cargo_cost
      vehicle_fuel_type {
        name
      }
      maintenance_kms{
        id
        name
      }
      package_model_types(where: $modelWhere) {
        vehicle_model_types {
          vehicle_model_year {
            id
            year_id
            year{
              name
            }
            
            vehicle_model {
              id
              name
              vehicle_brand{
                id
                name
              }
            }
          }
        }
      }
    }
  }
  `;
}

@Injectable()
export class PACKAGE_COUNT extends Query<any> {
  override document = gql`
    query PACKAGE_COUNT( $where: maintenance_packages_bool_exp) {
      maintenance_packages_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}

@Injectable()
export class MAINTENANCE_KMS_ALL extends Query<any> {
  override document = gql`
    query MAINTENANCE_KMS_ALL( $where: maintenance_packages_bool_exp) {
      maintenance_packages( where: $where) {
        maintenance_kms(order_by:{name:ASC}) {
          id
          name
        }
      
      }
    }
  `;
}

