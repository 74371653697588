<style>
    @media screen and ( min-width: 992px) and (max-width: 1199px) {
 

.w-lg-500px {
    width: 90% !important;
}
  
}
</style>
<div class="d-flex flex-center flex-column flex-column-fluid">
    <div class="w-lg-500px p-10 p-lg-15 mx-auto">
        <form class="form w-100" novalidate="novalidate" action="#" [formGroup]="authFormGroup"
            (ngSubmit)="authFormSubmit()">
            <div class="text-center mb-10"  *ngIf="router.url !== '/teklif'">
                <h1 class="text-dark mb-3">Garantili Oto Servis Portalı</h1>
                
            </div>
            <div class="fv-row mb-10">
                <label class="form-label fs-6 fw-bolder text-dark">Email</label>
                <input class="form-control form-control-lg form-control-solid" type="text" name="email"
                    autocomplete="off" placeholder="Email" formControlName="email" />
            </div>
            <div class="fv-row mb-10">
                <div class="d-flex flex-stack mb-2">
                    <label class="form-label fw-bolder text-dark fs-6 mb-0">Şifre</label>
                   
                </div>
                <input class="form-control form-control-lg form-control-solid" type="password" name="password"
                    autocomplete="off" placeholder="Şifre" formControlName="password" />
            </div>
            <div class="fv-row mb-10">
                <div class=" text-gray-400 fw-bold fs-4 w-402px clearfix">
                    <a [routerLink]="['/auth']" [queryParams]="{type:'signup'}" class="link-primary fw-bolder">Yeni Hesap Ekle</a>
                    <a [routerLink]="['/auth']" [queryParams]="{type:'forgot'}"
                    *ngIf="router.url !== '/teklif-al'"
                        class="link-primary fs-6 fw-bolder" style="float: right;">Şifremi Unuttum ?</a>
                </div>
            </div>
          
            <div class="text-center">
                <button type="submit" class="btn btn-lg btn-primary w-100 mb-5" [disabled]="!authFormGroup.valid">
                    <span class="indicator-label">Giriş</span>
                    <span class="indicator-progress">Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
                <div class="text-center text-muted text-uppercase fw-bolder mb-5">veya</div>
                <a class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5" (click)="googleLogin()">
                    <img alt="Logo" src="assets/media/svg/brand-logos/google-icon.svg" class="h-20px me-3" />Google ile devam et</a>
                <a class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5" (click)="facebookLogin()">
                    <img alt="Logo" src="assets/media/svg/brand-logos/facebook-4.svg" class="h-20px me-3" />Facebook ile devam et</a>
                <a class="btn btn-flex flex-center btn-light btn-lg w-100">
                    <img alt="Logo" src="assets/media/svg/brand-logos/apple-black.svg" class="h-20px me-3" />Apple ile devam et</a>
            </div>
        </form>
    </div>
</div>