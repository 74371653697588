import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { Order, ORDER_ID, ORDER_UPDATE } from 'src/app/graphql/order.graphql';
import { ORDER_PART_CREATE, ORDER_PART_UPDATE } from 'src/app/graphql/order_part.graphql';
import { Vehicle, VEHICLE_ID } from 'src/app/graphql/vehicle.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-shared-order-manuel-part',
  templateUrl: './order-manuel-part.component.html',
  styles: [
  ],
  providers:[ORDER_ID,VEHICLE_ID,ORDER_PART_UPDATE,ORDER_UPDATE,ORDER_PART_CREATE]
})
export class OrderManuelPartComponent implements OnInit {

  @Input() orderId: Number | undefined;

  @Output() updatePriceEmit:any = new EventEmitter();
  @Output() addServicePriceEmit:any = new EventEmitter();

  public orderDetail: Order | undefined;
  public drawerTitle: string ="";
  public showDrawer: boolean = false;
  public updatedId: Number | undefined;
  public selectedParts :any = [];
  public unselectedParts:any  = [];
  public vehicleInfo: Vehicle | undefined;
  public selectedPartCode: String | undefined;
  public updateList:any = [];
  public showAddPiece: boolean = false;
  

  constructor(
    private alertifyService: AlertifyService,
    private getOrder: ORDER_ID,
    private getVehicle: VEHICLE_ID,
    private authService : AuthService,
    private updateOrderPart: ORDER_PART_UPDATE,
    private updateOrder:ORDER_UPDATE,
    private createOrderPart: ORDER_PART_CREATE
  ) { }

  ngOnInit(): void {
    
    moment.locale('tr');
    moment.defaultFormat = "YYYY-MM-DD HH:mm";

  }

  ngOnChanges(){

    if(this.orderId == undefined){
      this.alertifyService.error("Randevu Id gelmedi");
    }

    this.getOrder.watch( {id: this.orderId }, { fetchPolicy: "no-cache" } ).valueChanges.subscribe((result:any)=>{

      if(result.errors){
        this.alertifyService.error(result.errors[0].message);
        return false;
      }

      if(result.data){

        this.orderDetail = result.data.orders_by_pk;

        if( this.orderDetail?.order_parts.length == 0 ){
          this.alertifyService.error("Bu siparişe parça eklenmemiş");
          return false;
        }


        this.orderDetail?.order_parts.forEach((element:any) => {
          element.name = element.parts.name;
          if(element.selected_part_id == null || parseInt(element.selected_part_id) == 0){
            this.unselectedParts.push(element);
          }else{
            this.selectedParts.push(element);
          }
        });



        if( this.orderDetail?.invoice_id != null ){
          this.alertifyService.error("Bu randevu faturalanadırılmış.");
          return false;
        }

        this.getVehicle.watch({id: this.orderDetail?.vehicle_id}).valueChanges.subscribe((result:any)=>{
          if(result.errors){
            this.alertifyService.error(result.errors[0].message);
          }
          if(result.data){
            this.vehicleInfo = result.data.vehicles_by_pk;
          }
        })


      }

        
    })

  }

  toggleDrawer(id:Number =0) {
    this.updatedId = 0;
    if( id > 0 ){
      this.updatedId = id;
    }
    this.drawerTitle = "Parça Güncelle";
    this.showDrawer = !this.showDrawer
  }

 

  setSelectedParts(event:any){
    this.updateList = event;
  }

  selectedtwo(event:any,type:string){
    switch(type){
      case "partCode":
        this.selectedPartCode = event;

        this.unselectedParts.filter()
      break;
    }
  }

  updateSelectedParts(){
    if(this.updateList?.parts.length == 0){
      this.alertifyService.error("Seçili parça yok");
      return false;
    }

    this.updateList.parts.forEach((element:any) => {
       let selectPart = this.unselectedParts.filter((obj:any)=>obj.parts.id == element.selectedGosPart_id);

       

       if(selectPart.length > 0){

        let updateObj = {
          order_id : this.orderId,
          part_id: element.selectedGosPart_id,
          labour_type_id:  element.labourTypeId,
          labour_value: (element.labourTypeId == null) ? 0 : element.labourTypeId,
          labour_discount: (element.labourDiscount == null ) ? 0 : element.labourDiscount,
          part_type_id: element.selectedEquivalent_partTypeId,
          part_value: element.selectedEquivalent_partValue,
          part_discount: (element.selectedEquivalent_partDiscount == null) ? 0 : element.selectedEquivalent_partDiscount,
          updated_personel_id: this.authService.userId,
          selected_part_id: element.selectedEquivalent_id,
          service_value: element.selectedEquivalent_serviceValue,
          brand_labour_price: (element.brandLabourPrice == null) ? 0 : element.brandLabourPrice,
          gos_labour_price: (element.gosLabourPrice == null) ? 0 : element.gosLabourPrice,
          price_unit_id: element.selectedEquivalent_priceUnitId,
          total_price_no_vat: element.selectedEquivalent_priceNoVatTotal,
          currency: element.selectedEquivalent_currencyPriceUnitId,
          total_price: element.selectedEquivalent_priceTotal,
          part_unit_type_id: element.selectedEquivalent_partUnitTypeId,
          part_unit: element.selectedEquivalent_partUnit,
          part_code: element.selectedEquivalent_partCode,
          part_no_vat_price: element.selectedEquivalent_partNoVatValue,
          updated_at: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
          currency_unit_price: element.selectedEquivalent_currencyPrice,
          currency_price_unit_id: element.selectedEquivalent_currencyPriceUnitId,
          service_value_unit: element.selectedEquivalent_serviceValueUnit
        }
        

         this.updateOrderPart.mutate({id: selectPart[0]["id"],changes:updateObj}).subscribe((result:any)=>{

          if(result.errors){
            this.alertifyService.error(result.errors[0].message);
          }

          if(result.data){
            this.unselectedParts = this.unselectedParts.filter((x:any)=>x.id != selectPart[0]["id"]);
            this.setOrderPrice();
          }

         })
       }else{
        let updateObj = {
          order_id : this.orderId,
          part_id: element.selectedGosPart_id,
          labour_type_id:  element.labourTypeId,
          labour_value: (element.labourTypeId == null) ? 0 : element.labourTypeId,
          labour_discount: (element.labourDiscount == null ) ? 0 : element.labourDiscount,
          part_type_id: element.selectedEquivalent_partTypeId,
          part_value: element.selectedEquivalent_partValue,
          part_discount: (element.selectedEquivalent_partDiscount == null) ? 0 : element.selectedEquivalent_partDiscount,
          updated_personel_id: this.authService.userId,
          selected_part_id: element.selectedEquivalent_id,
          service_value: element.selectedEquivalent_serviceValue,
          brand_labour_price: (element.brandLabourPrice == null) ? 0 : element.brandLabourPrice,
          gos_labour_price: (element.gosLabourPrice == null) ? 0 : element.gosLabourPrice,
          price_unit_id: element.selectedEquivalent_priceUnitId,
          total_price_no_vat: element.selectedEquivalent_priceNoVatTotal,
          currency: element.selectedEquivalent_currencyPriceUnitId,
          total_price: element.selectedEquivalent_priceTotal,
          part_unit_type_id: element.selectedEquivalent_partUnitTypeId,
          part_unit: element.selectedEquivalent_partUnit,
          part_code: element.selectedEquivalent_partCode,
          part_no_vat_price: element.selectedEquivalent_partNoVatValue,
          currency_unit_price: element.selectedEquivalent_currencyPrice,
          currency_price_unit_id: element.selectedEquivalent_currencyPriceUnitId,
          service_value_unit: element.selectedEquivalent_serviceValueUnit
        }
    
         this.createOrderPart.mutate(updateObj).subscribe((result:any)=>{
          if(result.errors){
            this.alertifyService.error(result.errors[0].message);
          }

          if(result.data){

            this.setOrderPrice();
          }

         })
       }
    });

  }

  setOrderPrice(){
    this.getOrder.watch({id:this.orderId},{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any) => {
      if(result.errors){
        this.alertifyService.error(result.errors[0].message);
      }

      if(result.data){
        let orderDetail: Order | undefined = result.data.orders_by_pk;

        let orderValue = 0;
        let serviceValue = 0;
        orderDetail?.order_parts.forEach((element:any) => {
          orderValue += element.total_price;
          serviceValue += element.service_value;
        });

        orderValue = parseFloat(orderValue.toFixed(2));
        serviceValue = parseFloat(serviceValue.toFixed(2));
        this.updateOrder.mutate({id:this.orderId,changes:{order_value: orderValue, service_value : serviceValue}}).subscribe((updateResult:any)=>{

          if(updateResult.errors){
            this.alertifyService.error(updateResult.errors[0].message);
          }

          if(updateResult.data){
            window.location.reload();
          }

        })
      }
    })
  }

  togglePiece(){
    this.showAddPiece = !this.showAddPiece;
  }

  updatePrice(id:Number,name:String,price:Number,service_value:Number,part_unit:number){
    this.updatePriceEmit.emit({id:id,name:name,price:price,service_value:service_value,part_unit:part_unit});
  }

  addServicePrice(id:Number,price:any){
    this.addServicePriceEmit.emit({id:id,price:price});
  }

}
