import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface PackageDetail {
  id: bigint;
  maintenance_package_id?: Number;
  part_id: BigInt;
  part_original_id?: Number;
  part_equivalent_id?: Number;
  part_unit?: Number;
  active?: Boolean;
  position_no?: Number;
  maintenance_package?: any;
  part?: any;
  part_original_list?: any;
  part_equivalent_list?: any;
  part_group_list?: any;
  part_type_id:bigint;
  part_unit_type?:Number;
  part_unit_types?:any;
}

interface ResponseArray {
  maintenance_package_details: PackageDetail[];
}

interface ResponseSingle {
  maintenance_package_details_by_pk: PackageDetail
}

@Injectable()
export class PACKAGE_DETAIL_ALL extends Query<ResponseArray> {
  override document = gql`
    query PACKAGE_DETAIL_ALL($where: maintenance_package_details_bool_exp){
      maintenance_package_details(where: $where, order_by: {id: desc_nulls_first}) {
        id
        maintenance_package_id
        part_id
        part_original_id
        part_unit
        part_unit_type
        position_no
        part_group_id
        active
        maintenance_package {
          name
        }
        part_group_list {
          name
        }
        part_unit_types{
          name
        }
        part_original_list {
          id
          name
          part_in_vat_price
          part_no_vat_price
          price_unit
          part_code
          part_type_id
          price_unit_id
          part_equivalent_lists {
            id
            name
            part_code
            part_in_vat_price
            part_no_vat_price
            price_unit
            part_type_id
            part_original_id
            price_unit_id
          }
        }
        
        part{
          name
          part_group_id
        }
      }
    }
  `;
}


@Injectable()
export class PACKAGE_DETAIL_ID extends Query<ResponseSingle> {
  override document = gql`
    query PACKAGE_DETAIL_ID($id: bigint!){
      maintenance_package_details_by_pk(id: $id) {
        id
        maintenance_package_id
        part_equivalent_id
        part_id
        part_original_id
        part_unit
        part_unit_type
        position_no
        active
        part_unit_types{
          name
        }
        maintenance_package {
          name
        }
        part {
          name
        }
        part_original_list {
          name
          part_in_vat_price
          price_unit

        }
        part_equivalent_list {
          name
          part_in_vat_price
          price_unit

        }
        part_group_list {
          part_group_id
          {
            part_groups{
              name
              part_group_id
            }
          }
        }
      }
    }
  `;
}

@Injectable()
export class PACKAGE_DETAIL_CREATE extends Mutation<ResponseSingle> {
  override document = gql`
  mutation PACKAGE_DETAIL_CREATE($maintenance_package_id: Int!, $part_equivalent_id: Int, $part_id: Int!, $part_unit: numeric!, $part_unit_type: Int, $part_original_id: Int!, $position_no: Int = 0, $active: Boolean = false, $part_group_id: bigint!) {
    insert_maintenance_package_details(objects: {
      maintenance_package_id: $maintenance_package_id, 
      part_equivalent_id: $part_equivalent_id, 
      part_id: $part_id, 
      part_unit: $part_unit, 
      part_unit_type: $part_unit_type, 
      part_original_id: $part_original_id, 
      position_no: $position_no,
       active: $active, 
       part_group_id: $part_group_id}) {
      affected_rows
      returning {
        id
      }
    }
  }
  `;
}

@Injectable()
export class PACKAGE_DETAIL_UPDATE_WITH_INPUT extends Mutation<ResponseSingle> {
  override document = gql`
    mutation PACKAGE_DETAIL_UPDATE_WITH_INPUT(
      $where: maintenance_package_details_bool_exp!, 
      $changes: maintenance_package_details_set_input ) {
      update_maintenance_package_details(
        where: $where,
         _set: $changes) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PACKAGE_DETAIL_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
    mutation PACKAGE_DETAIL_UPDATE(
      $id: bigint!, 
      $maintenance_package_id: Int, 
      $part_equivalent_id: Int, 
      $part_id: Int, 
      $part_unit: numeric, 
      $part_unit_type:numeric,
      $part_original_id: Int, 
      $position_no: Int, 
      $active: Boolean) {
      update_maintenance_package_details(where: {id: {_eq: $id}}, _set: {
        maintenance_package_id: $maintenance_package_id, 
        part_equivalent_id: $part_equivalent_id, 
        part_id: $part_id, 
        part_unit: $part_unit,
        part_unit_type: $part_unit_type, 
        part_original_id: $part_original_id, position_no: $position_no, active: $active}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PACKAGE_DETAIL_DELETE extends Mutation<any> {
  override document = gql`
    mutation PACKAGE_DETAIL_DELETE($id: bigint!) {
      delete_maintenance_package_details(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PACKAGE_DETAIL_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query PACKAGE_DETAIL_PAGINATE($limit: Int = 20, $offset: Int = 0, $orderBy: order_by = asc, $where: maintenance_package_details_bool_exp) {
      maintenance_package_details(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        maintenance_package_id
        part_equivalent_id
        part_id
        part_original_id
        part_unit
        part_unit_type
        position_no
        part_unit_types{
          name
        }
        active
        maintenance_package {
          name
        }
        part {
          name
          part_group_id
        }
        part_original_list {
          name
        }
        part_equivalent_list {
          name
        }
      }
    }
  `;
}

@Injectable()
export class PACKAGE_DETAIL_COUNT extends Query<any> {
  override document = gql`
    query PACKAGE_DETAIL_COUNT($active: Boolean = true) {
      maintenance_package_details_aggregate( where: {active: {_eq: $active}}) {
        aggregate {
          count
        }
      }
    }
  `;
}
