import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
@Component({
  selector: 'app-shared-selectwo',
  templateUrl: './selectwo.component.html',
  styles: [
  ],
  styleUrls:['./selectwo.component.css']
})
export class SelectwoComponent implements OnInit {
  public showResult: boolean = false;
  
  @Input() lists:any;
  @Input() test:boolean | undefined;
  @Input() name!:any;
  @Input() id!:string;
  @Input() showAll:boolean = true;
  @Input() extendSearch:boolean = false;
  @Output() selectedItem = new EventEmitter<any>();
  @Output() searchItem = new EventEmitter<any>();
  @Output() clickOutside = new EventEmitter<any>();
  filteringItems: any;
  @Input() fieldName:string = 'name';
  @Input() returnFields:any = [];
  @Input() returnField:string = 'id';
  @Input() setItem:any;
  @Input() isUserSearch:boolean=false;
  @Input() resultDesign:any=[];
  @Input() searchItemLength:number = 3;
  @Input() clearInput: boolean = false;
  @Input() multipleReturn: boolean = false;
  @Input() addRequiredValidator: boolean | undefined = false; 
  @Input() isValid: any = false;
  @Input() isClicked: any = false;
  private slctdItem:any = undefined;
  
  
  @ViewChild('selectTwoNativeElement') selectTwoNativeElement!: ElementRef;

  public inputval: String = "Tür";
  constructor(private eRef: ElementRef,private cd: ChangeDetectorRef) {}

  @HostListener('click', ['$event'])
  clickInside(event:any) {
    if(event.target.closest("a.selectwoItem")){
      this.closePopup();
    }else{
      this.showResult = true;
      if(this.lists){
      this.filteringItems = this.lists.filter((i:any)=> i != null);
    }
      let resultDiv = document.getElementById(this.id);
     
      if(resultDiv != undefined && !resultDiv.classList.contains("show")){
        resultDiv.classList.add("show");
        
      }
    }
   
  }
  
  @HostListener('document:click')
  clickout() {

      this.closePopup();
      this.clickOutside.emit(true);
    
  }

  ngOnInit(): void {
   this.slctdItem = undefined;
   
  // alert(this.slctdItem)
  }



  async ngOnChanges(){

    

    this.cd.detectChanges();

    if(this.clearInput){
      this.slctdItem = undefined;
    }


    if(this.name != undefined){
      this.inputval = this.name;
    
  
    }
    if(this.slctdItem != undefined){
      this.inputval = this.slctdItem;
    }

    if(this.lists){
      this.filteringItems = await this.lists.filter((i:any)=> i != null);
    }

     if(this.setItem && (this.filteringItems && this.filteringItems.length > 0)){
       
        this.lists.filter((obj: { [x: string]: string; }) => {
          if(this.setItem == obj[this.returnField]){
            this.selectItem(obj[this.fieldName]);
          }
        })
      }
      if(this.addRequiredValidator){
      }

      if(this.isClicked){
        this.selectTwoNativeElement.nativeElement.click();
        this.isClicked = false;
      }
  }

  closePopup(){
    if (!this.showResult) {
      document.getElementById(this.id)?.classList.remove("show");
      this.filteringItems = []

    }
    this.showResult = false;
  
  }

  toggle($event:any){
    this.showResult = !this.showResult;
    
  }

  async selectItem(item:any){
    let selected;

    
    if(item == 0){
      this.inputval = this.name;
    }else{
     
      selected = this.lists.filter((obj: { [x: string]: string; }) =>{
        if(obj[this.fieldName] === item){
          return obj;
        }
       
        return false;
      });
     
      if(selected){
       
        this.inputval = selected[0][ this.fieldName ];
        this.showResult = false;
        this.closePopup();
        
      }

      
    }
    let emitedData: any;
    
    if(selected){
      if(!this.multipleReturn){
        emitedData = selected[0][ ( this.returnField ) ? this.returnField : this.fieldName ];

      }else{
        emitedData = {};
        await this.returnFields.forEach(element => {
         
          emitedData[element] = selected[0][element];
        });

      }
    }else{
      emitedData = item;
    }

    
//    this.slctdItem = item;
    this.selectedItem.emit(emitedData);
    
  }

  search($event:any){
    this.searchItem.emit($event);
    if($event.target.value.length >= this.searchItemLength  ){
     
      if(this.extendSearch){

        this.searchItem.emit($event);
  
      }else{
        if($event.target.value.length == 0){
          this.filteringItems = [];
          
          return false;
        }
   
        const searchTerm = this.normalizeTurkish($event.target.value.toLowerCase());
        if(this.multipleReturn){

          this.filteringItems = this.lists.filter((obj: { [x: string]: any }) => {
            const fieldValue = obj[this.fieldName];
          
            // Eğer fieldValue bir string ise toLowerCase uygula, değilse onu string'e çevir.
            const normalizedField = this.normalizeTurkish(
              typeof fieldValue === 'string' 
                ? fieldValue.toLowerCase() 
                : String(fieldValue)
            );
          
            // searchTerm'de de Türkçe karakterleri normalize etmek için normalizeTurkish kullanıyoruz
            const normalizedSearchTerm = this.normalizeTurkish(searchTerm.toLowerCase());
            
            return normalizedField.includes(normalizedSearchTerm);
          });
        }else{

        
          this.filteringItems = this.lists.filter((obj: { [x: string]: any }) => {
            const fieldValue = obj[this.fieldName];
          
            // Eğer fieldValue bir string ise toLowerCase uygula, değilse onu string'e çevir.
            const normalizedField = this.normalizeTurkish(
              typeof fieldValue === 'string' 
                ? fieldValue.toLowerCase() 
                : String(fieldValue)
            );
          
            // searchTerm'de de Türkçe karakterleri normalize etmek için normalizeTurkish kullanıyoruz
            const normalizedSearchTerm = this.normalizeTurkish(searchTerm.toLowerCase());
            
            return normalizedField.includes(normalizedSearchTerm);
          });
/*
          this.filteringItems = this.lists.filter((obj: { [x: string]: string; }) =>{
            const locales = ["tr", "TR", "tr-TR", "tr-u-co-search", "tr-x-turkish"]
            if(obj[this.fieldName].toLocaleLowerCase(locales).search($event.target.value.toLocaleLowerCase()) > -1 ){
              return obj;
            } 
            return false;
          });*/
        }
       
      }
    }  

    if($event.target.value.length == 0  ){
      this.filteringItems = this.lists;
    }

    
  }

  normalizeTurkish(str: string): string {
    return str
      .replace(/İ/g, 'i') 
      .replace(/ı/g, 'i') 
      .replace(/i̇/g, 'i')
      .replace(/ç/g, 'c')
      .replace(/ğ/g, 'g')
      .replace(/ö/g, 'o')
      .replace(/ş/g, 's')
      .replace(/ü/g, 'u')
      .replace(/Ç/g, 'C')
      .replace(/Ğ/g, 'G')
      .replace(/Ö/g, 'O')
      .replace(/Ş/g, 'S')
      .replace(/Ü/g, 'U');
  }

  selectData(id:bigint){
    this.selectedItem.emit(id);
    
  }

  getField(item:any){
  
    if(this.fieldName.includes(".")){
      let newdata = this.fieldName.split(".");
      
      newdata.forEach(function(v,k){
        item = item[v];
      })
    
      return item;
    }else{
      return item[this.fieldName];
    }
    
  }

  isObject(val:any): string { return typeof val }

  objectkey(val:any):string{
    return Object.keys(val)[0];
  }
}
