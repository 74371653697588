
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<form [formGroup]="form">
<div>
<h3 class="text-center">Servisi Değerlendir</h3>
<div class="row" style="padding:15px  0px; ">
    <div class="col-md-3" style="padding-left: 0px;">
        <div class="cont">
            <div class="stars">
              <ngx-star-rating  formControlName="rating1" [id]="'rating1'"></ngx-star-rating>
            </div>
            <div>Genel Puan</div>
          </div>
      
    </div>
    <div class="col-md-3">
        <div class="cont">
            <div class="stars">
              <ngx-star-rating formControlName="rating2" [id]="'rating2'"></ngx-star-rating>
            </div>
            <div>Altyapı Puanı</div>
          </div>
    </div>
    <div class="col-md-3">
        <div class="cont">
            <div class="stars">
              <ngx-star-rating formControlName="rating3" [id]="'rating3'"></ngx-star-rating>
            </div>
            <div>Kadro Puanı</div>
          </div>
    </div>
    <div class="col-md-3" style="padding-right: 0px;">
        <div class="cont">
            <div class="stars">
              <ngx-star-rating formControlName="rating4" [id]="'rating4'"></ngx-star-rating>
            </div>
            <div>Memnuniyet Puanı</div>
           
          </div>
    </div>
    <div class="col-md-12">
        <div class="d-flex justify-content-end flex-shrink-0">

            <button class="btn btn-primary btn-sm" (click)="rate()">Değerlendir</button>  

        </div>

    </div>
</div>

    

    

     

     
   
      


   
</div>
</form>