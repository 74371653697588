import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { Company, COMPANY_ALL } from 'src/app/graphql/company.graphql';
import { Order, ORDER_ID } from 'src/app/graphql/order.graphql';
import { OrderType, ORDER_TYPE_ID } from 'src/app/graphql/order_type.graphql';
import { PaymentTypes, PAYMENT_TYPES_ID } from 'src/app/graphql/payment_types.graphql';
import { VehicleFuelType, VEHICLE_FUEL_TYPE_ID } from 'src/app/graphql/vehicle-fuel-type.graphql';
import { VehicleGearType, VEHICLE_GEAR_TYPE_ID } from 'src/app/graphql/vehicle-gear-type.graphql';
import { VehicleModelType, VEHICLE_MODEL_TYPE_ID } from 'src/app/graphql/vehicle-model-type.graphql';
import { numberFormat, numberFormatStr } from 'src/app/helpers/slugify';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-appointment-v2-proforma',
  templateUrl: './proforma-v2.component.html',
  styles: [
  ],
  providers:[COMPANY_ALL,VEHICLE_MODEL_TYPE_ID,VEHICLE_GEAR_TYPE_ID,VEHICLE_FUEL_TYPE_ID,ORDER_TYPE_ID,PAYMENT_TYPES_ID,ORDER_ID]
})
export class ProformaV2Component implements OnInit {

  @Input() orderId: BigInt | undefined;
 
  @Output() proformaEmitter = new EventEmitter<boolean>();
  public serviceLastDate:any;
  public todayDate:any;
  public deliveryDate:any;
  
  public selectedCompaignProducts:any = [];
  public selectedUncompaignProducts:any = [];
  public showProfoma = false;
  public gosCompany: Company | undefined;
  public serviceInfo: Company | undefined;
  public vehicle: VehicleModelType | undefined;
  public vehicleGearType: VehicleGearType | undefined;
  public vehicleFuelType: VehicleFuelType | undefined;
  public orderTime: any;
  public orderdeliveryTime: any;
  public orderTypeInfo: OrderType | undefined;
  public paymentTypeInfo: PaymentTypes | undefined;
  public labourPrice = 0;
  public order: Order | undefined
  public priceInfo: any;


  constructor(
    private getOrder: ORDER_ID,
    private getCompanies: COMPANY_ALL,
    private alertifyService: AlertifyService,
    private getVehicleModelType: VEHICLE_MODEL_TYPE_ID,
    private getGear: VEHICLE_GEAR_TYPE_ID,
    private getFuel: VEHICLE_FUEL_TYPE_ID,
    private getOrderType: ORDER_TYPE_ID,
    private getPaymentType: PAYMENT_TYPES_ID
  ) { }

  async ngOnInit(): Promise<void>{
    this.getOrder.watch({id:this.orderId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }

      if(resp.data){
        this.order = resp.data.orders_by_pk;

      }
    })
  }
/*
  async ngOnInit(): Promise<void> {
    moment.locale('tr');
    this.serviceLastDate = moment().add(1, 'days').endOf('day').format('LLL');
    this.serviceLastDate = moment(this.serviceDate).format('DD.MM.yyyy H:mm').toString();
    this.todayDate = moment().format('DD.MM.yyyy H:mm').toString();
    this.deliveryDate = moment(this.serviceDate).add(2, 'days').format('DD.MM.yyyy H:mm').toString();
    

    this.selectedCompaignProducts = this.selectedPieces.filter((obj:any) => (obj.selected_part_group_id == 2));//yağ gurubu ise ayrı göster

    this.selectedUncompaignProducts = this.selectedPieces.filter((obj:any) => (obj.selected_part_group_id != 2));

    await this.getCompanies.watch({where:{id:{_eq:1106}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.gosCompany = resp.data.companies[0];
      }
    })

   
    await this.getCompanies.watch({where:{id:{_eq:this.selectedServiceId}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.serviceInfo = resp.data.companies[0];
      }
    })

    await this.getVehicleModelType.watch({id:this.modelTypeId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }

      if(resp.data){
        this.vehicle = resp.data.vehicle_model_types_by_pk;
      }
    })

    await this.getGear.watch({id:this.gearId}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message)
      }
      if(resp.data){
        this.vehicleGearType = resp.data.vehicle_gear_types_by_pk;
      }
    })

    await this.getFuel.watch({id:this.fuelId}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message)
      }
      if(resp.data){
        this.vehicleFuelType = resp.data.vehicle_fuel_types_by_pk;
      }
    })

    await this.getOrderType.watch({id:this.orderType}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message)
      }
      if(resp.data){
        this.orderTypeInfo = resp.data.order_types_by_pk;
      }
    })

    await this.getPaymentType.watch({id:this.paymentTypeId}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message)
      }
      if(resp.data){
        this.paymentTypeInfo = resp.data.payment_types_by_pk;
      }
    })

    await this.selectedPieces.forEach(element => {
      if(element.part_unit_type == 3){
       this.labourPrice = element.newNoVatPrice
      }
    });

    this.proformaEmitter.emit(true);

  }*/

  
  filterCode(item:any){
    const firstThreeDigits = item.substr(0, 3);
    const remainingChars = item.substr(3);
    const convertedString = firstThreeDigits + remainingChars.replace(/./g, '*');
    return convertedString;
  }
 

  toNumberFormat(number: any) {
    return numberFormatStr(number);
  }

  numberFormat(number: any) {
    return numberFormat(number);
  }
}
