export const slugify = (text: String, spaces: string = "-") => {
    var trMap = {
        'çÇ': 'c',
        'ğĞ': 'g',
        'şŞ': 's',
        'üÜ': 'u',
        'ıİ': 'i',
        'öÖ': 'o'
    };
    for (var key in trMap) {
        text = text.replace(new RegExp('[' + key + ']', 'g'), trMap[key])
    }
    return text.replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
        .replace(/\s/gi, spaces) // convert spaces to dashes
        .replace(/[-]+/gi, "-") // trim repeated dashes
        .toLowerCase()

}

export const numberFormatStr = (number: number) => {
    return new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(number)
}

export const trFormatCurrency = (value: number): string => {
    // Türk Lirası formatına çevirme, ancak para birimi simgesi olmadan
    const formatted = new Intl.NumberFormat('tr-TR', {
        style: 'decimal', // 'currency' yerine 'decimal' kullanılıyor
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);

    // Sonuca '₺' işaretini ekleyerek döndürme
    return formatted + '₺';
}

export const numberFormat = (number: number) => {
    return new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(number).replace('₺', '')
}

export const numberFormatWithoutPennies = (number: number) => {
    number = Number(Math.ceil(number).toFixed(0));

    return new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY',  maximumFractionDigits: 0  }).format(number)
}