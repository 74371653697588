<div class="row mb-5">
    <div class="col-xl-3">
        <div class="fs-6 fw-bold">Mahalle</div>
    </div>
    <div class="col-xl-{{widthClass}} fv-row fv-plugins-icon-container">
        <select name="towns" class="form-control" id="townInputId" (change)="selected($event)">
            <option value="">Seçiniz</option>
            <option value="{{item.id}}" [selected]="selectedTownId!=null && selectedTownId == item.id" *ngFor="let item of townList">{{item.name}}</option>
        </select>
       
    </div>
</div>
