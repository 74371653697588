<app-shared-comments
    [openDrawer]="openDrawer"
    (toggleDrawer)="setOpenDrawer($event)"
    [company] = "selectedCompany"
></app-shared-comments>



<div class="d-flex justify-content-between py-5 form-container">
    <div class="d-flex flex-column-fluid justify-content-center tab-header-container">
        <div class="tab-header">
            <div class="tab active" [ngClass]="{active: selectedTab == 'selfService'}">
                <a (click)="changeTab('selfService')">Servisi Kendim Seçeceğim</a>
            </div>
            <div class="tab" [ngClass]="{active: selectedTab == 'nearestService'}">
                <a (click)="changeTab('nearestService')">Yakınımdaki Servisler</a>
            </div>
        </div>
    </div>

   
</div>

<div class="d-flex justify-content-center self-service-container" *ngIf="selectedTab == 'selfService'">
    <div class="service-location-selector-container">
        <div class="service-location-selector">
            <app-shared-cities 
                [widthClass]="12" 
                [event]="1" 
                [showLabel]="false" 
                [selectedCity]="serviceInfoInput.service_city_id"
                (city_id)="changeCity($event,'city')"></app-shared-cities>
                                    
            
        </div>
        <div class="service-location-selector">
            <app-shared-districts 
                [widthClass]="12" 
                [showLabel]="false" 
                [event]="selectedCityId" 
                [selectedDistrictId]="serviceInfoInput.service_district_id"
                (district_id)="changeCity($event,'district')"></app-shared-districts>
        </div>
    </div>

    <div 
        class="service-items-container" 
        [ngClass]="{'selected-service': serviceInfoInput.service_id == item.id}"
        *ngFor="let item of companyList">
        <div class="service-items">
            <div class="service-item-logo">
                <img 
                    *ngIf="item.logo !== null && item.logo !== '' "
                    src="https://cdn.destechhasar.com/garantili-oto-servis/{{item.logo}}" 
                    onerror="this.src='assets/images/garantili-oto-servis-38-750x450.png'" 
                    class="mw-100"  alt="" />

                <img class="mw-100" *ngIf="item.logo === null || item.logo === ''"   src="http://cdn.destechhasar.com/garantili-oto-servis/w:150/h:100/gos-no-image.jpeg" alt="" />
            </div>
            <div class="service-item-content">
                <div class="service-item-content-title justify-content-between">
                    <h4 style="width: 70%;">{{item.name}}</h4>
                    <div class="service-pointer align-items-end" style="width: 30%;">
                        <span style="display: block;" >
                            <i class="fa-star me-1 text-warning fs-5" [ngClass]="item.evalutaion_points && item.evalutaion_points >= 1 ? 'fa' : 'far'"></i>
                            <i class="fa-star me-1 text-warning fs-5" [ngClass]="item.evalutaion_points && item.evalutaion_points >= 2 ? 'fa' : 'far'"></i>
                            <i class="fa-star me-1 text-warning fs-5" [ngClass]="item.evalutaion_points && item.evalutaion_points >= 3 ? 'fa' : 'far'"></i>
                            <i class="fa-star me-1 text-warning fs-5" [ngClass]="item.evalutaion_points && item.evalutaion_points >= 4 ? 'fa' : 'far'"></i>
                            <i class="fa-star me-1 text-warning fs-5" [ngClass]="item.evalutaion_points && item.evalutaion_points >= 5 ? 'fa' : 'far'"></i>
                            <span class="text-gray-800 fw-bolder d-none">
                                {{item.evalutaion_points}}.0
                            </span>
                            
                        </span>
                    </div>
                </div>
                <div class="service-item-content-tools justify-content-between">
                    <span>{{ item.district?.name }}</span>
                    <a (click)="setOpenDrawer(null,item)">{{item.company_evaluations.length}} Yorum</a>
                </div>
                <div class="service-item-content-description">
                    "Sunmuş olduğunuz hizmetten son derece memnun kaldık. Hizmetinizin kalitesi ve göstermiş olduğunuz ilgiden dolayı teşekkür ederim."
                </div>

                <div class="service-item-link-container justify-content-between">
                    <span>Servis Randevusu Planlayın</span>
                    <button (click)="selectService(item.id)">Randevu Al</button>
                </div>

                <div class="service-item-link-container justify-content-between service-tools">
                    <span>Servis İle İletişime Geçin</span>
                    
              
                        <app-shared-service-contact-tooltip
                       
                        [serviceId]="item.id"
                        [serviceName]="item.name"
                        [phone]="item.company_tel_no"
                        (tooltipEmitter)="tooltipEmitter($event)"
                        ></app-shared-service-contact-tooltip>
                
                   
                   
                </div>
            </div>
        </div>

    </div>

   
    
    

    
</div>


<div class="d-flex justify-content-center self-service-container" *ngIf="selectedTab == 'nearestService'">
    <div class="align-items-center pt-5 bg-white"
    *ngIf="showLoader"
    >
        <p class="mx-auto" style="width:60px"> <i class=" fas fa-spinner fa-pulse text-success" style="font-size: 60px;"></i>
    
        </p>
        <p class="mx-auto fs-5" style="width:300px">Servisler getiriliyor lütfen bekleyiniz ...</p>
    </div>
    <div class="service-items-container" 
    [ngClass]="{'selected-service': serviceInfoInput.service_id == item.id}"
     *ngFor="let item of companyList" >
        <div class="service-items">
            <div class="service-item-logo">
                <img *ngIf="item.logo !== null && item.logo !== '' " src="https://cdn.destechhasar.com/garantili-oto-servis/{{item.logo}}" alt="">
                <img class="mw-100" *ngIf="item.logo === null || item.logo === ''"   src="http://cdn.destechhasar.com/garantili-oto-servis/w:150/h:100/gos-no-image.jpeg" alt="" />
            </div>
            <div class="service-item-content">
                <div class="service-item-content-title justify-content-between">
                    <h4>{{item.name}}</h4>
                    <div class="service-pointer align-items-end">
                        <span style="display: block;" >
                            <i class="fa-star me-1 text-warning fs-5" [ngClass]="item.evalutaion_points && item.evalutaion_points >= 1 ? 'fa' : 'far'"></i>
                            <i class="fa-star me-1 text-warning fs-5" [ngClass]="item.evalutaion_points && item.evalutaion_points >= 2 ? 'fa' : 'far'"></i>
                            <i class="fa-star me-1 text-warning fs-5" [ngClass]="item.evalutaion_points && item.evalutaion_points >= 3 ? 'fa' : 'far'"></i>
                            <i class="fa-star me-1 text-warning fs-5" [ngClass]="item.evalutaion_points && item.evalutaion_points >= 4 ? 'fa' : 'far'"></i>
                            <i class="fa-star me-1 text-warning fs-5" [ngClass]="item.evalutaion_points && item.evalutaion_points >= 5 ? 'fa' : 'far'"></i>
                            <span class="text-gray-800 fw-bolder">
                                {{item.evalutaion_points}}.0
                            </span>
                            
                            
                        </span>
                    </div>
                </div>
                <div class="service-item-content-tools justify-content-between">
                    <span>{{item.distance.toFixed(2)}} Km</span>
                    <a>34 Yorum</a>
                </div>
                <div class="service-item-content-description">
                    "Sunmuş olduğunuz hizmetten son derece memnun kaldık. Hizmetinizin kalitesi ve göstermiş olduğunuz ilgiden dolayı teşekkür ederim."
                </div>

                <div class="service-item-link-container justify-content-between">
                    <span>Servis Randevusu Planlayın</span>
                    <button (click)="selectService(item.id)">Randevu Al</button>
                </div>

                <div class="service-item-link-container justify-content-between">
                    <span>Servis İle İletişime Geçin</span>
                    <app-shared-service-contact-tooltip
                       
                        [serviceId]="item.id"
                        [serviceName]="item.name"
                        [phone]="(item.company_tel_no && item.company_tel_no != null ? item.company_tel_no : item.company_gsm_no) "
                        (tooltipEmitter)="tooltipEmitter($event)"
                        ></app-shared-service-contact-tooltip>
                </div>
            </div>
        </div>

    </div>
</div>