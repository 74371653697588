import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-appointment-paket-secimi-v4',
  templateUrl: './paket-secimi-v4.component.html',
  styles: [
  ]
})
export class PaketSecimiV4Component implements OnInit {

  @Input() packageId: bigint | undefined;
  constructor() { }

  ngOnInit(): void {
  }

}
