import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable()
export class AnonymousGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isTokenExpired()) {
      this.authService.logout();
      this.router.navigateByUrl('/');
      return false;
    }
    // token yok ise
    if (!this.authService.parseToken(false)) this.authService.logout();

    // sayfa yenilenirse
    this.authService.login();

    return true;
  }

}
