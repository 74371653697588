import { Injectable } from '@angular/core';
import { gql, Query, Mutation, Subscription } from 'apollo-angular';

export interface OzanpayResponse {
  id: Number;
  ozan_pay_request_id: Number;
  order_id: Number;
  reference_no: String;
  transaction_id: String;
  status: String;
  response_data: string;
  order_card_id: Number;
  code: Number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

interface ResponseArray {
  ozanpay_response: OzanpayResponse[];
}

interface ResponseSingle {
    ozanpay_response_by_pk: OzanpayResponse
}


@Injectable()
export class OZANPAY_RESPONSE_SUBS extends Subscription<ResponseArray> {
  override document = gql`
  subscription OZANPAY_RESPONSE_SUBS($where: ozanpay_response_bool_exp, $limit: Int = 1){
    ozanpay_response(where: $where, order_by: {created_at: desc}, limit: $limit){
        id
        ozan_pay_request_id
        order_id
        reference_no
        transaction_id
        status
        order_card_id
        code
        response_data
        created_at
        updated_at
        deleted_at
    }
  }`;
}
