import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'range'
})
export class RangePipe implements PipeTransform {

  transform(length: number, offset: number = 0): number[] {
    if (!length) {
      return [];
    }
    const array:any = [];
    if(length > 0){
      for (let n = 1; n <= length; ++n) {
        let total = (offset + n);
        array.push(total);
      }
    }
    
    return array;
  }

}
