import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-appointment-odemesiz-randevu-v3',
  templateUrl: './odemesiz-randevu-v3.component.html',
  styles: [
  ],
  styleUrls:['./odemesiz-randevu-v3.component.css']
})
export class OdemesizRandevuV3Component implements OnInit {

  @Input() orderCardId: bigint | undefined;
  @Input() priceInfo: any;
  @Input() userId: Number | undefined;
  @Input() firstnameInput: string | undefined;
  @Input() lastnameInput: string | undefined;
  @Input() emailInput: string | undefined;
  @Input() phoneInput: string | undefined;
  @Input() randevuButtonDisabled: boolean = false;

  public buttonDisable:boolean = false;


  @Output() invoiceAddressFormEmitter = new EventEmitter<any>();
  @Output() createOrderEmitter = new EventEmitter<boolean>();

  public selectedTab = "individual";

  /** invoice variables */
  public invoiceInfo:any = [];

  public disabledButton: boolean = true;

  /** user variavles */
  public userInfo: any;
  public selectedAddressId:number | undefined;

  invoiceAddressForm = new FormGroup({
    customer_name: new FormControl('',[Validators.required]),
    customer_surname: new FormControl('',[Validators.required]),
    country_id: new FormControl(1,[Validators.required]),
    city_id: new FormControl(null,[Validators.required]),
    district_id: new FormControl(null,[Validators.required]),
    town_id: new FormControl(null,[Validators.required]),
    address: new FormControl('',[Validators.required]),
    identity_no: new FormControl(null,Validators.required),
    company_name: new FormControl(null),
    tax_administration: new FormControl(null),
    tax_number: new FormControl(null),
    invoice_type: new FormControl(3,Validators.required),
    customer_invoice_type: new FormControl(1,Validators.required),
    gsm_number: new FormControl(null, [Validators.required]),
    tel_number: new FormControl(null),
    user_id: new FormControl(null, [Validators.required]),
    customer_email: new FormControl(null, [Validators.required]),
    
  })

  sozlesmeForm = new FormGroup({
    check_gizlilik: new FormControl(1,[Validators.required]),
    check_riza: new FormControl(1,[Validators.required]),
    check_aydinlatma: new FormControl(1,[Validators.required]),
    check_sozlesme: new FormControl(1,[Validators.required]),
    e_fatura: new FormControl(1,[Validators.required]),

  })

  constructor(
    public alertifyService: AlertifyService
  ) { 
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
   
    this.invoiceAddressForm.patchValue({
      user_id:this.userId,
      customer_name: this.firstnameInput,
      customer_surname: this.lastnameInput,
      tel_number: this.phoneInput,
      customer_email: this.emailInput,
      gsm_number: this.phoneInput
    })
   

  }

  ngOnChanges(){
    this.buttonDisable = this.randevuButtonDisabled;
  }

  changeGeoZone(type:String,$event:any){
    switch(type){
      case "country": 
     
        this.invoiceAddressForm.patchValue({
          country_id:$event,
          city_id: null,
          district_id: null,
          town_id: null
        });
  
        this.emitInvoiceAddressForm();
       
       
      break;
      case "city": 
       
        this.invoiceAddressForm.patchValue({
          city_id:$event,
          district_id: null,
          town_id: null
        });
  
        this.emitInvoiceAddressForm();
        
      break;
      case "district":
        
      this.invoiceAddressForm.patchValue({
        district_id: $event,
        town_id: null
      });
  
      this.emitInvoiceAddressForm();
        break;
      case "town":
        this.invoiceAddressForm.patchValue({
          town_id: $event
        });
  
        this.emitInvoiceAddressForm();
      break;
    }
  
  }

  emitInvoiceAddressForm(){
    this.invoiceAddressFormEmitter.emit(this.invoiceAddressForm.value);
  }

  setInvoiceInfo(type:string,$event:any,canEmpty:boolean=false){
   
    switch(type){
  
      case "customer_invoice_type": 
      alert($event.target.value);
        this.invoiceAddressForm.patchValue({customer_invoice_type: $event.target.value});
      
        if($event.target.value == 2){
          this.invoiceAddressForm.controls["firstname"].setValidators([]);
          this.invoiceAddressForm.controls["firstname"].updateValueAndValidity();
          this.invoiceAddressForm.controls["lastname"].setValidators([]);
          this.invoiceAddressForm.controls["lastname"].updateValueAndValidity();
          this.invoiceAddressForm.controls["identity_no"].setValidators([]);
          this.invoiceAddressForm.controls["identity_no"].updateValueAndValidity();
          this.invoiceAddressForm.controls["company_name"].setValidators([Validators.required]);
          this.invoiceAddressForm.controls["company_name"].updateValueAndValidity();
          this.invoiceAddressForm.controls["tax_number"].setValidators([Validators.required]);
          this.invoiceAddressForm.controls["tax_number"].updateValueAndValidity();
          this.invoiceAddressForm.controls["tax_administration"].setValidators([Validators.required]);
          this.invoiceAddressForm.controls["tax_administration"].updateValueAndValidity();
  
       
  
          this.invoiceAddressForm.patchValue({
            firstname: null,
            lastname: null,
            identity_no: null
          });
  
          this.emitInvoiceAddressForm();
        }else{
          this.invoiceAddressForm.controls["firstname"].setValidators([Validators.required]);
          this.invoiceAddressForm.controls["firstname"].updateValueAndValidity();;
          this.invoiceAddressForm.controls["lastname"].setValidators([Validators.required]);
          this.invoiceAddressForm.controls["lastname"].updateValueAndValidity();;
          this.invoiceAddressForm.controls["identity_no"].setValidators([Validators.required]);
          this.invoiceAddressForm.controls["identity_no"].updateValueAndValidity();
          this.invoiceAddressForm.controls["company_name"].setValidators([]);
          this.invoiceAddressForm.controls["company_name"].updateValueAndValidity();
          this.invoiceAddressForm.controls["tax_number"].setValidators([]);
          this.invoiceAddressForm.controls["tax_number"].updateValueAndValidity();
          this.invoiceAddressForm.controls["tax_administration"].setValidators([]);
          this.invoiceAddressForm.controls["tax_administration"].updateValueAndValidity();
          
         
  
          this.invoiceAddressForm.patchValue({
            company_name: null,
            tax_number: 0,
            tax_administration: null
          });
  
          this.emitInvoiceAddressForm();
        }
      break;  
  
      case "email":
     
       
        this.invoiceInfo.email = $event.target.value;
        this.invoiceAddressForm.patchValue({customer_email:$event.target.value});
        this.emitInvoiceAddressForm();
          
         
        
       
      break;
      case 'company_name':
        if(canEmpty && $event == ''){
          this.invoiceInfo.company_name = '';
          this.emitInvoiceAddressForm();
        }else{
          let company_name = $event.target.value;
          if(company_name.length < 3){
            this.alertifyService.error("İsim en az 3 karakter olmalıdır");
            //this.invoiceInfo.invoice_name = "";
            this.emitInvoiceAddressForm();
            return false;
          }else{
            this.invoiceInfo.invoice_name = name;
            this.emitInvoiceAddressForm();
          }
        }
      break;
      case "invoice_name":
        if(canEmpty && $event == ''){
          this.invoiceInfo.invoice_name = '';
          this.emitInvoiceAddressForm();
        }else{
          let name = $event.target.value;
          if(name.length < 3){
            this.alertifyService.error("İsim en az 3 karakter olmalıdır");
            //this.invoiceInfo.invoice_name = "";
            this.emitInvoiceAddressForm();
            return false;
          }else{
            this.invoiceInfo.invoice_name = name;
            this.emitInvoiceAddressForm();
          }
        }
        
      break;
  
      case "invoice_surname":
        if(canEmpty && $event == ''){
          this.invoiceInfo.invoice_surname = '';
          this.emitInvoiceAddressForm();
        }else{
          let surname = $event.target.value;
          if(surname.length < 3){
            this.alertifyService.error("İsim en az 3 karakter olmalıdır");
            this.emitInvoiceAddressForm();
          //  this.invoiceInfo.invoice_surname = "";
            return false;
          }else{
            this.invoiceInfo.invoice_surname = surname;
            this.emitInvoiceAddressForm();
          }
        }
        
      break;
  
      case "tax_administration":
        if(canEmpty && $event == ''){
          this.invoiceInfo.tax_administration = '';
          this.emitInvoiceAddressForm();
        }else{
          let taxAdministration = $event.target.value;
          if(taxAdministration.length < 3){
            this.alertifyService.error("Vergi dairesi en az 3 karakter olmalıdır");
            this.emitInvoiceAddressForm();
          //  this.invoiceInfo.invoice_surname = "";
            return false;
          }else{
            this.invoiceInfo.tax_administration = taxAdministration;
            this.emitInvoiceAddressForm();
          }
        }
        
      break;
  
      case "tax_number":
  
        if(canEmpty && $event == ''){
          this.invoiceInfo.tax_number = '';
          this.emitInvoiceAddressForm();
        }else{
         
            let taxNumber = $event.target.value;
            
            if(taxNumber.length != 11){
              this.alertifyService.error('Vergi numarası 11 haneli olmalı');
              this.emitInvoiceAddressForm();
              return false;
              
              
            }else{
              this.invoiceInfo.tax_number = taxNumber;
              this.emitInvoiceAddressForm();
            }
           
          
        }
  
        
        
      break;
  
      case "address":
        this.selectedAddressId = $event.target.value;
        this.invoiceInfo.address = $event.target.value;
        this.invoiceAddressForm.patchValue({address:$event.target.value});
       this.emitInvoiceAddressForm();
        //this.setSelectedAddressInfo();
      break;
  
      case "gsm":
  
              this.invoiceInfo.gsm_number = $event.target.value;
              this.invoiceInfo.tel_number = $event.target.value;
       
  this.invoiceAddressForm.patchValue({gsm_number:$event.target.value});
        
        
      break;
  
      case "new_gsm":
       
        if(canEmpty && $event == ''){
          this.invoiceInfo.gsm_number = '';
          this.emitInvoiceAddressForm();
        }else{
          if($event.target.value.length != 11){
            this.alertifyService.error("Gsm numarası 10 haneli olmalıdır");
            return false;
          }
  
          this.invoiceInfo.gsm_number = $event.target.value;
          this.invoiceInfo.tel_number = $event.target.value;
          this.emitInvoiceAddressForm();
        }
  
         
       
      break;
  
      case "identity_no":
        if(this.orderCardId){
          this.emitInvoiceAddressForm();
        }
        
        if(canEmpty && $event == ''){
          this.invoiceInfo.identity_no = '';
        }else{
          let identity_no = $event.target.value;
          if(identity_no.length != 11){
            this.alertifyService.error("Tc kimlik no 11 karakter olmalıdır.");
           // this.invoiceInfo.identity_no = "";
            return false;
          }else{
            this.invoiceInfo.identity_no = identity_no;
            this.emitInvoiceAddressForm();
          }
        }
        
      break;
  
     
    }
    
    this.emitInvoiceAddressForm();
  }

  changeTab(tab:string){
    this.selectedTab = tab;

    
    if(tab == "institutional"){
      
      this.invoiceAddressForm.patchValue({customer_invoice_type:2});
      this.invoiceAddressForm.controls["customer_name"].setValidators([]);
      this.invoiceAddressForm.controls["customer_name"].updateValueAndValidity();
      this.invoiceAddressForm.controls["customer_surname"].setValidators([]);
      this.invoiceAddressForm.controls["customer_surname"].updateValueAndValidity();
      this.invoiceAddressForm.controls["identity_no"].setValidators([]);
      this.invoiceAddressForm.controls["identity_no"].updateValueAndValidity();
      this.invoiceAddressForm.controls["company_name"].setValidators([Validators.required]);
      this.invoiceAddressForm.controls["company_name"].updateValueAndValidity();
      this.invoiceAddressForm.controls["tax_number"].setValidators([Validators.required]);
      this.invoiceAddressForm.controls["tax_number"].updateValueAndValidity();
      this.invoiceAddressForm.controls["tax_administration"].setValidators([Validators.required]);
      this.invoiceAddressForm.controls["tax_administration"].updateValueAndValidity();

   

      this.invoiceAddressForm.patchValue({
        firstname: null,
        lastname: null,
        identity_no: null
      });


    }else{
      this.invoiceAddressForm.patchValue({customer_invoice_type:1});
      this.invoiceAddressForm.controls["customer_name"].setValidators([Validators.required]);
      this.invoiceAddressForm.controls["customer_name"].updateValueAndValidity();;
      this.invoiceAddressForm.controls["customer_surname"].setValidators([Validators.required]);
      this.invoiceAddressForm.controls["customer_surname"].updateValueAndValidity();;
      this.invoiceAddressForm.controls["identity_no"].setValidators([Validators.required]);
      this.invoiceAddressForm.controls["identity_no"].updateValueAndValidity();
      this.invoiceAddressForm.controls["company_name"].setValidators([]);
      this.invoiceAddressForm.controls["company_name"].updateValueAndValidity();
      this.invoiceAddressForm.controls["tax_number"].setValidators([]);
      this.invoiceAddressForm.controls["tax_number"].updateValueAndValidity();
      this.invoiceAddressForm.controls["tax_administration"].setValidators([]);
      this.invoiceAddressForm.controls["tax_administration"].updateValueAndValidity();
      
     

      this.invoiceAddressForm.patchValue({
        company_name: null,
        tax_number: 0,
        tax_administration: null
      });

      
    }

    this.emitInvoiceAddressForm();



  }

  createOrder(){
    if(this.invoiceAddressForm.invalid){
      this.alertifyService.error("Lütfen formu eksiksiz doldurunuz");
    }

    if(this.invoiceAddressForm.valid){
      this.createOrderEmitter.emit(true);
    }
   
  }
  

}
