<div class="row mb-5">
    <div class="col-xl-3" *ngIf="showLabel">
        <div class="fs-6 fw-bold">Şehir</div>
    </div>
    <div class="col-xl-{{widthClass}} fv-row fv-plugins-icon-container">
        <app-shared-selectwo
            [id]="cityInputId"
            [name]="'Şehir Seçiniz'"
            [setItem]="selectedCity"
            [lists]="cityList"
            (selectedItem)="selected($event)"
            [showAll]="false"
        >
        </app-shared-selectwo>
       
    </div>
</div>