<style>
    .privacy p{
        font-size: 16px;
    }
</style>
<!--begin::Post-->
<div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">

        
        <div class="row gy-5 g-xl-8" >
            
            <!--begin::Col-->
            <div class="col-xl-12 mb-5">
                <!--begin::Mixed Widget 2-->
                <div class="card card-xl-stretch">
                  
                    <!--begin::Body-->
                    <div class="card-body p-0">
                        
                        <!--begin::Stats-->
                        <div class="card position-relative hero-section">
                            <!--begin::Row-->
                            <div class="row g-0" style="background-color: #F3F3F3; background-image:url(https://cdn.destechhasar.com/garantili-oto-servis/static/garantili-oto-servis-01-2000x1200.jpeg); background-repeat: no-repeat; background-position-x: right; background-position-y: bottom;">
                                <!--begin::Col-->
                                <div class="col px-6 py-8 rounded-2 me-7 mb-7" style=" display: table;">
                                </div>
                                <!--end::Col-->
                                <!--begin::Col-->
                                <div class="col-xl-6 px-6 py-8 rounded-2 mb-7" >
                                   
                               
                                </div>
                                <!--end::Col-->
                            </div>
                            <!--end::Row-->
                           
                        </div>
                        <!--end::Stats-->
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Mixed Widget 2-->
            </div>
            <!--end::Col-->
           
           
        </div>
        <!--end::Row-->

        <div class="row gy-5 g-xl-8">
           
            <!--begin::Col-->
            <div class="col-xl-12 col-xxl-12 mb-5 mb-xl-10">
                <!--begin::Table Widget 3-->
                <div class="card card-flush h-xl-100">
                    <!--begin::Card header-->
                    <div class="card-header py-5">
                        <!--begin::Tabs-->
                        <div class="card-title pt-3 mb-0 gap-4 gap-lg-10 gap-xl-15 nav nav-tabs border-bottom-0" data-kt-table-widget-3="tabs_nav">

                            <h2 style="font-weight: 600;
                            font-size: 32px;
                            color: #004422;
                            margin: 0 0 30px; ">Aydınlatma Metni</h2>
                        </div>
                        <!--end::Tabs-->
                    
                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body pt-1">
                        <!--begin::Sort & Filter-->
                        <div class="d-flex flex-stack flex-wrap gap-4 privacy">
                            <p>
                                Garantili Satış Sonrası Oto Hizmetleri A.Ş.  olarak, resmi internet sitemiz olan https://www.garantiliotoservis.com/ adresini ("Site") ziyaret ettiğinizde çağrı merkezimizi ("Çağrı Merkezi") aradığınızda ve başvurduğunuzda kişisel verilerinizin 6698 sayılı Kişisel Verilerin Korunması Kanunu ("KVKK") ve ikincil düzenlemelerine (birlikte "KVK mevzuatı") uygun olarak işlenmesi ve korunması için azami hassasiyeti göstermekteyiz.
                            </p>
                            <p>
                                İşbu KVK Aydınlatma Metni ("Aydınlatma Metni"), Garantili Satış Sonrası Oto Hizmetleri olarak veri sorumlusu sıfatıyla KVK mevzuatından kaynaklanan aydınlatma yükümlülüğümüzün yerine getirilmesi amacıyla hazırlanmıştır.
                            </p>
                            <p>
                                1- Kişisel Verileriniz Hangi Amaçlarla ve Hangi Yollarla Elde Edilmekte ve İşlenmektedir?
                            </p>
                            <p>
                                Şirketimiz tarafından, Garantili Satış Sonrası Oto Hizmetleri'nden herhangi bir yolla ürün/hizmet satın aldığınızda ya da ürün/hizmet satın almak için Garantili Satış Sonrası Oto Hizmetleri'ne herhangi bir yolla başvurduğunuzda ya da Site'yi, Uygulama’yı ziyaret ettiğinizde aşağıda belirtilen kişisel verileriniz, beraberinde açıklanan amaçlarla bağlantılı, sınırlı ve ölçülü bir şekilde ve KVKK md. 4'teki ilkeler kapsamında elektronik ve fiziki ortamlarda yazılı veya sözlü olarak, otomatik ve otomatik olmayan yöntemlerle KVK mevzuatına uygun olarak elde edilmekte ve işlenmektedir.
                            </p>
                            <p>
                                Ürün ve/veya hizmet için teklif vermek, Satın alınan ürün ve/veya hizmeti sunmak, Satın alınan ürün ve/veya hizmete ilişkin tahsilat yapmak amaçlarıyla; Ad-Soyad, Araç konumu, Araç fotoğrafı, Araç markası, Araç modeli, Araç plakası, Araç yılı, Cep Telefonu, E-posta adresi, Ev Adresi, İş Adresi, Araç ruhsatı, TC kimlik belgesi, Hasar detayları, Ödeme Onayı, Ses kaydı, Teslimat adresi verisi,
                            </p>
                            <p>
                                Müşteri ihtiyaç ve tercihlerini öngörerek ürün ve/veya hizmetleri daha uygun hale getirmek ve kişiselleştirilmiş ürün ve/veya hizmet sunabilmek amaçlarıyla; Ad-Soyad, Araç konumu, Araç bilgileri, Araç fotoğrafı, Araç markası, Araç modeli, Araç plakası, Araç yılı, Cep Telefonu, Cinsiyet, Doğum Tarihi, E-posta adresi, Ev Adresi, Hasar detayları, İş Adresi, Profil Fotoğrafı, Teslimat adresi verisi,
                            </p>
                            <p>
                                Ürün ve/veya hizmet süreçlerini geliştirmek, süreçlerin etkinliğini arttırmak ve varsa sorunları gidermek amaçlarıyla; Ad-Soyad, Aracın konumu, Araç fotoğrafı, Araç markası, Araç modeli, Araç plakası, Araç yılı, Asistan Davranış Puanı, Cep Telefonu, E-posta adresi, Hasar detayları, Müşteri Yorumu, Servis Kalite Puanı, Ses kaydı, Teslimat adresi verisi,
                            </p>
                            <p>
                                İstek, öneri ve şikayetleri değerlendirebilmek amacıyla; Ad-Soyad, Cep Telefonu, E-posta adresi; İstek, öneri ve şikayet içeriği,
                            </p>
                            <p>
                                Onay verilmesi durumunda, bilgilendirme, tanıtım, pazarlama ve satış da dahil olmak üzere her türlü iletişimi sağlamak ve ticari elektronik ileti göndermek amaçlarıyla; Ad- Soyad, Cep Telefonu, E-posta adresi, Posta adresi verisi işlenmektedir.
                            </p>
                            <p>
                                2- Kişisel Verilerinizin Toplanma Yöntemi ve Veri İşlemenin Hukuki Sebebi
                            </p>
                            <p>
                                 Şirketimiz tarafından, yukarıdaki belirtilen kişisel verileriniz, yine yukarıda amaçlar kapsamında doğrudan sizlerden ya da Garantili Satış Sonrası Oto Hizmetleri ile paylaşılmasına izin verdiğiniz aile bireyi, vekil gibi üçüncü kişilerden; sözlü veya yazılı olarak; web sitesi, kullandığınız app ve/veya çağrı merkezimiz üzerinden elektronik olarak ya da elektronik olmayan yollarla elde edilmektedir. Garantili Satış Sonrası Oto Hizmetleri, anılan kişisel verileri sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması; ilgili yasal düzenlemelerden doğan yükümlülüklerin yerine getirilmesi; temel hak ve özgürlüklere zarar vermemek kaydıyla Garantili Satış Sonrası Oto Hizmetleri meşru menfaatleri için veri işlenmesinin zorunlu olması ve açık rıza hukuki sebepleri ile KVKK md.4'te yer alan ilkeler doğrultusunda işlenmektedir.
                            </p>
                            <p>
                                3- Kişisel Verileriniz Kimlerle Hangi Amaçlarla Paylaşılmaktadır?
                            </p>
                            <p>
                                Şirketimiz tarafından, kişisel verilerinizi yukarıda belirtilen işleme amaçlarıyla bağlantılı, sınırlı ve ölçülü olmak kaydıyla ve (2). maddede anılan hukuki sebeplere dayalı olarak yurt içi tedarikçilere ve talep edilmesi durumunda yetkili kurum kuruluşlara aktarılmaktadır.
                            </p>
                            <p>
                                4- Bilgi Edinme Hakkınız
                            </p>
                            <p>
                                KVK Kanunu’nun 11. maddesi kapsamında, Şirketimize başvurarak kişisel verilerinizin;
                            </p>
                            <p>
                                İşlenip işlenmediğini öğrenme;
                            </p>
                            <p>
                                İşlenmişse buna ilişkin bilgi talep etme, işlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme,
                            </p>
                            <p>
                                Yurt içinde / yurt dışında aktarıldığı 3. kişileri bilme, eksik / yanlış işlenmişse düzeltilmesini isteme;
                            </p>
                            <p>
                                KVK Kanunu’nun 7. maddesinde öngörülen şartlar çerçevesinde silinmesini / yok edilmesini isteme;
                            </p>
                            <p>
                                Kişisel verilerinizin KVK Kanunu’nun 7. maddesi kapsamında silinmesi ve yok edilmesi ve eksik / yanlış işlenmiş kişisel verilerinin düzeltilmesi taleplerinin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme;
                            </p>
                            <p>
                                Münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,
                            </p>
                            <p>
                                Kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme haklarına sahip olduğunuzu bildiririz.
                            </p>
                            <p>
                                Kanun kapsamındaki yukarıda sayılan haklarınızdan yararlanmak için başvurularınızı yazılı ve ıslak imzalı olarak Cevizli Mah. Tugay Yolu Cad. No:59 Maltepe / İSTANBUL adresine gönderebilir; kayıtlı elektronik posta (KEP) adresi, güvenli elektronik imza, mobil imza ya da Şirketimize daha önce bildirdiğiniz ve Şirketimiz sisteminde kayıtlı bulunan elektronik posta adresinizden info@garantiliotoservis.com mail adresine iletebilirsiniz. Talebiniz mümkün olan en kısa sürede ve her halükarda KVKK'da belirtilen süre içerisinde değerlendirilerek sonuçlandırılacaktır.
                            </p>
                            <p>
                                Başvurunuzun incelenmesi ve sonuçlandırılmasına ilişkin işlemin bir maliyet gerektirmesi halinde Şirketimizce Kurul tarafından belirlenen tarifedeki ücret alınabilir.
                            </p>
                            <p>
                                Sizin adınıza 3. kişilerin başvuru talebinde bulunabilmesi için başvuruda bulunacak kişi adına noter kanalıyla düzenlenmiş özel vekaletname bulunmalıdır.
                            </p>
                            <p>
                                Kişisel veri envanter çalışmasında herhangi bir değişiklik olduğunda, şirketimiz bu bilgilendirmeyi güncelleyecektir.
                            </p>
                           
                            

                        </div>
                    </div>
                </div>
            </div>
        </div>


    
    </div>
    <!--end::Container-->
</div>
<!--end::Post-->