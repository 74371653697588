<form action="" class="mx-auto mw-1000px w-100 pb-10 ng-untouched ng-pristine ng-valid">
<div class="post d-flex flex-column-fluid" id="appointment-container">
    <div id="kt_content_container" class="container-xxl">
        <div class="card">
            <div class="card-header pt-3">
                <div class="nav-container">
                    <div class="nav-item-container" [ngStyle]="{'left.px': leftWidth,'transition': '10s all bounce;'}">
                 
                        <div class="nav-item" *ngFor="let item of stepList">
                            <div>
                                <h3 class="stepper-title">
                                    <div  class="d-flex flex-column text-center fs-6"  >{{item.step_title}}</div>
                                </h3>
                               <span  [ngClass]="{'act': step == item.position,'completed':item.is_completed && item.position != step}" class="gos-stepper ">
                                   <span *ngIf="!item.is_completed || item.position == step ">{{item.position}}</span>
                               </span>
                            </div>  
                               
                        </div>
                    </div>
                </div>
                </div>
                
            <div class="card-body">
                
                <div style="display:flex;justify-content: space-between; margin-bottom:20px">
                    <button [disabled]="step == 1 || step > 5" class="btn btn-primary btn-sm" (click)="stepChange('previous')" >geri</button>
                    <button *ngIf="showNextBtn" [disabled]="stepList && step == stepList.length || !isStepCompleted" class="btn btn-primary btn-sm " (click)="stepChange('next')" > İleri</button>
                </div>
               
             

               <div *ngFor="let item of stepList">
                    <app-appointment-hizmet-secimi 
                        *ngIf="step == item.position && item.service_name == 'app-appointment-hizmet-secimi'"
                        [selectedOrderTypeId]="appointmentFormGroup.value.serviceTypeId"
                        (orderTypeId)="setStepVariable($event)"
                        ></app-appointment-hizmet-secimi>
                    <app-appointment-arac-secimi 
                        *ngIf="step == item.position && item.service_name == 'app-appointment-arac-secimi'"
                        [selectedModelTypeId]="vehicleForm.model_type_id"
                        (emitVehicleForm)="setStepVariable($event)"
                        [garageId]="vehicleForm.garage_id"
                        [orderServiceId]="orderTypeId"
                        (removeBtn)="setRemoveBtn($event)"
                        ></app-appointment-arac-secimi>
                    <app-appointment-paket-secimi 
                        *ngIf="step == item.position && item.service_name == 'app-appointment-paket-secimi'"
                        [modelTypeId]="vehicleModelType"
                        [selecteMaintenanceKmsId]="appointmentFormGroup.value.maintenanceKmsId"
                        [kmsName]="selecteMaintenanceKmsName"
                        [selectedPackageId]="appointmentFormGroup.value.maintenancePackageId"
                        (packageEmitter)="setStepVariable($event)"
                        (removeBtn)="setRemoveBtn($event)"
                        
                        ></app-appointment-paket-secimi>
                    <app-appointment-parca-secimi 
                    *ngIf="step == item.position && item.service_name == 'app-appointment-parca-secimi' && orderCard"
                    [maintenancePackageId]="appointmentFormGroup.value.maintenancePackageId"
                    [gosLabourPrice]="orderCard?.vehicle_model_types?.vehicle_model_year?.vehicle_model?.gos_labour_price"
                    [brandLabourPrice]="orderCard?.vehicle_model_types?.vehicle_model_year?.vehicle_model?.brand_labour_price"
                    [selectedPieces]="selectedPieces"
                    [vehicleInfo]="vehicleForm"
                    [priceInfo]="priceInfo"
                    (packageDetail)="setStepVariable($event)"
                    ></app-appointment-parca-secimi>

                    <app-appointment-servis-secimi 
                        *ngIf="step == item.position && item.service_name == 'app-appointment-servis-secimi'"
                        (serviceEmitter)="setStepVariable($event)"
                        ></app-appointment-servis-secimi>

                    <app-appointment-proforma 
                        *ngIf="step == item.position && item.service_name == 'app-appointment-proforma'"
                        [userInfo]="userInfo"
                        [selectedPackageInfo]="selectedPackageInfo"
                        [selectedPieces]="selectedPieces"
                        [priceInfo]="priceInfo"
                        [orderType]="orderTypeId"
                        ></app-appointment-proforma>
                    <app-appointment-odeme 
                    *ngIf="step == item.position && item.service_name == 'app-appointment-odeme'"
                    [priceInfo]="priceInfo"
                    [selectedPieces]="selectedPieces"
                    [selectedPackageInfo]="selectedPackageInfo"
                    [selectedPaymentTypes]="appointmentFormGroup.value.serviceTypeId"
                    [orderCardId]="orderCard?.id"
                    [orderTypeId]="orderTypeId"
                    (returnData)="setStepVariable($event)"
                    ></app-appointment-odeme>
                    <app-appointment-login *ngIf="step == item.position && item.service_name == 'app-appointment-login'"></app-appointment-login>
                    <app-appointment-islem *ngIf="step == item.position && item.service_name == 'app-appointment-islem'"></app-appointment-islem>
                    <app-appointment-mekanik-parca-secimi  
                        *ngIf="step == item.position && item.service_name == 'app-appointment-mekanik-parca-secimi'"
                        [selectedPieces]="selectedPieces"
                        [priceInfo]="priceInfo"
                        [modelTypeId]="vehicleModelType"
                        (pieceEmitter)="setStepVariable($event)"
                        ></app-appointment-mekanik-parca-secimi>
                        <app-appointment-manuel-parca-secimi  
                        *ngIf="step == item.position && item.service_name == 'app-appointment-manuel-parca-secimi'"
                        [selectedPieces]="selectedPieces"
                        [priceInfo]="priceInfo"
                        [modelTypeId]="vehicleModelType"
                        (pieceEmitter)="setStepVariable($event)"
                        ></app-appointment-manuel-parca-secimi>
                       
               </div>
            </div>
        </div>
    </div>
</div>
</form>