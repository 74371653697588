import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../environments/environment";

interface firebaseResetPassResponse {
    email: string
}

@Injectable()
export class PasswordReset {

    constructor(
        private http: HttpClient
    ) { }

    sendPasswordResetResult(email: string) {
        const resetUrl = environment.firebaseRestForgotPasswordEndPoint;
        return this.http.post<firebaseResetPassResponse>(resetUrl, {
            requestType: "PASSWORD_RESET",
            email: email
        });
    }

}