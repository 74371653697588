

<div class="card">
    <div class="card-body p-0">

    <div class="tab-content" id="myTabContent">

        

            <form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                <div class="card-body p-0">
                    <div class="row mb-6">
                        <label class="col-lg-4 col-form-label required fw-bold fs-6">{{'NAME' | translate}} {{'LASTNAME' | translate}} </label>
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="col-lg-6 fv-row">
                                    <input type="text" name="name"
                                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        placeholder="Ad" formControlName="name" />
                                </div>
                                <div class="col-lg-6 fv-row">
                                    <input type="text" name="surname"
                                        class="form-control form-control-lg form-control-solid" placeholder="Soyad"
                                        formControlName="surname" />
                                </div>
                            </div>
                        </div>
                    </div>
                  
                      
                    <div class="row mb-6">
                        <label class="col-lg-4 col-form-label required fw-bold fs-6">Email</label>
                        <div class="col-lg-8 fv-row">
                            <input type="email" formControlName="email" name="email"
                                class="form-control form-control-lg form-control-solid" placeholder="Email" />
                        </div>
                    </div>
                    <div class="row mb-6">
                        <label class="col-lg-4 col-form-label required fw-bold fs-6">Kimlik No</label>
                        <div class="col-lg-8 fv-row">
                            <input class="form-control form-control-lg form-control-solid" type="number"
                            name="identity_no" autocomplete="off" placeholder="Kimlik No" formControlName="identity_no" />
                        </div>
                    </div>
                    <div class="row mb-6">
                       
                    </div>
                   
                   
                </div>
                
                <div class="card-footer d-flex justify-content-end py-6 px-9">
                    <button type="submit" class="btn btn-primary"
                        [disabled]="!formGroup.valid">{{'FORM.UPDATE'|translate}}</button>
                </div>
            </form>
     


    </div>

    </div>
</div>


