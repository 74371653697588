import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styles: [],
})
export class AppComponent implements OnInit {

    public appLoading: boolean = true;

    constructor(
        private router: Router
    ) { }

    ngOnInit(): void {
        // Sayfa geçişleri
        this.router.events.subscribe((event: any) => {
          this.navigationInterceptor(event);
      
          if (event instanceof NavigationEnd) {
            // Eğer URL '/teklif' ise google tag ekle
            if (event.url.startsWith('/teklif')) {
              this.loadGtagLibrary().then(() => {
                this.addGtagEventScript();
              }).catch(error => console.error('Gtag Library loading failed', error));
            } else {
              this.removeGtagEventScript();
            }
          }
        });
      }
      
      // Gtag.js kütüphanesini yükleme fonksiyonu (Promise ile asenkron yapılmış)
      loadGtagLibrary(): Promise<void> {
        return new Promise((resolve, reject) => {
          const existingScript = document.getElementById('gtag-library');
          if (!existingScript) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.id = 'gtag-library';
            script.async = true;
            script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-10877775987'; // Google Tag Manager script URL'si
            document.head.appendChild(script);
      
            // gtag config ayarını yapmak için gtag() fonksiyonunu tanımlıyoruz
            script.onload = () => {
              window['dataLayer'] = window['dataLayer'] || [];
              window['gtag'] = function () {
                window['dataLayer'].push(arguments);
              };
              window['gtag']('js', new Date());
              window['gtag']('config', 'AW-10877775987');
              resolve();  // gtag.js tamamen yüklendiğinde promise tamamlanır
            };
      
            script.onerror = (error: any) => {
              reject(error);  // Yükleme sırasında hata olursa
            };
          } else {
            resolve();  // Zaten yüklüyse doğrudan tamamlanır
          }
        });
      }

    // Gtag event scriptini ekleme fonksiyonu
  addGtagEventScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'gtag-conversion-script'; // Scripti tanımlamak için ID ekliyoruz
    script.innerHTML = `gtag('event', 'conversion', {'send_to': 'AW-10877775987/IO_JCOmG4c8ZEPPg9sIo'});`; // Gtag event kodu
    document.head.appendChild(script);
  }

  // Gtag event scriptini kaldırma fonksiyonu
  removeGtagEventScript() {
    const script = document.getElementById('gtag-conversion-script');
    if (script) {
      script.remove();
    }
  }

    navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.appLoading = true;
        }
        if (event instanceof NavigationEnd) {
            this.appLoading = false;
        }
        if (event instanceof NavigationCancel) {
            this.appLoading = false;
        }
        if (event instanceof NavigationError) {
            this.appLoading = false;
        }
    }
}
