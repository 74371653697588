import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { City } from 'src/app/graphql/cities.graphql';
import { Country, COUNTRY_ALL } from 'src/app/graphql/countries.graphql';
import { District } from 'src/app/graphql/districts.graphql';
import { Town } from 'src/app/graphql/town.graphql';
import { User, USER_UPDATE_BY_PK } from 'src/app/graphql/user.graphql';
import { USER_ADDRESS_CREATE } from 'src/app/graphql/user_address.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-offer-address-add',
  templateUrl: './address-add.component.html',
  styles: [
  ],
  providers: [COUNTRY_ALL, USER_ADDRESS_CREATE,USER_UPDATE_BY_PK]

})
export class AddressAddComponent implements OnInit {

  @Input() userId: number | undefined;
  @Input() countryInputId:any; 
  @Input() cityInputId:any; 
  @Input() districtInputId:any; 
  @Input() townInputId:any; 
  @Output() reloadList = new EventEmitter<any>();
  @Input() selectedUserId: Number | undefined;
  countryList: Country[] | undefined;
  @Input() id: any;
  cityList: Observable<City[]> | undefined;
  districtList: Observable<District[]> | undefined;
  townList: Observable<Town[]> | undefined;
  selectedTownId: Number = 0;
  selectedDistrictId: Number = 0;
  selectedCityId: Number = 0;
  selectedCountryId: Number = 0;
  user: User | undefined;
  addressForm = new FormGroup({
    addressTitle: new FormControl('', [Validators.required, Validators.min(3)]),
    active: new FormControl(true, [Validators.minLength(1)]),
    address: new FormControl('', [Validators.required, Validators.min(2)]),
    country: new FormControl('', [Validators.required, Validators.min(1)]),
    city: new FormControl('', [Validators.required, Validators.min(1)]),
    district: new FormControl('', [Validators.required, Validators.min(1)]),
    town: new FormControl('', [Validators.required, Validators.min(1)]),
    primary: new FormControl(false),
    user_id: new FormControl(null, [Validators.required])
  });
  constructor(
    private getCountries: COUNTRY_ALL,
    private alertifyService: AlertifyService,
    private createAddress: USER_ADDRESS_CREATE,
    private updateUsers:USER_UPDATE_BY_PK,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
     this.getCountries.watch().valueChanges.subscribe((result:any)=>{
      this.countryList = result.data.countries
    });

    this.addressForm.patchValue({user_id:this.authService.userId});
  }

  ngOnChanges(){
    if(this.cityInputId == undefined){
      this.cityInputId = 'cityList';
    }

    if(this.districtInputId == undefined){
      this.districtInputId = 'districtList1';
    }

    if(this.countryInputId == undefined){
      this.countryInputId = 'countryList';
    }

    if(this.townInputId == undefined){
      this.townInputId = 'townList';
    }
  }

  onSubmit() {
  

    if (this.addressForm.invalid) {
      //alert("hatalı form bilgileri");
      this.alertifyService.error("Lütfen zorunlu alanları doldurunuz.");
      return;
    }


    this.createAddress.mutate({
      user_id: this.addressForm.value.user_id,
      country_id: this.addressForm.value.country,
      city_id: this.addressForm.value.city,
      district_id: this.addressForm.value.district,
      town_id: this.addressForm.value.town,
      address: this.addressForm.value.address,
      address_name: this.addressForm.value.addressTitle,
      active: true
    }).subscribe((response:any) => {
      if (response.data) {
        this.alertifyService.success("Adres Kayıt Edildi")
        if(this.addressForm.value.primary == true){
          
          this.updateUsers.mutate({id:this.authService.userId,changes:{address_id:response.data.insert_user_address.returning[0].id}}).subscribe((result:any)=>{
            if(result.errors){
              this.alertifyService.error(result.errors[0].message);
            }
            if(result.data){
              this.alertifyService.success("Adres birincil adres olarak eklendi.")
              this.reloadList.emit({"reload":true,"addressId":response.data.insert_user_address.returning[0].id});
            }
          })
          
        }else{
          this.reloadList.emit({"reload":true,"addressId":response.data.insert_user_address.returning[0].id});
        }
      }
      if (response.errors) {
        this.alertifyService.error("Bir Hata Oluştu!")
      }
    })
  }

  changeGeoZone(type:String,$event:any){
    switch(type){
      case "country": 
        this.selectedCountryId = $event;
        this.addressForm.patchValue({country:this.selectedCountryId});
        this.selectedCityId = 0;
        this.selectedDistrictId = 0;
        this.selectedTownId = 0;
       
      break;
      case "city": 
        this.selectedCityId = $event;
        this.addressForm.patchValue({city:this.selectedCityId});
        this.selectedDistrictId = 0;
        this.selectedTownId = 0;
      break;
      case "district":
        this.selectedDistrictId = $event;
        this.addressForm.patchValue({district:this.selectedDistrictId});
        this.selectedTownId = 0;

        break;
      case "town":
      this.selectedTownId = $event;
      this.addressForm.patchValue({town:this.selectedTownId});

      break;
    }

  }

}
