import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-shared-phone-mask',
  templateUrl: './phone-mask.component.html',
})
export class PhoneMaskComponent {
  public phoneControl = new FormControl('',
    [
      Validators.required,
      Validators.pattern(/^[0-9]{10}$/) // Tam 10 rakamlı olmalı
    ]);
  @Input() phoneNumber:any;
  @Input() isValid:any = false;
  @Output() phoneEmitter = new EventEmitter<any>();

 
  ngOnChanges(){
   
    
    this.phoneControl.setValue(this.phoneNumber);
    this.maskPhoneNumber(null);
  }

  maskPhoneNumber(event: any) {
    let phoneNumber: any = null;
  
    if (event == null) {
      phoneNumber = this.phoneNumber;
    } else {
      phoneNumber = event.target.value;
    }
  
    if (phoneNumber && phoneNumber != null) {
      let cleanedNumber = phoneNumber.replace(/\D/g, ''); // Sadece sayıları al
      if (cleanedNumber.length > 10) {
        cleanedNumber = cleanedNumber.slice(0, 10); // Telefon numarasını 10 haneli yap
      }
  
      // İlk hane 5 ile başlamalı mı kontrolü
      if (cleanedNumber.length >= 1 && cleanedNumber.charAt(0) !== '5') {
        cleanedNumber = '5' + cleanedNumber.substring(1);
      }
      let gsmNumber = cleanedNumber;

      cleanedNumber = cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'); // Maskeleme işlemi
      this.phoneControl.setValue(cleanedNumber);
      
        
        this.phoneEmitter.emit(gsmNumber);
    
      
    }
  }
  
  
  
  
  
  
}
