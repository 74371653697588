import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface OzanpayRequest {
  id: Number;
  reference_no: String;
  user_id: Number;
  product_details: String;
  request_data: String;
  order_card_id: Number;
  order_id: Number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

interface ResponseArray {
  ozanpay_requests: OzanpayRequest[];
}

interface ResponseSingle {
    ozanpay_requests_by_pk: OzanpayRequest
}


@Injectable()
export class OZANPAY_REQUEST_ALL extends Query<ResponseArray> {
  // (where: {active: {_eq: true}})
  override document = gql`
    query OZANPAY_REQUEST_ALL($where: ozanpay_requests_bool_exp){
      ozanpay_requests(where:$where,order_by:{id:desc}) {
            id
            reference_no
            user_id
            product_details
            request_data
            order_card_id
            order_id
            created_at
            updated_at
            deleted_at
        }
    }
  `;
}

@Injectable()
export class OZANPAY_REQUEST_ORDER_ID_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
    mutation OZANPAY_REQUEST_ORDER_ID_UPDATE($id:bigint!,$order_id:bigint!) {
      update_ozanpay_requests(where: {id: {_eq: $id}}, _set: {order_id: $order_id}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

