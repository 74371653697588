import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-shared-minputs',
  templateUrl: './minputs.component.html',
  styles: [
  ],
  styleUrls:['./minputs.component.css']
})
export class MInputsComponent implements OnInit {

  @Input() type:string | undefined;
  @Input() label: string | undefined;
  @Input() value: string | number | undefined;
  public isFlashing = true;
  constructor() { }

  ngOnInit(): void {

    this.setType();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['label'] || changes['value']) {
     
      this.flash();
    }
  }

  flash(): void {
    this.isFlashing = true;
    setTimeout(() => {
      this.isFlashing = false; // Animasyonu tekrar başlatmak için sınıfı kaldırıyoruz
    }, 500); // Animasyon süresi ile eşleştiriyoruz (0.5s)
  }

  setType(){
    switch(this.type){

      case "info-input":

      break;

    }
  }

}
