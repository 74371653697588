import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface CompanyEvaluationPonints{
  id: bigint;
  user_id: bigint;
  company_id: bigint;
  invoice_id: bigint;
  evaluation_point: number;
  evaluation_type_id: bigint;
  created_at?: Date;
  company?:{
      name: String
  }
}

interface ResponseArray {
    company_evaluation_points: CompanyEvaluationPonints[];
}

interface ResponseSingle {
    ccompany_evaluation_points_by_pk: CompanyEvaluationPonints
}

@Injectable()
export class COMPANY_EVALUATION_POINTS_ALL extends Query<ResponseArray> {
  override document = gql`
    query COMPANY_EVALUATION_POINTS_ALL($where: company_evaluation_points_bool_exp){
      company_evaluation_points(where: $where) {
        id
        user_id
        company_id
        invoice_id
        evaluation_type_id
        evaluation_point
        created_at
      }
    }
  `;
}

@Injectable()
export class COMPANY_EVALUATION_POINT_ID extends Query<ResponseSingle> {
  override document = gql`
    query COMPANY_EVALUATION_POINT_ID($id: bigint!){
      company_evaluation_points_by_pk(id: $id) {
        id
        user_id
        company_id
        invoice_id
        evaluation_type_id
        evaluation_point
        created_at
        
      }
    }
  `;
}

@Injectable()
export class CREATE_COMPANY_EVALUATION_POINTS extends Mutation<any> {
  // connected_part_id - int | nullable
  override document = gql`
  mutation CREATE_COMPANY_EVALUATION_POINTS(
      $user_id: bigint, 
      $company_id: bigint, 
      $invoice_id: bigint, 
      $evaluation_point: numeric,
      $evaluation_type_id: bigint,
      ) {
    insert_company_evaluation_points(objects: {
        user_id: $user_id, company_id: $company_id, invoice_id: $invoice_id, evaluation_point: $evaluation_point, evaluation_type_id: $evaluation_type_id}) {
      affected_rows
      returning {
        id
      }
    }
  }
  
  `;
}

@Injectable()
export class COMPANY_EVALUATION_POINTS_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
  mutation COMPANY_EVALUATION_POINTS_UPDATE(
      $user_id: bigint, 
      $company_id: bigint, 
      $invoice_id: bigint, 
      $evaluation_point: Number, 
      ) {
    update_company_evaluation_points(where: {id: {_eq: $id}}, _set: {user_id: $user_id, company_id: $company_id, invoice_id: $invoice_id, evaluation_point: $evaluation_point}) {
      affected_rows
    }
  }
  
  `;
}

@Injectable()
export class COMPANY_EVALUATION_POINTS_DELETE extends Mutation<any> {
  override document = gql`
    mutation COMPANY_EVALUATION_POINTS_DELETE($id: bigint!) {
      delete_company_evaluation_points(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class COMPANY_EVALUATION_POINTS_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query COMPANY_EVALUATION_POINTS_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: company_evaluation_points_bool_exp) {
      company_emails(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        user_id
        company_id
        invoice_id
        evaluation_point
        created_at
        evaluation_type_id
      }
    }
  `;
}

@Injectable()
export class COMPANY_EVALUATION_POINTS_COUNT extends Query<any> {
  override document = gql`
  query COMPANY_EVALUATION_POINTS_COUNT($company_id: bigint!) {
    company_evaluation_points_aggregate(where: {company_id: {_eq: $company_id}}) {
      aggregate {
        count
      }
    }
  }
  `;
}