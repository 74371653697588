import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { Company, COMPANY_ALL } from 'src/app/graphql/company.graphql';
import { Order, ORDER_ID } from 'src/app/graphql/order.graphql';
import { OrderInvoices, ORDER_INVOICE_ONE } from 'src/app/graphql/order_invoices.graphql';
import {   OrderInvoiceInfo, ORDER_INVOICE_INFO_ONE } from 'src/app/graphql/order_invoice_info.graphql';
import { numberFormat, numberFormatStr } from 'src/app/helpers/slugify';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-shared-site-proforma',
  templateUrl: './site-proforma.component.html',
  styles: [
  ],
  providers: [ORDER_ID, COMPANY_ALL,ORDER_INVOICE_INFO_ONE,ORDER_INVOICE_ONE]
})


export class SiteProformaComponent implements OnInit {

  @Input() orderId: BigInt | undefined;
  @Input() serviceInfo: any;
  @Input() customerInfo: any;
  @Input() gosInfo: any;
  @Input() vehicleInfo: any;
  @Input() invoice: any;
  @Input() hidePartNumbers:boolean = true;
  @Input() invoiceParts: any;
  @Input() orderInfo: any;
  @Input() orderParts: any;
 
  public gosCompany: Company | undefined;
  public order: Order | undefined;
  public invoiceInfo: | OrderInvoiceInfo | undefined;
 


  
  public vehicleFuelType:any;
  public vehicleGearType:any;
  public priceInfo:any;
  public labourPrice:any;
  public vehicle:any;
  public serviceLastDate:any;
  public todayDate:any;
  public deliveryDate:any;
  public orderTypeInfo:any;
  public paymentTypeInfo:any;
  public partDiscount: any = 0;
  public labourDiscount: any = 0;
  


  
  constructor(
    private getCompanies: COMPANY_ALL,
    private alertifyService: AlertifyService,
    private getOrder: ORDER_ID,
    private getInvoiceInfo : ORDER_INVOICE_INFO_ONE,
    private getInovice: ORDER_INVOICE_ONE,
  ) { }

  async ngOnInit(): Promise<void> {

    moment.locale('tr');
    this.serviceLastDate = moment().add(1, 'days').endOf('day').format('LLL');
    this.todayDate = moment().format('LL');

   

  }

  async ngOnChanges(){

  
   if(this.orderId){
    await this.getOrder.watch({id:this.orderId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(async resp => {
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.order = resp.data.orders_by_pk;

       await this.getInvoiceInfo.watch({where:{order_id:{_eq:this.order.id}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(infoResp => {
          if(infoResp.errors){
            this.alertifyService.error(infoResp.errors[0].message);
          }
          if(infoResp.data){
            this.invoiceInfo = infoResp.data.order_invoice_info[0];
          }
        })

       await this.getInovice.watch({where:{order_id:{_eq:this.order.id}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(invoiceResp => {
          if(invoiceResp.errors){
            this.alertifyService.error(invoiceResp.errors[0].message);
          }
          if(invoiceResp.data){
            this.invoice = invoiceResp.data.order_invoices[0];
          }
        })
       
        await this.order.order_invoices.invoice_parts.forEach(element => {
          if(element.discount == null){
            element.discount = 0;
          }
          if(element.part_unit_type_id == 3){
            this.labourPrice = element.part_no_vat_price;
            this.labourDiscount = parseInt(element.discount)
          }else{
            this.partDiscount += parseInt(element.discount);
          }
        });

        if(this.orderParts){
          await this.orderParts.forEach(element => {
            if(element.part_unit_type_id == 3){
              this.labourPrice = element.total_price;
              this.labourDiscount = (element.labour_discount == null) ? 0 : element.labour_discount;
            }
          });
        }
      }
    })
   }
    
    

    await this.getCompanies.watch({where:{id:{_eq:1106}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.gosCompany = resp.data.companies[0];

        
      }
    })
  }

  filterCode(item:any){
    if(!this.hidePartNumbers){
      return item;
    }
    const firstThreeDigits = item.substr(0, 3);
    const remainingChars = item.substr(3);
    const convertedString = firstThreeDigits + remainingChars.replace(/./g, '*');
    return convertedString;
  }
 

  toNumberFormat(number: any) {
    return numberFormatStr(number);
  }

  numberFormat(number: any) {
    return numberFormat(number);
  }

}
