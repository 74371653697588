<div class="mb-10">
    <!--begin::Label-->
    <label class="form-label fw-bold">Filtreler:</label>
    <!--end::Label-->

    
    <!--begin::Input-->
    <div>
        <app-shared-selectwo>

        </app-shared-selectwo>
        <div class="form-select form-select-solid text-muted" style="cursor: pointer; display: none;" >
          Şehir
        </div>
    </div>
    <!--end::Input-->
    
</div>