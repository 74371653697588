<div class="d-flex justify-content-between py-5 form-container">
    <div class="form-content-container back-arrow-container">
        <a (click)="setSliderPositions(classList,true)">
            <i class="fas fa-chevron-circle-left"></i>
        </a>
    </div>
    <div class="form-content-container">
        <div class="slider-container">
            <div data-type="original" class="slider-item first-item {{classList.first}} withoutlogo">
                <div class="card card-flush py-4" style="width: 100%;">
                    <div class="card-header">
                        <div class=" w-100 package-header">
                            <h3>Orijinal</h3>
                       
                           <p class="mb-0" style="color: #015029; font-size: 12px; font-weight: 400;">(OEM Parçalar)</p>
                           
                            <div class="price-container flexColumn" id="original-discount-container" *ngIf="originalAmounts?.labourDiscount > 0 || originalAmounts.partDiscount > 0">
                                <span class="original-price">{{originalAmounts?.generalTotalWithoutDiscountFormatted}}&#8378;</span>
                                <span class="discounted-price">{{originalAmounts?.generalTotalFormatted}}&#8378;</span>
                            </div>
                            <span class="discounted-price" *ngIf="originalAmounts?.labourDiscount > 0 && originalAmounts.partDiscount > 0">{{originalAmounts?.generalTotalFormatted}}&#8378;</span>
                            <span class="bordered-text-primary">Üretici Garantisini Korur!</span>

                        </div>
                    </div>
                    <div class="card-body table-responsive border-bottom p-5">
                        <table class="table package-table"  *ngIf="originalList">
                            <thead>
                                <tr>
                                    <th>Ref No</th>
                                    <th>Açıklama</th>
                                    <th style="text-align: right;">Tutar</th>
                                </tr>
                               
                            </thead>
                            <tbody>
                            
                                <tr *ngFor="let item of originalList">
                                    <td class="hide-on-mobile">{{filterCode(item.part_code)}}</td>

                                    <td colspan="2" *ngIf="screenWidth <= 500" [class.show-on-mobile]="screenWidth <= 500">
                                        <div
                                            style="display: flex; flex-direction: column;"
                                        >
                                            <span *ngIf="item.original_name?.length <= 25">{{item.original_name}} <i style="color: #015029;" class="fas fa-info-circle" [appTooltip]="item.part_group_note"></i></span>
                                            <span *ngIf="item.original_name?.length > 25">{{item.original_name.substring(0,25)}} ... <i style="color: #015029;" class="fas fa-info-circle" [appTooltip]="item.part_group_note"></i></span>
                                           
                                            <span *ngIf="screenWidth <= 500">{{filterCode(item.part_code)}} - </span>

                                        </div>
                                       
                                    </td>

                                    <td class="hide-on-mobile" *ngIf="item.original_name?.length <= 25">{{item.original_name}} <i style="color: #015029;" class="fas fa-info-circle" [appTooltip]="item.part_group_note"></i>
                                    </td>
                                    <td class="hide-on-mobile" *ngIf="item.original_name?.length > 25">{{item.original_name.substring(0,25)}} ... <i style="color: #015029;" class="fas fa-info-circle" [appTooltip]="item.part_group_note"></i></td>

                                    <td>{{item.totalPartNoVatPriceFormatted}}&#8378;</td>
                                </tr>
                                
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td style="width: 50%;"></td>
                                    <td class="text-end px-5">Ara Toplam</td>
                                    <td>{{originalAmounts.totalFormatted}}&#8378;</td>
                                </tr>
                                <tr>
                                    <td style="width: 50%;"></td>
                                    <td class="text-end px-5">Parça İndirimi</td>
                                    <td>{{originalAmounts.partDiscountFormatted}}&#8378;</td>
                                </tr>
                                <tr>
                                    <td style="width: 50%;"></td>
                                    <td class="text-end px-5">İşçilik İndirimi</td>
                                    <td>{{originalAmounts.labourDiscountFormatted}}&#8378;</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td class="text-end px-5">Kdv</td>
                                    <td>{{originalAmounts.kdvFormatted}}&#8378;</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td class="text-end px-5">Genel Toplam</td>
                                    <td>{{originalAmounts.generalTotalFormatted}}&#8378;</td>
                                </tr>
                                <tr class="d-none">
                                    <td colspan="3" class="text-center ">
                                        <button class="btn btn-primary btn-sm my-5" (click)="setSelectedPackage('original')" >Paket Seçiniz</button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <div data-type="premium" class="slider-item second-item {{classList.second}} premium">
                <div class="card card-flush py-4 premium"  style="width: 100%;">

                    <div class="ribbon red"><span style="text-align: center !important;">Popüler</span></div>

                    <div class="card-header header-height">
                        <div class="w-100 package-header text-white">
                            <h3>Premium</h3>
                            <p class="mb-0" style="font-size: 12px; font-weight: 400;">(Logosuz Orj. / Sertifikalı Parçalar)</p>

                            <div class="price-container"  *ngIf="premiumAmounts?.labourDiscount > 0 || premiumAmounts.partDiscount > 0">
                                <span class="original-price premi">{{premiumAmounts?.generalTotalWithoutDiscountFormatted}}&#8378;</span>
                                <span class="discounted-price">{{premiumAmounts?.generalTotalFormatted}}&#8378;</span>
                            </div>

                            <span class="price-size" *ngIf="premiumAmounts?.labourDiscount == 0 && premiumAmounts.partDiscount == 0">{{premiumAmounts?.generalTotalFormatted}}&#8378;</span>
                            <span class="bordered-text-white" style="font-size: 12px;">Üretici Garantisini Korur!</span>

                            <p></p>

                        </div>
                    </div>
                    <div class="card-body table-responsive border-bottom p-5">
                        <table class="table text-white package-table"  *ngIf="premiumOfferList">
                            <thead>
                                <tr>
                                    <th class="hide-on-mobile">Ref No</th>
                                    <th colspan="2" *ngIf="screenWidth <= 500" [class.show-on-mobile]="screenWidth <= 500">Açıklama</th>
                                    <th  *ngIf="screenWidth > 500">Açıklama</th>
                                    <th style="text-align: right;">Tutar</th>
                                </tr>
                                
                            </thead>
                            <tbody>
                                
                                <tr *ngFor="let item of premiumOfferList">
                                    <td class="hide-on-mobile">{{filterCode(item.part_code)}}</td>
                                    <td colspan="2" *ngIf="screenWidth <= 500" [class.show-on-mobile]="screenWidth <= 500">
                                        <div
                                            style="display: flex; flex-direction: column;"
                                        >
                                            <span *ngIf="item.premium_name?.length <= 16">{{item.premium_name}} <i style="color: #015029;" class="fas fa-info-circle" [appTooltip]="item.part_group_note"></i></span>
                                            <span *ngIf="item.premium_name?.length > 16">{{item.premium_name.substring(0,16)}} ... <i style="color: #015029;" class="fas fa-info-circle" [appTooltip]="item.part_group_note"></i></span>
                                           
                                            <span *ngIf="screenWidth <= 500">{{filterCode(item.part_code)}} - </span>

                                        </div>
                                       
                                    </td>
                                    <td class="hide-on-mobile" *ngIf="item.premium_name?.length <= 25">{{item.premium_name}} <i style="color: #fff;" class="fas fa-info-circle" [appTooltip]="item.part_group_note"></i>
                                    </td>
                                    <td class="hide-on-mobile" *ngIf="item.premium_name?.length > 25">{{item.premium_name.substring(0,25)}} ... <i style="color: #fff;" class="fas fa-info-circle" [appTooltip]="item.part_group_note"></i></td>
                                    <td>{{item.totalPartNoVatPriceFormatted}}&#8378;</td>
                                </tr>
                                
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td style="width: 50%;"></td>
                                    <td class="text-end px-5">Ara Toplam</td>
                                    <td>{{premiumAmounts.totalFormatted}}&#8378;</td>
                                </tr>
                                <tr>
                                    <td style="width: 50%;"></td>
                                    <td class="text-end px-5">Parça İndirimi</td>
                                    <td>{{premiumAmounts.partDiscountFormatted}}&#8378;</td>
                                </tr>
                                <tr>
                                    <td style="width: 50%;"></td>
                                    <td class="text-end px-5">İşçilik İndirimi</td>
                                    <td>{{premiumAmounts.labourDiscountFormatted}}&#8378;</td>
                                </tr>
                                
                                <tr>
                                    <td></td>
                                    <td class="text-end px-5">Kdv</td>
                                    <td>{{premiumAmounts.kdvFormatted}}&#8378;</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td class="text-end px-5">Genel Toplam</td>
                                    <td>{{premiumAmounts.generalTotalFormatted}}&#8378;</td>
                                </tr>
                                <tr class="d-none">
                                    <td colspan="3" class="text-center">
                                        <button (click)="setSelectedPackage('premium')"  class="btn btn-primary btn-sm my-5">Paket Seçiniz</button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <div data-type="equivalent" class="slider-item third-item {{classList.last}} equivalent">
                <div class="card card-flush py-4"  style="width: 100%;">
                    <div class="card-header">
                        <div class="w-100 package-header">
                            <h3>Alternatif</h3>
                            <p class="mb-0" style="color: #015029; font-size: 12px; font-weight: 400;"> (Ekonomik Parçalar)</p>
                            <div class="price-container" *ngIf="equivalentAmounts?.labourDiscount > 0 || equivalentAmounts.partDiscount > 0">
                                <span class="original-price">{{equivalentAmounts?.generalTotalWithoutDiscountFormatted}}&#8378;</span>
                                <span class="discounted-price">{{equivalentAmounts?.generalTotalFormatted}}&#8378;</span>
                            </div>
                            <span class="discounted-price" *ngIf="equivalentAmounts?.labourDiscount == 0 || equivalentAmounts.partDiscount == 0">{{equivalentAmounts?.generalTotalFormatted}}&#8378;</span>
                            <span class="bordered-text-danger">Garantili Oto Servis Garantisi!</span>

                            <p></p>
                        </div>
                    </div>
                    <div class="card-body table-responsive border-bottom p-5">
                        <table class="table package-table" *ngIf="equivalentOfferList">
                            <thead>
                                <tr>
                                    <th>Ref No</th>
                                    <th >Açıklama</th>
                                    <th style="text-align: right;">Tutar</th>
                                </tr>
                               
                            </thead>
                            <tbody>
                                
                                <tr *ngFor="let item of equivalentOfferList">
                                    <td class="hide-on-mobile">{{filterCode(item.part_code)}}</td>
                                    <td colspan="2" *ngIf="screenWidth <= 500" [class.show-on-mobile]="screenWidth <= 500">
                                        <div
                                            style="display: flex; flex-direction: column;"
                                        >
                                            <span *ngIf="item.equivalent_name?.length <= 25">{{item.equivalent_name}} <i style="color: #015029;" class="fas fa-info-circle" [appTooltip]="item.part_group_note"></i></span>
                                            <span *ngIf="item.equivalent_name?.length > 25">{{item.equivalent_name.substring(0,25)}} ... <i style="color: #015029;" class="fas fa-info-circle" [appTooltip]="item.part_group_note"></i></span>
                                           
                                            <span *ngIf="screenWidth <= 500">{{filterCode(item.part_code)}} - </span>

                                        </div>
                                       
                                    </td>
                                    <td  class="hide-on-mobile" *ngIf="item.equivalent_name?.length <= 25">{{item.equivalent_name}} <i style="color: #015029;" class="fas fa-info-circle" [appTooltip]="item.part_group_note"></i>
                                    </td>
                                    <td  class="hide-on-mobile" *ngIf="item.equivalent_name?.length > 25">{{item.equivalent_name.substring(0,25)}} ... <i style="color: #015029;" class="fas fa-info-circle" [appTooltip]="item.part_group_note"></i></td>

                                    <td>{{item.totalPartNoVatPriceFormatted}}&#8378;</td>
                                </tr>
                                
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td style="width: 50%;"></td>
                                    <td class="text-end px-5">Ara Toplam</td>
                                    <td>{{equivalentAmounts.totalFormatted}}&#8378;</td>
                                </tr>
                                <tr>
                                    <td style="width: 50%;"></td>
                                    <td class="text-end px-5">Parça İndirimi</td>
                                    <td>{{equivalentAmounts.partDiscountFormatted}}&#8378;</td>
                                </tr>
                                <tr>
                                    <td style="width: 50%;"></td>
                                    <td class="text-end px-5">İşçilik İndirimi</td>
                                    <td>{{equivalentAmounts.labourDiscountFormatted}}&#8378;</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td class="text-end px-5">Kdv</td>
                                    <td>{{equivalentAmounts.kdvFormatted}}&#8378;</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td class="text-end px-5">Genel Toplam</td>
                                    <td>{{equivalentAmounts.generalTotalFormatted}}&#8378;</td>
                                </tr>
                                <tr class="d-none">
                                    <td colspan="3" class="text-center">
                                        <button (click)="setSelectedPackage('equivalent')"  class="btn btn-primary btn-sm my-5">Paket Seçiniz</button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-content-container forward-arrow-container">
        <a  (click)="setSliderPositions(classList,false)">
            <i class="fas fa-chevron-circle-right"></i>
        </a>
    </div>
</div>

<div class="d-flex justify-content-center payment-container" *ngIf="selectedPackage">
    <p class="selected-package">Seçilen Paket : {{selectedPackage.packageFrontendName}}</p>
</div>

<div class="d-flex justify-content-center payment-container"
     *ngIf="selectedPackage && 
     sessionUserInfo && 
     (authService?.userRole == 'service' || authService?.userRole == 'admin') && 
     urlPart != 'teklif'">
    
    
        <div class="payment-button-container" 
        *ngFor="let item of sessionUserInfo?.payment_type_roles ">
            <label class="btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 g-9" data-kt-button="true">
                <!--begin::Radio button-->
                <span class="form-check form-check-custom form-check-solid form-check-sm align-items-start" style="margin: auto;">
                    <input *ngIf="item.id" class="form-check-input" [checked]="item.payment_types.id == 1" type="radio" name="usage" value="1" style="margin: auto;" (click)="setPaymentType(item.payment_types.id)" >
                </span>
                <!--end::Radio button-->
                <span class="ms-5 d-flex" style="min-height: 65px;">
                    <span class="fs-4 fw-bolder mb-1 d-block" style="width: 100%;">{{item.payment_types.name}}</span>
                    <span class="fw-bold fs-7 text-gray-600" style="width: 100%;" *ngIf="item.payment_types.id == 1 || item.payment_types.id == 2">(Provizyon)</span>
                </span>
    
                <span class="ms-5">
                    <i class="far fa-credit-card"></i>
                   
                </span>
            </label>
       
        </div>
    
</div>
<div class="d-flex justify-content-center payment-container" *ngIf="
    selectedPackage && 
    userInfo && 
    (authService?.userRole != 'service' && authService?.userRole != 'admin') ||
    ((authService?.userRole == 'service' || authService?.userRole == 'admin') && urlPart == 'teklif') 
    ">

    
        <div class="payment-button-container" *ngFor="let item of userInfo?.payment_type_roles" #customerPayments>
   
            <label class="btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 g-9" data-kt-button="true">
                <!--begin::Radio button-->
                <span class="form-check form-check-custom form-check-solid form-check-sm align-items-start" style="margin: auto;">
                    <input class="form-check-input" [checked]="item.id == 1" type="radio" name="usage" value="1" style="margin: auto;" (click)="setPaymentType(item.payment_types.id)" >
                </span>
                <!--end::Radio button-->
                <span class="ms-5 d-flex" style="min-height: 65px;">
                    <span class="fs-4 fw-bolder mb-1 d-block" style="width: 100%;">{{item.payment_types.name}}</span>
                    <span class="fw-bold fs-7 text-gray-600" style="width: 100%;" *ngIf="item.id == 1 || item.id == 2 || item.id == 3">(Provizyon)</span>
                    <span class="fw-bold fs-7 text-gray-600" style="width: 100%;" *ngIf="item.id == 4">(Provizyon)</span>
                </span>
    
                <span class="ms-5">
                    <i class="far fa-credit-card"></i>
                   
                </span>
            </label>
        </div>
    

    
       
</div>


<div class="piece-image-container" style="border: 1px solid #f0f0f0;">
    
    <ul #imageListUl>
        <li *ngFor="let item of pieceImages">
            <img src="{{item.image_url}}" alt="">
        </li>
    </ul>

</div>