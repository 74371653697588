import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';
import { User } from './user.graphql';

export interface Gsm {
  id: bigint;
  track_id: Number;
  name: String;
  code: String;
  user_id: bigint;
  active?: Boolean;
  is_primary?: Number;
  user:User
}

interface ResponseArray {
    gsms: Gsm[];
}

interface ResponseSingle {
    gsms_by_pk: Gsm
}

@Injectable()
export class GSM_ALL extends Query<ResponseArray> {
  override document = gql`
    query GSM_ALL($where: gsms_bool_exp){
      gsms(where: $where) {
        id
        name
        code
      }
    }
  `;
}

@Injectable()
export class GSM_ID extends Query<ResponseSingle> {
  override document = gql`
    query GSM_ID($id: bigint!){
      gsms_by_pk(id: $id) {
        id
        track_id
        name
        code
        user_id
        active
        is_primary
      }
    }
  `;
}

@Injectable()
export class GSM_CREATE extends Mutation<any> {
  // connected_part_id - int | nullable
  override document = gql`
  mutation GSM_CREATE(
      $name: String, 
      $code: String, 
      $user_id: Int, 
      $active: Boolean,
      $is_primary: numeric
      ) {
        insert_gsms(objects: {name: $name, code: $code, active: $active, user_id: $user_id,is_primary:$is_primary}) {
          affected_rows
          returning {
            id
          } 
        }
  }
  
  `;
}

@Injectable()
export class GSM_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
  mutation GSM_UPDATE(
      $id: bigint, 
      $name: String!, 
      $code: String, 
      $user_id: Int, 
      $active: Boolean,
      $is_primary:numeric) {
    update_gsms(where: {id: {_eq: $id}}, _set: {code: $code, active: $active, name: $name, user_id: $user_id,is_primary:$is_primary}) {
      affected_rows
    }
  }
  
  `;
}

@Injectable()
export class GSM_DELETE extends Mutation<any> {
  override document = gql`
    mutation GSM_DELETE($id: bigint!) {
      delete_gsms(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class GSM_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query GSM_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = desc, $where: gsms_bool_exp) {
      gsms(limit: $limit, offset: $offset, order_by: {is_primary: $orderBy}, where: $where) {
        id
        name
        track_id
        user_id
        code
        active
        
      }
    }
  `;
}

@Injectable()
export class GSM_COUNT extends Query<any> {
  override document = gql`
  query GSM_COUNT($where: gsms_bool_exp) {
    gsms_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  `;
}


@Injectable()
export class GSM_UPDATE_CUSTOM extends Mutation<any>{
  override document = gql`
    mutation GSM_UPDATE_CUSTOM(
      $where: gsms_bool_exp!,
      $changes: gsms_set_input
    ){
      update_gsms(where:$where,  _set: $changes){
        affected_rows
        returning{
          id
        }
      }
    }
  `;
}

@Injectable()
export class GET_GSM_BY_NUMBER extends Query<ResponseArray> {
  override document = gql`
    query GET_GSM_BY_NUMBER($number: String!) {
      gsms(where: { code: { _eq: $number } }) {
        id
        track_id
        name
        code
        user_id
        active
        is_primary
        user {
          id
          name
          surname
          email
          job
          garages {
            vehicles {
              id
              model_type_id
              model_year_id
              model_id
              brand_id
              vehicle_model_types{
                id
                name
              }
              vehicle_model{
                id
                name
              }
              years{
                id
                year_id
              }
              vehicle_gear_type{
                id
                name
              }
              vehicle_fuel_type{
                id
                name
              }
            }
          }
        }
      }
    }
  `;
}