<app-shared-modal
    *ngIf="openPopup"
    [showCloseButton]="true"
    (modal)="closePopup($event)"
>
    <form id="kt_modal_create_api_key_form" class="form" action="#">
        <!--begin::Modal body-->
        <div class="modal-body py-10 px-lg-17">
            <!--begin::Scroll-->
            <div class="scroll-y me-n7 pe-7" id="kt_modal_create_api_key_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_create_api_key_header" data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll" data-kt-scroll-offset="300px">
                <!--begin::Notice-->
                <div class="notice d-flex bg-light-success rounded border-success border border-dashed mb-10 p-6">
                    <!--begin::Icon-->
                    <!--begin::Svg Icon | path: icons/duotune/general/gen044.svg-->
                    <span class="svg-icon svg-icon-2tx svg-icon-success me-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                            <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black" />
                            <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black" />
                        </svg>
                    </span>
                    <!--end::Svg Icon-->
                    <!--end::Icon-->
                    <!--begin::Wrapper-->
                    <div class="d-flex flex-stack flex-grow-1">
                        <!--begin::Content-->
                        <div class="fw-bold">
                            <div class="fs-6 text-gray-700 " *ngIf="showCodeSender">Doğrulama kodu almak için <a class="fw-bolder" (click)="sendVerifyCode()">Tıklayınız</a></div>
                            <div class="fs-6 text-gray-700 " *ngIf="showCodeSenderMessage">Doğrulama kodu gönderildi. ğer kod tarafınıza ulaşmaz ise {{codeSendTime}} saniye sonra tekrar kod alabilirisiniz.</div>
                            <div class="fs-6 text-gray-700 d-none">Lütfen telefonunuza sms ile gönderilen doğrulama kodunu giriniz</div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Wrapper-->
                </div>
                <!--end::Notice-->
                <!--begin::Input group-->
                <div class="mb-5 fv-row">
                    <!--begin::Label-->
                    <label class="required fs-5 fw-bold mb-2">Doğrulama Kodu</label>
                    <!--end::Label-->
                    <!--begin::Input-->
                    <input type="text" (keydown.enter)="$event.preventDefault()" [maxLength]="maxLength"  (keyup)="setVerifyCode($event)" (change)="setVerifyCode($event)"  class="form-control form-control-solid" placeholder="Doğrulama Kodu" name="name" />
                    <!--end::Input-->
                </div>
                <!--end::Input group-->
              
            </div>
            <!--end::Scroll-->
        </div>
        <!--end::Modal body-->
        <!--begin::Modal footer-->
        <div class="modal-footer flex-center">
           
            <!--begin::Button-->
            <button type="button" (click)="verifySms()" class="btn btn-sm btn-primary" [disabled]="isButtonDisabled">
                <span class="indicator-label" [ngClass]="{'d-none': isDisabled}">Doğrula</span>
                <span class="indicator-progress" [ngClass]="{'d-block': isDisabled}">Lütfen Bekleyiniz...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
            </button>
            <!--end::Button-->
        </div>
        <!--end::Modal footer-->
    </form>
</app-shared-modal>