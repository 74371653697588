import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface PaymentTypes{
  id: Number;
  name: String;
  active?: Boolean;
}

interface ResponseArray {
    payment_types: PaymentTypes[];
}

interface ResponseSingle {
    payment_types_by_pk: PaymentTypes
}

@Injectable()
export class PAYMENT_TYPES_ALL extends Query<ResponseArray> {
  override document = gql`
    query PAYMENT_TYPES_ALL($where: payment_types_bool_exp){
        payment_types(where: $where,order_by:{id: asc}) {
        id
        name
      }
    }
  `;
}

@Injectable()
export class PAYMENT_TYPES_ID extends Query<ResponseSingle> {
  override document = gql`
    query PAYMENT_TYPES_ID($id: Int!){
      payment_types_by_pk(id: $id) {
        id
        name
        active
        
      }
    }
  `;
}

@Injectable()
export class POST_PAYMENT_TYPES extends Mutation<any> {
  // connected_part_id - int | nullable
  override document = gql`
  mutation POST_PAYMENT_TYPES(
      $name: String,
      $active: Boolean
      ) {
    insert_payment_types(objects: {
        name: $name, active: $active}) {
      affected_rows
      returning {
        id
      }
    }
  }
  
  `;
}

@Injectable()
export class PAYMENT_TYPES_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
  mutation PAYMENT_TYPES_UPDATE(
      $id: bigint, 
      $name: String!, 
      $active: Boolean) {
    update_payment_types(where: {id: {_eq: $id}}, _set: {active: $active, name: $name}) {
      affected_rows
    }
  }
  
  `;
}

@Injectable()
export class PAYMENT_TYPES_DELETE extends Mutation<any> {
  override document = gql`
    mutation PAYMENT_TYPES_DELETE($id: bigint!) {
      delete_payment_types(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PAYMENT_TYPES_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query PAYMENT_TYPES_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: payment_types_bool_exp) {
      payment_types(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        name
        active
      }
    }
  `;
}

@Injectable()
export class PAYMENT_TYPES_COUNT extends Query<any> {
  override document = gql`
  query PAYMENT_TYPES_COUNT {
    payment_types_aggregate {
      aggregate {
        count
      }
    }
  }
  `;
}
