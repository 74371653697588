<input 
    type="number" 
    class="form form-control  fs-8 "  
    [ngClass]="{
        'ng-touched': addRequiredValidator,
        'ng-pristine': addRequiredValidator,
        'ng-dirty': addRequiredValidator,
        'ng-invalid': addRequiredValidator && !isValid,
        'ng-valid': addRequiredValidator && isValid
    }"
    (keyup)="getKms($event)" 
    placeholder="Güncel KM bilgisi" 
    value="{{(customerKm > 0 ? customerKm : '')}}">
<p style="margin-top: 5px; font-size: 12px; padding: 5px;" class="text-muted" *ngIf="nearestNumber">Seçilen Bakım Paketi : {{nearestNumber}} KM</p>

