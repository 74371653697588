<style>
    #kt_ecommerce_add_product_reviews td:first-child{
        width: 40%;
    }
    
</style>
<app-shared-drawer
    [show]="openDrawer"
    [title]="company?.name+' Yorumları'"
    [drawerWidth]="'500px'"
    (toggleDrawer)="setToggleDrawer()"
>
<div class="d-flex flex-column gap-7 gap-lg-10">
    <!--begin::Reviews-->
    <div class="card card-flush py-4">
       
       <div class="card-title">
            <h6 class="text-center"></h6>
       </div>
        <!--begin::Card body-->
        <div class="card-body p-0">
            <!--begin::Table-->
            <table class="table table-row-dashed fs-6 gy-5 my-0" id="kt_ecommerce_add_product_reviews" *ngIf="commentList && commentList.length > 0">
              
                <tbody>
                    <tr *ngFor="let item of commentList">
                        
                        <td data-order="rating-5">
                            <div class="comment-container">
                                <div class="customer-comment-conteiner">
                                    <div class="customer-comment-left">
                                        <a href="../../demo1/dist/apps/inbox/reply.html" class="d-flex text-dark text-gray-800 text-hover-primary">
                                            <!--begin::Avatar-->
                                            <div class="symbol symbol-circle symbol-25px me-3">
                                                <div class="symbol-label bg-light-danger">
                                                    <span class="text-danger">{{ item.customer_name.charAt(0) }}</span>
                                                </div>
                                            </div>
                                            <!--end::Avatar-->
                                            <!--begin::Name-->
                                            <span class="fw-bolder">{{item.customer_name}}</span>
                                            <!--end::Name-->
                                        </a>
                                        <!--begin::Rating-->
                                        <div class="rating" style="margin-left: 30px;">
                                            <div class="rating-label " *ngFor="let point of getPointsArray(totalPoint); let i = index" class="rating-label" [ngClass]="{'checked': i < item.evaluation_point}">
                                                <!--begin::Svg Icon | path: icons/duotune/general/gen029.svg-->
                                                <span class="svg-icon svg-icon-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z" fill="black" />
                                                    </svg>
                                                </span>
                                                <!--end::Svg Icon-->
                                            </div>
                                        </div>
                                        <!--end::Rating-->
                                    </div>
                                    <div class="customer-comment-right">
                                        <p class="text-grey-500 fs-8 text-end">13/05/2024</p>
                                        <p class="text-gray-600 fw-bolder">{{item.comment}}</p>
                                    </div>
                                   
    
                                </div>
    
                                <div class="service-comment-container">
                                    <div class="service-comment-left text-end">
                                        
                                        
                                       
                                            <!--begin::Name-->
                                            <span class="fw-bolder">Servis Yorumu</span>
                                            <!--end::Name-->
                                    
                                    </div>
                                    <div class="service-comment-right">
                                        <p class="text-grey-500 fs-8 text-end">13/05/2024</p>
                                    <p class="text-gray-600 fw-bolder">{{item.service_comment}}</p>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </td>
                      
                        
                        
                    </tr>
                   
                </tbody>
                <!--end::Table body-->
            </table>
            <!--end::Table-->

            <div *ngIf="commentList == undefined || commentList.length == 0" class="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                <!--begin::Icon-->
                    <i class="ki-duotone ki-design-1 fs-2tx text-primary me-4"></i>        <!--end::Icon-->
                
                <!--begin::Wrapper-->
                <div class="d-flex flex-stack flex-grow-1 ">
                                <!--begin::Content-->
                        <div class=" fw-semibold">
                            
                                                <div class="fs-6 text-gray-700 ">Servisimize henüz yorum yapılmamıştır.</div>
                                        </div>
                        <!--end::Content-->
                    
                        </div>
                <!--end::Wrapper-->  
            </div>
        </div>
        <!--end::Card body-->
    </div>
    <!--end::Reviews-->
</div>
</app-shared-drawer>