import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface PackageKms {
  id: BigInt;
  name: String;
  active: Boolean;
  position_no: Number;
  km:number;
}

interface ResponseArray {
  maintenance_kms: PackageKms[];
}

interface ResponseSingle {
  maintenance_kms_by_pk: PackageKms
}

@Injectable()
export class PACKAGE_KMS_ALL extends Query<ResponseArray> {
  // (where: {active: {_eq: true}})
  override document = gql`
    query PACKAGE_KMS_ALL($where: maintenance_kms_bool_exp,$orderBy: order_by = asc){
        maintenance_kms(where:$where,order_by: {km: $orderBy}) {
            id
            name
            active
            position_no
            km
        }
    }
  `;
}

@Injectable()
export class PACKAGE_KMS_ID extends Query<ResponseSingle> {
  override document = gql`
    query PACKAGE_KMS_ID($id: bigint!){
        maintenance_kms_by_pk(id: $id) {
            id
            name
            active
            position_no
            km
        }
    }
  `;
}

@Injectable()
export class PACKAGE_KMS_CREATE extends Mutation<any> {
  override document = gql`
    mutation PACKAGE_KMS_CREATE($name: String!, $active: Boolean = false, $position_no: Int = 0) {
    insert_maintenance_kms(objects: {name: $name, active: $active, position_no: $position_no}) {
    affected_rows
      returning {
        id
      }
    }
    }
  `;
}

@Injectable()
export class PACKAGE_KMS_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
    mutation PACKAGE_KMS_UPDATE($id: bigint!, $name: String!, $position_no: Int!, $active: Boolean!) {
      update_maintenance_kms(where: {id: {_eq: $id}}, _set: {name: $name, position_no: $position_no, active: $active}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PACKAGE_KMS_DELETE extends Mutation<any> {
  override document = gql`
    mutation PACKAGE_KMS_DELETE($id: bigint!) {
      delete_maintenance_kms(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PACKAGE_KMS_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query PACKAGE_KMS_PAGINATE($limit: Int = 20, $offset: Int = 0, $orderBy: order_by = asc, $where: maintenance_kms_bool_exp) {
    maintenance_kms(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        name
        active
        position_no
        
        }
    }
  `;
}

@Injectable()
export class PACKAGE_KMS_COUNT extends Query<any> {
  override document = gql`
    query PACKAGE_KMS_COUNT($active: Boolean = true) {
      maintenance_kms_aggregate( where: {active: {_eq: $active}}) {
        aggregate {
          count
        }
      }
    }
  `;
}

@Injectable()
export class PACKAGE_KMS_BY_MODEL_ID extends Query<any>{
  override document = gql`
  query PACKAGE_KMS_BY_MODEL_ID($model_type_id:bigint!) {
    maintenance_kms {
      id
      name
      maintenance_packages(where: {package_model_types: {model_type_id: {_eq: $model_type_id}}}, order_by: {name: asc}) {
        id
        fuel_id
        active
        km
      }
      active
    }
  }
  
  `
}