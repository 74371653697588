import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-shared-comments',
  templateUrl: './comments.component.html',
  styles: [
  ],
  styleUrls:['./comments.component.css'],

})
export class CommentsComponent implements OnInit {

  @Input() openDrawer:boolean=false;
  @Input() company:any;
  @Output() toggleDrawer = new EventEmitter<boolean>();
  public commentList:any = [];
  public totalPoint = 5;
  constructor() { }

  ngOnInit(): void {

  }

  async ngOnChanges(){
    this.commentList = [];
    if(this.company){

      if(this.company.company_evaluations){
        await this.company.company_evaluations.forEach(element => {
          let commentItem = {
            comment: element.comment,
            company_answer: element.company_answer,
            evaluation_point: 0,
            customer_name: "Mehmet ÖZALP",
            comment_date: "13/05/2024",
            service_comment: element.company_answer
          }

          if(element.company_evaluation_values && element.company_evaluation_values.length > 0){
            const values = element.company_evaluation_values.map(item => item.value);
            commentItem.evaluation_point = values.reduce((acc, val) => acc + val, 0) / values.length;
          }
          
          this.commentList.push(commentItem);


        });

      }


    }
  }

  setToggleDrawer() {
  
    this.openDrawer = !this.openDrawer
    this.toggleDrawer.emit(this.openDrawer);
  }

  getPointsArray(totalPoint: number): number[] {
    return Array.from({ length: totalPoint }, (_, i) => i);
}

}
