import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface PackageModelType {
  id: bigint,
  package_id: bigint,
  model_type_id: bigint,
  active?: boolean,
  package?: any,
  model_type?:any
  vehicle_model_types?:any
  maintenance_packages?: any;
  disabled: any,
  fuel_id: number,
  gear_id: number,
}

interface ResponseArray {
  maintenance_package_model_types: PackageModelType[];
}

interface ResponseSingle {
    maintenance_package_model_types_by_pk: PackageModelType
}

@Injectable()
export class PACKAGE_MODEL_TYPE_ALL extends Query<ResponseArray> {
  // (where: {active: {_eq: true}})
  override document = gql`
    query PACKAGE_MODEL_TYPE_ALL($where: maintenance_package_model_types_bool_exp){
        maintenance_package_model_types(where:$where) {
            id
            package_id
            model_type_id
            fuel_id
            gear_id
            active
            vehicle_model_types{
              name
            }
            maintenance_packages{
              maintenance_kms_id
            }
            vehicle_model_types{
              id 
              name
              vehicle_model_year{
                vehicle_model{
                  id
                  name
                  gos_labour_price
                  brand_labour_price
                }
              }
            }
            
        }
    }
  `;
}

@Injectable()
export class PACKAGE_MODEL_TYPE_PARTS_ALL extends Query<ResponseArray> {
  // (where: {active: {_eq: true}})
  override document = gql`
    query PACKAGE_MODEL_TYPE_PARTS_ALL($where: maintenance_package_model_types_bool_exp){
        maintenance_package_model_types(where:$where) {
            id
            package_id
            model_type_id
            active
            vehicle_model_types{
              name
            }
        }
    }
  `;
}

@Injectable()
export class PACKAGE_MODEL_TYPE_ID extends Query<ResponseSingle> {
  override document = gql`
    query PACKAGE_MODEL_TYPE_ID($id: bigint!){
        maintenance_package_model_types_by_pk(id: $id) {
            id
            package_id
            model_type_id
            active
            vehicle_model_types{
              name
        }
    }
}
  `;
}

@Injectable()
export class PACKAGE_MODEL_TYPE_CREATE extends Mutation<any> {
  override document = gql`
    mutation PACKAGE_MODEL_TYPE_CREATE($package_id:bigint, $model_type_id: bigint, $active: Boolean = true) {
    insert_maintenance_package_model_types(objects: {package_id:$package_id, model_type_id:$model_type_id, active: $active}) {
    affected_rows
      returning {
        id
      }
    }
    }
  `;
}

@Injectable()
export class PACKAGE_MODEL_TYPE_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
     mutation PACKAGE_MODEL_TYPE_UPDATE(
        $id:bigint, $package_id:bigint, $model_type_id: bigint, $active: Boolean = true) {
      update_maintenance_package_model_types(where: {id: {_eq: $id}}, _set: {
        active: $active, 
        package_id: $package_id,
        model_type_id: $model_type_id
        }) {
        affected_rows
      }
    }
  `;
}

@Injectable()
export class PACKAGE_MODEL_TYPE_DELETE extends Mutation<any> {
  override document = gql`
    mutation PACKAGE_MODEL_TYPE_DELETE($id: bigint!) {
      delete_maintenance_package_model_types(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PACKAGE_MODEL_TYPE_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query PACKAGE_MODEL_TYPE_PAGINATE($limit: Int = 20, $offset: Int = 0, $orderBy: order_by = asc, $where: maintenance_package_model_types_bool_exp) {
    maintenance_package_model_types(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        package_id
        model_type_id
        active
        vehicle_model_types{
            id
            name
            vehicle_model_year{
              id
              year_id
              vehicle_model{
                id
                name
                vehicle_brand{
                  id
                  name
                }
              }
            }
        }
    
        }
    }
  `;
}

@Injectable()
export class PACKAGE_MODEL_TYPE_COUNT extends Query<any> {
  override document = gql`
    query PACKAGE_MODEL_TYPE_COUNT( $where: maintenance_packages_bool_exp) {
        maintenance_package_model_types_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}