import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class MapService {

  constructor(
    private http: HttpClient
  ) { }

  getAddress(lat: number, lng: number): Observable<any> {
    return this.http.get<any>(
      `https://maps.googleapis.com/maps/api/geocode/json?key=${environment.googleMapApiKey}&latlng=${lat},${lng}`
    )
  }

  getLatitudeAndLongitude(address: string): Observable<any> {
    return this.http.get<any>(
      `https://maps.googleapis.com/maps/api/geocode/json?key=${environment.googleMapApiKey}&address=${address}`
    )
  }

  getDistance(destinations: string, origins: string): Observable<any> {
    // destinations = 34.4345435,37.435345
    // origins = 34.4345435,37.435345
    return this.http.get<any>(
      `https://maps.googleapis.com/maps/api/distancematrix/json?key=${environment.googleMapApiKey}&destinations=${destinations}&origins=${origins}`
    )
  }


}