import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Console } from 'console';
import { Currency, CURRENCY_ALL, CURRENCY_ID } from 'src/app/graphql/currency.graphql';
import { PartOriginalList, PART_ORIGINAL_ID, SEARCH_PART_ORIGINIALS_WITH_EQUIVALENTS } from 'src/app/graphql/part-original.graphql';
import { Part, PART_ALL, PART_ID } from 'src/app/graphql/part.graphql';
import { PartUnitType, PART_UNIT_TYPE_ALL } from 'src/app/graphql/part_unit_type.graphql';
import { PriceUnits, PRICE_UNITS_ID } from 'src/app/graphql/price_units.graphql';
import { Vehicle } from 'src/app/graphql/vehicle.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-shared-manuel-parts',
  templateUrl: './manuel-parts.component.html',
  styles: [
  ],
  providers: [PART_ORIGINAL_ID, PART_ID,SEARCH_PART_ORIGINIALS_WITH_EQUIVALENTS, PART_ALL,PART_UNIT_TYPE_ALL,PRICE_UNITS_ID,CURRENCY_ALL]
})
export class ManuelPartsComponent implements OnInit {

  public partOriginalID:Number | undefined;
  public gosPartId: Number | undefined;
  @Input() modelTypeId: BigInt | undefined;
  @Input() vehicleInfo: Vehicle | undefined;
  @Input() defaultPart: String | undefined;
  @Output() returnParts:any = new EventEmitter();

  public partOriginalInfo: PartOriginalList | undefined;
  public partOriginalList: PartOriginalList[] = [];
  public selectedParts: any  = [];
  public partEquivalentList:any = [];
  public gosPartInfo: Part | undefined;
  public partList: Part[] | undefined;
  public selectedOriginalPartName: String = "";
  public partOriginalListSearchResult: PartOriginalList[] | undefined;
  public partUnitTypes: PartUnitType[] | undefined;
  public selectedPartUnitType: PartUnitType | undefined;
  public selectedPartUnit: number = 0;

  public grandTotal : number = 0;
  public subTotal: number = 0;
  public difference : number = 0;
  public gosMaintenancePrice: number = 0;
  public brandMaintenancePrice: number = 0;
  public currencyList: Currency[] | undefined;
  public activeCurrencyId:number = 1;
  public activeCurrency:PriceUnits | undefined;

  constructor(
    private getOriginalPart: PART_ORIGINAL_ID,
    private alertifyService : AlertifyService,
    private getPart: PART_ID,
    private getPartAll: PART_ALL,
    private getOriginalPartsWithEquivalents: SEARCH_PART_ORIGINIALS_WITH_EQUIVALENTS,
    private getPartUnitTypes: PART_UNIT_TYPE_ALL,
    private getCurrency: CURRENCY_ALL,
    private getCurrencyById: PRICE_UNITS_ID

  ) { }

  ngOnInit(): void {
    this.getCurrencyById.watch({id:this.activeCurrencyId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
      this.activeCurrency = response.data.price_units_by_pk;
    })

    this.getPartUnitTypes.watch().valueChanges.subscribe(partUnitResult => {
      if(partUnitResult.errors){
        this.alertifyService.error(partUnitResult.errors[0].message);
      }

      if(partUnitResult.data){
        this.partUnitTypes = partUnitResult.data.part_unit_types;
      }
    })

    this.getCurrency.watch().valueChanges.subscribe((result:any)=>{
      if(result.errors){
        this.alertifyService.error(result.errors[0].message);
      }

      if(result.data){
        this.currencyList = result.data.currency;
      }
    });

  }

  ngOnChanges(){

    if(this.defaultPart){
    }
    
  }

  setPartEquivalentList(){
    
      if(this.partOriginalInfo == undefined || this.gosPartInfo == undefined){
        this.alertifyService.error("Orjinal parça ve Gos Parça Seçmelisiniz!")
        return false;
      }

      if(this.partEquivalentList && this.partEquivalentList.length > 0 ){
        this.partEquivalentList = this.partEquivalentList.filter((obj:any) => obj.part_id != this.partOriginalInfo?.id);
      }

      if(this.partOriginalList && this.partOriginalList.length > 0 ){
        this.partOriginalList = this.partOriginalList.filter((obj:any) => obj.id != this.partOriginalInfo?.id);
      }

      this.partOriginalList.push(this.partOriginalInfo);

      let newObj:any;

       newObj = {
        part_id:this.partOriginalInfo.id,
        selectedGosPart:{
          id: this.gosPartInfo.id,
          name: this.gosPartInfo.name,
          brand_labour_hour: this.gosPartInfo.brand_labour_hour
        },
        without_logo: null,
        offical_original: null,
        equivalent: null,
        gosPart: null,
        original:null,
        partTypeName:"Orijinal",
      };

      newObj.offical_original = {
        id: this.partOriginalInfo.id,
        name: this.partOriginalInfo.name,
        price: this.partOriginalInfo.part_in_vat_price.toFixed(2),
        priceNoVat: this.partOriginalInfo.part_no_vat_price.toFixed(2),
        price_unit: this.activeCurrency?.code,
        price_unit_id: this.activeCurrency?.id,
        
        //TODO buradaki değişkenleri currencyPriceTotal olarak güncelle
        currencyPriceTotal: 0,
        currencyPriceNoVatTotal: 0,
        currencyPriceUnitId : this.partOriginalInfo.price_unit_id,
        currencyPriceUnit: this.partOriginalInfo.price_unit
      }

      //Orjinak İçin Kur Çevir
      if(this.partOriginalInfo.price_unit_id != BigInt(1)){

          let currentCurrency = this.currencyList?.filter(i => i.price_unit_id == this.partOriginalInfo?.price_unit_id);

          if(currentCurrency && currentCurrency.length > 0){

            let currency = currentCurrency[0].currency_value;

            newObj.offical_original.priceWithCurrency  = parseFloat(this.partOriginalInfo.part_in_vat_price.toFixed(2));
            newObj.offical_original.priceNoVatCurrency = parseFloat(this.partOriginalInfo.part_no_vat_price.toFixed(2));

            // parça fiyatını çevir ve yeniden ata
            newObj.offical_original.part_in_vat_price = ( newObj.offical_original.priceWithCurrency * currency );
            newObj.offical_original.part_no_vat_price = ( newObj.offical_original.priceNoVatCurrency * currency );
        
          }

      }

    this.partOriginalInfo?.part_equivalent_lists.forEach((item:any)=>{

        let priceWithCurrency  = 0;
        let priceNoVatCurrency = 0;


        //Equivalent İçin Kur Çevir 
        if( item.price_unit_id != BigInt(1) ){

            let currentCurrency = this.currencyList?.filter(i => i.price_unit_id == item.price_unit_id);

            if(currentCurrency && currentCurrency.length > 0){

              let currency = currentCurrency[0].currency_value;

              priceWithCurrency  = parseFloat(item.part_in_vat_price.toFixed(2));
              priceNoVatCurrency = parseFloat(item.part_no_vat_price.toFixed(2));

              // parça fiyatını çevir ve yeniden ata
              item.part_in_vat_price = ( priceWithCurrency * currency );
              item.part_no_vat_price = ( priceNoVatCurrency * currency );
              item.currencyPriceUnitId = item.price_unit_id;
              item.currencyPriceUnit = item.price_unit;
              item.price_unit_id = this.activeCurrency?.id;
              item.price_unit = this.activeCurrency?.code;
              item.priceCurrency = priceWithCurrency;
              item.priceNoVatCurrency = priceNoVatCurrency;
            }

        }else{

        

          priceWithCurrency  = parseFloat(item.part_in_vat_price.toFixed(2));
          priceNoVatCurrency = parseFloat(item.part_no_vat_price.toFixed(2));

          // parça fiyatını çevir ve yeniden ata
          item.part_in_vat_price = priceWithCurrency;
          item.part_no_vat_price = priceNoVatCurrency;
          item.currencyPriceUnitId = item.price_unit_id;
          item.currencyPriceUnit = item.price_unit;
          item.price_unit_id = this.activeCurrency?.id;
          item.price_unit = this.activeCurrency?.code;
          item.priceCurrency = priceWithCurrency;
          item.priceNoVatCurrency = priceNoVatCurrency;

        }


        switch(item.part_type_id){

          case 2:
            //logosuz orjinal
            newObj.without_logo = {
              id: item.id,
              price: item.part_in_vat_price,
              priceNoVat: item.part_no_vat_price,
              price_unit: this.activeCurrency?.code,
              partUnitType: this.selectedPartUnitType?.name,
              partUnit : this.selectedPartUnit,
              partUnitTypeId: this.selectedPartUnitType?.id,
              price_unit_id:  this.activeCurrency?.id,
              partCode: item.part_code,
              currencyPriceUnitId: item.currencyPriceUnitId,
              currencyPriceUnit: item.currencyPriceUnit,
              priceCurrency: priceWithCurrency,
              priceNoVatCurrency: priceNoVatCurrency
            };
          break;

          case 3:
            //eşdeğer
            newObj.equivalent = {
              id:item.id,
              price :item.part_in_vat_price,
              priceNoVat: item.part_no_vat_price,
              price_unit: item.price_unit,
              partUnitType: this.selectedPartUnitType?.name,
              partUnit : this.selectedPartUnit,
              partUnitTypeId: this.selectedPartUnitType?.id,
              price_unit_id: this.activeCurrency?.id,
              partCode: item.part_code,
              currencyPriceUnitId: item.currencyPriceUnitId,
              currencyPriceUnit: item.currencyPriceUnit,
              priceCurrency: priceWithCurrency,
              priceNoVatCurrency: priceNoVatCurrency
              
            };
          break;

          case 5:
            //original
           
            newObj.original = {
              id: item.id,
              price: item.part_in_vat_price,
              priceNoVat: item.part_no_vat_price,
              price_unit: item.price_unit,
              partUnitType: this.selectedPartUnitType?.name,
              partUnit : this.selectedPartUnit,
              partUnitTypeId: this.selectedPartUnitType?.id,
              price_unit_id: this.activeCurrency?.id,
              partCode: item.part_code,
              currencyPriceUnitId: item.currencyPriceUnitId,
              currencyPriceUnit: item.currencyPriceUnit,
              priceCurrency: priceWithCurrency,
              priceNoVatCurrency: priceNoVatCurrency
              
            };
          break;

        }

    });

    this.partEquivalentList.push(newObj);

    

  }

  addPiece(partOriginalId:Number,selectedPartId:Number,equivalentId:Number,partType:string){
  
    
    if(selectedPartId == 0 || equivalentId == 0){
      this.alertifyService.error("Bir hata oluştu");
      return false;
    }

    if(this.partEquivalentList && this.partEquivalentList.length > 0){


      let selectedPartInfo = this.partEquivalentList.filter((obj:any) => (obj.selectedGosPart.id == selectedPartId));

      if(selectedPartInfo && selectedPartInfo.length > 0){


        //let selectedEquivalentDetail = this.partOriginalInfo?.part_equivalent_lists.filter((obj:any)=>obj.id == equivalentId)[0];

        let existsOriginalPart = this.partOriginalList.filter((obj:any)=>obj.id == partOriginalId );

        if(existsOriginalPart && existsOriginalPart.length > 0){

           let selectedOriginalPart = existsOriginalPart[0];
           let selectedEquivalent = selectedOriginalPart.part_equivalent_lists.filter((equivalentObj:any) => (equivalentObj.id == equivalentId));

           if(selectedEquivalent && selectedEquivalent.length > 0){

            if(this.selectedParts){
              this.selectedParts = this.selectedParts.filter((obj:any)=> obj.selectedGosPart_id != selectedPartId);

            }

              //? toFixed ile ücretleri 2 haneye indiriyoruz
              selectedEquivalent[0].part_in_vat_price = parseFloat(parseFloat(selectedEquivalent[0].part_in_vat_price).toFixed(2));
              selectedEquivalent[0].part_no_vat_price = parseFloat(parseFloat(selectedEquivalent[0].part_no_vat_price).toFixed(2));

              let priceWithUnit:number      = selectedEquivalent[0].part_in_vat_price * this.selectedPartUnit; 
              let priceNoVatWithUnit:number = selectedEquivalent[0].part_no_vat_price * this.selectedPartUnit; 
              

              let priceWithUnitCurrency = selectedEquivalent[0].priceCurrency * this.selectedPartUnit; 
              let priceNoVatWithUnitCurrency = selectedEquivalent[0].priceNoVatCurrency * this.selectedPartUnit; 

              //TODO:buraya labour hour hesaplayacağız.
              let brandLabourPrice = selectedPartInfo[0].selectedGosPart.brand_labour_hour * (this.vehicleInfo?.vehicle_model.brand_labour_price * selectedPartInfo[0][partType].partUnit)//vehicle_models üzerindeki fiyat ile çarpacağız.
              let gosLabourPrice = selectedPartInfo[0].selectedGosPart.brand_labour_hour * (this.vehicleInfo?.vehicle_model.gos_labour_price * selectedPartInfo[0][partType].partUnit)//vehicle_models üzerindeki fiyat ile çarpacağız.

              this.selectedParts.push({
                selectedGosPart_id :  selectedPartInfo[0].selectedGosPart.id,
                selectedGosPart_name: selectedPartInfo[0].selectedGosPart.name,
                labourTypeId: null,
                labourValue: null,
                labourDiscount: null,
                brandLabourPrice: brandLabourPrice, //* manuel parçada işçilik ürün olarak eklenmekte o yüzden gos ve brand labour price 0 gönderilmektedir.
                gosLabourPrice: gosLabourPrice,
                orderId: 0,
                selectedEquivalent_currencyPrice : selectedEquivalent[0].priceCurrency,
                selectedEquivalent_currencyNoVatPrice : selectedEquivalent[0].priceNoVatCurrency,
                selectedEquivalent_currencyTotalPrice : priceWithUnitCurrency,
                selectedEquivalent_currencyPriceUnitId : selectedEquivalent[0].price_unit_id,
                selectedEquivalent_currencyNoVatTotalPrice: priceNoVatWithUnitCurrency,
                selectedEquivalent_priceTotal: priceWithUnit.toFixed(2),
                selectedEquivalent_priceVat: parseFloat(( priceWithUnit - priceNoVatWithUnit ).toFixed(2)),
                selectedEquivalent_priceNoVatTotal: priceNoVatWithUnit.toFixed(2),
                selectedEquivalent_partDiscount: null, //TODO: buraya daha sonra discount eklenbilir.
                selectedEquivalent_partTypeId: selectedEquivalent[0].part_type_id,
                selectedEquivalent_partValue: selectedEquivalent[0].part_in_vat_price,
                selectedEquivalent_partNoVatValue: selectedEquivalent[0].part_no_vat_price,
                selectedEquivalent_id: selectedEquivalent[0].id,
                selectedEquivalent_name: selectedEquivalent[0].name,
                selectedEquivalent_partTypeName: selectedEquivalent[0].part_types.name,
                selectedEquivalent_priceUnitId: this.activeCurrency?.id,
                selectedEquivalent_priceUnitName: this.activeCurrency?.code,
                selectedEquivalent_partUnitType: selectedPartInfo[0][partType].partUnitType,
                selectedEquivalent_partUnitTypeId: selectedPartInfo[0][partType].partUnitTypeId,
                selectedEquivalent_partUnit : selectedPartInfo[0][partType].partUnit,
                selectedEquivalent_partCode : selectedEquivalent[0].part_code,
                selectedEquivalent_serviceValue: parseFloat( priceWithUnit.toFixed(2) ),
                selectedEquivalent_serviceValueUnit: parseFloat( selectedEquivalent[0].part_in_vat_price.toFixed(2) ),
              });
            
            this.setTotalAmount();

           }
        }

      }

    }

  }

  setTotalAmount(){


      this.grandTotal = 0;
      this.subTotal = 0;
      this.difference = 0;
      let orderInfo = {};
    
    if(this.selectedParts && this.selectedParts.length > 0){

      

      this.selectedParts.forEach((element:any) => {



        element.selectedEquivalent_priceTotal = parseFloat(parseFloat(element.selectedEquivalent_priceTotal).toFixed(2));
        element.selectedEquivalent_priceNoVatTotal = parseFloat(parseFloat(element.selectedEquivalent_priceNoVatTotal).toFixed(2));
       
        
        this.grandTotal = parseFloat(( element.selectedEquivalent_priceTotal + this.grandTotal ).toFixed(2));
        this.subTotal   = parseFloat(( element.selectedEquivalent_priceNoVatTotal + this.subTotal ).toFixed(2));

      });


      this.difference = parseFloat(( this.grandTotal - this.subTotal ).toFixed(2));

      orderInfo = {
        status_id: 1,
        type_id: 3,
        customer_id: 0,
        vehicle_id: this.vehicleInfo?.id,
        service_date: null,
        order_value: this.grandTotal,
        order_no_vat_value: this.subTotal,
        maintenance_package_id: 0,
        service_id: 0,
        customer_status_id: 2
      }

      this.returnParts.emit({amount:{grandTotal: this.grandTotal,serviceTotal: this.grandTotal, subTotal: this.subTotal, difference: this.difference},parts:this.selectedParts,orderInfo: orderInfo});

    }else{

      
      this.returnParts.emit({amount:{grandTotal: this.grandTotal,serviceTotal: this.grandTotal, subTotal: this.subTotal, difference: this.difference},parts:this.selectedParts,orderInfo: orderInfo});

    }


  }

  removePiece(selectedPartId:Number,equivalentId:Number){
    
    
    if(selectedPartId == 0 || equivalentId == 0){
      this.alertifyService.error("Bir hata oluştu");
      return false;
    }

    if(this.selectedParts && this.selectedParts.length > 0){
      this.selectedParts = this.selectedParts.filter((obj:any)=>obj.selectedGosPart_id != selectedPartId);
    }

    /*
    if(this.partEquivalentList && this.partEquivalentList.length > 0){
      this.partEquivalentList = this.partEquivalentList.filter((obj:any)=>obj.selectedGosPart.id != selectedPartId);
    }*/

    let rdbtn = document.getElementsByName("part_"+selectedPartId);

    if(rdbtn != null && rdbtn.length > 0){
     
      rdbtn.forEach((obj:any) => {
        if(obj.checked){
          obj.checked = false;
        }
      });
      
    }

    this.setTotalAmount();

  }

  selectedTwo(type:string,event:any){
    switch(type){

      case "originalParts":
        
        this.partOriginalID = event;

        if(this.partOriginalListSearchResult && this.partOriginalListSearchResult.length > 0){

          this.partOriginalInfo = this.partOriginalListSearchResult.filter(response => response.id == event)[0];
        
          let exists = this.partOriginalList?.filter((obj:any) => obj.id == event);

          if(exists == undefined && this.partOriginalInfo){
            this.partOriginalList.push(this.partOriginalInfo);
          }

          this.getPartAll.watch({},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {
            this.partList = resp.data.parts;
        });

        }

      break;

      case "parts":

        this.gosPartId = event;
        
        this.gosPartInfo = this.partList?.filter(response => response.id == event)[0];
     
   
      break;

      case "partUnitTypes":
        let existsUnit = this.partUnitTypes?.filter((obj)=>obj.id == event);

        if(existsUnit && existsUnit.length > 0){
          this.selectedPartUnitType = existsUnit[0];
       
        }
        
      break;

      case "partUnit":
        this.selectedPartUnit = parseInt(event.target.value);
      break;
    }
  }

  searchPart(event:any){
    this.getPartAll.watch({where:{name:{_ilike:"%"+event.target.value+"%"}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {
      this.partList = resp.data.parts;
    })
  }

  searchOriginalPart($event:any){
    
    this.selectedOriginalPartName = "Lütfen Orjinal Parça Seçiniz";

    if(this.modelTypeId == undefined){
      this.alertifyService.error("Lütfen Önce Araç Seçiniz");
      return false;
    }
    this.getOriginalPartsWithEquivalents.watch({where:{part_code:{_eq:$event.target.value}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
      if(result.errors){
        this.alertifyService.error(result.errors[0].message);
      }

      if(result.data){
        this.partOriginalListSearchResult = result.data.part_original_list;
      }

    })
  }


}
