import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-part-tooltip',
  templateUrl: './part-tooltip.component.html',
  styles: [
  ],
  styleUrls:['./part-tooltip.component.css']
})
export class PartTooltipComponent implements OnInit {

  @Input() title: string | undefined;
  constructor() { }

  ngOnInit(): void {
  }

}
