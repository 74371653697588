import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { COMPANY_GSM_ALL } from 'src/app/graphql/company_gsm.graphql';
import { GSM_ALL, GSM_CREATE } from 'src/app/graphql/gsm.graphql';
import { ORDER_CREATE } from 'src/app/graphql/order.graphql';
import { OrderCards, ORDER_CARDS_UPDATE, ORDER_CARD_PAYMENT_SUBS } from 'src/app/graphql/order_cards.graphql';
import { ORDER_CARD_INVOICE_INFO_ID, ORDER_CARD_INVOICE_INFO_UPDATE_ID } from 'src/app/graphql/order_card_invoice_info.graphql';
import { ORDER_PART_CREATE_ONE } from 'src/app/graphql/order_part.graphql';
import { PaymentTypes, PAYMENT_TYPES_ALL } from 'src/app/graphql/payment_types.graphql';
import { USER_CREATE, USER_GET_EMAIL, USER_GET_ID } from 'src/app/graphql/user.graphql';
import { UserAddress, USER_ADDRESS_ALL, USER_ADDRESS_CREATE } from 'src/app/graphql/user_address.graphql';
import { numberFormatStr } from 'src/app/helpers/slugify';
import { FireBaseEmailAuthReponse } from 'src/app/interfaces/firebase.interface';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { PasswordReset } from 'src/app/services/password.service';
import { PaymentService } from 'src/app/services/payment.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment-v2-odeme',
  templateUrl: './odeme-v2.component.html',
  styles: [
  ],
  providers:[PasswordReset,USER_GET_ID,ORDER_CARD_PAYMENT_SUBS,PAYMENT_TYPES_ALL,
    GSM_CREATE,
    COMPANY_GSM_ALL,
    USER_ADDRESS_ALL,
    ORDER_CARDS_UPDATE,ORDER_CREATE,ORDER_PART_CREATE_ONE,USER_GET_EMAIL,USER_CREATE,
    USER_ADDRESS_CREATE,
    GSM_ALL,ORDER_CARD_INVOICE_INFO_ID,ORDER_CARD_INVOICE_INFO_UPDATE_ID]
})
export class OdemeV2Component implements OnInit {

  public serviceLastDate:any;
  public todayWithPipe: any;
  public mictime : Number | undefined;
  public todayDate:any;
  public invoiceInfo:any = [];
  public isCompany:boolean = false;
  public paymentTypes: PaymentTypes[] | undefined;
  public subOrderCards: OrderCards | undefined;
  public momentOrderId:any;
  public ShopCode = "3123";  //Banka tarafindan verilen isyeri numarasi
  public PurchAmount = "1.15";         //Islem tutari
  public Currency = "949"; // Kur Bilgisi - 949 TL
  public OrderId = "";      //Siparis Numarasi
  public InstallmentCount = "";         //taksit sayisi
  public TxnType ="Auth";     //Islem tipi
  public MerchantPass = "gDg1N";  //isyeri 3D anahtari
  public hash : String | undefined;
  public rnd: any;
  private testAddress = "https://test.inter-vpos.com.tr/mpi/Default.aspx";
  public showOrderSpinner: boolean = false;
  public paymentFailMessage :String= "";
  public showPosMessage: boolean= false;
  public isPayed: Boolean = false;
  public orderId:bigint = BigInt("0");
  public addressList:UserAddress[] | undefined;
  public selectedAddressInfo:any;
  public drawerTitle:string = "Adres Ekle";
  public drawerPage:string = "";
  public showDrawer:boolean = false;
  public showKvkk: boolean = true;
  public gsmList:any  = [];
  public companyGsmList:any  = [];
  public selectedAddressId:BigInt | undefined;
  public hidePaymentButton= false;
  public hideAppointmentButton= false;
  public isPaymentBtnActive = false;

  public denizbankUrl = environment.denizbankUrl;
 // public denizbankUrl = "https://api.garantiliotoservis.com/payment-response-test";

  public shopCodeLive = environment.denizShopCode;
  public userCodeLive = environment.denizUserCode;
  public posPass = "";
  public threedKey = environment.deniz3DKey;
  public showPosError = false;
  public posErrorMessage = "";
  public paymentAttempt:Number = 0;
  public paymentErrorMessage = "";
  public inputCardNo:any;
  public filteredCardNo:any;
  public filteredExpiryDate:any;
  public inputExpiryDate: any;

  
  paymentFormGroup = new FormGroup({
    Expire: new FormControl('', [Validators.required]),
    Pan: new FormControl('', [Validators.required,Validators.maxLength(11),Validators.minLength(11)]),
    Cvv2: new FormControl('', [Validators.required,Validators.maxLength(3),Validators.minLength(3)]),
    CardType: new FormControl('', [Validators.required]),
    ShopCode: new FormControl('', [Validators.required]),
    PurchAmount: new FormControl('', [Validators.required]),
    Currency: new FormControl('', [Validators.required]),
    OrderId: new FormControl('', [Validators.required]),
    OkUrl: new FormControl('', [Validators.required]),
    FailUrl: new FormControl('', [Validators.required]),
    Rnd: new FormControl('', [Validators.required]),
    Hash: new FormControl('', [Validators.required]),
    TxnType: new FormControl('', [Validators.required]),
    InstallmentCount: new FormControl(''),
    SecureType: new FormControl('', [Validators.required]),
    Lang: new FormControl('', [Validators.required])

  });

  invoiceAddressForm = new FormGroup({
    firstname: new FormControl('',[Validators.required]),
    lastname: new FormControl('',[Validators.required]),
    country_id: new FormControl(1,[Validators.required]),
    city_id: new FormControl('',[Validators.required]),
    district_id: new FormControl('',[Validators.required]),
    town_id: new FormControl('',[Validators.required]),
    address: new FormControl('',[Validators.required]),
    identity_no: new FormControl('',Validators.required),
    company_name: new FormControl(null),
    tax_administration: new FormControl(null),
    tax_number: new FormControl(null),
    invoice_type: new FormControl(3,Validators.required),
    customer_invoice_type: new FormControl(1,Validators.required),
    gsm_number: new FormControl(null, [Validators.required]),
    tel_number: new FormControl(null),
    user_id: new FormControl(null, [Validators.required]),
    customer_email: new FormControl(null, [Validators.required]),
    order_id: new FormControl(null)
  })



  userForm = new FormGroup({
    firstname: new FormControl('',[Validators.required]),
    lastname: new FormControl('',[Validators.required]),
    gsm_id: new FormControl(null,Validators.required),
    email: new FormControl('',Validators.required),
    phone: new FormControl('',Validators.required),
    id: new FormControl('',Validators.required),
    check_gizlilik: new FormControl(''),
    check_riza: new FormControl(''),
    check_aydinlatma: new FormControl(''),
    check_sozlesme: new FormControl(''),
  })

   public checkCardInfo = {
    cardHolder: false,
    cardNumber: false,
    cvv: false,
    expiry: false

  }

  



  @Input() orderCardId: bigint | Number | undefined;
  @Input() selectedPackageInfo:any;
  @Input() selectedPieces: any;
  @Input() priceInfo: any;
  @Input() selectedPaymentTypes: Number | undefined;
  @Input() orderTypeId: Number | undefined;

  public userInfo: any;
  @Output() returnData = new EventEmitter();

  public orderCreateble = {
   
    user_info:  {
      status: false,
      msg: "hatalı kullanıcı bilgisi"
    },
    invoice_info: {
      status: false,
      msg: "hatalı fatura bilgisi"
    },
    is_payment: {
      status: false,
      msg: "hatalı ödeme"
    }
  }



  @ViewChild('paymentForm') 
  paymentFormElement!: ElementRef; 
  
  constructor(
    public authService: AuthService,
    private getUser: USER_GET_ID,
    private getPaymentTypes: PAYMENT_TYPES_ALL,
    private alertifyService: AlertifyService,
    private updateOrderCards: ORDER_CARDS_UPDATE,
    private PaymentService: PaymentService,
    private orderCardPaymentSubs: ORDER_CARD_PAYMENT_SUBS,

    private checkUser: USER_GET_EMAIL,
    private resetService: PasswordReset,
    private createGsm: GSM_CREATE,
    private getGsms: GSM_ALL,
    private getCompanyGsms: COMPANY_GSM_ALL,
    private getUserAddressList: USER_ADDRESS_ALL,
    private createAddress: USER_ADDRESS_CREATE,
    private getOrderCardInvoice: ORDER_CARD_INVOICE_INFO_ID,
    private orderCardInvoiceInfoUpdate: ORDER_CARD_INVOICE_INFO_UPDATE_ID

      ) { }

  ngOnInit() {

  

    this.denizbankUrl = environment.denizbankUrl;
    //this.denizbankUrl = "https://api.garantiliotoservis.com/payment-response-test";

    moment.locale('tr');
    this.serviceLastDate = moment().add(1, 'days').endOf('day').format('LLL');
    this.todayWithPipe = moment().add(1, 'days').endOf('day').format('yyyy-MM-DDTH:mm');

    let dd = new Date();
    this.mictime = dd.getMilliseconds();

    this.todayDate = moment().format('LL');

    let whereObj: any;

     whereObj = { active: { _eq: true }, main_id:{_eq:0} };

    if(this.orderTypeId == 3){
      whereObj.id = {_eq : 2 } ;
    }

    this.getPaymentTypes.watch({where:whereObj}).valueChanges.subscribe((result:any)=>{
      if(result.errors){
        this.alertifyService.error(result.errors[0].message);
      }
      if(result.data){
        this.paymentTypes = result.data.payment_types;
      }
     
    })
    

    this.changeGeoZone('country',1);
    
   
  }

 

  ngOnChanges(){
    if(this.authService.userId > 1){
      this.getUserInfo();
      this.getAddressList();
      this.userForm.patchValue({
        id: this.authService.userId
      });
      this.showKvkk = false;

    }

    

    
  }

  isOrderCreateble(){
    switch(this.selectedPaymentTypes){
      case 1:
        if(this.orderCreateble.user_info.status && this.orderCreateble.invoice_info.status){
          return true;
        }
      break;

      case 2:
        return (this.orderCreateble.user_info.status && this.orderCreateble.invoice_info.status && this.orderCreateble.is_payment);
      break;
    }
   
  }

  getUserInfo() {
    this.getUser.watch({ "id": this.authService.userId },{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
      if (response.data) {
           this.userInfo = response.data.users_by_pk;
           
           this.userForm.patchValue({
            firstname: this.userInfo.name,
            lastname: this.userInfo.surname,
            email: this.userInfo.email,
            phone: this.userForm.value.phone
           })

           this.invoiceAddressForm.patchValue({user_id:this.userInfo.id,customer_email: this.userInfo.email,tel_number:this.userForm.value.phone});

        }
      })
  }

  changePaymentType(paymentId:Number | undefined){
    
    this.selectedPaymentTypes = paymentId;
    //TODO:: açılacak
   /* this.updateOrderCards.mutate({id:this.orderCardId,changes:{payment_type:paymentId}}).subscribe(response=>{
      if(response.errors){
        this.alertifyService.error(response.errors[0].message);
      }
    })*/
  }

  setUserForm(type:string,$event:any){
    switch(type){
      case "firstname":
        this.userForm.patchValue({firstname:$event.target.value});
        if( this.invoiceAddressForm.value.firstname == "" || this.invoiceAddressForm.value.firstname.length == 0){
          this.invoiceAddressForm.patchValue({firstname: $event.target.value});
        }
        
    
        this.updateOrderCards.mutate({id:this.orderCardId,changes:{customer_firstname: $event.target.value}}).subscribe(response=>{
          if(response.errors){
            this.alertifyService.error(response.errors[0].message);
          }
        });
      break;
      case "lastname":
        this.userForm.patchValue({lastname:$event.target.value});
        if( this.invoiceAddressForm.value.lastname == "" || this.invoiceAddressForm.value.lastname.length == 0){
          this.invoiceAddressForm.patchValue({lastname: $event.target.value});
        }

    
        this.updateOrderCards.mutate({id:this.orderCardId,changes:{customer_lastname: $event.target.value}}).subscribe(response=>{
          if(response.errors){
            this.alertifyService.error(response.errors[0].message);
          }
        });
      break;
      case "gsm":

          this.userForm.patchValue({phone: $event?.target.value});
          this.invoiceAddressForm.patchValue({
            gsm_number:  $event?.target.value,
            tel_number:  $event?.target.value
          });
        
    
          this.updateOrderCards.mutate({id:this.orderCardId,changes:{customer_gsm: $event.target.value}}).subscribe(response=>{
            if(response.errors){
              this.alertifyService.error(response.errors[0].message);
            }
          });

         

       
    
        
      break;
    }
  }

  setInvoiceForm(type:string,$event:any){
    switch(type){
      case "firstname":
        this.userForm.patchValue({firstname:$event.target.value});
      break;
      case "lastname":
        this.userForm.patchValue({lastname:$event.target.value});
      break;
      case "gsm":
        this.userForm.patchValue({gsm_id:$event});
      break;
      case "identitiiy":

      break;
    }
  }



   paymentProcess(){


    if(this.userForm.invalid){
      
      this.alertifyService.error("Lütfen Kullanıcı Formunu Doldurunuz");
      return false;
    }

    if(this.invoiceAddressForm.invalid){
      
      this.alertifyService.error("Lütfen Fatura Bilgilerini Doldurunuz");
      return false;
    }

    

    this.hidePaymentButton = true;

    if(this.orderCardId != undefined){

      let rand1 = moment().format('YYMMDDHmmss').toString();
      let rand2 = moment().add(1,"hours").format('YYMMDDHmmss').toString();


      let rnd = ( parseInt(rand1) + parseInt(rand2) / 2);

      

      this.momentOrderId = rnd.toString()+"-oType:card-id:"+this.orderCardId;

      this.updateOrderCards.mutate({id:this.orderCardId,changes:{hash_order_id:this.momentOrderId}}).subscribe((result:any)=>{
        if(result.errors){
          this.alertifyService.error(result.errors[0].message);
        }
        if(result.data){
          if(this.orderCardId != undefined){

            if(this.selectedPaymentTypes == 2){
              this.formEmit();
           
            }else{
              this.isPayed = true;
             // this.priceInfo.generalTotal = 1.15;

                     //   this.formEmit();
                     //   return;
                //api tarafında hash oluşturup alıyoruz

              
                this.PaymentService.getHash(this.InstallmentCount,this.orderCardId,this.priceInfo.totalAmount,this.momentOrderId).subscribe((result:any)=>{
                  if(result.errors){
                    this.alertifyService.error(result.errors[0].message);
                  }
                 
                  if(result.hash){
                    this.hash = result.hash;
                    this.rnd = result.rnd;
                    this.paymentFormGroup.patchValue({
                      Rnd: result.rnd,
                      Hash: result.hash
                    });

                    //card durumunu istek gönderildi yapıyoruz
                    this.updateOrderCards.mutate({id:this.orderCardId,changes:{pos_process_status_id:2}}).subscribe((result:any)=>{
                      if(result.errors){
                        this.alertifyService.error(result.errors[0].message);
                      }
                      if(result.data){

                      
                          this.paymentFormElement?.nativeElement.submit();
                          this.paymentAttempt = 0;

                          //bankadan gelen cevabın tabloda pos_process_status_id sekmesini başarılı veya başarısız yapmasını bekliyoruz 
                         const orderSub = this.orderCardPaymentSubs.subscribe({where:{id:{_eq:this.orderCardId}}}).subscribe(orderSubResult=> {
  
                            this.subOrderCards = orderSubResult.data?.order_cards[0];
                        //    if(this.paymentAttempt != this.subOrderCards?.payment_attempts){

                              if(this.subOrderCards?.pos_process_status_id == 4){
                                orderSub.unsubscribe();
                                this.isPayed = true;
                                this.formEmit();
                                
                              //  this.submit()
                              //  this.step = this.steps.siparisi_tamamla.id;
    
                              }else if(this.subOrderCards?.pos_process_status_id == 3){
                                orderSub.unsubscribe();
                    
                                this.momentOrderId = moment().format('YYMMDDHmmss').toString()+"-"+this.orderCardId;
                                rand1 = moment().format('YYMMDDHmmss').toString();
                                rand2 = moment().add(1,"hours").format('YYMMDDHmmss').toString();
  
  
                                rnd = ( parseInt(rand1) + parseInt(rand2) / 2);
                                this.momentOrderId = rnd.toString()+"-oType:card-id:"+this.orderCardId;
                        
                              
  
                                if(this.orderCardId){
                                  this.PaymentService.getHash(this.InstallmentCount,this.orderCardId,this.priceInfo.totalAmount,this.momentOrderId).subscribe((result:any)=>{
                                    if(result.errors){
                                      this.alertifyService.error(result.errors[0].message);
                                    }
                                    
                                    if(result.hash){
                                      this.hash = result.hash;
                                      this.rnd = result.rnd;
                                      this.paymentFormGroup.patchValue({
                                        Rnd: result.rnd,
                                        Hash: result.hash
                                      });
                                    }
                                  } );
                                }
                              
                                this.showPosMessage = true;
                                if(this.subOrderCards?.pos_message != undefined && this.subOrderCards?.pos_message != ""){
                                  this.paymentFailMessage = this.subOrderCards.pos_message;
                                }
                             
                                var ths = this;
                                setTimeout(function(){
                                  ths.removePaymentMessage();
                                  ths.paymentFailMessage = "";
                                  ths.showPosMessage = false;
                          
                                },5000)
                              
                                
                        
                              }

                          // }
                            
                      
                           
                          });
                       
                        
                        
                
                      }
                    });

                  }
                })
            }

          
          }
          this.hidePaymentButton = false;
        }
      });

      
    }

    
  }

  removePaymentMessage(){
    this.updateOrderCards.mutate({id:this.orderCardId,changes:{pos_message:null}}).subscribe(result=> {

    });
  }


    openDrawer(type: string, id?: BigInt) {
      this.drawerTitle = 'Adres Ekle'
      this.drawerPage = type;
      if(this.drawerPage == "addressEdit" && id){
        this.drawerTitle = 'Adres Düzenle'
      
      }
      if(this.drawerPage == "addressAdd" && id){
        this.drawerTitle = 'Adres Ekle'
        
      }
  
      if(this.drawerPage == "gsmAdd"){
        this.drawerTitle = 'Cep Numarası Ekle'
      }
      this.showDrawer = true;
  
     
    }

    toggleDrawer() {
    
      this.showDrawer = !this.showDrawer
    }

    reloadList($event:any){
      if($event){
        this.toggleDrawer();  
  
      }
    
      if(this.drawerPage == "addressAdd"){
        if($event.reload){
          this.selectedAddressId = $event.addressId;
          this.invoiceInfo.address_id = $event.addressId;
          this.getAddressList();
  
        }
      }
  
      if(this.drawerPage == "addressEdit"){
        this.getAddressList();
      }
  
      if(this.drawerPage == "gsmAdd"){
        if($event.status){
          this.invoiceInfo.gsm_id = $event.gsm_id;
          this.getUserGsmList();
  
        }else{
          this.alertifyService.error("Bir hata oluştu");
        }
        
      }
      
    }

     checkEmail($event:any,email:string | null=null,createPhone=false){
     
      this.userForm.patchValue({id:''});
    
      if($event != null){
        this.userForm.patchValue({email:$event.target.value});
        this.invoiceAddressForm.patchValue({customer_email: $event.target.value});
      }else{
        if(email != null){
          this.userForm.patchValue({email:email});
          this.invoiceAddressForm.patchValue({customer_email: email});
        }
      }
    
        this.updateOrderCards.mutate({id:this.orderCardId,changes:{customer_email: this.userForm.value.email}}).subscribe(response=>{
          if(response.errors){
            this.alertifyService.error(response.errors[0].message);
          }
        });
   
     
        return new Promise((resolve,reject)=>{
          let mail = (email != null) ? email : $event.target.value;
          this.invoiceAddressForm.patchValue({
            invoice_email: mail
          })

          this.checkUser.watch({email:mail},{fetchPolicy:"no-cache"}).valueChanges.subscribe(ret => {
            if(ret.errors){
              this.alertifyService.error(ret.errors[0].message);
              reject();
            }
  
            if(ret.data){
              this.userInfo = ret.data.users[0];
              if(this.userInfo){
                  this.getAddressList();
                  this.showKvkk = false;
                  this.userForm.patchValue({
                    id: this.userInfo.id,
                    email: this.userInfo.email
                  });
                  this.invoiceAddressForm.patchValue({
                    user_id: this.userInfo.id
                   
                  });

                this.userForm.controls["check_gizlilik"].setValidators([]);
                this.userForm.controls["check_gizlilik"].updateValueAndValidity();
                this.userForm.controls["check_riza"].setValidators([]);
                this.userForm.controls["check_riza"].updateValueAndValidity();
                this.userForm.controls["check_aydinlatma"].setValidators([]);
                this.userForm.controls["check_aydinlatma"].updateValueAndValidity();
                this.userForm.controls["check_sozlesme"].setValidators([]);
                this.userForm.controls["check_sozlesme"].updateValueAndValidity();
                  
              }else{
                this.showKvkk = true;
                this.userForm.controls["check_gizlilik"].setValidators([Validators.required]);
                this.userForm.controls["check_gizlilik"].updateValueAndValidity();
                this.userForm.controls["check_riza"].setValidators([Validators.required]);
                this.userForm.controls["check_riza"].updateValueAndValidity();
                this.userForm.controls["check_aydinlatma"].setValidators([Validators.required]);
                this.userForm.controls["check_aydinlatma"].updateValueAndValidity();
                this.userForm.controls["check_sozlesme"].setValidators([Validators.required]);
                this.userForm.controls["check_sozlesme"].updateValueAndValidity();


              }
              resolve(true);
            }
          })
        })

       
      
    }

    changeGeoZone(type:String,$event:any){
      switch(type){
        case "country": 
       
          this.invoiceAddressForm.patchValue({
            country_id:$event,
            city_id: '',
            district_id: '',
            town_id: ''
          });

          this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{
            country_id:$event,
            city_id: null,
            district_id: null,
            town_id: null
          ,}}).subscribe(response=>{
            if(response.errors){
              this.alertifyService.error(response.errors[0].message);
            }
          })
         
         
        break;
        case "city": 
         
          this.invoiceAddressForm.patchValue({
            city_id:$event,
            district_id: null,
            town_id: null
          });

          this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{
            city_id: $event,
            district_id: null,
            town_id: null
          ,}}).subscribe(response=>{
            if(response.errors){
              this.alertifyService.error(response.errors[0].message);
            }
          })
          
        break;
        case "district":
          
        this.invoiceAddressForm.patchValue({
          district_id: $event,
          town_id: null
        });

        this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{
          district_id: $event,
          town_id: null
        ,}}).subscribe(response=>{
          if(response.errors){
            this.alertifyService.error(response.errors[0].message);
          }
        })
          break;
        case "town":
          this.invoiceAddressForm.patchValue({
            town_id: $event
          });

          this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{town_id: $event }}).subscribe(response=>{
            if(response.errors){
              this.alertifyService.error(response.errors[0].message);
            }
          })
        break;
      }
  
    }


      createNewUser(){
       return new Promise((resolve,reject)=>{
            if(this.userForm.valid){
          
              const password = "123123asdas12312";
              // register
              this.authService.firebaseAuth(this.userForm.value.email, password, false).subscribe(async response => {
                await this.userCreateApi(response);
                resolve(true)
   
              
              }, async err => {
                const response: FireBaseEmailAuthReponse = {} as FireBaseEmailAuthReponse
                response.email = this.userForm.value.email
                response.idToken = "order create, email exists in firebase"
                response.refreshToken = "order create, email exists in firebase"
                response.localId = "order create, email exists in firebase"
                await this.userCreateApi(response)
                resolve(true)
          
              
              })

          }else{
            this.alertifyService.error("Lütfen Kullanıcı Formunu Doldurunuz");
        
          }
       })
          
 
      
     

    }

    userCreateApi(response:any){
    
      return new Promise((resolve,reject)=>{
        this.authService.apiRegister(response,this.userForm.value.firstname, this.userForm.value.lastname,'',null,null).subscribe(response => {
    
          if (response.status) {
            this.resetService.sendPasswordResetResult(this.userForm.value.email).subscribe(response => {
              if (response.email) {
                this.invoiceAddressForm.patchValue({invoice_email: response.email});
              this.checkEmail(null,response.email, true).then(()=>{
    
                if(this.userInfo.id && this.userInfo.id > 1){
  
                  this.invoiceAddressForm.patchValue({user_id : this.userInfo.id});
  
                  this.createGsm.mutate({
                     name: "gsm",
                     code: this.userForm.value.phone.toString(),
                     user_id: this.userInfo.id,
                     active: true,
                     is_primary: 1
                   }).subscribe((retdata:any)=>{
                     if(retdata.errors){
                       this.alertifyService.error(retdata.errors[0].message);
                     }
                     if(retdata.data){
                       this.userForm.patchValue({
                         gsm_id: retdata.data.insert_gsms.returning[0].id
                       });
                       this.invoiceAddressForm.patchValue({
                        invoice_gsm_no: this.userForm.value.phone.toString(),
                        tel_number: this.userForm.value.phone.toString()
                      });
                   
                       this.createAddress.mutate({
                         address: this.invoiceAddressForm.value.address,
                         address_name: "adresim",
                         city_id: this.invoiceAddressForm.value.city_id,
                         country_id: this.invoiceAddressForm.value.country_id,
                         district_id: this.invoiceAddressForm.value.district_id,
                         town_id: this.invoiceAddressForm.value.town_id,
                         user_id: this.userInfo.id,
                         active: true,
                         firstname: this.invoiceAddressForm.value.firstname,
                         lastname: this.invoiceAddressForm.value.lastname,
                         identity_no: this.invoiceAddressForm.value.identity_no,
                         address_type: this.invoiceAddressForm.value.customer_invoice_type,
                         tax_number: (this.invoiceAddressForm.value.tax_number == 0) ? null : this.invoiceAddressForm.value.tax_number,
                         tax_administration: this.invoiceAddressForm.value.tax_administration,
                         company_name: this.invoiceAddressForm.value.company_name
                       }).subscribe(((adresResp:any)=>{
                         if(adresResp.errors){
                           this.alertifyService.error(adresResp.errors[0].message);
                         }
                         if(adresResp.data){
                          this.selectedAddressId = adresResp.data.insert_user_address.returning[0].id
                          this.getAddressList();
                     //     this.paymentProcess();
                          
                         }

                         resolve(true);
                       }))
  
                   
                     }
                   })
  
               }
              })
  
              
              }
            }, err => {
              this.alertifyService.error(err)
            });
           
          } else {
            if(response.error && response.error.code == 400){
              alert("Mail adresi zaten kayıtlı");
            }
            this.alertifyService.error("Kayıt Başarısız");
            resolve(true);
          }
        })
      })

    
    }

    async formEmit111(){

      if(this.userInfo && this.userInfo.id > 1){
  
        this.userForm.controls["id"].setValidators([Validators.required]);
        this.userForm.controls["id"].updateValueAndValidity();
        this.userForm.controls["gsm_id"].setValidators([Validators.required]);
        this.userForm.controls["gsm_id"].updateValueAndValidity();
        this.invoiceAddressForm.patchValue({user_id: this.userInfo.id});
  
      }else{
        this.userForm.controls["id"].setValidators([]);
        this.userForm.controls["id"].updateValueAndValidity();
        this.userForm.controls["gsm_id"].setValidators([]);
        this.userForm.controls["gsm_id"].updateValueAndValidity();
        await this.createNewUser();
  
        return false;
      }
  
  
      this.hideAppointmentButton = true;
  
      if(this.invoiceAddressForm.invalid){
        this.alertifyService.error("Lütfen fatura bilgilerini giriniz");
        this.hideAppointmentButton = false;
        return false;
      }
  
      if(this.userInfo && this.userInfo.gsms && this.userInfo.gsms.length > 0){
        let gsmID = 0;
        this.userInfo.gsms.forEach((element:any) => {
          if(element.code == this.userForm.value.phone){
            gsmID = element.id;
          }
        });
  
        if(gsmID == 0){
          this.createGsm.mutate({
            name: "gsm",
            code: this.userForm.value.phone.toString(),
            user_id: this.userInfo.id,
            active: true,
            is_primary: 1
          }).subscribe((retdata:any)=>{
            if(retdata.errors){
              this.alertifyService.error(retdata.errors[0].message);
            }
            if(retdata.data){
              this.userForm.patchValue({
                gsm_id: retdata.data.insert_gsms.returning[0].id
              });
           
            }
          })
        }else{
          this.userForm.patchValue({
            gsm_id: gsmID
          });
         
        }
      }else{
        this.createGsm.mutate({
          name: "gsm",
          code: this.userForm.value.phone.toString(),
          user_id: this.userInfo?.id,
          active: true,
          is_primary: 1
        }).subscribe((retdata:any)=>{
          if(retdata.errors){
            this.alertifyService.error(retdata.errors[0].message);
          }
          if(retdata.data){
            this.userForm.patchValue({
              gsm_id: retdata.data.insert_gsms.returning[0].id
            });
         
          }
        })
      }
  
      this.createAddress.mutate({
        address: this.invoiceAddressForm.value.address,
        address_name: "adresim",
        city_id: this.invoiceAddressForm.value.city_id,
        country_id: this.invoiceAddressForm.value.country_id,
        district_id: this.invoiceAddressForm.value.district_id,
        town_id: this.invoiceAddressForm.value.town_id,
        user_id: this.userInfo.id,
        active: true,
        firstname: this.invoiceAddressForm.value.firstname,
        lastname: this.invoiceAddressForm.value.lastname,
        identity_no: this.invoiceAddressForm.value.identity_no,
        address_type: this.invoiceAddressForm.value.address_type,
        tax_number: (this.invoiceAddressForm.value.tax_number == 0) ? null : this.invoiceAddressForm.value.tax_number,
        tax_administration: this.invoiceAddressForm.value.tax_administration,
        company_name: this.invoiceAddressForm.value.company_name
      }).subscribe(((adresResp:any)=>{
        if(adresResp.errors){
          this.alertifyService.error(adresResp.errors[0].message);
        }
        if(adresResp.data){
        this.selectedAddressId = adresResp.data.insert_user_address.returning[0].id
        let returnObj;
  
        this.invoiceAddressForm.patchValue({
          invoice_gsm_no: this.invoiceAddressForm.value.invoice_gsm_no,
          tel_number: this.invoiceAddressForm.value.invoice_gsm_no
        });
  
        switch(this.selectedPaymentTypes){
          case 1: //kredi kartı ödemesi
          if(this.invoiceAddressForm.invalid){
            this.alertifyService.error("Hatalı Fatura Bilgileri");
          }
          returnObj = {
            userInfo: {
              id: this.userForm.value.id,
              firstname: this.userForm.value.firstname,
              surname: this.userForm.value.lastname,
              email: this.userForm.value.email,
              phone: this.userForm.value.phone,
              gsm_id: this.userForm.value.gsm_id,
              isPayed: this.isPayed,
              garages: this.userInfo?.garages,
              address_id: (this.selectedAddressId) ? this.selectedAddressId : null,
              isCompanyisCompany: false
            },
            invoiceInfo:this.invoiceAddressForm.value,
            paymentType: this.selectedPaymentTypes,
            orderCreateable : true,
          }
  
          let orderCreateble = this.isOrderCreateble();
  
          this.hideAppointmentButton = false;
            this.returnData.emit(returnObj);
         
          
          break;
    
          default:
    
            this.invoiceAddressForm.patchValue({user_id: this.userInfo.id});
  
            if(this.invoiceAddressForm.invalid){
              this.alertifyService.error("Hatalı Fatura Bilgileri");
              this.hideAppointmentButton = false;
              return false;
            }
    
            if(this.userForm.invalid){
              this.alertifyService.error("Hatalı Kullanıcı Bilgileri");
              this.hideAppointmentButton = false;
              return false;
            }
    
            returnObj = {
              userInfo: {
                id: this.userForm.value.id,
                firstname: this.userForm.value.firstname,
                surname: this.userForm.value.lastname,
                email: this.userForm.value.email,
                phone: this.userForm.value.phone,
                gsm_id: this.userForm.value.gsm_id,
                isPayed: this.isPayed,
                garages: this.userInfo?.garages,
                address_id: (this.selectedAddressId) ? this.selectedAddressId : null
              },
              invoiceInfo:this.invoiceAddressForm.value,
              paymentType: this.selectedPaymentTypes,
              orderCreateable : true
            }
            this.returnData.emit(returnObj);
    
          break;
        }
        
        }
      }))
      
    
    }
  async formEmit(){
      
      if(this.userInfo && this.userInfo.id > 1){

        this.userForm.controls["id"].setValidators([Validators.required]);
        this.userForm.controls["id"].updateValueAndValidity();
        this.userForm.controls["gsm_id"].setValidators([Validators.required]);
        this.userForm.controls["gsm_id"].updateValueAndValidity();
        this.invoiceAddressForm.patchValue({user_id: this.userInfo.id});

      }else{
        this.userForm.controls["id"].setValidators([]);
        this.userForm.controls["id"].updateValueAndValidity();
        this.userForm.controls["gsm_id"].setValidators([]);
        this.userForm.controls["gsm_id"].updateValueAndValidity();
        await this.createNewUser();
      

        
      }

      this.hideAppointmentButton = true;

      if(this.invoiceAddressForm.invalid){
        this.alertifyService.error("Lütfen fatura bilgilerini giriniz");
        this.hideAppointmentButton = false;
        return false;
      }

      if(this.userInfo && this.userInfo.gsms && this.userInfo.gsms.length > 0){
        let gsmID = 0;
        this.userInfo.gsms.forEach((element:any) => {
          if(element.code == this.userForm.value.phone){
            gsmID = element.id;
          }
        });

        if(gsmID == 0){
          this.createGsm.mutate({
            name: "gsm",
            code: this.userForm.value.phone.toString(),
            user_id: this.userInfo.id,
            active: true,
            is_primary: 1
          }).subscribe((retdata:any)=>{
            if(retdata.errors){
              this.alertifyService.error(retdata.errors[0].message);
            }
            if(retdata.data){
              this.userForm.patchValue({
                gsm_id: retdata.data.insert_gsms.returning[0].id
              });
          
            }
          })
        }else{
          this.userForm.patchValue({
            gsm_id: gsmID
          });
        
        }
      }else{
        this.createGsm.mutate({
          name: "gsm",
          code: this.userForm.value.phone.toString(),
          user_id: this.userInfo?.id,
          active: true,
          is_primary: 1
        }).subscribe((retdata:any)=>{

          if(retdata.errors){
            this.alertifyService.error(retdata.errors[0].message);
          }
          if(retdata.data){
            this.userForm.patchValue({
              gsm_id: retdata.data.insert_gsms.returning[0].id
            });
        
          }
        })
      }

      let returnObj;

      this.invoiceAddressForm.patchValue({
        invoice_gsm_no: this.invoiceAddressForm.value.invoice_gsm_no,
        tel_number: this.invoiceAddressForm.value.invoice_gsm_no
      });


      switch(this.selectedPaymentTypes){
        case 1: //kredi kartı ödemesi
        if(this.invoiceAddressForm.invalid){
          this.alertifyService.error("Hatalı Fatura Bilgileri");
        }
        returnObj = {
          userInfo: {
            id: this.userForm.value.id,
            firstname: this.userForm.value.firstname,
            surname: this.userForm.value.lastname,
            email: this.userForm.value.email,
            phone: this.userForm.value.phone,
            gsm_id: this.userForm.value.gsm_id,
            isPayed: this.isPayed,
            garages: this.userInfo?.garages,
            address_id: (this.selectedAddressId) ? this.selectedAddressId : null,
            isCompanyisCompany: false
          },
          invoiceInfo:this.invoiceAddressForm.value,
          paymentType: this.selectedPaymentTypes,
          orderCreateable : true,
        }

        let orderCreateble = this.isOrderCreateble();

        this.hideAppointmentButton = false;
          this.returnData.emit(returnObj);
      
        
        break;

        default:

          this.invoiceAddressForm.patchValue({user_id: this.userInfo.id});

          if(this.invoiceAddressForm.invalid){
            this.alertifyService.error("Hatalı Fatura Bilgileri");
            this.hideAppointmentButton = false;
            return false;
          }

          if(this.userForm.invalid){
            this.alertifyService.error("Hatalı Kullanıcı Bilgileri");
            this.hideAppointmentButton = false;
            return false;
          }

          returnObj = {
            userInfo: {
              id: this.userForm.value.id,
              firstname: this.userForm.value.firstname,
              surname: this.userForm.value.lastname,
              email: this.userForm.value.email,
              phone: this.userForm.value.phone,
              gsm_id: this.userForm.value.gsm_id,
              isPayed: this.isPayed,
              garages: this.userInfo?.garages,
              address_id: (this.selectedAddressId) ? this.selectedAddressId : null
            },
            invoiceInfo:this.invoiceAddressForm.value,
            paymentType: this.selectedPaymentTypes,
            orderCreateable : true
          }
          this.returnData.emit(returnObj);

        break;
      }
    
  
  }

  getUserGsmList(){
    this.gsmList = [];
    this.gsmList.push({id:"0",name:"Yeni Cep Telefon Ekle"});
   
    this.getGsms.watch({where:{user_id:{"_eq":this.authService.userId}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
      if(result.errors){
        this.alertifyService.error("bir hata oluştu");
      }
      if(result.data){
        let list = result.data.gsms;

       
       
    
       list.filter((gsm:any) => {
          let gsmName:string = gsm.name + " " + gsm.code;
          this.gsmList.push({id:gsm.id,name:gsmName,code:gsm.code});
          if(this.userForm.value.gsm_id == undefined){
       
            this.userForm.patchValue({gsm_id : gsm.id,phone:gsm.code});
            this.invoiceAddressForm.patchValue({gsm_number:gsm.code,tel_number:gsm.code});
          }
        
           if(this.userForm.value.gsm_id === gsm.id){
            
            this.invoiceInfo.gsmName = gsmName;
            this.invoiceInfo.gsm_number = gsm.code;
            this.invoiceInfo.tel_number = gsm.code;

          }
        });
      }
    })
   }

   getAddressList(){

    if(this.authService.userId == undefined || this.authService.userId == null){
      return false;
    }

    if(this.isCompany){
      this.getCompanyGsmList();
    }else{
      this.setUserAddressList();
      this.getUserGsmList();

    }

  }

  getCompanyGsmList(){
    this.companyGsmList = [];
    this.companyGsmList.push({id:"0",name:"Yeni Cep Telefon Ekle"});
    this.getCompanyGsms.watch().valueChanges.subscribe((result:any)=>{
      if(result.errors){
        this.alertifyService.error("bir hata oluştu");
      }
      if(result.data){
        let list = result.data.company_gsms;
        list.filter((gsm:any) => {
          let gsmName:string = gsm.name + " " + gsm.code;
          this.companyGsmList.push({id:gsm.id,name:gsmName});
          if(this.invoiceInfo.gsm_id === gsm.id){
            this.invoiceInfo.gsmName = gsmName;
          }
        });
      }
    })
  }


  setUserAddressList(){
    if(this.authService.userId > 1){
      this.getUserAddressList.watch({where:{user_id:{_eq:this.authService.userId}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
        this.addressList = result.data.user_address;
        this.setSelectedAddressInfo();
  
      });  
    }
   
  }

  setSelectedAddressInfo(){
   
    
        if(this.addressList != undefined && this.selectedAddressId != undefined ){
          let exists = this.addressList.filter((data: {id:any}) => (data.id === this.selectedAddressId));
          if(exists.length > 0){
            this.selectedAddressInfo = exists[0];
          /*  this.invoiceInfo.address_id = this.selectedAddressInfo.id;
            this.invoiceInfo.country_id = this.selectedAddressInfo.country_id;
            this.invoiceInfo.city_id = this.selectedAddressInfo.city_id;
            this.invoiceInfo.district_id = this.selectedAddressInfo.district_id;
            this.invoiceInfo.town_id = this.selectedAddressInfo.town_id;*/
            this.invoiceAddressForm.patchValue({
              country_id: this.selectedAddressInfo.country_id,
              city_id: this.selectedAddressInfo.city_id,
              district_id:this.selectedAddressInfo.district_id,
              town_id: this.selectedAddressInfo.town_id,
              address: this.selectedAddressInfo.address,
              invoice_email: this.userInfo.email,
              invoice_gsm_no: this.userForm.value.phone,
              tel_number: this.userForm.value.phone,
              customer_email: this.userInfo.email
            });
          }
    
        }
    
      }


      setInvoiceInfo(type:string,$event:any,canEmpty:boolean=false){
   
        switch(type){

          case "customer_invoice_type": 
            this.invoiceAddressForm.patchValue({customer_invoice_type: $event.target.value});
          
            if($event.target.value == 2){
              this.invoiceAddressForm.controls["firstname"].setValidators([]);
              this.invoiceAddressForm.controls["firstname"].updateValueAndValidity();
              this.invoiceAddressForm.controls["lastname"].setValidators([]);
              this.invoiceAddressForm.controls["lastname"].updateValueAndValidity();
              this.invoiceAddressForm.controls["identity_no"].setValidators([]);
              this.invoiceAddressForm.controls["identity_no"].updateValueAndValidity();
              this.invoiceAddressForm.controls["company_name"].setValidators([Validators.required]);
              this.invoiceAddressForm.controls["company_name"].updateValueAndValidity();
              this.invoiceAddressForm.controls["tax_number"].setValidators([Validators.required]);
              this.invoiceAddressForm.controls["tax_number"].updateValueAndValidity();
              this.invoiceAddressForm.controls["tax_administration"].setValidators([Validators.required]);
              this.invoiceAddressForm.controls["tax_administration"].updateValueAndValidity();

              this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{
                customer_invoice_type: $event.target.value,
                customer_name: null,
                customer_surname: null,
                identity_no: null
              }}).subscribe(response=>{
                if(response.errors){
                  this.alertifyService.error(response.errors[0].message);
                }
              });

              this.invoiceAddressForm.patchValue({
                firstname: null,
                lastname: null,
                identity_no: null
              });
            }else{
              this.invoiceAddressForm.controls["firstname"].setValidators([Validators.required]);
              this.invoiceAddressForm.controls["firstname"].updateValueAndValidity();;
              this.invoiceAddressForm.controls["lastname"].setValidators([Validators.required]);
              this.invoiceAddressForm.controls["lastname"].updateValueAndValidity();;
              this.invoiceAddressForm.controls["identity_no"].setValidators([Validators.required]);
              this.invoiceAddressForm.controls["identity_no"].updateValueAndValidity();
              this.invoiceAddressForm.controls["company_name"].setValidators([]);
              this.invoiceAddressForm.controls["company_name"].updateValueAndValidity();
              this.invoiceAddressForm.controls["tax_number"].setValidators([]);
              this.invoiceAddressForm.controls["tax_number"].updateValueAndValidity();
              this.invoiceAddressForm.controls["tax_administration"].setValidators([]);
              this.invoiceAddressForm.controls["tax_administration"].updateValueAndValidity();
              
              this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{
                customer_invoice_type: $event.target.value,
                company_name: null,
                tax_number: null,
                tax_administration: null
              }}).subscribe(response=>{
                if(response.errors){
                  this.alertifyService.error(response.errors[0].message);
                }
              });

              this.invoiceAddressForm.patchValue({
                company_name: null,
                tax_number: 0,
                tax_administration: null
              });
            }
          break;  
    
          case "email":
            if(canEmpty && $event == ''){
              this.invoiceInfo.email = '';
            }else{
              if($event.target.value){
                this.invoiceInfo.email = $event.target.value;
              }else{
                this.invoiceInfo.email = this.userInfo?.email;
              }
             
            }
            this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{
              customer_email: this.invoiceInfo.email
              
            }}).subscribe(response=>{
              if(response.errors){
                this.alertifyService.error(response.errors[0].message);
              }
            });
          break;
          case 'company_name':
            if(canEmpty && $event == ''){
              this.invoiceInfo.company_name = '';
              this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{company_name:''}}).subscribe(response=>{
                if(response.errors){
                  this.alertifyService.error(response.errors[0].message);
                }
              })
            }else{
              let company_name = $event.target.value;
              if(company_name.length < 3){
                this.alertifyService.error("İsim en az 3 karakter olmalıdır");
                //this.invoiceInfo.invoice_name = "";
                this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{company_name:''}}).subscribe(response=>{
                  if(response.errors){
                    this.alertifyService.error(response.errors[0].message);
                  }
                })
                return false;
              }else{
                this.invoiceInfo.invoice_name = name;
                this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{company_name:company_name}}).subscribe(response=>{
                  if(response.errors){
                    this.alertifyService.error(response.errors[0].message);
                  }
                })
              }
            }
          break;
          case "invoice_name":
            if(canEmpty && $event == ''){
              this.invoiceInfo.invoice_name = '';
              this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{customer_name:''}}).subscribe(response=>{
                if(response.errors){
                  this.alertifyService.error(response.errors[0].message);
                }
              })
            }else{
              let name = $event.target.value;
              if(name.length < 3){
                this.alertifyService.error("İsim en az 3 karakter olmalıdır");
                //this.invoiceInfo.invoice_name = "";
                this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{customer_name:''}}).subscribe(response=>{
                  if(response.errors){
                    this.alertifyService.error(response.errors[0].message);
                  }
                })
                return false;
              }else{
                this.invoiceInfo.invoice_name = name;
                this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{customer_name:name}}).subscribe(response=>{
                  if(response.errors){
                    this.alertifyService.error(response.errors[0].message);
                  }
                })
              }
            }
            
          break;
    
          case "invoice_surname":
            if(canEmpty && $event == ''){
              this.invoiceInfo.invoice_surname = '';
              this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{customer_surname:''}}).subscribe(response=>{
                if(response.errors){
                  this.alertifyService.error(response.errors[0].message);
                }
              })
            }else{
              let surname = $event.target.value;
              if(surname.length < 3){
                this.alertifyService.error("İsim en az 3 karakter olmalıdır");
                this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{customer_surname:''}}).subscribe(response=>{
                  if(response.errors){
                    this.alertifyService.error(response.errors[0].message);
                  }
                })
              //  this.invoiceInfo.invoice_surname = "";
                return false;
              }else{
                this.invoiceInfo.invoice_surname = surname;
                this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{customer_surname:surname}}).subscribe(response=>{
                  if(response.errors){
                    this.alertifyService.error(response.errors[0].message);
                  }
                })
              }
            }
            
          break;

          case "tax_administration":
            if(canEmpty && $event == ''){
              this.invoiceInfo.tax_administration = '';
              this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{tax_adminstration:''}}).subscribe(response=>{
                if(response.errors){
                  this.alertifyService.error(response.errors[0].message);
                }
              })
            }else{
              let taxAdministration = $event.target.value;
              if(taxAdministration.length < 3){
                this.alertifyService.error("Vergi dairesi en az 3 karakter olmalıdır");
                this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{tax_administration:''}}).subscribe(response=>{
                  if(response.errors){
                    this.alertifyService.error(response.errors[0].message);
                  }
                })
              //  this.invoiceInfo.invoice_surname = "";
                return false;
              }else{
                this.invoiceInfo.tax_administration = taxAdministration;
                this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{tax_administration:taxAdministration}}).subscribe(response=>{
                  if(response.errors){
                    this.alertifyService.error(response.errors[0].message);
                  }
                })
              }
            }
            
          break;

          case "tax_number":
    
            if(canEmpty && $event == ''){
              this.invoiceInfo.tax_number = '';
              this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{tax_number:''}}).subscribe(response=>{
                if(response.errors){
                  this.alertifyService.error(response.errors[0].message);
                }
              })
            }else{
             
                let taxNumber = $event.target.value;
                
                if(taxNumber.length != 11){
                  this.alertifyService.error('Vergi numarası 11 haneli olmalı');
                  this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{tax_number:''}}).subscribe(response=>{
                    if(response.errors){
                      this.alertifyService.error(response.errors[0].message);
                    }
                  })
                  return false;
                  
                  
                }else{
                  this.invoiceInfo.tax_number = taxNumber;
                  this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{tax_number:taxNumber}}).subscribe(response=>{
                    if(response.errors){
                      this.alertifyService.error(response.errors[0].message);
                    }
                  })
                }
               
              
            }
    
            
            
          break;
    
          case "address":
            this.selectedAddressId = $event;
            this.invoiceInfo.address_id = $event;
            this.setSelectedAddressInfo();
          break;
    
          case "gsm":
    
            if(canEmpty && $event == ''){
              this.invoiceInfo.selectedPhone = '';
              this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{gsm_number:'',tel_number: ''}}).subscribe(response=>{
                if(response.errors){
                  this.alertifyService.error(response.errors[0].message);
                }
              })
            }else{
              if($event == 0){
                this.openDrawer('gsmAdd');
              }else{
               // this.invoiceInfo.gsm_id = $event;
              
                let selectedPhone = this.gsmList.filter((item:any) => item.id == $event);
                if(selectedPhone.length > 0){

                  this.invoiceInfo.gsm_number = selectedPhone[0].code;
                  this.invoiceInfo.tel_number = selectedPhone[0].code;
                  this.invoiceInfo.gsm_id = selectedPhone[0].id;
                  this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{gsm_number:selectedPhone[0].code}}).subscribe(response=>{
                    if(response.errors){
                      this.alertifyService.error(response.errors[0].message);
                    }
                  })
                  
                }
               
              }
            }
    
            
            
          break;
    
          case "new_gsm":
           
            if(canEmpty && $event == ''){
              this.invoiceInfo.gsm_number = '';
              this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{gsm_number:''}}).subscribe(response=>{
                if(response.errors){
                  this.alertifyService.error(response.errors[0].message);
                }
              })
            }else{
              if($event.target.value.length != 11){
                this.alertifyService.error("Gsm numarası 10 haneli olmalıdır");
                return false;
              }
    
              this.invoiceInfo.gsm_number = $event.target.value;
              this.invoiceInfo.tel_number = $event.target.value;
              this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{gsm_number:$event.target.value}}).subscribe(response=>{
                if(response.errors){
                  this.alertifyService.error(response.errors[0].message);
                }
              })
            }
    
             
           
          break;
    
          case "identity_no":
            if(this.orderCardId){
              this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{identity_no: $event.target.value}}).subscribe(response=>{
                if(response.errors){
                  this.alertifyService.error(response.errors[0].message);
                }
              })
            }
            
            if(canEmpty && $event == ''){
              this.invoiceInfo.identity_no = '';
            }else{
              let identity_no = $event.target.value;
              if(identity_no.length != 11){
                this.alertifyService.error("Tc kimlik no 11 karakter olmalıdır.");
               // this.invoiceInfo.identity_no = "";
                return false;
              }else{
                this.invoiceInfo.identity_no = identity_no;
                this.userInfo.identity_no = identity_no;
                this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:{identity_no:identity_no}}).subscribe(response=>{
                  if(response.errors){
                    this.alertifyService.error(response.errors[0].message);
                  }
                })
              }
            }
            
          break;
    
         
        }
        
    
      }

      filterFormatCardNo($event) {

        this.inputCardNo = $event.target.value.replace(" ","");

        let value = $event.target.value.replace(/\s/g, '').replace(/(.{4})/g, '$1 ').trim();

        this.filteredCardNo = value;
       
    }

    filterFormatExpirationDate($event) {
      
      
      this.inputExpiryDate = $event.target.value.replace("/","").trim();
      this.filteredExpiryDate = this.inputExpiryDate.replace(/\D/g, ''); // Sadece sayısal karakterleri alın
     
      if (this.filteredExpiryDate.length > 2) {
        this.filteredExpiryDate = this.filteredExpiryDate.slice(0, 2) + '/' + this.filteredExpiryDate.slice(2);
        this.filteredExpiryDate = this.filteredExpiryDate.slice(0,5);
      }
     
  }

  toNumberFormat(number: any) {
    return numberFormatStr(number);
  }

}
