
<label class="d-flex align-items-center fs-7 fw-bold form-label mb-2" *ngIf="showLabel">Mahalle</label>
<app-shared-selectwo
[id]="'townInputId'"
[name]="'Mahalle Seçiniz'"
[setItem]="selectedTownId"
[lists]="townList"
(selectedItem)="selected($event)"
[showAll]="false"
>
</app-shared-selectwo>