import { Injectable } from "@angular/core";
import { Mutation, Query } from "apollo-angular";
import gql from "graphql-tag";

export interface MechanicParts {
  parts: any,
  part_original_id: BigInt,
  part_original_list: any,
  model_type_id: Number,
  part_unit: Number,
  part_unit_type: Number,
  part_group_id: Number
  part_name?: String
}

interface ResponseArray {
    view_vehicle_mechanic_parts: MechanicParts[]
}

interface ResponseSingle {
    view_vehicle_mechanic_parts: MechanicParts
}

@Injectable()
export class MECHANIC_PARTS_ALL extends Query<ResponseArray>{
  override document = gql`
  query MECHANIC_PARTS_ALL($where: view_vehicle_mechanic_parts_bool_exp) {
    view_vehicle_mechanic_parts(where: $where) {
      parts {
        id
        name
      }
      part_original_id
      part_group_id
      part_original_list {
        id
        name
        part_equivalent_lists {
          id
          name
          part_code
          part_in_vat_price
          part_no_vat_price
          part_original_id
          part_type_id
          price_unit
        }
        part_in_vat_price
        part_no_vat_price
        part_type_id
        price_unit
      }
    part_unit,
    part_unit_type,
    model_type_id    }
  }
  
  `;

  
}

@Injectable()
export class MECHANIC_PARTS_BY_MODEL_TYPE_ID extends Query<ResponseArray>{
  override document = gql`
  query MECHANIC_PARTS_BY_MODEL_TYPE_ID($model_type_id: bigint) {
    view_vehicle_mechanic_parts(where:{model_type_id: {_eq: $model_type_id}}) {
      parts {
        id
        name
        original_price_ratio
        price_ratio
      }
      part_original_id
      part_group_id
      part_original_list {
        id
        name
        part_code
        price_unit_id
        part_equivalent_lists {
          id
          name
          part_code
          part_in_vat_price
          part_no_vat_price
          part_original_id
          part_type_id
          price_unit
          price_unit_id
          part_types{
            id
            name
          }
        }
        part_types{
          id
          name
        }
        part_in_vat_price
        part_no_vat_price
        part_type_id
        price_unit
      }
    part_unit,
    part_unit_type,
   
    model_type_id,
    part_unit_types{
      id
      name
    }   
   }
  }
  
  `;

  
}