import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debug } from 'console';
import { APPOINTMENT_PACKAGE_ID, Package } from 'src/app/graphql/package.graphql';
import { PackageModelType, PACKAGE_MODEL_TYPE_ALL } from 'src/app/graphql/package_model_type.graphql';
import { PART_MODEL_TYPES_GET } from 'src/app/graphql/part_model_types.graphql';
import { PieceImages, PIECE_IMAGES_ALL } from 'src/app/graphql/piece_images.graphql';
import { User } from 'src/app/graphql/user.graphql';
import { numberFormat, numberFormatStr, numberFormatWithoutPennies } from 'src/app/helpers/slugify';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { PackageParserService } from 'src/app/services/package/packageParser.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment-v3-paket-secimi',
  templateUrl: './paket-secimi-v3.component.html',
  styles: [
  ],
  styleUrls:['./paket-secimi-v3.component.css'],
  providers:[PACKAGE_MODEL_TYPE_ALL,APPOINTMENT_PACKAGE_ID,PIECE_IMAGES_ALL,PackageParserService,PART_MODEL_TYPES_GET]
})
export class PaketSecimiV3Component implements OnInit {

  public classList = {
    first: "animate-first first-item",
    second: "animate-middle second-item",
    last: "animate-last last-item"
  };

  @Input() appointmentForm: any;
  @Input() selectedPackageInput:any;
  @Input() vehicleForm:any;
  @Input() stepBack:boolean = false;
  @Input() userId: bigint | undefined;
  @Input() userInfo: any;
  @Input() sessionUserInfo: any;
  @ViewChild('imageListUl') imageListUl: ElementRef | undefined;
  public currentSlide = 0;
  public direction = 1;
  public imageWidth: any;
  public isReversed = false;
  public intevalElem: any;

  public packageModelType: PackageModelType | undefined;
  public package: Package | undefined;
  public originalList: any = [];
  public premiumList: any = [];
  public equivalentList: any = [];

  public premiumObjects:any = [];
  public equivalentObjects:any = [];
  public equivalentReutnObject:any = [];

  public premiumOfferList: any = [];
  public equivalentOfferList: any = [];
  public premiumReutnObject:any = [];

  public originalAmounts:any = [];
  public premiumAmounts:any= [];
  public equivalentAmounts: any = [];
  
  public totalLabourTime = 0;
  public originalLabourPrice = 0;
  public premiumLabourPrice = 0;
  public equivalentLabourPrice = 0;
  public originalLabourHour = 0;
  public premiumLabourHour = 0;
  public equivalentLabourHour = 0;

  public selectedPackage: any;
  public selectedPaymentType: number | null = null;

  public totalLabourHour = 0;

  public selectedPackageName = "";

  public pieceImages: PieceImages[] | undefined;
  public packageInfo: any;

  public isPackageSet = false;
  public urlPart:string | undefined;

  private touchStartX: number = 0;
  private touchEndX: number = 0;

  public packageForm = new FormGroup({
    maintenancePackageId: new FormControl(null,[Validators.required]),
    paymentType: new FormControl([null,[Validators.required]])
  })

  private baseUrl: string = environment.laravelApiEndpoint;

  public showPaymentButtons: boolean = false;
  @Output() selectedPackageEmitter = new EventEmitter<any>();
  @Output() isStepBack = new EventEmitter<boolean>();

  public screenWidth: number;

  constructor(
    private alertifyService: AlertifyService,
    private getPieceImageList: PIECE_IMAGES_ALL,
    private packageService: PackageParserService,
    private http: HttpClient,
    public authService: AuthService,
    private route: ActivatedRoute, 
    private router: Router
  ) { 
    this.screenWidth = window.innerWidth;
    window.scrollTo(0, 0);
  }

  

  async ngOnInit(): Promise<void> {
   


    const isMobile = this.isMobileDevice();

    this.originalLabourPrice   = 0;
    this.premiumLabourPrice    = 0;
    this.equivalentLabourPrice = 0;

    const fullUrl = this.router.url;
  
    const segments = fullUrl.split('/');
    const desiredSegment = segments.find(segment => segment === 'teklif');
    this.urlPart = desiredSegment;

    

    if(this.stepBack){
      this.selectedPackage = this.selectedPackageInput;
    }

    if(this.appointmentForm.value.modelTypeId && this.appointmentForm.value.gearId, this.appointmentForm.value.fuelId){


     // this.packageInfo = await this.packageService.parsePackage(this.selectedPackageInput.id,this.appointmentForm.value.model_id);
      /**
       * 
         this.originalPackageParts   = response.result.original_objects.parts;
         this.premiumPackageParts    = response.result.premium_objects.parts;
         this.equivalentPackageParts = response.result.equivalent_objects.parts;
         this.amounts                = response.result.amounts;
       * 
       */
         let items = { package_id:this.selectedPackageInput?.id, model_type_id: this.appointmentForm.value.modelTypeId  };
      if(this.selectedPackageInput.packageInfo && this.selectedPackageInput.packageInfo.id && this.selectedPackageInput.packageInfo.id > 0){
        items.package_id = this.selectedPackageInput.packageInfo.id;
      }
      
      let options = {
        headers: new HttpHeaders({
          'token': this.authService.parseToken(false)
        })
      }

      await this.http.post<any>(this.baseUrl + 'appointment/check-order-count',{user_id:this.userId},options).subscribe(async (checkresp:any) => {
                

         if (checkresp.status) {


           
          let dailyLimit = checkresp.result.daily_limit;
          let stepContinue = checkresp.result.isContinue;

          if(!stepContinue){
            this.alertifyService.error("Günlük randevu limite ulaştınız. yarın tekrar deneyiniz.");
            this.isStepBack.emit(true);
          }else{
            setTimeout(async ()=>{
              await this.http.post<any>(this.baseUrl + "appointment/appointment-package-id",items,
              { headers: { token: `${localStorage.getItem('access_token')}` } }
              ).subscribe(async response=>{
                if(response.status === false){
                  this.alertifyService.error("Paket alınamadı!");
                }else{
                
                   this.originalList        = response.result.original_objects.parts;
                   this.premiumOfferList    = response.result.premium_objects.parts;
                   this.equivalentOfferList = response.result.equivalent_objects.parts;
                   this.originalAmounts     = response.result.amounts.original;
                   this.premiumAmounts      = response.result.amounts.premium;
                   this.equivalentAmounts   = response.result.amounts.equivalent;
                   this.package             = response.result.package_info;
                 
         
                   let selectedPackageName = "premium";
           
                   if(this.appointmentForm.value.package_type_id == 1){
                     selectedPackageName = "original";
                   }
           
                   if(this.appointmentForm.value.package_type_id == 3){
                     selectedPackageName = "equivalent";
                   }
         
                   if( this.stepBack && this.selectedPackageInput.selectedPackageTypeId && this.selectedPackageInput.selectedPackageTypeId > 0 ){
                     selectedPackageName = this.selectedPackageInput.selectedPackageName;
                    
                     this.stepBack  = false;
         
                       await this.setSliderPositions(this.selectedPackageInput.selectedPackageClass,false)
                   
                    
                   }else{
                     setTimeout(async ()=>{
                  
                         await this.setSelectedPackage(selectedPackageName);
           
                     },1000);
                   }
                  
                  
                }
              });
            },1000);
          }


         }else {
          this.alertifyService.error(checkresp.message);
          }

      });


    }

    
   

   this.getImageList(2);

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
  }
 



  filterCode(item:any){

    if(item){
      const firstThreeDigits = item.substr(0, 3);
      const remainingChars = item.substr(3);
     // const convertedString = firstThreeDigits + remainingChars.replace(/./g, '*');
      const convertedString = firstThreeDigits + '****';
      return convertedString;
    }
   
  }

  setSelectedPackage(type:string){
    this.selectedPackage = undefined;
    this.showPayments(false);
   
    switch(type){
      case "original":
        this.selectedPackageName = "Orjinal";
        this.selectedPackage = {
          "amount":this.originalAmounts,
          "packageParts": this.originalList,
          "partTypeId": this.originalList[0].part_type_id,
          "type": "original",
          "packageInfo": this.package,
          "paymentType": this.selectedPaymentType,
          "packageFrontendName": 'Orijinal',
          "selectedPackageTypeId": 1,
          "selectedPackageName": "original",
          "selectedPackageClass": {
            first: "animate-last last-item",
            second: "animate-first first-item",
            last: "animate-middle second-item"
          }
          }
          this.selectedPackage.selectedPackageParts = this.originalList;
          this.appointmentForm.patchValue({"package_type_id":1})
      break;

      case "premium":
        this.selectedPackageName = "Premium";
        this.selectedPackage = {
          "amount":this.premiumAmounts,
          "packageParts": this.premiumOfferList,
          "partTypeId": this.premiumOfferList[0].part_type_id,
          "type": "premium",
          "packageInfo": this.package,
          "paymentType": this.selectedPaymentType,
          "packageFrontendName": 'Premium',
          "selectedPackageTypeId": 2,
          "selectedPackageName": "premium",
          "selectedPackageClass": {
            first:  "animate-middle second-item",
            second: "animate-last last-item",
            last: "animate-first first-item"
            }
         
        }
        
        this.selectedPackage.selectedPackageParts = this.premiumOfferList;
        this.appointmentForm.patchValue({"package_type_id":2})
      break;

      case "equivalent":
        this.selectedPackageName = "Eşdeğer";
        this.selectedPackage = {
          "amount":this.equivalentAmounts,
          "packageParts": this.equivalentOfferList,
          "partTypeId": this.equivalentOfferList[0].part_type_id,
          "type": "equivalent",
          "packageInfo": this.package,
          "paymentType": this.selectedPaymentType,
          "packageFrontendName": 'Alternatif',
          "selectedPackageTypeId": 3,
          "selectedPackageName": "equivalent",
          "selectedPackageClass": {
            first: "animate-first first-item",
            second: "animate-middle second-item",
            last: "animate-last last-item"
          }
         
        }

        this.selectedPackage.selectedPackageParts = this.equivalentOfferList;
        this.appointmentForm.patchValue({"package_type_id":3})
      break;

     
    }
    if(this.selectedPackage && this.selectedPackage.paymentType > 0 ){
      this.showPayments(true);
        this.selectedPackageEmitter.emit(this.selectedPackage);
      
    }
  }

  showPayments(status:boolean){
    this.showPaymentButtons = status;
  }

  setPaymentType(payment_id:number){
    
    this.selectedPaymentType = payment_id;
    this.packageForm.patchValue({
      paymentType: this.selectedPaymentType,
      maintenancePackageId: this.selectedPackage?.packageInfo?.id
    });

    this.selectedPackage.paymentType = this.selectedPaymentType;
    if(this.packageForm.invalid){
      this.alertifyService.error("Lütfen paket ve ödeme türü seçiniz");
      return false;
    }

    if(this.packageForm.valid && this.selectedPackage.paymentType > 0){
     
      this.selectedPackageEmitter.emit(this.selectedPackage);
    };
  }

 

  toNumberFormat(number: any) {
    return numberFormatStr(number);
  }

  toNumberFormatWithoutPennies(number: any) {
    return numberFormatWithoutPennies(number);
  }

  setSliderPositions(classes,position){
   

    let selectedPartType = 2;

   
    this.currentSlide = 0;
    
    clearInterval(this.intevalElem);
    
    const list = this.imageListUl?.nativeElement;
    list.style.transform = `translateX(-${this.currentSlide * this.imageWidth}px)`;
  

    let newClassList;
    if(!position){
      newClassList = {
        first: classes.last,
        second: classes.first,
        last: classes.second
      }
    }else{
      newClassList = {
        first: classes.second,
        second: classes.last,
        last: classes.first
      }
    }
    this.classList = newClassList;


    setTimeout(()=>{
      let secondValue = classes.second;
      let formattedClass = '.' + secondValue.split(' ').join('.');
 
      let selectedclass = document.querySelector('.animate-middle');
      let packageTypeId = 2;
      
   
      
      let selectedType = selectedclass?.getAttribute('data-type');
    
      if(selectedType == null){
        this.alertifyService.error('paket seçilemedi');
        return false;
      }

      let originalClass = document.getElementById('original-discount-container') as HTMLElement;
      if(originalClass && originalClass != null){
       
       
      }
      if(selectedType == 'premium'){
        
        if(originalClass && originalClass != null){
          originalClass.classList.add('flexColumn');
          originalClass.classList.remove('flexAlignEnd');
        }
        packageTypeId = 2;
       
      }else if(selectedType == 'equivalent'){
        packageTypeId = 3;
        originalClass.classList.remove('flexColumn');
        originalClass.classList.add('flexAlignEnd');
      }else{
        if(originalClass && originalClass != null){
          originalClass.classList.remove('flexColumn');
          originalClass.classList.remove('flexAlignEnd');
        }
        packageTypeId = 1;
      }

      this.getImageList(packageTypeId);

      this.setSelectedPackage(selectedType);
      switch(classes.second){
        case "animate-first first-item":
        //  this.setSelectedPackage('premium');
          selectedPartType = 2;
           
        break;
  
        case "animate-middle second-item":
          //this.setSelectedPackage('original');
          selectedPartType = 1;
        break;
  
        case "animate-last last-item":
          //this.setSelectedPackage('equivalent');
          selectedPartType = 3;
        break;
      }

     
    })
    

   
    if(this.stepBack){
      
      this.setPaymentType(this.selectedPackageInput.paymentType )
      this.stepBack = false;
    }
  }

  removePennies($price){
    
    return this.toNumberFormatWithoutPennies($price)
  }

  getImageList(partTypeId: number){
    this.getPieceImageList.watch({where:{part_type_id: {_eq:partTypeId}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }

      if(resp.data){
        this.pieceImages = resp.data.piece_images;

      this.intevalElem =  setInterval(() => {
          this.slideImages();
        }, 2000);
      }
    })

  }


  calculateImageWidth(): number {
    if(this.imageListUl && this.pieceImages){
      const list = this.imageListUl.nativeElement;
      const ulWidth = list.clientWidth;
      const numImages = this.pieceImages.length;
      return ulWidth / numImages;
    }

    return 0;
    
  }

  slideImages(): void {
    this.imageWidth = this.calculateImageWidth();
    if(this.imageListUl && this.pieceImages){
      const list = this.imageListUl.nativeElement;
      const totalWidth = this.pieceImages.length * this.imageWidth;
  
      
        this.currentSlide += 1;
        if(list.clientWidth - (this.currentSlide * this.imageWidth) < ((list.clientWidth / 2 ) - 200)){
          this.currentSlide = 0;
        }else{
          if (this.currentSlide * this.imageWidth >= totalWidth) {
        
            this.currentSlide = this.pieceImages.length - 1;
          }
        }
     
      list.style.transform = `translateX(-${this.currentSlide * this.imageWidth}px)`;
    
    }
  }
  
  
  
  isMobileDevice(): boolean {
    return /Mobi|Android/i.test(navigator.userAgent);
  }
  

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.touchStartX = event.changedTouches[0].screenX;
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    this.touchEndX = event.changedTouches[0].screenX;
    this.handleSwipe();
  }

  handleSwipe() {
    const swipeThreshold = 50; // Minimum kaydırma mesafesi
    if (this.touchEndX < this.touchStartX - swipeThreshold) {
      // Sağa kaydırma (geri)
     // this.setSliderPositions(classList, true);
    }
    if (this.touchEndX > this.touchStartX + swipeThreshold) {
      // Sola kaydırma (ileri)
     // this.setSliderPositions(classList, false);
    }
  }
  

    
    
  

}
