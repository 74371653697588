import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { exit } from 'process';
import { AlertifyService } from '../alertify.service';
import { AuthService } from '../auth.service';


@Injectable()
export class PermissionGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private alertifyService: AlertifyService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const url = state.url.split('/')[0]
    const componentName = route.data['name']


    if (componentName) {
      const perms = this.authService.permission(url, componentName);
      if (!perms.read) {
        if (componentName == "DashboardComponent") {
          this.router.navigateByUrl('/')
        } else {
          this.router.navigateByUrl('/' + url)
        }
        this.alertifyService.error('Sayfayı Görüntülüme Yetkiniz Yok! \n' + componentName)
      }
      return true
    }
    this.alertifyService.error('Sayfa Bulunamadı!')
    return false
  }

}