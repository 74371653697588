import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface OrderCardInvoiceInfo {
  order_card_id: Number;
  customer_name?: string;
  customer_surname?:string;
  identity_no?:Number;
  tax_number?:String;
  tax_administration?:string;
  user_id?: bigint;
  company_id?: bigint;
  gsm_number?:string;
  customer_email?:string;
  is_same_with_customer_info?:boolean;
  invoice_type:Number,
  created_at:Date,
  tel_number: string,
  address: Text,
  city_id: Number,
  district_id: Number,
  country_id: Number,
  town_id: Number,
  company_name?: string,
  is_rated?:boolean,
  customer_invoice_type:Number
}

interface ResponseArray {
  order_card_invoice_info: OrderCardInvoiceInfo[];
}
interface ResponseOne {
    order_card_invoice_info: OrderCardInvoiceInfo;
}

interface ResponseSingle {
    order_card_invoice_info_by_pk: OrderCardInvoiceInfo
}

@Injectable()
export class ORDER_CARD_INVOICE_INFO_ALL extends Query<ResponseArray> {
  override document = gql`
    query ORDER_CARD_INVOICE_INFO_ALL($where: order_card_invoice_info_bool_exp){
        order_card_invoice_info(where: $where) {
            order_card_id
        customer_name
        customer_surname
        identity_no
        tax_number
        tax_administration
        user_id
        company_id
        gsm_number
        customer_email
        is_same_with_customer_info
        invoice_type
        created_at
        tel_number
        address
        city_id
        district_id
        country_id
        town_id
        company_name
        is_rated
        customer_invoice_type
      }
    }
  `;
}

@Injectable()
export class ORDER_CARD_INVOICE_INFO_ONE extends Query<ResponseArray> {
  override document = gql`
    query ORDER_CARD_INVOICE_INFO_ONE($where: order_card_invoice_info_bool_exp){
      order_card_invoice_info(where: $where, limit:1) {
        order_card_id
        customer_name
        customer_surname
        identity_no
        tax_number
        tax_administration
        user_id
        company_id
        gsm_number
        customer_email
        is_same_with_customer_info
        invoice_type
        created_at
        tel_number
        address
        city_id
        district_id
        country_id
        town_id
        company_name
        is_rated
        customer_invoice_type
      }
    }
  `;
}

@Injectable()
export class ORDER_CARD_INVOICE_INFO_ID extends Query<ResponseSingle> {
  override document = gql`
    query ORDER_CARD_INVOICE_INFO_ID($id: bigint!){
      order_card_invoice_info_by_pk(id: $id) {
        order_card_id
        customer_name
        customer_surname
        identity_no
        tax_number
        tax_administration
        user_id
        company_id
        gsm_number
        customer_email
        is_same_with_customer_info
        invoice_type
        created_at
        tel_number
        address
        city_id
        district_id
        country_id
        town_id
        company_name
        is_rated
        customer_invoice_type
      }
    }
  `;
}

@Injectable()
export class ORDER_CARD_INVOICE_INFO_CREATE extends Mutation<any> {
  // connected_part_id - int | nullable
  override document = gql`
    mutation ORDER_CARD_INVOICE_INFO_CREATE( $object: order_card_invoice_info_insert_input! ) {
      
        insert_order_card_invoice_info_one(object: $object) {
            order_card_id
        }
    }
  `;
}



@Injectable()
export class ORDER_CARD_INVOICE_INFO_UPDATE extends Mutation<any> {
  override document = gql`
    mutation ORDER_CARD_INVOICE_INFO_UPDATE($where: order_card_invoice_info_bool_exp!, $changes: order_card_invoice_info_set_input ) {
      update_order_card_invoice_info(where: $where, _set: $changes) {
        affected_rows
        returning {
            order_card_id
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_CARD_INVOICE_INFO_UPDATE_ID extends Mutation<any> {
  override document = gql`
    mutation ORDER_CARD_INVOICE_INFO_UPDATE_ID($id: bigint, $changes: order_card_invoice_info_set_input ) {
      update_order_card_invoice_info(where: {order_card_id:{_eq:$id}}, _set: $changes) {
        affected_rows
        returning {
            order_card_id
        }
      }
    }
  `;
}


@Injectable()
export class ORDER_CARD_INVOICE_INFO_DELETE extends Mutation<any> {
  override document = gql`
    mutation ORDER_CARD_INVOICE_INFO_DELETE($id: bigint!) {
      delete_order_card_invoice_info(where: {order_card_id: {_eq: $id}}) {
        affected_rows
        returning {
            order_card_id
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_CARD_INVOICE_INFO_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query ORDER_CARD_INVOICE_INFO_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: order_card_invoice_info_bool_exp) {
      order_card_invoice_info(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        order_card_id
        customer_name
        customer_surname
        identity_no
        tax_number
        tax_administration
        user_id
        company_id
        gsm_number
        customer_email
        is_same_with_customer_info
        invoice_type
        created_at
        tel_number
        address
        city_id
        district_id
        country_id
        town_id
        company_name
        is_rated
        customer_invoice_type
      }
    }
  `;
}

@Injectable()
export class ORDER_CARD_INVOICE_INFO_COUNT extends Query<any> {
  override document = gql`
    query ORDER_CARD_INVOICE_INFO_COUNT($where: order_card_invoice_info_bool_exp) {
      order_card_invoice_info_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}