import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { Order, ORDER_ID, ORDER_UPDATE } from 'src/app/graphql/order.graphql';
import { ORDER_INVOICE_CREATE } from 'src/app/graphql/order_invoices.graphql';
import { ORDER_INVOICE_PART_CREATE } from 'src/app/graphql/order_invoice_parts.graphql';
import { SERVICE_INVOICE_CREATE } from 'src/app/graphql/service_invoices.graphql';
import { SERVICE_INVOICE_PART_CREATE } from 'src/app/graphql/service_invoice_parts.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-shared-invoice-create',
  templateUrl: './invoice-create.component.html',
  styles: [
  ],
  providers:[ORDER_ID,ORDER_INVOICE_PART_CREATE,ORDER_INVOICE_CREATE,SERVICE_INVOICE_CREATE,SERVICE_INVOICE_PART_CREATE]
})
export class InvoiceCreateComponent implements OnInit {
  @Input() orderId: Number | undefined;
  @Output() invoiceResult = new EventEmitter<boolean>();

  public orderDetail: Order | undefined;


  constructor(
    private alertifyService: AlertifyService,
    private getOrder: ORDER_ID,
    private createOrderInvoicePart: ORDER_INVOICE_PART_CREATE,
    private createOrderInvoice: ORDER_INVOICE_CREATE,
    private updateOrder: ORDER_UPDATE,
    private createServiceInvoice: SERVICE_INVOICE_CREATE,
    private createServiceInvoicePart: SERVICE_INVOICE_PART_CREATE
  ) { }

  ngOnInit(): void {

    this.getOrderDetail();

  }

  getOrderDetail() {
    this.getOrder.watch({id:this.orderId},{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any) => {

      if(result.errors){
        this.alertifyService.error(result.errors[0].message);
        return;
      }

      if(result.data){
        this.orderDetail = result.data.orders_by_pk;
      }
    });
  }

  createInvoice(type:string){
        switch(type){
          case "gos":
            if(this.orderDetail != undefined){

              let service_value_no_vat = (this.orderDetail.service_value - ((this.orderDetail.service_value*18)/100));
             
             /* this.createServiceInvoice.mutate({
                "order_id":this.orderDetail.id,
                "date":moment().format("YYYY-MM-DDTHH:mm:ssZ"),
                "value_in_vat":this.orderDetail.service_value,
                "value_no_vat":service_value_no_vat,
                "vat": ( this.orderDetail.order_value - this.orderDetail.order_no_vat_value ),
                "discount":0,
                "url":"",
                "invoice_type_id":this.orderDetail.type_id,
                "valor_date":moment().format("YYYY-MM-DDTHH:mm:ssZ"),
                "invoice_status_id":6, // TODO buraya bakılacak
                "paid_value": this.orderDetail.order_value, // TODO buraya bakılacak
                "remaining_value":0, // TODO buraya bakılacak
                "note":"",
                "invoice_reciever_id":this.orderDetail.customer_id,
              })*/

            }else{
              this.alertifyService.error("Sipariş detayına ulaşılamadı!");
            }
          break;
    
          case "customer":
            
            if(this.orderDetail != undefined){
              this.createOrderInvoice.mutate({
                object:{
                "order_id":this.orderDetail.id,
                "date":moment().format("YYYY-MM-DDTHH:mm:ssZ"),
                "value_in_vat":this.orderDetail.order_value,
                "value_no_vat":this.orderDetail.order_no_vat_value,
                "vat": ( this.orderDetail.order_value - this.orderDetail.order_no_vat_value ),
                "discount":0,
                "url":"",
                "invoice_type_id":this.orderDetail.type_id,
                "valor_date":moment().format("YYYY-MM-DDTHH:mm:ssZ"),
                "invoice_status_id":6,
                "paid_value": this.orderDetail.order_value, // TODO buraya bakılacak
                "remaining_value":0, // TODO buraya bakılacak
                "note":"",
                "invoice_reciever_id":this.orderDetail.customer_id,
               // "invoice_reciever_type_id": this.orderDetail.customer_info.role_id
              
              }
              }).subscribe((result:any) => {
                if(result.errors){
                  this.alertifyService.error(result.errors[0].message);
                  this.invoiceResult.emit(false);
                }
    
                if(result.data){
    
                  this.updateOrder.mutate({"id":this.orderId,"changes":{invoice_id:result.data.insert_order_invoices_one.id}}).subscribe((upresult:any) => {
                    if(upresult.errors){
                      this.alertifyService.error("Güncelleme esnasında bir sorun oluştu!");
                    }
                    if(upresult.data){
                      this.alertifyService.success("siparişiniz güncellendi");
                    }
                  })
                  this.orderDetail?.order_parts.forEach((element:any) => {
                    this.createOrderInvoicePart.mutate({
    
                      object:{
                      "invoice_id":result.data.insert_order_invoices_one.id,
                      "part_id": element.id,
                      "part_name": element.parts.name,
                      "part_code": element.part_equivalent_list[0].part_code,
                      "labour_type_id": element.labour_type_id,
                      "labour_value": element.labour_value,
                      "labour_discount": element.labour_discount,
                      "part_in_vat_price": element.part_value,
                      "part_no_vat_price": element.part_no_vat_price,
                      "invoice_type":1, // TODO buraya bakılacak
                      "part_unit_type_id": element.part_unit_type_id,
                      "part_unit": element.part_unit
                     
                    }
      
                    }).subscribe((partResult:any) => {
                      if(partResult.errors){
                        this.alertifyService.error(partResult.errors[0].message);
                        this.invoiceResult.emit(false);
                      }
                     if(partResult.data){
    
                     }
                    })
                  });
    
                  this.getOrderDetail();
    
                  this.alertifyService.success("Fatura oluşturuld");
                  this.invoiceResult.emit(true);
    
                }
              })
            }
            
          break;
        }
    
      }

}
