import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  public profileMenu:boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

  profileToggle() {

    this.profileMenu = !this.profileMenu;

  }
}
