

<form [formGroup]="identityFormGroup" (ngSubmit)="updateIdentity()">
    <div class="row mb-5" *ngIf="!showCreateAddress && showIdentityInput">
       <div class="col-md-8">
        <label class="required form-label">Tc Kimlik No</label>
        <input type="text" class="form form-control" formControlName="identity_no" name="identity_no">
       </div>
       <div class="col-md-4">
        
        <button type="submit" class="btn btn-primary btn-sm mt-8">Kimlik No Güncelle</button>
       </div>
           
    </div>
</form>


<div class="row" *ngIf="!showCreateAddress">
    {{addHeight}}
    <div class="col-md-8" [ngStyle]="{'min-height': (selectedAddress == undefined && addHeight) ? '500px':'' }">
  
     
        
        <app-shared-selectwo
            [id]="'sharedAddressSelect'"
            [lists]="userAddressList"
            (selectedItem)="setSelectedAddress($event)"
            [fieldName]="'address_name'"
            [name]="'Adres Seçiniz'"
            [showAll]="false"
        >
        </app-shared-selectwo>
       
    </div>
    <div class="col-md-4"> <button class="btn btn-primary btn-sm" (click)="setPage('address')">Yeni Adres Ekle</button></div>
</div>

<div class="row" *ngIf="selectedAddress && !showCreateAddress">
    <div class="card">
        <div class="card-header">
            <div class="card-title">
                {{selectedAddress?.address_name}} 
            </div>
            <div class="card-toolbar">
                
            </div>
        </div>
        <div class="card-body">
            {{selectedAddress?.address}} {{selectedAddress?.city?.name}} / {{selectedAddress?.district?.name}} / {{selectedAddress?.town?.name}} 
        </div>
        <div class="card-footer d-flex justify-content-center ">
          
                <button class="btn btn-primary  btn-sm" (click)="emitAddress()">Adresi Seç</button>
            
            
        </div>
    </div>
    
</div>

<div *ngIf="showCreateAddress">
    <div class="card">
        <form class="form fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" [formGroup]="formGroup" (ngSubmit)="formSubmit()">
    
            <div class="row mb-5">
                <div class="col-xl-3">
                    <div class="fs-6 fw-bold">{{'TITLE' | translate}}</div>
                </div>
                <div class="col-xl-9">
                    <div class="form-check form-switch form-check-custom form-check-solid">
                        <input type="text" class="form-control" name="address_name" formControlName="address_name" />
    
                    </div>
                </div>
            </div>
    
            <app-shared-countries (country_id)="changeGeoZone('country',$event)" ></app-shared-countries>
            <app-shared-cities [event]="selectedCountryId" (city_id)="changeGeoZone('city',$event)"></app-shared-cities>
    
            <app-shared-districts [event]="selectedCityId" (district_id)="changeGeoZone('district',$event)"></app-shared-districts>
            <app-shared-towns [event]="selectedDistrictId" (town_id)="changeGeoZone('town',$event)"></app-shared-towns>
    
            <div class="row mb-5">
                <div class="col-xl-3">
                    <div class="fs-6 fw-bold">{{'ADDRESS'|translate}}</div>
                </div>
                <div class="col-xl-9">
                    <div class="form-check form-switch form-check-custom form-check-solid">
                        <textarea class="form-control" name="address" formControlName="address"></textarea>
                    </div>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-xl-3">
                    <div class="fs-6 fw-bold">{{'STATUS'|translate}}</div>
                </div>
                <div class="col-xl-9">
                    <div class="form-check form-switch form-check-custom form-check-solid">
                        <input type="checkbox" class="form-check-input" name="active" formControlName="active">
                        <label class="form-check-label fw-bold text-gray-400 ms-3">{{'ACTIVE'|translate}}</label>
                    </div>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button type="reset" class="btn btn-light btn-active-light-primary me-2" (click)="setPage('close_address')">{{'FORM.DISCARD'|translate}}</button>
                <button type="submit" class="btn btn-primary" [disabled]="!formGroup.valid">
                    {{'FORM.SAVE'|translate}}
                </button>
            </div>
        </form>
    </div>
    
</div>




