<div class="card card-flush py-4">
    <div class="card-body pt-0">
        <div class="row">
            <div class="col-lg-3 col-md-9 col-sm-12"  >
                <label for="" class="required form-label">Orijinal Parça</label>
                <app-shared-selectwo
               
                        [lists]="partOriginalListSearchResult"
                        [id]="'originalParts'"
                        (selectedItem)="selectedTwo('originalParts',$event)" 
                        [name]="'Orjinal Parça'" 
              
                        [showAll]=false
                        [extendSearch]="true"
                        (searchItem)="searchOriginalPart($event)"
                        [searchItemLength]="4"
                     
                        ></app-shared-selectwo>
                       
                
            </div>
            <div class="col-lg-3 col-md-9 col-sm-12"  >
                <label for="" class="required form-label">Parça Birim Türü</label>
                <app-shared-selectwo
               
                        [lists]="partUnitTypes"
                        [id]="'partUnitTypes'"
                        (selectedItem)="selectedTwo('partUnitTypes',$event)" 
                        [name]="'Parça Birim Türü '" 
                        [showAll]=false
                     
                        ></app-shared-selectwo>
                       
                
            </div>
            <div class="col-lg-1 col-md-9 col-sm-12"  >
                <label for="" class="required form-label">Adet</label>
                <input type="number" name="part_unit" class="form form-control" (change)="selectedTwo('partUnit',$event)">
                
                       
                
            </div>
            
            <div class="col-lg-3 col-md-9 col-sm-12" >
                <label for="" class="required form-label">Gos Parça</label>
                <app-shared-selectwo
               
                        [lists]="partList"
                        [id]="'partList'"
                        (selectedItem)="selectedTwo('parts',$event)" 
                        [name]="'Gos Parça'" 
              
                        [showAll]=false
                        [extendSearch]="true"
                        (searchItem)="searchPart($event)"
                        [searchItemLength]="4"
                     
                        ></app-shared-selectwo>
                       
                
            </div>
            <div class="col-lg-2 col-md-9 col-sm-12"  >
                <label for="" class="required form-label">Parça Ekle</label>
                <input type="button" value="Parça Ekle" class="btn btn-primary btn-sm" (click)="setPartEquivalentList()">
                
                       
                
            </div>
            
            <div class="table-responsive" *ngIf="partEquivalentList.length > 0">
                <table class="table">
                      
                    <thead>
                        <tr class="border-bottom fs-6 fw-bolder text-muted text-uppercase">
                            <th class="min-w-175px pb-9">Parça</th>
                            <th class="min-w-150px pb-9">
                                Yetkili Orjinal 
                            </th>
                            <th class="min-w-150px pb-9"> 
                                Orjinal
                            </th>
                            <th class="min-w-100px pe-lg-6 pb-9 " >
                                Logosuz Orjinal 
                            </th>
            
                            <th class="min-w-80px pb-9 "> 
                                Eşdeğer
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="fw-bolder text-gray-700 fs-5 "  
                        *ngFor="let item of partEquivalentList">
                        <td class="d-flex align-items-center pt-11">
                               
                            {{item.selectedGosPart.name}} {{item.offical_original.id}}
                        </td>
                        <td class="pt-11 px-0 text-left">
                            
                                <span class="ps-3">
                                    {{item.offical_original.price | number:'1.0-2'}} {{item.offical_original.price_unit}} 
                                </span>
            
                        </td>
                        <td class="pt-11 px-0 text-left">
                            <input 
                                *ngIf="item.original"
                                class="form-check-input" 
                               type="radio" name="part_{{item.selectedGosPart.id}}"
                               (click)="addPiece(item.part_id,item.selectedGosPart.id,item.original.id,'original')"
            
                                id="original_{{item.offical_original.id}}">
                                <span class="ps-3">
                             {{item.original?.price | number:'1.0-2'}} {{item.original?.price_unit}} 
                                </span>
                        </td>    
                           
                        <td class="pt-11 text-left">
                            <input 
                            *ngIf="item.without_logo"
                            class="form-check-input" 
                            type="radio" name="part_{{item.selectedGosPart.id}}"
                            (click)="addPiece(item.part_id,item.selectedGosPart.id,item.without_logo.id,'without_logo')"
                            id="logosuz_{{item.offical_original.id}}">
                            <span class="ps-3">
                                {{item.without_logo?.price | number:'1.0-2'}} {{item.without_logo?.price_unit}} 
                            </span>
                        </td>
                     
                        <td class="pt-11 px-0 text-left">
                        
                            <input class="form-check-input" 
                            *ngIf="item.equivalent"
                             type="radio" name="part_{{item.selectedGosPart.id}}"
                             (click)="addPiece(item.part_id,item.selectedGosPart.id,item.equivalent.id,'equivalent')"
                            id="esdeger_1_{{item.offical_original.id}}">
                                <span class="ps-3">
                                    {{item.equivalent?.price | number:'1.0-2'}} {{item.equivalent?.price_unit}} 
                                </span>
                        </td>
                            
                          
                         
                        </tr>
                        
                    </tbody>
                </table>
            </div>
            
            
        </div>
        
    </div>
</div>

<div class="card card-flush py-4">
    <div class="card-body pt-0">
        <div class="row">
            <div class="d-flex flex-column gap-10 mt-5">
                <div class="w-100">
                    <label class="d-flex align-items-center form-label mb-5">Seçili Ürünler
                        <i class="fas fa-exclamation-circle ms-2 fs-7"
                            aria-label="Monthly billing will be based on your account plan"
                            title="Birden fazla seçim yapabilirsiniz."></i>
                    </label>
                    <div class="w-100" style="overflow: hidden;
                    overflow-x: scroll;">
                        
                    
                        <div class="card card-flush py-4 flex-row-fluid" >
                            <div class="card-header">
                                <div class="card-title">
                                    <h4>Ürün Bilgileri</h4>
                                </div>
                                <div class="card-tool">
            
                                </div>
                            </div>
            
                            
                          
                            <!--begin::Card body-->
                            <div class="card-body pt-0 ">
                                <div class="table-responsive">
                                    <table class="table">
                                      
                                        <thead>
                                            <tr class="border-bottom fs-6 fw-bolder text-muted text-uppercase">
                                                <th class="min-w-175px pb-9">Parça Adı</th>
                                                <th class="min-w-175px pb-9">Parça Türü</th>
                                                <th class="min-w-175px pb-9">Adet</th>
                                                <th class="min-w-175px pb-9">Ücret</th>
                                                <th class="min-w-175px pb-9">İşlemler</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="fw-bolder text-gray-700 fs-5 "  
                                            *ngFor="let item of selectedParts">
                                                <td>{{item.selectedGosPart_name}}</td>
                                                <td>{{item.selectedEquivalent_partTypeName}}</td>
                                                <td>{{item.selectedEquivalent_partUnit}} {{item.selectedEquivalent_partUnitType}}</td>
                                                <td>{{item.selectedEquivalent_priceTotal | number:'1.0-2'}} {{item.selectedEquivalent_priceUnitName}}</td>
                                                <td> <button class="btn btn-primary btn-sm" 
                                                   
                                                    (click)="removePiece(item.selectedGosPart_id,item.selectedEquivalent_id)">
                                                     <i class="fas fa-window-close"></i> Kaldır </button> 
                                                    
                                                    </td>
                                            </tr>

                                            
                                        </tbody>
                                        <tfoot>
                                            <tr class="border fs-6 fw-bolder text-muted text-uppercase " >
                                                <td colspan="4" class="text-end">
                                                    <span class="text-dark fw-bolder d-block">Ara Toplam</span>
                                                    </td>
                                                <td class="text-dark fw-bolder text-hover-primary fs-6">{{subTotal}} {{activeCurrency?.code}}</td>
                                            </tr>
                                            <tr class="border fs-6 fw-bolder text-muted text-uppercase " >
                                                <td colspan="4" class="text-end">
                                                    <span class="text-dark fw-bolder d-block">Kdv</span>
                                                    </td>
                                                <td class="text-dark fw-bolder text-hover-primary fs-6">{{difference}} {{activeCurrency?.code}}</td>
                                            </tr>
                                            <tr class="border border-bottom fs-6 fw-bolder text-muted text-uppercase " >
                                                <td colspan="4" class="text-end">
                                                    <span class="text-dark fw-bolder d-block">Genel Toplam</span>
                                                    </td>
                                                <td class="text-dark fw-bolder text-hover-primary fs-6">{{grandTotal}} {{activeCurrency?.code}}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
