import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface OrderStatusLogs {
  id: Number;
  order_id: Number;
  old_status_id?: Number;
  status_id?:Number;
  user_id?:Number;
}

interface ResponseArray {
  order_status_logs: OrderStatusLogs[];
}

interface ResponseSingle {
    order_status_logs: OrderStatusLogs
}

@Injectable()
export class ORDER_STATUS_LOGS_ALL extends Query<ResponseArray> {
  override document = gql`
    query ORDER_STATUS_LOGS_ALL($where: order_status_logs_bool_exp){
      order_status(where: $where) {
        id
        order_id
        old_status_id
        status_id
        user_id
      }
    }
  `;
}

@Injectable()
export class ORDER_STATUS_LOGS_ID extends Query<ResponseSingle> {
  override document = gql`
    query ORDER_STATUS_LOGS_ID($id: Int!){
      order_status_logs_by_pk(id: $id) {
        id
        order_id
        old_status_id
        status_id
        user_id
      }
    }
  `;
}

@Injectable()
export class ORDER_STATUS_LOG_CREATE extends Mutation<ResponseSingle> {
  // connected_part_id - int | nullable
  override document = gql`
    mutation ORDER_STATUS_LOG_CREATE(
      $order_id: bigint!, 
      $old_status_id: Int,
      $status_id: Int,
      $user_id: bigint
      ) {
      insert_order_status_logs(objects: {
        order_id: $order_id, 
        old_status_id: $old_status_id
        status_id: $status_id
        user_id: $user_id
      }) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

