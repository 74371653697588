<div class="collapse show">
    <form class="form" action="#" [formGroup]="addressForm" (ngSubmit)="onSubmit()">
        <div class="d-flex flex-column   pe-7">
            <div class="fv-row mb-7">
                <label class="required fw-bold fs-6 mb-2">Adres Başlığı</label>
                <input type="text" name="address_title" class="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Adres Başlığı" formControlName="address_name" />
            </div>
            
            <div class="fv-row mb-7">
                <app-shared-countries [id]="countryInputId" [selectedCountry]="selectedCountryId" (country_id)="changeGeoZone('country',$event)" ></app-shared-countries>
            </div>
            <div class="fv-row mb-7">
                <app-shared-cities [selectedCity]="selectedCityId" [cityInputId]="cityInputId"  [event]="selectedCountryId" (city_id)="changeGeoZone('city',$event)"></app-shared-cities>
            </div>
            <div class="fv-row mb-7">
                <app-shared-districts [selectedDistrictId]="selectedDistrictId" [districtInputId]="districtInputId"  [event]="selectedCityId" (district_id)="changeGeoZone('district',$event)"></app-shared-districts>
            </div>
            <div class="fv-row mb-7">
                <app-shared-towns [selectedTownId]="selectedTownId" [townInputId]="townInputId" [event]="selectedDistrictId" (town_id)="changeGeoZone('town',$event)"></app-shared-towns>            

            </div>
            <div class="fv-row mb-7">
                <label class="required fw-bold fs-6 mb-2">Adres</label>
                <textarea class="form-control" name="address" formControlName="address"></textarea>
            </div>
        </div>
        <div class="row mb-7">
            <div class="col-lg-12 fv-row fv-plugins-icon-container" style="clear: both;">
                <div class="form-check form-switch form-check-custom form-check-solid" style="text-align: right; float: left;">
                    <input type="checkbox" class="form-check-input" name="primary" formControlName="is_primary">
                    <label class="form-check-label fw-bold text-gray-400 ms-3">Primary</label>
                </div>
          
            </div>
        </div>
        <div class="text-center pt-15">
            <button type="submit" class="btn btn-primary">
                <span class="indicator-label">Kaydet</span>
                <span class="indicator-progress">Please wait...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
            </button>
        </div>
    </form>
</div>