<!--begin::Post-->
<div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">

        
        <div class="row gy-5 g-xl-8" >
            
            <!--begin::Col-->
            <div class="col-xl-12 mb-5">
                <!--begin::Mixed Widget 2-->
                <div class="card card-xl-stretch">
                  
                    <!--begin::Body-->
                    <div class="card-body p-0">
                        
                        <!--begin::Stats-->
                        <div class="card position-relative hero-section">
                            <!--begin::Row-->
                            <div class="row g-0" style="background-color: #F3F3F3; background-image:url(assets/images/background-lines.svg); background-repeat: no-repeat; background-position-x: right; background-position-y: bottom;">
                                <!--begin::Col-->
                                <div class="col px-6 py-8 rounded-2 me-7 mb-7" style=" display: table;">
                                    <h1 class="hero-content" style="font-weight: 600; color: #004422; font-size:46px; display:table-cell; vertical-align:middle">GARANTİLİ SERVİSLER</h1>
                                </div>
                                <!--end::Col-->
                                <!--begin::Col-->
                                <div class="col-xl-6 px-6 py-8 rounded-2 mb-7" style="">
                                    <img src="assets/images/garantili-oto-servis-38-750x450.png" style=" width: 100%;" alt="">
                               
                                </div>
                                <!--end::Col-->
                            </div>
                            <!--end::Row-->
                           
                        </div>
                        <!--end::Stats-->
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Mixed Widget 2-->
            </div>
            <!--end::Col-->
           
           
        </div>
        <!--end::Row-->

        <div class="row gy-5 g-xl-8">
           
            <!--begin::Col-->
            <div class="col-xl-12 col-xxl-12 mb-5 mb-xl-10">
                <!--begin::Table Widget 3-->
                <div class="card card-flush h-xl-100">
                    <!--begin::Card header-->
                    <div class="card-header py-5">
                        <!--begin::Tabs-->
                        <div class="card-title pt-3 mb-0 gap-4 gap-lg-10 gap-xl-15 nav nav-tabs border-bottom-0" data-kt-table-widget-3="tabs_nav">
                            <h2 style="font-weight: 600;
                            font-size: 32px;
                            color: #004422;
                            margin: 0 0 30px; ">“Garantili hizmet hakkınız”</h2>
                        </div>
                        <!--end::Tabs-->
                    
                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body pt-1">
                        <!--begin::Sort & Filter-->
                        <div class="d-flex flex-stack flex-wrap gap-4">
                            <p style="font-size:16px">Garantili Oto Servis’in “Kalite Standartları” değerlendirmesinden geçen, TSE belgeli üye oto servislere sayfamızdan zahmetsizce ulaşabilirsiniz. Üye servisleri, bulunduğunuz konuma göre arayabilir ve sayfamızda yayınlanan iletişim bilgileri vasıtasıyla aradığınız hizmete kolayca erişebilirsiniz.</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!--begin::Row-->
        <div class="row gy-5 g-xl-8">
           
            <!--begin::Col-->
            <div class="col-xl-12 col-xxl-12 mb-5 mb-xl-10">
                <!--begin::Table Widget 3-->
                <div class="card card-flush h-xl-100">
                    <!--begin::Card header-->
                    <div class="card-header py-7">
                        <!--begin::Tabs-->
                        <div class="card-title pt-3 mb-0 gap-4 gap-lg-10 gap-xl-15 nav nav-tabs border-bottom-0" data-kt-table-widget-3="tabs_nav">
                            <!--begin::Tab item-->
                            <div class="fs-4 fw-bolder pb-3 border-bottom border-3 border-primary cursor-pointer" data-kt-table-widget-3="tab" data-kt-table-widget-3-value="Show All">Servisler ({{dataCount}})</div>
                            <!--end::Tab item-->
                        </div>
                        <!--end::Tabs-->

                        <div class="card-toolbar">
                            <app-shared-filter>
                                <div class="mb-10">
                                    <!--begin::Label-->
                                    <label class="form-label fw-bold"></label>
                                    <!--end::Label-->
                                    <div class="d-flex align-items-center mb-5 fw-bolder filter-item">

                                        <app-shared-selectwo 
                                        [lists]="vehicleBrandList"
                                        (selectedItem)="filterResults($event,'brand')"
                                        [name]="'Marka'"
                                        [id]="'brandFilter2'"
                                        style="width: 100%;"
                                        ></app-shared-selectwo>
                                        
                                    </div>
                                     <!--begin::Status-->
                                     <div class="d-flex align-items-center mb-5 fw-bolder  filter-item">
                                        <!--begin::Select-->
                                        <app-shared-selectwo 
                                        [lists]="cityList"
                                        (selectedItem)="filterResults($event,'city')"
                                        [name]="'Şehir'"
                                        [id]="'cityFilter2'"
                                        style="width: 100%;"
                                        ></app-shared-selectwo>
                                        <!--end::Select-->
                                    </div>
                                    <!--begin::Status-->
                                    <!--begin::Budget-->
                                    <div class="d-flex align-items-center mb-5 fw-bolder  filter-item">

                                        <!--begin::Select-->
                                        <app-shared-selectwo 
                                        [lists]="[{id:'point',name:'Puan'},{id:'city',name:'Şehir'},{id:'sname',name:'Servis Ünvanı'}]"
                                        (selectedItem)="filterResults($event,'order')"
                                        [name]="'Sırala'"
                                        [id]="'sortOrder2'"
                                        [showAll]="false"
                                        style="width: 100%;"
                                        ></app-shared-selectwo>
                                        <!--end::Select-->
                                       
                                    </div>
                                
                                    
                                </div>
                            </app-shared-filter>
                        </div>
                        
                    
                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body pt-1">
                      
                        <!--begin::Seprator-->
                        <div class="separator separator-dashed my-5"></div>
                        <!--end::Seprator-->
                        <!--begin::Table-->
                        <div class="table-responsive">
                            <table id="kt_widget_table_3" class="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3" data-kt-table-widget-3="all">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>Genel Uzman Markaları</th>
                                        <th>Şehir / İlçe</th>
                                        <th>Genel Ortalama Puanı</th>
                                        <th>Yorum</th>
                                        <th>Detaylar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of companyList">
                                        <td class="brandLogo">
                                        
                                                <!--begin::Item-->
                                                <div class="d-flex mb-7 img-container">
                                                    <!--begin::Symbol-->
                                                    <div class="symbol symbol-60px symbol-2by3 flex-shrink-0 me-4">
                                                        <img 
                                                      
                                                        src="https://cdn.destechhasar.com/garantili-oto-servis/garantili-oto-servis-logo.png" 
                                                        class="mw-100" style="width: 80px; height: 70px;" alt="" />


                                                    </div>
                                                    <!--end::Symbol-->
                                                 
                                                </div>
                                                <!--end::Item-->
                                                
                                        </td>
                                        <td class="brandName">
                                            <!--begin::Item-->
                                            <div class="d-flex mb-7 img-container">
                                               
                                                <!--begin::Section-->
                                                <div class="d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1">
                                                    <!--begin::Title-->
                                                    <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                                                        
                                                        <span class="text-gray-400 fw-bold fs-7 my-1" >
                                                            <a [routerLink]=" getUrl(item.name,item.id)" routerLinkActive="router-link-active"  class="fs-5 text-gray-800 text-hover-primary fw-bolder" style="margin-top: 0px;">{{item.name}}</a>
                                                            <span style="margin-top: 10px; margin-bottom: 10px; display: block;" *ngIf="item.evalutaion_points != undefined">
                                                                <i class="fa fa-star me-1 text-warning fs-5" *ngIf="item.evalutaion_points > 0"></i>
                                                                <i class="fa fa-star me-1 text-warning fs-5" *ngIf="item.evalutaion_points > 1"></i>
                                                                <i class="fa fa-star me-1 text-warning fs-5" *ngIf="item.evalutaion_points > 2"></i>
                                                                <i class="fa fa-star me-1 text-warning fs-5" *ngIf="item.evalutaion_points > 3"></i>
                                                                <i class="fa fa-star me-1 text-warning fs-5" *ngIf="item.evalutaion_points > 4"></i>
                                                                <span class="text-gray-800 fw-bolder">
                                                                    </span>
                                                                
                                                            </span>
                                                                
                                                            <div 
                                                            class="gap-2 mb-2" 
                                                            [ngClass]="{'no-border': item.companys_franchises === undefined}"
                                                            style="margin-top: 10px; margin-bottom: 0px !important;"  
                                                            *ngIf="item.company_vehicle_brands != undefined">
                                                            
                                                                <a href="#"  *ngFor="let services of item.company_vehicle_brands!">
                                                                    <img src="https://cdn.destechhasar.com/garantili-oto-servis/{{services.vehicle_brand.logo_url}}" class="w-50px" alt="" />
                                                                </a>
                                                        
                                                                
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <!--end::Title-->
                                                
                                                </div>
                                                <!--end::Section-->
                                            </div>
                                            <!--end::Item-->
                                        </td>
                                        <td [ngClass]="{'no-border': item.companys_franchises === undefined}" class="franchises">
                                            <!--begin::Icons-->
                                            <div class="d-flex gap-2 mb-2" *ngIf="item.companys_franchises">
                                                <div class="symbol-group symbol-hover mb-1">
                                                    <!--begin::Member-->
                                                    <div class="symbol symbol-circle symbol-50px "  *ngFor="let services of item.companys_franchises!">
                                                        <img *ngIf="services.franchise_network.logo !== null" src="https://cdn.destechhasar.com/garantili-oto-servis/{{services.franchise_network.logo}}" alt="" />
                                                        <img *ngIf="services.franchise_network.logo === null"  src="assets/images/gos-no-image.jpg" alt="" />
                                                    </div>
                                                    <!--end::Member-->
                                                   
                                                </div>
                                                
                                        
                                            </div>
                                            <!--end::Icons-->
                                        </td>
                                       
                                    
                                        <td>
                                            {{item.City?.name}} / {{ item.district?.name }}
                                        </td>
                                        <td>
                                            <div class="badge badge-light ms-auto">  {{item.evalutaion_points}} </div> 
                                          
                                        </td>
                                        <td>
                                           <div class="badge badge-light ms-auto">0</div> 
                                        </td>

                                        <td class="min-w-125px" [ngClass]="{'no-border': item.companys_franchises === undefined}">
                                            <!--begin::Icons-->
                                            <div class="d-flex gap-7 mb-2">
                                                <a href="tel:+496170961709">
                                                    <i style="font-size: 24px;" class="fas fa-mobile-alt"></i>
                                                </a>
                                                <a [routerLink]=" getUrl(item.name,item.id)">
                                                    <i class="fas fa-caret-square-right" style="font-size: 25px;"></i>
                                                </a>
                                                <a href="https://www.google.com/maps/search/?api=1&query={{item.lat}}%2C{{item.lon}}" *ngIf="item.lat !== null && item.lon !== null" target="_blank">
                                                    <i class="fas fa-map-marked-alt" style="font-size: 20px;"></i>
                                                </a>
                                                
                                            </div>
                                            <!--end::Icons-->
                                            <div class="fs-7 fw-bolder text-muted" style="display: none;">Hizmet Verilen Markalar</div>
                                        </td>
                                        
                                    
                                    </tr>
                                
                                </tbody>
                            </table>
                            <!--end::Table-->
                        </div>
                    </div>
                    <!--end::Card body-->
                </div>
                <!--end::Table Widget 3-->
            </div>
            <!--end::Col-->

        </div>
        <!--end::Row-->
    
    </div>
    <!--end::Container-->
</div>
<!--end::Post-->