import { Injectable } from '@angular/core';
import { gql, Mutation, Query } from 'apollo-angular';

export interface Garage {
  id: number;
  name: String;
  user_id: bigint,
  vehicles: any
}

interface ResponseArray {
  garages: Garage[];
}

interface ResponseSingle {
  garages_by_pk: Garage
}

@Injectable()
export class GARAGES_ALL extends Query<ResponseArray> {
  override document = gql`
  query GARAGES_ALL($userId: Int!) {
    garages(where: {user_id: {_eq: $userId}}, order_by: {name: asc}) {
      id
      name
      user_id
      vehicles {
        id
        plate
        brand_id
        model_id
        type_id
        model_year_id
        colour_id
        traffic_release_date
        engine_no
        chassis_no
        note
        colour_code_id
        colour_type_id
        tire_id
        gear_type_id
        fuel_type_id
        last_km
        model_type_id
        license_name,
        license_surname,
        vehicle_model_types{
          id
          name
        }
        years {
          id
          year_id
        }
        vehicle_brand {
          id
          name
        }
        vehicle_model {
          id
          name
          gos_labour_price
          brand_labour_price
        }
      }
    }
  }`;
}

@Injectable()
export class GARAGES_FIND extends Query<ResponseArray> {
  override document = gql`
  query GARAGES_FIND($where:garages_bool_exp) {
    garages(where:$where) {
      id
      name
      user_id
      vehicles {
        id
        model_type_id
        plate
      }
    }
  }`
}

@Injectable()
export class GARAGE_CREATE extends Mutation<any> {
  override document = gql`
  mutation GARAGE_CREATE($name: String!, $user_id: Int!,$active: Boolean) {
    insert_garages(objects: {name: $name, user_id: $user_id, active: $active}) {
      affected_rows
      returning {
        id
      }
    }
  }`;
}