import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OrderCards, ORDER_CARDS_UPDATE, ORDER_CARD_PAYMENT_SUBS } from 'src/app/graphql/order_cards.graphql';
import { numberFormatStr } from 'src/app/helpers/slugify';
import { AlertifyService } from 'src/app/services/alertify.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ORDER_UPDATE } from 'src/app/graphql/order.graphql';
import { OzanpaySuccessfuls, OZANPAY_SUCCESSFULS_SUBS } from 'src/app/graphql/ozanpay_successful.graphql';
import { OzanpayResponse, OZANPAY_RESPONSE_SUBS } from 'src/app/graphql/ozanpay_response.graphql';

@Component({
  selector: 'app-appointment-v3-odeme',
  templateUrl: './odeme-v3.component.html',
  styles: [
  ],
  styleUrls:['./odeme-v3.component.css'],
  providers:[
    ORDER_CARDS_UPDATE,
    ORDER_CARD_PAYMENT_SUBS,
    ORDER_UPDATE,
    OZANPAY_SUCCESSFULS_SUBS,
    OZANPAY_RESPONSE_SUBS
  ]
})
export class OdemeV3Component implements OnInit {

 
  @Input() orderCardId: bigint | undefined;
  @Input() priceInfo: any;
  @Input() userForm: any;
  @Input() userId: Number | undefined;
  @Input() firstnameInput: string | undefined;
  @Input() lastnameInput: string | undefined;
  @Input() emailInput: string | undefined;
  @Input() phoneInput: string | undefined;
  @Input() paymentType: Number | undefined;
  @Input() iframeToken: string | null = null;
  @Output() invoiceAddressFormEmitter = new EventEmitter<any>();
  @Output() paymentEmitter = new EventEmitter<any>();
  @Output() paymentTypeEmitter = new EventEmitter<any>();
 

//  public denizbankUrl = environment.denizbankUrl;
 // public denizbankUrl = "https://api.garantiliotoservis.com/payment-response-test";

  public shopCodeLive = environment.denizShopCode;
  public userCodeLive = environment.denizUserCode;
  public posPass = "";
  public threedKey = environment.deniz3DKey;
  public showPosError = false;
  public posErrorMessage = "";
  public paymentAttempt:Number = 0;
  public paymentErrorMessage = "";
  public inputCardNo:any;
  public filteredCardNo:any;
  public filteredExpiryDate:any;
  public inputExpiryDate: any;
  public momentOrderId:any;
  public expriceMonth:Number = 0;
  public expiryYear:Number = 0;
  public paymentMessage: String = "";

  public selectedPaymentType:number = 0;
 
  /** payment variables */
  public InstallmentCount = "";         //taksit sayisi

  public hidePaymentButton= false;
  public isPayed: Boolean = false;
  public rnd: any;
  public hash : String | undefined;
  public subOrderCards: OrderCards | undefined;
  public showPosMessage: boolean= false;
  public paymentFailMessage :String= "";
  public hideAppointmentButton= false;


  public showPaymentMessage: boolean = false;

  public selectedInstallment = 1;
  public filteredData:any = [];
  public installmentInfo: any;


  public selectedTab = "individual";
  public token = "";

  paymentFormGroup = new FormGroup({
    Expire: new FormControl('', [Validators.required]),
    Pan: new FormControl('', [Validators.required,Validators.maxLength(11),Validators.minLength(11)]),
    Cvv2: new FormControl('', [Validators.required,Validators.maxLength(3),Validators.minLength(3)]),
    CardType: new FormControl('', [Validators.required]),
    ShopCode: new FormControl('', [Validators.required]),
    PurchAmount: new FormControl('', [Validators.required]),
    Currency: new FormControl('', [Validators.required]),
    OrderId: new FormControl('', [Validators.required]),
    OkUrl: new FormControl('', [Validators.required]),
    FailUrl: new FormControl('', [Validators.required]),
    Rnd: new FormControl('', [Validators.required]),
    Hash: new FormControl('', [Validators.required]),
    TxnType: new FormControl('', [Validators.required]),
    InstallmentCount: new FormControl(''),
    SecureType: new FormControl('', [Validators.required]),
    Lang: new FormControl('', [Validators.required])

  });


  invoiceAddressForm = new FormGroup({
    customer_name: new FormControl('',[Validators.required]),
    customer_surname: new FormControl('',[Validators.required]),
    country_id: new FormControl(1,[Validators.required]),
    city_id: new FormControl(null,[Validators.required]),
    district_id: new FormControl(null,[Validators.required]),
    town_id: new FormControl(null,[Validators.required]),
    address: new FormControl('',[Validators.required]),
    identity_no: new FormControl(null,Validators.required),
    company_name: new FormControl(null),
    tax_administration: new FormControl(null),
    tax_number: new FormControl(null),
    invoice_type: new FormControl(3,Validators.required),
    customer_invoice_type: new FormControl(1,Validators.required),
    gsm_number: new FormControl(null, [Validators.required]),
    tel_number: new FormControl(null),
    user_id: new FormControl(null, [Validators.required]),
    customer_email: new FormControl(null, [Validators.required]),

  })

  sozlesmeForm = new FormGroup({
    check_gizlilik: new FormControl(1,[Validators.required]),
    check_riza: new FormControl(1,[Validators.required]),
    check_aydinlatma: new FormControl(1,[Validators.required]),
    check_sozlesme: new FormControl(1,[Validators.required]),
    e_fatura: new FormControl(1,[Validators.required]),

  })


  public cardName: String | undefined;
  public cardExpire: String | undefined;
  public ccv2: String | undefined;
  public turnCard = false;

  /** invoice variables */
  public invoiceInfo:any = [];

  /** user variavles */
  public userInfo: any;
  public selectedAddressId:number | undefined;

  public ozanpaySuccessFuls: OzanpaySuccessfuls[] | undefined;
  public ozanpayResonse: OzanpayResponse[] | undefined;
  public installmentAmount = 0;


  @ViewChild('paymentForm') 
  paymentFormElement!: ElementRef; 

  constructor(
    private updateOrderCards: ORDER_CARDS_UPDATE,
    private alertifyService: AlertifyService,
    private http: HttpClient,
    private ozanpaySuccessfulsSubs: OZANPAY_SUCCESSFULS_SUBS,
    private ozanpayResponseSubs: OZANPAY_RESPONSE_SUBS,

  ) { 
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {

    this.invoiceAddressForm.patchValue({
      user_id:this.userId,
      customer_name: this.firstnameInput,
      customer_surname: this.lastnameInput,
      tel_number: this.phoneInput,
      gsm_number: this.phoneInput,
      customer_email: this.emailInput})


      this.setInstallment(1,this.priceInfo.generalTotal,true);

  }

  ngOnChanges(){
    
  }

  changeTab(tab:string){
    this.selectedTab = tab;
 
    if(tab == "institutional"){
      this.invoiceAddressForm.patchValue({customer_invoice_type:2});
       this.invoiceAddressForm.controls["identity_no"].setValidators([]);
       this.invoiceAddressForm.controls["identity_no"].updateValueAndValidity();
       this.invoiceAddressForm.controls["tax_number"].setValidators([Validators.required]);
       this.invoiceAddressForm.controls["tax_number"].updateValueAndValidity();
    }else{
      this.invoiceAddressForm.patchValue({customer_invoice_type:1});
      this.invoiceAddressForm.controls["tax_number"].setValidators([]);
      this.invoiceAddressForm.controls["tax_number"].updateValueAndValidity();
      this.invoiceAddressForm.controls["identity_no"].setValidators([Validators.required]);
      this.invoiceAddressForm.controls["identity_no"].updateValueAndValidity();
      
    }

    this.emitInvoiceAddressForm();



  }

  filterFormatCardNo($event) {
    this.turnCard = false;
    this.inputCardNo = $event.target.value.replace(" ","");

    let value = $event.target.value.replace(/\s/g, '').replace(/(.{4})/g, '$1 ').trim();

    this.filteredCardNo = value;

    if(this.inputCardNo.length == 16 && this.orderCardId){
      this.http.post(environment.laravelApiEndpoint+"payment/check-installment",{
        "card_number": this.inputCardNo,
        "order_card_id": this.orderCardId,
        "type": "order_card"
    },
    { headers: { token: `${localStorage.getItem('access_token')}` } }).subscribe(async (resp:any) => {

        if(!resp.status){
          this.alertifyService.error(resp.message);
        }

        if(resp.status){
          if(resp && resp.result && resp.result.return_data){
            this.installmentInfo = resp.result.return_data;

            if(this.installmentInfo.installmentRateList){
              const interestRates = [3, 6]; // Filtrelemek istediğiniz interestRate değerleri
              this.filteredData = [];
              await Object.keys(this.installmentInfo.installmentRateList).forEach(bank => {
                const bankInstallments = this.installmentInfo.installmentRateList[bank];
                interestRates.forEach(count => {
                  const installmentDetail = bankInstallments[count];
                  
                  if (installmentDetail) {
                    installmentDetail.installmentCount = count;
                    installmentDetail.baseAmount = ( installmentDetail.baseAmount / 100 );
                    installmentDetail.buyerCostAmount = ( installmentDetail.buyerCostAmount / 100 );
                    installmentDetail.buyerCostInstallmentPrice = ( installmentDetail.buyerCostInstallmentPrice / 100 );
                    installmentDetail.installmentPrice = ( installmentDetail.installmentPrice / 100 );
                    this.filteredData.push(installmentDetail);
                  }
                });

              });
             
           /*  
              debugger;

              const filteredItemsUsingIndex = await this.findItemsWithInterestRatesUsingIndex(this.installmentInfo.installmentRateList, [3, 6]);
              debugger;
              this.filteredData = filteredItemsUsingIndex;
            

             */
            }
           
           // this.installmentInfo.installmentRateList = Object.values(this.installmentInfo.installmentRateList);

          // interestRate'e göre artan sırayla sırala
         // this.installmentInfo.installmentRateList = this.installmentInfo.installmentRateList.sort((a:any, b:any) => parseInt(a.interestRate) - parseInt(b.interestRate));


//this.installmentInfo.installmentRateList = installmentListArray;
          }
        }
       
      })
    }

   
}

filterFormatExpirationDate($event) {
  
  
  let exp =  $event.target.value.split('/');

  this.expriceMonth = exp[0];
  this.expiryYear = exp[1];

  this.inputExpiryDate = $event.target.value.replace("/","").trim();
  this.filteredExpiryDate = this.inputExpiryDate.replace(/\D/g, ''); // Sadece sayısal karakterleri alın
 
  if (this.filteredExpiryDate.length > 2) {
    this.filteredExpiryDate = this.filteredExpiryDate.slice(0, 2) + '/' + this.filteredExpiryDate.slice(2);
    this.filteredExpiryDate = this.filteredExpiryDate.slice(0,5);
  }
 
}

toNumberFormat(number: any) {
return numberFormatStr(number);
}

setCardInfo(type:string,$event:any){
  switch(type){
    case "cardName":
      this.cardName = $event.target.value;
      this.turnCard = false;
    break;

    case "expire":
      this.cardExpire = $event.target.value;
      this.turnCard = false;
    break;

    case "ccv":
      this.ccv2 = $event.target.value;
      this.turnCard = true;
    break;
  }
}

setUserForm(type:string,$event:any){
  switch(type){
    case "firstname":
      this.invoiceAddressForm.patchValue({firstname:$event.target.value});
      if( this.invoiceAddressForm.value.firstname == "" || this.invoiceAddressForm.value.firstname.length == 0){
        this.invoiceAddressForm.patchValue({firstname: $event.target.value});
      }
      
  
      this.updateOrderCards.mutate({id:this.orderCardId,changes:{customer_firstname: $event.target.value}}).subscribe(response=>{
        if(response.errors){
          this.alertifyService.error(response.errors[0].message);
        }
      });
    break;
    case "lastname":
      this.invoiceAddressForm.patchValue({lastname:$event.target.value});
      if( this.invoiceAddressForm.value.lastname == "" || this.invoiceAddressForm.value.lastname.length == 0){
        this.invoiceAddressForm.patchValue({lastname: $event.target.value});
      }

  
      this.updateOrderCards.mutate({id:this.orderCardId,changes:{customer_lastname: $event.target.value}}).subscribe(response=>{
        if(response.errors){
          this.alertifyService.error(response.errors[0].message);
        }
      });
    break;
    case "gsm":

        this.invoiceAddressForm.patchValue({phone: $event?.target.value});
        this.invoiceAddressForm.patchValue({
          gsm_number:  $event?.target.value,
          tel_number:  $event?.target.value
        });
      
  
        this.updateOrderCards.mutate({id:this.orderCardId,changes:{customer_gsm: $event.target.value}}).subscribe(response=>{
          if(response.errors){
            this.alertifyService.error(response.errors[0].message);
          }
        });

       

     
  
      
    break;
  }
}

setInvoiceInfo(type:string,$event:any,canEmpty:boolean=false){
   
  switch(type){

    case "customer_invoice_type": 
      this.invoiceAddressForm.patchValue({customer_invoice_type: $event.target.value});
    
      if($event.target.value == 2){
        this.invoiceAddressForm.controls["firstname"].setValidators([]);
        this.invoiceAddressForm.controls["firstname"].updateValueAndValidity();
        this.invoiceAddressForm.controls["lastname"].setValidators([]);
        this.invoiceAddressForm.controls["lastname"].updateValueAndValidity();
        this.invoiceAddressForm.controls["identity_no"].setValidators([]);
        this.invoiceAddressForm.controls["identity_no"].updateValueAndValidity();
        this.invoiceAddressForm.controls["company_name"].setValidators([Validators.required]);
        this.invoiceAddressForm.controls["company_name"].updateValueAndValidity();
        this.invoiceAddressForm.controls["tax_number"].setValidators([Validators.required]);
        this.invoiceAddressForm.controls["tax_number"].updateValueAndValidity();
        this.invoiceAddressForm.controls["tax_administration"].setValidators([Validators.required]);
        this.invoiceAddressForm.controls["tax_administration"].updateValueAndValidity();

     

        this.invoiceAddressForm.patchValue({
          firstname: null,
          lastname: null,
          identity_no: null
        });

        this.emitInvoiceAddressForm();
      }else{
        this.invoiceAddressForm.controls["firstname"].setValidators([Validators.required]);
        this.invoiceAddressForm.controls["firstname"].updateValueAndValidity();;
        this.invoiceAddressForm.controls["lastname"].setValidators([Validators.required]);
        this.invoiceAddressForm.controls["lastname"].updateValueAndValidity();;
        this.invoiceAddressForm.controls["identity_no"].setValidators([Validators.required]);
        this.invoiceAddressForm.controls["identity_no"].updateValueAndValidity();
        this.invoiceAddressForm.controls["company_name"].setValidators([]);
        this.invoiceAddressForm.controls["company_name"].updateValueAndValidity();
        this.invoiceAddressForm.controls["tax_number"].setValidators([]);
        this.invoiceAddressForm.controls["tax_number"].updateValueAndValidity();
        this.invoiceAddressForm.controls["tax_administration"].setValidators([]);
        this.invoiceAddressForm.controls["tax_administration"].updateValueAndValidity();
        
       

        this.invoiceAddressForm.patchValue({
          company_name: null,
          tax_number: 0,
          tax_administration: null
        });

        this.emitInvoiceAddressForm();
      }
    break;  

    case "email":
   
      this.invoiceInfo.email = $event.target.value;
      this.invoiceAddressForm.patchValue({customer_email:$event.target.value});
      this.emitInvoiceAddressForm();
        
     
    break;
    case 'company_name':
      if(canEmpty && $event == ''){
        this.invoiceInfo.company_name = '';
        this.emitInvoiceAddressForm();
      }else{
        let company_name = $event.target.value;
        if(company_name.length < 3){
          this.alertifyService.error("İsim en az 3 karakter olmalıdır");
          //this.invoiceInfo.invoice_name = "";
          this.emitInvoiceAddressForm();
          return false;
        }else{
          this.invoiceInfo.invoice_name = name;
          this.emitInvoiceAddressForm();
        }
      }
    break;
    case "invoice_name":
      if(canEmpty && $event == ''){
        this.invoiceInfo.invoice_name = '';
        this.emitInvoiceAddressForm();
      }else{
        let name = $event.target.value;
        if(name.length < 3){
          this.alertifyService.error("İsim en az 3 karakter olmalıdır");
          //this.invoiceInfo.invoice_name = "";
          this.emitInvoiceAddressForm();
          return false;
        }else{
          this.invoiceInfo.invoice_name = name;
          this.emitInvoiceAddressForm();
        }
      }
      
    break;

    case "invoice_surname":
      if(canEmpty && $event == ''){
        this.invoiceInfo.invoice_surname = '';
        this.emitInvoiceAddressForm();
      }else{
        let surname = $event.target.value;
        if(surname.length < 3){
          this.alertifyService.error("İsim en az 3 karakter olmalıdır");
          this.emitInvoiceAddressForm();
        //  this.invoiceInfo.invoice_surname = "";
          return false;
        }else{
          this.invoiceInfo.invoice_surname = surname;
          this.emitInvoiceAddressForm();
        }
      }
      
    break;

    case "tax_administration":
      if(canEmpty && $event == ''){
        this.invoiceInfo.tax_administration = '';
        this.emitInvoiceAddressForm();
      }else{
        let taxAdministration = $event.target.value;
        if(taxAdministration.length < 3){
          this.alertifyService.error("Vergi dairesi en az 3 karakter olmalıdır");
          this.emitInvoiceAddressForm();
        //  this.invoiceInfo.invoice_surname = "";
          return false;
        }else{
          this.invoiceInfo.tax_administration = taxAdministration;
          this.emitInvoiceAddressForm();
        }
      }
      
    break;

    case "tax_number":

      if(canEmpty && $event == ''){
        this.invoiceInfo.tax_number = '';
        this.emitInvoiceAddressForm();
      }else{
       
          let taxNumber = $event.target.value;
          
          if(taxNumber.length == 11 || taxNumber.length == 10){
            this.invoiceInfo.tax_number = taxNumber;
            this.emitInvoiceAddressForm();
            
            
          }else{
            this.alertifyService.error('Vergi numarası 11 haneli olmalı');
            this.emitInvoiceAddressForm();
            return false;
           
          }
         
        
      }

      
      
    break;

    case "address":

     // this.selectedAddressId = $event;
     // this.invoiceInfo.address_id = $event;
     this.invoiceAddressForm.patchValue({
      address: $event.target.value
     });
      this.invoiceInfo.address = $event.target.value;
       this.emitInvoiceAddressForm();
      //this.setSelectedAddressInfo();
    break;

    case "gsm":

            this.invoiceInfo.gsm_number = $event.target.value;
            this.invoiceInfo.tel_number = $event.target.value;
     
this.invoiceAddressForm.patchValue({gsm_number:$event.target.value});
      
      
    break;

    case "new_gsm":
     
      if(canEmpty && $event == ''){
        this.invoiceInfo.gsm_number = '';
        this.emitInvoiceAddressForm();
      }else{
        if($event.target.value.length != 11){
          this.alertifyService.error("Gsm numarası 10 haneli olmalıdır");
          return false;
        }

        this.invoiceInfo.gsm_number = $event.target.value;
        this.invoiceInfo.tel_number = $event.target.value;
        this.emitInvoiceAddressForm();
      }

       
     
    break;

    case "identity_no":
      if(this.orderCardId){
        this.emitInvoiceAddressForm();
      }
      
      if(canEmpty && $event == ''){
        this.invoiceInfo.identity_no = '';
      }else{
        let identity_no = $event.target.value;
        if(identity_no.length != 11){
          this.alertifyService.error("Tc kimlik no 11 karakter olmalıdır.");
         // this.invoiceInfo.identity_no = "";
          return false;
        }else{
          this.invoiceInfo.identity_no = identity_no;
          this.emitInvoiceAddressForm();
        }
      }
      
    break;

   
  }
  

}

changeGeoZone(type:String,$event:any){
  switch(type){
    case "country": 
   
      this.invoiceAddressForm.patchValue({
        country_id:$event,
        city_id: null,
        district_id: null,
        town_id: null
      });

      this.emitInvoiceAddressForm();
     
     
    break;
    case "city": 
     
      this.invoiceAddressForm.patchValue({
        city_id:$event,
        district_id: null,
        town_id: null
      });

      this.emitInvoiceAddressForm();
      
    break;
    case "district":
      
    this.invoiceAddressForm.patchValue({
      district_id: $event,
      town_id: null
    });

    this.emitInvoiceAddressForm();
      break;
    case "town":
      this.invoiceAddressForm.patchValue({
        town_id: $event
      });

      this.emitInvoiceAddressForm();
    break;
  }

}

emitInvoiceAddressForm(){
  this.invoiceAddressFormEmitter.emit(this.invoiceAddressForm.value);
}
/*
paymentProcess_old(){


  if(this.invoiceAddressForm.invalid){
    
    this.alertifyService.error("Lütfen Fatura Bilgilerini Doldurunuz");
    return false;
  }

  

  this.hidePaymentButton = true;

  if(this.orderCardId != undefined){

    let rand1 = moment().format('YYMMDDHmmss').toString();
    let rand2 = moment().add(1,"hours").format('YYMMDDHmmss').toString();

    let rnd = ( parseInt(rand1) + parseInt(rand2) / 2);

    this.momentOrderId = rnd.toString()+"-oType:card-id:"+this.orderCardId;

    this.updateOrderCards.mutate({id:this.orderCardId,changes:{hash_order_id:this.momentOrderId}}).subscribe(async (result:any)=>{
      if(result.errors){
        this.alertifyService.error(result.errors[0].message);
      }
      if(result.data){
        if(this.orderCardId != undefined){
         
            
           // this.priceInfo.generalTotal = 1.15;

                   //   this.formEmit();
                   //   return;
              //api tarafında hash oluşturup alıyoruz

            
              await this.PaymentService.getHash(this.InstallmentCount,this.orderCardId,this.priceInfo.totalAmount,this.momentOrderId).subscribe((result:any)=>{
                if(result.errors){
                  this.alertifyService.error(result.errors[0].message);
                }
               
                if(result.hash){

                  this.hash = result.hash;
                  this.rnd  = result.rnd;

                  this.paymentFormGroup.patchValue({
                    Rnd: result.rnd,
                    Hash: result.hash
                  });

                  //card durumunu istek gönderildi yapıyoruz
                  this.updateOrderCards.mutate({id:this.orderCardId,changes:{pos_process_status_id:2}}).subscribe((result:any)=>{
                    if(result.errors){
                      this.alertifyService.error(result.errors[0].message);
                    }
                    if(result.data){

                    
                        this.paymentFormElement?.nativeElement.submit();
                        this.paymentAttempt = 0;

                        //bankadan gelen cevabın tabloda pos_process_status_id sekmesini başarılı veya başarısız yapmasını bekliyoruz 
                       const orderSub = this.orderCardPaymentSubs.subscribe({where:{id:{_eq:this.orderCardId}}}).subscribe(orderSubResult=> {

                          this.subOrderCards = orderSubResult.data?.order_cards[0];
                      //    if(this.paymentAttempt != this.subOrderCards?.payment_attempts){

                            if(this.subOrderCards?.pos_process_status_id == 4){
                              orderSub.unsubscribe();
                              this.isPayed = true;
                              
                              this.formEmit();
                              this.hidePaymentButton = false;
                            //  this.submit()
                            //  this.step = this.steps.siparisi_tamamla.id;
  
                            }else if(this.subOrderCards?.pos_process_status_id == 3){
                              orderSub.unsubscribe();
                  
                              this.momentOrderId = moment().format('YYMMDDHmmss').toString()+"-"+this.orderCardId;
                              rand1 = moment().format('YYMMDDHmmss').toString();
                              rand2 = moment().add(1,"hours").format('YYMMDDHmmss').toString();
                              rnd  = ( parseInt(rand1) + parseInt(rand2) / 2);
                             
                              this.momentOrderId = rnd.toString()+"-oType:card-id:"+this.orderCardId;
                      
                              if(this.orderCardId){
                                this.PaymentService.getHash(this.InstallmentCount,this.orderCardId,this.priceInfo.totalAmount,this.momentOrderId).subscribe((result:any)=>{
                                  if(result.errors){
                                    this.alertifyService.error(result.errors[0].message);
                                  }
                                  
                                  if(result.hash){
                                    this.hash = result.hash;
                                    this.rnd = result.rnd;
                                    this.paymentFormGroup.patchValue({
                                      Rnd: result.rnd,
                                      Hash: result.hash
                                    });
                                  }
                                } );
                              }
                              this.showPosMessage = true;
                              if(this.subOrderCards?.pos_message != undefined && this.subOrderCards?.pos_message != ""){
                                this.paymentFailMessage = this.subOrderCards.pos_message;
                              }
                              this.hidePaymentButton = false;
                              var ths = this;
                              setTimeout(function(){
                                ths.removePaymentMessage();
                                ths.paymentFailMessage = "";
                                ths.showPosMessage = false;
                        
                              },5000)
                            
                              
                      
                            }

                        // }
                          
                    
                         
                        });
                     
                      
                      
              
                    }
                  });

                }
              })
          
        
        }
       
      }
    });

    
  }

  
}*/


setPaymentType(type:string){
  if(type == "online"){
    this.selectedPaymentType = 1;
   
  }
  
  if(type == "offline"){
    this.selectedPaymentType = 2;
   
  }

  this.paymentTypeEmitter.emit(this.selectedPaymentType);

 
}

async formEmit(){
  this.token = `${localStorage.getItem('access_token')}`;
  
  if(this.invoiceAddressForm.invalid){
    this.alertifyService.error("Lütfen fatura bilgilerini giriniz");
  
    return false;
  }



  let returnObj;

  if(this.orderCardId){
    /** eğer sipariş oluştu ise sadece ödeme yap. */
  this.hidePaymentButton = true;
  this.showPaymentMessage = true;
  this.paymentMessage = "Banka ile iletişime geçiliyor...";
    await this.updateOrderCards.mutate({id:this.orderCardId,changes:{pos_process_status_id:0}}).subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
    });

    this.hidePaymentButton = true;

    this.http.post(environment.laravelApiEndpoint+"payment/pay",{
      order_card_id:this.orderCardId,
      card_number: this.inputCardNo,
      expiry_month: this.expriceMonth,
      expiry_year: this.expiryYear,
      cvv: this.ccv2,
      installment: this.selectedInstallment
    },{ headers: { token: `${localStorage.getItem('access_token')}` } }).subscribe(async (paymentResponse:any) => {
  
      //if (this.order.customer.user_phones.length) {
      //}
     // let ozanPayRequestId = paymentResponse.result.referenceNo[2];
      if (paymentResponse.status && paymentResponse.result.ozan3dUrl !== null) {
    
        let url = paymentResponse.result?.ozan3dUrl;

        if (url) {
          window.open(url, '_blank')?.focus();

          //this.order
          await this.updateOrderCards.mutate({id:this.orderCardId,changes:{pos_process_status_id:1}}).subscribe(resp=>{
            if(resp.errors){
              this.alertifyService.error(resp.errors[0].message);
            }else{

              this.ozanpayResponseSubs.subscribe(
                {
                  where:{
                    ozan_pay_request_id: {
                      _eq: paymentResponse.result.payment_request_id
                    }
                  }
                },
                {
                  fetchPolicy:"no-cache"
                }
              ).subscribe(aozanpayResponse => {

                if(aozanpayResponse.errors){
                  this.alertifyService.error(aozanpayResponse.errors[0].message);
                }

                if(aozanpayResponse.data){
                  this.ozanpayResonse = aozanpayResponse.data?.ozanpay_response;
                  if(this.ozanpayResonse[0]?.status == "APPROVED"){

                    let ozanSub = this.ozanpaySuccessfulsSubs.subscribe(
                      {
                        where:{
                          ozan_pay_request_id: {
                              _eq: paymentResponse.result.payment_request_id
                          }
                        }
                      },
                      {
                        fetchPolicy:"no-cache"
                      }
                      ).subscribe(subResp => {
                    if(subResp.errors){
                      this.alertifyService.error(subResp.errors[0].message);
                    }

                    if(subResp.data){

                      this.paymentMessage = "Bankadan yanıt bekleniyor...";
                     
                      this.ozanpaySuccessFuls = subResp.data.ozanpay_successfuls;
 
                      if( this.ozanpaySuccessFuls && this.ozanpaySuccessFuls.length > 0){
                        this.paymentMessage = "İşlem inceleniyor...";
                      }
    
                      this.hidePaymentButton = false;
                     // this.paymentMessage = this.ozanpaySuccessFuls.data
    
                     
                      if( this.ozanpaySuccessFuls[0]?.status == 'APPROVED'){
                        this.isPayed = true;

                       
                        returnObj = {
                          isPayed: this.isPayed,
                          invoiceInfo:this.invoiceAddressForm.value,
                          orderCreateable : true,
                          ozanpay_success_id: this.ozanpaySuccessFuls[0].id,
                          installment: this.selectedInstallment,
                          installmentAmount: this.installmentAmount,
                          ozanpayRequestId: paymentResponse.result.payment_request_id
                        }

                        this.paymentEmitter.emit(returnObj);
                        ozanSub.unsubscribe();
                       
                      }

                 
    
                    }
              
                  })

                  }

                  if(this.ozanpayResonse[0]?.status == "DECLINED"){

                    let data = JSON.parse(this.ozanpayResonse[0].response_data);

                    this.paymentMessage    = data.message;
                    this.hidePaymentButton = false;
                  }
                }

                
                

              });

             



            }

           
          });
      
            

        }
        
      }else{

        this.hidePaymentButton = false;

        this.paymentMessage = paymentResponse.result.message;
        this.showPaymentMessage = true;
        setTimeout(()=>{
          this.showPaymentMessage = false;
        },5000)

      }

    });

  }else{
    /*
    this.invoiceAddressForm.patchValue({
      invoice_gsm_no: this.invoiceAddressForm.value.invoice_gsm_no,
      tel_number: this.invoiceAddressForm.value.invoice_gsm_no
    });
  
      if(this.invoiceAddressForm.invalid){
        this.alertifyService.error("Hatalı Fatura Bilgileri");
      }
      returnObj = {
        isPayed: this.isPayed,
        invoiceInfo:this.invoiceAddressForm.value,
        orderCreateable : true,
      }
  
  
      this.hideAppointmentButton = false;
      this.paymentEmitter.emit(returnObj);
      */
  }

  
}

removePaymentMessage(){
  this.updateOrderCards.mutate({id:this.orderCardId,changes:{pos_message:null}}).subscribe(result=> {

  });
}

getField(obj:any,field:string){

  let retvalue:any = "";
  switch(field){
    case "baseAmount":
      case "installmentAmount":
      retvalue = (obj[field] / 100 )
    break;

    default:
      retvalue = obj[field];;
    break;
  }


  return retvalue;
}

setInstallment(event:any,obj,def=false){
 
  if(def){
    this.selectedInstallment = event;
    this.installmentAmount = (obj / 100);
  }else{
    this.selectedInstallment = event.target.value;
    this.installmentAmount = obj.buyerCostAmount ;
  }
  this.updateOrderCards.mutate({id:this.orderCardId,changes:{installment_amount: this.installmentAmount,installment_count:this.selectedInstallment}}).subscribe(response=>{
    if(response.errors){
      this.alertifyService.error(response.errors[0].message);
    }

    if(response.data){

    }
  });
}

findItemsWithInterestRatesUsingIndex(obj: any, rates: number[]): any[] {
  // Objeden ilk anahtarın alınması
  const firstKey = Object.keys(obj)[0];
  // İlk anahtara ait değerler üzerinden filtreleme
  return Object.values(obj[firstKey]).filter((item: any) => rates.includes(item.interestRate));
}

}
