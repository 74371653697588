<div class="card card-flush py-4">
    <div class="card-body pt-0">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12"  >
                <span class="fw-bolder fs-3 text-gray-800 mb-8"></span>
               
                       <div class="separator separator-dashed mt-5 mb-4"></div>
                
            </div>

            <div class="col-md-12 p-5">
                <label class="d-flex align-items-center form-label mb-5">Mekanik Parça Seçiniz
                    <i class="fas fa-exclamation-circle ms-2 fs-7"
                        aria-label="Monthly billing will be based on your account plan"
                        title="Birden fazla seçim yapabilirsiniz."></i>
                </label>
                <app-shared-selectwo
                [name] = "'Parça Seçiniz'"
                [lists] = "partCategories"
                [id]="'mechanicParts'"
                (selectedItem)="selectedTwo('mechanicPart',$event)"
                [showAll]=false
                [returnField]="'part_id'">
                    
                </app-shared-selectwo>
            </div>
            
            
            
            <div class="table-responsive" *ngIf="partEquivalentList.length > 0">
                <table class="table">
                      
                    <thead>
                        <tr class="border-bottom fs-6 fw-bolder text-muted text-uppercase">
                            <th class="min-w-175px pb-9">Parça</th>
                            <th class="min-w-150px pb-9 d-none">
                                Yetkili Orjinal 
                            </th>
                            <th class="min-w-150px pb-9"> 
                                <input type="radio" class="radio radio-success form-check-input"  name="selectAll" (click)="selectAll($event)" value="original">
                                Orjinal
                            </th>
                            <th class="min-w-100px pe-lg-6 pb-9 d-none" >
                                <input type="radio" class="radio radio-success form-check-input"  (click)="selectAll($event)" name="selectAll" value="logosuz">
                                Logosuz Orjinal 
                            </th>
            
                            <th class="min-w-80px pb-9 "> 
                                <input type="radio" class="radio radio-success form-check-input"  name="selectAll" (click)="selectAll($event)" value="esdeger">
                                Eşdeğer
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="fw-bolder text-gray-700 fs-5 "  
                        *ngFor="let item of partEquivalentList">
                        <td class="flex align-items-center pt-5">
                               
                            <div class="text-dark fw-bolder text-hover-primary fs-6">{{item.selectedGosPart.name}} {{item.offical_original.id}} as</div>
                            <span class="text-muted fw-bold d-block fs-7"> {{item?.part_unit}} {{item.part_unit_type_name}} </span>
                        </td>
                        <td class="pt-11 px-0 text-left d-none">
                            
                                <span class="ps-3">
                                    {{item.offical_original.totalPriceCurrency | number:'1.0-2'}} {{item.offical_original.price_unit}} 
                                </span>
            
                        </td>
                        <td class="pt-5 px-0 text-left">
                            <input 
                                *ngIf="item.original"
                                class="form-check-input" 
                               type="radio" name="part_{{item.selectedGosPart.id}}"
                               (click)="addPiece(item.part_id,item.selectedGosPart.id,item.original.id,'original')"
            
                                id="original_{{item.offical_original.id}}">
                                <span class="ps-3">
                             {{item.original?.totalPriceCurrency | number:'1.0-2'}} {{item.original?.price_unit}}  aa
                                </span>
                        </td>    
                           
                        <td class="pt-5 text-left d-none">
                            <input 
                            *ngIf="item.without_logo"
                            class="form-check-input" 
                            type="radio" name="part_{{item.selectedGosPart.id}}"
                            (click)="addPiece(item.part_id,item.selectedGosPart.id,item.without_logo.id,'without_logo')"
                            id="without_logo_{{item.offical_original.id}}">
                            <span class="ps-3">
                                {{item.without_logo?.totalPriceCurrency | number:'1.0-2'}} {{item.without_logo?.price_unit}} 
                            </span>
                        </td>
                     
                        <td class="pt-5 px-0 text-left">
                        
                            <input class="form-check-input" 
                            *ngIf="item.equivalent"
                             type="radio" name="part_{{item.selectedGosPart.id}}"
                             (click)="addPiece(item.part_id,item.selectedGosPart.id,item.equivalent.id,'equivalent')"
                            id="equivalent_{{item.offical_original.id}}">
                                <span class="ps-3">
                                    {{item.equivalent?.totalPriceCurrency | number:'1.0-2'}} {{item.equivalent?.price_unit}} 
                                </span>
                        </td>
                            
                          
                         
                        </tr>
                        
                    </tbody>
                </table>
            </div>
            
            
        </div>
        
    </div>
</div>

<div class="card card-flush py-4 flex-row-fluid mt-10" style="border: 1px solid #f0f0f0;" *ngIf="selectedParts.length > 0" >
    <!--begin::Card header-->
    <div class="card-header" style="min-width: 200px;">
        <div class="card-title" style="min-width: 200px;">
            
            Seçili Ürünler
        </div>
        <div class="card-tool">
           

        </div>
    </div>
    <!--end::Card header-->
    <!--begin::Card body-->
    <div class="card-body pt-0 " *ngIf="selectedParts.length > 0">
        <div class="table-responsive">
            <table class="table">
              
                <thead>
                    <tr class="border-bottom fs-6 fw-bolder text-muted text-uppercase">
                        <th class="min-w-175px pb-9">Parça Adı</th>
                        <th class="min-w-175px pb-9">Parça Türü</th>
                        <th class="min-w-175px pb-9">Adet</th>
                        <th class="min-w-175px pb-9">Ücret</th>
                        <th class="min-w-175px pb-9">İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="fw-bolder text-gray-700 fs-5 "  
                    *ngFor="let item of selectedParts">
                        <td>{{item.selectedEquivalent_name}}</td>
                        <td>{{item.selectedEquivalent_partTypeName}}</td>
                        <td>{{item.selectedEquivalent_partUnit}} {{item.selectedEquivalent_partUnitType}}</td>
                        <td>{{item.selectedEquivalent_currencyTotalPrice | number:'1.0-2'}}</td>
                        <td> <button class="btn btn-primary btn-sm" 
                            *ngIf="item.selectedEquivalent_priceTotal > 0"
                            (click)="removeFromSelectedList(item.selectedGosPart_id,item.selectedEquivalent_partInputId)">
                             <i class="fas fa-window-close"></i> Kaldır</button> 
                            
                            </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr style="background-color: #015029 !important;
                                color: #fff;
                                font-weight: bold;">
                                <td></td>
                        <td style="padding-left: 10px;">Toplam</td>
                        <td  style="padding-left: 30px;" >{{totalsByTypes.orjinal.toFixed(2)}}₺</td>
                        <td class="text-center d-none">{{totalsByTypes.logosuz.toFixed(2)}}₺</td>
                        <td style="padding-left: 30px;">{{totalsByTypes.es_deger.toFixed(2)}}₺</td>
                    </tr>
                    <tr class="border fs-6 fw-bolder text-muted text-uppercase" >
                        <td colspan="2" class="text-end">
                            <span class="text-dark fw-bolder d-block">Toplam</span>
                            </td>
                        <td class="text-dark fw-bolder text-hover-primary fs-6">{{(subTotal+difference).toFixed(2)}} {{activeCurrency?.code}}</td>
                    </tr>
                    <tr class="border fs-6 fw-bolder text-muted text-uppercase " >
                        <td colspan="2" class="text-end">
                            <span class="text-dark fw-bolder d-block">Gos İşçilik Hizmet Ücreti</span>
                            </td>
                        <td class="text-dark fw-bolder text-hover-primary fs-6" *ngIf="selectedParts.length == partEquivalentList.length">{{gosMaintenancePrice}} {{activeCurrency?.code}}</td>
                        <td class="text-dark fw-bolder text-hover-primary fs-6" *ngIf="selectedParts.length < partEquivalentList.length">0 TL</td>
                    </tr>
                    <tr class="border fs-6 fw-bolder text-muted text-uppercase d-none" >
                        <td colspan="2" class="text-end">
                            <span class="text-dark fw-bolder d-block">Ara Toplam</span>
                            </td>
                        <td class="text-dark fw-bolder text-hover-primary fs-6">{{subTotal}} {{activeCurrency?.code}}</td>
                    </tr>
                   
                    <tr class="border fs-6 fw-bolder text-muted text-uppercase d-none" >
                        <td colspan="2" class="text-end">
                            <span class="text-dark fw-bolder d-block">Marka Hizmet Ücreti</span>
                            </td>
                        <td class="text-dark fw-bolder text-hover-primary fs-6">{{brandMaintenancePriceNoVat}} {{activeCurrency?.code}}</td>
                    </tr>
                    <tr class="border fs-6 fw-bolder text-muted text-uppercase d-none" >
                        <td colspan="2" class="text-end">
                            <span class="text-dark fw-bolder d-block">Kdv</span>
                            </td>
                        <td class="text-dark fw-bolder text-hover-primary fs-6">{{difference}} {{activeCurrency?.code}}</td>
                    </tr>
                    <tr class="border border-bottom fs-6 fw-bolder text-muted text-uppercase " >
                        <td colspan="2" class="text-end">
                            <span class="text-dark fw-bolder d-block">Genel Toplam</span>
                            </td>
                        <td class="text-dark fw-bolder text-hover-primary fs-6">{{grandTotal}} {{activeCurrency?.code}}</td>
                    </tr>
                    <tr class="border border-bottom fs-6 fw-bolder text-muted text-uppercase d-none" >
                        <td colspan="2" class="text-end">
                            <span class="text-dark fw-bolder d-block">Toplam Tasarruf</span>
                            </td>
                        <td class="text-dark fw-bolder text-hover-primary fs-6">{{savingTotal.toFixed(2)}} {{activeCurrency?.code}}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>

