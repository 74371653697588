import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-shared-selectwo-multiple',
  templateUrl: './selectwo-multiple.component.html',
  styles: [
  ],
  styleUrls:['./selectwo-multiple.component.css']
})
export class SelectwoMulipleComponent implements OnInit {
  public showResult: boolean = false;
  
  @Input() lists:any;
  @Input() test:boolean | undefined;
  @Input() name!:any;
  @Input() id!:string;
  @Input() showAll:boolean = true;
  @Input() extendSearch:boolean = false;
  @Output() selectedItem = new EventEmitter<any>();
  @Output() searchItem = new EventEmitter<any>();
  filteringItems: any;
  @Input() fieldName:string = 'name';
  @Input() returnField:string = 'id';
  @Input() setItem:any;
  @Input() isUserSearch:boolean=false;
  @Input() resultDesign:any=[];
  @Input() searchItemLength:number = 3;
  @Input() clearInput: boolean = false;
  private slctdItem:any[] = [];
  public selectAllInputId:string | undefined;
  @Output() selectedItems = new EventEmitter<any>();

  formGroup = new FormGroup({
    selectedList: new FormControl('', [Validators.required]),
    
    
  });

  private selectedItemList:any[] = [];
  
 
  public inputval: String = "Type";
  constructor(private eRef: ElementRef,private cd: ChangeDetectorRef) {
   
  }

  @HostListener('click', ['$event'])
  clickInside(event:any) {
    if(event.target.closest("a.selectwoItem")){
      this.closePopup();
    }else{
      this.showResult = true;
      this.filteringItems = this.lists;

      let resultDiv = document.getElementById(this.id)
      if(resultDiv && !resultDiv.classList.contains("show")){
        resultDiv.classList.add("show");
        
      }
    }
   
  }
  
  @HostListener('document:click')
  clickout() {
      this.closePopup();
    
  }

  ngOnInit(): void {
   
  // alert(this.slctdItem)
  }



  ngOnChanges(){
    this.cd.detectChanges();

  

    this.selectAllInputId = "selectAllInput_"+this.id;
    

    if(this.clearInput){
      this.slctdItem = [];
    }


    if(this.name != undefined){
      this.inputval = this.name;
  
    }

    if(this.slctdItem != undefined){
   //   this.inputval = this.slctdItem;
    }else{
      this.inputval = this.name;
    }

    this.filteringItems = this.lists;


     if(this.setItem && (this.filteringItems && this.filteringItems.length > 0)){
     
       
        this.lists.filter((obj: { [x: string]: string; }) => {
          if(this.setItem == obj[this.returnField]){
            this.selectItem(obj[this.fieldName]);
          }
        })
      }
   
  }

  closePopup(){
    if (!this.showResult) {
      document.getElementById(this.id)?.classList.remove("show");
      this.filteringItems = []

    }
    this.showResult = false;
  }

  toggle($event:any){
    this.showResult = !this.showResult;
    
  }

  selectItem(item:any){
 
    let selected;

    if(item.target.checked){

      if(item.target.name == 'selectAllInputId'){
        this.selectedItemList = [];
        if(this.filteringItems.length > 0){
          this.filteringItems.forEach((element:any) => {
            this.selectedItemList.push(element[this.returnField]);
          });
        }
      }else{
       this.selectedItemList.push(item.target.value);
      }

    }else{

     if(item.target.name == 'selectAllInputId'){
        this.selectedItemList = [];
      }else{
        this.selectedItemList = this.selectedItemList.filter((i:any)=> i != item.target.value);
      }

    }

    if(this.selectedItemList.length > 0){
      this.inputval = '( '+ this.selectedItemList.length+' adet ) seçildi';
    }else{
      this.inputval = this.name;
    }
    let selectedInfos:any = [];

   if(this.selectedItemList.length > 0 && this.lists.length > 0){
    this.selectedItemList.forEach((item:any)=>{
      this.lists.forEach((element:any) => {
        if(element.id == item){
            selectedInfos.push(element);
        }
      });
    })
   }
//    this.slctdItem = item;
    this.selectedItems.emit({idList: this.selectedItemList,itemInfo: selectedInfos});
    
    
  }

  search($event:any){
    this.searchItem.emit($event);
    if($event.target.value.length >= this.searchItemLength  ){
     
      if(this.extendSearch){

        this.searchItem.emit($event);
  
      }else{
        if($event.target.value.length == 0){
          this.filteringItems = [];
          
          return false;
        }
       
        this.filteringItems = this.lists.filter((obj: { [x: string]: string; }) =>{
          
          if(obj[this.fieldName].toLocaleLowerCase().search($event.target.value.toLocaleLowerCase()) > -1 ){
            return obj;
          } 
          return false;
        });
      }
    }  

    if($event.target.value.length == 0  ){
      this.filteringItems = this.lists;
    }

    
  }

  selectData(id:bigint){
    this.selectedItem.emit(id);
    
  }

  getField(item:any){
  
    if(this.fieldName.includes(".")){
      let newdata = this.fieldName.split(".");
      
      newdata.forEach(function(v,k){
        item = item[v];
      })
    
      return item;
    }else{
      return item[this.fieldName];
    }
    
  }

  isObject(val:any): string { return typeof val }

  objectkey(val:any):string{
    return Object.keys(val)[0];
  }

  isChecked(id:any){
    let check = this.selectedItemList.filter((item:any) => item == id);
    
    return (check.length > 0) ? true : false;
  }
}
