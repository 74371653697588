import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface Company {
  id: bigint;
  name: String;
  title: String;
  company_type_id: Number;
  note?: String;
  vat?: bigint;
  vat_office?: String;
  city_id?: Number;
  district_id?: Number;
  town_id?: Number;
  country_id?: Number;
  active?: Boolean;
  address?: String;
  logo?: String;
  authorized_user_id?: Number;
  accounting_id?: Number;
  overtime_start?: any;
  overtime_finish?: any;
  weekend_overtime_finish?: any;
  main_company_id?: Number;
  lat?: String;
  lon?: String;
  evalutaion_points?: Number;
  assistant_company_id?: bigint;
  tel_id?: bigint;
  gsm_id?: bigint;
  email_id?: bigint;
  fax_id?: bigint;
  webservice_id?: Number;
  visibility?: Boolean;
  companys_franchises?:any;
  company_vehicle_brands?:any;
  company_social_medias?:any;
  company_acceptances: any;
  service_company_contacts:any;
  City:any
  district?:{
    name: String
  }
  company_tels?:any
  company_emails?:any
  company_email?:any
  company_certificates?:any
  company_services?: any
  company_evaluations?: any
  activation_date?:any
  is_sole_proprietorship?:Boolean
  tax_number: Number,
  tax_administration: String,
  company_type: Company,
  service_status: String
  company_service_groups: any
  service_additionals: any,
  company_tel_no: String,
  company_gsm_no: String,
  company_email_address: String,
  year_of_foundation: String,
  distance: any;
  company_tel?: any,
  company_gsm?: any
}

interface ResponseArray {
    companies: Company[];
}

interface ResponseSingle {
    insert_companies: any;
    companies_by_pk: Company
}

@Injectable()
export class COMPANY_MAPS extends Query<ResponseArray> {
  override document = gql`
  query COMPANY_MAPS($where: companies_bool_exp, $order_by: [companies_order_by!]){
    companies(where: $where, order_by: $order_by) {     
      id
      name
      title
      lat
      lon
      address
    }
  }
  `;
}


@Injectable()
export class COMPANY_ALL extends Query<ResponseArray> {
  override document = gql`
  query GET_COMPANIES($where: companies_bool_exp, $order_by: [companies_order_by!]){
    companies(where: $where, order_by: $order_by) {
      
      id
      name
      title
      evalutaion_points
      logo
      lat
      lon
      city_id
      district_id
      address
      company_tel_no
      company_gsm_no
      company_email_address
      year_of_foundation
      company_evaluations{
        id
        comment
        company_answer
        company_evaluation_values{
          id
          evaluation_type_id
          value
          evalutaion_id
          evaulation_type{
            id
            name
          }
        }
        company_evaluation_values{
          id
          evaluation_type_id
          value
          evaulation_type{
            id
            name
          }
        }
      }
      service_company_contacts{
        id
        company_id
        general_manager
        general_manager_phone
        general_manager_email
        service_manager
        service_manager_phone
        service_manager_email
        service_consultant
        service_consultant_phone
        service_consultant_email
        off_hours_phone
        created_at
        company_owner
      }
      company_tel{
        id
        code
      }
      company_gsm{
        id
        code
      }
      company_service_groups{
        id
        company_id
        company_service_group_list_id
        company_service_group_list{
          id
          name
          active
        }
      }
      companys_franchises{
        franchise_network{
          id
          name
          logo
        }
      }
      company_vehicle_brands {
        vehicle_brand {
          id
          logo_code
          name
          logo_url
        }
      }
      City {
        id
        name
        group
        days
      }
      district {
        name
      }
      company_email{
        id
        name
        email
      }
    }
  }
  `;
}

@Injectable()
export class COMPANY_ID extends Query<ResponseSingle> {
  override document = gql`
    query COMPANY_ID($id: bigint!){
      companies_by_pk(id: $id) {
        id
        name
        title
        company_type_id
        company_email_address
        note
        vat
        vat_office
        city_id
        district_id
        town_id
        country_id
        company_tel_no
        company_gsm_no
        active
        address
        logo
        authorized_user_id
        accounting_id
        overtime_start
        overtime_finish
        weekend_overtime_finish
        main_company_id
        service_status
        lat
        lon
        evalutaion_points
        assistant_company_id
        tel_id
        gsm_id
        email_id
        fax_id
        webservice_id
        visibility
        activation_date
        is_sole_proprietorship
        year_of_foundation
        service_additionals{
          id
          company_id
          additional_id
          service_company_additionals{
            id
            name
          }
        }
        company_service_groups{
          id
          company_id
          company_service_group_list_id
          company_service_group_list{
            id
            name
            active
          }
        }
        companys_franchises{
          franchise_network{
            id
            name
            logo
          }
        }
        City {
          name
        }
        district {
          name
        }
        company_tels{
          code
        }
        company_acceptances{
          company_id
          day_id
          start_hour
          finish_hour
          count
        }
        company_social_medias {
          social_media {
            name
          }
          social_media_link
        }
        company_emails {
          email
        }
        company_certificates{
          certicate {
            name
          }
        }
        company_vehicle_brands {
          vehicle_brand {
            name
          }
        }
        company_services {
          id
          company_service_type_id
          company_service_type {
            name
          }
        }
        company_evaluations {
          company_evaluation_values {
            value
            evaulation_type {
              name
            }
          }
        }
      }
    }
  `;
}

@Injectable()
export class COMPANY_CREATE extends Mutation<ResponseSingle> {
  // connected_part_id - int | nullable
  override document = gql`
  mutation COMPANY_CREATE(
    $accounting_id: Int, 
    $active: Boolean, 
    $evalutaion_points: Int, 
    $address: String, 
    $assistant_company_id: Int, 
    $authorized_user_id: Int, 
    $city_id: bigint, 
    $company_type_id: Int, 
    $country_id: Int, 
    $district_id: Int, 
    $email_id: bigint, 
    $fax_id: bigint, 
    $gsm_id: bigint, 
    $lat: String, 
    $logo: String, 
    $lon: String,
    $main_company_id: Int, 
    $name: String, 
    $note: String, 
    $overtime_finish: timetz, 
    $overtime_start: timetz, 
    $tel_id: bigint, 
    $title: String, 
    $town_id: Int, 
    $vat: bigint, 
    $vat_office: String, 
    $visibility: Boolean, 
    $webservice_id: Int, 
    $is_sole_proprietorship:Boolean
    $weekend_overtime_finish: timetz,
    $service_status: smallint
      ) {
    insert_companies(objects: {
        accounting_id: $accounting_id, 
        active: $active, 
        address: $address, 
        assistant_company_id: $assistant_company_id, 
        authorized_user_id: $authorized_user_id, 
        city_id: $city_id, 
        company_type_id: $company_type_id, 
        country_id: $country_id, 
        district_id: $district_id, 
        email_id: $email_id, 
        evalutaion_points: $evalutaion_points, 
        fax_id: $fax_id, 
        gsm_id: $gsm_id, 
        lat: $lat, 
        logo: $logo, 
        lon: $lon, 
        main_company_id: $main_company_id, 
        name: $name, 
        note: $note, 
        overtime_finish: $overtime_finish, 
        overtime_start: $overtime_start, 
        tel_id: $tel_id, 
        title: $title, 
        town_id: $town_id, 
        vat: $vat, 
        vat_office: $vat_office, 
        visibility: $visibility, 
        webservice_id: $webservice_id, 
        weekend_overtime_finish: $weekend_overtime_finish,
        service_status: $service_status
        is_sole_proprietorship:$is_sole_proprietorship}) {
      affected_rows
      returning{
        id
      }
    }
  }
  
  `;
}

@Injectable()
export class COMPANY_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
  mutation COMPANY_UPDATE(
    $id: bigint = "", 
    $accounting_id: Int, 
    $active: Boolean, 
    $evalutaion_points: Int, 
    $address: String, 
    $assistant_company_id: Int, 
    $authorized_user_id: Int, 
    $city_id: bigint, 
    $company_type_id: Int, 
    $country_id: Int, 
    $district_id: Int, 
    $email_id: bigint, 
    $fax_id: bigint, 
    $gsm_id: bigint, 
    $lat: String, 
    $logo: String, 
    $lon: String,
    $main_company_id: Int, 
    $name: String, 
    $note: String, 
    $overtime_finish: timetz, 
    $overtime_start: timetz, 
    $tel_id: bigint, 
    $title: String, 
    $town_id: Int, 
    $vat: bigint, 
    $vat_office: String, 
    $visibility: Boolean, 
    $webservice_id: Int, 
    $weekend_overtime_finish: timetz,
    $is_sole_proprietorship:Boolean
    ) {
  update_companies(where: {id: {_eq: $id}}, _set: {
    accounting_id: $accounting_id, 
    active: $active, 
    email_id: $email_id, 
    address: $address, 
    assistant_company_id: $assistant_company_id, 
    authorized_user_id: $authorized_user_id, 
    city_id: $city_id, 
    company_type_id: $company_type_id, 
    country_id: $country_id, 
    district_id: $district_id, 
    fax_id: $fax_id, 
    gsm_id: $gsm_id, 
    lat: $lat, 
    logo: $logo, 
    lon: $lon, 
    main_company_id: $main_company_id, 
    name: $name, 
    note: $note, 
    overtime_finish: $overtime_finish, 
    overtime_start: $overtime_start, 
    tel_id: $tel_id, 
    title: $title, 
    town_id: $town_id, 
    vat: $vat, 
    vat_office: $vat_office, 
    visibility: $visibility, 
    webservice_id: $webservice_id, 
    weekend_overtime_finish: $weekend_overtime_finish, 
    evalutaion_points: $evalutaion_points,
    is_sole_proprietorship:$is_sole_proprietorship}) {
    affected_rows
  }
}
  
  `;
}

@Injectable()
export class COMPANY_UPDATE_CUSTOM extends Mutation<ResponseSingle> {
  override document = gql`
  mutation COMPANY_UPDATE_CUSTOM(
    $id:bigint
    $changes: companies_set_input) {
  update_companies(where: {id: {_eq: $id}},  _set: $changes) {
    affected_rows
  }
}
  
  `;
}

@Injectable()
export class COMPANY_UPDATE_MAIL extends Mutation<ResponseSingle> {
  override document = gql`
  mutation COMPANY_UPDATE_MAIL($id: bigint = "", $mail_id: bigint!) {
    update_companies(where: {id: {_eq: $id}}, _set: { email_id: $mail_id}) {
      affected_rows
    }
  }`;
}

@Injectable()
export class COMPANY_UPDATE_GSM extends Mutation<ResponseSingle> {
  override document = gql`
  mutation COMPANY_UPDATE_GSM($id: bigint = "", $gsm_id: bigint!) {
    update_companies(where: {id: {_eq: $id}}, _set: { gsm_id: $gsm_id}) {
      affected_rows
    }
  }`;
}

@Injectable()
export class COMPANY_UPDATE_FAX extends Mutation<any> {
  override document = gql`
  mutation COMPANY_UPDATE_FAX($id: bigint!, $fax_id: bigint!) {
    update_companies(where: {id: {_eq: $id}}, _set: { fax_id: $fax_id}) {
      affected_rows
    }
  }`;
}

@Injectable()
export class COMPANY_UPDATE_TEL extends Mutation<any> {
  override document = gql`
  mutation COMPANY_UPDATE_TEL($id: bigint!, $tel_id: bigint!) {
    update_companies(where: {id: {_eq: $id}}, _set: { tel_id: $tel_id}) {
      affected_rows
    }
  }`;
}


@Injectable()
export class COMPANY_DELETE extends Mutation<any> {
  override document = gql`
    mutation COMPANY_DELETE($id: bigint!) {
      delete_companies(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class COMPANY_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query COMPANY_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: companies_bool_exp) {
      companies(limit: $limit, offset: $offset, order_by: {name: $orderBy}, where: $where) {
        id
        name
        title
        logo
        active
        visibility
        company_type_id
        company_type{
          id
          name
        }
        City {
            name
        }
        Town {
            name
        }
        district {
            name
        }
        webserice {
            name
        }
        companys_franchises {
          franchise_network {
            name
            logo
            id
          }
        }
       
      }
    }
  `;
}

@Injectable()
export class COMPANY_COUNT extends Query<any> {
  override document = gql`
  query COMPANY_COUNT($where: companies_bool_exp) {
    companies_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  `;
}