import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { getDateMeta } from '@fullcalendar/angular';
import { count } from 'console';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { CompanyAcceptance, COMPANY_ACCEPTANCES } from 'src/app/graphql/company_acceptances.graphql';
import { ORDER_CHECK_BY_DATE,OrderAggregate } from 'src/app/graphql/order.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { flattenDiagnosticMessageText } from 'typescript';

const DAY_MS = 60 * 60 * 24 * 1000;

@Component({
  selector: 'app-shared-company-date-selector',
  templateUrl: './company-date-selector.component.html',
  styles: [
  ],
  styleUrls:['./company-date-selector.component.css'],
  providers:[COMPANY_ACCEPTANCES,ORDER_CHECK_BY_DATE]
})
export class CompanyDateSelectorComponent implements OnInit {

  @Input() companyId: bigint | undefined;
  dates: Array<Date> | undefined;
  days = [ 'P', 'S', 'Ç', 'P', 'C', 'C','P'];
  date = new Date();
  @Output() selected = new EventEmitter();
  public currentDate = new Date;
  public companyAcceptanceList: CompanyAcceptance[] | undefined;
  private dayList:any[] = [];;
  public hourList:any[] = [];
  public hourListWithSelectable:any[]=[];
  public showCalendar: boolean = false;
  public inputText = "Servis Tarihi Seçiniz";
  public selectedData = {
    date: '',
    hour: '',
    rawDate: ''
  }
  public checkSelectedDate: Date | undefined;
  public checkOrderCount: OrderAggregate | undefined;
  constructor(
    private alertifyService: AlertifyService,
    private getCopmanyacceptance: COMPANY_ACCEPTANCES,
    private checkOrderByDate: ORDER_CHECK_BY_DATE,
    private eRef: ElementRef
  ) {
    this.dates = this.getCalendarDays(this.date);
  }
  
  @HostListener('document:click', ['$event'])
  clickout(event:any) {
    if(this.eRef.nativeElement.contains(event.target)) {
    
    } else {      

      this.showCalendar = false;
    }
  }

  ngOnInit(): void {
    moment.locale('Tr');

   
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getCopmanyacceptance.watch({company_id:this.companyId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{

      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }

      if(resp.data){
        this.companyAcceptanceList = resp.data.company_acceptances;
        if(this.companyAcceptanceList.length > 0){
          this.companyAcceptanceList.forEach((val,key)=>{
            
            let days =  {
              "day" : val.day.tr_name,
              "hours": [""]
            };
      
            
           days.hours = [];
            if(val.start_hour && val.finish_hour){
          
              let start = moment(val.start_hour,"H").hour().valueOf();
              let finish = moment(val.finish_hour,"H").hour().valueOf();
              for(let i=start; i <= finish; i++){
                let Obj = {
                  hour: "test",
                  isSelectable: false
                };

               let dateString = "";
                if(i < 10){
                 // days.hours.push('0'+i+':00');
                 
                 dateString =  '0'+i+':00';
             
                 
                }else{
                 // days.hours.push(String(i+':00'));
                 dateString = String(i+':00');
                }
                days.hours.push(dateString);

            
                
                
              }
              
            }
            this.dayList.push(days);
          })

        }
      }
    })

  }

  setMonth(inc:any) {
    const [year, month] = [this.date.getFullYear(), this.date.getMonth()];
    this.date = new Date(year, month + inc, 1);
    this.dates = this.getCalendarDays(this.date);
  }

  isToday(date:any){
     return date.getMonth() === this.date.getMonth() && date.getDate() == this.date.getDate();
  }
  
  isSameMonth(date:any) {
   let curDate     = moment();
   let momentDate  = moment(date);
   let finishhDate = curDate.add(1,"months");
   let different   = curDate.diff(momentDate,'days');
   let isTrue      = true;


    if(different+1 < 0){
      isTrue = false;
    }

    if(different +2 > 31){
      isTrue = false;
    }

    if(isTrue){
      let checkday = false;
      this.dayList.forEach((v,k)=>{
        if(v.day && v.day == momentDate.format('dddd')){
          checkday = true;
        }
      })
      isTrue = checkday
    }
   
    return isTrue;
  }

  private getCalendarDays(date = new Date) {
    
    let cal = this.getCalendarStartDay(date);
   
    if(cal){

      const calendarStartTime:any = cal.getTime() + 60 * 60 * 2 * 1000; /* add 2 hours for day light saving time adjustment */



    return this.range(1, 42)
      .map(num => new Date(calendarStartTime + DAY_MS * num));
    }
   
  }

  private getCalendarStartDay(date = new Date) {
    const [year, month] = [date.getFullYear(), date.getMonth()];


    //const firstDayOfMonth = new Date(year, month, 1).getTime();
    const firstDayOfMonth = new Date(year, month, date.getDate()).getTime();

    return this.range(1,7)
      .map(num => new Date(firstDayOfMonth - DAY_MS * num))
      .find(dt => dt.getDay() === 0)
  }

  private range(start:any, end:any, length = end - start + 1) {
   
    return Array.from({ length }, (_, i) => start + i)
  }

  async changeData(event:any,type:string,isClicable:boolean){
    if(isClicable){
      this.selectedData.hour = '';
   
      if(type == "date"){
        this.hourListWithSelectable= [];
        this.hourList = [];
        this.selectedData.date = moment(event).format("LL");
        this.selectedData.rawDate = moment(event,"MM-DD-YYYY").format("YYYY-MM-DD").toString();
        let selectedDayName = moment(event).format('dddd');
        let selectedDay = this.dayList.map((i:any)=>{ 

          if( i.day == selectedDayName) { 
           
            this.hourList = i.hours; 
            if(this.hourList.length > 0){

              this.hourList.forEach(async (v,k) => {
                
                let newList={hour:"",isActive:false,hourInt: 0};

                
               newList.hour= v;
               newList.hourInt = parseInt(v);
                newList.isActive = await this.isSelectableHour(v);
                
                this.hourListWithSelectable.push(newList);
                this.hourListWithSelectable.sort(function(b, a) {
                  return a.hourInt < b.hourInt ? 1 : -1;
              });


              });
            }
          }});

        
      }
  
      if(type == "hour"){
        this.selectedData.hour = event;
      }
  
      if(this.selectedData.date){
        this.inputText = this.selectedData.date + ' ' + this.selectedData.hour;
      }else{
        this.inputText = "Servis Tarihi Seçiniz";
      }

      if(this.selectedData.hour){
        this.returnData();
      }
      
     //this.dates = this.getCalendarDays(this.date);
    }
    
  }

  returnData(){
    if(this.selectedData.date == '' || this.selectedData.hour == ''){
      this.alertifyService.error("Lütfen tarih ve saaç seçiniz!");
      return false;
    }else{
      this.showCalendar = false;
      let returnData = moment(this.selectedData.date + " " + this.selectedData.hour,'D MMM YYYY H:i').format("YYYY-MM-DDTHH:mm");
      this.selected.emit(returnData);
      
    }
 
  }

  isActive(event:any){
    return this.selectedData.date == moment(event).format("LL"); 
  }
  setHours(){
    
  }
  isActiveHour(event:any){
   

    return this.selectedData.hour == event; 
  }

   async isSelectableHour(event:any):Promise<boolean>{
    let searchableDate = moment(this.selectedData.rawDate).format("YYYY-MM-DD").toString()+" "+event;
    return new Promise((resolve,reject)=>{
      this.checkOrderByDate.watch({service_id: this.companyId,service_date:searchableDate},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{
        if(resp.errors){
          this.alertifyService.error(resp.errors[0].message);
          resolve(false);
          return false;
        }
        if(resp.data){
          let count = resp.data.orders_aggregate.aggregate.count;
  
          if (count == 0){
            resolve(true);
            return true
       
          }else{
            resolve(false);
           return false;
           
          }
         
          
        }
      
      });
    })
    }
     

    toggleCalendar(){
      this.showCalendar = !this.showCalendar;
    }    
    


}
