import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-shared-paginate',
  templateUrl: './paginate.component.html',
  styles: []
})
export class PaginateComponent implements OnInit {

  @Input() dataCount!: number;
  @Input() limit: number = 20;
  @Output() offset = new EventEmitter<number>()

  public pageCount: number = 0;
  public page: number = 1;
  public pageList: number = 10;
  public rangeCount: Array<number> = [1];
  public selectCount: Array<number> = Array(20, 30, 50, 100, 250, 500, 1000, 2000, 5000)

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges() {
    this.pageCount = Math.ceil(this.dataCount / this.limit)

    setTimeout(() => {
      let ceil = Math.ceil((this.pageCount - this.pageList) / 10) * 10;
      ceil = ceil ? ceil : 10
      if (!this.selectCount.includes(ceil)) {
        this.selectCount.push(ceil)
      }
      this.selectCount = this.selectCount.filter(c => c <= this.pageCount)
    }, 300);

    this.pageList = this.pageCount >= 10 ? 10 : this.pageCount
    this.setRangeCount(1)
  }

  goToPage(page: number) {

    // generate page number lists
    if (page == this.rangeCount[this.rangeCount.length - 1]) {
      if (this.pageCount > page + 10) {
        this.setRangeCount(page)
      } else {
        this.setRangeCount(Math.ceil((this.pageCount - this.pageList) / 10) * 10)
      }
    } else if (page == this.rangeCount[0] - 1) {
      if (page > this.pageList) {
        this.setRangeCount(page - this.pageList + 1)
      } else {
        this.setRangeCount(1)
      }
    } else if (!this.rangeCount.includes(page)) {
      this.setRangeCount(page)
    }

    // set page
    this.page = this.pageCount > page ? page > 0 ? page : 1 : this.pageCount

    // emit offset
    this.offset.emit(page > 1 ? this.limit * page - this.limit : 0)

  }

  onChange($event: any) {
    this.goToPage(Number($event.target.value))
  }

  setRangeCount(number: number) {
    if (number) {
      this.rangeCount = Array(this.pageList).fill(1).map((_, index) => index + number);
      this.rangeCount = this.rangeCount.filter(r => r <= this.pageCount)
    }
  }

}
