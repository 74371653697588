<form action="" class="mx-auto mw-1000px w-100 pb-10 ng-untouched ng-pristine ng-valid bg-white form-container" style="padding:5px 45px; background-color: #fff;">
  
 
    <div class=" d-flex flex-column-fluid justify-content-center tab-header-container">
    
        <div class="tab-header" [ngStyle]="{'left.px': leftWidth,'transition': '10s all bounce;'}">
          
            <div class="tab" *ngFor="let item of stepListProcessed; let i = index">
               
                <span class="tab-number" [ngClass]="{'active': step == item.position}">
                    <span *ngIf="step == item.position" class="active-dot"></span>
                </span> 
               
                <span class="tab-text">{{item.step_title}}</span>
                <span class="dot-left" *ngIf="i != 0">.........</span> 
                <span class="dot-right" *ngIf="(i+1) != stepListProcessed.length">.......</span>
            </div>
            
            
        </div>

       
    </div>



    <app-shared-sms-verification
    [openPopup]="showModal"
    [ngClass]="{'d-none': !showModal}"
    (isConfirmed)="setVerification($event)"
    [sendType]="'sms'"
    [to]="userForm.value.gsm"
    [template]="'appointment_verification'"
    [sendDirectCode]="true"
    *ngIf="step == 2 && isStepCompleted && nextButtonClicked &&  !isPhoneVerified && messageCanSend"
    (closeModalEmitter)="closeModal($event)"
></app-shared-sms-verification>

    <div *ngFor="let item of stepList">
    
        <app-appointment-v3-hizmet-secimi
        *ngIf="step == item.position && item.service_name == 'app-appointment-v3-hizmet-secimi'"
        (orderTypeEmitter)="setOrderTypeId($event)">

        </app-appointment-v3-hizmet-secimi>
        <app-appointment-v3-arac-hizmet-secimi 
            *ngIf="step == item.position && item.service_name == 'app-appointment-v3-arac-hizmet-secimi'"
            [lastStep]="lastStep"
            [orderTypeId]="orderTypeId"
            [currentStep]="item.position"
            [userFormInput]="userForm.value"
            [vehicleInfoInput]="vehicleInfo"
            [vehicleFormInput]="vehicleForm"
            [orderTypeId]="orderTypeId"
            [packageList]="packageList"
            [selectedPackage]="selectedPackageInput"
            [stepBack]="stepBack"
            (packageKmListEmitter)="setPackageKmList($event)"
            [packageKmList]="packageKmList"
            (userInfoEmitter)="setUserInfoEmitter($event)"
            (packageEmitter)="setPackageEmitter($event)"
            (packageListEmitter)="setPackageList($event)"
            (stepControlEmitter)="setStepVariable($event)"
            (userFormEmitter)="userFormEmitter($event)"
            (vehicleFormEmitter)="vehicleFormEmitter($event)"
            [removeApproved]="removeApproved"
        >
        </app-appointment-v3-arac-hizmet-secimi>
 
        <app-appointment-randevusuz-kayit-v3
            *ngIf="step == item.position  && item.service_name == 'app-appointment-randevusuz-kayit-v3' && !vehicleForm.value.is_package_have"
            [vehicleForm]="vehicleForm"
            [userForm]="userForm"
            [orderTypeId]="orderTypeId"
        >
            
        </app-appointment-randevusuz-kayit-v3>
        <app-appointment-v3-paket-secimi
        *ngIf="step == item.position && item.service_name == 'app-appointment-v3-paket-secimi' && vehicleForm.value.is_package_have"   
        [appointmentForm]="appointmentFormGroup"
        [vehicleForm]="vehicleForm.value"
        [selectedPackageInput]="selectedPackageInput"
        (selectedPackageEmitter)="setStepVariable($event)"
        [stepBack]="stepBack"
        [userId]="userForm.value.id"
        (isStepBack)="getStepBack($event)"
        [userInfo]="userDbInfo"
        [sessionUserInfo]="sessionUserInfo"
        ></app-appointment-v3-paket-secimi>

        <app-appointment-v3-servis-secimi
        *ngIf="step == item.position && item.service_name == 'app-appointment-v3-servis-secimi'"
        (serviceEmitter)="setStepVariable($event)"
        [serviceInfoInput]="serviceInfoInput"
        ></app-appointment-v3-servis-secimi>

        <app-appointment-v3-randevu-olustur
        *ngIf="step == item.position && item.service_name == 'app-appointment-v3-randevu-olustur'"
        [companyId]="appointmentFormGroup.value.serviceId"
        (serviceDate)="setStepVariable($event)"
        [selectedDateInput]="appointmentFormGroup.value.serviceDate">
        
        </app-appointment-v3-randevu-olustur>

        <app-appointment-v3-odeme
        *ngIf="step == item.position && item.service_name == 'app-appointment-v3-odeme' && (selectedPaymentType == 1)" 
        (invoiceAddressFormEmitter)="setInvoiceAddressForm($event)"
        (paymentTypeEmitter)="setPaymentType($event)"
        [priceInfo]="amountInfo"
        [userId]="userForm.value.id"
        [userForm]="userForm"
        (returnData)="setStepVariable($event)"
        [orderCardId]="orderCardId"
        (paymentEmitter)="createOrders($event)"
        [firstnameInput]="userForm.value.firstname"
        [lastnameInput]="userForm.value.lastname"
        [emailInput]="userForm.value.email"
        [phoneInput]="userForm.value.phone"
        [paymentType]="appointmentFormGroup.value.paymentType"
        >
        </app-appointment-v3-odeme>

       


        <app-appointment-odemesiz-randevu-v3
        *ngIf="step == item.position && item.service_name == 'app-appointment-v3-odeme' && selectedPaymentType != 1"
        (invoiceAddressFormEmitter)="setInvoiceAddressForm($event)"
        (createOrderEmitter)="createOrders(undefined)"
        [userId]="userForm.value.id"
        [firstnameInput]="userForm.value.firstname"
        [lastnameInput]="userForm.value.lastname"
        [emailInput]="userForm.value.email"
        [phoneInput]="userForm.value.phone"
        [randevuButtonDisabled]="randevuButtonDisabled"
        >

        </app-appointment-odemesiz-randevu-v3>

        <app-appointment-v3-odeme-basarili  
        *ngIf="step == item.position && item.service_name == 'app-appointment-v3-odeme-basarili'"

            [userForm]="userForm"
            [orderId]="orderId"
           
            >
        </app-appointment-v3-odeme-basarili>
    </div>

    

  
    <div class="d-flex justify-content-between py-5 btn-container" >
        <div class="form-content-container back" *ngIf="step != 7">
          
            <button class="btn btn-primary btn-sm form-button" 
            (click)="stepChange('previous')"
            *ngIf="step != stepList.length && step != 2"
            >Geri</button>
            

            <button class="btn btn-primary btn-sm form-button" 
            [routerLink]="'/teklif'"
            *ngIf="step != stepList.length && step == 2"
            >Geri</button>
          
        </div>
        <div class="form-content-container proforma" *ngIf="step == 3 && selectedPackageInfo" >
          
          <app-shared-modal-proforma 
            [packageInfo]="selectedPackageInfo"
            [customerInfo]="customerInfo"
            [modelTypeId]="appointmentFormGroup.value.model_type_id"
            [vehicleInfo]="vehicleInfo"
            
    
            ></app-shared-modal-proforma>

        </div>
        <div class="form-content-container next"  *ngIf="step != 7">
         
            <button class="btn btn-primary btn-sm form-button" 
               
                (click)="stepChange('next')"
                [disabled]="stepList && step == stepList.length  || step == 5 || step == 6"
                >İlerle</button>
        </div>

        <div class="form-content-container "   *ngIf="step == 7 && router.url != '/teklif' && (authService.userRole == 'admin' || authService.userRole == 'service')">
            <a class="btn btn-primary btn-sm form-button" 
              
            [routerLink]="[orderEditUrl, orderId]"
               
                >Randevu Sayfasına Git</a>
        </div>
       
    </div>
    

</form>

