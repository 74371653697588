<style>
    .active-link{
        color: #009EF7 !important;
    }
    .active-link>span{
        color: #009EF7 !important;
    }
    .help-desk{
        font-size: 16px !important;
    font-family: "Open Sans", sans-serif !important;
    color: #004422 !important;
    font-weight: 600 !important;
    margin-left: 10px !important;
    position: relative !important;
    cursor: pointer !important;
    background-color: #99CC11;
    font-family: "Open Sans", sans-serif;
    color: #FFFFFF;
    font-size: 16px;
    border-radius: 2px;
    line-height: 22px;
    font-weight: 600;
    display: inline-block;
    padding: 2px 15px;
    -webkit-box-shadow: 0 3px 10px #99CC1177;
    box-shadow: 0 3px 10px #99CC1177;
}

.help-desk:hover{
  background-color: #004422;
  color: #99CC11 !important;
  border: 0px !important;
  -webkit-box-shadow: 0px !important;
    box-shadow: 0px !important;
}

.menu-link.active{
  color: #009EF7;
}


.menu-title{
  color: #5E6278;
  font-weight: 500 !important;
}

.menu-title.active{
  color: #009EF7;
}
    
    #tb-container{
        display: block;
    }

    #tb-container-mobile{
            display: none;
        }

    @media only screen and (max-width: 992px) {
        #tb-container{
        display: none;
        }
        #tb-container-mobile{
            display: block;
            flex-direction: row;
            background-color: #fff;
            position: fixed;
            z-index: 10;
            width: 100%;
        }

     

        .tb-container{
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            align-items: center;
             width: 240px; 
            padding: 10px 17px 0px 17px;
            margin-left: auto;
            list-style: none;
        }

        .tb-item{
            margin-left: auto;
            
        }

        .tb-item i{
            font-size: 25px;
            color: #004422;
        }

       
    }
</style>
<div class="toolbar-container" id="tb-container">
    <div class="toolbar" style="top: 35px !important;">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                    <span class="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                    <small class="text-muted fs-7 fw-bold my-1 ms-1"></small>
                </h1>
            </div>
        <!--begin::Navbar-->
        <div class="d-flex align-items-stretch" id="kt_header_nav">
            <!--begin::Menu wrapper-->
            <div 
            id="kt-head"
            class="header-menu align-items-stretch" 
            data-kt-drawer="false" 
            data-kt-drawer-name="header-menu" 
            data-kt-drawer-activate="{default: true, lg: false}" 
            data-kt-drawer-overlay="true" 
            data-kt-drawer-width="{default:'200px', '300px': '250px'}" 
            data-kt-drawer-direction="end" 
            data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" 
            data-kt-swapper="true" 
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
                <!--begin::Menu-->
                <div 
                [ngClass]="{'show':garanti_nedir}"
                class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch  menu-dropdown" id="#kt_header_menu" data-kt-menu="true">
                    <div 
                    (click)="toggleMenu('garanti_nedir')"
                    data-kt-menu-trigger="click" 
                    data-kt-menu-placement="bottom-start" 
                    class="menu-item menu-lg-down-accordion me-lg-1  menu-dropdown" 
                    [ngClass]="{'show':garanti_nedir }"
                    style="position: relative;">
                        <span class="menu-link py-3">
                            <span class="menu-title">Gos Nedir?</span>
                            <span class="menu-arrow d-lg-none"></span>
                        </span>
                        <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px show" style="position: absolute; top: 60px;">
                            <div class="menu-item">
                                <a class="menu-link py-3" href="https://www.garantiliotoservis.com/garanti-nedir">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">Otomobil Garantisi</span>
                                </a>
                            </div>
                            <div class="menu-item">
                                <a class="menu-link py-3" href="https://www.garantiliotoservis.com/hakkiniz-guvende">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">Hakkınız Güvende</span>
                                </a>
                            </div>
                            <div class="menu-item">
                                <a class="menu-link py-3" href="https://www.garantiliotoservis.com/kalite-standartlarimiz">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">Kalitede Standartlarımız</span>
                                </a>
                            </div>
                            <div class="menu-item">
                                <a class="menu-link py-3" href="https://www.garantiliotoservis.com/garantili-servis-manifestosu">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">Garantili Servis Manifestosu</span>
                                </a>
                            </div>
                            
                        </div>
                    </div>
                    <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-lg-1 ">
                    <a href="https://www.garantiliotoservis.com/hizmetlerimiz">
                        <span class="menu-link py-3">
                            <span class="menu-title">Hizmetlerimiz</span>
                            <span class="menu-arrow d-lg-none"></span>
                        </span>
                        </a>
                    </div>
                    <div data-kt-menu-trigger="click" 
                    data-kt-menu-placement="bottom-start" 
                    class="menu-item menu-lg-down-accordion me-lg-1 here show ">
                        <a href="https://www.garantiliotoservis.com/garantili-servisler" class="active-link" >
                            <span class="menu-link py-3 ">
                                <span class="menu-title">Garantili Servisler</span>
                                <span class="menu-arrow d-lg-none"></span>
                            </span>
                        </a>
                    </div>
                    <div 
                    (click)="toggleMenu('faydali_bilgiler')"
                    data-kt-menu-trigger="click" 
                    data-kt-menu-placement="bottom-start" 
                    class="menu-item menu-lg-down-accordion me-lg-1  menu-dropdown" 
                    [ngClass]="{'show':faydali_bilgiler }"
                    style="position: relative;">
                        <span class="menu-link py-3">
                            <span class="menu-title">Bilgi Bankası</span>
                            <span class="menu-arrow d-lg-none"></span>
                        </span>
                        <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px show" style="position: absolute; top: 60px;">
                            <div class="menu-item">
                                <a class="menu-link py-3" href="https://www.garantiliotoservis.com/bilgi-bankasi">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">Bilgi Bankası</span>
                                </a>
                            </div>
                            <div class="menu-item">
                                <a class="menu-link py-3" href="https://www.garantiliotoservis.com/sss">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">Sıkça Sorulan Sorular</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div 
                    (click)="toggleMenu('kurumsal')"
                    data-kt-menu-trigger="click" 
                    data-kt-menu-placement="bottom-start" 
                    class="menu-item menu-lg-down-accordion me-lg-1  menu-dropdown" 
                    [ngClass]="{'show':kurumsal }"
                    style="position: relative;">
                        <span class="menu-link py-3">
                            <span class="menu-title">Kurumsal</span>
                            <span class="menu-arrow d-lg-none"></span>
                        </span>
                        <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px show" style="position: absolute; top: 60px;">
                            <div class="menu-item">
                                <a class="menu-link py-3"  href="https://www.garantiliotoservis.com/hakkimizda">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">Garantili Oto Servis</span>
                                </a>
                            </div>
                            <div class="menu-item">
                                <a class="menu-link py-3" href="https://www.garantiliotoservis.com/vizyon-ve-degerler">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">Vizyon ve Değerlerimiz</span>
                                </a>
                            </div>

                            <div class="menu-item">
                                <a class="menu-link py-3" href="https://www.garantiliotoservis.com/servis-uyeligi">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">Servis Üyeliği</span>
                                </a>
                            </div>

                            <div class="menu-item">
                                <a class="menu-link py-3" href="https://www.garantiliotoservis.com/kariyer">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">Kariyer</span>
                                </a>
                            </div>

                            <div class="menu-item">
                                <a class="menu-link py-3" href="https://www.garantiliotoservis.com/iletisim">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">İtelişim</span>
                                </a>
                            </div>
                        </div>
                    </div>
                
                    <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-lg-1">
                        <span class="py-3">
                            <span class="menu-title">
                              <a href="http://www.garantiliotoservis.com/destek-hatti" class="btn btn-sm small-btn  help-desk d-none"  style="margin-left: 5px;" >Destek Hattı1</a>
                              <a href="https://api.whatsapp.com/send?phone=908504806655" class="whatsapp" target="_blank">
                                <img style="width:60px;" src="https://demobul.net/images/whatsapp.png" alt="whatsapp">
                                <span>Whatsapp</span>
                              </a>
                            </span>
                        </span>
                    </div>
                </div>
                <!--end::Menu-->
                
            </div>
            <!--end::Menu wrapper-->

            
        </div>
        <!--end::Navbar-->
          
        </div>
    </div>
</div>


<div class="toolbar-container" id="tb-container-mobile">
    <ul class="tb-container">
       
        <li class="tb-item">                            
            <span class="menu-title d-none"><a class="btn btn-sm small-btn  help-desk "  style="margin-left: 5px;" >Destek Hattı2 </a></span>
            <a href="https://api.whatsapp.com/send?phone=908504806655" class="whatsapp" target="_blank">
              <img style="width:60px;" src="https://demobul.net/images/whatsapp.png" alt="whatsapp">
              <span>Whatsapp</span>
            </a>
        </li>
        <li class="tb-item">
            <a (click)="openDrawer('showDestekMenu')">
                <i class="fa fa-bars"></i>
            </a>
        </li>

    </ul>
</div>

<!-- begin::Wrapper -->
<div
class="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
>

<app-shared-drawer *ngIf="drawerPage == 'showDestekMenu'" [show]="showDrawer" [title]="'Garantili Oto Sistemleri'" (toggleDrawer)="toggleDrawer()">
  
 <!-- MegaMenu -->
<div
class="menu-item menu-lg-down-accordion me-lg-1"
data-kt-menu-trigger="click"
data-kt-menu-placement="bottom-start">
<span class="menu-link py-3" (click)="showSub($event)"><span class="menu-title">Garanti Nedir?</span></span>
<div class="
    menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown
    w-100 w-lg-600px
    p-5 p-lg-5
  "
  data-kt-menu-dismiss="true"
>
  <div class="row" data-kt-menu-dismiss="true">
    <div class="col-lg-4 border-left-lg-1">
      <div class="menu-inline menu-column menu-active-bg">
        <div class="menu-item">
          <a class="menu-link cursor-pointer" href="http://www.garantiliotoservis.com/garanti-nedir"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Otomobil Garantisi</span></a
          >
        </div>
        <div class="menu-item">
          <a class="menu-link cursor-pointer"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Hakkınız Güvende</span></a
          >
        </div>
        <div class="menu-item">
          <a class="menu-link cursor-pointer"
          href="http://www.garantiliotoservis.com/kalite-standartlarimiz"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Kalite Standartlarımız</span></a
          >
        </div>
        <div class="menu-item">
          <a class="menu-link cursor-pointer"
          href="http://www.garantiliotoservis.com/garantili-servis-manifestosu"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Garantili Servis Manifestosu</span></a
          >
        </div>
        
      </div>
    </div>
  </div>
</div>
</div>

<!-- Layout Builder -->
<div class="menu-item me-lg-1">
  <a class="menu-link py-3" href="http://www.garantiliotoservis.com/hizmetlerimiz"
    ><span class="menu-title">Hizmetlerimiz</span></a
  >
</div>

<!-- Layout Builder -->
<div class="menu-item me-lg-1">
  <a class="menu-link py-3 active-link" href="https://www.garantiliotoservis.com/garantili-servisler"
    ><span class="menu-title">Garantili Servisler</span></a
  >
</div>

 <!-- MegaMenu -->
 <div
 class="menu-item menu-lg-down-accordion me-lg-1"
 data-kt-menu-trigger="click"
 data-kt-menu-placement="bottom-start">
 <span class="menu-link py-3" (click)="showSub($event)"><span class="menu-title">Faydalı Bilgiler?</span></span>
 <div class="
     menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown
     w-100 w-lg-600px
     p-5 p-lg-5
   "
   data-kt-menu-dismiss="true"
 >
   <div class="row" data-kt-menu-dismiss="true">
     <div class="col-lg-4 border-left-lg-1">
       <div class="menu-inline menu-column menu-active-bg">
         <div class="menu-item">
           <a class="menu-link cursor-pointer" href="http://www.garantiliotoservis.com/bilgi-bankasi"
             ><span class="menu-bullet"
               ><span class="bullet bullet-dot"></span></span
             ><span class="menu-title">Bilgi Bankası</span></a
           >
         </div>
         <div class="menu-item">
           <a class="menu-link cursor-pointer"
           href="http://www.garantiliotoservis.com/sss"
             ><span class="menu-bullet"
               ><span class="bullet bullet-dot"></span></span
             ><span class="menu-title">Sıkça Sorulan Sorular</span></a
           >
         </div>
       </div>
     </div>
   </div>
 </div>
 </div>

 <!-- MegaMenu -->
 <div
 class="menu-item menu-lg-down-accordion me-lg-1"
 data-kt-menu-trigger="click"
 data-kt-menu-placement="bottom-start">
 <span class="menu-link py-3" (click)="showSub($event)"><span class="menu-title">Kurumsal</span></span>
 <div class="
     menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown
     w-100 w-lg-600px
     p-5 p-lg-5
   "
   data-kt-menu-dismiss="true"
 >
   <div class="row" data-kt-menu-dismiss="true">
     <div class="col-lg-4 border-left-lg-1">
       <div class="menu-inline menu-column menu-active-bg">
         <div class="menu-item">
           <a class="menu-link cursor-pointer" href="http://www.garantiliotoservis.com/hakkimizda"
             ><span class="menu-bullet"
               ><span class="bullet bullet-dot"></span></span
             ><span class="menu-title">Garantili Oto Servis</span></a
           >
         </div>
         <div class="menu-item">
           <a class="menu-link cursor-pointer"
           href="http://www.garantiliotoservis.com/vizyon-ve-degerler"
             ><span class="menu-bullet"
               ><span class="bullet bullet-dot"></span></span
             ><span class="menu-title">Vizyon ve Değerlerimiz</span></a
           >
         </div>
         <div class="menu-item">
          <a class="menu-link cursor-pointer"
          href="http://www.garantiliotoservis.com/servis-uyeligi"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Servis Üyeliği</span></a
          >
        </div>

        <div class="menu-item">
          <a class="menu-link cursor-pointer"
          href="http://www.garantiliotoservis.com/kariyer"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Kariyer</span></a
          >
        </div>

        <div class="menu-item">
          <a class="menu-link cursor-pointer"
          href="http://www.garantiliotoservis.com/yardimlasma"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Yardımlaşma</span></a
          >
        </div>

        <div class="menu-item">
          <a class="menu-link cursor-pointer"
          href="http://www.garantiliotoservis.com/iletisim"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">İletişim</span></a
          >
        </div>
        
       </div>
     </div>
   </div>
 </div>
 </div>


  
  

</app-shared-drawer>
<!-- begin::Navbar -->

</div>
<!-- end::Wrapper -->