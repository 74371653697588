import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertifyService } from 'src/app/services/alertify.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shared-sms-verification',
  templateUrl: './sms-verification.component.html',
  styles: [
  ]
})
export class SmsVerificationComponent implements OnInit {

  @Input() openPopup: boolean = false;  
  @Input() template: string | undefined;  
  @Input() sendType: string | undefined;  
  @Input() to: string | undefined;  
  @Input() sendDirectCode:boolean = false;
  @Input() maxLength: number = 4;
  @Output() isConfirmed = new EventEmitter<boolean>();
  @Output() closeModalEmitter = new EventEmitter<boolean>();
  public verifyCode = "";
  public isDisabled:boolean=false;
  public isButtonDisabled:boolean=true;
  public showCodeSender:boolean=false;
  public showCodeSenderMessage:boolean=false;
  public codeSendTime:number = 60;
  private apiUrl: string = environment.laravelApiEndpoint;
  private verifyId: number | undefined;

  constructor(
    private http: HttpClient,
    private alertifyService: AlertifyService
  ) { }

  ngOnInit(): void {

    if(this.sendDirectCode){
      this.sendVerifyCode();
    }
    

  }

  startTimer() {
    this.showCodeSenderMessage = true;
    this.showCodeSender = false;

    setInterval(() => {
      if(this.codeSendTime > 0) {
        this.codeSendTime--;
      } else {
        this.showCodeSenderMessage = false;
        this.showCodeSender = true;
        this.codeSendTime = 60;
      }
    },1000)
  }

  async sendVerifyCode(){
    this.isButtonDisabled = true;
    await this.http.post<any>(this.apiUrl + "verification/send",
    {
      "send_type":this.sendType,
      "to": this.to,
      "template": this.template
    }, 
    { headers: { token: `${localStorage.getItem('access_token')}` } }).subscribe(async resp=>{
      
      if(resp.status){
        this.verifyId = resp.result.id;
       
      }else{
        this.alertifyService.error(resp.message)
        this.isButtonDisabled = false;
      
      }
    });
  

    this.startTimer();
  }

  setVerifyCode(event: any){
    this.verifyCode = event.target.value;
    
    if(this.verifyCode.length > 2){
      this.isButtonDisabled = false;
    }
  
  }

  async verifySms(){


    if(this.verifyCode && this.verifyCode.length == 4){
      this.isDisabled = true;
      this.isButtonDisabled = true;
      if(this.verifyId){

        await this.http.post<any>(this.apiUrl + "verification/verify",
        {
          "id":this.verifyId,
          "code": this.verifyCode
         
        }, 
        { headers: { token: `${localStorage.getItem('access_token')}` } }).subscribe(async resp=>{
        
              if(resp.result?.status){
                this.isConfirmed.emit(true);
              }
              else{
                this.alertifyService.error(resp.message)
                this.isButtonDisabled = false;
                this.isDisabled = false;
                this.isConfirmed.emit(false);
              }
           

        });

      }
     
    }
    
  }

  closePopup(event:any){
    this.openPopup = false;
    this.closeModalEmitter.emit(true);
  }

}
