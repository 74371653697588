import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-shared-multiple-select',
  templateUrl: './multiple-select.component.html',
  styles: [
    'option {  background-color: white; padding: 4px;   color: blue;  margin-bottom: 1px;font-size: 18px; cursor: pointer; }'
  ]
})
export class MultipleSelectComponent implements OnInit {
  @Input() lists!: any;
  @Input() selectName: string = ""
  @Input() name!: string;
  @Output() selected = new EventEmitter<any | []>()
  @Input() selectedItems:any;
  selectedIds: any = [];
  public id: string = "";


  constructor() {}

  ngOnInit(): void {
    this.id = this.name.replace(' ', '-').toLocaleLowerCase();
    
  }

  inputValue($event: any) {
    const exists = this.lists.filter((obj: { name: any; }) =>obj.name === $event.target.value );
if(this.selectedItems == undefined){
  this.selectedItems = [];
}
    let isSelected = ( this.selectedItems.length > 0) ? this.selectedItems.filter((obj: { name: any; }) => obj.name === $event.target.value ): [];
    if (exists.length > 0) {
      $event.target.classList.remove('ng-invalid')
      $event.target.classList.add('ng-dirty', 'ng-valid')
     
      if(isSelected.length == 0){
        this.selectedItems.push(exists[0]);
        this.selectedIds.push(exists[0].id);
        $event.target.value = "";
      }

    } else {
      $event.target.classList.remove('ng-dirty')
      $event.target.classList.add('ng-invalid', 'ng-touched')
    }

    this.selected.emit(this.selectedIds);
  }

  removeList(removeId: bigint){
    const index = this.selectedItems.findIndex((obj: { id: bigint; }) => obj.id === removeId);
    this.selectedItems.splice(index,1);
    this.selectedIds.splice(index,1);
    this.selected.emit(this.selectedIds);
  }
 

}
