<style>
    .privacy p {
        font-size: 16px;
    }

    li {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 16px;
    }
</style>
<!--begin::Post-->
<div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">


        <div class="row gy-5 g-xl-8">

            <!--begin::Col-->
            <div class="col-xl-12 mb-5">
                <!--begin::Mixed Widget 2-->
                <div class="card card-xl-stretch">

                    <!--begin::Body-->
                    <div class="card-body p-0">

                        <!--begin::Stats-->
                        <div class="card position-relative hero-section">
                            <!--begin::Row-->
                            <div class="row g-0"
                                style="background-color: #F3F3F3; background-image:url(https://cdn.destechhasar.com/garantili-oto-servis/static/garantili-oto-servis-01-2000x1200.jpeg); background-repeat: no-repeat; background-position-x: right; background-position-y: bottom;">
                                <!--begin::Col-->
                                <div class="col px-6 py-8 rounded-2 me-7 mb-7" style=" display: table;">
                                </div>
                                <!--end::Col-->
                                <!--begin::Col-->
                                <div class="col-xl-6 px-6 py-8 rounded-2 mb-7">


                                </div>
                                <!--end::Col-->
                            </div>
                            <!--end::Row-->

                        </div>
                        <!--end::Stats-->
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Mixed Widget 2-->
            </div>
            <!--end::Col-->


        </div>
        <!--end::Row-->

        <div class="row gy-5 g-xl-8">

            <!--begin::Col-->
            <div class="col-xl-12 col-xxl-12 mb-5 mb-xl-10">
                <!--begin::Table Widget 3-->
                <div class="card card-flush h-xl-100">
                    <!--begin::Card header-->
                    <div class="card-header py-5">
                        <!--begin::Tabs-->
                        <div class="card-title pt-3 mb-0 gap-4 gap-lg-10 gap-xl-15 nav nav-tabs border-bottom-0"
                            data-kt-table-widget-3="tabs_nav">

                            <h2 style="font-weight: 600;
                            font-size: 32px;
                            color: #004422;
                            margin: 0 0 30px; ">Kullanıcı Sözleşmesi</h2>
                        </div>
                        <!--end::Tabs-->

                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body pt-1">
                        <!--begin::Sort & Filter-->
                        <div class="d-flex flex-stack flex-wrap gap-4 privacy">

                            <ol class="flex">
                                <li>
                                    <p>Taraflar</p>

                                    İşbu Üyelik sözleşmesi, Cevizli Mahallesi Tugay Yolu Caddesi No 59 Maltepe -
                                    İstanbul adresinde mukim Garantili Oto Servis Oto Yönetim ve Destek Hizmetleri A.Ş.
                                    (bundan böyle Garantili Oto Servis olarak anılacaktır) ile siteye Üye olan Üye
                                    arasında, Üyenin www.garantiliotoservis.com alan adlı internet sitesi ve mobil
                                    uygulama (bundan böyle Garantili Oto Servis olarak anılacaktır) üzerinden kendi
                                    iradesi ile yaptığı Üye kaydı aşamasında yapılmıştır. Üye, Garantili Oto Servis’a
                                    Üye olmakla, Sözleşmenin tamamını okuduğunu, anladığını ve tüm hükümlerini
                                    onayladığını kabul, beyan ve taahhüt eder.

                                    İşbu Üyelik sözleşmesinde Garantili Oto Servis ve Üye ayrı ayrı Taraf, birlikte
                                    Taraflar olarak anılacaktır.

                                </li>
                                <li>
                                    <p>
                                        TANIMLAR
                                    </p>
                                    <ol>
                                        <li>
                                            Garantili Oto Servis: Garantili Oto Servis OTO YÖNETİM VE DESTEK HİZMETLERİ
                                            A.Ş. ve www.garantiliotoservis.com alan adı üzerinden yayın yapan web
                                            sitesi ve Garantili Oto Servis uygulama adıyla Apple IOS ve Google Android
                                            işletim sistemi üzerinden yayın yapan mobil uygulama.
                                        </li>
                                        <li>
                                            Kullanıcı: Garantili Oto Servis’a erişen her gerçek veya tüzel kişi.
                                        </li>
                                        <li>
                                            Üye: Garantili Oto Servis'un HİZMET PLATFORM’u üzerinden sunmuş olduğu
                                            HİZMETLER’i satın alarak, talep ederek, kiralayarak, abone olarak ya da
                                            ücretsiz olarak yararlanan gerçek ve tüzel kişileri ifade eder.
                                        </li>
                                        <li>
                                            Çağrı Merkezi: Garantili Oto Servis’da sunulan hizmetlere ilişkin müşteri
                                            iletişim hattı ve işlemleri yürüten kişi ve/veya kişiler.
                                        </li>
                                        <li>
                                            Hizmetler: Garantili Oto Servis içerisinde Üyenin işbu Üyelik Sözleşmesi
                                            içerisinde tanımlı olan iş ve işlemlerini gerçekleştirmelerini sağlamak
                                            amacıyla Garantili Oto Servis tarafından sunulan uygulama ve yazılımlar.
                                        </li>
                                        <li>
                                            Talep: Garantili Oto Servis üzerinde Üyenin hizmet satın alma veya sunulan
                                            yazılımdan faydalanma süreçlerini tamamlaması.
                                        </li>
                                        <li>
                                            Araç: Üyenin Garantili Oto Servis üzerinde kaydettiği araç ve/veya araçlar.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>SÖZLEŞMENİN KONUSU VE KAPSAMI</p>

                                    İşbu Üyelik Sözleşmesinin konusu, Garantili Oto Servis’da sunulan Hizmetlerin, bu
                                    Hizmetlerden yararlanma şartları ile tarafların hak ve yükümlülüklerinin tespitidir.
                                    Üyelik Sözleşmesi ve ekleri ile Garantili Oto Servis içerisinde yer alan kullanıma,
                                    Üyeliğe ve Hizmetlere ilişkin Garantili Oto Servis tarafından yapılan tüm uyarı,
                                    bildirim, uygulama, açıklama ve hizmet içeriği değişikliği gibi beyanlar kapsam
                                    dâhilindedir. Kullanıcı, işbu Üyelik Sözleşmesi hükümlerini kabul etmekle, Garantili
                                    Oto Servis içinde yer alan kullanıma, Üyeliğe ve hizmetlere ilişkin Garantili Oto
                                    Servis tarafından açıklanan her türlü beyana uygun davranacağını kabul ve taahhüt
                                    etmektedir.

                                </li>
                                <li>
                                    <p>ÜYELİK ŞARTLARI</p>
                                    <ol>
                                        <li>Garantili Oto Servis’a Üye olabilmek için reşit olmak ve Garantili Oto
                                            Servis tarafından işbu Üyelik Sözleşmesi kapsamında geçici olarak Üyelikten
                                            uzaklaştırılmamış, Üyeliği askıya alınmamış veya Üyelikten süresiz
                                            yasaklanmamış olmak gerekmektedir.</li>
                                        <li>Garantili Oto Servis herhangi bir zamanda gerekçe göstermeden, bildirimde
                                            bulunmadan, tazminat, ceza vb. sair yükümlülüğü bulunmaksızın derhal
                                            yürürlüğe girecek şekilde işbu Üyelik Sözleşmesini tek taraflı olarak
                                            feshedebilir, Üyenin Üyeliğine son verebilir veya geçici olarak
                                            durdurabilir. Garantili Oto Servis’da belirtilen kurallara aykırılık
                                            halleri, Üyenin Garantili Oto Servis bilgi güvenliği sistemine risk
                                            oluşturması halleri Üyeliğe son verme veya Üyeliği geçici durdurma
                                            hallerindendir.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>TARAFLARIN HAK VE YÜKÜMLÜLÜKLERİ</p>
                                    <ol>
                                        <li>
                                            <p>ÜYENİN HAK VE YÜKÜMLÜLÜKLERİ</p>
                                            <ol>
                                                <li>
                                                    Üye, Garantili Oto Servis’te belirtilen kurallara, beyanlara,
                                                    yürürlükteki tüm mevzuata ve ahlak kurallarına uygun hareket
                                                    edeceğini, Üyelik Sözleşmesi hükümleri ile Garantili Oto Servis’daki
                                                    tüm şart ve kuralları anladığını ve onayladığını kabul ve taahhüt
                                                    etmektedir.
                                                </li>
                                                <li>
                                                    Üye, Üyelik işlemlerinde belirtmiş olduğu kimlik, adres ve iletişim
                                                    bilgilerinin eksiksiz ve doğru olduğunu, bilgilerinde değişiklik
                                                    olması halinde bu bilgileri derhal Garantili Oto Servis’a
                                                    ileteceğini, eksik, güncel olmayan veya yanlış bilgi vermesi
                                                    nedeniyle ortaya çıkabilecek her türlü hukuki uyuşmazlık ve zarardan
                                                    sadece kendisinin sorumlu olacağını kabul ve beyan eder. Garantili
                                                    Oto Servis bu nedenle hiçbir sorumluluk izafe edilemez.
                                                </li>
                                                <li>
                                                    Üye, Garantili Oto Servis’in yürürlükteki mevzuat hükümleri
                                                    gereğince resmi makamlara açıklama yapmakla yükümlü olduğu
                                                    durumlarda; Üyelere ait gizli/özel/ticari bilgileri resmi makamlara
                                                    açıklamaya yetkili olduğunu, bu sebeple Garantili Oto Servis’ten her
                                                    ne nam altında olursa olsun tazminat talep etmeyeceğini kabul ve
                                                    taahhüt etmektedir. Bunun haricinde Üyenin Garantili Oto Servis
                                                    üzerinde verdiği hizmet talepleriyle ilgili olarak herhangi bir kişi
                                                    ya da kurumun haklarının ihlal edildiği iddiası ile Garantili Oto
                                                    Servis’a bildirimde bulunması, yargı yoluna başvuracağını bildirmesi
                                                    halinde; Üyenin kendisine bildirdiği ad-soyadı ve iletişim bilgisini
                                                    Garantili Oto Servis ilgili tarafa verebilir.
                                                </li>
                                                <li>
                                                    Üyelerin, Garantili Oto Servis Üyelik Hesabına girişte kullandıkları
                                                    kullanıcı adı ve şifrenin güvenliğini sağlamaları, münhasıran ve
                                                    münferiden kendileri tarafından kullanılmasını temin etmeleri,
                                                    üçüncü kişilerden saklamaları tamamen kendi sorumluluğundadır. Bu
                                                    konuda ihmal veya kusurlarından dolayı diğer Üyelerin ve/veya
                                                    Garantili Oto Servis’un ve/veya üçüncü kişilerin uğradığı veya
                                                    uğrayabileceği maddi ve/veya manevi her tür zararlardan Üye
                                                    sorumludur.
                                                </li>
                                                <li>
                                                    Üye, Garantili Oto Servis dâhilinde kendisi tarafından sağlanan
                                                    bilgilerin ve içeriklerin doğru ve hukuka uygun olduğunu,
                                                    yürürlükteki mevzuat doğrultusunda herhangi bir hukuka aykırılık
                                                    yaratmadığını kabul ve taahhüt etmektedir. Garantili Oto Servis, Üye
                                                    tarafından Garantili Oto Servis’a iletilen veya Garantili Oto Servis
                                                    üzerinden Üye tarafından yüklenen bilgilerin ve içeriklerin
                                                    doğruluğunu araştırmakla yükümlü ve sorumlu olmadığı gibi, söz
                                                    konusu bilgi ve içeriklerin yanlış veya hatalı olmasından dolayı
                                                    ortaya çıkacak hiçbir zarardan sorumlu tutulamaz.
                                                </li>
                                                <li>
                                                    Üye, Garantili Oto Servis ‘un yazılı onayı olmadan işbu Üyelik
                                                    Sözleşmesini veya bu Üyelik Sözleşmesinin kapsamındaki hak ve
                                                    yükümlülüklerini kısmen veya tamamen herhangi bir üçüncü kişiye
                                                    devredemez.
                                                </li>
                                                <li>
                                                    Garantili Oto Servis’da, Garantili Oto Servis’dan tamamen bağımsız
                                                    ve Garantili Oto Servis’un kendi kontrolünde olmayan üçüncü kişilere
                                                    ait başka web sitelerine linkler bulunabilir. Garantili Oto Servis
                                                    bu sitelerde bulunan bilgilerin doğruluğu garanti etmez. Bu linkler
                                                    vasıtasıyla erişilen web sitelerinden sunulan hizmetler/ürünler veya
                                                    bunların içeriği hakkında Garantili Oto Servis'un herhangi bir
                                                    sorumluluğu bulunmamaktadır. Üye’nin bu web sitelerine erişimi
                                                    tamamen kendi sorumluluğunda ve Garantili Oto Servis’un izni
                                                    dışındadır.
                                                </li>
                                                <li>
                                                    Üye, Garantili Oto Servis’dan hizmet almak için, üzerinde işlem
                                                    yaptığı Araç’tan sorumlu olup, Araç’ın başka bir gerçek veya tüzel
                                                    kişiye ait olma durumu ya da Araç kaynaklı bir problem yaşandığında
                                                    Garantili Oto Servis’dan her ne nam altında olursa olsun tazminat
                                                    talep etmeyeceğini kabul ve taahhüt etmektedir.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p>GARANTİLİ OTO SERVİS'İN HAK VE YÜKÜMLÜLÜKLERİ</p>
                                            <ol>
                                                <li>
                                                    Garantili Oto Servis, kontrol ve denetimi altındaki kişisel kimlik,
                                                    adres, iletişim bilgilerinin kaybolmasını, suiistimal edilmesini ve
                                                    değiştirilmesini engellemek amacıyla makul güvenlik önlemleri
                                                    almaktadır. Ancak Garantili Oto Servis, bu bilgilerinin güvenliğini
                                                    hiçbir şekilde garanti etmez. Üye’nin Garantili Oto Servis’a
                                                    aktardığı bilgi ve veriler gizli bilgi şeklinde yorumlanmayacaktır.
                                                </li>
                                                <li>
                                                    Garantili Oto Servis güvenlik nedeniyle Üye'nin Garantili Oto Servis
                                                    üzerindeki her türlü aktivitesini izleyebilir, kayda alabilir ve
                                                    gerekli gördüğünde, Garantili Oto Servis’dan uzaklaştırma, Üyelik
                                                    dondurma, Üyelik iptal etme ve benzeri her türlü müdahalede
                                                    bulunabilir.
                                                </li>
                                                <li>
                                                    Garantili Oto Servis önceden üyeye bildirimde bulunmaksızın
                                                    Garantili Oto Servis’un biçim ve içeriğini kısmen veya tamamen
                                                    değiştirebileceği gibi, Garantili Oto Servis’da yayın yaptığı alan
                                                    adını değiştirebilir, farklı alt alan adları kullanabilir, alan adı
                                                    yönlendirmesi yapabilir veya alan adını kapatabilir.
                                                </li>
                                                <li>
                                                    Garantili Oto Servis dilediği zamanda ve sebep göstermeksizin,
                                                    önceden üyeye bilgi vermeksizin Garantili Oto Servis’da sunduğu
                                                    hizmetlerin kapsam ve çeşitlerini değiştirebileceği gibi, Garantili
                                                    Oto Servis’da sunulan hizmetleri kısmen veya tamamen dondurabilir,
                                                    sona erdirebilir veya tamamen iptal edebilir.
                                                </li>
                                                <li>
                                                    Garantili Oto Servis, sözleşmede belirtilen iş ve işlemlerin daha
                                                    etkin gerçekleştirilebilmesi açısından dilediği zaman hizmet,
                                                    işleyişte ve ücretlendirmede değişiklikler ve/veya güncellemeler
                                                    yapabilir. Üye, işbu değişiklikleri kabul ettiğini, bu
                                                    değişikliklere uygun davranacağını şimdiden kabul ve beyan eder.
                                                </li>
                                                <li>
                                                    Site’de Üye’ye sunulacak olan teklif, Garantili Oto Servis
                                                    tarafından Üye’ye hizmet verme taahhüdünde bulunulduğu anlamına
                                                    gelmez. Üye, verilen fiyat teklifini kabul etse dahi Garantili Oto
                                                    Servis bu konuda tercih hakkına sahip olup herhangi bir neden
                                                    göstermeksizin hizmeti vermemekte serbesttir. Garantili Oto
                                                    Servis’un da hizmet alımını onaylaması durumunda, Üye ile Garantili
                                                    Oto Servis arasında alınacak hizmete ilişkin olarak Mesafeli Satış
                                                    Sözleşmesi akdedilmesi gerekmektedir.
                                                </li>
                                                <li>
                                                    Garantili Oto Servis, Üye’nin başvurusu ile ilgili yapacağı inceleme
                                                    neticesinde hiçbir sebep göstermeksizin Üye’nin başvurusunu reddetme
                                                    hakkına sahiptir. Ayrıca Üye tarafından verilen bilgi ve belgelerin
                                                    eksik, hatalı, yanlış ve/veya sahte olması durumunda Garantili Oto
                                                    Servis, Üye’nin başvurusunu reddetme hakkına sahiptir. Üye bu
                                                    nedenle Garantili Oto Servis’dan hiçbir ad altında hak ve alacak
                                                    talep edemez.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p>HİZMET ŞARTLARI</p>
                                            <ol>
                                                <li>
                                                    Hizmet satın almaya sadece hukuken bağlayıcı işbu sözleşmeyi
                                                    Garantili Oto Servis’a Üye olmak kaydıyla onaylayan ve sözleşmede
                                                    belirtilen şartlara haiz Üyeler katılabilecektir. Üyelik ve katılım
                                                    için 18 yaşının tamamlanmış ve reşit olunması şarttır.
                                                </li>
                                                <li>
                                                    Üye Garantili Oto Servis üzerinde belirtilmiş hizmetlerin talep
                                                    aşamalarını tamamlaması durumunda, hizmet satın alma talebini yapmış
                                                    kabul edilir. Talep sahibi Üye, Çağrı Merkezi tarafından
                                                    arandığında, sözlü olarak talebini onaylandığını ifade etmesi
                                                    durumunda, talep işleme alınır ve hizmet sunumu başlar. Üye talep
                                                    yapmış olsa bile, Çağrı Merkezi tarafından arandığında, talebini
                                                    iptal etme/hizmeti istememe hakkına sahiptir. Üye Çağrı Merkezi ile
                                                    yapılan görüşmelerin saklanmasını kabul eder.
                                                </li>
                                                <li>
                                                    Üye, Garantili Oto Servis üzerinden sunulan hizmet veya hizmetlerde
                                                    vale satın almış ise, aracının valeye teslimi aşamasında üyenin
                                                    atayacağı 3. şahsın da Üye ile aynı hak ve yükümlülüklere sahip
                                                    olacağını kabul eder.


                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>FİKRİ MÜLKİYET HAKLARI</p>
                                    <ol>
                                        <li>
                                            Garantili Oto Servis’in sunumu ve tüm içeriği T.C. Mevzuatı ve fikri
                                            mülkiyet mevzuatı tarafından korunmakta olup, Garantili Oto Servis’daki tüm
                                            ticari markalar, logolar ve hizmet işaretleri başta olmak üzere, yayınlanan
                                            tüm bilgi ve veriler Garantili Oto Servis’a ve/veya lisans verenlere aittir.
                                            Üye, Garantili Oto Servis’un yazılı onayı olmadan, doğrudan veya dolaylı
                                            olarak aynen ya da başka bir şekilde Garantili Oto Servis’un içeriği
                                            dağıtamaz, iletemez, değiştiremez, kopyalayamaz, görüntüleyemez, çoğaltamaz,
                                            yayınlayamaz, işleyemez veya başka bir şekilde kullanamaz veya başkasının
                                            Garantili Oto Servis’un hizmetlerine erişmesine veya kullanmasına izin
                                            veremez. Aksi takdirde, Garantili Oto Servis’un uğradığı/uğrayacağı her
                                            türlü zarar ile lisans verenler de dâhil ancak bunlarla sınırlı olmaksızın,
                                            üçüncü kişilerin uğradıkları zararlardan dolayı Garantili Oto Servis’dan
                                            talep edilen her türlü tazminat tutarını, Üye Garantili Oto Servis’a
                                            ödemekle sorumlu olacaktır.
                                        </li>
                                        <li>
                                            Garantili Oto Servis’in, Site hizmetleri, Site bilgileri, Site'nin telif
                                            haklarına tabi çalışmaları, Site’nin yazılım içerik ve kodları, Site'nin
                                            ticari markaları, Site'nin ticari görünümü veya Site'ye ilişkin her türlü
                                            maddi ve fikri mülkiyet hakları da dâhil tüm malvarlığı, ayni ve şahsi
                                            hakları, ticari bilgi ve know-how'a yönelik tüm hakları saklıdır.
                                        </li>
                                        <li>
                                            Sitede yer alan bütün yazıların, grafiklerin, görsellerin ve tüm resimlerin
                                            her hakkı saklıdır, izinsiz tasarruf edilemez.
                                        </li>
                                        <li>
                                            Site içeriğine, tasarımına ve yazılımına ilişkin tüm mali hakların izinsiz
                                            kullanımı (işleme, çoğaltma, yayma, temsil ve umuma arz) bunlarla sınırlı
                                            olmamak üzere her türlü izinsiz ifşa ve kullanım, fikri ve sınai mülkiyet
                                            haklarının ihlali anlamına gelecektir.
                                        </li>
                                        <li>
                                            Üye’ler, Site'nin kullanımında Türk Borçlar Kanunu, Türk Ceza Kanunu, Fikir
                                            ve Sanat Eserleri Kanunu, Türk Ticaret Kanunu, Markalar Hakkında KHK ve
                                            mevcut ve/veya ileride yürürlüğe girecek olan her türlü mevzuat hükümlerine
                                            uygun davranacaklarını kabul ve taahhüt ederler. Aksi kullanım sebebiyle
                                            doğabilecek hukuki, idari, cezai ve mali her türlü sorumluluk Üye’ye ait
                                            olup Garantili Oto Servis'un rücu hakkı saklıdır.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>SORUMLULUK</p>
                                    <ol>
                                        <li>
                                            Üye, Site’de sunulan/yayınlanan bilgi ve hizmetlerde eksiklik, iletişim
                                            sorunları, teknik problemler, altyapı ve internet arızaları, elektrik
                                            kesintisi, siber saldırılar, bağlantı hızı, sunucu, veri tabanı problemleri
                                            ve/veya sayılanlarla sınırlı olmaksızın başkaca problemler olabileceğini
                                            kabul etmekte olup, bu tür problemler/arızalar oluşması durumunda ve/veya
                                            Üyenin işbu sözleşmede belirtilen yükümlülüklerden herhangi birine aykırı
                                            davrandığının tespiti halinde Garantili Oto Servis Üye’ye herhangi bir
                                            bildirimde bulunmaya gerek olmaksızın ve sebep göstermeksizin internet
                                            üzerinden yayının durdurmaya yetkilidir. Üye bu nedenlerle Garantili Oto
                                            Servis’dan hiçbir ad altında hak ve ödeme talep edemez.
                                        </li>
                                        <li>

                                            Garantili Oto Servis, Üye’nin sitede yer alan herhangi bir ürün ya da
                                            hizmeti kullanmasından kaynaklanacak doğrudan ve/veya dolaylı hasarlardan
                                            sorumlu tutulamaz. Üye, hizmeti ya da siteyi kullanması sonucunda ortaya
                                            çıkabilecek her türlü hasar ve zarardan bizzat kendisinin sorumlu olduğunu
                                            kabul ve beyan eder.

                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>MÜCBİR SEBEPLER</p>
                                    <ol>
                                        <li>

                                            Doğal afet, isyan, savaş, grev, iletişim sorunları, teknik problemler,
                                            altyapı ve internet arızaları, elektrik kesintisi, siber saldırılar,
                                            bağlantı hızı, sunucu, veri tabanı problemleri ve kötü hava koşulları da
                                            dâhil ve fakat bunlarla sınırlı olmamak kaydıyla, Garantili Oto Servis’in
                                            makul kontrolü haricinde ve gerekli özeni göstermesine rağmen önleyemediği,
                                            kaçınılamayacak haller olan Mücbir Sebep durumlarında; Garantili Oto Servis,
                                            işbu sözleşme ile belirlenen yükümlülüklerinden herhangi birini geç veya
                                            eksik ifa etme veya hiç ifa etmeme hakkına sahiptir. Zira işbu Mücbir Sebep
                                            süresince Garantili Oto Servis'un yükümlülükleri askıya alınır. Bu ve bunun
                                            gibi durumlarda, Garantili Oto Servis’a gecikme, eksik ifa etme veya ifa
                                            etmeme veya temerrüt addedilmeyecektir. Bu durumlar için Garantili Oto
                                            Servis’dan her ne nam altında olursa olsun herhangi bir tazminat talep
                                            edilemeyecektir. Mücbir sebep halinin 7 (yedi) günden fazla sürmesi halinde
                                            Garantili Oto Servis, dilerse işbu SÖZLEŞME’yi herhangi bir bildirime gerek
                                            olmaksızın tazminatsız ve tek taraflı olarak feshetmeye yetkidir.

                                        </li>
                                        <li>

                                            Garantili Oto Servis, sözleşmede belirtilen iş ve işlemlerin daha etkin
                                            gerçekleştirilebilmesi açısından yapacağı değişiklikler ile hizmet
                                            kapsamında yapacağı değişiklikler nedeniyle ve ayrıca gördüğü lüzum üzerine
                                            Üye’ye bildirimde bulunmaya gerek olmaksızın ve sebep göstermeksizin işbu
                                            sözleşme hükümlerinde tek taraflı olarak değişiklik yapma hakkına sahiptir.
                                            Üye, bahse konu değişiklikleri Site’de yayınlandığı andan itibaren kabul
                                            etmiş sayılır. Üye, bu hususu şimdiden gayri kabili rücu olarak kabul eder.

                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>SÖZLEŞMENİN SÜRESİ, FESİH VE ÜYELİK İPTALİ</p>
                                    <ol>
                                        <li>

                                            İşbu sözleşme Site’de onaylandığı andan itibaren yürürlüğe girer ve
                                            Garantili Oto Servis’un Üye’nin Üyeliğini iptal etmesi ve/veya Site’de
                                            sunulan hizmetleri sona erdirmesi ile birlikte başkaca bildirime gerek
                                            olmaksızın kendiliğinden sona erer.

                                        </li>
                                        <li>

                                            Taraflardan herhangi biri, gerek görmesi durumunda dilediği zaman işbu
                                            sözleşmeyi hiçbir sebep göstermeksizin yazılı bildirimde bulunarak 1 gün
                                            önce vermek kaydıyla tek taraflı olarak feshetmeye yetkilidir. Bu takdirde
                                            diğer taraf, sözleşmeyi fesheden taraftan haksız, yersiz, sebepsiz, mehilsiz
                                            ve zamansız bir fesihte bulunulduğu, iyi niyete aykırı davranıldığı veya
                                            sair bir neden ve bahane öne sürerek hiçbir hak, alacak, kar kaybı, zarar
                                            ziyan tazminatı veya başkaca bir nam ve ünvan altında herhangi bir ödeme
                                            talep edemez. Ancak fesih tarihinden önce alınması konusunda mutabakat
                                            sağlanmış veya ifa edilmiş hizmetler kapsamında söz konusu hizmetler ile
                                            ilgili sözleşmeden kaynaklanan yükümlülükleri devam eder.

                                        </li>
                                        <li>

                                            Üye, Garantili Oto Servis üzerinden satın aldığı hizmetin/ürünün işlemi
                                            devam ediyor olması durumunda, Garantili Oto Servis, ÜYE’yi korumak, sunulan
                                            hizmetin/ürünün işlemleri tamamlanana kadar takip edebilmesi, onay
                                            verebilmesi, reddedebilmesi ve değişiklik talep edebilmesi için üyelik
                                            kaydını saklı tutar. Üye, Garantili Oto Servis üzerinde oluşturduğu
                                            profilini hizmeti tamamlanana kadar silemez ve kapatamaz. Hizmetin/ürünün
                                            tamamlanmasını müteakip Garantili Oto Servis üyelik iptali talebini yerine
                                            getirmekle yükümlü olduğunu kabul eder.

                                        </li>
                                        <li>
                                            Üye'nin işbu sözleşmeden kaynaklanan yükümlülüklerinden herhangi biri veya
                                            tamamına kısmen veya tamamen aykırı davranması durumunda Garantili Oto
                                            Servis, herhangi bir bildirime gerek olmaksızın ve gerekçe göstermeksizin
                                            tek taraflı olarak işbu sözleşmeyi feshederek Üye'nin Üyeliğini iptal
                                            edebilir ve Üye'nin siteden aldığı, almakta olduğu ya da alacağı hizmetleri,
                                            verdiği teklifleri kısmen veya tamamen dondurulabilir veya iptal edebilir.
                                            Bu nedenle fesih durumunda Üye Garantili Oto Servis’dan hiçbir hak ve
                                            talepte bulunamaz. Garantili Oto Servis doğmuş/doğacak her türlü zararını
                                            Üye’den talep etmeye yetkilidir. Garantili Oto Servis, işbu zararlarını
                                            Üye’nin varsa vermiş olduğu teminatı/provizyonu herhangi bir bildirime gerek
                                            olmaksızın irad kaydederek tazmin etmeye yetkilidir. Üye, bu hususa itiraz
                                            edemez. Üye’nin sorumluluğu teminat miktarıyla sınırlı değildir.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>GİZLİLİK</p>

                                    Üye, işbu Sözleşme’nin ifası tahtında Garantili Oto Servis ile ilgili olarak
                                    doğrudan veya dolaylı olarak edindiği, ticari, mali, hukuki veya teknik nitelikte,
                                    ticari sır ya da diğer yasal korumaya konu olan ya da olmayan her türlü bilgiyi
                                    gizli tutacak ve Garantili Oto Servis’un izni olmaksızın herhangi bir kişiye ifşa
                                    etmeyecektir. Aksi halde Üye Garantili Oto Servis’un uğradığı zararlardan
                                    sorumludur. Ayrıca Garantili Oto Servis, kullanıcı profili ve pazar araştırmaları
                                    yapmak, satış ve site kullanım istatistikleri oluşturmak gibi amaçlar dâhil ancak
                                    bunlarla sınırlı olmamak üzere tüm yasal amaçlar için, Üye'nin kimlik, adres,
                                    iletişim, IP ve site kullanım bilgilerini bir veri tabanında toplayabilir ve bu
                                    bilgileri herhangi bir kısıtlama olmaksızın kullanabilir. Garantili Oto Servis,
                                    Üye’ye ilişkin bilgileri sitede sunulan hizmetler ile ilgili trafik müşaviri firma,
                                    sigorta şirketi, banka, Garantili Oto Servis üye işyeri ile ayrıca gerek gördüğü
                                    üçüncü kişi/kurumlarla paylaşmaya yetkilidir. Ayrıca Garantili Oto Servis bu
                                    bilgileri, yasaların getirdiği zorunluluklara uyma amacıyla veya yetkili adli veya
                                    idari otoritenin yürüttüğü soruşturma veya araştırma açısından talep edilmesi
                                    durumunda veya kullanıcıların hak ve güvenliklerinin korunması amacıyla üçüncü
                                    kişi/kurumlarla paylaşabilir. İşbu gizlilik hükmü sözleşme süresi sona erdikten
                                    sonra da 5 yıl süreyle geçerlidir.

                                </li>
                                <li>
                                    <p>TEBLİGAT</p>
                                    Taraflar, Üyelik işlemleri esnasında belirttiği adreslerinin kanuni tebligat
                                    adresleri olduğunu, adres değişikliğinin diğer tarafa yazılı olarak bildirilmediği
                                    sürece bu adreslere yapılacak bildirimlerin kanunen geçerli tebligatın bütün hukuki
                                    sonuçlarını doğuracağını kabul ve taahhüt ederler. Üye, sözleşme ve hizmetlerde
                                    yapılacak değişikliklerin, açık artırma sonuçlarının, onayın, Üyeliğin/açık
                                    artırmanın iptalinin, işbu sözleşmenin feshinin, sona erdirilmesinin vs. her türlü
                                    bildirimin Üyelik işlemleri esnasına belirttiği e-mail adresine yapılmasına
                                    muvafakat etmiş olup, e- mail ile yapılan bildirimler ulaşsın veya ulaşmasın
                                    bildirimin Garantili Oto Servis tarafından gönderildiği andan itibaren tebliğ
                                    edilmiş olduğunu ve hukuki sonuçlarını doğuracağını kabul ve taahhüt eder.
                                    Bildirimin Üyeye geç ulaşması veya ulaşmamasından ve sonuçlarından Garantili Oto
                                    Servis sorumlu değildir. Üye’nin Garantili Oto Servis ile yapacağı yazışmalarda
                                    elektronik posta, faks kullanılamaz.
                                </li>
                                <li>
                                    <p>YETKİ ve DELİL ANLAŞMASI</p>
                                    <ol>
                                        <li>
                                            İşbu sözleşmenin uygulanmasından kaynaklanan sorunların çözümünde Türk
                                            Hukuku uygulanacak ve Tüm İstanbul Mahkemeleri ile İcra Daireleri yetkili
                                            olacaktır.
                                        </li>
                                        <li>
                                            Üye, çıkabilecek ihtilaflarda, Garantili Oto Servis’un her türlü belge,
                                            kayıt, defterleri ile bilgisayar ve internet ortamındaki her türlü, bilgi,
                                            yazı ve kayıtlarının tek, münhasır ve kesin delil teşkil edeceğini ve
                                            bağlayıcı olacağını, bu maddenin HMK.193 maddesi kapsamında bir delil
                                            sözleşmesi olduğunu kabul eder.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>MUHTELİF</p>
                                    <ol>
                                        <li>
                                            İşbu Sözleşmenin herhangi bir hükmünün, herhangi bir nedenden dolayı
                                            geçersiz sayılması veya uygulanabilirliğinin kalmaması halinde, sözleşmenin
                                            diğer hükümleri yürürlükte kalacaktır.
                                        </li>
                                        <li>
                                            Garantili Oto Servis’un Sözleşme tahtında sahip olduğu herhangi bir hak veya
                                            yetkiyi kullanmaması ya da kullanmakta gecikmesi o hak veya yetkiden feragat
                                            ettiği anlamına gelmediği gibi, bir hak veya yetkinin tek başına veya kısmen
                                            kullanılması o veya başka bir hak veya yetkinin daha sonra kullanılmasını
                                            engellemez. Bu Sözleşme’nin herhangi bir koşul, madde veya hükmünden feragat
                                            edilmesi o koşul, madde veya hükümden daha sonra veya devamlı olarak feragat
                                            edildiği anlamına gelmez veya bu şekilde yorumlanamaz.
                                        </li>
                                        <li>
                                            İşbu sözleşme Site’de onaylandığı andan itibaren, varsa daha önce Site’de
                                            onaylanan sözleşmenin yerine geçer. İhtilaf halinde, hangi sözleşmenin
                                            yürürlükte olduğu dönemden kaynaklandığına bakılmaksızın, bu sözleşme
                                            hükümleri uygulanır. Üye, varsa daha önce Site’de onaylanan sözleşme ve
                                            ticari işleyişten dolayı Garantili Oto Servis’dan hiçbir hak ve alacağı
                                            olmadığını, Garantili Oto Servis’u her türlü konuda en geniş manada gayri
                                            kabili rücu olarak ibra ettiğini, bahse konu sözleşme ve ticari ilişkiden
                                            dolayı Garantili Oto Servis’a karşı doğmuş ve/veya doğacak tüm
                                            sorumluluklarının devam ettiğini kabul, beyan ve taahhüt eder.
                                        </li>
                                        <li>
                                            Üye, sitede yer alan tüm uygulama ve kuralları okuyup anladıklarını ve kabul
                                            ettiklerini beyan ederler. Üye, sözleşmenin tamamında yer alan menfaatlerine
                                            aykırı olabilecek düzenlemeleri de sonuçlarını bilerek ve anlayarak kabul
                                            ettiğini beyan eder.
                                        </li>
                                        <li>
                                            Üye, işbu sözleşmeden kaynaklanan hak, alacak ve yükümlülüklerini öncesinde
                                            Garantili Oto Servis’un yazılı iznini almaksızın üçüncü kişilere devir veya
                                            temlik edemez.
                                        </li>
                                    </ol>
                                </li>
                            </ol>



                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div>
    <!--end::Container-->
</div>
<!--end::Post-->