<style>
    .odeme-item-container{
    display: flex;
    width: 49%;
    flex-direction: column;
    }
    .tab-input-container{
    width: 100%;
    margin-bottom: 5px;
    }

    @media screen and (max-width:768px){
        .odeme-item-container{
            width: 100%;
        }

        .checkbox-container{
            display: flex;
            flex-direction: row;
            justify-content: left;
        }
    
        
    }
</style>
<div class="post d-flex flex-column-fluid" id="appointment-container" *ngIf="!showForm">
        <div id="kt_content_container" class="container-xxl">
            <div class="card p-lg-20" >
                <div class="card-header pt-3" *ngIf="!orderDetail?.is_payed">
                    <div class="nav-container d-flex justify-content-center w-100">
                        <div class="mb-2">
                            <!--begin::Title-->
                            <h2 class="fw-bold text-gray-800 text-center lh-lg">
                                <span class="fw-boldest">{{orderDetail?.id}} numaralı</span>
                            <br /> randevunuz için ödenecek ücret: <span class="fw-boldest">{{orderDetail?.order_value}}&#8378;</span>
                            </h2>
                        </div>
                    </div>
                </div>
                    
                <div class="card-body d-flex justify-content-center">
                    

                    <div class="col-md-12" *ngIf="orderDetail?.is_payed">
                        <!--begin::Engage widget 1-->
                        <div class="card h-md-100">
                            <!--begin::Body-->
                            <div class="card-body d-flex flex-column flex-center">
                                <!--begin::Heading-->
                                <div class="mb-2">
                                    <!--begin::Title-->
                                    <h1 class="fw-bold text-gray-800 text-center lh-lg">
                                        <span class="fw-boldest">{{orderDetail?.id}} numaralı</span>
                                    <br /> randevunuzun ödemesi alınmıştır!
                                    </h1>
                                    <!--end::Title-->
                                    <!--begin::Illustration-->
                                    <div class="flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center card-rounded-bottom h-200px mh-200px my-5 my-lg-12" style="background-image:url('assets/media/svg/illustrations/easy/2.svg')"></div>
                                    <!--end::Illustration-->
                                </div>
                                <!--end::Heading-->
                               
                            </div>
                            <!--end::Body-->
                        </div>
                        <!--end::Engage widget 1-->
                    </div>

                  
                    <div *ngIf="orderDetail && !orderDetail?.is_payed" style="width:100%; max-width: 600px;">
                        <form action="" [formGroup]="invoiceAddressForm" id="invoiceInfoContainer">
                            <div class="odeme-container">
                                <div class="odeme-item-container">
                    
                                    <div class="form-content-container">
                                
                                        <div class="tab-input-container">
                                            <input type="text" class="form-control form-control-solid  form-control-sm"
                                                                value="{{filteredCardNo}}" 
                                                                placeholder="Kart Numarası" 
                                                                minlength="19"
                                                                maxlength="19"
                                                                autocomplete="off"
                                                                name="Pan1" 
                                                                (input)="filterFormatCardNo($event)"   />
                                            <input type="hidden" class="form-control form-control-solid  form-control-sm" placeholder="Kart Numarası" name="Pan" value="{{inputCardNo}}"   />
                                        </div>
                                
                                        <div class="tab-input-container">
                                            <input 
                                                type="text" 
                                                class="form-control tab-input" 
                                                name="surname"
                                                (change)="setCardInfo('cardName',$event)"
                                            
                                                autocomplete="off" 
                                                placeholder="Ad Soyad" 
                                            >
                                        </div>
                                
                                        <div class="tab-input-container">
                                        
                                            <input class="form-control  form-control-sm tab-input" (keyup)="setCardInfo('expire',$event)" placeholder="Son Kullanma Tarihi" minlength="5" maxlength="5" value="{{filteredExpiryDate}}" (input)="filterFormatExpirationDate($event)" type="text" name="Expiry1">
                                            <input class="form-control  form-control-sm" value="{{inputExpiryDate}}" type="hidden" name="Expiry">
                                        </div>
                                
                                        <div class="tab-input-container">
                                            <input type="text" class="form-control form-control-solid  form-control-sm tab-input"  minlength="3" (keyup)="setCardInfo('ccv',$event)"   maxlength="4" placeholder="CVV" name="Cvv2" />
                        
                                        </div>
                                        <div class="tab-input-container checkbox-container">
                                            <div class="form-check form-check-sm form-check-custom form-check-solid payment-check">
                                                <input class="form-check-input checbox-check" type="checkbox" value="1" checked>
                                                &nbsp;E-Fatura Mükellefiyim
                                            </div>
                                            <div class="form-check form-check-sm form-check-custom form-check-solid payment-check">
                                                <input class="form-check-input checbox-check" type="checkbox" value="1" checked>
                                                &nbsp;Aydınlatma Metni
                                            </div>
                                            <div class="form-check form-check-sm form-check-custom form-check-solid payment-check">
                                                <input class="form-check-input checbox-check" type="checkbox" value="1" checked>
                                                &nbsp;Gizlilik Politikası
                                            </div>
                                            <div class="form-check form-check-sm form-check-custom form-check-solid payment-check">
                                                <input class="form-check-input checbox-check" type="checkbox" value="1" checked>
                                                &nbsp;Kullanıcı Sözleşmesi
                                            </div>
                                            <div class="form-check form-check-sm form-check-custom form-check-solid payment-check">
                                                <input class="form-check-input checbox-check" type="checkbox" value="1" checked>
                                                &nbsp;Açık Rıza Metni
                                            </div>
                                        </div>
                        
                                    </div>
                                </div>
                                <div class="odeme-item-container">
                                    
                                    <app-shared-credit-card style="width: 100%;"
                                    [cardNumber]="filteredCardNo"
                                    [cardName]="cardName"
                                    [cardExpire]="cardExpire"
                                    [ccv2]="ccv2"
                                    [flipped]="turnCard"
                                    ></app-shared-credit-card>

                                    <div class="tab-input-container">
                                        <table class="table align-middle table-row-dashed fs-7 fw-bold gy-4 dataTable no-footer" style="width: 80%;">
                                            <thead>
                                                <tr>
                                                    <td style="width: 20px;"> <input checked type="radio" class="form-radio-input" name="installment"> </td>
                                                    <td>Tek Çekim (Peşin)</td>
                                                    <td>{{orderDetail?.order_value}}&#8378;</td>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="filteredData">
                                                <tr *ngFor="let i of filteredData">
                                                    
                                                    <td style="width: 20px;">
                                                        <input 
                                                            type="radio" 
                                                            class="form-radio-input"  
                                                            name="installment"
                                                            value="{{ getField(i,'installmentCount') | json}}"
                                                            (click)="setInstallment($event,i )"
                                                            >
                                                    </td>
                                                    <td>{{ getField(i,"installmentCount") | json}} x Taksit</td>
                                                    <td>{{ getField(i,"buyerCostAmount") | json}}&#8378;</td>
                                                    
                                                </tr>
                                               
                                            </tbody>
                                        </table>
                                    </div>

                                    <button 
                                    type="submit" 
                                    class="btn btn-primary btn-sm" 
                                    (click)="formEmit()"
                                    [ngClass]="{disabled: hidePaymentButton == true}"
                                    >
                                    <span
                                    class="indicator-label"
                                    [ngClass]="{'d-none': hidePaymentButton == true}"
                                    >Ödeme Yap</span>
                                  
                                    <span [ngClass]="{'d-none': hidePaymentButton == false}" class="translate-middle-y lh-0 me-1">
                                        <span class="spinner-border h-15px w-15px align-middle"></span>
                                        Lütfen Bekleyiniz!
                                    </span>
                                </button>    
                                
                               
                    
                                    <div class="alert alert-danger mt-5" *ngIf="showPaymentMessage">
                                        {{paymentMessage}}
                                    </div>
                                </div>
                            </div>
                            
                         </form>
        
                    </div>
                    
                   
                  
                </div>
            </div>

           
        </div>

       
    </div>
    <div class="post d-flex flex-column-fluid"  *ngIf="showForm" >
        
        <div id="kt_content_container" class="container-xxl">
            <div class="card" >
                <div class="card-header pt-3">
                    <div class="nav-container">
                        
                    </div>
                </div>
                    
                <div class="card-body">
                    <div class="alert alert-success">
                        Ödemeniz başarılı bir şekilde alınmıştır.
                    </div>
                </div>
            </div>
        </div>
        
    </div>




   
  