import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface ServiceCompanyContacts {
  id: bigint;
  company_id: bigint;
  general_manager: String;
  general_manager_phone: String;
  general_manager_email: String;
  service_manager: String;
  service_manager_phone: String;
  service_manager_email: String;
  service_consultant: String;
  service_consultant_phone: String;
  service_consultant_email: String;
  company_owner: String;
  off_hours_phone: String;
}

interface ResponseArray {
    service_company_contacts: ServiceCompanyContacts[];
}

interface ResponseSingle {
    insert_companies: any;
    service_company_contacts_by_pk: ServiceCompanyContacts
}

@Injectable()
export class SERVICE_COMPANY_CONTACTS_ALL extends Query<ResponseArray> {
  override document = gql`
    query SERVICE_COMPANY_CONTACTS_ALL($where: service_company_contacts_bool_exp){
      service_company_contacts(where: $where) {
        
        id
        company_id
        general_manager
        general_manager_phone
        general_manager_email
        service_manager
        service_manager_phone
        service_manager_email
        service_consultant
        service_consultant_phone
        service_consultant_email
        off_hours_phone
        company_owner
      }
    }
  `;
}

@Injectable()
export class SERVICE_COMPANY_CONTACTS_ID extends Query<ResponseSingle> {
  override document = gql`
    query SERVICE_COMPANY_CONTACTS_ID($id: Int!){
      service_company_contacts_by_pk(id: $id) {
        id
        company_id
        general_manager
        general_manager_phone
        general_manager_email
        service_manager
        service_manager_phone
        service_manager_email
        service_consultant
        service_consultant_phone
        service_consultant_email
        off_hours_phone
      }
    }
  `;
}

@Injectable()
export class SERVICE_COMPANY_CONTACTS_CREATE extends Mutation<ResponseSingle> {
  // connected_part_id - int | nullable
  override document = gql`
  mutation SERVICE_COMPANY_CONTACTS_CREATE(
    $company_id: bigint, 
    $general_manager: String, 
    $general_manager_phone: String, 
    $general_manager_email: String, 
    $service_manager: String, 
    $service_manager_phone: String, 
    $service_manager_email: String, 
    $service_consultant: String, 
    $service_consultant_phone: String, 
    $service_consultant_email: String, 
    $off_hours_phone: String,
    $company_owner: String
      ) {
    insert_service_company_contacts(objects: {
      company_id: $company_id, 
      general_manager: $general_manager, 
      general_manager_phone: $general_manager_phone, 
      general_manager_email: $general_manager_email, 
      service_manager: $service_manager, 
      service_manager_phone: $service_manager_phone, 
      service_manager_email: $service_manager_email, 
      service_consultant: $service_consultant, 
      service_consultant_phone: $service_consultant_phone, 
      service_consultant_email: $service_consultant_email, 
      off_hours_phone: $off_hours_phone,
      company_owner: $company_owner
    }) {
      affected_rows
      returning{
        id
      }
    }
  }
  
  `;
}

@Injectable()
export class SERVICE_COMPANY_CONTACTS_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
  mutation SERVICE_COMPANY_CONTACTS_UPDATE(
    $id: Int
    $company_id: bigint, 
    $company_owner: String,
    $general_manager: String, 
    $general_manager_phone: String, 
    $general_manager_email: String, 
    $service_manager: String, 
    $service_manager_phone: String, 
    $service_manager_email: String, 
    $service_consultant: String, 
    $service_consultant_phone: String, 
    $service_consultant_email: String, 
    $off_hours_phone: String
    ) {
  update_service_company_contacts(where: {id: {_eq: $id}}, _set: {
    company_id: $company_id, 
    company_owner:$company_owner,
      general_manager: $general_manager, 
      general_manager_phone: $general_manager_phone, 
      general_manager_email: $general_manager_email, 
      service_manager: $service_manager, 
      service_manager_phone: $service_manager_phone, 
      service_manager_email: $service_manager_email, 
      service_consultant: $service_consultant, 
      service_consultant_phone: $service_consultant_phone, 
      service_consultant_email: $service_consultant_email, 
      off_hours_phone: $off_hours_phone
    }) {
    affected_rows
  }
}
  
  `;
}
