import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { count } from 'console';
import { elementAt } from 'rxjs/operators';
import { Currency, CURRENCY_ALL } from 'src/app/graphql/currency.graphql';
import { Package, PACKAGE_ID } from 'src/app/graphql/package.graphql';
import { PartUnitType } from 'src/app/graphql/part_unit_type.graphql';
import { PriceUnits, PRICE_UNITS_ID } from 'src/app/graphql/price_units.graphql';
import { VehicleModel, VEHICLE_MODEL_ID } from 'src/app/graphql/vehicle-model.graphql';
import { Vehicle } from 'src/app/graphql/vehicle.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-shared-maintenance-package',
  templateUrl: './maintenance-package.component.html',
  styles: [
  ],
  providers:[PACKAGE_ID,CURRENCY_ALL,PRICE_UNITS_ID,VEHICLE_MODEL_ID]
})
export class MaintenancePackageComponent implements OnInit {

  @Input() modelTypeId: BigInt | undefined;
  @Input() vehicleInfo: Vehicle | undefined;
  @Input() packageId: BigInt | undefined;
  @Output() returnParts:any = new EventEmitter();
  @Input() gosLabourPrice: any;
  @Input() brandLabourPrice: any;

  public setSelectedItems:boolean = true;

  public packageDetail: Package | undefined;
  public partEquivalentList:any = [];
  public totalsByTypes:any = [];
  public selectedPartUnitType: PartUnitType | undefined;
  public selectedParts: any  = [];

  public grandTotal : number = 0;
  public subTotal: number = 0;
  public difference : number = 0;
  public gosMaintenancePrice: number = 0;
  public gosMaintenancePriceNoVat: number = 0;
  public brandMaintenancePrice: number = 0;
  public brandMaintenancePriceNoVat: number = 0;
  public firstLoad: boolean = true;
  public savingTotal:number = 0;
  public iscilikParts:any=[]
 


  public currencyList: Currency[] | undefined;
  public activeCurrencyId:number = 1;
  public activeCurrency:PriceUnits | undefined;
  public vehicleModel: VehicleModel | undefined;

  constructor(
    private getMaintenancePackage: PACKAGE_ID,
    private alertifyService : AlertifyService,
    private getCurrency: CURRENCY_ALL,
    private getCurrencyById: PRICE_UNITS_ID,
    private getModel: VEHICLE_MODEL_ID

  ) { }

  ngOnInit(): void {
   

    this.getCurrencyById.watch({id:this.activeCurrencyId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
      this.activeCurrency = response.data.price_units_by_pk;
    })


    this.getCurrency.watch().valueChanges.subscribe((result:any)=>{
      if(result.errors){
        this.alertifyService.error(result.errors[0].message);
      }

      if(result.data){
        this.currencyList = result.data.currency;
      }
    });

  }



  ngOnChanges(){

    if(this.vehicleInfo){
      this.getModel.watch({id:this.vehicleInfo.model_id},{fetchPolicy:"no-cache"}).valueChanges.subscribe(modelSubs => {
        if(modelSubs.errors){
          this.alertifyService.error(modelSubs.errors[0].message);
        }

        if(modelSubs.data){
          this.vehicleModel = modelSubs.data.vehicle_models_by_pk;
        }
      })
    }


    this.selectedParts = [];
    this.setTotalAmount();

    if( this.packageId == undefined ){

      this.alertifyService.error("Bakım Paketi Seçilemedi");
      
    }else{

      this.getMaintenancePackage.watch({id:this.packageId},{fetchPolicy:"no-cache"}).valueChanges.subscribe( response => {

        if(response.errors){
          this.alertifyService.error( response.errors[0].message );
        }

        if(response.data){

          this.packageDetail = response.data.maintenance_packages_by_pk;

          this.setPartEquivalentList();


        }

      } )


      setTimeout(()=>{
        this.firstLoad = false;
      },3000)

    }

    
  }

  setPartEquivalentList(){

    this.partEquivalentList = [];
    this.totalsByTypes = {
      yetkili_orjinal : 0,
      orjinal : 0,
      es_deger : 0,
      logosuz: 0
    };


    if(this.packageDetail != undefined){

      if( this.packageDetail.maintenance_package_details.length > 0 ){

        let newObj:any;

        let  i = 1;
          this.packageDetail.maintenance_package_details.forEach(( element:any ) => {

            //işçilik değil ise ekranda göster
             i++;

             let currentType = "original";
             let currentId = 0;


             newObj = {
               package_detail_id: element.id,
               part_id:element.part_original_list.id,
               selectedGosPart:{
                 id: element.part.id,
                 name: element.part.name,
                 unit_type: element.part.part_unit_type,
                 unit: element.part.part_unit 
               },
               without_logo: null,
               offical_original: null,
               equivalent: null,
               gosPart: null,
               original:null,
               partTypeName:"Orijinal",
               part_unit: element.part_unit,
               part_unit_type: element.part_unit_type,
               part_unit_type_name: element.part_unit_types.name,
               iscilik_id: 0
             };


             if(element.part.original_price_ratio > 0){
              element.part_original_list.part_in_vat_price  = element.part_original_list.part_in_vat_price  + ((element.part_original_list.part_in_vat_price  * element.part.original_price_ratio) / 100);
              element.part_original_list.part_no_vat_price  = element.part_original_list.part_no_vat_price + ((element.part_original_list.part_no_vat_price * element.part.original_price_ratio) / 100);
            }
 
             newObj.offical_original = {
               id: element.part_original_list.id,
               name: element.part_original_list.name,
               price: element.part_original_list.part_in_vat_price.toFixed(2),
               priceNoVat: element.part_original_list.part_no_vat_price.toFixed(2),
               price_unit: this.activeCurrency?.code,
               price_unit_id: this.activeCurrency?.id,
               currencyPriceTotal: 0,
               currencyPriceNoVatTotal: 0,
               currencyPriceUnitId : element.part_original_list.price_unit_id,
               currencyPriceUnit: element.part_original_list.price_unit,
               part_unit: element.part_unit,
               part_unit_type: element.part_unit_type,
               part_unit_type_name: element.part_unit_types.name
             }

             let originalPriceWithCurrency  = element.part_original_list.part_in_vat_price;
             let originalPriceNoVatCurrency = element.part_original_list.part_no_vat_price;

             if(element.part_original_list.price_unit_id != 1){

              let originalCurrentCurrency = this.currencyList?.filter(i => i.price_unit_id == element.part_original_list.price_unit_id);
         
              if(originalCurrentCurrency && originalCurrentCurrency.length > 0){
  
                let originalCurrency = originalCurrentCurrency[0].currency_value;
                
               
               let originalCurrencyPrice = element.part_original_list.part_in_vat_price;
               let originalCurrencyNoVatPrice = element.part_original_list.part_no_vat_price;

               let originalConvertToTlWithVat = ( element.part_original_list.part_in_vat_price * originalCurrency );
               let originalConvertToTlWithNoVat = ( element.part_original_list.part_no_vat_price * originalCurrency );

               /** kur farkından dolayı üzerine %5 ekliyoruz **/
               let vatPriceDiff = ( originalConvertToTlWithVat + ( (originalConvertToTlWithVat * 5) / 100) )
               let noVatPriceDiff = (originalConvertToTlWithNoVat + ( (originalConvertToTlWithNoVat * 5) / 100) )

                originalPriceWithCurrency  = parseFloat(vatPriceDiff.toFixed(2));
                originalPriceNoVatCurrency = parseFloat(vatPriceDiff.toFixed(2));
  
                // parça fiyatını çevir ve yeniden ata
                element.part_original_list.part_in_vat_price   = originalPriceWithCurrency;
                element.part_original_list.part_no_vat_price   = noVatPriceDiff;
                element.part_original_list.currencyPriceUnitId = element.part_original_list.price_unit_id;
                element.part_original_list.currencyPriceUnit   = element.part_original_list.price_unit;
                element.part_original_list.price_unit_id       = this.activeCurrency?.id;
                element.part_original_list.price_unit          = this.activeCurrency?.code;
                element.part_original_list.priceCurrency       = originalCurrencyPrice;
                element.part_original_list.priceNoVatCurrency  = originalPriceNoVatCurrency;

             }
            }

            

             newObj.original = {
              id: element.part_original_list.id,
              price: originalPriceWithCurrency,
              priceNoVat: parseFloat(originalPriceNoVatCurrency.toFixed(2)),
              price_unit: this.activeCurrency?.code,
              partUnitType: element.part_unit_types.name,
              partUnit :  element.part_unit,
              partUnitTypeId: element.part_unit_types.id,
              price_unit_id: this.activeCurrency?.id,
              partCode: element.part_original_list.part_code,
              currencyPriceUnitId: element.part_original_list.price_unit_id,
              currencyPriceUnit: element.part_original_list.price_unit,
              priceCurrency: originalPriceWithCurrency,
              priceNoVatCurrency: originalPriceNoVatCurrency,
              name: element.part_original_list.name,
              partTypeName: element.part_original_list.part_types.name,
              totalPriceCurrency: originalPriceWithCurrency * element.part_unit,
              partTypeId: 1,
              
            };
            currentType = "original";
            this.addPiece(newObj.original.id,newObj.selectedGosPart.id,newObj.original.id,"original",true);
            //addPiece(partOriginalId:Number,selectedPartId:Number,equivalentId:Number,partType:string,showdebug=false){
 
//             this.totalsByTypes.yetkili_orjinal += (element.part_original_list.part_in_vat_price * element.part_unit);
 
             if(element.part_original_list.part_equivalent_lists.length > 0){
 
               let selectedEsdegerPrice = 0;

               element.part_original_list.part_equivalent_lists.forEach((item:any) => {

                if(element.part_unit_type == 3 && newObj.iscilik_id == 0){
                  newObj.iscilik_id = item.id
               
                }
                 
                 let priceWithCurrency  = 0;
                 let priceNoVatCurrency = 0;
              
                let oldPrice = item.part_in_vat_price;
               
                 if(element.part.price_ratio > 0){
                  //Parça üzerine girilen fiyatı ekle default: 50 //part_tablosundali_price_ratio

                  if(item.part_type_id == 5){
                    item.part_in_vat_price  = item.part_in_vat_price  + ((item.part_in_vat_price  * element.part.original_price_ratio) / 100);
                    item.part_no_vat_price  = item.part_no_vat_price + ((item.part_no_vat_price * element.part.original_price_ratio) / 100);
                  }else{
                    item.part_in_vat_price  = item.part_in_vat_price  + ((item.part_in_vat_price  * element.part.original_price_ratio) / 100);
                    item.part_no_vat_price  = item.part_no_vat_price + ((item.part_no_vat_price * element.part.original_price_ratio) / 100);
                  }

                  
               
                 }
                 
  
                 
                 //Equivalent İçin Kur Çevir 
                 if( item.price_unit_id != BigInt(1) ){
         
                     let currentCurrency = this.currencyList?.filter(i => i.price_unit_id == item.price_unit_id);
         
                     if(currentCurrency && currentCurrency.length > 0){
         
                       let currency = currentCurrency[0].currency_value;
                       
                      
                      let currencyPrice = item.part_in_vat_price;
                      let currencyNoVatPrice = item.part_no_vat_price;

                      let convertToTlWithVat = ( item.part_in_vat_price * currency );
                      let convertToTlWithNoVat = ( item.part_no_vat_price * currency );

                      /** kur farkından dolayı üzerine %5 ekliyoruz **/
                      let vatPriceDiff = ( convertToTlWithVat + ( (convertToTlWithVat * 5) / 100) )
                      let noVatPriceDiff = (convertToTlWithNoVat + ( (convertToTlWithNoVat * 5) / 100) )

                       priceWithCurrency  = parseFloat(vatPriceDiff.toFixed(2));
                       priceNoVatCurrency = parseFloat(vatPriceDiff.toFixed(2));
         
                       // parça fiyatını çevir ve yeniden ata
                       item.part_in_vat_price = priceWithCurrency;
                       item.part_no_vat_price = noVatPriceDiff;
                       item.currencyPriceUnitId = item.price_unit_id;
                       item.currencyPriceUnit = item.price_unit;
                       item.price_unit_id = this.activeCurrency?.id;
                       item.price_unit = this.activeCurrency?.code;
                       item.priceCurrency = currencyPrice;
                       item.priceNoVatCurrency = currencyNoVatPrice;
                     }
         
                 }else{
         
                   priceWithCurrency  = item.part_in_vat_price;
                   priceNoVatCurrency = item.part_no_vat_price;
         
                   // parça fiyatını çevir ve yeniden ata
                   item.part_in_vat_price = priceWithCurrency;
                   item.part_no_vat_price = priceNoVatCurrency;
                   item.currencyPriceUnitId = item.price_unit_id;
                   item.currencyPriceUnit = item.price_unit;
                   item.price_unit_id = this.activeCurrency?.id;
                   item.price_unit = this.activeCurrency?.code;
                   item.priceCurrency = priceWithCurrency;
                   item.priceNoVatCurrency = priceNoVatCurrency;
         
                 }

                 
                 switch(item.part_type_id){
         
                   case 2:
                     //logosuz orjinal
                     newObj.without_logo = {
                       id: item.id,
                       price: item.part_in_vat_price,
                       priceNoVat: item.part_no_vat_price,
                       price_unit: this.activeCurrency?.code,
                       partUnitType: element.part_unit_types.name,
                       partUnit : element.part_unit,
                       partUnitTypeId: element.part_unit_types.id,
                       price_unit_id:  this.activeCurrency?.id,
                       partCode: item.part_code,
                       currencyPriceUnitId: item.currencyPriceUnitId,
                       currencyPriceUnit: item.currencyPriceUnit,
                       priceCurrency: priceWithCurrency,
                       priceNoVatCurrency: priceNoVatCurrency,
                       name: item.name,
                       partTypeName: item.part_types.name,
                       partTypeId: item.part_types.id,
                       totalPriceCurrency: (priceWithCurrency * element.part_unit),
                     };

                     currentType = "without_logo";
                     
 
                     break;
         
                   case 3:
                    currentType = "equivalent";
                    if(selectedEsdegerPrice == 0){


                      //Datayı manüpüle edeceğiz o yüzden burayı kapadık
                       //eşdeğer
                      
                       /*
                        newObj.equivalent = {
                          id:item.id,
                          price :item.part_in_vat_price,
                          priceNoVat: item.part_no_vat_price,
                          price_unit: item.price_unit,
                          partUnitType: element.part_unit_types.name,
                          partUnit :  element.part_unit,
                          partUnitTypeId: element.part_unit_types.id,
                          price_unit_id: this.activeCurrency?.id,
                          partCode: item.part_code,
                          currencyPriceUnitId: item.currencyPriceUnitId,
                          currencyPriceUnit: item.currencyPriceUnit,
                          priceCurrency: priceWithCurrency,
                          priceNoVatCurrency: priceNoVatCurrency,
                          totalPriceCurrency: (priceWithCurrency * element.part_unit),
                          name: item.name,
                          partTypeName: item.part_types.name,
                          partTypeId: item.part_types.id
                        };
                      */

                        //?Manipüle ettik
                        let halfPartInVatPrice = ( ( element.part_original_list.part_in_vat_price * 60 ) / 100 );
                        let halfPartNoVatPrice = ( ( element.part_original_list.part_no_vat_price * 60 ) / 100 );

                        let newItemPartInVatPrice = (halfPartInVatPrice + (halfPartInVatPrice / 2));
                        let newItemPartNoVatPrice = (halfPartNoVatPrice + (halfPartNoVatPrice / 2));
                        priceWithCurrency = newItemPartInVatPrice;

                        newObj.equivalent = {
                          id:item.id,
                          price :newItemPartInVatPrice,
                          priceNoVat: newItemPartNoVatPrice,
                          price_unit: item.price_unit,
                          partUnitType: element.part_unit_types.name,
                          partUnit :  element.part_unit,
                          partUnitTypeId: element.part_unit_types.id,
                          price_unit_id: this.activeCurrency?.id,
                          partCode: item.part_code,
                          currencyPriceUnitId: item.currencyPriceUnitId,
                          currencyPriceUnit: item.currencyPriceUnit,
                          priceCurrency: newItemPartInVatPrice,
                          priceNoVatCurrency: newItemPartNoVatPrice,
                          totalPriceCurrency: (priceWithCurrency * element.part_unit),
                          name: item.name,
                          partTypeName: item.part_types.name,
                          partTypeId: item.part_types.id
                        };
                      
                       
                       selectedEsdegerPrice = item.part_in_vat_price;
                    }else{


                       if(item.part_in_vat_price > selectedEsdegerPrice){

                        let halfPartInVatPrice = ( ( element.part_original_list.part_in_vat_price * 60 ) / 100 );
                        let halfPartNoVatPrice = ( ( element.part_original_list.part_no_vat_price * 60 ) / 100 );

                        let newItemPartInVatPrice = ( halfPartInVatPrice + ( halfPartInVatPrice / 2 ) );
                        let newItemPartNoVatPrice = ( halfPartNoVatPrice + ( halfPartNoVatPrice / 2 ) );
                        priceWithCurrency = newItemPartInVatPrice;
                        //eşdeğer
                        newObj.equivalent = {
                          id:item.id,
                          price :newItemPartInVatPrice,
                          priceNoVat: newItemPartNoVatPrice,
                          price_unit: item.price_unit,
                          partUnitType: element.part_unit_types.name,
                          partUnit :  element.part_unit,
                          partUnitTypeId: element.part_unit_types.id,
                          price_unit_id: this.activeCurrency?.id,
                          partCode: item.part_code,
                          currencyPriceUnitId: item.currencyPriceUnitId,
                          currencyPriceUnit: item.currencyPriceUnit,
                          priceCurrency: newItemPartInVatPrice,
                          priceNoVatCurrency: newItemPartNoVatPrice,
                          name: item.name,
                          partTypeName: item.part_types.name,
                          totalPriceCurrency: (priceWithCurrency * element.part_unit),
                          partTypeId: item.part_types.id
                        };

                           //eşdeğer
                          /* newObj.equivalent = {
                             id:item.id,
                             price :item.part_in_vat_price,
                             priceNoVat: item.part_no_vat_price,
                             price_unit: item.price_unit,
                             partUnitType: element.part_unit_types.name,
                             partUnit :  element.part_unit,
                             partUnitTypeId: element.part_unit_types.id,
                             price_unit_id: this.activeCurrency?.id,
                             partCode: item.part_code,
                             currencyPriceUnitId: item.currencyPriceUnitId,
                             currencyPriceUnit: item.currencyPriceUnit,
                             priceCurrency: priceWithCurrency,
                             priceNoVatCurrency: priceNoVatCurrency,
                             name: item.name,
                             partTypeName: item.part_types.name,
                             totalPriceCurrency: (priceWithCurrency * element.part_unit),
                             partTypeId: item.part_types.id
                           };*/
                         
                         
                           selectedEsdegerPrice = priceWithCurrency;
                       }
 
                    }

                 
                   break;
         
                  /*    
                    case 5:
                     //original
                     newObj.original = {
                       id: item.id,
                       price: item.part_in_vat_price,
                       priceNoVat: item.part_no_vat_price,
                       price_unit: item.price_unit,
                       partUnitType: element.part_unit_types.name,
                       partUnit :  element.part_unit,
                       partUnitTypeId: element.part_unit_types.id,
                       price_unit_id: this.activeCurrency?.id,
                       partCode: item.part_code,
                       currencyPriceUnitId: item.currencyPriceUnitId,
                       currencyPriceUnit: item.currencyPriceUnit,
                       priceCurrency: priceWithCurrency,
                       priceNoVatCurrency: priceNoVatCurrency,
                       name: item.name,
                       partTypeName: item.part_types.name,
                       totalPriceCurrency: priceWithCurrency * element.part_unit,
                       
                       
                       
                     };
                     currentType = "original";
                     
                    break;
                  */
         
                 }

                
               });
 

               if(element.part_unit_type == 3){
                this.addPiece(newObj.offical_original.id,newObj.selectedGosPart.id,newObj.iscilik_id,"original",true);
                this.iscilikParts.push(newObj);



               }else{
                this.partEquivalentList.push(newObj);
              
                this.totalsByTypes.orjinal = parseFloat( (this.totalsByTypes.orjinal + newObj.original.totalPriceCurrency ).toFixed(2));
                newObj.original.totalPriceCurrency = parseFloat(newObj.original.totalPriceCurrency.toFixed(2));
              
                if(newObj.equivalent != null){
                  this.totalsByTypes.es_deger =  (parseFloat(this.totalsByTypes.es_deger.toFixed(2)) + parseFloat(newObj.equivalent?.totalPriceCurrency.toFixed(2)) );
                  newObj.equivalent.totalPriceCurrency = parseFloat( newObj.equivalent?.totalPriceCurrency.toFixed(2));
                }
                
               
               // this.totalsByTypes.logosuz = 0; //parseFloat( (this.totalsByTypes.logosuz + newObj.without_logo.totalPriceCurrency ).toFixed(2));
               // newObj.without_logo.totalPriceCurrency  = 0; //parseFloat( newObj.without_logo.totalPriceCurrency.toFixed(2) );
                
   
               }
             
             

           
              
                 /* this.firstLoad = false;
            
               if(this.selectedPartIds.length > 0 && this.firstLoad){
                 this.selectedPartIds.filter((item:any)=>{
                   if(item == newObj.id){
                     this.addPiece(element.part_original_list.id,element.part.id,item,currentType);
                   }
                 });
               }
               */
 
             }
          
            
     
           

          });

      }

    }    

  }

  selectAll(event:any){

    let action = event.target.value;

    this.selectedParts = [];
    if( this.partEquivalentList.length > 0 ){

      this.partEquivalentList.forEach((element:any) => {

        if( element[action] != null ){

          this.addPiece( element.offical_original.id, element.selectedGosPart.id, element[action].id, action );

          let id = action + "_" + element.offical_original.id
        
          let checkbox = document.getElementById(id) as HTMLInputElement;
          checkbox.checked = true;

        }else{

          if(element.original?.partUnitTypeId == 3){
            //?eğer işçilik ise orjinali seç
            this.addPiece( element.offical_original.id, element.selectedGosPart.id, element.original.id, 'original' )
          }

        }

      });

    }
  }

  removePiece(selectedPartId:Number,equivalentId:Number){
  
  }

  addPiece(partOriginalId:Number,selectedPartId:Number,equivalentId:Number,partType:string,showdebug=false){
 
    if(showdebug){
     //;
    }

    if(selectedPartId == 0 || equivalentId == 0){
      this.alertifyService.error("Bir hata oluştu22");
      return false;
    }
    if(this.partEquivalentList && this.partEquivalentList.length > 0){

      let selectedPartInfo = this.partEquivalentList.filter((obj:any) => (obj.selectedGosPart.id == selectedPartId));
      if(selectedPartInfo && selectedPartInfo.length > 0){

        let existsOriginalPart = this.packageDetail?.maintenance_package_details.filter((obj:any)=>obj.part_original_id == partOriginalId );
        if(existsOriginalPart && existsOriginalPart.length > 0){

           let selectedOriginalPart = existsOriginalPart[0];

           let selectedEquivalent;

           if(partType == "original"){
            selectedEquivalent = selectedOriginalPart.part_original_list;
           }else{
            selectedEquivalent = selectedOriginalPart.part_original_list.part_equivalent_lists.filter((equivalentObj:any) => (equivalentObj.id == equivalentId));
            selectedEquivalent = selectedEquivalent[0];
          }


           
           if(selectedEquivalent){

              this.selectedParts = this.selectedParts.filter((obj:any)=> obj.selectedGosPart_id != selectedPartId);

             
              //?burayı manipüle ettik  
              let halfPartInVatPrice = ( ( selectedOriginalPart.part_original_list.part_in_vat_price * 60 ) / 100 );
              let halfPartNoVatPrice = ( ( selectedOriginalPart.part_original_list.part_no_vat_price * 60 ) / 100 );

              selectedEquivalent.part_in_vat_price = ( halfPartInVatPrice + (halfPartInVatPrice / 2 ) );
              selectedEquivalent.part_no_vat_price = ( halfPartNoVatPrice + (halfPartNoVatPrice / 2 ) );


              //? toFixed ile ücretleri 2 haneye indiriyoruz
              //?burayı manipüle ettik  
            /*  selectedEquivalent.part_in_vat_price = parseFloat(parseFloat(selectedEquivalent.part_in_vat_price).toFixed(2));
              selectedEquivalent.part_no_vat_price = parseFloat(parseFloat(selectedEquivalent.part_no_vat_price).toFixed(2));*/

              let priceWithUnit:number      = selectedPartInfo[0][partType].priceCurrency * selectedPartInfo[0].part_unit; 
              let priceNoVatWithUnit:number = selectedPartInfo[0][partType].priceNoVatCurrency * selectedPartInfo[0].part_unit; 
              

              let priceWithUnitCurrency = selectedEquivalent.priceCurrency * selectedPartInfo[0].part_unit; 
              let priceNoVatWithUnitCurrency = selectedEquivalent.priceNoVatCurrency * selectedPartInfo.part_unit; 
              let priceCurrency = selectedEquivalent.priceCurrency;
              let gosLabourPrice = 0;
              let brandLabourPrice = 0;

              if( selectedPartInfo[0][partType].partUnitTypeId == 3 ){//saat ise işçilik ekle

                if( parseInt(this.gosLabourPrice) > 0 ){

                  gosLabourPrice += ( this.gosLabourPrice * selectedPartInfo[0][partType].partUnit );

                }

                if( parseInt(this.brandLabourPrice) > 0 ){

                  brandLabourPrice += ( this.brandLabourPrice * selectedPartInfo[0][partType].partUnit );

                }

                //gosLabourPrice += ()

              }else{
                /*if(existsOriginalPart[0].part.price_ratio > 0){
                  priceWithUnitCurrency = priceWithUnitCurrency + ((priceWithUnitCurrency * existsOriginalPart[0].part.price_ratio) / 100);
                  priceNoVatWithUnitCurrency = priceNoVatWithUnitCurrency + ((priceNoVatWithUnitCurrency * existsOriginalPart[0].part.price_ratio) / 100);
                  priceCurrency = priceCurrency + ((priceCurrency * existsOriginalPart[0].part.price_ratio) / 100);
                  priceWithUnit = priceWithUnit + ((priceWithUnit * existsOriginalPart[0].part.price_ratio) / 100);
                 }*/
              }

            

              
              this.selectedParts.push({
                package_detail_id: selectedPartInfo[0].package_detail_id,
                selectedGosPart_id :  selectedPartInfo[0].selectedGosPart.id,
                selectedGosPart_name: selectedPartInfo[0].selectedGosPart.name,
                labourTypeId: null,
                labourValue: null,
                labourDiscount: null,
                brandLabourPrice: brandLabourPrice, //* manuel parçada işçilik ürün olarak eklenmekte o yüzden gos ve brand labour price 0 gönderilmektedir.
                gosLabourPrice: gosLabourPrice,
                orderId: 0,
                selectedEquivalent_currencyPrice : priceCurrency,
                selectedEquivalent_currencyNoVatPrice : priceNoVatWithUnitCurrency,
                selectedEquivalent_currencyTotalPrice : priceWithUnitCurrency,
                selectedEquivalent_currencyPriceUnitId : selectedEquivalent.price_unit_id,
                selectedEquivalent_currencyNoVatTotalPrice: priceNoVatWithUnitCurrency,
                selectedEquivalent_priceTotal: priceWithUnit.toFixed(2),
                selectedEquivalent_priceVat: parseFloat(( priceWithUnit - priceNoVatWithUnit ).toFixed(2)),
                selectedEquivalent_priceNoVatTotal: priceNoVatWithUnit.toFixed(2),
                selectedEquivalent_partDiscount: null, //TODO: buraya daha sonra discount eklenbilir.
                selectedEquivalent_partTypeId: selectedEquivalent.part_type_id,
                selectedEquivalent_partValue: selectedEquivalent.part_in_vat_price,
                selectedEquivalent_partNoVatValue: selectedEquivalent.part_no_vat_price,
                selectedEquivalent_id: selectedEquivalent.id,
                selectedEquivalent_name: selectedEquivalent.name,
                selectedEquivalent_partTypeName: selectedEquivalent.part_types.name,
                selectedEquivalent_priceUnitId: this.activeCurrency?.id,
                selectedEquivalent_priceUnitName: this.activeCurrency?.code,
                selectedEquivalent_partUnitType: selectedPartInfo[0][partType].partUnitType,
                selectedEquivalent_partUnitTypeId: selectedPartInfo[0][partType].partUnitTypeId,
                selectedEquivalent_partUnit : selectedPartInfo[0][partType].partUnit,
                selectedEquivalent_partUnitName : selectedOriginalPart.part_unit_types.name,
                selectedEquivalent_partCode : selectedEquivalent.part_code,
                selectedEquivalent_serviceValue: 0,
                selectedEquivalent_serviceValueUnit: 0,
              });

            
          

           }
        }

      }

    }
    this.setTotalAmount();
  }

  addPiece_old(partOriginalId:Number,selectedPartId:Number,equivalentId:Number,partType:string,showdebug=false){
    /** eşdeğer fiyat manüpilasyonu sonrası burayı aktif hale getireceğiz.  */
    if(showdebug){
     //;
    }

    if(selectedPartId == 0 || equivalentId == 0){
      this.alertifyService.error("Bir hata oluştu22");
      return false;
    }
    if(this.partEquivalentList && this.partEquivalentList.length > 0){

      let selectedPartInfo = this.partEquivalentList.filter((obj:any) => (obj.selectedGosPart.id == selectedPartId));

      if(selectedPartInfo && selectedPartInfo.length > 0){


        let existsOriginalPart = this.packageDetail?.maintenance_package_details.filter((obj:any)=>obj.part_original_id == partOriginalId );
        if(existsOriginalPart && existsOriginalPart.length > 0){

           let selectedOriginalPart = existsOriginalPart[0];

           let selectedEquivalent;

           if(partType == "original"){
            selectedEquivalent = selectedOriginalPart.part_original_list;
           }else{
            selectedEquivalent = selectedOriginalPart.part_original_list.part_equivalent_lists.filter((equivalentObj:any) => (equivalentObj.id == equivalentId));
            selectedEquivalent = selectedEquivalent[0];
          }


           
           if(selectedEquivalent){

              this.selectedParts = this.selectedParts.filter((obj:any)=> obj.selectedGosPart_id != selectedPartId);

             
              

              //? toFixed ile ücretleri 2 haneye indiriyoruz
              selectedEquivalent.part_in_vat_price = parseFloat(parseFloat(selectedEquivalent.part_in_vat_price).toFixed(2));
              selectedEquivalent.part_no_vat_price = parseFloat(parseFloat(selectedEquivalent.part_no_vat_price).toFixed(2));

              let priceWithUnit:number      = selectedEquivalent.part_in_vat_price * selectedPartInfo[0].part_unit; 
              let priceNoVatWithUnit:number = selectedEquivalent.part_no_vat_price * selectedPartInfo[0].part_unit; 
              

              let priceWithUnitCurrency = selectedEquivalent.priceCurrency * selectedPartInfo[0].part_unit; 
              let priceNoVatWithUnitCurrency = selectedEquivalent.priceNoVatCurrency * selectedPartInfo.part_unit; 
              let priceCurrency = selectedEquivalent.priceCurrency;

              let gosLabourPrice = 0;
              let brandLabourPrice = 0;

              if( selectedPartInfo[0][partType].partUnitTypeId == 3 ){//saat ise işçilik ekle

                if( parseInt(this.gosLabourPrice) > 0 ){

                  gosLabourPrice += ( this.gosLabourPrice * selectedPartInfo[0][partType].partUnit );

                }

                if( parseInt(this.brandLabourPrice) > 0 ){

                  brandLabourPrice += ( this.brandLabourPrice * selectedPartInfo[0][partType].partUnit );

                }

                //gosLabourPrice += ()

              }else{
                /*if(existsOriginalPart[0].part.price_ratio > 0){
                  priceWithUnitCurrency = priceWithUnitCurrency + ((priceWithUnitCurrency * existsOriginalPart[0].part.price_ratio) / 100);
                  priceNoVatWithUnitCurrency = priceNoVatWithUnitCurrency + ((priceNoVatWithUnitCurrency * existsOriginalPart[0].part.price_ratio) / 100);
                  priceCurrency = priceCurrency + ((priceCurrency * existsOriginalPart[0].part.price_ratio) / 100);
                  priceWithUnit = priceWithUnit + ((priceWithUnit * existsOriginalPart[0].part.price_ratio) / 100);
                 }*/
              }

              
              
              this.selectedParts.push({
                package_detail_id: selectedPartInfo[0].package_detail_id,
                selectedGosPart_id :  selectedPartInfo[0].selectedGosPart.id,
                selectedGosPart_name: selectedPartInfo[0].selectedGosPart.name,
                labourTypeId: null,
                labourValue: null,
                labourDiscount: null,
                brandLabourPrice: brandLabourPrice, //* manuel parçada işçilik ürün olarak eklenmekte o yüzden gos ve brand labour price 0 gönderilmektedir.
                gosLabourPrice: gosLabourPrice,
                orderId: 0,
                selectedEquivalent_currencyPrice : priceCurrency,
                selectedEquivalent_currencyNoVatPrice : priceNoVatWithUnitCurrency,
                selectedEquivalent_currencyTotalPrice : priceWithUnitCurrency,
                selectedEquivalent_currencyPriceUnitId : selectedEquivalent.price_unit_id,
                selectedEquivalent_currencyNoVatTotalPrice: priceNoVatWithUnitCurrency,
                selectedEquivalent_priceTotal: priceWithUnit.toFixed(2),
                selectedEquivalent_priceVat: parseFloat(( priceWithUnit - priceNoVatWithUnit ).toFixed(2)),
                selectedEquivalent_priceNoVatTotal: priceNoVatWithUnit.toFixed(2),
                selectedEquivalent_partDiscount: null, //TODO: buraya daha sonra discount eklenbilir.
                selectedEquivalent_partTypeId: selectedEquivalent.part_type_id,
                selectedEquivalent_partValue: selectedEquivalent.part_in_vat_price,
                selectedEquivalent_partNoVatValue: selectedEquivalent.part_no_vat_price,
                selectedEquivalent_id: selectedEquivalent.id,
                selectedEquivalent_name: selectedEquivalent.name,
                selectedEquivalent_partTypeName: selectedEquivalent.part_types.name,
                selectedEquivalent_priceUnitId: this.activeCurrency?.id,
                selectedEquivalent_priceUnitName: this.activeCurrency?.code,
                selectedEquivalent_partUnitType: selectedPartInfo[0][partType].partUnitType,
                selectedEquivalent_partUnitTypeId: selectedPartInfo[0][partType].partUnitTypeId,
                selectedEquivalent_partUnit : selectedPartInfo[0][partType].partUnit,
                selectedEquivalent_partUnitName : selectedOriginalPart.part_unit_types.name,
                selectedEquivalent_partCode : selectedEquivalent.part_code,
                selectedEquivalent_serviceValue: 0,
                selectedEquivalent_serviceValueUnit: 0,
              });

            
          

           }
        }

      }

    }
    this.setTotalAmount();
  }

  setTotalAmount(){

      this.grandTotal = 0;
      this.subTotal = 0;
      this.difference = 0;
      let orderInfo = {};
      this.brandMaintenancePrice = 0;
      this.gosMaintenancePrice = 0;
//? işçilik ücreti gosMaintenancePrice kısmına ekelencek mi?
//? işçilik kdv li hesaplanıp kdvsi alınacak mı?
//? gos işçiliik ücreti kdvsiz alınıp kdv si kdv sekmesinemi eklenecek?
//? parça işçilik ücreti kdvsiz alınıp kdv si kdv sekmesinemi eklenecek?


      if(this.iscilikParts.length > 0 && this.vehicleModel){
        this.iscilikParts.forEach(element => {
          if(this.vehicleModel && this.vehicleModel.gos_labour_price){
            this.gosMaintenancePrice += ( element.original.partUnit * Number(this.vehicleModel.gos_labour_price) );


          }
        });
      }

      //this.selectedPieces = this.selectedParts;
    
    if(this.selectedParts && this.selectedParts.length > 0){

     
      this.selectedParts.forEach((element:any) => {

        element.selectedEquivalent_priceTotal = parseFloat(parseFloat(element.selectedEquivalent_priceTotal).toFixed(2));
        element.selectedEquivalent_priceNoVatTotal = parseFloat(parseFloat(element.selectedEquivalent_priceNoVatTotal).toFixed(2));
       
        this.grandTotal = parseFloat(( element.selectedEquivalent_priceTotal + this.grandTotal ).toFixed(2));
        this.subTotal   = parseFloat(( element.selectedEquivalent_priceNoVatTotal + this.subTotal ).toFixed(2));

        if(element.selectedEquivalent_partUnitTypeId == 3){

         if( element.gosLabourPrice > 0 ){
            this.grandTotal += element.gosLabourPrice;
            this.gosMaintenancePrice += element.gosLabourPrice;
          }
  
     /*     if( element.brandLabourPrice > 0 ){
            this.grandTotal += element.brandLabourPrice;
            this.brandMaintenancePrice = element.brandLabourPrice;
            this.brandMaintenancePriceNoVat = ( element.brandLabourPrice - ( ( element.brandLabourPrice * 18 ) / 100 ) )
          }*/

          

          //this.subTotal   = parseFloat(( this.subTotal + this.brandMaintenancePriceNoVat + this.brandMaintenancePriceNoVat ).toFixed(2));
       //   this.subTotal   = parseFloat(this.subTotal.toFixed(2));

          


        }else{

          


        }

        

      });

      let selectedCompaignProducts = this.selectedParts.filter((obj:any) => (obj.selected_part_group_id == 2));//yağ gurubu ise ayrı göster

      let selectedUncompaignProducts = this.selectedParts.filter((obj:any) => (obj.selected_part_group_id != 2));


     
      /*this.selectedParts = selectedCompaignProducts;
      this.selectedParts += selectedUncompaignProducts;*/


      


      this.difference = parseFloat(( this.grandTotal - this.subTotal ).toFixed(2));

      if( this.gosMaintenancePrice > 0 ){
        this.grandTotal += this.gosMaintenancePrice;
      
      }

      this.grandTotal = parseFloat(this.grandTotal.toFixed(2));
      orderInfo = {
        status_id: 1,
        type_id: 3,
        customer_id: 0,
        vehicle_id: this.vehicleInfo?.id,
        service_date: null,
        order_value: this.grandTotal,
        order_no_vat_value: this.subTotal,
        maintenance_package_id: 0,
        service_id: 0,
        customer_status_id: 2
      }


      if(this.selectedParts.length == this.partEquivalentList.length){
        this.savingTotal = (this.totalsByTypes.yetkili_orjinal - this.grandTotal);
        this.returnParts.emit({amount:{grandTotal: this.grandTotal,serviceTotal: this.grandTotal, subTotal: this.subTotal, difference: this.difference},parts:this.selectedParts,orderInfo: orderInfo});

      }else{
        this.savingTotal = 0;
      }


    }else{

      if(this.selectedParts.length == this.partEquivalentList.length){

        this.returnParts.emit({amount:{grandTotal: this.grandTotal,serviceTotal: this.grandTotal, subTotal: this.subTotal, difference: this.difference},parts:this.selectedParts,orderInfo: orderInfo});
      }
    }

  }
  




}
