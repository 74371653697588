import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { City } from 'src/app/graphql/cities.graphql';
import { Country } from 'src/app/graphql/countries.graphql';
import { District } from 'src/app/graphql/districts.graphql';
import { Town } from 'src/app/graphql/town.graphql';
import { USER_UPDATE_BY_PK } from 'src/app/graphql/user.graphql';
import { UserAddress, USER_ADDRESS_ID, USER_ADDRESS_UPDATE_CUSTOM } from 'src/app/graphql/user_address.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-offer-address-edit',
  templateUrl: './address-edit.component.html',
  styles: [
  ],
  providers:[ USER_UPDATE_BY_PK,USER_ADDRESS_ID,USER_ADDRESS_UPDATE_CUSTOM]

})
export class AddressEditComponent implements OnInit {

  @Input() addressId: BigInt | undefined;
  @Input() userId: bigint | undefined;
  @Input() primaryAddress: bigint | undefined;
  @Output() reloadList = new EventEmitter<any>();

  @Input() countryInputId:any; 
  @Input() cityInputId:any; 
  @Input() districtInputId:any; 
  @Input() townInputId:any; 

  countryList: Country[] | undefined;
  cityList: Observable<City[]> | undefined;
  districtList: Observable<District[]> | undefined;
  townList: Observable<Town[]> | undefined;
  selectedTownId: Number = 0;
  selectedDistrictId: Number = 0;
  selectedCityId: Number = 0;
  selectedCountryId: Number = 0;

  addressForm = new FormGroup({
    address_name: new FormControl('', [Validators.required, Validators.min(3)]),
    active: new FormControl(true, [Validators.required, Validators.minLength(1)]),
    address: new FormControl('', [Validators.required, Validators.min(2)]),
    country_id: new FormControl('', [Validators.required, Validators.min(1)]),
    city_id: new FormControl('', [Validators.required, Validators.min(1)]),
    district_id: new FormControl('', [Validators.required, Validators.min(1)]),
    town_id: new FormControl('', [Validators.required, Validators.min(1)]),
    is_primary: new FormControl(false),
    user_id: new FormControl(null, [Validators.required])
  });

  address: UserAddress | undefined;
  public resetForm: boolean = false;

  constructor(
    private getAddress: USER_ADDRESS_ID,
    public activatedRoute: ActivatedRoute,
    private updateAddress: USER_ADDRESS_UPDATE_CUSTOM,
    public alertifyService: AlertifyService,
    private authService: AuthService,
    private updateUsers : USER_UPDATE_BY_PK


  ) {  }

  ngOnInit(): void {
  }

  
  ngOnChanges(){

    if(this.cityInputId == undefined){
      this.cityInputId = 'cityList';
    }

    if(this.districtInputId == undefined){
      this.districtInputId = 'districtList1';
    }

    if(this.countryInputId == undefined){
      this.countryInputId = 'countryList';
    }

    if(this.townInputId == undefined){
      this.townInputId = 'townList';
    }

   
    this.getAddress.watch({id:this.addressId},{fetchPolicy:"no-cache"}).valueChanges.subscribe((result:any)=>{
      if(result.error){
        this.alertifyService.error("Bir sorun oluştu");
      }

      if(result.data){
        this.address = result.data.user_address_by_pk;
       

        if(this.address){
          this.addressForm.patchValue(this.address);
          if(this.address.country_id > 0){
            this.changeGeoZone("country",this.address.country_id);
            
  
            if(this.address.city_id > 0){
              this.changeGeoZone("city",this.address.city_id);
  
              if(this.address.district_id > 0){
                this.changeGeoZone("district",this.address.district_id);
  
                if(this.address.town_id > 0){
                  this.changeGeoZone("town",this.address.town_id);
                }
              }
            }
          }
        }

      }

    });
  }

  changeGeoZone(type:String,$event:any){
    switch(type){
      case "country": 
        this.selectedCountryId = $event;
        this.addressForm.patchValue({country_id:this.selectedCountryId});
       // this.selectedCityId = 0;
       // this.selectedDistrictId = 0;
       // this.selectedTownId = 0;
       
      break;
      case "city": 
        this.selectedCityId = $event;
        this.addressForm.patchValue({city_id:this.selectedCityId});
       // this.selectedDistrictId = 0;
       // this.selectedTownId = 0;
      break;
      case "district":
        this.selectedDistrictId = $event;
        this.addressForm.patchValue({district_id:this.selectedDistrictId});
       // this.selectedTownId = 0;

        break;
      case "town":
       
      this.selectedTownId = $event;
      this.addressForm.patchValue({town_id:this.selectedTownId});

      break;
    }

  }

  onSubmit() {

    if(this.addressId){
      if (this.addressForm.invalid) {
        //alert("hatalı form bilgileri");
        this.alertifyService.error("Lütfen zorunlu alanları doldurunuz.");
        return;
      }

      if(this.addressForm.value.is_primary){
        this.addressForm.patchValue({is_primary:1});
      }else{
        this.addressForm.patchValue({is_primary:0});
  
      }
  
      if(this.addressForm.value.is_primary == 1){
        this.updateAddress.mutate({
          where:{user_id:{_eq:this.authService.userId}},
          changes:{is_primary:0}
        }).subscribe((result:any)=>{
          if(result.errors){
            this.alertifyService.error("Bir sorun oluştu");
          }
  
          if(result.data){
            this.addressForm.patchValue({is_primary:1});
            this.updateAddress.mutate({
              where:{id: {_eq:this.addressId}},
              changes:{...this.addressForm.value}
            }).subscribe((response:any) => {
              if (response.data) {
                this.alertifyService.success("Adres Kayıt Edildi")
                if(this.addressForm.value.is_primary == 1){
                  
                  
                  this.updateUsers.mutate({id:this.authService.userId,changes:{address_id:this.addressId}}).subscribe((result:any)=>{
                    if(result.errors){
                      this.alertifyService.error(result.errors[0].message);
                    }
                    if(result.data){
                      this.alertifyService.success("Adres birincil adres olarak eklendi.")
                      this.reloadList.emit(true);
                    }
                  })
                  
                }else{
                  this.reloadList.emit(true);
                }
              }
              if (response.errors) {
                this.alertifyService.error("Bir Hata Oluştu!")
              }
            })
          }
        })
      }else{
        this.updateAddress.mutate({
          where:{id: {_eq:this.addressId}},
          changes:{...this.addressForm.value}
        }).subscribe((response:any) => {
          if (response.data) {
            this.alertifyService.success("Adres Kayıt Edildi")
            if(this.addressForm.value.is_primary == 1){
              
              
              this.updateUsers.mutate({id:this.authService.userId,changes:{address_id:this.addressId}}).subscribe((result:any)=>{
                if(result.errors){
                  this.alertifyService.error(result.errors[0].message);
                }
                if(result.data){
                  this.alertifyService.success("Adres birincil adres olarak eklendi.")
                  this.reloadList.emit(true);
                }
              })
              
            }else{
              this.reloadList.emit(true);
            }
          }
          if (response.errors) {
            this.alertifyService.error("Bir Hata Oluştu!")
          }
        })
      }
    
      
    }

    
  }

}
