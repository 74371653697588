<div class="card card-flush py-4 flex-row-fluid mt-10" style="border: 1px solid #f0f0f0;" 
*ngIf="orderDetail?.type_id == 3">
    <div class="card-header">
        <div class="card-title"><h2>Eklenecek Parçalar</h2></div>
        <div class="d-flex align-items-center gap-2 gap-lg-3"><button (click)="togglePiece()" class="btn btn-sm btn-primary">Parça Ekle</button></div>
    </div>
    <div class="card-body pt-0 ">
        <div class="col-md-12">
            <span style="margin-right: 10px;" class="badge badge-success fs-base" *ngFor="let item of unselectedParts">{{item.parts.name}}</span>
        </div>
    </div>
    
</div>

<div class="card card-flush py-4 flex-row-fluid mt-10" style="border: 1px solid #f0f0f0;" 
    *ngIf="orderDetail?.type_id == 3 && showAddPiece">
        <div class="card-body">
            <app-shared-manuel-parts
            [vehicleInfo]="vehicleInfo"
            [modelTypeId]="vehicleInfo?.model_type_id"
            [defaultPart]="selectedPartCode"
            (returnParts)="setSelectedParts($event)"
            ></app-shared-manuel-parts>

            <div class="card-px text-center">
                <button class="btn btn-sm btn-primary" (click)="updateSelectedParts()">Seçilenleri Güncelle</button>

            </div>
        </div>

</div>

<div class="card card-flush py-4 flex-row-fluid" >
                                  
    <div class="card-body pt-0 ">
    <div class="table-responsive">
        <table class="table" *ngIf="selectedParts && selectedParts.length > 0">
            <thead>
                <tr class="border-bottom fs-6 fw-bolder text-muted text-uppercase">
                    <th class="min-w-175px pb-9">Parça Adı</th>
                    <th class="min-w-px pb-9">Adet</th>
                    <th class="min-w-150px pb-9">Tip</th>
                    <th class="min-w-150px pe-lg-6 pb-9">Tedarik Tipi</th>
                    <th class="w-120px pb-9">Gos Ücreti</th>
                    <th class="w-120px pb-9">Servis Ücreti</th>
                    <th class="min-w-150px pb-9"></th>
                
                </tr>
            </thead>
            <tbody>
                <tr class="fw-bolder text-gray-700 fs-5"  
                *ngFor="let item of selectedParts">
                    <td class="d-flex align-items-center pt-6">
                        {{item.parts.name}}
                    </td>
                    <td class="pt-6">
                        <span  >{{item.part_unit}} {{item.part_unit_types.name}}</span>
                    </td>
                    <td class="pt-6">
                        <span  >{{item.part_types.name}}</span>
                    </td>
                    <td class="pt-6">
                        <span *ngIf="item.service_value > 0;else gosType">Servis</span>
                        <ng-template #gosType>
                            <span>Gos</span>
                        </ng-template>
                    </td>
                   
                    <td class="pt-6">
                        <span class="text-gray-400 fw-bolder text-hover-primary d-block mb-1 fs-7">( {{item.part_value  | number:'1.0-2'}} &#8378; )</span>
                        <span >{{item.total_price  | number:'1.0-2'}} &#8378;</span>
                    </td>
                   
                    <td class="pt-6">
                        <span class="text-gray-400 fw-bolder text-hover-primary d-block mb-1 fs-7">( {{item.service_value_unit  | number:'1.0-2'}} &#8378; )</span>
                        <span>{{item.service_value  | number:'1.0-2'}} &#8378;</span>
                   
                    </td> 
                    <td class="pt-6">
                        <app-shared-price-input
                        *ngIf="item.service_price_lock == false"
                        [process]="'loe'"
                        [compare]="item.part_value"
                        (returnVal)="addServicePrice(item.id,$event)"
                        [name]="'input_'+item.id"
                    ></app-shared-price-input>
                    </td>
                    <td class="pt-7">
                        <a *ngIf="item.service_price_lock == false" class="btn btn-icon btn-primary btn-sm mr-2" (click)="updatePrice(item.id,item.parts.name,item.part_value,item.service_value,item.part_unit)"><i class="fas fa-plus"></i></a>
                    </td>
                    
                </tr>
                
            </tbody>
        </table>
        
    </div>
</div>
</div>
