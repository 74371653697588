<div class="me-3 " style="position: relative;">
    <a class="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder" (click)="showFilter()"
        [ngClass]="{show: show}">
        <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                    fill="black" />
            </svg>
        </span>
        Filtre
    </a>
    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px filterContainer" id="filtCont" [ngClass]="{show: show}"
        style="z-index: 105; position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate3d(0px, 40px, 0px);">
        <div class="px-7 py-5" style="clear: both;">
            <div class="text-dark fw-bolder" style="float: left;">Filtre</div>
            <a class="" style="display:block;float: right;"><span class="svg-icon svg-icon-1" (click)="close()">
                <svg xmlns="http://www.w3.org/2000/svg" class="closebtn" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                </svg>
            </span></a>
        </div>
        <div class="separator border-gray-200"></div>
        <div class="px-7 py-5">
            <ng-content></ng-content>
        </div>
    </div>
</div>