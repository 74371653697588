import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface ServiceInvoices {
  id: bigint;
  order_id: bigint;
  date?: Date;
  value_no_vat?: Number;
  value_in_vat?: Number;
  vat?:Number;
  discount?:Number;
  url?:string;
  invoice_type_id?:Number;
  valor_date?:Date;
  invoice_status_id?:Number;
  refeasual_date?:Date;
  refasual_value?:Number;
  refasual_reason_id?:Number;
  paid_value?:Number;
  remaining_value?:Number;
  returned_invoice_id?:Number;
  note?: String;
  invoice_reciever_id?: Number;
  invoice_reciever_type_id?: Number;
  position_no: Number;
  service_invoice_parts:any;
 
}

interface ResponseArray {
  service_invoices: ServiceInvoices[];
}

interface ResponseSingle {
  service_invoices_by_pk: ServiceInvoices
}

@Injectable()
export class SERVICE_INVOICE_ALL extends Query<ResponseArray> {
  override document = gql`
    query SERVICE_INVOICE_ALL($where: service_invoices_bool_exp){
        service_invoices(where: $where) {
        id
        order_id
        date
        value_no_vat
        value_in_vat
        vat
        discount
        url
        invoice_type_id
        valor_date
        invoice_status_id
        refeasual_date
        refasual_value
        refasual_reason_id
        paid_value
        remaining_value
        returned_invoice_id
        note
        invoice_reciever_id
        invoice_reciever_type_id
        position_no
      }
    }
  `;
}

@Injectable()
export class SERVICE_INVOICE_ID extends Query<ResponseSingle> {
  override document = gql`
    query SERVICE_INVOICE_ID($id: bigint!){
      service_invoices_by_pk(id: $id) {
        id
        order_id
        date
        value_no_vat
        value_in_vat
        vat
        discount
        url
        invoice_type_id
        valor_date
        invoice_status_id
        refeasual_date
        refasual_value
        refasual_reason_id
        paid_value
        remaining_value
        returned_invoice_id
        note
        invoice_reciever_id
        invoice_reciever_type_id
        position_no
        
        service_invoice_parts{
          id
          invoice_id
          part_id
          part_name
          part_code
          labour_type_id
          labour_value
          labour_discount
          part_in_vat_price
          part_no_vat_price
          invoice_type
          part_unit_type_id
          part_unit
          part_unit_types{
            name
          }
        }
      }
    }
  `;
}

@Injectable()
export class SERVICE_INVOICE_CREATE extends Mutation<any> {
  // connected_part_id - int | nullable
  override document = gql`
    mutation SERVICE_INVOICE_CREATE( $object: service_invoices_insert_input! ) {
        insert_service_invoices_one(object: $object) {
            id   
        }
    }
  `;
}



@Injectable()
export class SERVICE_INVOICE_UPDATE extends Mutation<any> {
  override document = gql`
    mutation SERVICE_INVOICE_UPDATE($id: bigint!, $changes: service_invoices_set_input ) {
      service_invoices_set_input(where: {id: {_eq: $id}}, _set: $changes) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}


@Injectable()
export class SERVICE_INVOICE_DELETE extends Mutation<any> {
  override document = gql`
    mutation SERVICE_INVOICE_DELETE($id: bigint!) {
      delete_service_invoices(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class SERVICE_INVOICE_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query SERVICE_INVOICE_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: service_invoices_bool_exp) {
      service_invoices(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        order_id
        date
        value_no_vat
        value_in_vat
        vat
        discount
        url
        invoice_type_id
        valor_date
        invoice_status_id
        refeasual_date
        refasual_value
        refasual_reason_id
        paid_value
        remaining_value
        returned_invoice_id
        note
        invoice_reciever_id
        invoice_reciever_type_id
        position_no
        
      }
    }
  `;
}

@Injectable()
export class SERVICE_INVOICE_COUNT extends Query<any> {
  override document = gql`
    query SERVICE_INVOICE_COUNT($where: service_invoices_bool_exp) {
      service_invoices_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}