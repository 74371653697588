import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MapService } from 'src/app/services/map.service';

@Component({
  selector: 'app-shared-maps',
  templateUrl: './maps.component.html',
  styles: [
  ]
})
export class MapsComponent implements OnInit {

  @Input() locations: any[] | undefined;
  @Output() selectedMarker = new EventEmitter<Number>()
  @Output() selectedAddress = new EventEmitter<any>()
  public center!: google.maps.LatLngLiteral
  public markers: any[] = [];
  public options: google.maps.MapOptions = {

  }

  constructor(
    private mapService: MapService
  ) { }

  ngOnInit(): void {
    // Set RS Servis Location
    this.center = {
      lat: 40.918203,
      lng: 29.1591702
    }
    // Get Current Location
    this.currentLocation()
    // Set Service Location
    this.serviceLocation()
  }

  currentLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      this.markers.push({
        position: {
          lat: Number(this.center.lat),
          lng: Number(this.center.lng)
        },
        title: String(0),
        options: {
          draggable: true,
          icon: {
            path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
            fillColor: "green",
            fillOpacity: 1,
            strokeWeight: 2,
            strokeColor: "light-green",
            scale: 2,
            anchor: new google.maps.Point(15, 30)
          }
        }
      })
    })
  }

  mapClick(event: google.maps.MapMouseEvent) {
  }

  markerClick(title: String) {
    if (Number(title)) {
      this.selectedMarker.emit(Number(title))
    }
  }

  markerDragend(event: google.maps.MapMouseEvent) {
    this.mapService.getAddress(event.latLng!.lat(), event.latLng!.lng()).subscribe(response => {
      if (response.status == "OK") {
        this.selectedAddress.emit(response.results[0])
      }
    })
  }

  serviceLocation() {
    if (this.locations) {
      this.locations.forEach(loc => {
        this.markers.push({
          position: {
            lat: Number(loc.lat),
            lng: Number(loc.lon)
          },
          label: {
            color: 'red',
            text: loc.name,
            fontSize: "14px",
            fontWeight: "bold"
          },
          title: String(loc.id),
          options: {
            animation:  google.maps.Animation.BOUNCE,
            icon: {
              path: "M27.648 -41.399q0 -3.816 -2.7 -6.516t-6.516 -2.7 -6.516 2.7 -2.7 6.516 2.7 6.516 6.516 2.7 6.516 -2.7 2.7 -6.516zm9.216 0q0 3.924 -1.188 6.444l-13.104 27.864q-0.576 1.188 -1.71 1.872t-2.43 0.684 -2.43 -0.684 -1.674 -1.872l-13.14 -27.864q-1.188 -2.52 -1.188 -6.444 0 -7.632 5.4 -13.032t13.032 -5.4 13.032 5.4 5.4 13.032z",
              scale: 0.80,
              strokeWeight: 1,
              strokeColor: 'black',
              strokeOpacity: 1,
              fillColor: 'red',
              fillOpacity: 1
            }
          }
        })
      })
    }
  }

}
