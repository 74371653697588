import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface OrderInvoices {
  id: bigint;
  order_id: bigint;
  date?: Date;
  value_no_vat?: Number;
  value_in_vat?: Number;
  vat?:Number;
  discount?:Number;
  url?:string;
  invoice_type_id?:Number;
  valor_date?:Date;
  invoice_status_id?:Number;
  refeasual_date?:Date;
  refasual_value?:Number;
  refasual_reason_id?:Number;
  paid_value?:Number;
  remaining_value?:Number;
  returned_invoice_id?:Number;
  note?: String;
  invoice_reciever_id?: Number;
  invoice_reciever_type_id?: Number;
  position_no: Number;
  is_rated: boolean;
  invoice_parts:any;
  orders?: any;
  vat_price: Number;
  labour_price: Number;
  spare_part_price: Number;
  labour_hour: Number;
  labour_discount: Number;
  labour_discount_price: Number;
  ettn:String,
  invoice_no: String
}

interface ResponseArray {
  order_invoices: OrderInvoices[];
}

interface ResponseSingle {
  order_invoices_by_pk: OrderInvoices
}

@Injectable()
export class ORDER_INVOICE_ALL extends Query<ResponseArray> {
  override document = gql`
    query ORDER_INVOICE_ALL($where: order_invoices_bool_exp){
        order_invoices(where: $where) {
        id
        order_id
        date
        value_no_vat
        value_in_vat
        vat
        discount
        url
        invoice_type_id
        valor_date
        invoice_status_id
        refeasual_date
        refasual_value
        refasual_reason_id
        paid_value
        remaining_value
        returned_invoice_id
        note
        invoice_reciever_id
        invoice_reciever_type_id
        position_no
        is_rated
        vat_price
        labour_price
        spare_part_price
        labour_hour
        labour_discount
        labour_discount_price
        invoice_no
      }
    }
  `;
}

@Injectable()
export class ORDER_INVOICE_ONE extends Query<ResponseArray> {
  override document = gql`
  query ORDER_INVOICE_ONE($where: order_invoices_bool_exp) {
    order_invoices(where: $where, limit: 1) {
      id
      order_id
      date
      value_no_vat
      value_in_vat
      vat
      discount
      url
      invoice_type_id
      valor_date
      invoice_status_id
      refeasual_date
      refasual_value
      refasual_reason_id
      paid_value
      remaining_value
      returned_invoice_id
      note
      invoice_reciever_id
      invoice_reciever_type_id
      position_no
      vat_price
      labour_price
      spare_part_price
      invoice_no
      labour_hour
      labour_discount
        labour_discount_price
      is_rated
      orders {
        user_id
      }
      invoice_parts(order_by: {parts: {part_group: {position_no: desc}, position_no: desc}}) {
        id
        invoice_id
        part_id
        part_name
        part_code
        labour_type_id
        labour_value
        labour_discount
        part_in_vat_price
        part_no_vat_price
        part_unit_price
        invoice_type
        part_unit_type_id
        part_unit
        part_unit_types {
          name
        }
        part_types{
          id
          name
        }
      }
    }
  }
  
  `;
}

@Injectable()
export class ORDER_INVOICE_ID extends Query<ResponseSingle> {
  override document = gql`
    query ORDER_INVOICE_ID($id: bigint!){
      order_invoices_by_pk(id: $id) {
        id
        order_id
        date
        value_no_vat
        value_in_vat
        vat
        discount
        url
        invoice_type_id
        valor_date
        invoice_status_id
        refeasual_date
        refasual_value
        refasual_reason_id
        paid_value
        remaining_value
        returned_invoice_id
        invoice_no
        note
        invoice_reciever_id
        invoice_reciever_type_id
        position_no
        is_rated
        vat_price
        labour_price
        spare_part_price
        labour_hour
        labour_discount
        labour_discount_price
        ettn
        orders{
          vehicle{
            plate
          }
        }
        invoice_parts{
          id
          invoice_id
          part_id
          part_name
          part_code
          labour_type_id
          labour_value
          labour_discount
          part_in_vat_price
          part_no_vat_price
          invoice_type
          part_unit_type_id
          part_unit
          part_unit_types{
            name
          }
        }
       
      }
    }
  `;
}

@Injectable()
export class ORDER_INVOICE_CREATE extends Mutation<any> {
  // connected_part_id - int | nullable
  override document = gql`
    mutation ORDER_INVOICE_CREATE( $object: order_invoices_insert_input! ) {
        insert_order_invoices_one(object: $object) {
            id   
        }
    }
  `;
}



@Injectable()
export class ORDER_INVOICE_UPDATE extends Mutation<any> {
  override document = gql`
    mutation ORDER_INVOICE_UPDATE($id: bigint!, $changes: order_invoices_set_input ) {
      update_order_invoices(where: {id: {_eq: $id}}, _set: $changes) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}


@Injectable()
export class ORDER_INVOICE_DELETE extends Mutation<any> {
  override document = gql`
    mutation ORDER_INVOICE_DELETE($id: bigint!) {
      delete_order_invoices(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class ORDER_INVOICE_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query ORDER_INVOICE_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: order_invoices_bool_exp) {
      order_invoices(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        order_id
        date
        value_no_vat
        value_in_vat
        vat
        discount
        url
        invoice_type_id
        valor_date
        invoice_no
        invoice_status_id
        refeasual_date
        refasual_value
        refasual_reason_id
        paid_value
        remaining_value
        returned_invoice_id
        note
        invoice_reciever_id
        invoice_reciever_type_id
        position_no
        is_rated
        labour_discount
        labour_discount_price
      }
    }
  `;
}

@Injectable()
export class ORDER_INVOICE_COUNT extends Query<any> {
  override document = gql`
    query ORDER_INVOICE_COUNT($where: order_invoices_bool_exp) {
      order_invoices_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}