import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormStepParts, FORM_STEP_PARTS_ALL } from 'src/app/graphql/form_step_parts.graphql';
import { GSM_CREATE } from 'src/app/graphql/gsm.graphql';
import { OrderCards, ORDER_CARDS_ALL, ORDER_CARDS_CREATE, ORDER_CARDS_UPDATE } from 'src/app/graphql/order_cards.graphql';
import { ORDER_CARD_INVOICE_INFO_CREATE } from 'src/app/graphql/order_card_invoice_info.graphql';
import { USER_GET_EMAIL, USER_GET_ID } from 'src/app/graphql/user.graphql';
import { Vehicle } from 'src/app/graphql/vehicle.graphql';
import { FireBaseEmailAuthReponse } from 'src/app/interfaces/firebase.interface';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { PasswordReset } from 'src/app/services/password.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment-v4',
  templateUrl: './appointment-v4.component.html',
  styles: [
  ],
  styleUrls:['./appointment-v4.component.css'],
  providers:[
    FORM_STEP_PARTS_ALL,
    ORDER_CARDS_CREATE,
    ORDER_CARD_INVOICE_INFO_CREATE,
    ORDER_CARDS_UPDATE,
    ORDER_CARDS_ALL,
    USER_GET_EMAIL,
    USER_GET_ID,
    PasswordReset,
    GSM_CREATE,
  ]
})
export class AppointmentV4Component implements OnInit {

  public step: number = 1;
  public leftWidth:number = 0;
  public canGoToPrev = false;
  public isStepCompleted = false;
  public stepList:any = [];
  public stepParts: FormStepParts[] | undefined;
  public orderTypeId: any = 1;
  public stepName:string = "";

  public appointmentFormGroup = new FormGroup({
    serviceTypeId: new FormControl(1, [Validators.required]),
    modelTypeId: new FormControl('',Validators.required),
    maintenanceKmsId: new FormControl('',Validators.required),
    maintenancePackageId: new FormControl('',Validators.required),
    gearId: new FormControl(null,[Validators.required]),
    fuelId: new FormControl(null,[Validators.required]),
    serviceId: new FormControl(null,[Validators.required]),
    serviceDate: new FormControl(null,[Validators.required]),
    paymentType: new FormControl(1,[Validators.required]),
    customer_ip_address: new FormControl(1,[Validators.required]),
    customer_user_agent: new FormControl(1,[Validators.required])
  });
 
  /** ordercard variables **/
  public userUuid: String | null | undefined;
  public orderCard: OrderCards | undefined;
  public orderCardId: bigint | undefined;
  public orderCardInvoiceInfoId: bigint | undefined;

  /** firstStepVariables  */

  public selectedPackageInfo:any;
  public userInfo: any;



  public customerInfo = {
    customer_name: "",
    customer_surname: "",
    customer_email: "",
    tel_number: "",
    city: "",
    district: "",
    address: ""
  }

  public vehicleInfo = {
    chassis_no: "",
    plate: "",
    brand_name: "",
    model_name: "",
    year: "",
    model_type: "",
    fuel_type: "",
    gear_type: "",
    km_name: "",
    customerKm:0
  }

  public firstStepForm = new FormGroup({
    customer_id: new FormControl(null),
    customer_name: new FormControl(null,[Validators.required]),
    customer_surname: new FormControl(null,[Validators.required]),
    customer_email: new FormControl(null,[Validators.required]),
    customer_phone: new FormControl(null,[Validators.required]),
    customer_job: new FormControl(null,[Validators.required]),
    vehicle_brand_id: new FormControl(null,[Validators.required]),
    vehicle_model_id: new FormControl(null,[Validators.required]),
    vehicle_year_id: new FormControl(null,[Validators.required]),
    vehicle_model_type_id: new FormControl(null,[Validators.required]),
    vehicle_gear_id: new FormControl(null,[Validators.required]),
    vehicle_fuel_id: new FormControl(null,[Validators.required]),
    vehicle_km_id: new FormControl(null,[Validators.required]),
    customer_km: new FormControl(null,[Validators.required]),
    vehicle_km_name: new FormControl(null,[Validators.required]),
    order_type: new FormControl(1,[Validators.required]),
    approved: new FormControl(null,[Validators.required]),
    package_id: new FormControl(null,[Validators.required]),
    is_user_registered: new FormControl(false)
  });

  /** firststep variables */
  private isUserChecked = false;

  constructor(
    private alertifyService: AlertifyService,
    private getFormSteps: FORM_STEP_PARTS_ALL,
    public authService: AuthService,
    public orderCardInsert: ORDER_CARDS_CREATE,
    private createOrderCardInvoiceInfo: ORDER_CARD_INVOICE_INFO_CREATE,
    public orderCardUpdate: ORDER_CARDS_UPDATE,
    public checkOrderCard: ORDER_CARDS_ALL,
    private checkUser: USER_GET_EMAIL,
    private getUser: USER_GET_ID,
    private resetService: PasswordReset,
    private createGsm: GSM_CREATE,
    public http: HttpClient

  ) { }

  ngOnInit(): void {
    
    const userAgent = window.navigator.userAgent;
    //this.appointmentFormGroup.patchValue({customer_user_agent:userAgent});
    this.getStepList();

  }

  async getStepList(){
   
    await this.getFormSteps.watch({where:{form_step_id: {_eq:6}, order_type_id: {_eq: this.orderTypeId}}}).valueChanges.subscribe( async ret => {
      if(ret.errors){
        this.alertifyService.error(ret.errors[0].message);
      }

      if(ret.data){
        this.stepParts = ret.data.form_step_parts;
        this.stepList = [];
        await this.stepParts.forEach(element => {
          
            this.stepList.push({step_title: element.step_title, position: element.position, isCompleted: false,service_name: element.service_name,id:element.id});
        
        });


        this.setOrderCard(true);
      }

    });


  }

  async setOrderCard(setStep=false){

    return new Promise(async (resolve,reject)=>{
      this.userUuid = localStorage.getItem('user_uuid');
     
      if(this.userUuid && this.userUuid != null){

        await this.getOrderCard().then(async ()=>{
      
          if(this.orderCard == undefined){
            
            await this.createOrderCard().then(async ()=>{
              resolve(true);
              
            })
          }
        })

      }else{

        if( this.authService.isLogin){
          this.checkOrderCard.watch({where:{user_id:{_eq:this.authService.userId},is_complete:false}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(async ret=>{
            if(ret.errors){
              this.alertifyService.error(ret.errors[0].message);
            }

            if(ret.data){
              if(ret.data.order_cards.length > 0){
                //this.orderCard = ret.data.order_cards[0];
                this.orderCardId = ret.data.order_cards[0].id;
              }else{
          
                await this.createOrderCard().then(async ()=>{
                  resolve(true);
              });
              }
              
            }
          })
        }else{

          await this.createOrderCard().then(async ()=>{
            resolve(true);
          
        })
        
        }
      

      }
    })

    
  }

  async stepChange(direct:String){
    this.isStepCompleted = false;
    let isContinue = false;
    switch(direct){
     
      case "next":
        switch(this.step){

          case 1:
        
            await this.checkEmail(null,this.firstStepForm.value.customer_email).then((resp => {
            }));
          break;

        }
 

        if(this.step > this.stepList.length){
          this.step == (this.stepList.length-1);

         

          break;
        }else{
          this.isStepCompleted = false;
          this.stepList[(this.step-1)].is_completed = true;
  
          this.step  += 1;
          
          if(this.step == 2 ){
          
            this.leftWidth -= 30;
          }
  
          if(this.step > 2 && this.stepList.length != this.step ){
          
            this.leftWidth -= 130;
          }
        }
        
        

      break;

      case "previous":
      
        this.stepList[(this.step-1)].isCompleted = false;
        if(this.step >= 5){
          this.canGoToPrev = false;
        }
        this.step -= 1;
        if(this.step > 1 && (this.stepList.length - 1) != this.step){
      
          this.leftWidth = this.leftWidth+180;
        
        }
        

      break;
    }

    if(this.step > this.stepList.length){
      this.step = this.stepList.length;
    }

    if(this.step < 1){
      this.step = 1;
      this.leftWidth = 0;
    }

  }

  async setStepVariable($event:any){

    let stepName = this.stepList[(this.step-1)].service_name;
    this.stepName = this.stepList[(this.step-1)].service_name;;
    switch(stepName){

      case "app-appointment-arac-hizmet-secimi-v4":

        this.firstStepForm.patchValue($event);


        if(this.firstStepForm.value.customer_id == null){
         // await this.checkEmail(null,this.firstStepForm.value.customer_email);
        }

        if(this.firstStepForm.valid){
          this.isStepCompleted = true;
        }

      break;


    }
  }

  async createOrderCard(){
    return new Promise((resolve,reject)=>{
      this.orderCardInsert.mutate({
        object:{step: 1}
      }).subscribe((ret:any)=>{
        if(ret.errors){
          this.alertifyService.error(ret.errors[0].message);
          resolve(false);
        }

        if(ret.data){
          if(ret.data.insert_order_cards_one.card_uuid){
          


            localStorage.setItem("user_uuid",ret.data.insert_order_cards_one.card_uuid);
            this.userUuid = ret.data.insert_order_cards_one.card_uuid;
            this.orderCardId = ret.data.insert_order_cards_one.id;

              
            this.createOrderCardInvoiceInfo.mutate({object: { order_card_id:this.orderCardId,customer_invoice_type: 1, invoice_type: 3 } }).subscribe(orderCardInvoiceInfoResponse => {
              if(orderCardInvoiceInfoResponse.errors){
                this.alertifyService.error(orderCardInvoiceInfoResponse.errors[0].message);
              }
              if(orderCardInvoiceInfoResponse.data?.order_card_invoice_info_by_pk){
                this.orderCardInvoiceInfoId = orderCardInvoiceInfoResponse.data.returning[0].id;
              }

            })

            // this.setOrderCard();


            //this.setOrderCard();
            resolve(true);
          }
          
        }
      })
    })
  }

  async updateCard(changes:Object){
    if(this.orderCard){
      return new Promise((resolve,reject)=>{
  
        this.orderCardUpdate.mutate({id:this.orderCard?.id,changes:changes}).subscribe((ret:any)=>{
          if(ret.errors){
            this.alertifyService.error(ret.errors[0].message);
            reject();
          }
          if(ret.data){
            resolve(true);
          }
        })
      });
    }
    
  
  }

  async getOrderCard(){

    return new Promise((resolve,reject)=>{
      this.checkOrderCard.watch({where:{card_uuid:{_eq:this.userUuid},is_complete:{_eq:false}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(ret=>{
        if(ret.errors){
          this.alertifyService.error(ret.errors[0].message);
          resolve(false);
        }

        if(ret.data){
          if(ret.data.order_cards.length > 0){
            this.orderCard = ret.data.order_cards[0];
           this.orderCardId = this.orderCard.id;
            localStorage.setItem("user_uuid",this.orderCard.card_uuid);
              
            resolve(true);
          }else{
            resolve(false);
          }
        }
      });
    });
  }
  
  async createNewUser(){
    
    return new Promise((resolve,reject)=>{
         if(this.firstStepForm.value.customer_name.length > 0 && this.firstStepForm.value.customer_surname.length > 0 && this.firstStepForm.value.customer_email.length > 0){
       
           const password = "123123asdas12312";
           // register
           this.authService.firebaseAuth(this.firstStepForm.value.custormer_email, password, false).subscribe(async response => {
             await this.userCreateApi(response);
             resolve(true)

             
           
           }, async err => {
             const response: FireBaseEmailAuthReponse = {} as FireBaseEmailAuthReponse
          
             response.email = this.firstStepForm.value.customer_email
             response.idToken = "order create, email exists in firebase"
             response.refreshToken = "order create, email exists in firebase"
             response.localId = "order create, email exists in firebase"
             await this.userCreateApi(response)
             resolve(true)
       
           

           })

       }else{
         this.alertifyService.error("Lütfen Kullanıcı Formunu Doldurunuz");
     
       }
    })
       
 }

 userCreateApi(response:any){
    
  return new Promise((resolve,reject)=>{
    this.authService.apiRegister(response,this.firstStepForm.value.customer_name, this.firstStepForm.value.customer_surname,'',this.firstStepForm.value.customer_phone,this.firstStepForm.value.customer_job).subscribe(response => {

      if (response.status) {
        this.resetService.sendPasswordResetResult(this.firstStepForm.value.customer_email).subscribe(response => {
          if (response.email) {
          this.checkEmail(null,response.email, true).then(()=>{

            if(this.firstStepForm.value.customer_id && this.firstStepForm.value.customer_id > 1){


              this.createGsm.mutate({
                 name: "gsm",
                 code: this.firstStepForm.value.phone.toString(),
                 user_id: this.firstStepForm.value.customer_id,
                 active: true,
                 is_primary: 1
               }).subscribe((retdata:any)=>{
                 if(retdata.errors){
                   this.alertifyService.error(retdata.errors[0].message);
                 }
               })

           }
          })

          
          }
        }, err => {
          this.alertifyService.error(err)
        });
       
      } else {
        if(response.error && response.error.code == 400){
          alert("Mail adresi zaten kayıtlı");
        }
        this.alertifyService.error("Kayıt Başarısız");
        resolve(true);
      }
    })
  })


}
 
  async setUser(){

    

  }

  checkEmail($event:any,email:string | null=null,createPhone=false){
  
      return new Promise(async (resolve,reject)=>{
       
        

        await this.checkUser.watch({email:this.firstStepForm.value.customer_email},{fetchPolicy:"no-cache"}).valueChanges.subscribe(async ret => {
          if(ret.errors){
            this.alertifyService.error(ret.errors[0].message);
            reject();
          }

          if(ret.data){

            if(ret.data.users.length == 0 ){
              this.firstStepForm.patchValue({is_user_registered:false})
              await this.createNewUser();
           

            }else{
              this.firstStepForm.patchValue({is_user_registered:true})
              this.userInfo = ret.data.users[0];
              if(this.userInfo){
                this.firstStepForm.patchValue({
                  customer_id: this.userInfo.id
                  
                });

              
               
            }
            resolve(true);
            }

           
            
          }
        })
      })

     
    
  }

  

}
