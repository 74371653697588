import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/helpers/custom.validator';
import { AlertifyService } from 'src/app/services/alertify.service';
import { PasswordReset } from 'src/app/services/password.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: [],
  providers: [PasswordReset]
})
export class ForgotComponent implements OnInit {

  @Input() pageType: string = "forgot";

  resetFormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  })
  constructor(
    private resetService: PasswordReset,
    private alertify: AlertifyService
  ) { }

  ngOnInit(): void {

  }

  onSubmit() {
    this.resetService.sendPasswordResetResult(this.resetFormGroup.value.email).subscribe(response => {
      if (response.email) {
        if(this.pageType == "forgot"){
          this.alertify.success("Parola sıfırlama linki mail adresinize gönderilmiştir.");
        }else{
          this.alertify.success("Şifre alma talebiniz mail adresinize gönderilmiştir.");
        }
      }
    }, err => {
      this.alertify.error(err)
    });

  }

}


