import { Injectable } from "@angular/core";
import { Mutation, Query } from "apollo-angular";
import gql from "graphql-tag";

export interface VehicleModelType{
    id: bigint,
    trsbs_id: Number,
    vehicle_model_year_id: bigint,
    name: String,
    active: Boolean,
    vehicle_model_year: any;
    disabled:any;
}

interface ResponseArray {
    vehicle_model_types: VehicleModelType[];
  }
  
interface ResponseSingle {
    vehicle_model_types_by_pk: VehicleModelType
}

@Injectable()
export class VEHICLE_MODEL_TYPE_ALL extends Query<ResponseArray> {
  override document = gql`
  query VEHICLE_MODEL_TYPE_ALL($where: vehicle_model_types_bool_exp){
    vehicle_model_types(where:  $where) {
      id
      trsbs_id
      vehicle_model_year_id
      name
      active
      vehicle_model_year {
        vehicle_model_id
        vehicle_model {
          name
        }
      }
    }
  }`;
}

@Injectable()
export class VEHICLE_MODEL_TYPE_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query VEHICLE_MODEL_TYPE_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc,$where: vehicle_model_types_bool_exp) {
      vehicle_model_types(limit: $limit, offset: $offset, order_by: {id: $orderBy},where:$where) {
        id
        trsbs_id
        vehicle_model_year_id
      name
      active
      vehicle_model_year {
        year{
          id
          name
        }
        vehicle_model_id
        vehicle_model {
          name
        }
      }
      }
    }
  `;
}

@Injectable()
export class VEHICLE_MODEL_TYPE_COUNT extends Query<any> {
  override document = gql`
    query VEHICLE_MODEL_TYPE_COUNT($where: vehicle_model_types_bool_exp) {
        vehicle_model_types_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}

@Injectable()
export class VEHICLE_MODEL_TYPE_DELETE extends Mutation<any> {
  override document = gql`
    mutation VEHICLE_MODEL_TYPE_DELETE($id: bigint!) {
        delete_vehicle_model_types(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class VEHICLE_MODEL_TYPE_CREATE extends Mutation<ResponseSingle> {
  override document = gql`
  mutation VEHICLE_MODEL_TYPE_CREATE($active: Boolean, $name: String, $trsbs_id: Int,$vehicle_model_year_id:bigint) {
    insert_vehicle_model_types(objects: {active: $active, name: $name, trsbs_id: $trsbs_id,vehicle_model_year_id:$vehicle_model_year_id}) {
      returning {
        id
        name
      }
      affected_rows
    }
  }`;
}

@Injectable()
export class VEHICLE_MODEL_TYPE_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
  mutation VEHICLE_MODEL_TYPE_UPDATE($id:bigint, $active: Boolean, $trsbs_id: Int, $vehicle_model_year_id: bigint, $name: String) {
    update_vehicle_model_types(where: {id: {_eq: $id}}, _set: {active: $active, trsbs_id: $trsbs_id, vehicle_model_year_id: $vehicle_model_year_id, name: $name}) {
      returning {
        id
      }
      affected_rows
    }
  }
   
  `;
}

@Injectable()
export class VEHICLE_MODEL_TYPE_ID extends Query<ResponseSingle> {
  override document = gql`
  query VEHICLE_MODEL_TYPE_ID($id: bigint = "") {
    vehicle_model_types_by_pk(id: $id) {
      id
      name
      trsbs_id
      active
      vehicle_model_year_id
      vehicle_model_year {
        id
        vehicle_model_id
        year_id
        year{
          id
          name
        }
        vehicle_model {
          id
          name
          segment_id
          tsrbs_model_id
          vehicle_brand_id
          vehicle_brand {
            id
            name
            active
            tsrbs_brand_id
          }
        }
      }
    }
  }`;
}