import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';
import { BannerPages } from './banner_pages.graphql';

export interface Banners {
  id: Number;
  banner_page_id: Number;
  section: String;
  image: String;
  url: String;
  title:String,
  name: String,
  start_date: Date,
  end_date: Date,
  active: Number,
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  banner_pages:BannerPages
  
}

interface ResponseArray {
  banners: Banners[];
}

interface ResponseSingle {
  banners_by_pk: Banners
}

@Injectable()
export class BANNERS_ALL extends Query<ResponseArray> {

  override document = gql`
    query BANNERS_ALL($where: banners_bool_exp){
        banners(where:$where) {
            id
            banner_page_id
            section
            image
            url
            title
            name
            start_date
            end_date
            created_at
            updated_at
            deleted_at
            banner_pages{
              id
              name
              tag
            }
        }
    }
  `;
}

@Injectable()
export class BANNERS_ID extends Query<ResponseSingle> {
  override document = gql`
    query BANNERS_ID($id: bigint!){
        banners_by_pk(id: $id) {
            id
            banner_page_id
            section
            image
            title
            name
            active
            url
            start_date
            end_date
            created_at
            updated_at
            deleted_at
        }
    }
  `;
}

@Injectable()
export class BANNERS_CREATE extends Mutation<any> {
  override document = gql`
    mutation BANNERS_CREATE(
      $banner_page_id: Int!,
      $section: String!,
      $image: String!,
      $url: String!,
      $start_date:timestamp,
      $end_date:timestamp,
      $active: smallint,
      $title: String!,
      $name: String!
    ) {
      insert_banners(objects: {
        banner_page_id: $banner_page_id,
        section: $section,
        image: $image,
        url: $url,
        start_date:$start_date,
        end_date:$end_date,
        active: $active,
        title: $title,
        name: $name
      }) {
        affected_rows
        returning {
          id
        }
      }
    }`;
}

@Injectable()
export class BANNERS_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
    mutation BANNERS_UPDATE(
        $id:bigint!, 
        $banner_page_id: Int!, 
        $section: String!, 
        $image: String!, 
        $url: String!, 
        $start_date:timestamp,
        $end_date: timestamp, 
        $active: smallint,
        $title: String,
        $name: String) {
      update_banners(
        where: {
            id: {_eq: $id}},
             _set: {
                banner_page_id: $banner_page_id, 
                section: $section,
                image: $image, 
                url: $url, 
                start_date:$start_date, 
                end_date:$end_date, 
                active: $active ,
                title: $title,
                name: $name
            }) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class BANNERS_DELETE extends Mutation<any> {
  override document = gql`
    mutation BANNERS_DELETE($id: bigint!) {
      delete_banners(where: {id: { _eq: $id } } ) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class BANNERS_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query BANNERS_PAGINATE(
      $limit: Int = 20,
      $offset: Int = 0,
      $orderBy: order_by = asc,
      $where: banners_bool_exp
    ) {
      banners(limit: $limit, offset: $offset, order_by:{id: $orderBy}, where: $where) {
        id
        banner_page_id
        section
        image
        title
        name
        url
        start_date
        end_date
        created_at
        updated_at
        deleted_at
        banner_pages{
            id
            name
        }
      }
    }
  `;
}

@Injectable()
export class BANNERS_COUNT extends Query<any> {
  override document = gql`
    query BANNERS_COUNT($where:banners_bool_exp!) {
        banners_aggregate( where:$where) {
        aggregate {
          count
        }
      }
    }
  `;
}