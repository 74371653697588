import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface PartUnitType {
  id: bigint;
  name: String;
  active?: Boolean
}

interface ResponseArray {
  part_unit_types: PartUnitType[];
}

interface ResponseSingle {
  part_unit_types_by_pk: PartUnitType
}

@Injectable()
export class PART_UNIT_TYPE_ID extends Query<ResponseSingle> {
  override document = gql`
    query PART_UNIT_TYPE_ID($id: bigint!) {
      part_unit_types_by_pk(id: $id) {
        active
        id
        name
        }
      }
    }
  `;
}

@Injectable()
export class PART_UNIT_TYPE_ALL extends Query<ResponseArray> {
  override document = gql`
    query PART_UNIT_TYPE_ALL($where: part_unit_types_bool_exp){
      part_unit_types(where: $where) {
        id
        name
        active
      }
    }
  `;
}

@Injectable()
export class PART_UNIT_TYPE_CREATE extends Mutation<ResponseSingle> {
  override document = gql`
    mutation PART_UNIT_TYPE_CREATE($name: String!, $active: Boolean!) {
      insert_part_unit_types(objects: {name: $name, active: $active}) {
        affected_rows
        returning {
          id
          name
        }
      }
    }
  `;
}

@Injectable()
export class PART_UNIT_TYPE_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
    mutation PART_UNIT_TYPE_UPDATE($id: bigint!, $name: String!, $active: Boolean!) {
      update_part_groups(where: {id: {_eq: $id}}, _set: {name: $name, active: $active}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PART_UNIT_TYPE_DELEETE extends Mutation<any> {
  override document = gql`
    mutation PART_UNIT_TYPE_DELEETE($id: bigint!) {
      delete_part_unit_types(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PART_UNIT_TYPE_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query PART_UNIT_TYPE_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: part_unit_type_bool_exp) {
      part_unit_types(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        name
        active
      }
    }
  `;
}

@Injectable()
export class PART_UNIT_TYPE_COUNT extends Query<any> {
  override document = gql`
    query PART_UNIT_TYPE_COUNT($where: part_unit_types_bool_exp) {
      part_unit_types_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
    
  `;
}