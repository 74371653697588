import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface UrlUtmLog {
  id: Number;
  url: String;
  sub_url: String;
  utm_source: String;
  utm_medium: String;
  utm_campaign: String;
  utm_content: String;
  utm_term: String;
  page: String;
  ip_address: Boolean;
  created_at: Date;
  browser_info: String;
  os_info: String;
  user_agent: Text
}

interface ResponseArray {
  url_utm_log: UrlUtmLog[];
}

interface ResponseSingle {
    url_utm_log_by_pk: UrlUtmLog
}

@Injectable()
export class URL_UTM_LOG_ALL extends Query<ResponseArray> {
  // (where: {active: {_eq: true}})
  override document = gql`
    query URL_UTM_LOG_ALL($where: url_utm_log_bool_exp){
        url_utm_log {
            id
            url
            sub_url
            utm_source
            utm_medium
            utm_campaign
            utm_content
            utm_term
            page
            ip_address
            created_at
            browser_info
            os_info
            user_agent
        }
    }
  `;
}

@Injectable()
export class URL_UTM_LOG_ID extends Query<ResponseSingle> {
  override document = gql`
    query URL_UTM_LOG_ID($id: Int!){
        url_utm_log_by_pk(id: $id) {
            id
            url
            sub_url
            utm_source
            utm_medium
            utm_campaign
            utm_content
            utm_term
            page
            ip_address
            created_at
            browser_info
            os_info
            user_agent
        }
    }
  `;
}

@Injectable()
export class URL_UTM_LOG_CREATE extends Mutation<any> {
  override document = gql`
    mutation URL_UTM_LOG_CREATE(
        $url: String!, 
        $sub_url: String,
        $utm_source: String,
        $utm_medium: String,
        $utm_campaign: String,
        $utm_content: String,
        $utm_term: String,
        $page: String,
        $ip_address: String,
        $created_at: timestamp,
        $browser_info: String,
        $os_info: String,
        $user_agent: String,
        ) {
    insert_url_utm_log(objects: {
        url: $url,
        sub_url: $sub_url, 
        utm_source: $utm_source,
        utm_medium: $utm_medium,
        utm_campaign: $utm_campaign, 
        utm_content: $utm_content, 
        utm_term: $utm_term,
        page: $page,
        ip_address: $ip_address,
        created_at: $created_at,
        browser_info: $browser_info
        os_info: $os_info
        user_agent: $user_agent
     }) {
    affected_rows
      returning {
        id
      }
    }
    }
  `;
}


@Injectable()
export class URL_UTM_LOG_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query URL_UTM_LOG_PAGINATE($limit: Int = 20, $offset: Int = 0, $orderBy: order_by = asc, $where: url_utm_log_bool_exp) {
        url_utm_log(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
            id
            url
            sub_url
            utm_source
            utm_medium
            utm_campaign
            utm_content
            utm_term
            page
            ip_address
            created_at
            browser_info
            os_info
            user_agent
        }
    }
  `;
}

@Injectable()
export class URL_UTM_LOG_COUNT extends Query<any> {
  override document = gql`
  query URL_UTM_LOG_COUNT($where: url_utm_log_bool_exp!) {
    url_utm_log_aggregate(where: $where) {  
        aggregate {
            count
        }
    }
}
  `;
  
}