<div class="container">
    <div class="card">
        <div class="card-inner" [@flipState]="isFlipped()">
            <div class="front" >
                <img src="https://cdn.destechhasar.com/garantili-oto-servis/teklif/map.png?test123" class="map-img">
                <div class="rows">
                    <img src="https://cdn.destechhasar.com/garantili-oto-servis/teklif/chip.png" class="card-brand" width="60px">
                    <img style="width: 30%;" *ngIf="cardType == 4" src="https://cdn.destechhasar.com/garantili-oto-servis/teklif/visa.png?test123" class="card-brand" width="60px">
                    <img style="width: 30%;" *ngIf="cardType == 5" src="https://cdn.destechhasar.com/garantili-oto-servis/teklif/master-card.png?test123" class="card-brand" width="60px">
                    
                </div>
                <div class="rows card-no">
                    <span>{{number1}}</span>
                    <span>{{number2}}</span>
                    <span>{{number3}}</span>
                    <span>{{number4}}</span>
                </div>
                <div class="rows card-holder">
                    <p>KART SAHİBİ</p>
                    <p>TARİH</p>
                </div>
                <div class="rows name">
                    <p>{{cardName}}</p>
                    <p>{{cardExpire}}</p>
                </div>
            </div>
            <div class="back">
                <img src="https://cdn.destechhasar.com/garantili-oto-servis/teklif/map.png?test123" class="map-img">
                <div class="bar"></div>
                <div class="rows card-cvv">
                    <div>
                        <img src="https://cdn.destechhasar.com/garantili-oto-servis/teklif/pattern.png?test123">
                    </div>
                    <p>{{ccv2}}</p>
                </div>
                1
                <div class="rows signature">
                    <p>İMZA</p>
                    <img *ngIf="cardType == 4" src="https://cdn.destechhasar.com/garantili-oto-servis/teklif/visa.png?test123" width="80px">
                    <img *ngIf="cardType == 5" src="https://cdn.destechhasar.com/garantili-oto-servis/teklif/master-card.png?test123" width="80px">
                </div>
            </div>
        </div>
    </div>
</div>