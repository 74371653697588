<div class="d-flex  ">
    <div class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3 " style="margin-right: 35px !important;">
        <div class="d-flex justify-content-between w-100">
            <span class="fw-bold fs-6 text-gray-400">Genel Profil Tamamlama</span>
            <span class="fw-bolder fs-6">{{profilePercentage}}%</span>
        </div>
        <div class="h-5px mx-3 w-100 bg-light mb-3">
            <div class="bg-success rounded h-5px" role="progressbar" style="width: {{profilePercentage}}%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </div>
    
    <div class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
        <div class="d-flex justify-content-between w-100 ">
            <span class="fw-bold fs-6 text-gray-400">Zorunlu Profil Tamamlama</span>
            <span class="fw-bolder fs-6">{{requiredProfilePercentage}}%</span>
        </div>
        <div class="h-5px mx-3 w-100 bg-light mb-3">
            <div class="bg-success rounded h-5px" role="progressbar" style="width: {{requiredProfilePercentage}}%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div>
            <ul class="list">
                <li *ngFor="let item of requiredButEmpty">{{item}}</li>
            </ul>
        </div>
    </div>
   
</div>


