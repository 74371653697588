import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-shared-price-input',
  templateUrl: './price-input.component.html',
  styles: [
  ]
})
export class PriceInputComponent implements OnInit {
  @Input() compare:number | undefined;
  @Input() process:string="greater";
  @Input() selectedVal: number | undefined;
  @Input() name:any;
  @Output() returnVal:EventEmitter<number>  = new EventEmitter();;
  public inputval: number | undefined;
  public price:number = 0;
  public idVal:any;
  private isComplite:boolean = true; 

  @HostListener('document:click', ['$event'])
  clickout(event: { target: any; }) {
    if(this.eRef.nativeElement.contains(event.target)) {
      
    } else {
      if(!this.isComplite){
        //alert("Please enter a valid price");
        this.selectedVal = 0;
        this.returnVal.emit(0);
      }
    }
  }


  constructor(private alertifyService:AlertifyService,private eRef: ElementRef) { }

  ngOnInit(): void {
    this.idVal = this.name;
    

  }

  changePrice(event:any){
    this.price = parseFloat(event.target.value);
    
    
    if(this.compare){
      let newprice = ((this.compare * 10) / 100);
    let comparePrice = this.compare - ( (this.compare * 10) / 100);
  //  this.compare = this.compare.toFixed(2);
      switch(this.process){
        case "greater":
          if(this.price < comparePrice){
            this.alertifyService.error("Ücret "+comparePrice+" Tl'den büyük olmalı");
            this.price = 0;
            this.inputval = 0;
            this.returnVal.emit(0);
            this.isComplite = false;
          }else{
            this.isComplite = true;
            this.returnVal.emit(parseFloat(this.price.toFixed(2)));
          }
          break;

        case "less":
          if(this.price > comparePrice){
            this.alertifyService.error("Ücret "+comparePrice+" Tl'den küçük olmalı");
            this.price = 0;
            this.inputval = 0;
            this.isComplite = false;

            this.returnVal.emit(0);
          }else{
           this.isComplite = true;
            this.returnVal.emit(this.price);
          }
        break;
        case "equal":
          if(this.price != comparePrice){
            this.alertifyService.error("Ücret "+comparePrice+" Tl'ye eşit olmalı");
            this.price = 0;
            this.inputval = 0;
            this.isComplite = false;

            this.returnVal.emit(0);
          }else{
            this.isComplite = true;
            this.returnVal.emit(this.price);
          }
        break;

        case "goe": 
        if(this.price <= comparePrice){
          this.alertifyService.error("Ücret "+comparePrice + " Tl'den büyük olmalı");
          this.price = 0;
            this.inputval = 0;
            this.isComplite = false;

            this.returnVal.emit(0);
        }else{
          this.isComplite = true;
          this.returnVal.emit(this.price);
          
        }
        break;

        case "loe": 
        if(this.price >= comparePrice){
          this.alertifyService.error("Ücret "+comparePrice+ " Tl'den küçük olmalı");
          this.price = 0;
            this.inputval = 0;
            this.returnVal.emit(0);
            this.isComplite = false;
        }else{
          this.returnVal.emit(this.price);
          this.isComplite = true;
        }
        break;
      }

   
    }else{
      this.isComplite = true;
      this.returnVal.emit(this.price);
    }

  }

}
