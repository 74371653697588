import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/helpers/custom.validator';

@Component({
  selector: 'app-shared-payment-auth',
  templateUrl: './payment-auth.component.html',
  styles: [
  ]
})
export class PaymentAuthComponent implements OnInit {

  public signUpFormGroup = new FormGroup(
    {
      name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
      surname: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
      email: new FormControl('', [Validators.required, CustomValidator.isValidEmail]),
      mobile_phone:  new FormControl('', [Validators.required, CustomValidator.isValidEmail]),
    }
  )

  constructor() { }

  ngOnInit(): void {
  }

  signUpFormSubmit(){
    
  }

}
