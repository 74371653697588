import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-shared-service-contact-tooltip',
  templateUrl: './service-contact-tooltip.component.html',
  styles: [
  ],
  styleUrls:['./service-contact-tooltip.component.css']
})
export class ServiceContactTooltipComponent implements OnInit {

  @Input() serviceId: BigInt | undefined;
  @Input() serviceName: String | undefined;
  @Input() phone: String | undefined;
  @Input() tooltipid:BigInt | undefined;

  public show:boolean = false;


  @Output() tooltipEmitter:EventEmitter<boolean> = new EventEmitter();
  constructor(private _elementRef: ElementRef) {
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.show = false;
    } else {
      this.show = true;
    }
  }

  ngOnInit(): void {
  }

  setTootipId(id:BigInt | undefined){
    this.tooltipid = id;
   }

}
