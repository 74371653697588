import { Component, Input, OnInit } from '@angular/core';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { PdfService } from 'src/app/services/pdf.service';

@Component({
  selector: 'app-shared-proforma-pdf-generator',
  templateUrl: './proforma-pdf-generator.component.html',
  styles: [
  ]
})
export class ProformaPdfGeneratorComponent implements OnInit {

  @Input() userInfo: any;
  @Input() serviceLastDate: any;
  @Input() todayDate: any;
  @Input() selectedPackageInfo: any;
  @Input() selectedUncompaignProducts: any;
  @Input() subTotal: any;
  @Input() vat: any;
  @Input() discount: any;
  @Input() totalAmount: any;

  public docDefinition:TDocumentDefinitions | undefined; 
  public content:any;
  private customerInfo:any;


  private proformaInfo:any;

  constructor(
    private pdfService: PdfService,
  ) { }

  ngOnInit(): void {

    this.createProforma();
    

  }

 


  async createProforma(){
    this.content = {}

    await this.createContentLogo();
    await this.setCompanyInfo();
    await this.setCustomerInfo();
    await this.setDateAndPackageName();
   // await this.setEttn();
    await this.setProducts();
   // await this.setTotalTable();
  //  await this.setInvoiceEnd()
    await this.setInvoice();
  }



  async setCompanyInfo(){
    this.content.content.push({
      columns: [
        [ ],
        [
          {
            image: 'invoice',
            width: 100,
            alignment: 'center'
          },
          {
            text: "Garantili Satış Sonrası Oto Hiz.A.Ş.\n Cevizli Mahallesi Tugay Yolu Caddesi No:59\n  Maltepe / İstanbul",
            bold: true,
            alignment: 'center',
            fontSize: 10,
            margin: [0, 10, 0, 0]
          }
        ],
        []
        
      ]
    });
  }
  

  async createContentLogo(){

  

    this.content = {
      content:[],
      images: {
        invoice: 'https://cdn.destechhasar.com/garantili-oto-servis/invoice/invoice_logo.png',
        invoice_logo: 'https://cdn.destechhasar.com/garantili-oto-servis/invoice/invoice_logo.png'
      },
      
      defaultStyle: {
        fontSize: 9
      },
      styles: {
        titleStyle: {
          fontSize: 8
        },
        tableStyle: {
          fontSize: 8,
          alignment: 'center',
          margin: [0, 10, 0, 10],
          widths: ['30%', '20%', '17.5%', '17.5%', '17.5%']
        }
      }
    }
  }

  async setCustomerInfo(){
    let customerInfo = [
    "Sayın " + this.userInfo?.name + " " + this.userInfo?.surname + "\n",
    "(" + this.userInfo?.email + ")\n",
    "\n",
    "Randevu detayını görüntülemektesiniz. Bizi tercih ettiğiniz için teşekkür ederiz.\n",
    "(" + this.serviceLastDate + " tarihine kadar geçerlidir)\n"
    ];
  ;
  
  this.content.content.push({ text: customerInfo, fontSize: 10, margin: [0, 50, 0, 0] });
  this.content.content.push([' ']);
  
   
   }

   async setDateAndPackageName(){
     this.content.content.push({ text: "--------------------------------------------------------------", alignment:"center", fontSize: 10, margin: [0, 10, 0, 10] })
     this.content.content.push([],{ text: "09-12-2002", fontSize: 10, margin: [450, 0, 0, 0] },[])
     this.content.content.push({ text: "periyodik bakım", fontSize: 12, margin: [0, 10, 0, 10]});
   }

  async setInvoiceEnd(){
  
    let totalContinue = {
      table: {
        widths: ['*', 'auto'],
        body: [
          ["Sigorta Şirketi", "EUREKO SİGORTA"],
          ["Dosya No ", "22211772"],
          ["Dosya Sorumlusu", ""],
          ["Plaka", " 34BAF679"],
          ["Masraf Yeri", "RS İSTANBUL - MALTEPE"],
          ["Eksper", ""],
          ["KM Bilgisi ", ""],
          ["Onay No", ""],
          ["Ödeme Koşulu ", "#45 GÜN"],
          ["Genel Açıklamalar", "YALNIZ #ÜÇBİNYETMİŞİKİ TÜRK LİRASI KIRKBİR KURUŞ#"],
          [{
            colSpan: 2, text: "Banka Bilgileri"
          }],
          ["Banka Adı", "GARANTİ BANKAS"],
          ["Şube Adı/Kodu", "YENİBOSNA TİCARİ / 432"],
          ["Hesap No", "6290715"],
          ["IBAN", "TR67 0006 2000 4320 0006 2907 15"],
        ]
      },
      style: 'tableStyle',
      margin: [0, 150, 0, 0],
      alignment: 'left',
      layout: {
        hLineWidth: function (i:any, node:any) {
          return (i === 0 || i === node.table.body.length) ? 1 : 0;
        },
        vLineWidth: function (i:any, node:any) {
          return (i === 0 || i === node.table.widths?.length) ? 1 : 0;
        }
      }
    };
  
    this.content.content.push([' ']);
  
    this.content.content.push(totalContinue);
  }

  async setInvoice(){

    this.docDefinition = this.content;
  }

  async setProducts(){
    let table = {
      headerRows: 1,
      body: [
        ["Yedek Parça Adı", "Parça Türü", "Adet",  "Birim Fiyat", "Tutar"]
      ]
    }
    if(this.selectedUncompaignProducts && this.selectedUncompaignProducts.length > 0){
      this.selectedUncompaignProducts.forEach((v:any,k:number)=>{
        table.body.push([v.selected_equivalent_name, v.selected_equivalent_part_type_name, v.part_unit + " " + v.part_unit_name, v.part_value, v.total_price + " TL" ]);
   
      })
    }
 
    table.body.push(["", "", "", "Toplam", this.subTotal + " TL" ]);
    table.body.push(["", "", "", "Kdv (18%)", this.vat + " TL" ]);
    table.body.push(["", "", "", "İndirim", this.discount + " TL" ]);
    table.body.push(["", "", "", "Genel Toplam", this.totalAmount + " TL" ]);
   
    this.content.content.push({table:table, style: 'titleStyle'});
  
    this.content.content.push([' ']);
  }
  
  

  invoiceChange(event:any)
  {
    switch (event.target.value) {
      case "preview":
        if(this.docDefinition != undefined){
        this.pdfService.preview(this.docDefinition)
      }
        //this.pdfService.generator("preview", this.invoiceBody.nativeElement)
        break;
      case "download":
        if(this.docDefinition != undefined){
        this.pdfService.download(this.docDefinition)
        }
        break;
      case "print":
        if(this.docDefinition != undefined){
        this.pdfService.print(this.docDefinition)
        }
        break;
      default:
        break;
    }
  }
}
