<form class="form fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" [formGroup]="formGroup"
    (ngSubmit)="formSubmit()">
    
        <div class="row mb-5">
            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                <input type="text" class="form-control form-control-lg  mb-3 mb-lg-0" placeholder="Başlık" formControlName="name" />
            </div>
            <div class="col-xl-6 fv-row fv-plugins-icon-container">
                <input type="text" class="form-control form-control-lg  mb-3 mb-lg-0" placeholder="Gsm" formControlName="code" />
            </div>
        </div>
        
        <div class="row mb-5">
            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                <div class="form-check form-switch form-check-custom form-check-solid" style="text-align: right;">
                    <input type="checkbox" class="form-check-input" name="active" formControlName="active">
                    <label class="form-check-label fw-bold text-gray-400 ms-3">Birincil</label>
                </div>
            </div>
            <div class="col-lg-6 fv-row fv-plugins-icon-container" style="clear: right;">
                <button type="submit" class="btn btn-primary" style="float: right;" >
                    Gsm Ekle
                 </button>
            </div>
        </div>
</form>