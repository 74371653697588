import { Directive, ElementRef, Renderer2, HostListener, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appResize]'
})
export class ResizeDirective implements AfterViewInit {
  private aElement!: HTMLElement; // Non-null assertion

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    // a tagini seç
    this.aElement = this.el.nativeElement.querySelector('a');
    this.checkSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkSize();
  }

  private checkSize() {
    const width = this.el.nativeElement.offsetWidth;
    const height = this.el.nativeElement.offsetHeight;

    if (this.aElement) { // aElement kontrolü
      if (width > 300) {
        this.renderer.removeClass(this.aElement, 'vertical');
        this.renderer.addClass(this.aElement, 'horizontal');

        // hizmet-box öğesine sınıf ekleme/çıkarma
        this.renderer.removeClass(this.el.nativeElement, 'three-or-more');
        this.renderer.addClass(this.el.nativeElement, 'two-or-less');
      } else {
        this.renderer.removeClass(this.aElement, 'horizontal');
        this.renderer.addClass(this.aElement, 'vertical');

        this.renderer.removeClass(this.el.nativeElement, 'two-or-less');
        this.renderer.addClass(this.el.nativeElement, 'three-or-more');
        
      }
    }
  }
}
