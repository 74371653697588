import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { map } from 'rxjs';
import { FormStepParts, FORM_STEP_PARTS_ALL } from 'src/app/graphql/form_step_parts.graphql';
import { GARAGE_CREATE } from 'src/app/graphql/garages.graphql';
import { Order, ORDER_CREATE, ORDER_ID, ORDER_UPDATE } from 'src/app/graphql/order.graphql';
import { OrderCards, ORDER_CARDS_ALL, ORDER_CARDS_CREATE, ORDER_CARDS_UPDATE } from 'src/app/graphql/order_cards.graphql';
import { ORDER_CARD_INVOICE_INFO_CREATE, ORDER_CARD_INVOICE_INFO_UPDATE_ID } from 'src/app/graphql/order_card_invoice_info.graphql';
import { ORDER_CARD_PARTS_CREATE, ORDER_CARD_PARTS_DELETE } from 'src/app/graphql/order_card_parts.graphql';
import { ORDER_INVOICE_CREATE } from 'src/app/graphql/order_invoices.graphql';
import { ORDER_INVOICE_INFO_CREATE } from 'src/app/graphql/order_invoice_info.graphql';
import { ORDER_INVOICE_PART_CREATE } from 'src/app/graphql/order_invoice_parts.graphql';
import { ORDER_PART_CREATE } from 'src/app/graphql/order_part.graphql';
import { OrderType, ORDER_TYPE_ALL } from 'src/app/graphql/order_type.graphql';
import { OzanpayRequest, OZANPAY_REQUEST_ORDER_ID_UPDATE } from 'src/app/graphql/ozanpay_request.graphql';
import { OzanpaySuccessfuls, OZANPAY_SUCCESFYLS_UPDATE_ORDER_ID, OZANPAY_SUCCESSFULS_ALL } from 'src/app/graphql/ozanpay_successful.graphql';
import { Package } from 'src/app/graphql/package.graphql';
import { SUCCESSFUL_PAYMENTES_CREATE } from 'src/app/graphql/successful_payments.graphql';
import { URL_UTM_LOG_CREATE } from 'src/app/graphql/url_utm_log.graphql';
import { User, USER_GET_ID } from 'src/app/graphql/user.graphql';
import { VEHICLE_CREATE_APPOINTMENT_FORM } from 'src/app/graphql/vehicle.graphql';
import { CustomValidator } from 'src/app/helpers/custom.validator';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { MailService } from 'src/app/services/mail.service';
import { SmsService } from 'src/app/services/sms.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment-v3',
  templateUrl: './appointment-v3.component.html',
  styles: [
  ],
  styleUrls:['./appointment-v3.component.css'],
  providers:[
    FORM_STEP_PARTS_ALL,
    ORDER_CARDS_ALL,
    ORDER_CARDS_CREATE,
    ORDER_CARD_INVOICE_INFO_CREATE,
    ORDER_CARDS_UPDATE,
    ORDER_CARD_PARTS_DELETE,
    ORDER_CARD_PARTS_CREATE,
    ORDER_CARD_INVOICE_INFO_UPDATE_ID,
    ORDER_CREATE,
    ORDER_PART_CREATE,
    ORDER_INVOICE_PART_CREATE,
    ORDER_INVOICE_CREATE,
    ORDER_INVOICE_INFO_CREATE,
    ORDER_UPDATE,
    ORDER_ID,
    GARAGE_CREATE,
    VEHICLE_CREATE_APPOINTMENT_FORM,
    USER_GET_ID,
    OZANPAY_SUCCESFYLS_UPDATE_ORDER_ID,
    ORDER_TYPE_ALL,
    OZANPAY_REQUEST_ORDER_ID_UPDATE,
    SUCCESSFUL_PAYMENTES_CREATE,
    URL_UTM_LOG_CREATE
    ]
})
export class AppointmentV3Component implements OnInit {

  @Output() stepEmitter = new EventEmitter<number>();

  public step: number = 1;
  public lastStep: number = 1;
  public leftWidth:number = 0;
  public canGoToPrev = false;
  public isStepCompleted = false;
  public stepList:any = [];
  public stepListProcessed:any = [];
  public stepParts: FormStepParts[] | undefined;
  public orderTypeId: number = 1;
  public stepName:string = "";
  public nextButtonClicked = false;
 
  /** ordercard variables **/
  public userUuid: String | null | undefined;
  public orderCard: OrderCards | undefined;
  public orderCardId: bigint | undefined;
  public orderCardInvoiceInfoId: bigint | undefined;
  public orderCardParts:any = [];

  private baseUrl: string = environment.laravelApiEndpoint;

  /* vehicle varibles **/
  public vehicleModelType: Number | undefined;
  public gearId: Number | undefined;
  public fuelId: Number | undefined;
  public kmId: Number | undefined;
  public packageList: Package[] | undefined;
  public packageKmList: any;
  public userDbInfo: User | undefined;
  public sessionUserInfo: User | undefined;

  public vehicleForm= new FormGroup({
    id: new FormControl(0),
    name: new FormControl('', [Validators.required]),
    brand_id: new FormControl(0, [Validators.required, CustomValidator.isGratherThanZero]),
    model_id: new FormControl(0, [Validators.required, CustomValidator.isGratherThanZero]),
    model_year_id: new FormControl(0, [Validators.required, CustomValidator.isGratherThanZero]),
    type_id: new FormControl(0, [Validators.required, CustomValidator.isGratherThanZero]),
    gear_type_id: new FormControl(0, [Validators.required, CustomValidator.isGratherThanZero]),
    fuel_type_id: new FormControl(0, [Validators.required, CustomValidator.isGratherThanZero]),
    last_km: new FormControl(0, [Validators.required, CustomValidator.isGratherThanZero]),
    km_id: new FormControl(0, [Validators.required, CustomValidator.isGratherThanZero]),
    chasses_no: new FormControl(null, [Validators.minLength(17), Validators.maxLength(17)]),
    is_package_have: new FormControl(false, [Validators.required]),
    beygir: new FormControl(null, []),
    kwh: new FormControl(null, []),
    year_id: new FormControl(null, []),
    note: new FormControl(null, []),
    year: new FormControl(null,[])
  });

  

  /** user  variables */
  public userForm = new FormGroup({
    firstname: new FormControl('',[Validators.required]),
    lastname: new FormControl('',[Validators.required]),
    gsm_id: new FormControl(null),
    gsm: new FormControl(null,[Validators.required]),
    email: new FormControl('',[Validators.required]),
    phone: new FormControl(''),
    approved: new FormControl('',[Validators.required]),
    id: new FormControl(0,[Validators.required]),
    is_user_registered: new FormControl(null,[Validators.required]),
    job: new FormControl(null)
  })

  public userInfo: any;
  public removeApproved:boolean = false;

  /** package variables */
  public selectedPieces: any;
  public amountInfo: any;
  public priceInfo:any;
  public selectedPaymentType: Number | undefined;
  public selectedPackageTypeId: Number | undefined;
  public selectedPackageInput: any;
  public slugOrderType: OrderType[] | undefined;

  /** service variables */
  public serviceInfoInput = {
    service_id: 0,
    service_city_id: 0,
    service_district_id: 0,
    service_tab:null
  }  

  /** payment variables */
  public invoiceInfoObj: any;
  public proformaCanShow = false;
  public orderDetail: Order | undefined;

  public orderId:bigint | undefined;
  public vehicleId: bigint | undefined
  public isPayed:boolean = false;
  public randevuButtonDisabled: boolean = false;
  public labourHour: any;
  public labourPrice: any;
  public gsmCreated: boolean = false;

  public ozanpaySuccessFuls: OzanpaySuccessfuls[] | undefined;

  invoiceAddressForm = new FormGroup({
    customer_name: new FormControl('',[Validators.required]),
    customer_surname: new FormControl('',[Validators.required]),
    country_id: new FormControl(1,[Validators.required]),
    city_id: new FormControl(null,[Validators.required]),
    district_id: new FormControl(null,[Validators.required]),
    town_id: new FormControl(null,[Validators.required]),
    address: new FormControl('',[Validators.required]),
    identity_no: new FormControl(null),
    company_name: new FormControl(null),
    tax_administration: new FormControl(null),
    tax_number: new FormControl(null),
    invoice_type: new FormControl(3,Validators.required),
    customer_invoice_type: new FormControl(1,Validators.required),
    gsm_number: new FormControl(null, [Validators.required]),
    tel_number: new FormControl(null),
    user_id: new FormControl(null, [Validators.required]),
    customer_email: new FormControl(null, [Validators.required]),

  })
  
  public customerInfo = {
    customer_name: "",
    customer_surname: "",
    customer_email: "",
    tel_number: "",
    city: "",
    district: "",
    address: ""
  }

  public vehicleInfo = {
    chassis_no: "",
    plate: "",
    brand_name: "",
    model_name: "",
    year: "",
    model_type: "",
    fuel_type: "",
    gear_type: "",
    km_name: "",
    customerKm:0
  }

  /** package variables */
  public selecteMaintenanceKmsId: Number | undefined;
  public selecteMaintenanceKmsName: string = "Km Seçiniz";
  public selectedPackageInfo:any;
  public ipAddress = "127.0.0.1";


  public formSuccess = {
    order_created: false,
    invoice_created: false,
    invoice_parts_created: false,
    order_parts_created: false
  }

  public appointmentFormGroup = new FormGroup({
    serviceTypeId: new FormControl(1, [Validators.required]),
    modelTypeId: new FormControl('',Validators.required),
    maintenanceKmsId: new FormControl('',Validators.required),
    maintenancePackageId: new FormControl('',Validators.required),
    gearId: new FormControl(null,[Validators.required]),
    fuelId: new FormControl(null,[Validators.required]),
    serviceId: new FormControl(null,[Validators.required]),
    serviceDate: new FormControl(null,[Validators.required]),
    paymentType: new FormControl(1,[Validators.required]),
    customer_ip_address: new FormControl(1,[Validators.required]),
    customer_user_agent: new FormControl(1,[Validators.required]),
    model_id:  new FormControl(1,[Validators.required]),
    package_type_id: new FormControl(2)
  });


  public stepBack = false;
  public showModal:boolean = false;
  public isPhoneVerified:boolean = false;
  public isMessageSend:boolean = true;
  public firstStepContinue:boolean = true;
  public firstStepCount = 0;
  public dailyLimit = 10;
  public showMessageCount = 0;
  public messageCanSend:boolean = false;
  public ozanpayRequestOrderIdUpdateResponse: OzanpayRequest | undefined; 
  public orderEditUrl = "";
  public slug: String | undefined;

  public browserInfo: string = '';
  public osInfo: string = '';
  public urlUtmId = 0;

  constructor(
    private alertifyService: AlertifyService,
    private getFormSteps: FORM_STEP_PARTS_ALL,
    public checkOrderCard: ORDER_CARDS_ALL,
    public authService: AuthService,
    public orderCardInsert: ORDER_CARDS_CREATE,
    private createOrderCardInvoiceInfo: ORDER_CARD_INVOICE_INFO_CREATE,
    public orderCardUpdate: ORDER_CARDS_UPDATE,
    public deleteOrderCardParts: ORDER_CARD_PARTS_DELETE,
    private orderCardInvoiceInfoUpdate: ORDER_CARD_INVOICE_INFO_UPDATE_ID,
    private createOrder: ORDER_CREATE,
    private createOrderpart: ORDER_PART_CREATE,
    private createOrderInvoicePart: ORDER_INVOICE_PART_CREATE,
    private createOrderInvoice: ORDER_INVOICE_CREATE,
    private createInvoiceInfo: ORDER_INVOICE_INFO_CREATE,
    private smsService: SmsService,
    private mailService: MailService,
    private updateOrder: ORDER_UPDATE,
    private getOrder: ORDER_ID,
    private createGarage: GARAGE_CREATE,
    private createVehicle: VEHICLE_CREATE_APPOINTMENT_FORM,
    private http: HttpClient,
    private getUser: USER_GET_ID,
    private orderCardPartsCreate: ORDER_CARD_PARTS_CREATE,
    private updateOzanpayOrderId: OZANPAY_SUCCESFYLS_UPDATE_ORDER_ID,
    private getOrderTypeList: ORDER_TYPE_ALL,
    private ozanpayRequestOrderIdUpdate: OZANPAY_REQUEST_ORDER_ID_UPDATE,
    public router: Router,
    private createSuccessfulPayment: SUCCESSFUL_PAYMENTES_CREATE,
    private activatedRoute: ActivatedRoute,
    private createUrlUtmLog: URL_UTM_LOG_CREATE
  ) { 

    this.activatedRoute.params.subscribe(params => {

     
      this.slug = params['slug'];
    });

    
    
    
    
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnterKeyPress(event: KeyboardEvent) {
    event.preventDefault();
  }


  async ngOnInit(): Promise<void> {
    
    const userAgent = window.navigator.userAgent;
    this.appointmentFormGroup.patchValue({customer_user_agent:userAgent});
    this.getStepList();
    await this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip.toString();
      this.appointmentFormGroup.patchValue({customer_ip_address: this.ipAddress});
      
    });

    this.getUser.watch({id:this.authService.userId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp=>{
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.sessionUserInfo = resp.data.users_by_pk;
      }
    })

    

    if(this.authService.userRole == 'service'){
      this.orderEditUrl = "/service/orders/edit";
    }

    if(this.authService.userRole == 'admin'){
      this.orderEditUrl = "/admin/orders/edit";
    }

    const isMobile = this.isMobileDevice();
  
  if(this.slug && this.step == 1){

    this.getOrderTypeList.watch({where:{url:{_eq:this.slug}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.slugOrderType = resp.data.order_types;
        if(this.slugOrderType.length > 0){

          let elm = {
            orderTypeId: this.slugOrderType[0].id
          }
          this.setOrderTypeId(elm,this.slugOrderType[0].url);
   
        }
      }
    })
  }

    const queryParams = this.activatedRoute.snapshot.queryParams;

    
    if(queryParams['utm_campaign'] && queryParams['utm_campaign'].length > 0){
      this.insertUrlUtmLog(queryParams);
    }

  }

 
  insertUrlUtmLog(queryParams:any){
    if(queryParams){
      const userAgent = window.navigator.userAgent;
      this.getBrowserInfo(userAgent);
      this.getOSInfo(userAgent);

      const fullPath = this.activatedRoute.snapshot.url.map(segment => segment.path).join('/');
   
      let queryObj = {
        url: window.location.href, 
        sub_url: fullPath,
        utm_source: queryParams['utm_source'],
        utm_medium: queryParams['utm_medium'],
        utm_campaign: queryParams['utm_campaign'],
        utm_content: queryParams['utm_content'],
        utm_term: queryParams['utm_term'],
        page: 'teklif',
        ip_address: this.authService.ipAddress,
        created_at: moment().format('yy-MM-DD HH:mm:ss'),
        browser_info: this.browserInfo,
        os_info: this.osInfo,
        user_agent: userAgent
      }
     
      this.createUrlUtmLog.mutate(queryObj).subscribe(resp => {
        if(resp.errors){
          this.alertifyService.error(resp.errors[0].message);
        }

        if(resp.data){
          this.urlUtmId = resp.data.insert_url_utm_log.returning[0].id;
          
        }
      })
    }
  }

  getBrowserInfo(userAgent): void {
    
    
    // Basit bir tarayıcı tespiti (daha detaylı tespitler için regex kullanılabilir)
    if (userAgent.indexOf('Firefox') > -1) {
      this.browserInfo = 'Mozilla Firefox';
    } else if (userAgent.indexOf('Chrome') > -1) {
      this.browserInfo = 'Google Chrome';
    } else if (userAgent.indexOf('Safari') > -1) {
      this.browserInfo = 'Safari';
    } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident/') > -1) {
      this.browserInfo = 'Internet Explorer';
    } else {
      this.browserInfo = 'Unknown Browser';
    }

  }

  getOSInfo(userAgent): void {

    // Basit bir işletim sistemi tespiti
    if (userAgent.indexOf('Win') > -1) {
      this.osInfo = 'Windows';
    } else if (userAgent.indexOf('Mac') > -1) {
      this.osInfo = 'MacOS';
    } else if (userAgent.indexOf('Linux') > -1) {
      this.osInfo = 'Linux';
    } else if (userAgent.indexOf('Android') > -1) {
      this.osInfo = 'Android';
    } else if (userAgent.indexOf('like Mac') > -1) {
      this.osInfo = 'iOS';
    } else {
      this.osInfo = 'Unknown OS';
    }

  }

  async getStepList(){
    await this.getFormSteps.watch({where:{form_step_id: {_eq:5}, order_type_id: {_eq: this.orderTypeId}}}).valueChanges.subscribe( async ret => {
      if(ret.errors){
        this.alertifyService.error(ret.errors[0].message);
      }

      if(ret.data){
        this.stepParts = ret.data.form_step_parts;
        this.stepList = [];
        await this.stepParts.forEach(element => {
          
            this.stepList.push({step_title: element.step_title, position: element.position, isCompleted: false,service_name: element.service_name,id:element.id});
        
        });
        

        this.setOrderCard(true);
        this.processStepList();
      }

    });


  }

  async setOrderTypeId($event,$slug=""){

   

    if($event.orderTypeId && parseInt($event.orderTypeId) > 0){
      this.orderTypeId = $event.orderTypeId;
      this.slug = $event.slug;
      await this.getStepList();
      this.stepChange('next');
   
    }
  }

  stepChange(direct:String){
   
    this.lastStep = this.step;
    this. nextButtonClicked = false;
    this.isStepCompleted = false;
    switch(direct){
      case "next":
       
      
        if(this.step == 1){
         this.firstStepCount = this.firstStepCount + 1;

        }


        if(this.step == 2 && !this.isPhoneVerified && this.messageCanSend){
          this.nextButtonClicked = true;
        
          if(!this.isPhoneVerified){
            this.alertifyService.warning("Lütfen önce sms doğrulama adımını geçiniz");
            this.isStepCompleted = true;
            return false;
          }
        }
       

        this.stepBack = false;
        if(this.step > this.stepList.length){
          this.step == (this.stepList.length-1);
          break;
        }else{
          //this.isStepCompleted = false;
          this.stepList[(this.step-1)].is_completed = true;
          this.step  += 1;
        
          if(this.step == 3 ){
          
            this.leftWidth -= 30;
          }
  
          if(this.step > 3 && this.stepList.length != this.step ){
          
            this.leftWidth -= 130;
          }
        }
        
       // this.isStepCompleted = false;
      break;

      case "previous":
        this.stepBack = true;
        this.stepList[(this.step-1)].isCompleted = false;
        if(this.step >= 5){
          this.canGoToPrev = false;
        }
        this.step -= 1;
        if(this.step > 1 && (this.stepList.length - 1) != this.step){
      
          this.leftWidth = this.leftWidth+180;
        
        }

        if(this.step == 2){
          this.isPhoneVerified = false;

        }
      

      break;
    }
    if(this.step > this.stepList.length){
      this.step = this.stepList.length;
    }

    if(this.step < 1){
      this.step = 1;
      this.leftWidth = 0;
    }
   
    this.stepEmitter.emit(this.step);

  }

 
  async setOrderCard(setStep=false){

    return new Promise(async (resolve,reject)=>{
      this.userUuid = localStorage.getItem('user_uuid');
     
      if(this.userUuid && this.userUuid != null){

        await this.getOrderCard().then(async ()=>{
      
          if(this.orderCard == undefined){
            
            await this.createOrderCard().then(async ()=>{
              resolve(true);
              
            })
          }
        })

      }else{

        if( this.authService.isLogin){
          this.checkOrderCard.watch({where:{user_id:{_eq:this.authService.userId},is_complete:false}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(async ret=>{
            if(ret.errors){
              this.alertifyService.error(ret.errors[0].message);
            }

            if(ret.data){
              if(ret.data.order_cards.length > 0){
                //this.orderCard = ret.data.order_cards[0];
                this.orderCardId = ret.data.order_cards[0].id;
              }else{
          
                await this.createOrderCard().then(async ()=>{
                  resolve(true);
              });
              }
              
            }
          })
        }else{

          await this.createOrderCard().then(async ()=>{
            resolve(true);
          
        })
        
        }
      

      }
    })

    
  }

  async updateCard(changes:Object){
    if(this.orderCard){
      return new Promise((resolve,reject)=>{

        this.orderCardUpdate.mutate({id:this.orderCard?.id,changes:changes}).subscribe((ret:any)=>{
          if(ret.errors){
            this.alertifyService.error(ret.errors[0].message);
            reject();
          }
          if(ret.data){
            resolve(true);
          }
        })
      });
    }
    
  
  }

  async getOrderCard(){

    return new Promise((resolve,reject)=>{
      this.checkOrderCard.watch({where:{card_uuid:{_eq:this.userUuid},is_complete:{_eq:false}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(ret=>{
        if(ret.errors){
          this.alertifyService.error(ret.errors[0].message);
          resolve(false);
        }

        if(ret.data){
          if(ret.data.order_cards.length > 0){
            this.orderCard = ret.data.order_cards[0];
           this.orderCardId = this.orderCard.id;
            localStorage.setItem("user_uuid",this.orderCard.card_uuid);
              
            resolve(true);
          }else{
            resolve(false);
          }
        }
      });
    });
  }

  async createOrderCard(){
    return new Promise((resolve,reject)=>{
      this.orderCardInsert.mutate({
        object:{step: 1}
      }).subscribe((ret:any)=>{
        if(ret.errors){
          this.alertifyService.error(ret.errors[0].message);
          resolve(false);
        }

        if(ret.data){
          if(ret.data.insert_order_cards_one.card_uuid){
          


            localStorage.setItem("user_uuid",ret.data.insert_order_cards_one.card_uuid);
            this.userUuid = ret.data.insert_order_cards_one.card_uuid;
            this.orderCardId = ret.data.insert_order_cards_one.id;

              
            this.createOrderCardInvoiceInfo.mutate({object: { order_card_id:this.orderCardId,customer_invoice_type: 1, invoice_type: 3 } }).subscribe(orderCardInvoiceInfoResponse => {
              if(orderCardInvoiceInfoResponse.errors){
                this.alertifyService.error(orderCardInvoiceInfoResponse.errors[0].message);
              }
              if(orderCardInvoiceInfoResponse.data?.order_card_invoice_info_by_pk){
                this.orderCardInvoiceInfoId = orderCardInvoiceInfoResponse.data.returning[0].id;
              }

            })

            // this.setOrderCard();


            //this.setOrderCard();
            this.getOrderCard();
            resolve(true);
          }
          
        }
      })
    })
  }

  async deleteCardParts(){
    if(this.orderCard){
      return new Promise((resolve,reject)=>{
  
        this.deleteOrderCardParts.mutate({where:{order_card_id:{_eq:this.orderCard?.id}}}).subscribe((ret:any)=>{
          if(ret.errors){
            this.alertifyService.error(ret.errors[0].message);
            reject();
          }
          if(ret.data){
            resolve(true);
          }
      
        })
      });
    }
  }

  async setStepVariable($event:any){
  

    let stepName = this.stepListProcessed[(this.step-1)].service_name;
    this.stepName = this.stepListProcessed[(this.step-1)].service_name;;
    this.isStepCompleted = false;
    switch(stepName){

      case "app-appointment-v3-arac-hizmet-secimi":
        this.isStepCompleted = $event;
        if(!this.isPhoneVerified){
          this.showModal = true;
        }
     
          if(this.isStepCompleted && this.isMessageSend ){
            this.isMessageSend = false;
            this.isStepCompleted = false;
          
            let options = {
              headers: new HttpHeaders({
                'token': this.authService.parseToken(false)
              })
            }
  
             // this.checkOrderCounter = false;
                
             
                     this.isStepCompleted = true;
                     
                     this.messageCanSend = true;
                                       
                     if(this.isStepCompleted ){
               
                 
                       if( !this.gsmCreated && this.userDbInfo?.gsms.length > 0){
                         await this.userDbInfo?.gsms.forEach(element => {
                           if(element.code == this.userForm.value.gsm){
                             this.gsmCreated = true;
                           }
                         });
                       }
             
                       if(!this.gsmCreated){
                        
                       }else{
                        
                       }
             
                       if(!this.isPhoneVerified){
                         this.showModal = true;
                       }
             
                     }
             
   
                     if(this.userForm.valid && this.vehicleForm.valid && this.isPhoneVerified){
                 
                       await this.checkVehicle();
                       this.deleteCardParts();
                     }
                   
               
               
            
            
             
         
          }
        

       
     
        

      break;

      case "app-appointment-v2-hizmet-secimi-v2":
        if(parseInt($event) > 0){
          let oldTypeId = this.orderTypeId
          this.orderTypeId = $event;
          if(oldTypeId != $event){
            this.getStepList();
          }
        
          this.isStepCompleted = true;
          
          this.appointmentFormGroup.patchValue({
            serviceTypeId: this.orderTypeId
          });

          this.updateCard({
            type_id: this.orderTypeId,
            step: this.stepList[this.step].id,
          });

          this.deleteCardParts();
        }
        
      break;


      case "app-appointment-v3-paket-secimi":

        this.isStepCompleted = false;

        this.selectedPackageInput = $event;

        this.selectedPackageInfo = $event.packageInfo ;


        this.selectedPackageInfo.amount = $event.amount;
        this.selectedPieces = $event.packageParts;
        this.selectedPackageInfo.packageParts = this.selectedPieces;

        this.amountInfo = $event.amount;

        this.priceInfo = $event.amount;
        this.selectedPackageTypeId = $event.selectedPackageTypeId;
       
       this.selectedPaymentType = $event.paymentType;
        this.appointmentFormGroup.patchValue({
          maintenancePackageId: this.selectedPackageInfo.id,
          paymentType: this.selectedPaymentType 
        });
       
   
        this.updateCard({
          maintenance_package_id: this.selectedPackageInfo.id,
          step: this.stepList[this.step].id,
          maintenance_package_type_id: $event.selectedPackageTypeId,
          payment_type: this.selectedPaymentType ,
          order_value: this.selectedPackageInfo.amount.generalTotal,
          order_no_vat_value: this.selectedPackageInfo.amount.total,
          package_total_value: this.selectedPackageInfo.amount.generalTotal,
        });


        await this.deleteCardParts();
          let name = "";
          let part_type_name = "";
       await this.selectedPackageInput.selectedPackageParts.forEach(async element => {
          switch(element.part_type_id){
            case 1:
              name = element.original_name;
              part_type_name: "original";
            break;

            case 2:
              name = element.premium_name;
              part_type_name: "premium";
            break;

            case 3:
              name = element.equivalent_name;
              part_type_name: "equivalent";
            break;
          }

          await this.orderCardPartsCreate.mutate({
            object:{
              order_card_id: this.orderCardId,
              part_id: element.part_id,
              labour_type_id: 3,
              labour_value: element.labour_price,
              labour_discount: 0,
              part_type_id: element.part_type_id,
              part_discount: 0,
              selected_part_id: element.selected_part_id,
              brand_labour_price: element.brand_labour_price,
              gos_labour_price: element.gos_labour_price,
              price_unit_id: 1,
              price_without_currency: element.totalPartInVatPrice,
              price_vat_without_currency: element.totalPartNoVatPrice,
              part_unit_type_id: element.part_unit_type,
              part_unit: element.part_unit,
              part_code: element.part_code,
              part_no_vat_price: element.totalPartNoVatPrice,
              part_name: name,
              package_detail_id: element.package_detail_id,
              selected_type: part_type_name,
              part_in_vat_price: element.totalPartInVatPrice
            }
            
          }).subscribe(resp => {
            if(resp.errors){
              this.alertifyService.error(resp.errors[0].message);
            }

          })
        });

        //this.stepChange('next');

        this.isStepCompleted = true;
       
      break;

      

      case "app-appointment-v3-servis-secimi":
        this.isStepCompleted = false;
        if($event.service_id){
          this.serviceInfoInput = $event;
          this.updateCard({service_id:$event.service_id,service_date:null});
          this.appointmentFormGroup.patchValue({
            serviceId: $event.service_id
          });
          this.isStepCompleted = true;
          this.stepChange('next');
        }

        this.isStepCompleted = true;
        
      break;

      case "app-appointment-v3-randevu-olustur":
        this.isStepCompleted = false;
 
        if($event){
          this.updateCard({service_date: $event});
          this.appointmentFormGroup.patchValue({
            serviceDate: $event
          });
          this.isStepCompleted = true;
          this.stepChange('next');
          
        }

       // this.isStepCompleted = true;
        
      break;

      case "app-appointment-v3-odeme":
        if($event.orderCreateable){

          this.createOrders(undefined);


      
        

        }

      break;

      case "app-appiontment-v3-odemesi-randevu":
        this.createOrders(undefined);
      break;
  
    }

  
  }

  userFormEmitter($event){
    this.updateCard({
      customer_firstname: $event.firstname,
      customer_lastname: $event.lastname,
      customer_email: $event.email,
      customer_gsm: $event.gsm,
      customer_phone: $event.phone,
      user_id: $event.id,
      customer_id: $event.id
    });

    this.userForm.patchValue({
      firstname: $event.firstname,
      lastname: $event.lastname,
      approved: $event.approved,
      email: $event.email,
      gsm: $event.gsm,
      gsm_id: $event.gsm_id,
      phone: $event.phone,
      id: $event.id,
      is_user_registered: $event.is_user_registered,
      job: $event.job
    })
    this.customerInfo.customer_name = $event.firstname;
    this.customerInfo.customer_surname = $event.lastname;
    this.customerInfo.customer_email = $event.email;
    this.customerInfo.tel_number = $event.gsm;
 
  }

  vehicleFormEmitter($event){
   // if(this.stepBack){
    //  this.stepBack = false;
   // }else{
      this.appointmentFormGroup.patchValue({
        modelTypeId: $event.vehicleForm.type_id,
        maintenanceKmsId: $event.vehicleForm.km_id,
        gearId: $event.vehicleForm.gear_type_id,
        fuelId: $event.vehicleForm.fuel_type_id,
        model_id: $event.vehicleForm.model_id
      })

      this.vehicleForm.patchValue($event.vehicleForm)
      this.updateCard({
        vehicle_model_type_id: $event.vehicleForm.type_id,
        step: this.stepList[this.step].id,
        vehicle_fuel_id: $event.vehicleForm.fuel_type_id,
        vehicle_gear_id: $event.vehicleForm.gear_type_id,
        maintenance_kms_id: $event.vehicleForm.km_id,
        customer_km: $event.vehicle_info.customerKm,
        url_utm_log_id: this.urlUtmId
      }); 
  
      this.vehicleInfo = $event.vehicle_info;
   // }

   
   this.processStepList();
  
  }

  selectedPackageEmitter($event:any){
    this.selectedPackageInfo = $event;

    
  }
  
  setInvoiceAddressForm($event:any){
    this.invoiceAddressForm.patchValue($event);

    this.orderCardInvoiceInfoUpdate.mutate({id:this.orderCardId,changes:$event}).subscribe(response=>{
      if(response.errors){
        this.alertifyService.error(response.errors[0].message);
      }

      if(response.data){
      
      }
    });
  }

  async checkVehicle(){

    
    if(this.userForm.value.id && 
      this.userForm.value.id > 0 && 
      this.vehicleForm.value.type_id && 
      this.vehicleForm.value.type_id > 0){


      await this.http.post<any>(this.baseUrl + "appointment/check-vehicle",
        {
          user_id: this.userForm.value.id,
          brand_id: this.vehicleForm.value.brand_id,
          model_id:this.vehicleForm.value.model_id,
          model_year_id: this.vehicleForm.value.model_year_id,
          model_type_id: this.vehicleForm.value.type_id,
          gear_type_id: this.vehicleForm.value.gear_type_id,
          fuel_type_id: this.vehicleForm.value.fuel_type_id,
          customer_km: this.vehicleForm.value.last_km
      
      
      },
      { headers: { token: `${localStorage.getItem('access_token')}` } }
      ).subscribe(response=>{
        if(response.status === false){
          this.alertifyService.error("Araç alınamadı!");
        }else{

          if(response.status && response.result ){
            this.vehicleId = response.result.id;
            this.vehicleForm.patchValue({
              id:this.vehicleId,
              garage_id: response.result.garage_id
            });
            this.updateCard({vehicle_id: this.vehicleId});
          }

        }
      });
/*
      if(this.vehicleForm.value.garage_id == undefined  || this.vehicleForm.value.garage_id == 0){

        await this.createGarage.mutate({name:"Garajım",user_id: this.userInfo.id,active:true}).subscribe(async (garageRet:any)=>{

          if(garageRet.errors){
            this.alertifyService.error(garageRet.errors[0].message);
          }

          if(garageRet.data){

            this.vehicleForm.patchValue({garage_id:garageRet.data.insert_garages.returning[0].id}) ;

            if(this.vehicleForm.valid){

              await this.createVehicle.mutate({
                brand_id: this.vehicleForm.value.brand_id,
                model_id:this.vehicleForm.value.model_id,
                model_year_id: this.vehicleForm.value.model_year_id,
                model_type_id: this.vehicleForm.value.type_id,
                garage_id: this.vehicleForm.value.garage_id,
                gear_type_id: this.vehicleForm.value.gear_type_id,
                fuel_type_id: this.vehicleForm.value.fuel_type_id
              }).subscribe((vehicleRet:any)=>{
                
                if(vehicleRet.errors){
                  this.alertifyService.error(vehicleRet.errors[0].message);
                }
  
                if(vehicleRet.data){
                  this.vehicleId = vehicleRet.data.insert_vehicles.returning[0].id;
                  this.vehicleForm.patchValue({id:this.vehicleId});
                  this.updateCard({vehicle_id: this.vehicleId});
                }
  
              })
            }

          }else{
            this.alertifyService.error("lütfen araç bilgilerini doldurunuz")
          }

        })

      }else{

        
        await this.userInfo.garages.forEach(async (garageItem:any) => {
          
         await garageItem.vehicles.forEach((vehicleItems:any) => {
            if(vehicleItems.model_type_id == this.vehicleForm.value.type_id){
              this.vehicleId = vehicleItems.id
              this.updateCard({vehicle_id: this.vehicleId});
      
            }
          });

          
          
        });

        if(this.vehicleId == undefined){
          await this.createVehicle.mutate({
            brand_id: this.vehicleForm.value.brand_id,
            model_id:this.vehicleForm.value.model_id,
            model_year_id: this.vehicleForm.value.model_year_id,
            model_type_id: this.vehicleForm.value.type_id,
            garage_id: this.userInfo.garages[0].id,
            gear_type_id: this.vehicleForm.value.gear_type_id,
            fuel_type_id: this.vehicleForm.value.fuel_type_id
          }).subscribe((vehicleRet:any)=>{
         
            if(vehicleRet.errors){
              this.alertifyService.error(vehicleRet.errors[0].message);
            }

            if(vehicleRet.data){
              this.vehicleId = vehicleRet.data.insert_vehicles.returning[0].id;
              this.updateCard({vehicle_id: this.vehicleId});

             
            }

          })
        }

      }
*/
      
    }else{
     
    }
  }

  async createOrders(paymentForm:any | undefined){



    if(this.orderTypeId == 1){
      this.appointmentFormGroup.controls["maintenanceKmsId"].setValidators([Validators.required]);
      this.appointmentFormGroup.controls["maintenanceKmsId"].updateValueAndValidity()
    }else{
      this.appointmentFormGroup.controls["maintenanceKmsId"].setValidators([]);
      this.appointmentFormGroup.controls["maintenanceKmsId"].updateValueAndValidity()
    }
      if(this.appointmentFormGroup.invalid){
        this.alertifyService.error("Hatalı veya eksik form datası");
        return false;
      }

      

      let infoObj:any = {
        order_id:this.orderId,
        user_id: this.userForm.value.id,
        customer_name: (this.invoiceAddressForm.value.customer_invoice_type == 2)?this.invoiceAddressForm.value.company_name:this.invoiceAddressForm.value.customer_name,
        customer_surname: this.invoiceAddressForm.value.customer_surname,
        customer_email: this.invoiceAddressForm.value.customer_email,
        identity_no: this.invoiceAddressForm.value.identity_no,
        tax_number: this.invoiceAddressForm.value.tax_number,
        tax_administration: this.invoiceAddressForm.value.tax_administration,
        gsm_number: this.invoiceAddressForm.value.gsm_number,
        tel_number: this.invoiceAddressForm.value.tel_number,
      //     is_same_with_customer: this.invoiceInfo.is_same_with_customer,
        invoice_type: 3,
        address: this.invoiceAddressForm.value.address,
        city_id: this.invoiceAddressForm.value.city_id,
        town_id: this.invoiceAddressForm.value.town_id,
        district_id: this.invoiceAddressForm.value.district_id,
        country_id: this.invoiceAddressForm.value.country_id,
        company_name: this.invoiceAddressForm.value.company_name,
        customer_invoice_type: this.invoiceAddressForm.value.customer_invoice_type,
       // customer_gms: this.userForm.value.gsm,
       // cutomer_gsm_id: this.userForm.value.gsm_id
      }

   
      if(paymentForm && paymentForm.isPayed){
        this.isPayed = paymentForm.isPayed;
        
      }
  
      if(this.invoiceAddressForm.value.isCompany){
                  
        infoObj.tax_number = this.invoiceAddressForm.value.tax_number;
        infoObj.tax_administration = this.invoiceAddressForm.value.tax_administration;
        infoObj.company_name = this.invoiceAddressForm.value.company_name;
        if(this.userForm.value.company_id != undefined){
          infoObj.company_id = this.userForm.value.company_id;
  
        }
        infoObj.tel_number = this.invoiceAddressForm.value.tel_number;
      }else{
        infoObj.gsm_number = this.invoiceAddressForm.value.phone;
        infoObj.identity_no =this.invoiceAddressForm.value.identity_no;
      }
     
      
      if(this.orderId && this.orderId > 0){
        
        if( this.isPayed ){
          this.stepChange('next');
        }

      }else{

        let orderObj = {
          status_id: 1,
          type_id: this.orderTypeId,
          customer_id: this.userForm.value.id,
          order_value: this.priceInfo.generalTotal.toFixed(2),
          order_no_vat_value: this.priceInfo.total.toFixed(2),
          service_value: 0,
          service_id: this.appointmentFormGroup.value.serviceId,
          service_date: this.appointmentFormGroup.value.serviceDate,
          maintenance_package_id: (this.orderTypeId == 1) ? this.selectedPackageInfo.id : 0,
          customer_status_id: 14, //müşteri onayı verildi,
          payment_type: this.selectedPaymentType,
          is_payed: this.isPayed,
          vehicle_id: this.vehicleForm.value.id,
          //customer_address_id: $event.userInfo.address_id,
          //customer_gsm_id: $event.userInfo.gsm_id,
          invoice_firstname: this.invoiceAddressForm.value.firstname,
          invoice_lastname: this.invoiceAddressForm.value.firstname,
          invoice_identity_number: this.invoiceAddressForm.value.identity_no,
          selected_package_type_id: this.selectedPackageTypeId,
          payment_value: this.priceInfo.generalTotal.toFixed(2),
          labour_hour: this.selectedPackageInfo.amount.labourHour,
          labour_price: this.selectedPackageInfo.amount.labourPrice,
          customer_ip_address: this.appointmentFormGroup.value.customer_ip_address,
          customer_user_agent: this.appointmentFormGroup.value.customer_user_agent,
          customer_gsm_id: this.userForm.value.gsm_id,
          installment_count: paymentForm?.installment_count,
          installment_amount: paymentForm?.installment_amount,
          note: this.vehicleForm.value.note,
          labour_discount_percentage: this.priceInfo.labourDiscountPercentage,
          labour_discount_price: this.priceInfo.labourDiscount,
          part_discount_percentage: this.priceInfo.partDiscountPercentage,
          part_discount_price: this.priceInfo.partDiscount,
          cargo_cost: this.selectedPackageInfo.cargo_cost,
          url_utm_log_id: this.urlUtmId
        }

  
        await this.createOrder.mutate(orderObj).subscribe(async (result:any)=>{
          if(result.errors){
            this.alertifyService.error("Kayıt esnasında hata oluştu");
          }
  
          if(result.data){
            let insertedId = result.data.insert_orders.returning[0].id;
            this.orderId = insertedId;
            let i = 1;
            let invoiceCreatable = true;
            infoObj.order_id = this.orderId;

            if(paymentForm){
              this.updateOzanpayOrderId.mutate({id:paymentForm.ozanpay_success_id,order_id:insertedId}).subscribe(resp => {
                if(resp.errors){
                  this.alertifyService.error(resp.errors[0].message);
                }
              })

              this.ozanpayRequestOrderIdUpdate.mutate({id:paymentForm.ozanpayRequestId,order_id:insertedId}).subscribe(respo => {
                if(respo.errors){
                  this.alertifyService.error(respo.errors[0].message);
                }
              });
              
            }
          
            let sendsms =  this.sendSmsService('Siparişiniz Oluşturuldu',this.orderId+" numaralı randevunuz oluşturulmuştur.", [this.userForm.value.gsm]);
  

            this.getOrder.watch({id:this.orderId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(orderSubResp => {

              if(orderSubResp.errors){
                this.alertifyService.error(orderSubResp.errors[0].message);
              }

              if(orderSubResp.data){

                let ordDetail = orderSubResp.data.orders_by_pk;

                this.createSuccessfulPayment.mutate({
                  order_id: this.orderDetail?.id,
                  payment_type_id: this.orderDetail?.payment_type,
                  payment_value: this.orderDetail?.order_value,
                  created_by: this.orderDetail?.customer_id,
                  created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                  request_id:  paymentForm.ozanpayRequestId
                }).subscribe(succResp => {
                  if(succResp.errors){
                    this.alertifyService.error(succResp.errors[0].message);
                  }
                  if(succResp.data){
      
                  }
                });
             

                if(ordDetail?.payment_type == 15){
                  this.sendSmsService('Garantili ödeme bilgisi','Ödemenizi https://gos.garantiliotoservis.com/siparis-odeme/'+ordDetail.track_id + ' adresinden yapabilirsiniz. ',['0'+this.userForm.value.gsm]);
                 
                }

              }

            })

            this.formSuccess.order_created = true;
  
            let message = insertedId+" numaralı randevu " + this.userForm.value.firstname + " " + this.userForm.value.lastname + " adına oluşturulmuştur.";
  
            this.mailService.send(environment.garantiliEmail,'Yeni Randevu Oluşturuldu.',message).subscribe(mailResponse => {
  
              //if (this.order.customer.user_phones.length) {
              //}
  
              if(mailResponse.success){
                 this.alertifyService.success("Mail Gönderildi");
              }
  
            });
  
           
  
              this.invoiceInfoObj = infoObj;
  
              await this.createInvoiceInfo.mutate({object:infoObj}).subscribe((infoResult:any) => {
                    
                if(infoResult.errors){
                  
                  this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
                }
                if(infoResult.data){
                  let insertedId = infoResult.data.insert_order_invoice_info_one.id;
              /*   this.updateOrder.mutate({id:this.orderId,changes:{ invoice_info_id: insertedId }}).subscribe((e:any)=>{
                    if(e.errors){
                      this.alertifyService.error(e.errors[0].message);
                    }
                  });*/
                }
  
              });
  
  
           await this.selectedPieces.filter( async (obj:any) => {
  
              let orderPartObj = {
                
                order_id:insertedId,
                part_id:obj.part_id,
                part_type_id:obj.part_type_id,
                part_value:obj.part_value,
                selected_part_id:obj.selected_part_id,
                service_value:0,
                part_unit_type_id:obj.part_unit_type,
                part_unit:obj.part_unit,
                brand_labour_price: obj.brand_labour_price,
                gos_labour_price: obj.gos_labour_price,
                price_unit_id:obj.price_unit_id,
                total_price_no_vat: obj.newNoVatPrice,
                total_price: obj.total_price,
                created_at: moment().format('yyyy-MM-DDTH:mm:ssZ'),
                part_code: obj.part_code,
                part_no_vat_price: obj.newNoVatPrice,
                part_labour_hour: obj.brand_labour_hour,
                part_labour_price: obj.part_labour_price,
                part_brand_labour_price: obj.labour_price,
                labour_value: obj.part_labour_price,
                labour_discount: 0,//TODO:: buraya dşscount eklenecek
                labour_type_id: 3
                
              };
  
              if(obj.part_unit_type == 3){
                this.labourHour = obj.part_unit
                this.labourPrice = obj.part_no_vat_price
              }
  
       
  
             if(obj.part_unit_type == 3){
              orderPartObj.total_price = obj.part_in_vat_price;
              orderPartObj.part_value = obj.part_in_vat_price;
              orderPartObj.part_no_vat_price = obj.part_in_vat_price;
              orderPartObj.part_labour_hour = obj.totalLabourHour;
              orderPartObj.total_price_no_vat =  obj.part_no_vat_price;
              orderPartObj.part_brand_labour_price= obj.part_no_vat_price;
              orderPartObj.part_unit = obj.totalLabourHour; //TODO:: burası hatalı
             }
  
  
  
              await this.createOrderpart.mutate(orderPartObj).subscribe((partTypeResult:any) => {
                if(partTypeResult.errors){
                  this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
                }
                if(partTypeResult.data){
                  //this.step = this.steps.siparisi_tamamla.id;
                  i++;
                 /* if(i>=this.selectedPieces.length && invoiceCreatable && $event.userInfo.isPayed){
                    
                    invoiceCreatable = false;
                  }*/
  
              //    this.step =step;
                }
              });
  
              
              this.updateCard({
                step:5,
                customer_id: this.userForm.value.id,
                order_value:this.priceInfo.generalTotal.toFixed(2),
                order_no_vat_value: this.priceInfo.total.toFixed(2),
                main_order_id: this.orderId,
                is_complete: true
              });
  
  
            });
  
            //await this.createInvoice( this.isPayed );
            await this.createInvoice( true );
  
          
            this.alertifyService.success("siparişiniz oluşturuldu");
           
            
  
            
          }
  
        });

      }
      

      

  }
/*
  async createOrders_old($event:any){


    let infoObj:any = {
      order_id:this.orderId,
      user_id: $event.userInfo.id,
      customer_name: $event.invoiceInfo.firstname,
      customer_surname: $event.invoiceInfo.lastname,
      customer_email: $event.userInfo.email,
      identity_no: null,
      tax_number: null,
      tax_administration: null,
      gsm_number: null,
      tel_number:null,
    //     is_same_with_customer: this.invoiceInfo.is_same_with_customer,
      invoice_type: 3,
      address: $event.invoiceInfo.address,
      city_id: $event.invoiceInfo.city_id,
      town_id: $event.invoiceInfo.town_id,
      district_id: $event.invoiceInfo.district_id,
      country_id: $event.invoiceInfo.country_id,
      company_name: null,
      customer_invoice_type: 1
    }

    if($event.invoiceInfo.isCompany){
                
      infoObj.tax_number = $event.invoiceInfo.tax_number;
      infoObj.tax_administration = $event.invoiceInfo.tax_administration;
      infoObj.company_name = $event.invoiceInfo.company_name;
      if(this.userForm.value.company_id != undefined){
        infoObj.company_id = this.userForm.value.company_id;

      }
      infoObj.tel_number = $event.invoiceInfo.tel_number;
    }else{
      infoObj.gsm_number = $event.invoiceInfo.phone;
      infoObj.identity_no = $event.invoiceInfo.identity_no;
    }

    switch(parseInt($event.paymentType)){
      case 1: //kredi kartı
      if(this.orderTypeId && this.orderTypeId > 0 &&  this.selectedPieces.length > 0){
        let Obj = {
          status_id: 1,
          type_id: this.orderTypeId,
          customer_id: $event.userInfo.id,
          order_value: this.priceInfo.generalTotal.toFixed(2),
          order_no_vat_value: this.priceInfo.total.toFixed(2),
          service_value: 0,
          service_id: this.appointmentFormGroup.value.serviceId,
          maintenance_package_id: (this.orderTypeId == 1) ? this.selectedPackageInfo.id : 0,
          customer_status_id: 14, //müşteri onayı verildi,
          payment_type: $event.paymentType,
          is_payed: $event.userInfo.isPayed,
          vehicle_id: this.vehicleId,
          customer_address_id: $event.userInfo.address_id,
          customer_gsm_id: $event.userInfo.gsm_id,
          invoice_firstname: $event.invoiceInfo.firstname,
          invoice_lastname: $event.invoiceInfo.lastname,
          invoice_identity_number: $event.invoiceInfo.identity_no,
          selected_package_type_id: this.selectedPackageTypeId,
          payment_value: this.priceInfo.generalTotal.toFixed(2)
        }



        this.createOrder.mutate(Obj).subscribe(async (result:any)=>{
          if(result.errors){
            this.alertifyService.error("Kayıt esnasında hata oluştu");
          }

          if(result.data){
            let insertedId = result.data.insert_orders.returning[0].id;
            this.orderId = insertedId;
            let i = 1;
            let invoiceCreatable = true;
            infoObj.order_id = this.orderId;
            
            let sendsms =  this.sendSmsService('Siparişiniz Oluşturuldu',this.orderId+" numaralı randevunuz oluşturulmuştur.", [$event.userInfo.phone]);

            let message = insertedId+" numaralı randevu " + this.userForm.value.firstname + " " + this.userForm.value.lastname + " adına oluşturulmuştur.";

            this.mailService.send(environment.garantiliEmail,'Yeni Randevu Oluşturuldu.',message).subscribe(mailResponse => {

              //if (this.order.customer.user_phones.length) {
              //}

              if(mailResponse.success){
                // this.alertifyService.success("Mail Gönderildi");
              }

            });

            if($event.userInfo.isPayed){

              this.invoiceInfoObj = infoObj;

              this.createInvoiceInfo.mutate({object:infoObj}).subscribe((infoResult:any) => {
                    
                if(infoResult.errors){
                  
                  this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
                }
                if(infoResult.data){
                  let insertedId = infoResult.data.insert_order_invoice_info_one.id;
              //   this.updateOrder.mutate({id:this.orderId,changes:{ invoice_info_id: insertedId }}).subscribe((e:any)=>{
               //     if(e.errors){
                //      this.alertifyService.error(e.errors[0].message);
                 //   }
                  //});
                //}

              });



            }



           await this.selectedPieces.filter( async (obj:any) => {

              let orderPartObj = {
                
                order_id:insertedId,
                part_id:obj.part_id,
                part_type_id:obj.part_type_id,
                part_value:obj.part_value,
                selected_part_id:obj.selected_part_id,
                service_value:0,
                part_unit_type_id:obj.part_unit_type,
                part_unit:obj.part_unit,
                brand_labour_price: obj.brand_labour_price,
                gos_labour_price: obj.gos_labour_price,
                price_unit_id:obj.price_unit_id,
                total_price_no_vat: obj.newNoVatPrice,
                total_price: obj.total_price,
                created_at: moment().format('yyyy-MM-DDTH:mm:ssZ'),
                part_code: obj.part_code,
                part_no_vat_price: obj.newNoVatPrice,
                part_labour_hour: obj.brand_labour_hour,
                part_labour_price: obj.part_labour_price,
                part_brand_labour_price: obj.labour_price,
                labour_value: obj.part_labour_price,
                labour_discount: 0,//TODO:: buraya dşscount eklenecek
                labour_type_id: 3
                
              };

       

             if(obj.part_unit_type == 3){
              orderPartObj.total_price = obj.part_in_vat_price;
              orderPartObj.part_value = obj.part_in_vat_price;
              orderPartObj.part_no_vat_price = obj.part_in_vat_price;
              orderPartObj.part_labour_hour = obj.totalLabourHour;
              orderPartObj.total_price_no_vat =  obj.part_no_vat_price;
              orderPartObj.part_brand_labour_price= obj.part_no_vat_price;
              orderPartObj.part_unit = obj.totalLabourHour;
             }



              await this.createOrderpart.mutate(orderPartObj).subscribe((partTypeResult:any) => {
                if(partTypeResult.errors){
                  this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
                }
                if(partTypeResult.data){
                  //this.step = this.steps.siparisi_tamamla.id;
                  i++;
                  if(i>=this.selectedPieces.length && invoiceCreatable && $event.userInfo.isPayed){
                    
                    invoiceCreatable = false;
                  }

              //    this.step =step;
                }
              });

              

             

              this.updateCard({
                step:5,
                customer_id: this.userForm.value.id,
                order_value:this.priceInfo.generalTotal.toFixed(2),
                order_no_vat_value: this.priceInfo.total.toFixed(2),
                main_order_id: this.orderId,
                is_complete: true
              });


            });

            await this.createInvoice();

          
            this.alertifyService.success("siparişiniz oluşturuldu");
            this.stepChange('next');

            
          }

        });


      }
      break;

      case 2:
        if(this.orderTypeId && this.orderTypeId > 0 &&  this.selectedPieces.length > 0){
      
          let Obj = {
            status_id: 1,
            type_id: this.orderTypeId,
            customer_id: $event.userInfo.id,
            order_value: this.priceInfo.generalTotal.toFixed(2),
            order_no_vat_value: this.priceInfo.total.toFixed(2),
            service_value: 0,
            service_id: this.appointmentFormGroup.value.serviceId,
            maintenance_package_id: (this.orderTypeId == 1) ? this.selectedPackageInfo.id : 0,
            customer_status_id: 14, //müşteri onayı verildi,
            payment_type: $event.paymentType,
            is_payed: false,
            vehicle_id: this.vehicleId,
            customer_address_id: $event.userInfo.address_id,
            customer_gsm_id: $event.userInfo.gsm_id,
            invoice_firstname: $event.invoiceInfo.firstname,
            invoice_lastname: $event.invoiceInfo.lastname,
            invoice_identity_number: $event.invoiceInfo.identity_no
          }


          await this.createOrder.mutate(Obj).subscribe(async (result:any)=>{
            if(result.errors){
              this.alertifyService.error("Kayıt esnasında hata oluştu");
            }

            if(result.data){
              let insertedId = result.data.insert_orders.returning[0].id;
              this.orderId = insertedId;

              infoObj.order_id = this.orderId;

              let sendsms =  this.sendSmsService('Siparişiniz Oluşturuldu',this.orderId+" numaralı randevunuz oluşturulmuştur.", [$event.userInfo.phone]);
              let i = 1;
              await this.selectedPieces.filter((obj:any) => {

                this.createOrderpart.mutate(
                  {
                  
                    order_id:insertedId,
                    part_id:obj.part_id,
                    part_type_id:obj.part_type_id,
                    part_value:obj.part_value,
                    selected_part_id:obj.selected_part_id,
                    service_value:0,
                    part_unit_type_id:obj.part_unit_type,
                    part_unit:obj.part_unit,
                    brand_labour_price: obj.brand_labour_price,
                    gos_labour_price: obj.gos_labour_price,
                    price_unit_id:obj.price_unit_id,
                    currency_unit_price: obj.currency_unit_price,
                    currency_total_price: obj.currency_total_price,
                    currency: obj.currency,
                    total_price_no_vat: obj.total_price_no_vat,
                    total_price: obj.total_price,
                    created_at: moment().format('yyyy-MM-DDTH:mm:ssZ'),
                    part_code: obj.part_code,
                    part_no_vat_price: obj.price_no_vat
                    
                  }).subscribe((partTypeResult:any) => {
                  if(partTypeResult.errors){
                    this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
                  }
                  if(partTypeResult.data){
                     i++;
                    if(i == this.selectedPieces.length){
                      this.formSuccess.order_parts_created = true;
                    }
                  

                    //this.step = this.steps.siparisi_tamamla.id;
                //    this.step =step;
                  }
                });

                this.updateCard({
                  step:5,
                  customer_id: this.userForm.value.id,
                  order_value:this.priceInfo.generalTotal.toFixed(2),
                  order_no_vat_value: this.priceInfo.total.toFixed(2),
                  main_order_id: this.orderId,
                  is_complete: true
                });


                this.formSuccess.invoice_parts_created = true;
              });
            
              

              this.invoiceInfoObj = infoObj;
             await this.createInvoiceInfo.mutate({object:infoObj}).subscribe((infoResult:any) => {
                    
                if(infoResult.errors){
                  
                  this.alertifyService.error("Kayıt esnasında bir sorun oluştu!");
                }
                if(infoResult.data){
                  let insertedId = infoResult.data.insert_order_invoice_info_one.id;
               //: insertedId }}).subscribe((e:any)=>{
                //    if(e.errors){
                //      this.alertifyService.error(e.errors[0].message);
                 //   }
                 // });
                }

              });

              
  
                this.alertifyService.success("siparişiniz oluşturuldu");
      
          
               
             

              
            }

          });


        }


      break;
    }

  }*/

  sendSmsService(title: String, content: String, phones: any[]) {
    this.smsService.send(title, content, phones).subscribe(response => {

      if (response.status) {
        // this.alertifyService.success("Sms Gönderildi")
        // Gönderilen smsler için kayıt işlemi olacaksa burada yapılacak
      }
    })
  }

  async createInvoice(changeStep:boolean=true){
    this.randevuButtonDisabled = true;
    await this.getOrder.watch({id:this.orderId},{fetchPolicy:"no-cache"}).valueChanges.subscribe(async (result:any) => {

      if(result.errors){
        this.alertifyService.error(result.errors[0].message);
        return;
      }

      if(result.data){
        this.orderDetail = result.data.orders_by_pk;

        
        
        if(this.orderDetail != undefined){

          //let invoiceObject = 

         await this.createOrderInvoice.mutate({
            object:{
            "order_id":this.orderDetail.id,
            "date":moment().format("YYYY-MM-DDTHH:mm:ssZ"),
            "value_in_vat":this.orderDetail.order_value,
            "value_no_vat":this.orderDetail.order_no_vat_value,
            "vat": 20,
            "vat_price": ( this.orderDetail.order_value - this.orderDetail.order_no_vat_value ),
            "discount":0,
            "url":"",
            "invoice_type_id":this.orderDetail.type_id,
            "valor_date":moment().format("YYYY-MM-DDTHH:mm:ssZ"),
            "invoice_status_id":6, // TODO buraya bakılacak
            "paid_value": this.orderDetail.order_value, // TODO buraya bakılacak
            "remaining_value":0, // TODO buraya bakılacak
            "note":"",
            "invoice_reciever_id":this.orderDetail.service_id,
            "labour_price": this.labourPrice,
            "labour_hour": this.labourHour,
            "labour_discount": this.orderDetail.labour_discount_percentage,
            "labour_discount_price":this.orderDetail.labour_discount_price
            // "invoice_reciever_type_id": this.orderDetail.customer_info.role_id
          
          }
          }).subscribe(async (result:any) => {
            if(result.errors){
              this.alertifyService.error(result.errors[0].message);
              
            }

            if(result.data){

              await this.updateOrder.mutate({"id":this.orderId,"changes":{invoice_id:result.data.insert_order_invoices_one.id}}).subscribe((upresult:any) => {
                if(upresult.errors){
                  this.alertifyService.error("Güncelleme esnasında bir sorun oluştu!");
                }
                if(upresult.data){
                  this.alertifyService.success("siparişiniz güncellendi");
                }
              })


              let i = 1;
             await  this.selectedPieces.forEach(async (element:any,val:number) => {
              
              
                let pname = "";
                
                if( element.original_name) {
                  pname =   element.original_name;
                }
              
                if(element.premium_name){
                  pname = element.premium_name;
                }

                if(element.equivalent_name){
                  pname = element.equivalent_name;
                }

            
               await this.createOrderInvoicePart.mutate({

                  object:{
                  "invoice_id":result.data.insert_order_invoices_one.id,
                  "part_id": element.id,
                  "part_name": pname,
                  "part_code":  element.part_code,
                  "labour_type_id": null,
                  "labour_value": element.labour_price,
                  "labour_discount": null,
                  "part_in_vat_price": element.totalPartInVatPrice,
                  "part_no_vat_price": element.totalPartNoVatPrice,
                  "invoice_type":1, // TODO buraya bakılacak
                  "part_unit_type_id": element.part_unit_type,
                  "part_unit": element.part_unit,
                  "part_type_id": element.part_type_id,
                  "gos_part_id": element.part_id,
                  "part_unit_price": (element.newNoVatPrice && element.newNoVatPrice != null) ? element.newNoVatPrice : 0,
                  "selected_part_id":  element.id,
                  "part_cost": element.cost
                  
                }

                }).subscribe((partResult:any) => {
                  if(partResult.errors){
                    this.alertifyService.error(partResult.errors[0].message);
                  }
                  if(partResult.data){
                    i++;
                    if(i == this.selectedPieces.length){
                      this.proformaCanShow = true;
                      this.formSuccess.invoice_parts_created = true;
                    }
                  }
                });
              });

             
              this.alertifyService.success("Fatura oluşturuldu");
              this.formSuccess.invoice_created = true;
            
                setTimeout(()=>{
                  this.randevuButtonDisabled = false;

                  if(changeStep){
                    this.stepChange('next');
                  }
                },3000)
            
            
            
           
            }
          })
        }
      }
    });
        
            
            
        
    
  }

  setUserInfoEmitter($event){

    this.userInfo = $event;
    this.getUser.watch({id:this.userInfo.id},{fetchPolicy:"no-cache"}).valueChanges.subscribe(resp => {
      if(resp.errors){
        this.alertifyService.error(resp.errors[0].message);
      }
      if(resp.data){
        this.userDbInfo = resp.data.users_by_pk;
      }
    })


  }

  setPackageEmitter($event){

    this.selectedPackageInput = $event;

  }
  
  setPackageList($event){
    this.packageList = $event;
  }

  setPackageKmList($event){
    this.packageKmList = $event;
  }

  setPaymentType($event){
    this.appointmentFormGroup.patchValue({
      paymentType: $event.paymentType,
      maintenancePackageId: this.selectedPackageInfo.id
    });
   
    this.selectedPaymentType = $event.paymentType;

    this.updateCard({
      step: this.stepList[this.step].id,
      maintenance_package_type_id: $event.selectedPackageTypeId
    });
  }


  setVerification(event: any){

    if(event){
      this.isPhoneVerified = true;
      this.showModal = false;
      this.isMessageSend = true;
    this.isStepCompleted = true;
    
    this.setStepVariable(true);
    setTimeout(()=> {
      this.stepChange('next');
    },1000)
    
    }else{
      this.isPhoneVerified = false;
    }

    

  }

  getStepBack(event){
    this.step = 1
  }
  
 async processStepList(){
  this.stepListProcessed =[];
  await this.stepList.forEach((element:any,index:number) => {
    if(!this.vehicleForm.value.is_package_have && element.position < 3){
      this.stepListProcessed.push(element);
    }
    
    if(this.vehicleForm.value.is_package_have  && element.position != 3 ){
      this.stepListProcessed.push(element);
    }

    if(this.vehicleForm.value.is_package_have && element.position == 3 && element.service_name == "app-appointment-v3-paket-secimi"){
      this.stepListProcessed.push(element);
    }

    if(!this.vehicleForm.value.is_package_have && element.position == 3 && element.service_name == "app-appointment-randevusuz-kayit-v3"){
      this.stepListProcessed.push(element);
    }
    
  });

 
 
 }

 closeModal(event:any){
  if(event){
    this.isPhoneVerified = false;
    this.showModal = false;
   
    this.isMessageSend = true;
    this.isStepCompleted = false;
    this.removeApproved = true;
  }
 }

 isMobileDevice(): boolean {
  return /Mobi|Android/i.test(navigator.userAgent);
}


}
