import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AlertifyService } from 'src/app/services/alertify.service';
import { environment } from 'src/environments/environment';

interface invoiceInfo  {
  gos_info: any,
  service_info: any,
  vehicle_info: any,
  total_amounts: any,
  invoice_info: any,
  invoice_parts: any
};

@Component({
  selector: 'app-shared-proforma-invoice',
  templateUrl: './proforma-invoice.component.html',
  styles: [
  ]
})


export class ProformaInvoiceComponent implements OnInit {

  @Input() invoice_id: bigint | undefined;
  @Input() proforma_key: number = 0;
  public apiUrl = environment.laravelApiEndpoint;
  public invoiceInfo: invoiceInfo | undefined;
  public hidePartNumbers = false;
  public invoiceTaked = false;
  constructor(
    private http: HttpClient,
    private alertifyService: AlertifyService
  ) { }

  async ngOnInit(): Promise<void> {

    

  }

  async ngOnChanges(){
    if(this.invoice_id){
      await this.http.post<any>(this.apiUrl + "appointment/proforma-invoice-id/",{invoice_id:this.invoice_id}, { headers: { token: `${localStorage.getItem('access_token')}` } }).subscribe(async resp=>{

        if(!resp.status){
          this.alertifyService.error(resp.message);
        }
      
        if(resp.status){
        
          this.invoiceInfo = resp.result;
          this.invoiceTaked = true;
        }else{
          this.alertifyService.error(resp.message)
        }
      });
    }
  }

 

  filterCode(item:any){
    if(!this.hidePartNumbers){
      return item;
    }
    const firstThreeDigits = item.substr(0, 3);
    const remainingChars = item.substr(3);
    const convertedString = firstThreeDigits + remainingChars.replace(/./g, '*');
    return convertedString;
  }

}
