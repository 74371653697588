import { Injectable } from '@angular/core';
import { gql, Mutation, Query } from 'apollo-angular';

export interface VehicleFuelType {
  id: bigint;
  name: String;
  active?: Boolean
  position_no?: Number
}

interface ResponseArray {
  vehicle_fuel_types: VehicleFuelType[];
}

interface ResponseSingle {
  vehicle_fuel_types_by_pk: VehicleFuelType
}

@Injectable()
export class VEHICLE_FUEL_TYPE_ALL extends Query<ResponseArray> {
  override document = gql`
  query VEHICLE_FUEL_TYPE_ALL($where: vehicle_fuel_types_bool_exp){
    vehicle_fuel_types(where: $where) {
      id
      name
    }
  }`;
}

@Injectable()
export class VEHICLE_FUEL_TYPE_ID extends Query<ResponseSingle> {
  override document = gql`
  query VEHICLE_FUEL_TYPE_ID($id: bigint!){
    vehicle_fuel_types_by_pk(id: $id) {
      id
      name
      active
    }
  }`;
}

@Injectable()
export class VEHICLE_FUEL_TYPE_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query VEHICLE_FUEL_TYPE_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc,$where: vehicle_fuel_types_bool_exp) {
      vehicle_fuel_types(limit: $limit, offset: $offset, order_by: {id: $orderBy},where:$where) {
        id
        name
        position_no
        active
      }
    }
  `;
}

@Injectable()
export class VEHICLE_FUEL_TYPE_COUNT extends Query<any> {
  override document = gql`
    query VEHICLE_FUEL_TYPE_COUNT($where: vehicle_fuel_types_bool_exp) {
        vehicle_fuel_types_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
  `;
}

@Injectable()
export class VEHICLE_FUEL_TYPE_DELETE extends Mutation<any> {
  override document = gql`
    mutation VEHICLE_FUEL_TYPE_DELETE($id: bigint!) {
        delete_vehicle_fuel_types(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

Injectable()
export class VEHICLE_FUEL_TYPE_CREATE extends Mutation<ResponseSingle> {
  override document = gql`
  mutation VEHICLE_FUEL_TYPE_CREATE($active: Boolean, $name: String) {
    insert_vehicle_fuel_types(objects: {active: $active, name: $name}) {
      returning {
        id
        name
        position_no
      }
      affected_rows
    }
  }`;
}

@Injectable()
export class VEHICLE_FUEL_TYPE_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
    mutation VEHICLE_FUEL_TYPE_UPDATE($id: bigint!, $active: Boolean, $name: String) {
        update_vehicle_fuel_types(where: {id: {_eq: $id}}, _set: {active: $active, name: $name}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}