import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-appointment-parca-secimi',
  templateUrl: './parca-secimi.component.html',
  styles: [
  ]
})
export class ParcaSecimiComponent implements OnInit {

  @Input() vehicleInfo: any;
  @Input() gosLabourPrice: any;
  @Input() brandLabourPrice: any;
  @Input() maintenancePackageId: bigint | undefined;
  @Output() packageDetail = new EventEmitter();
  @Input() selectedPieces:any = [];
  @Input() priceInfo:any;
  public totalAmount:number=0;
  public labourAmount:number=0;
  public gosMaintenancePrice: number = 0;
  public brandMaintenancePrice: number = 0;
  public subTotal:number = 0;
  public discount:number = 0;
  public vat:number = 0;
  public isFirst:boolean=true;

  constructor() { }

  ngOnInit(): void {
 
   
  }

  setOrderParts(event:any){


    this.selectedPieces = [];
    

    if(event.parts != undefined && event.parts.length > 0){
      
      event.parts.forEach((element:any) => {


        this.selectedPieces.push({
          package_detail_id: element.package_detail_id,
          part_id: element.selectedGosPart_id, 
          part_type_id: ( element.selectedEquivalent_partTypeId ) ? element.selectedEquivalent_partTypeId : 0,
          part_value: ( element.selectedEquivalent_partValue ) ? element.selectedEquivalent_partValue : 0 ,
          selected_part_id: ( element.selectedEquivalent_id ) ? element.selectedEquivalent_id : 0,
          service_value: ( element.selectedEquivalent_partValue ) ? element.selectedEquivalent_partValue : 0,
          part_unit_type: element.selectedEquivalent_partUnitTypeId,
          part_unit: element.selectedEquivalent_partUnit,
          brand_labour_price: ( element.brandLabourPrice ) ?  element.brandLabourPrice : 0,
          gos_labour_price: ( element.gosLabourPrice ) ?  element.gosLabourPrice : 0,
          price_unit_id: element.selectedEquivalent_priceUnitId,
          currency_unit_price: ( element.selectedEquivalent_currencyPriceUnitId ) ? element.selectedEquivalent_currencyPriceUnitId : 0,
          currency_total_price: ( element.selectedEquivalent_currencyTotalPrice ) ? element.selectedEquivalent_currencyTotalPrice : 0,
          currency_price_unit_id: element.selectedEquivalent_currencyPriceUnitId,
          currency: 1, //TODO: o anki döviz kurunu kayıt edecek
          total_price_no_vat : ( element.selectedEquivalent_priceNoVatTotal ) ? element.selectedEquivalent_priceNoVatTotal : 0,
          total_price: ( element.selectedEquivalent_priceTotal ) ? element.selectedEquivalent_priceTotal : 0,
          part_code: element.selectedEquivalent_partCode,
          part_no_vat_price: ( element.selectedEquivalent_partNoVatValue ) ? element.selectedEquivalent_partNoVatValue : 0,
          selected_equivalent_name: element.selectedEquivalent_name,
          selected_equivalent_part_type_name: element.selectedEquivalent_partTypeName,
          part_unit_name: element.selectedEquivalent_partUnitName,
          selectedGosPart_id :  element.selectedGosPart_id,
          selectedGosPart_name: element.selectedGosPart_name,
          selectedGosPart_groupName: element.selectedGosPart_groupName
        })
      });

      this.totalAmount = event.amount.grandTotal;
      this.subTotal = event.amount.subTotal;
      this.vat = event.amount.difference;
      this.discount = 0; //TODO: discounta bak
      
      let returnObj = {
        totalAmount: this.totalAmount,
        subTotal: this.subTotal,
        vat: this.vat,
        discount: this.discount,
        selectedPieces: this.selectedPieces
      };

      this.packageDetail.emit(returnObj);

    }

  }
  
}
