import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderType, ORDER_TYPE_ALL } from 'src/app/graphql/order_type.graphql';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-appointment-v2-hizmet-secimi-v2',
  templateUrl: './hizmet-secimi-v2.component.html',
  styles: [
  ],
  providers: [ORDER_TYPE_ALL]
})
export class HizmetSecimiV2Component implements OnInit {

  public orderTypes: OrderType[] | undefined;

  @Input() selectedOrderTypeId: Number | undefined;

  @Output() orderTypeId = new EventEmitter<Number>();
  @Output() buttonEmitter = new EventEmitter<boolean>();

  constructor(
    private getOrderTypes: ORDER_TYPE_ALL,
    private alertifyService: AlertifyService
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(){

    this.getOrderTypes.watch({where:{active:{_eq:true}}}).valueChanges.subscribe((response:any)=>{
      if(response.errors){
        this.alertifyService.error(response.errors[0].message);
      }

      if(response.data){
        this.orderTypes = response.data.order_types;
      }

      if(this.selectedOrderTypeId && this.selectedOrderTypeId > 0){
        this.orderTypeId.emit(this.selectedOrderTypeId);
      }
    })
  }
  

  orderTypeChange($event:any){
    this.orderTypeId.emit($event.target.value);
  }

  emitNext(){
    this.buttonEmitter.emit(true);
  }

}
