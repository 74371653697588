import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GET_DISTRICTS,District } from 'src/app/graphql/districts.graphql';

@Component({
  selector: 'app-shared-districts',
  templateUrl: './districts.component.html',
  styles: [
  ],
  providers:[GET_DISTRICTS]
})
export class DistrictsComponent implements OnInit {
  districtList: District[] | undefined;
  selectedCity!: any;
  @Input() selectedDistrictId!: any;
  @Input() districtInputId: any;
  @Output() district_id = new EventEmitter<Number | null>();
  @Input() widthClass: number | undefined = 9;
  @Input() showLabel:boolean = true;
  
  @Input() 
  set event(event: Number | null){
    this.selectedCity = event;
    if(this.selectedCity == null || this.selectedCity == "" || this.selectedCity === false){
      this.districtList = [];
    } else {
    
      this.getDistricts.watch({"where":{"city_id":{"_eq":this.selectedCity}}},{fetchPolicy:"no-cache"}).valueChanges.subscribe(response => {
        this.districtList = response.data.districts;
      })
    }
    
  }

  constructor(
    private getDistricts: GET_DISTRICTS
  ) { }

  ngOnInit(): void { 
  }

  ngOnChanges(){
    if(this.districtInputId == undefined){
      this.districtInputId = 'districtListId';
    }
  }

  // Shared Select Event
  selected(event: any) {
    
    this.district_id.emit(event);
   

}

}
