import { Injectable } from '@angular/core';
import { gql, Query, Mutation } from 'apollo-angular';

export interface PartGroup {
  id: bigint;
  name: String;
  note?: String;
  position_no?: Number;
  active?: Boolean;
  part_group_code: String;
  logo_group_code: String;
}

interface ResponseArray {
  part_groups: PartGroup[];
}

interface ResponseSingle {
  part_groups_by_pk: PartGroup
}

interface InsertMutation {
  insert_part_groups: {
    affected_rows: number
    returning: [{
      id: Number
    }]
  }
}

@Injectable()
export class PART_GROUP_ID extends Query<ResponseSingle> {
  override document = gql`
    query PART_GROUP_ID($id: bigint!) {
      part_groups_by_pk(id: $id) {
        active
        id
        name
        part_group_code
        note
        logo_group_code
        position_no
        parts {
          name
        }
      }
    }
  `;
}

@Injectable()
export class PART_GROUP_ALL extends Query<ResponseArray> {
  override document = gql`
    query PART_GROUP_ALL($where: part_groups_bool_exp){
      part_groups(where: $where) {
        id
        name
        part_group_code
        logo_group_code
        position_no
      }
    }
  `;
}

@Injectable()
export class PART_GROUP_CREATE extends Mutation<InsertMutation> {
  override document = gql`
    mutation PART_GROUP_CREATE($name: String!, $position_no: Int, $note: String, $active: Boolean!, $part_group_code:String!,$logo_group_code: String) {
      insert_part_groups(objects: {name: $name, position_no: $position_no, note: $note, active: $active, part_group_code: $part_group_code, logo_group_code: $logo_group_code}) {
        affected_rows
        returning {
          id
          name
        }
      }
    }
  `;
}

@Injectable()
export class PART_GROUP_UPDATE extends Mutation<ResponseSingle> {
  override document = gql`
    mutation PART_GROUP_UPDATE($id: bigint!, $name: String!, $note: String!, $position_no: Int!, $active: Boolean!, $part_group_code: String!,$logo_group_code: String) {
      update_part_groups(where: {id: {_eq: $id}}, _set: {name: $name, note: $note, position_no: $position_no, active: $active, part_group_code: $part_group_code, logo_group_code: $logo_group_code}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PART_GROUP_DELEETE extends Mutation<any> {
  override document = gql`
    mutation PART_GROUP_DELEETE($id: bigint!) {
      delete_part_groups(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
}

@Injectable()
export class PART_GROUP_PAGINATE extends Query<ResponseArray> {
  override document = gql`
    query PART_GROUP_PAGINATE($limit: Int!, $offset: Int!, $orderBy: order_by = asc, $where: part_groups_bool_exp) {
      part_groups(limit: $limit, offset: $offset, order_by: {id: $orderBy}, where: $where) {
        id
        name
        part_group_code
        position_no
        active
        note
        logo_group_code
      }
    }
  `;
}

@Injectable()
export class PART_GROUP_COUNT extends Query<any> {
  override document = gql`
    query PART_GROUP_COUNT($where: part_groups_bool_exp) {
      part_groups_aggregate( where: $where) {
        aggregate {
          count
        }
      }
    }
    
  `;
}