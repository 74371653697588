<div class="d-flex flex-center flex-column flex-column-fluid" style="width: 100%;">
    <div class="w-lg-500px p-10 p-lg-15 mx-auto">
        <form class="form w-100" novalidate="novalidate" [formGroup]="resetFormGroup" (ngSubmit)="onSubmit()">
            <div class="text-center mb-10">
                <h1 class="text-dark mb-3" *ngIf="pageType == 'newPass'">Yeni Şifre Al</h1>
                <h1 class="text-dark mb-3" *ngIf="pageType == 'forget'">Şifremi Unuttum!</h1>

                <div class="text-gray-400 fw-bold fs-4">Lütfen mail adresinizi giriniz</div>
            </div>
            <div class="fv-row mb-10">
                <label class="form-label fw-bolder text-gray-900 fs-6">Email</label>
                <input class="form-control form-control-solid" type="email" placeholder="" name="email"
                    autocomplete="off" formControlName="email" />
            </div>
            <div class="d-flex flex-wrap justify-content-center pb-lg-0">
                <button type="submit" class="btn btn-lg btn-primary fw-bolder me-4">
                    <span class="indicator-label">Şifre Al</span>
                    <span class="indicator-progress">Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
                <a [routerLink]="['/auth']" class="btn btn-lg btn-light-primary fw-bolder">Vazgeç</a>
            </div>
        </form>
    </div>
</div>

