<!--begin::Pricing-->
<div class="text-center" id="kt_pricing">
    <!--begin::Nav group-->
    <div class="nav-group landing-dark-bg d-inline-flex mb-15 d-none" data-kt-buttons="true" style="border: 1px dashed #2B4666;">
        <a href="#" class="btn btn-color-gray-600 btn-active btn-active-success px-6 py-3 me-2 active" data-kt-plan="month">Monthly</a>
        <a href="#" class="btn btn-color-gray-600 btn-active btn-active-success px-6 py-3" data-kt-plan="annual">Annual</a>
    </div>
    <!--end::Nav group-->
    <!--begin::Row-->
    <div class="row g-10">
        <!--begin::Col-->
        <div class="col-xl-4">
            <div class="d-flex h-100 align-items-center">
                <!--begin::Option-->
                <div class="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
                    <!--begin::Heading-->
                    <div class="mb-7 text-center">
                        <!--begin::Title-->
                        <h1 class="text-dark mb-5 fw-boldest">Orjinal</h1>
                        <!--end::Title-->
                        <!--begin::Description-->
                        <div class="text-gray-400 fw-bold mb-5">{{package?.name}}</div>
                        <!--end::Description-->
                        <!--begin::Price-->
                        <div class="text-center">
                            <span class="mb-2 text-primary d-none">$</span>
                            <span class="fs-3x fw-bolder text-primary" data-kt-plan-price-month="99" data-kt-plan-price-annual="999">{{toNumberFormat(originalAmounts?.generalTotal)}}</span>
                            <span class="fs-7 fw-bold opacity-50" data-kt-plan-price-month="Mon" data-kt-plan-price-annual="Ann">&#8378;</span>
                        </div>
                        <!--end::Price-->
                    </div>
                    <!--end::Heading-->
                    <!--begin::Features-->
                    <div class="w-100 mb-10">
                        <!--begin::Item-->
                        <div class="d-flex flex-stack mb-5" *ngFor="let item of originalList">
                            <span class="fw-bold fs-6 text-gray-800 text-start pe-3">{{filterCode(item.part_code)}} {{item.original_name}}</span>
                            <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                            <span class="fw-bold fs-6 text-gray-800 text-start pe-3">
                               {{toNumberFormat(item.totalPartNoVatPrice)}}
                            </span>
                            <!--end::Svg Icon-->
                        </div>
                        <!--end::Item-->
                      
                       
                    </div>
                    <!--end::Features-->
                    <!--begin::Select-->
                    <p>toplam: {{toNumberFormat(originalAmounts.total)}}</p>
                    <p>kdv: {{toNumberFormat(originalAmounts.kdv)}}</p>
                    <p>genelToplam: {{toNumberFormat(originalAmounts.generalTotal)}}</p>
                    <a (click)="setSelectedPackage('original')" class="btn btn-primary">Seçiniz</a>
                    <!--end::Select-->
                </div>
                <!--end::Option-->
            </div>
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-xl-4">
            <div class="d-flex h-100 align-items-center">
                <!--begin::Option-->
                <div class="w-100 d-flex flex-column flex-center rounded-3  py-20 px-10" style="background-color: #015029 !important;">
                    <!--begin::Heading-->
                    <div class="mb-7 text-center">
                        <!--begin::Title-->
                        <h1 class="text-white mb-5 fw-boldest">Premium</h1>
                        <!--end::Title-->
                        <!--begin::Description-->
                        <div class="text-white opacity-75 fw-bold mb-5">{{package?.name}}</div>
                        <!--end::Description-->
                        <!--begin::Price-->
                        <div class="text-center">
                            <span class="mb-2 text-white d-none">$</span>
                            <span class="fs-3x fw-bolder text-white" data-kt-plan-price-month="199" data-kt-plan-price-annual="1999">{{toNumberFormat(premiumAmounts.generalTotal)}}</span>
                            <span class="fs-7 fw-bold text-white opacity-75 d-none" data-kt-plan-price-month="Mon" data-kt-plan-price-annual="Ann">&#8378;</span>
                        </div>
                        <!--end::Price-->
                    </div>
                    <!--end::Heading-->
                    <!--begin::Features-->
                    <div class="w-100 mb-10">
                        <!--begin::Item-->
                        <div class="d-flex flex-stack mb-5" *ngFor="let item of premiumOfferList">
                            <span class="fw-bold fs-6 text-white opacity-75 text-start pe-3">{{filterCode(item.part_code)}} {{item.original_name}}</span>
                            <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                            <span class="fw-bold fs-6 text-white opacity-75 text-start pe-3">
                                {{toNumberFormat(item.newNoVatPrice)}}
                            </span>
                            <!--end::Svg Icon-->
                        </div>
                        <!--end::Item-->

                       
                   
                    </div>
                    <!--end::Features-->
                    <!--begin::Select-->
                    <p class="fw-bold fs-6 text-white opacity-75 text-start pe-3">toplam: {{toNumberFormat(premiumAmounts.total)}}</p>
                    <p class="fw-bold fs-6 text-white opacity-75 text-start pe-3">kdv: {{toNumberFormat(premiumAmounts.kdv)}}</p>
                    <p class="fw-bold fs-6 text-white opacity-75 text-start pe-3">genelToplam: {{toNumberFormat(premiumAmounts.generalTotal)}}</p>
                    <a (click)="setSelectedPackage('premium')" class="btn btn-color-primary btn-active-light-primary btn-light">Seçiniz</a>
                    <!--end::Select-->
                </div>
                <!--end::Option-->
            </div>
        </div>
        
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-xl-4">
            <div class="d-flex h-100 align-items-center">
                <!--begin::Option-->
                <div class="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
                    <!--begin::Heading-->
                    <div class="mb-7 text-center">
                        <!--begin::Title-->
                        <h1 class="text-dark mb-5 fw-boldest">Eşdeğer</h1>
                        <!--end::Title-->
                        <!--begin::Description-->
                        <div class="text-gray-400 fw-bold mb-5">{{package?.name}}</div>
                        <!--end::Description-->
                        <!--begin::Price-->
                        <div class="text-center">
                            <span class="mb-2 text-primary d-none">$</span>
                            <span class="fs-3x fw-bolder text-primary" data-kt-plan-price-month="999" data-kt-plan-price-annual="9999">{{toNumberFormat(equivalentAmounts.generalTotal)}}</span>
                            <span class="fs-7 fw-bold opacity-50 d-none" data-kt-plan-price-month="Mon" data-kt-plan-price-annual="Ann">&#8378;</span>
                        </div>
                        <!--end::Price-->
                    </div>
                    <!--end::Heading-->
                    <!--begin::Features-->
                    <div class="w-100 mb-10">
                        <!--begin::Item-->
                        <div class="d-flex flex-stack mb-5"  *ngFor="let item of equivalentOfferList">
                            <span class="fw-bold fs-6 text-gray-800 text-start pe-3"> {{filterCode(item.part_code)}} {{item.original_name}} </span>
                            <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                            <span class="fw-bold fs-6 text-gray-800 text-start pe-3">
                                {{toNumberFormat(item.newNoVatPrice)}}
                            </span>
                            <!--end::Svg Icon-->
                        </div>
                        <!--end::Item-->

                       
                      
                    </div>
                    <!--end::Features-->
                    <!--begin::Select-->
                    <p>toplam: {{toNumberFormat(equivalentAmounts.total)}}</p>
                    <p>kdv: {{toNumberFormat(equivalentAmounts.kdv)}}</p>
                    <p>genelToplam: {{toNumberFormat(equivalentAmounts.generalTotal)}}</p>
                    <a (click)="setSelectedPackage('equivalent')" class="btn btn-primary">Seçiniz</a>
                    <!--end::Select-->
                </div>
                <!--end::Option-->
            </div>
        </div>
        <!--end::Col-->
    </div>
    <!--end::Row-->

    <div class="mt-10" *ngIf="showPaymentButtons">
        
        <button (click)="setPaymentType('offline')" class="btn  btn-info" style="margin-right: 10px;">Serviste Öde (Fiyat Değişebilir)</button>
        <button (click)="setPaymentType('online')" class="btn  btn-primary">Şimdi Öde (Fiyatı sabitle ve ödül kazan)</button>
    </div>
</div>
<!--end::Pricing-->