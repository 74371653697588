<style>
    .privacy p{
        font-size: 16px;
    }
</style>
<!--begin::Post-->
<div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">

        
        <div class="row gy-5 g-xl-8" >
            
            <!--begin::Col-->
            <div class="col-xl-12 mb-5">
                <!--begin::Mixed Widget 2-->
                <div class="card card-xl-stretch">
                  
                    <!--begin::Body-->
                    <div class="card-body p-0">
                        
                        <!--begin::Stats-->
                        <div class="card position-relative hero-section">
                            <!--begin::Row-->
                            <div class="row g-0" style="background-color: #F3F3F3; background-image:url(https://cdn.destechhasar.com/garantili-oto-servis/static/garantili-oto-servis-01-2000x1200.jpeg); background-repeat: no-repeat; background-position-x: right; background-position-y: bottom;">
                                <!--begin::Col-->
                                <div class="col px-6 py-8 rounded-2 me-7 mb-7" style=" display: table;">
                                </div>
                                <!--end::Col-->
                                <!--begin::Col-->
                                <div class="col-xl-6 px-6 py-8 rounded-2 mb-7" >
                                   
                               
                                </div>
                                <!--end::Col-->
                            </div>
                            <!--end::Row-->
                           
                        </div>
                        <!--end::Stats-->
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Mixed Widget 2-->
            </div>
            <!--end::Col-->
           
           
        </div>
        <!--end::Row-->

        <div class="row gy-5 g-xl-8">
           
            <!--begin::Col-->
            <div class="col-xl-12 col-xxl-12 mb-5 mb-xl-10">
                <!--begin::Table Widget 3-->
                <div class="card card-flush h-xl-100">
                    <!--begin::Card header-->
                    <div class="card-header py-5">
                        <!--begin::Tabs-->
                        <div class="card-title pt-3 mb-0 gap-4 gap-lg-10 gap-xl-15 nav nav-tabs border-bottom-0" data-kt-table-widget-3="tabs_nav">

                            <h2 style="font-weight: 600;
                            font-size: 32px;
                            color: #004422;
                            margin: 0 0 30px; ">Açık Rıza Metni</h2>
                        </div>
                        <!--end::Tabs-->
                    
                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body pt-1">
                        <!--begin::Sort & Filter-->
                        <div class="d-flex flex-stack flex-wrap gap-4 privacy">

                            <p>
                                İletişim / Veri İşleme Faliyetlerine İlişkin Açık Rıza Beyanı

                            </p>

                            <p>
                                Elektronik Ticari İleti: Garantili Oto Servis Oto Yönetim ve Destek Hizmetleri A.Ş. (Garantili Oto Servis) nezdinde tarafıma verilen/verilecek olan hizmet kapsamında ilettiğim ve Garantili Oto Servis Oto Yönetim ve Destek Hizmetleri A.Ş. (Garantili Oto Servis) tarafından ilgili kişi ve kurumlardan hizmet kapsamında sağlanacak kişisel bilgilerimin ihtiyaçlarım doğrultusunda ürün, uygulama, avantaj veya kampanyalardan yaralanabilmem için tarafıma bilgilendirme yapılmasına, başkaca hizmet ürününün tanıtılması, pazarlanması ve satışı da dahil olmak üzere tarafımla her türlü iletişimin sağlanması amacıyla işlenmesine ve bu doğrultuda kişisel bilgilerimin Garantili Oto Servis Oto Yönetim ve Destek Hizmetleri A.Ş. (Garantili Oto Servis) tarafından iş ortakları ile paylaşılmasına muvafakat ettiğimi, bunlar tarafından doğrudan ya da SMS, Resim, Animasyon, Kısa Mesaj, MMS, Telefon, Faks, Otomatik Arama Makineleri, Elektronik Posta ve benzeri iletişim kanallarından iletilecek veri, ses ve görüntü içerikli bilgilendirme, tanıtım ve pazarlama iletilerinin 6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanuna uygun olarak tarafıma gönderilmesine izin verdiğimi; dilediğim zaman destek@garantiliotoservis.com adresine e-posta göndererek, tamamen veya ürün ve kanal bazında, veri, ses ve görüntü dahil her türlü ileti gönderimi reddetme hakkına sahip olduğumu bildiğimi kabul ve beyan ederim.

                            </p>
                            

                        </div>
                    </div>
                </div>
            </div>
        </div>


    
    </div>
    <!--end::Container-->
</div>
<!--end::Post-->