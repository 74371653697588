<div class="table-responsive">
    <!--begin::Table-->
    <table class="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
        <!--begin::Table head-->
        <thead>
            <tr class="fw-bolder text-muted">
                <th class="w-25px"></th>
                <th class="min-w-150px">Parça Adı/Kodu</th>
                <th class="min-w-200px">Marka / Eşdeğer Kodu</th>
                <th class="min-w-30px">Stok</th>
                <th class="min-w-150px">Fiyat-İndirim</th>
                <th class="min-w-100px">Toplam</th>
               
            </tr>
        </thead>
        <!--end::Table head-->
        <!--begin::Table body-->
        <tbody>
            <tr *ngFor="let item of pList">
                <td>
                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input class="form-check-input widget-13-check" name="selectedAnswerId" (click)="setAnswer($event)" [checked]="item.id == item.order_offers.selected_answer_id" type="radio" value="{{item.id}}" />
                    </div>
                </td>
                <td>
                    <a class="text-dark fw-bolder text-hover-primary fs-6">{{item.current_name}}</a>
                    <span class="text-muted fw-bold text-muted d-block fs-7">{{item.part_code}}</span>
                </td>
                <td>
                    <a class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{{item.part_brand}}</a>
                    <span class="text-muted fw-bold text-muted d-block fs-7">{{item.equivalent_part_code}}</span>
                </td>
                <td>
                    <a class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{{item.offer_part_count}}</a>
                </td>
                <td>
                    <a  class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{{item.offer_part_price}}₺</a>
                    <span class="text-muted fw-bold text-muted d-block fs-7">%{{item.discount}}</span>
                </td>
                <td class="text-dark fw-bolder text-hover-primary fs-6">{{ item.offer_part_price }}₺</td>
              
            </tr>
          
        </tbody>
        <!--end::Table body-->
    </table>
    <!--end::Table-->
</div>